import React from 'react';
import {translate} from 'react-i18next';
import PropTypes from 'prop-types';
import constants from "../../Logic/constants";
// import SVGInline from 'react-svg-inline';
import Fade from "../../Animation/Fade";

let LoaderView = ({t, isLoading, loaderImage, altText, animate, useInlineSVG}) => (
	<Fade visible={isLoading}>
		<div id="component-loader" className={(animate ? 'animate' : '')}>
			{!useInlineSVG && <img src={loaderImage} alt={t(altText)}/>}
			{/*{useInlineSVG && <SVGInline svg={loaderImage}/>}*/}
		</div>
	</Fade>
);
LoaderView.propTypes = {
	isLoading: PropTypes.bool,
	loaderImage: PropTypes.node,
	altText: PropTypes.string,
	animate: PropTypes.bool
};
LoaderView.defaultProps = {
	isLoading: false,
	loaderImage: constants.LOADER,
	altText: 'imageAlt.loader',
	animate: false,
	useInlineSVG: true
};

export default translate()(LoaderView);

