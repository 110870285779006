import {connect} from "react-redux";
import {isOnline} from "../../../actions/userActions";
import {appointmentAction__load} from "../../../actions/appointmentActions";
import {loadResourcesOfHouse} from "../../../actions/resourceActions";
import {SECTION} from "../../../Logic/constants";
import Appointments from "../../../components/intern/appointments/Appointments";
import {loadAreasAndGroups} from "../../../actions/rightActions";
import {withRights} from "../../../Tools/RightsProvider";
import {translate} from "react-i18next";
import withInit from "../../../Logic/withInit";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_APPOINTMENTS} from "../../../actions";

const m2s = (state) => {
	return {
		appointments: state.appointments,
		resources: state.resources.list,
		serviceMap: state.kfz.service.list,
		loading: isLoading(state, PROC_APPOINTMENTS)
	};
};

const m2d = (dispatch) => {
	return {
		init: () => {
			dispatch(isOnline(() => {
				dispatch(loadAreasAndGroups());
				// dispatch(appointmentAction__load());
				dispatch(loadResourcesOfHouse());
				// dispatch(kfzAction__fetchServices());
				// dispatch(vehicleAction__fetch());
				// dispatch(clientAction__fetch());
				
			}));
		},
		onFetch: (from, to, onSuccess) => dispatch(appointmentAction__load({from, to}, false, onSuccess))
	};
};

let AppointmentsController = withRights(SECTION.ORDERS, Appointments);
AppointmentsController = withInit(AppointmentsController);
AppointmentsController = connect(m2s, m2d)(AppointmentsController);
AppointmentsController = translate()(AppointmentsController);

export default AppointmentsController;