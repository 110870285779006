import * as React from "react";
import BaseModel from "./BaseModel";
import {RegistrationMark} from "../components/intern/vehicles/RegistrationMark";
import moment from "../Logic/Moment";
import {Icon} from "semantic-ui-react";
import {clientCall__getOne} from "../actions/clientActions";
import {kfzCall__getModel} from "../actions/kfzActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {vehicleAction__collect, vehicleAction__collectOrders} from "../actions/vehicleActions";
import {get, isArray, isFunction} from "lodash";
import withInit, {withChange} from "../Logic/withInit";
import {array2object} from "../Logic/extensions";
import {ProviderComponent} from "../components/partials";

const Provider = ({vehicle, placeholder, children}) => vehicle &&  isFunction(children) ? children(vehicle) : placeholder;
Provider.propTypes = {
	vehicle_id: PropTypes.number,
	placeholder: PropTypes.node
};
Provider.defaultProps = {
	placeholder: null
};

const OrderProvider = ({order_vehicle, placeholder, children}) => order_vehicle && isFunction(children) ? children(order_vehicle) : placeholder;
OrderProvider.propTypes = {
	order_vehicle_id: PropTypes.number,
	placeholder: PropTypes.node
};
OrderProvider.defaultProps = {
	placeholder: null
};

const CollectProvider = ({children, placeholder, vehicles, loading}) => isArray(vehicles) && isFunction(children) ? children(vehicles) : placeholder;
CollectProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({vehicle_id: PropTypes.number})])),
	preview: PropTypes.bool,
	placeholder: PropTypes.node,
	dynamic: PropTypes.bool
};
CollectProvider.defaultProps = {
	ids: [],
	placeholder: null,
	preview: false,
	dynamic: true
};

const CollectOrderProvider = ({children, placeholder, order_vehicles, vehicles, loading}) => isArray(order_vehicles) && isFunction(children) ? children(order_vehicles, vehicles) : placeholder;
CollectOrderProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({order_vehicle_id: PropTypes.number})])),
	preview: PropTypes.bool,
	placeholder: PropTypes.node,
	dynamic: PropTypes.bool
};
CollectOrderProvider.defaultProps = {
	ids: [],
	placeholder: null,
	preview: false,
	dynamic: true,
	vehicles: {}
};

export default class Vehicle extends BaseModel {
	_validateModel(model) {
		return "vehicle_id" in model && "client_id" in model && "model_id" in model;
	}
	
	get id() { return this.__model.vehicle_id; }
	get vehicle_id() { return this.__model.vehicle_id; }
	get client_id() { return this.__model.client_id; }
	get client() { return clientCall__getOne(this.client_id); }
	get model_id() { return this.__model.model_id; }
	get model() { return kfzCall__getModel(this.model_id); }
	get hsn() { return this.__model.hsn; }
	get tsn() { return this.__model.tsn; }
	get hsn_tsn() { return this.join('hsn', 'r||/', 'tsn'); }
	get chassis_number() { return this.__model.chassis_number; }
	get chassis_number_icon_name() { return "crop"; }
	get chassis_number_icon() { return <Icon name={this.chassis_number_icon_name}/>; }
	get registration_mark() { return this.__model.registration_mark; }
	get registration_shield() { return this.registration_mark ? <RegistrationMark>{this.registration_mark}</RegistrationMark> : null}
	get km() { return this.__model.km; }
	get km_icon_name() { return "road"}
	get km_icon() { return <Icon name={this.km_icon_name}/>; }
	get model_year() { return this.__model.model_year; }
	get name() { return this.__model.name; }
	get loan_car() { return Boolean(this.__model.loan_car); }
	get is_loan_car() {
		return Vehicle.loan_car_status(this.__model);
	}
	static loan_car_status(vehicle) {
		const now = moment();
		if (vehicle.loan_car_start) {
			return moment(vehicle.loan_car_start) < now && (!vehicle.loan_car_end || moment(vehicle.loan_car_end) > now);
		}
		if (vehicle.loan_car_end) {
			return moment(vehicle.loan_car_end) > now;
		}
		return false;
	}
	get loan_car_yes_no() { return this.loan_car ? 'Ja' : 'Nein'; }
	get loan_car_start() { return this.__model.loan_car_start; }
	get loan_car_start_moment() { return this.loan_car_start ? moment(this.loan_car_start) : null; }
	get loan_car_end() { return this.__model.loan_car_end; }
	get loan_car_end_moment() { return this.loan_car_end ? moment(this.loan_car_end) : null; }
	get is_deleted() { return Boolean(this.__model.deleted_at); }
	get first_registration() { return this.__model.first_registration; }
	get first_registration_moment() { return this.first_registration ? moment(this.first_registration) : null; }
	get first_registration_xxx() { return this.first_registration_moment ? this.first_registration_moment.format("LLL") : null; }
	get first_registration_xx() { return this.first_registration_moment ? this.first_registration_moment.format("LL") : null; }
	get first_registration_x() { return this.first_registration_moment ? this.first_registration_moment.format("L") : null; }
	get first_registration_icon_name() { return "key"; }
	get first_registration_icon() { return <Icon name={this.first_registration_icon_name}/>; }
	
	static Provider = connect((state, props) => {
		const v = state.map.vehicles[props.vehicle_id] || state.vehicles.list[props.vehicle_id];
		return {
			vehicle: v ? new Vehicle(v) : null
		};
	}, (dispatch, props) => {
		return {
			withChange: () => {
				props.vehicle_id && dispatch(vehicleAction__collect([props.vehicle_id]));
			}
		};
	})(withChange(Provider, {access: 'vehicle_id', dynamicIndex: 'dynamic'}));
	
	static OrderProvider = connect(
		(state, props) => ({
			order_vehicle: get(state, ['map', 'order2vehicles', props.order_vehicle_id], null)
		}),
		(dispatch, props) => ({
			init: () => props.order_vehicle_id && dispatch(vehicleAction__collectOrders([props.order_vehicle_id]))
		})
	)(withInit(OrderProvider));
	
	static CollectProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.vehicle_id || id)).filter(Boolean);
			let vehicles = [...ids].map(id => state.map.vehicles[id] || null);
			if (!vehicles.length) {
				vehicles = null;
			} else if (vehicles.includes(null)) {
				if (props.preview) {
					vehicles = vehicles.filter(Boolean);
				} else {
					vehicles = null;
				}
			}
			
			return {
				vehicles,
				ids,
			};
		},
		(dispatch) => ({
			withChange: ({ids}) => ids && ids.length && dispatch(vehicleAction__collectOrders(ids))
		})
	)(withChange(CollectProvider));
	
	static CollectOrderProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.order_vehicle_id || id)).filter(Boolean);
			let vehicles = [...ids].map(id => state.map.order2vehicles[id] || null);
			if (!vehicles.length) {
				vehicles = null;
			} else if (vehicles.includes(null)) {
				if (props.preview) {
					vehicles = vehicles.filter(Boolean);
				} else {
					vehicles = null;
				}
			}
			
			return {
				order_vehicles: vehicles,
				ids,
				vehicles: isArray(vehicles) ? array2object(vehicles.map(v => state.map.vehicles[v.vehicle_id]).filter(Boolean))(v => v.vehicle_id) : null
			};
		},
		(dispatch) => ({
			withChange: ({ids}) => ids && ids.length && dispatch(vehicleAction__collectOrders(ids))
		})
	)(withChange(CollectOrderProvider));
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.vehicles[id];
			data = data ? new Vehicle(data) : null;
			return {data};
		}
	)(ProviderComponent)
	
}