import * as React from 'react';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {startOfMinute} from "date-fns";
import cn from "classnames";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Required} from "../../../../Logic/extensions";
import {moment} from "../../../../Logic/Moment";
import {dateTime, pipeDateFunc} from "./Helpers";
import {translate} from "react-i18next";
import {MaterialCompoundDateInput} from "../../../../Tools/DatePicker";

export class RestrictedRangeSelector extends StatyComponent {
	static propTypes = {
		rangeStart: PropTypes.instanceOf(Date).isRequired,
		rangeEnd: PropTypes.instanceOf(Date).isRequired,
		defaultStart: PropTypes.instanceOf(Date),
		defaultEnd: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
		className: PropTypes.string,
		timeIntervals: PropTypes.number,
		startChildren: PropTypes.node,
		endChildren: PropTypes.node,
		dateProps: PropTypes.object,
		nowBuffer: PropTypes.number,
		strict: PropTypes.bool
	};
	static defaultProps = {
		defaultStart: null,
		defaultEnd: null,
		timeIntervals: 15,
		nowBuffer: 0,
		strict: false
	};
	
	state = {
		start_point: null,
		end_point: null
	};
	
	componentDidMount() {
		super.componentDidMount();
		this.setState({
			start_point: this.props.defaultStart,
			end_point: this.props.defaultEnd,
		}, () => {
			this.publish();
		});
		// this.publish();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {defaultStart, defaultEnd, rangeStart, rangeEnd} = this.props;
		if (dateTime(rangeStart) !== dateTime(prevProps.rangeStart)) {
			if (defaultStart && dateTime(defaultStart) !== dateTime(this.state.start_point)) {
				this.set('start_point')(startOfMinute(defaultStart));
			}
		}
		if (dateTime(rangeEnd) !== dateTime(prevProps.rangeEnd)) {
			if (defaultEnd && dateTime(defaultEnd) !== dateTime(this.state.end_point)) {
				this.set('end_point')(startOfMinute(defaultEnd));
			}
		}
	}
	
	publish = () => {
		this.props.onChange(this.params, {
			valid: this.valid,
			valid_start: this.valid_start,
			valid_end: this.valid_end
		});
	}
	
	get params() {
		return {
			start_point: this.start_point,
			end_point: this.end_point
		};
	}
	
	get start_point() {
		return this.state.start_point || this.props.rangeStart;
	}
	
	get end_point() {
		return this.state.end_point || this.props.rangeEnd;
	}
	
	get valid_start() {
		const now = startOfMinute(new Date());
		if (now.valueOf() > this.start_point.valueOf()) {
			if (now.valueOf() - this.start_point.valueOf() > (this.props.nowBuffer * 1000)) {
				return false;
			}
		}
		return this.start_point.valueOf() <= this.props.rangeStart.valueOf();
	}
	
	get valid_end() {
		return this.end_point >= this.props.rangeEnd;
	}
	
	get valid() {
		return this.valid_start && this.valid_end;
	}
	
	get min_date() {
		return new Date(Math.min(Date.now() - this.props.nowBuffer * 1000, this.props.rangeStart));
	}
	
	set = (index) => (value) => this.setState({[index]: value}, () => {
		this.publish();
	});
	
	render() {
		let {className, defaultStart, defaultEnd, rangeStart, rangeEnd, timeIntervals, dateProps, startChildren, endChildren, nowBuffer, strict, t, i18n, tReady, onChange,...props} = this.props;
		return <div className={cn('order-search-restricted-range-selector', className)} {...props}>
			<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.CENTER} wrap>
				<div className={cn('restricted-range-selector-body', 'restricted-range-selector-body-start')}>
					<MaterialCompoundDateInput
						className={cn('restricted-range-selector', 'restricted-range-selector-start')}
						dateLabel={<Required>Annahmezeit</Required>}
						selectsStart
						required
						minDate={this.min_date}
						maxDate={rangeStart}
						startDate={this.start_point}
						endDate={this.end_point}
						date={this.start_point}
						onChange={pipeDateFunc(this.set('start_point'))}
						timeLabel={<span>&nbsp;</span>}
						timePlaceholder={'__:__'}
						{...dateProps}
						children={startChildren}
					/>
					<div className={cn('restricted-range-selector-info', 'restricted-range-selector-info-start')}>Bis: {moment(rangeStart).format('LLL [Uhr]')}</div>
				</div>
				<div className={cn('restricted-range-selector-body', 'restricted-range-selector-body-end')}>
					<MaterialCompoundDateInput
						className={cn('restricted-range-selector', 'restricted-range-selector-end')}
						dateLabel={<Required>Abgabezeit</Required>}
						selectsEnd
						required
						minDate={rangeEnd}
						startDate={this.start_point}
						endDate={this.end_point}
						date={this.end_point}
						onChange={pipeDateFunc(this.set('end_point'))}
						timeLabel={<span>&nbsp;</span>}
						timePlaceholder={'__:__'}
						{...dateProps}
						children={endChildren}
					/>
					<div className={cn('restricted-range-selector-info', 'restricted-range-selector-info-end')}>Ab: {moment(rangeEnd).format('LLL [Uhr]')}</div>
				</div>
			</EasyFlex>
		</div>
	}
}
RestrictedRangeSelector = translate()(RestrictedRangeSelector);