import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon} from "semantic-ui-react";
import {round} from "lodash";
import {connect} from "react-redux";
import {kfzState__getServicePrice} from "../../../../actions/kfzActions";
import {resourceState__getAccumulatedHoursOfWork} from "../../../../actions/resourceActions";

class _PriceView extends React.PureComponent {
	render() {
		const {priceData: {price, level}, empty} = this.props;
		if ( !price || !price.price ) {
			if (empty) {
				return empty;
			}
			return <Button>FAIL</Button>;
		}
		
		return(
			<React.Fragment>
				{level === 'model:custom' && <Button disabled icon><Icon name={'car'} color={"red"}/></Button>}
				{level === 'model:origin' && <Button disabled icon><Icon name={'car'}/></Button>}
				{level === 'kfz:custom' && <Button disabled icon><Icon name={"home"}/></Button>}
				{/*{level === 'kfz:origin' && <Button disabled icon><Icon name={"home"}/></Button>}*/}
				<Button icon disabled><Icon name="time"/> {price.hours_of_work}&nbsp;&nbsp;<Icon name="eur"/> {price.price}</Button>
			</React.Fragment>
		);
	}
}

const PriceView = connect(
	(state, props) => {
		return {
			priceData: kfzState__getServicePrice(state, props.kfz_id, props.model_id, props.service_id)
		};
	}
)(_PriceView);
PriceView.propTypes = {
	kfz_id: PropTypes.number.isRequired,
	model_id: PropTypes.number.isRequired,
	service_id: PropTypes.number.isRequired,
	priceData: PropTypes.shape({
		level: PropTypes.string,
		price: PropTypes.number.isRequired
	}),
	empty: PropTypes.bool
};
PriceView.defaultProps = {
	empty: false
};


class _ResourceView extends React.PureComponent {
	render() {
		const {hours_of_work: givenHours, priceData: {price}} = this.props;
		if ( !price || !price.price) {
			return <Button>FAIL</Button>;
		}
		const hours_of_work = givenHours || price.hours_of_work;
		const diff = round(price.hours_of_work - hours_of_work, 1);
		let color = null;
		if ( diff < 0) {
			color = 'red';
		} else if (diff > 0) {
			color = "green";
		}
		
		return(
			<React.Fragment>
				<Button icon disabled><Icon name="users" color={color}/> <strong style={{color}}>{diff}</strong></Button>
			</React.Fragment>
		);
	}
}

const ResourceView = connect(
	(state, props) => {
		return {
			hours_of_work: resourceState__getAccumulatedHoursOfWork(state, props.kfz_id, props.model_id, props.service_id),
			priceData: props.priceData || kfzState__getServicePrice(state, props.kfz_id, props.model_id, props.service_id)
		};
	}
)(_ResourceView);
ResourceView.propTypes = {
	kfz_id: PropTypes.number.isRequired,
	model_id: PropTypes.number.isRequired,
	service_id: PropTypes.number.isRequired,
	priceData: PropTypes.shape({
		price: PropTypes.number.isRequired,
		level: PropTypes.string
	})
};

export {PriceView, ResourceView};