import * as React from 'react';
import PropTypes from 'prop-types';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {Worker} from "../../../../models";
import {array2object, deepMemoize as memoizeOne, Required, trueNull, withScreen} from "../../../../Logic/extensions";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {Button, Checkbox, Dropdown, Header, Icon, Message, Modal, Segment, Table} from "semantic-ui-react";
import {moment} from "../../../../Logic/Moment";
import {MaterialMobileAwarePicker, TimeInput} from "../../../../Tools/DatePicker";
import {TextField} from "material-ui";
import {Trans, translate} from "react-i18next";
import {addDays, areIntervalsOverlapping, format, startOfMinute, startOfWeek} from 'date-fns';
import {sortNumAsc} from "ith-sort/source";
import {NegativeSegment} from "../../../../components/partials/Segments";
import {scheduleCall__dynamicInsert} from "../../../../actions/scheduleActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";

export const DynamicScheduleInsertModes = {
	DELETE: 'delete',
	DELETE_DAY: 'delete-day',
	DELETE_DAY_RESOURCE: 'delete-day-resource',
	SKIP_DAY: 'skip-day',
	SKIP_DAY_RESOURCE: 'skip-day-resource',
	REPLACE: 'replace',
	MERGE: 'merge'
};

export const DynamicScheduleModeInformation = ({inverted}) => (
	<Segment.Group>
		{Object.values(DynamicScheduleInsertModes).map(mode =>
			<Segment key={mode} inverted={inverted}>
				<Header as={'h3'}><Trans defaults={mode} i18nKey={`dynamic-schedule.mode.${mode}`}/></Header>
				<p><Trans defaults={mode} i18nKey={`dynamic-schedule.mode-description.${mode}`}/></p>
			</Segment>
		)}
	</Segment.Group>
);

const ConfigSummary = ({title, config, resourceMap, getDay, getTime, actionButton, inverted, ...props}) => (
	<Segment inverted={inverted} {...props}>
		<EasyFlex align={title ? EasyFlex.align.SPACE_BETWEEN : EasyFlex.align.END}>
			{title && <Header inverted={inverted} as={'h4'}>{title}</Header>}
			{actionButton}
		</EasyFlex>
		<div style={{marginBottom: 8}}>
			<Button.Group basic inverted={inverted}>
				{config.resources.map(id =>
					<Button basic className={'not-clickable'} key={`summary-resource-${id}`}>{resourceMap[id].name}</Button>
				)}
			</Button.Group>
		</div>
		<div style={{marginBottom: 8}}>
			<Button.Group basic inverted={inverted}>
				{config.days.map(index =>
					<Button basic className={'not-clickable'} key={`summary-day-${index}`}>{getDay(index)}</Button>
				)}
			</Button.Group>
		</div>
		<div>
			<Button.Group basic inverted={inverted}>
				{config.times.map(({start, end}) =>
					<Button basic className={'not-clickable'} key={'summary-' + getTime(start) + getTime(end)}>{getTime(start)} - {getTime(end)}</Button>
				)}
			</Button.Group>
		</div>
	</Segment>
);
ConfigSummary.defaultProps = {
	inverted: true
};

export class DynamicScheduleInsertMask extends StatyComponent {
	static propTypes = {
		worker: PropTypes.object.isRequired,
		resources: PropTypes.arrayOf(PropTypes.object),
		showFull: PropTypes.bool,
		onSave: PropTypes.func,
		onNotification: PropTypes.func,
		onAfterSave: PropTypes.func,
	}
	static defaultProps = {
		resources: [],
		onSave: scheduleCall__dynamicInsert,
		onNotification: dispatchSnack,
		onAfterSave: () => {}
	};
	
	state = {
		configs: [],
		resources: {},
		days: {},
		start: null,
		end: null,
		times: [],
		range_start: null,
		range_end: null,
		show_config: null,
		mode: DynamicScheduleInsertModes.DELETE,
		saving: false
	};
	
	save = async () => {
		const {onNotification, onSave, worker, onAfterSave} = this.props;
		const {range_start, range_end, configs, mode} = this.state;
		try {
			this.setState({saving: true});
			const result = await onSave(configs, {start: range_start, end: range_end}, worker.workers_id, mode);
			this.setState({configs: [], range_start: null, range_end: null, show_config: null, mode: DynamicScheduleInsertModes.DELETE});
			onAfterSave(result);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({saving: false});
		}
	}
	
	addConfig = () => this.setState(state => ({
		...state,
		configs: [...state.configs, {
			resources: this.getResourceList(state.resources),
			days: this.getDayList(state.days),
			times: state.times
		}],
		resources: {},
		days: {},
		start: null,
		end: null,
		times: [],
		show_config: null,
		show_mode: false
	}));
	
	removeConfig = index => () => this.setState(state => ({
		...state,
		configs: state.configs.filter((_, i) => i !== index),
		show_config: null,
		range_start: null,
		range_end: null
	}));
	
	setMode = (mode) => () => this.setState({mode});
	
	getWorker = memoizeOne(
		worker => new Worker(worker)
	);
	
	get worker() {
		return this.getWorker(this.props.worker);
	}
	
	getResourceMap = memoizeOne(
		list => array2object(list)(r => Number(r.resource_id || r))
	);
	
	get resourceMap() {
		return this.getResourceMap(this.props.resources);
	}
	
	getResourceList = memoizeOne(
		map => Object.entries(map).filter(s => Boolean(s[1])).map(s => Number(s[0]))
	);
	
	get resources() {
		return this.getResourceList(this.state.resources);
	}
	
	getDayList = memoizeOne(
		map => Object.entries(map).filter(s => Boolean(s[1])).map(s => Number(s[0]))
	);
	
	get days() {
		return this.getDayList(this.state.days);
	}
	
	set = (field) => value => this.setState({[field]: value});
	setValue = (field, value) => () => this.set(field)(value);
	
	setConfigView = (index) => () => this.setState(state => ({
		show_config: state.show_config === index ? null : index
	}));
	
	getDay = index => moment().day(index + 1).format('dd');
	getTime = date => format(date, 'HH:mm')
	
	setResource = id => () => this.setState(state => ({
		...state,
		resources: {
			...state.resources,
			[id]: !state.resources[id]
		}
	}));
	
	isResource = (id) => Boolean(this.state.resources[id]);
	
	setDay = index => () => this.setState(state => ({
		...state,
		days: {
			...state.days,
			[index]: !state.days[index]
		}
	}));
	
	isDay = (index) => Boolean(this.state.days[index]);
	
	addTime = () => this.setState(state => ({
		...state,
		times: [...state.times, {start: state.start, end: state.end}].sort(sortNumAsc(t => t.start.valueOf())),
		start: null,
		end: null
	}));
	
	removeTime = (index) => () => this.setState(state => ({
		...state,
		times: state.times.filter((v, i) => i !== index)
	}));
	
	getId = (...args) => args.join('_');
	assignTime = (date, source) => {
		// noinspection JSCheckFunctionSignatures
		const base = new Date(date.valueOf());
		base.setHours(source.getHours());
		base.setMinutes(source.getMinutes());
		return startOfMinute(base);
	}
	
	prepareConfig = memoizeOne(
		config => {
			const start = startOfWeek(new Date());
			// const end = endOfWeek(new Date());
			let holder = {};
			for (const data of config) {
				for (const rid of data.resources) {
					for (const day of data.days) {
						for (const time of data.times) {
							const id = this.getId(rid, day);
							if (!holder[id]) {
								holder[id] = [];
							}
							const baseDate = addDays(start, day);
							const values = {start: this.assignTime(baseDate, time.start), end: this.assignTime(baseDate, time.end)};
							holder[id].push(values);
						}
					}
				}
			}
			const list = Object.values(holder);
			let overlaps = [];
			for (const items of list) {
				for (let id1 = 0; id1 < items.length - 1; ++id1) {
					for (let id2 = id1 + 1; id2 < items.length; ++id2) {
						if (id1 === id2) {
							continue;
						}
						const item1 = items[id1];
						const item2 = items[id2];
						if (areIntervalsOverlapping(item1, item2)) {
							overlaps.push({id1, id2, item1, item2});
						}
					}
				}
			}
			return overlaps;
		}
	);
	
	get alignment() { return this.props.screen.width > 767 ? EasyFlex.align.START : EasyFlex.align.CENTER; }
	
	get overlaps() {
		return this.prepareConfig(this.state.configs);
	}
	
	get conflicts() {
		return this.overlaps.length > 0;
	}
	
	get validTimes() {
		return this.state.start && this.state.end && this.state.end > this.state.start;
	}
	
	get daysOk() {
		return this.resources.length > 0;
	}
	
	get timesOk() {
		return this.daysOk && this.days.length > 0;
	}
	
	get rangeOk() {
		return this.timesOk && this.state.times.length > 0;
	}
	
	get validRange() {
		return this.state.range_start !== null && this.state.range_end !== null && this.state.range_end >= this.state.range_start && !this.conflicts;
	}
	
	get configOk() {
		return this.state.configs.length > 0;
	}
	
	render() {
		const {resources, showFull, t} = this.props;
		const {start, end, range_start, range_end, configs, show_config, mode, show_mode, saving} = this.state;
		return (
			<div>
				{/*<pre>{JSON.stringify(this.state.configs, null, 2)}</pre>*/}
					<Table basic={'very'} definition>
						<Table.Body>
							{trueNull(configs.length) && <Table.Row>
								<Table.Cell textAlign={'center'}><Trans defaults="Konfiguration"/></Table.Cell>
								<Table.Cell>
									{showFull ?
										<Segment.Group>
											{configs.map((config, configIndex) =>
												<ConfigSummary
													key={configIndex}
													title={`Konfiguration ${configIndex + 1}`}
													resourceMap={this.resourceMap}
													config={config}
													getDay={this.getDay}
													getTime={this.getTime}
													actionButton={<Button style={{position: 'absolute', top: -2, right: -5}} inverted basic icon onClick={this.removeConfig(configIndex)}><Icon name={'trash'} color={'red'}/></Button>}
													style={{position: 'relative'}}
												/>
											)}
											{this.conflicts && <NegativeSegment>
												<p>Zeitliche Konflikte entdeckt, bitte beseitigen!</p>
											</NegativeSegment>}
										</Segment.Group> :
										<div>
											{configs.map((config, configIndex) =>
												<Button.Group key={configIndex} basic style={{margin: 4}}>
													<Button onClick={this.setConfigView(configIndex)}>Konfiguration {configIndex + 1}</Button>
													<Button icon={'trash'} onClick={this.removeConfig(configIndex)}/>
												</Button.Group>
											)}
											{this.conflicts && <Message negative>
												<p>Zeitliche Konflikte entdeckt, bitte beseitigen!</p>
											</Message>}
										</div>
									}
									{trueNull(!showFull && show_config !== null) && <ConfigSummary title={`Konfiguration ${show_config + 1}`} resourceMap={this.resourceMap} config={configs[show_config]} getDay={this.getDay} getTime={this.getTime}/>}
								</Table.Cell>
							</Table.Row>}
							<Table.Row>
								<Table.Cell textAlign={'center'}><Trans defaults="Ressourcen"/></Table.Cell>
								<Table.Cell textAlign={'center'}>
									<EasyFlex align={this.alignment} wrap>
										{resources.map(resource =>
											<EasyFlex key={`checkbox-${resource.resource_id}`} direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{margin: 8}}>
												<Checkbox id={`checkable-${resource.resource_id}`} checked={this.isResource(resource.resource_id)} onChange={this.setResource(resource.resource_id)}/>
												<label htmlFor={`checkable-${resource.resource_id}`}>{resource.name}</label>
											</EasyFlex>
										)}
									</EasyFlex>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign={'center'}><Trans defaults="Tage"/></Table.Cell>
								<Table.Cell textAlign={'center'}>
									<EasyFlex align={this.alignment} wrap>
										{[0, 1, 2, 3, 4, 5, 6].map(day => {
											return <EasyFlex key={`day-checkbox-${day}`} direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{margin: 12}}>
												<Checkbox disabled={!this.daysOk} id={`day-checkable-${day}`} checked={this.isDay(day)} onChange={this.setDay(day)}/>
												<label htmlFor={`day-checkable-${day}`}>{this.getDay(day)}</label>
											</EasyFlex>
										})}
									</EasyFlex>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign={'center'}><Trans defaults="Zeiten"/></Table.Cell>
								<Table.Cell textAlign={'center'}>
									<EasyFlex align={this.alignment} wrap>
										{this.state.times.map((timeEntry, timeIndex) =>
											<Button.Group style={{margin: '4px 8px'}} basic key={[timeEntry.start, timeEntry.end].map(t => format(t, 'HHmm')).join('_')}>
												<Button className={'not-clickable'}>{this.getTime(timeEntry.start)} - {this.getTime(timeEntry.end)}</Button>
												<Button icon={'trash'} color={'red'} onClick={this.removeTime(timeIndex)}/>
											</Button.Group>
										)}
									</EasyFlex>
									<EasyFlex valign={EasyFlex.valign.BASELINE} align={this.alignment} wrap>
										<TimeInput disabled={!this.timesOk} floatingLabelText={'Von'} time={start} onProvide={this.set('start')} baseDate={end || new Date()} inputProps={{style: {width: 80}}}>
											<TextField/>
										</TimeInput>
										<TimeInput disabled={!this.timesOk} floatingLabelText={'Bis'} time={end} onProvide={this.set('end')} baseDate={start || new Date()} inputProps={{style: {width: 80}}}>
											<TextField/>
										</TimeInput>
										<Button style={{marginLeft: 12}} icon basic disabled={!this.validTimes} onClick={this.addTime}><Icon name={'plus'}/> <Trans defaults={'vormerken'}/></Button>
										<Button disabled={!this.rangeOk} onClick={this.addConfig} basic style={{marginLeft: 12}} icon><Icon name={'plus'}/> <Trans defaults={'Konfiguration vormerken'}/></Button>
									</EasyFlex>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell textAlign={'center'}><Trans defaults="Kalenderspannweite"/></Table.Cell>
								<Table.Cell textAlign={'center'}>
									<EasyFlex valign={EasyFlex.valign.BASELINE} align={this.alignment} wrap>
										<EasyFlex>
											<MaterialMobileAwarePicker
												disabled={!this.configOk}
												floating
												placeholderText={<Required><Trans defaults={'Startdatum'}/></Required>}
												baseDate={range_end || new Date()}
												selected={range_start}
												minDate={new Date()}
												selectsStart
												startDate={range_start || new Date()}
												endDate={range_end}
												onChange={this.set('range_start')}
												materialProps={{style: {width: 120}}}
											/>
											<MaterialMobileAwarePicker
												disabled={!this.configOk}
												floating
												placeholderText={<Required><Trans defaults={'Enddatum'}/></Required>}
												baseDate={range_start || new Date()}
												selected={range_end}
												minDate={new Date()}
												selectsEnd
												startDate={range_start || new Date()}
												endDate={range_end}
												onChange={this.set('range_end')}
												materialProps={{style: {width: 120}}}
											/>
										</EasyFlex>
										<Dropdown
											style={{marginLeft: 12}}
											disabled={!this.validRange}
											placeholder={'Modus'}
											button
											labeled
											icon={<Icon name={'pencil'}/>}
											className={'icon'}
											text={t(`dynamic-schedule.mode.${mode}`)}
										>
											<Dropdown.Menu>
												{Object.entries(DynamicScheduleInsertModes).map(([, iterMode]) =>
													<Dropdown.Item onClick={this.setMode(iterMode)} key={iterMode}><Trans defaults={iterMode} i18nKey={`dynamic-schedule.mode.${iterMode}`}/></Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
										<Button icon={'help'} basic onClick={this.setValue('show_mode', true)}/>
										<Button loading={saving} disabled={!this.validRange || saving} onClick={this.save} positive style={{marginLeft: 12}} icon><Icon name={'plus'}/> <Trans defaults={'Konfiguration speichern'}/></Button>
									</EasyFlex>
								</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				<Modal open={show_mode} onClick={this.setValue('show_mode', false)}>
					<Modal.Header><Trans defaults={'Option für Kalendereintragung'}/></Modal.Header>
					<Modal.Content>
						<DynamicScheduleModeInformation inverted/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setValue('show_mode', false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

DynamicScheduleInsertMask = withScreen()(DynamicScheduleInsertMask);
DynamicScheduleInsertMask = translate()(DynamicScheduleInsertMask);