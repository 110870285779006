import BaseModel from "./BaseModel";
import * as React from "react";
import {moment} from "../Logic/Moment";
import {isArray, isFunction} from "lodash";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {orderAction__collect, orderAction__collectReleases, orderAction__getOne, orderAction__releasableList} from "../actions/orderActions";
import withInit, {withChange} from "../Logic/withInit";
import {isLoading} from "../actions/loaderActions";
import {PROC_ORDER} from "../actions";
import {withRights} from "../Tools";
import {SECTION} from "../Logic/constants";
import {filterData} from "../cointainer/intern/release/ReleaseMethods";
import {Table} from "semantic-ui-react";
import {OrderRangeView, OrderStatusView} from "../components/intern/orders/OrderStatusView";
import {ProviderComponent} from "../components/partials";

const Line = ({title, children}) => <Table.Row><Table.Cell textAlign={"right"}>{title}</Table.Cell><Table.Cell>{children}</Table.Cell></Table.Row>;
Line.propTypes = {
	title: PropTypes.node,
	children: PropTypes.node
};

const PopupInfo = ({order: o, inverted, ...props}) => {
	if (!o) {
		return null;
	}
	let order;
	if (o instanceof Order) {
		order = o;
	} else {
		order = new Order(o);
	}
	
	return (
		<Table celled basic={"very"} collapsing {...props}>
			<Table.Body>
				<Table.Row><Table.Cell colSpan={2} textAlign={"center"}><OrderStatusView backgroundColor={'transparent'} order={order.origin}/></Table.Cell></Table.Row>
				<Table.Row><Table.Cell colSpan={2} textAlign={"center"}><OrderRangeView order={order.origin}/></Table.Cell></Table.Row>
			</Table.Body>
		</Table>
	);
};

const Provider = ({order, children, placeholder}) => order && isFunction(children) ? children(order) : placeholder;
Provider.propTypes = {
	order_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	placeholder: PropTypes.node
};
Provider.defaultProps = {
	placeholder: null
};

const ReleaseProvider = ({release, children, placeholder}) => release && isFunction(children) ? children(release) : placeholder;
ReleaseProvider.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	placeholder: PropTypes.node,
	provideOnly: PropTypes.bool
};
ReleaseProvider.defaultProps = {
	placeholder: null
};

const ReleaseListProvider = ({children, releases, count, data, alt}) => {
	if (alt && !data.length) {
		return alt;
	}
	return isFunction(children) ? children({releases, count, data}) : null;
};
ReleaseListProvider.propTypes = {
	children: PropTypes.func,
	init_data: PropTypes.bool,
	alt: PropTypes.node
};
ReleaseListProvider.defaultProps = {
	count: 0,
	init_data: false,
	data: []
};

const CollectProvider = ({orders, children, placeholder, loading}) => isArray(orders) && isFunction(children) ? children(orders, loading) : placeholder;
CollectProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({order_id: PropTypes.number})])),
	preview: PropTypes.bool,
	placeholder: PropTypes.node,
	onSuccess: PropTypes.func,
	full: PropTypes.bool,
	initOnly: PropTypes.bool,
	dynamic: PropTypes.bool
};
CollectProvider.defaultProps = {
	ids: [],
	placeholder: null,
	preview: false,
	full: false,
	initOnly: false,
	dynamic: true
};

export class Order extends BaseModel {
	_validateModel(model) {
		return ("order_id" in model) && ("created_at" in model) && ("deliver_point" in model);
	}
	
	get id() { return this.__model.order_id; }
	get order_id() { return this.__model.order_id; }
	get order_vehicle_id() { return this.__model.order_vehicle_id; }
	get workers_id() { return this.__model.workers_id; }
	get house_id() { return this.__model.house_id; }
	get consultant_id() { return this.__model.consultant_id; }
	get consultant_duration() { return this.__model.consultant_duration; }
	get deliver_point() { return this.__model.deliver_point; }
	get deliver_moment() { return moment(this.deliver_point); }
	get fetch_point() { return this.__model.fetch_point; }
	get fetch_moment() { return moment(this.fetch_point); }
	get latest_point() { return this.__model.latest_point; }
	get latest_moment() { return moment(this.latest_point); }
	get delivered_at() { return this.__model.delivered_at; }
	get affirmed_at() { return this.__model.affirmed_at; }
	get affirmed_moment() { return this.affirmed_at ? moment(this.affirmed_at) : null; }
	get affirmed_by() { return this.__model.affirmed_by ? Number(this.__model.affirmed_by) : null; }
	get post_processed_at() { return this.__model.post_processed_at; }
	get post_processed_moment() { return this.post_processed_at ? moment(this.post_processed_at) : null; }
	get post_processed_by() { return this.__model.post_processed_by ? Number(this.__model.post_processed_by) : null; }
	get post_process_duration() { return Number(this.__model.post_process_duration); }
	get post_processing() { return Number(this.__model.post_processing); }
	get delivery_service() { return Boolean(this.__model.delivery_service); }
	get waiting() { return Boolean(Number(this.__model.waiting)); }
	get delivered_moment() { return this.delivered_at ? moment(this.delivered_at) : null; }
	get released_at() { return this.__model.released_at; }
	get released_moment() { return this.released_at ? moment(this.released_at) : null; }
	get released_by() { return this.__model.released_by; }
	get progress() { return this.__model.progress; }
	get fetched_at() { return this.__model.fetched_at; }
	get fetched_moment() { return this.fetched_at ? moment(this.fetched_at) : null; }
	get finished_at() { return this.__model.finished_at; }
	get finished_moment() { return this.__model.finished_at ? moment(this.finished_at) : null; }
	get name() { return this.__model.name; }
	get info() { return this.__model.info; }
	get paused_by() { return this.__model.paused_by; }
	get range_start() { return this.__model.range_start; }
	get range_start_moment() { return this.range_start ? moment(this.range_start) : null; }
	get range_end() { return this.__model.range_end; }
	get range_end_moment() { return this.range_end ? moment(this.range_end) : null; }
	get created_at() { return this.__model.created_at; }
	get created_moment() { return moment(this.created_at); }
	get updated_at() { return this.__model.updated_at; }
	get updated_moment() { return this.updated_at ? moment(this.updated_at) : null; }
	get deleted_at() { return this.__model.deleted_at; }
	get deleted_moment() { return this.deleted_at ? moment(this.deleted_at) : null; }
	
	static Provider = connect(
		(state, props) => {
			const order = state.map.orders[props.order_id] || state.appointments.orders[props.order_id] || state.orders.orders[props.order_id];
			return {
				order: order ? new Order(order) : null
			}
		},
		(dispatch, props) => {
			return {
				init: () => {
					dispatch(orderAction__getOne(props.order_id));
				}
			};
		}
	)(withInit(Provider));
	
	static ReleaseProvider = connect(
		(state, props) => {
			const release = state.map.releases[props.id] || null;
			return {
				release
			}
		},
		(dispatch, props) => {
			return {
				init: () => {
					!props.provideOnly && props.id && dispatch(orderAction__collectReleases([props.id]));
				}
			};
		}
	)(withInit(ReleaseProvider));
	
	
	static ConnectedRelease = connect(
		(state, {id}) => ({
			data: state.map.releases[id] || null
		})
	)(ProviderComponent)
	
	static CollectProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.order_id || id)).filter(Boolean);
			let orders = [...ids].map(id => state.map.orders[id] || null);
			if (!orders.length) {
				orders = null;
			} else if (orders.includes(null)) {
				if (props.preview) {
					orders = orders.filter(Boolean);
				} else {
					orders = null
				}
			}
			return {
				orders,
				ids,
				loading: isLoading(state, PROC_ORDER)
			}
		},
		(dispatch, props) => {
			// !props.initOnly && props.orders && props.orders.length && dispatch(orderAction__collect(props.orders, props.full));
			return {
				withChange: (_props) => {
					_props.ids && _props.ids.length && dispatch(orderAction__collect(_props.ids, _props.full));
				}
			};
		}
	)(withChange(CollectProvider,  {access: 'ids', dynamicIndex: 'dynamic'}));
	
	static ReleaseListProvider = withRights(SECTION.RELEASE, connect(
		(state, {rights, allRights}) => {
			const mayService = rights.mayChange;
			const mayStock = allRights[SECTION.STOCK].mayChange;
			let releases = [], count = 0, data = [];
			try {
				let result = filterData(state.orders.release_data, state.map.releases, mayService, mayStock);
				releases = result.release_list;
				count = result.count;
				data = result.data_list
			} catch (e) {
				console.error('release collection', e);
			}
			return {
				releases: releases || [],
				count,
				data
			};
		},
		(dispatch, props) => ({
			init: () => dispatch(orderAction__releasableList(false, props.init_data))
		})
	)(withInit(ReleaseListProvider)));
	
	static PopupInfo = PopupInfo;
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.orders[id];
			data = data ? new Order(data) : null;
			return {data};
		}
	)(ProviderComponent)
}

Order.CollectProvider.propTypes = {
	useModel: PropTypes.bool
};
Order.CollectProvider.defaultProps = {
	useModel: true
};