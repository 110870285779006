import {SOUND_ACTIONS} from "./types";

export const soundAction__init = dispatch => {
	console.error('initiating sound');
	dispatch({type: SOUND_ACTIONS.PLAY, volume: 0});
	console.error('first silent sound played!');
	window.setTimeout(() => {
		dispatch({type: SOUND_ACTIONS.STOP});
		dispatch({type: SOUND_ACTIONS.INITIATED});
		console.error('sound also stopped!');
	}, 100);
};

export const soundAction__initiated = ({type: SOUND_ACTIONS.INITIATED});
export const soundAction__play = {type: SOUND_ACTIONS.PLAY};
export const soundAction__stop = {type: SOUND_ACTIONS.STOP};