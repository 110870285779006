import {CARHOUSE_ACTION} from "../actions/types";
import {updateCache} from "ith-redux-helpers";
import {CACHE_COUNTRIES, CACHE_TIMEZONES} from "../actions";

const initState = {
	list: [],
	own: null,
	timezones: {
		list: []
	},
	countries: {},
	customerone: false,
	cache: {}
};

const carhouseState = (state = initState, {type, houses, carhouse, timezones, house_id, countries, mode, customerone}) => {
	switch (type) {
		// case RESET_ACTION:
		// 	return initState;
		case CARHOUSE_ACTION.LOAD_LIST:
			return {...state, list: houses, ...updateCache('list')};
		case CARHOUSE_ACTION.LOAD_TIMEZONES:
			return {...state, timezones: {...state.timezones, list: timezones, ...updateCache(CACHE_TIMEZONES)}};
		case CARHOUSE_ACTION.CUSTOMERONE:
			return {
				...state,
				customerone
			};
		case CARHOUSE_ACTION.LOAD_HOUSE:
			try {
				const list = [...state.list.filter(house => house.house_id !== carhouse.house_id), carhouse];
				return {...state, list};
			} catch (e) {
				console.warn('error on load house', e);
				return state;
			}
		case CARHOUSE_ACTION.SAVE_TIMEZONE:
			return state;
		case CARHOUSE_ACTION.REMOVE_LOGO:
			return {
				...state,
				list: state.list.map(h => {
					if ( h.house_id === house_id) {
						h = {
							...h,
							logo_id: null,
							logo: null
						};
					}
					return h;
				}),
				own: {
					...state.own,
					logo: null,
					logo_id: null
				}
			};
		case CARHOUSE_ACTION.OWN:
			return {
				...state,
				own: {
					...carhouse,
					logo: carhouse.logo ? {...carhouse.logo} : carhouse.logo
				}
			};
		case CARHOUSE_ACTION.SET_COUNTRIES:
			if (mode === 'merge') {
				return {
					...state,
					countries: {
						...state.countries,
						...countries
					},
					cache: {
						...state.cache,
						...updateCache(CACHE_COUNTRIES)
					}
				};
			}
			return {
				...state,
				countries,
				cache: {
					...state.cache,
					...updateCache(CACHE_COUNTRIES)
				}
			};
		default:
			return state;
	}
};

export default carhouseState;