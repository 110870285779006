import {WORKER_ACTION} from "./types";
import {addLoader, removeLoader, setLoader, unsetLoader} from "./loaderActions";
// import {DELETE, GET, POST, PUT, UPLOAD} from "ith-fetch";
import {DELETE, GET, POST, PUT, UPLOAD} from "../Logic/fetch";
import {shouldUpdate, updateFetch} from "ith-redux-helpers";
import {addError} from "./errorActions";
import {mapOfObject} from "../Logic/extensions";
import {updateUser, userState__setEmail} from "./userActions";
import isFunc from 'lodash/isFunction';
import {
	PROC_WORKER,
	PROC_WORKER_ACTIVITIES,
	PROC_WORKER_DELETE,
	PROC_WORKER_EMAIL,
	PROC_WORKER_FETCH_WORK,
	PROC_WORKER_GROUP,
	PROC_WORKER_UPDATE,
	PROC_WORKER_UPLOAD_AVATAR,
	STORE_SECTION_ACITIVITY
} from "./index";
import pick from 'lodash/pick';
import {push} from "connected-react-router";
import {appointmentState__setSection, appointmentState__updateProgress} from "./appointmentActions";
import {Collector} from "../Logic/helper";
import {mapState__workers_add} from "./mapActions";

const _loaded = workers => ({type: WORKER_ACTION.LOAD_ALL, workers});
const _loadedOne = worker => ({type: WORKER_ACTION.LOAD_ONE, worker});

export const resetWorkers = () => ({type: WORKER_ACTION.RESET});
export const setWorkerFilter = (filter = 'all') => ({type: WORKER_ACTION.FILTER.SET, filter});
export const resetWorkerFilter = () => setWorkerFilter();

export const workerState__removeAvatar = workers_id => ({type: WORKER_ACTION.REMOVE_AVATAR, workers_id});
export const workerState__setEmail = (workers_id, email) => ({type: WORKER_ACTION.UPDATE_EMAIL, workers_id, email});
export const workerState__setDeleted = (workers_id, deleted) => ({type: WORKER_ACTION.REMOVE, workers_id, deleted});
export const workerState__setWork = (work) => ({type: WORKER_ACTION.SET_WORK, work});
export const workerState__setWorkHistory = (work) => ({type: WORKER_ACTION.SET_WORK_HISTORY, work});
export const workerState__setActivities = (activities) => ({type: WORKER_ACTION.SET_ACTIVITIES, activities});
export const workerState__setResourceMap = (resourceMap) => ({type: WORKER_ACTION.SET_RESOURCE_MAP, resourceMap});
export const workerState__setWorking = (working) => ({type: WORKER_ACTION.SET_WORKING, working});
export const workerState__updateWorking = (work) => ({type: WORKER_ACTION.UPDATE_WORKING, work});
export const workerState__removeWorking = (workers_id) => ({type: WORKER_ACTION.REMOVING_WORKING, workers_id});
export const workerState__addIDs = ids => ({type: WORKER_ACTION.ADD_ID, ids});
export const workerState__removeIDs = ids => ({type: WORKER_ACTION.REMOVE_ID, ids});
export const workerState__setConsultantSchedule = (consultant_schedules) => ({type: WORKER_ACTION.SET_CONSULTANT_SCHEDULE, consultant_schedules});
export const workerState__removeConsultantSchedule = (consultant_schedule) => ({type: WORKER_ACTION.REMOVE_CONSULTANT_SCHEDULE, consultant_schedule});

export const workerCall__getActivities = (day = "now", workers_id) => GET(`/workers/work/${day}${workers_id ? '/' + workers_id : ''}`);
export const workerCall__stopWork = (worker) => DELETE(`/workers/work/${worker.workers_id || worker}`);
export const workerCall__collect = worker_ids => POST('/workers/collect', {list: worker_ids});
export const workerCall__getIDs = () => GET('/workers/all-ids');
export const workerCall__getAll = () => GET('/workers/all');
let __consultants = null;
export const workerCall__getConsultants = async(force = false) => {
	if (!force && null !== __consultants) {
		return __consultants;
	}
	return __consultants = await GET('/workers/consultants');
};
/**
 *
 * @param {Object} data
 * @param {int|string} data.workers_id
 * @param {int|string} data.start
 * @param {int|string} data.end
 * @returns {Promise | Promise<unknown>}
 */
export const workerCall__getWorkload = (data) => POST('/workers/workload', data);

let loadWorkersFetch = false;

export const loadWorkers = (force=false, throws = false) =>
	async (dispatch, getState) => {
		try {
			if (loadWorkersFetch || (!force && !shouldUpdate(getState().workers) ) ) {
				return getState().map.workers;
			}
			dispatch(addLoader());
			loadWorkersFetch = true;
			const response = await workerCall__getAll();
			dispatch(_loaded(mapOfObject(response, worker => updateFetch(worker))));
			dispatch(mapState__workers_add(response));
			return response;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(removeLoader());
			loadWorkersFetch = false;
		}
	};


let loadWorkerQueue = {};
export const loadWorker = (id, force=false) =>
	async (dispatch, getState) => {
		if (!loadWorkerQueue[id]) {
			loadWorkerQueue[id] = new Promise(async k => {
				try {
					const test = getState().workers.list[id];
					if ( !force && !shouldUpdate(test)) {
						k(test);
						return test;
					}
					dispatch(addLoader('loadWorker'));
					const worker = await GET('/workers/detail/' + id);
					k(worker);
					return worker;
				} catch (e) {
					dispatch(addError(e));
					return null;
				} finally {
					dispatch(removeLoader('loadWorker'));
					delete loadWorkerQueue[id];
				}
			});
		}
		const worker =  await loadWorkerQueue[id];
		dispatch(_loadedOne(worker));
		dispatch(mapState__workers_add(worker));
		return worker;
	};
export const changeWorkerGroup = (worker, group_id, onSuccess) =>
	async dispatch => {
		const worker_id = worker.workers_id || worker;
		try {
			// dispatch(addLoader('worker::changeGroup'));
			dispatch(setLoader(PROC_WORKER_GROUP));
			const worker = await POST('/workers/group',{worker_id, group_id});
			dispatch(_loadedOne(worker));
			dispatch(mapState__workers_add(worker));
			isFunc(onSuccess) && onSuccess(worker);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_GROUP));
			// dispatch(removeLoader('worker::changeGroup'));
		}
		
	};
export const changeWorkerPermission = (worker, area, permission, onSuccess) =>
	async dispatch => {
		const worker_id = worker.workers_id || worker;
		try {
			// dispatch(addLoader('worker::changePermission'));
			dispatch(setLoader(PROC_WORKER_GROUP));
			const worker = await POST('/workers/permission', {worker_id, area, permission});
			dispatch(_loadedOne(worker));
			dispatch(mapState__workers_add(worker));
			isFunc(onSuccess) && onSuccess(worker);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_GROUP));
			// dispatch(removeLoader('worker::changePermission'));
		}
	};
export const deleteWorker = (worker, afterDispatch=null) =>
	async dispatch => {
		const worker_id = worker.workers_id || worker;
		try {
			dispatch(setLoader(PROC_WORKER_DELETE, worker_id));
			// dispatch(addLoader('worker::delete'));
			const result = await DELETE('/workers/remove/' + worker_id);
			dispatch(workerState__setDeleted(worker_id, result.worker.deleted));
			afterDispatch && afterDispatch(result);
			dispatch(workerState__removeIDs(worker_id));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			// dispatch(removeLoader('worker::delete'));
			dispatch(unsetLoader(PROC_WORKER_DELETE, worker_id));
		}
	};
export const updateWorker = (worker, data, onSuccess) =>
	async (dispatch, getState) => {
		const id = worker.workers_id || worker;
		const updateSelf = getState().user.data.workers_id === id;
		try {
			dispatch(setLoader(PROC_WORKER_UPDATE, id));
			// dispatch(addLoader('worker::update(' + id + ')'));
			const worker = await POST('/workers/update/'+id, data);
			dispatch(_loadedOne(worker));
			dispatch(mapState__workers_add(worker));
			if (updateSelf) {
				const update = pick(worker, ['first_name', 'name', 'gender']);
				dispatch(updateUser(update));
			}
			isFunc(onSuccess) && onSuccess(worker);
			// dispatch(push(`/workers/detail/${worker.workers_id}`))
		} catch (e) {
			dispatch(addError(e));
		} finally {
			// dispatch(removeLoader('worker::update(' + id + ')'));
			dispatch(unsetLoader(PROC_WORKER_UPDATE, id));
		}
	};
export const updateEmailOfWorker = (worker, email, onSuccess) =>
	async (dispatch, getState) => {
		const workers_id = worker.workers_id || worker;
		const updateSelf = getState().user.data.workers_id === workers_id;
		try {
			dispatch(setLoader(PROC_WORKER_EMAIL, workers_id));
			const result = await POST('/workers/email', {workers_id, email});
			dispatch(workerState__setEmail(result.workers_id, result.email));
			"worker" in result && dispatch(mapState__workers_add(result.worker));
			updateSelf && dispatch(userState__setEmail(result.email));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_EMAIL, workers_id));
		}
	};
export const createWorker = (data, onSuccess) =>
	async dispatch => {
		try {
			// dispatch(addLoader('worker:create'));
			dispatch(setLoader(PROC_WORKER));
			const worker = await PUT('/workers', data);
			dispatch(_loadedOne(worker));
			dispatch(mapState__workers_add(worker));
			dispatch(workerState__addIDs(worker));
			isFunc(onSuccess) && onSuccess(worker);
			// dispatch(push(`/workers/detail/${worker.workers_id}`));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			// dispatch(removeLoader('worker:create'));
			dispatch(unsetLoader(PROC_WORKER));
		}
	};

export const uploadAvatarOfWorker = (worker, image, onSuccess) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		const data = new FormData();
		data.append('file', image);
		try {
			dispatch(setLoader(PROC_WORKER_UPLOAD_AVATAR));
			const result = await UPLOAD('/image/worker-avatar/' + workers_id, data);
			dispatch(_loadedOne(result.worker));
			dispatch(mapState__workers_add(result.worker));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_UPLOAD_AVATAR));
		}
	};

export const removeAvatarFromWorker = (worker, onSuccess) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		try {
			dispatch(setLoader(PROC_WORKER_UPLOAD_AVATAR));
			const result = await DELETE('/image/worker-avatar/' + workers_id);
			dispatch(workerState__removeAvatar(result.workers_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_UPLOAD_AVATAR));
		}
	};

export const navigateToProfile = (worker = null) =>
	async (dispatch, getState) => {
		let workers_id;
		if ( worker ) {
			workers_id = worker.workers_id || worker;
		} else {
			workers_id = getState().user.data.workers_id;
		}
		if ( !workers_id ) throw new Error('errors.params-profile');
		dispatch(push(`/workers/detail/${workers_id}`));
	};

export const workerAction__getWork = (order, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_WORKER_FETCH_WORK, order_id));
			const result = await GET(`/order/work/${order_id}`);
			dispatch(workerState__setWork(result.work));
			dispatch(workerState__setWorkHistory(result.work_history));
			dispatch(appointmentState__updateProgress(order_id, result.number[order_id]));
			isFunc(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_WORKER_FETCH_WORK, order_id));
		}
	};

export const workerAction__getActivities = (day, workers_id, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_WORKER_ACTIVITIES));
			const result = await workerCall__getActivities(day, workers_id);
			dispatch(workerState__setActivities(result.activities));
			dispatch(appointmentState__setSection(STORE_SECTION_ACITIVITY, result.order_data));
			dispatch(workerState__setResourceMap(result.resourceMap));
			dispatch(workerState__setWorking(result.active));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_ACTIVITIES));
		}
	};

export const workerAction__stopWorkOf = (worker, onSuccess) =>
	async dispatch => {
		const id = worker.workers_id || worker;
		try {
			dispatch(setLoader(PROC_WORKER_ACTIVITIES, id));
			const result = await workerCall__stopWork(id);
			dispatch(workerState__setWorking(result.active));
			isFunc(onSuccess) && onSuccess(result.active);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_WORKER_ACTIVITIES, id));
		}
	};

export const workerAction__getList = (force = false) =>
	async (dispatch, getState) => {
		let ids = getState().workers.ids;
		if (!force && ids.length) {
			return ids;
		}
		try {
			dispatch(setLoader(PROC_WORKER));
			ids = await workerCall__getIDs();
			ids = ids.list;
			dispatch(workerState__addIDs(ids));
			return ids;
		} catch (e) {
			dispatch(addError(e));
			return [];
		} finally {
			dispatch(unsetLoader(PROC_WORKER));
		}
	};

let __collect = {};
export const workerAction__collect = workers =>
	Collector._(__collect, workers, 'workers_id')
		.withLoader([PROC_WORKER])
		.withStateProvider('map.workers')
		.withApiProvider(list => workerCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__workers_add(result)))
		.execute();