import * as React from 'react';
import {StatyComponent} from "../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import ActionHeader, {ActionHeaderGroup} from "../../partials/ActionHeader";
import {Button, Confirm, Segment, Table} from "semantic-ui-react";
import {Trans, translate} from "react-i18next";
import {Order} from "../../../models";
import {OrderQuickClient, OrderQuickInfo, OrderQuickLoanCar, OrderQuickService, OrderQuickVehicle} from "../../../cointainer/intern/orders/quick";
import {orderAction__abortProcessing, orderAction__completeProcessing, orderCall__abortProcessing, orderCall__completeProcessing} from "../../../actions/orderActions";
import {connect} from "react-redux";
import {dispatchSnack} from "../../../actions/snackbarActions";
import cn from 'classnames';

export class PostProcessActiveView extends StatyComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		onAbort: PropTypes.func,
		onComplete: PropTypes.func,
		onNotification: PropTypes.func
	}
	static defaultProps = {
		onAbort: orderCall__abortProcessing,
		onComplete: orderCall__completeProcessing,
		onNotification: dispatchSnack
	};
	
	state = {
		aborting: false,
		finishing: false,
		confirm_finish: false
	};
	
	confirmFinish = (confirm_finish) => () => this.setState({confirm_finish});
	
	abort = async () => {
		const {order, onAbort, onNotification, t} = this.props;
		try {
			this.setState({aborting: true});
			await onAbort(order.order_id);
			onNotification(t('...', 'Nachbearbeitung wurde abgebrochen'), 'black');
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({aborting: false});
		}
	};
	
	complete = async() => {
		const {order, onComplete, onNotification, t} = this.props;
		try {
			this.setState({finishing: true, confirm_finish: false});
			await onComplete(order.order_id);
			onNotification(t('...', 'Nachbearbeitung erfolgreich abgeschlossen'));
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({finishing: false});
		}
	};
	
	render() {
		const {aborting, finishing, confirm_finish} = this.state;
		const {t} = this.props;
		return (
			<div>
				<ActionHeader alignment={'flex-end'}>
					<ActionHeaderGroup>
						<Button.Group>
							<Button loading={aborting} className={cn({'not-clickable': aborting || finishing})} onClick={this.abort}><Trans defaults={"Vorgang abbrechen"}/></Button>
							<Button.Or/>
							<Button loading={finishing} className={cn({'not-clickable': aborting || finishing})} positive onClick={this.confirmFinish(true)}><Trans defaults={"Nachbearbeitung abschließen"}/></Button>
						</Button.Group>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<Order.Connected id={this.props.order.order_id}>{order => {
						return <div>
							<OrderQuickInfo order_id={order.order_id} readOnly/>
							<Segment basic><h3><Trans defaults={"Service"}/></h3></Segment>
							<OrderQuickService order_id={order.order_id} readOnly/>
							<Table padded basic={"very"} striped celled style={{margin: "30px 15px", width: "calc(100% - 30px)"}}>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell><h3 style={{marginTop: 0}}><Trans defaults={"Fahrzeug"}/></h3></Table.HeaderCell>
										<Table.HeaderCell>&nbsp;</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<OrderQuickVehicle order_id={order.order_id} onlyBody/>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell><h3 style={{marginTop: 40}}><Trans defaults={"Kunde"}/></h3></Table.HeaderCell>
										<Table.HeaderCell>&nbsp;</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<OrderQuickClient order_id={order.order_id} onlyBody/>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell><h3 style={{marginTop: 40}}><Trans defaults={"Leihfahrzeug"}/></h3></Table.HeaderCell>
										<Table.HeaderCell>&nbsp;</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<OrderQuickLoanCar order_id={order.order_id} readOnly onlyBody/>
							</Table>
							{/*<Segment basic><h3><Trans defaults={"Fahrzeug"}/></h3></Segment>*/}
							{/*<OrderQuickVehicle order_id={order.order_id}/>*/}
							{/*<Segment basic><h3><Trans defaults={"Kunde"}/></h3></Segment>*/}
							{/*<OrderQuickClient order_id={order.order_id}/>*/}
							{/*<Segment basic><h3><Trans defaults={"Leihfahrzeug"}/></h3></Segment>*/}
							{/*<OrderQuickLoanCar order_id={order.order_id} readOnly/>*/}
						</div>
					}}</Order.Connected>
				</div>
				<Confirm
					size={'mini'}
					content={t('...', "Bitte bestätigen Sie den Abschluss der Nachbearbeitung")}
					confirmButton={t('...', "Bestätigen")}
					cancelButton={t('...', "Abbrechen")}
					onCancel={this.confirmFinish(false)}
					onConfirm={this.complete}
					open={confirm_finish}
				/>
			</div>
		);
	}
}

PostProcessActiveView = connect(
	null,
	dispatch => ({
		onAbort: (orderId) => dispatch(orderAction__abortProcessing(orderId, true)),
		onComplete: (orderId) => dispatch(orderAction__completeProcessing(orderId, true))
	})
)(PostProcessActiveView);
PostProcessActiveView = translate()(PostProcessActiveView);