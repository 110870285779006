import * as React from 'react';
import PropTypes from 'prop-types';
import {Checkbox as SemanticCheckbox, Grid, Label, Segment} from "semantic-ui-react";
import {EasyFlex} from "../../partials/ActionHeader";
import './styles/OrderOptionsView.css';
import cn from 'classnames';


const Column = ({active, setActive, style, label, id, disabled, ...props}) => {
	id = id + '-' + Math.random();
	return (
		<Grid.Column>
			<Segment color={active ? 'blue' : null} style={{...style}} {...props}>
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
					<label style={{marginRight: 5}} htmlFor={id}><Label color={active ? 'blue' : null}>{label}</Label></label>
					<SemanticCheckbox
						toggle
						disabled={disabled}
						id={id}
						checked={active}
						onChange={(_, {checked}) => setActive(checked)}
					/>
				</EasyFlex>
			</Segment>
		</Grid.Column>
	)
};

const OrderOptionsView = ({className, hasLoanCarModule, loanCar, waiting, setWaiting, directService, deliveryService, setLoanCar, setDirectService, setDeliveryService, wheelService, setWheelService, hasWheelModule, segmentProps, style, ...props}) => (
	<Grid className={cn('order-options-view', className)} columns={hasLoanCarModule ? 2 : 2} style={{marginTop: 10, marginBottom: 10, ...style}} stackable stretched relaxed={false} {...props}>
		<Grid.Row>
			<Column disabled={!hasLoanCarModule} active={loanCar} id={'loan-car-selector'} setActive={setLoanCar} label={'Leihwagen'} {...segmentProps}/>
			<Column label={'Direktannahme'} active={directService} setActive={setDirectService} id={'direct-service-selector'} {...segmentProps}/>
		</Grid.Row>
		<Grid.Row>
			<Column label={'Bring- & Abholservice'} active={deliveryService} setActive={setDeliveryService} id={'delivery-service-selector'} {...segmentProps}/>
			<Column label={'Warteauftrag'} active={waiting} setActive={setWaiting} id={'waiting-selector'} {...segmentProps}/>
		</Grid.Row>
		{hasWheelModule && <Grid.Row>
			<Column label={'Reifenauslagerung'} active={wheelService} setActive={setWheelService} id={'wheel-service-selector'} {...segmentProps}/>
		</Grid.Row>}
	
	</Grid>
);

OrderOptionsView.propTypes = {
	hasLoanCarModule: PropTypes.bool.isRequired,
	hasWheelModule: PropTypes.bool.isRequired,
	loanCar: PropTypes.bool.isRequired,
	directService: PropTypes.bool.isRequired,
	deliveryService: PropTypes.bool.isRequired,
	waiting: PropTypes.bool.isRequired,
	setLoanCar: PropTypes.func.isRequired,
	setDirectService: PropTypes.func.isRequired,
	setDeliveryService: PropTypes.func.isRequired,
	setWaiting: PropTypes.func.isRequired,
	wheelService: PropTypes.bool,
	setWheelService: PropTypes.func.isRequired
};

export {OrderOptionsView};