import React from 'react' ;
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import ActionHeader, {
	ActionBackButton, ActionHeaderGroup, ActionHeading
} from "../../partials/ActionHeader";
import {ACCESS_PROP_DEFAULTS, ACCESS_PROP_DEFINTIONS, SECTION} from "../../../Logic/constants";
import StackGrid from 'react-stack-grid';
import {withSize} from 'react-sizeme';
import {WorkersDetailBasicCreateController} from "./WorkersDetailBasicCreate";
import {Icon, Responsive, Segment} from "semantic-ui-react";
import WorkersAvatarController from "../../../cointainer/intern/workers/WorkersAvatarController";
import {WorkersDetailBasicEditController} from "./WorkersDetailBasicEdit";
import {WorkersDetailChangePasswordController} from "./WorkersDetailChangePassword";
import {WorkersDetailChangeEmailController} from "./WorkersDetailChangeEmail";
import {WorkersDetailRightsController} from './WorkersDetailRights';
import ConfirmDialog from "../../../Logic/TriggerDialog";
import {FlatButton} from "material-ui";
import User from '../../../models/User';
import {MiniMenu, ResourceCalendarLink, WorkersCalendarLink} from "../../../Logic/MiniMenu";
import {deepMemoize} from "../../../Logic/extensions";

const NoRemainingWorkers = ({t}) => (
	<div>{t('worker.detail.create.error-message')}</div>
);

class WorkersDetailView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			worker: props.worker
		};
		this.worker = null;
	}
	
	grid = null;
	
	handleDelete = () => {
		this.props.onDelete && this.props.worker && this.props.onDelete(this.props.worker);
	};
	
	updateLayout = () => {
		this.grid && this.grid.updateLayout();
	};
	
	updateWorker = deepMemoize(worker => {
		this.setState({worker})
	})
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (! this.worker && this.props.worker ) {
			this.worker = {...this.props.worker};
		}
		if ( !prevProps.createMode ) {
			this.updateWorker(this.props.worker)
			// this.setState({worker: this.props.worker});
		}
	}
	
	setUpdateLayout = ref => {
		this.grid = ref;
	};
	
	handleBack = () => {
		const to = this.props.user.hasAccessTo(SECTION.WORKERS, User.RIGHT.READ) ? '/workers' : '/';
		this.props.onBack && this.props.onBack(to);
	};
	
	
	render() {
		const {
			t,
			createMode,
			mayGrant,
			mayDelete,
			mayCreate,
			mayChangeSelf,
			mayChange,
			isSupport,
			size,
			user,
			carhouse,
			right_groups,
			right_areas,
			right_relations,
			workers,
			isDeleting,
			house
		} = this.props;
		const {worker} = this.state;
		
		if ( !worker ) {
			return null;
		}
		
		if ( createMode && !mayCreate ) {
			return (
				<div>{t('no-access')}</div>
			);
		}
		
		const groupKeys = Object.keys(right_groups).filter(val => val !== 'support');
		const workersCount = Object.values(workers).filter(w => w.deleted === false).length;
		const maxWorkers = carhouse.max_workers || 0;
		const remainingWorkers = Math.max(0, maxWorkers - workersCount);
		const isSelf = user.isSelf(worker);
		const {first_name, name} = (worker ? worker : {});
		
		return (
			<Segment basic>
				<ActionHeader alignment={'space-between'} wrap={'wrap'}>
					<ActionHeaderGroup>
						<ActionBackButton onBack={this.handleBack}/>
						<ActionHeading>{createMode ? t('worker.detail.create.title') : t('worker.detail.title', {first_name, name})}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup alignment={'flex-end'}>
						{createMode &&
							<ActionHeaderGroup alignment={'center'}>
								{t('worker.detail.create.number-info', {current: workersCount, max: maxWorkers, count: remainingWorkers})}
							</ActionHeaderGroup>
						}
						{!createMode && mayDelete &&
						<ConfirmDialog loading={isDeleting} onConfirm={this.handleDelete} trigger={<FlatButton disabled={isSelf} style={{paddingLeft: 10, paddingRight: 10}} secondary ><Responsive as={'span'} minWidth={700}>{t('worker.detail.labels.delete-profile')} </Responsive><Icon name={'trash'}/> </FlatButton>}/>
						}
						{!createMode &&
							<MiniMenu>
								<WorkersCalendarLink workers_id={worker.workers_id}/>
								<ResourceCalendarLink workers_id={worker.workers_id}/>
							</MiniMenu>
						}
					</ActionHeaderGroup>
				</ActionHeader>
				<StackGrid
					columnWidth={size.width < 700 ? '100%' : 500}
					monitorImagesLoaded
					duration={0}
					gutterWidth={10}
					gutterHeight={10}
					style={{marginTop: 40}}
					gridRef={this.setUpdateLayout}
				>
					{createMode ? (remainingWorkers === 0  ? <NoRemainingWorkers t={t}/> : <WorkersDetailBasicCreateController house_id={user.getData().house_id}/>) : <WorkersDetailBasicEditController self={isSelf} worker={worker} mayChange={!isSupport && Boolean(mayChange || (isSelf && house.worker_may_change_profile))}/>}
					{!createMode && !isSupport && worker && <WorkersDetailRightsController areas={right_areas} groups={groupKeys} worker={worker} self={isSelf} relation={right_relations} mayGrant={mayGrant}/>}
					{!createMode && !isSupport && worker && <WorkersAvatarController onChange={this.updateLayout} onImageLoad={this.updateLayout} worker={worker} mayWrite={mayChange || (mayChangeSelf && house.worker_may_change_avatar)} mayDelete={mayDelete || (mayChangeSelf && house.worker_may_change_avatar)}/>}
					{!createMode && !isSupport && worker && user.isSelf(worker.workers_id) && <WorkersDetailChangePasswordController onUpdateLayout={this.updateLayout}/>}
					{!createMode && !isSupport && worker && mayChange && <WorkersDetailChangeEmailController email={worker.email} workers_id={worker.workers_id} onUpdateLayout={this.updateLayout}/>}
				</StackGrid>
			</Segment>
		);
	}
}
WorkersDetailView.propTypes = {
	worker: PropTypes.shape({
		workers_id: PropTypes.number.isRequired,
		first_name: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		gender: PropTypes.string.isRequired,
		house_id: PropTypes.number.isRequired,
		right_group_id: PropTypes.string.isRequired
	}),
	house: PropTypes.shape({
		worker_may_change_profile: PropTypes.bool.isRequired,
		worker_may_change_avatar: PropTypes.bool.isRequired
	}).isRequired,
	right_areas: PropTypes.arrayOf(PropTypes.string),
	right_groups: PropTypes.object,
	right_relations: PropTypes.object,
	createMode: PropTypes.bool,
	onBack: PropTypes.func,
	mayChangeSelf: PropTypes.bool,
	isSupport: PropTypes.bool,
	// carhouse: PropTypes.shape({
	// 	// max_workers: PropTypes.number.isRequired
	// }),
	onDelete: PropTypes.func.isRequired,
	...ACCESS_PROP_DEFINTIONS
};

WorkersDetailView.defaultProps = {
	onDelete: () => alert('onDelete() is not implemented yet'),
	createMode: false,
	onBack: null,
	onDialog: null,
	mayChangeSelf: false,
	right_areas: [],
	right_relations: null,
	right_groups: {},
	isSupport: false,
	workers: {},
	carhouse: {},
	...ACCESS_PROP_DEFAULTS
};

WorkersDetailView = translate()(WorkersDetailView);

export default withSize()(WorkersDetailView);

