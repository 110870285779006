import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Message, Modal, Popup, Segment} from "semantic-ui-react";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {Trans, translate} from "react-i18next";
import {ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import {COLORS, SECTION} from "../../../Logic/constants";
import {isOnline} from "../../../actions/userActions";
import {withRights} from "../../../Tools";
import {loanCarAction__get, loanCarAction__putSchedule, loanCarAction__removeSchedule} from "../../../actions/loanCarActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {isFunction} from "lodash";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import {deepMemoize as memoizeOne, Required, trueNull} from "../../../Logic/extensions";
import Client from "../../../models/Client";
import {TextField} from "material-ui";
import moment from "../../../Logic/Moment";
import {PROC_LOAN_CARS_REMOVE, PROC_LOAN_CARS_SEND} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {MaterialCompoundDateInput} from "../../../Tools/DatePicker";
import {clientCall__collectOne} from "../../../actions/clientActions";
import {vehicleCall__get} from "../../../actions/vehicleActions";
import {orderCall__get} from "../../../actions/orderActions";
import hash from 'object-hash';
import OrderQuickView from "../orders/OrderQuickView";
import {StatyComponent} from "../../../Tools/ReactExtension";
import {Vehicle} from "../../../models";
import {RegistrationMark} from "../../../components/intern/vehicles";
import LoanCarSearchController from "./LoanCarSearchController";
import ClientPopup from "../../../components/intern/clients/ClientPopup";
import {ClientSearchModal} from "../../../components/intern/clients/search/ClientSearch";
import {withModule} from "../../../Tools/RightsProvider";


///////////////////////////////////////////////
///     Default schedule object
////////////////////
const DEFAULT_CAR = {
	loan_car_schedule_id: null,
	vehicle_id: null,
	house_id: null,
	client_id: null,
	order_id: null,
	info: "",
	start_point: null,
	end_point: null
};

///////////////////////////////////////////////
///     Client Name
////////////////////
let ClientName = ({client, client_id, t, tReady, i18n, ...props}) => {
	if ( !client_id ) {
		return <span {...props}>Kunde wählen</span>
	}
	if (!client) {
		return <span {...props}>Lade Kunde</span>
	}
	client =  Client._(client);
	return (
		<span {...props}>{client.getName(t)}</span>
	);
};
ClientName.propTypes = {
	client: PropTypes.object
};
ClientName = translate()(ClientName);

///////////////////////////////////////////////
///     Delete Button
////////////////////
class DeleteButton extends React.PureComponent {
	static propTypes = {
		loading: PropTypes.bool,
		onRemove: PropTypes.func.isRequired,
		resetOnCall: PropTypes.bool
	};
	state = {
		confirm: false
	};
	reset = () => this.setState({confirm: false});
	onRemove = () => {
		this.props.onRemove(this.reset);
		this.props.resetOnCall && this.setState({confirm: false});
	};
	render() {
		const {t, tReady, i18n, onRemove, resetOnCall, ...props} = this.props;
		const {confirm} = this.state;
		if ( confirm ) {
			return (
				<Button.Group>
					<Button basic color={'grey'} onClick={this.reset}>{t("actions.cancel")}</Button>
					<Button negative {...props} onClick={this.onRemove}>{t("actions.really-remove")}</Button>
				</Button.Group>
			);
		}
		return <Button basic negative {...props} onClick={() => this.setState({confirm: true})}>{t("actions.delete")}</Button>;
	}
}

DeleteButton = translate()(DeleteButton);

// const MaterialPicker = withMaterial(Picker);

///////////////////////////////////////////////
///     Loan Car Schedule Mask
////////////////////
class LoanCarScheduleMask extends StatyComponent {
	static propTypes = {
		loanCarSchedule: PropTypes.object,
		onAfterSave: PropTypes.func,
		onAfterDelete: PropTypes.func,
		onFetchClient: PropTypes.func,
		onFetchVehicle: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onFetchOrder: PropTypes.func
	};
	static defaultProps = {
		onAfterSave: () => {},
		onAfterDelete: () => {},
		onFetchClient: (client_id) => clientCall__collectOne(client_id),
		onFetchVehicle: (vehicle_id) => vehicleCall__get(vehicle_id),
		onFetchOrder: (order_id) => orderCall__get(order_id, true),
		onNotificationBad: (message) => console.error(message)
	};
	state = {
		origin: DEFAULT_CAR,
		car: DEFAULT_CAR,
		vehicle: null,
		client: null,
		order: null,
		showLoanCarSearch: false,
		showClientSearch: false,
		showOrder: false,
		fetchingClient: false,
		fetchingVehicle: false,
		fetchingOrder: false
	};
	
	
	constructor(props) {
		super(props);
		const nextState = this.setByProps(props.loanCarSchedule);
		if (nextState) {
			this.state = {...this.state, ...nextState};
		}
	}
	
	
	
	fetchClient = async (client_id) => {
		client_id = client_id || this.state.car.client_id;
		const {onFetchClient, onNotificationBad} = this.props;
		try {
			this.setState({fetchingClient: true});
			const client = await onFetchClient(client_id);
			this.setState({client});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({fetchingClient: false});
		}
	}
	
	fetchVehicle = async (vehicle_id) => {
		vehicle_id = vehicle_id || this.state.car.client_id;
		const {onFetchVehicle, onNotificationBad} = this.props;
		try {
			this.setState({fetchingVehicle: true});
			const vehicle = await onFetchVehicle(vehicle_id);
			this.setState({vehicle});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({fetchingVehicle: false});
		}
	}
	
	fetchOrder = async (order_id) => {
		order_id = order_id || this.state.car.order_id;
		if (!order_id) {
			console.warn('No order id given for order call');
			return;
		}
		const {onNotificationBad, onFetchOrder} = this.props;
		try {
			this.setState({fetchingOrder: true});
			const order = await onFetchOrder(order_id);
			this.setState({order});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.message);
		} finally {
			this.setState({fetchingOrder: false});
		}
	};
	
	getStartDate = memoizeOne(
		date => date ? new Date(date) : null
	);
	
	getEndDate = memoizeOne(
		date => date ? new Date(date) : null
	);
	
	get start_date() {
		return this.getStartDate(this.state.car.start_point);
	}
	
	get end_date() {
		return this.getEndDate(this.state.car.end_point);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (hash(prevProps.loanCarSchedule || {}) !== hash(this.props.loanCarSchedule || {})) {
			const nextState = this.setByProps(this.props.loanCarSchedule);
			nextState && this.setState(nextState);
		}
	}
	
	isChanged = memoizeOne(
		(origin, current) => hash(origin) !== hash(current)
	);
	
	get is_changed() {
		return this.isChanged(this.state.origin, this.state.car);
	}
	
	setByProps = schedule => {
		if ( schedule ) {
			const {car: prev} = this.state;
			let {client: nextClient, vehicle: nextVehicle, order: nextOrder} = this.state;
			let start_date = moment(schedule.start_point).toDate();
			let end_date = moment(schedule.end_point).toDate();
			
			if (schedule.client_id) {
				if (!prev || prev.client_id !== schedule.client_id) {
					if (!schedule.client_id) {
						nextClient = null;
					} else {
						this.fetchClient(schedule.client_id);
					}
				}
			}
			if (schedule.vehicle_id) {
				if (!prev || prev.vehicle_id !== schedule.vehicle_id) {
					if (!schedule.vehicle_id) {
						nextVehicle = null;
					} else {
						this.fetchVehicle(schedule.vehicle_id);
					}
				}
			}
			
			if (schedule.order_id) {
				if (!prev || prev.order_id !== schedule.order_id) {
					if (!schedule.order_id) {
						nextOrder = null;
					} else {
						this.fetchOrder(schedule.order_id);
					}
				}
			}
		
			this.setState({car: schedule, origin: schedule, start_date, end_date, client: nextClient, vehicle: nextVehicle, order: nextOrder});
		}
		return null;
	};
	
	showLoanCarSearch = showLoanCarSearch => () => this.setState({showLoanCarSearch});
	showClientSearch = showClientSearch => () => this.setState({showClientSearch});
	updateSchedule = (data = {}, base = {}, callback) => this.setState(state => ({
		...state,
		car: {
			...state.car,
			...data
		},
		...base
	}), callback)
	setStartDate = (_, start_point) => this.updateSchedule({start_point, vehicle_id: null}, {vehicle: null});
	setEndDate = (_, end_point) => this.updateSchedule({end_point, vehicle_id: null}, {vehicle: null});
	reset = () => this.setState(state => ({car: state.origin}), () => {
		const {car, vehicle, client, order} = this.state;
		if (car.vehicle_id && (!vehicle || car.vehicle_id !== vehicle.vehicle_id)) {
			this.fetchVehicle(car.vehicle_id);
		}
		if (car.client_id && (!client || car.client_id !== client.client_id)) {
			this.fetchClient(car.client_id);
		}
		if (car.order_id && (!order || car.order_id !== order.order_id)) {
			this.fetchOrder(car.order_id);
		}
	});
	setInfo = (_, info) => this.updateSchedule( {info});
	setLoanCar = vehicle => {
		this.setState(state => ({
			...state,
			vehicle,
			car: {
				...state.car,
				vehicle_id: vehicle ? vehicle.vehicle_id : null
			}
		}))
	};
	setClient = client => {
		console.debug('setting client', client)
		this.setState(state => ({
			...state,
			client,
			car: {
				...state.car,
				client_id: client ? client.client_id : null
			}
		}));
	};
	
	usnetClient = () => {
		this.setState(state => ({
			...state,
			car: {
				...state.car,
				client_id: null
			}
		}))
	}
	
	
	get valid() {
		const {
			car: {vehicle_id, client_id, start_point, end_point},
			// order
		} = this.state;
		// if (order && (new Date(start_point) > new Date(order.deliver_point) || new Date(end_point) < new Date(order.fetch_point))) {
		// 	return false;
		// }
		return Boolean(vehicle_id && client_id && start_point && end_point && new Date(start_point) < new Date(end_point));
	}
	
	getOrderWarning = memoizeOne(
		(order, startPoint, endPoint)  => (order && (new Date(startPoint) > new Date(order.deliver_point) || new Date(endPoint) < new Date(order.fetch_point))) ? 'Zeiten überschneiden sich mit dem zeitlichen Rahmen des Auftrages' : null
	);
	
	get orderWarning() {
		return this.getOrderWarning(this.state.order, this.state.car.start_point, this.state.car.end_point);
	}
	
	getVehicle = memoizeOne(
		vehicle => vehicle ? new Vehicle(vehicle) : null
	)
	
	get vehicle() {
		return this.getVehicle(this.state.vehicle);
	}
	
	get maxStartDate() {
		// if (this.state.order) {
		// 	return new Date(this.state.order.deliver_point);
		// }
		return this.end_date;
	}
	
	get minEndDate() {
		// if (this.state.order) {
		// 	return new Date(this.state.order.fetch_point);
		// }
		return this.start_date;
	}
	
	errorMessage = memoizeOne(
		(start, end, order) => {
			// if (order) {
			// 	if ( moment(order.deliver_point) < moment(start)) {
			// 		return this.props.t('Startzeit muss vor der Auftragsannahme sein');
			// 	}
			// 	if (moment(order.fetch_point) > moment(end)) {
			// 		return this.props.t('Endzeit muss nach der Auftragsabgabe sein');
			// 	}
			// }
			return moment(start) >= moment(end) ? this.props.t("Endzeit ist vor der Startzeit!") : null
		}
	);
	
	get error_message() {
		return this.errorMessage(this.state.car.start_point, this.state.car.end_point, this.state.order);
	}
	
	handleRemove = (reset) => {
		this.props.onRemove(this.state.car, () => {
			isFunction(reset) && reset();
			this.props.onAfterDelete();
			this.setState({
				car: DEFAULT_CAR,
				loan_car: null,
				client: null,
				start_date: null,
				end_date: null,
				start_time : "",
				end_time: "",
				showLoanCarSearch: false,
				showClientSearch: false
			});
		});
	};
	
	handleCreate = () => {
		this.props.onCreate(this.state.car, result => {
			this.props.onAfterSave(result);
			this.setByProps(result.schedule);
		});
	};
	
	render() {
		const {
			saving,
			removing,
			rights,
			allRights,
			modules
		} = this.props;
		const {
			showLoanCarSearch,
			showClientSearch,
			showOrder,
			client, vehicle,
			car: {loan_car_schedule_id: changeMode, info, order_id, vehicle_id, client_id},
			fetchingClient, fetchingVehicle
		} = this.state;
		const mayDelete = allRights[SECTION.MASTER_DATA].mayDelete || rights.mayDelete;
		const mayChange = allRights[SECTION.MASTER_DATA].mayChange || rights.mayChange;
		// const state = omit(this.state, ['car', 'origin']);
		return (
			<Segment basic>
				
				<ActionHeader alignment={"space-between"}>
					
					<ActionHeaderGroup>
						<ActionHeading>{changeMode ? "Leihfahrzeugtermin ändern" : "Leihfahrzeugtermin vergeben"}</ActionHeading>
					</ActionHeaderGroup>
					
					<ActionHeaderGroup>
						{this.is_changed && <Popup content={'Rückgängig machen'} trigger={<Button icon={'history'} basic onClick={this.reset}/>}/>}
					</ActionHeaderGroup>
					
				</ActionHeader>
				{!modules.loancar &&
					<Message warning>
						<p><strong><Trans defaults={'Leihwagenmodul is inaktiv!'}/></strong></p>
					</Message>
				}
				
				<EasyFlex align={EasyFlex.align.CENTER} wrap>
					<MaterialCompoundDateInput
						date={this.start_date}
						selectsStart
						required
						dateLabel={<Required>Startzeit</Required>}
						startDate={this.start_date}
						endDate={this.end_date}
						onChange={this.setStartDate.bind(null, null)}
						maxDate={this.maxStartDate}
						timePlaceholder={'__:__'}
						timeLabel={<span>&nbsp;</span>}
					/>
					<MaterialCompoundDateInput
						date={this.end_date}
						selectsEnd
						required
						minDate={this.minEndDate}
						startDate={this.start_date}
						endDate={this.end_date}
						onChange={this.setEndDate.bind(null, null)}
						dateLabel={<Required>Endzeit</Required>}
						timePlaceholder={'__:__'}
						timeLabel={<span>&nbsp;</span>}
					/>
				</EasyFlex>
				
				{this.orderWarning &&
				<Message warning><p>{this.orderWarning}</p></Message>
				}
				
				{this.error_message && <Message negative>
					<p>{this.error_message}</p>
				</Message>}
				{/*<pre>{JSON.stringify(this.state, null, 2)}</pre>*/}
				
				{trueNull(order_id) &&
					<Flex align={"space-between"} valign={"center"} vmargin={10} style={{background: COLORS.SEMANTIC_ORANGE, borderRadius: ".28571429rem"}}>
						<div style={{marginLeft: 10, color: 'white', fontWeight: 'bold'}}><Icon name={'exclamation triangle'}/> Leihwagentermin ist einem Auftrag zugeteilt</div>
						<div>
							{/*<RaisedButton secondary onClick={this.showLoanCarSearch(true)}>
								auswählen
							</RaisedButton>*/}
							<Button.Group>
								<Button color={'orange'} onClick={()=> this.setState({showOrder: true})}>Anzeigen</Button>
							</Button.Group>
						</div>
						{ showOrder &&
							<OrderViewDialog open onClose={() => this.setState({showOrder: false})}>
								<OrderQuickView order_id={order_id} onRequestClose={() => this.setState({showOrder: false})}/>
							</OrderViewDialog>
						}
					</Flex>
				}
				
				
				<Flex align={"space-between"} valign={"center"} vmargin={10} style={{background: "#e0e1e2", borderRadius: ".28571429rem"}}>
					<div style={{marginLeft: 10}}>
						{fetchingVehicle && <Icon loading color={'orange'} name={'spinner'}/>}
						{vehicle ?
							<EasyFlex inline valign={EasyFlex.valign.CENTER}><RegistrationMark style={{transform: 'scale(0.7)', transformOrigin: 'center left'}}>{this.vehicle.registration_mark}</RegistrationMark> {this.vehicle.name}<Required/></EasyFlex>
							: <span>Fahrzeug wählen<Required/></span>}
					</div>
					<div>
						{/*<RaisedButton secondary onClick={this.showLoanCarSearch(true)}>
							auswählen
						</RaisedButton>*/}
						<Button.Group>
							<Button disabled={!mayChange} onClick={this.showLoanCarSearch(true)}>{vehicle_id ? 'Ändern' : 'Auswählen'}</Button>
							{/*{vehicle && <Button disabled={!mayChange} icon negative onClick={() => this.setLoanCar(null)}><Icon inverted name={"close"}/></Button>}*/}
						</Button.Group>
					</div>
				</Flex>
				
				<Flex align={"space-between"} valign={"center"} vmargin={10} style={{background: "#e0e1e2", borderRadius: ".28571429rem"}}>
					<div style={{marginLeft: 10}}>{fetchingClient && <Icon loading color={'orange'} name={'spinner'}/>}<ClientPopup client={client}><ClientName client_id={client_id} client={client}/></ClientPopup></div>
					<Button.Group>
						<Button disabled={!mayChange || !!order_id} onClick={this.showClientSearch(true)}>{client_id ? 'Ändern' : 'Auswählen'}</Button>
						{!order_id && client && <Button disabled={!mayChange || !!order_id} negative icon onClick={this.usnetClient}><Icon inverted name={"close"}/></Button>}
					</Button.Group>
				</Flex>
				
				
				<Flex vmargin={10} align={"center"}>
					<TextField
						fullWidth
						multiLine
						value={info}
						onChange={this.setInfo}
						floatingLabelText={"Weitere Informationen"}
						disabled={!mayChange}
					/>
				</Flex>
				
				
				<Flex align={"space-between"} vmargin={20}>
					{changeMode && mayDelete ? <DeleteButton onRemove={this.handleRemove} loading={removing}>Löschen</DeleteButton> : <span/>}
					{mayChange ? <Button positive={this.valid} loading={saving} onClick={this.handleCreate} disabled={!this.valid || !this.is_changed || saving}>{changeMode ? "Speichern" : "Anlegen"}</Button> : <span/>}
				</Flex>
				
				
				<Modal open={Boolean(showLoanCarSearch && this.start_date && this.end_date)} onClose={this.showLoanCarSearch(false)} centered={false}>
					<Modal.Content scrolling>
						<LoanCarSearchController
							onAbort={this.showLoanCarSearch(false)}
							onSelect={this.setLoanCar}
							selectOnClick
							loanCarMode
							loanCarScheduleId={changeMode}
							delayed={!!order_id}
							startPoint={this.start_date}
							endPoint={this.end_date}
						/>
					</Modal.Content>
				</Modal>
				
				<ClientSearchModal
					open={showClientSearch}
					closeOnSelect
					onSelectClient={this.setClient}
					onClose={this.showClientSearch(false)}
					showSubmitButton showResetButton directSelect
				/>
			</Segment>
		);
	}
}

// LoanCarScheduleMask = sizeMe()(LoanCarScheduleMask);
LoanCarScheduleMask = withModule(LoanCarScheduleMask);
LoanCarScheduleMask = withInit(LoanCarScheduleMask);
LoanCarScheduleMask = connect(
	(state, props) => {
		return {
			cars: state.loan_cars.list,
			clients: state.map.clients || state.clients.list,
			saving: isLoading(state, PROC_LOAN_CARS_SEND),
			removing: isLoading(state, PROC_LOAN_CARS_REMOVE)
		};
	},
	(dispatch, props) => {
		const rights = props.rights;
		return {
			init: () => dispatch(isOnline(() => {
				if (rights.mayRead) {
					dispatch(loanCarAction__get());
					if (props.loanCarSchedule) {
						// dispatch(clientAction__fetch(true));
					}
					// dispatch(clientAction__fetch());
				}
			})),
			onCreate: (data, onSuccess) => dispatch(loanCarAction__putSchedule(data, result => {
				dispatch(addSnackbar("Leihwagentermin gespeichert"));
				isFunction(onSuccess) && onSuccess(result);
			})),
			onRemove: (schedule, onSuccess) => dispatch(loanCarAction__removeSchedule(schedule, result => {
				dispatch(addSnackbar("Leihwagentermin wurde gelöscht"));
				isFunction(onSuccess) && onSuccess(result);
			}))
		};
	}
)(LoanCarScheduleMask);
LoanCarScheduleMask = withRights(SECTION.ORDERS, LoanCarScheduleMask);
LoanCarScheduleMask = translate()(LoanCarScheduleMask);

export default LoanCarScheduleMask;
