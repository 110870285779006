import * as React from 'react';
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {Button, Segment} from "semantic-ui-react";
import {ClientSearchModal} from "../intern/clients/search/ClientSearch";
import {ConnectedConsultantSelector} from "../intern/clients/consultant/ConsultantClientSelector";


export class ConsultantSelectorTest extends React.Component {
	state = {
		client: null,
		open: false
	}
	
	open = open => () => this.setState({open});
	
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Berater-Auswahl-Test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic>
					<Segment>
						<pre>{JSON.stringify(this.state)}</pre>
						<Button onClick={this.open(true)}>Kunde waehlen</Button>
						<ClientSearchModal
							onClose={this.open(false)}
							closeOnSelect
							open={this.state.open}
							onSelectClient={client => this.setState({client})}
						/>
					</Segment>
					<ConnectedConsultantSelector onSelect={c => console.warn('selected consultant: ', c)}/>
				</Segment>
			</div>
		);
	}
}