import * as React from 'react';
import PropTypes from 'prop-types';
import PhotoCard from "../../partial/PhotoCard";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER_REMOVE_AVATAR, PROC_WORKER_UPLOAD_AVATAR} from "../../../actions";
import {CardHeader} from "material-ui";
import AvatarF from '../../../images/avatar-f.png';
import AvatarM from '../../../images/avatar-m.gif';
import {removeAvatarFromWorker, uploadAvatarOfWorker} from "../../../actions/workerActions";
import isFunc from 'lodash/isFunction';
import {addSnackbar} from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import {translate} from "react-i18next";

const _getAvatar = id => id === 'f' ? AvatarF : AvatarM;

const m2s = (state, props) => {
	const worker = props.worker;
	return {
		image: worker.avatar,
		uploadParams: [worker],
		deleteParams: [worker],
		isDeleting: isLoading(state, PROC_WORKER_REMOVE_AVATAR),
		isUploading: isLoading(state, PROC_WORKER_UPLOAD_AVATAR),
		CardHeader: <CardHeader title={props.t('worker.detail.parts.worker-info')} subtitle={props.t('worker.detail.parts.avatar')}/>,
		defaultImage: _getAvatar(worker.gender)
	};
};

const m2d = (dispatch, props) => {
	const {mayWrite, mayDelete} = props;
	return {
		onUpload: mayWrite ? (worker, image, onSuccess) => dispatch(uploadAvatarOfWorker(worker, image, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.worker.avatar-uploaded'));
		})) : null,
		onDelete: mayDelete  ? (worker, onSuccess) => dispatch(removeAvatarFromWorker(worker, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.worker.avatar-removed'));
		})) : null
	};
};

const Result = connect(m2s, m2d)(PhotoCard);
Result.propTypes = {
	worker: PropTypes.shape({
		workers_id: PropTypes.number.isRequired,
		avatar: PropTypes.object,
		avatar_id: PropTypes.number
	}),
	mayWrite: PropTypes.bool,
	mayDelete: PropTypes.bool
};
Result.defaultProps = {
	mayWrite: false,
	mayDelete: false
};
export default translate()(Result);