/*
* Lists the service prices/worktimes of selected SERVICES for contrracted BRANDS
*
* */
import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon, Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {
	ActionBackButton,
	ActionHeader,
	ActionHeaderGroup,
	ActionHeading,
	Flex,
	ResponsiveFlexChild
} from "../../partials/ActionHeader";
import {tryable} from "ah-lib";
import { processTranslate} from "../../../Logic/extensions";
import {Card,  CardText, Divider, List, ListItem} from "material-ui";
import CustomValues, {LineInfo} from './service/MDServicePrice';
import ServiceResourceController from "../../../cointainer/intern/master-data/service/ServiceResourceController";


class MasterDataServiceDetailView extends React.Component {
	static propTypes = {
		mayRead: PropTypes.bool,
		mayChange: PropTypes.bool,
		fetchingService: PropTypes.bool,
		fetchingCars: PropTypes.bool,
		service: PropTypes.shape({
			service_id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired
		}),
		noAccess: PropTypes.func,
		kfz_list: PropTypes.array.isRequired,
		service_cars: PropTypes.shape({
			origin: PropTypes.object.isRequired,
			custom: PropTypes.object.isRequired
		}).isRequired,
		toModelsList: PropTypes.func.isRequired,
		onSetCustomService: PropTypes.func.isRequired,
		onRemoveCustomService: PropTypes.func.isRequired,
		loadingCustom: PropTypes.func,
		resource_list: PropTypes.array
	};
	static defaultProps = {
		mayRead: false,
		mayChange: false,
		fetchingCars: false,
		fetchingService: false,
		resources: {},
		kfz_list: [],
		toModelsList: () => alert('toModelsList() is not implemented yet!'),
		onSetCustomService: () => alert('onSetCustomService() is not implemented yet!'),
		onRemoveCustomService: () => alert('onSetCustomService() is not implemented yet!'),
		loadingCustom: () => false,
		resource_list: []
	};
	
	state = {
		resource_toggle: {}
	};
	
	componentDidMount() {
		const {mayRead, noAccess} = this.props;
		if ( !mayRead ) {
			noAccess();
		}
	}
	
	toggleResource = (id) => () => this.setState(state => ({
		...state,
		resource_toggle: {
			...state.resource_toggle,
			[id]: !state.resource_toggle[id]
		}
	}));
	
	showResource = id => Boolean(this.state.resource_toggle[id]);
	
	linkToModelView = kfz_id => () => this.props.toModelsList(kfz_id);
	
	// handleResourceAssign = (kfz) => (resource, hours_of_work) => this.props.onResourceAssign(kfz, resource, hours_of_work);
	
	render() {
		const {
			t,
			mayRead,
			mayChange,
			fetchingServices,
			fetchingCars,
			service: pureService,
			onBack,
			kfz_list,
			service_cars,
			noAccess,
			onSetCustomService,
			onRemoveCustomService,
			loadingCustom,
			resource_list,
			resources
		} = this.props;
		if ( !mayRead ) {
			return noAccess();
		}
		if ( !pureService ) {
			return (
				<Segment basic style={{minHeight: 400}} loading/>
			);
		}
		const service = processTranslate(t, pureService, 'title')(s => `service.name.service-${s.service_id}`);
		const {origin: carOrigin, custom: carCustom} = service_cars;
		return(
			<Segment basic loading={fetchingCars || fetchingServices}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						{onBack && <ActionBackButton onBack={onBack}/>}
						<ActionHeading>{service ? t('service.service-detail-title', {title: service.title}) : t('service.service-detail-title-loading')}</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<div style={{marginTop: 40, marginBottom: 40}}>
					<Flex gutterHeight={50} gutterWidth={50} wrap={'wrap'}>
						{kfz_list.map(kfz => {
							const origin = tryable(() => carOrigin[service.service_id][kfz.kfz_id])();
							if ( !origin ) {
								return (
									<strong key={`kfz-not-origin-${kfz.kfz_id}`}>{kfz.name} <Icon name={'spinner'} loading/></strong>
								);
							}
							const custom = tryable(() => carCustom[service.service_id][kfz.kfz_id])() || {};
							return(
								
									<ResponsiveFlexChild key={kfz.kfz_id} width={1} widthXS={1} widthSM={1} widthMD={1/2} widthLG={1/3} widthELG={1/4}>
										{/* Header row ... brand name left ... model edit link right*/}
										<Flex align={'space-between'} valign={'flex-start'}>
											<h4>{kfz.name}</h4>
											{/*eslint-disable-next-line*/}
											<a className={'cursor pointer'} onClick={this.linkToModelView(kfz.kfz_id)}>{t('service.edit-models')}</a>
										</Flex>
										<Card>
											<List>
												<ListItem disabled>
													<LineInfo title={t('service.worktime-hours')} value={<strong>{origin.hours_of_work}</strong>} custom={custom.hours_of_work}/>
												</ListItem>
												<ListItem disabled>
													<LineInfo title={t('service.system-price-euro')} value={<strong>€ {origin.price}</strong>} custom={custom.price}/>
												</ListItem>
											</List>
											<Divider/>
											<div style={{display: this.showResource(kfz.kfz_id) ? 'none' : 'block'}}>
												<CardText>
													<CustomValues
														loading={loadingCustom(service.service_id, kfz.kfz_id)}
														onDelete={onRemoveCustomService}
														onSubmit={onSetCustomService}
														service_id={service.service_id}
														kfz_id={kfz.kfz_id}
														editable={mayChange}
														hours_of_work={custom.hours_of_work}
														price={custom.price}/>
												</CardText>
											</div>
											{/*<CardHeader title="Ressourcen zuweisen (Standard)" actAsExpander showExpandableButton className={'ith-card-pointer'} />
											<List expandable>
												<ListItem disabled primaryText="Standardressource mit angegebener Stundenzahl"/>
												<Divider/>
												<MenuItem primaryText="Ressourcen selbst festlegen" leftIcon={<AddIcon/>}/>
												<Divider/>
												<ListItem disabled primaryText="Techniker"  rightIconButton={<AHIconButton><CloseIcon/></AHIconButton>}/>
												<Divider/>
												<MenuItem primaryText={<Flex valign={'center'} align={'space-between'}><spann style={{color: 'blue'}}>weitere Ressource</spann><AddIcon color={'blue'}/></Flex>}/>
											</List>*/}
											<ServiceResourceController
												kfz_id={kfz.kfz_id}
												service_id={service.service_id}
												resources={resources[kfz.kfz_id]}
												hours_of_work={custom.hours_of_work || origin.hours_of_work}
												// onSave={this.handleResourceAssign(kfz)}
												toggled={this.showResource(kfz.kfz_id)}
												onToggle={this.toggleResource(kfz.kfz_id)}
												time={custom.hours_of_work || origin.hours_of_work}
												resource_list={resource_list}/>
										</Card>
									</ResponsiveFlexChild>
							);
						})}
					</Flex>
				</div>
			</Segment>
		);
	}
}

export default translate()(MasterDataServiceDetailView);