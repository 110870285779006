import * as React from "react";
import {connect} from "react-redux";
import withInit from "../../../Logic/withInit";
import {isOnline, userAction__getWork} from "../../../actions/userActions";
import {Segment} from "semantic-ui-react";
import WorkEntryPoint from "../work/WorkEntryPoint";
import AppmtCtrl from "./v2/AppmntController";
import {withInterval} from "../../../Logic/withInterval";
import {workAction__ofDay} from "../../../actions/orderActions";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";

let AppmntController = withInterval(30000)(AppmtCtrl);
AppmntController = connect(
	null,
	dispatch => ({
		interval: (props) => {
			dispatch(workAction__ofDay(undefined, true, props.user.getData().workers_id, null, true));
			// dispatch(scheduleAction__ofWorker(props.user.getData(), undefined, undefined, true));
		}
	})
)(AppmntController);
AppmntController = withRights(SECTION.APPOINTMENTS, AppmntController);


let AppointmentsBarrier = ({loaded, work}) => {
	if (!loaded) {
		return (
			<Segment loading basic style={{minHeight: 200}}/>
		);
	}
	const hasWork = work.length > 0;
	return (
		hasWork ? <WorkEntryPoint/> : <AppmntController intervalActive numberInList={hasWork ? 1 : 2}/>
	);
};

AppointmentsBarrier.defaultProps = {
	loaded: false,
	work: []
};

AppointmentsBarrier = withInit(AppointmentsBarrier);
AppointmentsBarrier = connect(
	(state, props) => ({
		loaded: state.user.loaded.work,
		work: state.user.work
	}),
	(dispatch, props) => {
		
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(userAction__getWork());
			}))
		};
	}
)(AppointmentsBarrier);

export default AppointmentsBarrier;