import {connect} from "react-redux";
import MDHouseCEO from "../../../../components/intern/master-data/base/MDHouseCEO";
import {PROC_HOUSE} from "../../../../actions";
import {isLoading} from "../../../../actions/loaderActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import isFunc from "lodash/isFunction";
import {updateMasterDataOfHouse} from "../../../../actions/carhouseActions";
import User from "../../../../models/User";
import {SECTION} from "../../../../Logic/constants";

const m2s = state => {
	const house = state.carhouse.own;
	const user = User._(state.user.data);
	return ({
		house,
		isSaving: house ? isLoading(state, PROC_HOUSE, house.house_id, 'ceo') : false,
		...user.getAccessTo(SECTION.MASTER_DATA)
	});
};

const m2d = dispatch => ({
	onSave: (house, data, onSuccess) => dispatch(updateMasterDataOfHouse(house, data, 'ceo', result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.md-house.updated'));
	}))
});

export default connect(m2s, m2d)(MDHouseCEO);