import ClientListView from "../../../components/intern/clients/ClientListView";
import {connect} from "react-redux";
import {push} from 'connected-react-router';
import {SECTION} from "../../../Logic/constants";
import User from "../../../models/User";
import {localStorageAction__setClientistViewType} from "../../../actions/localStorageActions";

const m2s = state => {
	const user = User._(state.user.data);
	return {
		// client_list: state.clients.list,
		// clients: values(state.clients.list),
		// vehicles: state.vehicles.list,
		// client_vehicles: state.vehicles.client2vehicle,
		// changingLoanCar: (id) => isLoading(state, PROC_LOAN_CARS_SEND, id),
		// has_loan_car: Boolean(state.modules.list[MODULE_LOAN_CAR]),
		// mayLoanCar: user.hasAccessTo(SECTION.MASTER_DATA, User.RIGHT.WRITE),
		viewType: state.local.client_list_view_type,
		// fetching: isLoading(state, PROC_CLIENTS_FETCH),
		...user.getAccessTo(SECTION.CLIENTS)
	};
};
const m2d = dispatch => {
	return {
		// init: () => dispatch(
		// 	isOnline(() => {
		// 		// dispatch(clientAction__fetch());
		// 		// dispatch(vehicleAction__relations())
		// 	})
		// ),
		onBack: () => dispatch(push('/')),
		toCreate: () => dispatch(push('/clients/add')),
		onSelect: (client) => dispatch(push(`/clients/${client.client_id || client}`)),
		onSelectVehicle: (client, vehicle) => () => {
			return dispatch(push(`/clients/${client.client_id || client}/vehicles/${vehicle.vehicle_id || vehicle}`));
		},
		// onSetLoanCar: (car, value, onSuccess) => dispatch(loanCarAction__set(car, value, result => {
		// 	dispatch(addSnackbar(value ? 'snackbar.loan-car.added' : 'snackbar.loan-car.removed'));
		// 	isFunc(onSuccess) && onSuccess(result);
		// })),
		updateViewType: type => dispatch(localStorageAction__setClientistViewType(type))
	};
};

export default connect(m2s, m2d)(ClientListView);