import * as React from "react";
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../components/partials/ActionHeader";
import {withRights} from "../../../Tools";
import {COLORS, SECTION} from "../../../Logic/constants";
import {orderAction__getOne} from "../../../actions/orderActions";
import WorkView from "./WorkView";
import {Divider, Menu, MenuItem, Popover, RaisedButton} from "material-ui";
import {filter_object, Loadable, trueNull} from "../../../Logic/extensions";
import {appointmentAction__pauseMany, appointmentAction__quitMany, appointmentAction__quitPaused, appointmentAction__quitWork} from "../../../actions/appointmentActions";
import {PROC_APPOINTMENTS, PROC_APPOINTMENTS_PAUSED_QUIT, STORE_SECTION_WORK} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {loadAreasAndGroups} from "../../../actions/rightActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {get, isArray, isFunction, keys, values} from "lodash";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";

const InnerSub = ({children}) => trueNull(children) && <div style={{position: "absolute", bottom: -13, fontSize: "small", color: "black"}}><small>{children}</small></div>;

const InnerItem = ({title, finished, paused, released}) => {
	let sub = null;
	if (finished) {
		sub = <InnerSub>Beendet</InnerSub>;
	} else if (paused) {
		sub = <InnerSub>Pausiert</InnerSub>;
	} else if (!released) {
		sub = <InnerSub>Nicht freigegeben</InnerSub>;
	}
	
	return <div style={{position: "relative"}}>
		<div>{title}</div>
		{sub}
	</div>;
};

class WorkActions extends React.Component {
	state = {
		pauseAnchor: null,
		quitAnchor: null,
		pauseSelection: {},
		quitSelection: {}
	};
	
	togglePause = index => () => this.setState(state => ({
		pauseSelection: {
			...state.pauseSelection,
			[index]: !state.pauseSelection[index]
		}
	}));
	toggleQuit = index => () => this.setState(state => ({
		quitSelection: {
			...state.quitSelection,
			[index]: !state.quitSelection[index]
		}
	}));
	
	pause = () => {
		const ids = keys(filter_object(this.state.pauseSelection)());
		this.props.onPause(ids);
	};
	
	quit = () => {
		const resource_id = this.props.work.resource_id;
		const ids = keys(filter_object(this.state.quitSelection)());
		this.props.onQuit(resource_id, ids);
	};
	
	pauseCount = memoizeOne(
		obj => values(obj).filter(Boolean).length
	);
	quitCount = memoizeOne(
		obj => values(obj).filter(Boolean).length
	);
	
	render() {
		let {
			loading,
			quitWork,
			// quitPaused,
			// work,
			services
		} = this.props;
		if ( !isArray(services)) return "...";
		const pauseCount = this.pauseCount(this.state.pauseSelection);// values(this.state.pauseSelection).filter(p => p).length;
		const quitCount = this.quitCount(this.state.quitSelection); // values(this.state.quitSelection).filter(q => q).length;
		return <React.Fragment>
			<RaisedButton disabled={loading} className={"work-action-button close"} buttonStyle={{background: COLORS.SEMANTIC_RED, color: COLORS.CONTRAST}} onClick={quitWork.bind(null, null, false)}><span style={{paddingLeft: 10, paddingRight: 10}}>Arbeit zurückgeben</span></RaisedButton>
			<RaisedButton disabled={loading} className={"work-action-button pause"} buttonStyle={{background: COLORS.SEMANTIC_ORANGE}} onClick={e => this.setState({pauseAnchor: e.currentTarget})}><span
				style={{paddingLeft: 10, paddingRight: 10}}>Arbeit pausieren/zurückstellen</span></RaisedButton>
			<Popover
				open={null !== this.state.pauseAnchor}
				anchorEl={this.state.pauseAnchor}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
				targetOrigin={{horizontal: 'right', vertical: 'top'}}
				onRequestClose={() => this.setState({pauseAnchor: null, pauseSelection: {}})}
			>
				<Menu style={{backgroundColor: COLORS.SEMANTIC_ORANGE}}>
					{services.map((service) => <MenuItem
						key={service.order_service_id}
						disabled={Boolean(service.paused_by || service.finished_at || !service.released)}
						checked={this.state.pauseSelection[service.order_service_id]}
						primaryText={<InnerItem title={service.title} paused={service.paused_by} finished={service.finished_at} released={service.released}/>}
						insetChildren
						onClick={this.togglePause(service.order_service_id)}
					/>)}
					<Divider/>
						<Loadable hoc={MenuItem} loading={loading}  disabledOnLoading disabled={pauseCount === 0} onClick={this.pause}><span
							style={{paddingLeft: 10, paddingRight: 10}}>Pausieren/Zurückstellen</span></Loadable>
				</Menu>
			</Popover>
			<RaisedButton disabled={loading} className={"work-action-button finish"} buttonStyle={{background: COLORS.SEMANTIC_GREEN}}  onClick={e => this.setState({quitAnchor: e.currentTarget})}><span
				style={{paddingLeft: 10, paddingRight: 10}}>Arbeit abschließen</span></RaisedButton>
			<Popover
				open={null !== this.state.quitAnchor}
				anchorEl={this.state.quitAnchor}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
				targetOrigin={{horizontal: 'right', vertical: 'top'}}
				onRequestClose={() => this.setState({quitAnchor: null, quitSelection: {}})}
			>
				<Menu style={{backgroundColor: COLORS.SEMANTIC_GREEN}}>
					{services.map((service) => <MenuItem
						key={service.order_service_id}
						disabled={Boolean(service.paused_by || service.finished_at || !service.released)}
						checked={this.state.quitSelection[service.order_service_id]}
						primaryText={<InnerItem title={service.title} paused={service.paused_by} finished={service.finished_at} released={service.released}/>}
						insetChildren
						onClick={this.toggleQuit(service.order_service_id)}
					/>)}
					<Divider/>
					<Loadable hoc={MenuItem} loading={loading} disabled={ quitCount === 0} disabledOnLoading onClick={this.quit}><span
						style={{paddingLeft: 10, paddingRight: 10}}>Abschließen</span></Loadable>
				</Menu>
			</Popover>
		</React.Fragment>;
	}
}

WorkActions = connect(
	(state, props) => {
		const work = state.user.work[0];
		if ( !work ) return {};
		const section = get(state, ["appointments", "section", STORE_SECTION_WORK]);
		if (!section) return {};
		const order = get(state.map.orders, work.order_id, get(section, ["orders", work.order_id]));
		// const order = get(state.map.order, work.order_id);//get(section, ["orders", work.order_id]);
		if ( !order ) return {};
		const serviceMap = state.map.services;
		
		const userResources = state.user.resources;
		
		/*const prepServices = memoizeOne(
			(os, sec, res, map) => get(os, [order.order_id], get(sec, ["order2services", order.order_id], [])).map(id =>
				get(section, ["services", id])
			).filter(service => {
				const resources = get(section, ["service2resources", service.order_service_id], []).map(id => get(sec, ["resources", id])).filter( r => res.includes(r.resource_id) || r.resource_id === work.resource_id);
				return resources.length > 0;
			}).map(service => ({
				...service,
				title: get(map, [service.service_id, "title"], 'no-title')
			}))
		);*/
		
		// const getServices = memoize(
		// 	(order_services, order_id) => {
		// 		let list = {};
		// 		for (const [sid, service] of Object.entries(order_services)) {
		// 			if (service.order_id === order_id) {
		// 				list[sid] = service;
		// 			}
		// 		}
		// 		return list;
		// 	}
		// );
		
		const services = /*prepServices(state.map.order2services, section, userResources, serviceMap);*/ get(section, ["order2services", order.order_id], []).map(id =>
			get(section, ["services", id])
		).filter(service => {
			const resources = get(section, ["service2resources", service.order_service_id], []).map(id => get(section, ["resources", id])).filter( r => userResources.includes(r.resource_id) || r.resource_id === work.resource_id);
			return resources.length > 0;
		}).map(service => {
			return {
				...service,
				title: get(serviceMap, [service.service_id, "title"], 'no-title')
			};
		});
		return {
			work,
			order,
			services,
			loading: props.loading || isLoading(state, PROC_APPOINTMENTS) || isLoading(state, PROC_APPOINTMENTS_PAUSED_QUIT),
			releases: state.map.releases
		};
	},
	dispatch => ({
		onPause: (ids, onSuccess) => dispatch(appointmentAction__pauseMany(ids, result => {
			isFunction(onSuccess) && onSuccess(result);
			dispatch(addSnackbar("Arbeiten wurden pausiert!"));
		})),
		onQuit: (resource_id, ids, onSuccess) => dispatch(appointmentAction__quitMany(resource_id, ids, result => {
			isFunction(onSuccess) && onSuccess(result);
			dispatch(addSnackbar("Arbeiten wurden beendet!"));
		}))
	})
)(WorkActions);

WorkActions.propTypes = {
	loading: PropTypes.bool,
	quitWork: PropTypes.func.isRequired,
	quitPaused: PropTypes.func.isRequired,
	work: PropTypes.array.isRequired
};

class WorkEntryPoint extends React.Component {
	static propTypes = {
		work: PropTypes.arrayOf(
			PropTypes.shape({
				order_id: PropTypes.number.isRequired,
				workers_id: PropTypes.number.isRequired,
				service_id: PropTypes.number.isRequired,
				resource_id: PropTypes.number.isRequired,
				start_point: PropTypes.number.isRequired
			})
		),
		quitting: PropTypes.bool,
		pausing: PropTypes.bool,
		workLoaded: PropTypes.bool,
		fetchOne: PropTypes.func,
		quitWork: PropTypes.func,
		quitPaused: PropTypes.func
	};
	static defaultProps = {
		work: [],
		workLoaded: false,
		quitting: false,
		fetchOne: () => console.error('WorkEntryPoint::fetchOne() is not implemented, yet'),
		quitWork: () => alert('WorkEntryPoint::quitWork() is not implemented, yet!')
	};
	
	render() {
		const {work, workLoaded, quitting, quitWork, quitPaused} = this.props;
		return (
			<Segment basic>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionHeading>
							Du bist an der Arbeit!
						</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{/*<Loadable hoc={RaisedButton} loading={pausing || quitting} disabledOnLoading primary onClick={quitWork.bind(null, null, false)}><span style={{paddingLeft: 10, paddingRight: 10}}>Arbeit beenden</span></Loadable>*/}
						{/*<Loadable hoc={RaisedButton} loading={pausing || quitting} disabledOnLoading onClick={quitPaused.bind(null, null)}><span style={{paddingLeft: 10, paddingRight: 10}}>Arbeit pausieren/zurückstellen</span></Loadable>*/}
						{/*<Loadable hoc={RaisedButton} loading={pausing || quitting} disabled={work.length !== 1} disabledOnLoading secondary onClick={quitWork.bind(null, null, true)}><span style={{paddingLeft: 10, paddingRight: 10}}>Arbeit abschließen</span></Loadable>*/}
						<WorkActions work={work} quitPaused={quitPaused} quitWork={quitWork}/>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic loading={!workLoaded || quitting} style={{minHeight: 200}}>
					{work.map(w => {
						return (<WorkView key={`work-order-${w.order_id}`} work={w}/>);
					})}
				</Segment>
			</Segment>
		);
	}
}

WorkEntryPoint = withRights(SECTION.APPOINTMENTS, WorkEntryPoint);
WorkEntryPoint = withInit(WorkEntryPoint);
WorkEntryPoint = connect(
	(state, props) => ({
		work: state.user.work,
		workLoaded: state.user.loaded.work
	}),
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(loadAreasAndGroups());
			// dispatch(appointmentAction__load());
			// dispatch(loadResourcesOfHouse());
			// dispatch(kfzAction__fetchServices());
			// dispatch(vehicleAction__fetch());
			// dispatch(clientAction__fetch());
			// dispatch(appointmentAction__workload());
		})),
		fetchOrder: (order, force = false, onSuccess) => dispatch(orderAction__getOne(order, force, onSuccess)),
		quitWork: (order, quit_id, onSuccess) => dispatch(appointmentAction__quitWork(order, quit_id, onSuccess)),
		quitPaused: (onSuccess) => dispatch(appointmentAction__quitPaused(result => {
			dispatch(addSnackbar("Arbeit pausiert/zurückgestellt"));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(WorkEntryPoint);
WorkEntryPoint = translate()(WorkEntryPoint);
export default WorkEntryPoint;