import {LOCAL_STORAGE_ACTIONS} from "../actions/types";

const LOCAL_STORAGE_NAME = "carperto-storage";

const getStorageObject = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME) || "{}");
const updateStorageObject = (object) => {
	localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({...object}));
	return object;
};

const defaultState = {
	worker_list_view_type: "grid",
	client_list_view_type: "stack",
	timezones: null,
	countries: null
};

export default (state = defaultState, {type, value}) => {
	switch (type) {
		case LOCAL_STORAGE_ACTIONS.WORKER_LIST_VIEW:
			return updateStorageObject({
				...state,
				worker_list_view_type: value
			});
		case LOCAL_STORAGE_ACTIONS.CLIENT_LIST_VIEW:
			return updateStorageObject({
				...state,
				client_list_view_type: value
			});
		case LOCAL_STORAGE_ACTIONS.TIMEZONES:
			return updateStorageObject({
				...state,
				timezones: value
			});
		case LOCAL_STORAGE_ACTIONS.COUNTRIES:
			return updateStorageObject({
				...state,
				countries: value
			})
		default:
			return {
				...state,
				...getStorageObject()
			};
	}
};