import ClientEditView from "../../../components/intern/clients/ClientEditView";
import PropTypes from "prop-types";
import {push} from 'connected-react-router';
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_CLIENT_CREATE} from "../../../actions";
import {clientAction__create} from "../../../actions/clientActions";
import isFunc from 'lodash/isFunction';
import {addSnackbar} from "../../../actions/snackbarActions";
import {SECTION} from "../../../Logic/constants";
import User from "../../../models/User";
import {searchAction__zipcode} from "../../../actions/searchActions";

const m2s = state => {
	const user = User._(state.user.data);
	return {
		isSaving: isLoading(state, PROC_CLIENT_CREATE),
		is_create: true,
		...user.getAccessTo(SECTION.CLIENTS)
	};
};

const m2d = (dispatch, {t, onAfterSave}) => {
	return {
		onBack: () => window.history.back(),
		toClients: () => dispatch(push('/clients')),
		headerTitle: t('Neuen Kunden anlegen'),
		onSave: (client, onSuccess) => dispatch(clientAction__create(client, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.clients.created'));
			onAfterSave(dispatch, result);
		})),
		searchZipcode: (zipcode, onSucces, onFail, onComplete) => dispatch(searchAction__zipcode(zipcode, onSucces, onFail, onComplete))
	};
};

const ClientCreateController = translate()(connect(m2s, m2d)(ClientEditView))
ClientCreateController.propTypes = {
	onAfterSave: PropTypes.func
};
ClientCreateController.defaultProps = {
	onAfterSave: (dispatch, result) =>  dispatch(push(`/clients/${result.client_id}`))
};

export default ClientCreateController;