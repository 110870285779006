import * as React from "react";
import PropTypes from "prop-types";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import {cloneDeep, get, isFunction, pick, values} from "lodash";
import {Button, Message, Segment} from "semantic-ui-react";
import {Checkbox, Divider, IconButton, TextField} from "material-ui";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_ORDER} from "../../../actions";
import {orderAction__extendForced, orderAction__extendForcedToken, orderAction__extendToken, orderAction__fetchOneCached, orderCall__appointmentLookup_v6, orderCall__findForced} from "../../../actions/orderActions";
import {OrderServiceSelector, requestServiceSelectorReset} from "./OrderServiceSelector";
import {OrderServiceSelectionList} from "./OrderServiceSelectionList";
import {OrderDateInfo, requestDatePickerReset} from "./OrderDatePickerV2";
import {EasyFlex, Flex, FlexChild} from "../../../components/partials/ActionHeader";
import {moment, toISO} from "../../../Logic/Moment";
import {COLORS} from "../../../Logic/constants";
import {IconRefresh} from "../../../Logic/icons";
import {addSnackbar} from "../../../actions/snackbarActions";
import {OrderRemoveServiceView} from "./OrderRemoveServiceView";
import {OrderRangeSummary} from "../../../components/intern/orders/OrderRangeSummary";
import {translate} from "react-i18next";
import {MaterialCompoundDateInput} from "../../../Tools/DatePicker";
import {startOfMinute} from 'date-fns';

const DEFAULT_STATE = {
	services: [],
	target_date: null,
	order_info: "",
	released: false,
	lookup: {
		data: null,
		loading: false,
		error: null
	},
	forced: {
		data: null,
		loading: false,
		error: null
	}
};

export class OrderInsertServiceView extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		data: PropTypes.shape({
			orders: PropTypes.object.isRequired,
			order2services: PropTypes.object.isRequired,
			services: PropTypes.object.isRequired,
			service2resources: PropTypes.object.isRequired,
			resources: PropTypes.object.isRequired,
			order_vehicles: PropTypes.object.isRequired,
			loan_cars: PropTypes.object.isRequired,
			clients: PropTypes.object.isRequired
		}),
		onUpdateOrder: PropTypes.func,
		onAfterUpdate: PropTypes.func,
		loading: PropTypes.bool,
		onForce: PropTypes.func,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		order2services: {},
		services: {},
		onAfterUpdate: () => {
		},
		onUpdateOrder: () => alert('OrderInsertServiceView::onUpdateOrder() is missing!'),
		onNotification: (message) => console.log(message)
	};
	
	
	state = cloneDeep(DEFAULT_STATE);
	
	reset = () => this.setState(cloneDeep(DEFAULT_STATE), requestServiceSelectorReset);
	
	release = (_, released) => this.setState({released});
	
	setLookup = (data, callback) => this.setState(state => ({
		...state,
		lookup: {
			...state.lookup,
			...data
		}
	}), callback);
	/**
	 *
	 * @param {Object} data
	 * @param {Object|null} [data.data]
	 * @param {string|null} [data.error]
	 * @param {boolean} [data.loading]
	 * @param {function} [callback]
	 * @param callback
	 */
	setForced = (data, callback) => this.setState(state => ({
		...state,
		forced: {
			...state.forced,
			...data
		}
	}), callback);
	
	resetForced = () => this.setState(state => ({
		...state,
		forced: {
			...DEFAULT_STATE.forced
		}
	}));
	
	setServices = (services) => {
		const inList = this.getServices(this.props.order_id, this.props.data.order2services, this.props.data.services).map(s => s.service_id);
		services = services.filter(s => !inList.includes(s.service_id));
		this.setState({services});
		this.resetForced();
	};
	
	onRequestLookup = async () => {
		const order = this.getOrder(this.props.order_id, this.props.data.orders);
		const vehicle = this.getVehicle(order, this.props.data.order_vehicles);
		const services = this.state.services;
		const loan_car = false;
		const serviceIds = services.map(s => s.service_id);
		const mDeliver = moment(order.deliver_point);
		const mFetch = moment(this.state.target_date || order.fetch_point);
		const data = {
			services: serviceIds,
			vehicle_id: vehicle.vehicle_id,
			loan_car,
			deliver_date: mDeliver.toDate(),
			deliver_time: mDeliver.format("HH:mm"),
			target_date: mFetch.toDate(),
			target_time: mFetch.format("HH:mm"),
			info: this.state.order_info.trim() || order.info
		};
		try {
			this.setLookup({loading: true, error: null});
			this.setState({released: false});
			const result = await orderCall__appointmentLookup_v6(data);
			this.setLookup({loading: false, data: result});
		} catch (e) {
			this.props.onNotification(e.error || e.message, 'alert');
			this.setLookup({loading: false, error: e.error || e.message, data: null});
		}
	};
	
	onOrderExtendToken = () => {
		const order = this.getOrder(this.props.order_id, this.props.data.orders);
		const data = {
			order_id: order.order_id,
			token: this.state.lookup.data.token,
			version: this.state.lookup.data.version,
			info: this.state.order_info.trim() || order.info,
			released: this.state.released
		};
		this.props.onUpdateOrder(data, result => {
			this.props.onAfterUpdate(result);
			this.reset();
			// noinspection JSUnresolvedFunction
			this.props.dispatch(addSnackbar('Änderung wurde gespeichert'))
		});
	};
	
	onRequestForced = async () => {
		const order = this.getOrder(this.props.order_id, this.props.data.orders);
		const vehicle = this.getVehicle(order, this.props.data.order_vehicles);
		const services = this.state.services;
		const loan_car = false;
		const serviceIds = services.map(s => s.service_id);
		const data = {
			order_id: this.props.order_id,
			services: serviceIds,
			vehicle_id: vehicle.vehicle_id,
			deliver_date: order.deliver_point,
			target_date: toISO(this.state.target_date || order.fetch_point),
			loan_car,
			info: this.state.order_info.trim() || order.info,
			released: this.state.released
		};
		try {
			this.setForced({loading: true, error: null});
			const result = await orderCall__findForced(data);
			this.setForced({data: result});
		} catch (e) {
			this.setForced({error: e.error || e.message});
		} finally {
			this.setForced({loading: false});
		}
		
	};
	
	onForceToken = () => {
		const {order_info, forced: {data}} = this.state;
		const order = this.getOrder(this.props.order_id, this.props.data.orders);
		const put = {
			token: data.token,
			info: order_info.trim() || order.info
		};
		// noinspection JSUnresolvedFunction
		this.props.onForceToken(put, result => {
			this.props.onAfterUpdate(result);
			this.reset();
		});
	};
	
	onForce = () => {
		const order = this.getOrder(this.props.order_id, this.props.data.orders);
		const vehicle = this.getVehicle(order, this.props.data.order_vehicles);
		const services = this.state.services;
		const serviceIds = services.map(s => s.service_id);
		const mDeliver = moment(order.deliver_point);
		const mFetch = moment(this.state.target_date || order.fetch_point);
		const data = {
			order_id: order.order_id,
			services: serviceIds,
			vehicle_id: vehicle.vehicle_id,
			deliver_date: toISO(mDeliver),
			target_date: toISO(mFetch),
			info: this.state.order_info.trim() || order.info
		};
		this.props.onForce(data, (result) => {
			this.props.onAfterUpdate(result);
			this.reset();
		});
	};
	
	getServices = memoize(
		(order_id, order2services, serviceMap) =>
			values(pick(serviceMap, order2services[order_id] || []))
	);
	getServiceIds = memoize(
		services => services.map(s => s.service_id)
	);
	getVehicle = memoize(
		(order, vehicles) => get(vehicles, order.order_vehicle_id, null)
	);
	getOrder = memoize(
		(order_id, orders) => {
			try {
				return orders[order_id] || null;
			} catch (e) {
				return null;
			}
		}
	);
	
	getStart = memoize(date => startOfMinute(new Date(date)));
	getEnd = memoize(date => startOfMinute(new Date(date)));
	
	render() {
		const {
			order_id,
			data,
			loading,
			t
		} = this.props;
		const {
			lookup,
			order_info,
			forced: lookForced
		} = this.state;
		// noinspection DuplicatedCode,DuplicatedCode
		const order = this.getOrder(order_id, data.orders);
		if (!order) return null;
		const services = this.getServices(order_id, data.order2services, data.services);
		const serviceIds = this.getServiceIds(services);
		const orderInfo = order_info || order.info;
		const start = this.getStart(order.deliver_point);
		const end = this.getEnd(order.fetch_point);
		return (
			<Segment basic loading={loading}>
				<OrderRemoveServiceView order={order}/>
				<Divider/>
				<OrderServiceSelector asGrid vehicle={data.order_vehicles[order.order_vehicle_id]} onChange={this.setServices} exclude={serviceIds}/>
				{services.length > 0 &&
				<React.Fragment>
					<OrderServiceSelectionList updateOnChange vehicle={data.order_vehicles[order.order_vehicle_id]} services={this.state.services}/>
				</React.Fragment>
				}
				{this.state.services.length > 0 &&
				<div>
					<div style={{paddingTop: 40, paddingBottom: 40}}>
						<EasyFlex align={EasyFlex.align.CENTER} wrap>
							<MaterialCompoundDateInput
								readOnly
								timeLabel={<span>&nbsp;</span>}
								dateLabel={'Annahme'}
								date={start}
								selectsStart
								startDate={start}
								endDate={end}
							/>
							<MaterialCompoundDateInput
								timeLabel={<span>&nbsp;</span>}
								dateLabel={'Abgabe'}
								selectsEnd
								minDate={start}
								startDate={start}
								endDate={end}
								date={end}
								onChange={target_date => this.setState({target_date, lookup: {...DEFAULT_STATE.lookup}, forced: {...DEFAULT_STATE.forced}})}
							/>
						</EasyFlex>
						<div>
							<OrderDateInfo
								titlePrimary={"Ur. Abgabe"}
								titleSecondary={"Sichere Abgabe ab"}
								date={new Date(order.fetch_point)}
								range={new Date(order.range_end)}
								wrap
							/>
						</div>
					</div>
					<Flex align={"space-between"}>
						<IconButton onClick={() => requestDatePickerReset()}><IconRefresh/></IconButton>
						<Button positive onClick={this.onRequestLookup} loading={lookup.loading}>Prüfen</Button>
					</Flex>
					{lookup.error &&
					<Message negative>{t(lookup.error)}</Message>
					}
					{lookup.data &&
					<div>
						<div>
							<TextField
								fullWidth
								floatingLabelText={"Zusätzliche Info"}
								value={orderInfo}
								onChange={(_, value) => this.setState({order_info: value})}
								multiLine
							/>
						</div>
						<Flex valign={"center"}>
							<Checkbox title={"Freigabe"} label={"Service direkt freigeben"} onCheck={this.release} checked={this.state.released}/>
						</Flex>
						{lookup.data.result ?
							<Flex valign={"center"} vmargin={5} gutterWidth={25}>
								<FlexChild><strong style={{color: COLORS.SEMANTIC_GREEN}}>Termin verfügbar:</strong></FlexChild>
								<FlexChild shrink={0.1} grow={100}/>
								<FlexChild shrink={1} grow={100} style={{textAlign: "right"}}>
									<Button color={'blue'} loading={loading} onClick={this.onOrderExtendToken}>Jetzt hinzubuchen</Button>
								</FlexChild>
							</Flex>
							:
							<div>
								<Flex valign={"center"} vmargin={5} gutterWidth={25}>
									<FlexChild><strong style={{color: COLORS.SEMANTIC_RED}}>Kein Termin:</strong></FlexChild>
									<FlexChild grow={100} shrink={0.1} style={{textAlign: "right"}}>
										<Button.Group>
											<Button loading={lookForced.loading} negative onClick={this.onRequestForced}>Überbuchung prüfen</Button>
										</Button.Group>
									</FlexChild>
								</Flex>
								{lookForced.error &&
								<Message negative><p>{t(lookForced.error)}</p></Message>
								}
								{lookForced.data &&
								<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
									<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} direction={EasyFlex.direction.COLUMN}>
										<u>Zeitraum gefunden:</u>
										<OrderRangeSummary ranges={{range_start: lookForced.data.range[0], range_end: lookForced.data.range[1]}}/>
									</EasyFlex>
									<Button style={{marginLeft: 20}} positive onClick={this.onForceToken}>Überbuchen</Button>
								</EasyFlex>
								}
							</div>
						}
					</div>
					}
				</div>
				}
			
			</Segment>
		);
	}
}

OrderInsertServiceView = withInit(OrderInsertServiceView);
OrderInsertServiceView = connect(
	(state, props) => {
		return {
			data: state.orders,
			loading: isLoading(state, PROC_ORDER) || isLoading(state, PROC_ORDER, props.order_id)
		};
	},
	(dispatch, {order_id}) => ({
		init: () => {
			order_id && dispatch(orderAction__fetchOneCached(order_id));
		},
		dispatch,
		onUpdateOrder: (data, onSuccess) => dispatch(orderAction__extendToken(data, onSuccess)),
		onForce: (data, onSuccess) => dispatch(orderAction__extendForced(data, result => {
			dispatch(addSnackbar("Order wurde erweitert!"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		onForceToken: (data, onSuccess) => dispatch(orderAction__extendForcedToken(data, result => {
			dispatch(addSnackbar("Order wurde forciert eingetragen!"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		onNotification: (message, color) => dispatch(addSnackbar(message, color))
	})
)(OrderInsertServiceView);
OrderInsertServiceView = translate()(OrderInsertServiceView);