import * as React from 'react';
import PropTypes from 'prop-types';
import LoginController from "../../cointainer/partial/LoginController";
import {translate} from "react-i18next";


class CarhouseDetail extends React.Component {

	render() {
		const {house} = this.props;
		return (
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 300px)'}}>
				{house && <LoginController style={{margin: 'auto'}} logo={house.logo} title={house.name} house_id={house.house_id} />}
			</div>
		);
	}
}

CarhouseDetail.propTypes = {
	house: PropTypes.shape({
		name: PropTypes.string.isRequired,
		house_id: PropTypes.number.isRequired
	}).isRequired
};


export default translate()(CarhouseDetail);