import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import fuzzysearch from 'fuzzysearch';

export class Search {
	static _ = (tokens, haystack = '') => new Search(tokens, haystack);
	
	
	tokens = [];
	haystack = '';
	boost = 1;
	fuzzy = false;
	operatorAnd = false;
	tokenMatch = false;
	
	constructor(tokens, haystack) {
		this.setTokens(tokens).setHaystack(haystack);
	}
	
	setTokens = tokens => {
		if ( isString(tokens) ) {
			this.tokens = this.split(tokens);
		} else if (isArray(tokens)) {
			this.tokens = tokens.filter(t => t !== '').map(t => t.toLowerCase());
		}
		return this;
	};
	
	split = string => string.split(/\s+/).filter(t => t !== '').map(t => t.toLowerCase());
	
	setHaystack = haystack => {
		this.haystack = `${haystack}`.trim().toLowerCase();
		return this;
	};
	
	setBoost = boost => {
		this.boost = Math.max(0.1, boost);
		return this;
	};
	
	setFuzzy = (fuzzy = true) => {
		this.fuzzy = Boolean(fuzzy);
		return this;
	};
	
	setOperatorAnd = (operator = true) => {
		this.operatorAnd = Boolean(operator);
		return this;
	};
	
	setTokenMatch = (match = true) => {
		this.tokenMatch = Boolean(match);
		return this;
	};
	
	execute = () => {
		let hits = 0;
		for (const token of this.tokens) {
			if ( this.tokenMatch ) {
				const parts = this.split(this.haystack);
				if ( includes(parts, token)) {
					hits += this.boost;
				}
			}
			if (token === this.haystack) {
				hits += this.boost * 2;
			} else if (this.fuzzy && fuzzysearch(token, this.haystack)) {
				hits += this.boost;
			} else if (this.operatorAnd) {
				hits = 0;
				break;
			}
		}
		return hits;
	};
	
	get hits() {
		return this.execute();
	}
}

export default Search;