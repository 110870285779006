import * as React from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {appointmentAction__updateServiceState} from "../../../actions/appointmentActions";
import {PROC_APPOINTMENTS} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {get} from "lodash";
import {AppointmentServiceListView} from "../../../cointainer/intern/appointments";
import {AppointmentClientHeader} from "./appointment_info";
import {Client} from "../../../models";
import {Icon} from "semantic-ui-react";

class AppointmentInfo extends React.PureComponent {
	static propTypes = {
		order: PropTypes.shape({
			order_id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			info: PropTypes.string
		}).isRequired,
		client_id: PropTypes.number.isRequired,
		services: PropTypes.object.isRequired,
		serviceMap: PropTypes.object.isRequired,
		order2services: PropTypes.object.isRequired,
		onUpdateServiceState: PropTypes.func,
		isChanging: PropTypes.func,
		userResources: PropTypes.array,
		service2resources: PropTypes.object,
		resources: PropTypes.object,
		changeView: PropTypes.func.isRequired,
		strict: PropTypes.bool,
		fused: PropTypes.bool,
		orderVehicle: PropTypes.object,
		currentWork: PropTypes.object,
		work_map: PropTypes.object,
		filter_unreleased: PropTypes.bool
	};
	static defaultProps = {
		services: {},
		serviceMap: {},
		isChanging: () => false,
		userResources: [],
		service2resources: {},
		resources: {},
		strict: false,
		fused: false,
		work_map: {},
		filter_unreleased: false,
		changeView: () => alert('AppointmentInfo::changeView() is missing as prop type!'),
		onUpdateServiceState: () => alert('AppointmentInfo::onUpdateServiceState() is not implemented yet!')
	};
	state = {
		toggle: {}
	};
	onToggle = (id, value = null) => () => this.setState(state => {
		this.props.changeView('workers')();
	});
	
	onRequestChange = (id, value) => () => this.props.onUpdateServiceState(id, value, () => {
		this.onToggle(id, false)();
	});
	
	render() {
		const {
			t,
			order,
			services,
			// serviceMap,
			order2services,
			// isChanging,
			userResources,
			service2resources,
			resources,
			strict,
			user,
			client_id,
			// clients,
			// client: givenClient,
			orderVehicle,
			currentWork,
			fused,
			work_map,
			filter_unreleased
		} = this.props;
		
		let service_ids = order2services[order.order_id] || [];
		if (filter_unreleased) {
			service_ids = service_ids.filter(id => (work_map[order.order_id] || []).find(o => o.order_service_id === id));
		}
		return (
			<div>
				<Client.Connected id={client_id} placeholder={<Icon name={'circle notch'} loading/>}>{client =>
					<AppointmentClientHeader client={client} vehicle={orderVehicle} t={t}/>
				}</Client.Connected>
				
				<div>
					<AppointmentServiceListView
						onClick={this.onToggle}
						{...{
							service_ids,
							services,
							service2resources,
							userResources,
							resources,
							strict,
							order,
							fused,
							currentWork,
							user
						}}
					/>
					
				</div>
			</div>
		);
	}
}

AppointmentInfo = withInit(AppointmentInfo);
AppointmentInfo = connect(
	(state, props) => ({
		isChanging: (id) => isLoading(state, PROC_APPOINTMENTS, id),
		userResources: state.user.resources,
		// service2resources: state.appointments.service2resources,
		// resources: state.appointments.resources,
		clients: state.map.clients,
		orderVehicle: props.orderVehicle || get(state, ["vehicles", "order2vehicle", props.order.order_vehicle_id || 0]),
		currentWork: state.user.work[0] || null,
		work_map: props.work_map || state.appointments.work_map
	}),
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			// props.client_id && dispatch(clientAction__fetch());
			// vehicleAction__fetch();
		})),
		onUpdateServiceState: (orderService, finished, onSuccess) => dispatch(appointmentAction__updateServiceState(orderService, finished, onSuccess))
	})
)(AppointmentInfo);
AppointmentInfo = withRights(SECTION.APPOINTMENTS, AppointmentInfo);
AppointmentInfo = translate()(AppointmentInfo);
export default AppointmentInfo;