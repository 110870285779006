import * as React from "react";
import PropTypes from "prop-types";

const Nbsp = ({count, visible}) => visible ? <span>{[...Array(count).keys()].map(_ => "\u00a0")}</span> : null;
Nbsp.propTypes = {
	count: PropTypes.number,
	visible: PropTypes.bool
};
Nbsp.defaultProps = {
	count: 1,
	visible: false
};

export default Nbsp;