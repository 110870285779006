import React from 'react'
import {Button, Card, Confirm, Divider, Form, FormField, FormTextArea, Modal, Table} from "semantic-ui-react";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Required, trueNull} from "../../../../Logic/extensions";
import {Trans, translate} from "react-i18next";
import PropTypes from "prop-types";
import {get, isFunction} from "lodash";
import {v4} from "uuid";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import EditField from "../../../partials/EditField";
import {wheelCall_ewoTw_cancelOrder, wheelCall_ewoTw_cancelRemoval, wheelCall_ewoTw_createOrder, wheelCall_ewoTw_createRemoval, wheelCall_ewoTw_logs, wheelCall_ewoTw_updateRemoval, wheelDispatch_ewoTw_refresh} from "../../../../actions/wheelActions";
import {WheelEditor} from "../WheelEditor";
import {WheelSearchModal} from "../WheelSearchModal";
import {DefaultDatePicker, withMobileAwareness} from "../../../../Tools/DatePicker";
import {Wheel} from "../../../../models";
import {getServiceOptions, getTireOptions} from "../WheelMethods";
import {startOfDay} from "date-fns";
import {DropdownField} from "../../../partials";
import moment from "../../../../Logic/Moment";
import {Card as CardUI, CardHeader as CardHeaderUI, CardText as CardTextUI} from "material-ui";


export const FSModal = (props) => <Modal {...props} className={'modal-fullscreen'} size={'fullscreen'} centered={false}/>

export const CARD_STYLE = {margin: 12, display: 'inline-flex'}

export const ButtonHolder = ({style, ...props}) => [
	<Divider style={{marginBottom: 0, marginTop: 30}} key={'divider'}/>,
	<EasyFlex align={EasyFlex.align.END} style={{paddingTop: 4, paddingBottom: 4, marginTop: -25}} key={'holder'}>
		<Button {...props} style={{marginRight: 0, ...style}}/>
	</EasyFlex>
]

export const Or = ({style, ...props}) => <EasyFlex align={EasyFlex.align.CENTER} style={{marginTop: 10, marginBottom: 20, ...style}} {...props}>
	<Button.Group vertical>
		<Button.Or></Button.Or>
	</Button.Group>
</EasyFlex>

export const TireView = ({tire}) => {
	
	// noinspection JSUnresolvedVariable
	return <React.Fragment>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell textAlign={'center'} colSpan={2} style={{paddingTop: 12}}>Reifen <Trans i18nKey={`ewotw.position.${tire.tirePosition}`} defaults={tire.tirePosition}/></Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			<Table.Row>
				<Table.Cell>Profil (Innen, Mitte, Außen)</Table.Cell>
				<Table.Cell>{tire.innerProfileDepth}, {tire.centerProfileDepth}, {tire.outerProfileDepth}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Position</Table.Cell>
				<Table.Cell><Trans i18nKey={`ewotw.position.${tire.tirePosition}`} defaults={tire.tirePosition}/></Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Marke</Table.Cell>
				<Table.Cell>{tire.brand}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Erstellt (Jahr, Woche)</Table.Cell>
				<Table.Cell>{tire.productionYear}, {tire.productionWeek}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Model</Table.Cell>
				<Table.Cell>{tire.model}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Größe</Table.Cell>
				<Table.Cell>{tire.rimWidth}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Typ</Table.Cell>
				<Table.Cell><Trans i18nKey={`ewotw.tire-type.${tire.tireType}`} defaults={tire.tireType}/></Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Beschädigt</Table.Cell>
				<Table.Cell>{tire.damaged ? 'Ja' : 'Nein'}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Fehlend</Table.Cell>
				<Table.Cell>{tire.missing ? 'Ja' : 'Nein'}</Table.Cell>
			</Table.Row>
			{trueNull(tire.damagedDescription) && <Table.Row>
				<Table.Cell>Schadensbeschreibung</Table.Cell>
				<Table.Cell>{tire.damagedDescription}</Table.Cell>
			</Table.Row>}
		</Table.Body>
	</React.Fragment>
}

export const CustomerView = ({customer}) => {
	
	// noinspection JSUnresolvedVariable
	return <React.Fragment>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell textAlign={'center'} colSpan={2} style={{paddingTop: 12}}>Kunde</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{trueNull(customer.customerNumber) && <Table.Row>
				<Table.Cell>Kundennummer</Table.Cell>
				<Table.Cell>{customer.customerNumber}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.tenantName) && <Table.Row>
				<Table.Cell>Tenant</Table.Cell>
				<Table.Cell>{customer.tenantName}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.firstName) && <Table.Row>
				<Table.Cell>Vorname</Table.Cell>
				<Table.Cell>{customer.firstName}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.lastName) && <Table.Row>
				<Table.Cell>Nachname</Table.Cell>
				<Table.Cell>{customer.lastName}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.street) && <Table.Row>
				<Table.Cell>Straße</Table.Cell>
				<Table.Cell>{customer.street}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.houseNumber) && <Table.Row>
				<Table.Cell>Hausnummer</Table.Cell>
				<Table.Cell>{customer.houseNumber}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.zip) && <Table.Row>
				<Table.Cell>PLZ</Table.Cell>
				<Table.Cell>{customer.zip}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.city) && <Table.Row>
				<Table.Cell>Stadt</Table.Cell>
				<Table.Cell>{customer.city}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.phone) && <Table.Row>
				<Table.Cell>Telefon</Table.Cell>
				<Table.Cell>{customer.phone}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.fax) && <Table.Row>
				<Table.Cell>Fax</Table.Cell>
				<Table.Cell>{customer.fax}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.mobile) && <Table.Row>
				<Table.Cell>Mobilnummer</Table.Cell>
				<Table.Cell>{customer.mobile}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.email) && <Table.Row>
				<Table.Cell>E-Mail</Table.Cell>
				<Table.Cell>{customer.email}</Table.Cell>
			</Table.Row>}
			{trueNull(customer.internet) && <Table.Row>
				<Table.Cell>WWW</Table.Cell>
				<Table.Cell>{customer.internet}</Table.Cell>
			</Table.Row>}
		</Table.Body>
	</React.Fragment>
}

export const VehicleView = ({vehicle}) => {
	
	// noinspection JSUnresolvedVariable
	return <React.Fragment>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell textAlign={'center'} colSpan={2} style={{paddingTop: 12}}>Fahrzeug</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			<Table.Row>
				<Table.Cell>Kennzeichen</Table.Cell>
				<Table.Cell>{vehicle.numberPlate}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Fahrgestellnummer</Table.Cell>
				<Table.Cell>{vehicle.vehicleIdentificationNumber}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Typ</Table.Cell>
				<Table.Cell>{vehicle.type}</Table.Cell>
			</Table.Row>
		</Table.Body>
	</React.Fragment>
}

export class TireSetView extends React.Component {
	state = {
		showTires: false
	}
	render() {
		let {tire} = this.props;
		// noinspection JSUnresolvedVariable
		return <React.Fragment>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell textAlign={'center'} colSpan={2} style={{paddingTop: 12}}>Reifensatz</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row>
					<Table.Cell>Reifensatznummer</Table.Cell>
					<Table.Cell>{tire.tireSetNumber}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Reifen</Table.Cell>
					<Table.Cell>{tire.tires.length}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Reifentyp</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.tire-type.${tire.tireType}`} defaults={tire.tireType}/></Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Reifensatztyp</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.consumer-type.${tire.type}`} defaults={tire.type}/></Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Serviceart</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.service.${tire.orderType}`} defaults={tire.orderType}/></Table.Cell>
				</Table.Row>
				{trueNull(tire.tireRackNumber) && <Table.Row>
					<Table.Cell>Regalnummer</Table.Cell>
					<Table.Cell>{tire.tireRackNumber}</Table.Cell>
				</Table.Row>}
				<Table.Row>
					<Table.Cell>Auftragsnummer</Table.Cell>
					<Table.Cell>{tire.storageOrderId}</Table.Cell>
				</Table.Row>
				{trueNull(tire.removalOrderId) && <Table.Row>
					<Table.Cell>Auslagerungsauftragsnummer</Table.Cell>
					<Table.Cell>{tire.removalOrderId}</Table.Cell>
				</Table.Row>}
				<Table.Row>
					<Table.Cell>Waschen</Table.Cell>
					<Table.Cell>{tire.toWash ? 'ja' : 'Nein'}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Wuchten</Table.Cell>
					<Table.Cell>{tire.toBalance ? 'ja' : 'Nein'}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Reparieren</Table.Cell>
					<Table.Cell>{tire.toRepair ? 'ja' : 'Nein'}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Fotos</Table.Cell>
					<Table.Cell>{tire.toPhotograph ? 'ja' : 'Nein'}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Kommentare</Table.Cell>
					<Table.Cell>{tire.comments ? 'ja' : 'Nein'}</Table.Cell>
				</Table.Row>
			</Table.Body>
			
			{tire.customer && <CustomerView customer={tire.customer}/>}
			{tire.vehicle && <VehicleView vehicle={tire.vehicle}/>}
			<Table.Body>
				<Table.Row>
					<Table.Cell colSpan={2} textAlign={'center'}>
						<Button basic onClick={e => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation()
							this.setState({showTires: true})
						}}>Zeige Reifendetails an</Button>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
			{this.state.showTires && <Modal size={'tiny'} open centered={false} onClose={() => this.setState({showTires: false})}>
				<Modal.Header>{tire.tires.length} Reifen von {tire.tireSetNumber}</Modal.Header>
				<Modal.Content scrolling>
					<Table striped definition basic={'very'} celled>
						{tire.tires.map((t, idx) => <TireView key={`${idx}-${t.tirePosition}`} tire={t}/>)}
					</Table>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => this.setState({showTires: false})}><Trans i18nKey={'actions.close'}/></Button>
				</Modal.Actions>
			</Modal>}
		
		</React.Fragment>
	}
}

TireSetView.propTypes = {tire: PropTypes.any}

export const TireList = ({result: list, onSelect, actions}) => {
	return <React.Fragment>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>Reifensatznummer</Table.HeaderCell>
				<Table.HeaderCell>Kennzeichen</Table.HeaderCell>
				<Table.HeaderCell>Typ</Table.HeaderCell>
				<Table.HeaderCell>Fahrgestellnr.</Table.HeaderCell>
				<Table.HeaderCell>Reifentyp</Table.HeaderCell>
				<Table.HeaderCell>Servicetyp</Table.HeaderCell>
				{trueNull(actions) && <Table.HeaderCell>&nbsp;</Table.HeaderCell>}
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{list.map(tire => {
				// noinspection JSUnresolvedVariable
				return <Table.Row key={tire.tireSetNumber} onClick={e => {
					onSelect && onSelect(tire, e)
				}}>
					<Table.Cell>{tire.tireSetNumber}</Table.Cell>
					<Table.Cell>{get(tire, 'vehicle.numberPlate', '-')}</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.consumer-type.${tire.type}`} defaults={tire.type}/></Table.Cell>
					<Table.Cell>{get(tire, 'vehicle.vehicleIdentificationNumber', '-')}</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.tire-type.${tire.tireType}`} defaults={tire.tireType}/></Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.service.${tire.orderType}`} defaults={tire.orderType}/></Table.Cell>
					{trueNull(actions) && <Table.Cell>{isFunction(actions) ? actions(tire) : actions}</Table.Cell>}
				</Table.Row>
			})}
		</Table.Body>
	</React.Fragment>
}

export const OrderStateView = ({result}) => {
	// noinspection JSUnresolvedVariable
	return <React.Fragment>
		<Table definition celled basic={'very'}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell textAlign={'center'} colSpan={2} style={{paddingTop: 12}}>{'dueDate' in result ? 'Auslagerungsauftrag' : 'Einlagerungsauftrag'} {result.tireSet.storageOrderId}</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row>
					<Table.Cell>Status</Table.Cell>
					<Table.Cell><Trans i18nKey={`ewotw.status.${result.orderStatus}`} defaults={result.orderStatus}/></Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Erledigt am</Table.Cell>
					<Table.Cell>{result.doneDate ? moment(result.doneDate).format('LLL') : result.doneDate}</Table.Cell>
				</Table.Row>
				{trueNull("dueDate" in result) && <Table.Row>
					<Table.Cell>Auslagerung am</Table.Cell>
					<Table.Cell>{result.dueDate ? moment(result.dueDate).format('LLL') : 'Keine Auslagerung angefordert'}</Table.Cell>
				</Table.Row>}
			</Table.Body>
			<TireSetView tire={result.tireSet}/>
		</Table>
	</React.Fragment>
}

export const TireSetTable = ({result}) => {
	return <Table definition celled basic={'very'}>
		<TireSetView tire={result}/>
	</Table>
}

export class TireSetListView extends React.Component {
	static propTypes = {
		onSelect: PropTypes.func,
		loading: PropTypes.bool
	}
	state = {
		detailOf: null
	}
	
	render() {
		const {detailOf: tire} = this.state
		const {result, onSelect, loading} = this.props
		// noinspection JSUnresolvedVariable
		return (
			<React.Fragment>
				<Table striped selectable basic={'very'} celled>
					<TireList result={result} onSelect={onSelect} actions={tire => {
						return <Button basic icon={'info'} onClick={(e) => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation()
							this.setState({detailOf: tire})
						}}/>
					}}/>
				</Table>
				{tire && <Modal size={'tiny'} open centered={false} onClose={() => this.setState({detailOf: null})}>
					<Modal.Header>Reifensatz {tire.tireSetNumber}</Modal.Header>
					<Modal.Content scrolling>
						<Table basic={'very'} celled definition>
							<TireSetView tire={tire}/>
						</Table>
					</Modal.Content>
					<Modal.Actions>
						<Button type={'button'} onClick={() => this.setState({detailOf: null})}><Trans i18nKey={'actions.close'}/></Button>
						{onSelect && <Button type={'button'} loading={loading} positive onClick={e => {
							this.setState({detailOf: null}, () => onSelect(tire, e))
						}}><Trans i18nKey={'actions.select'}/></Button>}
					</Modal.Actions>
				</Modal>}
			</React.Fragment>
		);
	}
}

// noinspection JSIgnoredPromiseFromCall
export class EwoRefresh extends React.Component {
	static propTypes = {
		onRefresh: PropTypes.func,
		wheelId: PropTypes.number,
		onNotification: PropTypes.func,
		notifyOnSuccess: PropTypes.bool
	}
	static defaultProps = {
		onRefresh: wheelDispatch_ewoTw_refresh,
		onNotification: dispatchSnack
	}
	state = {
		loading: false,
	}
	
	refresh = async () => {
		const {wheelId, onRefresh, onNotification, notifyOnSuccess} = this.props
		if (!wheelId) return
		try {
			this.setState({loading: true})
			await onRefresh(wheelId)
			notifyOnSuccess && onNotification && onNotification('EwoDaten wurden aktualisiert')
		} catch (e) {
			console.error(e)
			onNotification && onNotification(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {loading} = this.state
		const {wheelId, onNotification, onRefresh, notifyOnSuccess, dispatch, ...props} = this.props
		if (!wheelId) return null
		return (
			<Button icon={'redo'} basic {...props} loading={loading} onClick={e => {
				// noinspection JSUnresolvedFunction
				e && e.stopPropagation()
				this.refresh()
			}}/>
		);
	}
}

export class Search extends React.Component {
	static propTypes = {
		request: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
		onResult: PropTypes.func,
		resultComponent: PropTypes.element,
		useModal: PropTypes.any,
		defaultValue: PropTypes.string,
	}
	static defaultProps = {
		useModal: FSModal,
		defaultValue: ''
	}
	state = {
		request: this.props.defaultValue || '',
		result: null,
		loading: false
	}
	id = v4()
	
	
	fetch = async () => {
		const id = this.state.request.trim()
		if (!id) return
		try {
			this.setState({loading: true, result: null})
			const result = await this.props.request(id)
			this.props.onResult ? this.props.onResult(result) : this.setState({result})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const MyModal = this.props.useModal
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>{this.props.title}</Card.Header>
					<Divider/>
					<Card.Description>
						<Form id={this.id} onSubmit={this.fetch} loading={this.state.loading}>
							<FormField>
								{/*<label>{this.props.label}</label>*/}
								{/*<FormInput required value={this.state.request} onChange={(e, {value}) => this.setState({request: value})}/>*/}
								<EditField
									editable
									required
									label={this.props.label}
									value={this.state.request}
									onChange={(e, {value}) => this.setState({request: value})}
								/>
							</FormField>
						
						</Form>
					</Card.Description>
					<ButtonHolder disabled={this.state.loading || !this.state.request.trim()} form={this.id}>Suchen</ButtonHolder>
				
				</Card.Content>
				
				<MyModal open={this.state.result !== null} onClose={() => this.setState({result: null})}>
					<Modal.Header>{this.props.header}</Modal.Header>
					<Modal.Content scrolling>
						{React.isValidElement(this.props.resultComponent) ? React.cloneElement(this.props.resultComponent, {result: this.state.result}) :
							<pre>{JSON.stringify(this.state.result, null, 4)}</pre>
						}
					
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({result: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</MyModal>
			</Card>
		
		);
	}
}

export class CreateStorage extends React.Component {
	state = {
		showWheelModal: false,
		wheel: null,
		loading: false,
		result: null
	}
	
	create = async () => {
		const {wheel, tiresetNumber, referenceNumber, comment} = this.state
		try {
			this.setState({loading: true})
			const result = await wheelCall_ewoTw_createOrder({
				wheel_id: wheel.wheel_id,
				tireset_number: tiresetNumber,
				reference_number: referenceNumber.trim() || null,
				comment: comment.trim() || null
			})
			this.setState({result})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	afterSave = ({wheel, result}) => {
		this.setState({wheel, showWheelModal: false, result});
	}
	
	render() {
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>Reifensatz einlagern</Card.Header>
					<Divider/>
					<Button fluid onClick={() => this.setState({showWheelModal: true})}>Reifensatz einlagern</Button>
				</Card.Content>
				{this.state.result && [
					<Divider key={'divider'}/>,
					<Card.Content>
						<label style={{display: 'block'}}>Erhaltene Auftragsnummer</label>
						<pre>{this.state.result}</pre>
					</Card.Content>
				]}
				{this.state.showWheelModal && <Modal open onClose={() => this.setState({showWheelModal: false})}>
					<Modal.Header>Reifensatz einlagern</Modal.Header>
					<Modal.Content>
						<WheelEditor confirmationNeeded clientRequired onAfterChange={wheel => this.setState({wheel, showWheelModal: false})} saveText={'Einlagern'} onSave={wheelCall_ewoTw_createOrder}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({showWheelModal: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Card>
		);
	}
}


export class CancelStorage extends React.Component {
	state = {
		orderId: '',
		wheel: null,
		loading: false,
		result: null,
		modal: false,
		confirm: false
	}
	id = v4()
	set = (_, {name, value}) => this.setState({[name]: value})
	
	cancel = async () => {
		const {wheel, orderId} = this.state
		try {
			this.setState({loading: true, result: null})
			const result = await wheelCall_ewoTw_cancelOrder(orderId || null, wheel ? wheel.wheel_id : null)
			this.setState({result, orderId: '', wheel: null})
			dispatchSnack('Stornierung war erfolgreich')
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {loading} = this.state
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>Einlagerung stornieren</Card.Header>
					<Divider/>
					{/*<Form onSubmit={() => this.setState({confirm: true})}>*/}
					{/*	<FormField>*/}
					{/*		<EditField*/}
					{/*			label={'Auftragsnummer'}*/}
					{/*			value={this.state.orderId}*/}
					{/*			editable*/}
					{/*			name={'orderId'}*/}
					{/*			onChange={this.set}*/}
					{/*		/>*/}
					{/*	</FormField>*/}
					{/*	<ButtonHolder disabled={loading || !this.state.orderId.trim()} loading={loading}>Stornieren</ButtonHolder>*/}
					{/*</Form>*/}
					{/*<Or/>*/}
					<Button disabled={loading} loading={loading} fluid onClick={() => this.setState({modal: true})}>Reifensatz auswählen</Button>
				</Card.Content>
				<WheelSearchModal
					open={this.state.modal}
					onClose={() => this.setState({modal: false})}
					onSelect={wheel => this.setState({wheel})}
				/>
				{this.state.confirm && <Confirm
					size={'mini'}
					open
					content={'Wirklich ausführen?'}
					cancelButton={'Nein'}
					onCancel={() => this.setState({confirm: false})}
					onConfirm={this.cancel}
				/>}
				{this.state.wheel && <Confirm
					size={'mini'}
					open
					content={'Wirklich ausführen?'}
					cancelButton={'Nein'}
					onCancel={() => this.setState({wheel: null})}
					onConfirm={this.cancel}
				/>}
			</Card>
		);
	}
}

export const MobileDatePicker = withMobileAwareness(DefaultDatePicker)

export class StorageRemoval extends React.Component {
	state = {
		wheel: null,
		date: new Date(),
		tireset_number: '',
		reference_number: '',
		tire_type: Wheel.TIRE_MIXED,
		order_type: Wheel.SERVICE_STANDARD,
		comment: '',
		search: false,
		loading: false,
		result: null,
		confirm: false
	}
	
	get tireOptions() {
		return getTireOptions(Wheel.TIRES, this.props.t)
	}
	
	get serviceOptions() {
		return getServiceOptions(Wheel.SERVICE_TYPES, this.props.t)
	}
	
	get resetData() {
		return {
			wheel: null,
			date: new Date(),
			tireset_number: '',
			reference_number: '',
			tire_type: Wheel.TIRE_MIXED,
			order_type: Wheel.SERVICE_STANDARD,
			comment: ''
		}
	}
	
	set = (_, {name, value}) => this.setState({[name]: value})
	
	get invalid() {
		return Boolean(this.state.loading || !this.state.tireset_number.trim() || !this.state.date || this.state.date < startOfDay(new Date()))
	}
	
	execute = async () => {
		if (this.invalid) {
			return
		}
		const {wheel, date, tireset_number, reference_number, tire_type, order_type, comment} = this.state
		try {
			this.setState({loading: true, result: null, confirm: false})
			const result = await wheelCall_ewoTw_createRemoval({
				wheel_id: get(wheel, 'wheel_id', null),
				date,
				tireset_number,
				reference_number,
				tire_type,
				order_type,
				comment
			})
			this.setState({result, ...this.resetData})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	
	render() {
		const {tire_type, tireset_number, order_type, comment, reference_number, search, loading, confirm, date, result} = this.state
		const {t} = this.props
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>Auslagerung</Card.Header>
					<Divider/>
					<Form onSubmit={() => !this.invalid && this.setState({confirm: true})}>
						<FormField>
							<label><Required>Auslagerungsdatum</Required></label>
							<MobileDatePicker
								wrapperClassName={'fluid'}
								onChange={date => this.setState({date})}
								selected={date}
								minDate={new Date()}
								//	customInput={<Form.Input fluid />} style={{width: '100%'}}
							/>
						</FormField>
						<FormField>
							<EditField
								required
								editable
								label={'Reifensatznummer'}
								name={'tireset_number'}
								value={tireset_number}
								onChange={this.set}
							/>
						</FormField>
						<FormField>
							<EditField
								editable
								label={'Referenznummer'}
								value={reference_number}
								name={'reference_number'}
								onChange={this.set}
							/>
						</FormField>
						<FormField>
							<DropdownField
								editable
								label={'Reifentyp'}
								value={tire_type}
								options={this.tireOptions}
								name={'tire_type'}
								onChange={this.set}
							/>
						</FormField>
						<FormField>
							<DropdownField
								editable
								label={'Auftrag'}
								value={order_type}
								options={this.serviceOptions}
								name={'order_type'}
								onChange={this.set}
							/>
						</FormField>
						<FormField>
							<label>Kommentar</label>
							<FormTextArea
								value={comment}
								name={'comment'}
								onChange={this.set}
							/>
						</FormField>
						<ButtonHolder loading={loading} disabled={this.invalid || loading}>Auslagern</ButtonHolder>
					</Form>
					<Or/>
					<Button fluid disabled={loading} onClick={() => this.setState({search: true})}>Reifensatz wählen</Button>
				</Card.Content>
				{search && <WheelSearchModal
					closeOnSelect={false}
					onClose={() => this.setState({search: false})}
					open
					onSelect={wheel => {
						const tireset_number = get(wheel, 'ewo_tw.tireSetNumber', get(wheel, 'ewo_tw.tireSet.tireSetNumber', ''))
						if (!tireset_number) {
							dispatchSnack('Keine Reifensatznummer gefunden', 'alert')
							return
						}
						this.setState({
							wheel,
							reference_number: wheel.reference_number,
							tire_type: wheel.type,
							order_type: wheel.service_type,
							comment: wheel.comment,
							tireset_number,
							search: false
						})
					}}
				/>}
				{confirm && <Confirm
					size={'mini'}
					open
					cancelButton={t('actions.cancel')}
					content={'Wirklich ausführen?'}
					onCancel={() => this.setState({confirm: false})}
					onConfirm={this.execute}
				/>}
				{result && <FSModal open onClose={() => this.setState({result: null})}>
					<Modal.Header>Auslagerungsergebnis</Modal.Header>
					<Modal.Content scrolling>
						<pre>{JSON.stringify({removalOrderId: result.removalOrderId, wheel: result.wheel}, null, 2)}</pre>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({result: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
			</Card>
		);
	}
}

StorageRemoval = translate()(StorageRemoval)

export class StorageRemovalUpdate extends React.Component {
	state = {
		date: null,
		prev_date: null,
		order_id: '',
		comment: '',
		search: false,
		wheel: null,
		confirm: false,
		loading: false,
		result: null
	}
	
	set = (_, {name, value}) => this.setState({[name]: value})
	
	get invalid() {
		return (this.state.loading || !this.state.date || !this.state.order_id.trim())
	}
	
	get unsetData() {
		return {
			date: null,
			prev_date: null,
			order_id: '',
			comment: '',
			search: false,
			wheel: null,
			confirm: false
		}
	}
	
	execute = async () => {
		if (this.invalid) return
		const {date, prev_date, order_id, comment, wheel} = this.state
		try {
			this.setState({loading: true, result: null, search: false, confirm: false})
			const result = await wheelCall_ewoTw_updateRemoval({date, prev_date, order_id, comment, wheel_id: wheel ? wheel.wheel_id : null})
			this.setState({result, ...this.unsetData})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {t} = this.props
		const {date, prev_date, order_id, comment, search, loading, confirm, result} = this.state
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>Auslagerung aktualisieren</Card.Header>
					<Divider/>
					<Form onSubmit={() => !this.invalid && this.setState({confirm: true})}>
						<FormField>
							<EditField
								editable
								required
								label={'Auslagerungsnummer'}
								value={order_id}
								name={'order_id'}
								onChange={this.set}
							/>
						</FormField>
						<FormField>
							<label><Required>Neues Auslagerungsdatum</Required></label>
							<MobileDatePicker
								wrapperClassName={'fluid'}
								onChange={date => this.setState({date})}
								selected={date}
								minDate={new Date()}
								//	customInput={<Form.Input fluid />} style={{width: '100%'}}
							/>
						</FormField>
						<FormField>
							<label>Altes Auslagerungsdatum</label>
							<MobileDatePicker
								wrapperClassName={'fluid'}
								onChange={prev_date => this.setState({prev_date})}
								selected={prev_date}
								isClearable
								//	customInput={<Form.Input fluid />} style={{width: '100%'}}
							/>
						</FormField>
						<FormField>
							<label>Kommentar</label>
							<FormTextArea
								name={'comment'}
								value={comment}
								onChange={this.set}
							/>
						</FormField>
						<ButtonHolder loading={loading} disabled={this.invalid}>Auslagerung aktualisieren</ButtonHolder>
					</Form>
					<Or/>
					<Button disabled={loading} fluid onClick={() => this.setState({search: true})}>Reifensatz nutzen</Button>
				</Card.Content>
				{search && <WheelSearchModal
					onClose={() => this.setState({search: false})}
					closeOnSelect={false}
					open
					onSelect={wheel => {
						const id = wheel.id || get(wheel, 'ewo_tw.storageOrderId', null)
						if (!id) {
							dispatchSnack('Reifensatz hat keine Auftragsnummer hinterlegt', 'alert')
							return
						}
						const prev = wheel.extract_at;
						const insert = prev ? {prev_date: new Date(prev)} : {}
						this.setState({wheel, order_id: id, search: false, ...insert})
					}}
				/>}
				{confirm && <Confirm
					size={'mini'}
					open
					cancelButton={t('actions.cancel')}
					content={'Wirklich ausführen?'}
					onCancel={() => this.setState({confirm: false})}
					onConfirm={this.execute}
				/>}
				{result && <FSModal open onClose={() => this.setState({result: null})}>
					<Modal.Header>Auslagerungsaktualisierungsergebnis</Modal.Header>
					<Modal.Content scrolling>
						<pre>{JSON.stringify(result, null, 2)}</pre>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({result: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
			</Card>
		);
	}
}

StorageRemovalUpdate = translate()(StorageRemovalUpdate)

export class StorageRemovalCancel extends React.Component {
	state = {
		order_id: '',
		wheel: null,
		loading: false,
		search: false,
		confirm: false,
		result: null
	}
	
	get invalid() {
		return Boolean(this.state.loading || !this.state.order_id.trim())
	}
	
	get unsetData() {
		return {
			order_id: '',
			wheel: null,
			search: false,
			confirm: false
		}
	}
	
	set = (_, {name, value}) => this.setState({[name]: value})
	
	execute = async () => {
		if (this.invalid) return
		try {
			this.setState({loading: true, result: null, confirm: false})
			const result = await wheelCall_ewoTw_cancelRemoval(this.state.order_id, this.state.wheel ? this.state.wheel.wheel_id : null)
			this.setState({result, ...this.unsetData})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {order_id,  loading, search, confirm, result} = this.state
		const {t} = this.props
		return (
			<Card raised style={CARD_STYLE}>
				<Card.Content>
					<Card.Header>Auslagerung stornieren</Card.Header>
					<Divider/>
					<Form onSubmit={() => !this.invalid && this.setState({confirm: true})}>
						<FormField>
							<EditField
								editable
								required
								label={'Auslagerungsnummer'}
								value={order_id}
								name={'order_id'}
								onChange={this.set}
							/>
						</FormField>
						<ButtonHolder loading={loading} disabled={this.invalid}>Stornieren</ButtonHolder>
					</Form>
					<Or/>
					<Button fluid disabled={loading} onClick={() => this.setState({search: true})}>Reifensatz nutzen</Button>
				</Card.Content>
				{search && <WheelSearchModal
					open
					onClose={() => this.setState({search: false})}
					closeOnSelect={false}
					onSelect={wheel => {
						const id = wheel.id || get(wheel, 'ewo_tw.storageOrderId', null)
						if (!id) {
							dispatchSnack('Reifensatz hat keine Auftragsnummer hinterlegt', 'alert')
							return
						}
						this.setState({wheel, order_id: id, search: false})
					}}
				/>}
				{confirm && <Confirm
					size={'mini'}
					open
					cancelButton={t('actions.cancel')}
					content={'Wirklich ausführen?'}
					onCancel={() => this.setState({confirm: false})}
					onConfirm={this.execute}
				/>}
				{result && <FSModal open onClose={() => this.setState({result: null})}>
					<Modal.Header>Auslagerungsstornierungssergebnis</Modal.Header>
					<Modal.Content scrolling>
						<pre>{JSON.stringify(result, null, 2)}</pre>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({result: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
			</Card>
		);
	}
}

StorageRemovalCancel = translate()(StorageRemovalCancel)


export const LogFieldWrapper = (props) => <div {...props}/>


export const LogDataModal = ({trigger, data, ...props}) => <FSModal trigger={<span className={'cursor pointer'}>{trigger || 'Vorhanden'}</span>} closeIcon {...props}>
	<Modal.Content scrolling>
		<pre>{JSON.stringify(data, null, 2)}</pre>
	</Modal.Content>
</FSModal>

// noinspection JSUnresolvedVariable
export const LogList = ({items}) =>
	<Table compact={'very'} selectable striped className={'default-cursor'}>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>ID</Table.HeaderCell>
				<Table.HeaderCell>Reifen</Table.HeaderCell>
				<Table.HeaderCell>Tenant</Table.HeaderCell>
				<Table.HeaderCell>Branch</Table.HeaderCell>
				<Table.HeaderCell>OrderID</Table.HeaderCell>
				<Table.HeaderCell>RemovalID</Table.HeaderCell>
				<Table.HeaderCell>ReifensatzNR</Table.HeaderCell>
				<Table.HeaderCell>Aktion</Table.HeaderCell>
				<Table.HeaderCell>Request</Table.HeaderCell>
				<Table.HeaderCell>Antwort</Table.HeaderCell>
				<Table.HeaderCell>Datum</Table.HeaderCell>
			</Table.Row>
		
		</Table.Header>
		<Table.Body>
			{items.map(item =>
				<Table.Row key={item.id}>
					<Table.Cell>{item.id}</Table.Cell>
					<Table.Cell>{item.wheel_id}</Table.Cell>
					<Table.Cell>{item.tenant_id}</Table.Cell>
					<Table.Cell>{item.branch_id}</Table.Cell>
					<Table.Cell>{item.storage_order_id}</Table.Cell>
					<Table.Cell>{item.storage_removal_id}</Table.Cell>
					<Table.Cell>{item.tireset_number}</Table.Cell>
					<Table.Cell>{item.action}</Table.Cell>
					<Table.Cell>{item.data ? <LogDataModal trigger={'Vorhanden'} data={item.data}/> : 'Keine'}</Table.Cell>
					<Table.Cell>{item.response ? <LogDataModal trigger={'Vorhanden'} data={item.response}/> : 'Keine'}</Table.Cell>
					<Table.Cell>{moment(item.created_at).format('L LT')}</Table.Cell>
				</Table.Row>
			)}
		</Table.Body>
	</Table>
LogList.defaultProps = {
	items: []
}

export class Logs extends React.Component {
	state = {
		loading: false,
		search: false,
		results: null,
		wheel_id: null,
		order_id: '',
		removal_id: '',
		tireset_number: '',
		action: '',
		limit: '0'
	}
	
	get unsetData() {
		return {
			wheel_id: null,
			order_id: '',
			removal_id: '',
			tireset_number: '',
			action: '',
			limit: '0'
		}
	}
	
	set = (_, {name, value}) => this.setState({[name]: value})
	
	execute = async () => {
		try {
			const {wheel_id, order_id, removal_id, tireset_number, action, limit} = this.state
			this.setState({loading: true})
			const results = await wheelCall_ewoTw_logs({
				wheel_id,
				order_id,
				removal_id,
				tireset_number,
				action,
				limit
			})
			this.setState({results})
			dispatchSnack('Logs aktualisiert')
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {expandable} = this.props
		const {loading, search, results, wheel_id, order_id, removal_id, tireset_number, action, limit} = this.state
		return (
			<CardUI>
				<CardHeaderUI title={'EwoTW Logs'} subtitle={'Log daten ansehen'} actAsExpander={expandable} showExpandableButton={expandable}/>
				<CardTextUI expandable={expandable}>
					<Form onReset={() => this.setState(this.unsetData)} onSubmit={this.execute}>
						<EasyFlex wrap valign={EasyFlex.valign.END}>
							<LogFieldWrapper>
								<Button.Group>
									<Button type={'button'} onClick={() => this.setState({search: true})}>{wheel_id ? `Reifen ${wheel_id}` : 'Reifen wählen'}</Button>
									{wheel_id && <Button type={'button'} icon={'close'} onClick={() => this.setState({wheel_id: null})}/>}
								</Button.Group>
							</LogFieldWrapper>
							<LogFieldWrapper>
								<EditField
									editable
									label={'Auftragsnummer'}
									name={'order_id'}
									value={order_id}
									onChange={this.set}
								/>
							</LogFieldWrapper>
							
							<LogFieldWrapper>
								<EditField
									editable
									label={'Stornierungsnummer'}
									name={'removal_id'}
									value={removal_id}
									onChange={this.set}
								/>
							</LogFieldWrapper>
							<LogFieldWrapper>
								<EditField
									editable
									label={'Reifensatznummer'}
									name={'tireset_number'}
									value={tireset_number}
									onChange={this.set}
								/>
							</LogFieldWrapper>
							<LogFieldWrapper>
								<EditField
									editable
									label={'Aktion'}
									name={'action'}
									value={action}
									onChange={this.set}
								/>
							</LogFieldWrapper>
							
							<LogFieldWrapper>
								<EditField
									editable
									label={'Max. Ergebnisse'}
									type={'number'}
									name={'limit'}
									min={0}
									step={1}
									onChange={this.set}
									value={limit}
								/>
							</LogFieldWrapper>
							<LogFieldWrapper style={{paddingLeft: 12, paddingRight: 12}}>
								<Button loading={loading} disabled={loading} type={'submit'}>Anzeigen</Button>
							</LogFieldWrapper>
							<LogFieldWrapper style={{paddingLeft: 12, paddingRight: 12}}>
								<Button type={'reset'}>Zurücksetzen</Button>
							</LogFieldWrapper>
						</EasyFlex>
					</Form>
				</CardTextUI>
				{search && <WheelSearchModal
					onClose={() => this.setState({search: false})}
					open
					onSelect={wheel => this.setState({wheel_id: wheel.wheel_id, search: false})}
				/>}
				{results && <FSModal open onClose={() => this.setState({results: null})}>
					<Modal.Header>Logs ({results.length})</Modal.Header>
					<Modal.Content scrolling>
						<LogList items={results}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({results: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
			</CardUI>
		);
	}
}


export const MyTable = ({children, tableProps, ...props}) => {
	if (React.isValidElement(children)) {
		return <Table selectable={!!props.onSelect} {...tableProps}>
			{React.cloneElement(children, props)}
		</Table>
	}
	return null
}