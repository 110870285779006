import * as React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import {Button, Popup, Segment} from "semantic-ui-react";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import VehicleMask from "./VehicleMask";
import {trueNull} from "../../../Logic/extensions";
import ConfirmDialog from "../../../Tools/material-ui/ConfirmDialog";
import {LoanCar} from "./widgets/LoanCar";
import {connect} from "react-redux";
import {addSnackbar} from "../../../actions/snackbarActions";
import {SUB_CLIENT_SEARCH} from "../../../actions";
import {publish} from "../../../Logic/PubSub";
import {get} from "lodash";
import {withModule} from "../../../Tools/RightsProvider";

const ConnectecLoanCar = connect(
	null,
	dispatch => ({
		onNotification: (message, color) => dispatch(addSnackbar(message, color))
	})
)(LoanCar);

class VehicleEditView extends React.Component {
	static propTypes = {
		client: PropTypes.object.isRequired,
		vehicle: PropTypes.object,
		mayRead: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayDelete: PropTypes.bool,
		kfz_list: PropTypes.array,
		onSave: PropTypes.func.isRequired,
		isLoading: PropTypes.bool,
		isSaving: PropTypes.bool,
		onDelete: PropTypes.func,
		onAfterTransfer: PropTypes.func,
		onAfterSave: PropTypes.func,
		showHeader: PropTypes.bool
	};
	static defaultProps = {
		client: {},
		kfz_list: [],
		isLoading: false,
		isSaving: false,
		showHeader: true,
		onSave: () => alert('onSave() is not implemented yet!')
	};
	
	state = {
		confirm: null
	};
	
	setConfirm = (confirm = null) => () => this.setState({confirm});
	
	handleDelete = () => {
		const {onDelete, vehicle} = this.props;
		onDelete && onDelete(vehicle);
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (get(this.props, 'match.params.client_id', null) !== get(prevProps, 'match.params.client_id', null)) {
			this.props.init && this.props.init();
		}
	}
	
	render() {
		const {t, onBack, mayRead, modules, mayCreate, toClient, toVehicles, toWheels, mayChange, mayDelete, kfz_list, onSave, vehicle, isLoading, isSaving, onDelete, client, showHeader} = this.props;
		const title = t(vehicle ? 'client.edit-car-of' : 'client.create-car-of', {client: client.name});
		const {confirm} = this.state;
		return (
			<Segment basic>
				{showHeader && <ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						{onBack && <Button icon={'angle left'} basic onClick={onBack}/>}
						<ActionHeading>{title}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<Button icon={'search'} basic onClick={() => publish(SUB_CLIENT_SEARCH)}/>
						{toClient && <Popup position={'bottom right'} content={'Zum Kunden'} trigger={<Button icon={'user'} color={'brown'} style={{marginLeft: 5}} onClick={toClient}/>}/>}
						{toVehicles && <Popup position={'bottom right'} content={'Zu den Kundenfahrzeugen'} trigger={<Button icon={'car'} color={'brown'} style={{marginLeft: 5}} onClick={toVehicles}/>}/>}
						{modules.wheel && toWheels && <Popup position={'bottom right'} content={'Zu den Fahrzeugreifen'} trigger={<Button icon={'dot circle'} color={'brown'} style={{marginLeft: 5}} onClick={toWheels}/>}/>}
						{mayDelete && onDelete && vehicle && !vehicle.deleted_at && <Popup inverted content={'Fahrzeug löschen?'} position={'bottom right'} trigger={<Button style={{marginLeft: 25}} icon={'trash'} color={'red'} onClick={this.setConfirm(true)}/>}/>}
					</ActionHeaderGroup>
				</ActionHeader>}
				<div style={{marginLeft: 20, marginRight: 20}}>
					<VehicleMask
						onAfterTransfer={this.props.onAfterTransfer}
						onAfterSave={this.props.onAfterSave}
						client_id={client.client_id}
						vehicle={vehicle}
						mayRead={mayRead}
						mayChange={mayChange}
						mayCreate={mayCreate}
						kfz_list={kfz_list}
						onSave={onSave}
						isLoading={isLoading}
						isSaving={isSaving}
					/>
					{trueNull(vehicle) && <ConnectecLoanCar vehicle_id={vehicle.vehicle_id}/>}
				</div>
				<ConfirmDialog onCancel={this.setConfirm()} onConfirm={this.handleDelete} open={null !== confirm}/>
				{/*<NavigationClientSearchModal open={this.state.showSearch} onModalClose={() => this.setState({showSearch: false})}/>*/}
			</Segment>
		);
	}
}

VehicleEditView = withModule(VehicleEditView)
export default translate()(VehicleEditView);