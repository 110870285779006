import * as React from 'react';
import PropTypes from "prop-types";
import {FlatButton} from "material-ui";
import {Loadable} from "../../Logic/extensions";
import {translate} from "react-i18next";

class DeleteButton extends React.Component {
	static propTypes = {
		hoc: PropTypes.func,
		onDelete: PropTypes.func.isRequired,
		isLoading: PropTypes.bool,
		preText: PropTypes.node
	};
	static defaultProps = {
		hoc: 'RaisedButton',
		onDelete: () => alert('DeleteButton.onDelete() is not implemented yet!'),
		isLoading: false
	};
	
	state = {confirm: false};
	
	handleConfirm = confirm => () => this.setState({confirm});
	
	handleDelete = () => {
		this.handleConfirm(false)();
		this.props.onDelete();
	};
	
	render() {
		const {t, hoc: Component, tReady, i18n, onDelete, preText, isLoading, ...props} = this.props;
		const {confirm: confirmState} = this.state;
		if (!confirmState) {
			return <Loadable hoc={Component} loading={isLoading} disabledOnLoading {...props} onClick={this.handleConfirm(true)}>{t('actions.delete')}</Loadable>
		}
		return(
			<React.Fragment>
				{preText}
				<FlatButton onClick={this.handleConfirm(false)}>{t('actions.no')}</FlatButton>
				<Component {...props} onClick={this.handleDelete}>{t('actions.yes')}</Component>
			</React.Fragment>
		);
	}
}

export default translate()(DeleteButton);