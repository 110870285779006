import * as React from "react";
import {moment} from "../../../Logic/Moment";
import {Button, Form, Icon, Segment, Statistic} from "semantic-ui-react";
import {OrderDatePicker} from "./OrderDatePickerV2";
import {scheduleCall__workloadBetween, scheduleCall__workloadOfDay} from "../../../actions/scheduleActions";
import {connect} from "react-redux";
import {addError} from "../../../actions/errorActions";
import {EasyFlex} from "../../../components/partials/ActionHeader";

export class WorkloadViewer extends React.PureComponent {
	now = moment();
	state = {
		start: this.now.toDate(),
		end: null,
		loading: false,
		result: null
	};
	
	
	call = async() => {
		const {dispatch} = this.props;
		const {start, end} = this.state;
		if (!start) {
			return;
		}
		if (!end) {
			try {
				this.setState({loading: true});
				const result = await scheduleCall__workloadOfDay(start);
				this.setState({result});
			} catch (e) {
				dispatch(addError(e));
			} finally {
				this.setState({loading: false});
			}
		} else {
			try {
				this.setState({loading: true});
				const result = await scheduleCall__workloadBetween(start, end);
				this.setState({result});
			} catch (e) {
				dispatch(addError(e));
			} finally {
				this.setState({loading: false});
			}
		}
	};
	
	render() {
		const {start, end, loading, result} = this.state;
		return(
			<Segment basic style={{display: "inline-block"}}>
				<Form onSubmit={this.call}>
					<div>
						<OrderDatePicker
							date={this.now.toDate()}
							time={this.now.format("HH:mm")}
							textDate={"Startdatum"}
							textTime={"Startzeit"}
							onChange={start => this.setState({start})}
						/>
					</div>
					<div>
						<OrderDatePicker
							textDate={"Enddatum"}
							textTime={"Endzeit"}
							minDate={start}
							onChange={end => this.setState({end})}
						/>
					</div>
					{
						result &&
						<EasyFlex align={EasyFlex.align.CENTER} style={{padding: 25}}>
							<Statistic.Group horizontal>
								{
									!end &&
									<Statistic size={"tiny"}>
										<Statistic.Value text>{moment(result.end_point).format("ll")}</Statistic.Value>
										{/*<Statistic.Label>am</Statistic.Label>*/}
									</Statistic>
								}
								{
									end &&
									<Statistic>
										<Statistic.Value text>{moment(result.start_point).format("lll")}</Statistic.Value>
										<Statistic.Label>Ab</Statistic.Label>
									</Statistic>
								}
								<Statistic>
									<Statistic.Value>{result.workload} <Icon name={"percent"}/></Statistic.Value>
									<Statistic.Label>Auslastung</Statistic.Label>
								</Statistic>
								{
									end &&
									<Statistic>
										<Statistic.Value text>{moment(result.end_point).format("lll")}</Statistic.Value>
										<Statistic.Label>Bis</Statistic.Label>
									</Statistic>
								}
								
							</Statistic.Group>
						</EasyFlex>
					}
					<div style={{textAlign: "center"}}>
						<Button loading={loading}>Laden</Button>
					</div>
				</Form>
			</Segment>
		);
	}
}

WorkloadViewer = connect(null, dispatch => ({dispatch}))(WorkloadViewer);