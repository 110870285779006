import * as React from "react";
import {moment} from "../../../Logic/Moment";
import {EasyFlex} from "../../partials/ActionHeader";
import {Message} from "semantic-ui-react";
import {SIGNS} from "../../../Logic/constants";
import PropTypes from "prop-types";

export const OrderRangeSummary = ({ranges, chidren, format, ...props}) => {
	let {range_start, range_end} = ranges;
	[range_start, range_end] = [range_start, range_end].map(d => moment(d));
	let notice = moment() > range_start;
	return (
		<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} {...props}>
			{notice && <Message color={"blue"}>
				<p><strong>Vorsicht!</strong> Startzeit liegt in der Vergangenheit.</p>
			</Message>}
			<div>{range_start.format(format)}</div>
			<div>{SIGNS.DOTS_VERTICAL}</div>
			<div>{range_end.format(format)}</div>
		</EasyFlex>
	);
};
OrderRangeSummary.propTypes = {
	ranges: PropTypes.shape({
		range_start: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.instanceOf(Date)
		]).isRequired,
		range_end: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.instanceOf(Date)
		]).isRequired
	}).isRequired,
	format: PropTypes.string
};
OrderRangeSummary.defaultProps = {
	format: "LLL"
};