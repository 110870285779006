import * as React from "react";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../../components/partials";
import {Button} from "semantic-ui-react";
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";
import {connect} from "react-redux";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_LOAN_CARS_REMOVE} from "../../../../actions";
import {loanCarAction__removeSchedule} from "../../../../actions/loanCarActions";
import {translate} from "react-i18next";
import PropTypes from "prop-types";

export let LoanCarRemove = ({rights: {mayDelete}, t, id, onRemove, loading}) => (
	mayDelete ?
		<ConfirmButton negative content={"Leihwagen entfernen"}>
			<ConfirmButtonConsumer>{confirm =>
				<Button.Group>
					<Button disabled={loading} onClick={confirm.bind(undefined, false)}>{t("actions.cancel")}</Button>
					<Button.Or/>
					<Button negative loading={loading} disabled={loading} onClick={onRemove}>{t("actions.delete")}</Button>
				</Button.Group>
			}</ConfirmButtonConsumer>
		</ConfirmButton> : null
);
LoanCarRemove = withRights(SECTION.ORDERS, LoanCarRemove);
LoanCarRemove = connect(
	state => ({
		loading: isLoading(state, PROC_LOAN_CARS_REMOVE)
	}),
	(dispatch, {id, onAfterRemove}) => ({
		onRemove: () => dispatch(loanCarAction__removeSchedule(id, onAfterRemove))
	})
)(LoanCarRemove);
LoanCarRemove = translate()(LoanCarRemove);
LoanCarRemove.propTypes = {
	id: PropTypes.number.isRequired
};