import * as React from "react";
import {Button, Checkbox, Icon, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {CustomServiceForm} from "../../cointainer/intern/master-data/service/CustomServiceForm";
import {isEmpty} from "lodash";
import {PROC_SERVICE_CUSTOM} from "../../actions";
import { withLoadingWithoutDispatch} from "../../actions/loaderActions";
import {ConfirmButton, ConfirmButtonConsumer} from "../partials/ConfirmButton";

const LoaderButton = withLoadingWithoutDispatch("loading", PROC_SERVICE_CUSTOM)(Button);
const DisabledButton = withLoadingWithoutDispatch("disabled", PROC_SERVICE_CUSTOM)(Button);
const LoaderConfirmButton = withLoadingWithoutDispatch("loading", PROC_SERVICE_CUSTOM)(ConfirmButton);

export class CustomServiceTest extends React.Component {
	state = {
		result: {},
		visible: false,
		errorsBeneeth: false,
		mock: false
	};
	render() {
		const {result, errorsBeneeth, visible, mock} = this.state;
		return (
			<Segment basic>
				<ActionHeader alignment={"flex-start"}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Custom Service Creation</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<CustomServiceForm mock={mock} errorsBeneeth={errorsBeneeth} showErrors={visible} id={"test"} onSubmit={data => {
					this.setState({result: data});
				}}/>
				<div style={{height: 20}}/>
				<h4>Options</h4>
				<Checkbox checked={visible} style={{marginRight: 15}} label={"Show errors immediately"} onChange={(_, {checked}) => this.setState({visible: checked})}/>
				<Checkbox checked={errorsBeneeth} style={{marginRight: 15}} label={"Display errors beneeth"} onChange={(_, {checked}) => this.setState({errorsBeneeth: checked})}/>
				<Checkbox checked={mock} label={"Mock request"} onChange={(_, {checked}) => this.setState({mock: checked})}/>
				<div style={{height: 40}}/>
				<LoaderButton form={"test"} positive>Submit</LoaderButton>
				<hr/>
				<LoaderConfirmButton positive content={"Service speichern"} type={"button"}>
					<ConfirmButtonConsumer>
						{confirm =>
							<Button.Group>
								<DisabledButton negative onClick={e => {
									e && e.stopPropagation();
									confirm(false);
								}}><Icon name={"close"}/> Abbrechen</DisabledButton>
								<Button.Or/>
								<LoaderButton positive form={"test"}>Speichern</LoaderButton>
							</Button.Group>
						}
					</ConfirmButtonConsumer>
				</LoaderConfirmButton>
				{!isEmpty(result) && <pre>{JSON.stringify(result, null, 2)}</pre>}
			</Segment>
		);
	}
}