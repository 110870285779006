import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Segment} from "semantic-ui-react";
import {Trans, translate} from "react-i18next";

export const asTrigger = (targetProp = 'open') => Component =>
	class extends React.PureComponent {
		state = {
			open: false
		};
		
		eventHandler = () =>
			this.setState(state => ({
				open: !state.open
			}));
		
		hide = () => this.setState({open: false});
		
		
		
		render() {
			const {open} = this.state;
			const {trigger: Trigger, [targetProp]: dump2, ...props} = this.props;
			const view = {
				[targetProp]: open
			};
			return (
				<React.Fragment>
					{/*<Trigger onClick={this.eventHandler}/>*/}
					{React.cloneElement(Trigger, {onClick: this.eventHandler})}
					<Component {...props} {...view}/>
				</React.Fragment>
			);
		}
	};
// eslint-disable-next-line
export class Trigger extends React.PureComponent {
	state = {
		triggered: false
	};
	
	setTriggered = triggered => () => this.setState({triggered});
	
	render() {
		const {trigger: TriggerElement, children} = this.props;
		const {triggered} = this.state;
		const Trigger = React.cloneElement(TriggerElement, {onClick: this.setTriggered(true)});
		return(
			<React.Fragment>
				{Trigger}
				{triggered && children}
			</React.Fragment>
		);
	}
}

class ConfirmDialog extends React.PureComponent {
	static propTypes = {
		text: PropTypes.string,
		buttonCancelText: PropTypes.string,
		buttonConfirmText: PropTypes.string,
		loading: PropTypes.bool,
		trigger: PropTypes.element,
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		closeOnConfirm: PropTypes.bool
	};
	static defaultProps = {
		text: 'dialog.sure',
		buttonCancelText: 'actions.no',
		buttonConfirmText: 'actions.yes',
		loading: false,
		closeOnConfirm: true
	};
	
	state = {
		open: false
	};
	
	show = () => this.setState({open: true});
	hide = () => this.setState({open: false});
	
	handleHide = () => {
		this.props.onCancel && this.props.onCancel.call();
		this.hide();
	};
	
	handleConfirm = () => {
		this.props.onConfirm && this.props.onConfirm.call(this, this.hide);
		this.props.closeOnConfirm && this.hide();
	};
	
	render() {
		const {open} = this.state;
		const {t, loading,  text, buttonCancelText, buttonConfirmText, trigger} = this.props;
		const Trigger = trigger ? React.cloneElement(trigger, {onClick: this.show}) : null;
		return(
			<React.Fragment>
				{Trigger}
			{/*	<Dialog open={open} {...props} onRequestClose={this.handleHide} autoScrollBodyContent>
					<Segment basic loading={loading} padded>
						{t(text)}
						<Flex align={'flex-end'}>
							<FlatButton onClick={this.handleHide}>{t(buttonCancelText)}</FlatButton>
							<RaisedButton secondary onClick={this.handleConfirm}>{t(buttonConfirmText)}</RaisedButton>
						</Flex>
					</Segment>
				</Dialog>*/}
				{open && <Modal open centered={false} onClose={this.handleHide}>
					<Modal.Content>
						<Segment basic loading={loading} padded>
							{t(text)}
						</Segment>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.handleHide}><Trans i18nKey={buttonCancelText} defaults={buttonCancelText}/></Button>
						<Button primary onClick={this.handleConfirm}><Trans i18nKey={buttonConfirmText} defaults={buttonConfirmText}/></Button>
					</Modal.Actions>
				</Modal>}
			</React.Fragment>
		);
	}
}

ConfirmDialog = translate()(ConfirmDialog);
export default ConfirmDialog;