import {Vehicle} from "../../../../models";
import {EasyFlex} from "../../../partials/ActionHeader";
import VehiclePopup from "../../vehicles/VehiclePopup";
import {RegistrationMark} from "../../vehicles";
import * as React from "react";
import {isFunction} from "lodash";
import {Icon} from "semantic-ui-react";

const clickHandler = (onClick, vehicle, name) => (e) => {
	if (isFunction(onClick)) {
		onClick(e, vehicle, name);
	}
};

const VehicleHeader = ({vehicle: v, showLoader, onClick}) => {
	if (!v) {
		return showLoader ? (<div><Icon loading name={"spinner"}/></div>) : null;
	}
	let vehicle2;
	if (v instanceof Vehicle) {
		vehicle2 = v;
	} else {
		vehicle2 = new Vehicle(v);
	}
	return (
		<Vehicle.Provider vehicle_id={vehicle2.vehicle_id}>{ vehicle =>
			<EasyFlex inline valign={EasyFlex.valign.CENTER} wrap={EasyFlex.wrap.YES}>
				<VehiclePopup vehicle={vehicle}>
					<RegistrationMark onClick={clickHandler(onClick, vehicle, 'registration_mark')} style={{transform: "scale(0.8)", transformOrigin: "left"}}>{vehicle.registration_mark}</RegistrationMark>
				</VehiclePopup>
				<VehiclePopup vehicle={vehicle}>
					<strong onClick={clickHandler(onClick, vehicle, 'chassis_number')} className={"monospace"} style={{marginRight: 25, marginTop:3, marginBottom: 3}}>{vehicle.chassis_number_icon} {vehicle.chassis_number}</strong>
				</VehiclePopup>
				<VehiclePopup vehicle={vehicle}>
					<strong onClick={clickHandler(onClick, vehicle, 'first_registration')} className={"monospace"} style={{marginRight: 25, marginTop:3, marginBottom: 3}}>{vehicle.first_registration_x || "n.v."}</strong>
				</VehiclePopup>
				<VehiclePopup vehicle={vehicle}>
					<strong onClick={clickHandler(onClick, vehicle, 'km')} className={"monospace"} style={{marginRight: 25, marginTop:3, marginBottom: 3}}>{vehicle.km_icon} {Number(vehicle.km).toLocaleString() || "n.v."} km</strong>
				</VehiclePopup>
			</EasyFlex>
		}</Vehicle.Provider>
	);
};


export default VehicleHeader;