import i18n from '../i18n'
import {get, mapValues} from 'lodash'
import {useSelector} from "react-redux";
import {MODULES} from "../Logic/constants";
import {useEffect} from "react";
import {subscribe} from "../Logic/PubSub";
import {User} from "../models/User";

/**
 *
 * @return {[function, object]}
 */
export const useTranslation = () => {
	const t = i18n.t.bind(i18n)
	return [t, i18n]
}
/**
 *
 * @return {{house_id: number, sse: boolean, ewo_tw: boolean, sse_base64: boolean, schedule_type: string, wheel_prepration_duration: number, post_process_duration: number, delivery_service_duration: number, direct_service_duration: number, prio_tuv: boolean, max_workers: number, link: string}}
 */
export const useHouse = () => {
	return useSelector(state => get(state, 'carhouse.own', {}))
}
/**
 *
 * @param {boolean} pure
 * @return {*|User|null}
 */
export const useUser = (pure = false) => {
	const userData = useSelector(state => get(state, 'user.data', null))
	return userData ? (pure ? userData : new User(userData)) : null
}
/**
 *
 * @param section
 * @return {[*|{mayCreate: boolean, mayDelete: boolean, mayGrant: boolean, mayRead: boolean, mayChange: boolean}, *, User|null]}
 */
export const useRights = (section = null) => {
	const user = useUser()
	return [
		section ? (user ? user.access(section) : User.noRights) : User.noRights,
		user.accessMap(),
		user
	]
}
/**
 *
 * @return {{loancar: boolean, wheel: boolean, assist: boolean, payment: boolean, basic: boolean}}
 */
export const useModules = () => {
	const modules = useSelector(state => get(state, 'modules.list', {}))
	return mapValues(MODULES, val => !!modules[val])
}
/**
 *
 * @param {string} flag
 * @param {function} callee
 */
export const useSubscription = (flag, callee) => {
	useEffect(() => {
		const listener = subscribe(flag, callee)
		return () => {
			listener && listener()
		}
	}, [flag, callee])
}
