import * as React from "react";
import PropTypes from "prop-types";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {isOnline} from "../../../actions/userActions";
import {orderAction__releasableList, orderAction__releaseList} from "../../../actions/orderActions";
import {PROC_ORDER_RELEASE, PROC_ORDER_RELEASE_LIST} from "../../../actions";
import {IconButton, RaisedButton} from "material-ui";
import {isLoading} from "../../../actions/loaderActions";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex} from "../../../components/partials/ActionHeader";
import {Button, Checkbox, Icon, Image, Popup, Segment, Table} from "semantic-ui-react";
import {IconKeyboardTab, IconMinus, IconPlus} from "../../../Logic/icons";
import {push} from "connected-react-router";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderQuickView from "../orders/OrderQuickView";
import {cloneDeep, get, isEmpty, isFunction, omit, uniq, values} from "lodash";
import {addSnackbar} from "../../../actions/snackbarActions";
import {Loadable, trueNull} from "../../../Logic/extensions";
import {Client, Order, OrderService, Service, Vehicle, Worker} from "../../../models";
import ClientPopup from "../../../components/intern/clients/ClientPopup";
import VehiclePopup from "../../../components/intern/vehicles/VehiclePopup";
import {RegistrationMark} from "../../../components/intern/vehicles";
import NoAccess from "../../../components/NoAccess";
import memoize from 'memoize-one'
import {logo_service} from "../../../Logic/helper";
import {LoadingSegment} from "../../../components/partials/MiniComponents";
import {filterData} from "./ReleaseMethods";
import {workerAction__collect} from "../../../actions/workerActions";
import {deepMemoize} from "../../../Logic/extensions";

const SORT_OPTIONS = [
	{key: "name-asc", order: "ascending", title: "Name aufsteigend"},
	{key: "name-desc", order: "descending", title: "Name absteigend"},
	{key: "create-asc", order: "ascending", title: "Erstellt aufsteigend"},
	{key: "create-desc", order: "descending", title: "Erstellt absteigend"},
	{key: "deliver-asc", order: "ascending", title: "Zustellung aufsteigend"},
	{key: "deliver-desc", order: "descending", title: "Zustellung absteigend"},
	{key: "fetch-asc", order: "ascending", title: "Abholung aufsteigend"},
	{key: "fetch-desc", order: "descending", title: "Abholung absteigend"}
];

const NoReleases = () => <Segment padding={"very"} basic><h3>Keine Aufträge zur Freigabe vorhanden</h3></Segment>;
const TableHeader = ({withInfo}) => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell>Kunde</Table.HeaderCell>
			<Table.HeaderCell textAlign={"center"}>Fahrzeug</Table.HeaderCell>
			<Table.HeaderCell textAlign={"center"}>
				<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
					<div style={{paddingLeft: 8, paddingRight: 8}}>Annahme</div>
					<div style={{paddingLeft: 8, paddingRight: 8, paddingTop: 6}}><IconKeyboardTab/></div>
					<div style={{paddingLeft: 8, paddingRight: 8}}>Abgabe</div>
				</EasyFlex>
			</Table.HeaderCell>
			{trueNull(withInfo) && <Table.HeaderCell>Info</Table.HeaderCell>}
			<Table.HeaderCell>&nbsp;</Table.HeaderCell>
			<Table.HeaderCell>&nbsp;</Table.HeaderCell>
			<Table.HeaderCell>&nbsp;</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);

const ReleaseView = ({id, onSelect, isSelected, writeStock, writeService}) => {
	return <Order.ReleaseProvider id={id}>{
		release => {
			return <EasyFlex>
				<EasyFlex style={{padding: 10}} valign={EasyFlex.valign.CENTER}>
					<label style={{paddingRight: 10, cursor: writeService ? "pointer" : "default"}} htmlFor={`service-${id}`}>Service</label>
					{release ? <Checkbox id={`service-${id}`} checked={isSelected(release, 'service')} disabled={!writeService} onChange={writeService ? onSelect(release, 'service') : null} readOnly={!writeService}/> :
						<Icon name={"spinner"} loading/>}
				</EasyFlex>
				<EasyFlex style={{padding: 10}} valign={EasyFlex.valign.CENTER}>
					{release ? <Checkbox id={`stock-${id}`} checked={isSelected(release, 'stock')} disabled={!writeStock} onChange={writeStock ? onSelect(release, 'stock') : null} readOnly={!writeStock}/> :
						<Icon name={"spinner"} loading/>}
					<label style={{paddingLeft: 10, cursor: writeStock ? "pointer" : "default"}} htmlFor={`stock-${id}`}>Lager</label>
				</EasyFlex>
			</EasyFlex>;
		}
	}</Order.ReleaseProvider>;
};

let ServiceView = ({release_list, onSelect, isSelected, writeService, writeStock}) => {
	return <Service.CollectProvider ids={release_list.map(r => r.service_id)}>{(services, complete) => {
		
		return <Segment basic loading={!complete} style={{minHeight: 50}}>
			<Table basic={"very"} compact fixed>
				<Table.Body>
					{services.map((service, index) =>
						<Table.Row key={release_list[index].order_service_id}>
							<Table.Cell>
								<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
									<EasyFlex grow={10} align={EasyFlex.align.START} valign={EasyFlex.valign.CENTER}>
										<div className={"square"} style={{
											background: `url("${logo_service(service.logo)}")`,
											borderRadius: '50%',
											backgroundSize: "contain",
											backgroundPosition: "center",
											backgroundRepeat: "no-repeat",
											width: 50,
											marginRight: 10
										}}/>
										<div style={{padding: 10, fontWeight: "bold"}}>{service.title}</div>
									</EasyFlex>
									<OrderService.Provider id={release_list[index].order_service_id}>{order_service =>
										<OrderService.Popup order_service={order_service}>
											<div style={{padding: 10}}>
												<Icon name={"info"} color={"grey"}/>
											</div>
										</OrderService.Popup>
									}</OrderService.Provider>
								</EasyFlex>
							</Table.Cell>
							<Table.Cell>
								<OrderService.Provider id={release_list[index].order_service_id}>{order_service => {
									return <span><strong>{order_service.hours_of_work_resource} {order_service.different_hours_of_work ? `(${order_service.hours_of_work})` : null}</strong> Std.</span>;
								}}</OrderService.Provider>
							</Table.Cell>
							<Table.Cell textAlign={"right"}>
								<ReleaseView writeService={writeService} writeStock={writeStock} isSelected={isSelected} onSelect={onSelect} id={release_list[index].order_service_id}/>
							</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>
			</Table>
		</Segment>;
		
	}}</Service.CollectProvider>
};
// ServiceView = connect()(ServiceView);

const Container = ({loading, children}) => (
	<Segment basic>
		<ActionHeader alignment={"space-between"}>
			<ActionHeaderGroup>
				<ActionBackButton loading={loading}/>
				<ActionHeading>Freigabe Aufträge</ActionHeading>
			</ActionHeaderGroup>
		</ActionHeader>
		{children()}
	</Segment>
);

class ReleaseMain extends React.Component {
	static propTypes = {
		orders: PropTypes.object,
		loading: PropTypes.bool,
		goTo: PropTypes.func,
		onRefresh: PropTypes.func,
		allRights: PropTypes.object,
		onRelease: PropTypes.func.isRequired,
		isReleasing: PropTypes.bool,
		releaseMap: PropTypes.object
	};
	static defaultProps = {
		orders: null,
		loading: false,
		isReleasing: false,
		releases: {}
	};
	
	state = {
		showOrderID: 0,
		releaseSelections: {},
		sort: SORT_OPTIONS[2].key,
		detail: {}
	};
	
	toggleDetail = order => () => {
		this.setState(state => {
			const id = order.order_id || order;
			return {
				...state,
				detail: {
					...state.detail,
					[id]: !state.detail[id]
				}
			};
		});
	}
	
	showOrder = (showOrderID) => () => {
		this.setState({showOrderID});
	}
	
	
	toggleRelease = (releaseSource, entity) => () => {
		this.setState(state => {
			const releaseCmp = this.props.releases[releaseSource.order_service_id];
			let release;
			if (releaseSource.order_service_id in state.releaseSelections) {
				release = cloneDeep(state.releaseSelections[releaseSource.order_service_id]);
				release[entity] = !release[entity];
			} else {
				release = {
					...releaseSource,
					[entity]: !releaseSource[entity]
				};
			}
			if ((!!release.stock) === (!!releaseCmp.stock) && (!!release.service) === (!!releaseCmp.service)) {
				return {
					...state,
					releaseSelections: omit(state.releaseSelections, [release.order_service_id])
				};
			}
			return {
				...state,
				releaseSelections: {
					...state.releaseSelections,
					[release.order_service_id]: release
				}
			};
		});
	}
	
	
	handleOrderClick = (order_id) => {
		const orderRights = this.props.allRights[SECTION.ORDERS];
		if (orderRights.mayRead) {
			return e => {
				e && e.preventDefault();
				e && e.stopPropagation();
				this.showOrder(order_id ? Number(order_id) : order_id)();
			}
			
		}
	};
	
	
	sorted = () => {
		return (a, b) => {
			const extension = Number(!!b.services.find(s => !!s.extension)) - Number(!!a.services.find(s => !!s.extension));
			if (!extension) {
				return a.order.deliver_moment.unix() - b.order.deliver_moment.unix();
			}
			return extension;
			
		};
	};
	
	memSelected = memoize(
		(selections, release, entity) => !!get(selections, [release.order_service_id, entity], get(release, entity, false))
	);
	
	handleRelease = () => {
		this.props.onRelease(values(this.state.releaseSelections), () => this.setState({releaseSelections: {}}));
	};
	
	
	
	uniqOrderIds = memoize(
		list => uniq(list.map(data => data.order_id))
	);
	
	serviceIds = memoize(
		(list, order_id) => list.filter(data => "order_id" in data && data.order_id === order_id)
	);
	
	filterData = memoize(filterData)
	
	get filteredData() {
		const {release_list, releases, rights:{mayChange: mayService}, allRights: {[SECTION.STOCK]: {mayChange: mayStock}}} = this.props
		return this.filterData(release_list, releases, mayService, mayStock).data_list
	}
	
	prepareData = memoize((data_list, orders, order2services, clients, order2vehicles) => {
		const order_ids = this.uniqOrderIds(data_list);
		let order_list = order_ids.map(id => orders[id] || null);
		
		if (order_list.includes(null)) {
			return [null, false];
		}
		const hasInfo = order_list.reduce((found, order) => found || Boolean(get(order, 'info', '').trim()), false);
		let consultants = {};
		// const orderMap = array2object(order_list)('order_id');
		let list = [];
		for (const ord of order_list) {
			const order = new Order(ord);
			if (order.consultant_id) {
				consultants[order.consultant_id] = order.consultant_id;
			}
			const orderVehicle = order2vehicles[order.order_vehicle_id];
			if (!orderVehicle) {
				return [null, false];
			}
			let client = clients[orderVehicle.client_id];
			if (!client) {
				return [null, false];}
			client = new Client(client);
			let services = this.serviceIds(data_list, order.id).map(item => order2services[item.order_service_id] || null);
			if (services.includes(null)) {
				return [null, false];
			}
			
			list.push({
				order,
				orderVehicle,
				client,
				services
			});
		}
		return [list, hasInfo, Object.values(consultants)];
	})
	
	get preparedData() {
		const {
			orders,
			// vehicles,
			order2services,
			clients,
			order2vehicles,
		} = this.props;
		const data_list = this.filteredData;
		return this.prepareData(data_list, orders, order2services, clients, order2vehicles)
	};
	
	provideWorkers = deepMemoize(consultants => this.props.provideWorkers(consultants))
	
	componentDidMount() {
		const [,,consultants] = this.preparedData
		this.provideWorkers(consultants)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const[,,consultants] = this.preparedData
		this.provideWorkers(consultants)
	}
	
	render() {
		const {
			loading,
			isReleasing,
			rights: {mayRead, mayChange},
			allRights,
			release_list,
		} = this.props;
		
		const {showOrderID, releaseSelections, detail} = this.state;
		
		return (
			<React.Fragment>
				<Container loading={loading}>{() => {
					const {mayRead: stockRead, mayChange: stockChange} = allRights[SECTION.STOCK];
					if (!mayRead && !stockRead) {
						return <NoAccess/>;
					}
					const [list, hasInfo] = this.preparedData;
					
					// this.props.provideWorkers(consultants);
					if (null === list) {
						return <LoadingSegment/>;
					}
					if (!list.length) {
						return <NoReleases/>;
					}
					return (
						<Table stackable celled>
							<TableHeader withInfo={hasInfo}/>
							<Table.Body>
								{list.sort(this.sorted()).map(({order, client, orderVehicle, services}) => {
									const showDetail = Boolean(detail[order.order_id]);
									return (
										<React.Fragment key={order.id}>
											<Table.Row>
												<Table.Cell>
													<ClientPopup modal client={client.origin} popupProps={{style: {backgroundColor: 'transparent'}}}><EasyFlex valign={EasyFlex.valign.CENTER}>{client.icon} {client.name}</EasyFlex></ClientPopup>
												</Table.Cell>
												<Table.Cell textAlign={"center"}>
													<Vehicle.Provider vehicle_id={Number(orderVehicle.vehicle_id)}
													                  placeholder={<RegistrationMark>{orderVehicle.registration_mark}</RegistrationMark>}>{vehicle =>
														<VehiclePopup modal vehicle={vehicle.origin}>
															{vehicle.registration_shield}
														</VehiclePopup>
														
													}</Vehicle.Provider>
												</Table.Cell>
												<Table.Cell textAlign={"center"}>
													<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
														<div style={{paddingLeft: 8, paddingRight: 8}}>
															{order.deliver_moment.format("L [-] LT")}
														</div>
														<div style={{paddingLeft: 8, paddingRight: 8, paddingTop: 6}}>
															<IconKeyboardTab/>
														</div>
														<div style={{paddingLeft: 8, paddingRight: 8}}>
															{order.fetch_moment.format("L [-] LT")}
														</div>
													</EasyFlex>
													
													
													
												</Table.Cell>
												{trueNull(hasInfo) && <Table.Cell>{order.info}</Table.Cell>}
												<Table.Cell textAlign={'center'} style={{width: 50}}>
													
													{trueNull(order.consultant_id) &&
													<Worker.Connected id={order.consultant_id} placeholder={<span>FUFU</span>}>{worker =>
														<Popup position={'left center'} inverted content={`Serviceberater: ${worker.name}`} trigger={
															<Image wrapped src={worker.avatar} avatar/>
														}/>
													}</Worker.Connected>}
												</Table.Cell>
												<Table.Cell textAlign={"center"} style={{width: 50}}>
													<Button
														onClick={this.handleOrderClick(order.order_id)}
														size={"small"}
														icon={"list"}
														circular>
														{/*{order.order_id}*/}
													</Button>
												</Table.Cell>
												<Table.Cell textAlign={"center"}>
													<IconButton onClick={this.toggleDetail(order)}>{showDetail ? <IconMinus/> : <IconPlus/>}</IconButton>
												</Table.Cell>
											</Table.Row>
											{trueNull(showDetail) &&
											<Table.Row style={{background: '#f5f5f5'}}>
												<Table.Cell colSpan={6}>
													<ServiceView writeService={mayChange} writeStock={stockChange} isSelected={this.memSelected.bind(null, this.state.releaseSelections)}
													             onSelect={this.toggleRelease}
													             release_list={this.serviceIds(release_list, order.order_id)}/>
												</Table.Cell>
											</Table.Row>
											}
										</React.Fragment>
									);
								})}
							</Table.Body>
						</Table>
					);
				}}</Container>
				
				{/*Submit Button*/}
				{isEmpty(releaseSelections) ? null : [
					<div key={"bottom-padding"} style={{height: 30}}/>,
					< div key={"save-selection-button"} style={{position: "fixed", bottom: 0, zIndex: 10, left: "50%", transform: "translateX(-50%)"}}>
						<Loadable inverse hoc={RaisedButton} loading={isReleasing} disabledOnLoading primary onClick={this.handleRelease}><span style={{marginLeft: 5, marginRight: 5}}>Auswahl freigeben</span></Loadable>
					</div>
				]}
				{/*order layer view*/}
				<OrderViewDialog open={showOrderID > 0} onClose={this.showOrder(0)}>
					<OrderQuickView order_id={showOrderID} onRequestClose={this.showOrder(0)}/>
				</OrderViewDialog>
			</React.Fragment>
		);
	}
}

ReleaseMain = withInit(ReleaseMain);
ReleaseMain = withRights(SECTION.RELEASE, ReleaseMain);
ReleaseMain = connect(
	(state) => ({
		release_list: state.orders.release_data,
		orders: state.map.orders,
		order2vehicles: state.map.order2vehicles,
		order2services: state.map.order2services,
		vehicles: state.map.vehicles,
		clients: state.map.clients,
		releases: state.map.releases,
		loading: isLoading(state, PROC_ORDER_RELEASE),
		isReleasing: isLoading(state, PROC_ORDER_RELEASE_LIST),
		// releaseMap: state.orders.releases
	}),
	(dispatch) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__releasableList(true));
		})),
		onRefresh: (onSuccess) => {
		},
		goTo: (to) => dispatch(push(to)),
		onRelease: (list, onSuccess) => dispatch(orderAction__releaseList(list, result => {
			dispatch(addSnackbar("Auswahl wurde freigegeben"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		provideWorkers: (list) => dispatch(workerAction__collect(list))
		// onRelease: (list, s) => dispatch(addSnackbar("To be implemented", "alert"))
	})
)(ReleaseMain);

export default ReleaseMain;