import * as React from 'react';
import PropTypes from 'prop-types';
import NotFound from "../../../components/NotFound";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import FullScreen from "../../../Tools/FullScreen";
import {Flex, ResponsiveFlexChild} from "../../../components/partials/ActionHeader";
import {Segment} from "semantic-ui-react";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {kfzAction__fetchModelsOf} from "../../../actions/kfzActions";
import values from 'lodash/values';
import {isLoading} from "../../../actions/loaderActions";
import {PROC_KFZ_FETCH} from "../../../actions";
import VirtualList from 'react-tiny-virtual-list';
import {globals} from 'ah-tools';
import Search from '../../../models/Search';
import {SCREEN} from "../../../Logic/constants";
import {ScreenContext} from "../../../Logic/extensions";

class ModelSearch extends React.Component {
	static propTypes = {
		kfz_id: PropTypes.number.isRequired,
		models: PropTypes.object,
		onHide: PropTypes.func,
		isLoading: PropTypes.bool,
		onSelect: PropTypes.func.isRequired,
		directSelect: PropTypes.bool
	};
	static defaultProps = {
		models: {},
		isLoading: false,
		directSelect: false,
		onSelect: () => alert('onSelect() is not implemented yet!')
	};
	
	state = {
		size: {width: 0, height: 0},
		selected: 0,
		searchText: ''
	};
	element = null;
	
	componentDidMount() {
		window.setTimeout(() => this.handleElement(), 100);
		window.addEventListener('resize', this.handleElement, true);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleElement);
	}
	
	handleSelect = index => () => {
		const {onSelect, kfz_id, models, onHide} = this.props;
		const model = models[kfz_id][index];
		onSelect(model);
		onHide && onHide();
	};
	
	handleElement = () => {
		if(this.element) {
			this.setState({size: {
				width: this.element.clientWidth,
				height: this.element.clientHeight
			}});
		}
	};
	
	setSearch = e => this.setState({searchText: e.target.value});
	
	select = selected => () => this.props.directSelect ? this.handleSelect(selected)() : this.setState(state => ({
		selected: state.selected === selected ? 0 : selected
	}));
	
	
	search = list => {
		const {searchText: token} = this.state;
		const tokens = token.split(/\s+/).map(t => t.toLowerCase()).filter(t => t !== '');
		if ( !tokens.length ) return list;
		const search = Search._(tokens);
		list = list.map(m => {
			search.setBoost(1).setFuzzy(false).setTokenMatch(false).setOperatorAnd(false);
			m.hits = 0;
			m.hits += search.setHaystack(m.kw).execute();
			m.hits += search.setHaystack(m.ps).execute();
			m.hits += search.setHaystack(m.ccm).execute();
			m.hits += search.setHaystack(m.fromYear).execute();
			m.hits += search.setHaystack(m.hsn).setBoost(2).execute();
			m.hits += search.setHaystack(m.tsn).setBoost(2).execute();
			m.hits += search.setHaystack(`${m.hsn}/${m.tsn}`).setBoost(1).setFuzzy().execute();
			m.hits += search.setHaystack(m.type).setBoost(4).setFuzzy().execute();
			return m;
		});
		list = list.filter(m => m.hits !== 0);
		list = list.sort((a,b) => b.hits - a.hits);
		return list;
	};
	
	
	render() {
		const {t, kfz_id, onHide, models: modelBase, isLoading} = this.props;
		const {size, searchText} = this.state;
		if (kfz_id <= 0 ) {
			return (
				<FullScreen>
					<Flex direction={'column'}>
						<Flex grow={100} align={'center'} valign={'center'}>
							<NotFound/>
						</Flex>
						{onHide && <Flex align={'center'}><button onClick={onHide} className={'fs-close'}>{t('actions.close')}</button></Flex>}
					</Flex>
				</FullScreen>
			);
		}
		const modelsFull = modelBase[kfz_id] ? values(modelBase[kfz_id]) : [];
		
		const models = this.search([...modelsFull]);
		
		
		
		return (
			<FullScreen>
				<Flex style={{height: '100vh'}} direction={'column'}>
					<Flex align={'center'} shrink={0}>
							<span className={'fs-search'}>
								<input ref={ref => {
									if (ref) {
										if (!this.inputRef) {
											ref.select();
										}
										this.inputRef = ref;
										
									}
								}} onChange={this.setSearch} value={searchText} placeholder={t('labels.search-vehicle-type')} type="search" style={{border: '1px solid white', padding: 14, fontSize: 16, outline: 0, margin: 0}}/>
							</span>
					</Flex>
					<Flex grow={100} >
						<ScreenContext>
							<Segment basic style={{width: '100%',margin: 0, padding: 0}} loading={isLoading}>
								<div style={{height: '100%', display: 'flex', alignItems: 'stretch'}} ref={el => {
									if (el && !this.element) {
										this.element = el;
										this.handleElement();
									}
								}}>
									<VirtualList
										width={size.width}
										height={size.height}
										itemCount={models.length}
										itemSize={this.listHeight(size)}
										renderItem={({index, style}) => {
											const model = models[index];
											const selected = this.state.selected === model.model_id;
											const highlight = index % 2 === 1;
											return (
												<Flex wrap={'wrap'} className={'click pointer'} gutterWidth={20} onClick={this.select(model.model_id)} key={model.model_id} align={'flex-start'} valign={'center'} style={{...style, paddingLeft: 20, paddingRight: 10, background: selected ? globals.COLOR_PRIMARY : (highlight ? '#e8e8e8' : null)}}>
													<ResponsiveFlexChild ellipsis width={9/9} widthELG={4/9} widthLG={4/9} widthMD={3/9} widthSM={5/9} widthXS={9/9}>{model.type}</ResponsiveFlexChild>
													<ResponsiveFlexChild ellipsis  widthELG={1/9} widthLG={1/9} widthMD={1/9} widthSM={2/9} hiddenXS hiddenXXS>{model.fuel}</ResponsiveFlexChild>
													<ResponsiveFlexChild ellipsis width={3/9} widthELG={1/9} widthLG={1/9} widthMD={1/9} widthSM={2/9} widthXS={3/9}>{model.hsn} / {model.tsn}</ResponsiveFlexChild>
													<ResponsiveFlexChild className={'hidden-field'} hiddenELG hiddenLG hiddenMD hiddenSM={false} widthSM={3/9} hiddenXS hiddenXXS>&nbsp;</ResponsiveFlexChild>
													<ResponsiveFlexChild ellipsis width={3/9} widthELG={1/9} widthLG={1/9} widthMD={2/9} widthSM={2/9} widthXS={3/9}>{model.kw} KW / {model.ps} PS</ResponsiveFlexChild>
													<ResponsiveFlexChild ellipsis widthELG={1/9} widthLG={1/9} widthMD={1/9} widthSM={2/9} hiddenXS hiddenXXS>{model.fromYear} - {model.tillYear}</ResponsiveFlexChild>
													<ResponsiveFlexChild ellipsis width={3/9} widthELG={1/9} widthLG={1/9} widthMD={1/9} widthSM={2/9} widthXS={3/9}>{model.ccm} CCM</ResponsiveFlexChild>
												</Flex>
											);
										}}
									/>
								</div>
							</Segment>
						</ScreenContext>
					</Flex>
					{onHide && <Flex shrink={0} align={'center'} >
						{this.state.selected ? <button onClick={this.handleSelect(this.state.selected)} className={'fs-close'}>{t('actions.select')}</button> : null}
						<button onClick={onHide} className={'fs-close'}>{t('actions.close')}</button>
					</Flex>}
				</Flex>
			</FullScreen>
		);
	}
	
	listHeight = (size) => {
		if (size.width >= SCREEN.MD) return 50;
		return 75;
	}
}

ModelSearch = translate()(ModelSearch);


const m2s = (state, props) => {
	return {
		models: state.kfz.models,
		isLoading: isLoading(state, PROC_KFZ_FETCH, props.kfz_id)
	};
};

const m2d = (dispatch, props) => {
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(kfzAction__fetchModelsOf(props.kfz_id))
		}))
	};
};

export default connect(m2s, m2d)(withInit(ModelSearch));