import Snackbar from "../Tools/Snackbar";
import {hideSnackbar} from "../actions/snackbarActions";
import {connect} from "react-redux";

const m2s = state => ({
	list: state.snackbar
});

const m2d = dispatch => ({
	onRequestClose: message => dispatch(hideSnackbar(message))
});

export default connect(m2s, m2d)(Snackbar);