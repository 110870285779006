/* eslint-disable */
import * as React from "react";
import {GET} from "ith-fetch";
import {Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {Client, Order, User} from "../../models";
import {moment} from "../../Logic/Moment";

// eslint-disable-next-line
const data = {
	"debug": {
		"date": "2019-12-13T00:00:00+01:00",
		"startDate": "2019-12-13T00:00:00+01:00",
		"endDate": "2019-12-13T23:59:59+01:00",
		"houseId": 8,
		"grouped": true,
		"workerId": 30,
		"fromNow": false,
		"attempts": 3,
		"result": null,
		"stickOrdersTogether": true,
		"assignByWorkedGenerally": false
	},
	"from": "2019-12-13T00:00:00+01:00",
	"to": "2019-12-13T23:59:59+01:00",
	"paused": {
		"30": [{
			"order_id": 2077,
			"house_id": 8,
			"deliver_point": "2019-12-13T08:30:00+01:00",
			"fetch_point": "2019-12-13T10:00:00+01:00",
			"latest_point": "2019-12-13T08:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-13T08:29:27+01:00",
			"released_at": "2019-12-09T11:13:30+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 163,
			"vehicle_id": 306,
			"chassis_number": "WF0NXXGCHNJP23720",
			"registration_mark": "AC-RV 808",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford Focus 2019 (01\/2018-)",
			"paused_by": 30,
			"service_id": 101308,
			"company_name": "",
			"first_name": "",
			"last_name": "Rheinischer Verein",
			"salutation": "him",
			"order_service_resource_id": 4320,
			"order_service_id": 3353,
			"resource_id": 11,
			"workers_id": 30,
			"hours_of_work": 1.2,
			"start_point": "2019-12-13T08:30:00+01:00",
			"end_point": "2019-12-13T09:42:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"29": [{
			"order_id": 2093,
			"house_id": 8,
			"deliver_point": "2019-12-12T09:00:00+01:00",
			"fetch_point": "2019-12-16T15:00:00+01:00",
			"latest_point": "2019-12-16T03:30:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-11T17:59:06+01:00",
			"released_at": "2019-12-10T08:53:55+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 3766,
			"vehicle_id": 10113,
			"chassis_number": "WME4533911K314226",
			"registration_mark": "MO-E 6066E",
			"hsn": "1313",
			"tsn": "HSF",
			"vehicle_name": "Smart EQ Fortwo Coupe",
			"paused_by": 29,
			"service_id": 101312,
			"company_name": "",
			"first_name": "Dirk",
			"last_name": "Kettner",
			"salutation": "him",
			"order_service_resource_id": 4339,
			"order_service_id": 3365,
			"resource_id": 11,
			"workers_id": 29,
			"hours_of_work": 6.5,
			"start_point": "2019-12-13T16:48:00+01:00",
			"end_point": "2019-12-13T17:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}]
	},
	"orderlist": {
		"2058": [{
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4192,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-10T17:00:00+01:00",
			"end_point": "2019-12-10T22:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 57,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4195,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-11T13:00:00+01:00",
			"end_point": "2019-12-11T13:36:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4193,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-11T07:00:00+01:00",
			"end_point": "2019-12-11T08:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4198,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-12T13:00:00+01:00",
			"end_point": "2019-12-12T15:12:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4194,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-11T09:00:00+01:00",
			"end_point": "2019-12-11T11:48:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4192,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-10T17:00:00+01:00",
			"end_point": "2019-12-10T22:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4197,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-12T07:00:00+01:00",
			"end_point": "2019-12-12T12:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 107,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4196,
			"order_service_id": 3258,
			"resource_id": 16,
			"workers_id": 57,
			"hours_of_work": 24,
			"start_point": "2019-12-11T14:36:00+01:00",
			"end_point": "2019-12-11T22:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 56,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4325,
			"order_service_id": 3358,
			"resource_id": 21,
			"workers_id": 29,
			"hours_of_work": 1.1,
			"start_point": "2019-12-13T15:42:00+01:00",
			"end_point": "2019-12-13T16:48:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 101262,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4211,
			"order_service_id": 3267,
			"resource_id": 15,
			"workers_id": 29,
			"hours_of_work": 4,
			"start_point": "2019-12-13T13:00:00+01:00",
			"end_point": "2019-12-13T14:12:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4324,
			"order_service_id": 3357,
			"resource_id": 21,
			"workers_id": 29,
			"hours_of_work": 1.5,
			"start_point": "2019-12-13T14:12:00+01:00",
			"end_point": "2019-12-13T15:42:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2058,
			"house_id": 8,
			"deliver_point": "2019-12-10T11:12:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-12T00:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T10:19:34+01:00",
			"released_at": "2019-12-06T15:04:24+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 741,
			"vehicle_id": 1470,
			"chassis_number": "WF0JXXWPBJES82193",
			"registration_mark": "KLE-M 1185",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
			"paused_by": null,
			"service_id": 101262,
			"company_name": "",
			"first_name": "Jens",
			"last_name": "Mevissen",
			"salutation": "him",
			"order_service_resource_id": 4210,
			"order_service_id": 3267,
			"resource_id": 15,
			"workers_id": 29,
			"hours_of_work": 4,
			"start_point": "2019-12-13T09:12:00+01:00",
			"end_point": "2019-12-13T12:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2026": [{
			"order_id": 2026,
			"house_id": 8,
			"deliver_point": "2019-12-13T08:00:00+01:00",
			"fetch_point": "2019-12-13T09:30:00+01:00",
			"latest_point": "2019-12-13T08:00:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-13T08:06:12+01:00",
			"released_at": "2019-12-04T10:05:00+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 5725,
			"vehicle_id": 7082,
			"chassis_number": "WME4514011K735063",
			"registration_mark": "EL-PM 691",
			"hsn": "0999",
			"tsn": "BAI",
			"vehicle_name": "Smart FORTWO CDI 40 KW",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "",
			"last_name": "Hocke",
			"salutation": "him",
			"order_service_resource_id": 4094,
			"order_service_id": 3209,
			"resource_id": 21,
			"workers_id": 29,
			"hours_of_work": 1.5,
			"start_point": "2019-12-13T08:00:00+01:00",
			"end_point": "2019-12-13T09:30:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 29,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2026,
			"house_id": 8,
			"deliver_point": "2019-12-13T08:00:00+01:00",
			"fetch_point": "2019-12-13T09:30:00+01:00",
			"latest_point": "2019-12-13T08:00:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-13T08:06:12+01:00",
			"released_at": "2019-12-04T10:05:00+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 5725,
			"vehicle_id": 7082,
			"chassis_number": "WME4514011K735063",
			"registration_mark": "EL-PM 691",
			"hsn": "0999",
			"tsn": "BAI",
			"vehicle_name": "Smart FORTWO CDI 40 KW",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "",
			"last_name": "Hocke",
			"salutation": "him",
			"order_service_resource_id": 4094,
			"order_service_id": 3209,
			"resource_id": 21,
			"workers_id": 27,
			"hours_of_work": 1.5,
			"start_point": "2019-12-13T08:00:00+01:00",
			"end_point": "2019-12-13T09:30:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2116": [{
			"order_id": 2116,
			"house_id": 8,
			"deliver_point": "2019-12-12T16:36:02+01:00",
			"fetch_point": "2019-12-13T11:54:02+01:00",
			"latest_point": "2019-12-13T07:36:02+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:34:03+01:00",
			"released_at": "2019-12-12T14:34:02+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 501,
			"vehicle_id": 10165,
			"chassis_number": "WF0JXXGAHJKK03842",
			"registration_mark": "95212 \/ 25",
			"hsn": "0928",
			"tsn": "476",
			"vehicle_name": "Ford Fiesta",
			"paused_by": null,
			"service_id": 93,
			"company_name": "",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "him",
			"order_service_resource_id": 4352,
			"order_service_id": 3371,
			"resource_id": 22,
			"workers_id": 39,
			"hours_of_work": 3.5,
			"start_point": "2019-12-13T08:24:02+01:00",
			"end_point": "2019-12-13T11:54:02+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 39,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2116,
			"house_id": 8,
			"deliver_point": "2019-12-12T16:36:02+01:00",
			"fetch_point": "2019-12-13T11:54:02+01:00",
			"latest_point": "2019-12-13T07:36:02+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:34:03+01:00",
			"released_at": "2019-12-12T14:34:02+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 501,
			"vehicle_id": 10165,
			"chassis_number": "WF0JXXGAHJKK03842",
			"registration_mark": "95212 \/ 25",
			"hsn": "0928",
			"tsn": "476",
			"vehicle_name": "Ford Fiesta",
			"paused_by": null,
			"service_id": 93,
			"company_name": "",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "him",
			"order_service_resource_id": 4352,
			"order_service_id": 3371,
			"resource_id": 22,
			"workers_id": 39,
			"hours_of_work": 3.5,
			"start_point": "2019-12-13T08:24:02+01:00",
			"end_point": "2019-12-13T11:54:02+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2113": [{
			"order_id": 2113,
			"house_id": 8,
			"deliver_point": "2019-12-12T13:32:00+01:00",
			"fetch_point": "2019-12-13T10:48:00+01:00",
			"latest_point": "2019-12-13T06:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:26:32+01:00",
			"released_at": "2019-12-12T14:26:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 3247,
			"vehicle_id": 3408,
			"chassis_number": "WME4530421Y163108",
			"registration_mark": "95239 \/ 31",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
			"paused_by": null,
			"service_id": 88,
			"company_name": "Herbrand art mobil GmbH",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "company",
			"order_service_resource_id": 4345,
			"order_service_id": 3366,
			"resource_id": 21,
			"workers_id": 30,
			"hours_of_work": 1.1,
			"start_point": "2019-12-13T09:42:00+01:00",
			"end_point": "2019-12-13T10:48:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 30,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2113,
			"house_id": 8,
			"deliver_point": "2019-12-12T13:32:00+01:00",
			"fetch_point": "2019-12-13T10:48:00+01:00",
			"latest_point": "2019-12-13T06:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:26:32+01:00",
			"released_at": "2019-12-12T14:26:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 3247,
			"vehicle_id": 3408,
			"chassis_number": "WME4530421Y163108",
			"registration_mark": "95239 \/ 31",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
			"paused_by": null,
			"service_id": 93,
			"company_name": "Herbrand art mobil GmbH",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "company",
			"order_service_resource_id": 4346,
			"order_service_id": 3367,
			"resource_id": 22,
			"workers_id": 39,
			"hours_of_work": 3.5,
			"start_point": "2019-12-12T13:32:00+01:00",
			"end_point": "2019-12-12T17:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2113,
			"house_id": 8,
			"deliver_point": "2019-12-12T13:32:00+01:00",
			"fetch_point": "2019-12-13T10:48:00+01:00",
			"latest_point": "2019-12-13T06:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:26:32+01:00",
			"released_at": "2019-12-12T14:26:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 3247,
			"vehicle_id": 3408,
			"chassis_number": "WME4530421Y163108",
			"registration_mark": "95239 \/ 31",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
			"paused_by": null,
			"service_id": 93,
			"company_name": "Herbrand art mobil GmbH",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "company",
			"order_service_resource_id": 4347,
			"order_service_id": 3367,
			"resource_id": 22,
			"workers_id": 39,
			"hours_of_work": 3.5,
			"start_point": "2019-12-13T08:00:00+01:00",
			"end_point": "2019-12-13T08:02:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2113,
			"house_id": 8,
			"deliver_point": "2019-12-12T13:32:00+01:00",
			"fetch_point": "2019-12-13T10:48:00+01:00",
			"latest_point": "2019-12-13T06:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T14:26:32+01:00",
			"released_at": "2019-12-12T14:26:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 3247,
			"vehicle_id": 3408,
			"chassis_number": "WME4530421Y163108",
			"registration_mark": "95239 \/ 31",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
			"paused_by": null,
			"service_id": 88,
			"company_name": "Herbrand art mobil GmbH",
			"first_name": "",
			"last_name": "Herbrand art mobil GmbH",
			"salutation": "company",
			"order_service_resource_id": 4345,
			"order_service_id": 3366,
			"resource_id": 21,
			"workers_id": 30,
			"hours_of_work": 1.1,
			"start_point": "2019-12-13T09:42:00+01:00",
			"end_point": "2019-12-13T10:48:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2100": [{
			"order_id": 2100,
			"house_id": 8,
			"deliver_point": "2019-12-11T11:00:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T14:30:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-11T10:21:40+01:00",
			"released_at": "2019-12-11T10:21:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 2605,
			"vehicle_id": 3640,
			"chassis_number": "WF0YXXTTGYEU40550",
			"registration_mark": "KLE-TB 589",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford TRANSIT CUSTOM 2012 (04\/2012-)",
			"paused_by": null,
			"service_id": 101310,
			"company_name": "",
			"first_name": "",
			"last_name": "Thesing Bedachung GbR",
			"salutation": "him",
			"order_service_resource_id": 4329,
			"order_service_id": 3361,
			"resource_id": 17,
			"workers_id": 27,
			"hours_of_work": 1.5,
			"start_point": "2019-12-12T10:37:22+01:00",
			"end_point": "2019-12-12T12:07:22+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 27,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2100,
			"house_id": 8,
			"deliver_point": "2019-12-11T11:00:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T14:30:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-11T10:21:40+01:00",
			"released_at": "2019-12-11T10:21:29+01:00",
			"fetched_at": null,
			"progress": 1,
			"client_id": 2605,
			"vehicle_id": 3640,
			"chassis_number": "WF0YXXTTGYEU40550",
			"registration_mark": "KLE-TB 589",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford TRANSIT CUSTOM 2012 (04\/2012-)",
			"paused_by": null,
			"service_id": 101310,
			"company_name": "",
			"first_name": "",
			"last_name": "Thesing Bedachung GbR",
			"salutation": "him",
			"order_service_resource_id": 4329,
			"order_service_id": 3361,
			"resource_id": 17,
			"workers_id": 27,
			"hours_of_work": 1.5,
			"start_point": "2019-12-12T10:37:22+01:00",
			"end_point": "2019-12-12T12:07:22+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": true,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2077": [{
			"order_id": 2077,
			"house_id": 8,
			"deliver_point": "2019-12-13T08:30:00+01:00",
			"fetch_point": "2019-12-13T10:00:00+01:00",
			"latest_point": "2019-12-13T08:12:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-13T08:29:27+01:00",
			"released_at": "2019-12-09T11:13:30+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 163,
			"vehicle_id": 306,
			"chassis_number": "WF0NXXGCHNJP23720",
			"registration_mark": "AC-RV 808",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford Focus 2019 (01\/2018-)",
			"paused_by": 30,
			"service_id": 101308,
			"company_name": "",
			"first_name": "",
			"last_name": "Rheinischer Verein",
			"salutation": "him",
			"order_service_resource_id": 4320,
			"order_service_id": 3353,
			"resource_id": 11,
			"workers_id": 30,
			"hours_of_work": 1.2,
			"start_point": "2019-12-13T08:30:00+01:00",
			"end_point": "2019-12-13T09:42:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"1936": [{
			"order_id": 1936,
			"house_id": 8,
			"deliver_point": "2019-12-13T14:15:00+01:00",
			"fetch_point": "2019-12-13T15:45:00+01:00",
			"latest_point": "2019-12-13T12:45:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": null,
			"released_at": "2019-11-26T10:31:09+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 1391,
			"vehicle_id": 2241,
			"chassis_number": "WME4533421K177716",
			"registration_mark": "KLE-H 8077",
			"hsn": "0999",
			"tsn": "BAI",
			"vehicle_name": "Daimler fortwo coupe 52kw",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "Volker",
			"last_name": "Horn",
			"salutation": "him",
			"order_service_resource_id": 3874,
			"order_service_id": 3042,
			"resource_id": 21,
			"workers_id": 27,
			"hours_of_work": 1.5,
			"start_point": "2019-12-13T14:15:00+01:00",
			"end_point": "2019-12-13T15:45:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2065": [{
			"order_id": 2065,
			"house_id": 8,
			"deliver_point": "2019-12-13T13:00:00+01:00",
			"fetch_point": "2019-12-13T13:36:00+01:00",
			"latest_point": "2019-12-13T13:00:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": null,
			"released_at": "2019-12-06T16:46:08+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 5670,
			"vehicle_id": 7022,
			"chassis_number": "WF01XXTTG1HU00206",
			"registration_mark": "D-WE 11",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford TRANSIT CUSTOM 2012 (04\/2012-)",
			"paused_by": null,
			"service_id": 35,
			"company_name": "",
			"first_name": "Manfred",
			"last_name": "Weiss",
			"salutation": "him",
			"order_service_resource_id": 4214,
			"order_service_id": 3270,
			"resource_id": 21,
			"workers_id": 30,
			"hours_of_work": 0.6,
			"start_point": "2019-12-13T13:00:00+01:00",
			"end_point": "2019-12-13T13:36:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2085": [{
			"order_id": 2085,
			"house_id": 8,
			"deliver_point": "2019-12-13T09:00:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T13:48:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T18:24:12+01:00",
			"released_at": "2019-12-09T14:56:35+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 4408,
			"vehicle_id": 9962,
			"chassis_number": "WME4534911K402302",
			"registration_mark": "BOH-PM 90E",
			"hsn": "1313",
			"tsn": "GOO",
			"vehicle_name": "Smart Fortwo Coupe ED",
			"paused_by": null,
			"service_id": 101285,
			"company_name": "",
			"first_name": "",
			"last_name": "Pflege und Mehr GmbH",
			"salutation": "him",
			"order_service_resource_id": 4251,
			"order_service_id": 3298,
			"resource_id": 17,
			"workers_id": 27,
			"hours_of_work": 0.5,
			"start_point": "2019-12-13T09:42:00+01:00",
			"end_point": "2019-12-13T10:12:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2085,
			"house_id": 8,
			"deliver_point": "2019-12-13T09:00:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T13:48:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T18:24:12+01:00",
			"released_at": "2019-12-09T14:56:35+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 4408,
			"vehicle_id": 9962,
			"chassis_number": "WME4534911K402302",
			"registration_mark": "BOH-PM 90E",
			"hsn": "1313",
			"tsn": "GOO",
			"vehicle_name": "Smart Fortwo Coupe ED",
			"paused_by": null,
			"service_id": 101284,
			"company_name": "",
			"first_name": "",
			"last_name": "Pflege und Mehr GmbH",
			"salutation": "him",
			"order_service_resource_id": 4250,
			"order_service_id": 3297,
			"resource_id": 17,
			"workers_id": 27,
			"hours_of_work": 0.7,
			"start_point": "2019-12-13T09:00:00+01:00",
			"end_point": "2019-12-13T09:42:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2085,
			"house_id": 8,
			"deliver_point": "2019-12-13T09:00:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T13:48:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T18:24:12+01:00",
			"released_at": "2019-12-09T14:56:35+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 4408,
			"vehicle_id": 9962,
			"chassis_number": "WME4534911K402302",
			"registration_mark": "BOH-PM 90E",
			"hsn": "1313",
			"tsn": "GOO",
			"vehicle_name": "Smart Fortwo Coupe ED",
			"paused_by": null,
			"service_id": 101286,
			"company_name": "",
			"first_name": "",
			"last_name": "Pflege und Mehr GmbH",
			"salutation": "him",
			"order_service_resource_id": 4252,
			"order_service_id": 3299,
			"resource_id": 17,
			"workers_id": 27,
			"hours_of_work": 2,
			"start_point": "2019-12-13T10:12:00+01:00",
			"end_point": "2019-12-13T12:12:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2114": [{
			"order_id": 2114,
			"house_id": 8,
			"deliver_point": "2019-12-13T10:48:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T15:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T13:57:11+01:00",
			"released_at": "2019-12-12T13:53:15+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 3631,
			"vehicle_id": 4744,
			"chassis_number": "WF0SXXWPGSJL58010",
			"registration_mark": "VIE-NV 236",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford Transit Connect (06\/2002-)",
			"paused_by": null,
			"service_id": 101316,
			"company_name": "",
			"first_name": "",
			"last_name": "Niersverband",
			"salutation": "him",
			"order_service_resource_id": 4356,
			"order_service_id": 3375,
			"resource_id": 11,
			"workers_id": 30,
			"hours_of_work": 0.5,
			"start_point": "2019-12-13T13:36:00+01:00",
			"end_point": "2019-12-13T14:06:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2114,
			"house_id": 8,
			"deliver_point": "2019-12-13T10:48:00+01:00",
			"fetch_point": "2019-12-13T17:00:00+01:00",
			"latest_point": "2019-12-13T15:18:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T13:57:11+01:00",
			"released_at": "2019-12-12T13:53:15+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 3631,
			"vehicle_id": 4744,
			"chassis_number": "WF0SXXWPGSJL58010",
			"registration_mark": "VIE-NV 236",
			"hsn": "0928",
			"tsn": "284",
			"vehicle_name": "Ford Transit Connect (06\/2002-)",
			"paused_by": null,
			"service_id": 101313,
			"company_name": "",
			"first_name": "",
			"last_name": "Niersverband",
			"salutation": "him",
			"order_service_resource_id": 4348,
			"order_service_id": 3368,
			"resource_id": 11,
			"workers_id": 30,
			"hours_of_work": 1.2,
			"start_point": "2019-12-13T10:48:00+01:00",
			"end_point": "2019-12-13T12:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2093": [{
			"order_id": 2093,
			"house_id": 8,
			"deliver_point": "2019-12-12T09:00:00+01:00",
			"fetch_point": "2019-12-16T15:00:00+01:00",
			"latest_point": "2019-12-16T03:30:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-11T17:59:06+01:00",
			"released_at": "2019-12-10T08:53:55+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 3766,
			"vehicle_id": 10113,
			"chassis_number": "WME4533911K314226",
			"registration_mark": "MO-E 6066E",
			"hsn": "1313",
			"tsn": "HSF",
			"vehicle_name": "Smart EQ Fortwo Coupe",
			"paused_by": 29,
			"service_id": 101312,
			"company_name": "",
			"first_name": "Dirk",
			"last_name": "Kettner",
			"salutation": "him",
			"order_service_resource_id": 4339,
			"order_service_id": 3365,
			"resource_id": 11,
			"workers_id": 29,
			"hours_of_work": 6.5,
			"start_point": "2019-12-13T16:48:00+01:00",
			"end_point": "2019-12-13T17:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}],
		"2076": [{
			"order_id": 2076,
			"house_id": 8,
			"deliver_point": "2019-12-12T16:00:00+01:00",
			"fetch_point": "2019-12-23T08:30:00+01:00",
			"latest_point": "2019-12-23T07:00:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T18:25:13+01:00",
			"released_at": "2019-12-09T11:03:38+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 221,
			"vehicle_id": 9922,
			"chassis_number": "DUMMY",
			"registration_mark": "DUMMY",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Fremd",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "",
			"last_name": "DUMMY",
			"salutation": "him",
			"order_service_resource_id": 4235,
			"order_service_id": 3287,
			"resource_id": 21,
			"workers_id": 30,
			"hours_of_work": 1.5,
			"start_point": "2019-12-13T16:00:00+01:00",
			"end_point": "2019-12-13T17:00:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": false,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}, {
			"order_id": 2076,
			"house_id": 8,
			"deliver_point": "2019-12-12T16:00:00+01:00",
			"fetch_point": "2019-12-23T08:30:00+01:00",
			"latest_point": "2019-12-23T07:00:00+01:00",
			"name": "@depreciated",
			"finished_at": null,
			"delivered_at": "2019-12-12T18:25:13+01:00",
			"released_at": "2019-12-09T11:03:38+01:00",
			"fetched_at": null,
			"progress": 0,
			"client_id": 221,
			"vehicle_id": 9922,
			"chassis_number": "DUMMY",
			"registration_mark": "DUMMY",
			"hsn": "0001",
			"tsn": "001",
			"vehicle_name": "Fremd",
			"paused_by": null,
			"service_id": 103,
			"company_name": "",
			"first_name": "",
			"last_name": "DUMMY",
			"salutation": "him",
			"order_service_resource_id": 4236,
			"order_service_id": 3287,
			"resource_id": 21,
			"workers_id": 30,
			"hours_of_work": 1.5,
			"start_point": "2019-12-16T08:00:00+01:00",
			"end_point": "2019-12-16T08:30:00+01:00",
			"resource_finished_at": null,
			"resource_finished_by": null,
			"deleted_at": null,
			"worked_by": 0,
			"ordered_id": null,
			"before_range": false,
			"after_range": true,
			"last_updated": 1576227066000,
			"_type": "orderdistributionview"
		}]
	},
	"schedule": [{"workers_id": 27, "resource_id": 11, "times": [{"fixed": false, "start": "2019-12-13T13:00:00+01:00", "end": "2019-12-13T17:00:00+01:00"}]}, {
		"workers_id": 29,
		"resource_id": 11,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {
		"workers_id": 30,
		"resource_id": 11,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {"workers_id": 27, "resource_id": 15, "times": [{"fixed": false, "start": "2019-12-13T13:00:00+01:00", "end": "2019-12-13T17:00:00+01:00"}]}, {
		"workers_id": 29,
		"resource_id": 15,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {
		"workers_id": 30,
		"resource_id": 15,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {
		"workers_id": 57,
		"resource_id": 16,
		"times": [{"fixed": false, "start": "2019-12-13T07:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T22:00:00+01:00"
		}]
	}, {"workers_id": 27, "resource_id": 17, "times": [{"fixed": false, "start": "2019-12-13T13:00:00+01:00", "end": "2019-12-13T17:00:00+01:00"}]}, {
		"workers_id": 40,
		"resource_id": 20,
		"times": []
	}, {"workers_id": 27, "resource_id": 21, "times": [{"fixed": false, "start": "2019-12-13T13:00:00+01:00", "end": "2019-12-13T17:00:00+01:00"}]}, {
		"workers_id": 29,
		"resource_id": 21,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {
		"workers_id": 30,
		"resource_id": 21,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {"workers_id": 31, "resource_id": 21, "times": []}, {
		"workers_id": 39,
		"resource_id": 22,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}, {"workers_id": 27, "resource_id": 23, "times": [{"fixed": false, "start": "2019-12-13T13:00:00+01:00", "end": "2019-12-13T17:00:00+01:00"}]}, {
		"workers_id": 30,
		"resource_id": 23,
		"times": [{"fixed": false, "start": "2019-12-13T08:00:00+01:00", "end": "2019-12-13T12:00:00+01:00"}, {
			"fixed": false,
			"start": "2019-12-13T13:00:00+01:00",
			"end": "2019-12-13T17:00:00+01:00"
		}]
	}],
	"list": [{
		"order_id": 2113,
		"house_id": 8,
		"deliver_point": "2019-12-12T13:32:00+01:00",
		"fetch_point": "2019-12-13T10:48:00+01:00",
		"latest_point": "2019-12-13T06:12:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T14:26:32+01:00",
		"released_at": "2019-12-12T14:26:29+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 3247,
		"vehicle_id": 3408,
		"chassis_number": "WME4530421Y163108",
		"registration_mark": "95239 \/ 31",
		"hsn": "0001",
		"tsn": "001",
		"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
		"paused_by": null,
		"service_id": 88,
		"company_name": "Herbrand art mobil GmbH",
		"first_name": "",
		"last_name": "Herbrand art mobil GmbH",
		"salutation": "company",
		"order_service_resource_id": 4345,
		"order_service_id": 3366,
		"resource_id": 21,
		"workers_id": 30,
		"hours_of_work": 1.1,
		"start_point": "2019-12-13T08:00:00+01:00",
		"end_point": "2019-12-13T10:12:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 30,
		"ordered_id": 3,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview",
		"resource_ids": [21]
	}, {
		"order_id": 2114,
		"house_id": 8,
		"deliver_point": "2019-12-13T10:48:00+01:00",
		"fetch_point": "2019-12-13T17:00:00+01:00",
		"latest_point": "2019-12-13T15:18:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T13:57:11+01:00",
		"released_at": "2019-12-12T13:53:15+01:00",
		"fetched_at": null,
		"progress": 0,
		"client_id": 3631,
		"vehicle_id": 4744,
		"chassis_number": "WF0SXXWPGSJL58010",
		"registration_mark": "VIE-NV 236",
		"hsn": "0928",
		"tsn": "284",
		"vehicle_name": "Ford Transit Connect (06\/2002-)",
		"paused_by": null,
		"service_id": 101316,
		"company_name": "",
		"first_name": "",
		"last_name": "Niersverband",
		"salutation": "him",
		"order_service_resource_id": 4356,
		"order_service_id": 3375,
		"resource_id": 11,
		"workers_id": 30,
		"hours_of_work": 0.5,
		"start_point": "2019-12-13T10:12:00+01:00",
		"end_point": "2019-12-13T11:54:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 0,
		"ordered_id": 8,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview",
		"resource_ids": [11]
	}, {
		"order_id": 1936,
		"house_id": 8,
		"deliver_point": "2019-12-13T14:15:00+01:00",
		"fetch_point": "2019-12-13T15:45:00+01:00",
		"latest_point": "2019-12-13T12:45:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": null,
		"released_at": "2019-11-26T10:31:09+01:00",
		"fetched_at": null,
		"progress": 0,
		"client_id": 1391,
		"vehicle_id": 2241,
		"chassis_number": "WME4533421K177716",
		"registration_mark": "KLE-H 8077",
		"hsn": "0999",
		"tsn": "BAI",
		"vehicle_name": "Daimler fortwo coupe 52kw",
		"paused_by": null,
		"service_id": 103,
		"company_name": "",
		"first_name": "Volker",
		"last_name": "Horn",
		"salutation": "him",
		"order_service_resource_id": 3874,
		"order_service_id": 3042,
		"resource_id": 21,
		"workers_id": 30,
		"hours_of_work": 1.5,
		"start_point": "2019-12-13T11:54:00+01:00",
		"end_point": "2019-12-13T13:24:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 0,
		"ordered_id": 10,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview",
		"resource_ids": [21]
	}, {
		"order_id": 2058,
		"house_id": 8,
		"deliver_point": "2019-12-10T11:12:00+01:00",
		"fetch_point": "2019-12-13T17:00:00+01:00",
		"latest_point": "2019-12-12T00:18:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T10:19:34+01:00",
		"released_at": "2019-12-06T15:04:24+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 741,
		"vehicle_id": 1470,
		"chassis_number": "WF0JXXWPBJES82193",
		"registration_mark": "KLE-M 1185",
		"hsn": "0928",
		"tsn": "284",
		"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
		"paused_by": null,
		"service_id": 101262,
		"company_name": "",
		"first_name": "Jens",
		"last_name": "Mevissen",
		"salutation": "him",
		"order_service_resource_id": 4211,
		"order_service_id": 3267,
		"resource_id": 15,
		"workers_id": 30,
		"hours_of_work": 4,
		"start_point": "2019-12-13T13:24:00+01:00",
		"end_point": "2019-12-13T17:24:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 0,
		"ordered_id": 13,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview",
		"resource_ids": [15]
	}],
	"orders": {
		"orders": {
			"2058": {
				"order_id": 2058,
				"order_vehicle_id": 2114,
				"workers_id": null,
				"house_id": 8,
				"fetch_point": 1576252800000,
				"latest_point": 1576106280000,
				"deliver_point": 1575972720000,
				"delivered_at": 1576142374000,
				"released_at": 1575641064000,
				"released_by": 26,
				"progress": 1,
				"fetched_at": null,
				"finished_at": null,
				"name": "@depreciated",
				"paused_by": null,
				"info": "",
				"range_start": 1575993600000,
				"range_end": 1576252080000,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order"
			},
			"2113": {
				"order_id": 2113,
				"order_vehicle_id": 2169,
				"workers_id": null,
				"house_id": 8,
				"fetch_point": 1576230480000,
				"latest_point": 1576213920000,
				"deliver_point": 1576153920000,
				"delivered_at": 1576157192000,
				"released_at": 1576157189000,
				"released_by": 38,
				"progress": 1,
				"fetched_at": null,
				"finished_at": null,
				"name": "@depreciated",
				"paused_by": null,
				"info": "",
				"range_start": 1576153920000,
				"range_end": 1576230480000,
				"created_at": 1576150359000,
				"last_updated": 1576227066000,
				"_type": "v2_order"
			},
			"2114": {
				"order_id": 2114,
				"order_vehicle_id": 2170,
				"workers_id": null,
				"house_id": 8,
				"fetch_point": 1576252800000,
				"latest_point": 1576246680000,
				"deliver_point": 1576230480000,
				"delivered_at": 1576155431000,
				"released_at": 1576155195000,
				"released_by": 38,
				"progress": 0,
				"fetched_at": null,
				"finished_at": null,
				"name": "@depreciated",
				"paused_by": null,
				"info": "Diesel R\u00fccklaufleitung ist bestellt ",
				"range_start": 1576230480000,
				"range_end": 1576242360000,
				"created_at": 1576151595000,
				"last_updated": 1576227066000,
				"_type": "v2_order"
			},
			"1936": {
				"order_id": 1936,
				"order_vehicle_id": 1990,
				"workers_id": null,
				"house_id": 8,
				"fetch_point": 1576248300000,
				"latest_point": 1576237500000,
				"deliver_point": 1576242900000,
				"delivered_at": null,
				"released_at": 1574760669000,
				"released_by": 34,
				"progress": 0,
				"fetched_at": null,
				"finished_at": null,
				"name": "@depreciated",
				"paused_by": null,
				"info": "",
				"range_start": 1576242900000,
				"range_end": 1576248300000,
				"created_at": 1574757069000,
				"last_updated": 1576227066000,
				"_type": "v2_order"
			}
		},
		"services": {
			"3042": {
				"order_service_id": 3042,
				"custom": false,
				"order_id": 1936,
				"service_id": 103,
				"hours_of_work": 1.6,
				"hours_of_work_resource": 1.5,
				"price": 80,
				"service_name": "Wartungsdienst gro\u00df",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1574757069000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3358": {
				"order_service_id": 3358,
				"custom": false,
				"order_id": 2058,
				"service_id": 56,
				"hours_of_work": 1.1,
				"hours_of_work_resource": 1.1,
				"price": 80,
				"service_name": "Bremsscheiben und Bel\u00e4ge hinten erneuern",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576135746000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3357": {
				"order_service_id": 3357,
				"custom": false,
				"order_id": 2058,
				"service_id": 103,
				"hours_of_work": 1.6,
				"hours_of_work_resource": 1.5,
				"price": 80,
				"service_name": "Wartungsdienst gro\u00df",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576135746000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3258": {
				"order_service_id": 3258,
				"custom": false,
				"order_id": 2058,
				"service_id": 107,
				"hours_of_work": 24,
				"hours_of_work_resource": 24,
				"price": 80,
				"service_name": "Lack Fremd 3 Tage",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1575629829000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3266": {
				"order_service_id": 3266,
				"custom": true,
				"order_id": 2058,
				"service_id": 101261,
				"hours_of_work": 3,
				"hours_of_work_resource": 3,
				"price": 80,
				"service_name": "Unfallschaden abr\u00fcsten",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": 1576048856000,
				"created_at": 1575638979000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3267": {
				"order_service_id": 3267,
				"custom": true,
				"order_id": 2058,
				"service_id": 101262,
				"hours_of_work": 4,
				"hours_of_work_resource": 4,
				"price": 80,
				"service_name": "Unfallschaden komplettieren",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1575638979000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3366": {
				"order_service_id": 3366,
				"custom": false,
				"order_id": 2113,
				"service_id": 88,
				"hours_of_work": 1.2,
				"hours_of_work_resource": 1.1,
				"price": 80,
				"service_name": "Wartungsdienst durchf\u00fchren",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576150359000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3367": {
				"order_service_id": 3367,
				"custom": false,
				"order_id": 2113,
				"service_id": 93,
				"hours_of_work": 3.5,
				"hours_of_work_resource": 3.5,
				"price": 65,
				"service_name": "Aufbereitung GW (AW Nr. 555555)",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576150359000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3368": {
				"order_service_id": 3368,
				"custom": true,
				"order_id": 2114,
				"service_id": 101313,
				"hours_of_work": 1.2,
				"hours_of_work_resource": 1.2,
				"price": 99,
				"service_name": "Dieselverlust ",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576151595000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			},
			"3375": {
				"order_service_id": 3375,
				"custom": true,
				"order_id": 2114,
				"service_id": 101316,
				"hours_of_work": 0.5,
				"hours_of_work_resource": 0.5,
				"price": 99,
				"service_name": "UVV durchf\u00fchren",
				"is_tuv": false,
				"paused_by": null,
				"finished_at": null,
				"created_at": 1576219337000,
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "v2_order2service"
			}
		},
		"resources": {
			"3874": {
				"order_service_resource_id": 3874,
				"custom": false,
				"order_service_id": 3042,
				"resource_id": 21,
				"workers_id": 27,
				"hours_of_work": 1.5,
				"start_point": 1576242900000,
				"end_point": 1576248300000,
				"resource_name": "Wartung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1574757094000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4192": {
				"order_service_resource_id": 4192,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1575993600000,
				"end_point": 1576011600000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4193": {
				"order_service_resource_id": 4193,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576044000000,
				"end_point": 1576047600000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4194": {
				"order_service_resource_id": 4194,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576051200000,
				"end_point": 1576061280000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4195": {
				"order_service_resource_id": 4195,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576065600000,
				"end_point": 1576067760000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4196": {
				"order_service_resource_id": 4196,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576071360000,
				"end_point": 1576098000000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4197": {
				"order_service_resource_id": 4197,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576130400000,
				"end_point": 1576148400000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4198": {
				"order_service_resource_id": 4198,
				"custom": false,
				"order_service_id": 3258,
				"resource_id": 16,
				"workers_id": 57,
				"hours_of_work": 24,
				"start_point": 1576152000000,
				"end_point": 1576159920000,
				"resource_name": "Lack Fremd",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575629829000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4208": {
				"order_service_resource_id": 4208,
				"custom": true,
				"order_service_id": 3266,
				"resource_id": 15,
				"workers_id": 29,
				"hours_of_work": 3,
				"start_point": 1576159920000,
				"end_point": 1576166400000,
				"resource_name": "Karosserie",
				"finished_at": 1576048856000,
				"finished_by": 27,
				"created_at": 1575638979000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4209": {
				"order_service_resource_id": 4209,
				"custom": true,
				"order_service_id": 3266,
				"resource_id": 15,
				"workers_id": 29,
				"hours_of_work": 3,
				"start_point": 1576220400000,
				"end_point": 1576224720000,
				"resource_name": "Karosserie",
				"finished_at": 1576048856000,
				"finished_by": 27,
				"created_at": 1575638979000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4210": {
				"order_service_resource_id": 4210,
				"custom": true,
				"order_service_id": 3267,
				"resource_id": 15,
				"workers_id": 29,
				"hours_of_work": 4,
				"start_point": 1576224720000,
				"end_point": 1576234800000,
				"resource_name": "Karosserie",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575638979000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4211": {
				"order_service_resource_id": 4211,
				"custom": true,
				"order_service_id": 3267,
				"resource_id": 15,
				"workers_id": 29,
				"hours_of_work": 4,
				"start_point": 1576238400000,
				"end_point": 1576242720000,
				"resource_name": "Karosserie",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1575638979000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4324": {
				"order_service_resource_id": 4324,
				"custom": false,
				"order_service_id": 3357,
				"resource_id": 21,
				"workers_id": 29,
				"hours_of_work": 1.5,
				"start_point": 1576242720000,
				"end_point": 1576248120000,
				"resource_name": "Wartung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576135746000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4325": {
				"order_service_resource_id": 4325,
				"custom": false,
				"order_service_id": 3358,
				"resource_id": 21,
				"workers_id": 29,
				"hours_of_work": 1.1,
				"start_point": 1576248120000,
				"end_point": 1576252080000,
				"resource_name": "Wartung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576135746000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4345": {
				"order_service_resource_id": 4345,
				"custom": false,
				"order_service_id": 3366,
				"resource_id": 21,
				"workers_id": 30,
				"hours_of_work": 1.1,
				"start_point": 1576226520000,
				"end_point": 1576230480000,
				"resource_name": "Wartung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576150359000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4346": {
				"order_service_resource_id": 4346,
				"custom": false,
				"order_service_id": 3367,
				"resource_id": 22,
				"workers_id": 39,
				"hours_of_work": 3.5,
				"start_point": 1576153920000,
				"end_point": 1576166400000,
				"resource_name": "Aufbereitung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576150359000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4347": {
				"order_service_resource_id": 4347,
				"custom": false,
				"order_service_id": 3367,
				"resource_id": 22,
				"workers_id": 39,
				"hours_of_work": 3.5,
				"start_point": 1576220400000,
				"end_point": 1576220520000,
				"resource_name": "Aufbereitung",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576150359000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4348": {
				"order_service_resource_id": 4348,
				"custom": true,
				"order_service_id": 3368,
				"resource_id": 11,
				"workers_id": 30,
				"hours_of_work": 1.2,
				"start_point": 1576230480000,
				"end_point": 1576234800000,
				"resource_name": "Mechanik",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576151595000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			},
			"4356": {
				"order_service_resource_id": 4356,
				"custom": true,
				"order_service_id": 3375,
				"resource_id": 11,
				"workers_id": 30,
				"hours_of_work": 0.5,
				"start_point": 1576240560000,
				"end_point": 1576242360000,
				"resource_name": "Mechanik",
				"finished_at": null,
				"finished_by": null,
				"created_at": 1576219337000,
				"last_updated": 1576227066000,
				"_type": "v2_order2service2resource"
			}
		},
		"order2services": {"1936": [3042], "2058": [3358, 3357, 3258, 3266, 3267], "2113": [3366, 3367], "2114": [3368, 3375]},
		"service2resources": {
			"3042": [3874],
			"3258": [4192, 4193, 4194, 4195, 4196, 4197, 4198],
			"3266": [4208, 4209],
			"3267": [4210, 4211],
			"3357": [4324],
			"3358": [4325],
			"3366": [4345],
			"3367": [4346, 4347],
			"3368": [4348],
			"3375": [4356]
		},
		"order_vehicles": {
			"1990": {
				"order_vehicle_id": 1990,
				"vehicle_id": 2241,
				"client_id": 1391,
				"model_id": 10005,
				"hsn": "0999",
				"tsn": "BAI",
				"chassis_number": "WME4533421K177716",
				"registration_mark": "KLE-H 8077",
				"km": 14000,
				"model_year": null,
				"name": "Daimler fortwo coupe 52kw",
				"loan_car": false,
				"deleted_at": null,
				"first_registration": 1482188400000,
				"last_updated": 1576227066000,
				"_type": "order2vehicle"
			},
			"2114": {
				"order_vehicle_id": 2114,
				"vehicle_id": 1470,
				"client_id": 741,
				"model_id": 8969,
				"hsn": "0928",
				"tsn": "284",
				"chassis_number": "WF0JXXWPBJES82193",
				"registration_mark": "KLE-M 1185",
				"km": 48865,
				"model_year": null,
				"name": "Ford C-MAX & GRAND (07\/2010-)",
				"loan_car": false,
				"deleted_at": null,
				"first_registration": 1399240800000,
				"last_updated": 1576227066000,
				"_type": "order2vehicle"
			},
			"2169": {
				"order_vehicle_id": 2169,
				"vehicle_id": 3408,
				"client_id": 3247,
				"model_id": 27807,
				"hsn": "0001",
				"tsn": "001",
				"chassis_number": "WME4530421Y163108",
				"registration_mark": "95239 \/ 31",
				"km": 31436,
				"model_year": null,
				"name": "Daimler FORFOUR 52KW TWINAMIC",
				"loan_car": false,
				"deleted_at": null,
				"first_registration": 1513206000000,
				"last_updated": 1576227066000,
				"_type": "order2vehicle"
			},
			"2170": {
				"order_vehicle_id": 2170,
				"vehicle_id": 4744,
				"client_id": 3631,
				"model_id": 8969,
				"hsn": "0928",
				"tsn": "284",
				"chassis_number": "WF0SXXWPGSJL58010",
				"registration_mark": "VIE-NV 236",
				"km": 10069,
				"model_year": null,
				"name": "Ford Transit Connect (06\/2002-)",
				"loan_car": false,
				"deleted_at": null,
				"first_registration": 1519340400000,
				"last_updated": 1576227066000,
				"_type": "order2vehicle"
			}
		},
		"clients": {
			"741": {
				"client_id": 741,
				"house_id": 8,
				"self": false,
				"client_number": "102193",
				"salutation": "him",
				"company_name": "",
				"name": "Mevissen",
				"first_name": "Jens",
				"street": "Eyller Str. 48",
				"house_no": "",
				"city": "Kerken",
				"zipcode": "47647",
				"country": "germany",
				"address_extra": "",
				"email": "jensmevissen@gmx.de",
				"telephone": "573851",
				"telephone_prefix": "2833",
				"mobile": "",
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "client"
			},
			"1391": {
				"client_id": 1391,
				"house_id": 8,
				"self": false,
				"client_number": "104325",
				"salutation": "him",
				"company_name": "",
				"name": "Horn",
				"first_name": "Volker",
				"street": "Kiefernstr. 41",
				"house_no": "",
				"city": "Goch",
				"zipcode": "47574",
				"country": "germany",
				"address_extra": "",
				"email": "V.R.Horn@t-online.de",
				"telephone": "29429",
				"telephone_prefix": "2823",
				"mobile": "1639088749",
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "client"
			},
			"3247": {
				"client_id": 3247,
				"house_id": 8,
				"self": false,
				"client_number": "",
				"salutation": "company",
				"company_name": "Herbrand art mobil GmbH",
				"name": "Herbrand art mobil GmbH",
				"first_name": "",
				"street": "",
				"house_no": "",
				"city": "Alpen",
				"zipcode": "46519",
				"country": "germany",
				"address_extra": "",
				"email": "k.vennemann@icloud.com",
				"telephone": "809259",
				"telephone_prefix": "2802",
				"mobile": "15167507582",
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "client"
			},
			"3631": {
				"client_id": 3631,
				"house_id": 8,
				"self": false,
				"client_number": "205572",
				"salutation": "him",
				"company_name": "",
				"name": "Niersverband",
				"first_name": "",
				"street": "",
				"house_no": "",
				"city": "",
				"zipcode": "",
				"country": "germany",
				"address_extra": "",
				"email": "",
				"telephone": "",
				"telephone_prefix": "",
				"mobile": "",
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "client"
			}
		},
		"loan_cars": {
			"2058": {
				"loan_car_schedule_id": 247,
				"vehicle_id": 9803,
				"house_id": 8,
				"client_id": 741,
				"order_id": 2058,
				"info": "",
				"start_point": "2019-12-10T11:12:00+01:00",
				"end_point": "2019-12-13T18:00:00+01:00",
				"deleted_at": null,
				"last_updated": 1576227066000,
				"_type": "loancarschedule"
			}
		}
	},
	"workers": [{"workers_id": 27, "resource_id": 11, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 29,
		"resource_id": 11,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 30, "resource_id": 11, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 27,
		"resource_id": 15,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 29, "resource_id": 15, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 30,
		"resource_id": 15,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 57, "resource_id": 16, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 27,
		"resource_id": 17,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 40, "resource_id": 20, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 27,
		"resource_id": 21,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 29, "resource_id": 21, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 30,
		"resource_id": 21,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 31, "resource_id": 21, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 39,
		"resource_id": 22,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}, {"workers_id": 27, "resource_id": 23, "last_updated": 1576227066000, "_type": "resource2worker"}, {
		"workers_id": 30,
		"resource_id": 23,
		"last_updated": 1576227066000,
		"_type": "resource2worker"
	}],
	"wlist": {
		"default": [[57, 16], [29, 21], [39, 22], [30, 21], [27, 17], [29, 15], [27, 21], [30, 11], [29, 11]],
		"by_work": [[[57, 16], [57, 16], [57, 16], [57, 16], [57, 16], [57, 16], [57, 16], [57, 16]], [[29, 21], [29, 21], [29, 15], [29, 21], [29, 15], [29, 11]], [[39, 22], [39, 22], [39, 22], [39, 22]], [[30, 21], [30, 21], [30, 11], [30, 21], [30, 11], [30, 11], [30, 21], [30, 21]], [[27, 17], [27, 17], [27, 21], [27, 21], [27, 17], [27, 17], [27, 17]]]
	},
	"olist": {
		"57-2058": 1,
		"39-2116": 1,
		"30-2113": 1,
		"29-2026": 1,
		"27-2100": 1,
		"30-2058": 1,
		"27-2058": 0,
		"39-2113": 1,
		"27-2085": 1,
		"30-2114": 1,
		"29-2076": 1,
		"30-1936": 1,
		"29-2065": 1,
		"29-2058": 1
	},
	"olist_order": ["+ 57-2058", "+ 39-2116", "+ 30-2113", "+ 29-2026", "+ 27-2100", "+ 30-2058", "- 30-2058", "+ 27-2058", "- 27-2058", "+ 39-2113", "+ 27-2085", "+ 30-2114", "+ 29-2076", "+ 30-1936", "+ 29-2065", "+ 29-2058", "- 29-2058", "+ 29-2058", "- 29-2058", "+ 29-2058", "- 29-2058", "+ 27-2058", "- 27-2058", "+ 29-2058", "+ 30-2058"],
	"min_max": ["2019-12-13T06:00:00+01:00", "2019-12-13T23:00:00+01:00"],
	"work": [{
		"order_id": 2058,
		"house_id": 8,
		"deliver_point": "2019-12-10T11:12:00+01:00",
		"fetch_point": "2019-12-13T17:00:00+01:00",
		"latest_point": "2019-12-12T00:18:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T10:19:34+01:00",
		"released_at": "2019-12-06T15:04:24+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 741,
		"vehicle_id": 1470,
		"chassis_number": "WF0JXXWPBJES82193",
		"registration_mark": "KLE-M 1185",
		"hsn": "0928",
		"tsn": "284",
		"vehicle_name": "Ford C-MAX & GRAND (07\/2010-)",
		"paused_by": null,
		"service_id": 107,
		"company_name": "",
		"first_name": "Jens",
		"last_name": "Mevissen",
		"salutation": "him",
		"order_service_resource_id": 4192,
		"order_service_id": 3258,
		"resource_id": 16,
		"workers_id": 57,
		"hours_of_work": 24,
		"start_point": "2019-12-10T17:00:00+01:00",
		"end_point": "2019-12-10T22:00:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 57,
		"ordered_id": null,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview"
	}, {
		"order_id": 2026,
		"house_id": 8,
		"deliver_point": "2019-12-13T08:00:00+01:00",
		"fetch_point": "2019-12-13T09:30:00+01:00",
		"latest_point": "2019-12-13T08:00:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-13T08:06:12+01:00",
		"released_at": "2019-12-04T10:05:00+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 5725,
		"vehicle_id": 7082,
		"chassis_number": "WME4514011K735063",
		"registration_mark": "EL-PM 691",
		"hsn": "0999",
		"tsn": "BAI",
		"vehicle_name": "Smart FORTWO CDI 40 KW",
		"paused_by": null,
		"service_id": 103,
		"company_name": "",
		"first_name": "",
		"last_name": "Hocke",
		"salutation": "him",
		"order_service_resource_id": 4094,
		"order_service_id": 3209,
		"resource_id": 21,
		"workers_id": 29,
		"hours_of_work": 1.5,
		"start_point": "2019-12-13T08:00:00+01:00",
		"end_point": "2019-12-13T09:30:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 29,
		"ordered_id": null,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview"
	}, {
		"order_id": 2116,
		"house_id": 8,
		"deliver_point": "2019-12-12T16:36:02+01:00",
		"fetch_point": "2019-12-13T11:54:02+01:00",
		"latest_point": "2019-12-13T07:36:02+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T14:34:03+01:00",
		"released_at": "2019-12-12T14:34:02+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 501,
		"vehicle_id": 10165,
		"chassis_number": "WF0JXXGAHJKK03842",
		"registration_mark": "95212 \/ 25",
		"hsn": "0928",
		"tsn": "476",
		"vehicle_name": "Ford Fiesta",
		"paused_by": null,
		"service_id": 93,
		"company_name": "",
		"first_name": "",
		"last_name": "Herbrand art mobil GmbH",
		"salutation": "him",
		"order_service_resource_id": 4352,
		"order_service_id": 3371,
		"resource_id": 22,
		"workers_id": 39,
		"hours_of_work": 3.5,
		"start_point": "2019-12-13T08:24:02+01:00",
		"end_point": "2019-12-13T11:54:02+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 39,
		"ordered_id": null,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview"
	}, {
		"order_id": 2113,
		"house_id": 8,
		"deliver_point": "2019-12-12T13:32:00+01:00",
		"fetch_point": "2019-12-13T10:48:00+01:00",
		"latest_point": "2019-12-13T06:12:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-12T14:26:32+01:00",
		"released_at": "2019-12-12T14:26:29+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 3247,
		"vehicle_id": 3408,
		"chassis_number": "WME4530421Y163108",
		"registration_mark": "95239 \/ 31",
		"hsn": "0001",
		"tsn": "001",
		"vehicle_name": "Daimler FORFOUR 52KW TWINAMIC",
		"paused_by": null,
		"service_id": 88,
		"company_name": "Herbrand art mobil GmbH",
		"first_name": "",
		"last_name": "Herbrand art mobil GmbH",
		"salutation": "company",
		"order_service_resource_id": 4345,
		"order_service_id": 3366,
		"resource_id": 21,
		"workers_id": 30,
		"hours_of_work": 1.1,
		"start_point": "2019-12-13T09:42:00+01:00",
		"end_point": "2019-12-13T10:48:00+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 30,
		"ordered_id": null,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview"
	}, {
		"order_id": 2100,
		"house_id": 8,
		"deliver_point": "2019-12-11T11:00:00+01:00",
		"fetch_point": "2019-12-13T17:00:00+01:00",
		"latest_point": "2019-12-13T14:30:00+01:00",
		"name": "@depreciated",
		"finished_at": null,
		"delivered_at": "2019-12-11T10:21:40+01:00",
		"released_at": "2019-12-11T10:21:29+01:00",
		"fetched_at": null,
		"progress": 1,
		"client_id": 2605,
		"vehicle_id": 3640,
		"chassis_number": "WF0YXXTTGYEU40550",
		"registration_mark": "KLE-TB 589",
		"hsn": "0928",
		"tsn": "284",
		"vehicle_name": "Ford TRANSIT CUSTOM 2012 (04\/2012-)",
		"paused_by": null,
		"service_id": 101310,
		"company_name": "",
		"first_name": "",
		"last_name": "Thesing Bedachung GbR",
		"salutation": "him",
		"order_service_resource_id": 4329,
		"order_service_id": 3361,
		"resource_id": 17,
		"workers_id": 27,
		"hours_of_work": 1.5,
		"start_point": "2019-12-12T10:37:22+01:00",
		"end_point": "2019-12-12T12:07:22+01:00",
		"resource_finished_at": null,
		"resource_finished_by": null,
		"deleted_at": null,
		"worked_by": 27,
		"ordered_id": null,
		"before_range": false,
		"after_range": false,
		"last_updated": 1576227066000,
		"_type": "orderdistributionview"
	}],
	"workloads": {"57": "207.14", "39": "131.25", "30": "117.50", "29": "96.25", "27": "155.00"},
	"workmap": [[2058, 27, 15], [2058, 57, 16], [2113, 30, 21]],
	"resmap": null
};

const _map = (list) => {
	return list;
};

const Display = ({list}) => {
	return (
		<table border={1} cellPadding={8} style={{borderCollapse: "collapse"}}>
			<thead>
				<tr>
					<th>Kennzeichen</th>
					<th>Geliefert</th>
					<th>Lieferung</th>
					<th>Latest</th>
					<th>Abholung</th>
				</tr>
			</thead>
			<tbody>
				{list.map(o => {
					return <tr key={o.order_service_resource_id}>
						<td>{o.registration_mark}</td>
						<td>{o.delivered_at ? 'Ja' : 'Nein'}</td>
						<td>{moment(o.deliver_point).format("LLL")}</td>
						<td>{moment(o.latest_point).format("LLL")}</td>
						<td>{moment(o.fetch_point).format("LLL")}</td>
					</tr>
				})}
			</tbody>
		</table>
	);
};

const _sort = (source, ...method) => {
	const target = [...source];
	for (let k = method.length - 1; k >= 0; --k) {
		target.sort(method[k]);
	}
	return target;
};

export class FunctionTest extends React.Component {
	state = {
		client: null,
		worker: null,
		orders: [],
		area: ""
	};
	
	componentDidMount() {
		GET('/client/11').then(value => this.setState({client: Client._(value[11])}));
		GET('/workers/access').then(res => this.setState({worker: User._(res.worker)}));
	}
	
	take = () => {
		const {area} = this.state;
		let orders = area.split('\n').map(s => s.trim()).filter(Boolean);
		this.setState({orders});
	};
	
	render() {
		const {client} = this.state;
		const list = _map(data.list);
		const list2 = _sort(list, (a,b) => !!b.delivered_at - !!a.delivered_at);
		const list3 = _sort(list, (a,b) => !!b.delivered_at - !!a.delivered_at, (a,b) => moment(a.deliver_point) - moment(b.deliver_point));
		// let list3 = [...list];
		// list3.sort()
		return (
			<Segment basic loading={client === null}>
				<Display list={list}/>
				<Display list={list2}/>
				<Display list={list3}/>
				<pre>{JSON.stringify({area: this.state.area, orders: this.state.orders})}</pre>
				<textarea name="test" id="test" cols="30" rows="10" onChange={e => this.setState({area: e.target.value})} value={this.state.area}/>
				<div>
					<button onClick={this.take}>Uebernehmen</button>
				</div>
				<Order.CollectProvider dynamic preview ids={this.state.orders}>{(orders, loading) =>
					<Segment loading={loading}><pre>{JSON.stringify(orders, null, 2)}</pre></Segment>
				}</Order.CollectProvider>
			</Segment>
		);
	}
}

FunctionTest = translate()(FunctionTest);