import md5 from 'md5';
import {PRODUCTION_MODE} from "./constants";
import {isFunction, values} from "lodash";

const channels = {};

export const subscribe = (channel, method) => {
	const token = md5(new Date().getTime() * Math.random());
	channels[channel] = {
		...channels[channel],
		[token]: method
	};
	return () => unsubscribe(channel, token);
};

export const unsubscribe = (channel, token) => {
	if (isFunction(channel)) {
		channel();
	} else if ( channels[channel] && channels[channel][token]) {
		delete (channels[channel][token]);
		return true;
	}
	return false;
};

export const publish = (channel, ...args) => {
	if ( channels[channel] ) {
		values(channels[channel]).forEach(method => method.apply(null, args));
	}
};
if (!PRODUCTION_MODE) {
	window.getChannels = () => channels;
	window.publish = publish;
}