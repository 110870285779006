import * as React from "react";
import PropTypes from "prop-types";
import {IconButton, List, ListItem} from "material-ui";
import {IconClose} from "../../../Logic/icons";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {orderAction__fetchOneCached, orderAction__removeService} from "../../../actions/orderActions";
import {isFunction, pick, values} from "lodash";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {PROC_ORDER} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {Message, Segment} from "semantic-ui-react";
import {If} from "../../../Logic/Switch";

export class OrderRemoveServiceView extends React.PureComponent {
	static propTypes = {
		order: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		order_id: PropTypes.number,
		order2services: PropTypes.object,
		services: PropTypes.object,
		loading: PropTypes.bool,
		onAfterRemove: PropTypes.func
	};
	static defaultProps = {
		order2services: {},
		services: {},
		onAfterRemove: () => {}
	};
	
	getServices = memoize(
		(order, order2services, serviceMap) =>
			order ?
			values(pick(serviceMap, order2services[order.order_id || order] || [])) : []
		
	);
	
	render() {
		const {
			order,
			order2services,
			services: serviceMap,
			loading,
			onRemove
		} = this.props;
		const services = this.getServices(order, order2services, serviceMap);
		return (
			<Segment basic loading={loading}>
				<If condition={services.length === 0}>
					<Message>Noch kein Service zugewiesen</Message>
				</If>
				<List>
					{services.map(service => {
						
						return [
							<ListItem
								key={service.order_service_id}
								disabled
								primaryText={service.service_name}
								rightIconButton={
									<IconButton onClick={onRemove.bind(undefined, service.order_service_id, this.props.onAfterRemove.bind(undefined, service.order_service_id))}><IconClose/></IconButton>
								}
							/>
						];
					})}
				</List>
			</Segment>
		);
	}
}

OrderRemoveServiceView = withInit(OrderRemoveServiceView);
OrderRemoveServiceView = connect(
	(state, {order, order_id}) => {
		return {
			order: order || state.orders.orders[order_id],
			order2services: state.orders.order2services,
			services: state.orders.services,
			loading: isLoading(state, PROC_ORDER)
		};
	},
	(dispatch, {order_id}) => ({
		init: () => {
			order_id && dispatch(orderAction__fetchOneCached(order_id));
		},
		onRemove: (id, onSuccess) => dispatch(orderAction__removeService(id, result => {
			dispatch(addSnackbar("Service wurde entfernt"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		dispatch
	})
)(OrderRemoveServiceView);