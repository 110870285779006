import * as React from "react";
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {soundAction__play, soundAction__stop} from "../../actions/soundActions";
import {SOUND_STATES} from "../../actions/types";


export class SoundTest extends React.Component {
	componentDidMount() {
		this.props.play();
	}
	
	componentWillUnmount() {
		this.props.stop();
	}
	
	render() {
		const {stop, play, isPlaying} = this.props;
		return(
			<Segment basic>
				<button onClick={() => isPlaying ? stop() : play()}>{isPlaying ? "Stop" : "Start"}</button>
			</Segment>
		);
	}
}

SoundTest = connect(
	state => ({
		isPlaying: state.sound.playStatus === SOUND_STATES.PLAYING
	}),
	dispatch => ({
		play: () => dispatch(soundAction__play),
		stop: () => dispatch(soundAction__stop)
	})
)(SoundTest);