import * as React from "react";
import ServiceResourceView from "../../../../components/intern/master-data/service/ServiceResourceView";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
	loadResourcesOfHouse,
	resourceAction__assignMassiveToModel,
	resourceAction__assignToKfz,
	resourceAction__assignToModel,
	resourceAction__unassignFromKfz,
	resourceAction__unassignFromModel,
	resourceAction__unassignMassiveFromModels
} from "../../../../actions/resourceActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import isFunc from "lodash/isFunction";
import values from "lodash/values";
import ResourceSelector from "../../../../components/intern/master-data/ResourceSelector";
import withInit from "../../../../Logic/withInit";
import get from "lodash/get";
import find from "lodash/find";
import {Flex, FlexChild} from "../../../../components/partials/ActionHeader";
import {Chip, LinearProgress, MenuItem, SelectField, Tab, Tabs} from "material-ui";
import {translate} from "react-i18next";
import isArray from "lodash/isArray";
import {Button, Form, Modal} from "semantic-ui-react";
import EditField from "../../../../components/partials/EditField";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_RESOURCE_MODEL_MASS_ASSIGN} from "../../../../actions";

const m2s = (state, props) => {
	// console.info("connect", props.service_id, props.kfz_id);
	return {
		// resources: get(state.resources.kfz, `${props.service_id}.${props.kfz_id}`, []),
		resource_list: values(state.resources.list)
	};
};

const m2d = (dispatch, props) => {
	return {
		onResourceAssign: (resource, hours_of_work, onSuccess) => dispatch(resourceAction__assignToKfz(props.service_id, props.kfz_id, resource, hours_of_work, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.md-service.kfz-resource-assigned'));
		})),
		onResourceRemove: (resource, onSuccess) => dispatch(resourceAction__unassignFromKfz(props.service_id, props.kfz_id, resource, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.md-service.kfz-resource-unassigned'));
		}))
	};
};

const Controller = connect(m2s, m2d)(ServiceResourceView);

Controller.propTypes = {
	kfz_id: PropTypes.number.isRequired
};

export default Controller;


const _ResourceButton = ({resourceList=[], onSubmit, ...props}) => resourceList.length === 0 ? null : (
	<ResourceSelector resourceList={resourceList} inputProps={{required: true, type: "number", min: "0.1", step: "0.1"}} onSubmit={onSubmit} {...props}/>
);

const _ResourceChips = ({model, summary, resources = [], types = {}, onDelete, style}) => (
	<Flex gutterHeight={20} gutterWidth={20} align={"flex-start"} style={style}>
		{resources.length ? resources.map(resource => {
			return (
				<FlexChild key={`resource-assign-${model.model_id}-${resource.resource_id}`}>
					<Chip onRequestDelete={onDelete.bind(null, resource)}>{types[resource.resource_id].name || 'fail'} <strong>{resource.hours_of_work}</strong></Chip>
				</FlexChild>
			);
		}) : <FlexChild>Default Resource...</FlexChild>
		}
	</Flex>
);

const ResourceSelect = ({id, onSelect, resourceTypes, t}) => (
	<SelectField
		fullWidth
		underlineStyle={{display: 'none'}}
		value={id}
		onChange={onSelect}
		floatingLabelText={t('Select resource')}
	>
		<MenuItem primaryText="please select" disabled value={-1}/>
		{resourceTypes.map(resource =>
			<MenuItem key={`res-mass-select-${resource.resource_id}`} value={resource.resource_id} primaryText={resource.name}/>
		)}
	</SelectField>
);
ResourceSelect.propTypes = {
	id: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	resourceTypes: PropTypes.array.isRequired,
	t: PropTypes.func.isRequired
};

class _ResourceDialog extends React.PureComponent {
	state = {
		selected: -1,
		hours_of_work: '',
		mode: 'create'
	};
	
	onChange = (e, {name, value}) => this.setState({[name]: value});
	onSelect = (e, index, selected) => this.setState({selected});
	onMode = (mode) => () => {
		console.error("mode", mode);
		this.setState({mode});
	};
	
	onSave = () => {
		const {selected, hours_of_work} = this.state;
		const {resourceTypes, onSave, loading} = this.props;
		const resource = find(resourceTypes, {'resource_id': selected});
		if ( !loading && resource && hours_of_work ) {
			onSave(resource, hours_of_work);
		}
	};
	
	onRemove = () => {
		const {selected} = this.state;
		const {onRemove, loading} = this.props;
		if( !loading || selected <= 0 ) {
			onRemove(selected);
		}
	};
	
	render() {
		const {resourceTypes, onHide, t, tReady, i18n, loading, ...props} = this.props;
		const {selected: selectedResourceID, hours_of_work, mode} = this.state;
		const ok = !loading && selectedResourceID > 0 && hours_of_work >= 0.1;
		return(
			<>
			{/*<Dialog bodyStyle={{padding: 0}} {...props} autoScrollBodyContent onRequestClose={onHide} actions={
				<Flex align={"space-between"}>
					<FlatButton onClick={onHide}>{t('actions.close')}</FlatButton>
					{mode === 'create' && <RaisedButton disabled={!ok} primary onClick={this.onSave}>
						{t("actions.process")}
						</RaisedButton>}
					{mode === 'remove' && <RaisedButton disabled={loading || selectedResourceID === -1} secondary onClick={this.onRemove}>
						{t("actions.delete")}
					</RaisedButton>}
				</Flex>
			}>
				<LinearProgress mode="indeterminate" style={{display: loading ? 'block' : 'none', position: 'absolute', left: 0, top: 0, right: 0}} color={"red"}/>
				<Tabs tabTemplateStyle={{paddingLeft: 20, paddingRight: 20, paddingBottom: 20}}>
					<Tab label="Festlegen" onActive={this.onMode('create')}>
					<ResourceSelect id={selectedResourceID} resourceTypes={resourceTypes} onSelect={this.onSelect} t={t}/>
					<Form onSubmit={this.onSave}>
						<EditField value={hours_of_work} onChange={this.onChange} type={"number"} step="0.1" min={"0.1"} name="hours_of_work" editable text={"Arbeitszeit für Ressource"}/>
					</Form>
					</Tab>
					<Tab label="Entfernen" onActive={this.onMode('remove')}>
						<ResourceSelect id={selectedResourceID} resourceTypes={resourceTypes} onSelect={this.onSelect} t={t}/>
					</Tab>
				</Tabs>
			</Dialog>*/}
				<Modal centered={false} {...props} onClose={onHide}>
					<Modal.Content>
						<LinearProgress mode="indeterminate" style={{display: loading ? 'block' : 'none', position: 'absolute', left: 0, top: 0, right: 0}} color={"red"}/>
						<Tabs tabTemplateStyle={{paddingLeft: 20, paddingRight: 20, paddingBottom: 20}}>
							<Tab label="Festlegen" onActive={this.onMode('create')}>
								<ResourceSelect id={selectedResourceID} resourceTypes={resourceTypes} onSelect={this.onSelect} t={t}/>
								<Form onSubmit={this.onSave}>
									<EditField value={hours_of_work} onChange={this.onChange} type={"number"} step="0.1" min={"0.1"} name="hours_of_work" editable text={"Arbeitszeit für Ressource"}/>
								</Form>
							</Tab>
							<Tab label="Entfernen" onActive={this.onMode('remove')}>
								<ResourceSelect id={selectedResourceID} resourceTypes={resourceTypes} onSelect={this.onSelect} t={t}/>
							</Tab>
						</Tabs>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={onHide}>{t('actions.close')}</Button>
						{mode === 'create' && <Button disabled={!ok} primary onClick={this.onSave}>
							{t("actions.process")}
						</Button>}
						{mode === 'remove' && <Button disabled={loading || selectedResourceID === -1} negative onClick={this.onRemove}>
							{t("actions.delete")}
						</Button>}
					</Modal.Actions>
				</Modal>
			</>
			
		);
	};
}




let Disaptched = false;
const ResourceButton = connect(
	(state, props) => {
		return {
			resourceList: props.resourceList || values(state.resources.list),
			getKey: 'name'
		}
	},
	(dispatch, props) => {
		return {
			init: () => {
				if (!Disaptched) {
					Disaptched = true;
					dispatch(loadResourcesOfHouse());
				}
			},
			onSubmit: ({resource, value, deselect}) => {
				dispatch(addSnackbar('Ok'));
				dispatch(resourceAction__assignToModel(props.service_id, props.model, resource, value, result => dispatch(addSnackbar('Saved...'))));
				deselect();
			}
		};
	}
)(withInit(_ResourceButton));
ResourceButton.propTypes = {
	service_id: PropTypes.number.isRequired,
	model: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({model_id: PropTypes.number.isRequired})]).isRequired
};

const ResourceChips = connect(
	(state, props) => {
		const path = `resources.models.${props.service_id}.${props.model.model_id}`;
		const data = get(state, path, []);
		return {
			resources: data,
			summary: {},
			types: props.types || state.resources.list,
			style: props.style
		};
	},
	(dispatch, props) => {
		return {
			onDelete: (resource, onSuccess) => dispatch(resourceAction__unassignFromModel(props.service_id, props.model, resource, result => {
				isFunc(onSuccess) && onSuccess(result);
				dispatch(addSnackbar('Deleted...'));
			}))
		};
	}
)(_ResourceChips);

const ResourceDialog = connect(
	(state, props) => {
		return {
			resourceTypes: isArray(props.resourceTypes) ? props.resourceTypes : values(state.resources.list || {}),
			loading: isLoading(state, PROC_RESOURCE_MODEL_MASS_ASSIGN)
		};
	},
	(dispatch, props) => {
		
		return {
			onSave: (resource, hours_of_work, onSuccess) => {
				const models = props.modelList.map(m => m.model_id || m);
				if (!models.length) {
					dispatch(addSnackbar('NO MODELS', 'alert'));
					return;
				}
				dispatch(resourceAction__assignMassiveToModel(props.service_id, models, resource, hours_of_work, result => {
					isFunc(onSuccess) && onSuccess(result);
					dispatch(addSnackbar('Alle gespeichert...'));
				}))
			},
			onRemove: (resource, onSuccess) => {
				const models = props.modelList;
				if ( !models.length) {
					dispatch(addSnackbar('No Models', 'alert'));
					return;
				}
				dispatch(resourceAction__unassignMassiveFromModels(props.service_id, models, resource, result => {
					isFunc(onSuccess) && onSuccess(result);
					dispatch(addSnackbar(`Alle ${result.removed_ids.length} entfernt`));
				}))
			}
		};
	}
)(translate()(_ResourceDialog));
ResourceDialog.propTypes = {
	onHide: PropTypes.func.isRequired,
	resourceTypes: PropTypes.array.isRequired,
	loading: PropTypes.bool,
	modelList: PropTypes.array.isRequired,
	service_id: PropTypes.number.isRequired
};
ResourceDialog.defaultProps = {
	open: true,
	loading: false,
	resourceTypes: [],
	modelList: []
};

export {ResourceButton, ResourceChips, ResourceDialog};
