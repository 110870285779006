import * as React from "react";
import {translate} from "react-i18next";
import PropTypes from "prop-types";
import {Client} from "../../../../models";
import {EasyFlex} from "../../../partials/ActionHeader";
import ClientPopup from "../ClientPopup";
import {COLORS} from "../../../../Logic/constants";
import {IconCar, IconClose, IconEdit, IconMore, IconVisibility} from "../../../../Logic/icons";
import cn from "classnames";
import Ink from "react-ink";
import {isUndefined} from "lodash";


export const ClientOption = ({children, style, className, ...props}) => <EasyFlex className={cn(className, 'client-option-button')} align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{padding: 12, position: "relative", borderRadius: '50%', overflow: "hidden",...style}} {...props}>
	{children}
	<Ink/>
</EasyFlex>;




export class ClientGrid extends React.Component {
	static propTypes = {
		client: PropTypes.oneOfType([
			PropTypes.shape({
				client_id: PropTypes.number.isRequired
			}),
			PropTypes.instanceOf(Client)
		]).isRequired,
		hidePopup: PropTypes.bool,
		onShowDetails: PropTypes.func,
		onShowVehicles: PropTypes.func,
		onEdit: PropTypes.func
	};
	static defaultProps = {
		hidePopup: false
	};
	
	state = {
		options: false
	};
	
	toggleOptions = (options = null) => (e) => {
		e && e.stopPropagation && e.stopPropagation();
		this.setState(state => ({
			options: null === options ? !state.options: options
		}));
	}
	
	outsideClick = e => {
		if (this.state.options && e && e.target && (!e.target.closest('.icon-option'))) {
			this.toggleOptions(false)();
		}
	}
	
	componentDidMount() {
		window.addEventListener('click', this.outsideClick);
	}
	
	componentWillUnmount() {
		window.removeEventListener('click', this.outsideClick);
	}
	
	render() {
		let {client, hidePopup, onShowDetails, onEdit, onShowVehicles, t} = this.props;
		let c = client;
		if (!(c instanceof Client)) {
			c = new Client(client);
		}
		const hasMore = Boolean(onShowVehicles || onShowDetails || onEdit);
		return <React.Fragment>
			<EasyFlex valign={EasyFlex.valign.CENTER} className={"client-origin"}>
				<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
					<div style={{marginRight: 20}}>
						<ClientPopup modal client={hidePopup ? null : c} flowing popupProps={{style: {backgroundColor: 'transparent'}}}>
							<c.Icon color={c.self ? COLORS.SEMANTIC_BLUE : 'grey'} style={{width: 40, height: 40}}/>
						</ClientPopup>
					</div>
				</EasyFlex>
				<EasyFlex direction={EasyFlex.direction.COLUMN} grow={1} shrink={1}>
					<strong onClick={onShowDetails ? onShowDetails.bind(null, c) : null} className={cn({'cursor pointer': Boolean(onShowDetails)})}>{c.is_company ? c.company_name : c.contact_full}</strong>
					<small>{c.street_full || <span style={{color: "#ccc"}}>Keine Straße angegeben</span>}</small>
					<small>{c.city_full || <span style={{color: "#ccc"}}>Kein Ort angegeben</span>}</small>
					{/*{!isUndefined(c.origin.vehicles) && <small>{c.origin.vehicles} Fahreug(e) {trueNull(c.origin.loan_cars) && <span>davon {c.origin.loan_cars} Leihwagen</span>}</small>}*/}
					{!isUndefined(c.origin.vehicles) && <small>{t('vehicles.total-and-loan', {total: c.origin.vehicles, loan: c.origin.loan_cars || 0})}</small>}
				</EasyFlex>
				{hasMore && <EasyFlex direction={EasyFlex.direction.COLUMN} alignSelf={EasyFlex.alignSelf.START} shrink={0}>
					<div className={"client-option-trigger"}><ClientOption onClick={this.toggleOptions(true)}><IconMore
						color={'#777'}/></ClientOption></div>
					{/*<div><IconButton><IconVisibility color={'#777'}/></IconButton></div>*/}
					{/*<div><IconButton><IconCar color={'#777'}/></IconButton></div>*/}
				</EasyFlex>}
			</EasyFlex>
			{/*{this.state.options && <Popover open={this.state.client && this.state.client.client_id === c.client_id} className={"client-options"} anchorEl={this.state.options} anchorOrigin={{horizontal: "right", vertical: "top"}} targetOrigin={{horizontal: 'right', vertical: 'top'}} onRequestClose={this.setOptions(null)}>*/}
			{hasMore && <div className={cn("client-options", {open: this.state.options})}>
				<div className={"client-title"}>{(c && c.contact_full) || 'n.v.'}</div>
				<div className={"client-separator"}/>
				<EasyFlex className={"client-buttons"} valign={EasyFlex.valign.CENTER}>
					{onEdit && <div className="icon-option"><ClientOption><IconEdit/></ClientOption></div>}
					{onShowDetails && <div className="icon-option"><ClientOption onClick={onShowDetails.bind(null, c)}><IconVisibility/></ClientOption></div>}
					{onShowVehicles && <div className="icon-option">
							<ClientOption onClick={onShowVehicles.bind(null, c)} style={{position: 'relative'}}><IconCar/></ClientOption>
						</div>
					}
					<div className="icon-option close">
						<ClientOption
							onClick={this.toggleOptions(false)}
						>
							<IconClose color={COLORS.SEMANTIC_RED}/>
						</ClientOption>
					</div>
				</EasyFlex>
			</div>}
		</React.Fragment>
	}
}
ClientGrid = translate()(ClientGrid);