import React from 'react'
import PropTypes from 'prop-types'
import {Button, Message, Modal} from "semantic-ui-react";
import {WheelSearchForm} from "./WheelSearchForm";
import uuid from 'uuid'
import {wheelCall_getEwoTw_tiresetRemoval, wheelCall_list, wheelDispatch_ewoTw_import} from "../../../actions/wheelActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {CircleNotchLoader} from "../../Loaders";
import {WheelList} from "./WheelList";
import {Trans} from "react-i18next";
import {WheelEditor, WheelModuleInactiveLabel} from "./WheelEditor";
import {EasyFlex} from "../../partials/ActionHeader";
import {trueNull} from "../../../Logic/extensions";
import {withHouse} from "../../../Tools/RightsProvider";
import {FSModal, Search, TireSetListView} from "./ewotw/EwoTW";

export class WheelSearchModal extends React.Component {
	static propTypes = {
		clientId: PropTypes.number,
		vehicleId: PropTypes.number,
		onSearch: PropTypes.func.isRequired,
		onImport: PropTypes.func.isRequired,
		onNotification: PropTypes.func.isRequired,
		onSelect: PropTypes.func,
		closeOnSelect: PropTypes.bool,
		loadOnDisplay: PropTypes.bool,
		open: PropTypes.bool,
		onClose: PropTypes.func,
		importValue: PropTypes.string,
		skipDetail: PropTypes.bool
	}
	static defaultProps = {
		onSearch: params => wheelCall_list(params),
		onImport: (id, cid) => wheelDispatch_ewoTw_import(id, cid),
		onNotification: dispatchSnack,
		closeOnSelect: true,
		importValue: '',
		skipDetail: false
	}
	
	state = {
		searching: false,
		list: null,
		wheel: null,
		ewoTW: false,
		tireSet: null,
		importing: false
	}
	formId = uuid.v4()
	formRef = null
	
	search = async params => {
		const {onSearch, onNotification} = this.props;
		try {
			this.setState({searching: true, list: []})
			const response = await onSearch(params)
			this.setState({list: Object.values(response)})
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({searching: false})
		}
	}
	
	import = async (tireset) => {
		try {
			this.setState({importing: true})
			const wheel = await this.props.onImport(tireset.storageOrderId, this.props.clientId || null);
			this.setState({wheel: this.props.skipDetail ? null : wheel, tireSet: null, ewoTW: false}, () => {
				this.props.skipDetail && this.handleSelect(wheel)
			})
		} catch (e) {
			console.error(e)
			this.props.onNotification(e.message, 'alert')
		} finally {
			this.setState({importing: false})
		}
	}
	
	componentDidMount() {
		this.props.loadOnDisplay && this.props.open && this.formRef.submit()
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.loadOnDisplay && this.props.open !== prevProps.open && this.props.open && this.formRef) {
			this.formRef();
		}
	}
	
	handleSelect = wheel => {
		const {onSelect, skipDetail, closeOnSelect, onClose} = this.props
		if (skipDetail) {
			closeOnSelect && onClose && onClose()
			onSelect && onSelect(wheel)
		} else {
			this.setState({wheel})
		}
	}
	
	render() {
		const {searching, list, wheel, importing} = this.state
		const {onSearch, onNotification, onClose, onSelect, clientId, vehicleId, open, loadOnDisplay, closeOnSelect, dispatch, houseData, onImport, importValue, skipDetail, ...props} = this.props
		return (
			<React.Fragment>
				<Modal open={open} centered={false} onClose={onClose} {...props}>
					<Modal.Content>
						<WheelSearchForm provideMethod={r => this.formRef = r} clientId={clientId} vehicleId={vehicleId} formId={this.formId} onChange={this.search}/>
					</Modal.Content>
					<Modal.Actions>
						{houseData.ewo_tw && <Button type={'button'} onClick={() => this.setState({ewoTW: true})}>EwoTW Import</Button>}
						<Button type={'reset'} form={this.formId}>Reset</Button>
						<Button positive type={'submit'} form={this.formId}>Suchen</Button>
					</Modal.Actions>
				</Modal>
				<FSModal basic open={!!list} centered={false} onClose={() => this.setState({list: null})} >
					<Modal.Content scrolling>
						{searching && <CircleNotchLoader loading/>}
						{list && <WheelList list={list} headerProps={{sticky: true, stickyTop: -22}} onSelect={this.handleSelect}/>}
					</Modal.Content>
					<Modal.Actions>
						<Button inverted onClick={() => this.setState({list: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>
				<Modal open={!!wheel} centered={false} onClose={() => this.setState({wheel: null})}>
					<Modal.Content>
						<pre>FUFU</pre>
						<WheelModuleInactiveLabel ribbon={'right'}/>
						{wheel && <WheelEditor wheel={wheel}>
							{trueNull(onSelect) && <EasyFlex align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER}>
								<Button type={'button'} onClick={() => this.setState({wheel: null})}><Trans i18nKey={'actions.cancel'}/></Button>
								<Button type={'button'} positive onClick={() => this.setState({wheel: null, searching: false, list: null}, () => {
									closeOnSelect && onClose && onClose()
									onSelect && onSelect(wheel)
								})}><Trans i18nKey={'actions.select'}/></Button>
							</EasyFlex>}
						</WheelEditor>}
					</Modal.Content>
					
				</Modal>
				{this.state.ewoTW && <Modal open size={'tiny'} centered={false} onClose={() => this.setState({ewoTW: false})}>
					<Modal.Header>Reifensatz aus EwoTW laden</Modal.Header>
					<Modal.Content>
						<Message info><p>Importieren Sie einen Reifensatz nur, wenn dieser noch nicht in der Datenbank vorhanden ist, bzw. mit <strong>EwoTW verknüpft</strong> ist.</p></Message>
						<EasyFlex align={EasyFlex.align.CENTER}>
							<Search
								defaultValue={this.props.importValue}
								title={'Reifensatz-Suche'}
								label={'Reifensatznummer / Kennzeichen / Kundennummer / Fahrgestellnummer'}
								header={'Reifensätze'}
								request={wheelCall_getEwoTw_tiresetRemoval}
								resultComponent={<TireSetListView loading={importing} onSelect={(tireSet, e) => {
									e && e.stopPropagation()
									this.import(tireSet)
								}}/>}
							/>
						</EasyFlex>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({ewoTW: false})}><Trans i18nKey={'actions.cancel'}/></Button>
					</Modal.Actions>
				</Modal>}
			</React.Fragment>
		);
	}
}
WheelSearchModal = withHouse(WheelSearchModal)