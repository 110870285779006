import {Icon} from "semantic-ui-react";
import {Client} from "../../../../models";
import {EasyFlex} from "../../../partials/ActionHeader";
import ClientPopup from "../../clients/ClientPopup";
import * as React from "react";
import {isFunction} from "lodash";
import VehicleHeader from "./VehicleHeader";

const clickHandler = (onClick, client, name) => (e) => {
	if (isFunction(onClick)) {
		onClick(e, client, name);
	}
};

const Header = ({client: c, vehicle, onClick}) => {
	if (!c) {
		return (<div><Icon loading name={"spinner"}/></div>);
	}
	const client = Client._(c);
	return (
		<EasyFlex valign={EasyFlex.valign.CENTER} wrap={EasyFlex.wrap.YES} style={{paddingBottom: 7, borderBottom: "1px solid #f5f5f5"}}>
			<ClientPopup client={client.origin}>
				<strong onClick={clickHandler(onClick, client, 'name')} className={"monospace"} style={{marginRight: 25, marginTop: 3, marginBottom: 3}}><Icon name={"user circle"}/>{client.name}</strong>
			</ClientPopup>
			<VehicleHeader vehicle={vehicle}/>
		</EasyFlex>
	);
};


export default Header;
