import * as React from "react";
import PropTypes from "prop-types";
import {isInteger} from "lodash";
import {translate} from "react-i18next";

class Countdown extends React.PureComponent {
	static propTypes = {
		targetDate: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
		render: PropTypes.func,
		period: PropTypes.number,
		paddingDays: PropTypes.number,
		paddingHours: PropTypes.number,
		paddingMinutes: PropTypes.number,
		paddingSeconds: PropTypes.number,
		paddingMillis: PropTypes.number,
		liveChange: PropTypes.bool,
		onTrigger: PropTypes.func,
		triggers: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			timer: PropTypes.number.isRequired
		})),
		skipDays: PropTypes.bool
	};
	static defaultProps = {
		period: 1000,
		paddingDays: 0,
		paddingHours: 2,
		paddingMinutes: 2,
		paddingSeconds: 2,
		paddingMillis: 3,
		liveChange: false,
		skipDays: false
	};
	state = {
		countdown: 0
	};
	_countdown = null;
	_lastTime = null;
	_tracker = {};
	
	constructor(props) {
		super(props);
		const {targetDate} = props;
		if (targetDate instanceof Date) {
			this._lastTime = targetDate.getTime();
			this.state.countdown = targetDate.getTime() - Date.now();
		} else if (isInteger(targetDate)) {
			this._lastTime = targetDate;
			this.state.countdown = targetDate - Date.now();
		}
	}
	
	getParts = (total) => {
		return calculateDifferenceByMillis(total, !this.props.skipDays);
	};
	
	padded = (result) => applyPadding(result, [this.props.paddingDays, this.props.paddingHours, this.props.paddingMinutes, this.props.paddingSeconds, this.props.paddingMillis]);
	
	componentDidMount() {
		const period = Math.max(1, this.props.period);
		this._countdown = window.setInterval(() => {
			this.setState(state => {
				const countdown = state.countdown - period;
				const {triggers, onTrigger} = this.props;
				if (triggers && triggers.length && onTrigger) {
					for (const k in triggers ) {
						if ( triggers.hasOwnProperty(k) ) {
							const trigger = triggers[k];
							if (!this._tracker[k]) {
								if (countdown <= trigger.time * 1000) {
									onTrigger(trigger, Math.round(countdown / 1000), countdown);
									this._tracker[k] = true;
								}
							}
						}
					}
				}
				return {countdown};
			});
			
		}, period);
	}
	// OKAY
	// componentWillReceiveProps({targetDate}, nextContext) {
	// 	if (this.props.liveChange) {
	// 		if ( targetDate instanceof Date || isInteger(Date)) {
	// 			const time = targetDate instanceof Date ? targetDate.getTime() : targetDate;
	// 			if ( time !== this._lastTime) {
	// 				this._lastTime = time;
	// 				this.setState({countdown: time - Date.now()});
	// 			}
	// 		}
	// 	}
	// }
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {targetDate} = this.props
		if (this.props.liveChange) {
			if ( targetDate instanceof Date || isInteger(Date)) {
				const time = targetDate instanceof Date ? targetDate.getTime() : targetDate;
				if ( time !== this._lastTime) {
					this._lastTime = time;
					this.setState({countdown: time - Date.now()});
				}
			}
		}
	}
	
	componentWillUnmount() {
		window.clearInterval(this._countdown);
	}
	
	defaultRender = (props) => {
		const {
			render,
			tReady,
			i18n,
			targetDate,
			period,
			paddingDays,
			paddingHours,
			paddingMinutes,
			paddingSeconds,
			paddingMillis,
			...remainingProps
		} = this.props;
		if (this.props.render) {
			return this.props.render(props, Math.abs(Math.round(this.state.countdown/1000)), remainingProps, this);
		}
		const [negative, days, hours, minutes, seconds] = this.padded(props);
		if (negative) {
			return <React.Fragment>Seit {days} Tage {hours}:{minutes}:{seconds} abgelaufen.</React.Fragment>;
		}
		return <React.Fragment>{days} Tage {hours}:{minutes}:{seconds}</React.Fragment>;
	};
	
	render() {
		const {countdown} = this.state;
		const timeParts = this.getParts(countdown);
		return this.defaultRender(timeParts);
	}
}

const calculateDifferenceBySeconds = (total, extractDays = true) => {
	const negative = total < 0;
	total = Math.abs(total);
	let days = 0;
	if (extractDays) {
		days = Math.floor(total / (60 * 60 * 24));
		total = total % (60 * 60 * 24);
	}
	const hours = Math.floor(total / (60 * 60));
	total = total % (60 * 60);
	const minutes = Math.floor(total / (60));
	const seconds = total % 60;
	
	return [negative, days, hours, minutes, seconds, 0];
};

const calculateDifferenceByMillis = (total, extractDays = true) => {
	const negative = total < 0;
	total = Math.abs(total);
	const millis = total % 1000;
	total = Math.round(total / 1000);
	const result = calculateDifferenceBySeconds(total, extractDays);
	result[0] = negative;
	result[5] = millis;
	return result;
};

const calculateDifferenceByDates = (bigger, smaller, extractDays = true) => {
	if (bigger instanceof Date) {
		bigger = bigger.getTime();
	}
	if (smaller instanceof Date) {
		smaller = smaller.getTime();
	}
	const difference = bigger - smaller;
	return calculateDifferenceByMillis(difference, extractDays);
};

const applyPadding = (resultArray, padding = [0, 2, 2, 2, 3]) => {
	return resultArray.map((value, index) => {
		if (index) {
			return String(value).padStart(padding[index - 1] || 0, '0');
		}
		return value;
	});
};

const getFastTimeString = (seconds, extractDays = true) => applyPadding(calculateDifferenceBySeconds(seconds, extractDays)).slice(2,5).join(":");

const renderSimple = (data) => applyPadding(data).slice(2,5).join(":");

const renderBeautiful = ([negative, days, hours, minutes, seconds]) => {
	let time;
	if (!negative) {
		if (days) {
			time = `Noch über ${days} Tage`;
		} else if (hours) {
			time = `Noch über ${hours} Stunden`;
		} else if (minutes) {
			if (minutes > 9) {
				time = `Noch über ${minutes} Minuten`;
			} else {
				time = `Noch ca ${minutes} Minuten`;
			}
		} else {
			time = `Nur noch ${seconds} Sekunden !`;
		}
	} else {
		if (days) {
			time = `Seit über ${days} Tagen fällig!`;
		} else if (hours) {
			time = `Seit über ${hours} Stunden fällig!`;
		} else if (minutes) {
			time = `Mit ${minutes} Minuten und ${seconds} Sekungen überfällig`;
		} else {
			time = `Wenige Sekunden überfällig`;
		}
	}
	return <span>{time}</span>
};

export default translate()(Countdown);
export {renderBeautiful, calculateDifferenceByMillis, calculateDifferenceBySeconds, calculateDifferenceByDates, applyPadding, getFastTimeString, renderSimple};