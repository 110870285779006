/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {OrderVehicleSelector} from "../../cointainer/intern/orders/OrderVehicleSelector";
import {trueNull} from "../../Logic/extensions";
import {OrderServiceSelector} from "../../cointainer/intern/orders/OrderServiceSelector";
import {OrderServiceSelectionList} from "../../cointainer/intern/orders/OrderServiceSelectionList";
import {OrderOptionsView} from "../intern/orders/OrderOptionsView";
import {Button, Modal, Segment} from "semantic-ui-react";
import {DateRangeSelector} from "../intern/orders/finder/DateRangeSelector";
import {orderCall__find} from "../../actions/orderActions";
import {dispatchSnack} from "../../actions/snackbarActions";
import {omit} from "lodash/object";
import {AppointmentList, AppointmentOptionView} from "../intern/orders/finder/OrderAppointmentFinder";


export class FindAppointmentTest extends React.Component {
	static propTypes = {
		onSearch: PropTypes.func,
		onNotificationBad: PropTypes.func
	};
	static defaultProps = {
		onSearch: orderCall__find,
		onNotificationBad: message => dispatchSnack(message, 'alert')
	};
	state = {
		show_search: true,
		params: {
			vehicle: null,
			services: [],
			loan_car: false,
			direct_service: false,
			waiting: false,
			delivery_service: false,
			target_date: null,
			deliver_date: null
		},
		worktime: 0,
		searching: false,
		results: null,
		error: null
	}
	
	get params() {
		let params = omit(this.state.params, ['vehicle']);
		params.vehicle_id = this.state.params.vehicle.vehicle_id;
		params.services = params.services.map(s => s.service_id);
		return params;
	}
	
	setParams = params => this.setState(state => ({
		...state,
		params: {
			...state.params,
			...params
		}
	}));
	
	search = async () => {
		const {onSearch, onNotificationBad} = this.props;
		try {
			this.setState({searching: true, error: null});
			const response = await onSearch(this.params);
			this.setState({results: response});
		} catch (e) {
			console.error(e);
			this.setState({error: e});
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({searching: false});
		}
	};
	
	get search_params() {
		return {
			vehicle_id: 250,
			services: [34]
		};
	}
	
	render() {
		const {
			show_search,
			params: {
				vehicle,
				services,
				direct_service,
				delivery_service,
				loan_car,
				waiting,
				target_date,
				deliver_date
			},
			searching,
			results,
			error
		} = this.state;
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Fufufu</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					{show_search && <Modal defaultOpen centered={false}>
						<Modal.Header>Terminoptionen</Modal.Header>
						<Modal.Content scrolling>
							<AppointmentList
								params={this.search_params}
								onSelect={(result) => this.setState({show_search: false}, () => console.debug('selected result:', result))}
							/>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => this.setState({show_search: false})}>Schließen</Button>
						</Modal.Actions>
					</Modal>}
					<OrderVehicleSelector onSelectVehicle={vehicle => this.setParams({vehicle})} vehicle={vehicle} onUnselect={() => this.setParams({vehicle: null})}/>
					{vehicle && <div>
						<OrderServiceSelector vehicle={vehicle} onChange={services => this.setParams({services})} asGrid sortable/>
						{trueNull(services.length) && <div>
							<OrderServiceSelectionList
								vehicle={vehicle}
								services={services}
								updateOnChange
								onNotifyWorktime={worktime => this.setState({worktime})}
							/>
							<OrderOptionsView
								deliveryService={delivery_service}
								loanCar={loan_car}
								setDeliveryService={delivery_service => this.setParams({delivery_service})}
								waiting={waiting}
								setWaiting={waiting => this.setParams({waiting})}
								setDirectService={direct_service => this.setParams({direct_service})}
								hasLoanCarModule={true}
								directService={direct_service}
								setLoanCar={loan_car => this.setParams({loan_car})}
								segmentProps={{basic: true, color: null}}
								celled={'internally'}
							/>
							<Segment basic padded>
								<DateRangeSelector
									start_point={deliver_date}
									end_point={target_date}
									changeStartPoint={deliver_date => this.setParams({deliver_date})}
									changeEndPoint={target_date => this.setParams({target_date})}
								/>
							</Segment>
							<Button attached={'bottom'} secondary loading={searching} onClick={this.search}>Suchen</Button>
							{trueNull(results && results.length) && <div style={{padding: 20}}>
								{results.map(result => <AppointmentOptionView key={result.result.token} {...result}/>)}
							</div>}
						</div>}
					</div>}
				</div>
			</div>
		);
	}
}