import {LOADER_ACTION, RESET_ACTION} from "../actions/types";
const defaultState = {
	global: 0,
	detail: {
	
	}
};
const _inc = what => {
	if ( what === undefined )  return 1;
	return what + 1;
};
const _dec = what => {
	if ( what === undefined || what <= 0 ) return 0;
	return what -1;
};
const loaderState = (state = defaultState, {type, source, id}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case LOADER_ACTION.ADD:
			return {
				...state,
				global: state.global + 1
			};
		case LOADER_ACTION.DONE:
			return {
				...state,
				global: Math.max(0, state.global - 1)
			};
		case LOADER_ACTION.SET:
			return {
				...state,
				detail: {
					...state.detail,
					[id]: _inc(state.detail[id])
				}
			};
		case LOADER_ACTION.UNSET:
			return {
				...state,
				detail: {
					...state.detail,
					[id]: _dec(state.detail[id])
				}
			};
		default:
			return state;
	}
};

export default loaderState;