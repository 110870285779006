import * as React from 'react';
import cn from "classnames";
import {EasyFlex} from "../../../partials/ActionHeader";
import PropTypes from "prop-types";
import {MaterialCompoundDateInput} from "../../../../Tools/DatePicker";
import {Icon} from "semantic-ui-react";
import {DefaultPicker, pipeDateFunc} from "./Helpers";
import {translate} from "react-i18next";

// const MaterialPicker = withMaterial(DatePicker);



const DateResetButton = ({reset}) => (
	<div style={{poition: 'absolute'}}>
		<Icon
			color={'grey'}
			className={'cursor pointer'}
			name={'close'}
			circular
			style={{border: 'none', boxShadow: 'none', position: 'absolute', right: 0, bottom: 8}}
			onClick={reset}
		/>
	</div>
);





export let DateRangeSelector = ({className, start_point, end_point, changeStartPoint, changeEndPoint, timeIntervals, dateProps, startProps, endProps, startChildren, endChildren}) => {
	return (
		<div className={cn('order-appointment-search-date-range', className)}>
			<EasyFlex align={EasyFlex.align.CENTER} wrap>
				<div style={{position: 'relative'}}>
					<DefaultPicker
						placeholderText={'Startzeit'}
						selectsStart
						minDate={new Date()}
						maxDate={end_point}
						startDate={start_point}
						endDate={end_point}
						selected={start_point}
						timeIntervals={timeIntervals}
						onChange={pipeDateFunc(changeStartPoint)}
						{...dateProps}
						{...startProps}
						children={startChildren}
					/>
					{start_point && <DateResetButton reset={changeStartPoint.bind(null, null)}/>}
				</div>
				<div style={{position: 'relative'}}>
					<DefaultPicker
						placeholderText={'Endzeit'}
						selectsEnd
						minDate={new Date(Math.max(start_point, Date.now()))}
						startDate={start_point}
						endDate={end_point}
						selected={end_point}
						timeIntervals={timeIntervals}
						onChange={pipeDateFunc(changeEndPoint)}
						{...dateProps}
						{...endProps}
						children={endChildren}
					/>
					{end_point && <DateResetButton reset={changeEndPoint.bind(null, null)}/>}
				</div>
			
			</EasyFlex>
		</div>
	);
};
DateRangeSelector.propTypes = {
	className: PropTypes.any,
	start_point: PropTypes.any,
	end_point: PropTypes.any,
	changeStartPoint: PropTypes.any,
	changeEndPoint: PropTypes.any,
	timeIntervals: PropTypes.number,
	dateProps: PropTypes.object,
	startProps: PropTypes.object,
	endProps: PropTypes.object
};
DateRangeSelector.defaultProps = {
	timeIntervals: 15,
	dateProps: {
		monthsShown: 1,
		showWeekNumbers: true
	}
};

DateRangeSelector = translate()(DateRangeSelector);

export let MaterialRangeSelector = ({className, strict, startPoint, endPoint, changeStartPoint, changeEndPoint, dateProps, startProps, endProps, startChildren, endChildren}) => (
	<div className={cn('order-appointment-search-date-range', className)}>
		<EasyFlex align={EasyFlex.align.CENTER} wrap>
			<div style={{position: 'relative'}}>
				<MaterialCompoundDateInput
					date={startPoint}
					selectsStart
					minDate={new Date()}
					maxDate={strict ? endPoint : null}
					startDate={startPoint}
					endDate={endPoint}
					baseDate={endPoint}
					onChange={changeStartPoint}
					children={startChildren}
					dateLabel={'Startzeit'}
					timeLabel={<span>&nbsp;</span>}
					timePlaceholder={'__:__'}
					timeStyle={{width: 95}}
					{...dateProps}
					{...startProps}
				/>
				{startPoint && <DateResetButton reset={changeStartPoint.bind(null, null)}/>}
			</div>
			<div style={{position: 'relative'}}>
				<MaterialCompoundDateInput
					date={endPoint}
					selectsEnd
					minDate={strict ? (startPoint || new Date()) : new Date()}
					startDate={startPoint}
					endDate={endPoint}
					baseDate={startPoint}
					onChange={changeEndPoint}
					children={endChildren}
					dateLabel={'Endzeit'}
					timeLabel={<span>&nbsp;</span>}
					endOfDay
					timePlaceholder={'__:__'}
					timeStyle={{width: 95}}
					{...dateProps}
					{...endProps}
				/>
				{endPoint && <DateResetButton reset={changeEndPoint.bind(null, null)}/>}
			</div>
		</EasyFlex>
	</div>
);
MaterialRangeSelector.propTypes = {
	strict: PropTypes.bool,
	className: PropTypes.string,
	startPoint: PropTypes.any,
	endPoint: PropTypes.any,
	changeStartPoint: PropTypes.any,
	changeEndPoint: PropTypes.any,
	dateProps: PropTypes.object,
	startProps: PropTypes.object,
	endProps: PropTypes.object
};
MaterialRangeSelector.defaultProps = {
	dateProps: {
		monthsShown: 1,
		showWeekNumbers: true
	}
};
MaterialRangeSelector = translate()(MaterialRangeSelector);