import {setLoader, unsetLoader} from "./loaderActions";
import {PROC_LOAN_CARS_FETCH, PROC_LOAN_CARS_REMOVE, PROC_LOAN_CARS_SEND} from "./index";
import {addError} from "./errorActions";
import isFunc from 'lodash/isFunction';
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {vehicleAction__fetch, vehicleState__add} from "./vehicleActions";
import {LOAN_CAR_ACTION} from "./types";
import {clientState__insert} from "./clientActions";
import {Collector} from "../Logic/helper";
import {mapState__loanCars_add, mapState__vehicles_add} from "./mapActions";

export const loanCarState__set = (loan_cars) => ({type: LOAN_CAR_ACTION.SET, loan_cars});
export const loanCarState__setSchedule = (schedule) => ({type: LOAN_CAR_ACTION.SET_SCHEDULE, schedule});
export const loanCarState__addSchedule = (schedule) => ({type: LOAN_CAR_ACTION.ADD_SCHEDULE, schedule});
export const loanCarState__removeSchedule = (schedule) => ({type: LOAN_CAR_ACTION.REMOVE_SCHEDULE, schedule});
export const loanCarState__setScheduleRange = (range_start, range_end) => ({
	type: LOAN_CAR_ACTION.SET_SCHEDULE_RANGE,
	range_start,
	range_end
});
export const loanCarState__setOrderLoanCars = (loan_cars) => ({type: LOAN_CAR_ACTION.ORDER_LOAN_CAR, loan_cars});

const dateToString = (date) => {
	if (date instanceof Date) {
		return date.toISOString();
	}
	return date;
};
export const loanCarCall__get = (vehicle_id) => GET("/loan-car" + (vehicle_id ? `/${vehicle_id}` : ""));
export const loanCarCall__schedule = (day, range) => GET('/loan-car/schedule' + (day ? `/${dateToString(day)}` : "") + (day && range ? `/${range}` : ""));
export const loanCarCall__putSchedule = (data) => PUT("/loan-car/schedule", data);
export const loanCarCall__postSchedule = (data) => POST("/loan-car/schedule", data);
export const loanCarCall__removeSchedule = (schedule) => DELETE(`/loan-car/schedule/${schedule.loan_car_schedule_id || schedule}`);
export const loanCarCall__getByOrder = (order) => GET(`/loan-car/order/${order.order_id || order}`);
export const loanCarCall__collect = (loan_car_schedules) => POST('/loan-car/collect', {list: loan_car_schedules});
export const loanCarCall__remove = id => DELETE('/vehicle/loancar/' + id);
export const loanCarCall__set = (data) => POST('/vehicle/loancar', data);
export const loanCarCall__conflicts = vehicle_id => GET('/loan-car/conflicts/' + vehicle_id);
/**
 *
 * @param {Object} data
 * @param {number} data.vehicle_id
 * @param {*} data.start_point
 * @param {*} data.end_point
 * @returns {Promise | Promise<unknown>}
 */
export const loanCarCall__update = data => POST('/loan-car', data);
/**
 *
 * @param {Object} data
 * @param {number} data.vehicle_id
 * @param {*} data.start_point
 * @param {*} data.end_point
 * @returns {Promise | Promise<unknown>}
 */
export const loanCarCall__rangedConflicts = data => POST('/loan-car/conflicts', data);

/**
 *
 * @param {Object} params
 * @param {(string|int|Date)} params.start_point
 * @param {(string|int|Date)} params.end_point
 * @param {boolean} [params.no_delay]
 * @param {Array} [params.ignore_vehicles]
 * @return {Promise}
 */
export const loanCarCall__findInRange = params => POST('/loan-car/find/range', params);
/**
 *
 * @param {(number|Object)} order_id
 * @param {number} [order_id.order_id]
 * @return {Promise}
 */
export const loanCarCall__findOfOrder = order_id => GET(`/loan-car/find/order/${order_id.order_id || order_id}`);
/**
 *
 * @param {Object} data
 * @param {number} data.vehicle_id
 * @param {number} data.order_id
 * @param {(string|int|Date)} data.start_point
 * @param {(string|int|Date)} data.end_point
 * @return {*}
 */
export const loanCarCall__assignOrder = (data) => POST('/loan-car/assign/order', data);
export const loanCarCall__replace = (loan_car_schedule_id, replace_id) => POST('/loan-car/replace', {
	loan_car_schedule_id,
	replace_id
});
export const loanCarCall__getAll = () => GET('/loan-car/all');
export const loanCarCall__activeSchedule = vehicle_id => GET('/loan-car/schedules/' + vehicle_id);
export const loanCarCall__scheduleById = schedule_id => GET('/loan-car/schedule/id/' + schedule_id);

export const loanCarAction__fetchAvailable_pure = () => GET('/loan-car/available');

export const loanCarAction__fetch = (onSuccess) => vehicleAction__fetch(onSuccess);

export const loanCarAction__update = data =>
	async dispatch => {
		try {
			const result = await loanCarCall__update(data);
			if (result.ack) {
				dispatch(loanCarState__set({[result.vehicle.vehicle_id]: result.vehicle}));
				dispatch(mapState__vehicles_add(result.vehicle));
			}
			return result;
		} catch (e) {
			dispatch(addError(e, false));
			throw e;
		}
	}

export const loanCarAction__get = (vehicle_id, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_LOAN_CARS_FETCH));
			const result = await loanCarCall__get(vehicle_id);
			if (result.loan_car) {
				dispatch(loanCarState__set({[result.loan_car.vehicle_id]: result.loan_car}));
			}
			if (result.loan_cars) {
				dispatch(loanCarState__set(result.loan_cars));
				dispatch(mapState__loanCars_add(result.loan_cars));
			}
			isFunc(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_FETCH));
		}
	};

export const loanCarAction__schedule = (day, range, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_LOAN_CARS_FETCH));
			const result = await loanCarCall__schedule(day, range);
			dispatch(loanCarState__set(result.loan_cars));
			dispatch(loanCarState__setSchedule(result.schedule));
			dispatch(loanCarState__setScheduleRange(result.rangestart, result.rangeend));
			dispatch(clientState__insert(result.clients));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_FETCH));
		}
	};

export const loanCarAction__set = (loan_car, value, onSuccess) =>
	async dispatch => {
		const vehicle_id = loan_car.vehicle_id || loan_car;
		try {
			dispatch(setLoader(PROC_LOAN_CARS_SEND, vehicle_id));
			const result = await POST('/loan-car', {vehicle_id, value});
			dispatch(vehicleState__add(result));
			isFunc(onSuccess(result));
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_SEND, vehicle_id));
		}
	};

export const loanCarAction__putSchedule = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_LOAN_CARS_SEND));
			const result = await loanCarCall__putSchedule(data);
			dispatch(loanCarState__addSchedule(result.schedule));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_SEND));
		}
	};
export const loanCarAction__postSchedule = loanCarAction__putSchedule;

export const loanCarAction__removeSchedule = (schedule, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_LOAN_CARS_REMOVE));
			const result = await loanCarCall__removeSchedule(schedule);
			console.error("result of delete", result);
			dispatch(loanCarState__removeSchedule(result.schedule));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_REMOVE));
		}
	};

export const loanCarAction__getByOrder = (order, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_LOAN_CARS_FETCH, order_id));
			const result = await loanCarCall__getByOrder(order);
			dispatch(loanCarState__setOrderLoanCars(result.loan_cars));
			dispatch(loanCarState__set(result.list));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_FETCH, order_id));
		}
	};

export const loanCarAction__assignOrder = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_LOAN_CARS_SEND));
			const result = await loanCarCall__assignOrder(data);
			dispatch(loanCarState__addSchedule(result.schedule));
			dispatch(loanCarState__setOrderLoanCars(result.loan_cars));
			dispatch(loanCarState__set(result.list));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_LOAN_CARS_SEND));
		}
	};

let __collect = {};
export const loanCarAction__collect = (schedules) =>
	Collector._(__collect, schedules, 'loan_car_schedule_id')
		.withLoader([PROC_LOAN_CARS_FETCH])
		.withStateProvider('map.loan_cars')
		.withApiProvider(list => loanCarCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__loanCars_add(result)))
		.execute();
	