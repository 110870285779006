import * as React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import constants, {ACCESS_PROP_DEFAULTS, ACCESS_PROP_DEFINTIONS} from "../../../../Logic/constants";
import {TimePicker} from "material-ui";
import {Col, Grid, Row} from "react-bootstrap";
import {Button, Modal} from "semantic-ui-react";

const moment = extendMoment(Moment);
const dateFormat = constants.DATE_FORMAT;

class WorkerTimeDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			start: null,
			end: null,
			collisions: null
		};
		this.setEvent = this.setEvent.bind(this);
		this.onSave = this.onSave.bind(this);
		this.isOk = this.isOk.bind(this);
	}
	
	setEvent = ({start, end}) => this.setState({start, end});
	
	isOk = () => this.props.event && moment().startOf('day') < moment(this.props.event.end);
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.props.event && prevProps.event === null && this.setEvent(this.props.event);
	}
	
	onSave = () => {
		const {start, end} = this.state;
		this.props.onSave({...this.props.event, start, end}, ({collisions}) => this.setState({collisions}));
	};
	
	render() {
		const {
			event,
			onRequestClose,
			mayDelete,
			// mayChange,
			onDelete
		} = this.props;
		
		if (!event) {
			return null;
		}
		
		const changeable = this.isOk();
		
		return (
		/*	<Dialog
				onRequestClose={onRequestClose}
				title="Arbeitszeit bearbeiten"
				open={event !== null}
				actionsContainerStyle={{display: 'flex', justifyContent: 'space-between'}}
				actions={[
					<RaisedButton primary={true} label="Speichern" onClick={this.onSave} disabled={!changeable}/>,
					<FlatButton label="Schließen" onClick={() => onRequestClose()}/>,
					<RaisedButton label="Löschen" disabled={!mayDelete || !changeable} secondary={true} onClick={() => onDelete(event.id)}/>
				]}
			>
			
			</Dialog>*/
			event && <Modal open centered={false} onClose={onRequestClose}>
				<Modal.Header>Arbeitszeit bearbeiten</Modal.Header>
				<Modal.Content>
					<Grid fluid={true}>
						<Row>
							<Col xs={12} sm={6}>
								<div className="line">
									<div><strong>{this.props.event.title}</strong></div>
									<div>{moment(this.props.event.start).format(dateFormat)}</div>
								</div>
								<div className="line">
									<TimePicker hintText={`Beginnt um`} floatingLabelText="von" disabled={!changeable}
									            value={this.state.start} onChange={(_, start) => this.setState({start})} format="24hr"/>
									<TimePicker hintText="Endet um" value={this.state.end} floatingLabelText="bis" disabled={!changeable}
									            onChange={(_, end) => this.setState({end})} format="24hr"/>
								</div>
							</Col>
							<Col xs={12} sm={6}>
								{!changeable && <div className="line alert-info">Kann nicht mehr geändert werden.</div>}
								{this.state.collisions !== null && <div>
									<strong className="alert-danger">Neue Zeit überlappt mit anderen Zeiten:</strong>
									{this.state.collisions.map(collision => {
										const {start, end} = collision;
										return (
											<div key={`collide-${start}-${end}`} style={{textAlign: 'right'}}>{moment(start).format('HH:mm')} bis {moment(end).format('HH:mm')} Uhr</div>
										);
									})}
								</div>}
							</Col>
						</Row>
					</Grid>
				</Modal.Content>
				<Modal.Actions>
					<Button label="Schließen" onClick={() => onRequestClose()}/>,
					<Button primary={true} label="Speichern" onClick={this.onSave} disabled={!changeable}/>,
					<Button  label="Löschen" disabled={!mayDelete || !changeable} negative onClick={() => onDelete(event.id)}/>
				</Modal.Actions>
			</Modal>
		);
	}
}

WorkerTimeDialog.propTypes = {
	event: PropTypes.object,
	allRelatedEvents: PropTypes.arrayOf(PropTypes.object),
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	...ACCESS_PROP_DEFINTIONS
};
WorkerTimeDialog.defaultProps = {
	event: null,
	allRelatedEvents: [],
	...ACCESS_PROP_DEFAULTS
};

export default WorkerTimeDialog;