import * as React from 'react';
import PropTypes from "prop-types";
import {Icon, Search} from "semantic-ui-react";
import debounce from "lodash/debounce";
import {Flex} from "./ActionHeader";
import SearchIcon from 'material-ui/svg-icons/action/search';
import CloseIcon from "material-ui/svg-icons/navigation/close";
import isFunc from "lodash/isFunction";
import isString from "lodash/isString";
import Spinner from "./Spinner";
import cn from "classnames";
import {FormLabel} from "../../Logic/extensions";
import isArray from "lodash/isArray";
import {translate} from "react-i18next";
import {withValidation} from "./ValidationContext";
import {validateEditFieldClass} from "./ValidationField";
import get from "lodash/get";
import {deepMemoize as memoizeOne} from "../../Logic/extensions";

const DELAY_PROPS = {
	propTypes: {
		onDelay: PropTypes.func.isRequired,
		delay: PropTypes.number
	},
	defaultProps: {
		delay: 500
	}
};

/**
 * WRAPPER FOR DELAYING ACTION OF AN INPUT !!
 *
 * @param Component
 * @param options
 * @return {{propTypes, defaultProps, new(*=): {ref, debouncedValue, input, componentDidMount(): void, componentWillUnmount(): void, render(): *}, prototype: {ref, debouncedValue, input, componentDidMount(): void, componentWillUnmount(): void, render(): *}}}
 * @private
 */
const _delay = (Component, options) => class extends React.Component {
	// eslint-disable-next-line
	static propTypes = DELAY_PROPS.propTypes;
	static defaultProps = DELAY_PROPS.defaultProps;
	
	constructor(props) {
		super(props);
		const {accessValue, accessMethod} = {
			accessValue: (args) => args[0].target.value,
			accessMethod: 'onChange', ...options
		};
		const THIS = this;
		this.debounced = debounce(function (e, f) {
			const args = [].slice.call(arguments);
			if (!props[accessMethod] || !props.onDelay) {
				return;
			}
			const value = isString(accessValue) ? props[accessValue] : accessValue(args);
			if (value === THIS.debouncedValue) {
				return;
			}
			THIS.debouncedValue = value;
			props.onDelay.apply(null, args);
		}, Math.max(0, props.delay));
	}
	
	debouncedValue = '';
	input = null;
	
	ref = (next) => (element) => {
		this.input = element;
		if (isFunc(next)) {
			next(element);
		}
	};
	
	componentDidMount() {
		this.input.addEventListener('keyup', this.debounced, true);
	}
	
	componentWillUnmount() {
		this.input.removeEventListener('keyup', this.debounced);
	}
	
	render() {
		const {delay, onDelay, ref, children, ...props} = this.props;
		return (<Component ref={this.ref(ref)} {...props}/>);
	}
};

const DelayedSearch = _delay(Search);
DelayedSearch.propTypes = {
	...Search.propTypes,
	onDelay: PropTypes.func.isRequired,
	delay: PropTypes.number
};
DelayedSearch.defaultProps = {
	...Search.defaultProps,
	...DELAY_PROPS.defaultProps
};

/**
 * WRAPPER TO DECORATE AN INPUT WITH FLEX AND CHOOSABLE ICON WITH LOADING STATE
 *
 * @param Component
 * @return {{new(): {render(): *}, prototype: {render(): *}}}
 * @private
 */
const _decorate = Component => class extends React.Component {
	state = {
		focused: false
	};
	
	handleFocus = (focused, next) => e => {
		this.setState({focused});
		if (isFunc(next)) {
			next(e);
		}
	};
	
	handleEscape = next => e => {
		if ( this.props.onClear && this.props.value && this.state.focused && e.which === 27) {
			this.props.onClear(e);
		}
		if ( isFunc(next) ) {
			next(e);
		}
	};
	
	translate = (errorText) => isArray(errorText) ? this.props.t.call(null, errorText[0], errorText[1] || {}) : this.props.t(errorText);
	
	render() {
		const {t, i18n, tReady, content, loading, error, icon: IconProp, onFocus, onClear, onBlur, onKeyup, value, ...props} = this.props;
		return (
			<Flex valign="center" className={cn("search-field", {error, focused: this.state.focused})}>
				{Icon && (<FormLabel popup={error ? {
					content: this.translate(error || content),
					inverted: true,
					position: 'top left'
				} : null}>{loading ? <Spinner inverted size={20}/> : IconProp}</FormLabel>)}
				<Component onFocus={this.handleFocus(true, onFocus)} onBlur={this.handleFocus(false, onBlur)} onKeyUp={this.handleEscape(onKeyup)} value={value} {...props}/>
				{onClear && <FormLabel onClick={onClear} className={cn("input-clear", {visible: value && value.length})}><CloseIcon/></FormLabel>}
			</Flex>
		);
	}
};

/**
 * REFERENCE FORWARDING OF INPUT
 *
 * @type {{$$typeof, render}}
 */
const MyInput = React.forwardRef((props, ref) =>
	<input {...props} ref={ref}/>
);


/**
 * DELAYINPUT:
 * # first wrapped by delayer
 * # then decorated
 *
 * @type {{new(): {render, (): *}, prototype: {render, (): *}}}
 */
const DelayedInput = translate()(_decorate(_delay(MyInput)));


/**
 * SearchField, like EditField.js but wrapping <Search/> of Semantic-UI
 */
class SearchField extends React.Component {
	static propTypes = {
		onSearch: PropTypes.func.isRequired,
		onKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		renderItem: PropTypes.func,
		delay: PropTypes.number,
		dropdown: PropTypes.bool,
		onDone: PropTypes.func,
		onResult: PropTypes.func,
		onFail: PropTypes.func,
		resultPath: PropTypes.string,
		standalone: PropTypes.bool,
		onClear: PropTypes.func,
		minSearchLength: PropTypes.number,
	};
	static defaultProps = {
		delay: 500,
		icon: <SearchIcon/>,
		minSearchLength: 1,
		dropdown: true,
		onDone: () => {},
		onResult: () => {},
		onFail: () => {}
	};
	
	
	state = {
		search: '',
		results: [],
		searching: false,
		errorText: null,
	};
	
	componentDidMount() {
		this.handleValue(this.props.value);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.handleValue(this.props.value);
	}
	
	inputRef = null;
	
	handle = (onChange) => (e) => {
		this.inputRef = e;
		const search = e.target.value;
		this.setState({search});
		isFunc(onChange) && onChange(e, e.target);
	};
	
	
	search = async (e) => {
		const value = e.target.value;
		const {minSearchLength, onResult, onFail, onDone, dropdown, resultPath} = this.props;
		if (value.length < minSearchLength) {
			// this.setState({errorText: `Suche muss mindestens ${minSearchLength} Zeichen enthalten`});
			onDone(value, this);
			return;
		}
		const {onSearch} = this.props;
		
		if (!onSearch) {
			this.setState({errorText: 'onSearch not implemented!'});
			onFail(new Error('onSearch not implemented!'));
			onDone(value, this);
			return;
		}
		
		try {
			this.setState({searching: true, errorText: null});
			const _results = await onSearch(value);
			const results = get(_results, resultPath, _results);
			dropdown && this.setState({results});
			onResult(results, value, this);
		} catch (error) {
			this.setState({error: error.message});
			onFail(error, value, this);
		} finally {
			this.setState({searching: false});
			onDone(value, this);
		}
	};
	
	handleValue = memoizeOne( (value) => {
		if (value) {
			this.setState({search: value});
		}
	});
	
	handleClear = next => () => {
		const search = '';
		this.setState({searching: false, search, errorText: null, results: []});
		if (isFunc(next)) {
			// if ( this.inputRef ) {
			// 	return next(this.inputRef, this.inputRef.target);
			// }
			return next(null, {name: this.props.name, value: search});
		}
	};
	
	render() {
		const {search, searching, results: foundResults, errorText: internError} = this.state;
		const {renderItem, label, onResultSelect, required, onKey, name, icon, onChange, loading: propLoading, placeholder,  errorText: externError, isValid, resultPath, onResult, onFail, onDone, standalone, ...remainingProps} = this.props;
		const results = foundResults.map(r => {
			if (isString(onKey)) {
				r.title = r[onKey];
			} else if (isFunc(onKey)) {
				r.title = onKey(r);
			}
			return r;
		});
		const loading = propLoading || searching;
		const errorText = externError || internError;
		const {t, i18n, tReady, onSearch, minSearchLength, dropdown, ...nextProps} = remainingProps;
		return (
			<div className={cn("ith-search", {error: errorText, required: required, standalone})}>
				{label && <label className={cn("search-label")}>{label}</label>}
				<Search
					onSearchChange={this.handle(onChange)}
					value={search}
					name={name}
					showNoResults={dropdown}
					
					input={
						<DelayedInput
							name={name}
							error={errorText}
							icon={icon}
							placeholder={placeholder}
							loading={loading}
							onDelay={this.search}
							onClear={this.handleClear(onChange)}
						/>}
					results={results}
					onResultSelect={onResultSelect}
					{...nextProps}
				/>
				
			</div>
		);
	}
}

export const ValidationSearch = withValidation(validateEditFieldClass(SearchField));
ValidationSearch.propTypes = {
	onSearch: PropTypes.func.isRequired,
	onKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	renderItem: PropTypes.func,
	delay: PropTypes.number,
	silentMode: PropTypes.bool,
	trimmed: PropTypes.bool,
	required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	type: PropTypes.string,
	pattern: PropTypes.string,
	patternMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minlength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxlength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	optional: PropTypes.bool,
	onValidate: PropTypes.func
};
ValidationSearch.defaultProps = {
	...SearchField.defaultProps,
	silentMode: null
};

export default SearchField;
export {_delay as inputDelay}
