import * as React from "react";
import {Button, Message, Segment, Table} from "semantic-ui-react";
import {COLORS} from "../../../../Logic/constants";
import {connect} from "react-redux";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_LOAN_CARS_FETCH, PROC_LOAN_CARS_SEND} from "../../../../actions";
import {loanCarAction__assignOrder, loanCarAction__getByOrder, loanCarCall__findOfOrder} from "../../../../actions/loanCarActions";
import {Trans, translate} from "react-i18next";
import PropTypes from "prop-types";
import moment, {toISO} from "../../../../Logic/Moment";
import {EasyFlex, FlexItem, Space} from "../../../../components/partials/ActionHeader";
import {OrderDatePicker} from "../OrderDatePickerV2";
import {RegistrationMark} from "../../../../components/intern/vehicles";
import {get, isFunction} from "lodash";
import withInit from "../../../../Logic/withInit";
import {isOnline} from "../../../../actions/userActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {ErrorView} from "./ErrorView";
import {LoanCarRemove} from "./LoanCarRemove";
import {Vehicle} from "../../../../models";
import {TimeTrans} from "../../../../Tools";
import {CircleNotchLoader} from "../../../../components/Loaders";


const QuickLoanCarBody = ({loanCar, schedule, t}) => (
	<Table.Body>
		<Table.Row>
			<Table.Cell><Trans defaults="Fahrzeug"/></Table.Cell>
			<Table.Cell>{loanCar.name}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Von"/></Table.Cell>
			<Table.Cell><TimeTrans type={"full"} value={new Date(schedule.start_point)}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Bis"/></Table.Cell>
			<Table.Cell><TimeTrans type={'full'} value={new Date(schedule.end_point)}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="HSN / TSN"/></Table.Cell>
			<Table.Cell>{`${loanCar.hsn} / ${loanCar.tsn}`}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Fahrgestellnummer"/></Table.Cell>
			<Table.Cell>{loanCar.chassis_number || t('...', 'k.A.')}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Kennzeichen"/></Table.Cell>
			<Table.Cell>{loanCar.registration_mark ? <RegistrationMark>{loanCar.registration_mark}</RegistrationMark> : t('...', 'k.A.')}</Table.Cell>
		</Table.Row>
		
		<Table.Row>
			<Table.Cell>&nbsp;</Table.Cell>
			<Table.Cell><LoanCarRemove id={schedule.loan_car_schedule_id}/></Table.Cell>
		</Table.Row>
	</Table.Body>
);

/////////////
//      Quick LoanCar
///////////////////////
export class OrderQuickLoanCar extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		scheduleList: PropTypes.array,
		loanCarsMap: PropTypes.object,
		loading: PropTypes.bool,
		onAssign: PropTypes.func,
		assigning: PropTypes.bool,
		readOnly: PropTypes.bool,
		onlyBody: PropTypes.bool
	};
	
	state = {
		fetching: false,
		loan_cars: [],
		error: null,
		ack: null,
		range_start: null,
		range_end: null,
		selected: 0
	};
	
	lookup = async () => {
		try {
			this.setState({fetching: true, error: null, ack: null, selected: 0});
			const result = await loanCarCall__findOfOrder(this.props.order_id);
			this.setState({ack: result.ack, loan_cars: result.list || [], range_start: result.start || null, range_end: result.end || null});
		} catch (e) {
			this.setState({error: e.error || e.message || String(e)})
		} finally {
			this.setState({fetching: false})
		}
	};
	
	assign = () => {
		const {range_start, range_end, selected} = this.state;
		const data = {
			start_point: toISO(range_start),
			end_point: toISO(range_end),
			order_id: this.props.order_id,
			vehicle_id: selected
		};
		this.props.onAssign(data, () => this.setState({loan_cars: [], error: null, ack: null, range_start: null, range_end: null, selected: 0}));
	};
	
	render() {
		const {loading, scheduleList, t, readOnly, onlyBody} = this.props;
		if (loading) {
			if (onlyBody) {
				return <Table.Body>
					<Table.Row>
						<Table.Cell textAlign={'center'} colSpan={2}>
							<CircleNotchLoader/>
						</Table.Cell>
					</Table.Row>
				</Table.Body>;
			}
			return (<Segment basic loading style={{minHeight: 100, minWidth: 100}}/>)
		}
		
		if (!scheduleList.length) {
			const {
				loan_cars,
				fetching,
				ack,
				error,
				range_start,
				range_end
			} = this.state;
			if (onlyBody) {
				return <Table.Body>
					<Table.Row>
						<Table.Cell colSpan={2}>
							<Trans defaults="Kein Leihwagen vergeben"/>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			}
			return (
				<Segment basic loading={this.props.assigning}>
					<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
						<FlexItem style={{pading: "0 10px"}}>
							<Message><p><Trans defaults="Kein Leihwagen vergeben"/></p></Message>
						</FlexItem>
						<FlexItem style={{pading: "0 10px"}}>
							{!readOnly && <Button onClick={this.lookup} loading={fetching}><Trans defaults="Leihfahrzeug hinzubuchen"/></Button>}
						</FlexItem>
					</EasyFlex>
					{ack === false &&
					<Message color={"blue"}>
						<p><Trans defaults="Kein Leihfahrzeug gefunden"/></p>
					</Message>
					}
					<ErrorView error={error} errorView={error && <Message negative><p>{t([error.message || error.error || String(error)], "errors.bad")}</p></Message>}>
						<Space height={20}/>
						{range_start && range_end &&
						<EasyFlex align={EasyFlex.align.CENTER} style={{paddingTop: 40, paddingBottom: 30, background: "#fafafa"}}>
							<FlexItem style={{textAlign: "center", paddingLeft: 5, paddingRight: 5}}>
								<strong>Fahrzeug ab</strong>
								<OrderDatePicker
									date={moment(range_start).toDate()}
									time={moment(range_start).toDate()}
									disabled
								/>
							</FlexItem>
							<FlexItem style={{paddingLeft: 5, paddingRight: 5, borderLeft: "1px solid #ddd", textAlign: "center"}}>
								<strong>Fahrzeug bis</strong>
								<OrderDatePicker
									date={moment(range_end).toDate()}
									time={moment(range_end).toDate()}
									disabled
								/>
							</FlexItem>
						</EasyFlex>
						}
						{loan_cars && loan_cars.length > 0 &&
						<div>
							{loan_cars.map((v, i) =>
								<EasyFlex onClick={() => this.setState({selected: v.vehicle_id})} key={v.vehicle_id} align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER} style={{
									padding: 20,
									background: this.state.selected === v.vehicle_id ? COLORS.PRIMARY : (i % 2 ? "#fafafa" : null),
									cursor: "pointer"
								}}>
									<strong>{v.name}</strong>
									<RegistrationMark>{v.registration_mark}</RegistrationMark>
								</EasyFlex>
							)}
						</div>
						}
						{this.state.selected > 0 &&
						<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{padding: 40}}>
							<Button positive onClick={this.assign}><Trans defaults="Fahrzeug zuweisen"/></Button>
						</EasyFlex>
						}
					</ErrorView>
				
				</Segment>
			);
		}
		
		if (onlyBody) {
			return scheduleList.map(schedule =>
				<Vehicle.Provider key={schedule.loan_car_schedule_id} vehicle_id={schedule.vehicle_id}>{loanCar =>
					<QuickLoanCarBody key={schedule.loan_car_schedule_id} t={t} loanCar={loanCar} schedule={schedule}/>
				}</Vehicle.Provider>
			);
		}
		
		return scheduleList.map(schedule => {
				return (
					<Vehicle.Provider key={schedule.loan_car_schedule_id} vehicle_id={schedule.vehicle_id}>{loanCar =>
						<Table padded basic={"very"} striped celled style={{margin: "30px 15px", width: "calc(100% - 30px)"}}>
							<QuickLoanCarBody t={t} loanCar={loanCar} schedule={schedule}/>
						</Table>
					}</Vehicle.Provider>
				
				);
			}
		);
		
	}
}

OrderQuickLoanCar = withInit(OrderQuickLoanCar);
OrderQuickLoanCar = connect(
	(state, props) => {
		return {
			loading: isLoading(state, PROC_LOAN_CARS_FETCH, props.order_id),
			scheduleList: get(state, ["loan_cars", "order", props.order_id], []),
			// loanCarsMap: get(state, "loan_cars.list", {}),
			vehicles: state.map.vehicles,
			assigning: isLoading(state, PROC_LOAN_CARS_SEND)
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(loanCarAction__getByOrder(props.order_id));
		})),
		onAssign: (data, onSuccess) => dispatch(loanCarAction__assignOrder(data, result => {
			dispatch(addSnackbar('Leihfahrzeug wurde zugewiesen'));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(OrderQuickLoanCar);
OrderQuickLoanCar = translate()(OrderQuickLoanCar);
