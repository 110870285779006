import * as React from 'react'
import {StatyComponent} from "../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {wheelAction_delete, wheelAction_list, wheelCall_delete, wheelCall_list} from "../../../actions/wheelActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {Confirm, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {WheelList} from "./WheelList";
import {startOfDay} from 'date-fns'

export class WheelListView extends StatyComponent {
	static propTypes = {
		onLoad: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		wheelMap: PropTypes.object,
		clientId: PropTypes.number,
		vehicleId: PropTypes.number,
		onNotification: PropTypes.func.isRequired,
		isLoading: PropTypes.func.isRequired,
		provideReload: PropTypes.func.isRequired,
		onSelect: PropTypes.func,
		emptyRender: PropTypes.node
	}
	static defaultProps = {
		onLoad: wheelCall_list,
		onNotification: dispatchSnack,
		onDelete: wheelCall_delete,
		isLoading: () => {
		},
		provideReload: () => {
		}
	}
	state = {
		loading: false,
		deleting: {},
		confirmDelete: null,
		list: []
	}
	
	get list() {
		return this.getList(this.props.wheelMap, this.state.list, this.props.clientId, this.props.vehicleId, startOfDay(new Date()));
	}
	
	setDeleting = (id, value) => this.setState(state => ({
		...state,
		deleting: {
			...state.deleting,
			[id]: value
		}
	}))
	
	setConfirmDelete = (confirmDelete) => this.setState({confirmDelete})
	unsetConfirmDelete = () => this.setConfirmDelete(null)
	
	componentDidMount() {
		super.componentDidMount();
		this.props.provideReload(this.load)
		this.loadHandler(this.props.client_id, this.props.vehicle_id);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.loadHandler(this.props.client_id, this.props.vehicle_id);
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		this.props.provideReload(null)
	}
	
	// noinspection JSUnusedLocalSymbols
	loadHandler = memoizeOne((clientId, vehicleId) => this.load());
	
	load = async () => {
		const {clientId, vehicleId, wheelMap, onLoad, onNotification, isLoading} = this.props;
		try {
			this.setState({loading: true});
			isLoading(true);
			const params = {
				client_id: clientId,
				vehicle_id: vehicleId
			}
			const wheels = await onLoad(params);
			if (!wheelMap) {
				this.setState({list: Object.values(wheels)});
			}
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
			isLoading(false);
		}
	}
	
	remove = async () => {
		const {onDelete, onNotification} = this.props;
		const {confirmDelete} = this.state;
		try {
			this.unsetConfirmDelete()
			this.setDeleting(confirmDelete.wheel_id, true);
			await onDelete(confirmDelete.wheel_id)
			onNotification('Eintrag wurde gelöscht')
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert')
		} finally {
			this.setDeleting(confirmDelete.wheel_id, false)
		}
	}
	
	getList = memoizeOne((wheelMap, wheelList, clientId, vehicleId, day) => {
		if (wheelMap) {
			let list = [];
			for(const wheel of Object.values(wheelMap)) {
				if (clientId && wheel.client_id !== clientId) {
					continue;
				}
				if (vehicleId && wheel.vehicle_id !== vehicleId) {
					continue;
				}
				if (wheel.extract_at && new Date(wheel.extract_at) < day) {
					continue
				}
				list.push(wheel);
			}
			return list
		} else {
			return wheelList;
		}
	})
	
	get showList() {
		if (this.list.length){
			return true
		}
		return !this.props.emptyRender
	}
	
	render() {
		const {deleting, confirmDelete} = this.state;
		const {onSelect, headerProps, emptyRender} = this.props;
		return (
			<Segment basic style={{padding: 0}}>
				{this.showList ? <WheelList list={this.list} onSelect={onSelect} onDelete={this.setConfirmDelete} deleteMap={deleting} headerProps={headerProps}/> : emptyRender}
				<Confirm
					open={null !== confirmDelete}
					onCancel={this.unsetConfirmDelete}
					onConfirm={this.remove}
					cancelButton={'Abbrechen'}
					confirmButton={'Löschen'}
					size={'mini'}
					content={'Eintrag wirklich löschen?'}
				/>
			</Segment>
		);
	}
}


export const ConnectedWheelListView = connect(
	state => ({
		wheelMap: state.map.wheels
	}),
	dispatch => ({
		onLoad: params => dispatch(wheelAction_list(params, true)),
		onDelete: id => dispatch(wheelAction_delete(id, true))
	})
)(WheelListView);