import * as React from 'react';
import {Icon} from "semantic-ui-react";
import PropTypes from 'prop-types';

export const CircleNotchLoader = ({size, loading, ...props}) => <Icon name={"circle notch"} loading={loading} size={"big"} {...props}/>;
CircleNotchLoader.propTypes = {
	size: PropTypes.string,
	loading: PropTypes.bool
};
CircleNotchLoader.defaultProps = {
	size: 'big',
	loading: true
};


export let ConnectedLoaderIcon = ({loading, display = 'inline', style, dispatch, ...props}) =><Icon {...props} style={{...style, display: loading ? display : 'none'}}/>