import * as React from "react";
import Alarm from "../sounds/house-alarm.mp3";
import {connect} from "react-redux";
import {soundAction__initiated} from "../actions/soundActions";
import {SOUND_STATES} from "../actions/types";

class WebApiAlarmSound extends React.Component {
	audio = null;
	interval = null;
	
	constructor(props) {
		super(props);
		window.createjs.Sound.on("fileload", this.onLoad);
		window.createjs.Sound.registerSound(Alarm, "alarm");
		window.clearInterval(this.interval);
		this.interval = null;
	}
	
	onLoad = () => {
		this.audio = window.createjs.Sound.play("alarm");
		this.audio.volume = 0;
		this.audio.loop = -1;
		this.props.initiated();
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {playStatus} = this.props
		if (this.audio && playStatus !== prevProps.playStatus) {
			if ( playStatus === SOUND_STATES.PLAYING) {
				this.audio.volume = 1;
			} else {
				this.audio.volume = 0;
			}
		}
	}
	
	render() {
		return <React.Fragment/>;
	}
}
WebApiAlarmSound = connect(
	state => state.sound,
	dispatch => ({
		initiated: () => dispatch(soundAction__initiated)
	})
)(WebApiAlarmSound);

export { WebApiAlarmSound};