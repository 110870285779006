import * as React from 'react'
import PropTypes from 'prop-types'
import {Order} from "../../../../models";
import {PortalWrapper, StatyComponent} from "../../../../Tools/ReactExtension";
import {Button, Icon, Message} from "semantic-ui-react";
import {MaterialCompoundDateInput} from "../../../../Tools/DatePicker";
import {add, startOfMinute} from 'date-fns'
import {TimeTrans} from "../../../../Tools";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {Trans} from "react-i18next";
import {pipeDateFunc} from "../../../../components/intern/orders/finder/Helpers";
import {orderAction__updateFetchPoint, orderCall__updateFetchPoint} from "../../../../actions/orderActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {toISO} from "../../../../Logic/Moment";
import {connect} from "react-redux";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../../components/partials";

export class EditFetchPoint extends StatyComponent {
	static propTypes = {
		order: PropTypes.instanceOf(Order),
		buttonPortal: PropTypes.any,
		onDismiss: PropTypes.func,
		onUpdate: PropTypes.func,
		onNotification: PropTypes.func
	}
	static defaultProps = {
		onDismiss: () => {},
		onUpdate: orderCall__updateFetchPoint,
		onNotification: dispatchSnack
	}
	
	state = {
		date: null,
		updating: false
	}
	
	update = async() => {
		const {date} = this.state
		const {order, onUpdate, onNotification, onDismiss} = this.props
		try {
			this.setState({updating: true})
			await onUpdate(order.order_id, toISO(date))
			onNotification('Daten wurden aktualisiert')
			onDismiss()
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
			
		} finally {
			this.setState({updating: false})
		}
	}
	
	get date() {
		return startOfMinute(this.state.date || this.props.order.fetch_moment.toDate())
	}
	
	get earliestDate() {
		return startOfMinute(add(new Date(), {minutes: this.props.order.post_process_duration}))
	}
	
	get invalidDate() {
		return this.state.date && this.state.date < this.earliestDate;
	}
	
	setDate = (date) => this.setState({date})
	
	render() {
		const {buttonPortal} = this.props;
		const {updating} = this.state
		return (
			<div>
				<Message>
					<p>
						<Trans defaults={'Änderungen an der Abgabezeit sollten nur gemacht werden, um die Nachbearbeitungsreihenfolge zu verändern.'}/>
					</p>
				</Message>
				<EasyFlex align={EasyFlex.align.CENTER}>
					<div>
						
						<MaterialCompoundDateInput
							date={this.date}
							minDate={this.earliestDate}
							onChange={pipeDateFunc(this.setDate)}
						/>
						<div style={{fontSize: '0.8em', textAlign: 'center', color: 'brown', marginTop: -5}}>Nicht vor <TimeTrans type={'pure-long'} value={this.earliestDate}/></div>
					</div>
					
				</EasyFlex>
				{this.invalidDate && <Message negative>
					<p>
						<Trans defaults={'Die gewünschte Zeit ist nicht mehr realisierbar!'}/>
					</p>
				</Message>}
				<PortalWrapper portal={buttonPortal}>
					<ConfirmButton color={this.invalidDate ? 'red' : 'green'} disabled={!this.state.date || this.invalidDate} content={<Trans i18nKey={'actions.change'} defaults={'Ändern'}/>}>
						<ConfirmButtonConsumer>{ confirm =>
							<Button.Group>
								<Button basic color={'grey'} disabled={updating} icon onClick={e => {
									e && e.stopPropagation();
									confirm(false);
								}}><Icon name={"close"}/></Button>
								<Button.Or/>
								<Button loading={updating} positive icon onClick={this.update}><Icon name={'checkmark'}/></Button>
							</Button.Group>
						}</ConfirmButtonConsumer>
					</ConfirmButton>
				</PortalWrapper>
			</div>
		);
	}
}

export const ConnectedEditFetchPoint = connect(
	null,
	dispatch => ({
		onUpdate: (order_id, date) => dispatch(orderAction__updateFetchPoint(order_id, date, true))
	})
)(EditFetchPoint)