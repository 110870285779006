import * as React from 'react';
import PropTypes from 'prop-types';
import {Segment} from "semantic-ui-react";
import {Paper} from "material-ui";
import NavLink from "../../cointainer/partial/NavLink";
import {translate} from "react-i18next";


const EmailLoginView = ({t, isLoading, failed, houseLink}) => (
	<div style={{display: 'flex'}}>
		<Paper zDepth={2} rounded style={{margin: 'auto', padding: 20}}>
			{isLoading && <p>{t('login.email.checking-auth')}</p>}
			<Segment style={{minWidth: 300, minHeight: 200}} loading={isLoading} basic>
				{failed &&
				<React.Fragment>
					<h2>
						{t('login.email.checking-header')}
					</h2>
					<p>{t('login.email.checking-reason')}</p>
					<p>
						{houseLink ? <NavLink to={'/' + houseLink}>
							{t('login.email.checking-request')}
						</NavLink> : <span>{t('login.email.checking-request')}</span>
						}
					</p>
				</React.Fragment>
				}
			</Segment>
		</Paper>
	</div>
);

EmailLoginView.propTypes = {
	isLoading: PropTypes.bool,
	failed: PropTypes.bool,
	houseLink: PropTypes.string
};

EmailLoginView.defaultProps = {
	isLoading: false,
	failed: false
};

export default translate()(EmailLoginView);