import {RESET_ACTION, VEHICLE_ACTION} from "../actions/types";
import omit from 'lodash/omit';
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import isArray from "lodash/isArray";
import {updateCache, updateCacheData} from "ith-redux-helpers";
import {CACHE_VEHICLES, CACHE_VEHICLES_RELATIONS} from "../actions";

const defaultState = {
	list: {},
	client2vehicle: {},
	order2vehicle: {},
	cache: {}
};

export default (state = defaultState, {type, vehicle, vehicles, vehicle_id, client2vehicle, client_id, order2vehicle}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case VEHICLE_ACTION.SET_ORDER_VEHICLE:
			return {
				...state,
				order2vehicle: {
					...state.order2vehicle,
					...order2vehicle
				}
			};
		case VEHICLE_ACTION.SET:
			return {
				...state,
				list: vehicles,
				cache: {
					...state.cache,
					...updateCache(CACHE_VEHICLES)
				}
			};
		case VEHICLE_ACTION.ADD:
			return {
				...state,
				list: {
					...state.list,
					[vehicle.vehicle_id]: vehicle
				},
				client2vehicle: {
					...state.client2vehicle,
					[vehicle.client_id]: uniq([...(state.client2vehicle[vehicle.client_id] || []), vehicle.vehicle_id])
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_VEHICLES)(state.cache),
					...updateCacheData(CACHE_VEHICLES_RELATIONS)(state.cache)
				}
			};
		case VEHICLE_ACTION.INSERT:
			return {
				...state,
				list: {
					...state.list,
					...vehicles
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_VEHICLES)(state.cache)
				}
			};
		case VEHICLE_ACTION.REMOVE:
			if (!isArray(vehicle_id)) {
				vehicle_id = [vehicle_id];
			}
			return {
				...state,
				list: omit(state.list, vehicle_id),
				// vehicle2client: omit(state.vehicle2client, [vehicle_id]),
				client2vehicle: mapValues(state.client2vehicle, list => list.filter(id => !vehicle_id.includes(id))),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_VEHICLES)(state.cache),
					...updateCacheData(CACHE_VEHICLES_RELATIONS)(state.cache)
				}
			};
		case VEHICLE_ACTION.SET_CLIENT_RELATION:
			return {
				...state,
				client2vehicle,
				list: {
					...state.list,
					...vehicles
				},
				cache: {
					...state.cache,
					...updateCache(CACHE_VEHICLES),
					...updateCache(CACHE_VEHICLES_RELATIONS)
				}
			};
		case VEHICLE_ACTION.REMOVE_CLIENT:
			
			return {
				...state,
				client2vehicle: omit(state.client2vehicle, [client_id]),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_VEHICLES)(state.cache),
					...updateCacheData(CACHE_VEHICLES_RELATIONS)(state.cache)
				}
				// vehicle2client: omitBy(state.vehicle2client, value => value === client_id)
			};
		default:
			return state;
	}
};