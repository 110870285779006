import * as React from 'react';
import PropTypes from 'prop-types';
import {vehicleCall__get} from "../../../../actions/vehicleActions";
import {Segment} from "semantic-ui-react";


// noinspection JSPotentiallyInvalidUsageOfThis,JSIgnoredPromiseFromCall
export const VehicleEditWrapper = (Component, showLoading = false, loader = null) => class extends React.Component {
	static propTypes = {
		onFetch: PropTypes.func,
		vehicle_id: PropTypes.number.isRequired
	};
	static defaultProps = {
		onFetch: vehicle_id => vehicleCall__get(vehicle_id)
	};
	
	state = {
		fetching: false,
		vehicle: null
	};
	
	componentDidMount() {
		this.fetch();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// noinspection JSPotentiallyInvalidUsageOfThis
		if (this.props.vehicle_id !== prevProps.vehicle_id) {
			this.fetch();
		}
	}
	
	
	fetch = async () => {
		const {onFetch, vehicle_id} = this.props;
		try {
			this.setState({fetching: true});
			const vehicle = await onFetch(vehicle_id);
			this.setState({vehicle});
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({fetching: false});
		}
	};
	
	render() {
		const {vehicle} = this.state;
		const {onFetch, ...props} = this.props;
		if (!vehicle) {
			return loader;
		}
		if (showLoading) {
			return <Segment basic style={{padding: 0, margin: 0}}>
				<Component {...props} vehicle={vehicle} />
			</Segment>
		}
		return (
			<Component {...props} vehicle={vehicle} />
		);
	}
}