import React from 'react';
import {COLORS, SCREEN} from '../../Logic/constants';
import NavigationToggleController from "../../cointainer/partial/NavigationToggleController";
import {translate} from "react-i18next";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {Icon, Popup, Responsive} from "semantic-ui-react";
import {connect} from "react-redux";
import {navigateToProfile} from "../../actions/workerActions";
import {SqlDump} from "../../cointainer/intern/SqlDump";
import {IconCar, IconPause} from "../../Logic/icons";
import {publish} from "../../Logic/PubSub";
import {SUB_PAUSED_NOTIFICATION} from "../../actions";
import {ConnectedRelocateConflicts} from "./orders/relocate";
import {withSSEStatus} from "../../Logic/withInit";


const ALLOWED = ["gp@itholics.de", "mg@itholics.de", "eg@itholics.de", "c.beerden@herbrand.de", "c.beerden@art-mobil.de", "support@ithollics.de"];

const SSEIcon = withSSEStatus(({sseOnline}) => <Icon name={'user'} color={sseOnline ? 'green' : null}/>)

let Header = ({t, name, house, onUserDetail, history, user, paused}) => (
	<header>
		<div className="left" style={{cursor: 'default'}} onClick={(e) => {
			e.stopPropagation();
			history.push('/');
		}}>
			<Responsive as={'h1'} minWidth={SCREEN.XS} className="App-title">{house ? house.name : t('welcome')}</Responsive>
		</div>
		
		<div className="right">
			<ConnectedRelocateConflicts opened alwaysSymbol/>
			{user && user.email && ALLOWED.includes(user.email) && <SqlDump title={"Datenbankabbild erstellen"} style={{marginRight: 8}}/>}
			{paused.length ?
				<Popup hideOnScroll disabled position={"bottom right"} content={`${paused.length} pausierte Aufträge!`} trigger={<span onClick={() => publish(SUB_PAUSED_NOTIFICATION, paused)} className={"paused-notification"}><IconCar className={"paused-car"}/><IconPause className={"icon-pause"} color={COLORS.CONTRAST}/><span className={"paused-count"}>{paused.length}</span></span> }/>
				: null
			}
			<span className={'click pointer'} onClick={onUserDetail.bind(null, null)}><SSEIcon/> {/*<Responsive as={'span'} minWidth={SCREEN.SM}>*/}{name}{/*</Responsive>*/}</span>
			{/*<LanguageV2Controller/>*/}
			<NavigationToggleController id="toggle"/>
		</div>
	</header>
);

Header.propTypes = {
	name: PropTypes.string,
	house: PropTypes.shape({
		name: PropTypes.string.isRequired
	}),
	onUserDetail: PropTypes.func
};
Header.defaultProps = {
	name: '',
	house: null,
	onUserDetail: null
};

const eHeader = withRouter(translate()(Header));

export default eHeader;

const m2s = state => {
	const user = state.user.data;
	if ( !user ) return {name: ''};
	const {first_name, name: last_name, workers_id} = user;
	const name = `${first_name.substr(0, 1).toUpperCase()} ${last_name}`;
	const paused = state.appointments.paused_services;
	return {
		name, workers_id, user, paused
	};
};
const m2d = (dispatch) => ({
	onUserDetail: (worker=null) => dispatch(navigateToProfile(worker))
});
export const HeaderController = connect(m2s, m2d)(eHeader);