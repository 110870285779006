import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, Divider, List, ListItem} from "material-ui";
import {translate} from "react-i18next";
import {Segment} from "semantic-ui-react";
import {Subbar} from "ah-tools";

const MDPrice = ({t, house}) => {
	if ( !house ) {
		return (
			<Card>
				<Segment style={{minHeight: 500}} loading/>
			</Card>
		);
	}
	return (
		<Card>
			<CardHeader title={t('payment.setting')} subtitle={t('payment.price-performance')}/>
			<List style={{paddingBottom: 35}}>
				<ListItem disabled>
					<Subbar align={Subbar.ALIGN_BETWEEN}>
						<span>{t('payment.max-workers')}</span>
						<span>{house.max_workers}</span>
					</Subbar>
				</ListItem>
				<Divider inset/>
				<ListItem disabled>
					<Subbar align={Subbar.ALIGN_BETWEEN}>
						<span>{t('payment.price-month')}</span>
						<span>€ {house.price_month}</span>
					</Subbar>
				</ListItem>
			</List>
		</Card>
	);
};

MDPrice.propTypes = {
	house: PropTypes.shape({
		price_month: PropTypes.number.isRequired,
		max_workers: PropTypes.number.isRequired
	})
};
MDPrice.defaultProps = {};

export default translate()(MDPrice);