import {RESET_ACTION, SNACKBAR_ACTION} from "../actions/types";
import {globals} from 'ah-tools';
import md5 from 'md5';
import {omit} from "lodash";
import {COLORS} from "../Logic/constants";

const _color = input => {
	switch (input) {
		case 'alert':
			return globals.COLOR_ALERT;
		case 'black':
			return input;
		default:
			if (input && typeof input === 'string' && input.trim()) {
				return input
			}
			return COLORS.SEMANTIC_GREEN;
	}
};

export default (state = {}, {type, message, color = null, action, actionCallback, duration = 3000}) => {
	const index = md5(message || '');
	color = _color(color);
	switch (type) {
		case RESET_ACTION:
			return {};
		case SNACKBAR_ACTION.ADD:
			return {
				...state,
				[index]: {
					message,
					color,
					action,
					actionCallback,
					duration
				}
			};
		case SNACKBAR_ACTION.REMOVE:
			return omit(state, [index]);
		default:
			return state;
	}
};