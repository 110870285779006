import * as React from "react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {Segment} from "semantic-ui-react";
import {OrderInsertServiceView} from "../../cointainer/intern/orders/OrderInsertServiceViewV2";

export class OrderExtendTest extends React.Component {
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Order-Extend-Test</ActionHeading>
						
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic>
					<OrderInsertServiceView order_id={262}/>
				</Segment>
			</div>
		);
	}
}