import {Segment} from "semantic-ui-react";
import {COLORS} from "../../../../Logic/constants";
import {OrderDateRangeProvider} from "../../../../Logic/OrderDateRangeProvider";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import * as React from "react";
import PropTypes from "prop-types";
import Star from "./Star";
import Nbsp from "./Nbsp";
import {withUser} from "../../../../Tools/RightsProvider";

// const WaitingOrder = () => (
// 	<Popup
// 		inverted
// 		content={"Warteauftrag"}
// 		trigger={<Icon name={'user'} color={'red'}/>}
// 	/>
// );

const DateInfo = ({order, user, inverted, dispatch, ...props}) => {
	if (!order) return <Segment basic loading style={{minWidth: 50, minHeight: 50}}/>;
	const topStyle = inverted ? {
		color: "black",
		fontSize: "smaller"
	} : {
		color: COLORS.CONTRAST
	};
	return (
		<OrderDateRangeProvider order={order} isEngineer={user.is_engineer}>{p =>
			<div style={{textAlign: "center"}} {...props}>
				<EasyFlex align={EasyFlex.align.CENTER} style={topStyle} className={"monospace"}>
					<span>{p.start.format("DD.MM.")}</span>
					<span>&nbsp;&nbsp;&nbsp;</span>
					<span>{p.start.format("LT")}</span>
				</EasyFlex>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<div style={{textAlign: "center", color: p.separator.color, position: "relative", marginTop: -6}}>{p.separator.value}</div>
				<EasyFlex align={EasyFlex.align.CENTER} style={{color: COLORS.SEMANTIC_GREEN}} className={"monospace"}>
					<span><Nbsp count={1} visible={p.is_delivered}/>{p.delivered.format("DD.MM.")}</span>
					<span>&nbsp;&middot;&nbsp;&nbsp;</span>
					<span>{p.delivered.format("LT")} <Star title={'Abgabezeitpunkt'} visible={order.is_delivered}/></span>
				</EasyFlex>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<div style={{textAlign: "center", color: COLORS.SEMANTIC_ORANGE}} className={"monospace"}>{p.latest.format("DD.MM.\u00a0\u00b7\u00a0\u00a0LT")}</div>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<EasyFlex align={EasyFlex.align.CENTER} style={{color: COLORS.SEMANTIC_RED}} className={"monospace"}>
					<span><Nbsp count={1} visible={p.is_fetched}/>{p.end.format("DD.MM.")}</span>
					<span>&nbsp;&middot;&nbsp;&nbsp;</span>
					<span>{p.end.format("LT")} <Star title={'Übergabezeitpunkt'} visible={order.is_fetched}/></span>
				</EasyFlex>
			</div>
		}</OrderDateRangeProvider>
	);
};
DateInfo.propTypes = {
	order: PropTypes.shape({
		order_id: PropTypes.number.isRequired,
		deliver_point: PropTypes.any.isRequired
	}),
	inverted: PropTypes.bool
};
DateInfo.defaultProps = {
	inverted: false
};

export default  withUser(DateInfo);