import * as React from 'react';
import {Button, Segment} from "semantic-ui-react";
import {GET} from '../../Logic/fetch';
import {connect} from "react-redux";
import {addError} from "../../actions/errorActions";
// import {GET} from 'ith-fetch';

export class WorkerizeTest extends React.Component {
	state = {
		list: []
	};
	
	clicki = async () => {
		console.debug('clicked');
		try {
			let result = await GET('/house/all');
			console.debug('result in WorkerizeTest', result);
			this.setState({list: result});
		} catch (e) {
			this.props.dispatch(addError(e));
			console.error(e);
		}
	};
	
	render() {
		return (
			<Segment basic padded>
				<Segment>
					<h3>Hello</h3>
				</Segment>
				<Segment>
					<Button onClick={this.clicki}>Test</Button>
				</Segment>
				<Segment>
					<pre>{JSON.stringify(this.state.list, null, 2)}</pre>
				</Segment>
			</Segment>
		);
	}
}
WorkerizeTest = connect()(WorkerizeTest);