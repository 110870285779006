import {deleteWorker, setWorkerFilter} from "../../../actions/workerActions";
import WorkersView from "../../../components/intern/workers/WorkersListView";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import User from "../../../models/User";
import {addDialog} from "../../../actions/dialogActions";
import {loadGroups} from "../../../actions/rightActions";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {localStorageAction__setWorkerListViewType} from "../../../actions/localStorageActions";


const mapStateToProps = (state, props) => {
	const user = User._(state.user.data);
	return ({
		user,
		workers: state.workers.list,
		filter: state.workers.filter,
		viewType: state.local.worker_list_view_type || "grid",
		// user: new User(state.user.data),
		groups: Object.keys(state.rights.groups.list),
		// mayCreate: user.hasAccessTo(SECTION.WORKERS, User.RIGHT_CREATE),
		// mayDelete: user.hasAccessTo(SECTION.WORKERS, User.RIGHT_DELETE),
		...user.getAccessTo(SECTION.WORKERS, [2,3]),
		asGrid: true
	});
};

const mapDispatchToProps = (dispatch, props) => ({
	init: (refresh) => {
		// dispatch(loadWorkers(refresh));
		dispatch(loadGroups(refresh));
	},
	onCreate: () => dispatch(push('/workers/create')),
	onDetail: id => dispatch(push('/workers/detail/'+id)),
	onDelete: id => dispatch(deleteWorker(id)),
	onBack: () => dispatch(push('/')),
	onDialog: (dialog) => dispatch(addDialog(dialog)),
	updateFilter: (filter) => dispatch(setWorkerFilter(filter)),
	updateViewType: type => dispatch(localStorageAction__setWorkerListViewType(type)),
	push: to => dispatch(push(to))
});

let WorkersListController = connect(mapStateToProps, mapDispatchToProps)(withInit(WorkersView));

WorkersListController.propTypes = {
	headerPosition: PropTypes.oneOf(['above', 'beneeth', 'both', 'none'])
};

export default WorkersListController;