import * as React from "react";
import {Icon, Segment} from "semantic-ui-react";
import {vehicleCall__get} from "../../actions/vehicleActions";
import VehiclePopup from "../intern/vehicles/VehiclePopup";


const VEHICLES = [19, 91, 666];

export class VehiclePopupTest extends React.Component {
	state = {
		vehicles: [],
		
	};
	async componentDidMount() {
		const result = await Promise.all(
			VEHICLES.map( async id => {
				return await vehicleCall__get(id);
			})
		);
		this.setState({vehicles: result});
	}
	
	
	render() {
		const {vehicles} = this.state;
		// const v = Promise.all(vehicles.map(v => Vehicle._(v).client()));
		return (
			<Segment basic>
				{vehicles.map(vehicle =>
					<div style={{margin: 50}} key={vehicle.vehicle_id}>
						<VehiclePopup inverted vehicle={vehicle}><Icon name={"car"} size={"large"}/></VehiclePopup>
					</div>
				)}
				<pre>{JSON.stringify(vehicles, null, 2)}</pre>
			</Segment>
		);
	}
}