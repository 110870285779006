import * as React from 'react';
import PropTypes from 'prop-types';
import {FormField} from "semantic-ui-react";
import {translate} from "react-i18next";
import NoAccess from "../../../NoAccess";
import {_req, jsonRemoveNull} from "../../../../Logic/extensions";
import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';
import EditField from "../../../partials/EditField";
import {RaisedButton} from "material-ui";
import {Flex} from "../../../partials/ActionHeader";
import ValidationField, {ValidationDate} from "../../../partials/ValidationField";
import {getValidationValue, ValidationForm} from "../../../partials/ValidationContext";
import moment from "../../../../Logic/Moment";

const NOW = moment();

class LoanCarMask extends React.Component {
	static propTypes = {
		mayRead: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayDelete: PropTypes.bool,
		loan_car: PropTypes.shape({
			loan_car_id: PropTypes.number.isRequired
		}),
		isLoading: PropTypes.bool,
		onSave: PropTypes.func.isRequired
	};
	static defaultProps = {
		mayRead: false,
		mayChange: false,
		mayCreate: false,
		mayDelete: false,
		isLoading: false,
		onSave: () => alert('LoanCarMask.onSave() is not implemented yet!')
	};
	
	state = {
		loan_car: {
			house_id: '',
			title: '',
			hsn: '',
			tsn: '',
			chassis_number: '',
			km: '',
			first_registration: '',
			registration_mark: ''
		},
		focus: {},
		isValid: 0
	};
	
	componentDidMount() {
		this.handleLoanCarProps(this.props.loan_car);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.handleLoanCarProps(this.props.loan_car);
	}
	
	onValidationUpdate = (data) => {
		this.setState({isValid: data.error});
	};
	
	validation = getValidationValue(this.onValidationUpdate);
	
	handleLoanCarProps = loan_car => {
		loan_car && this.setState({loan_car: jsonRemoveNull(loan_car)});
	};
	
	set = (e, {name, value}) => this.setState(state => ({...state, loan_car: {...state.loan_car, [name]: value || ''}}));
	setCallback = (name, value) => () => this.setState(state => ({...state, loan_car: {...state.loan_car, [name]: value || ''}}));
	
	focus = name => () => !this.state.focus[name] && this.setState(state => ({...state, focus: {...state.focus, [name]: true}}));
	
	handleSave = () => {
		this.props.onSave(this.state.loan_car);
	};
	
	required = (index, error = 'errors.input.required') => {
		const {t} = this.props;
		const {focus, loan_car} = this.state;
		return focus[index] && loan_car[index].trim() === '' ? t(error) : null;
	};
	
	isValid = () => {
		const {hsn, tsn, km} = this.state.loan_car;
		return hsn.trim().length === 4 && tsn.trim().length === 3 && _req(km) && Number(km) >= 0;
	};
	
	
	render() {
		const {t, mayRead, mayChange, mayCreate, loan_car: givenLoanCar, size, isLoading} = this.props;
		
		if ( !givenLoanCar ) {
			if ( !mayCreate ) return <NoAccess/>;
		} else {
			if ( !mayRead ) return <NoAccess/>;
		}
		
		const editable = givenLoanCar ? mayChange : mayCreate;
		
		const {loan_car} = this.state;
		return(
				<ValidationForm value={this.validation} loading={isLoading} onSubmit={this.handleSave} style={{paddingTop: 30, paddingBottom: 30}}>
				    <StackGrid duration={0} gutterHeight={20} gutterWidth={20} columnWidth={size.width < 700 ? '100%' : 350} monitorImagesLoaded>
					    <FormField>
						    <ValidationField
						        editable={editable}
						        name={'title'}
						        text={t("loan-car.title")}
						        autoComplete={'off'}
						        value={loan_car.title}
						        onChange={this.set}
						        required
						        trimmed
						    />
					    </FormField>
					    <FormField>
						    <ValidationField
						        editable={editable}
						        name={'hsn'}
						        text={t("loan-car.hsn")}
						        autoComplete={'off'}
						        value={loan_car.hsn}
						        onChange={this.set}
						        onFocus={this.focus('hsn')}
						        required
						        pattern={'[a-zA-Z0-9]{4}'}
						        patternMessage={'errors.input.pattern-hsn'}
						        trimmed
						        
						    />
					    </FormField>
					    <FormField>
						    <ValidationField
							    editable={editable}
							    name={'tsn'}
							    text={t("loan-car.tsn")}
							    autoComplete={'off'}
							    value={loan_car.tsn}
							    onChange={this.set}
							    onFocus={this.focus('tsn')}
							    required
							    pattern={'[a-zA-Z0-9]{3}'}
							    patternMessage={'errors.input.pattern-tsn'}
							    trimmed
						    />
					    </FormField>
					    <FormField>
						    <EditField
							    editable={editable}
							    name={'chassis_number'}
							    text={t("loan-car.chassis-number")}
							    autoComplete={'off'}
							    value={loan_car.chassis_number}
							    onChange={this.set}
						    />
					    </FormField>
					    <FormField>
						    <ValidationField
							    editable={editable}
							    name={'km'}
							    text={t("loan-car.km")}
							    autoComplete={'off'}
							    value={loan_car.km}
							    min={0}
							    required
							    type={'int'}
							    onChange={this.set}
						    />
					    </FormField>
					    <FormField>
						    <EditField
							    editable={editable}
							    name={'registration_mark'}
							    text={t("loan-car.registration-mark")}
							    autoComplete={'off'}
							    value={loan_car.registration_mark}
							    onChange={this.set}
						    />
					    </FormField>
					    <FormField>
						    <ValidationDate
							    value={loan_car.first_registration}
							    name={'first_registration'}
							    startMode={'year'}
							    maxDate={NOW}
							    editable={editable}
							    autoComplete={'off'}
							    text={t("loan-car.first-registration")}
							    onClear={this.setCallback('first_registration', '')}
							    onChange={this.set}
						    />
					    </FormField>
				    </StackGrid>
					<div style={{marginTop: 30, marginBottom: 30}}>
						<Flex align={'flex-end'}>
							<RaisedButton type={'submit'} disabled={this.state.isValid > 0} primary>{t(givenLoanCar ? 'actions.save' : 'actions.create')}</RaisedButton>
						</Flex>
					</div>
				</ValidationForm>
		);
	}
}

export default translate()(sizeMe()(LoanCarMask));