import * as React from 'react';
import PropTypes from 'prop-types';
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading, Flex, ResponsiveFlexChild} from "../../partials/ActionHeader";
import {translate} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {Card, Divider, List, ListItem, MenuItem} from "material-ui";
import '../../../Logic/extensions';
import {PRIMARY_COLOR} from "../../../Logic/styles";
import {values} from "lodash";
import {ACCESS_PROP_DEFAULTS, ACCESS_PROP_DEFINTIONS} from "../../../Logic/constants";
import {ResourceAssigner, ResourceChips} from "../../../cointainer/intern/workers";
import PersonAddIcon from "material-ui/svg-icons/social/person-add";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import CloseTimeIcon from "material-ui/svg-icons/av/featured-play-list";
import GroupIcon from "material-ui/svg-icons/social/group";
import {IconMenu} from "../../../Logic/MiniMenu";

/**
 * Resource list of members.
 * May add or resmove reasources.
 */
class MasterDataResourceView extends React.Component {
	state = {
		resource: null,
		snackbar: null,
		confirmDialog: null
	};
	
	handleSelect = (worker, resource) => e => {
		if (e && (e.target.closest('.Worker-Resource-Assigner') || e.target.closest('.Worker-Resource-Chip'))) {
			return;
		}
		this.props.onSelectResource(worker, resource=null);
	};
	
	disableWorker = (workers_id) => {
		return !this.props.resources4workers || !this.props.resources4workers[workers_id] || !values(this.props.resources4workers[workers_id]).length
	};
	
	render() {
		const {
			t,
			// history: {push},
			init,
			workers,
			resources,
			resources4workers,
			push,
			onSelectResource
		} = this.props;
		
		return (
			<div id="master-data__resources">
				<ActionHeader className="resource-action-header" alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton onBack={() => push('/master-data')}/>
						<ActionHeading>{t('nav.master-data.resources')} (Ressourcenzuweisung)</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{/*<ActionHeaderLink to="/master-data/closed">Schließzeiten</ActionHeaderLink>*/}
						{/*<ActionIconButton icon="plus" onAction={() => push('/master-data/base')}/>*/}
						{/*<ActionRefreshButton onRefresh={() => {*/}
							{/*init(true)*/}
						{/*}}/>*/}
						<IconMenu
							closeOnClick
						>
							<MenuItem primaryText={"Schließzeiten"} leftIcon={<CloseTimeIcon/>} onClick={push.bind(null, '/master-data/closed')}/>
							<MenuItem primaryText={"Ressourcen bearbeiten"} leftIcon={<GroupIcon/>} onClick={push.bind(null, '/master-data/resources/view')}/>
							<MenuItem primaryText={"Neuen Mitarbeiter anlegen"} leftIcon={<PersonAddIcon/>} onClick={push.bind(null, '/workers/create')}/>
							<MenuItem primaryText={"Aktualisieren"} leftIcon={<RefreshIcon/>} onClick={init.bind(null, true)}/>
						</IconMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<Card>
					<List>
						<ListItem>
							<Row>
								<Col xs={6} sm={4} md={2} lg={2}>
									<strong>Mitarbeiter</strong>
								</Col>
								<Col xs={6} sm={8} md={10} lg={10}>
									<em style={{display: "block", fontSize: 14, paddingLeft: 10}}>Gruppe</em>
								</Col>
							</Row>
						</ListItem>
						{Object.keys(workers).filter(key => workers[key].right_group_id !== 'support' && workers[key].active).map(id => {
							const worker = workers[id];
							const workerResources = values(resources4workers).filter(t => t.workers_id === worker.workers_id).map(wr => {
								const res = 'find' in resources ? resources.find(el => el.resource_id === wr.resource_id) : null;
								wr.name = res ? res.name : 'N.V.';
								wr.color = res ? res.color : PRIMARY_COLOR;
								return wr;
							}).sort((a, b) => a.name.localeCompare(b.name));
							return (
								<React.Fragment key={`workers-res-${id}`}>
									<ListItem onClick={this.handleSelect(worker)} disabled={this.disableWorker(id)}>
										<Flex align={"flex-start"} valign={"center"} wrap gutterHeight={20} gutterWidth={20}>
											<ResponsiveFlexChild
												widthLG={1/10}
												onClick={() => workerResources.length && push(`/master-data/resources/${id}`)}
												style={{'cursor': workerResources.length ? 'pointer' : 'default', minWidth: 150}}>
												<strong>{worker.name} {worker.first_name.substring(0, 1)}.</strong>
												<em style={{paddingLeft: 10, fontSize: 12, display: "block"}}>{t(`rights.groups.${worker.right_group_id}`)}</em>
											</ResponsiveFlexChild>
											<ResponsiveFlexChild shrink={0} grow={100}>
												<Flex>
													<ResourceChips
														onClick={onSelectResource}
														workers_id={worker.workers_id}
														style={{marginLeft: 5, marginRight: 5}}
													>
														Bitte zuweisen
													</ResourceChips>
												</Flex>
											</ResponsiveFlexChild>
											<ResponsiveFlexChild shrink={0} grow={1} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
												<ResourceAssigner workers_id={worker.workers_id}/>
											</ResponsiveFlexChild>
										</Flex>
									</ListItem>
									<Divider/>
								</React.Fragment>
							);
						})}
					</List>
				</Card>
			</div>
		);
	}
}

MasterDataResourceView.propTypes = {
	init: PropTypes.func.isRequired,
	workers: PropTypes.object,
	resources: PropTypes.array.isRequired,
	resources4workers: PropTypes.object.isRequired,
	...ACCESS_PROP_DEFINTIONS
};
MasterDataResourceView.defaultProps = {
	workers: {},
	resources: [],
	resources4workers: [],
	...ACCESS_PROP_DEFAULTS
};

export default withRouter(translate()(MasterDataResourceView));