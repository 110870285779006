/* eslint-disable */
import React from 'react'
import {MDEwoTW} from "../intern/master-data/base/MDEwoTW";
import {withHouse} from "../../Tools/RightsProvider";
import {Divider, Modal, Segment} from "semantic-ui-react";
import {wheelCall_getEwoTw_order, wheelCall_getEwoTw_orderRemoval, wheelCall_getEwoTw_tireset, wheelCall_getEwoTw_tiresetRack, wheelCall_getEwoTw_tiresetRemoval} from "../../actions/wheelActions";
import {CancelStorage, CreateStorage, Logs, OrderStateView, Search, StorageRemoval, StorageRemovalCancel, StorageRemovalUpdate, TireSetListView, TireSetTable, TireSetView} from "../intern/wheel/ewotw/EwoTW";


const DefaultModal = ({...props}) => <Modal {...props} centered={false} size={'tiny'}/>

export class EtoTwTest extends React.Component {
	
	render() {
		const {houseData} = this.props
		return (
			<Segment.Group>
				<Segment>
					<MDEwoTW expandable/>
				</Segment>
				<Segment basic>
					<Logs expandable/>
				</Segment>
				<Segment basic>
					<Search
						title={'Reifensatz-Suche (Erweitert)'}
						label={'Reifensatznummer / Kennzeichen / Kundennummer / Fahrgestellnummer'}
						header={'Reifensätze'}
						request={wheelCall_getEwoTw_tiresetRemoval}
						resultComponent={<TireSetListView/>}
					/>
					<Search
						title={'Auftrags-Suche'}
						label={'Auftragsnummer'}
						header={'Auftrag'}
						request={wheelCall_getEwoTw_order}
						resultComponent={<OrderStateView/>}
						useModal={DefaultModal}
					/>
					<Search
						title={'Auftrags-Stornierungs-Suche'}
						label={'Auftragsnummer'}
						header={'Auftrag'}
						request={wheelCall_getEwoTw_orderRemoval}
						useModal={DefaultModal}
						resultComponent={<OrderStateView/>}
					/>
					<Search
						title={'Reifensatz-Suche'}
						label={'Reifensatznummer'}
						header={'Reifensatz'}
						request={wheelCall_getEwoTw_tireset}
						useModal={DefaultModal}
						resultComponent={<TireSetTable/>}
					/>
					<Search title={'Reifensatz-Rack-Suche'} label={'Rack-Nummer'} header={'Reifensätze'} request={wheelCall_getEwoTw_tiresetRack}/>
				</Segment>
				<Divider/>
				<Segment basic>
					<CreateStorage/>
					<CancelStorage/>
					<StorageRemoval/>
					<StorageRemovalUpdate/>
					<StorageRemovalCancel/>
				</Segment>
			
			</Segment.Group>
		);
	}
}

EtoTwTest = withHouse(EtoTwTest)