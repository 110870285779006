import VehicleListView from "../../../components/intern/vehicles/VehicleListView";
import {connect} from "react-redux";
import User from "../../../models/User";
import values from 'lodash/values';
import {push} from 'connected-react-router';
import withInit from "../../../Logic/withInit";
import {clientAction__collect} from "../../../actions/clientActions";
import {isOnline} from "../../../actions/userActions";
import {SECTION} from "../../../Logic/constants";
import {vehicleAction__fetchOfClient} from "../../../actions/vehicleActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_VEHICLES_FETCH} from "../../../actions";

const m2s = (state, props) => {
	const client_id = Number(props.match.params.client_id);
	const client = state.map.clients[client_id];//state.clients.list[client_id];
	const user =  User._(state.user.data);
	const vehicles = values(state.map.vehicles).filter(v => v.client_id === client_id);
	return {
		client,
		loading: isLoading(state, PROC_VEHICLES_FETCH, client_id),
		...user.getAccessTo(SECTION.CLIENTS),
		vehicles, client_id,
		kfz: state.map.kfz || state.kfz.cars,
		models: state.map.kfz_model || state.kfz.models,
		inverseModels: state.kfz.inverseModels,
	};
};

const m2d = (dispatch, props) => {
	const client_id = Number(props.match.params.client_id);
	return {
		init: () => dispatch(isOnline(() => {
			// dispatch(kfzAction__all());
			dispatch(clientAction__collect([client_id]));
			// dispatch(kfzAction__loadKfzByClient(client_id));
			// dispatch(kfzAction__loadModelsByClient(client_id));
			dispatch(vehicleAction__fetchOfClient(client_id));
		})),
		onBack:  () => window.history.back(),
		toCreate: () => dispatch(push(`/clients/${client_id}/vehicles/add`)),
		toClient: () => dispatch(push(`/clients/${client_id}`)),
		toVehicle: (vehicle_id) => dispatch(push(`/clients/${client_id}/vehicles/${vehicle_id}`))
	};
};

export default connect(m2s, m2d)(withInit(VehicleListView));