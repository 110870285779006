import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, Divider, List, ListItem} from "material-ui";
import {translate} from "react-i18next";
import InfoIcon from 'material-ui/svg-icons/action/info';



const MDModules = ({t, modules}) => {
	return (
		<Card>
			<CardHeader title={t('payment.setting')} subtitle={t('payment.booked-models')}/>
			<List style={{paddingBottom: 35}}>
				{modules.map(({module_id, name, default: isDefault}) =>
					<React.Fragment key={module_id}>
						<Divider/>
						<ListItem primaryText={t([`modules.name.module-${module_id}`,name])} rightIcon={<InfoIcon/>}/>
					</React.Fragment>
				)}
				<Divider/>
			</List>
		</Card>
	);
};

MDModules.propTypes = {
	modules: PropTypes.arrayOf(PropTypes.shape({
		module_id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		default: PropTypes.bool.isRequired
	})).isRequired
};

MDModules.defaultProps = {
	modules: []
};

export default translate()(MDModules);