import {RESET_ACTION, WORK_ACTION} from "../actions/types";

const defaultState = {
	list: [],
	orders: {},
	services: {},
	resources: {},
	order2services: {},
	service2resources: {},
	workload: "0"
};

export default (state = defaultState, {type, list, orders, services, resources, order2services, service2resources, workload}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case WORK_ACTION.SET_WORKLOAD:
			return {
				...state,
				workload
			};
		case WORK_ACTION.LOAD:
			return {
				...state,
				list,
				orders,
				services,
				resources,
				order2services,
				service2resources
			};
		default:
			return state;
	}
};