import MasterDataServiceDetailView from "../../../components/intern/master-data/MasterDataServiceDetailView";
import User from "../../../models/User";
import {
	kfzAction__fetch,
	kfzAction__fetchKfzServices,
	kfzAction__fetchServices, kfzAction__removeKfzService,
	kfzAction__setCustomKfzService
} from "../../../actions/kfzActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_KFZ_FETCH, PROC_KFZ_FETCH_SERVICES, PROC_KFZ_SERVICE_UPDATE} from "../../../actions";
import {SECTION} from "../../../Logic/constants";
import {isOnline} from "../../../actions/userActions";
import {connect} from "react-redux";
import {tryable} from "ah-lib";
import {routeState__noAccess, routeState__push} from "../../../actions/routeActions";
import {values} from '../../../Logic/extensions';
import {get} from "lodash";
import isFunc from 'lodash/isFunction';
import {addSnackbar} from "../../../actions/snackbarActions";
import withInit from "../../../Logic/withInit";
import {
	loadResourcesOfHouse,
	resourceAction__fetchKfz,
	resourceAction__fetchModels
} from "../../../actions/resourceActions";

const m2s = (state, props) => {
	const user = User._(state.user.data);
	const service_id = Number(props.match.params.service_id);
	return {
		service_id,
		fetchingService: isLoading(state, PROC_KFZ_FETCH_SERVICES),
		fetchingCars: isLoading(state, PROC_KFZ_FETCH),
		...user.getAccessTo(SECTION.MASTER_DATA, [0, 1]),
		service: tryable(() => state.kfz.service.list[service_id])(undefined),
		kfz_list: tryable(() => values(state.kfz.cars))(undefined),
		service_cars: tryable(() => state.kfz.service.cars)(undefined),
		resource_list: values(state.resources.list),
		resources: get(state.resources.kfz, service_id, {}),
		loadingCustom: (service_id, kfz_id) => isLoading(state, PROC_KFZ_SERVICE_UPDATE, service_id, kfz_id)
	};
};

const m2d = (dispatch, props) => {
	const service_id = Number(props.match.params.service_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(kfzAction__fetchServices());
			dispatch(kfzAction__fetch());
			dispatch(kfzAction__fetchKfzServices(service_id));
			dispatch(resourceAction__fetchKfz());
			dispatch(resourceAction__fetchModels());
			dispatch(loadResourcesOfHouse());
		})),
		noAccess: () => dispatch(routeState__noAccess()),
		toModelsList: kfz_id => dispatch(routeState__push(`/master-data/services/${service_id}/kfz/${kfz_id}`)),
		onBack: () => dispatch(routeState__push('/master-data/services')),
		onSetCustomService: (data, onSuccess) => dispatch(kfzAction__setCustomKfzService(data, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.md-service.custom-service.updated'));
		})),
		onRemoveCustomService: (service_id, kfz_id, onSuccess) => dispatch(kfzAction__removeKfzService(service_id, kfz_id, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.md-service.custom-service.removed'));
		}))
	};
};

export default connect(m2s, m2d)(withInit(MasterDataServiceDetailView));