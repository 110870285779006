/////////////
//      Quick Vehicle
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {Grid, Segment} from "semantic-ui-react";
import {List, ListItem} from "material-ui";
import moment from "../../../../Logic/Moment";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {vehicleAction__getByOrder} from "../../../../actions/vehicleActions";

export class OrderQuickVehicle extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		order2vehicle: PropTypes.object,
		vehicle: PropTypes.object
	};
	static defaultProps = {
		order: {},
		order2vehicle: {},
		vehicle: {}
	};
	
	render() {
		const {
			order2vehicle: vehicle,
			vehicle: currentVehicle
		} = this.props;
		
		if (isEmpty(vehicle) || isEmpty(currentVehicle)) {
			return <Segment loading basic style={{minWidth: 100, minHeight: 100}}/>;
		}
		
		return (
			<Grid stackable columns={2}>
				<Grid.Column>
					<List>
						<ListItem disabled secondaryText={"Bezeichnung"} primaryText={vehicle.name}/>
						<ListItem disabled secondaryText={"HSN/TSN"} primaryText={`${vehicle.hsn}/${vehicle.tsn}`}/>
						<ListItem disabled secondaryText={"Fahrgestellnummer"} primaryText={vehicle.chassis_number}/>
						<ListItem disabled secondaryText={"Kennzeichen"} primaryText={vehicle.registration_mark}/>
						{vehicle.registration_mark !== currentVehicle.registration_mark ? <ListItem disabled secondaryText={"akt. Kennzeichen"} primaryText={currentVehicle.registration_mark}/> : null}
					</List>
				</Grid.Column>
				<Grid.Column>
					<List>
						{vehicle.loan_car ? <ListItem disabled primaryText={"Leichfahrzeug"}/> : null}
						<ListItem disabled secondaryText={"km"} primaryText={vehicle.km}/>
						{vehicle.km !== currentVehicle.km ? <ListItem disabled secondaryText={"akt. km"} primaryText={currentVehicle.km}/> : null}
						<ListItem disabled secondaryText={"Modeljahr"} primaryText={vehicle.model_year}/>
						<ListItem disabled secondaryText={"Erstzulassung"} primaryText={moment(vehicle.first_registration).format("LL")}/>
					</List>
				</Grid.Column>
			</Grid>
		);
	}
}

OrderQuickVehicle = withInit(OrderQuickVehicle);
OrderQuickVehicle = connect(
	(state, props) => {
		const order_id = Number(props.order_id);
		const order = state.appointments.orders[order_id] || {};
		let order2vehicle = {};
		let vehicle = {};
		if (!isEmpty(order)) {
			order2vehicle = state.vehicles.order2vehicle[order.order_vehicle_id] || {};
			if (!isEmpty(order2vehicle)) {
				vehicle = state.vehicles.list[order2vehicle.vehicle_id];
			}
		}
		return {
			order_id,
			order,
			order2vehicle,
			vehicle
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(vehicleAction__getByOrder(props.order_id, [true, true, true]));
		}))
	})
)(OrderQuickVehicle);