import * as React from "react";
import {trueNull} from "../../Logic/extensions";
import {Message, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import LoginController from "../partial/LoginController";

let LogoutOverlay = ({carhouse, t, i18n, tReady, ...props}) => (
	trueNull(carhouse) && <Modal open dimmer={"blurring"} {...props}>
		<LoginController house_id={carhouse.house_id} logo={carhouse.logo} className={"login-form"}>
			<Message negative style={{marginLeft: 20, marginRight: 20, textAlign: "center"}}>
				<p>{t('errors.session-lost')}</p>
			</Message>
		</LoginController>
	</Modal>
);
LogoutOverlay = connect(
	state => ({
		carhouse: state.carhouse.list.find(h => h.house_id === state.user.data.house_id)
	})
)(LogoutOverlay);
LogoutOverlay = translate()(LogoutOverlay);

export default LogoutOverlay;