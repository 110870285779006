import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import OrderCreateController from "../../cointainer/intern/orders/OrderCreateController";


export class OrderAppointmentTest extends React.Component {
	render() {
		return (
			<Segment basic>
				<ActionHeader alignment={"flex-start"}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Create Appointment</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<OrderCreateController/>
			</Segment>
		);
	}
}