/////////////
//      Quick Info
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Popup, Segment, Statistic} from "semantic-ui-react";
import {COLORS, SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../../components/NoAccess";
import {EasyFlex, Flex} from "../../../../components/partials/ActionHeader";
import {IconButton, Popover, Toggle} from "material-ui";
import {IconEdit} from "../../../../Logic/icons";
import nl2br from "react-nl2br";
import {BasicDialog} from "../../../../Tools/Dialog/Dialogs";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {get, isEmpty, isFunction} from "lodash";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_ORDER, PROC_ORDER_DELETE} from "../../../../actions";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__delete, orderAction__delivered, orderAction__fetched, orderAction__fetchOne, orderAction__updateInfo} from "../../../../actions/orderActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {withRights} from "../../../../Tools";
import {ConnectedOrderInfoUpdateView} from "./OrderInfoUpdate";
import {OrderStateProvider} from "../../../../Logic/OrderStateProvider";
import {Order, User} from "../../../../models";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../../components/partials";
import {OrderQuickEdit} from "./OrderQuickEdit";

const PopupStateInfo = ({status, on, off, children}) => (
	<div>
		<em style={{display: "block", color: COLORS.PRIMARY}}>{status ? on : off}</em>
		{children && <span style={{color: '#ccc'}}><small>{children}</small></span>}
	</div>
);

const Hr = ({style, ...props}) => <hr style={{borderColor: "rgba(255,255,255,0.2)", borderWidth: 0.5, ...style}} {...props}/>;
const Small = ({style, ...props}) => <small style={{background: "#ccc", padding: 3, borderRadius: 3, color: COLORS.SECONDARY, marginLeft: 4, transform: "scale(0.8)", ...style}} {...props}/>;

export class OrderQuickInfo extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		loading: PropTypes.bool,
		order2services: PropTypes.array,
		updateReleased: PropTypes.func,
		updateDelivered: PropTypes.func,
		updateFetched: PropTypes.func,
		updateInfo: PropTypes.func,
		rights: PropTypes.object,
		allRights: PropTypes.object,
		deleting: PropTypes.bool,
		onOpened: PropTypes.func,
		iconSize: PropTypes.number,
		iconStyle: PropTypes.object,
		dateFormat: PropTypes.string
	};
	static defaultProps = {
		order: {},
		loading: false,
		deleting: false,
		order2services: [],
		iconSize: 32,
		iconStyle: {},
		dateFormat: "L LT",
		onOpened: () => {
		}
	};
	state = {
		info_update_dialog: false,
		releaseView: null
	};
	
	showUpdateDialog = (open) => () => this.setState({info_update_dialog: open});
	setReleaseView = (e) => this.setState({releaseView: e ? e.currentTarget : null});
	
	render() {
		const {
			loading,
			order: orderData,
			order2services,
			// updateReleased,
			updateDelivered,
			updateFetched,
			rights,
			allRights,
			deleting,
			iconSize,
			iconStyle,
			dateFormat
		} = this.props;
		
		if (!orderData || isEmpty(orderData)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const order = new Order(orderData);
		
		const mayRead = rights.mayRead || allRights[SECTION.APPOINTMENTS].mayRead;
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		// const mayRelease = allRights[SECTION.RELEASE].mayChange || rights.mayChange;
		const mayChange = rights.mayChange;
		const mayDelete = rights.mayDelete;
		
		const {
			info_update_dialog,
			releaseView
		} = this.state;
		
		return (
			<Segment basic loading={loading}>
				<Statistic.Group size={"mini"} style={{justifyContent: "center"}}>
					<Statistic>
						<Statistic.Label>Autragsnummer</Statistic.Label>
						<Statistic.Value>{order.id}</Statistic.Value>
					</Statistic>
					<Statistic>
						<Statistic.Value>{order2services.length}</Statistic.Value>
						<Statistic.Label>Serviceleistungen</Statistic.Label>
					</Statistic>
				</Statistic.Group>
				<OrderStateProvider order={order}>{({Icon, color, state}) =>
					<div style={{background: "#505050", margin: "40px 0", paddingTop: 12, paddingBottom: 12}}>
						<EasyFlex wrap={EasyFlex.wrap.YES} direction={EasyFlex.direction.ROW} align={EasyFlex.align.SPACE_AROUND} valign={EasyFlex.valign.START}>
							{/*<User.ReleaseProvider>{({mayRelease}) =>
								<Popup
									inverted
									content={
										<PopupStateInfo
											status={state.released}
											on={"Auftrag ist freigegeben"}
											off={"Auftrag muss noch freigegeben werden"}>
											Klicken zum Ändern
										</PopupStateInfo>}
									trigger={
										<EasyFlex
											direction={EasyFlex.direction.COLUMN}
											valign={EasyFlex.valign.CENTER}
											style={{padding: 10}} className={cn({pointer: mayRelease})}
											onClick={trueNull(mayRelease) && this.setReleaseView}
										>
											<Icon.Released color={color.released} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
											<div style={{marginLeft: 12, flexGrow: 1, color: COLORS.CONTRAST}}>
												<div>{order.created_moment.format(dateFormat)} <Small>erstellt</Small></div>
												{state.released &&
												<React.Fragment>
													<Hr/>
													<div>{order.released_moment.format(dateFormat)} <Small>freigegeben</Small></div>
												</React.Fragment>
												}
											</div>
										</EasyFlex>
									}/>
							}</User.ReleaseProvider>*/}
							<Popup
								inverted
								content={
									<PopupStateInfo
										status={state.delivered}
										on={"Fahrzeug entgegengenommen"}
										off={"Fahrzeug wird erwartet"}>
										Klicken zum Ändern
									</PopupStateInfo>}
								trigger={
									<EasyFlex
										direction={EasyFlex.direction.COLUMN}
										valign={EasyFlex.valign.CENTER}
										style={{padding: 10}}
										className={"pointer"}
										onClick={updateDelivered.bind(null, order.origin, !state.delivered)}
									>
										<Icon.Delivered color={color.delivered} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
										<div style={{marginLeft: 12, flexGrow: 1, color: COLORS.CONTRAST}}>
											<div>{order.deliver_moment.format(dateFormat)} <Small>erwartet</Small></div>
											{state.delivered &&
											<React.Fragment>
												<Hr/>
												<div>{order.delivered_moment.format(dateFormat)} <Small>angenommen</Small></div>
											</React.Fragment>
											}
										</div>
									</EasyFlex>
								}/>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Progress color={color.progress} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12}}>{state.progress ? (state.finished ? 'Arbeit abgeschlossen' : 'In Bearbeitung') : 'Unbearbeitet'}</div>
							</EasyFlex>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Finished color={color.finished} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12}}>{state.finished ? <div>{order.finished_moment.format(dateFormat)} <Small>Abschluss</Small></div> : 'Unfertig'}</div>
							</EasyFlex>
							<Popup
								inverted
								content={
									<PopupStateInfo
										status={state.fetched}
										on={"Fahrzeug zurückgegeben"}
										off={state.delivered ? 'Fahrzeug auf dem Hof' : 'Fahrzeug wird erwartet'}>
										Klicken zum Ändern
									</PopupStateInfo>}
								trigger={
									<EasyFlex
										direction={EasyFlex.direction.COLUMN}
										valign={EasyFlex.valign.CENTER}
										style={{padding: 10}}
										className={"pointer"}
										onClick={updateFetched.bind(null, order.origin, !state.fetched)}
									>
										<Icon.Fetched color={color.fetched} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
										<div style={{marginLeft: 12, flexGrow: 1, color: COLORS.CONTRAST}}>
											<div>{order.fetch_moment.format(dateFormat)} <Small>abzugeben</Small></div>
											{state.fetched &&
											<React.Fragment>
												<Hr/>
												<div>{order.fetched_moment.format(dateFormat)} <Small>abgegeben</Small></div>
											</React.Fragment>
											}
										</div>
									</EasyFlex>
								}/>
						</EasyFlex>
					</div>
				}</OrderStateProvider>
				
				<Popover
					open={null !== releaseView}
					anchorEl={releaseView}
					anchorOrigin={{vertical: "top", horizontal: "left"}}
					onRequestClose={this.setReleaseView.bind(null, null)}
				>
					<User.ReleaseProvider order_id={order.order_id}>{({mayService, mayStock, releaseService, releaseStock, release, loading: releaseLoading}) =>
						<Segment padded basic loading={releaseLoading}>
								<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.STRETCH}>
									<strong style={{whiteSpace: 'nowrap', marginBottom: 10}}>Freigabe</strong>
									{mayService && <div style={{marginBottom: 10}}>
											<Toggle
												label={<span style={{whiteSpace: "nowrap"}}>{release.service ? 'Freigegeben' : 'Freigabe erforderlich'}</span>}
												toggled={!!release.service}
												onToggle={releaseService.bind(null, order.origin, this.setReleaseView.bind(null, null))}
												labelPosition={"right"}
											/>
										</div>}
									{mayStock && <div>
											<Toggle
												label={<span style={{whiteSpace: "nowrap"}}>{release.stock ? 'Freigegeben (Lager)' : 'Freigabe (Lager) erforderlich'}</span>}
												toggled={!!release.stock}
												onToggle={releaseStock.bind(null, order.origin, this.setReleaseView.bind(null, null))}
												labelPosition={"right"}
											
											/>
									</div>}
								</EasyFlex>
						</Segment>
						
					}</User.ReleaseProvider>
				</Popover>
				<fieldset style={{border: "1px solid #ccc", position: "relative"}}>
					<legend style={{padding: "0 10px", fontWeight: "bold"}}><Icon name={"info circle"} size={"large"}/></legend>
					{order.info && order.info.trim() ?
						<p style={{margin: 10}}>{nl2br(order.info)}</p> :
						<p style={{margin: 10, color: '#ccc'}}>Keine Informationen vorhanden.</p>
					}
					{mayChange && <IconButton style={{position: "absolute", top: -10, right: -20, background: "#fff"}} onClick={this.showUpdateDialog(true)}><IconEdit color={"#666"}/></IconButton>}
				</fieldset>
				{mayChange && <EasyFlex align={mayDelete ? EasyFlex.align.SPACE_BETWEEN : EasyFlex.align.END} style={{marginTop: 40}} wrap={EasyFlex.wrap.YES}>
					<OrderQuickEdit order={order.origin} onOpened={this.props.onOpened}/>
					{mayDelete && <ConfirmButton negative content={"Auftrag löschen"}>
						<ConfirmButtonConsumer>{confirm =>
							<Button.Group>
								<Button disabled={deleting} onClick={e => {
									e && e.stopPropagation();
									confirm(false);
								}}><Icon name={"close"}/>Abbrechen</Button>
								<Button.Or/>
								<Button loading={deleting} negative onClick={() => this.props.deleteOrder(order)}>Löschen</Button>
							</Button.Group>
						}</ConfirmButtonConsumer>
					</ConfirmButton>}
				</EasyFlex>}
				
				
				<BasicDialog modal open={info_update_dialog} autoScrollBodyContent onClose={this.showUpdateDialog(false)} noPadding closeButton
				             renderActions={closeBtn => <Flex align={"space-between"} valign={"center"}>
					             {closeBtn}
					             <Button positive form={"order-update-info"}>Speichern</Button>
				             </Flex>}>
					{info_update_dialog && <ConnectedOrderInfoUpdateView order_id={order.order_id} info={order.info} id={"order-update-info"} onAfterUpdate={this.showUpdateDialog(false)}/>}
				</BasicDialog>
			</Segment>
		);
	}
}

OrderQuickInfo = withInit(OrderQuickInfo);
OrderQuickInfo = connect(
	(state, props) => {
		const orderID = props.order_id;
		const order = get(state.appointments.orders, [orderID], {});
		const order2services = get(state.appointments.order2services, [orderID], []);
		let service2resources = [];
		for (const id of order2services) {
			service2resources.concat(get(state.appointments.service2resources, [id], []));
		}
		return {
			loading: isLoading(state, PROC_ORDER, orderID),
			orders: state.appointments,
			order,
			order2services,
			service2resources,
			deleting: isLoading(state, PROC_ORDER_DELETE)
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			if (props.rights.mayRead) {
				dispatch(orderAction__fetchOne(props.order_id));
			}
		})),
		updateReleased: (order, state) => {},
		updateDelivered: (order, state) => dispatch(orderAction__delivered(order, state, () => dispatch(addSnackbar("Status: Geliefert geändert")))),
		updateFetched: (order, state) => dispatch(orderAction__fetched(order, state, () => dispatch(addSnackbar("Status: Abgeholt geändert")))),
		deleteOrder: (order, onSuccess) => dispatch(orderAction__delete(order, result => {
			dispatch(addSnackbar("Auftrag wurde gelöscht!"));
			isFunction(onSuccess) && onSuccess(result);
		}, false)),
		updateInfo: (order_id, info, onSuccess) => dispatch(orderAction__updateInfo(order_id, info, result => {
			dispatch(addSnackbar('Infos wurden aktualisiert'));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(OrderQuickInfo);
OrderQuickInfo = withRights(SECTION.ORDERS, OrderQuickInfo);