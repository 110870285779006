import React from 'react'
import BaseModel from "./BaseModel";
import moment from "../Logic/Moment";
import {Table} from "semantic-ui-react";
import {Trans} from "react-i18next";

export class Wheel extends BaseModel {
	static TIRE_WINTER = 'winter';
	static TIRE_SUMMER = 'summer';
	static TIRE_MIXED = 'mixed';
	static TIRES = [Wheel.TIRE_WINTER, Wheel.TIRE_SUMMER, Wheel.TIRE_MIXED];
	
	static SET_CLIENT = 'client';
	static SET_RENT_PKW = 'rent_pkw';
	static SET_RENT_TRAPO = 'rent_trapo';
	static SET_COMPANY = 'company'
	static SET_USED = 'used'
	static SET_TYPES = [Wheel.SET_CLIENT, Wheel.SET_RENT_PKW, Wheel.SET_RENT_TRAPO, Wheel.SET_COMPANY, Wheel.SET_USED]
	
	static SERVICE_STANDARD = 'standard'
	static SERVICE_PREMIUM = 'premium'
	static SERVICE_TYPES = [Wheel.SERVICE_STANDARD, Wheel.SERVICE_PREMIUM]
	
	static RIM_ALUMINIUM = 'aluminium'
	static RIM_STEEL = 'steel'
	static RIM_NONE = 'none'
	static RIM_TYPES = [Wheel.RIM_ALUMINIUM, Wheel.RIM_STEEL, Wheel.RIM_NONE]
	
	static EWO_AVAILABLE = 'yes'
	static EWO_AVAILABLE_NOT = 'no'
	static EWO_AVAILABLE_IGNORE = 'none'
	static EWO_AVAILABLE_TYPES = [Wheel.EWO_AVAILABLE_IGNORE, Wheel.EWO_AVAILABLE, Wheel.EWO_AVAILABLE_NOT]
	
	_validateModel(model) {
		return ("wheel_id" in model);
	}
	
	get wheel_id() {
		return Number(this.__model.wheel_id);
	}
	
	get id() {
		return this.__model.id;
	}
	
	get house_id() {
		return Number(this.__model.house_id);
	}
	
	get client_id() {
		return this.__model.client_id ? Number(this.__model.client_id) : null;
	}
	
	get vehicle_id() {
		return this.__model.vehicle_id ? Number(this.__model.vehicle_id) : null;
	}
	
	get set_count() {
		return Number(this.__model.set_count);
	}
	
	get title() {
		return this.__model.title;
	}
	
	get type() {
		return this.__model.type;
	}
	
	get set_type() {
		return this.__model.set_type
	}
	
	get service_type() {
		return this.__model.service_type
	}
	
	get type_winter() {
		return this.type === Wheel.TIRE_WINTER;
	}
	
	get type_summer() {
		return this.type === Wheel.TIRE_SUMMER;
	}
	
	get type_mixed() {
		return this.type === Wheel.TIRE_MIXED;
	}
	
	get inch() {
		return Number(this.__model.inch);
	}
	
	get registration_mark() {
		return this.__model.registration_mark;
	}
	
	get chassis_number() {
		return this.__model.chassis_number;
	}
	
	get without_rims() {
		return Boolean(this.__model.without_rims)
	}
	
	get rim_type() {
		return this.__model.rim_type
	}
	
	get reference_number() {
		return this.__model.reference_number
	}
	
	get comment() {
		return this.__model.comment
	}
	
	get deleted_at() {
		return this.__model.deleted_at || null;
	}
	
	get extract_at() {
		return this.__model.extract_at || null;
	}
	
	get extraction_confirmed() {
		return Boolean(this.__model.extraction_confirmed)
	}
	
	get extract_moment() {
		return this.extract_at ? moment(this.extract_at) : null;
	}
	
	get created_at() {
		return this.__model.created_at
	}
	
	get created_moment() {
		return moment(this.created_at)
	}
	
	get ewo_tw() {
		return this.__model.ewo_tw
	}
	
	isType = (type) => type === this.type;
	
	static PopupInfo = ({wheel: w, bodyProps, rowProps, titleCellProps, contentCellProps, ...props}) => {
		const wheel = w instanceof Wheel ? w : new Wheel(w)
		
		return (
			<Table {...props}>
				<Table.Body {...bodyProps}>
					<TableLine title={'Kundennummer'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.client_id}</TableLine>
					<TableLine title={'Fahrzeugnummer'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.vehicle_id}</TableLine>
					<TableLine title={'Service'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}><Trans i18nKey={`wheel-service-options.${wheel.service_type}`}/></TableLine>
					<TableLine title={'Reifentyp'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}><Trans i18nKey={`tires.${wheel.type}`}/></TableLine>
					<TableLine title={'Reifensatztyp'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}><Trans i18nKey={`set-type-options.${wheel.set_type}`}/></TableLine>
					<TableLine title={'Bezeichnung'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.title}</TableLine>
					<TableLine title={'# Reifen'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.set_count}</TableLine>
					<TableLine title={'Größe in Zoll'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.inch}</TableLine>
					<TableLine title={'Kennzeichen'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.registration_mark}</TableLine>
					<TableLine title={'Fahrgestellnummer'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.chassis_number}</TableLine>
					<TableLine title={'Felgen'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}><Trans i18nKey={`wheel-rim.${wheel.rim_type}`}/></TableLine>
					<TableLine title={'Kommentar'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.comment}</TableLine>
					<TableLine title={'Auslagerung'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>{wheel.extract_at ? wheel.extract_moment.format('LLL') : <Trans i18nKey={'actions.no'}/>}</TableLine>
					<TableLine title={'Auslagerung bestätigt'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}><Trans i18nKey={wheel.extraction_confirmed ? 'actions.yes' : 'actions.no'}/></TableLine>
					{wheel.ewo_tw && <TableLine title={'EwoTW'} titleCellProps={titleCellProps} contentCellProps={contentCellProps} {...rowProps}>Daten vorhanden</TableLine>}
				</Table.Body>
			</Table>
		)
	}
}

const TableLine = ({titleCellProps, contentCellProps, title, ikey, children, ...rowProps}) => (
	<Table.Row {...rowProps}><Table.Cell {...titleCellProps}><Trans defaults={title} i18nKey={ikey}/></Table.Cell><Table.Cell {...contentCellProps}>{children}</Table.Cell></Table.Row>
)