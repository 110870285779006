import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {trueNull} from "../Logic/extensions";
import {EasyFlex} from "./partials/ActionHeader";
import {subscribe} from "../Logic/PubSub";
import {SUB_VERSION_UPDATE} from "../actions";
import cn from "classnames";
import {Case, Default, Switch} from "../Logic/Switch";
import {Paper} from "material-ui";
import {userAction__version} from "../actions/userActions";

const Body = ({onUpdate, className, loading}) => (
	<div className={cn('versioner', className)}>
		<Header as={"h3"} className={"versioner-header"}>
			Eine neue Version ist verfügbar.
		</Header>
		<EasyFlex align={EasyFlex.align.END}  className={"versioner-flex"}>
			<Button positive loading={loading} onClick={onUpdate}  className={"versioner-button"}>Seite neuladen</Button>
		</EasyFlex>
	</div>
);

const Semantic = ({loading, onUpdate}) => (
	<Modal open dimmer={"blurring"} size={"mini"}>
		<Body className={"versioner-modal"} loading={loading} onUpdate={onUpdate}/>
	</Modal>
);

const Material = ({loading, onUpdate}) => (
	<Paper style={{position: "fixed", right: 20, bottom: 20, zIndex: 999}} zDepth={3}>
		<Body onUpdate={onUpdate} loading={loading} className={"versioner-material"}/>
	</Paper>
);

export class VersionNotification extends React.Component {
	static defaultProps = {
		modal: false
	};
	
	state = {
		show: false,
		loading: false
	};
	
	reload = () => this.setState({loading: true}, () => {
		window.location.href = window.location.href.replace(/#.*/, "") + `?${Math.random()}`;
	});
	
	componentDidMount() {
		// noinspection JSIgnoredPromiseFromCall
		userAction__version();
		this.unsubscribe = subscribe(SUB_VERSION_UPDATE, () => this.setState({show: true}));
	}
	
	componentWillUnmount() {
		this.unsubscribe && this.unsubscribe();
	}
	
	
	render() {
		const {show, loading} = this.state;
		const {modal} = this.props;
		return (
			trueNull(show) && <Switch value={modal}>
				<Case is={true}>
					<Semantic loading={loading} onUpdate={this.reload}/>
				</Case>
				<Default>
					<Material loading={loading} onUpdate={this.reload}/>
				</Default>
			</Switch>
		);
	}
}