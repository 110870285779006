import * as React from "react";
import 'react-vis/dist/style.css';
import {DiscreteColorLegend, HorizontalBarSeries, HorizontalGridLines, VerticalGridLines, XAxis, XYPlot, YAxis} from "react-vis";
import {controlCall__get} from "../../actions/controlActions";
import {deepMemoize as memoize} from "../../Logic/extensions";
import {SizeMe} from "react-sizeme";
import ReactTooltip from "react-tooltip";
import round from "round-precision";
import {get} from "lodash";

class ChartTest extends React.Component {
	state = {
		data: null,
		tooltip: null
	};
	
	async componentDidMount() {
		try {
			const data = await controlCall__get('2019-10-17');
			this.setState({data});
		} catch (e) {
			console.error('failed!');
		}
	}
	
	getWorkerName = memoize(
		(worker) => {
			return worker.name + ' ' + worker.first_name.substr(0, 1) + '.';
		}
	);
	
	setTooltip = (type, color) => point => this.setState({
		tooltip: {
			on: type,
			type: type.toLowerCase(),
			...point
		}
	});
	
	unsetTooltip = ()  => this.setState({tooltip: null});
	
	render() {
		const {data} = this.state;
		if (!data) {
			return 'loading...';
		}
		const workers = data.id_list.map(id => {
			const worker = {...data.workers[id]};
			worker.id = worker.workers_id;
			worker.name = this.getWorkerName(worker);
			return worker;
		});
		const schedule = [];
		const worked = [];
		const balance = [];
		const buffer = [];
		for (const worker of workers) {
			const schedule_duration = get(data, ['schedule_duration', worker.id], 0);
			const complete = get(data, ['work', worker.id, 'complete'], 0);
			const balance_cleared = get(data, ['balance_cleared', worker.id], 0);
			schedule.push({y: worker.name, x: schedule_duration / 3600});
			worked.push({y: worker.name, x: complete / 3600});
			balance.push({y: worker.name, x: balance_cleared / 3600});
			buffer.push({y: worker.name, x: (schedule_duration - complete - balance_cleared) / 3600})
		}
		// const {size} = this.props;
		return (
			<div className="App"  data-for={"barinfo"} data-tip>
				<SizeMe>{({size}) =>
					<div>
						<XYPlot
							height={Math.max(400, window.innerHeight - 150)}
							width={Math.max(300, size.width - 150)}
							yType={"ordinal"}
							xType={"linear"}
							margin={{left: 100, top: 50}}
							stroke={'white'}
						>
							<VerticalGridLines/>
							<HorizontalGridLines/>
							<HorizontalBarSeries
								barWidth={0.6}
								cluster={'Arbeitszeit'}
								color={"gray"}
								data={schedule}
								onValueMouseOver={this.setTooltip('Arbeitszeit', 'gray')}
								onValueMouseOut={this.unsetTooltip}
							/>
							<HorizontalBarSeries
								barWidth={0.6}
								color={"green"}
								cluster={'Absolivert'}
								data={worked}
								onValueMouseOver={this.setTooltip('Absolviert', 'green')}
								onValueMouseOut={this.unsetTooltip}
							/>
							<HorizontalBarSeries
								barWidth={0.6}
								color={"red"}
								cluster={'Warteschlange'}
								data={balance}
								onValueMouseOver={this.setTooltip('Warteschlange', 'red')}
								onValueMouseOut={this.unsetTooltip}
							/>
							<HorizontalBarSeries
								barWidth={0.6}
								color={"orange"}
								cluster={'Puffer'}
								data={buffer}
								onValueMouseOver={this.setTooltip('Puffer', 'orange')}
								onValueMouseOut={this.unsetTooltip}
							/>
							<XAxis title={"Stunden"}/>
							<YAxis title={"Mitarbeiter"}/>
							<DiscreteColorLegend
								style={{position: 'absolute', bottom: '-50px', right: '10px'}}
								orientation="horizontal"
								items={[
									{
										title: 'Arbeitszeit',
										color: 'gray'
									},
									{
										title: 'Absolviert',
										color: 'green'
									},
									{
										title: 'Warteschlange',
										color: 'red'
									},
									{
										title: 'Puffer',
										color: 'orange'
									}
								]}
							/>
						</XYPlot>
					</div>
				}</SizeMe>
				{this.state.tooltip && <ReactTooltip id={"barinfo"}>
						{this.state.tooltip.on}: {round(this.state.tooltip.x, 2)} Stunden
				</ReactTooltip>}
			</div>
		);
	}
}

// ChartTest = sizeMe()(ChartTest);

export {ChartTest};