import '../Logic/extensions';
import {RESET_ACTION, SCHEDULE_ACTION, WORKER_ACTION} from "../actions/types";
import {updateCache} from "ith-redux-helpers";
import {isArray, uniq, omit, mapValues, difference} from "lodash";

const initState = {
	ids: [],
	list: {},
	work: {},
	activities: [],
	working: {},
	resourceMap: {},
	work_history: {},
	schedule: {},
	day_schedule: {},
	consultant_schedules: [],
	filter: 'all',
	updated: null
};

const workersState = (state = initState, action) => {
	const {workers_id} = action;
	let ids;
	switch (action.type) {
		case RESET_ACTION:
			return initState;
		case WORKER_ACTION.ADD_ID:
			ids = action.ids;
			if (!isArray(ids)) {
				ids = [ids];
			}
			ids = ids.map(id => Number(id.workers_id || id)).filter(Boolean);
			return {
				...state,
				ids: uniq([...ids, ...state.ids])
			};
		case WORKER_ACTION.SET_CONSULTANT_SCHEDULE:
			return {
				...state,
				consultant_schedules: action.consultant_schedules
			};
		case WORKER_ACTION.REMOVE_CONSULTANT_SCHEDULE:
			return {
				...state,
				consultant_schedules: state.consultant_schedules.filter(cs => Number(cs.consultant_schedule_id) !== Number(action.consultant_schedule.consultant_schedule_id || action.consultant_schedule || -1))
			}
		case WORKER_ACTION.REMOVE_ID:
			ids = action.ids;
			if (!isArray(ids)) {
				ids = [ids];
			}
			ids = ids.map(id => Number(id.workers_id || id)).filter(Boolean);
			return {
				...state,
				ids: difference(state.ids, ids)
			};
		case WORKER_ACTION.LOAD_ALL:
			return {...state, list: action.workers, updated: new Date().getTime(), ...updateCache()};
		case WORKER_ACTION.LOAD_ONE:
			const worker = action.worker;
			return {...state, list: {...state.list, [worker.workers_id]: worker}, updated:  new Date().getTime()};
		case WORKER_ACTION.ADD:
			return {...state, list: {...state.list, [action.worker.workers_id]: action.worker}, updated: state.updated || new Date().getTime() };
		case WORKER_ACTION.REMOVE:
			return {
				...state,
				list: omit(state.list, [workers_id])
			};
		case WORKER_ACTION.SET_ACTIVITIES:
			return {
				...state,
				activities: action.activities
			};
		case WORKER_ACTION.SET_WORKING:
			return {
				...state,
				working: action.working
			};
		case WORKER_ACTION.UPDATE_WORKING:
			return {
				...state,
				working: {
					...state.working,
					[action.work.workers_id]: action.work
				}
			};
		case WORKER_ACTION.REMOVING_WORKING:
			return {
				...state,
				working: omit(state.working, [action.workers_id])
			};
		case WORKER_ACTION.SET_RESOURCE_MAP:
			return {
				...state,
				resourceMap: action.resourceMap
			};
		case WORKER_ACTION.FILTER.SET:
			return {...state, filter: action.filter};
		case WORKER_ACTION.RESET:
			return {...initState};
		case WORKER_ACTION.UPDATE_EMAIL:
			return {
				...state,
				list: mapValues(state.list, (w => {
					if ( w.workers_id === action.workers_id ) {
						w = {
							...w,
							email: action.email
						};
					}
					return w;
				}))
			};
		case WORKER_ACTION.REMOVE_AVATAR:
			return {
				...state,
				list: mapValues(state.list, (w => {
					if ( w.workers_id === action.workers_id ) {
						w = {
							...w,
							avatar_id: null,
							avatar: null
						};
					}
					return w;
				}))
			};
		case SCHEDULE_ACTION.SET:
			return {
				...state,
				schedule: {
					...state.schedule,
					[action.workers_id]: action.schedule
				}
			};
		case WORKER_ACTION.SET_WORK:
			return {
				...state,
				work: {
					...state.work,
					...action.work
				}
			};
		case WORKER_ACTION.SET_WORK_HISTORY:
			return {
				...state,
				work_history: {
					...state.work_history,
					...action.work
				}
			};
		case SCHEDULE_ACTION.SET_WORK_SCHEDULE:
			return {
				...state,
				day_schedule: {
					...state.day_schedule,
					[action.workers_id]: {
						...(state.day_schedule[action.workers_id] || {}),
						[action.day]: {
							"schedule": action.schedule,
							"duration": action.duration
						}
					}
				}
			};
		default:
			return state;
	}
};

export default workersState;