import * as React from 'react';
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../../components/partials/ActionHeader";
import {Button, Dropdown, Icon, Label, Message, Modal, Segment} from "semantic-ui-react";
import {Worker} from "../../../../models";
import {blackOrWhite, deepMemoize as memoizeOne, falseNull, trueNull} from "../../../../Logic/extensions";
import {DynamicWorkerSchedule} from "./DynamicWorkerSchedule";
import {Trans} from "react-i18next";
import PropTypes from 'prop-types';
import {DynamicScheduleInsertMask} from "./DynamicScheduleInsertMask";
import {DynamicScheduleDeleteMask} from "./DynamicScheduleDeleteMask";
import {IconMenu, ResourceLink, WorkerDetailLink, WorkersCalendarLink} from "../../../../Logic/MiniMenu";
import {Divider, MenuItem} from "material-ui";
import {IconCalendar, IconDelete, IconEvent, IconRefresh} from "../../../../Logic/icons";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {scheduleCall__dynamicDeleteIDs} from "../../../../actions/scheduleActions";

export const EditModeButton = ({active, count}) => {
	if (active) {
		return <span>
			<Icon name={'check'}/>
			{count} <Trans defaults="ausgewählt"/>
		</span>;
	}
	return <span><Trans defaults="Bearbeiten"/></span>;
};

// noinspection JSUnresolvedFunction
export class DynamicWorkerScheduleView extends React.Component {
	static propTypes = {
		workerId: PropTypes.number,
		titleProvider: PropTypes.func,
		onSwitchView: PropTypes.func,
		active: PropTypes.bool
	};
	static defaultProps = {
		titleProvider: selected => <Trans defaults={'Arbeitskalender'}/>
	};
	state = {
		worker_list: [],
		resource_list: null,
		selected: null,
		resource: null,
		loading_workers: false,
		loading_calendar: false,
		deleting_ids: false,
		edit_mode: false,
		edit_fields: [],
		editable: false,
		resourcable: false,
		date: null,
		view: null,
		show_create: false,
		show_delete: false
	};
	scheduleMethods = null;
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.handleWorkerChange(this.props.workerId, 'update');
	}
	
	handleWorkerChange = memoizeOne(
		(id, from) => {
			this.setState(state => {
				if (!id) {
					if (state.selected) {
						return {selected: null, show_create: false};
					}
					return {};
				}
				let selected = state.worker_list.find(w => Number(w.workers_id) === id) || null;
				return {
					selected,
					show_create: false
				};
			});
		}
	);
	
	deleteIds = async () => {
		try {
			if (!this.state.edit_fields.length || this.state.deleting_ids) {
				return
			}
			this.setState({deleting_ids: true})
			await scheduleCall__dynamicDeleteIDs(this.state.edit_fields)
			this.setState({edit_fields: [], edit_mode: false}, () => {
				this.scheduleMethods && this.scheduleMethods.refresh()
			})
		} catch (e) {
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({deleting_ids: false})
		}
	}
	
	handleAfterInsert = () => {
		this.setFieldValue('show_create', false)();
		this.scheduleMethods && this.scheduleMethods.refresh();
	}
	handleAfterDelete = () => {
		this.setFieldValue('show_delete', false)();
		this.scheduleMethods && this.scheduleMethods.refresh();
	}
	
	setSelectedWorker = (worker) => () => this.setState({selected: worker, resource: null, show_create: false});
	setWorkerList = worker_list => this.setState({worker_list}, () => this.handleWorkerChange(this.props.workerId, 'callback'));
	toggleField = field => () => this.setState(state => ({
		[field]: !state[field]
	}));
	setField = field => value => this.setState({[field]: value});
	setFieldValue = (field, value) => () => this.setState({[field]: value});
	setEditable = editable => this.setState(state => ({editable, edit_mode: state.edit_mode && editable}));
	
	render() {
		const {workerId, titleProvider, onSwitchView} = this.props;
		const {worker_list, resource_list, selected, resource, loading_calendar, loading_workers, edit_mode, edit_fields, editable, resourcable, show_delete, show_create, deleting_ids} = this.state;
		return (
			<Segment basic>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton loading={loading_calendar}/>
						<ActionHeading>{titleProvider(selected)} {'active' in this.props && !this.props.active && <Label color={'red'} style={{marginLeft: 8}}>Inaktiv</Label>}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{falseNull(workerId) && <Dropdown
							placeholder={'Mitarbeiter'}
							button
							labeled
							icon={<Icon name={loading_workers ? 'spinner' : 'user'}/>}
							className={'icon'}
							text={selected ? selected.name : 'Bitte auswählen'}
						>
							<Dropdown.Menu>
								{worker_list.map(w => {
									const worker = new Worker(w);
									return <Dropdown.Item
										key={worker.workers_id}
										selected={selected !== null && selected.workers_id === worker.workers_id}
										onClick={() => this.setState({selected: w, resource: null})}
									>
										{worker.name_short}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown>}
						{resourcable && resource_list && <Dropdown
							placeholder={'Ressourcen'}
							button
							labeled
							icon={<Icon name={'cog'} style={{color: resource && blackOrWhite(resource.color), backgroundColor: resource && resource.color}}/>}
							className={'icon'}
							text={resource ? resource.name : 'Alle Ressourcen'}
						>
							<Dropdown.Menu>
								{trueNull(resource) && <React.Fragment>
									<Dropdown.Item onClick={() => this.setState({resource: null})}>Alle Ressourcen</Dropdown.Item>
									<Dropdown.Divider/>
								</React.Fragment>}
								{resource_list.map(r =>
									<Dropdown.Item
										key={r.resource_id}
										selected={resource && resource.resource_id === r.resource_id}
										style={{backgroundColor: r.color, color: blackOrWhite(r.color)}}
										onClick={() => this.setState({resource: r})}
									>
										{r.name}
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>}
						<Button.Group disabled={!selected || !editable}>
							<Button
								disabled={!selected || !editable}
								toggle active={edit_mode && editable}
								onClick={this.toggleField('edit_mode')}
								icon
							>
								<EditModeButton active={edit_mode && editable} count={edit_fields.length}/>
							</Button>
							{trueNull(edit_fields.length && edit_mode && editable) && <Button loading={deleting_ids} onClick={this.deleteIds} icon={'trash'} negative/>}
						</Button.Group>
						{/*{selected && <Button icon={'plus'} basic style={{marginLeft: 5}} onClick={this.toggleField('show_create')}/>}*/}
						{/*{selected && <Button icon={'trash'} negative onClick={this.toggleField('show_delete')}/>}*/}
						{/*<Button icon={'refresh'} basic onClick={() => this.scheduleMethods && this.scheduleMethods.refresh()}/>*/}
						<IconMenu closeOnClick>
							<ResourceLink/>
							<MenuItem leftIcon={<IconRefresh/>} primaryText={'Neuladen'} onClick={() => this.scheduleMethods && this.scheduleMethods.refresh()}/>
							<Divider/>
							<MenuItem leftIcon={<IconEvent/>} primaryText={'Zeiten zuweisen'} onClick={this.toggleField('show_create')}/>
							<MenuItem leftIcon={<IconDelete/>} primaryText={'Zeiten löschen'} onClick={this.toggleField('show_delete')}/>
							{workerId && <>
								<Divider/>
								<WorkerDetailLink workers_id={workerId}/>
								<WorkersCalendarLink workers_id={workerId}/>
							</>}
							{onSwitchView && <>
								<Divider/>
								<MenuItem leftIcon={<IconCalendar/>} primaryText={'Zum statischen Kalender'} onClick={onSwitchView}/>
							</>}
						</IconMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<DynamicWorkerSchedule
						hideDateLabel
						workerId={selected && selected.workers_id}
						resourceId={resource && resource.resource_id}
						onProvideWorkerList={this.setWorkerList}
						onProvideWorkerResources={this.setField('resource_list')}
						isLoadingCalendar={this.setField('loading_calendar')}
						isLoadingWorkers={this.setField('loading_workers')}
						onProvideSelection={this.setField('edit_fields')}
						editMode={edit_mode}
						isEditable={this.setEditable}
						isResourceConsidered={this.setField('resourcable')}
						onProvideView={this.setField('view')}
						onProvideDate={this.setField('date')}
						methodsProvider={methods => this.scheduleMethods = methods}
					/>
				</div>
				<Modal closeOnDimmerClick={false} open={show_create} centered={false} onClose={this.setFieldValue('show_create', false)}>
					<Modal.Header><Trans defaults="Zeiten eintragen"/></Modal.Header>
					<Modal.Content>
						{selected ? <DynamicScheduleInsertMask showFull worker={selected} resources={resource_list} onAfterSave={this.handleAfterInsert}/> : <Message negative><p><Trans defaults="Kein Mitarbeiter gewählt!"/></p></Message>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setFieldValue('show_create', false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>
				<Modal closeOnDimmerClick={false} open={show_delete} centered={false} onClose={this.setFieldValue('show_delete', false)}>
					<Modal.Header><Trans defaults="Zeiten löschen"/></Modal.Header>
					<Modal.Content>
						{selected ? <DynamicScheduleDeleteMask worker={selected} resources={resource_list} onAfterDelete={this.handleAfterDelete}/> : <Message negative><p><Trans defaults="Kein Mitarbeiter gewählt!"/></p></Message>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setFieldValue('show_delete', false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>
			</Segment>
		);
	}
}