import React from 'react';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import history from '../../../Logic/history';
import WorkersListController from "../../../cointainer/intern/workers/WorkersListController";
import WorkersDetailController from "../../../cointainer/intern/workers/WorkersDetailController";
import WorkersCreateController from "../../../cointainer/intern/workers/WorkersCreateController";
import WorkersCalendarMainController from "./calendar/WorkersCalendarMainController";

const WorkerRoutes = () => (
	<ConnectedRouter history={history}>
		<div id="view__workers" className="view__main">
			<Switch>
				<Route exact path="/workers" component={WorkersListController}/>
				{/*<Route exact path="/workers" component={WorkerViewTest}/>*/}
				<Route path={'/workers/create'} component={WorkersCreateController} />
				<Route path={'/workers/detail/:id'} component={WorkersDetailController} />
				<Route path={'/workers/calendar/:workers_id'} component={WorkersCalendarMainController} />
				<Route path={'/workers/calendar'} component={WorkersCalendarMainController} />
			</Switch>
		</div>
	</ConnectedRouter>
);

export default WorkerRoutes;