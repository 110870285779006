import {isOnline} from "../../../../actions/userActions";
import MDModules from "../../../../components/intern/master-data/payment/MDModules";
import {fetchModules} from "../../../../actions/moduleActions";
import {connect} from "react-redux";
import withInit from "../../../../Logic/withInit";

const m2s = (state) => ({
	modules: Object.values(state.modules.list)
});

const m2d = (dispatch) => {
	// dispatch(isOnline(() => {
	// 	dispatch(fetchModules());
	// }));
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(fetchModules());
		}))
	};
};

export default connect(m2s, m2d)(withInit(MDModules));