import uuid from 'uuid';
// noinspection ES6CheckImport
import Worker from './fetch.worker';
import * as ith from 'ith-fetch';
// import Worker from './fetch.workerize';
// // export const FETCH = fetch;
// //
// // import {async as async1, setBaseUrl as setBaseUrl1} from "ith-fetch";
// //
// //
const worker = new Worker();
// const worker = new Worker('./fetch.worker-plugin.js', {type: 'module'});
// //


let callbacks = {};

window.workers = function() {return callbacks; };

worker.addEventListener('message', function(e) {
	const { id } = e.data;
	if (id in callbacks) {
		callbacks[id](e.data);
		delete callbacks[id];
	}
});

const timeoutError = 'Request timed out!';

// function resolver(method, params) {
// 	return new Promise(function(resolve, reject) {
// 		const id = uuid();
// 		const handler = e => {
// 			const {error, id: check, response} = e.data;
// 			if (id === check) {
// 				if (error) {
// 					reject(error);
// 				} else {
// 					resolve(response);
// 				}
// 				worker.removeEventListener('message', handler);
// 			}
// 		};
// 		worker.addEventListener('message', handler);
// 		worker.postMessage({method, params, id});
// 	});
// }

function resolver2(method, params) {
	return new Promise(function(resolve, reject) {
		const id = uuid();
		let done = false;
		callbacks[id] = function(data) {
			const {error, response} = data;
			done = true;
			if (error) {
				console.debug('received error', error);
				reject(error);
			} else {
				resolve(response);
			}
		};
		worker.postMessage({id, method, params});
		window.setTimeout(function() {
			if (id in callbacks) {
				delete callbacks[id];
				!done && reject({error: timeoutError, message: timeoutError});
			}
		}, 60000);
	});
}

	export const async = ith.async;
	export const setBaseUrl = ith.setBaseUrl;
	export const UPLOAD = ith.UPLOAD;

	// export const GET = ith.GET;
	// export const POST = ith.POST;
	// export const PUT = ith.PUT;
	// export const DELETE = ith.DELETE;
	// export const UPLOAD = ith.UPLOAD;
	// export const FETCH = fetch;


	export function GET(url, options = {}, defaultBody = true, withContentType = true) {
		return resolver2('GET', Array.from(arguments));
	}

	export function POST(url, data, options) {
		return resolver2('POST', Array.from(arguments));
	}

	export function PUT(url, data, options) {
		return resolver2('PUT', Array.from(arguments));
	}

	export function DELETE(url, data, options) {
		return resolver2('DELETE', Array.from(arguments));
	}

	// export function UPLOAD(url, data, options) {
	// 	return resolver2('UPLOAD', Array.from(arguments));
	// }
//Failed to execute 'postMessage' on 'DedicatedWorkerGlobalScope'
	export function FETCH(url, options) {
		return resolver2('FETCH', [url, options]);
	}