import * as React from 'react';
import PropTypes from 'prop-types';
import {Col, Grid, Row} from "react-bootstrap";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';
import {translate} from "react-i18next";
import MDModulesController from "../../../cointainer/intern/master-data/payment/MDModulesController";
import MDPriceController from "../../../cointainer/intern/master-data/payment/MDPriceController";
import MDCarsController from "../../../cointainer/intern/master-data/payment/MDCarsController";

class MasterDataPaymentView extends React.Component {
	state = {
		grid: null
	};
	
	render() {
		const {t, onBack, size} = this.props;
		return (
			<Grid className="text-left" fluid={true}>
				<Row>
					<Col xs={12}>
						<ActionHeader alignment={'space-between'}>
							<ActionHeaderGroup>
								{onBack && <ActionBackButton onBack={onBack}/>}
								<ActionHeading style={{marginLeft: 20}}>{t('payment.settings')}</ActionHeading>
							</ActionHeaderGroup>
						</ActionHeader>
					</Col>
				</Row>
				<StackGrid columnWidth={size.width < 700 ? '100%' : 500} gutterWidth={10} gutterHeight={10} gridRef={grid => this.setState({grid})}>
					<MDModulesController key={'house-modules'}/>
					<MDPriceController key={'house-price'}/>
					<MDCarsController key={'house-cars'}/>
				</StackGrid>
			</Grid>
		);
	}
}

MasterDataPaymentView.propTypes = {
	onBack: PropTypes.func
};
MasterDataPaymentView.defaultProps = {
	onBack: null
};


export default sizeMe()(translate()(MasterDataPaymentView));