import * as React from "react";
import PropTypes from "prop-types";
import {deepMemoize as memoize} from "./extensions";
import {IconAssignmentLate, IconAssignmentTurnedIn, IconBuild, IconDelivered, IconDone, IconDoneAll, IconFetched, IconPerson, IconSwapHoriz, IconThumbUp} from "./icons";
import {COLORS} from "./constants";


export class OrderStateProvider extends React.PureComponent {
	static propTypes = {
		children: PropTypes.func.isRequired,
		order: PropTypes.shape({
			released_at: PropTypes.any,
			delivered_at: PropTypes.any,
			progress: PropTypes.any.isRequired,
			finished_at: PropTypes.any,
			fetched_at: PropTypes.any,
			waiting: PropTypes.any,
			delivery_service: PropTypes.any
		}).isRequired,
		positive: PropTypes.string,
		negative: PropTypes.string
	};
	static defaultProps = {
		negative: '#ccc'
	};
	getStates = memoize(
		(released, delivered, progress, finished, affirmed, processed, processing, fetched, waiting, delivery_service, positive, negative) => {
			const state = {
				released: Boolean(released),
				delivered: Boolean(delivered),
				progress: Boolean(Number(progress)) || Boolean(finished),
				finished: Boolean(finished),
				affirmed: Boolean(affirmed),
				processed: Boolean(processed),
				processing: Boolean(processing),
				fetched: Boolean(fetched),
				waiting: Boolean(Number(waiting)),
				delivery_service: Boolean(Number(delivery_service))
			};
			return {
				state,
				color: {
					released: state.released ? positive || '#CC0' : negative,
					delivered: state.delivered ? positive || '#00F' : negative,
					progress: state.progress ? positive || '#F93' : negative,
					finished: state.finished ? positive || '#008000' : negative,
					affirmed: state.affirmed ? positive || '#008000' : negative,
					processed: state.processed ? positive || '#008000' : (state.processing ? '#F93' : negative),
					processing: state.processing ? positive || '#008000' : negative,
					fetched: state.fetched ? positive || '#600' : negative,
					waiting: COLORS.SEMANTIC_RED,
					delivery_service: COLORS.SEMANTIC_BLUE,
				},
				Icon: {
					Released: IconDone,
					Delivered: IconDelivered,
					Progress: IconBuild,
					Finished: IconThumbUp,
					Affirmed: IconDoneAll,
					Processed: processing ? IconAssignmentLate : IconAssignmentTurnedIn,
					Fetched: IconFetched,
					Waiting: IconPerson,
					Delivery: IconSwapHoriz
				}
			};
		}
	);
	
	render() {
		const {
			children,
			order: {
				released_at,
				delivered_at,
				progress,
				finished_at,
				fetched_at,
				waiting,
				delivery_service,
				affirmed_at,
				post_processed_at,
				post_processing
			},
			positive,
			negative
		} = this.props;
		const provided = this.getStates(released_at, delivered_at, progress, finished_at, affirmed_at, post_processed_at, post_processing, fetched_at, waiting, delivery_service, positive, negative);
		return children(provided);
	}
}