
// noinspection DuplicatedCode,DuplicatedCode
/////////////
//      Quick Service
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty, uniqBy} from "lodash";
import {Button, Card, Icon, Image, Item, Label, Message, Modal, Popup, Segment} from "semantic-ui-react";
import {If} from "../../../../Logic/Switch";
import round from "round-precision";
import {blackOrWhite, logoLink, partitionArray, trueNull} from "../../../../Logic/extensions";
import {ConnectedWorkerAvatarView, ConnectedWorkerAvatarWorkingView} from "../../workers";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__collect, orderAction__collectServices, orderAction__fetchOne} from "../../../../actions/orderActions";
import {kfzAction__fetchServices} from "../../../../actions/kfzActions";
import {workerAction__getWork} from "../../../../actions/workerActions";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {Work} from "../../../../models/Work";
import {WorkerAvatarView} from "../../workers/WorkerAvatarView";
import {ReleaseIcon} from "../../release";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {Worker} from "../../../../models";
import {Trans, translate} from "react-i18next";
import {MobileAwarePopup} from "../../../../components/partials/MiniComponents";
import {StatyComponent} from "../../../../Tools/ReactExtension";
import ServiceLogo from '../../../../images/service2.png';
import {isMobile} from 'react-device-detect';
import cn from 'classnames';
import {ReleaseView} from "../../release/ReleaseBatch";

const ServiceStatus = ({serviceInfo, workDone, t, onClickRelase, readOnly}) => {
	workDone = round(workDone / 3600, 1);
	return (
		<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_AROUND}>
			<ReleaseIcon id={serviceInfo.order_service_id} api size={28} childStyle={{margin: '0 0.2em'}} onClick={readOnly ? null : onClickRelase}/>
			<Popup
				inverted
				content={t('...', 'Geplante Arbeitzeit')}
				trigger={
					<EasyFlex style={{margin: '0 0.2em'}} valign={EasyFlex.valign.CENTER}>
						<Icon name={'time'} size={"large"}/> <strong>{round(serviceInfo.hours_of_work_resource, 1)}</strong>
					</EasyFlex>
				}
			/>
			<Popup
				inverted
				content={t('...', 'Absolvierte Arbeitszeit (Kann auch bei fertigen Leistungen 0 betragen)')}
				trigger={
					<EasyFlex style={{margin: '0 0.2em'}} valign={EasyFlex.valign.CENTER}>
						<Icon name={'wrench'} size={"large"}/> <strong>{workDone}</strong>
					</EasyFlex>
				}
			/>
			{trueNull(serviceInfo.paused_by) && <MobileAwarePopup modal inverted content={<ConnectedWorkerAvatarView workerId={serviceInfo.paused_by}>Pausiert</ConnectedWorkerAvatarView>}>
				<Icon color={'red'} name={'pause circle outline'} size={"large"} style={{margin: '0 0.2em'}}/>
			</MobileAwarePopup>}
			<Popup
				inverted
				flowing
				content={serviceInfo.finished_at ? t('...', 'Servicearbeiten abgeschloßen') : t('...', 'Servicearbeiten nicht abgeschloßen')}
				trigger={<Icon color={serviceInfo.finished_at ? 'green' : 'red'} name={'checkmark'} size={"large"} style={{margin: '0 0.2em'}}/>}
			/>
		</EasyFlex>
	);
};

let WorkerView = ({worker: w}) => {
	if (!w) {
		return null;
	}
	
	return <WorkerAvatarView worker={w}/>;
};
WorkerView = connect((state, props) => ({worker: state.map.workers[props.workers_id] || state.workers.list[props.workers_id]}))(WorkerView);

const WorkDoneView = ({work}) => {
	if (!work.length) {
		return <div style={{textAlign: 'center', width: '100%'}}><Trans defaults={'Abgeschloßen'}/></div>;
	}
	// const total = work.map(o => o.reduce((s, w) => s + w.duration, 0));
	let next = work.map(w => w.sort((a, b) => b.end_point - a.end_point)).map(w => w[w.length - 1]);
	
	// return <pre>{JSON.stringify(next, null, 2)}</pre>;
	return next.map((w) => <EasyFlex style={{marginBottom: 8}} direction={EasyFlex.direction.COLUMN} key={w.workers_id}>
		<div style={{fontSize: '.8em'}}><Trans defaults="Durchgeführt am {date, date, medium} {date, time, short} Uhr von" values={{date: new Date(w.end_point)}}/></div>
		<div style={{padding: '4px 8px 0 12px'}}><WorkerView workers_id={w.workers_id}/></div>
	</EasyFlex>);
};

//
// const DescModal = ({service, title, children, ...props}) =>
// 	<Modal {...props} trigger={children} closeIcon>
// 		{service.title && <Modal.Header><Icon name={'info'}/> {service.title}</Modal.Header>}
// 		<Modal.Content>
// 			{service.description}
// 		</Modal.Content>
// 	</Modal>;
	
const WorkHistory = ({serviceWork}) => (
	<Segment inverted>
		{serviceWork.map(work =>
			<ConnectedWorkerAvatarWorkingView key={work.work_id} workerId={work.workers_id} since={work.start_point}>
				{/*Mitarbeiter ({work.workers_id}) seit {moment(work.start_point).format("LLL")}*/}
				<Trans defaults="Mitarbeiter ({wid}) seit {date, date, medium} {date, time, short} Uhr" values={{wid: work.workers_id, date: new Date(work.start_point)}}/>
			</ConnectedWorkerAvatarWorkingView>
		)}
	</Segment>
);

const UnfinishedWork = ({serviceWork}) => {
	if (serviceWork.length) {
		const work = serviceWork[serviceWork.length - 1];
		return <Card.Meta>
			<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
				<ConnectedWorkerAvatarWorkingView workerId={work.workers_id} since={work.start_point}>
					{/*Mitarbeiter ({work.workers_id}) seit {moment(work.start_point).format("LLL")}*/}
					<Trans defaults="Mitarbeiter ({wid}) seit {date, date, medium} {date, time, short} Uhr" values={{wid: work.workers_id, date: new Date(work.start_point)}}/>
				</ConnectedWorkerAvatarWorkingView>
				{trueNull(serviceWork.length > 1) &&
					<div>
						<MobileAwarePopup modal content={<WorkHistory serviceWork={serviceWork}/>} flowing inverted>
							<Icon name={'list alternate outline'} size={'large'} className={cn({'cursor pointer': isMobile})}/>
						</MobileAwarePopup>
					</div>
				}
			</EasyFlex>
		</Card.Meta>;
	}
	return <Card.Meta style={{textAlign: 'center'}}>
		<Trans defaults="Aktuell unbearbeitet."/>
	</Card.Meta>;
};

class WorkState extends StatyComponent {
	static propTypes = {
		serviceInfo: PropTypes.object.isRequired,
		order: PropTypes.object.isRequired,
		serviceWork: PropTypes.array.isRequired
	};
	render() {
		const {serviceInfo, order, t, serviceWork} = this.props;
		return (
			serviceInfo.finished_at ?
					<Card.Meta>
						<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
							<Work.Connector order_id={order.order_id} service_id={serviceInfo.service_id}>{worklist => {
								worklist = partitionArray(worklist, w => w.workers_id);
								return <WorkDoneView work={worklist} t={t}/>
							}}</Work.Connector>
							{trueNull(serviceWork.length > 1) &&
							<div>
								<MobileAwarePopup modal content={<WorkHistory serviceWork={serviceWork}/>} flowing inverted>
									<Icon name={'list alternate outline'} size={'large'} className={cn({'cursor pointer': isMobile})}/>
								</MobileAwarePopup>
							</div>
							}
						</EasyFlex>
					</Card.Meta>
					:
					<UnfinishedWork serviceWork={serviceWork}/>
			
		);
	}
}

// noinspection DuplicatedCode
export class OrderQuickService extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		services: PropTypes.arrayOf(PropTypes.object),
		serviceMap: PropTypes.object,
		work: PropTypes.arrayOf(PropTypes.object),
		workHistory: PropTypes.arrayOf(PropTypes.object),
		onCollectOrders: PropTypes.func,
		onCollectOrderServices: PropTypes.func,
		readOnly: PropTypes.bool
	};
	static defaultProps = {
		order: {},
		services: [],
		serviceMap: {},
		work: [],
		workHistory: []
	};
	
	state = {
		release_of: 0
	};
	
	setReleaseOf = (release_of) => this.setState({release_of});
	unsetRelease = () => this.setState({release_of: null});
	
	componentDidMount() {
		this.fetchOrder(this.props.order.order_id);
		this.fetchOrderServices(this.props.services);
	}
	
	fetchOrder = memoizeOne(
		order_id => this.props.onCollectOrders([order_id])
	);
	fetchOrderServices = memoizeOne(
		services => {
			const ids = services.map(s => s.order_service_id);
			this.props.onCollectOrderServices([ids]);
		}
	);
	
	resourceArray = memoizeOne(
		resources => Object.values(resources)
	);
	
	buildResources = memoizeOne(
		(services, resources, source) => {
			let map = {};
			const resourceList = this.resourceArray(resources);
			for (const {order_service_id} of services) {
				for (const resource of resourceList) {
					if (Number(resource.order_service_id) === Number(order_service_id)) {
						if (!map[order_service_id]) {
							map[order_service_id] = [];
						}
						if (source[resource.resource_id]) {
							map[order_service_id].push(source[resource.resource_id]);
						} else {
							map[order_service_id].push(resource);
						}
					}
				}
			}
			return map;
		}
	);
	
	uniqResources = memoizeOne(
		map => {
			let next = {};
			for (const i in map) {
				// noinspection JSUnfilteredForInLoop
				next[i] = uniqBy(map[i], r => r.resource_id);
			}
			return next;
		}
	);
	
	historyWorkers = memoizeOne(
		list => {
			let map = {};
			for (const work of list) {
				map[work.workers_id] = true;
			}
			return Object.keys(map).sort();
		}
	);
	
	render() {
		const {
			order,
			services,
			serviceMap,
			orderResources,
			resources,
			work,
			workHistory,
			readOnly,
			t
		} = this.props;
		if (isEmpty(serviceMap) || isEmpty(order)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const {release_of} = this.state;
		const resourceMap = this.uniqResources(this.buildResources(services, orderResources, resources));
		const collectableWorkerIds = this.historyWorkers(workHistory);
		return (
			<div style={{marginTop: 25, padding: 15}}>
				<Worker.CollectProvider ids={collectableWorkerIds}/>
				<Item.Group relaxed divided>
					<If condition={services.length === 0}>
						<Item key={"missing-services"}>
							<Item.Content>
								<Item.Header>
									<Message negative><Trans defaults="Keine Services zugwiesen!"/></Message>
								</Item.Header>
							</Item.Content>
						</Item>
					</If>
					<EasyFlex valign={EasyFlex.valign.START} wrap>
						{services.map(serviceInfo => {
							const service = serviceMap[serviceInfo.service_id];
							const serviceWork = work.filter(w => Number(w.service_id) === Number(service.service_id));
							const serviceWorkHistory = workHistory.filter(w => Number(w.service_id) === Number(service.service_id));
							let workDone = serviceWorkHistory.reduce((carry, work) => carry + work.duration, 0);
							const resourceList = resourceMap[serviceInfo.order_service_id] || [];
							return (
								<Card key={serviceInfo.order_service_id} style={{margin: 5}} className={'service-card'}>
									<Card.Content>
										<MobileAwarePopup modal inverted content={service.title}><Card.Header style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'default'}}>{service.title}</Card.Header></MobileAwarePopup>
									
									</Card.Content>
									<Card.Content style={{padding: '.5em', textAlign: 'right'}}>
										{resourceList.map(resource =>
											<Label key={resource.resource_id} style={{backgroundColor: resource.color || null, color: blackOrWhite(resource.color || '#ffffff')}}>{resource.name || resource.resource_name}</Label>
										)}
									</Card.Content>
									<Image src={logoLink(service)} wrapped style={{padding: 8}} onError={e => e.target.src = ServiceLogo}/>
									<Card.Content>
										{/*<Card.Meta>*/}
										<ServiceStatus workDone={workDone} readOnly={readOnly} serviceInfo={serviceInfo} itemStyle={{margin: 8}} className={"osl-info-status"} t={t} onClickRelase={this.setReleaseOf}/>
										{/*</Card.Meta>*/}
									</Card.Content>
									<Card.Content>
										<WorkState order={order} serviceInfo={serviceInfo} t={t} serviceWork={serviceWork}/>
									</Card.Content>
								</Card>
							);
						})}
					</EasyFlex>
				</Item.Group>
				<Modal open={!!release_of} onClose={this.unsetRelease} size={'tiny'}>
					{release_of && <Modal.Content>
						<ReleaseView orderServiceId={release_of}/>
					</Modal.Content>}
					<Modal.Actions><Button onClick={this.unsetRelease}><Trans defaults={'Schließen'}/></Button></Modal.Actions>
				</Modal>
			</div>
		);
	}
}

OrderQuickService = withInit(OrderQuickService);
OrderQuickService = connect(
	(state, props) => {
		const orderID = Number(props.order_id);
		const order = state.map.orders[orderID];//state.appointments.orders[orderID] || {};
		let services = [];
		for (const [, service] of Object.entries(state.map.order2services)) {
			if (service.order_id === orderID) {
				services.push(service);
			}
		}
		// let services = state.appointments.order2services[orderID] || [];
		// services = services.map(sid => state.appointments.services[sid] || {});
		return {
			order,
			services,
			serviceMap: state.map.services,// state.kfz.service.list,
			orderResources: state.map.order2service2resources,
			resources: state.map.resources,
			work: state.workers.work[orderID] || [],
			workHistory: state.workers.work_history[orderID] || []
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__fetchOne(props.order_id));
			dispatch(kfzAction__fetchServices());
			dispatch(workerAction__getWork(props.order_id));
		})),
		onCollectOrders: ids => dispatch(orderAction__collect(ids)),
		onCollectOrderServices: ids => dispatch(orderAction__collectServices(ids))
	})
)(OrderQuickService);
OrderQuickService = translate()(OrderQuickService);