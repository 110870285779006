import Vehicle from "../../../models/Vehicle";
import {Line, Spacer} from "../../partials/PopupInfo";
import * as React from "react";
import PropTypes from "prop-types";

const VehiclePopupInfo = ({vehicle: v, inverted,  lineStyle, ...props}) => {
	if (!v) {
		return null;
	}
	let vehicle;
	if (v instanceof Vehicle) {
		vehicle = v;
	} else {
		vehicle = new Vehicle(v);
	}
	
	return (
		<table style={{borderCollapse: "collapse"}} cellPadding={6} border={0} {...props}>
			<tbody>
				<Line inverted={inverted} style={lineStyle} name="Typ">{vehicle.name}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={""}>{vehicle.registration_shield || 'n.v.'}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="HSN / TSN">{vehicle.hsn_tsn}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={"Fahrgestellnr."}>{vehicle.chassis_number_icon} {vehicle.chassis_number || 'n.v.'}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={"Fahrleistung"}>{vehicle.km_icon} {vehicle.km || '-'} km</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={"Baujahr"}>{vehicle.model_year || 'n.v.'}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={"EZ"}>{vehicle.first_registration_icon} {vehicle.first_registration_xx || 'n.v.'}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name={"Leihfahrzeug"}>{vehicle.loan_car_yes_no}</Line>
			</tbody>
		</table>
	);
};

VehiclePopupInfo.propTypes = {
	vehicle: PropTypes.object,
	inverted: PropTypes.bool,
	lineStyle: PropTypes.object
};
VehiclePopupInfo.defaultProps = {
	inverted: true
};

export default VehiclePopupInfo;