/* noinspection JSUnusedGlobalSymbols */
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";

import {ORDER_ACTION, WORK_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {PROC_ORDER, PROC_ORDER_DAY, PROC_ORDER_DAY_LIST, PROC_ORDER_DELETE, PROC_ORDER_DELIVER_STATE, PROC_ORDER_FETCH_STATE, PROC_ORDER_RELEASE, PROC_ORDER_RELEASE_LIST, PROC_ORDER_RESOURCE, PROC_ORDER_SERVICE, PROC_USER_FETCH_WORK, SUB_ORDER_DELETE} from "./index";
import {addError} from "./errorActions";
import {get, isFunction, isObject, values} from "lodash";
import {appointmentState__dayLoaded, appointmentState__delete, appointmentState__list, appointmentState__multi, appointmentState__paused, appointmentState__removeResources, appointmentState__set, appointmentState__update, appointmentState__workMap} from "./appointmentActions";
import {publish} from "../Logic/PubSub";
import {scheduleState__setWorkload, scheduleState__workSchedule} from "./scheduleActions";
import {
	mapDispatch,
	mapState__loanCars_remove,
	mapState__orderResources_add,
	mapState__orderResources_remove,
	mapState__orders_add,
	mapState__orders_remove,
	mapState__orderServices_add,
	mapState__orderServices_remove,
	mapState__releases_add,
	mapState__work_add
} from "./mapActions";
import {Collector} from "../Logic/helper";
import {vehicleAction__collect, vehicleAction__collectOrders} from "./vehicleActions";
import {clientAction__collect} from "./clientActions";
import {toISO} from "../Logic/Moment";
import {serviceAction__collect} from "./serviceActions";
import moment from "moment";

export const orderState__meta = (callee, date, endDate) => ({type: ORDER_ACTION.META, meta: {callee, date, endDate}});
export const orderState__set = (data) => ({type: ORDER_ACTION.SET, data});
export const orderState__update = (data) => ({type: ORDER_ACTION.UPDATE, data});
export const orderState__unset = (data) => ({type: ORDER_ACTION.UNSET, data});
export const orderState__insertPartial = (orders, order2service, order2service2resource) => ({type: ORDER_ACTION.PARTIAL, orders, order2service, order2service2resource});
export const orderState__activities = (activities) => ({type: ORDER_ACTION.SET_ACTIVITIES, activities});
export const workState__load = (list, orders) => ({type: WORK_ACTION.LOAD, list, ...orders});
export const orderState__release = (list, merge = false) => ({type: ORDER_ACTION.RELEASED, list, merge});
export const orderState__clearReleased = (list) => ({type: ORDER_ACTION.CLEAR_RELEASED, list});
export const orderState__addToRelease = order => ({type: ORDER_ACTION.ADD_TO_RELEASE, order});
export const orderState__delete = order_id => ({type: ORDER_ACTION.DELETE, order_id});
export const orderState__release_set = releases => ({type: ORDER_ACTION.SET_RELEASES, releases});
export const orderState__release_merge = releases => ({type: ORDER_ACTION.MERGE_RELEASES, releases});
export const orderState__release_removed = releases => ({type: ORDER_ACTION.REMOVE_RELEASES, releases});
// noinspection JSUnusedGlobalSymbols
export const orderState__release_clear_all = () => ({type: ORDER_ACTION.CLEAR_ALL_RELEASES});
export const orderState__release_clear_complete = () => ({type: ORDER_ACTION.CLEAR_COMPLETE_RELEASES});
export const orderState__addReleaseIDs = release_data => ({type: ORDER_ACTION.ADD_RELEASE_IDS, release_data});
export const orderState__removeReleaseIDs = release_data => ({type: ORDER_ACTION.REMOVE_RELEASE_IDS, release_data});
export const orderState__setReleaseIDs = release_data => ({type: ORDER_ACTION.SET_RELEASE_IDS, release_data});
export const orderState__setConflicts = conflicts => ({type: ORDER_ACTION.SET_CONFLICTS, conflicts});
export const orderState__updateConflicts = conflicts => ({type: ORDER_ACTION.UPDATE_CONFLICTS, conflicts});
export const orderState__removeConflicts = conflicts => ({type: ORDER_ACTION.REMOVE_CONFLICTS, conflicts});
export const orderState__processable = list => ({type: ORDER_ACTION.SET_PROCESSABLE, list});


export const orderCall__appointmentLookup = (data) =>
	POST('/order/v2/appointment/lookup', data);
export const orderCall__appointmentLookup_v4 = (data) =>
	POST('/order/v4/appointment/lookup', data);
export const orderCall__appointmentLookup_v5 = (data) =>
	POST('/order/v5/appointment/lookup', data);
export const orderCall__appointmentLookup_v6 = (data) =>
	POST('/order/v6/appointment/lookup', data);

export const orderCall__put = (data) => PUT('/order', data);
export const orderCall__put_v2 = (data) => PUT('/order/v2', data);
export const orderCall__put_v3 = data => PUT('/order/v3', data);
export const orderCall__put_v4 = data => PUT('/order/v4', data);
export const orderCall__put_v6 = data => PUT('/order/v6', data);
export const orderCall__put_forced = data => PUT('/order/forced/v2', data);
export const orderCall__extend_v2 = data => POST('/order/extend/v2', data);
export const orderCall__extend_v4 = data => POST('/order/extend/v4', data);
export const orderCall__extend_v6 = data => POST('/order/extend/v6', data);
export const orderCall__updateInfo = (order_id, info) => POST('/order/info', {order_id, info});
export const orderCall__collect = (orders, full = false) => POST('/order/collect', {list: orders, full});
export const orderCall__collectServices = (services) => POST('/order/collect/services', {list: services});
export const orderCall__collectResources = (resources) => POST('/order/collect/resources', {list: resources});
export const orderCall__collectReleases = (releases) => POST('/order/collect/releases', {list: releases});
export const orderCall__collectReleasesByOrders = (orders) => POST('/order/collect/releases', {list: orders});
export const orderCall__collectWork = (work) => POST('/order/collect/work', {list: work});
export const orderCall__releasable = () => GET('/order/release/v2');
export const orderCall__listDay = day => GET(`/order/list/${toISO(day || new Date())}`);
export const orderCall__removeConsultant = orderId => DELETE(`/order/consultant/${orderId}`);
export const orderCall__listConsultants = orderId => GET(`/order/consultants/${orderId}`)
export const orderCall__updateConsultant = (orderId, consultantId) => POST(`/order/consultant/${orderId}/${consultantId}`);

// noinspection JSUnusedLocalSymbols
// const debugParams = (section, ...args) => {
// 	console.warn("Debug of method:", section, args);
// 	return true;
// };

export const orderCall__get = (order, basic = false) => {
	basic = basic ? '/basic' : '';
	return GET(`/order/id/${order.order_id || order}${basic}`);
}
export const orderCall__released = (order, type) => POST('/order/update/release', {order_id: order.order_id || order, type});
export const orderCall__releaseList = (list) => POST('/order/update/release/list', {list});
export const orderCall__delivered = (order, state) => POST('/order/update/deliver', {order_id: order.order_id || order, state});
export const orderCall__fetched = (order, state) => POST('/order/update/fetch', {order_id: order.order_id || order, state});
export const orderCall__delete = (order) => DELETE(`/order/id/${order.order_id || order}`);
export const orderCall__releaseOf = (order_id) => GET(`/order/release/id/${order_id}`);
export const orderCall__updateRanges = (order_id, deliver_point, fetch_point) => POST('/order/update/ranges', {
	order_id,
	deliver_point: toISO(deliver_point),
	fetch_point: toISO(fetch_point)
});
export const orderCall__removeService = (order_service_id) => POST('/order/remove/service', {order_service_id});
export const orderCall__findNext = (data) => POST('/order/find/next', data);
/**
 *
 * @param {Object} data
 * @param {number} data.order_id
 * @param {number} data.vehicle_id
 * @param {Array} data.services
 * @param {number|string|Date} data.deliver_date
 * @param {number|string|Date} data.target_date
 * @param {string} [data.name]
 * @param {string} [data.info]
 * @param {boolean} [data.released]
 * @param {boolean} [data.in_range]
 * @return {Promise}
 */
export const orderCall__extendForced = (data) => PUT('/order/extend/forced', data);
/**
 *
 * @param {Object} data
 * @param {number} data.order_id
 * @param {number} data.vehicle_id
 * @param {Array} data.services
 * @param {number|string|Date} data.deliver_date
 * @param {number|string|Date} data.target_date
 * @param {string} [data.name]
 * @param {string} [data.info]
 * @param {boolean} [data.released]
 * @param {boolean} [data.in_range]
 * @return {Promise}
 */
export const orderCall__findForced = (data) => POST('/order/extend/forced', data);
/**
 *
 * @param {Object} data
 * @param {string} data.token
 * @param {string|null} [data.info]
 * @param {number|string|Date|null} [data.deliver_date]
 * @param {number|string|Date|null} [data.target_date]
 * @return {Promise}
 */
export const orderCall__extendForcedToken = data => PUT('/order/extend/forced/token', data);
export const orderCall__getPaused = () => GET('/order/paused');
export const orderCall__updateDeliveryService = (order_id, delivery_service) => POST('/order/update/delivery-service', {order_id, delivery_service});
export const orderCall__updateWaitingService = (order_id, waiting) => POST('/order/update/waiting', {order_id, waiting});
// noinspection JSUnusedGlobalSymbols
export const orderCall__putToken = (data) => {
	switch (data.version) {
		case 2:
			return orderCall__put_v2(data);
		case 4:
			return orderCall__put_v4(data);
		case 6:
			return orderCall__put_v6(data);
		default:
			throw new Error(`Version ${data.version} not recognized in orderAction__putToken()`);
	}
}
export const orderCall__getConflicts = () => GET('/order/conflicts');
export const orderCall__getConflictDetails = serviceId => GET('/order/conflict/' + serviceId);
export const orderCall__resolveConflict = serviceId => POST('/order/conflict/' + serviceId);
/**
 *
 * @param {Object} params
 * @param {Number|string|Date} [params.deliver_date]
 * @param {Number|string|Date} [params.target_date]
 * @param {Array} params.services
 * @param {Number} params.vehicle_id
 * @param {boolean} [params.loan_car]
 * @param {boolean} [params.direct_service]
 * @return {Promise | Promise<unknown>}
 */
export const orderCall__find = (params) => POST('/order/find', params);

export const orderCall__relocateResource = (resourceId) => POST(`/order/relocate/resource/${resourceId}`);

export const orderCall__extend = (params) => POST('/order/extend', params);
export const orderAction__extend = (params, throws = false) =>
	async dispatch => {
		try {
			const result = await orderCall__extend(params);
			if (result.ack) {
				if (result.type === 'direct') {
					mapDispatch(dispatch, result);
					dispatch(appointmentState__update(result));
					if ('remove_resources' in result) {
						mapState__orderResources_remove(result.remove_resources);
						dispatch(appointmentState__removeResources(result.remove_resources));
					}
				}
			}
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			} else {
				dispatch(addError(e));
				return null;
			}
		}
	};

export const orderCall__putExtend = (params) => PUT('/order/extend', params);
export const orderAction__putExtend = (params, throws = false) =>
	async dispatch => {
		try {
			const result = await orderCall__putExtend(params);
			if (result.ack) {
				mapDispatch(dispatch, result);
				dispatch(appointmentState__update(result));
				if ('remove_resources' in result) {
					mapState__orderResources_remove(result.remove_resources);
					dispatch(appointmentState__removeResources(result.remove_resources));
				}
			}
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			} else {
				dispatch(addError(e));
				return null;
			}
		}
	};

export const orderCall__putExtendForcedV2 = (params) => PUT('/order/extend/forced/v2', params);
export const orderAction__putExtendForcedV2 = (params, throws = false) =>
	async dispatch => {
		try {
			const result = await orderCall__putExtendForcedV2(params);
			if (result.ack) {
				dispatch(appointmentState__update(result));
				mapDispatch(dispatch, result);
			}
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
		
	};

export const orderCall__move = (params) => POST('/order/move', params);
export const orderCall__saveMove = (params) => PUT('/order/move', params);
export const orderAction__saveMove = (params, throws = false) =>
	async dispatch => {
		try {
			const result = await orderCall__saveMove(params);
			dispatch(appointmentState__update(params));
			mapDispatch(dispatch, result);
			'purged_services' in result && dispatch(mapState__orderServices_remove(result.purged_services));
			'removed_resources' in result && dispatch(mapState__orderResources_remove(result.removed_resources));
			'purged_resources' in result && dispatch(mapState__orderResources_remove(result.purged_resources));
			'purged_loancars' in result && dispatch(mapState__loanCars_remove(result.purged_loancars));
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__affirm = (order_id, state) => POST('/order/update/affirmation', {order_id, state});
export const orderAction__affirm = (order_id, state, throws = false) =>
	async dispatch => {
		try {
			const order = await orderCall__affirm(order_id, state);
			const orders = {orders: {[order.order_id]: order}};
			mapDispatch(dispatch, orders);
			appointmentState__update(orders);
			return order;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__postProcessed = (order_id, state) => POST('/order/update/post-process', {order_id, state});
export const orderAction__postProcessed = (order_id, state, throws = false) =>
	async dispatch => {
		try {
			const order = await orderCall__postProcessed(order_id, state);
			const orders = {orders: {[order.order_id]: order}};
			mapDispatch(dispatch, orders);
			appointmentState__update(orders);
			return order;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
		
	};

export const orderCall__processable = () => GET('/order/affirmed');
export const orderAction__processable = (throws = false) =>
	async dispatch => {
		try {
			const list = await orderCall__processable();
			dispatch(orderState__processable(list));
			return list;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__startProcessing = (order_id) => POST('/order/post-process/start/' + order_id);
export const orderAction__startProcessing = (order_id, throws = false) =>
	async dispatch => {
		try {
			const list = await orderCall__startProcessing(order_id);
			dispatch(orderState__processable(list));
			return list;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
		
	};

export const orderCall__abortProcessing = (order_id) => POST('/order/post-process/abort/' + order_id);
export const orderAction__abortProcessing = (order_id, throws = false) =>
	async dispatch => {
		try {
			const list = await orderCall__abortProcessing(order_id);
			dispatch(orderState__processable(list));
			return list;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__completeProcessing = (order_id) => POST('/order/post-process/complete/' + order_id);
export const orderAction__completeProcessing = (order_id, throws = false) =>
	async dispatch => {
		try {
			const list = await orderCall__completeProcessing(order_id);
			dispatch(orderState__processable(list));
			return list;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__updateReleaseService = (order_service_id, active, toggle) => POST('/order/update/release/service', {order_service_id, active, toggle});
export const orderAction__updateReleaseService = (order_service_id, active, toggle, throws = false) =>
	async dispatch => {
		try {
			const orderData = await orderCall__updateReleaseService(order_service_id, active, toggle);
			dispatch(appointmentState__update(orderData));
			mapDispatch(dispatch, orderData);
			return orderData;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};


export const orderCall__updateReleaseStock = (order_service_id, active, toggle) => POST('/order/update/release/stock', {order_service_id, active, toggle});
export const orderAction__updateReleaseStock = (order_service_id, active, toggle, throws = false) =>
	async dispatch => {
		try {
			const orderData = await orderCall__updateReleaseStock(order_service_id, active, toggle);
			dispatch(appointmentState__update(orderData));
			mapDispatch(dispatch, orderData);
			return orderData;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		}
	};

export const orderAction__getConflicts = () =>
	async dispatch => {
		try {
			const result = await orderCall__getConflicts();
			mapDispatch(dispatch, result);
			dispatch(orderState__setConflicts(result.conflicts));
			return result.conflicts;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const orderAction__relocateResource = (resourceId, throws = false) =>
	async dispatch => {
		try {
			const result = await orderCall__relocateResource(resourceId);
			if (result.ack) {
				mapDispatch(dispatch, result);
			}
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			} else {
				dispatch(addError(e));
				return null;
			}
		}
	};

export const orderAction__getConflictDetail = serviceId =>
	async dispatch => {
		try {
			const result = await orderCall__getConflictDetails(serviceId);
			mapDispatch(dispatch, result);
			// noinspection JSUnresolvedVariable
			return result.conflict_relation;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const orderAction__resolveConflict = (serviceId) =>
	async dispatch => {
		try {
			const result = await orderCall__resolveConflict(serviceId);
			mapDispatch(dispatch, result);
			// noinspection JSUnresolvedVariable
			dispatch(orderState__removeConflicts(result.conflicts_resolved));
			// noinspection JSUnresolvedVariable
			return result.conflicts_resolved;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};


export const orderAction__getLanes = (date, grouped) => {
	date = toISO(date);
	grouped = grouped ? '/true' : '';
	return GET('/order/day/' + date + grouped);
};
/**
 *
 * @param {number|string|Date} date
 * @param {Object} [sick]
 * @param {number|string} sick.worker_id
 * @param {number|string|Date} sick.start
 * @param {number|string|Date} sick.end
 * @param {boolean} sick.past_allowed
 * @return {*}
 */
export const orderAction__getLaneView = (date, sick) => {
	date = date ? toISO(date) : date;
	if (isObject(sick)) {
		sick.worker_id = Number(sick.worker_id && (sick.worker_id.workers_id || sick.worker_id)) || 0;
		sick.start = sick.start && toISO(sick.start);
		sick.end = sick.end && toISO(sick.end);
		sick.past_allowed = Boolean(sick.past_allowed)
	}
	const url = date ? `/order/lane/${date}` : '/order/lane';
	return sick ? POST(url, sick) : GET(url);
};

export const orderAction__getDynamicLanes = (date = 'now') => {
	if ('toISOString' in date) {
		date = date.toISOString();
	}
	return GET(`/order/dynamic${date ? "/" + date : ""}`);
};

export const orderAction__released = (order, type, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_ORDER_RELEASE, order_id));
			const result = await orderCall__released(order_id, type);
			dispatch(appointmentState__update(result));
			if (result.release_list) {
				dispatch(orderState__release(result.release_list, true));
			}
			if (result.release) {
				if (result.release.stock && result.release.service) {
					dispatch(orderState__clearReleased([order_id]));
				} else {
					dispatch(orderState__addToRelease(result.order));
				}
				dispatch(orderState__release_merge(result.release));
				dispatch(orderState__release_clear_complete());
			}
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_RELEASE, order_id));
		}
	};
export const orderAction__releaseList = (list, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER_RELEASE_LIST));
			const result = await orderCall__releaseList(list);
			"services" in result && dispatch(mapState__orderServices_add(result.services));
			"releases" in result && dispatch(mapState__releases_add(result.releases));
			"cleared" in result && result.cleared.length && dispatch(orderState__removeReleaseIDs(result.cleared));
			// dispatch(orderState__clearReleased(result.cleared));
			// dispatch(orderState__release_merge(result.releases));
			// dispatch(orderState__release_clear_complete());
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_RELEASE_LIST));
		}
	};
export const orderAction__delivered = (order, state, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_ORDER_DELIVER_STATE, order_id));
			const result = await orderCall__delivered(order_id, state);
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_DELIVER_STATE, order_id));
		}
	};
export const orderAction__fetched = (order, state, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_ORDER_FETCH_STATE, order_id));
			const result = await orderCall__fetched(order_id, state);
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_FETCH_STATE, order_id));
		}
	};


let LAST_LOADED_DAY = "today";

export const workAction__ofDay = (day = LAST_LOADED_DAY, grouped, worker, onSuccess, hideErrors = false) =>
	async (dispatch, getState) => {
		const workers_id = worker.workers_id || worker;
		day = day || LAST_LOADED_DAY;
		try {
			dispatch(setLoader(PROC_ORDER_DAY));
			grouped = grouped ? '/true' : '';
			worker = grouped && workers_id ? `/${workers_id}` : '';
			const result = await GET('/order/day/' + day + grouped + worker);
			LAST_LOADED_DAY = day;
			mapDispatch(dispatch, result.orders);
			dispatch(workState__load(result.list, result.orders));
			// dispatch(appointmentState__set(result.orders));
			// dispatch(appointmentState__list(result.list));
			// dispatch(appointmentState__paused(result.paused));
			// dispatch(appointmentState__dayLoaded());
			let appmnts = [
				appointmentState__set(result.orders),
				appointmentState__list(result.list),
				appointmentState__paused(result.paused)
			];
			!getState().appointments.loaded.daywork && appmnts.push(appointmentState__dayLoaded());
			"orderlist" in result && appmnts.push(appointmentState__workMap(result.orderlist));
			if (('day_schedule' in result) && ('schedule' in result.day_schedule) && workers_id) {
				const {schedule, day: responseDay, duration} = result.day_schedule;
				dispatch(scheduleState__workSchedule(worker.workers_id || worker, moment(responseDay).format("YYYY-MM-DD"), schedule, duration))
			}
			dispatch(appointmentState__multi(...appmnts));
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			if (hideErrors) {
				console.warn('Loading work of day failed with error', e);
			} else {
				dispatch(addError(e));
			}
		} finally {
			dispatch(unsetLoader(PROC_ORDER_DAY));
		}
	};

export const orderAction__get = (starts = null, till = null, force = false, onSuccess) =>
	async (dispatch) => {
		try {
			dispatch(setLoader(PROC_ORDER));
			let url = '/order';
			if (starts) {
				till = till || starts;
				if (starts instanceof Date) {
					starts = starts.toISOString();
				}
				if (till instanceof Date) {
					till = till.toISOString()
					;
				}
				url += `/ranged/${starts}/${till}`;
			}
			const result = await GET(url);
			dispatch(orderState__meta('orders', starts, till));
			dispatch(orderState__set(result));
			mapDispatch(dispatch, result);
			"workload" in result && dispatch(scheduleState__setWorkload(result.workload.workload));
			isFunction(onSuccess) && onSuccess(result, dispatch);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
	};
// export const orderAction__get2 = appointmentAction__load.bind(null, null, false);

export const orderState__updateSSE = (data) =>
	(dispatch) => {
		// if ( moment())
		dispatch(orderState__insertPartial(data));
		"orders" in data && dispatch(mapState__orders_add(data.orders));
	};

export const orderAction__put = (data, throwError = false) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER));
			const result = await orderCall__put(data);
			// dispatch(appointmentState__set(result.result));
			// mapDispatch(dispatch, result.result);
			dispatch(appointmentState__set(result));
			'remove_resources' in result && dispatch(appointmentState__removeResources(result.remove_resources));
			mapDispatch(dispatch, result);
			return result;
		} catch (e) {
			if (throwError) {
				throw e;
			} else {
				dispatch(addError(e));
			}
			return null;
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
	};
/**
 *
 * @param {Object} data
 * @param {string} data.token
 * @param {string} [data.info]
 * @param {boolean} [data.force]
 * @param {number} [data.loan_car_id]
 * @param {(Date|number|string)} [data.next_start]
 * @param {(Date|number|string)} [data.next_end]
 * @param onSuccess
 * @return {Function}
 */
export const orderAction__putToken = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER));
			let result;
			// noinspection JSUnresolvedVariable
			switch (data.version) {
				case 2:
					result = await orderCall__put_v2(data);
					dispatch(appointmentState__update(result));
					mapDispatch(dispatch, result);
					break;
				case 4:
					result = await orderCall__put_v4(data);
					dispatch(appointmentState__update(result));
					mapDispatch(dispatch, result);
					break;
				case 6:
					result = await orderCall__put_v6(data);
					// noinspection JSUnresolvedVariable
					dispatch(appointmentState__removeResources(result.remove_resources));
					dispatch(appointmentState__update(result.data));
					mapDispatch(dispatch, result.data);
					break;
				default:
					// noinspection ExceptionCaughtLocallyJS,JSUnresolvedVariable
					throw new Error(`Version ${data.version} not recognized in orderAction__putToken()`);
			}
			
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
	};

export const orderAction__extendToken = (data, onSuccess) =>
	async (dispatch) => {
		try {
			dispatch(setLoader(PROC_ORDER, data.order_id));
			let result;
			switch (data.version) {
				case 2:
					result = await orderCall__extend_v2(data);
					dispatch(appointmentState__update(result));
					mapDispatch(dispatch, result);
					break;
				case 4:
					result = await orderCall__extend_v4(data);
					dispatch(appointmentState__update(result));
					mapDispatch(dispatch, result);
					break;
				case 6:
					result = await orderCall__extend_v6(data);
					// noinspection JSUnresolvedVariable
					dispatch(appointmentState__removeResources(result.remove_resources));
					dispatch(appointmentState__update(result.data));
					mapDispatch(dispatch, result.data);
					break;
				default:
					// noinspection ExceptionCaughtLocallyJS
					throw new Error(`Version ${data.version} not recognized in orderAction__extendToken()`);
			}
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER, data.order_id));
		}
	};

export const orderAction__putForced = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER));
			const result = await orderCall__put_forced(data);
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
		
	};


let getOneQueue = {};
export const orderAction__getOne = (order, force = false) =>
	async (dispatch, getState) => {
		const order_id = order.order_id || order;
		if (!getOneQueue[order_id]) {
			getOneQueue[order_id] = new Promise(async k => {
				let order = get(getState(), ['orders', 'order', order_id], null);
				try {
					if (order && !force) {
						k(order);
						return order;
					}
					dispatch(setLoader(PROC_ORDER, order_id));
					const response = await GET(`/order/id/${order_id}`);
					
					k(response.orders[order_id]);
					return response.orders[order_id];
				} catch (e) {
					dispatch(addError(e));
					return null;
				} finally {
					dispatch(unsetLoader(PROC_ORDER, order_id));
					delete getOneQueue[order_id];
				}
			});
		}
		const orderResult = await getOneQueue[order_id];
		dispatch(orderState__insertPartial({[orderResult.order_id]: orderResult}));
		dispatch(mapState__orders_add(orderResult));
		return orderResult;
	};

let releaseQueue = {};
export const orderAction__getReleaseOf = (order, force = false) =>
	async (dispatch, getState) => {
		const order_id = order.order_id || order;
		if (!releaseQueue[order_id]) {
			releaseQueue[order_id] = new Promise(async k => {
				let release = get(getState(), ['orders', 'releases', order_id], null);
				
				try {
					if (release && !force) {
						k(release);
						return release;
					}
					dispatch(setLoader(PROC_ORDER_RELEASE, order_id));
					release = await orderCall__releaseOf(order_id);
					k(release);
					return release;
				} catch (e) {
					console.error(e);
					dispatch(addError(e));
				} finally {
					dispatch(unsetLoader(PROC_ORDER_RELEASE, order_id));
					delete (releaseQueue[order_id]);
				}
			});
		}
		const release = await releaseQueue[order_id];
		dispatch(orderState__release_merge({[order_id]: release}));
		return release;
	};
export const orderAction__removeConsultant = (orderId, throws = false) =>
	async dispatch => {
		try {
			const order = await orderCall__removeConsultant(orderId);
			dispatch(mapState__orders_add(order));
			dispatch(orderState__update({orders: {[order.order_id]: order}}));
			return order;
		} catch (e) {
			if (throws) {
				throw e;
			} else {
				dispatch(addError(e));
			}
			return null;
		}
	};
export const orderAction__updateConsultant = (orderId, consultantId, throws = false) =>
	async dispatch => {
		try {
			const order = await orderCall__updateConsultant(orderId, consultantId);
			dispatch(mapState__orders_add(order));
			dispatch(orderState__update({orders: {[order.order_id]: order}}));
			return order;
		} catch (e) {
			if (throws) {
				throw e;
			} else {
				dispatch(addError(e));
			}
			return null;
		}
	};
export const orderAction__activities = (onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER));
			const result = await GET('/order/activities');
			dispatch(orderState__activities(result.activities));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
	};
export const orderAction__fetchOne = (order, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_ORDER, order_id));
			const result = await orderCall__get(order_id);
			dispatch(appointmentState__set(result, true));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER, order_id));
		}
	};
export const orderAction__fetchOneCached = (order_id, onSuccess) =>
	async (dispatch, getState) => {
		const orders = getState().orders.orders;
		if (order_id in orders) {
			isFunction(onSuccess) && onSuccess(orders[order_id]);
			return;
		}
		try {
			dispatch(setLoader(PROC_ORDER, order_id));
			const result = await orderCall__get(order_id);
			dispatch(orderState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER, order_id));
		}
	};

export const orderAction__getForRelease = (onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER_RELEASE));
			const list = await GET('/order/release');
			dispatch(orderState__release(list.orders));
			dispatch(orderState__release_set(list.releases));
			isFunction(onSuccess) && onSuccess(list);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_RELEASE));
		}
	};

export const orderAction__delete = (order, onSuccess, redirectOnError = true) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER_DELETE));
			const result = await orderCall__delete(order);
			// const result = {order_id: order.order_id || order};
			publish(SUB_ORDER_DELETE, result.order_id);
			dispatch(orderState__delete(result.order_id));
			dispatch(appointmentState__delete(result.order_id));
			dispatch(mapState__orders_remove(result.order_id));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, redirectOnError));
		} finally {
			dispatch(unsetLoader(PROC_ORDER_DELETE));
		}
	};

export const orderAction__updateRanges = (order_id, deliver_point, fetch_point, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER, order_id));
			const result = await orderCall__updateRanges(order_id, deliver_point, fetch_point);
			if (result.ack) {
				dispatch(orderState__update(result));
				dispatch(appointmentState__update(result));
				mapDispatch(dispatch, result);
			}
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_ORDER, order_id));
		}
	};

export const orderAction__removeService = (order_service_id, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER));
			const result = await orderCall__removeService(order_service_id);
			dispatch(orderState__unset(result.remove));
			dispatch(orderState__update(result.change));
			"orders" in result.remove && dispatch(mapState__orders_remove(Object.values(result.remove.orders)));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER));
		}
	};

export const orderAction__updateInfo = (order_id, info, onSuccess, throws = false) =>
	async dispatch => {
		try {
			// dispatch(setLoader(PROC_ORDER, order_id));
			const result = await orderCall__updateInfo(order_id, info);
			dispatch(orderState__update(result));
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			if (throws) {
				throw e
			}
			dispatch(addError(e));
			return null
		}
	};

/**
 *
 * @param {Object} data
 * @param {number} data.order_id
 * @param {number} data.vehicle_id
 * @param {Array} data.services
 * @param {number|string|Date} data.deliver_date
 * @param {number|string|Date} data.target_date
 * @param {string} [data.name]
 * @param {string} [data.info]
 * @param {boolean} [data.released]
 * @param {boolean} [data.in_range]
 * @param {function} [onSuccess]
 * @return {Promise}
 */
export const orderAction__extendForced = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER, data.order_id));
			const result = await orderCall__extendForced(data);
			dispatch(orderState__update(result));
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER, data.order_id));
		}
	};

/**
 *
 * @param {Object} data
 * @param {string} data.token
 * @param {string|null} [data.info]
 * @param {number|string|Date|null} [data.deliver_date]
 * @param {number|string|Date|null} [data.target_date]
 * @param {function} [onSuccess]
 * @return {*}
 */
export const orderAction__extendForcedToken = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER, data.token));
			const result = await orderCall__extendForcedToken(data);
			dispatch(orderState__update(result));
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_ORDER, data.token));
		}
	};

let __releasableList = false;
// noinspection JSUnusedLocalSymbols
export const orderAction__releasableList = (force = false, initData = false) =>
	async (dispatch, getState) => {
		let ids = getState().orders.release_data;
		if (__releasableList) {
			return [];
		}
		if (!force && ids.length) {
			return ids;
		}
		try {
			__releasableList = true;
			dispatch(setLoader(PROC_ORDER_RELEASE));
			const result = await orderCall__releasable();
			
			if (result.data.length) {
				let oid = {}, vid = {}, cid = {}, sid = {};
				for (const d of result.data) {
					oid[d.order_id] = d.order_id;
					vid[d.order_vehicle_id] = d.order_vehicle_id;
					cid[d.client_id] = d.client_id;
					sid[d.order_service_id] = d.order_service_id;
				}
				oid = Object.values(oid);
				// vid = Object.values(vid);
				// cid = Object.values(cid);
				// sid = Object.values(sid);
				await dispatch(orderAction__collect(oid, true));
				// await Promise.all([
				// 	dispatch(orderAction__collect(oid)),
				// 	dispatch(vehicleAction__collectOrders(vid)),
				// 	dispatch(clientAction__collect(cid)),
				// 	dispatch(orderAction__collectServices(sid))
				// ]);
			}
			dispatch(orderState__setReleaseIDs(result.data));
			dispatch(mapState__releases_add(result.releases));
			return result.releases || [];
		} catch (e) {
			dispatch(addError(e));
			return [];
		} finally {
			__releasableList = false;
			dispatch(unsetLoader(PROC_ORDER_RELEASE));
		}
	};

export const orderAction__listDay = (day, extendedCalls = false) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_ORDER_DAY_LIST));
			const result = await orderCall__listDay(day);
			if (extendedCalls) {
				let clientID = [], vehicleID = [], orderVehicleID = [], serviceID = [], orderServiceID = [];//, loanCarID = [];
				for (const order of result.orders) {
					clientID.push(order.client_id);
					vehicleID.push(order.vehicle_id);
					orderVehicleID.push(order.order_vehicle_id);
					// order.loan_car_schedule_id && loanCarID.push(order.loan_car_schedule_id);
					order.loan_car_id && vehicleID.push(order.loan_car_id);
					for (const service of values(order.services)) {
						serviceID.push(service.service_id);
						orderServiceID.push(service.order_service_id);
					}
				}
				'workload' in result && 'workload' in result.workload && dispatch(scheduleState__setWorkload(result.workload.workload));
				clientID.length && dispatch(clientAction__collect(clientID));
				vehicleID.length && dispatch(vehicleAction__collect(vehicleID));
				orderVehicleID.length && dispatch(vehicleAction__collectOrders(orderVehicleID));
				serviceID.length && dispatch(serviceAction__collect(serviceID));
				orderServiceID.length && dispatch(orderAction__collectServices(orderServiceID));
				orderServiceID.length && dispatch(orderAction__collectReleases(orderServiceID));
				// loanCarID.length && dispatch(loanCarAction__collect(loanCarID));
			}
			return result;
		} catch (e) {
			throw e;
		} finally {
			dispatch(unsetLoader(PROC_ORDER_DAY_LIST));
		}
	};

export const orderAction__updateDeliveryService = (order_id, delivery_service) =>
	async dispatch => {
		try {
			const order = await orderCall__updateDeliveryService(order_id, delivery_service);
			dispatch(mapState__orders_add(order));
			return order;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const orderAction__updateWaitingService = (order_id, waiting) =>
	async dispatch => {
		try {
			const order = await orderCall__updateWaitingService(order_id, waiting);
			dispatch(mapState__orders_add(order));
			return order;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const orderCall__updateFetchPoint = (order_id, date) => POST(`/order/update/fetch_point/${order_id}/${date}`);
export const orderAction__updateFetchPoint = (order_id, date, throws = false) =>
	async dispatch => {
		try {
			const order = await orderCall__updateFetchPoint(order_id, date)
			const data = {orders: {[order.order_id]: order}}
			mapDispatch(dispatch, data)
			dispatch(appointmentState__update(data))
			dispatch(orderState__update(data));
			return order;
		} catch (e) {
			if (throws) {
				throw e
			}
			dispatch(addError(e))
			return null
		}
	};
	

let __collect = {};
export const orderAction__collect = (orders, full = false) => {
	return Collector._(__collect, orders, 'order_id')
		.withLoader([PROC_ORDER])
		.withResultProvider('orders')
		.withStateProvider('map.orders')
		.withApiProvider(list => orderCall__collect(list, full))
		.withStoreProvider((result, dispatch) => mapDispatch(dispatch, result))
		.execute();
};


let __collectService = {};
export const orderAction__collectServices = (services) =>
	Collector._(__collectService, services, 'order_service_id')
		.withLoader([PROC_ORDER_SERVICE])
		.withStateProvider('map.order2services')
		.withApiProvider(list => orderCall__collectServices(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__orderServices_add(result)))
		.execute();

let __collectResource = {};
export const orderAction__collectResources = (resources) =>
	Collector._(__collectResource, resources, 'order_service_resource_id')
		.withLoader([PROC_ORDER_RESOURCE])
		.withStateProvider('map.order2service2resources')
		.withApiProvider(list => orderCall__collectResources(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__orderResources_add(result)))
		.execute();

let __collectRelease = {};
export const orderAction__collectReleases = (releases) =>
	Collector._(__collectRelease, releases, 'order_service_id')
		.withLoader([PROC_ORDER_RELEASE])
		.withStateProvider('map.releases')
		.withApiProvider(list => orderCall__collectReleases(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__releases_add(result)))
		.execute();

let __collectOrderRelease = {};
export const orderAction__collectOrderReleases = (orders) =>
	Collector._(__collectOrderRelease, orders, 'order_service_id')
		.withLoader([PROC_ORDER_RELEASE])
		.withApiProvider(list => orderCall__collectReleasesByOrders(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__releases_add(result)))
		.execute();

let __collectWork = {};
export const orderAction__collectWork = (work) =>
	Collector._(__collectWork, work, 'work_id')
		.withLoader([PROC_USER_FETCH_WORK])
		.withStateProvider('map.work')
		.withApiProvider(list => orderCall__collectWork(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__work_add(result)))
		.execute();

