import React, {Component} from 'react';
import IndexController from "../cointainer/IndexController";
import MainController from "../cointainer/intern/MainController";
import {translate} from 'react-i18next';
import EventHandler from "../cointainer/partial/EventHandler";
import {dismissError} from "../actions/errorActions";
import {showMenu, toggleMenu} from "../actions/menuActions";
import {dismissDialog} from "../actions/dialogActions";
import './../Logic/extensions';
import SnackbarController from "../cointainer/SnackbarController";
import ErrorControllerV2 from "../cointainer/ErrorControllerV2";
import {ErrorBoundary} from "../cointainer";
import {connect} from "react-redux";
import {isOnline, prefetch} from "../actions/userActions";
import withInit from "../Logic/withInit";
import {SSEWrapper} from "../cointainer/intern";
import constants from "../Logic/constants";
import {dataHandler} from "../actions/sse";
import {VersionNotification} from "./VersionNotification";
import {AlarmSound} from "./index";
import cn from 'classnames';
import {isMobile} from 'react-device-detect';

let Online = ({user, children}) => user && children;
Online = withInit(Online);
Online = connect(
	state => ({
		user: state.user.data
	}),
	dispatch => ({
		init: () => dispatch(isOnline(() => {
			dispatch(prefetch());
		}))
	})
)(Online);



class AppView extends Component {
	
	componentDidMount() {
		this.props.onPreload && this.props.onPreload();
	}
	
	render() {
		return (
			<ErrorBoundary visible>
				<div className={cn("App", {'is-mobile': isMobile})}>
					<AlarmSound/>
					{this.props.user.data === false && <IndexController/>}
					<Online>
						<SSEWrapper
							endpoint={constants.URL + '/sse'}
							options={{withCredentials: true}}
							reconnectAfter={3000}
							// debug={!PRODUCTION_MODE}
							onMessage={dataHandler}
						>
							<MainController/>
						</SSEWrapper>
					</Online>
					
					{/*<DialogContainer/>*/}
					{/*<ErrorController/>*/}
					{/*<FloatingActionButton mini style={{zIndex: 5, position: 'fixed', right: 5, bottom: 5}}><MenuIcon/></FloatingActionButton>*/}
					<ErrorControllerV2/>
					<SnackbarController/>
					{/*<LoaderController size={75} color="#61DAFB"/>*/}
					<EventHandler onKeyUp={(e, dispatch, state) => {
						if (state.errors.list.length) {
							e.preventDefault();
							e.stopImmediatePropagation();
							if (e.which === 27) {
								dispatch(dismissError());
							}
							return;
						}
						if (state.dialogs.length) {
							e.preventDefault();
							e.stopImmediatePropagation();
							if (e.which === 27) {
								dispatch(dismissDialog());
							}
							return;
						}
						if (state.menu && e.which === 27) {
							e.preventDefault();
							e.stopImmediatePropagation();
							dispatch(showMenu(false));
							return;
						}
						if (e.altKey && e.ctrlKey && e.keyCode === 77) {
							e.preventDefault();
							e.stopImmediatePropagation();
							dispatch(toggleMenu());
						}
					}}/>
					<VersionNotification/>
				</div>
			</ErrorBoundary>
		);
	}
	
}

export default translate()(AppView);
