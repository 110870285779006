'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compareDefault = exports.equals = exports.compareNum = exports.compare = exports.COMPARE_DEFAULT_OPTIONS = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _fuzzysearch = require('fuzzysearch');

var _fuzzysearch2 = _interopRequireDefault(_fuzzysearch);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var lowered = function lowered() {
  var what = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var toLower = arguments[1];
  return toLower ? what.toLowerCase() : what;
};

var trimed = function trimed() {
  var what = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var trimed = arguments[1];
  return trimed ? what.trim() : what;
};

var _ = function _(what) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    lowercase: true,
    trimed: true
  };
  return lowered(trimed(what, options.trimed), options.lowercase);
};

var COMPARE_DEFAULT_OPTIONS = exports.COMPARE_DEFAULT_OPTIONS = {
  fuzzy: true,
  lowercase: true,
  trimed: true
};

var compare = exports.compare = function compare(needle) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : COMPARE_DEFAULT_OPTIONS;
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      options = _extends({}, COMPARE_DEFAULT_OPTIONS, options);
      return options.fuzzy ? (0, _fuzzysearch2.default)(_(needle, options), _(collector(haystack), options)) : !!_(collector(haystack), options).match(_(needle, options));
    };
  };
};

var compareNum = exports.compareNum = function compareNum(needle) {
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      return Number(needle) === Number(collector(haystack));
    };
  };
};

var equals = exports.equals = function equals(needle) {
  var lowercased = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      return lowered(needle, lowercased) === lowered(collector(haystack));
    };
  };
};

var compareDefault = exports.compareDefault = function compareDefault() {
  return true;
};