import {connect} from 'react-redux';
import LoginView from '../../components/partials/LoginView';
import PropTypes from 'prop-types';
import {login, requestEmailLogin, requestNewPassword} from '../../actions/userActions'
import {isLoading} from "../../actions/loaderActions";
import {PROC_USER_LOGIN} from "../../actions";
import isFunc from 'lodash/isFunction';
import {addSnackbar} from "../../actions/snackbarActions";

const mapDispatchToProps = (dispatch, ownProps) => ({
	onLogin: (email, password, onSuccess=null, actions={}) => {
		dispatch(login(ownProps.house_id, email, password, onSuccess, actions));
	},
	onEmailRequest: (email, house, onSuccess) => dispatch(requestEmailLogin(email, house, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.login.requested'));
	})),
	onRequestPassword: (email, house, onSuccess) => dispatch(requestNewPassword(email, house, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.login.password-sent'));
	}))
});

const mapStateToProps = (state, ownProps) => {
	return {
		isLoading: isLoading(state, PROC_USER_LOGIN),
	}
};

let LoginController = connect(mapStateToProps, mapDispatchToProps)(LoginView);

LoginController.propTypes = {
	house_id: PropTypes.number.isRequired,
	title: PropTypes.string,
	logo: PropTypes.object,
	triggerSSEReconnect: PropTypes.bool
};

export default LoginController;