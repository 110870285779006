import * as React from "react";
import PropTypes from "prop-types";
import {isFunction, isString, mapValues, values} from "lodash";
import {filter_object} from "../../../../Logic/extensions";
import {Checkbox, Dropdown, Form, FormField, FormTextArea} from "semantic-ui-react";
import EditField from "../../../../components/partials/EditField";
import DropdownField from "../../../../components/partials/DropdownField";
import {IconPerson, IconTimelapse} from "../../../../Logic/icons";
import {Flex, FlexChild} from "../../../../components/partials/ActionHeader";
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {resourceAction__fetch} from "../../../../actions/resourceActions";
import {serviceAction__mockSaveCustom, serviceAction__saveCustom} from "../../../../actions/serviceActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {translate} from "react-i18next";
import {PROC_SERVICE_CUSTOM} from "../../../../actions";
import {withLoading} from "../../../../actions/loaderActions";

const DEFAULT_DATA = {
	title: "",
	description: "",
	resource_id: 0,
	price: "",
	hours_of_work: "",
	hours_of_work_resource: "",
	tuv: false
};
const DEFAULT_VISITED = {
	title: false,
	description: false,
	resource_id: false,
	price: false,
	hours_of_work: false,
	hours_of_work_resource: false,
	tuv: false
};

export class CustomServiceForm extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func,
		showErrors: PropTypes.bool,
		errorsBeneeth: PropTypes.bool,
		maxWidth: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		hideLoader: PropTypes.bool,
		mock: PropTypes.bool,
		onCreated: PropTypes.func
	};
	static defaultProps = {
		onSubmit: () => alert("CustomServiceForm::onSubmit() is missing in props!"),
		maxWidth: 400
	};
	state = {
		data: DEFAULT_DATA,
		visited: DEFAULT_VISITED
	};
	
	update = (e, {value, name}) => this.setState(state => ({...state, data: {...state.data, [name]: value}}));
	
	check = (e, {checked, name}) => this.setState(state => ({...state, data: {...state.data, [name]: checked}}));
	
	text = () => {
		let result = this.props.resources.find(v => v.resource_id === this.state.data.resource_id);
		return result ? result.name : "";
	};
	
	reset = () => this.setState({
		data: DEFAULT_DATA,
		visited: DEFAULT_VISITED
	});
	
	visit = e => this.setState(state => ({...state, visited: {...state.visited, [e]: true}}));
	visitAll = (state = true, callback) => this.setState({
		visited: mapValues(this.state.visited, () => Boolean(state))
	}, callback);
	
	visited = (name) => this.props.showErrors || this.state.visited[name];
	value = (name) => this.state.data[name];
	
	validate = () => {
		return filter_object({
			title: this.visited("title") && this.value("title").trim() === "" && "Titel is Pflichtfeld",
			resource_id: this.visited("resource_id") && this.value("resource_id") <= 0 && "Ressource muss ausgewählt werden",
			// price: this.visited("price") && this.value("price") <= 0 && "Preis muss angeben werden",
			hours_of_work: this.visited("hours_of_work") && this.value("hours_of_work") < 0.1 && "Arbeitszeit muss 0.1 oder größer sein"
		})(isString);
	};
	
	submit = (e) => {
		if (!e.isPropagationStopped()) {
			this.visitAll(true, () => {
				const invalid = values(this.validate()).reduce((carry, value) => Boolean(carry || value), false);
				if (invalid) {
					console.warn('form is invalid!');
					return;
				}
				const {onMock, onSubmit, mock} = this.props;
				const data = {...this.state.data};
				data.hours_of_work_resource = data.hours_of_work_resource || data.hours_of_work;
				mock ? onMock(data) : onSubmit.call(this, data);
			});
			
		}
	};
	
	render() {
		let {style, t, i18n, tReady, init, resources, allRights, rights, user, dispatch, errorsBeneeth, maxWidth, onSave, loading, hideLoader, onMock, mock, showErrors, onCreated, ...props} = this.props;
		const {data: {title, description, resource_id, /*price,*/ hours_of_work, hours_of_work_resource, tuv}} = this.state;
		const valid = this.validate();
		return(
			<Form loading={!hideLoader && loading} style={{maxWidth, ...style}} widths={"equal"} {...props} onSubmit={this.submit}>
				<FormField>
					<EditField
						text={"Service-Titel"}
						required
						labelPosition={"top"}
						name={"title"}
						value={title}
						editable
						errorText={valid.title}
						errorBeneeth={errorsBeneeth}
						onChange={this.update}
						onBlur={e => this.visit(e.target.name)}
					/>
				</FormField>
				<FormField>
					<Checkbox checked={tuv} label={"TÜV"} name={"tuv"} onChange={this.check}/>
				</FormField>
				<FormField>
					<FormTextArea
						name={"description"}
						label={"Beschreibung"}
						value={description}
						// autoHeight
						onChange={this.update}
					/>
				</FormField>
				<FormField>
					<DropdownField
						required
						editable
						name={"resource_id"}
						label={"Ressource wählen"}
						value={resource_id}
						selection
						errorText={valid.resource_id}
						errorBeneeth={errorsBeneeth}
						placeholder={"Ressource wählen"}
						text={this.text()}
						useInput
						icon={<IconPerson/>}
						onClose={(e, {name}) => this.visit(name)}
					>
						<Dropdown.Menu>
							{ resources.map(resource =>
								<Dropdown.Item name={"resource_id"} value={resource.resource_id} key={resource.resource_id} onClick={this.update}><span style={{paddingLeft: 25}}>{resource.name}</span></Dropdown.Item>
							)}
						</Dropdown.Menu>
					</DropdownField>
				</FormField>
				{/*<FormField>*/}
				{/*	<EditField*/}
				{/*		required*/}
				{/*		name={"price"}*/}
				{/*		editable*/}
				{/*		type={"number"}*/}
				{/*		step={0.01}*/}
				{/*		min={0}*/}
				{/*		errorText={valid.price}*/}
				{/*		errorBeneeth={errorsBeneeth}*/}
				{/*		text={"Preis /Std."}*/}
				{/*		icon={<IconEuro/>}*/}
				{/*		value={price}*/}
				{/*		onChange={this.update}*/}
				{/*		onBlur={e => this.visit(e.target.name)}*/}
				{/*	/>*/}
				{/*</FormField>*/}
				<Flex gutterWidth={10} gutterHeight={10}>
					<FlexChild grow={1}>
						<FormField>
							<EditField
								required
								inline
								editable
								name={"hours_of_work"}
								type={"number"}
								step={0.1}
								min={0.1}
								max={99.9}
								errorText={valid.hours_of_work}
								errorBeneeth={errorsBeneeth}
								text={"Arbeitzeit in Std."}
								icon={<IconTimelapse/>}
								value={hours_of_work}
								onChange={this.update}
								onBlur={e => this.visit(e.target.name)}
							/>
						</FormField>
					</FlexChild>
					<FlexChild grow={1}>
						<FormField>
							<EditField
								inline
								editable
								name={"hours_of_work_resource"}
								type={"number"}
								step={0.1}
								min={0.1}
								max={99.9}
								label={"Arbeitzeit in Std. (real)"}
								placeholder={Number(hours_of_work) ? hours_of_work : "Arbeitzeit in Std. (real)"}
								icon={<IconTimelapse/>}
								value={hours_of_work_resource}
								onChange={this.update}
							/>
						</FormField>
					</FlexChild>
				</Flex>
			</Form>
		);
	}
}

CustomServiceForm = withRights(SECTION.ORDERS, CustomServiceForm);
CustomServiceForm = withInit(CustomServiceForm);
CustomServiceForm = withLoading("loading", PROC_SERVICE_CUSTOM)(CustomServiceForm);
CustomServiceForm = connect(
	(state, props) => {
		return {
			resources: props.resources || values(state.resources.list) || [],
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(resourceAction__fetch());
			})),
			onSubmit: props.onSubmit || ((data, onSuccess) => dispatch(serviceAction__saveCustom(data, result => {
				dispatch(addSnackbar("Service angelegt"));
				isFunction(props.onCreated) && props.onCreated(result);
				isFunction(onSuccess) && onSuccess(result);
			}))),
			onMock: (data, onSuccess) => dispatch(serviceAction__mockSaveCustom(data, result => {
				dispatch(addSnackbar("Service angelegt (mock)"));
				isFunction(onSuccess) && onSuccess(result);
			}))
		};
	}
)(CustomServiceForm);
CustomServiceForm = translate()(CustomServiceForm);
// eslint-disable-next-line
export const propTypes = CustomServiceForm.propTypes;
export const defaultProps = CustomServiceForm.defaultProps;