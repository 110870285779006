import * as React from "react";
import {Client} from "../../../models";
import {Segment} from "semantic-ui-react";
import PropTypes from "prop-types";
import {IconBusiness, IconPerson} from "../../../Logic/icons";
import {EasyFlex} from "../../partials/ActionHeader";
import {Icon, Line, Spacer} from "../../partials/PopupInfo";


const ClientPopupInfo = ({client, inverted, tableProps, lineStyle, ...props}) => {
	if (!client) {
		return null;
	}
	let c;
	if (client instanceof Client) {
		c = client;
	} else {
		c = Client._(client);
	}
	return <Segment basic inverted={inverted} {...props}>
		<table border={0} cellPadding={6} style={{borderCollapse: "collapse"}} {...tableProps}>
			<tbody>
				<Line inverted={inverted} style={lineStyle} name="Name"><EasyFlex valign={EasyFlex.valign.END}>{c.is_company ? <Icon icon={IconBusiness}/> : <Icon icon={IconPerson}/>} {c.is_company ? c.company_name : c.contact_full}</EasyFlex></Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="Kundennumer">{c.client_number}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="E-Mail">{c.email}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="Tel.">{c.phone_full}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="Mobil">{c.mobile_full}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="Adresse">{c.street_full}</Line>
				<Spacer/>
				<Line inverted={inverted} style={lineStyle} name="Ort">{c.city_full}</Line>
			</tbody>
		</table>
	</Segment>
};
ClientPopupInfo.propTypes = {
	client: Client.propTypes__both.isRequired,
	inverted: PropTypes.bool,
	tableProps: PropTypes.object
};
ClientPopupInfo.defaultProps = {
	inverted: true
};

export default ClientPopupInfo;