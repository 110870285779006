import * as React from 'react';
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {moment} from "../../Logic/Moment";
import {TextField} from "material-ui";
import {MaterialCompoundDateInput, TimeInput} from "../../Tools/DatePicker";


export class TimeInputTest extends React.Component {
	state = {
		date: null,
		end: null
	}
	render() {
		return (
			<Segment basic>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Time Input Test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<TimeInput time={this.state.date} onProvide={date => this.setState({date})} placeholder={'time'}/>
					<TimeInput time={this.state.date} onProvide={date => this.setState({date})}>
						<TextField floatingLabelText={'Time'}/>
					</TimeInput>
					<MaterialCompoundDateInput selectsStart baseDate={this.state.end} startDate={this.state.date} endDate={this.state.end} dateLabel={'Startdatum'} timeLabel={'Startzeit'} date={this.state.date} onChange={date => this.setState({date})}/>
					<MaterialCompoundDateInput selectsEnd baseDate={this.state.date} startDate={this.state.date} endDate={this.state.end} dateLabel={'Enddatum'} timeLabel={'Endzeit'} date={this.state.end} onChange={end => this.setState({end})} endOfDay/>
					<pre style={{marginTop: 30}}>
						<div>start: {this.state.date ? moment(this.state.date).format('LLL') : 'NONE'}</div>
						<div>end  : {this.state.end ? moment(this.state.end).format('LLL') : 'NONE'}</div>
					</pre>
				</div>
			</Segment>
		);
	}
}