import * as React from 'react';
import PropTypes from 'prop-types';
import {Label, Button, Segment} from "semantic-ui-react";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../partials/ActionHeader";
import {translate} from "react-i18next";
import NoAccess from "../../../NoAccess";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {LoanCarList} from "../../vehicles/widgets/LoanCarList";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {withModule} from "../../../../Tools/RightsProvider";

const ConnectedLoanCarList = connect(
	null,
	dispatch => ({
		onShowVehicle: vehicle => dispatch(push(`/clients/${vehicle.client_id}/vehicles/${vehicle.vehicle_id}`)),
		onNotificationGood: (message) => dispatch(addSnackbar(message, 'black'))
	})
)(LoanCarList);


class LoanCarListView extends React.Component {
	static propTypes = {
		onBack: PropTypes.func,
		mayRead: PropTypes.bool,
	};
	static defaultProps = {
		mayRead: false,
	};
	
	state = {
		methods: null
	};
	
	updateMethods = methods => this.setState({methods});
	
	render() {
		const {t, onBack, mayRead, modules} = this.props;
		
		if ( !mayRead ) return <NoAccess/>;
		const {methods} = this.state;
		
		return(
			<Segment basic>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						{onBack && <Button icon={'angle left'} basic onClick={onBack}/>}
						<ActionHeading>{t('loan-car.list-title')} {!modules.loancar && <Label style={{marginLeft: 8}} color={'red'}>Modul inaktiv</Label>}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{methods && <Button basic icon={'redo'} onClick={methods.load}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<ConnectedLoanCarList methodProvider={this.updateMethods}/>
			</Segment>
		);
	}
}
LoanCarListView = withModule(LoanCarListView);
export default translate()(LoanCarListView);