import React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import AvatarF from '../../../images/avatar-f.png';
import AvatarM from '../../../images/avatar-m.gif';
import {Segment} from "semantic-ui-react";
import {IMAGE_URL} from "../../../Logic/constants";
import {sort, sortDefault} from "ith-sort";
import {processData} from "ah-lib";
import {compare, compareDefault} from "ith-compare";
import {BaseModel, Worker} from "../../../models";
import {workerAction__getList} from "../../../actions/workerActions";
import {trueNull} from "../../../Logic/extensions";
import {Case, Default, Switch} from "../../../Logic/Switch";
import {WorkerGridView, WorkerListHeader, WorkerListView} from "./lists";


const sortOptions = [
	{key: 'name-asc', order: 'ascending', title: 'Name aufsteigend'},
	{key: 'name-desc', order: 'descending', title: 'Name absteigend'}
];
const searchOptions = [
	{key: 'name', text: 'Name', value: 'name'}
];

const GRID_SIZE = 150;

class WorkersListView extends React.Component {
	state = {
		groups: 'all',
		state: 'active',
		order: sortOptions[0].key,
		searchText: '',
		searchType: searchOptions[0].key
	};
	
	groups = [];
	searchOptions = null;
	
	componentDidMount() {
		// const { onLoad } = this.props;
		// onLoad && onLoad();
		this.handleGroupProps(this.props.groups);
		if (null === this.searchOptions) {
			this.searchOptions = searchOptions.map(opt => processData(opt)(d => d.name = this.props.t(`filter.${d.key}`)));
		}
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.handleGroupProps(this.props.groups, prevProps.groups.length);
	}
	
	handleGroupProps = (groups, cmp = -1) => {
		if (cmp !== groups.length) {
			this.groups = groups.map(group => ({
				id: group,
				text: this.props.t(`rights.groups.${group}`)
			}));
		}
	};
	
	refresh = () => this.props.init && this.props.init();
	
	handleSelect = index => (e, key, value) => this.setState({[index]: value});
	set = index => value => this.setState({[index]: value});
	
	getHeader(workers) {
		const {
			onBack,
			viewType,
			updateViewType
		} = this.props;
		
		const {groups: selectedGroup, state: selectedState, order: sortOrder, searchText, searchType} = this.state;
		return (
			<WorkerListHeader
				workers={workers}
				onBack={onBack}
				viewType={viewType}
				updateViewType={updateViewType}
				selectedState={selectedState}
				selectedGroup={selectedGroup}
				handleSelect={this.handleSelect}
				sortOptions={sortOptions}
				sortOrder={sortOrder}
				searchText={searchText}
				searchType={searchType}
				groups={this.groups}
				searchOptions={this.searchOptions}
				setSearch={this.set}
			/>
		);
		
	}
	
	profileImage = (avatar, gender) => {
		if (avatar) {
			const {ident, origin_extension, updated_at} = avatar;
			return `${IMAGE_URL}${ident}.${origin_extension}?${updated_at}`;
		}
		return this.avatar(gender);
	};
	
	backgroundProfileImage = (avatar, gender) => {
		return `url("${this.profileImage(avatar, gender)}")`;
	};
	
	avatar = type => type === 'm' ? AvatarM : AvatarF;
	
	filteredState = () => {
		switch (this.state.state) {
			case 'inactive':
				return w => !w.active;
			default:
				return w => w.active;
		}
	};
	
	filteredGroups = () => {
		const state = this.state.groups;
		if (state === 'all') {
			return () => true;
		}
		return w => w.right_group_id === state;
	};
	
	filteredNames = () => {
		const {searchText, searchType} = this.state;
		if (searchType.trim() === '') {
			return compareDefault;
		}
		switch (searchType) {
			case 'name':
			default:
				return compare(searchText)(w => `${w.first_name} ${w.name}`);
		}
	};
	
	ordered = () => {
		const option = sortOptions.find(el => el.key === this.state.order);
		const _ = option.key.split('-');
		const order = _.pop();
		const key = _.join('-');
		
		switch (key) {
			case 'name':
				return sort(order)(w => w.name);
			default:
				return sortDefault;
		}
	};
	
	render() {
		const {
			headerPosition,
			onDetail,
			// filter,
			viewType,
			// size
		} = this.props;
		
		
		return (
			<BaseModel.ProviderList list={[workerAction__getList]}>{(worker_ids) => {
				return trueNull(worker_ids) && <Worker.CollectProvider dynamic allowEmpty ids={worker_ids}>{(workers) => {
					const visibleWorkers = workers.filter(this.filteredGroups()).filter(this.filteredState()).filter(this.filteredNames()).sort(this.ordered());
					return (
						<Segment basic>
							{['above', 'both'].indexOf(headerPosition) !== -1 && this.getHeader(workers)}
							<Switch value={viewType}>
								<Case is={'list'}>
									<WorkerListView onDetail={onDetail} workers={visibleWorkers}/>
								</Case>
								<Default>
									<WorkerGridView onDetail={onDetail} workers={visibleWorkers} gridSize={GRID_SIZE}/>
								</Default>
							</Switch>
							{['beneeth', 'both'].indexOf(headerPosition) !== -1 && this.getHeader(workers)}
						</Segment>
					);
				}}</Worker.CollectProvider>
			}
			}</BaseModel.ProviderList>
		);
	}
}

WorkersListView.propTypes = {
	workers: PropTypes.object,
	headerPosition: PropTypes.oneOf(['above', 'beneeth', 'both', 'none']),
	onDetail: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
	updateFilter: PropTypes.func.isRequired,
	init: PropTypes.func,
	onBack: PropTypes.func,
	mayCreate: PropTypes.bool,
	mayDelete: PropTypes.bool,
	filter: PropTypes.string,
	updateViewType: PropTypes.func,
	viewType: PropTypes.oneOf(["list", "grid"])
};

WorkersListView.defaultProps = {
	workers: {},
	headerPosition: 'above',
	groups: [],
	onBack: null,
	mayCreate: false,
	mayDelete: false,
	filter: 'all'
};


export default translate()(WorkersListView);