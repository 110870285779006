import React from 'react';
import PropTypes from 'prop-types';
import DialogController from "./DialogController";
import DialogButton from "./DialogButton";
import {translate} from "react-i18next";

let ConfirmDialog = ({message, t, onOk}) => (
	<DialogController className="dialog__confirm" cancelText="actions.no" beforeCancel={
		<DialogButton onClick={onOk} className="btn btn-primary pull-left">{t('actions.yes')}</DialogButton>
	}>
		{message}
	</DialogController>
);

ConfirmDialog.propTypes = {
	message: PropTypes.string.isRequired,
	onOk: PropTypes.func.isRequired
};

export default translate()(ConfirmDialog);