import * as React from 'react';
import {Segment} from "semantic-ui-react";
import {ConnectedConsultantCalendar} from "../intern/master-data/consultant-calendar/ConsultantCalendar";


export class ConsultantTest extends React.Component {
	render() {
		return (
			<Segment basic>
				<ConnectedConsultantCalendar/>
			</Segment>
		);
	}
}