import * as React from "react";
import PropTypes from "prop-types";
import {Segment} from "semantic-ui-react";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../../Tools/RightsProvider";
import {SECTION} from "../../../../Logic/constants";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import NoAccess from "../../../../components/NoAccess";
import {isOnline} from "../../../../actions/userActions";
import {serviceAction__findOfModel} from "../../../../actions/serviceActions";
import {Card, CardMedia, CardTitle, Divider, FlatButton, IconButton, List, ListItem, RaisedButton, TextField} from "material-ui";
import {Flex, FlexChild} from "../../../../components/partials/ActionHeader";
import {omit, values} from "lodash";
import {deepMemoize as memoize} from "../../../../Logic/extensions";
import cn from "classnames";
import {async} from "ith-fetch";
import {compare} from "ith-compare/source";
import {IconClose} from "../../../../Logic/icons";
import {logo_service, logo_service_default} from "../../../../Logic/helper";
import Ink from "react-ink";

const ListBody = ({serviceList, selectedService, selectService}) => (
	<List style={{marginBottom: 15}}>
		{serviceList.length ?
			serviceList.map(service => {
				const selected = service.service_id === selectedService;
				return (
					<ListItem
						className={cn("model-service-selector-controller-item", {selected})}
						key={service.service_id}
						primaryText={service.title}
						secondaryText={service.description}
						onClick={selectService(service.service_id)}/>
				);
				
			})
			:
			<ListItem disabled primaryText={'Kein Service gefunden...'}/>
		}
	</List>
);

const TILE_WIDTH = 150;
// const TILE_RESPONSIVE = 320;
// const TILE_MARGIN = 20;
const GridBody = ({serviceList, selectedService, selectService}) => (
	<div style={{height: '100%'}}>
		{/*<SizeMe>{({size: screen}) =>*/}
		{/*	<StackGrid duration={0} columnWidth={screen.width < TILE_RESPONSIVE ? '100%' : TILE_WIDTH} gutterHeight={TILE_MARGIN} gutterWidth={screen.width < TILE_RESPONSIVE ? 0 : TILE_MARGIN} monitorImagesLoaded>*/}
				{serviceList.map(service => {
						let bg = service.logo ? logo_service(service.logo) : logo_service_default();
						return <Card style={{display: 'inline-block', width: TILE_WIDTH, height: TILE_WIDTH, marginRight: 10, marginBottom:10, position: 'relative', overflow: 'hidden'}} key={`service-detail-${service.service_id}`} className={'pointer service-grid'} onClick={selectService(service.service_id)}>
							<CardMedia
								className={"service-card-media"}
								mediaStyle={{overflow: 'hidden', height: TILE_WIDTH, position: 'relative'}}
								style={{backgroundImage: `url('${bg}')`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeast"}}
								overlay={<CardTitle style={{marginBottom: 8}} className={"service-card-overlay"} title={service.title}/>}
							>
								{/*<img src={this.logo(service)} alt={service.title} />*/}
							</CardMedia>
							<Ink radius={400}/>
						</Card>;
					}
				)}
			{/*</StackGrid>*/}
		{/*}</SizeMe>*/}
	</div>
);

class ModelServiceSelectorController extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		model_id: PropTypes.number.isRequired,
		onFetch: PropTypes.func,
		onAbort: PropTypes.func,
		onSelect: PropTypes.func.isRequired,
		selectOnClick: PropTypes.bool,
		excludeServiceIds: PropTypes.array,
		focus: PropTypes.bool,
		asGrid: PropTypes.bool
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		selectOnClick: false,
		excludeServiceIds: [],
		focus: true,
		onFetch: () => alert("ModelServiceSelectorController::onFetch() is not implemented, yet!"),
		onSelect: () => alert("ModelServiceSelectorController::onSelect() is not implemented, yet!")
	};
	state = {
		services: {},
		loading: false,
		errorText: null,
		selectedService: 0,
		filter: ""
	};
	
	searchField = React.createRef();
	
	// componentWillMount() {
	//
	// }
	//
	componentDidMount() {
		this.onFetch();
		this.props.focus && async(250).then(() => this.searchField && this.searchField.current && this.searchField.current.focus());
	}
	
	selectService = idx => () => this.setState(state => ({
		...state,
		selectedService: state.selectedService === idx ? 0 : idx
	}), () => {
		this.props.selectOnClick && this.onSelect();
	});
	
	onSelect = () => {
		const {onSelect} = this.props;
		const {services, selectedService} = this.state;
		selectedService && onSelect(services[selectedService]);
	};
	
	omitElements = memoize(
		(map, list) => omit(map, list)
	);
	
	onFetch = async() => {
		try {
			this.setState({loading: true, errorText: null});
			const response = await this.props.onFetch();
			const filtered = this.props.excludeServiceIds.length ? this.omitElements(response.services, this.props.excludeServiceIds) : response.services;
			this.setState({services: filtered, loading: false});
		} catch (e) {
			this.setState({errorText: e.message, loading: false});
		}
	};
	
	filteredList = (services, filter = "") => {
		if (filter.trim() === "") return services;
		return services.filter(compare(filter, {fuzzy:true})(s => s.title));
	};
	
	serviceList = memoize(
		map => values(map)
	);
	
	updateFilter = (_, filter) => this.setState({filter});
	
	render() {
		const {
			rights,
			allRights,
			onAbort,
			selectOnClick,
			asGrid
		} = this.props;
		
		const mayRead = rights.mayRead || allRights[SECTION.ORDERS].mayRead;
		if (!mayRead){ return <NoAccess/>; }
		
		const {
			loading,
			errorText,
			services: serviceMap,
			selectedService,
			filter
		} = this.state;
		
		
		const serviceList = this.filteredList(this.serviceList(serviceMap), filter);
		
		return (
			<Segment basic loading={loading}>
				<h3 style={{marginBottom: 15}}>Service wählen</h3>
				{errorText && <h5 style={{color: 'red'}}>{errorText}</h5>}
				<Flex valign={"baseline"}>
					<FlexChild shrink={0.1} grow={100}>
						<TextField
							fullWidth
							ref={this.searchField}
							floatingLabelText={"Filter"}
							hintText={"Servicename..."}
							value={filter}
							onChange={this.updateFilter}
						/>
					</FlexChild>
					{filter.trim().length > 0 && <FlexChild shrink={0.1} grow={0.1}>
						<IconButton onClick={() => this.setState({filter: ""})}><IconClose/></IconButton>
					</FlexChild>}
				</Flex>
				{asGrid ? <GridBody serviceList={serviceList} selectService={this.selectService} selectedService={selectedService}/> : <ListBody selectedService={selectedService} selectService={this.selectService} serviceList={serviceList}/>}
				<Divider/>
				<Flex valign={"center"} align={"space-between"}>
					<span>{onAbort && <FlatButton onClick={onAbort}>Schließen</FlatButton>}</span>
					<span>{!selectOnClick && <RaisedButton primary onClick={this.onSelect} disabled={0 === selectedService}>Auswählen</RaisedButton>}</span>
				</Flex>
			</Segment>
		);
	}
}

ModelServiceSelectorController = withRights(SECTION.MASTER_DATA, ModelServiceSelectorController);
ModelServiceSelectorController = withInit(ModelServiceSelectorController);
ModelServiceSelectorController = connect(
	(state, props) => {
		return {};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				// props.test = 'haha';
			})),
			onFetch: serviceAction__findOfModel.bind(null, props.model_id || 0)
		};
	}
)(ModelServiceSelectorController);
ModelServiceSelectorController = translate()(ModelServiceSelectorController);

export default ModelServiceSelectorController;