import * as React from "react";
import {falseNull, trueNull} from "../Logic/extensions";
import PropTypes from "prop-types";
import uuid from "uuid";
import cn from "classnames";
import {Case, Default, Switch} from "../Logic/Switch";
import {IconCheckboxIndeterminate, IconCheckboxNegative, IconCheckboxPositive} from "../Logic/icons";
import {COLORS} from "../Logic/constants";
import {debounce} from "lodash";

const CheckboxLabel = ({children, label, labelRight, labelProps: {style: labelStyle, ...labelProps}, labelFlexProps, id, onContextMenu}) => {
	if (!label) return children;
	return <label onContextMenu={onContextMenu} {...labelProps} style={{
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
			userSelect: "none",
			marginLeft: labelRight ? 10 : 0,
			marginRight: labelRight ? 0 : 10,
			...labelStyle
		}} htmlFor={id}>{trueNull(labelRight) && children} {label} {falseNull(labelRight) && children}</label>;
};
CheckboxLabel.defaultProps = {
	labelProps: {}
};

export class ThreewayCheckbox extends React.Component {
	static propTypes = {
		onStateChange: PropTypes.func,
		value: PropTypes.oneOf([0, -1, 1]),
		disabled: PropTypes.bool,
		colored: PropTypes.bool,
		size: PropTypes.number,
		label: PropTypes.node,
		labelRight: PropTypes.bool,
		labelProps: PropTypes.object,
		labelContainerProps: PropTypes.object,
		id: PropTypes.string
	};
	
	static defaultProps = {
		colored: true,
		size: 24,
		labelProps: {},
		labelContainerProps: {}
	};
	
	static STATUS = {
		INDETERMINATE: 0,
		NEGATIVE: -1,
		POSITIVE: 1
	};
	
	order = [ThreewayCheckbox.STATUS.INDETERMINATE, ThreewayCheckbox.STATUS.POSITIVE, ThreewayCheckbox.STATUS.NEGATIVE];
	
	state = {
		value: ThreewayCheckbox.STATUS.INDETERMINATE
	};
	
	constructor(props) {
		super(props);
		if (this.order.includes(props.value)) {
			this.state.value = props.value;
		}
		this.handleClick = debounce(this.handleClick, 50);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {value} = this.props
		if (value !== this.state.value && value !== prevProps.value && this.order.includes(value)) {
			this.setState({value: this.props.value});
		}
	}
	
	stateClass = (value) => {
		switch (value) {
			case ThreewayCheckbox.STATUS.INDETERMINATE: return "indeterminate";
			case ThreewayCheckbox.STATUS.POSITIVE: return "positive";
			case ThreewayCheckbox.STATUS.NEGATIVE: return "negative";
			default: return null;
		}
	};
	
	change = (value, e) => this.setState({value}, () => this.props.onStateChange && this.props.onStateChange(value, e));
	
	handleClick = (e) => {
		const index = (this.order.indexOf(this.state.value) + 1) % 3;
		const value = this.order[index];
		this.change(value, e);
	};
	
	handleContext = e => {
		if (!e.isPropagationStopped()) {
			this.change(ThreewayCheckbox.STATUS.INDETERMINATE, e);
			e.preventDefault();
		}
	};
	
	render() {
		const {
			value: propValue,
			disabled,
			colored,
			size,
			onClick,
			onContextMenu,
			style: buttonStyle,
			className,
			id,
			label,
			labelRight,
			labelProps,
			labelContainerProps,
			onStateChange,
			...props
		} = this.props;
		const {value: stateValue} = this.state;
		
		const value = this.order.includes(propValue) ? propValue : stateValue;
		
		const sizeStyle = {
			margin: "auto"
		};
		
		if ( size ) {
			sizeStyle.width = sizeStyle.height = size;
		}
		
		
		const style = {
			cursor: disabled ? "default" : "pointer",
			position: "relative",
			display: "inline-flex",
			padding: 0,
			margin: 0,
			border: "none",
			background: "transparent",
			outline: "none"
		};
		
		if ( size ) {
			style.width = style.height = size;
		}
		
		const labelId = id || uuid.v4();
		
		return(
			<CheckboxLabel id={labelId} label={label} labelRight={labelRight} labelFlexProps={labelContainerProps} labelProps={labelProps} onContextMenu={this.handleContext}>
				<button
					{...props}
					onClick={this.handleClick}
					onContextMenu={this.handleContext}
					className={
						cn(className, "threeway-checkbox", this.stateClass(value), trueNull(size) && `size-${size}`, {
							disabled,
							colored
						})
					}
					id={labelId}
					style={{...buttonStyle, ...style}}
				>
					<Switch value={value}>
						<Case is={ThreewayCheckbox.STATUS.POSITIVE}>
							<IconCheckboxPositive color={trueNull(colored) && COLORS.SEMANTIC_GREEN} style={{...sizeStyle}}/>
						</Case>
						<Case is={ThreewayCheckbox.STATUS.NEGATIVE}>
							<IconCheckboxIndeterminate color={trueNull(colored) && COLORS.SEMANTIC_RED} style={{...sizeStyle}}/>
						</Case>
						<Default>
							<IconCheckboxNegative color={trueNull(colored) && COLORS.SECONDARY} style={{...sizeStyle}}/>
						</Default>
					</Switch>
				</button>
			</CheckboxLabel>
		);
	}
}