import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import Countdown, {renderBeautiful, renderSimple} from "../partials/Countdown";
import {moment} from "../../Logic/Moment";


export class CountdownTest extends React.PureComponent {
	date = moment().add(-1, "day").add(-1, "hour").add(20, "seconds").toDate();
	future = moment().add(1, "day").add(1, "hour").add(20, "seconds").toDate();
	render() {
		return(
			<Segment basic>
				<ActionHeader alignment={"flex-start"}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Countdown</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<h3>Default</h3>
				<div><Countdown targetDate={this.date}/></div>
				<div><Countdown targetDate={this.future}/></div>
				<h3>Beautiful render</h3>
				<div><Countdown targetDate={this.date} render={renderBeautiful}/></div>
				<div><Countdown targetDate={this.future} render={renderBeautiful}/></div>
				<h3>Beautiful render (without days)</h3>
				<div><Countdown targetDate={this.date} render={renderBeautiful} skipDays/></div>
				<div><Countdown targetDate={this.future} render={renderBeautiful} skipDays/></div>
				<h3>Simple render</h3>
				<div><Countdown targetDate={this.date} render={renderSimple}/></div>
				<div><Countdown targetDate={this.future} render={renderSimple}/></div>
				<h3>Simple render (without days)</h3>
				<div><Countdown targetDate={this.date} render={renderSimple} skipDays/></div>
				<div><Countdown targetDate={this.future} render={renderSimple} skipDays/></div>
			</Segment>
		);
	}
}