import {RIGHT_ACTION} from "./types";
import {addLoader, removeLoader} from "./loaderActions";
import {addError} from "./errorActions";
// import {GET} from "ith-fetch";
import {GET} from "../Logic/fetch";
import {isCached} from "ith-redux-helpers";
import {CACHE_RIGHT_AREAS, CACHE_RIGHT_GROUPS, CACHE_RIGHT_RELATIONS, CACHE_TIME_RIGHT_AREAS, CACHE_TIME_RIGHT_GROUPS, CACHE_TIME_RIGHT_RELATIONS} from "./index";

const areasLoaded = areas => ({type: RIGHT_ACTION.LOAD_AREAS, areas});
const groupsLoaded = groups => ({type: RIGHT_ACTION.LOAD_GROUPS, groups});
const bothLoaded = (areas, groups) => ({type: RIGHT_ACTION.LOAD_AREAS_GROUPS, areas, groups});

export const resetAreasAndGroups = () => ({type:RIGHT_ACTION.RESET});

export const loadAreas = () =>
	async (dispatch, getState) => {
		if ( isCached(getState.rights, CACHE_RIGHT_AREAS)(CACHE_TIME_RIGHT_AREAS)) {
			return;
		}
		try {
			dispatch(addLoader());
			const areas = await GET('/rights/areas');
			dispatch(areasLoaded(areas));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader());
		}
	};

export const loadGroups = (force = false) =>
	async (dispatch, getState) => {
		if ( !force &&
			isCached(getState().rights, CACHE_RIGHT_GROUPS)(CACHE_TIME_RIGHT_GROUPS) &&
			isCached(getState().rights, CACHE_RIGHT_RELATIONS)(CACHE_TIME_RIGHT_RELATIONS) ) {
			return;
		}
		try {
			dispatch(addLoader());
			const groups = await GET('/rights/groups');
			dispatch(groupsLoaded(groups));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader());
		}
	};

export const loadAreasAndGroups = (force = false) =>
	async (dispatch, getState) => {
		if ( !force &&
			isCached(getState().rights, CACHE_RIGHT_GROUPS)(CACHE_TIME_RIGHT_GROUPS) &&
			isCached(getState().rights, CACHE_RIGHT_RELATIONS)(CACHE_TIME_RIGHT_RELATIONS) &&
			isCached(getState().rights, CACHE_RIGHT_AREAS)(CACHE_TIME_RIGHT_AREAS) ) {
			return;
		}
		try {
			dispatch(addLoader());
			const areas = await GET('/rights/areas');
			const groups = await GET('/rights/groups');
			dispatch(bothLoaded(areas, groups));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader());
		}
	};