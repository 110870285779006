import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import withInit from "../../../../Logic/withInit";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../../Tools/RightsProvider";
import {SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../NoAccess";
import {Button, Message, Segment, Table} from "semantic-ui-react";
import {EasyFlex, Flex, FlexChild} from "../../../partials/ActionHeader";
import {deepMemoize as memoize, SelectField} from "../../../../Logic/extensions";
import {Checkbox, Divider, MenuItem, TextField} from "material-ui";
import {isFunction, isUndefined, pick, values} from "lodash";
import moment from "../../../../Logic/Moment";
import {freedaysAction__createWorkers} from "../../../../actions/freedaysActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_FREEDAYS_WORKERS} from "../../../../actions";
import ConfirmDialog from "../../../../Logic/TriggerDialog";
import {workerCall__getWorkload} from "../../../../actions/workerActions";
import round from 'round-precision';
import {MaterialCompoundDateInput} from "../../../../Tools/DatePicker";
import {pipeDateFunc} from "../../orders/finder/Helpers";


const WorkloadView = ({data}) => {

	const {schedule_time, work_time, order_count, workload} = data;
	
	const scheduleTime = round(schedule_time / 3600, 1);
	const workTime = round(work_time / 3600, 1);
	
	
	return <EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
		<Table basic celled>
			<Table.Body>
				<Table.Row>
					<Table.Cell>Arbeitszeit</Table.Cell>
					<Table.Cell>{scheduleTime} Std.</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Auftragszeit</Table.Cell>
					<Table.Cell>{workTime} Std.</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>beeinflußte Aufträge</Table.Cell>
					<Table.Cell>{order_count}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Auslastung</Table.Cell>
					<Table.Cell>{workload} %</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	</EasyFlex>;
}

class WorkersFreedayCreateView extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		workersMap: PropTypes.object,
		type: PropTypes.oneOf([null, 'sick', 'holiday']),
		workers_id: PropTypes.number,
		onCreate: PropTypes.func,
		onAfterCreate: PropTypes.func,
		loading: PropTypes.bool,
		onClose: PropTypes.func
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		workersMap: {},
		loading: false
	};
	state = {
		type: null,
		workers_id: 0,
		start: null,
		end: null,
		start_date: null,
		start_time: "",
		end_date: null,
		end_time: "",
		description: "",
		allday: false,
		confirm: false,
		force: false,
		force_message: '',
		info: null
	};
	
	componentDidMount() {
		// this.state.workers_id = this.props.workers_id || 0;
		// this.state.type = this.props.type || null;
		this.setState({workers_id:  this.props.workers_id || 0, type: this.props.type || null});
	}
	
	select = index => (e, idx, value) => this.setState({[index]: value, force: false, force_message: ''}, this.getWorkload);
	selectPure = index => (e, idx, value) => this.setState({[index]: value});
	set = index => (e, value) => this.setState({[index]: !isUndefined(value) ? value : e.target.value, force: false, force_message: ''}, this.getWorkload);
	setPure = (index) => (e, value) => this.setState({[index]: !isUndefined(value) ? value : e.target.value});
	update = index => value => this.setState({[index]: value});
	
	onCreate = async() => {
		const {onCreate} = this.props;
		// const data = pick(this.state, ['type', 'workers_id', 'start_pont', 'end_point', 'description', 'allday', 'force']);
		const data = pick(this.state, ['type', 'workers_id', 'start', 'end', 'description', 'allday', 'force']);
		const result = await onCreate(data);
		if (!result.ack) {
			if (result.count) {
				this.setForceMessage(`Durch die Änderungen ergeben sich ${result.count} Konflikte.`)();
			} else {
				this.setForceMessage('Durch die Änderungen haben sich Konflikte ergeben')();
			}
		} else {
			this.setForceMessage()();
			this.props.onAfterCreate(result);
		}
	};
	
	getWorkload = async() => {
		const {start_date, end_date, start_time, end_time, allday, workers_id} = this.state;
		if (start_date && end_date && workers_id && (allday || (start_time && end_time))) {
			let start = moment(start_date);
			let end = moment(end_date);
			if (allday) {
				start.startOf('day');
				end.endOf('day');
			} else {
				const test = /\d{2}:\d{2}/;
				if (!start_time.match(test) || !end_time.match(test)) {
					this.setState({info: null});
					return;
				}
				const stime = moment(start_time, 'HH:mm');
				const etime = moment(end_time, 'HH:mm');
				if (!stime.isValid() || !etime.isValid()) {
					return;
				}
				start.set({hour: stime.get('hour'), minute: stime.get('minute'), second: 0});
				end.set({hour: etime.get('hour'), minute: etime.get('minute'), second: 0});
			}
			start = start.toDate().toISOString();
			end = end.toDate().toISOString();
			try {
				const result = await workerCall__getWorkload({workers_id, start, end});
				// noinspection JSUnresolvedVariable
				const divider = result.schedule_time || 1;
				// noinspection JSUnresolvedVariable
				result.workload = round((result.work_time / divider) * 100, 2);
				this.setState({info: result});
			} catch (e) {
				console.error(e);
				this.setState({info: null});
			}
		} else {
			this.setState({info: null});
		}
	};
	
	getWorkerList = memoize(map => values(map).filter(w => w.active && !w.deleted));
	toggleConfirm = (confirm = null) => () => this.setState(state => ({
		confirm: confirm === null ? !state.confirm : confirm
	}));
	toggleForce = (force = null) => () => this.setState(state => ({
		force: null === force ? !state.force : force
	}));
	setForceMessage = (force_message = '') => () => this.setState({force_message});
	
	get invalid() {
		const {start, end,  workers_id} = this.state;
		if (!workers_id || !start || !end) return true;
		return start > end;
	}
	
	get invalidForced() {
		const {force_message, force} = this.state;
		return Boolean(this.invalid || (force_message && !force));
	}
	
	render() {
		const {
			rights: {mayCreate},
			workersMap,
			loading
		} = this.props;
		
		if ( !mayCreate ) {
			return <NoAccess/>;
		}
		
		const {
			type: freedayType,
			workers_id,
			// start_date, start_time,
			// end_date, end_time,
			description,
			allday,
			// info
		} = this.state;
		
		const workerList = this.getWorkerList(workersMap);
		
		return (
			<Segment basic loading={loading}>
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={15} gutterWidth={25} valign={"center"} >
					<FlexChild>
						<SelectField
							floatingLabelText={"Mitarbeiter"}
							value={workers_id}
							onChange={this.select("workers_id")}
						>
							<MenuItem disabled primaryText={'Bitte wählen'} value={0}/>
							<Divider/>
							{workerList.map(({first_name, name, workers_id}) =>
								<MenuItem key={`create-freeday-${workers_id}`} value={workers_id}
								          primaryText={`${first_name.substr(0, 1).toUpperCase()}. ${name}`}/>
							)}
						</SelectField>
					</FlexChild>
					<FlexChild>
						<SelectField
							value={freedayType}
							onChange={this.selectPure("type")}
							floatingLabelText={"Art der Fehlzeit"}
						>
							<MenuItem primaryText={'Bitte auswählen'} disabled/>
							<Divider/>
							<MenuItem primaryText={'Urlaub'} value={'holiday'}/>
							<MenuItem primaryText={'Krankheit'} value={'sick'}/>
							<MenuItem primaryText={'Meistervertretung'} value={'master_replacement'}/>
							<MenuItem primaryText={'Intern/Schulung'} value={'intern'}/>
						</SelectField>
					</FlexChild>
					<FlexChild>
						<TextField
							floatingLabelText={'Beschreibung'}
							hintText={'Kind ist krank'}
							value={description}
							onChange={this.setPure("description")}
						/>
					</FlexChild>
					{/*<div style={{width: '100%'}}/>*/}
					{/*<FlexChild>*/}
					{/*	<DatePicker*/}
					{/*		{...DATE_PICKER()}*/}
					{/*		floatingLabelText={"Von"}*/}
					{/*		hintText={"Startzeit"}*/}
					{/*		minDate={new Date()}*/}
					{/*		value={start_date}*/}
					{/*		onChange={this.set("start_date")}*/}
					{/*	/>*/}
					{/*</FlexChild>*/}
					{/*<FlexChild>*/}
					{/*	<InputMask*/}
					{/*		{...TIME_MASK_PROPS}*/}
					{/*		value={start_time}*/}
					{/*		onChange={this.set('start_time')}*/}
					{/*		disabled={false}*/}
					{/*	>*/}
					{/*		{() =>*/}
					{/*			<TextField*/}
					{/*				floatingLabelText={'Uhrzeit [hh:mm]'}*/}
					{/*			/>*/}
					{/*		}*/}
					{/*	</InputMask>*/}
					{/*</FlexChild>*/}
					{/*<div style={{width: '100%'}}/>*/}
					{/*<FlexChild>*/}
					{/*	<DatePicker*/}
					{/*		{...DATE_PICKER()}*/}
					{/*		floatingLabelText={"Bis"}*/}
					{/*		hintText={"Endzeit"}*/}
					{/*		minDate={moment.max([moment(), moment(start_date)]).toDate()}*/}
					{/*		value={end_date}*/}
					{/*		onChange={this.set("end_date")}*/}
					{/*	/>*/}
					{/*</FlexChild>*/}
					{/*<FlexChild>*/}
					{/*	<InputMask*/}
					{/*		{...TIME_MASK_PROPS}*/}
					{/*		value={end_time}*/}
					{/*		onChange={this.set('end_time')}*/}
					{/*		disabled={false}*/}
					{/*	>*/}
					{/*		{() =>*/}
					{/*			<TextField*/}
					{/*				floatingLabelText={'Uhrzeit [hh:mm]'}*/}
					{/*			/>*/}
					{/*		}*/}
					{/*	</InputMask>*/}
					{/*</FlexChild>*/}
				</Flex>
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={25} gutterWidth={25} valign={"center"}>
					<FlexChild>
						<MaterialCompoundDateInput
							onChange={pipeDateFunc(this.update('start'))}
							date={this.state.start}
							dateLabel={'Von'}
							timeLabel={<span>&nbsp;</span>}
							timePlaceholder={'__:__'}
							selectsStart
							startDate={this.state.start}
							endDate={this.state.end}
						/>
					</FlexChild>
					<FlexChild>
						<MaterialCompoundDateInput
							onChange={pipeDateFunc(this.update('end'))}
							date={this.state.end}
							dateLabel={'Bis'}
							timeLabel={<span>&nbsp;</span>}
							timePlaceholder={'__:__'}
							selectsEnd
							startDate={this.state.start}
							endDate={this.state.end}
							endOfDay
						/>
					</FlexChild>
				</Flex>
				{this.state.info && <WorkloadView data={this.state.info}/>}
				{this.state.force_message && <Message warning>
					<p>{this.state.force_message}</p>
				</Message>}
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={25} gutterWidth={25} valign={"center"} align={"flex-end"}>
					{this.state.force_message && <FlexChild>
						<Checkbox labelPosition={"left"} labelStyle={{marginRight: 5, whiteSpace: 'nowrap'}} style={{alignItems: 'center'}} label={'Änderungen erzwingen'} required checked={this.state.force} onCheck={this.setPure('force')}/>
					</FlexChild>}
					<FlexChild>
						<Checkbox labelPosition={"left"} labelStyle={{marginRight: 5}} label={"Ganztags"} checked={allday} onCheck={this.set('allday')}/>
					</FlexChild>
					<FlexChild>
						<ConfirmDialog
							trigger={<Button disabled={this.invalidForced} primary onClick={this.toggleConfirm(true)}>Anlegen</Button>}
							onConfirm={this.onCreate}
							buttonCancelText={'Abbrechen'}
							buttonConfirmText={'Ausführen'}
							text={'Durch das Ausführen werden unter Umständen Termine verschoben und verändert. Sind Sie sicher, dass Sie diese Aktion ausführen wollen?'}
						/>
						
					</FlexChild>
				</Flex>
			</Segment>
		);
	}
}

WorkersFreedayCreateView = withRights(SECTION.WORKERS, WorkersFreedayCreateView);
WorkersFreedayCreateView = withInit(WorkersFreedayCreateView);
WorkersFreedayCreateView = connect(
	(state, props) => {
		// noinspection JSUnresolvedVariable
		return {
			workersMap: props.workersMaps || state.workers.list,
			loading: isLoading(state, PROC_FREEDAYS_WORKERS)
		};
	},
	(dispatch) => {
		return {
			onCreate: (data, onSuccess) => dispatch(freedaysAction__createWorkers(data, result => {
				isFunction(onSuccess) && onSuccess(result);
				result && result.ack && dispatch(addSnackbar('Daten gespeichert'));
			}))
		};
	}
)(WorkersFreedayCreateView);

WorkersFreedayCreateView = translate()(WorkersFreedayCreateView);

export default WorkersFreedayCreateView;