import * as React from 'react';
import PropTypes from 'prop-types';
import {difference, get, pick, values} from "lodash";
import {DatePicker, Divider, FlatButton, IconButton, Menu, MenuItem, Popover, RaisedButton} from "material-ui";
import {moment} from "../../../Logic/Moment";
import constants, {DATE_PICKER, IMAGE_URL} from "../../../Logic/constants";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading, Flex, FlexChild} from "../../partials/ActionHeader";
import {withRouter} from "react-router-dom";
import {IconChevronLeft, IconDateRange, IconFilterList, IconRefresh, IconSettingsBackRestore} from "../../../Logic/icons";
import {MiniMenu} from "../../../Logic/MiniMenu";
import {Button, Icon, Label, Modal, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_APPOINTMENTS} from "../../../actions";
import {appointmentAction__load, appointmentStore__get, appointmentStore__set} from "../../../actions/appointmentActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {getTimeSpan as getSpan, Loadable} from "../../../Logic/extensions";
import cn from "classnames";
import ServiceImage from '../../../images/service2.png';
import AppointmentPaper from "../../../cointainer/intern/appointments/AppointmentPaper";
import {Trans} from "react-i18next";


// const DATE_FORMAT = "DD.MM.YY";
// const TIME_FORMAT = "HH:mm";
// const TIME_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;


class DateRangeView extends React.Component {
	static propTypes = {
		from: PropTypes.instanceOf(Date),
		to: PropTypes.instanceOf(Date),
		onFetch: PropTypes.func.isRequired,
		loading: PropTypes.bool
	};
	static defaultProps = {
		onFetch: () => alert('DateRangeView::onFetch() is not implemented, yet!'),
		loading: false
	};
	state = {
		from: null,
		to: null
	};
	
	componentDidMount() {
		const {from, to} = this.props;
		if (from instanceof Date) {
			this.setState({from});
		}
		if ( to instanceof Date) {
			this.setState({to});
		}
	}
	
	changeDate = (index) => (e, value) => this.setState({[index]: value});
	
	setDays = (count) => () => {
		let from = moment();
		if ( count < 0 ) {
			from = from.subtract(1, 'day');
		}
		let to = from.clone();
		to = to.add(count, 'day').toDate();
		from = from.toDate();
		if (count <  0 ) {
			this.setState({from: to, to: from});
		} else {
			this.setState({from, to});
		}
	};
	
	render() {
		const {onFetch, loading} = this.props;
		const {from, to} = this.state;
		
		return (
			<Segment basic>
				<Flex valign={"center"}>
					<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Schnellwahl:</strong>
					<Flex gutterHeight={20} gutterWidth={20} wrap>
						<FlexChild><FlatButton primary onClick={this.setDays(0)}>Heute</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(1)}>2 Tage</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(2)}>3 Tage</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(6)}>1 Woche</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(-6)}>letzte Woche</FlatButton></FlexChild>
						
					</Flex>
				</Flex>
				<Flex>
					<FlexChild>
						<Flex valign={'center'}>
							<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Von:</strong>
							<DatePicker
								{...DATE_PICKER()}
								hintText={"Datum"}
								floatingLabelText={'Datum'}
								maxDate={to}
								value={from}
								onChange={this.changeDate('from')}
							/>
						</Flex>
						<Flex valign={'center'}>
							<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Bis:</strong>
							<DatePicker
								{...DATE_PICKER()}
								hintText={"Datum"}
								floatingLabelText={'Datum'}
								minDate={from}
								value={to}
								onChange={this.changeDate('to')}
							/>
						</Flex>
					</FlexChild>
					<Flex align={'center'} grow={1} valign={"center"}>
						<Loadable hoc={RaisedButton} inverse loading={loading} disabledOnLoading onClick={onFetch.bind(null, from.toISOString(), to.toISOString())} primary disabled={null === from && null === to}>Aktualisieren</Loadable>
					</Flex>
				</Flex>
			</Segment>
		);
	}
}
DateRangeView = connect(
	(state, props) => ({
		loading: isLoading(state, PROC_APPOINTMENTS),
		from: props.from || new Date(state.appointments.from),
		to: props.to || new Date(state.appointments.to),
	}),
	dispatch => ({
		onFetch: (from, to) => dispatch(appointmentAction__load({from, to}, res => dispatch(addSnackbar('Aufträge aktualisiert'))))
	})
)(DateRangeView);


const RangeTitle = ({from, to, loading}) => {
	if (!loading && Boolean(from && to)) {
		from = moment(from);
		to = moment(to);
		const now = moment();
		const diff = to.diff(from, 'day');
		if ( now.isSame(from, 'day')) {
			if ( from.isSame(to, 'day')) {
				return <strong>Heute</strong>;
			}
			from = 'Heute';
		} else {
			from = from.format(constants.DATE_FORMAT);
		}
		if ( diff === 1 ) {
			to  = 'Morgen';
		} else if (diff === 2) {
			to = 'Übermorgen';
		} else {
			to = to.format(constants.DATE_FORMAT);
		}
		return <span>von <strong>{from}</strong> bis <strong>{to}</strong></span>;
	}
	return  <Icon name={'spinner'} loading/>;
};
RangeTitle.propTypes = {
	from: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]),
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]),
	loading: PropTypes.bool
};
RangeTitle.defaultProps = {
	loading: false
};

const MenuFilterPoint = ({elem, inverted, noText, children, type, className, spanStyle, strongStyle, smallStyle}) => (
	<Flex align={"space-between"} className={className}>
		<span style={{position: "relative", ...spanStyle}}>
			{inverted && <small style={{display: "block", position: "absolute", top: -15, fontSize: '65%', color: "orange", ...smallStyle}}>{noText}</small>}
			{children}
		</span>
		<strong style={{display: "block", textAlign: 'right', flexShrink: 0, minWidth: 28, paddingLeft: 4, paddingRight: 4, ...strongStyle}}>{inverted ? elem.filterCountInverted[type] : elem.filterCount[type]}</strong>
	</Flex>
);


const STATE_FILTERS = [
	"delivered",
	"released",
	"progress",
	"finished",
	"fetched"
];

class Appointments extends React.Component {
	static propTypes = {
		appointments: PropTypes.shape({
			from: PropTypes.string,
			to: PropTypes.string,
			orders: PropTypes.object.isRequired,
			services: PropTypes.object.isRequired,
			resources: PropTypes.object.isRequired,
			vehicles: PropTypes.object.isRequired
		}),
		onFetch: PropTypes.func.isRequired,
		work: PropTypes.array
	};
	static defaultProps = {
		onFetch: () => alert('Appointments::onFetch() is not implemented, yet!')
	};
	state = {
		viewDateRange: false,
		popover: null,
		popid: -1,
		showFilters: null,
		activeFilters: [],
		activeFiltersIn: [],
		activeFiltersOut: [],
		tmpFilters: [],
		tmpFiltersIn: [],
		tmpFiltersOut: [],
		activeInverted: false,
		tmpInverted: false
	};
	filterMap = {};
	filterMapIn = {};
	filterMapOut = {};
	filterCount = {};
	filterCountInverted = {};
	
	componentDidMount() {
		// let data = appointmentStore__get();
		this.setState(appointmentStore__get());
	}
	
	beforeFilter = () => {
		// using intermediate filter map, for faster access in the
		// filtered() function.
		const {activeFilters, activeFiltersIn, activeFiltersOut} = this.state;
		for (const filter of STATE_FILTERS) {
			this.filterMap[filter] = activeFilters.indexOf(filter) !== -1;
			this.filterMapIn[filter] = activeFiltersIn.indexOf(filter) !== -1;
			this.filterMapOut[filter] = activeFiltersOut.indexOf(filter) !== -1;
			this.filterCount[filter] = 0;
			this.filterCountInverted[filter] = 0;
		}
	};
	
	
	filtered = (order) => {
		this.filterCount.delivered += order.delivered_at !== null;
		this.filterCountInverted.delivered += order.delivered_at === null;
		this.filterCount.released += order.released_at !== null;
		this.filterCountInverted.released += order.released_at === null;
		this.filterCount.progress += order.progress > 0;
		this.filterCountInverted.progress += order.progress === 0;
		this.filterCount.finished += order.finished_at !== null;
		this.filterCountInverted.finished += order.finished_at === null;
		this.filterCount.fetched += order.fetched_at !== null;
		this.filterCountInverted.fetched += order.fetched_at === null;
		
		if ( !this.state.activeFiltersIn.length && !this.state.activeFiltersOut.length ) {
			return true;
		}
		const {delivered: deliveredIn, released: releasedIn, progress: progressIn, finished: finishedIn, fetched: fetchedIn} = this.filterMapIn;
		
		const include = ((!deliveredIn || deliveredIn) && order.delivered_at !== null)
			&& ((!releasedIn || releasedIn) && order.released_at !== null)
			&& ((!progressIn || progressIn) && order.progress !== 0)
			&& ((!finishedIn || finishedIn) && order.finished_at !== null)
			&& ((!fetchedIn || fetchedIn) && order.fetched_at !== null);
		if ( false === include ) {
			return false;
		}
		const {delivered: deliveredOut,  released: releasedOut, progress: progressOut,  finished: finishedOut, fetched: fetchedOut} = this.filterMapOut;
		return ((!deliveredOut || deliveredOut) && order.delivered_at === null)
			&& ((!releasedOut || releasedOut) && order.released_at === null)
			&& ((!progressOut || progressOut) && order.progress === 0)
			&& ((!finishedOut || finishedOut) && order.finished_at === null)
			&& ((!fetchedOut || fetchedOut) && order.fetched_at === null);
	};
	
	sorted = (a, b) => {
		return a.latest_point - b.latest_point;
	};
	
	getResource = resource_id => {
		const {user, resources} = this.props;
		try {
			return resources.find(r => r.resource_id === resource_id && r.house_id === user.house_id).name;
		} catch (e) {
			return 'N.V.';
		}
	};
	
	logo = ({logo}) => {
		if( !logo ) {
			return ServiceImage;
		}
		return `${IMAGE_URL}${logo.ident}.${logo.origin_extension}?${logo.updated_at}`;
	};
	
	changeFilter = (e, value) => this.setState({tmpFilters: value});
	
	changeFilterIn = (e, value) => this.setState(state => {
		const diff = difference(value, state.tmpFiltersIn);
		if (diff.length && state.tmpFiltersIn.length < value.length) {
			const filter = diff[0];
			const index = state.tmpFiltersOut.indexOf(filter);
			let other = state.tmpFiltersOut;
			if ( index !== -1 ) {
				other.splice(index, 1);
			}
			return {
				tmpFiltersIn: value,
				tmpFiltersOut: other
			};
		}
		return {tmpFiltersIn: value};
	});
	
	changeFilterOut = (e, value) => this.setState(state => {
		const diff = difference(value, state.tmpFiltersOut);
		if (diff.length && state.tmpFiltersOut.length < value.length) {
			const filter = diff[0];
			const index = state.tmpFiltersIn.indexOf(filter);
			let other = state.tmpFiltersIn;
			if ( index !== -1 ) {
				other.splice(index, 1);
			}
			return {
				tmpFiltersOut: value,
				tmpFiltersIn: other
			};
		}
		return {tmpFiltersOut: value};
	});
	
	setFilters = () => this.setState(state => ({activeFiltersIn: state.tmpFiltersIn, activeFiltersOut: state.tmpFiltersOut}), () => {
		appointmentStore__set(pick(this.state, ['activeFiltersIn', 'activeFiltersOut']));
	});
	
	showFilters = (e) => this.setState({showFilters: e ? e.currentTarget : null, tmpFiltersIn: this.state.activeFiltersIn, tmpFiltersOut: this.state.activeFiltersOut});
	
	resetFilters = () => this.setState({tmpFiltersIn: [], tmpFiltersOut: []});
	
	showRangePicker = (viewDateRange) => () => this.setState({viewDateRange});
	
	fastcall = (days) => () => {
		const [from, to] = getSpan(days, true);
		this.props.onFetch(from, to);
	};
	
	refresh = () => {
		const {appointments: {from, to}, onFetch} = this.props;
		if (Boolean(from && to)) {
			const starts = moment(from).toISOString();
			const ends = moment(to).toISOString();
			onFetch(starts, ends);
		}
	};
	
	render() {
		const {
			appointments: {
				from,
				to,
				orders,
				// services,
				// resources,
				vehicles
			},
			history: {push},
			// onFetch,
			// serviceMap,
			loading
		} = this.props;
		const {
			viewDateRange,
			activeFiltersIn,
			activeFiltersOut,
			// tmpFilters,
			tmpFiltersIn,
			tmpFiltersOut,
			// tmpInverted: inverted
		} = this.state;
		// let lastSection = null;
		// const mFrom = from ? moment(from).format(constants.DATE_FORMAT) : null;
		// const mTo = to ? moment(to).format(constants.DATE_FORMAT) : null;
		let orderList = values(orders).slice(0, 5);
		const orderOrginalCount = orderList.length;
		this.beforeFilter();
		orderList = orderList.filter(this.filtered);
		orderList = orderList.sort(this.sorted);
		const totalFilters = activeFiltersIn.length + activeFiltersOut.length;
		return (
			<div id="view__appointments" className="view__main" style={{textAlign: 'left'}}>
				<ActionHeader alignment="space-between">
					<ActionHeaderGroup>
						<ActionBackButton onBack={() => push('/')}/>
						<ActionHeading>Auftragspool ( {orderList.length} / {orderOrginalCount} )</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{/*von {mFrom} bis {mTo}*/}
						<RangeTitle from={from} to={to} loading={loading}/>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<IconButton onClick={this.refresh}>
							<IconRefresh/>
						</IconButton>
						<IconButton className={cn('labelled', {active: totalFilters > 0})} onClick={this.showFilters}>
							<IconFilterList/>
							<Label floating color={'orange'} circular size="mini">{totalFilters}</Label>
						</IconButton>
						<Popover
							anchorEl={this.state.showFilters}
							onRequestClose={this.showFilters.bind(null, null)}
							open={this.state.showFilters !== null}
							anchorOrigin={{vertical: "top", horizontal: "right"}}
							targetOrigin={{vertical: "top", horizontal: "right"}}
						>
							<Segment basic>
								<Flex>
									<Menu
										autoWidth
										multiple
										value={tmpFiltersIn}
										onChange={this.changeFilterIn}
										className={'quickfilter'}
									>
										{/*<MenuItem
											insetChildren
											checked={inverted}
											primaryText={'Umgekehrt'}
											onClick={this.onInvert}
										/>
										<Divider/>*/}
										<MenuItem
											insetChildren
											value={'released'}
											checked={tmpFiltersIn.indexOf('released') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'released'}>Freigegeben</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'delivered'}
											checked={tmpFiltersIn.indexOf('delivered') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'delivered'}>Auf dem Hof</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'progress'}
											checked={tmpFiltersIn.indexOf('progress') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'progress'}>In Bearbeitung</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'finished'}
											checked={tmpFiltersIn.indexOf('finished') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'finished'}>Abgeschlossen</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'fetched'}
											checked={tmpFiltersIn.indexOf('fetched') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'fetched'}>Abgeholt</MenuFilterPoint>}
										/>
										<Divider/>
										
									</Menu>
									<Menu
										autoWidth
										multiple
										value={tmpFiltersOut}
										onChange={this.changeFilterOut}
										style={{borderLeft: '1px solid #ccc'}}
									>
										{/*<MenuItem
											insetChildren
											checked={inverted}
											primaryText={'Umgekehrt'}
											onClick={this.onInvert}
										/>
										<Divider/>*/}
										<MenuItem
											insetChildren
											value={'released'}
											checked={tmpFiltersOut.indexOf('released') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'released'} inverted>Freigegeben</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'delivered'}
											checked={tmpFiltersOut.indexOf('delivered') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'delivered'} inverted>Auf dem Hof</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'progress'}
											checked={tmpFiltersOut.indexOf('progress') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'progress'} inverted>In Bearbeitung</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'finished'}
											checked={tmpFiltersOut.indexOf('finished') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'finished'} inverted>Abgeschlossen</MenuFilterPoint>}
										/>
										<MenuItem
											insetChildren
											value={'fetched'}
											checked={tmpFiltersOut.indexOf('fetched') !== -1}
											primaryText={<MenuFilterPoint elem={this} type={'fetched'} inverted>Abgeholt</MenuFilterPoint>}
										/>
										<Divider/>
										
									</Menu>
								</Flex>
								<Flex align={'center'} valign={'center'}>
									<FlatButton primary style={{width: '100%'}} onClick={this.setFilters}>Übernehmen</FlatButton>
									<IconButton onClick={this.resetFilters}><IconSettingsBackRestore/></IconButton>
								</Flex>
							</Segment>
						</Popover>
						<MiniMenu>
							<MenuItem
								primaryText={'Zeitspanne'}
								leftIcon={<IconDateRange/>}
								onClick={this.showRangePicker(true)}
							/>
							<MenuItem
								primaryText={"Schnellwahl"}
								leftIcon={<IconChevronLeft/>}
								insetChildren
								menuItems={[
									<MenuItem
										primaryText={'Heute'}
										onClick={this.fastcall(0)}
									/>,
									<MenuItem
										primaryText={'2 Tage'}
										onClick={this.fastcall(1)}
									/>,
									<MenuItem
										primaryText={'3 Tage'}
										onClick={this.fastcall(2)}
									/>,
									<MenuItem
										primaryText={'1 Woche'}
										onClick={this.fastcall(6)}
									/>,
									<MenuItem
										primaryText={'Letzte Woche'}
										onClick={this.fastcall(-6)}
									/>
								]}
							/>
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				{orderList.map(order => {
					const vehicle = vehicles[order.order_vehicle_id];
					const client_id = get(vehicle, 'client_id', 0);
					const vehicle_id = get(vehicle, 'vehicle_id', 0);
					return <AppointmentPaper key={order.order_id} order={order} client_id={client_id} vehicle_id={vehicle_id}/>;
				})}
			{/*	{viewDateRange &&
					<Dialog open autoScrollBodyContent modal actions={[
						<FlatButton onClick={this.showRangePicker(false)}>Schließen</FlatButton>
					]}>
						<DateRangeView/>
					</Dialog>
				}*/}
				{viewDateRange && <Modal open centered={false}>
					<Modal.Content><DateRangeView/></Modal.Content>
					<Modal.Action><Button onClick={this.showRangePicker(false)}><Trans i18nKey={'actions.close'}/></Button></Modal.Action>
				</Modal>}
			</div>
		);
	}
}


MenuFilterPoint.propTypes = {
	elem: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.instanceOf(Appointments)]).isRequired,
	inverted: PropTypes.bool,
	children: PropTypes.node.isRequired,
	type: PropTypes.string.isRequired,
	className: PropTypes.string,
	spanStyle: PropTypes.object,
	strongStyle: PropTypes.object,
	smallStyle: PropTypes.object
};
MenuFilterPoint.defaultProps = {
	noText: "Nicht",
	inverted: false,
	spanStyle: {},
	strongStyle: {},
	smallStyle: {}
};

export default withRouter(Appointments);