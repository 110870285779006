import React from 'react';
import './Fade.css';
import PropTypes from 'prop-types';
import {deepMemoize} from "../Logic/extensions";

class Fade extends React.Component {
	constructor(props) {
		super(props);
		this.visibleOnStart = props.visible;
		this.state = {
			show: null
		};
		// this.setFadeClasses = this.setFadeClasses.bind(this);
		// this.setFadeClasses(props);
	}
	
	setFadeClasses = deepMemoize((fadeIn, fadeOut, fadeStart) => {
		this.setState({
			fade_in: fadeIn || 'fade-in',
			fade_out: fadeOut || 'fade-out',
			fade_start: fadeStart || !this.visibleOnStart ? 'fade-off' : '',
		});
	})
	
	updateShow = deepMemoize(show => this.setState({show}))
	
	componentDidMount() {
		this.setFadeClasses(this.props.fadeIn, this.props.fadeOut, this.props.fadeStart);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// this.setState({show: this.props.visible});
		this.updateShow(this.props.visible)
		this.setFadeClasses(this.props.fadeIn, this.props.fadeOut, this.props.fadeStart)
	}
	
	render() {
		const {children, visible, className,  inStyle = {}, outStyle = {}, style = {}, ...props} = this.props;
		const {show, fade_in, fade_out, fade_start} = this.state;
		let cl = ['Fade'];
		if (!this.visibleOnStart) {
			cl = [...cl, fade_start];
		}
		if (className) {
			cl = [...cl, ...className.split(' ')];
		}
		if (show === true) {
			cl = [...cl, fade_in];
		}
		if (show === false) {
			cl = [...cl, fade_out];
		}
		const _style = () => {
			if (show === true) {
				return {...style, ...inStyle};
			}
			if ( show === false ) {
				return {...style, ...outStyle};
			}
			return {};
		};
		
		return (
			<div {...props} style={_style()} className={cl.join(' ')}>{children}</div>
		);
	}
}

Fade.propTypes = {
	visible: PropTypes.bool,
	inStyle: PropTypes.object,
	outStyle: PropTypes.object
};

export default Fade;