import * as React from 'react';
import {Button, Checkbox, Table, Transition} from "semantic-ui-react";
import {Trans} from "react-i18next";
import PropTypes from 'prop-types'
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {mapRights} from "./WheelMethods";
import {get} from 'lodash'
import cn from 'classnames'
import {withHouse, withModule} from "../../../Tools/RightsProvider";
import {Wheel} from "../../../models";

export const DEFAULT_COLUMNS = {
	title: false,
	type: true,
	set_type: true,
	service_type: true,
	reference_number: true,
	set_count: false,
	inch: false,
	extract_at: true,
	registration_mark: true,
	chassis_number: true,
	remove_action: true,
	client_id: false,
	vehicle_id: false,
	ewo_order_id: false,
	ewo_removal_id: false,
	ewo_tireset_number: true
}

export let WheelListHeader = ({columns, sticky, stickyTop}) => {
	const stickyStyle = sticky ? {position: 'sticky', zIndex: 12, top: stickyTop} : null;
	return (
		<Table.Header>
			<Table.Row>
				{columns.ewo_tireset_number && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'EwoTW: Reifensatznr.'}/></Table.HeaderCell>}
				{columns.registration_mark && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Kennzeichen'}/></Table.HeaderCell>}
				{columns.set_type && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Reifensatztyp'}/></Table.HeaderCell>}
				{columns.chassis_number && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Fahrgestellnr.'}/></Table.HeaderCell>}
				{columns.type && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Reifentyp'}/></Table.HeaderCell>}
				{columns.service_type && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Servicetyp'}/></Table.HeaderCell>}
				{columns.title && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Bezeichnung'}/></Table.HeaderCell>}
				{columns.client_id && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Kunde'}/></Table.HeaderCell>}
				{columns.vehicle_id && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Fahrzeug'}/></Table.HeaderCell>}
				{columns.reference_number && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Referenz'}/></Table.HeaderCell>}
				{columns.set_count && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'# Reifen'}/></Table.HeaderCell>}
				{columns.inch && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Größe in Zoll'}/></Table.HeaderCell>}
				{columns.extract_at && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'Auslagerung'}/></Table.HeaderCell>}
				{columns.ewo_order_id && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'EwoTW: Auftragsnr.'}/></Table.HeaderCell>}
				{columns.ewo_removal_id && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}><Trans defaults={'EwoTW: Auslagerungsnr.'}/></Table.HeaderCell>}
				{columns.remove_action && <Table.HeaderCell className={cn({sticky})} style={stickyStyle}>&nbsp;</Table.HeaderCell>}
			</Table.Row>
		</Table.Header>
	)
}
WheelListHeader.defaultProps = {
	columns: DEFAULT_COLUMNS,
	sticky: false,
	stickyTop: 0
}

export let WheelList = ({onSelect, modules, list, header, columns: iniCols, allRights, onDelete, deleteMap, t, i18n, tReady, dispatch, headerProps, isModule, houseData, ...props}) => {
	const getRights = memoizeOne(mapRights)
	const rights = getRights(allRights)
	const _onDelete = (callback, wheel) => e => {
		e.stopPropagation();
		callback && callback(wheel, e);
	}
	const _onSelect = (callback, wheel) => e => {
		callback && callback(wheel, e);
	}
	const memCols = memoizeOne((list, columns) => {
		return list.reduce((col, wheel) => {
			col.reference_number = col.reference_number && !!wheel.reference_number.trim()
			col.extract_at = col.extract_at && !!wheel.extract_at
			return col;
		}, columns);
	})
	const hasEwo = memoizeOne(list => list.some(w => !!w.ewo_tw))
	const columns = memCols(list, {...iniCols})
	
	columns.remove_action = modules.wheel && columns.remove_action && rights.delete && onDelete;
	const hasEwoData = Boolean(hasEwo(list) && houseData.ewo_tw)
	columns.ewo_order_id = hasEwoData && columns.ewo_order_id
	columns.ewo_removal_id = hasEwoData && columns.ewo_removal_id
	columns.ewo_tireset_number = hasEwoData && columns.ewo_tireset_number
	return (
		<Table {...props} selectable={!!onSelect}>
			{React.cloneElement(header, {...headerProps, columns})}
			<Transition.Group as={Table.Body}>
				{list.map(w => {
					const wheel = new Wheel(w)
					const isDeleting = get(deleteMap, wheel.wheel_id, false)
					return <Table.Row key={wheel.wheel_id} onClick={_onSelect(onSelect, wheel.origin)}>
						{columns.ewo_tireset_number && <Table.Cell>{get(wheel, 'ewo_tw.tireSetNumber', '-')}</Table.Cell>}
						{columns.registration_mark && <Table.Cell>{wheel.registration_mark}</Table.Cell>}
						{columns.set_type && <Table.Cell><Trans i18nKey={`set-type-options.${wheel.set_type}`} defaults={wheel.set_type}/></Table.Cell>}
						{columns.chassis_number && <Table.Cell>{wheel.chassis_number}</Table.Cell>}
						{columns.type && <Table.Cell><Trans i18nKey={`tires.${wheel.type}`} defaults={wheel.type}/></Table.Cell>}
						{columns.service_type && <Table.Cell><Trans i18nKey={`wheel-service-options.${wheel.service_type}`} defaults={wheel.service_type}/></Table.Cell>}
						
						{columns.title && <Table.Cell>{wheel.title}</Table.Cell>}
						{columns.client_id && <Table.Cell><Checkbox disabled checked={!!wheel.client_id}/></Table.Cell>}
						{columns.vehicle_id && <Table.Cell><Checkbox disabled checked={!!wheel.vehicle_id}/></Table.Cell>}
						{columns.reference_number && <Table.Cell>{wheel.reference_number}</Table.Cell>}
						{columns.set_count && <Table.Cell>{wheel.set_count}</Table.Cell>}
						{columns.inch && <Table.Cell>{wheel.inch}</Table.Cell>}
						{columns.extract_at && <Table.Cell>{wheel.extract_at ? wheel.extract_moment.format('L') : '-'} {wheel.extract_at && (wheel.extraction_confirmed ? '(Bestätigt)' : '(Unbestätigt)')}</Table.Cell>}
						
						{columns.ewo_order_id  && <Table.Cell>{get(wheel, 'ewo_tw.storageOrderId', '-')}</Table.Cell>}
						{columns.ewo_removal_id  && <Table.Cell>{get(wheel, 'ewo_tw.removalOrderId', '-')}</Table.Cell>}
						
						{columns.remove_action && <Table.Cell textAlign={'right'}>
							{!w.ewo_tw && <Button icon={'trash'} basic onClick={_onDelete(onDelete, wheel.origin)} loading={isDeleting} disabled={isDeleting}/>}
						</Table.Cell>}
					</Table.Row>
				})}
			</Transition.Group>
		</Table>
	)
}
WheelList = withRights(SECTION.ORDERS, WheelList);
WheelList = withModule(WheelList)
WheelList = withHouse(WheelList)
// WheelList = translate()(WheelL)

WheelList.propTypes = {
	onSelect: PropTypes.func,
	list: PropTypes.arrayOf(PropTypes.object),
	header: PropTypes.element
}
WheelList.defaultProps = {
	header: <WheelListHeader/>,
	columns: DEFAULT_COLUMNS,
	deleteMap: {}
}
