import { connect } from 'react-redux';
import AppView from '../components/AppView';
import {prefetch} from "../actions/userActions";
import {houseAction__getCountries, loadTimezones} from "../actions/carhouseActions";

const mapStateToProps = (state) => {
	return {
		user: state.user
	}
};

const mapDispatchToProps = (dispatch) => ({
	onPreload: () => {
		dispatch(prefetch());
		dispatch(loadTimezones());
		dispatch(houseAction__getCountries());
	}
});

let AppController = connect(
	mapStateToProps,
	mapDispatchToProps
)(AppView);



export default AppController;