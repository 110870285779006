import * as React from 'react';
import PropTypes from 'prop-types';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {Button, Checkbox, Icon, Table} from "semantic-ui-react";
import {Trans} from "react-i18next";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {Required, withScreen} from "../../../../Logic/extensions";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {moment} from "../../../../Logic/Moment";
import {MaterialMobileAwarePicker} from "../../../../Tools/DatePicker";
import {startOfDay, endOfDay} from 'date-fns'
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {scheduleCall__dynamicDelete} from "../../../../actions/scheduleActions";

export class DynamicScheduleDeleteMask extends StatyComponent {
	static propTypes = {
		worker: PropTypes.object.isRequired,
		resources: PropTypes.arrayOf(PropTypes.object),
		onNotification: PropTypes.func,
		onDelete: PropTypes.func,
		onAfterDelete: PropTypes.func
	}
	static defaultProps = {
		onNotification: dispatchSnack,
		onDelete: scheduleCall__dynamicDelete,
		onAfterDelete: result => {}
	}
	
	state = {
		resources: {},
		days: {},
		range_start: null,
		range_end: null,
		deleting: false
	}

	delete = async () => {
		const {onNotification, onDelete, worker, onAfterDelete} = this.props;
		const {range_start, range_end} = this.state;
		try {
			this.setState({deleting: true});
			const result = await onDelete(worker.workers_id, this.resources, this.days, range_start, range_end);
			onNotification(`${result.count} Einträge wurden gelöscht.`);
			onAfterDelete(result);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({deleting: false});
		}
	}
	
	set = (field) => (value) => this.setState({[field]: value});
	setValue = (field, value) => () => this.set(field)(value);
	setRangeStart = (date) => this.set('range_start')(startOfDay(date));
	setRangeEnd = (date) => this.set('range_end')(endOfDay(date));
	getDay = index => moment().day(index + 1).format('dd');
	setResource = (id) => () => this.setState(state => ({
		...state,
		resources: {
			...state.resources,
			[id]: !state.resources[id]
		}
	}));
	isResource = (id) => Boolean(this.state.resources[id]);
	setDay = (index) => () => this.setState(state => ({
		...state,
		days: {
			...state.days,
			[index]: !state.days[index]
		}
	}));
	isDay = (index) => Boolean(this.state.days[index]);
	getResourceList = memoizeOne(map => Object.entries(map).filter(s => Boolean(s[1])).map(s => Number(s[0])) );
	getDayList = memoizeOne(map => Object.entries(map).filter(s => Boolean(s[1])).map(s => Number(s[0])) );
	
	get alignment() { return this.props.screen.width > 767 ? EasyFlex.align.START : EasyFlex.align.CENTER; }
	get resources() { return this.getResourceList(this.state.resources); }
	get days() { return this.getDayList(this.state.days); }
	get daysOk() { return this.resources.length > 0; }
	get rangeOk() { return this.daysOk && this.days.length > 0; }
	get deleteOk() { return this.rangeOk && this.state.range_start && (!this.state.range_end || (this.state.range_end && this.state.range_end >= this.state.range_start)); }
	render() {
		const {resources} = this.props;
		const {range_start, range_end, deleting} = this.state;
		return (
			<div>
				<Table basic={'very'} definition>
					<Table.Body>
						<Table.Row>
							<Table.Cell textAlign={'center'}><Trans defaults={'Ressourcen'}/></Table.Cell>
							<Table.Cell textAlign={'center'}>
								<EasyFlex wrap align={this.alignment}>
									{resources.map(resource =>
										<EasyFlex key={`dsdm-resource-${resource.resource_id}`} direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{margin: 8}}>
											<Checkbox id={`dsdm-resource-${resource.resource_id}`} checked={this.isResource(resource.resource_id)} onChange={this.setResource(resource.resource_id)}/>
											<label htmlFor={`dsdm-resource-${resource.resource_id}`}>{resource.name}</label>
										</EasyFlex>
									)}
								</EasyFlex>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell textAlign={'center'}><Trans defaults={'Tage'}/></Table.Cell>
							<Table.Cell textAlign={'center'}>
								<EasyFlex align={this.alignment} wrap>
									{[0,1,2,3,4,5,6].map(day =>
										<EasyFlex key={`dsdm-day-${day}`} direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{margin: 12}}>
											<Checkbox disabled={!this.daysOk} id={`dsdm-day-${day}`} checked={this.isDay(day)} onChange={this.setDay(day)}/>
											<label htmlFor={`dsdm-day-${day}`}>{this.getDay(day)}</label>
										</EasyFlex>
									)}
								</EasyFlex>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell textAlign={'center'}><Trans defaults={'Kalenderspannweite'}/></Table.Cell>
							<Table.Cell textAlign={'center'}>
								<EasyFlex valign={EasyFlex.valign.BASELINE} align={this.alignment} wrap>
									<EasyFlex>
										<MaterialMobileAwarePicker
											disabled={!this.rangeOk}
											required
											floating
											placeholderText={<Required><Trans defaults={'Startdatum'}/></Required>}
											baseDate={range_end || new Date()}
											minDate={new Date()}
											selectsStart
											selected={range_start}
											startDate={range_start}
											endDate={range_end}
											onChange={this.setRangeStart}
											materialProps={{style: {width: 120}}}
											
										/>
										<MaterialMobileAwarePicker
											disabled={!this.rangeOk}
											floating
											placeholderText={<Trans defaults={'Enddatum'}/>}
											baseDate={range_start || new Date()}
											minDate={new Date()}
											selectsEnd
											selected={range_end}
											startDate={range_start}
											endDate={range_end}
											onChange={this.setRangeEnd}
											materialProps={{style: {width: 120}}}
										/>
									</EasyFlex>
									<Button loading={deleting} disabled={!this.deleteOk || deleting} icon style={{marginLeft: 12}} negative onClick={this.delete}><Icon name={'trash'}/> <Trans i18nKey={'actions.delete'}/></Button>
								</EasyFlex>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>
		);
	}
}
DynamicScheduleDeleteMask = withScreen()(DynamicScheduleDeleteMask);