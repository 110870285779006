import * as React from "react";
import PropTypes from "prop-types";
import {Button, Header, Modal, Table} from "semantic-ui-react";
import {trueNull} from "../../../Logic/extensions";
import {connect} from "react-redux";
import {sessionStorageAction__setPaused} from "../../../actions/sessionStorageActions";
import moment from "../../../Logic/Moment";
import {OrderRangeView, OrderStatusView} from "../orders/OrderStatusView";
import Countdown, {renderSimple} from "../../partials/Countdown";
import {COLORS} from "../../../Logic/constants";
import {EasyFlex} from "../../partials/ActionHeader";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {translate} from "react-i18next";
import {Client, OrderService, Vehicle} from "../../../models";
import VehiclePopupInfo from "../vehicles/VehiclePopupInfo";
import ClientPopupInfo from "../clients/ClientPopupInfo";
import {soundAction__play, soundAction__stop} from "../../../actions/soundActions";
import {IconAlertAdd} from "../../../Logic/icons";
import {uniqBy} from "lodash";
import {CircleNotchLoader} from "../../Loaders";

const INTERVAL = 60000;
const SECTION_ORDER = 'order';
const SECTION_SERVICE = 'service';
const SECTION_VEHICLE = 'vehicle';
const SECTION_CLIENT = 'client';

const ModalLoader = ({iconProps, ...props}) => (
	<Modal defaultOpen centered={false} size={"mini"}{...props}>
		<Modal.Content style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: 150}}>
			<CircleNotchLoader/>
		</Modal.Content>
	</Modal>
);

const Empty = ({padded = false}) => (
	<Table.Row style={{background: '#f5f5f5'}}>
		<Table.Cell colSpan={2}>{trueNull(padded) && '\u00a0'}</Table.Cell>
	</Table.Row>
);

const renderCountdown = data => {
	data[2] = data[2] + data[1] * 24;
	const value = (data[0] ? '-' : '') + renderSimple(data);
	
	return <span style={{fontWeight: "bold", color: data[0] ? COLORS.SEMANTIC_RED : COLORS.SECONDARY}}>{value}</span>;
};

const TimeTillDeadline = ({deadline: time}) => {
	const deadline = moment(time);
	return (
		<Countdown targetDate={deadline.toDate()} render={renderCountdown}/>
	);
};

const ItemView = ({item, onDetail, last}) => (
	<React.Fragment>
		<Table.Row style={{fontWeight: "bold"}}>
			<Table.Cell textAlign={"right"}>Service</Table.Cell>
			<Table.Cell textAlign={"center"}>{item.service_name}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"}>Auftragsnummer</Table.Cell>
			<Table.Cell textAlign={"center"}>{item.order_id}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"}>Status</Table.Cell>
			<Table.Cell><OrderStatusView order={item} backgroundColor={"transparent"}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"}>Zeitrahmen</Table.Cell>
			<Table.Cell><OrderRangeView order={item}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"} style={{fontWeight: "bold"}}>Deadline</Table.Cell>
			<Table.Cell textAlign={"center"}><TimeTillDeadline deadline={item.latest_point}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"} style={{fontWeight: "bold"}}>Zeit bis zur Abgabe</Table.Cell>
			<Table.Cell textAlign={"center"}><TimeTillDeadline deadline={item.fetch_point}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell textAlign={"right"}>Optionen</Table.Cell>
			<Table.Cell textAlign={"center"}>
				<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
					<div style={{paddingLeft: 5, paddingRight: 5}}><Button circular icon={"list"} onClick={onDetail(SECTION_ORDER, item)}/></div>
					<div style={{paddingLeft: 5, paddingRight: 5}}><Button circular icon={"wrench"} onClick={onDetail(SECTION_SERVICE, item)}/></div>
					<div style={{paddingLeft: 5, paddingRight: 5}}><Button circular icon={"car"} onClick={onDetail(SECTION_VEHICLE, item.order_vehicle_id)}/></div>
					<div style={{paddingLeft: 5, paddingRight: 5}}><Button circular icon={"user"} onClick={onDetail(SECTION_CLIENT, item.order_vehicle_id)}/></div>
				</EasyFlex>
			</Table.Cell>
		</Table.Row>
		{last ? null : <Empty padded/>}
	</React.Fragment>
);

const serviceSubtitle = (list) => {
	const orders = uniqBy(list, s => s.order_id).length;
	const services = list.length;
	if (orders === 1) {
		return `${services} Service in einem Auftrag`;
	}
	return `${services} Service in ${orders} Aufträgen`;
};

export class PausedNotificationAlert extends React.PureComponent {
	static propTypes = {
		paused: PropTypes.arrayOf(PropTypes.shape({
			order_id: PropTypes.number.isRequired,
			order_service_id: PropTypes.number.isRequired,
			delivered_at: PropTypes.any,
			fetch_point: PropTypes.any,
			latest_point: PropTypes.any
		})),
		markSeen: PropTypes.func,
		playSound: PropTypes.bool,
		play: PropTypes.func
	};
	static defaultProps = {
		paused: [],
		playSound: true,
		play: () => {},
		markSeen: () => alert('PausedNotificationAlert::markSeen() has not been implemented yet!')
	};
	state = {
		display: [],
		section: '',
		data: null
	};
	
	section = (section = '', data = null) => () => this.setState({section, data});
	
	displayModal = (display) => () => this.setState({display}, () => this.props.playSound && this.props.play(Boolean(display && display.length)));
	
	checkPause = () => {
		console.debug('CHecking paused list', this.props.paused);
		let alertList = [];
		for (const item of this.props.paused) {
			if (moment(item.latest_point) < moment().subtract(15, 'minute')) {
				alertList.push(item);
			}
		}
		alertList.length && this.displayModal(alertList)();
	};
	
	start = () => {
		if (!this.timer) {
			this.checkPause();
			this.timer = window.setInterval(this.checkPause, INTERVAL);
		}
	};
	
	stop = () => {
		if (this.timer) {
			window.clearInterval(this.timer);
			this.timer = null;
		}
	};
	
	componentDidMount() {
		this.props.paused.length && this.start();
	}
	
	componentWillUnmount() {
		this.stop();
		this.props.playSound && this.props.play(false);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.paused.length) {
			this.start();
		} else {
			this.stop();
		}
	}
	
	render() {
		const {display, section, data} = this.state;
		const {t, markSeen, playSound} = this.props;
		let service;
		return (
			
			trueNull(display.length) && <React.Fragment>
				<Modal centered={false} closeOnDimmerClick={false} defaultOpen onClose={this.displayModal(false)}>
					<Modal.Header>
						<EasyFlex valign={EasyFlex.valign.CENTER}>
							{trueNull(playSound) && <IconAlertAdd className={"alert-icon"} color={COLORS.SEMANTIC_RED} style={{width: 64, height: 64, marginRight: 40}}/>}
							<span>Pausierte Aufträge haben die Deadline erreicht <Header.Subheader as={'h5'}>{serviceSubtitle(display)}</Header.Subheader></span>
						</EasyFlex>
					</Modal.Header>
					<Modal.Content scrolling>
						<Table celled compact basic={"very"} padded={"very"}>
							<Table.Body>
								{display.map((item, i) => {
									return <ItemView key={item.order_service_id} item={item} onDetail={this.section} last={i + 1 === display.length}/>;
								})}
							</Table.Body>
						</Table>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => {
							markSeen(display.map(s => s.order_service_id));
							this.displayModal(false)();
						}}>Ok, ich habe das wahrgenommen</Button>
					</Modal.Actions>
				</Modal>
				{trueNull(SECTION_ORDER === section && data) && <Modal defaultOpen closeOnDimmerClick={false} style={{
					top: 0, width: "100%", background: "white", padding: 0
				}}>
					<Modal.Content scrolling style={{padding: 0, maxHeight: "calc(100vh - 93px)", height: "calc(100vh - 93px)"}}>
						<OrderQuickView order_id={data.order_id || data} onRequestClose={this.section()}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.section()}>{t('actions.close')}</Button>
					</Modal.Actions>
				</Modal>}
				{trueNull(SECTION_VEHICLE === section && data) && <Vehicle.OrderProvider placeholder={<ModalLoader/>} order_vehicle_id={data}>{vehicle =>
					<Modal defaultOpen onClose={this.section()} closeIcon centered={false} size={"tiny"}>
						<Modal.Header as={"h3"}>Fahrzeug: {vehicle.name}</Modal.Header>
						<Modal.Content style={{textAlign: "center"}}>
							<VehiclePopupInfo inverted={false} vehicle={vehicle} style={{display: "inline-table", borderCollapse: "collapse"}}/>
						</Modal.Content>
					</Modal>
				}</Vehicle.OrderProvider>}
				{trueNull(SECTION_CLIENT === section && data) && <Vehicle.OrderProvider placeholder={<ModalLoader/>} order_vehicle_id={data}>{vehicle =>
					<Client.Provider client_id={vehicle.client_id} placeholder={<ModalLoader/>}>{client =>
						<Modal defaultOpen onClose={this.section()} closeIcon centered={false} size={"tiny"}>
							<Modal.Header as={"h3"}>Kunde: {client.contact}</Modal.Header>
							<Modal.Content style={{textAlign: "center"}}>
								<ClientPopupInfo inverted={false} client={client} style={{display: "inline-table", borderCollapse: "collapse"}}/>
							</Modal.Content>
						</Modal>
					}</Client.Provider>
				}</Vehicle.OrderProvider>}
				{trueNull(SECTION_SERVICE === section && (service = new OrderService(data))) && <Modal defaultOpen onClose={this.section()} closeIcon centered={false} size={"tiny"}>
					<Modal.Header>Service: {service.service_name}</Modal.Header>
					<Modal.Content style={{textAlign: "center"}}>
						<OrderService.PopupInfo inverted={false} service={service} style={{display: "inline-table"}}/>
					</Modal.Content>
				</Modal>}
			</React.Fragment>
		);
	}
}

PausedNotificationAlert = connect(
	state => {
		const pauseMap = state.session.notification_paused_map;
		const paused = state.appointments.paused_services.filter(s => !(s.order_service_id in pauseMap));
		return {
			paused
		};
	},
	dispatch => ({
		markSeen: (value) => dispatch(sessionStorageAction__setPaused(value)),
		play: (yes) => dispatch(yes ? soundAction__play : soundAction__stop)
	})
)(PausedNotificationAlert);
PausedNotificationAlert = translate()(PausedNotificationAlert);