import React from 'react';
import PropTypes from 'prop-types';
// import SVGInline from "react-svg-inline";
import {translate} from "react-i18next";
import cn from 'classnames';
import {withRouter} from "react-router-dom";
import Mini from '../../images/carperto_logo_mini.png';

const Header = ({t, className, history: {push}}) => (
	<header className={cn(className)} style={{height: 57, padding: '0 20px'}}>
		<div className="left" style={{height: 57}}>
			{/*<SVGInline id="app-logo" svg={constants.LOADER}/>*/}
			<div className="App-title pointer" onClick={() => push('/')} style={{position: 'relative', height: 57}}>
				<img src={Mini} alt={t('welcome')}/>
			</div>
		</div>
		<div className="right">
			{/*<LanguageV2Controller/>*/}
		</div>
	</header>
);

Header.propTypes = {
	className: PropTypes.string
};

export default translate()(withRouter(Header));