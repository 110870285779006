import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {orderAction__updateInfo} from "../../../../actions/orderActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {TextArea, Button, Form} from "semantic-ui-react";
// import {TextField} from "material-ui";
import {PortalWrapper, StatyComponent} from "../../../../Tools/ReactExtension";
import {Trans, translate} from "react-i18next";
import {EasyFlex} from "../../../../components/partials/ActionHeader";

export class OrderInfoUpdateView extends StatyComponent {
	static propTypes = {
		info: PropTypes.string,
		id: PropTypes.string,
		children: PropTypes.node,
		order_id: PropTypes.number.isRequired,
		onUpdateInfo: PropTypes.func.isRequired,
		onAfterUpdate: PropTypes.func,
		buttonPortal: PropTypes.any,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		onAfterUpdate: () => {
		},
		onUpdateInfo: () => {
			throw Error('Method not implemented!')
		},
		onNotification: dispatchSnack
	};
	
	state = {
		value: this.props.info || "",
		saving: false
	};
	
	save = async () => {
		const {value} = this.state
		const {onUpdateInfo, onAfterUpdate, onNotification, order_id} = this.props
		try {
			this.setState({saving: true});
			const result = await onUpdateInfo(order_id, value)
			onNotification('Informationen wurden gespeichert')
			onAfterUpdate(result)
		}catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({saving: false});
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.info !== this.props.info) {
			this.setState({value: this.props.info});
		}
	}
	
	setValue = (_, {value}) => this.setState({value})
	
	render() {
		const {value, saving} = this.state;
		const { buttonPortal, t} = this.props;
		return (
			// <Form id={id} {...props} onSubmit={() => onUpdateInfo(order_id, value, onAfterUpdate)} style={{paddingBottom: 40}}>
			<Form>
				{/*<TextField*/}
				{/*	value={value}*/}
				{/*	hintText={"Information bzgl. des Termines/Autrages hier eingtragen"}*/}
				{/*	multiLine*/}
				{/*	fullWidth*/}
				{/*	floatingLabelText={"Informationen"}*/}
				{/*	onChange={(_, value) => this.setState({value})}*/}
				{/*/>*/}
				<TextArea value={value} onChange={this.setValue} style={{display: 'block'}} placeholder={t('...', 'Auftragsinformationen...')} rows={11}/>
				
				{!buttonPortal && <EasyFlex style={{marginTop: 20}} align={EasyFlex.align.END}>
					<Button loading={saving} positive onClick={this.save}><Trans defaults={'Speichern'} i18nKey={'actions.save'}/></Button>
				</EasyFlex>}
				<PortalWrapper portal={buttonPortal} only>
					<Button loading={saving} positive onClick={this.save}><Trans defaults={'Speichern'} i18nKey={'actions.save'}/></Button>
				</PortalWrapper>
			</Form>
		);
	}
}

// export class ConnectedOrderInfoUpdateView extends React.Component {
// 	static propTypes = {
// 		info: PropTypes.string,
// 		id: PropTypes.string,
// 		children: PropTypes.node,
// 		order_id: PropTypes.number.isRequired,
// 		onAfterUpdate: PropTypes.func
// 	};
//
// 	render() {
// 		return (
// 			<OrderInfoUpdateView {...this.props}/>
// 		);
// 	}
// }

OrderInfoUpdateView = translate()(OrderInfoUpdateView);

export const ConnectedOrderInfoUpdateView = connect(
	null,
	(dispatch) => ({
		onUpdateInfo: (id, info, onSucccess) => dispatch(orderAction__updateInfo(id, info, onSucccess, true))
	})
)(OrderInfoUpdateView);
