import React, {useEffect, useState} from 'react'
import {useHouse} from "../../../actions/useStates";
import {ActionBackButton, ActionHeader, ActionHeading, EasyFlex} from "../../partials/ActionHeader";
import {CircleNotchLoader} from "../../Loaders";
import MasterDataResourceCalendar from "./MasterDataResourceCalendar";
import {get} from 'lodash'
import {DynamicWorkerScheduleView} from "../../../cointainer/intern/master-data/calendar/DynamicWorkerScheduleView";
import {Worker} from "../../../models";
import {useDispatch} from "react-redux";
import {resourceAction__fetchWorkers} from "../../../actions/resourceActions";
import {Segment} from "semantic-ui-react";

/**
 *
 * @param {object|null} w
 * @return {string}
 * @private
 */
const _provider = w => {
	if (!w) {
		return 'Mitarbeiter-Ressourcen'
	}
	const worker = new Worker(w)
	return `${worker.name}: Ressourcen`
}
/**
 *
 * @param {function} dispatch
 * @param {function} setter
 * @return {Promise<void>}
 * @private
 */
const _init = async (dispatch, setter) => {
	const result = await dispatch(resourceAction__fetchWorkers())
	setter(result)
}

export const MasterDataResourceCalendarView = ({workersId}) => {
	const house = useHouse()
	const calendar = get(house, 'schedule_type', null)
	const [view, setView] = useState(calendar)
	const dispatch = useDispatch()
	const [resources, setResources] = useState(null)
	
	// setup calender view
	useEffect(() => {
		setView(calendar)
	}, [calendar])
	
	// init worker resources
	useEffect(() => {
		// noinspection JSIgnoredPromiseFromCall
		_init(dispatch, setResources)
	}, [])
	
	// show loader
	if (!house || !resources) {
		return <EasyFlex style={{minHeight: 150}} alignCenter valignCenter>
			<CircleNotchLoader/>
		</EasyFlex>
	}
	
	// show resource 'error'
	if (!(workersId in resources)) {
		return <Segment basic style={{paddingTop: 0}}>
			<ActionHeader alignStart>
				<ActionBackButton/>
				<ActionHeading>Aktion nicht verfügbar: Mitarbeiter ohne zugewiesene Ressourcen</ActionHeading>
			</ActionHeader>
		</Segment>
	}
	
	return <>
		{view === 'static' && <MasterDataResourceCalendar skipWorkerResources active={calendar === 'static'} workersId={workersId} onSwitchView={setView.bind(this, 'dynamic')}/>}
		{view === 'dynamic' && <DynamicWorkerScheduleView active={calendar === 'dynamic'} workerId={workersId} onSwitchView={setView.bind(this, 'static')} titleProvider={_provider}/>}
	</>
}