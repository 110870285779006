import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {workerAction__collect, workerAction__getList, workerCall__getConsultants} from "../../../../actions/workerActions";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {Worker} from "../../../../models";
import {array2object, falseNull, trueNull} from "../../../../Logic/extensions";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Dropdown, Icon, Image} from "semantic-ui-react";
import * as React from "react";
import {connect} from "react-redux";
import cn from 'classnames';
import {Trans, translate} from "react-i18next";

export class ConsultantSelector extends StatyComponent {
	static propTypes = {
		client: PropTypes.object,
		onNotificationBad: PropTypes.func,
		onLoadConsultants: PropTypes.func,
		workers: PropTypes.object.isRequired,
		onLoadWorkers: PropTypes.func,
		onSelect: PropTypes.func,
		className: PropTypes.string,
		white: PropTypes.bool,
		readOnly: PropTypes.bool,
		borderless: PropTypes.bool
	};
	static defaultProps = {
		onNotificationBad: (message) => dispatchSnack(message, 'alert'),
		onLoadConsultants: () => workerCall__getConsultants()
	};
	state = {
		consultants: null,
		loading: false
	};
	
	loadConsultants = async() => {
		const {onNotificationBad, onLoadConsultants} = this.props;
		try {
			this.setState({loading: true});
			const consultants = await onLoadConsultants();
			this.setState({consultants});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.message);
			this.setState({consultants: false});
		} finally {
			this.setState({loading: false});
		}
	};
	
	componentDidMount() {
		super.componentDidMount();
		this.loadConsultants();
		this.props.onLoadWorkers() && this.props.onLoadWorkers();
	}
	
	initWorkers = memoizeOne(
		map => Object.values(map).filter(w => w.active && !w.deleted)
	);
	
	get workerList() {
		return this.initWorkers(this.props.workers);
	}
	
	initConsultant = memoizeOne(
		(client, workers) => {
			let worker = client && client.consultant_id ? (workers[client.consultant_id] || null) : null
			return worker ? new Worker(worker) : null;
		}
	);
	
	get consultant() {
		return this.initConsultant(this.props.client, this.props.workers);
	}
	
	select = (consultant) => () => {
		this.props.onSelect && this.props.onSelect(consultant);
	};
	
	getConsultantMap = memoizeOne(
		consultants => array2object(consultants || [])( c => c.workers_id)
	);
	
	render() {
		const consultants = this.getConsultantMap(this.state.consultants);
		const {client, children, onNotificationBad, onLoadConsultants, workers, onLoadWorkers, onSelect, className, white, readOnly, borderless, t, tReady, i18n, ...props} = this.props;
		return (
			trueNull(this.workerList.length) &&
					<Dropdown
						placeholder={'Serviceberater'}
						basic
						button
						className={cn('consultant-selector', {white, 'read-only': readOnly, borderless}, className)}
						{...props}
						trigger={this.consultant ? <EasyFlex valign={EasyFlex.valign.CENTER}>
							<EasyFlex inline align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER} style={{width: 40, marginRight: 8}}>
								<Image src={this.consultant.avatar} style={{maxWidth: 40, maxHeight: 40, borderRadius: 4}}/>
							</EasyFlex>
							<div>{this.consultant.name}</div>
						</EasyFlex> : t('...', 'Keinen Berater ausgewählt')}
					>
						{falseNull(readOnly) && <Dropdown.Menu>
							{this.consultant && <React.Fragment>
								<Dropdown.Item onClick={this.select(null)}><Trans defaults="Auswahl entfernen"/></Dropdown.Item>
								<Dropdown.Divider/>
							</React.Fragment>}
							{this.workerList.map(worker => {
								const w = new Worker(worker);
								const isConsultant = !!consultants[worker.workers_id];
								return <Dropdown.Item active={this.consultant && this.consultant.workers_id === worker.workers_id} onClick={this.select(worker)} key={worker.workers_id}>
									<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_BETWEEN}>
										<EasyFlex align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER} style={{width: 50, marginRight: 8}}>
											<Image src={w.avatar} style={{maxWidth: 50, maxHeight: 50, borderRadius: 4}}/>
										</EasyFlex>
										<span style={{flexGrow: 10}}>{w.name}</span>
										<span style={{marginLeft: 8}}>{isConsultant ? <Icon name={'user'}/> : ''}</span>
									</EasyFlex>
								</Dropdown.Item>;
							})}
						</Dropdown.Menu>}
					</Dropdown>
					
		);
	}
}
ConsultantSelector = translate()(ConsultantSelector);

export const ConnectedConsultantSelector = connect(
	state => ({
		workers: state.map.workers
	}),
	dispatch => ({
		onLoadWorkers: () => dispatch(workerAction__getList()).then(ids => dispatch(workerAction__collect(ids)))
	})
)(ConsultantSelector);
