import * as React from 'react';
import PropTypes from 'prop-types';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex} from "../../../partials/ActionHeader";
import {Button, Checkbox, Header, Input, Segment, Table} from "semantic-ui-react";
import hash from 'object-hash';
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {houseCall__getEvosecInterface, houseCall__postEvosecInterface} from "../../../../actions/carhouseActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";

const DEFAULT_STATE = {
	active: false,
	host: '',
	port: '',
	ssl: false,
	virtual_host: '',
	username: '',
	password: '',
	exchange: '',
	queue: '',
	routing_key: ''
};

class EvosecInterface extends StatyComponent {
	static propTypes = {
		onFetch: PropTypes.func,
		onSave: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onNotificationGood: PropTypes.func
	};
	static defaultProps = {
		onFetch: () => houseCall__getEvosecInterface(),
		onSave: (options) => houseCall__postEvosecInterface(options),
		onNotificationBad: (message) => dispatchSnack(message, 'alert'),
		onNotificationGood: (message) => dispatchSnack(message)
	};
	
	state = {
		origin: DEFAULT_STATE,
		options: DEFAULT_STATE,
		loading: false,
		saving: false
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.fetch();
	}
	
	fetch = async () => {
		const {onFetch, onNotificationBad} = this.props;
		try {
			this.setState({loading: true});
			const result = await onFetch();
			this.setState(state => ({
				...state,
				origin: {
					...state.origin,
					active: result.active,
					...result.options
				},
				options: {
					...state.options,
					active: result.active,
					...result.options
				}
			}))
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loading: false});
		}
	};
	
	save = async () => {
		const {onSave, onNotificationBad, onNotificationGood} = this.props;
		try {
			this.setState({saving: true});
			const result = await onSave(this.state.options);
			this.setState(state => ({
				...state,
				origin: {
					...state.origin,
					active: result.active,
					...result.options
				},
				options: {
					...state.options,
					active: result.active,
					...result.options
				}
			}), () => onNotificationGood('Daten wurden gespeichert.'))
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({saving: false});
		}
	}
	
	isChanged = memoizeOne(
		(left, right) => hash(left) !== hash(right)
	);
	
	get changed() {
		return this.isChanged(this.state.origin, this.state.options);
	}
	
	toggle = (e, {value, name}) => this.setState(state => ({
		...state,
		options: {
			...state.options,
			[name]: !state.options[name]
		}
	}));
	
	set = (e, {value, name}) => this.setState(state => ({
		...state,
		options: {
			...state.options,
			[name]: value
		}
	}));
	
	render() {
		const {
			options,
			loading,
			saving
		} = this.state;
		return (
			<Segment loading={loading}>
				
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
					<Header as={'h3'}>Evosec Kunden- und Fahrzeugschnittstelle</Header>
					<Checkbox toggle name={'active'} checked={options.active} onChange={this.toggle}/>
				</EasyFlex>
				
				<form action="" onSubmit={e => {
					e.preventDefault();
					this.save();
				}}>
					<Table basic={'very'} collapsing style={{display: options.active ? 'block' : 'none'}}>
						<Table.Body>
							
							<Table.Row>
								<Table.Cell>Host</Table.Cell>
								<Table.Cell><Input name={'host'} value={options.host} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Port</Table.Cell>
								<Table.Cell><Input name={'port'} value={options.port} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>SSL</Table.Cell>
								<Table.Cell><Checkbox toggle name={'ssl'} checked={options.ssl} onChange={this.toggle}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Virtual Host</Table.Cell>
								<Table.Cell><Input name={'virtual_host'} value={options.virtual_host} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Username</Table.Cell>
								<Table.Cell><Input autoComplete={'off'} name={'username'} value={options.username} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Passwort</Table.Cell>
								<Table.Cell><Input type={'password'} name={'password'} value={options.password} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Exchange</Table.Cell>
								<Table.Cell><Input name={'exchange'} value={options.exchange} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Queue</Table.Cell>
								<Table.Cell><Input name={'queue'} value={options.queue} onChange={this.set}/></Table.Cell>
							</Table.Row>
							
							<Table.Row>
								<Table.Cell>Routing Key</Table.Cell>
								<Table.Cell><Input name={'routing_key'} value={options.routing_key} onChange={this.set}/></Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
					{this.changed && <EasyFlex>
						<Button positive loading={saving}>Speichern</Button>
					</EasyFlex>}
				</form>
			</Segment>
		);
	}
}

export class MDInterface extends StatyComponent {
	render() {
		return (
			<div>
				<ActionHeader ulStyle={{marginLeft: 20, marginRight: 20}} alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Schnittstellen</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<EvosecInterface/>
			</div>
		);
	}
}