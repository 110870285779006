import React from 'react';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import history from '../../../Logic/history';
import {AssistOverview} from "./AssistOverview";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {NoAccess} from "../../index";

let AssistanceRoutes = ({rights}) => {
	if (!rights.mayRead) {
		return <NoAccess/>;
	}
	return <ConnectedRouter history={history}>
		<div id="view__workers" className="view__main">
			<Switch>
				<Route exact path="/assistance" component={AssistOverview}/>
				{/*<Route exact path="/workers" component={WorkerViewTest}/>*/}
				{/*<Route path={'/workers/create'} component={WorkersCreateController} />*/}
				{/*<Route path={'/workers/detail/:id'} component={WorkersDetailController} />*/}
				{/*<Route path={'/workers/calendar/:workers_id'} component={WorkersCalendarMainController} />*/}
				{/*<Route path={'/workers/calendar'} component={WorkersCalendarMainController} />*/}
			</Switch>
		</div>
	</ConnectedRouter>
};

AssistanceRoutes = withRights(SECTION.ASSISTANCE, AssistanceRoutes);

export default AssistanceRoutes;