import * as React from 'react';
import PropTypes from "prop-types";
import cn from "classnames";
import DefaultIcon from 'material-ui/svg-icons/editor/short-text';
import {Dropdown, Popup} from "semantic-ui-react";
import isFunc from "lodash/isFunction";
import {translate} from "react-i18next";
import isArray from "lodash/isArray";
import {COLORS} from "../../Logic/constants";

const infuseIcon = (element, props) => {
	let color = "black";
	const {readOnly, editable, errorText} = props;
	if ( errorText ) {
		color = COLORS.SEMANTIC_RED;
	} else if (readOnly || !editable) {
		color = "lightgray";
	}
	return React.cloneElement(element, {className: cn("dropdown-field-icon"), color});
};

const PopupIcon = ({error, icon, visible, ...props}) => {
	if ( !visible ) {
		return null;
	}
	icon = infuseIcon(icon, props);
	if ( error ) {
		return <Popup inverted content={error} trigger={icon}/>
	}
	return icon;
};


class DropdownField extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		label: PropTypes.node,
		id: PropTypes.string,
		wrapperClassName: PropTypes.string,
		dropdownClassName: PropTypes.string,
		icon: PropTypes.element,
		readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		editable: PropTypes.bool,
		errorBeneeth: PropTypes.bool,
		useInput: PropTypes.bool
	};
	static defaultProps = {
		id: "dropdown-field-" + Math.random(),
		icon: <DefaultIcon/>,
		selection: true
	};
	
	state = {
		focused: false
	};
	
	ref = React.createRef()	;
	
	handleFocus = (focused, next) => e => {
		this.setState({focused});
		if (isFunc(next)) next(e);
	};
	
	translate = (errorText) => isArray(errorText) ? this.props.t.call(null, errorText[0], errorText[1] || {}) : this.props.t(errorText);
	
	render() {
		const {t, i18n, tReady, label, required, id, className, errorText, useInput, search, wrapperClassName, errorBeneeth, dropdownClassName, readOnly, editable, icon, selection, onFocus, onBlur, ...props} = this.props;
		const blocked = readOnly || !editable;
		return(
			<div className={cn("dropdown-field", "field", className, {required, error: errorText, focused: this.state.focused, readonly: blocked, "error-beneeth": errorBeneeth})}>
				{label && <label htmlFor={id}>{label}</label>}
				<div className={cn("dropdown-field-wrapper", wrapperClassName, {search})}>
					<Dropdown
						ref={this.ref}
						fluid
						required={required}
						className={cn("dropdown-field-element", dropdownClassName, {selection})}
						id={id}
						search={search}
						{...props}
						error={!!errorText}
						onFocus={this.handleFocus(true, onFocus)}
						onBlur={this.handleFocus(false, onBlur)}
						readOnly={blocked}
					/>
					{useInput && <input onChange={(() => {})} required={required} value={props.text} style={{height: 0, opacity: 0, marginTop: -3, overflow: "hidden", padding: 0, display: "block"}}/>}
					<PopupIcon
						{...this.props}
						error={errorText}
						visible={!!icon}
						icon={icon}
					/>
				</div>
				{errorBeneeth && errorText ? <div className={'ith-edit-field-error-bottom'}>{this.translate(errorText)}</div> : null}
			</div>
		);
	}
}

export default translate()(DropdownField);