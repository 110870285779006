/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {scheduleCall__dynamicAnalyze} from "../../actions/scheduleActions";
import {dispatchSnack} from "../../actions/snackbarActions";
import {array2object, deepMemoize as memoizeOne, trueNull} from "../../Logic/extensions";
import {SizeMe} from 'react-sizeme';
import {Crosshair, DiscreteColorLegend, Highlight, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot, YAxis} from "react-vis";
import {moment} from "../../Logic/Moment";
import {startOfDay} from 'date-fns'
import {get, groupBy, isArray} from "lodash";
import {workerCall__getAll} from "../../actions/workerActions";
import {Button, Header, Segment, Table} from "semantic-ui-react";
import KeyHandler, {KEYUP} from "react-key-handler";

class DynamicScheduleAnalyzerChart extends React.Component {
	static propTypes = {
		onFetchData: PropTypes.func,
		onNotification: PropTypes.func,
		isLoading: PropTypes.func,
		startDate: PropTypes.instanceOf(Date),
		endDate: PropTypes.instanceOf(Date),
		onLoadWorkers: PropTypes.func,
		workers: PropTypes.object,
		controls: PropTypes.bool
	};
	static defaultProps = {
		onFetchData: scheduleCall__dynamicAnalyze,
		onNotification: dispatchSnack,
		startDate: new Date(),
		endDate: null,
		isLoading: value => {
		},
		onLoadWorkers: workerCall__getAll,
		workers: null
	};
	
	static minWidth = 1000;
	
	state = {
		loading: false,
		loading_workers: false,
		workers: {},
		data: [],
		width: DynamicScheduleAnalyzerChart.minWidth,
		scale: null,
		detail: []
	};
	
	componentDidMount() {
		this.fetchData();
		this.props.workers === null && this.loadWorkers();
	}
	
	get workers() {
		return this.props.workers !== null ? this.props.workers : this.state.workers;
	}
	
	fetchData = async () => {
		const {onNotification, onFetchData, startDate, endDate, isLoading} = this.props;
		try {
			this.setState({loading: true});
			isLoading(true);
			let data = await onFetchData(startDate, endDate);
			this.setState({data});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
			isLoading(false);
		}
	};
	
	loadWorkers = async () => {
		const {onLoadWorkers, onNotification} = this.props;
		try {
			this.setState({loading_workers: true});
			const workers = await onLoadWorkers();
			if (null === workers) {
				return onNotification('Mitarbeiter konnten nicht geladen werden', 'alert');
			}
			console.warn('foudn workers', workers);
			this.setState({workers});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_workers: false});
		}
	};
	
	groupData = memoizeOne(
		data => groupBy(data, 'workers_id')
	);
	
	prepareData = memoizeOne(
		data => {
			const base = startOfDay(this.props.startDate || new Date());
			return data.map(s => ({
				...s,
				x: moment(s.schedule_date).format('L'),//(startOfDay(moment(s.schedule_date).toDate()).valueOf() - base.valueOf()) / 1000 / 3600 / 24) + '-' + s.workers_id,
				y: s.resource_hours
			}));
		}
	);
	
	minMaxFnc = (data) => {
		if (!data.length) {
			return [null, null];
		}
		return [data[0], data[data.length - 1]];
	};
	
	minMax = memoizeOne(this.minMaxFnc);
	
	setScale = (scale) => {
		if (isArray(scale)) {
			this.setState({scale});
			return;
		}
		this.setState(scale ? {scale: [Math.floor(scale.left), Math.ceil(scale.right)]} : {scale: null});
	}
	resetScale = () => this.setScale(null);
	
	unsetDetails = () => this.setState({detail: []});
	setDetails = (data) => (value, {index}) => {
		if (!data) return;
		let details = [];
		for (const [wid, list] of Object.entries(data)) {
			details.push(list[index]);
		}
		this.setState({detail:details});
	}
	
	
	fillData = (data, wid, minValue, max) => {
		const segments = array2object(data)(s => moment(s.schedule_date).format('YYYY-MM-DD'));
		// let [min, max] = this.minMaxFnc(data).map(s => moment(s.schedule_date));
		const min = minValue.clone();
		let next = [];
		let i = 0;
		do {
			const index = min.format('YYYY-MM-DD');
			if (index in segments) {
				next.push({ x: i++, y: segments[index].y, workers_id: Number(wid), date: moment(segments[index].schedule_date)});
			} else {
				next.push({x: i++, y: 0, workers_id: Number(wid), date: min.clone()});
			}
			min.add(1, 'day');
		} while(min < max);
		
		return next;
	};
	
	segmentData = memoizeOne(
		(data, min, max) => {
			let next = {};
			for (const [wid, item] of Object.entries(data)) {
				next[wid] = this.fillData(item, wid, min, max);
			}
			return next;
		}
	);
	
	moveRight = (max = 0, step = 1) => {
		this.setState(state => {
			let nextScale = state.scale || [0, max];
			if (nextScale[1] < (max - step + 1)) {
				nextScale = nextScale.map(s => s + step);
			}
			return {
				scale: nextScale
			}
		})
	};
	
	moveLeft = (max = 0, step = 1) => {
		this.setState(state => {
			let nextScale = state.scale || [0, max];
			if (nextScale[0] > (step - 1)) {
				nextScale = nextScale.map(s => s - step);
			}
			return {
				scale: nextScale
			}
		})
		
	};
	
	handleWheel = (max = 0, step = 1) => e => {
		const event = e.nativeEvent;
		
		if (event.deltaY > 0) {
			this.moveRight( max, step);
		} else {
			this.moveLeft( max, step);
		}
	}
	
	
	extractWorkersForLegend = memoizeOne(
		(workers, segments) => Object.keys(segments).map(wid => get(workers, [wid, 'name'], wid))
	);
	
	reset = () => this.setState({width: DynamicScheduleAnalyzerChart.minWidth});
	
	scale = memoizeOne(
		(data, max) => data ? [data.left, data.right] : null
	);
	
	filterOfIndex = memoizeOne(
		(data, index) => {
			let next = {};
			for (const [wid, list] of Object.entries(data)) {
				next[wid] = list[index] || null
			}
			return next;
		}
	)
	
	filterData = memoizeOne(
		data => {
			let next = [];
			let test = moment('2020-08-02');
			for (const list of Object.values(data)) {
				for (const d of list) {
					if (d.x === 2) {
						next.push(d);
					}
				}
			}
			return next;
		}
	)
	
	render() {
		const {data, width, scale, detail} = this.state;
		const {controls} = this.props;
		const viewData = this.prepareData(data);
		const [min, max] = this.minMax(viewData).map(s => moment(s ? s.schedule_date : null));
		let diff = 0, count = 0;
		if (min && max){
			count = moment(max).diff(min, 'day');
			diff = count + 7
		}
		const groupedData = this.groupData(viewData);
		const labels = this.extractWorkersForLegend(this.workers, groupedData);
		const filledData = this.segmentData(groupedData, min, max);
		return (
			<div>
				<SizeMe>{({size}) =>
					<div>
						{controls && <div style={{textAlign: 'center'}}><Button.Group>
							<Button disabled={!scale || scale.left <= 0} icon={'arrow left'} color={'blue'} onClick={() => this.moveLeft( diff, 2)}/>
							<Button icon={'close'} disabled={!scale} onClick={this.resetScale}/>
							<Button disabled={!scale || scale.right >= diff} icon={'arrow right'} color={'blue'} onClick={() => this.moveRight( diff, 2)}/>
						</Button.Group></div>}
						<div>
							<KeyHandler
								keyValue={'ArrowLeft'}
								keyEventName={KEYUP}
								onKeyHandle={() => this.moveLeft( diff)}
							/>
							<KeyHandler
								keyValue={'ArrowRight'}
								keyEventName={KEYUP}
								onKeyHandle={() => this.moveRight( diff)}
							/>
							<XYPlot
								width={Math.max(width, size.width)}
								height={500}
								onWheel={this.handleWheel( diff)}
								// xType={'ordinal'}
								// xDomain={this.scale(scale, count)}
								xDomain={scale}
								margin={{left: 40, bottom: 70}}
								onMouseLeave={this.unsetDetails}
								// getX={data => moment(data.schedule_date).format('L')}
							>
								<VerticalGridLines/>
								<HorizontalGridLines/>
								{
									Object.entries(filledData).map(([wid, data]) => {
										// const filledData = this.fillData(data);
										return <VerticalBarSeries key={wid}
										                          data={data}
										                          cluster={wid}
										                          onNearestX={this.setDetails(filledData)}
										/>;
									})
								}
								
								<XAxis title={'Tag'} tickLabelAngle={-90} tickFormat={(day) => {
									return min.clone().add(day, 'day').format('L');
								}}/>
								<YAxis title={'Stunden'}/>
								<DiscreteColorLegend items={labels} orientation={'horizontal'} style={{textAlign: 'center'}}/>
								<Crosshair
									values={detail}
									className={'test-class-name'}
								>
									{trueNull(detail.length) && <Segment inverted>
										<Header as={'h5'} className={'align center'}>{detail[0].date.format('dd L')}</Header>
										<Table inverted striped>
											<Table.Body>
												{detail.map(d =>
													<Table.Row key={d.workers_id}>
														<Table.Cell>{get(this.workers, [d.workers_id, 'name'], `M-${d.workers_id}`)}</Table.Cell>
														<Table.Cell style={{whiteSpace: 'nowrap'}}><strong>{d.y}</strong> Std.</Table.Cell>
													</Table.Row>
												)}
											</Table.Body>
										</Table>
									</Segment>}
								</Crosshair>
								<Highlight
									enableY={false}
									// drag
									onBrushEnd={this.setScale}
								/>
							</XYPlot>
						</div>
					</div>
				}</SizeMe>
			</div>
		);
	}
}

// DynamicScheduleAnalyzerChart = withSize()

export class DynamicScheduleAnalyzerTest extends React.Component {
	state = {
		loading: false
	}
	
	setField = field => value => this.setState({[field]: value});
	
	render() {
		const {loading} = this.state;
		return (
			<div>
				<div style={{paddingLeft: 14, paddingRight: 14}}>
					<ActionHeader alignment={'space-between'}>
						<ActionHeaderGroup>
							<ActionBackButton loading={loading}/>
							<ActionHeading>Kalenderanalyse</ActionHeading>
						</ActionHeaderGroup>
					</ActionHeader>
				</div>
				<div style={{paddingLeft: 14, paddingRight: 14, paddingBottom: 14}}>
					<DynamicScheduleAnalyzerChart
						isLoading={this.setField('loading')}
						controls
					/>
				</div>
			</div>
		);
	}
}