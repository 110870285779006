import {Icon} from "semantic-ui-react";
import {IconDone} from "../../../../Logic/icons";
import * as React from "react";
import {ReleaseIcon} from "../../release";

const WorkLabel = ({ paused, working, serviceInfo}) => {
	if (!serviceInfo.released) {
		return <ReleaseIcon id={serviceInfo.order_service_id} size={20} api/>;
	}
	if ( paused ) {
		return <Icon name={"pause"} style={{margin: 0}}/>;
	}
	if (working) {
		return <Icon name={"cog"} style={{margin: 0}} loading/>;
	}
	return serviceInfo.finished_at ? <IconDone/> : `${serviceInfo.hours_of_work_resource} Std.`;
};

export default WorkLabel;