import * as React from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {translate} from "react-i18next";
import {Form} from "semantic-ui-react";
import {_empty, AHToggle, ListItemMargined, Loadable} from "../../../../Logic/extensions";
import {Subbar} from "ah-tools";
import EditField from "../../../partials/EditField";
import {IconSettings} from "../../../../Logic/icons";
import {withModule} from "../../../../Tools/RightsProvider";


class MDHouseAppointments extends React.PureComponent {
	static propTypes = {
		t: PropTypes.func,
		i18n: PropTypes.object,
		tReady: PropTypes.any,
		house: PropTypes.shape({
			house_id: PropTypes.number.isRequired,
			prio_tuv: PropTypes.bool.isRequired,
			direct_service_duration: PropTypes.number.isRequired
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool
	};
	static defaultProps = {
		isSaving: false
	};
	
	state = {
		prio_tuv: null,
		direct_service_duration: null,
		delivery_service_duration: null,
		post_process_duration: null,
		wheel_preparation_duration: null,
		use_login_balancing: null
	};
	
	value = index => this.state[index] !== null ? this.state[index] : this.props.house[index];
	
	get prio() {
		return this.value('prio_tuv');
	}
	
	get duration() {
		return this.value('direct_service_duration');
	}
	
	get deliveryDuration() {
		return this.value('delivery_service_duration');
	}
	
	get postProcessDuration() {
		return this.value('post_process_duration');
	}
	
	get wheelPreparationDuration() {
		return this.value('wheel_preparation_duration')
	}
	
	get useLoginBalancing() {
		return this.value('use_login_balancing')
	}
	
	handleChange = (e, {name, value}) => {
		if (this.props.house[name] === value) {
			return this.setState({[name]: null});
		}
		return this.setState({[name]: value});
	};
	
	handleToggle = (name) => (e, value) => this.handleChange(e, {name, value});
	
	isValid = () => {
		const save = this.state;
		return !_empty(save);
	};
	
	handleSave = () => {
		this.props.onSave(this.props.house, this.state, () => this.setState({
			prio_tuv: null,
			direct_service_duration: null,
			delivery_service_duration: null,
			post_process_duration: null,
			wheel_preparation_duration: null,
			use_login_balancing: null
		}));
	};
	
	
	render() {
		const {t, isSaving, modules} = this.props;
		// const changePrioTuv = this.value('prio_tuv');
		return (
			<Card>
				<CardHeader title={t('worker.detail.parts.basisdata')} subtitle={t('worker.detail.parts.appointment-options')}/>
				<CardText>
					<Form onSubmit={this.handleSave}>
						<ListItemMargined
							primaryText={'TÜV prioritisieren (Terminsuche)'}
							secondaryText={this.prio ? 'TÜV-Service werden bei der Terminsuche bevorzugt gesucht' : 'Keine Beeinflußung durch den TÜV'}
							rightToggle={<AHToggle toggled={this.prio} onToggle={this.handleToggle('prio_tuv')}/>}/>
						<ListItemMargined
							primaryText={'Arbeitszuweisung mittels Anmeldestatus'}
							secondaryText={this.useLoginBalancing ? 'Nur angemeldete Mitarbeiter werden berücksichtigt' : 'Alle Mitarbeiter werden berücksichtigt'}
							rightToggle={<AHToggle toggled={!!this.useLoginBalancing} onToggle={this.handleToggle('use_login_balancing')}/>}/>
						<Form.Field>
							<div style={{height: 12}}/>
							<EditField
								icon={<IconSettings/>}
								text={'Direktannahme Zeitaufwand in Minuten'}
								required
								type={'number'}
								min={1}
								step={1}
								name={'direct_service_duration'}
								defaultValue={this.duration}
								editable={true}
								onChange={this.handleChange}
							/>
						</Form.Field>
						<Form.Field>
							<div style={{height: 12}}/>
							<EditField
								icon={<IconSettings/>}
								text={'Bring- & Abholservice: Zeitaufwand in Minuten'}
								required
								type={'number'}
								min={1}
								step={1}
								name={'delivery_service_duration'}
								defaultValue={this.deliveryDuration}
								editable={true}
								onChange={this.handleChange}
							/>
						</Form.Field>
						{modules.assist && <Form.Field>
							<div style={{height: 12}}/>
							<EditField
								icon={<IconSettings/>}
								text={'Auftragsnachbearbeitung: Zeitaufwand in Minuten'}
								required
								type={'number'}
								min={0}
								step={1}
								name={'post_process_duration'}
								defaultValue={this.postProcessDuration}
								editable={true}
								onChange={this.handleChange}
							/>
						</Form.Field>}
						{modules.wheel && <Form.Field>
							<div style={{height: 12}}/>
							<EditField
								icon={<IconSettings/>}
								text={'Reifenvorbereitungszeit: Zeitpuffer in Stunden'}
								required
								type={'number'}
								min={0}
								step={1}
								name={'wheel_preparation_duration'}
								defaultValue={this.wheelPreparationDuration}
								editable={true}
								onChange={this.handleChange}
							/>
						</Form.Field>}
						<Subbar style={{marginTop: 20}} align={Subbar.ALIGN_END}>
							<Loadable loading={isSaving} disabledOnLoading disabled={!this.isValid()} inverse type={'submit'} primary hoc={RaisedButton}>{t('actions.save')}</Loadable>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}

MDHouseAppointments = withModule(MDHouseAppointments);
export default translate()(MDHouseAppointments);