import * as React from "react";
import PropTypes from "prop-types";
import {Flex} from "./ActionHeader";
import DefaultIcon from 'material-ui/svg-icons/editor/short-text';
import cn from "classnames";
import isFunc from "lodash/isFunction";
import values from "lodash/values";
import {withValidation} from "./ValidationContext";
import {validateEditFieldClass} from "./ValidationField";


const {Provider: WrappedInputProvider, Consumer: WrappedInputConsumer} = React.createContext();


class InputWrapper extends React.Component {
	static propTypes = {
		icon: PropTypes.element,
		rightIcon: PropTypes.element,
		children: PropTypes.node.isRequired,
		label: PropTypes.node
	};
	
	state = {
		focus: false,
		children: null,
		valid: {}
	};
	
	children = null;
	ref = React.createRef();
	
	
	handleValid = (name, valid) => {
		if ( !name || valid === undefined) return;
		this.setState(state => ({
			...state,
			valid: {
				...state.valid,
				[name]: valid
			}
		}));
	};
	
	isValid = () => {
		const result =  values(this.state.valid);
		return result.reduce((p, c) => Boolean(p && c), true);
	};
	
	handleChange = next => e => {
		const value = e.target ? e.target.value : e.value;
		const parent = e.target ? e.target.parentNode : e.parentNode;
		value ? parent.classList.remove("empty") : parent.classList.add("empty");
		if (isFunc(next)) {
			next(e, e.target);
		}
	};
	
	render() {
		let {icon: Icon, rightIcon: RightIcon, children, onChange, label, disabled, readOnly, editable, required, keepRatio, ...props} = this.props;
		
		props = {
			...props,
			disabled, readOnly, editable, required,
			ref: this.ref,
			// onFocus:this.handleFocus(true, onFocus),
			// onBlur: this.handleFocus(false, onBlur),
			isValid: this.handleValid,
			// isFocus: this.handleFocus,
			// onKeyUp: this.handleChange(onChange),
			onChange: this.handleChange(onChange),
			// onMount: this.handleChange(),
			className: cn(props.className, "input-wrapped")
		};
		
		return(
			<WrappedInputProvider value={props}>
				<div ref={el => this.ref = el} className={cn("input-wrapper", {focused: this.state.focus, "keep-ratio": keepRatio, disabled, readonly: readOnly || !editable, required, error: !this.isValid()})}>
					{label && <label className="input-label">{label}</label>}
					<Flex valign="center" className={"input-line"}>
						{Icon ? React.cloneElement(Icon, {style: {flexShrink: 0}}) : <DefaultIcon style={{flexShrink: 0}}/>}
						{children}
						{RightIcon}
					</Flex>
				</div>
			</WrappedInputProvider>
		);
	}
}



const withWrappedInputConsumer = Component => props => (
	<WrappedInputConsumer>{wrappedProps =>
		<Component
			wrappedContext={wrappedProps}
			{...props}
			required={props.required || wrappedProps.required}
			min={props.min || wrappedProps.min}
			max={props.max || wrappedProps.max}
			minLength={props.minLength || wrappedProps.minLength}
			maxLength={props.maxLength || wrappedProps.maxLength}
			type={props.type}
		/>}
	</WrappedInputConsumer>);

class WrappedInput extends React.Component {
	static defaultProps = {
		grow: null
	};
	
	state = {
		focus: false
	};
	
	ref = React.createRef();
	
	componentDidMount() {
		if (isFunc(this.props.wrappedContext.isValid)) {
			this.props.wrappedContext.isValid(this.props.name, this.props.isValid);
		}
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {isValid} = this.props
		if (isValid !== prevProps.isValid) {
			if (isFunc(prevProps.wrappedContext.isValid)) {
				prevProps.wrappedContext.isValid(prevProps.name, isValid);
			}
		}
	}
	
	handleChange = e => {
		const {onChange} = this.props;
		const {onChange: onContextChange} = this.props.wrappedContext;
		if (isFunc(onChange)) {
			onChange(e, e.target);
		}
		if (isFunc(onContextChange)) {
			onContextChange(e, e.target);
		}
	};
	
	handleFocus = (focus, next) => e => {
		this.setState({focus});
		if ( isFunc(next)) {
			next(e);
		}
		if ( e.target) {
			const parent = e.target.closest(".input-wrapper");
			if ( parent ) {
				parent.classList.add("touched");
				if ( focus ) {
					parent.classList.add("focused");
				} else {
					parent.classList.remove("focused");
				}
			}
		}
	};
	
	render() {
		const {wrappedContext, onFocus, onBlur, grow, shrink: flexShrink, style, errorText: inputError, isValid, ...inputProps} = this.props;
		const flexGrow = null === grow ? null : Math.min(100, Math.abs(grow));
		const flexBasis = null === flexGrow ? null : flexGrow + '%';
		const readonly = inputProps.readOnly || wrappedContext.readOnly;
		const disabled = inputProps.disabled || wrappedContext.disabled;
		const editable = inputProps.editable || wrappedContext.editable;
		const errorText = inputError || wrappedContext.errorText;
		const required = inputProps.required || wrappedContext.required;
		const blocked = readonly || !editable;
		return(
			<span className={cn("input-span", {empty: inputProps.value === '', focused: this.state.focus, error: errorText})} style={{flexBasis, flexGrow, flexShrink}} data-placeholder={inputProps.placeholder}>
				<RefInput
					readOnly={blocked}
					disabled={disabled}
					required={required}
					onChange={this.handleChange}
					onFocus={this.handleFocus(true, onFocus)}
					onBlur={this.handleFocus(false, onBlur)}
					className={cn("input-wrapped", {focused: this.state.focus})}
					style={{...style}}
					{...inputProps}
				/>
			</span>
		);
	}
}


const WrappedValidatedInput = withValidation(withWrappedInputConsumer(validateEditFieldClass(WrappedInput)));
WrappedValidatedInput.defaultProps = {
	silentMode: null
};

WrappedInput = withWrappedInputConsumer(WrappedInput);

const RefInput = React.forwardRef((props, ref) => <input ref={ref} {...props}/>);

export default InputWrapper;
export {WrappedInput, WrappedValidatedInput};