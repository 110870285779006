import {isFunction, isString, sortedUniq, sortedUniqBy, partition, get, isArray} from "lodash";
import memoize from 'memoize-one'
import {dequal} from "dequal";
import {IMAGE_URL} from "./constants";
import AvatarM from "../images/avatar-m.gif";
import AvatarF from "../images/avatar-f.png";
import ServiceImage from "../images/service2.png";
import hasher from "object-hash";
import {setLoader, unsetLoader} from "../actions/loaderActions";
import {addError} from "../actions/errorActions";

export const capital = (value, defaultReturn = '') => {
	if (isString(value) && value.trim()) {
		return value.trim().charAt(0).toUpperCase() + '.';
	}
	return defaultReturn;
};


export const propertiesExists = (obj, ...props) => {
	for (const prop of props) {
		if (!obj.hasOwnProperty(prop)) {
			return false;
		}
	}
	return true;
};

export const capitalizeString = (str, defaultReturn = str) => isString(str) ? str.charAt(0).toUpperCase() + str.slice(1) : defaultReturn;

export const joiner = (target, t, ...args) => {
	args = args.map(value => {
		if (isFunction(value)) {
			return value.call(this, target, t);
		}
		if (isString(value)) {
			if (value.indexOf('r||') === 0) {
				return value.substr(3);
			}
			if (value.indexOf('u||') === 0) {
				value = value.substr(3);
				return capitalizeString(target[value], '')
			}
			if (value.indexOf('c||') === 0) {
				value = value.substr(3);
				return capital(value in target ? target[value] : null)
			}
			if (value.match(/.*({{\w+}}).*/)) {
				value = value.replace(/.*({{\w+}}).*/g, (all, part) => {
					let repl = part.replace(/[{}]+/g, '');
					if (!(repl in target)) {
						return '';
					}
					return all.replace(part, target[repl]);
				});
				if (!value || !t) {
					console.error('🙈 no value mapped');
					return null;
				}
				return t ? t([value, 'empty_string']) : null;
			}
			return value in target ? target[value] : null;
		}
		return null;
	});
	args = args.filter(value => isString(value) && value.trim());
	return args.join(' ');
};

export const avatar_gender = memoize(
	gender => gender === 'm' ? AvatarM : AvatarF, dequal
);

export const avatar = memoize(
	(avatar, gender) => {
		if (avatar) {
			const {ident, origin_extension, updated_at} = avatar;
			return `${IMAGE_URL}${ident}.${origin_extension}?${updated_at}`;
		}
		return avatar_gender(gender);
	}, dequal
);

export const logo_service_default = () => ServiceImage;

export const logo_service = memoize(
	source => {
		if (!source) {
			return logo_service_default();
		}
		return `${IMAGE_URL}${source.ident}.${source.origin_extension}?${source.updated_at}`;
	}, dequal
);

export class Collector {
	collection = {};
	list = [];
	accessor = '';
	loader = [];
	
	stateProvider = (state) => {
		return {};
	};
	
	apiProvider = (list) => new Promise(k => {
		console.warn('`Collector` : Using default api provider, please provide own one with `Collector.withApiProvider(method)`');
		k({});
		return {};
	});
	
	storeProvider = (result, dispatch, getState) => {};
	
	resultProvider = (result) => result;
	
	sort = (a, b) => a - b;
	objSort = (a, b) => a[this.accessor] - b[this.accessor];
	normalizer = value => Number(value);
	
	constructor(collection, list, accessor) {
		this.collection = collection;
		this.list = list;
		this.accessor = accessor;
	}
	
	withLoader(loader) {
		if (isArray(loader)) {
			this.loader = loader;
		}
		return this;
	}
	
	withStateProvider(method) {
		if (isString(method)) {
			this.stateProvider = (state) => get(state, method, {});
		} else if (isFunction(method)) {
			this.stateProvider = method;
		}
		return this;
	}
	
	withApiProvider(method) {
		if (isFunction(method)) {
			this.apiProvider = method;
		}
		return this;
	}
	
	withStoreProvider(method) {
		if (isFunction(method)) {
			this.storeProvider = method;
		}
		return this;
	}
	
	withResultProvider(method) {
		if (isString(method)) {
			this.resultProvider = (result) => get(result, method, {});
		} else if (isFunction(method)) {
			this.resultProvider = method;
		}
		return this;
	}
	
	static _(collector, list, accessor) {
		return new Collector(collector, list, accessor);
	}
	
	execute() {
		return async function(dispatch, getState) {
			if (!this.list) {
				return [];
			}
			let prep = this.list.filter(Boolean);
			prep = prep.map(l => this.normalizer(l[this.accessor] || l));
			prep = prep.filter(Boolean);
			prep = prep.sort(this.sort);
			let collect = sortedUniq(prep), exists = [];
			const hash = hasher(collect);
			if (!(hash in this.collection)) {
				// this.accessor === 'client_id' && console.error('client should now fetch', collect.map(l => l[this.accessor] || l));
				this.collection[hash] = new Promise(async k => {
					const state = this.stateProvider(getState());
					[exists, collect] = partition(collect, l => l in state);
					try {
						if (!collect.length) {
							exists = exists.sort(this.sort).map(l => state[l]);
							k(exists);
							// this.accessor === 'client_id' && console.error(this.accessor, ' exists already !!');
							return exists;
						}
						this.loader.length && dispatch(setLoader(...this.loader));
						let result = await this.apiProvider(collect.map(l => l[this.accessor] || l));
						this.storeProvider(result, dispatch, getState);
						result = this.resultProvider(result);
						exists = exists.map(e => state[e[this.accessor] || e]);
						exists = [...Object.values(result || {}), ...exists];
						exists = sortedUniqBy(exists.sort(this.objSort), e => e[this.accessor]);
						k(exists);
						return exists;
					} catch (e) {
						dispatch(addError(e));
						k([]);
						return [];
					} finally {
						this.loader.length && dispatch(unsetLoader(...this.loader));
						delete this.collection[hash];
					}
				});
			}
			return await this.collection[hash];
		}.bind(this);
	}
}