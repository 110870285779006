import * as React from "react";
import BaseModel from "./BaseModel";
import {isFunction} from "lodash";
import PropTypes from "prop-types";
import {logo_service} from "../Logic/helper";
import {connect} from "react-redux";
import {withChange} from "../Logic/withInit";
import {serviceAction__collect} from "../actions/serviceActions";
import {Popup as SemanticPopup} from "semantic-ui-react";
import {Line} from "../components/partials/PopupInfo";
import {ProviderComponent} from "../components/partials";

const Provider = ({service, children, placeholder}) => service && isFunction(children) ? children(service) : placeholder;
Provider.propTypes = {
	service_id: PropTypes.number.isRequired,
	placeholder: PropTypes.node,
	dynamic: PropTypes.bool
};
Provider.defaultProps = {
	placeholder: null,
	dynamic: true
};

const CollectProvider = ({services, children, complete}) => isFunction(children) ? children(services, complete) : null;
CollectProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({service_id: PropTypes.number})])),
	dynamic: PropTypes.bool
};
CollectProvider.defaultProps = {
	ids : [],
	dynamic: true
};

const PopupInfo = ({service: s, inverted}) => {
	if (!s) {
		return null;
	}
	let service;
	if (s instanceof Service) {
		service = s;
	} else {
		service = new Service(s);
	}
	return (
		<table style={{borderCollapse: "collapse"}} cellPadding={6} border={0}>
			<tbody>
				<Line inverted={inverted} name={"Titel"}>{service.title}</Line>
			</tbody>
		</table>
	);
};

const Popup = ({service, children, ...props}) => service ? <SemanticPopup trigger={children} {...props} content={<PopupInfo inverted={props.inverted} service={service}/>}/> : children;
Popup.propTypes = {
	service: PropTypes.oneOfType([PropTypes.object]),
	children: PropTypes.node,
	inverted: PropTypes.bool,
	flowing: PropTypes.bool,
	position: PropTypes.string
};
Popup.defaultProps = {
	inverted: true,
	flowing: true,
	position: "bottom left"
};

export class Service extends BaseModel {
	_validateModel(model) {
		return ("service_id" in model) && ("tuv" in model);
	}
	
	get service_id() { return this.__model.service_id; }
	get id() { return this.service_id; }
	get title() { return this.__model.title; }
	get description() { return this.__model.desc || ''; }
	get active() { return Boolean(this.__model.active); }
	get tuv() { return Boolean(this.__model.tuv); }
	get logo_id() { return this.__model.logo_id; }
	get logo() { return this.__model.logo ? logo_service(this.__model.logo) : null; }
	get logo_background() {
		const logo = this.logo;
		if (logo) {
			return `url("${logo}")`;
		}
		return '';
	}
	
	static Provider = connect(
		(state, props) => {
			const s = state.map.services[props.service_id];
			return {
				service: s ? new Service(s) : null
			};
		},
		(dispatch) => ({
			withChange: ({service_id}) => dispatch(serviceAction__collect([service_id]))
		})
	)(withChange(Provider, {access: 'service_id', dynamicIndex: 'dynamic'}));
	
	static CollectProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.service_id || id)).filter(Boolean);
			let services = [...ids].map(id => state.map.services[id] || null).filter(Boolean);
			
			return {
				services,
				complete: services.length === ids.length
			};
		},
		(dispatch) => ({
			withChange: ({ids}) => ids && ids.length && dispatch(serviceAction__collect(ids))
		})
	)(withChange(CollectProvider, {access: 'ids', dynamicIndex: 'dynamic'}));
	
	static Popup = Popup;
	static PopupInfo = PopupInfo;
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.services[id];
			data = data ? new Service(data) : null;
			return {data};
		}
	)(ProviderComponent)
}