import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import withInit from "../../../../Logic/withInit";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../../Tools/RightsProvider";
import {SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../NoAccess";
import {Button, Segment} from "semantic-ui-react";
import {Flex, FlexChild} from "../../../partials/ActionHeader";
import {SelectField} from "../../../../Logic/extensions";
import {Checkbox, Divider, FlatButton, MenuItem, TextField} from "material-ui";
import {isFunction, values} from "lodash";
import {freedaysAction__removeWorkers, freedaysAction__updateWorkers} from "../../../../actions/freedaysActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_FREEDAYS_WORKERS} from "../../../../actions";
import {FREEDAY_WORKER_DEFINITION} from "../../../../props";
import {MaterialCompoundDateInput} from "../../../../Tools/DatePicker";

class WorkersFreedayEditView extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		workersMap: PropTypes.object,
		freeday: PropTypes.shape(FREEDAY_WORKER_DEFINITION).isRequired,
		onRemove: PropTypes.func,
		onChange: PropTypes.func,
		onAfterRemove: PropTypes.func,
		onAfterChange: PropTypes.func,
		loading: PropTypes.bool,
		onClose: PropTypes.func
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		workersMap: {},
		loading: false
	};
	state = {
		freedays_id: 0,
		type: null,
		workers_id: 0,
		start: null,
		end: null,
		start_date: "",
		start_time: "",
		end_date: "",
		end_time: "",
		description: "",
		allday: false
	};
	
	componentDidMount() {
		const {freeday} = this.props;
		const start = new Date(freeday.start_time);
		const end = new Date(freeday.end_time);
		this.setState({
			...freeday,
			start, end,
			start_date: start,
			end_date: end,
			start_time: freeday.allday ? "" : start.toLocaleTimeString(),
			end_time: freeday.allday ? "" : end.toLocaleTimeString()
		});
	}
	
	select = index => (e, idx, value) => this.setState({[index]: value});
	set = index => (e, value) => this.setState({[index]: value || e.target.value});
	update = index => value => this.setState({[index]: value});
	
	onChange = () => {
		const {onChange} = this.props;
		onChange(this.state);
	};
	
	onRemove = () => {
		const {onRemove, freeday} = this.props;
		onRemove(freeday);
	};
	
	render() {
		const {
			rights: {mayChange, mayDelete},
			workersMap,
			loading,
			freeday: {
				start_time: start,
				end_time: end
			}
		} = this.props;
		
		if ( !mayChange ) {
			return <NoAccess/>;
		}
		
		const {
			type: freedayType,
			workers_id,
			// start_date, start_time,
			// end_date, end_time,
			description,
			allday
		} = this.state;
		
		return (
			<Segment basic loading={loading}>
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={15} gutterWidth={25} valign={"center"}>
					<FlexChild>
						<SelectField
							disabled
							floatingLabelText={"Mitarbeiter"}
							value={workers_id}
							onChange={this.select("workers_id")}
						>
							<MenuItem disabled primaryText={'Bitte wählen'} value={0}/>
							<Divider/>
							{values(workersMap).map(({first_name, name, workers_id}) =>
								<MenuItem key={`create-freeday-${workers_id}`} value={workers_id}
								          primaryText={`${first_name.substr(0, 1).toUpperCase()}. ${name}`}/>
							)}
						</SelectField>
					</FlexChild>
					<FlexChild>
						<SelectField
							disabled
							value={freedayType}
							onChange={this.select("type")}
							floatingLabelText={"Art der Fehlzeit"}
						>
							<MenuItem primaryText={'Bitte auswählen'} disabled/>
							<Divider/>
							<MenuItem primaryText={'Urlaub'} value={'holiday'}/>
							<MenuItem primaryText={'Krankheit'} value={'sick'}/>
							<MenuItem primaryText={'Meistervertretung'} value={'master_replacement'}/>
							<MenuItem primaryText={'Intern/Schulung'} value={'intern'}/>
						</SelectField>
					</FlexChild>
					<FlexChild>
						<TextField
							floatingLabelText={'Beschreibung'}
							hintText={'Kind ist krank'}
							value={description}
							onChange={this.set("description")}
							disabled
						/>
					</FlexChild>
				</Flex>
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={25} gutterWidth={25} valign={"center"}>
					<FlexChild>
						<MaterialCompoundDateInput
							disabled
							date={start}
							dateLabel={'Von'}
							timeLabel={<span>&nbsp;</span>}
							timePlaceholder={'__:__'}
							selectsStart
							startDate={start}
							endDate={end}
						/>
					</FlexChild>
					<FlexChild>
						<MaterialCompoundDateInput
							disabled
							date={end}
							dateLabel={'Bis'}
							timeLabel={<span>&nbsp;</span>}
							timePlaceholder={'__:__'}
							selectsEnd
							startDate={start}
							endDate={end}
							endOfDay
						/>
					</FlexChild>
				</Flex>
				<Flex vmargin={20} wrap={"wrap"} gutterHeight={25} gutterWidth={25} valign={"center"} align={"space-between"}>
					<div>
						{mayDelete && <FlatButton secondary onClick={this.onRemove}>Löschen</FlatButton>}
					</div>
					<Flex vmargin={20} wrap={"wrap"} gutterHeight={25} gutterWidth={25} valign={"center"} align={"flex-end"}>
						<FlexChild>
							<Checkbox labelPosition={"left"} labelStyle={{marginRight: 5}} label={"Ganztags"} checked={allday} disabled onCheck={this.set('allday')}/>
						</FlexChild>
						{this.props.onClose && <FlexChild>
							<Button primary onClick={this.props.onClose}>Schließen</Button>
						</FlexChild>}
					</Flex>
				</Flex>
			</Segment>
		);
	}
}

WorkersFreedayEditView = withRights(SECTION.WORKERS, WorkersFreedayEditView);
WorkersFreedayEditView = withInit(WorkersFreedayEditView);
WorkersFreedayEditView = connect(
	(state, props) => {
		// noinspection JSUnresolvedVariable
		return {
			workersMap: props.workersMaps || state.workers.list,
			loading: isLoading(state, PROC_FREEDAYS_WORKERS)
		};
	},
	(dispatch, props) => {
		return {
			onChange: (data, onSuccess) => dispatch(freedaysAction__updateWorkers(data, result => {
				isFunction(onSuccess) && onSuccess(result);
				isFunction(props.onAfterChange) && props.onAfterChange(result);
				dispatch(addSnackbar('Daten geändert'));
			})),
			onRemove: (freeday, onSuccess) => dispatch(freedaysAction__removeWorkers(freeday, result => {
				isFunction(onSuccess) && onSuccess(result);
				isFunction(props.onAfterRemove) && props.onAfterRemove(result);
				dispatch(addSnackbar('Zeitspanne entfernt'));
			}))
		};
	}
)(WorkersFreedayEditView);

WorkersFreedayEditView = translate()(WorkersFreedayEditView);

export default WorkersFreedayEditView;