import {RESET_ACTION, RIGHT_ACTION} from "../actions/types";
import {updateCache} from "ith-redux-helpers";
import {CACHE_RIGHT_AREAS, CACHE_RIGHT_GROUPS, CACHE_RIGHT_RELATIONS} from "../actions";

const initState = {
	groups: {
		list: {},
		relation: {}
	},
	areas: {
		list: []
	}
};

const rightState = (state = initState, action) => {
	switch (action.type) {
		case RESET_ACTION:
			return initState;
		case RIGHT_ACTION.LOAD_AREAS:
			return {
				...state,
				areas: {
					list: action.areas
				},
				...updateCache(CACHE_RIGHT_AREAS)
			};
		case RIGHT_ACTION.LOAD_GROUPS:
			return {
				...state,
				groups: {
					list: action.groups.list,
					relation: action.groups.g2r
				},
				...updateCache(CACHE_RIGHT_GROUPS),
				...updateCache(CACHE_RIGHT_RELATIONS)
			};
		case RIGHT_ACTION.LOAD_AREAS_GROUPS:
			return {
				...state,
				areas: {
					list: action.areas
				},
				groups: {
					list: action.groups.list,
					relation: action.groups.g2r
				},
				...updateCache(CACHE_RIGHT_AREAS),
				...updateCache(CACHE_RIGHT_GROUPS),
				...updateCache(CACHE_RIGHT_RELATIONS)
			};
		case RIGHT_ACTION.RESET:
			return initState;
		default:
			return state;
	}
};

export default rightState;