import React from 'react'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {deepMemoize as memoizeOne, falseNull} from "../../../../Logic/extensions";
import {orderAction__collect} from "../../../../actions/orderActions";
import {Button, Divider, Form, Icon, Message, Modal, Segment} from "semantic-ui-react";
import {CircleNotchLoader} from "../../../../components/Loaders";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {WheelSearchModal} from "../../../../components/intern/wheel/WheelSearchModal";
import {wheelDispatch_assignOrder, wheelDispatch_clearOrder, wheelDispatch_one} from "../../../../actions/wheelActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {COLORS} from "../../../../Logic/constants";
import {ConnectedWheelEditor, WheelEditor, WheelModuleInactiveLabel} from "../../../../components/intern/wheel/WheelEditor";
import {withModule} from "../../../../Tools/RightsProvider";
import v4 from "uuid";
import {get} from 'lodash'
import {Trans} from "react-i18next";
import {FSModal} from "../../../../components/intern/wheel/ewotw/EwoTW";
import {ConnectedWheelListView} from "../../../../components/intern/wheel/WheelListView";

class WheelSelector extends React.Component {
	static propTypes = {
		order: PropTypes.object.isRequired,
		orderVehicle: PropTypes.object,
		onAssign: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		onNotification: PropTypes.func.isRequired
	}
	static defaultProps = {
		onAssign: wheelDispatch_assignOrder,
		onNotification: dispatchSnack,
		onRemove: wheelDispatch_clearOrder
	}
	state = {
		show_search: false,
		saving: false,
		removing: false,
		show_editor: false,
		show_wheel_search: false,
		wheel: null,
		comment: '',
		inset: null,
		show_client_wheels: false,
		show_vehicle_wheels: false,
		wheel_detail: null
	}
	formId = v4()
	
	assign = async () => {
		if (this.state.saving) return
		const {order, onNotification, onAssign} = this.props
		try {
			this.setState({saving: true})
			const response = await onAssign(order.order_id, this.state.wheel.wheel_id, this.state.comment.trim() || null)
			if (!response.ack) {
				onNotification(response.reason, COLORS.SEMANTIC_BROWN)
			} else {
				onNotification('Reifensatz wird ausgelagert')
				this.setState({wheel: null})
			}
			
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({saving: false})
		}
	}
	
	remove = async() => {
		const {order, onNotification, onRemove} = this.props
		if (!order.wheel_id) return
		try {
			this.setState({removing: true})
			await onRemove(order.order_id)
			onNotification('Satz wurde zurückgezogen')
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({removing: false})
		}
	}
	
	render() {
		const {order, orderVehicle, modules} = this.props
		if (!orderVehicle) {
			return <CircleNotchLoader/>
		}
		return (
			<React.Fragment>
				<EasyFlex wrap align={EasyFlex.align.SPACE_BETWEEN} style={{marginTop: 30}}>
					{!modules.wheel && <WheelModuleInactiveLabel/>}
					{order.wheel_id ? <div>Reifensatz vorhanden</div> : <div>Kein Reifensatz ausgelagert.</div>}
					<Button.Group>
						{order.wheel_id && <Button negative labelPosition={'left'} icon disabled={this.state.removing || this.state.saving} loading={this.state.removing} onClick={this.remove}><Icon name={'close'}/>Auslagerung stornieren</Button>}
						{/*{falseNull(order.wheel_id) && <Button labelPosition={'left'} onClick={() => this.setState({show_wheel_search: true})} icon><Icon name={'search'}/>Satz auslagern</Button>}*/}
						{modules.wheel && <Button labelPosition={'left'} positive disabled={this.state.removing || this.state.saving} onClick={() => this.setState({show_editor: true})} icon><Icon name={'upload'}/>Satz einlagern</Button>}
						{falseNull(order.wheel_id) && <Button color={'grey'} icon={'list'} className={'not-clickable'}/>}
						{falseNull(order.wheel_id) && <Button color={'grey'} icon={'search'} onClick={() => this.setState({show_wheel_search: true})}/>}
						{falseNull(order.wheel_id) && <Button color={'grey'} icon={'user'} onClick={() => this.setState({show_client_wheels: true})}/>}
						{falseNull(order.wheel_id) && <Button color={'grey'} icon={'car'} onClick={() => this.setState({show_vehicle_wheels: true})}/>}
					</Button.Group>
				</EasyFlex>
				{this.state.show_search && <WheelSearchModal
					onSelect={this.assign}
					open
					onClose={() => this.setState({show_search: false})}
					clientId={orderVehicle.client_id}
					vehicleId={orderVehicle.vehicle_id}
				/>}
				{this.state.show_editor && <Modal open centered={false} onClose={() => this.setState({show_editor: false})} closeIcon>
					<Modal.Header>Reifensatz einlagern</Modal.Header>
					<Modal.Content>
						<WheelEditor clientId={orderVehicle.client_id} vehicleId={orderVehicle.vehicle_id} onAfterChange={(inset) => this.setState({show_editor: false, inset})}/>
					</Modal.Content>
				</Modal>}
				{this.state.inset && <Modal open size={'tiny'} centered={false} onClose={() => this.setState({inset: null})}>
					<Modal.Content>
						Einlagerung <strong>{get(this.state.inset, 'ewo_tw.tireSetNumber', this.state.inset.wheel_id)}</strong> wurde erfolgreich durchgeführt.
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({inset: null})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{this.state.show_wheel_search && <WheelSearchModal
					onClose={() => this.setState({show_wheel_search: false})}
					closeOnSelect
					open
					vehicleId={orderVehicle.vehicle_id}
					clientId={orderVehicle.client_id}
					onSelect={(wheel) => {
						this.setState({wheel, show_wheel_search: false})
					}}
				/>}
				{this.state.wheel && <Modal
					open
					centered={false}
					onClose={() => this.setState({wheel: null})}
					size={'mini'}
				>
					<Modal.Content>
						<Form id={this.formId} onSubmit={this.assign}>
							<Form.Field>
								<label>Auslagerungskommentar</label>
								<Form.TextArea value={this.state.comment} onChange={(e, {value}) => this.setState({comment: value})}/>
							</Form.Field>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button type={'button'} onClick={() => this.setState({wheel: null})}><Trans i18nKey={'actions.cancel'}/></Button>
						<Button type={'submit'} form={this.formId} positive loadig={this.props.saving} disabled={this.props.saving}>Ok, auslagern</Button>
					</Modal.Actions>
				</Modal>}
				{this.state.show_client_wheels && <FSModal
					open
					centered={false}
					onClose={() => this.setState({show_client_wheels: false})}
				>
					<Modal.Header>Kundenreifen</Modal.Header>
					<Modal.Content>
						<ConnectedWheelListView
							onSelect={wheel => this.setState({wheel_detail: wheel})}
							clientId={orderVehicle.client_id}
							emptyRender={<Message info><p>Keine Reifen hinterlegt</p></Message>}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({show_client_wheels: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
				{this.state.show_vehicle_wheels && <FSModal
					open
					centered={false}
					onClose={() => this.setState({show_vehicle_wheels: false})}
				>
					<Modal.Header>Fahrzeugreifen</Modal.Header>
					<Modal.Content>
						<ConnectedWheelListView
							onSelect={wheel => this.setState({wheel_detail: wheel})}
							vehicleId={orderVehicle.vehicle_id}
							emptyRender={<Message info><p>Keine Reifen hinterlegt</p></Message>}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({show_vehicle_wheels: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
				{this.state.wheel_detail && <Modal
					open
					size={'small'}
					onClose={() => this.setState({wheel_detail: null})}
					centered={false}
				>
					<Modal.Content>
						<ConnectedWheelEditor wheel={this.state.wheel_detail}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({wheel_detail: null})}><Trans i18nKey={'actions.close'}/></Button>
						<Button icon={'download'} labelPosition={'left'} content={'Auslagern'} positive onClick={() => this.setState({wheel: this.state.wheel_detail, wheel_detail: null, show_client_wheels: false, show_vehicle_wheels: false})}/>
					</Modal.Actions>
				</Modal>}
			</React.Fragment>
		);
	}
}
WheelSelector = withModule(WheelSelector)
WheelSelector = connect(
	(state, props) => {
		const map = memoizeOne((map, id) => map[id] || null)
		return {
			orderVehicle: map(state.map.order2vehicles, props.order.order_vehicle_id)
		}
	}
)(WheelSelector)

class HasWheels extends React.Component {
	static propTypes = {
		wheelId: PropTypes.number.isRequired,
		wheel: PropTypes.object,
		onLoad: PropTypes.func,
		onNotification: PropTypes.func
	}
	static defaultProps = {
		onLoad: wheelDispatch_one,
		onNotification: dispatchSnack
	}
	state = {
		loading: false
	}
	
	componentDidMount() {
		this.loader(this.props.wheelId)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.loader(this.props.wheelId)
	}
	
	load = async () => {
		const {wheelId, onLoad, onNotification, wheel} = this.props
		if (wheel) return
		try {
			this.setState({loading: true})
			await onLoad(wheelId)
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	loader = memoizeOne(id => this.load())
	
	render() {
		if (!this.props.wheel) {
			return <CircleNotchLoader/>
		}
		return (
			<div>
				<h4>Zu verwendender Reifensatz</h4>
				<WheelEditor readOnly wheel={this.props.wheel}/>
			</div>
		);
	}
}
HasWheels = connect(
	(state, props) => {
		const map = memoizeOne((map, id) => map[id] || null)
		return {
			wheel: map(state.map.wheels, props.wheelId)
		}
	}
)(HasWheels)

export class OrderQuickWheel extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		onLoad: PropTypes.func
	}
	static defaultProps = {}
	state = {
		loading: false
	}
	
	componentDidMount() {
		this.load()
	}
	
	load = async () => {
		try {
			this.setState({loading: true})
			await this.props.onLoad()
		} catch (e) {
			console.error(e)
		} finally {
			this.setState({loading: false})
		}
	}
	
	render() {
		const {order} = this.props;
		if (!order) {
			return <CircleNotchLoader loading/>
		}
		return (
			<Segment basic loading={this.state.loading}>
				{order.wheel_id && <HasWheels wheelId={order.wheel_id}/>}
				{order.wheel_id && <Divider/>}
				<WheelSelector order={order}/>
			</Segment>
		);
	}
}

OrderQuickWheel = connect(
	(state, props) => {
		const map = memoizeOne((orders, id) => orders[id] || null)
		return {
			order: map(state.map.orders, props.order_id)
		}
	},
	(dispatch, props) => ({
		onLoad: () => dispatch(orderAction__collect([props.order_id], true))[0]
	})
)(OrderQuickWheel);