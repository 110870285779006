import * as React from "react";
import {workerAction__getList} from "../../../actions/workerActions";
import {trueNull} from "../../../Logic/extensions";
import {BaseModel, Worker} from "../../../models";
import {Dropdown} from "semantic-ui-react";
import {OrderDatePicker} from "../../../cointainer/intern/orders/OrderDatePickerV2";
import {EasyFlex} from "../../partials/ActionHeader";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import PropTypes from "prop-types";

const SelectHolder = () => (
	<Dropdown placeholder={"Mitarbeiter waehler"} options={[]} disabled fluid selection loading/>
);

export class OrderLaneSickness extends React.PureComponent {
	static propTypes = {
		onValid: PropTypes.func,
		onSelect: PropTypes.func
	};
	state = {
		worker_id: null,
		start: null,
		end: null
	};
	
	isValid = memoize(
		(state) => null !== state.worker_id && null !== state.start && null !== state.end && state.start < state.end
	);
	
	customState = (state) => this.setState(state, () => {
		let valid = this.isValid(this.state);
		this.props.onValid && this.props.onValid(valid);
		valid && this.props.onSelect && this.props.onSelect(this.state);
	});
	
	render() {
		const {worker_id, start, end} = this.state;
		return (
			<React.Fragment>
				<div>
					<BaseModel.ProviderList placeholder={<SelectHolder/>} list={[workerAction__getList]}>{(worker_ids) => {
						return trueNull(worker_ids) && <Worker.CollectProvider dynamic placeholder={<SelectHolder/>} allowEmpty ids={worker_ids}>{(workers) => {
							const visibleWorkers = workers.map(w => ({
								text: w.name,
								key: w.workers_id,
								value: w.workers_id,
								image: w.avatar
							}));
							return (
								<Dropdown
									placeholder={"Mitarbeiter waehlen"}
									fluid
									selection
									value={worker_id}
									options={visibleWorkers}
									onChange={(e, data) => this.customState({worker_id: data.value})}
								/>
							);
						}}</Worker.CollectProvider>
					}
					}</BaseModel.ProviderList>
					<div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
						<label>Von</label>
						<EasyFlex align={EasyFlex.align.CENTER}>
							<OrderDatePicker
								style={{marginTop: 0}}
								date={start}
								onChange={start => this.customState({start})}
							/>
						</EasyFlex>
					</div>
					
					<div style={{paddingLeft: 20, paddingRight: 20}}>
						<label>Bis</label>
						<EasyFlex align={EasyFlex.align.CENTER}>
							<OrderDatePicker
								style={{marginTop: 0}}
								date={end}
								onChange={end => this.customState({end})}
							/>
						</EasyFlex>
					</div>
				</div>
				{this.props.children}
			</React.Fragment>
		);
	}
}