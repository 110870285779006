import constants from "./Logic/constants";
import {applyMiddleware, createStore, compose} from "redux";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {createLogger} from "redux-logger";
// import {routerMiddleware} from "connected-react-router";
import {routerMiddleware} from "connected-react-router";
import history from "./Logic/history";
import AppReducer from "./reducers";

// const routerMiddleWare = routerMiddleware(history);
const logger = createLogger({
	collapsed: true,
	level: 'log'
});

const force = Boolean(process.env.REACT_APP_FORCE_DEBUG);

console.debug('Forcing debug is', force);

const middleWare = () => {
	if ( constants.PRODUCTION && !force) {
		console.log = console.debug = console.info = console.warn = function() {};
		return compose(applyMiddleware(routerMiddleware(history), thunkMiddleware));
	}
	return composeWithDevTools(applyMiddleware(routerMiddleware(history), thunkMiddleware, logger));
};

export const store = createStore(AppReducer(history), middleWare());
export const dispatch = store.dispatch
export const getState = store.getState