import PropTypes from 'prop-types';
import {SECTION} from "../Logic/constants";
import {invert, isFunction, values, get} from "lodash"
import {Worker} from "./Worker";
import {deepMemoize as memoize} from "../Logic/extensions";
import {connect} from "react-redux";
import {orderAction__getReleaseOf, orderAction__released} from "../actions/orderActions";
import {isLoading} from "../actions/loaderActions";
import {PROC_ORDER_RELEASE} from "../actions";
import withInit from "../Logic/withInit";

const ReleaseProvider = ({loading, order_id, release, releaseService, releaseStock, user, rights, allRights, placeholder, children}) => {
	if (!rights || !allRights || (order_id && !release)) {
		return placeholder;
	}
	if (isFunction(children)) {
		const mayService = rights.mayChange;
		const mayStock = allRights[SECTION.STOCK].mayChange;
		return children({mayService, mayStock, mayRelease: mayService || mayStock, user, releaseService, releaseStock, release, loading});
	}
	console.error("Children in `ReleaseProvider` must be of type `function`");
	return placeholder;
};
ReleaseProvider.propTypes = {
	placeholder: PropTypes.node,
	children: PropTypes.func,
	order_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ReleaseProvider.defaultProps = {
	placeholder: null,
	loading: false
};

export class User extends Worker {
	static iInstance = 0;
	constructor(user, t) {
		super(user, t);
		// this.user = user;
		// this.accessMap();
		User.iInstance++;
		// console.error('User instance:', User.iInstance);
		// this.isSelf = this.isSelf.bind(this);
		
	}
	static _i =0;
	static _(user) {
		return User._mem_load(user);
	}
	
	static _mem_load = memoize(
		user => {
			// User._i++;
			// console.debug('static _User_ instance:', User._i);
			return new User(user);
		}
	);
	
	rightMap = null;
	
	static propTypes = {
		workers_id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		first_name: PropTypes.string.isRequired,
		gender: PropTypes.string.isRequired,
		right_group_id: PropTypes.string.isRequired,
		house_id: PropTypes.number.isRequired,
		password: PropTypes.string,
		email: PropTypes.string
	};
	
	static noRights = {
		mayRead: false,
		mayChange: false,
		mayCreate: false,
		mayDelete: false,
		mayGrant: false
	};
	
	static rightNames = [
		'mayNone', 'mayRead', 'mayChange', 'mayCreate', 'mayDelete', 'mayGrant'
	];
	
	static RIGHT_TOP_DOWN = [
		'grant', 'delete', 'create', 'write', 'read'
	];
	
	static RIGHT = {
		NONE: 'none',
		READ: 'read',
		WRITE: 'write',
		CREATE: 'create',
		DELETE: 'delete',
		GRANT: 'grant'
	};
	
	static rightsList = [
		User.RIGHT.NONE,
		User.RIGHT.READ,
		User.RIGHT.WRITE,
		User.RIGHT.CREATE,
		User.RIGHT.DELETE,
		User.RIGHT.GRANT
	];
	
	
	static mapRights = {
		[User.RIGHT.NONE]: -1,
		[User.RIGHT.READ]: 0,
		[User.RIGHT.WRITE]: 1,
		[User.RIGHT.CREATE]: 2,
		[User.RIGHT.DELETE]: 3,
		[User.RIGHT.GRANT]: 4,
	};
	
	static RIGHT_MAP = {
		'mayNone': User.RIGHT.NONE,
		'mayRead': User.RIGHT.READ,
		'mayChange': User.RIGHT.WRITE,
		'mayCreate': User.RIGHT.CREATE,
		'mayDelete': User.RIGHT.DELETE,
		'mayGrant': User.RIGHT.GRANT
	};
	
	static getDummy() {
		return User.Dummy;
	}
	
	static get Dummy() {
		return {
			workers_id: -1,
			name: '',
			first_name: '',
			gender: 'f',
			right_group_id: 'useless',
			house_id: 0,
			password: '',
			email: ''
		};
	}
	
	getRights() {
		return this.rights;
	}
	
	getName() {
		return this.name;
	}
	
	isSupport() {
		return this.is_support;
	}
	
	getAccessTo = memoize((area, selection = [0, 1, 2, 3, 4]) => {
		// mapting User::rightNames with their corresponding rights
		// starting at an offset of 1 (leaving out Right::NONE)
		// Default selection if from read to delete
		return selection.map((s) => ({
			[User.rightNames[s + 1]]: this.hasAccessTo(area, User.rightsList[s + 1])
		})).reduce((a, b) => ({...a, ...b}));
	});
	
	get access_map() {
		return this._mem_access_map();
	};
	static i = 0;
	_mem_access_map = memoize(
		() => {
			const map = {};
			values(SECTION).forEach(section => {
				map[section] = this.accessTo(section); // this.getAccessTo(section, [0, 1, 2, 3, 4]);
			});
			return map;
		}
	);
	
	
	accessMap() {
		return this.access_map;
	}
	
	access = (section) => {
		return this.access_map[section];
	};
	
	isSelf(worker) {
		return this.is(worker);
	}
	
	accessTo = area => {
		if (!this.rights || !(area in this.rights)) {
			return User.noRights;
		}
		const r = invert(User.RIGHT_MAP);
		const right = {};
		let hit = false;
		const accessFound = this.rights[area].access || 'none';
		User.RIGHT_TOP_DOWN.forEach(accessType => {
			if (accessType === accessFound) {
				hit = true;
			}
			right[r[accessType]] = hit;
		});
		return right;
	};
	
	getAvatar = () => {
		return this.avatar;
	};
	
	getAvatarForBackground = () => {
		return this.avatar_background;
	};
	
	hasAccessTo = memoize((area, type = User.RIGHT.READ) => {
		if (!(area in this.rights)) {
			return false;
		}
		const level = this.rights[area].access;
		const map = User.mapRights;
		if (!(level in map)) {
			return false;
		}
		return map[type] <= map[level];
	});
	
	getData() {
		return this.origin;
	}
	
	static ReleaseProvider = connect(
		(state, props) => {
			const user = User._(state.user.data);
			const release = props.order_id ? get(state, ['orders', 'releases', props.order_id], null) : null
			return {
				user,
				rights: user ? user.access(SECTION.RELEASE) : User.noRights,
				allRights: user.accessMap(),
				release,
				loading: props.order_id ? isLoading(state, PROC_ORDER_RELEASE, props.order_id) : false
			};
		},
		(dispatch, props) => {
			return {
				init: () => {
					props.order_id && dispatch(orderAction__getReleaseOf(props.order_id));
				},
				releaseService: (release, onSuccess) => dispatch(orderAction__released(release, 'service', onSuccess)),
				releaseStock: (release, onSuccess) => dispatch(orderAction__released(release, 'stock', onSuccess)),
			};
		}
	)(withInit(ReleaseProvider));
}

// User.RIGHT_NONE = 'none';
// User.RIGHT_READ = 'read';
// User.RIGHT_WRITE = 'write';
// User.RIGHT_CREATE = 'create';
// User.RIGHT_DELETE = 'delete';
// User.RIGHT_GRANT = 'grant';

// User.rightsList = [
// 	User.RIGHT_NONE,
// 	User.RIGHT_READ,
// 	User.RIGHT_WRITE,
// 	User.RIGHT_CREATE,
// 	User.RIGHT_DELETE,
// 	User.RIGHT_GRANT
// ];

// User.noRights = {
// 	mayRead: false,
// 	mayChange: false,
// 	mayCreate: false,
// 	mayDelete: false,
// 	mayGrant: false
// };

// User.rightNames = [
// 	'mayNone', 'mayRead', 'mayChange', 'mayCreate', 'mayDelete', 'mayGrant'
// ];

// User.RIGHT_MAP = {
// 	'mayNone': 'none',
// 	'mayRead': 'read',
// 	'mayChange': 'write',
// 	'mayCreate': 'create',
// 	'mayDelete': 'delete',
// 	'mayGrant': 'grant'
// };

// User.RIGHT_TOP_DOWN = [
// 	'grant', 'delete', 'create', 'write', 'read'
// ];

// User.getDummy = () => ({
// 	workers_id: -1,
// 	name: '',
// 	first_name: '',
// 	gender: 'f',
// 	right_group_id: 'useless',
// 	house_id: 0,
// 	password: '',
// 	email: ''
// });

// User.propTypes = {
// 	workers_id: PropTypes.number.isRequired,
// 	name: PropTypes.string.isRequired,
// 	first_name: PropTypes.string.isRequired,
// 	gender: PropTypes.string.isRequired,
// 	right_group_id: PropTypes.string.isRequired,
// 	house_id: PropTypes.number.isRequired,
// 	password: PropTypes.string,
// 	email: PropTypes.string
// };

export default User;