
import {MENU_ACTION} from "./types";

const menu = visible => ({type: MENU_ACTION.SHOW, visible});

export const showMenu = visible => (dispatch, getState) => {
	if ( visible !== getState().menu) {
		dispatch(menu(visible));
	}
};
export const toggleMenu = () => ({type: MENU_ACTION.TOGGLE});

