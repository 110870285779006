import * as React from "react";
import ClientPopupInfo from "./ClientPopupInfo";
import PropTypes from "prop-types";
import {Client} from "../../../models";
import {MobileAwarePopup} from "../../partials/MiniComponents";

const ClientPopup = ({client, inverted, children, popupProps, ...props}) => (
	client ? <MobileAwarePopup trigger={children} inverted={inverted} {...props} content={<ClientPopupInfo client={client} inverted={inverted} {...popupProps}/>}/> : children
);
ClientPopup.propTypes = {
	client: Client.propTypes__both,
	inverted: PropTypes.bool,
	children: PropTypes.node,
	flowing: PropTypes.bool,
	position: PropTypes.string
};
ClientPopup.defaultProps = {
	inverted: true,
	flowing: true,
	position: "bottom left"
};

export default ClientPopup;
