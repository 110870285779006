import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import NavLink from "../partial/NavLink";
import {logout} from "../../actions/userActions";
import {showMenu} from "../../actions/menuActions";
import {IMAGE_URL, SECTION, SECTIONS} from "../../Logic/constants";
import {translate} from "react-i18next";
// import SVGInline from "react-svg-inline";
import User from "../../models/User";
import {SUB_SHOW_CALLBACK, SUB_SHOW_FEEDBACK} from "../../actions";
import {publish} from "../../Logic/PubSub";
import {Flex} from "../../components/partials/ActionHeader";
import {Icon, Label} from "semantic-ui-react";
import Ink from 'react-ink';
import cn from "classnames";
import {ReleaseBatch} from "./release/ReleaseBatch";
import {AssistProvder} from "../../components/intern/asistance/AssistProvider";
import {trueNull} from "../../Logic/extensions";
import {IconExit, IconHome} from "../../Logic/icons";
import {withModule} from "../../Tools/RightsProvider";

class ExternNavigationView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
		this.handleResize = this.handleResize.bind(this);
	}
	
	handleResize = (e, open) => {
		if (open === undefined) {
			open = this.props.open || false;
		}
		const {clientWidth} = window.document.body;
		if (clientWidth >= 1024) {
			this.setState({open: true});
		} else {
			this.setState({open});
		}
	};
	
	componentDidMount() {
		if (this.props.onRequestClose) {
			this.onRequestClose = this.props.onRequestClose;
			window.addEventListener('click', this.onRequestClose, false);
		}
		window.addEventListener('resize', this.handleResize, false);
		this.handleResize();
	}
	
	componentWillUnmount() {
		if (this.onRequestClose) {
			window.removeEventListener('click', this.onRequestClose, false);
		}
		window.removeEventListener('resize', this.handleResize, false);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open !== prevProps.open) {
			this.handleResize(null, this.props.open);
		}
	}
	
	logo = (house) => {
		if (house && house.logo) {
			return <img className={'logo'} src={`${IMAGE_URL}${house.logo.ident}.${house.logo.origin_extension}?${house.logo.updated_at}`} alt={house.name} style={{maxWidth: 110, maxHeight: 110, margin: '20px auto', borderRadius: 12}}/>
		}
		return null; //<SVGInline className="logo" svg={constants.LOADER}/>;
	};
	
	render() {
		const {t, user, onRequestLogout, onRequestClose, house, hasWork, modules} = this.props;
		const {open} = this.state;
		return (
			<nav className={open ? 'open' : ''} onClick={e => e.stopPropagation()} style={{display: 'flex', flexDirection: 'column'}}>
				{this.logo(house)}
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 100}}>
					<ul>
						<li style={{position: 'relative'}}>
							<NavLink style={{position: 'relative', overflow: 'hidden'}} activeClassName="active" to="/" exact={true}>
								<span className="nav-title">Home</span>
								<IconHome className={'nav-icon'}/>
							</NavLink>
						</li>
						{
							SECTIONS.map(section => {
								if (user && (user.hasAccessTo(section.id, User.RIGHT.READ) || (section.id === SECTION.RELEASE && user.hasAccessTo(SECTION.STOCK, User.RIGHT.READ)))) {
									if (section.id === SECTION.APPOINTMENTS) {
										return <li key={section.id}>
											<NavLink style={{position: 'relative', overflow: 'hidden'}} activeClassName="active" to={section.link}>
												{trueNull(hasWork) && <Icon name={'wrench'} className={"release-batch"} color={'red'}  style={{transform: 'scale(1.3)', transformOrigin: 'left top'}}/>}
												<span className="nav-title">{t(section.translate)}</span>
												<section.Icon className={'nav-icon'}/>
											</NavLink>
										</li>;
									} else if (section.id === SECTION.ASSISTANCE) {
										// return <li key={section.id} className={cn({highlight: section.id === 'appointments' && hasWork})}>
										// 	<AssistProvder>{(order, workable) =>
										// 		<React.Fragment>
										// 			<NavLink style={{position: 'relative', overflow: 'hidden'}} activeClassName="active" to={section.link}>
										// 				{order && <Icon name={'paperclip'} className={"release-batch"} color={'red'}/>}
										// 				{trueNull(!order && workable) && <Label circular color={"red"} className={'release-batch'} style={{transform: 'scale(0.8)', transformOrigin: 'left top'}}>{workable}</Label>}
										// 				<span className="nav-title">{t(section.translate)}</span>
										// 				<section.Icon className={'nav-icon'}/>
										// 			</NavLink>
										// 		</React.Fragment>
										// 	}</AssistProvder>
										// </li>;
										
										return (
											<AssistProvder key={section.id}>{(order, workable) =>
												!modules.assist && !order && !workable ? null :
												<li className={cn({highlight: section.id === 'appointments' && hasWork})}>
													<NavLink style={{position: 'relative', overflow: 'hidden'}} activeClassName="active" to={section.link}>
														{order && <Icon name={'paperclip'} className={"release-batch"} color={'red'}/>}
														{trueNull(!order && workable) && <Label circular color={"red"} className={'release-batch'} style={{transform: 'scale(0.8)', transformOrigin: 'left top'}}>{workable}</Label>}
														<span className="nav-title">{t(section.translate)}</span>
														<section.Icon className={'nav-icon'}/>
													</NavLink>
												</li>
											}</AssistProvder>
										);
									}
									return <li key={section.id} className={cn({highlight: section.id === 'appointments' && hasWork})}>
										
										<NavLink style={{position: 'relative', overflow: 'hidden'}} activeClassName="active" to={section.link}>
											{section.id === SECTION.RELEASE && <ReleaseBatch className={"release-batch"} style={{transform: 'scale(0.8)', transformOrigin: 'left top'}}/>}
											<span className="nav-title">{t(section.translate)}</span>
											<section.Icon className={'nav-icon'}/>
										</NavLink>
									</li>;
								}
								return null;
							})
						}
					
					</ul>
					<ul>
						<li>
							<Flex align={'center'} wrap valign={'center'} basis={10}>
								<Flex as={'a'}
								      className={'click pointer'}
								      style={{padding: "13px 0", overflow: 'hidden', position: 'relative', width: "100%", color: "white", boxSizing: "border-box", userSelect: "none"}}
								      align={'center'}
								      valign={'baseline'}
								      title={'Feedback?'}
								      onClick={() => publish(SUB_SHOW_FEEDBACK, true)}
								>
									<Icon name={'bug'} color={'blue'}/>
									<span className="nav-title">Feedback</span>
									<Ink/>
								</Flex>
								<Flex as={'a'}
								      className={'click pointer'}
								      style={{padding: "13px 0", overflow: 'hidden', position: 'relative', width: "100%", color: "white", boxSizing: "border-box", userSelect: "none"}}
								      align={'center'}
								      valign={'baseline'}
								      title={'Rückruf?'}
								      onClick={() => publish(SUB_SHOW_CALLBACK, true)}
								>
									<Icon name={'phone'} color={'blue'}/>
									<span className="nav-title">Rückruf</span>
									<Ink/>
								</Flex>
							</Flex>
						</li>
						{onRequestLogout && <li>
							<NavLink onAction={() => {
								onRequestLogout(house.link);
								onRequestClose()
							}}>
								<span className="nav-title">Logout</span>
								<IconExit  className={'nav-icon'}/>
							</NavLink>
						</li>}
					</ul>
				</div>
			</nav>
		);
	}
}

ExternNavigationView.propTypes = {
	user: PropTypes.instanceOf(User),
	onRequestClose: PropTypes.func.isRequired,
	onRequestLogout: PropTypes.func,
	hasWork: PropTypes.bool
};
ExternNavigationView.defaultProps = {
	hasWork: false
};

ExternNavigationView = withModule(ExternNavigationView);

const mapStateToProps = (state, props) => ({
	open: props.open || state.menu,
	rights: state.user.data.rights,
	user: User._(state.user.data),
	house: state.carhouse.list.find(h => h.house_id === state.user.data.house_id),
	hasWork: state.user.work.length > 0
});

const m2d = (dispatch) => ({
	onRequestClose: () => {
		dispatch(showMenu(false));
	},
	onRequestLogout: (house_id) => dispatch(logout(house_id))
});

let InternNavigationController = connect(mapStateToProps, m2d)(translate()(ExternNavigationView));

InternNavigationController.propTypes = {
	open: PropTypes.bool
};

export default InternNavigationController;