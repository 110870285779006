import React from 'react';

function Editable(WrappedComponent) {
	return class extends React.Component{
		render() {
			return <WrappedComponent {...this.props}/>
		}
	}
}

export default Editable;


