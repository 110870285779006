import React from 'react'
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {OrderQuickWheel} from "../../cointainer/intern/orders/quick";
import {WheelSelector} from "../intern/orders/finder/WheelSelector";

export class OrderWheelTest extends React.Component {
	render() {
		return (
			<Segment basic style={{paddingTop: 0}}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Hello</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<OrderQuickWheel order_id={713}/>
					<WheelSelector vehicleId={13881} clientId={10799} onSelect={() => {}}/>
				</div>
			</Segment>
		);
	}
}