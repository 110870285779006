import * as React from "react";
import PropTypes from "prop-types";
import {List, ListItem} from "material-ui";
import {Worker} from "../../../../models";
import {OnlineIcon} from "../../../../Tools/OnlineIcon";

export const WorkerListView = ({workers, onDetail, avatarSize, avatarStyle}) => (
	<List>
		{workers.map(worker => {
			// const worker = Worker._(w);
			return (
				<ListItem
					key={worker.id}
					leftAvatar={<div style={{
						width: avatarSize,
						height: avatarSize,
						borderRadius: "50%",
						backgroundImage: worker.avatar_background,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						...avatarStyle
					}}/>}
					onClick={() => onDetail(worker.id)}
					primaryText={<span>{worker.name} <OnlineIcon style={{transform: 'translateY(-3px)'}} size={'mini'} online={worker.logged_at}/></span>}
					secondaryText={worker.section}
				/>
			);
		})}
	</List>
);

WorkerListView.propTypes = {
	workers:  PropTypes.arrayOf(PropTypes.instanceOf(Worker)).isRequired,
	onDetail: PropTypes.func.isRequired,
	avatarSize: PropTypes.number,
	avatarStyle: PropTypes.object
};
WorkerListView.defaultProps = {
	avatarSize: 40
};