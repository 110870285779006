import * as React from 'react';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {loadWorkers, workerCall__getAll} from "../../../../actions/workerActions";
import {scheduleAction__getConsultant, scheduleCall__deleteConsultant, scheduleCall__getConsultant, scheduleCall__putConstultant} from "../../../../actions/scheduleActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {Worker} from "../../../../models";
import {sortAsc} from "ith-sort/source";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex} from "../../../partials/ActionHeader";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../partials";
import {Button, Checkbox, Dropdown, Header, Icon, Label, Modal, Segment} from "semantic-ui-react";
import BigCalendar from "react-big-calendar";
import moment from "../../../../Logic/Moment";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {TimeInput} from "../../../../Tools/DatePicker";
import {pipeDateFunc} from "../../orders/finder/Helpers";
import {NegativeSegment} from "../../../partials/Segments";
import {TextField} from "material-ui";


const WIDTH = 80;

const WEEKDAYS = [
	'Montag',
	'Dienstag',
	'Mittwoch',
	'Donnerstag',
	'Freitag',
	'Samstag',
	'Sonntag'
];

class CreateConsultantCalender extends StatyComponent {
	static propTypes = {
		onDismiss: PropTypes.func,
		worker: PropTypes.object.isRequired,
		onSave: PropTypes.func
	};
	static defaultProps = {
		onSave: params => scheduleCall__putConstultant(params)
	};
	
	state = {
		saving: false,
		start: null,
		end: null,
		selection: [false, false, false, false, false, false, false],
		overwrite: false
	};
	
	save = async() => {
		const {onSave, worker, onDismiss} = this.props;
		try {
			this.setState({saving: true});
			const {start,end, selection} = this.state;
			const result = await onSave({
				workers_id: worker.workers_id,
				start_point: moment(start).format('HH:mm'),
				end_point: moment(end).format('HH:mm'),
				weekdays: selection,
				overwrite: this.state.overwrite
			});
			onDismiss && onDismiss(result);
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({saving: false, overwrite: false});
		}
	};
	
	get isSet() {
		return this.state.start && this.state.end;
	}
	
	get valid() {
		const {start, end, selection} = this.state;
		if (start && end) {
			return end > start && selection.reduce((state, value) => state || value, false);
		}
		return false;
	}
	
	set = (index) => date => this.setState({[index]: date});
	select = (index) => (e, {checked}) => this.setState(state => ({
		...state,
		selection: state.selection.map((val, i) => i === index ? checked : val)
	}));
	overwrite = (e, {checked}) => this.setState({overwrite: checked});
	
	render() {
		const {start, end, selection, saving, overwrite} = this.state;
		const {worker} = this.props;
		const W = new Worker(worker);
		return (
			<Segment.Group>
				<Segment padded style={{textAlign: 'center', paddingTop: 40}}><Header as={'h3'}><Icon name={'user'}/> {W.name}</Header></Segment>
				<Segment>
					<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} wrap>
						{WEEKDAYS.map((day, index) =>
							<span key={index} style={{padding: '12px 12px'}}>
								<Checkbox label={day} onChange={this.select(index)} checked={selection[index]}/>
							</span>
						)}
					</EasyFlex>
				</Segment>
				
				<Segment>
					<Label color={'teal'} ribbon>Zeitspanne</Label>
					<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}>
						<TimeInput
							time={start}
							onProvide={pipeDateFunc(this.set('start'))}
						>
							<TextField floatingLabelText={'Startzeit'} style={{width: WIDTH}}/>
						</TimeInput>
						<TimeInput
							time={end}
							onProvide={pipeDateFunc(this.set('end'))}
						>
							<TextField floatingLabelText={'Endzeit'} style={{width: WIDTH}}/>
						</TimeInput>
						{/*<MaterialPicker*/}
						{/*	floating*/}
						{/*	placeholderText={'Startzeit'}*/}
						{/*	showTimeSelect*/}
						{/*	showTimeSelectOnly*/}
						{/*	timeIntervals={15}*/}
						{/*	timeCaption={'Zeit'}*/}
						{/*	dateFormat={'HH:mm'}*/}
						{/*	onChange={pipeDateFunc(this.set('start'))}*/}
						{/*	selected={start}*/}
						{/*/>*/}
						{/*<MaterialPicker*/}
						{/*	floating*/}
						{/*	placeholderText={'Endzeit'}*/}
						{/*	showTimeSelect*/}
						{/*	showTimeSelectOnly*/}
						{/*	timeIntervals={15}*/}
						{/*	timeCaption={'Zeit'}*/}
						{/*	dateFormat={'HH:mm'}*/}
						{/*	onChange={pipeDateFunc(this.set('end'))}*/}
						{/*	selected={end}*/}
						{/*/>*/}
					</EasyFlex>
				</Segment>
				{this.isSet && !this.valid && <NegativeSegment >
					<p>Ungültige Zeitspanne oder fehlende Tagwahl!</p>
				</NegativeSegment>}
				<Segment style={{textAlign: 'center'}}>
					<Checkbox label={'Bei Konflikt überschreiben'} checked={overwrite} onChange={this.overwrite} style={{marginRight: 12}}/>
					<Button positive loading={saving} disabled={!this.valid} onClick={this.save}>Speichern</Button>
				</Segment>
			</Segment.Group>
		);
	}
}

export class ConsultantCalendar extends StatyComponent {
	static propTypes = {
		workers: PropTypes.object,
		onLoadWorkers: PropTypes.func,
		onLoadSchedule: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onDeleteSchedule: PropTypes.func,
		schedule: PropTypes.array,
	};
	static defaultProps = {
		workers: {},
		onLoadWorkers: workerCall__getAll,
		onLoadSchedule: scheduleCall__getConsultant,
		onNotificationBad: message => dispatchSnack(message, 'alert'),
		onDeleteSchedule: id => scheduleCall__deleteConsultant(id),
		schedule: []
	};
	state = {
		loadingWorkers: false,
		loadingSchedule: false,
		deletingSchedule: false,
		schedule: null,
		worker: null,
		create: false,
		selected: null,
		onlyAssigned: false
	};
	
	componentDidMount() {
		super.componentDidMount();
		this.loadWorkers();
		this.loadSchedule();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateSchedule(this.props.schedule);
	}
	
	loadWorkers = async () => {
		const {onLoadWorkers, onNotificationBad} = this.props;
		try {
			this.setState({loadingWorkers: true});
			await onLoadWorkers();
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loadingWorkers: false});
		}
	};
	
	loadSchedule = async () => {
		const {onLoadSchedule, onNotificationBad} = this.props;
		try {
			this.setState({loadingSchedule: true});
			const schedule = await onLoadSchedule();
			this.updateSchedule(schedule);
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loadingSchedule: false});
		}
		
	};
	
	updateSchedule = memoizeOne(
		schedule => this.setState({schedule})
	);
	
	
	
	deleteSchedule = id => async () => {
		if (!id) {
			return;
		}
		const {onDeleteSchedule, onNotificationBad} = this.props;
		try {
			this.setState({deletingSchedule: true});
			const deleted = await onDeleteSchedule(id);
			this.setState(state => ({
				schedule: state.schedule.filter(s => s.consultant_schedule_id !== deleted.consultant_schedule_id),
				selected: null
			}));
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({deletingSchedule: false});
		}
	};
	
	handleAdding = (schedule) => {
		schedule && this.setState({schedule});
		this.handleCreate(false)();
	}
	handleCreate = (create) => () => this.setState({create, selected: null});
	handleWorker = (e, {value}) => this.setState({worker: value, selected: null});
	handleSelect = (schedule) => this.setState(state => ({
		selected: schedule ? schedule.consultant_schedule_id : null
	}));
	handleAssigned = (e, {checked}) => this.setState({onlyAssigned: checked, worker: null, selected: null});
	
	get calendarProps() {
		const {i18n, t} = this.props;
		const messages = i18n.getResource(i18n.language.match(/\w+/), 'translations', 'big-calendar.defs') || {};
		messages.showMore = count => t('big-calendar.showMore', {count});
		return {
			defaultDate: new Date(),
			messages,
			culture: 'de',
			defaultView: 'week',
			toolbar: false
		};
	}
	
	hasSlot = (id) => this.state.schedule && this.state.schedule.find(s => s.workers_id === id);
	
	getWorkers = memoizeOne(
		(workers, assigned) => {
			let list = [];
			for (let worker of Object.values(workers)) {
				if (worker.active && !worker.deleted) {
					if (assigned && !this.hasSlot(worker.workers_id)) {
						continue;
					}
					const W = new Worker(worker);
					list.push({
						key: worker.workers_id,
						value: worker.workers_id,
						text: W.name
					});
				}
			}
			return list.sort(sortAsc(w => w.text));
		}
	);
	
	buildEvents = memoizeOne(
		(worker, schedule) => {
			if (!worker) {
				return [];
			}
			let next = schedule.filter(s => s.workers_id === worker);
			next = next.map(s => ({
				...s,
				title: '',
				start: new Date(s.range[0]),
				end: new Date(s.range[1])
			}));
			return next;
		}
	);
	
	render() {
		const {workers} = this.props;
		const {worker, loadingSchedule, deletingSchedule, schedule, create, selected, onlyAssigned} = this.state;
		const workerList = this.getWorkers(workers, onlyAssigned);
		const events = this.buildEvents(worker, schedule);
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton loading={loadingSchedule}/>
						<ActionHeading>Serviceberater-Kalender</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{selected && <ConfirmButton icon negative content={<span><Icon name={'trash'}/> Löschen</span>}>
							<ConfirmButtonConsumer>{confirm =>
								<Button.Group>
									<Button disabled={deletingSchedule} basic color={'grey'} onClick={confirm.bind(undefined, false)}>Abbrechen</Button>
									<Button.Or/>
									<Button negative loading={deletingSchedule} onClick={this.deleteSchedule(selected)}>Löschen</Button>
								</Button.Group>
							}</ConfirmButtonConsumer>
						</ConfirmButton>}
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<Checkbox label={'Nur zugewiesen'} checked={onlyAssigned} onChange={this.handleAssigned} style={{marginRight: 8}}/>
						<Dropdown
							options={workerList}
							placeholder={'Mitarbeiter wählen'}
							selection
							value={worker}
							onChange={this.handleWorker}
						/>
						{worker && <Button icon={'plus'} basic style={{marginLeft: 8}} onClick={this.handleCreate(true)}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<BigCalendar
					{...this.calendarProps}
					events={events}
					// startAccessor={event => new Date(event.start_time)}
					// endAccessor={event => new Date(event.end_time)}
					// allDayAccessor={event => event.allday}
					// titleAccessor={this.titleAccessor}
					// eventPropGetter={this.eventPropGetter()}
					onSelectEvent={this.handleSelect}
					step={30}
					min={moment().startOf('d').add(6, 'h').toDate()}
					max={moment().startOf('d').add(20, 'h').toDate()}
					timeslots={2}
				/>
				<Modal open={create} centered={false} basic>
					<Modal.Header as={'h3'}>Eintrag erstellen</Modal.Header>
					<Modal.Content>
						{worker && <CreateConsultantCalender worker={workers[worker]} onDismiss={this.handleAdding}/>}
					</Modal.Content>
					<Modal.Actions>
						<Button inverted icon onClick={this.handleCreate(false)}><Icon name={'close'}/> Schließen</Button>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}
ConsultantCalendar = translate()(ConsultantCalendar);

export const ConnectedConsultantCalendar = connect(
	state => ({
		workers: state.map.workers,
		schedule: state.workers.consultant_schedules
	}),
	dispatch => ({
		onNotificationBad: () => {},
		onLoadWorkers: () => dispatch(loadWorkers()),
		onLoadSchedule: () => dispatch(scheduleAction__getConsultant())
	})
)(ConsultantCalendar);