import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

class Event extends React.Component {
	componentDidMount() {
		const {onKeyUp, onKeyPress, onKeyDown} = this.props;
		if ( onKeyUp ) {
			this.onKeyUp = e => onKeyUp(e, this.props.state);
			window.addEventListener('keyup',this.onKeyUp, false);
		}
		if ( onKeyPress ) {
			this.onKeyPress = e => onKeyPress(e, this.props.state);
			window.addEventListener('keypress',this.onKeyPress, false);
		}
		if ( onKeyDown ) {
			this.onKeyDown = e => onKeyDown(e, this.props.state);
			window.addEventListener('keydown',this.onKeyDown, false);
		}
	}
	
	componentWillUnmount() {
		const {onKeyUp, onKeyPress, onKeyDown} = this.props;
		if ( onKeyUp ) {
			window.removeEventListener('keyup', this.onKeyUp, false);
		}
		if ( onKeyPress ) {
			window.removeEventListener('keypress', this.onKeyPress, false);
		}
		if ( onKeyDown ) {
			window.removeEventListener('keydown', this.onKeyDown, false);
		}
	}
	
	render() {
		return null;
	}
}

const stateToProps = (state, props) => ({
	state: state
});

const dispatchToProps = (dispatch, props) => ({
	onKeyUp: (e, state) => props.onKeyUp && props.onKeyUp(e, dispatch, state),
	onKeyPress: (e, state) => props.onKeyPress && props.onKeyPress(e, dispatch, state),
	onKeyDown: (e, state) => props.onKeyDown && props.onKeyDown(e, dispatch, state)
});

let EventHandler = connect(stateToProps, dispatchToProps)(Event);

EventHandler.propTypes = {
	onKeyUp: PropTypes.func,
	onKeyDown: PropTypes.func,
	onKeyPress: PropTypes.func,
};

export default EventHandler;