import * as React from 'react';
import {Button, Icon, Label, Message, Modal, Popup, Segment, Table} from "semantic-ui-react";
import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {orderAction__getConflictDetail, orderAction__getConflicts, orderAction__resolveConflict, orderCall__getConflictDetails, orderCall__getConflicts, orderCall__relocateResource} from "../../../../actions/orderActions";
import {deepMemoize as memoizeOne, falseNull, trueNull} from "../../../../Logic/extensions";
import {FullscreenPortal} from "../../../../Tools/Dialog/Dialogs";
import {SECTION} from "../../../../Logic/constants";
import OrderQuickView from "../../../../cointainer/intern/orders/OrderQuickView";
import {connect} from "react-redux";
import {withRights} from "../../../../Tools";
import round from 'round-precision';
import {OrderService} from "../../../../models";
import {moment} from "../../../../Logic/Moment";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../partials";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {MobileAwarePopup} from "../../../partials/MiniComponents";
import {get} from 'lodash';

class RelocateSummary extends StatyComponent {
	static propTypes = {
		serviceId: PropTypes.number.isRequired,
		orderMap: PropTypes.object.isRequired,
		serviceMap: PropTypes.object.isRequired,
		resourceMap: PropTypes.object.isRequired,
		summary: PropTypes.object.isRequired,
		onResolve: PropTypes.func.isRequired,
		onDismiss: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onRelocateResource: PropTypes.func
	};
	static defaultProps = {
		onNotificationBad: message => dispatchSnack(message, 'alert'),
		onRelocateResource:orderCall__relocateResource,
	};
	
	state = {
		relocating: 0,
		relocated: {},
		resolving: false,
		detail: null
	}
	
	updateRelocated = (id, value) => this.setState(state => ({
		...state,
		relocated: {
			...state.relocated,
			[id]: value
		}
	}));
	
	relocate = (resourceId) => async() => {
		resourceId = Number(resourceId);
		const {onRelocateResource, onNotificationBad} = this.props;
		try {
			this.setState({relocating: resourceId});
			const result = await onRelocateResource(resourceId);
			this.updateRelocated(resourceId, result.ack);
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({relocating: 0});
		}
	};
	
	resolve = async () => {
		const {onResolve, onDismiss} = this.props;
		try {
			this.setState({resolving: true});
			await onResolve(this.props.serviceId);
			onDismiss && onDismiss();
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({resolving: false});
		}
	}
	
	setDetail = detail => () => this.setState({detail});
	
	render() {
		const {detail, resolving} = this.state;
		let {serviceId, orderMap, serviceMap, resourceMap, summary} = this.props;
		const originService = serviceMap[serviceId];
		const originOrder = orderMap[originService.order_id];
		return <Segment basic>
			<Table basic={'very'} celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Service</Table.HeaderCell>
						<Table.HeaderCell>Überlappungszeit</Table.HeaderCell>
						<Table.HeaderCell>% Anteil</Table.HeaderCell>
						<Table.HeaderCell>Std. Gesamt</Table.HeaderCell>
						<Table.HeaderCell>Letzter Zeitpunkt</Table.HeaderCell>
						<Table.HeaderCell>&nbsp;</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{Object.entries(summary).map(([rid, [, overLapDuration, selfPart, otherPart, selfTotal, otherTotal]]) => {
						const resource = resourceMap[rid];
						const service = serviceMap[resource.order_service_id];
						const order = orderMap[service.order_id];
						// const resolved = get(relocated, rid, null);
						// const isLoading = relocating === rid;
						return (
							<Table.Row key={rid}>
								<Table.Cell>
									{service.service_name}
								</Table.Cell>
								<Table.Cell>{round(overLapDuration / 3600, 1)} Std.</Table.Cell>
								<Table.Cell>{round(selfPart, 1)} % <Icon name={'arrows alternate horizontal'}/> {round(otherPart, 1)} %</Table.Cell>
								<Table.Cell>{round(selfTotal / 3600, 1)} Std. <Icon name={'arrows alternate horizontal'}/> {round(otherTotal / 3600, 1)} Std.</Table.Cell>
								<Table.Cell textAlign={'center'}>
									<div>{moment(originOrder.latest_point).format('LLL')}</div>
									<div><Icon name={'arrows alternate vertical'}/></div>
									<div>{moment(order.latest_point).format('LLL')}</div>
								</Table.Cell>
								<Table.Cell textAlign={'right'}>
									<Button.Group basic style={{marginRight: 5}}>
										<Button icon={'info'} onClick={this.setDetail(service.order_id)}/>
										<OrderService.Popup basic order_service={service} inverted on={['click']}><Button icon><strong style={{paddingLeft: 3, paddingRight: 3}}>S</strong></Button></OrderService.Popup>
										{/*<MobileAwarePopup basic inverted content={'Konflikt auflösen probieren'} trigger={
											<Button
												primary
												loading={isLoading}
												disabled={isLoading || resolved === false}
												color={resolved === false ? 'red' : null}
												icon={null === resolved ? 'random' : true === resolved ? 'thumbs up' : 'thumbs down'}
												onClick={this.relocate(rid)}
											/>
										}/>*/}
									</Button.Group>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.Cell colSpan={6} textAlign={'center'}>
							<ConfirmButton positive content={"Konflikt auflösen"}>
								<ConfirmButtonConsumer>{confirm =>
									<Button.Group>
										<Button disabled={resolving} basic color={'grey'} onClick={e => {
											// noinspection JSUnresolvedFunction
											e && e.stopPropagation();
											confirm(false);
										}}><Icon name={"close"} />Abbrechen</Button>
										<Button.Or/>
										<Button positive loading={resolving} onClick={this.resolve}>Auflösen</Button>
									</Button.Group>
								}</ConfirmButtonConsumer>
							</ConfirmButton>
						</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>
			<FullscreenPortal scrolling open={!!detail} bottomBar={Bar => <Bar><Button onClick={this.setDetail(null)}>Schließen</Button></Bar>}>
				{detail && <OrderQuickView order_id={detail} onRequestClose={this.setDetail(null)}/>}
			</FullscreenPortal>
		</Segment>
	}
}

RelocateSummary.propTypes = {
	serviceId: PropTypes.any,
	orderMap: PropTypes.any,
	serviceMap: PropTypes.any,
	resourceMap: PropTypes.any,
	summary: PropTypes.any
}

export class RelocateConflicts extends StatyComponent {
	static propTypes = {
		conflicts: PropTypes.arrayOf(PropTypes.number.isRequired),
		orderMap: PropTypes.object,
		serviceMap: PropTypes.object,
		resourceMap: PropTypes.object,
		onLoad: PropTypes.func,
		onDetail: PropTypes.func,
		allRights: PropTypes.object,
		opened: PropTypes.bool,
		onResolve: PropTypes.func,
		alwaysSymbol: PropTypes.bool
	};
	static defaultProps = {
		conflicts: [],
		orderMap: {},
		serviceMap: {},
		resourceMap: {},
		onLoad: () => orderCall__getConflicts(),
		onDetail: (serviceId) => orderCall__getConflictDetails(serviceId),
		onResolve: () => {},
		allRights: {},
		opened: false
	};
	
	state = {
		loading: false,
		loading_detail: 0,
		resolving: 0,
		open: false,
		order: null,
		detail: 0,
		summary: null
	};
	
	componentDidMount() {
		super.componentDidMount();
		// noinspection JSIgnoredPromiseFromCall
		this.fetch();
	}
	
	
	
	fetch = async () => {
		const {onLoad, opened} = this.props;
		try {
			this.setState({loading: true});
			const response = onLoad();
			const list = response.conflicts || response;
			opened && list.length && this.setState({open: true});
		} catch (e) {
		
		} finally {
			this.setState({loading: false});
		}
	};
	
	
	
	detail = serviceId => async() => {
		const {onDetail} = this.props;
		try {
			this.setState({loading_detail: serviceId});
			const response = await onDetail(serviceId);
			if (response) {
				// noinspection JSUnresolvedVariable
				const summary = response.conflict_relation || response;
				this.setState({detail: serviceId, summary});
			}
			
		} catch (e) {
		
		} finally {
			this.setState({loading_detail: 0});
		}
	}
	
	resolve = serviceId => async() => {
		const {onResolve} = this.props;
		try {
			this.setState({resolving: serviceId});
			await onResolve(serviceId);
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({revolving: 0});
		}
	};
	
	setOpen = (open = null) => () => this.setState(state => ({
		open: null === open ? !state.open : open
	}));
	
	setOrder = (order) => () => this.setState({order});
	
	getResources = memoizeOne(
		(map, id) => {
			let hash = {};
			for(const [key, resource] of Object.entries(map)) {
				if (resource.order_service_id === id && !resource.finished_at) {
					hash[key] = resource;
				}
			}
			return Object.values(hash);
		}
	);
	
	getHighestGrade = memoizeOne(
		list => {
			const found = list.find(r => r.relocated === 'FAILED');
			if (found) return found;
			return list.find(r => r.relocated === 'FORCED');
		}
	);
	
	hideDetail = () => this.setState({summary: null, detail: 0})
	
	sortedConflicts = memoizeOne((list, serviceMap, orderMap, resourceMap) => {
		let conflicts = list.map(sid => {
			const service = serviceMap[sid];
			const order = orderMap[service.order_id];
			const resources = this.getResources(resourceMap, sid);
			const grades = this.getHighestGrade(resources);
			return {
				sid,
				service,
				order,
				resources,
				grades
			}
		})
		return conflicts.sort((left, right) => {
			const cmp = get(left, 'order.order_id', 0) - get(right, 'order.order_id', 0)
			if (!cmp) {
				return get(left, 'sid', 0) - get(right, 'sid', 0);
			}
			return cmp;
		})
	})
	
	render() {
		const {open, order, loading, loading_detail, summary, detail} = this.state;
		const {conflicts, serviceMap, orderMap, resourceMap, allRights, onResolve, alwaysSymbol} = this.props;
		const mayShow = allRights[SECTION.MASTER_DATA].mayChange;// || allRights[SECTION.APPOINTMENTS].mayRead;
		if (!mayShow){
			return null;
		}
		const sortedConflicts = this.sortedConflicts(conflicts, serviceMap, orderMap, resourceMap);
		return (
			<React.Fragment>
				{trueNull(alwaysSymbol || conflicts.length) && <MobileAwarePopup
					content={conflicts.length ? 'Terminkonflikte müssen gelöst werden!' : 'Keine Terminkonflikte'}
					trigger={<Button loading={loading} icon={'bell'} inverted negative={!!conflicts.length}
					                 onClick={this.setOpen(true)}/>}
				/>}
				<Modal open={open} centered={false} size={'large'}>
					<Modal.Header as={'h3'}>Terminkonflikte</Modal.Header>
					<Modal.Content scrolling>
						{falseNull(conflicts.length) && <Message info><p style={{textAlign: 'center'}}>Keine Konflikte</p></Message>}
						{trueNull(conflicts.length) && <Table basic={'very'}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Auftragsnummer</Table.HeaderCell>
									<Table.HeaderCell>Servicename</Table.HeaderCell>
									<Table.HeaderCell textAlign={'center'}>Konfliktgrad</Table.HeaderCell>
									<Table.HeaderCell>&nbsp;</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{sortedConflicts.map(data => {
									const {sid, service, order, grades} = data;
									// const service = serviceMap[sid];
									// const order = orderMap[service.order_id];
									// const resources = this.getResources(resourceMap, sid);
									// const grades = this.getHighestGrade(resources);
									if (!grades) {
										return null;
									}
									return (
										<React.Fragment key={sid}>
											<Table.Row positive={detail === sid}>
												<Table.Cell>{order.order_id}</Table.Cell>
												<Table.Cell>
													<span>{service.service_name}</span>
												</Table.Cell>
												<Table.Cell textAlign={'center'}>
													<Popup
														content={grades.relocated === 'FAILED' ? 'Nicht realisierbar' : 'Es gibt Überschneidungen mit anderen Terminen'}
														trigger={<Label circular empty
														                color={grades.relocated === 'FAILED' ? 'red' : 'orange'}/>}
														inverted
														position={'top center'}
													/>
												
												</Table.Cell>
												<Table.Cell textAlign={'right'}>
													<Button.Group basic>
														<Button icon={'info'} onClick={this.setOrder(order.order_id)}/>
														<OrderService.Popup basic order_service={service} inverted
														                    on={['click']}><Button icon><strong style={{
															paddingLeft: 3,
															paddingRight: 3
														}}>S</strong></Button></OrderService.Popup>
														{grades.relocated !== 'FAILED' && <Button
															// onClick={detail === sid ? () => this.setState({detail: 0}) : this.detail(sid)}
															onClick={this.detail(sid)}
															loading={loading_detail === sid}>{detail === sid ? 'Verstecken' : 'Anzeigen'}</Button>}
													</Button.Group>
													{/*{grades.relocated === 'FAILED' && <ConfirmButton style={{marginLeft: 8}} positive content={"Konflikt auflösen"}>
														<ConfirmButtonConsumer>{confirm =>
															<Button.Group style={{marginLeft: 8}}>
																<Button disabled={resolving} basic color={'grey'} onClick={e => {
																	e && e.stopPropagation();
																	confirm(false);
																}}><Icon name={"close"} />Abbrechen</Button>
																<Button.Or/>
																<Button positive loading={resolving} onClick={this.resolve(sid)}>Auflösen</Button>
															</Button.Group>
														}</ConfirmButtonConsumer>
													</ConfirmButton>}*/}
												</Table.Cell>
											</Table.Row>
											{/*{detail === sid && <Table.Row color={'grey'}>
												<Table.Cell colSpan={4}>
													<RelocateSummary onDismiss={() => this.setState({detail: 0})}
													                 onResolve={onResolve} orderMap={orderMap}
													                 serviceId={sid} serviceMap={serviceMap}
													                 resourceMap={resourceMap} summary={summary}/>
												</Table.Cell>
											</Table.Row>}*/}
										</React.Fragment>
									);
								})}
							</Table.Body>
						</Table>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setOpen(false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>
				<FullscreenPortal scrolling open={!!order} bottomBar={Bar => <Bar><Button onClick={this.setOrder(null)}>Schließen</Button></Bar>}>
					{order && <OrderQuickView order_id={order} onRequestClose={this.setOpen(null)}/>}
				</FullscreenPortal>
				<Modal open={null !== summary} centered={false}>
					<Modal.Header>{get(serviceMap, [detail, 'service_name'], 'Service')}</Modal.Header>
					<Modal.Content>
						<RelocateSummary onDismiss={this.hideDetail}
						                 onResolve={onResolve} orderMap={orderMap}
						                 serviceId={detail} serviceMap={serviceMap}
						                 resourceMap={resourceMap} summary={summary}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.hideDetail}>Schließen</Button>
					</Modal.Actions>
				</Modal>
			</React.Fragment>
		);
	}
}

RelocateConflicts = withRights(SECTION.ORDERS, RelocateConflicts);

export const ConnectedRelocateConflicts = connect(
	state => ({
		orderMap: state.map.orders,
		serviceMap: state.map.order2services,
		resourceMap: state.map.order2service2resources,
		conflicts: state.orders.conflicts
	}),
	dispatch => ({
		onLoad: () => dispatch(orderAction__getConflicts()),
		onDetail: serviceId => dispatch(orderAction__getConflictDetail(serviceId)),
		onResolve: serviceId => dispatch(orderAction__resolveConflict(serviceId))
	})
)(RelocateConflicts);
