import {isOnline} from "../../../actions/userActions";
import MasterDataServiceView from "../../../components/intern/master-data/MasterDataServiceView";
import {connect} from "react-redux";
import {kfzAction__fetchServices} from "../../../actions/kfzActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_KFZ_FETCH_SERVICES} from "../../../actions";
import {values} from '../../../Logic/extensions';
import {SECTION} from "../../../Logic/constants";
import User from "../../../models/User";
import {routeState__noAccess, routeState__push} from "../../../actions/routeActions";
import withInit from "../../../Logic/withInit";

const m2s = (state, props) => {
	const user = User._(state.user.data);
	return ({
		fetchingService: isLoading(state, PROC_KFZ_FETCH_SERVICES),
		services: values(state.kfz.service.list),
		...user.getAccessTo(SECTION.MASTER_DATA, [0,1,2,3])
	});
};

const m2d = (dispatch, props) => {
	// dispatch(isOnline(() => {
	// 	// load services...
	// 	dispatch(kfzAction__fetchServices());
	// }));
	return {
		init: () => dispatch(isOnline(() => {
			// load services...
			dispatch(kfzAction__fetchServices());
		})),
		noAccess: () => dispatch(routeState__noAccess()),
		onBack: () => dispatch(routeState__push('/master-data')),
		onSelect: (service) => {
			const service_id = service.service_id || service;
			dispatch(routeState__push(`/master-data/services/${service_id}`))
		}
	};
};

export default connect(m2s, m2d)(withInit(MasterDataServiceView));