import * as React from "react";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {IconButton, Menu, MenuItem, Paper} from "material-ui";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {IconAssignment, IconAssignmentPerson, IconContactCalendar, IconDelete, IconGroup, IconHouseClosed, IconMore, IconPersonAdd, IconRefresh} from "./icons";
import cn from "classnames";
import {isFunction} from "lodash";

/**
 *
 * @param {String} value
 * @return {Object}
 */
const calcPosition = (value) => {
	const [h, v] = value.split(' ')
	return {
		[h]: 0,
		[v]: 0
	}
}

export let IconMenu = ({children, closeOnClick, zDepth, closeOnEscape, onClick, icon, position, style, padded, buttonProps, wrapperClassname, menuWrap, expose, ...props}) => {
	const [show, setShow] = useState(false)
	const [anchor, setAnchor] = useState(null)
	const posStyle = calcPosition(position)
	const close = () => {
		console.debug('CLOSING MENU TRIGGERTr')
		setShow(false)
	}
	const keyHandler = e => {
		if (closeOnEscape && e.key === 'Escape') {
			e.stopPropagation()
			setShow(false)
		}
	}
	const clickHandler = e => {
		if (anchor && !anchor.contains(e.target)) {
			setShow(false)
		}
	}
	useEffect(() => {
		window.document.addEventListener('click', clickHandler)
		return () => {
			window.document.removeEventListener('click', clickHandler)
		}
	}, [closeOnClick, anchor])
	useEffect(() => {
		window.addEventListener('keyup', keyHandler)
		return () => {
			window.removeEventListener('keyup', keyHandler)
		}
	})
	useEffect(() => {
		expose && expose(() => setShow(!show), setShow)
		return () => {
			expose && expose(null)
		}
	}, [expose, show])
	return <div className={wrapperClassname} style={{display: 'inline-block', width: 48, height: 48, position: 'relative'}} onClick={(e) => {
		e.stopPropagation()
		closeOnClick && setShow(false)
		onClick && onClick(e)
	}}>
		<IconButton {...buttonProps} onClick={e => {
			e.stopPropagation()
			setAnchor(e.currentTarget.parentNode)
			setShow(true)
		}}>
			{icon}
		</IconButton>
		{show && <Paper zDepth={zDepth} style={{position: 'absolute', ...posStyle, minHeight: 48, minWidth: 48, padding: padded ? 12 : null, zIndex: 1002, ...style}} {...props}>
			{menuWrap ? <Menu className={'mini-menu'}>{isFunction(children) ? children(close) : children }</Menu> : isFunction(children) ? children(close) : children}
			{/*{isFunction(children) ? children(close) : children }*/}
		</Paper>}
	</div>
}
IconMenu.propTypes = {
	closeOnClick: PropTypes.bool,
	padded: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	zDepth: PropTypes.number,
	icon: PropTypes.node,
	expose: PropTypes.func,
	position: PropTypes.oneOf(['top left', 'top right', 'bottom left', 'bottom right']),
	menuWrap: PropTypes.bool
}
IconMenu.defaultProps = {
	closeOnClick: true,
	padded: false,
	closeOnEscape: true,
	zDepth: 4,
	icon: <IconMore/>,
	position: 'top right',
	menuWrap: true
}


export const MiniMenu = ({children, ...props}) => (
	<IconMenu
		{...props}
	>
		{children}
	</IconMenu>
);
MiniMenu.propTypes = {
	closeOnClick: PropTypes.bool,
	padded: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	zDepth: PropTypes.number,
	icon: PropTypes.node,
	position: PropTypes.oneOf(['top left', 'top right', 'bottom left', 'bottom right'])
}

const _MiniMenuItem = (Component) => ({className, link, onClick, onAfterClick, push, ...props}) => (
	<Component
		className={cn("mini-menu-item", className)}
		onClick={link ? push.bind(null, link) : () => {
			onClick();
			onAfterClick()
		}}
		{...props}
	/>
);
export const MiniMenuItem = connect(
	null,
	dispatch => ({push: (to) => dispatch(push(to))})
)(_MiniMenuItem(MenuItem));

MiniMenuItem.propTypes = {
	...MenuItem.propTypes,
	link: PropTypes.string,
	onAfterClick: PropTypes.func
};
MiniMenuItem.defaultProps = {
	...MenuItem.defaultProps,
	onAfterClick: () => {
	}
};

const _MenuLink = ({link, push,  ...props}) => {
	return <MenuItem {...props}/>
};


// export const MenuLink = _MenuLink
// export const MenuLink = withRouter(_MenuLink)




export const MenuLink = connect(
	null,
	(dispatch, props) => {
		const pushTo = (link) => dispatch(push(link));
		return {
			push: pushTo,
			onClick: props.onClick || pushTo.bind(null, props.link)
		};
	}
)(_MenuLink);
MenuLink.propTypes = {
	// ...MenuItem.defaultProps,
	link: PropTypes.string,
	primaryText: PropTypes.node,
	secondaryText: PropTypes.node,
	leftIcon: PropTypes.element
};


export const MenuDelete = ({leftIcon, ...props}) => (
	<MenuItem leftIcon={leftIcon || <IconDelete/>} {...props}/>
);

MenuDelete.propTypes = {
	...MenuItem.propTypes
};

export const ResourceLink = ({targetOrigin, insetChildren, focusState, disabled, anchorOrigin, checked, desktop, ...props}) => (
	<MenuLink primaryText={'Ressourcen zuweisen'} link={'/master-data/resources/assign'}
	          leftIcon={<IconAssignment/>} {...props}/>);
ResourceLink.propTypes = {
	...MenuLink.propTypes
};
export const ResourceCalendarLink = ({workers_id, ...props}) => (
	<ResourceLink link={`/master-data/resources/${workers_id}`} {...props}/>
);
ResourceCalendarLink.propTypes = {
	...ResourceLink.propTypes,
	workers_id: PropTypes.number.isRequired
};
export const HouseClosedLink = (props) => (
	<MenuLink primaryText={'Schließzeiten'} link={'/master-data/closed'} leftIcon={<IconHouseClosed/>} {...props}/>);
HouseClosedLink.propTypes = {
	...MenuLink.propTypes
};
export const WorkerCreateLink = (props) => (
	<MenuLink primaryText={'neuen MitarbeiterIn anlegen'} link={'/workers/create'}
	          leftIcon={<IconPersonAdd/>} {...props}/>);
WorkerCreateLink.propTypes = {
	...MenuLink.propTypes
};
export const ResourceEditLink = (props) => (
	<MenuLink primaryText={'Ressourcen bearbeiten'} link={'/master-data/resources/view'}
	          leftIcon={<IconGroup/>} {...props}/>);
ResourceEditLink.propTypes = {
	...MenuLink.propTypes
};
export const MenuRefreh = (props) => (
	<MenuItem primaryText={'Inhalt aktualisieren'} leftIcon={<IconRefresh/>}  {...props}/>);
MenuRefreh.propTypes = {
	...MenuItem.propTypes
};
export const WorkerDetailLink = ({workers_id, ...props}) => (
	<MenuLink
		primaryText={'Mitarbeiterdetails'}
		link={`/workers/detail/${workers_id}`}
		leftIcon={<IconAssignmentPerson/>}
		{...props}
	/>);
WorkerDetailLink.propTypes = {
	...MenuLink.propTypes,
	workers_id: PropTypes.number.isRequired
};
export const WorkersCalendarLink = ({workers_id, ...props}) => (
	<MenuLink
		primaryText={'Mitarbeiterkalender'}
		link={workers_id ? `/workers/calendar/${workers_id}` : '/workers/calendar'}
		leftIcon={<IconContactCalendar/>}
		{...props}
	/>
);
WorkersCalendarLink.propTypes = {
	...MenuLink.propTypes,
	workers_id: PropTypes.number
};

export const ConsultantCalendarLink = (props) => (
	<MenuLink
		primaryText={'Serviceberater-Kalender'}
		link={'/master-data/resources/consultant-calendar'}
		leftIcon={<IconGroup/>}
		{...props}
	/>
);