// try {
// 	require('babel-polyfill');
// } catch(e) {
// 	// console.info('Babel polyfill could not be loaded...', e);
// }
import str from 'lodash/isString';
import 'core-js/fn/array/includes';

var truish = function truish(what) {
  return ['asc', 'ascending', false].includes(str(what) ? what.toLowerCase() : what);
};

var boolValue = function boolValue(value) {
  return Boolean(value) ? 1 : 0;
};

var sortNumAsc = function sortNumAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (a, b) {
    return collector(a) - collector(b);
  };
};

var sortNumDesc = function sortNumDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (b, a) {
    return collector(a) - collector(b);
  };
};

var sortNum = function sortNum() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortNumAsc(collector) : sortNumDesc(collector);
  };
};

var sortBoolAsc = function sortBoolAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return sortNumAsc(function (data) {
    return boolValue(collector(data));
  });
};

var sortBoolDesc = function sortBoolDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return sortNumDesc(function (data) {
    return boolValue(collector(data));
  });
};

var sortBool = function sortBool() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortBoolAsc(collector) : sortBoolDesc(collector);
  };
};

var sortAsc = function sortAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (a, b) {
    return collector(a).toLowerCase().localeCompare(collector(b).toLowerCase());
  };
};

var sortDesc = function sortDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (b, a) {
    return collector(a).toLowerCase().localeCompare(collector(b).toLowerCase());
  };
};

var sort = function sort() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortAsc(collector) : sortDesc(collector);
  };
};

var sortDefault = function sortDefault() {
  return 0;
};

export { sortNumAsc, sortNumDesc, sortNum, sortBoolAsc, sortBoolDesc, sortBool, sortAsc, sortDesc, sortDefault, sort };