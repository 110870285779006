import * as React from "react";
import PropTypes from "prop-types";
import {EasyFlex, Flex} from "../../partials/ActionHeader";
import {translate} from "react-i18next";
import {Icon} from "semantic-ui-react";
import {FlatButton, Table, TableBody, TableRow, TableRowColumn} from "material-ui";
import round from "round-precision";

const Summary = ({list=[], onChange}) => {
	const length = list.length;
	// noinspection JSUnresolvedVariable
	list = list.reduce((given, current) => ({
		hours_of_work: given.hours_of_work + current.hours_of_work,
		price: given.price + current.extra.complete_price
	}), {hours_of_work: 0, price: 0});
	onChange(list);
	if ( length < 2 ) return null;
	return (
			<TableRow style={{backgroundColor: '#ddd'}}>
				<TableRowColumn>Gesamt</TableRowColumn>
				<TableRowColumn>{round(list.hours_of_work, 1)} Stunden</TableRowColumn>
				{/*<TableRowColumn>{round(list.price, 2)} €</TableRowColumn>*/}
			</TableRow>
	);
};

// noinspection JSUnresolvedVariable
const ExtraHours = ({data}) => data.extra.resource_hours_of_work === data.hours_of_work ? null : (
	<div><small>({round(data.extra.resource_hours_of_work, 1)} Stunden)</small></div>
);

const ListBody = ({serviceMap, list, onChange}) => (
	<Table selectable={false} bordered={false}>
		<TableBody selectable={false} displayRowCheckbox={false}>
			{list.map(data => {
				const service = data.custom ? data : (serviceMap[data.service_id] || {title: data.service_id});
				// let price = round(data.extra.complete_price, 2);
				return (
					<TableRow key={`service-detail-${data.service_id}`} selectable={false} style={{borderBottom: 'none'}}>
						<TableRowColumn>{service.title}</TableRowColumn>
						<TableRowColumn>{round(data.hours_of_work, 1)} Stunden<ExtraHours data={data}/></TableRowColumn>
						{/*<TableRowColumn>{price} €</TableRowColumn>*/}
					</TableRow>
				);
			})}
			<Summary list={list} onChange={onChange}/>
		</TableBody>
	</Table>
);

// const TILE_WIDTH = 150;
// const TILE_RESPONSIVE = 320;
// const TILE_MARGIN = 20;
// const GridBody = ({serviceMap, list, onChange}) => (
// 	<div style={{height: '100%'}}>
// 		<SizeMe>{({size: screen}) =>
// 			<StackGrid duration={0} columnWidth={screen.width < TILE_RESPONSIVE ? '100%' : TILE_WIDTH} gutterHeight={TILE_MARGIN} gutterWidth={screen.width < TILE_RESPONSIVE ? 0 : TILE_MARGIN} monitorImagesLoaded>
// 				{list.map(data => {
// 						const service = data.custom ? data : (serviceMap[data.service_id] || {title: data.service_id});
// 						let bg = service.logo ? logo_service(service.logo) : logo_service_default();
// 						let price = round(data.extra.complete_price, 2);
// 						return <Card key={`service-detail-${data.service_id}`} className={'pointer service-grid'}>
// 							<CardMedia
// 								className={"service-card-media"}
// 								mediaStyle={{overflow: 'hidden', height: screen.width < TILE_RESPONSIVE ? screen.width : TILE_WIDTH, position: 'relative'}}
// 								style={{backgroundImage: `url('${bg}')`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeast"}}
// 								overlay={<CardTitle style={{marginBottom: 8}} className={"service-card-overlay"} title={service.title}/>}
// 							>
// 								{/*<img src={this.logo(service)} alt={service.title} />*/}
// 							</CardMedia>
// 							<Ink radius={400}/>
// 						</Card>;
// 					}
// 				)}
// 			</StackGrid>
// 		}</SizeMe>
// 		<Summary list={list} onChange={onChange}/>
// 	</div>
// );

class OrderServiceDetailView extends React.PureComponent {
	static propTypes = {
		loading: PropTypes.bool,
		onRequestPrices: PropTypes.func.isRequired,
		list: PropTypes.array,
		error: PropTypes.string,
		serviceMap: PropTypes.object,
		onChange: PropTypes.func,
	};
	static defaultProps = {
		loading: false,
		onRequestPrices: () => alert('OrderServiceDetailView::onRequestPrices() is not implemented yet!'),
		error: null,
		list: [],
		serviceMap: {},
		onChange: () => {}
	};
	render() {
		const {serviceMap, onChange, loading, onRequestPrices, list, error, t, tReady, i18n, ...props} = this.props;
		return (
			<EasyFlex {...props} direction={EasyFlex.direction.COLUMN}>
				<Flex align={"flex-end"} valign={"center"}>{loading && <Icon name={"spinner"} loading/>} <FlatButton secondary onClick={onRequestPrices} disabled={loading}>Aktualisieren</FlatButton></Flex>
				{error && <pre>{error}</pre>}
				<ListBody onChange={onChange} list={list} serviceMap={serviceMap}/>
			</EasyFlex>
		);
	}
}

OrderServiceDetailView = translate()(OrderServiceDetailView);

export default OrderServiceDetailView;