import {setLoader, unsetLoader} from "./loaderActions";
import {addError} from "./errorActions";
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {isCached} from "ith-redux-helpers";
import {FREEDAY_ACTION} from "./types";
import {
	CACHE_FREEDAYS,
	CACHE_FREEDAYS_WORKERS,
	CACHE_TIME_FREEDAYS,
	PROC_FREEDAYS,
	PROC_FREEDAYS_WORKERS
} from "./index";
import {isFunction} from "lodash";


export const freedaysState__set = (list) => ({type: FREEDAY_ACTION.LOAD, list});
export const freedaysState__remove = id => ({type: FREEDAY_ACTION.REMOVE_BY_ID, id});
export const freedaysState__setWorkers = list => ({type: FREEDAY_ACTION.LOAD_WORKERS, list});
export const freedaysState__addWorkers = freeday => ({type: FREEDAY_ACTION.PUT_WORKERS, freeday});
export const freedaysState__updateWorkers = freeday => ({type: FREEDAY_ACTION.UPDATE_WORKERS, freeday});
export const freedaysState__removeWorkers = id => ({type: FREEDAY_ACTION.REMOVE_WORKERS, id});


export const resetFreedays = () =>
	dispatch => dispatch(freedaysState__set(null));

let fetchQueue = [];
export const freedaysAction__fetch = (force = false, onSuccess) =>
	async (dispatch, getState) => {
		if (!force && isCached(getState().freedays.cache, CACHE_FREEDAYS)(CACHE_TIME_FREEDAYS)) {
			isFunction(onSuccess) && onSuccess(getState().freedays.list);
			return;
		}
		fetchQueue.push(onSuccess);
		if ( fetchQueue.length > 1 ) {
			return;
		}
		try {
			dispatch(setLoader(PROC_FREEDAYS));
			const result = await GET('/freedays');
			dispatch(freedaysState__set(result.list));
			for (const callable of fetchQueue) {
				isFunction(callable) && callable(result);
			}
			fetchQueue = [];
		} catch (e) {
			fetchQueue = [];
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS));
		}
	};
export const freedaysAction__remove = (delete_ids, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_FREEDAYS));
			const result = await DELETE('/freedays', {delete_ids});
			dispatch(freedaysState__set(result.list));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS));
		}
	};

export const freedaysCall__create = data => PUT('/freedays', data);
export const freedaysAction__create = (data, onSuccess, throws = false) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_FREEDAYS));
			const result = await freedaysCall__create(data);
			if (!("ack" in result) || result.ack === false) {
				dispatch(freedaysState__set(result.list));
				isFunction(onSuccess) && onSuccess(result);
			}
			return result;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS));
		}
	};
let workerQueue = [];
export const freedaysAction__fetchWorkers = (force=false, onSuccess) =>
	async(dispatch, getState) => {
		if (!force && isCached(getState().freedays.cache, CACHE_FREEDAYS_WORKERS)(CACHE_TIME_FREEDAYS)) {
			isFunction(onSuccess) && onSuccess(getState().freedays.workers);
			return;
		}
		workerQueue.push(onSuccess);
		if (workerQueue.length > 1) {
			return;
		}
		try {
			dispatch(setLoader(PROC_FREEDAYS_WORKERS));
			const result = await GET('/freedays/workers');
			dispatch(freedaysState__setWorkers(result.list));
			for ( const callback of workerQueue) {
				isFunction(callback) && callback(result);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			workerQueue = [];
			dispatch(unsetLoader(PROC_FREEDAYS_WORKERS));
		}
	};
export const freedaysAction__createWorkers = (data, onSuccess) =>
	async dispatch => {
		// const workers_id = data.workers_id;
		try {
			dispatch(setLoader(PROC_FREEDAYS_WORKERS));
			const result = await PUT('/freedays/workers', data);
			if (result.ack) {
				dispatch(freedaysState__addWorkers(result.freeday));
			}
			
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			dispatch(addError(e));
			return {error: e.message || e.error};
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS_WORKERS));
		}
	};
export const freedaysAction__updateWorkers = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_FREEDAYS_WORKERS));
			const result = await POST('/freedays/workers', data);
			dispatch(freedaysState__updateWorkers(result.freeday));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS_WORKERS));
		}
	};
export const freedaysAction__removeWorkers = (freeday, onSuccess) =>
	async dispatch => {
		const freedays_id = freeday.freedays_id || freeday;
		try {
			dispatch(setLoader(PROC_FREEDAYS_WORKERS));
			const result = await DELETE(`/freedays/workers/${freedays_id}`);
			dispatch(freedaysState__removeWorkers(result.freedays_id || freedays_id));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_FREEDAYS_WORKERS));
		}
	};