import * as React from 'react';
import PropTypes from 'prop-types';
import {Snackbar} from "material-ui";
import {translate} from "react-i18next";
import {blackOrWhite} from "../Logic/extensions";

let IthSnackbar = ({t, i18n, tReady, list, autoHideDuration, onRequestClose, style, ...props}) => (
		Object.keys(list).map(index => {
			const {message, color: backgroundColor, action, actionCallback, duration} = list[index];
			console.debug('snackbar is', action, list[index]);
			return (
				<Snackbar
					key={index}
					open
					autoHideDuration={autoHideDuration || duration}
					message={t([message || 'errors.default'])}
					bodyStyle={{backgroundColor, paddingTop: 16, paddingBottom: 16, lineHeight: '24px', height: 'auto', ...style}}
					contentStyle={{display: 'flex', color: blackOrWhite(backgroundColor)}}
					onRequestClose={() => onRequestClose(message)}
					action={action ? <span style={{whiteSpace: 'nowrap'}}>{action}</span> : null}
					onActionClick={() => {
						onRequestClose(message);
						actionCallback();
					}}
					{...props}
				/>
			);
		})
);

IthSnackbar.propTypes = {
	autoHideDuration: PropTypes.number,
	onRequestClose: PropTypes.func.isRequired,
	style: PropTypes.object,
	list: PropTypes.object.isRequired
};

IthSnackbar.defaultProps = {
	list: {},
	autoHideDuration: 3000
};


export default translate()(IthSnackbar);