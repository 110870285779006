// export * as ActionHeader from "./ActionHeader";
import {isFunction} from "lodash";
import PropTypes from "prop-types";

export {ConfirmButton, ConfirmButtonConsumer} from "./ConfirmButton";
// export * as Countdown from "./Countdown";
// export * as DateDifference from "./DateDifference";
export {default as DatePicker} from "./DatePicker";
export {default as DevToolsHandler} from "./DevToolsHandler";
export {default as DropdownField} from "./DropdownField";
export {default as EditField} from "./EditField";
export {HoverContext} from "./HoverContext";
// export * as InputWrapper from "./InputWrapper";
// export * as ListView from "./ListView";
export {default as LoaderView} from "./LoaderView";
export {default as LoginView, requestHideLoginView} from "./LoginView";
export {default as LogoutView} from "./LogoutView";
// export * as MaskField from "./MaskField";
export {NoBreak} from "./MiniComponents";
// export * as PopupInfo from "./PopupInfo";
export {default as RegisterView} from "./RegisterView";
export {default as SearchView, inputDelay, ValidationSearch} from "./SearchField";
export {default as Spinner} from "./Spinner";


export const ShallowComponent = ({children}) => children;

export const ProviderComponent = ({data, children, placeholder}) => data && isFunction(children) ? children(data) : placeholder;
ProviderComponent.propTypes = {
	id: PropTypes.number,
	placeholder: PropTypes.node
};
ProviderComponent.defaultProps = {
	placeholder: null
};
export const ProviderListComponent = ({data, children, placeholder}) => data && isFunction(children) ? children(data) : placeholder;
ProviderListComponent.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.number),
	placeholder: PropTypes.node
};
ProviderListComponent.defaultProps = {
	placeholder: null
};
// export * as ValidationContext from "./ValidationContext";
// export * as ValidationField from "./ValidationField";

