import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {Form, FormField} from "semantic-ui-react";
import EditField from "../../partials/EditField";
import EmailIcon from 'material-ui/svg-icons/communication/email';
import {Subbar} from "ah-tools";
import {translate} from "react-i18next";
import * as mail from 'email-validator';
import isFunc from 'lodash/isFunction';
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER_EMAIL} from "../../../actions";
import {updateEmailOfWorker} from "../../../actions/workerActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {async} from "ith-fetch";


class WorkersDetailChangeEmail extends React.PureComponent {
	static propTypes = {
		isLoading: PropTypes.bool,
		email: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		onUpdateLayout: PropTypes.func,
		workers_id: PropTypes.number.isRequired
	};
	static defaultProps = {
		isLoading: false,
		onChange: () => alert('onChange() is not implemented yet'),
		onUpdateLayout: () => {}
	};
	
	state = {
		email: '',
		email2: '',
		expanded: false
	};
	
	reset = () => this.setState({
		email: '',
		email2: ''
	});
	
	handleSubmit = () => {
		if (!this.isValid()) return;
		this.props.onChange(this.props.workers_id, this.state.email, this.reset);
	};
	
	handleChange = (e, {name, value}) => this.setState({[name]: value});
	
	handleExpand = () => {
		this.setState(state => ({
			expanded: !state.expanded
		}), () => async(100).then(this.props.onUpdateLayout));
	};
	
	isValid = () => {
		const {email, email2} = this.state;
		return Boolean(
			mail.validate(email) && mail.validate(email2) && email === email2
		);
	};
	
	eMailError = email => email.trim().length && !mail.validate(email);
	eMailMissmatch = (one, two) => two.trim().length && one !== two;
	invalidText = email => this.eMailError(email) ? this.props.t('worker.detail.errors.email') : null;
	missMatchText = (one, two) => this.eMailMissmatch(one, two) ? this.props.t('worker.detail.errors.email-mismatch') : null;
	
	render() {
		const {t, isLoading, email: currentMail} = this.props;
		const {email, email2, expanded} = this.state;
		return(
			<Card expanded={expanded} onExpandChange={this.handleExpand}>
				<CardHeader title={t('worker.detail.parts.worker-email')} subtitle={t('worker.detail.parts.change-email')} actAsExpander showExpandableButton className={'ith-card-pointer'}/>
				<CardText expandable>
					<Form onSubmit={this.handleSubmit} loading={isLoading}>
						{currentMail &&
						<FormField>
							<EditField
								icon={<EmailIcon/>}
								text={t('worker.detail.labels.current-email')}
								value={currentMail}
								readOnly
							/>
						</FormField>
						}
						<FormField>
							<EditField
								icon={<EmailIcon/>}
								text={t('worker.detail.labels.new-email')}
								value={email}
								name={'email'}
								onChange={this.handleChange}
								type={'email'}
								editable
								errorText={this.invalidText(email)}
							/>
						</FormField>
						<FormField>
							<EditField
								icon={<EmailIcon/>}
								text={t('worker.detail.labels.confirm-email')}
								value={email2}
								name={'email2'}
								onChange={this.handleChange}
								type={'email'}
								editable
								errorText={this.missMatchText(email, email2)}
							/>
						</FormField>
						<Subbar align={Subbar.ALIGN_END}>
							<RaisedButton disabled={!this.isValid()} type={'submit'} primary><span style={{paddingLeft: 10, paddingRight: 10}}>{t('actions.change-email')}</span></RaisedButton>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}

WorkersDetailChangeEmail = translate()(WorkersDetailChangeEmail);

export default WorkersDetailChangeEmail;

const m2s = (state, props) => ({
	isLoading: isLoading(state, PROC_WORKER_EMAIL, props.workers_id)
});
const m2d = dispatch => ({
	onChange: (worker, email, onSuccess) => dispatch(updateEmailOfWorker(worker, email, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.email-changed'));
	}))
});

export const WorkersDetailChangeEmailController = connect(m2s, m2d)(WorkersDetailChangeEmail);
WorkersDetailChangeEmailController.propTypes = {
	email: PropTypes.string,
	onUpdateLayout: PropTypes.func,
	workers_id: PropTypes.number.isRequired
};
