import * as React from "react";
import PropTypes from "prop-types";
import {Chip} from "material-ui";
import {values, isFunction} from "lodash";
import {connect} from "react-redux";
import {resourceAction__unassignFromWorker} from "../../../actions/resourceActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {blackOrWhite} from "../../../Logic/extensions";
import cn from "classnames";


const _ResourceChips = ({ref, workers_id, resources, resourceMap, onClick, onRemove, className, children, ...props}) => {
	const list = resources.map(({resource_id}) => {
		const res = resourceMap[resource_id];
		if ( !res ) {
			return null;
		}
		return (
			<Chip ref={r => ref && ref(r, workers_id, resource_id)} className={cn("Worker-Resource-Chip", className)} key={`resource-chip-${workers_id}-${resource_id}`} onClick={onClick.bind(null, workers_id, resource_id)} onRequestDelete={onRemove.bind(null, resource_id)} backgroundColor={res.color} labelColor={blackOrWhite(res.color)} {...props}>
				{res.name}
			</Chip>
	)});
	if (!list.length) {
		return children;
	}
	return list;
};

const ResourceChips = connect(
	(state, props) => {
		return {
			resources: props.resources || values(state.resources.workers[props.workers_id] || {}),
			resourceMap: props.resourceMap || state.resources.list,
			workers_id: props.workers_id
		};
	},
	(dispatch, props) => {
		return {
			onRemove: (resource, onSuccess) => dispatch(resourceAction__unassignFromWorker(props.workers_id, resource, result => {
				dispatch(addSnackbar('snackbar.md-resource.revoked'));
				isFunction(onSuccess) && onSuccess(result);
			}))
		};
	}
)(_ResourceChips);
ResourceChips.propTypes = {
	workers_id: PropTypes.number.isRequired,
	onRemove: PropTypes.func,
	onClick: PropTypes.func,
	resources: PropTypes.array,
	resourceMap: PropTypes.object
};

export {ResourceChips};