import * as React from "react";
import {OrderStateProvider} from "../../../Logic/OrderStateProvider";
import {COLORS} from "../../../Logic/constants";
import {Flex, FlexChild} from "../../partials/ActionHeader";
import {OrderDateRangeProvider} from "../../../Logic/OrderDateRangeProvider";
import {withModule} from "../../../Tools/RightsProvider";

export let OrderStatusView = ({order, backgroundColor, modules}) => {
	return (
		<OrderStateProvider order={order} negative={COLORS.SEMANTIC_RED}>{
			({Icon, color}) =>
				<Flex style={{backgroundColor: backgroundColor || COLORS.SECONDARY, padding: 8}} align={'space-around'} valign={"center"} gutterWidth={15}>
					{/*<FlexChild><Icon.Released color={color.released}/></FlexChild>*/}
					<FlexChild><Icon.Delivered color={color.delivered}/></FlexChild>
					<FlexChild><Icon.Progress color={color.progress}/></FlexChild>
					<FlexChild><Icon.Finished color={color.finished}/></FlexChild>
					{modules.assist && <FlexChild><Icon.Affirmed color={color.affirmed}/></FlexChild>}
					{modules.assist && <FlexChild><Icon.Processed color={color.processed}/></FlexChild>}
					<FlexChild><Icon.Fetched color={color.fetched}/></FlexChild>
				</Flex>
		}</OrderStateProvider>
	);
};
OrderStatusView = withModule(OrderStatusView);

const Star = ({visible, title}) => visible ? <span title={title}>*</span> : null;

export const OrderRangeView = ({order}) => <OrderDateRangeProvider order={order}>{p =>
	<React.Fragment>
		<div style={{textAlign: "center", color: "gray"}}>{p.created_at.format(p.format)}</div>
		<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
		<div style={{textAlign: "center", color: COLORS.SEMANTIC_GREEN}}>{p.delivered.format(p.format)} <Star title={'Abgabezeitpunkt'} visible={p.is_delivered}/></div>
		<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
		<div style={{textAlign: "center", color: COLORS.SEMANTIC_ORANGE}}>{p.latest.format(p.format)}</div>
		<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
		<div style={{textAlign: "center", color: COLORS.SEMANTIC_RED}}>{p.end.format(p.format)} <Star title={'Übergabezeitpunkt'} visible={p.is_fetched}/></div>
	</React.Fragment>
}</OrderDateRangeProvider>;