import * as React from "react";
import * as PropTypes from "prop-types";
import {Popover} from "material-ui";
import {Segment} from "semantic-ui-react";
import {isFunction} from "lodash";
import {PRODUCTION_MODE} from "../Logic/constants";

export class TriggeredPopover extends React.PureComponent {
	static propTypes = {
		children: PropTypes.element,
		popover: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
		wrapperProps: PropTypes.object,
		vertical: PropTypes.string,
		horizontal: PropTypes.string,
		disabled: PropTypes.bool,
		bind: PropTypes.any
	};
	static defaultProps = {
		vertical: 'top',
		horizontal: 'left',
		disabled: false
	};
	state = {
		anchor: null,
		bound: null
	};
	set = (e) => {
		if (this.props.disabled) return;
		e && "preventDefault" in e && e.preventDefault();
		e && "stopImmediatePropagation" in e && e.stopImmediatePropagation();
		e && "stopPropagation" in e && e.stopPropagation();
		const {bind} = this.props;
		this.setState({anchor: (e && e.currentTarget) || null, bound: isFunction(bind) ? bind() : bind})
	};
	children = (children, props) => children ? React.cloneElement(children, props) : null;
	render() {
		let {popover, vertical, horizontal, bind, disabled, children, ...props} = this.props;
		if (disabled) return this.children(children, props);
		const {anchor, bound} = this.state;
		const handle = {onContextMenu: this.set};
		return <React.Fragment>
			{this.children(children, {...props, ...handle})}
			<Popover
				anchorEl={anchor}
				open={!!anchor && !disabled}
				onRequestClose={this.set.bind(null, null)}
				anchorOrigin={{vertical: "top", horizontal: "left"}}
			>{isFunction(popover) ? popover(bound) : popover}</Popover>
		</React.Fragment>;
	}
};



export const DebugPopover = TriggeredPopover;
DebugPopover.defaultProps = {
	popover: (bound) => <Segment padded={"very"} basic>
		<pre>{JSON.stringify(bound, null, 2)}</pre>
	</Segment>,
	disabled: PRODUCTION_MODE,
	vertical: 'top',
	horizontal: 'left',
};
