// eslint-disable-next-line
import * as React from "react";
import PropTypes from "prop-types";
import {deepMemoize as memoize} from "./extensions";
import {SIGNS} from "./constants";
import moment from "./Moment";
import {isFunction} from "lodash";

export const OrderDateRangeProvider = ({order, children, isEngineer = false}) => {
	if (!order) {
		throw new Error("Prop `order` is missing and required in `OrderDateRangeProvider!`");
	}
	if (!children || !isFunction(children)) {
		throw new Error("Prop `children` must be of type `function`!")
	}
	// console.error(order);
	const created = memoize(d => d ? moment(d) : null);
	// const released = memoize(d => d ? moment(d) : null);
	const delivered = memoize(d => d ? moment(d) : null);
	const deliver = memoize(d => d ? moment(d) : null);
	const latest = memoize(d => d ? moment(d) : null);
	const fetched = memoize(d => d ? moment(d) : null);
	const fetch = memoize(d => d ? moment(d) : null);
	const finished = memoize(d => d ? moment(d) : null);
	const data = {
		separator : {
			value: SIGNS.DOTS_VERTICAL,
			color: "#666"
		},
		format: "LLL",
		created_at: created(order.created_at),
		released_at: null,
		delivered_at: delivered(order.delivered_at),
		deliver_point: deliver(order.deliver_point),
		latest: latest(order.latest_point),
		fetched_at: fetched(order.fetched_at),
		fetch_point: fetch(order.fetch_point),
		engineer_fetch_point: fetch(order.fetch_point).clone().subtract(order.post_process_duration, 'minute'),
		finished_at: finished(order.finished_at),
	};
	data.is_released = false;
	data.is_delivered = Boolean(data.delivered_at);
	data.is_fetched = Boolean(data.fetched_at);
	data.is_finished = Boolean(data.finished_at);
	data.start = data.created_at;
	data.delivered = data.delivered_at || data.deliver_point;
	data.end = data.fetched_at || (isEngineer ? data.engineer_fetch_point : data.fetch_point);
	return children(data);
};

OrderDateRangeProvider.propTypes = {
	order: PropTypes.shape({
		created_at: PropTypes.any.isRequired,
		released_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]),
		delivered_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]),
		deliver_point: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]).isRequired,
		latest_point: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]).isRequired,
		fetched_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]),
		fetch_point: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]).isRequired,
		finished_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date), PropTypes.func]),
	}).isRequired,
	children: PropTypes.func.isRequired
};