import * as React from "react";
import PropTypes from "prop-types";
import {Icon, Popup, Segment} from "semantic-ui-react";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import User from "../../../models/User";
import {appointmentAction__updateDelivered, appointmentAction__updateFetched, appointmentAction__updateReleased} from "../../../actions/appointmentActions";
import {CardText, IconButton, Popover, RaisedButton, Toggle} from "material-ui";
import {EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_APPOINTMENTS} from "../../../actions";
import {Loadable, trueNull} from "../../../Logic/extensions";
import {OrderStateProvider} from "../../../Logic/OrderStateProvider";

class AppointmentCarStatus extends React.Component {
	static propTypes = {
		user: PropTypes.instanceOf(User),
		order: PropTypes.object,
		order_id: PropTypes.number.isRequired,
		// appointments: PropTypes.shape({
		// 	orders: PropTypes.object.isRequired,
		// 	services: PropTypes.object.isRequired,
		// 	resources: PropTypes.object.isRequired,
		// 	vehicles: PropTypes.object
		// }),
		onUpdateReleased: PropTypes.func,
		onUpdateDelivered: PropTypes.func,
		onUpdateFetched: PropTypes.func,
		loading: PropTypes.bool,
		accessable: PropTypes.bool
	};
	static defaultProps = {
		accessable: true
	};
	state = {
		release: null,
		deliver: null,
		fetch: null,
	};
	
	set = (index) => e => this.setState({[index]: e ? e.currentTarget : null});
	toggle = (index) => (e, value) => this.setState({[index]: value});
	
	onRelease = (order) => () => {
		this.props.onUpdateReleased(this.props.order_id, !order.released_at, this.set('release').bind(null, null));
		// this.set('release')(null);
	};
	onDeliver = (order) => () => {
		this.props.onUpdateDelivered(this.props.order_id, !order.delivered_at, this.set('deliver').bind(null, null));
		// this.set('deliver')(null);
	};
	onFetch = (order) => () => {
		this.props.onUpdateFetched(this.props.order_id, !order.fetched_at, this.set('fetch').bind(null, null));
	};
	
	render() {
		const {order_id, appointments, loading, accessable, rights, allRights, order: givenOrder, map} = this.props;
		const mayRead = rights.mayRead || allRights[SECTION.ORDERS].mayRead;
		if (!order_id || !mayRead) {
			return <span><Icon name={"dont"} color={"red"}/></span>;
		}
		let order = givenOrder || map.orders[order_id] || appointments.orders[order_id];
		if (!order) {
			return <span><Icon name={"spinner"} loading color={"blue"}/></span>;
		}
		const {release: releaseView, deliver: deliverView} = this.state;
		
		const inverted = false;
		const mayChange = accessable && (rights.mayChange || allRights[SECTION.ORDERS].mayChange);
		// const mayRelease = allRights[SECTION.ORDERS].mayChange || allRights[SECTION.RELEASE].mayChange;
		return (
			<React.Fragment>
				<OrderStateProvider order={order}>{
					({Icon, color, state}) =>
						<ul className={'appointment-car-status'}>
						
							<li>
								<Popup position="right center" inverted={inverted} content={'Auf dem Hof'} trigger={
									<IconButton
										disableTouchRipple={!mayChange}
										onClick={trueNull(mayChange) && this.set('deliver')}
										style={{cursor: mayChange ? 'pointer' : 'default'}}
									>
										<Icon.Delivered color={color.delivered}/>
									</IconButton>
								}/>
							</li>
							<li>
								<Popup position="right center" inverted={inverted} content={'In Arbeit'} trigger={
									<IconButton
										disableTouchRipple
										style={{cursor: 'default'}}
									>
										<Icon.Progress color={color.progress}/>
									</IconButton>
								}/>
							</li>
							<li>
								<Popup position="right center" inverted={inverted} content={'Fertig'} trigger={
									<IconButton
										disableTouchRipple
										style={{cursor: 'default'}}
									>
										<Icon.Finished color={color.finished}/>
									</IconButton>
								}/>
							</li>
							{state.delivery_service && <li>
								<Popup position="right center" inverted={inverted} content={'Bring- & Abholservice'} trigger={
									<IconButton disableTouchRipple
									            style={{cursor: 'default'}}><Icon.Delivery color={color.delivery_service}/></IconButton>
								}/>
							</li>
							}
							{state.waiting && <li>
								<Popup position="right center" inverted={inverted} content={'Warteauftrag'} trigger={
								<IconButton disableTouchRipple
								            style={{cursor: 'default'}}><Icon.Waiting color={color.waiting}/></IconButton>
							}/>
								</li>
							}
							{/*<li>*/}
							{/*	<Popup position="right center" inverted={inverted} content={'Fahrzeug abgeholt'} trigger={*/}
							{/*		<IconButton*/}
							{/*			disableTouchRipple={!mayChange}*/}
							{/*			onClick={trueNull(mayChange) && this.set('fetch')}*/}
							{/*			style={{cursor: mayChange ? 'pointer' : 'default'}}*/}
							{/*		>*/}
							{/*			<Icon.Fetched color={color.fetched}/>*/}
							{/*		</IconButton>*/}
							{/*	}/>*/}
							{/*</li>*/}
						</ul>
				}</OrderStateProvider>
				
				<Popover
					open={releaseView !== null}
					anchorEl={releaseView}
					onRequestClose={this.set('release').bind(null, null)}
					anchorOrigin={{vertical: "top", horizontal: "left"}}
				>
					<User.ReleaseProvider order_id={order.order_id}>{({mayService, mayStock, releaseService, releaseStock, release, loading: releaseLoading}) =>
						<Segment padded basic loading={releaseLoading}>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.STRETCH}>
								<strong style={{whiteSpace: 'nowrap', marginBottom: 10}}>Freigabe</strong>
								{mayService && <div style={{marginBottom: 10}}>
									<Toggle
										label={<span style={{whiteSpace: "nowrap"}}>{release.service ? 'Freigegeben' : 'Freigabe erforderlich'}</span>}
										toggled={!!release.service}
										onToggle={releaseService.bind(null, order.origin, this.set('release').bind(null, null))}
										labelPosition={"right"}
									/>
								</div>}
								{mayStock && <div>
									<Toggle
										label={<span style={{whiteSpace: "nowrap"}}>{release.stock ? 'Freigegeben (Lager)' : 'Freigabe (Lager) erforderlich'}</span>}
										toggled={!!release.stock}
										onToggle={releaseStock.bind(null, order.origin, this.set('release').bind(null, null))}
										labelPosition={"right"}
									
									/>
								</div>}
							</EasyFlex>
						</Segment>
						
					}</User.ReleaseProvider>
				</Popover>
				<Popover
					open={deliverView !== null}
					anchorEl={deliverView}
					onRequestClose={this.set('deliver').bind(null, null)}
					anchorOrigin={{vertical: "top", horizontal: "left"}}
				>
					<Segment basic>
						<CardText>
							<Flex direction={'column'} valign={"center"}>
								<strong style={{whiteSpace: 'nowrap', marginBottom: 15}}>Auf dem Hof</strong>
								<Loadable loading={loading} inverse hoc={RaisedButton} disabledOnLoading primary={!order.delivered_at} secondary={order.delivered_at} onClick={this.onDeliver(order)}
								          style={{marginLeft: 20}}>
									<span style={{paddingLeft: 10, paddingRight: 10}}>{order.delivered_at ? 'Vom Hof entfernen' : 'Auf den Hof setzen'}</span>
								</Loadable>
							</Flex>
						</CardText>
					</Segment>
				</Popover>
				{/*<Popover*/}
				{/*	open={fetchView !== null}*/}
				{/*	anchorEl={fetchView}*/}
				{/*	onRequestClose={this.set('fetch').bind(null, null)}*/}
				{/*	anchorOrigin={{vertical: "bottom", horizontal: "left"}}*/}
				{/*	targetOrigin={{vertical: "bottom", horizontal: "left"}}*/}
				{/*>*/}
				{/*	<Segment basic>*/}
				{/*		<CardText>*/}
				{/*			<Flex direction={'column'} valign={"center"}>*/}
				{/*				<strong style={{whiteSpace: 'nowrap', marginBottom: 15}}>Abgeholt</strong>*/}
				{/*				<Loadable loading={loading} inverse hoc={RaisedButton} disabledOnLoading primary={!order.fetched_at} secondary={order.fetched_at} onClick={this.onFetch(order)}*/}
				{/*				          style={{marginLeft: 20}}>*/}
				{/*					<span style={{paddingLeft: 10, paddingRight: 10}}>{order.fetched_at ? 'Doch nicht abgeholt' : 'Wurde abgeholt'}</span>*/}
				{/*				</Loadable>*/}
				{/*			</Flex>*/}
				{/*		</CardText>*/}
				{/*	</Segment>*/}
				{/*</Popover>*/}
			</React.Fragment>
		);
	}
}

AppointmentCarStatus = withRights(SECTION.APPOINTMENTS, AppointmentCarStatus);
AppointmentCarStatus = connect(
	(state, props) => ({
		appointments: state.appointments,
		map: state.map,
		loading: isLoading(state, PROC_APPOINTMENTS, props.order_id)
	}),
	(dispatch) => ({
		onUpdateReleased: (order, state, onSucess) => dispatch(appointmentAction__updateReleased(order, state, onSucess)),
		onUpdateDelivered: (order, state, onSucess) => dispatch(appointmentAction__updateDelivered(order, state, onSucess)),
		onUpdateFetched: (order, state, onSucess) => dispatch(appointmentAction__updateFetched(order, state, onSucess))
	})
)(AppointmentCarStatus);
AppointmentCarStatus = translate()(AppointmentCarStatus);


export default AppointmentCarStatus;