import * as React from "react";
import PropTypes from "prop-types";
import {clientCall__cursor} from "../../../../actions/clientActions";
import {deepMemoize as memoize} from "../../../../Logic/extensions";
import {chunk} from "lodash";
import {Grid, Segment} from "semantic-ui-react";
import {Client} from "../../../../models";
import cn from "classnames";
import {FixedBottomPagination} from "../../../../Tools/Pagination";
import {ClientGrid} from "./ClientGrid";
import {get} from 'lodash';
import {withSize} from "react-sizeme";
import {vehicleCall__ofClients} from "../../../../actions/vehicleActions";
import {array2object} from "../../../../Logic/extensions";

export class ClientList extends React.Component {
	static propTypes = {
		onFetch: PropTypes.func,
		onFetchVehicles: PropTypes.func,
		itemsPerPage: PropTypes.number,
		startPage: PropTypes.number,
		onClientCount: PropTypes.func,
		hideClientPopup: PropTypes.bool,
		onShowClient: PropTypes.func,
		onShowVehicles: PropTypes.func,
		searchText: PropTypes.string,
		sort: PropTypes.string
	};
	static defaultProps = {
		onFetch: clientCall__cursor,
		onFetchVehicles: vehicleCall__ofClients,
		itemsPerPage: 25,
		startPage: 0,
		onClientCount: () => {},
		hideClientPopup: false,
		searchText: ''
	}
	
	state = {
		page: 0,
		items: 25,
		sort: 'name--asc',
		result: null,
		cursor: null,
		loading: false,
		total: null,
		vehicles: {}
	};
	
	searchChanged = false;
	
	constructor(props) {
		super(props);
		this.state.page = Math.max(0, this.props.startPage);
		this.state.items = Math.max(1, this.props.itemsPerPage);
	}
	
	fetch = async () => {
		const {page: givenPage, items} = this.state;
		const {onFetch, searchText: search, onFetchVehicles, sort} = this.props;
		try {
			let page = this.searchChanged ?  0 : givenPage;
			this.setState({loading: true, page}, () => {
				this.searchChanged = false;
			});
			const res = await onFetch({page, items, sort, search});
			this.setState({cursor: res.paging, result: res.items, total: get(res, 'summary.item_count', null)});
			if (search && onFetchVehicles) {
				const client_ids = res.items.map(c => c.client_id);
				const vehicles = await onFetchVehicles(client_ids);
				this.setState({vehicles: this.partitionVehicles(vehicles)});
			}
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({loading: false}, () => {
				this.props.onClientCount(this.state.total);
			});
		}
	};
	
	setPage = (_, page) => this.setState({page: page.activePage - 1}, this.fetch);
	
	
	componentDidMount() {
		this.fetch();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.searchText !== this.props.searchText || prevProps.sort !== this.props.sort) {
			if (prevProps.searchText !== this.props.searchText) {
				this.searchChanged = true;
			}
			this.fetch();
		}
		if (prevProps.itemsPerPage !== this.props.itemsPerPage) {
			this.setState({items: this.props.itemsPerPage, page: 0}, this.fetch);
		}
	}
	
	partitionVehicles = memoize(
		list => array2object(list, true)('client_id')
	);
	
	columns = memoize(
		width => {
			return Math.max(1, Math.floor(width / 400));
		}
	);
	
	chunks = memoize(
		(list, columns) => {
			return chunk(list, columns);
		}
	);
	
	render() {
		const {size, hideClientPopup, onShowClient, onShowVehicles} = this.props;
		let clients = this.state.result || [];
		const chunks = this.columns(size.width);
		clients = this.chunks(clients, chunks);
		
		return <div>
			<Segment basic loading={this.state.loading} style={{minHeight: 200}}>
				<Grid columns={chunks} celled={"internally"} stretched>
					{clients.map((row, line) =>
						<Grid.Row key={line}>
							{row.map(client => {
								const c = new Client(client);
								const open = Boolean(this.state.client && this.state.client.client_id === c.client_id);
								return <Grid.Column key={c.client_id} stretched style={{paddingRight: 0, position: "relative"}} className={cn("client-cell", {'show-options': open})}>
									<ClientGrid client={c} hidePopup={hideClientPopup} onShowVehicles={onShowVehicles} onShowDetails={onShowClient}/>
								</Grid.Column>
							})}
						</Grid.Row>
					)}
				</Grid>
			</Segment>
			{this.state.cursor && <FixedBottomPagination
				page={this.state.cursor.current}
				pages={this.state.cursor.last + 1}
				onPageChange={this.setPage}
				style={{position: 'fixed', bottom: 0, width: size.width, zIndex: 3, background: 'rgba(243,243,243,0.9)', paddingTop:10, paddingBottom: 10}}
			/>}
		</div>;
	}
}
ClientList = withSize()(ClientList);