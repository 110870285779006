import * as React from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {Form, FormField} from "semantic-ui-react";
import EditField from "../../../partials/EditField";
import EuroIcon from 'material-ui/svg-icons/action/euro-symbol';
import TimerIcon from 'material-ui/svg-icons/av/av-timer';
import RestoreIcon from 'material-ui/svg-icons/action/restore';
import {Flex} from "../../../partials/ActionHeader";
import {AHIconButton, deepMemoize} from "../../../../Logic/extensions";
import {RaisedButton} from "material-ui";

class MDServicePrice extends React.PureComponent {
	static propTypes = {
		service_id: PropTypes.number.isRequired,
		kfz_id: PropTypes.number.isRequired,
		hours_of_work: PropTypes.number,
		price: PropTypes.number,
		editable: PropTypes.bool,
		onSubmit: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		loading: PropTypes.bool,
		defaultPriceText: PropTypes.string,
		defaultHoursText: PropTypes.string
	};
	static defaultProps = {
		editable: false,
		loading: false,
		onSubmit: () => alert('onSubmit() is not implemented yet!'),
		onDelete: () => alert('onDelete() is not implemented yet!'),
		defaultPriceText: 'wie Systempreis',
		defaultHoursText: 'wie Systemzeit'
	};
	
	state = {
		hours_of_work: '',
		price: ''
	};
	
	componentDidMount() {
		this.setFromProps(this.props);
		// this.update(this.props)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// const next = {...prevProps, ...this.props};
		// this.setState(next);
		this.setFromProps(this.props)
	}
	
	update = deepMemoize((hours_of_work, price) => this.setState({hours_of_work, price}))
	setFromProps = ({hours_of_work = '', price = ''}) => this.update(hours_of_work, price);
	
	valid = () => {
		const {hours_of_work, price} = this.state;
		return Boolean(
			String(hours_of_work).trim() !== '' && String(price).trim() !== ''
			&&
			this.resetable()
		);
	};
	set = (e, {name, value}) => this.setState({[name]: value});
	get = name => this.state[name] !== '' ? this.state[name] : (this.props[name] || '');
	resetable = () => String(this.state.hours_of_work) !== String(this.props.hours_of_work) || String(this.state.price) !== String(this.props.price);
	reset = () => this.setFromProps(this.props);
	deletable = () => Boolean(this.props.hours_of_work && this.props.price);
	
	handleSubmit = () => {
		if ( !this.valid() ) {
			return;
		}
		const {service_id, kfz_id, onSubmit} = this.props;
		const data = {
			service_id,
			kfz_id,
			hours_of_work: this.get('hours_of_work'),
			price: this.get('price')
		};
		onSubmit(data, this.reset);
	};
	
	handleDelete = () => {
		if (!this.deletable()) {
			return;
		}
		const {service_id, kfz_id, onDelete} = this.props;
		onDelete(service_id, kfz_id, this.reset);
	};
	
	
	render() {
		const {t, editable, loading, defaultHoursText, defaultPriceText} = this.props;
		const {hours_of_work, price} = this.state;
		return(
			<Form onSubmit={this.handleSubmit} loading={loading}>
				<FormField required={editable}>
					{editable ?
						<EditField
							name={'hours_of_work'}
							type={'number'}
							min={0.1}
							step={0.1}
							required
							editable
							labelPosition={'top'}
							label={'Arbeitszeit in Stunden'}
							placeholder={t(defaultHoursText)}
							icon={<TimerIcon/>}
							value={hours_of_work}
							onChange={this.set}
						/> : <LineInfo title={'Arbeitszeit in Stunden'} value={t(defaultHoursText)}/>}
				</FormField>
				<FormField required={editable}>
					{editable ? <EditField
						name={'price'}
						type={'number'}
						min={0}
						step={0.01}
						required
						editable
						icon={<EuroIcon/>}
						labelPosition={'top'}
						label={'Stundensatz in Euro'}
						placeholder={t(defaultPriceText)}
						value={price}
						onChange={this.set}
					/> : <LineInfo title={'Stundenpreis in Euro'} value={t(defaultPriceText)}/>}
				</FormField>
				{editable &&
				<Flex align={'space-between'} wrap={'wrap'}>
					<AHIconButton type={'button'} disabled={!this.resetable()} onClick={this.reset}><RestoreIcon/></AHIconButton>
					<Flex valign={'center'}>
						{this.deletable() && <RaisedButton type={'button'} secondary style={{marginRight: 20}} onClick={this.handleDelete}>{t('actions.delete')}</RaisedButton>}
						<RaisedButton primary disabled={!this.valid()} type={'submit'}>{t('actions.save')}</RaisedButton>
					</Flex>
				</Flex>
				}
			</Form>
		);
	}
}

//
export const LineInfo = ({title, value, custom=null, ...props}) => (
	<Flex valign={'center'} align={'space-between'} {...props}>
		<span>{title}</span>
		<span>{value} {custom && <span title="Aktuelle Werte">({custom})</span>}</span>
	</Flex>
);
LineInfo.propTypes = {
	title: PropTypes.node,
	value: PropTypes.node
};

export default translate()(MDServicePrice);