// import React from 'react';
// noinspection JSUnresolvedVariable
import React from 'react'
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import InternNavigationController from "../../cointainer/intern/InternNavigationController";
import history from '../../Logic/history';
import {Redirect, Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import DashView from "./DashView";
import {HeaderController} from "./Header";
import WorkerRoutes from "./workers/WorkerRoutes";
import MasterDataRoutes from "./master-data/MasterDataRoutes";
import NotFound from "../NotFound";
import NoAccess from "../NoAccess";
import TestException from "../TestException";
import FeedbackController from "../../cointainer/intern/FeedbackController";
import ScrollTop from "../ScrollTop";
import isEmpty from 'lodash/isEmpty';
import {Flex} from "../partials/ActionHeader";
import MissingCarImage from '../../images/car-missing.png';
import ClientRoutes from "./clients/ClientRoutes";
import User from "../../models/User";
import OrderRoutes from "./orders/OrderRoutes";
import {PRODUCTION_MODE, SECTION, SHOW_DEV_PRODUCTION} from "../../Logic/constants";
import Development from "../Development";
import AppointmentsBarrier from "../../cointainer/intern/appointments/AppointmentsBarrier";
import ReleaseMain from "../../cointainer/intern/release/ReleaseMain";
import AppointmentsController from "../../cointainer/intern/appointments/AppointmentsController";
import {publish, subscribe} from "../../Logic/PubSub";
import {SUB_CLIENT_SEARCH, SUB_LOGIN_SUCCESSFUL, SUB_SESSION_TIMEOUT, SUB_TEST} from "../../actions";
import {PausedNotificationController} from "../../cointainer/intern/PausedNotificationController";
import LogoutOverlay from "../../cointainer/intern/LogoutOverlay";
import {connect} from "react-redux";
import {withRights} from "../../Tools";
import hash from "object-hash";
import {PausedNotificationAlert} from "./appointments/PausedNotificationAlert";
import KeyHandler from "react-key-handler";
import {KeyModifier} from "../../Logic/KeyHandler";
import {NavigationClientSearchModal} from "./clients/search/ClientSearch";
import AssistanceRoutes from "./asistance/AssistanceRoutes";
import {WheelSearchModal} from "./wheel/WheelSearchModal";

// eslint-disable-next-line
class Watcher extends React.PureComponent {
	handlePauses = (pauses) => {
		// do something
		// noinspection JSUnresolvedVariable
		pauses = pauses || this.props.paused;
		console.error("handling pauses", pauses);
	};
	
	start = (pauses) => {
		this.handlePauses(pauses);
		this.interval = setInterval(this.handlePauses.bind(this, pauses), 60000);
		return this;
	};
	
	stop = () => {
		clearInterval(this.interval);
		this.interval = null;
		return this;
	};
	
	componentDidMount() {
		this.start();
	}
	componentDidUpdate(prevProps) {
		// noinspection JSUnresolvedVariable
		if (hash(prevProps.paused) !== hash(this.props.paused)) {
			this.stop().start();
		}
	}
	
	componentWillUnmount() {
		this.stop();
	}
	
	render() {
		return null;
	}
}

Watcher = connect(
	(state) => ({
		paused: state.appointments.paused_services
	}),
	dispatch => ({
		publish: (...args) => publish(SUB_TEST, ...args),
		dispatch
	})
)(Watcher);
Watcher = withRights(SECTION.APPOINTMENTS, Watcher);

class MainView extends React.Component {
	static propTypes = {
		user: PropTypes.instanceOf(User),
		house: PropTypes.object,
		kfz: PropTypes.object,
		onRequestLogout: PropTypes.func
	};
	static defaultProps = {
		kfz: {}
	};
	
	state = {
		show_login: false,
		showClientSearch: false,
		showWheelSearch: false
	};
	
	noHouse = (
		<div>Loading...</div>
	);
	
	showLogin = (show_login) => () => this.setState({show_login});
	
	
	componentDidMount() {
		this.unsubscribeSessionTimeout = subscribe(SUB_SESSION_TIMEOUT, this.showLogin(true));
		this.unsubscribeLoginSuccess = subscribe(SUB_LOGIN_SUCCESSFUL, this.showLogin(false));
		this.unsubscribeClientSearch = subscribe(SUB_CLIENT_SEARCH, () => this.setState({showClientSearch: true}));
	}
	
	componentWillUnmount() {
		this.unsubscribeSessionTimeout && this.unsubscribeSessionTimeout();
		this.unsubscribeLoginSuccess && this.unsubscribeLoginSuccess();
		this.unsubscribeClientSearch && this.unsubscribeClientSearch();
	}
	
	inactive = () => (
		<Flex style={{marginTop: 50}} direction={'column'} align={'center'} valign={'center'}>
			<strong>{this.props.t('errors.house-inactive')}</strong>
			<div style={{marginTop: 25, maxWidth: 500}}>
					<img style={{maxWidth: 'calc(100% - 20px)'}} src={MissingCarImage} alt={this.props.t('errors.house-inactive')}/>
				
			</div>
		</Flex>
	);
	deleted = () => (
		<Flex style={{marginTop: 50}} direction={'column'} align={'center'} valign={'center'}>
			<strong>{this.props.t('errors.house-deleted')}</strong>
			<div style={{marginTop: 25, maxWidth: 500}}>
				<img style={{maxWidth: 'calc(100% - 20px)'}} src={MissingCarImage} alt={this.props.t('errors.house-deleted')}/>
			</div>
		</Flex>
		
	);
	noKfz = () => (
		<Flex style={{marginTop: 50}} direction={'column'} align={'center'} valign={'center'}>
			<strong>{this.props.t('errors.no-cars')}</strong>
			<div style={{marginTop: 25, maxWidth: 500}}>
				<img style={{maxWidth: 'calc(100% - 20px)'}} src={MissingCarImage} alt={this.props.t('errors.no-cars')}/>
			</div>
		</Flex>
	);
	_render = (View) => {
		const {carhouse, kfz, user} = this.props;
		if (!carhouse) return this.noHouse;
		if (carhouse.deleted_at && !user.isSupport()) return this.deleted();
		if (!carhouse.active && !user.isSupport()) return this.inactive();
		if (isEmpty(kfz) && !user.isSupport()) return this.noKfz();
		return View;
	};
	
	render() {
		const {carhouse} = this.props;
		const {show_login} = this.state;
		return (
			<ConnectedRouter history={history}>
				<React.Fragment>
					<KeyModifier ctrl shift>
						<KeyHandler
							keyValue={'K'}
							onKeyHandle={() => this.setState({showClientSearch: true})}
						/>
					</KeyModifier>
					<KeyModifier ctrl shift alt>
						<KeyHandler
							keyValue={'W'}
							onKeyHandle={() => this.setState({showWheelSearch: true})}
						/>
					</KeyModifier>
					<div id="main__content">
						<HeaderController house={carhouse}/>
						{/*<Watcher/>*/}
						<div id="body">
							{
								this._render(
									<Switch>
										<Route exact path="/" component={DashView}/>
										<Route path="/workers" component={WorkerRoutes}/>
										<Route path="/master-data" component={MasterDataRoutes}/>
										<Route path="/appointments" component={AppointmentsBarrier}/>
										<Route path="/appointments-pool" component={AppointmentsController}/>
										<Route path="/release" component={ReleaseMain}/>
										<Route path="/clients" component={ClientRoutes}/>
										<Route path="/orders" component={OrderRoutes}/>
										<Route path="/assistance" component={AssistanceRoutes}/>
										<Route path="/not-found" component={NotFound}/>
										<Route path="/no-access" component={NoAccess}/>
										<Route path="/test/exception" component={TestException}/>
										<Redirect from="/email-login/:hash" to="/" />
										{carhouse && <Route path={`/${carhouse.link}`} component={DashView}/>}
										{(!PRODUCTION_MODE || SHOW_DEV_PRODUCTION) && <Route path="/dev" component={Development}/>}
										<Route component={NotFound}/>
									</Switch>
								)
							}
						</div>
					</div>
					<InternNavigationController/>
					<ScrollTop/>
					<FeedbackController/>
					{show_login && <LogoutOverlay/>}
					<NavigationClientSearchModal open={this.state.showClientSearch} onModalClose={() => this.setState({showClientSearch: false})} closeOnSelect/>
					{this.state.showWheelSearch && <WheelSearchModal open onClose={() => this.setState({showWheelSearch: false})}/>}
					<PausedNotificationController/>
					<PausedNotificationAlert/>
				</React.Fragment>
			</ConnectedRouter>
		);
	}
}


export const requestLoginView = publish.bind(undefined, SUB_SESSION_TIMEOUT);

export default translate()(MainView);