import * as React from "react";
import PropTypes from "prop-types";
import {Button, Segment} from "semantic-ui-react";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {isEmpty, isFunction, keys, omitBy, values} from "lodash"
import moment from "moment";
import {Checkbox, Divider, TextField} from "material-ui";
import {Flex, FlexChild} from "../../../components/partials/ActionHeader";
import {isOnline} from "../../../actions/userActions";
import {scheduleAction__insert} from "../../../actions/scheduleActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_SCHEDULE_INSERT} from "../../../actions";
import {COLORS} from "../../../Logic/constants";
import {TimeInput} from "../../../Tools/DatePicker";
import {format} from 'date-fns'

class Mask extends React.Component {
	static propTypes = {
		workers_id: PropTypes.number.isRequired,
		loading: PropTypes.bool,
		resources: PropTypes.array,
		resourceMap: PropTypes.object,
		selectedResource: PropTypes.number
	};
	static defaultProps = {
		resources: [],
		resourceMap: {}
	};
	
	state = {
		resources: {},
		days: {},
		start: null,
		end: null,
		force: false
	};
	
	constructor(props) {
		super(props);
		if (props.selectedResource) {
			this.state.resources[props.selectedResource] = true;
		}
	}
	
	
	get valid() {
		const {
			resources, days, start, end
		} = this.state;
		return Boolean(!isEmpty(resources) && !isEmpty(days) && start && end && end > start);
	}
	
	onDayChecked = (day) => (e, checked) => this.setState(state => ({
		...state,
		days: {
			...state.days,
			[day]: checked
		}
	}));
	
	onResourceChecked = (resource) => (e, checked) => this.setState(state => ({
		...state,
		resources: {
			...state.resources,
			[resource]: checked
		}
	}));
	
	set = (index) => (e, value) => this.setState({[index]: value || e.target.value});
	update = index => value => this.setState({[index]: value});
	
	onForce = (e, force) => this.setState({force});
	
	onSubmit = () => {
		const {resources, force, days, end, start} = this.state;
		const {workers_id, onCreate} = this.props;
		const data = {
			workers_id: workers_id,
			start_time: format(start, 'HH:mm'),
			end_time: format(end, 'HH:mm'),
			weekdays: keys(omitBy(days, e => !e)).sort(),
			resource_ids: keys(omitBy(resources, e => !e)).sort(),
			force
		};
		onCreate(data, () => {
			this.setState({start: "", end: "", force: false});
		});
	};
	
	render() {
		const {loading, resources, resourceMap, onClose} = this.props;
		
		if (!resources.length) {
			return <Segment basic inverted loading style={{minHeight: 400}}/>
		}
		
		const resourceList = resources.map(r => resourceMap[r.resource_id]);
		
		return (
			<Segment basic loading={loading}>
				<Flex vmargin={20} gutterWidth={15} gutterHeight={15} align="flex-start" valign="flex-start">
					<FlexChild style={{minWidth: 150}}>Ressourcen: </FlexChild>
					<FlexChild>
						<Flex gutterWidth={15} gutterHeight={15} align="flex-start" valign="flex-start" wrap={'wrap'}>
							{resourceList.map(({resource_id, name}) => {
								return (
									<FlexChild key={resource_id}>
										<Checkbox onCheck={this.onResourceChecked(resource_id)} label={<span style={{whiteSpace: 'nowrap'}}>{name}</span>}
										          checked={this.state.resources[resource_id] === true}/>
									</FlexChild>
								);
							})}
						</Flex>
					</FlexChild>
				
				</Flex>
				<Divider/>
				<Flex vmargin={20} gutterWidth={15} gutterHeight={15} align="flex-start" valign="center">
					<FlexChild style={{minWidth: 150}}>Tage: </FlexChild>
					{[0, 1, 2, 3, 4, 5, 6].map(dayIndex => {
						return (
							<FlexChild key={dayIndex}>
								<Flex direction={"column"} align={"center"} valign={"flex-start"}>
									<Checkbox onCheck={this.onDayChecked(dayIndex)}
									          checked={this.state.days[dayIndex] === true}/>
									<span style={{marginLeft: 3}}>{moment().day(dayIndex + 1).format('dd')}</span>
								</Flex>
							</FlexChild>
						);
					})}
				</Flex>
				<Divider/>
				<Segment style={{backgroundColor: COLORS.BACKGROUND}}>
					<Flex vmargin={20} gutterHeight={15} gutterWidth={15} align={"center"} valign={"center"}>
						<FlexChild>
							<TimeInput
								time={this.state.start}
								style={{width: 90}}
								onProvide={this.update('start')}
								floatingLabelText={'Von'}
								children={<TextField/>}
								placeholder={'__:__'}
								placeHolderOnFocus
							/>
						</FlexChild>
						<FlexChild>
							<TimeInput
								time={this.state.end}
								style={{width: 90}}
								onProvide={this.update('end')}
								floatingLabelText={'Bis'}
								children={<TextField/>}
								placeholder={'__:__'}
								placeHolderOnFocus
							/>
						</FlexChild>
					</Flex>
				</Segment>
				<Flex align={'space-between'} valign={"center"}>
					{onClose && <Button basic onClick={onClose}>Abbrechen</Button>}
					<Flex vmargin={20} gutterWidth={15} gutterHeight={15} align={"flex-end"} valign={"center"}>
						<FlexChild>
							<Checkbox
								label={"Überschreiben?"}
								onCheck={this.onForce}
								checked={this.state.force}
								labelPosition={'left'}
								labelStyle={{marginRight: 4}}
							/>
						</FlexChild>
						<FlexChild>
							<Button primary={!this.state.force} secondary={this.state.force} disabled={!this.valid} onClick={this.onSubmit}>
								Speichern
							</Button>
						</FlexChild>
					</Flex>
				</Flex>
			</Segment>
		);
	}
}

Mask = withInit(Mask);

Mask = connect(
	(state, props) => {
		return {
			resourceMap: props.resourceMap || state.resources.list,
			resources: props.resources || values(state.resources.workers[props.workers_id] || {}),
			loading: isLoading(state, PROC_SCHEDULE_INSERT)
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
			
			})),
			onCreate: (data, onSuccess) => dispatch(scheduleAction__insert(data, result => {
				isFunction(onSuccess) && onSuccess(result);
				dispatch(addSnackbar('Gespeichert'));
			}))
		};
	}
)(Mask);

Mask = translate()(Mask);

export default Mask;