/* eslint-disable */
import * as React from "react";
import {Segment} from "semantic-ui-react";
import {orderAction__collect} from "../../actions/orderActions";
import {AppointmentAlertDialog} from "../../cointainer/intern/appointments";
import {loadWorkers} from "../../actions/workerActions";
import {connect} from "react-redux";

export class OrderAlertTest extends React.Component {
	state = {
		order: null,
		list: null,
		show: true
	};
	async componentDidMount() {
		this.props.dispatch(loadWorkers())
		this.props.dispatch(orderAction__collect([1065], true)).then(order => {
			console.warn('orer is', order)
			this.setState({order: order[0]})
			// this.props.dispatch(clientAction__getOne(order.client_id))
			// this.props.dispatch(vehicleAction__getByOrder(order))1
		});
		// const w = await GET('/order/day/today/true/59');
		// this.setState({list: w});
		
	}
	
	client = async id => {
		const client = await this.props.getClient(id);
		this.setState({client});
	};
	
	render() {
		const { list, order} = this.state;
		// if (!list) return <Segment loading/>;
		return (
			<Segment basic>
				<pre>{JSON.stringify(this.state, null, 2)}</pre>
				{this.state.show && <AppointmentAlertDialog onUpdateSeen={() => this.setState({show: false})} orders={order ? [order] : []}/>}
			{/*	<BaseModel.ProviderList placeholder={<Segment loading/>} list={[
					clientAction__getOne.bind(null, 2790),
					vehicleAction__getOne.bind(null, 19),
					loadWorker.bind(null, 27)
				]}>{(client, vehicle, worker) =>
					<pre>{JSON.stringify({client, vehicle, worker}, null, 2)}</pre>
				}</BaseModel.ProviderList>*/}
			</Segment>
		);
	}
}
OrderAlertTest = connect()(OrderAlertTest)
