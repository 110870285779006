import * as React from "react";
import PropTypes from "prop-types";
import {isFunction} from "lodash";
import {subscribe} from "./PubSub";
import {SUB_UNAUTHERIZED} from "../actions";

const withInterval = (interval = 1000, method = "interval") => (Component) =>
	class extends React.Component {
		static propTypes = {
			intervalActive: PropTypes.bool
		};
		static defaultProps = {
			intervalActive: true
		};
		
		_intervalTimer = null;
		
		_isActive = () => {
			let active = this.props.intervalActive;
			return active === undefined ? true : Boolean(active);
		};
		
		_stopInterval = () => {
			window.clearInterval(this._intervalTimer);
			this._intervalTimer = null;
		};
		
		_startInterval = () => {
			if (isFunction(this.props[method]) && this._isActive()) {
				this._stopInterval();
				interval = Math.max(0, interval);
				this._intervalTimer = window.setInterval(this.props[method].bind(this, this.props), interval);
			}
		};
	
		componentDidMount() {
			this._startInterval();
			this.unsubscribe = subscribe(SUB_UNAUTHERIZED, this._stopInterval);
		}
		
		componentDidUpdate({intervalActive}, prevState, snapshot) {
			if ( intervalActive !== this.props.intervalActive ) {
				if (this._isActive()) {
					this._startInterval();
				} else {
					this._stopInterval();
				}
			}
		}
		
		componentWillUnmount() {
			this._stopInterval();
			this.unsubscribe && this.unsubscribe();
		}
		
		render() {
			return <Component {...this.props}/>;
		}
	};

const withTimeout = (interval = 1000, method = "interval") => (Component) =>
	class extends React.Component {
		
		_intervalTimer = null;
		
		componentDidMount() {
			if (isFunction(this.props[method])) {
				interval = Math.max(0, interval);
				this._intervalTimer = window.setTimeout(this.props[method].bind(this, this.props), interval);
			}
		}
		
		componentWillUnmount() {
			window.clearInterval(this._intervalTimer);
		}
		
		render() {
			return <Component {...this.props}/>;
		}
	};

export {withInterval, withTimeout};