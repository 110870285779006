import * as React from "react";
import PropTypes from "prop-types";
import {Button, Modal, Portal, Segment} from "semantic-ui-react";
import cn from "classnames";
import {translate} from "react-i18next";
import {Dialog, FlatButton} from "material-ui";
import {EasyFlex, Flex} from "../../components/partials/ActionHeader";
import {ErrorBoundary} from "../../cointainer";
import {isFunction} from "lodash";
import {COLORS} from "../../Logic/constants";


/////////////
//      Basic Dialog
///////////////////////
let BasicDialog = ({
	                   t, tReady, i18n, className, open, children, onClose, closeButton, renderActions,
	                   modal, autoScrollBodyContent, actionsAbove, closeRight, noPadding, darkMode, noBodyPadding,
	                   bodyClassName, contentClassName, actionsContainerClassName, paperClassName, overlayClassName, titleClassName,
	                   ...props}) => (
	<Dialog
		open={open}
		className={cn("basic-dialog", {
			open,
			modal,
			autoscroll: autoScrollBodyContent,
			"close-button": closeButton,
			"actions-above": actionsAbove,
			"close-right": closeRight,
			"no-padding": noPadding,
			"dark-mode": darkMode
		}, className)}
		onRequestClose={onClose}
		actions={(closeButton) ? (isFunction(renderActions) ? renderActions(<FlatButton onClick={onClose}>{t('actions.close')}</FlatButton>, onClose) : [<Flex align={"flex-start"}>
			<FlatButton onClick={onClose}>{t('actions.close')}</FlatButton>
		</Flex>]) : null}
		modal={modal}
		autoScrollBodyContent={autoScrollBodyContent}
		bodyClassName={cn('body', bodyClassName, {"no-padding": noBodyPadding})}
		contentClassName={cn("content", contentClassName)}
		actionsContainerClassName={cn('actions', actionsContainerClassName)}
		paperClassName={cn('paper', paperClassName)}
		overlayClassName={cn("overlay", overlayClassName)}
		titleClassName={cn("title", titleClassName)}
		{...props}>
		{open ? <ErrorBoundary visible>{children}</ErrorBoundary> : <Segment loading basic style={{minWidth: 100, minHeight: 100}}/>}
	</Dialog>
);
BasicDialog.propTypes = {
	open: PropTypes.bool,
	children: PropTypes.node,
	onClose: PropTypes.func,
	closeButton: PropTypes.bool,
	actionsAbove: PropTypes.bool,
	closeRight: PropTypes.bool,
	noPadding: PropTypes.bool,
	noBodyPadding: PropTypes.bool,
	darkMode: PropTypes.bool,
	actions: PropTypes.node,
	actionsContainerClassName: PropTypes.string,
	actionsContainerStyle: PropTypes.object,
	autoDetectWindowHeight: PropTypes.bool,
	bodyClassName: PropTypes.string,
	bodyStyle: PropTypes.object,
	modal: PropTypes.bool,
	overlayClassName: PropTypes.string,
	paperClassName: PropTypes.string,
	paperProps: PropTypes.object,
	repositionOnUpdate: PropTypes.bool,
	style: PropTypes.object,
	title: PropTypes.node,
	titleClassName: PropTypes.string,
	titleStyle: PropTypes.object,
	renderActions: PropTypes.func,
	autoScrollBodyContent: PropTypes.bool
};
BasicDialog.defaultProps = {
	open: false,
	noBodyPadding: false,
	closeButton: false,
	actionsAbove: false,
	closeRight: false,
	noPadding: false,
	darkMode: false
};
BasicDialog = translate()(BasicDialog);


/////////////
//      Order Search
///////////////////////
let OrderSearchDialog = ({t, tReady, i18n, className, open, children, ...props}) => (
	<Modal
		className={cn(className, "order-search-dialog")}
		open={open}
		{...props}
	>
		<Modal.Header>Auftrag suchen</Modal.Header>
		{open ? children : <Segment loading basic style={{minHeight: 100, minWidth: 100}}/>}
	</Modal>
);
OrderSearchDialog.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	size: PropTypes.string,
	closeIcon: PropTypes.bool,
	centered: PropTypes.bool,
	basic: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node
};
OrderSearchDialog.defaultProps = {
	open: false,
	size: "tiny",
	closeIcon: true,
	centered: false,
	basic: true,
	onClose: () => alert('OrderSearchDialog::onClose() is not implemented yet!')
};
OrderSearchDialog = translate()(OrderSearchDialog);


/////////////
//      Order Detail View
///////////////////////
// let OrderViewDialog = ({t, tReady, i18n, className, open, onClose, closeButton, style, children, ...props}) => (
// 	<Dialog
// 		className={cn("order-quick-view", className)}
// 		style={{paddingTop: 0, ...style}}
// 		open={open}
// 		onRequestClose={onClose}
// 		{...props}
// 	>
// 		{open ? children : <Segment loading basic style={{minHeight: 100, minWidth: 100}}/>}
// 		{closeButton ? <Flex align={"flex-end"} className={"bottom-close-line"} style={{background: 'rgba(255,255,255,0.9)', padding: 12}}>
// 			<FlatButton onClick={onClose}>Schließen</FlatButton>
// 		</Flex> : null}
// 	</Dialog>
// );

let OrderViewDialog = ({t, tReady, i18n, className, open, onClose, closeButton, style, bottomBar, children, ...props}) => (
	<FullscreenPortal scrolling className={className} open={open} bodyStyle={style} onClose={onClose} bottomBar={bottomBar || (Bar => <Bar><Button onClick={onClose}>Schließen</Button></Bar>)} {...props}>
		{open ? children : <Segment loading basic style={{minHeight: 100, minWidth: 100}}/>}
	</FullscreenPortal>
);
OrderViewDialog.propTypes = {
	open: PropTypes.bool,
	style: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	closeButton: PropTypes.bool,
	children: PropTypes.node,
	autoScrollBodyContent: PropTypes.bool
};
OrderViewDialog.defaultProps = {
	open: false,
	onClose: () => alert("OrderViewDialog::onRequestClose() is not implemented yet!"),
	closeButton: true,
	autoScrollBodyContent: true
};
OrderViewDialog = translate()(OrderViewDialog);


/////////////
//      Order Create
///////////////////////
let OrderCreateDialog = ({t, i18n, tReady, className, ...props}) => (
	<BasicDialog
		autoScrollBodyContent
		className={cn("order-create-dialog", className)}
		closeButton
		{...props}
		
	/>
);
OrderCreateDialog.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	modal: PropTypes.bool,
	autoScrollBodyContent: PropTypes.bool,
	onClose: PropTypes.func
};
OrderCreateDialog.defaultProps = {
	open: false,
	modal: true,
	autoScrollBodyContent: true
};
OrderCreateDialog = translate()(OrderCreateDialog);

/////////////
//      Client Create
///////////////////////
let ClientCreateDialog = ({className, ...props}) => (
	<BasicDialog className={cn("client-create-dialog", className)} {...props}/>
);
ClientCreateDialog.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	modal: PropTypes.bool,
	autoScrollBodyContent: PropTypes.bool,
	onClose: PropTypes.func,
	closeButton: PropTypes.bool,
	noBodyPadding: PropTypes.bool,
};
ClientCreateDialog.defaultProps = {
	open: false,
	closeButton: false,
	autoScrollBodyContent: true
};


/////////////
//      Vehicle Create
///////////////////////
let VehicleCreateDialog = ({className, ...props}) => (
	<BasicDialog
		className={cn("vehicle-create-dialog", className)}
		{...props}/>
);
VehicleCreateDialog.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	modal: PropTypes.bool,
	autoScrollBodyContent: PropTypes.bool,
	onClose: PropTypes.func,
	noBodyPadding: PropTypes.bool,
	closeButton: PropTypes.bool
};
VehicleCreateDialog.defaultProps = {
	open: false,
	closeButton: false,
	autoScrollBodyContent: true
};

/////////////
//      Fullscreen
///////////////////////
let FullscreenDialog = ({className, ...props}) => (
	<BasicDialog className={cn("fullscreen-dialog", className)} {...props}/>
);
FullscreenDialog.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	modal: PropTypes.bool,
	autoScrollBodyContent: PropTypes.bool,
	onClose: PropTypes.func,
	closeButton: PropTypes.bool,
	actionsAbove: PropTypes.bool,
	noBodyPadding: PropTypes.bool,
	closeRight: PropTypes.bool
};
FullscreenDialog.defaultProps = {
	open: false,
	closeButton: true,
	autoScrollBodyContent: false
};

let FullscreenPortalBar = ({style, ...props}) => (
	<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.END} style={{height: 60, borderTop: '1px solid #ccc', backgroundColor: COLORS.BACKGROUND, position: 'fixed', bottom: 0, width: '100%', padding: '0 20px', ...style}} {...props}/>
);

let FullscreenPortal = ({children, bodyStyle, bodyProps, bottomBar, scrolling, ...props}) => (
	<Portal closeOnDocumentClick={false} closeOnEscape={false} {...props}>
		<div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1000}}>
			<div style={{
				position: 'fixed',
				top: 0,
				right: 0,
				bottom: bottomBar ? 60 : 0,
				left: 0,
				zIndex: 1000,
				margin: 0,
				background: COLORS.BACKGROUND,
				overflowY: scrolling ? 'auto' : null,
				...bodyStyle
			}} {...bodyProps}>
				{children}
			</div>
			{typeof bottomBar === 'function' ? bottomBar(FullscreenPortalBar) : bottomBar}
		</div>
	</Portal>
);
FullscreenPortal.propTypes = {
	open: PropTypes.bool,
	defaultOpen: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.node,
	bodyStyle: PropTypes.object,
	bodyProps: PropTypes.object,
	bottomBar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	scrolling: PropTypes.bool
};
FullscreenPortal.defaultProps = {
	scrolling: true
};




/////////////
//      Exports
///////////////////////
export {
	BasicDialog,
	OrderSearchDialog,
	OrderViewDialog,
	OrderCreateDialog,
	ClientCreateDialog,
	VehicleCreateDialog,
	FullscreenDialog,
	FullscreenPortal,
	FullscreenPortalBar
}