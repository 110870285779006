import * as React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from "material-ui";
import {translate} from "react-i18next";
import Flag from "react-world-flags";
import {Subbar} from "ah-tools";
import {IconMenu} from "../Logic/MiniMenu";

const langs = [
	{key: 'de', value: 'de', flag: 'de', text: 'Deutsch'},
	{key: 'en', value: 'en', flag: 'us', text: 'English'},
];

const find = (array) => (callback) => (def = null) => {
	const l = array.length;
	for(let i = 0; i < l; ++i) {
		if ( callback.call(array, array[i], i)) {
			return array[i];
		}
	}
	return def;
};

const LanguageV2 = ({i18n, language}) => {
	return (
		<IconMenu
			closeOnEscape
			style={{marginLeft: 15, marginRight: 15}}
			icon={<Flag className={'language-flag'} code={find(langs)(el => el.value === language)(langs[0]).flag}/>}
		>
			{
				langs.map(({value, flag, text}) =>
					<MenuItem key={value} onClick={() => i18n.changeLanguage(value)} disabled={value === language} value={value} primaryText={<Subbar><Flag code={flag} style={{maxWidth: 20, maxHeight: 20, marginRight: 10}}/> {text}</Subbar>}/>
				)
			}
		</IconMenu>
	);
};

LanguageV2.propTypes = {
	language: PropTypes.string
};
LanguageV2.defaultProps = {
	language: 'de'
};

export default translate()(LanguageV2);