import {MODULE_ACTION, RESET_ACTION} from "../actions/types";
import {updateCache} from "ith-redux-helpers";

const defaultState = {
	list: {},
	cache: {}
};

export default (state = defaultState, {type, modules = {}}) => {
	switch(type) {
		case RESET_ACTION:
			return defaultState;
		case MODULE_ACTION.SET:
			return {
				...state,
				list: modules,
				cache: {...updateCache()}
			};
		default:
			return state;
	}
};