import BaseModel from "./BaseModel";
import PropTypes from "prop-types";
import {isFunction} from "lodash";
import round from "round-precision";
import {connect} from "react-redux";
import {withChange} from "../Logic/withInit";
import {orderAction__collectServices} from "../actions/orderActions";
import {moment} from "../Logic/Moment";
import {Icon, Popup as SemanticPopup} from "semantic-ui-react";
import * as React from "react";
import {Line, Spacer} from "../components/partials/PopupInfo";
import {ProviderComponent} from "../components/partials";

const Provider = ({service, children, placeholder}) => service && isFunction(children) ? children(service) : placeholder;
Provider.propTypes = {
	id: PropTypes.number.isRequired,
	children: PropTypes.func,
	placeholder: PropTypes.node,
	dynamic: PropTypes.bool
};
Provider.defaultProps = {
	placeholder: null,
	dynamic: true
};

const IconBool = ({truish}) => <Icon name={truish ? "check circle outline" : "minus circle"} color={truish ? "green" : "red"}/>;
IconBool.propTypes = {
	truish: PropTypes.bool.isRequired
};

const PopupInfo = ({service: s, inverted, style, ...props}) => {
	if (!s) {
		return null;
	}
	let service;
	if (s instanceof OrderService) {
		service = s;
	} else {
		service = new OrderService(s);
	}
	return (
		<table style={{borderCollapse: "collapse", ...style}} cellPadding={6} border={0} {...props}>
			<tbody>
				<Line inverted={inverted} name={"Titel"}>{service.service_name}</Line>
				<Spacer/>
				<Line inverted={inverted} name={"Direktservice"}><IconBool truish={service.custom}/></Line>
				<Spacer/>
				<Line inverted={inverted} name={"Erweiterung"}><IconBool truish={service.extension}/></Line>
				<Spacer/>
				<Line inverted={inverted} name={"Tüv"}><IconBool truish={service.is_tuv}/></Line>
				<Spacer/>
				<Line inverted={inverted} name={"Pausiert"}><IconBool truish={service.is_paused}/></Line>
				<Spacer/>
				<Line inverted={inverted} name={"Freigegeben"}><IconBool truish={service.released}/></Line>
				<Spacer/>
				<Line inverted={inverted} name={"Arbeitszeit"}>{service.hours_of_work_resource_string} {service.different_hours_of_work ? `(${service.hours_of_work_string})` : ''} Std.</Line>
				<Spacer/>
				<Line inverted={inverted} name={"Stundenpreis"}>€ {service.price}</Line>
				<Spacer/>
				<Line inverted={inverted} name={"Beendet"}><IconBool truish={service.is_finished}/> {service.is_finished && service.finished_moment('L [-] LT')}</Line>
			</tbody>
		</table>
	);
};

const Popup = ({order_service, children, ...props}) => order_service ? <SemanticPopup trigger={children} {...props} content={<PopupInfo inverted={props.inverted} service={order_service}/>}/> : children;
Popup.propTypes = {
	order_service: PropTypes.oneOfType([PropTypes.object]),
	children: PropTypes.node,
	inverted: PropTypes.bool,
	flowing: PropTypes.bool,
	position: PropTypes.string
};
Popup.defaultProps = {
	inverted: true,
	flowing: true,
	position: "bottom left"
};

export class OrderService extends BaseModel {
	_validateModel(model) {
		return ("order_service_id" in model) && ("hours_of_work" in model) && ("custom" in model);
	}
	
	get order_service_id() {
		return Number(this.__model.order_service_id);
	}
	
	get id() {
		return this.order_service_id;
	}
	
	get custom() {
		return !!this.__model.custom;
	}
	
	get released() {
		return !!this.__model.released;
	}
	
	get extension() {
		return !!this.__model.extension;
	}
	
	get service_id() {
		return Number(this.__model.service_id);
	}
	
	get order_id() {
		return Number(this.__model.order_id);
	}
	
	get hours_of_work() {
		return round(this.__model.hours_of_work || 0, 1);
	}
	
	get hours_of_work_string() {
		return OrderService.timeStringConvert(this.hours_of_work);
	}
	
	get hours_of_work_resource() {
		return round(this.__model.hours_of_work_resource || 0, 1);
	}
	
	get hours_of_work_resource_string() {
		return OrderService.timeStringConvert(this.hours_of_work_resource);
	}
	
	get different_hours_of_work() {
		return this.hours_of_work !== this.hours_of_work_resource;
	}
	
	get price() {
		return Number(round(this.__model.price, 2));
	}
	
	get service_name() {
		return this.__model.service_name;
	}
	
	get is_tuv() {
		return !!this.__model.is_tuv;
	}
	
	get paused_by() {
		return this.__model.paused_by ? Number(this.__model.paused_by) : null;
	}
	
	get is_paused() {
		return !!this.paused_by;
	}
	
	get finished_at() {
		return this.__model.finished_at;
	}
	
	get finished_moment() {
		return this.finished_at ? moment(this.finished_at) : null;
	}
	
	get is_finished() {
		return !!this.finished_at;
	}
	
	get deleted_at() {
		return this.__model.deleted_at;
	}
	
	get is_deleted() {
		return !!this.deleted_at;
	}
	
	get deleted_moment() {
		return this.deleted_at ? moment(this.deleted_at) : null;
	}
	
	get created_at() {
		return this.__model.created_at;
	}
	
	get created_moment() {
		return moment(this.created_at);
	}
	
	static timeConvert = (hours) => [Math.floor(hours), Math.round((hours - Math.floor(hours)) * 60)];
	static timeStringConvert = (_hours) => {
		let [hours, minutes] = OrderService.timeConvert(_hours).map(String);
		return `${hours}:${minutes.padStart(2, '0')}`;
	};
	
	static Provider = connect(
		(state, props) => {
			const s = state.map.order2services[props.id];
			return {
				service: s ? new OrderService(s) : null
			};
		},
		(dispatch) => ({
			withChange: ({id}) => dispatch(orderAction__collectServices([id]))
		})
	)(withChange(Provider, {access: 'id', dynamicIndex: 'dynamic'}));
	
	static Popup = Popup;
	static PopupInfo = PopupInfo;
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.order2services[id];
			data = data ? new OrderService(data) : null;
			return {data};
		}
	)(ProviderComponent)
	
	static ConnectedList = connect(
		(state, {ids, filter}) => {
			let data = ids.map(sid => {
				let data = state.map.order2services[sid];
				return data ? new OrderService(data) : null;
			});
			if (filter) {
				data = data.filter(Boolean);
			}
			// data = data ? new OrderService(data) : null;
			return {data};
		}
	)(ProviderComponent);
}