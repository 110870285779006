import {LOADER_ACTION} from "./types";
import {connect} from "react-redux";
import * as React from "react";
import {compose} from "redux";

const _set = id => ({type: LOADER_ACTION.SET, id});
const _unset = id => ({type: LOADER_ACTION.UNSET, id});
const _extend = (id, extend) => [id, ...extend].filter(el => el !== null && el !== undefined).join('-');

export const addLoader = (source=null) => ({type: LOADER_ACTION.ADD, source});
export const removeLoader = (source=null) => ({type: LOADER_ACTION.DONE, source});

export const setLoader = (id, ...extend) => _set(_extend(id, extend));
export const unsetLoader = (id, ...extend) => _unset(_extend(id, extend));

export const isLoading = (state, id, ...extend) => !!state.loaders.detail[_extend(id, extend)];

const Clear = Component => props => {
	const {dispatch, ...rprops} = props;
	return <Component {...rprops}/>
};


export const withLoading = (index, loader, ...extend) => (Component) => compose(
	connect(
		state => ({
			[index]: isLoading(state, loader, ...extend)
		})
	)
)(Component);

export const withLoadingWithoutDispatch = (index, loader, ...extend) => (Component) => compose(
	connect(
		state => ({
			[index]: isLoading(state, loader, ...extend)
		})
	),
	Clear
)(Component);


export const withLoadings = (...list) => (Component) => connect(
	state => {
		const back = {};
		for (const [index, loader, ...extend] of list) {
			back[index] = isLoading(state, loader, ...extend);
		}
		return back;
	}
)(Component);

export const withLoader = (callback) => Component => {
	return compose(
		connect(
			(state, props) => callback(state, props, isLoading)
		)
	)(Component)
};
