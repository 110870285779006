import * as React from "react";
import PropTypes from "prop-types";
import {joiner} from "../Logic/helper";
import lang from "../i18n";
import {store} from "../store";
import {isFunction} from "lodash";
import hasher from "object-hash";



class ProviderList extends React.Component {
	static propTypes = {
		list: PropTypes.arrayOf(PropTypes.func).isRequired,
		children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
		placeholder: PropTypes.node,
		callee: PropTypes.arrayOf(PropTypes.bool),
		stateMapper: PropTypes.arrayOf(PropTypes.func),
		renderAlways: PropTypes.bool,
		inOrder: PropTypes.bool
	};
	static defaultProps = {
		placeholder: null,
		list: [],
		callee: [],
		stateMapper: [],
		renderAlways: false,
		inOrder: false
	};
	
	state = {
		data: null,
		loading: false
	};
	
	async componentDidMount() {
		const {list, callee, inOrder} = this.props;
		try {
			this.setState({loading: true});
			let data = [];
			if (inOrder) {
				for (const i in list){
					const f = list[i];
					const call = await callee[i] ? f() : store.dispatch(f());
					data.push(call);
				}
			} else {
				data = await Promise.all(list.map(async (f, i) => await callee[i] ? f() : store.dispatch(f())));
			}
			this.setState({data});
		} finally {
			this.setState({loading: false});
		}
	}
	
	shouldComponentUpdate(props, state, nextContext) {
		if (this.props.renderAlways || props.renderAlways){
			return true;
		}
		if (hasher(this.state.data) !== hasher(state.data)) {
			return true;
		}
		
		
		return true;
	}
	
	render() {
		const {children, placeholder, renderAlways} = this.props;
		if(renderAlways){
			return children;
		}
		const {data} = this.state;
		if (data && isFunction(children)) {
			return children(...data);
		}
		return placeholder;
	}
}

export default class BaseModel {
	_validateModel(model) {
		return true;
	}
	constructor(model, t) {
		if (!model || !this._validateModel(model.origin ? model.origin : model)) {
			// console.error("model data is:", model);
			console.warn('model is', model);
			throw new Error('No valid model data given');
		}
		this.__model = model.origin ? model.origin : model;
		this.t = t || lang.getFixedT();
	}
	static _(model, t) { return new this(model, t); }
	
	join(...args) {
		return joiner(this.__model, this.t, ...args);
	}
	
	get origin() {
		return this.__model;
	}
	
	static ProviderList = ProviderList;
}