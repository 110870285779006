import * as React from "react";
import {EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import {moment, parseDate, parseTime, toISO} from "../../../Logic/Moment";
import PropTypes from "prop-types";
import {falseNull, trueNull} from "../../../Logic/extensions";
import {Button, Divider, Form, Label, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_ORDER, SUB_ORDER_CHANGE_TIMES_RELOAD} from "../../../actions";
import {orderAction__updateRanges} from "../../../actions/orderActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {isFunction} from "lodash";
import {publish, subscribe} from "../../../Logic/PubSub";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {MaterialCompoundDateInput} from "../../../Tools/DatePicker";
import {TimeTrans} from "../../../Tools";
import {Trans, translate} from "react-i18next";
import {AlternateOrderAppointmentFinder} from "../../../components/intern/orders/finder/AlternateOrderAppointmentFinder";
import {MessageGroup} from "../../../components/partials/Segments";
import hash from 'object-hash';
import {startOfMinute} from 'date-fns';
import {MobileAwarePopup} from "../../../components/partials/MiniComponents";

const Notice = ({consultant_id, rangeStart, rangeEnd}) => {
	return (
		<div>
			{falseNull(consultant_id) && <div>
				<p className={'align center'}><Trans defaults={'Der Termin kann problemlos ausserhalb des folgenden zeitlichen Rahmens geändert werden.'}/></p>
				<EasyFlex align={EasyFlex.align.CENTER}>
					<Label><TimeTrans type={'full'} value={rangeStart.toDate()}/></Label>
					<span style={{marginLeft: 8, marginRight: 8, fontWeight: 'bold', fontSize: 16}}>→</span>
					<Label><TimeTrans type={'full'} value={rangeEnd.toDate()}/></Label>
				</EasyFlex>
			</div>}
			{trueNull(consultant_id) && <div>
				<p className={'align center'}><Trans defaults={'Der Termin kann problemlos zeitlich nach hinten verlegt werden.'}/></p>
				<EasyFlex align={EasyFlex.align.CENTER}>
					<Label style={{position: 'relative'}}><Label.Detail style={{marginRight: 4}}><Trans defaults={'Beraterabhängig'}/></Label.Detail>
						<div style={{display: 'inline-block', position: 'absolute', 'width': 1, background: '#bbb', 'height': '100%', top: 0}}/>
						<span style={{marginLeft: 4}}><TimeTrans type={'full'} value={rangeStart.toDate()}/></span></Label>
					<span style={{marginLeft: 8, marginRight: 8, fontWeight: 'bold', fontSize: 16}}>→</span>
					<Label><TimeTrans type={'full'} value={rangeEnd.toDate()}/></Label>
				</EasyFlex>
			</div>}
		</div>
	)
};

export class OrderChangeTimesView extends React.PureComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		onUpdate: PropTypes.func,
		loading: PropTypes.bool,
		id: PropTypes.string,
		hideReload: PropTypes.bool,
		hideSubmit: PropTypes.bool,
		hideToggle: PropTypes.bool,
		flexProps: PropTypes.object,
		onAfterChange: PropTypes.func,
		acces: PropTypes.any,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		onUpdate: () => alert('OrderChangeTimes::onUpdate() is not implemented yet!'),
		onAfterChange: () => {
		},
		onNotification: dispatchSnack
	};
	
	state = {
		updating: false,
		extended_search: false,
		deliver: startOfMinute(parseDate(this.props.order.deliver_point)),
		target: startOfMinute(parseDate(this.props.order.fetch_point)),
		deliver_date: parseDate(this.props.order.deliver_point),
		deliver_time: parseTime(this.props.order.deliver_point),
		fetch_date: parseDate(this.props.order.fetch_point),
		fetch_time: parseTime(this.props.order.fetch_point),
		error: null
	};
	
	
	componentDidMount() {
		if (this.props.access) {
			this.props.access = this;
		}
		this.reloadUnsubscribe = subscribe(SUB_ORDER_CHANGE_TIMES_RELOAD, this.reset);
	}
	
	componentWillUnmount() {
		this.reloadUnsubscribe && this.reloadUnsubscribe();
	}
	
	update = async () => {
		const {onUpdate, onNotification, onAfterChange} = this.props;
		const {deliver, target} = this.state;
		try {
			this.setState({updating: true, extended_search: false});
			const result = await onUpdate(toISO(deliver), toISO(target));
			if (result.ack) {
				onNotification('Termin wurde erfolgreich angepasst');
				onAfterChange(result);
			} else {
				onNotification('Der Termin muss neu verschoben werden.', 'black');
				this.setState({extended_search: true});
			}
		} finally {
			this.setState({updating: false});
		}
	};
	
	
	_procValidation = memoizeOne(
		(deliver, target, t) => {
			if (!deliver) return t('...', 'Abgabedatum fehlt!');
			if (!target) return t('...', 'Abholdatum fehlt!');
			if (target <= deliver) return t('...', 'Abholzeit bevor Abgabezeit');
			return null;
		}
	)
	
	_validate = memoizeOne(
		(t, start, end, currentStart, currentEnd) => {
			let errors = [];
			if (currentStart > start) {
				errors.push(t('...', 'Annahmezeit ist zu spät'));
			}
			if (currentEnd < end) {
				errors.push(t('...', 'Abgabezeit ist zu früh'));
			}
			return errors.length ? errors : null;
		}
	);
	
	validate = () => {
		this.setState({error: this._procValidation(this.state.deliver, this.state.target, this.props.t)});
	};
	
	get error() {
		return this._procValidation(this.state.deliver, this.state.target, this.props.t);
	}
	
	
	reset = () => this.setState({
		deliver: startOfMinute(parseDate(this.props.order.deliver_point)),
		target: startOfMinute(parseDate(this.props.order.fetch_point)),
		deliver_date: new Date(this.props.order.deliver_point),
		deliver_time: moment(this.props.order.deliver_point).format("HH:mm"),
		fetch_date: new Date(this.props.order.fetch_point),
		fetch_time: moment(this.props.order.fetch_point).format("HH:mm"),
		updating: false,
		extended_search: false,
		error: null
	});
	
	rangeDelimiter = memoizeOne(
		(start, end, duration, consultant_duration) => {
			let range = [start, end].map(r => moment(r));
			range[0] = range[0].subtract(consultant_duration, 'minute');
			range[1] = range[1].add(duration, 'minute');
			return range;
		}
	);
	
	render() {
		const {
			order: {
				range_start,
				range_end,
				consultant_id,
				post_process_duration,
				consultant_duration
			},
			id,
			hideReload,
			hideSubmit,
			hideToggle,
			t
		} = this.props;
		const {
			deliver,
			target,
			updating,
			extended_search
			// error
		} = this.state;
		const hideAll = Boolean(hideReload && hideSubmit && hideToggle);
		const [rangeStart, rangeEnd] = this.rangeDelimiter(startOfMinute(new Date(range_start)), startOfMinute(new Date(range_end)), post_process_duration, consultant_id ? consultant_duration : 0);
		const errors = this._validate(t, rangeStart, rangeEnd, deliver, target);
		return (
			<div>
				{falseNull(extended_search) && <Form id={id} className={"order-change-times"} loading={updating} onSubmit={errors ? null : this.update}>
					<Notice consultant_id={consultant_id} rangeStart={rangeStart} rangeEnd={rangeEnd}/>
					<Divider style={{marginTop: 25, marginBottom: 35}}/>
					<div>
						<EasyFlex align={EasyFlex.align.CENTER} style={{marginBottom: 40}}>
							<MaterialCompoundDateInput
								dateLabel={<Trans defaults='Annahme'/>}
								timeLabel={<span>&nbsp;</span>}
								timePlaceholder={'__:__'}
								date={deliver}
								baseDate={deliver}
								onChange={deliver => this.setState({deliver: startOfMinute(deliver)})}
								selectsStart
								startDate={deliver}
								endDate={target}
								disabled
							/>
							<MaterialCompoundDateInput
								dateLabel={<Trans defaults='Abgabe'/>}
								timeLabel={<span>&nbsp;</span>}
								timePlaceholder={'__:__'}
								date={target}
								baseDate={target}
								onChange={target => this.setState({target: startOfMinute(target)})}
								selectsStart
								startDate={deliver}
								endDate={target}
							/>
						</EasyFlex>
						{trueNull(errors) && <MessageGroup>
							{errors.map(e  => <Message warning={true} key={hash(e)}><p className="align center">{e}</p></Message>)}
						</MessageGroup>}
						
						{falseNull(hideAll) && <Flex vmargin={20} align={"flex-end"} valign={"center"}>
							<Button.Group>
								{falseNull(hideReload) && <MobileAwarePopup flowing inverted content={<Trans defaults={'Zurücksetzen'}/>}><Button type={'button'} icon={'redo'} onClick={this.reset}/></MobileAwarePopup>}
								<MobileAwarePopup flowing inverted content={<Trans defaults={'Termin komplett verschieben'}/>}><Button icon={'level down alternate'} color={'brown'} onClick={() => this.setState({extended_search: true})}/></MobileAwarePopup>
								{falseNull(hideSubmit) && <Button disabled={updating || errors !== null} loading={updating} positive><Trans defaults="Ändern" i18nKey="actions.change"/></Button>}
							</Button.Group>
						</Flex>}
					</div>
				</Form>}
				
				{trueNull(extended_search) && <div>
					<Notice consultant_id={consultant_id} rangeStart={rangeStart} rangeEnd={rangeEnd}/>
					<Divider style={{marginTop: 25, marginBottom: 35}}/>
					<EasyFlex align={EasyFlex.align.END}><MobileAwarePopup flowing inverted content={'Terminrahmen ändern (Maske anzeigen)'}><Button icon={'level up alternate'} color={'brown'} onClick={() => this.setState({extended_search: false})}/></MobileAwarePopup></EasyFlex>
					<AlternateOrderAppointmentFinder order={this.props.order} onAfterSave={() => this.setState({extended_search: false}, () => this.reset())}/>
				</div>}
			</div>
		);
	}
}

OrderChangeTimesView = connect(
	(state, props) => ({
		loading: isLoading(state, PROC_ORDER, props.order.order_id)
	}),
	(dispatch, props) => {
		
		return {
			onUpdate: (deliver_point, fetch_point, onSuccess) => dispatch(orderAction__updateRanges(props.order.order_id, deliver_point, fetch_point, result => {
				// dispatch(addSnackbar('Termin wurde aktualisiert'));
				isFunction(onSuccess) && onSuccess(result);
			}))
		};
	}
)(OrderChangeTimesView);
OrderChangeTimesView = translate()(OrderChangeTimesView);

export const requestOrderChangeTimesReload = () => publish(SUB_ORDER_CHANGE_TIMES_RELOAD);