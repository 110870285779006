import {DIALOG_ACTION} from "../actions/types";

export default (state = [], action) => {
	switch (action.type) {
		case DIALOG_ACTION.ADD:
			return [...state, action.dialog];
		case DIALOG_ACTION.DISMISS:
			if ( state.length ) {
				state.pop();
				return [...state];
			}
			return state;
		default:
			return state;
	}
};