/* eslint-disable */
import * as React from "react";
import Timeline, {CustomMarker, DateHeader, SidebarHeader, TimelineHeaders} from "react-calendar-timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import {moment} from "../../Logic/Moment";
// import {DayPicker} from "../partials/DayPicker";
import {GET} from "ith-fetch";
import {EasyFlex, Flex, FlexChild} from "../partials/ActionHeader";
import {Checkbox, List, ListItem} from "material-ui";
import {COLORS, SECTION} from "../../Logic/constants";
import {get, isFunction} from "lodash";
import {Button, Icon, Modal, Segment} from "semantic-ui-react";
import {blackOrWhite, deepMemoize as memoize} from "../../Logic/extensions";
import ReactTooltip from "react-tooltip";
import {Trans, translate} from "react-i18next";
import {OrderStateProvider} from "../../Logic/OrderStateProvider";
import {withRights} from "../../Tools";
import KeyHandler from "react-key-handler";
import {KeyModifier} from "../../Logic/KeyHandler";
import {OrderRangeView} from "../intern/orders/OrderStatusView";
import {OnlineIcon} from "../../Tools/OnlineIcon";


const Star = ({visible, title}) => visible ? <span title={title}>*</span> : null;

const StatusView = ({order}) => {
	return (
		<OrderStateProvider order={order} negative={COLORS.SEMANTIC_RED}>{
			({Icon, color}) =>
				<Flex style={{backgroundColor: COLORS.SECONDARY, padding: 8}} align={'space-around'} valign={"center"} gutterWidth={15}>
					{/*<FlexChild><Icon.Released color={color.released}/></FlexChild>*/}
					<FlexChild><Icon.Delivered color={color.delivered}/></FlexChild>
					<FlexChild><Icon.Progress color={color.progress}/></FlexChild>
					<FlexChild><Icon.Finished color={color.finished}/></FlexChild>
					<FlexChild><Icon.Fetched color={color.fetched}/></FlexChild>
				</Flex>
		}</OrderStateProvider>
	);
};

const CarView = ({order: {hsn, tsn, registration_mark, chassis_number, vehicle_name}, ...props}) => (
	<div {...props}>
		{vehicle_name && <div style={{textAlign: "center"}}>{vehicle_name}</div>}
		<Flex align={"flex-start"} valign={"center"} >
			<Icon name={"car"}/>
			<div style={{minWidth: 15}}/>
			<div>
				
				<table>
					<tbody>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>HSN/TSN:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"} style={{whiteSpace: "nowrap", fontSize: 11}}>{hsn} / {tsn}</span></td>
					</tr>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>Kennzeichen:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"} style={{whiteSpace: "nowrap", fontSize: 11}}>{registration_mark || "n.v."}</span></td>
					</tr>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>F.g.N.:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"} style={{whiteSpace: "nowrap", fontSize: 11}}>{chassis_number || "n.v."}</span></td>
					</tr>
					</tbody>
				</table>
			
			</div>
		</Flex>
	</div>
);

let PopupView = ({order, t}) => {
	const contact = `${order.first_name} ${order.last_name}`;
	const company = order.company_name;
	let title = company || contact;
	title = t(`client.salutations.${order.salutation}`)  + " " + title;
	return <Segment basic>
		<StatusView order={order}/>
		<div style={{height: 16}}/>
		<em style={{display: "block", textAlign: "center"}}>{title}</em>
		<div style={{height: 16}}/>
		<CarView order={order} style={{padding: 8, backgroundColor: COLORS.SECONDARY}}/>
		<div style={{height: 16}}/>
		<OrderRangeView order={order}/>
		{/*<OrderDateRangeProvider order={order}>{p =>
			<React.Fragment>
				<div style={{textAlign: "center", color: "gray"}}>{p.created_at.format(p.format)}</div>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<div style={{textAlign: "center", color: COLORS.SEMANTIC_GREEN}}>{p.delivered.format(p.format)} <Star title={'Abgabezeitpunkt'} visible={p.is_delivered}/></div>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<div style={{textAlign: "center", color: COLORS.SEMANTIC_ORANGE}}>{p.latest.format(p.format)}</div>
				<div style={{textAlign: "center", color: p.separator.color}}>{p.separator.value}</div>
				<div style={{textAlign: "center", color: COLORS.SEMANTIC_RED}}>{p.end.format(p.format)} <Star title={'Übergabezeitpunkt'} visible={p.is_fetched}/></div>
			</React.Fragment>
		}</OrderDateRangeProvider>*/}
	</Segment>;
};
PopupView = translate()(PopupView);

export class GrouplineTest extends React.Component {
	current_day = 'today';
	state = {
		groups: [{ id: 1, title: 'group 1', stackItems: true }, { id: 2, title: 'group 2' }, { id: 3, title: 'group 3' }],
		items: [],
		schedule: {},
		workers: {},
		resources: {},
		min: moment().startOf('day'),
		max: moment().endOf('day'),
		data: {},
		wlist: {},
		wr: [],
		now: Date.now(),
		menuIsOpen: false,
		isGrouped: false,
		isLoading: false,
		tooltip: null,
		orders: {}
	};
	
	showMenu = menuIsOpen => () => this.setState({menuIsOpen});
	toggleGrouped = () => this.setState(state => ({...state, isGrouped: !state.isGrouped}));
	setTooltip = (item) => e => "_order" in item ? this.setState({
		tooltip: {
			item,
			x: e.clientX,
			y: e.clientY
		}
	}) : null;
	unsetTooltip = () => this.setState({tooltip: null});
	
	dateChangeWatcher = (start, end) => trigger => {
		const next = moment(Math.round((start + end) / 2)).format("YYYY-MM-DD");
		if (isFunction(trigger) && this._dateWatcher !== next) {
			this._dateWatcher = next;
			trigger(next, start, end);
		}
	};
	
	handleSchedule = schedule => {
		const {workers} = this.state;
		const {user} = this.props;
		const _groups = [];
		let keys = Object.keys(schedule);
		if (user.house_id === 8) {
			keys = [27, 30, 58, 31, 55, 40, 39];
		} else if (user.house_id === 11) {
			keys = [61, 62, 76, 77, 78, 79];
		}
		keys.forEach(id => {
			let title = `Worker-${id}`;
			let online = null;
			if (workers[id]) {
				title = workers[id].name;
				if (workers[id].first_name) {
					title = `${title} ${workers[id].first_name.substr(0, 1).toUpperCase()}.`;
				}
				title = `${title} [${id}]`;
				if ('logged_at' in workers[id]) {
					online = !!workers[id].logged_at;
				}
				if (null !== online) {
					title = <span><OnlineIcon style={{transform: 'translateY(-3px)'}} size={'mini'} online={online}/>{title}</span>
				}
			}
			_groups.push(
				{id: `schedule-${id}`, title, type: 'name'},
				{id: `work-${id}`, title: <div style={{textAlign: 'right'}}>Arbeit</div>, stackItems: true, type: 'work'},
				{id: `spacer-${id}`, title: '', height: 20, type: 'spacer'}
			);
		});
		const _items = [];
		let c = 1;
		Object.keys(schedule).forEach(id => {
			schedule[id].forEach(entry => {
				_items.push({
					id: c++,
					group: `schedule-${id}`,
					title: 'Arbeitszeit',
					start_time: entry.start,
					end_time: entry.end,
					canMove: false,
					canChangeGroup: false,
					canResize: false,
					itemProps: {
						style: {
							background: "rgba(0,0,0,0.8)"
						}
					}
				});
			});
		});
	
		this.setState({groups: [..._groups], items: [..._items]});
	};
	
	handleBigSchedule = ({list, min_max, orderlist, orders, paused, schedule, wlist, workers: w, workloads}) => {
		const {resources} = this.state;
		const items = [];
		const color = ['#2196f3', '#14578c'];
		const colors = {};
		list.sort((a, b) => new Date(a.start_point).getTime() - new Date(b.start_point).getTime());
		list.forEach(order => {
			let clr = get(resources, [order.resource_id, 'color'], null);
			let i = colors[order.workers_id] || 0;
			let title = order.last_name;
			if(order.first_name) {
				title = `${title} ${order.first_name.substr(0,1).toUpperCase()}.`;
			}
			if (order.registration_mark) {
				title = `${title} <${order.registration_mark}>`;
			}
			let background;
			if (clr) {
				background = clr;
			} else {
				background = color[i % 2];
			}
			items.push({
				id: order.order_service_resource_id,
				group: `work-${order.workers_id}`,
				title,
				_order: order,
				start_time: new Date(order.start_point),
				end_time: new Date(order.end_point),
				canMove: false,
				canChangeGroup: false,
				canResize: false,
				itemProps: {
					style: {
						overflow: 'hidden',
						background,
						color: this.fontColor(background)
					}
				}
			});
			if (!clr) {
				colors[order.workers_id] = i+1;
			}
		});
		
		this.setState(state => ({...state, items: [...state.items, ...items], isLoading: false, orders: orders.orders}));
	};
	
	fontColor = color => blackOrWhite(color);
	
	itemRenderer = ({item, itemContext, getItemProps, getResizeProps}) => {
		const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
		return (
			<div {...getItemProps(item.itemProps)} onMouseEnter={this.setTooltip(item)} onMouseMove={this.setTooltip(item)} onMouseLeave={this.unsetTooltip}>
				{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
				
				<div
					className="rct-item-content"
					style={{ maxHeight: `${itemContext.dimensions.height}` }}
				>
					{itemContext.title}
				</div>
				
				{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
			</div>
		);
	};
	
	handleWorkers = workers => {
		this.setState({workers, isLoading: true});
		GET('/control/schedule').then(this.handleSchedule);
		GET('/order/day/today/true').then(this.handleBigSchedule);
	};
	
	handleDay = day => {
		this.setState({isLoading: true});
		this.current_day = day;
		GET('/control/schedule/' + day).then(this.handleSchedule);
		GET('/order/day/' + day + '/true').then(this.handleBigSchedule);
	};
	
	mapColors = memoize(
		(list, resources) => {
			list.forEach(entry => {
				const wid = entry._order.workers_id;
				const color = get(resources, [wid, 'color'], entry.itemProps.style.background);
				return {
					...entry,
					itemProps: {
						...entry.itemProps,
						style: {
							...entry.itemProps.style,
							background: color
						}
					}
				}
			});
			return [...list];
		}
	);
	
	getOriginOrder = order => ({...order, ...(this.state.orders[order.order_id] || {})});
	
	componentDidMount() {
		this._dateWatcher = moment().format("YYYY-MM-DD");
		this._nowTimer = window.setInterval(() => this.setState({now: Date.now()}), 1000);
		this._reloadTimer = window.setInterval(() => {
			this.handleDay(this.current_day);
		}, 60000);
		GET('/workers/all').then(this.handleWorkers);
		GET('/resource').then(res => this.setState({resources: res, items: this.mapColors(this.state.items, res)}));
	}
	
	componentWillUnmount() {
		window.clearInterval(this._nowTimer);
		window.clearInterval(this._reloadTimer);
	}
	
	render() {
		const {
			groups,
			items,
			menuIsOpen,
			isGrouped,
			isLoading,
			tooltip
		} = this.state;
		return (
			<div data-for={'workinfo'} data-tip>
				<KeyModifier ctrl={false} shift={false} alt={false}>
					<KeyHandler
						keyValue={"r"}
						onKeyHandle={this.handleDay.bind(this, this.current_day)}
					/>
				</KeyModifier>
				<div>
					<Timeline
						groups={groups}
						items={items}
						defaultTimeStart={moment().startOf('day')}
						defaultTimeEnd={moment().endOf('day')}
						horizontalLineClassNamesForGroup={group => [group.type]}
						groupRenderer={({group}) => <div className={group.type}>{group.title}</div>}
						timeSteps={{
							second: 1,
							minute: 6,
							hour: 1,
							day: 1,
							month: 1,
							year: 1
						}}
						maxZoom={7 * 86400 * 1000}
						onTimeChange={(s,e,d) => {
							this.dateChangeWatcher(s,e)(date => {
								this.handleDay(date);
							});
							d(s,e);
						}}
						itemRenderer={this.itemRenderer}
					>
						<TimelineHeaders>
							<SidebarHeader>
								{({ getRootProps }) => {
									return <div {...getRootProps()}>
										<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{height: "100%"}}>
											{/*<IconButton onClick={this.showMenu(true)}><IconMore color={COLORS.PRIMARY}/></IconButton>*/}
											{isLoading && <Icon name={"spinner"} loading={isLoading} color={"blue"}/>}
										</EasyFlex>
									</div>
								}}
							</SidebarHeader>
							<DateHeader unit="primaryHeader" /><DateHeader />
						</TimelineHeaders>
						<CustomMarker date={this.state.now}>
							{({ styles, date }) => <div style={{...styles, backgroundColor: 'red'}} />}
						</CustomMarker>
					</Timeline>
				</div>
				{/*<BasicDialog open={menuIsOpen} onClose={this.showMenu(false)} closeButton closeRight>
					{menuIsOpen && <div>
						<List>
							<ListItem
								primaryText={"Gruppiert"}
								secondaryText={isGrouped ? "Termine werden gruppiert (Auftragsliste)" : "Interne Darstellung der Termine"}
								rightToggle={<Checkbox onCheck={this.toggleGrouped} checked={isGrouped} />}
							/>
						</List>
					</div>}
				</BasicDialog>*/}
				{menuIsOpen && <Modal open centered={false} onClose={this.showMenu(false)}>
					<Modal.Content>
						<div>
							<List>
								<ListItem
									primaryText={"Gruppiert"}
									secondaryText={isGrouped ? "Termine werden gruppiert (Auftragsliste)" : "Interne Darstellung der Termine"}
									rightToggle={<Checkbox onCheck={this.toggleGrouped} checked={isGrouped} />}
								/>
							</List>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showMenu(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{tooltip && <ReactTooltip id={'workinfo'} style={{background: '#222'}}>
					<PopupView order={this.getOriginOrder(tooltip.item._order)}/>
				</ReactTooltip>}
			</div>
		);
	}
}

GrouplineTest = withRights(SECTION.ORDERS, GrouplineTest);