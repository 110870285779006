/**
 * EditField.js
 *
 * extends semantic-ui's FormInput.
 *
 * basic design:
 *  - 1 icon on the left und black/blue design. Semantic-UI's Icon or material-ui's svg-icon placeable here, or just text as label
 *  - 1 icon - if input is editable - on the right, always an pencil icon. this icon also works as error hover for error-popup-message
 *  - 1 label on top of the input (optional)
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import {FormInput} from "semantic-ui-react";
import {FormLabel} from "../../Logic/extensions";
import DefaultIcon from 'material-ui/svg-icons/editor/short-text';
import {translate} from "react-i18next";
import {COLOR_SEMANTIC_ERROR} from "../../Logic/constants";
import isArray from 'lodash/isArray';
import cn from 'classnames';
import isFunc from "lodash/isFunction";

/**
 * function to inject some styles, etc. into given icon...
 * @param icon
 * @param color
 * @param error
 * @return {*}
 * @private
 */
const _injectIcon = (icon, color='black', error = false) => typeof icon === 'string' ? <span
	style={{color: error ? COLOR_SEMANTIC_ERROR : color}}>{icon}</span> : React.cloneElement(icon, {color: error ? COLOR_SEMANTIC_ERROR : color});


/**
 * Pencil icon on the right, originally set with iconPosition 'left right', but this validates the propTypes.
 * Thus using this style for the input if icon is visible
 * @type {{borderTopRightRadius: number, borderBottomRightRadius: number, borderRight: string}}
 */
const INPUT_ON_RIGHT_ICON_STYLE = {
	borderTopRightRadius: 0,
	borderBottomRightRadius: 0,
	borderRight: 'none'
};
/**
 *
 * @param t translation method given by HOC
 * @param tReady translation property by HOC
 * @param i18n translation property by HOC
 * @param errorText show error including this message as popup
 * @param error set error flag (red input)
 * @param icon set the left icon
 * @param editable if input is editable
 * @param text label and placeholder value, if not explicit set
 * @param label text above input
 * @param labelPosition
 * @param placeholder in input itself
 * @param readOnly html5 readonly
 * @param editPopup show popup with this content
 * @param props other props of Semantic-UI's FormInput
 * @return {*}
 * @constructor
 */
class EditField extends React.Component {
	static propTypes = {
		icon: PropTypes.any.isRequired,
		editable: PropTypes.bool,
		editPopup: PropTypes.string,
		placeholder: PropTypes.string,
		label: PropTypes.node,
		text: PropTypes.string,
		readOnly: PropTypes.bool,
		errorText: PropTypes.oneOfType([
			PropTypes.string, PropTypes.array
		]),
		error: PropTypes.bool,
		autoComplete: PropTypes.string,
		labelPosition: PropTypes.string,
		errorBeneeth: PropTypes.bool,
		before: PropTypes.node,
		inline: PropTypes.bool
	};
	static defaultProps = {
		icon: <DefaultIcon/>,
		editable: false,
		readOnly: false,
		editPopup: 'Kann bearbeitet werden',
		errorText: null,
		error: false,
		autoComplete: 'off',
		errorBeneeth: false,
		inline: false
	};
	
	state = {
		focused: false
	};
	
	handleFocus = (focused, next) => (e) => {
		this.setState({focused});
		if (next) next(e);
	};
	
	handleClick = (next) => (e, data) => {
		const blocked = !this.props.editable || this.props.readyOnly;
		if (isFunc(next) && !blocked) {
			return next(e, data);
		}
		e.stopPropagation();
		e.preventDefault();
		return false;
	};
	
	translate = (errorText) => isArray(errorText) ? this.props.t.call(null, errorText[0], errorText[1] || {}) : this.props.t(errorText);
	
	render() {
		const {t, tReady, i18n, errorText, required, before, error, inline, icon, editable, text, label, labelPosition, errorBeneeth, placeholder, readOnly, editPopup: content, onFocus, onBlur, onClick, children, isValid, ...props} = this.props;
		const blocked = readOnly || !editable;
		return (
			<React.Fragment>
				<FormInput className={cn('ith-edit-field', {'label-on-top': labelPosition === 'top', focused: this.state.focused, readonly: blocked, "error-beneeth": errorBeneeth, inline})}
				           labelPosition={'left'}
				           error={error || Boolean(errorText)}
				           label={label || text}
				           placeholder={placeholder || text}
				           required={required}
				           {...props}
				           readOnly={blocked}
				           onFocus={this.handleFocus(true, onFocus)}
				           onBlur={this.handleFocus(false, onBlur)}
				           onClick={this.handleClick(onClick)}
				           >
					<FormLabel className={'ith-edit-field-icon-left'} popup={errorText ? {
						content: this.translate(errorText || content),
						inverted: true,
						position: 'top left'
					} : null}>{_injectIcon(icon, blocked ? 'lightgray' : 'black', errorText)}</FormLabel>
					{before}
					<input style={editable ? INPUT_ON_RIGHT_ICON_STYLE : null}/>
					{children}
				</FormInput>
				{errorBeneeth && errorText ? <div className={'ith-edit-field-error-bottom'}>{this.translate(errorText)}</div> : null}
			</React.Fragment>
		);
	}
}

EditField = translate()(EditField);


export default EditField;