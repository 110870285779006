import * as React from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {translate} from "react-i18next";
import {Form} from "semantic-ui-react";
import {_empty, AHToggle, ListItemMargined, Loadable} from "../../../../Logic/extensions";
import {Subbar} from "ah-tools";


class MDHouseWorker extends React.PureComponent {
	static propTypes = {
		t: PropTypes.func,
		i18n: PropTypes.object,
		tReady: PropTypes.any,
		house: PropTypes.shape({
			house_id: PropTypes.number.isRequired,
			worker_may_change_profile: PropTypes.bool.isRequired,
			worker_may_change_avatar: PropTypes.bool.isRequired,
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool
	};
	static defaultProps = {
		isSaving: false
	};
	
	state = {};
	
	handleChange = (e, {name, value}) => {
		if (this.props.house[name] === value) {
			return this.setState({[name]: undefined});
		}
		return this.setState({[name]: value});
	};
	
	handleToggle = (name) => (e, value) => this.handleChange(e, {name, value});
	
	isValid = () => {
		const save = this.state;
		return !_empty(save);
	};
	
	handleSave = () => {
		this.props.onSave(this.props.house, this.state, () => this.setState({
			worker_may_change_profile: undefined,
			worker_may_change_avatar: undefined
		}));
	};
	
	value = index => this.state[index] !== undefined ? this.state[index] : this.props.house[index];
	
	render() {
		const {t, isSaving} = this.props;
		const changeAvatar = this.value('worker_may_change_avatar');
		const changeProfile = this.value('worker_may_change_profile');
		return(
			<Card>
				<CardHeader title={t('worker.detail.parts.basisdata')} subtitle={t('worker.detail.parts.worker-options')}/>
				<CardText>
					<Form onSubmit={this.handleSave}>
						<ListItemMargined
							primaryText={t('worker.detail.labels.change-profile')}
							secondaryText={changeProfile ? t('worker.detail.labels.may-change-profile') : t('worker.detail.labels.may-not-change-profile')}
							rightToggle={<AHToggle toggled={changeProfile} onToggle={this.handleToggle('worker_may_change_profile')}/>}/>
						<ListItemMargined
							primaryText={t('worker.detail.labels.change-avatar')}
							secondaryText={changeAvatar ? t('worker.detail.labels.may-change-avatar') : t('worker.detail.labels.may-not-change-avatar')}
							rightToggle={<AHToggle toggled={changeAvatar} onToggle={this.handleToggle('worker_may_change_avatar')}/>}/>
						<Subbar style={{marginTop: 20}} align={Subbar.ALIGN_END}>
							<Loadable loading={isSaving} disabledOnLoading disabled={!this.isValid()} inverse type={'submit'} primary hoc={RaisedButton}>{t('actions.save')}</Loadable>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}


export default translate()(MDHouseWorker);