import withInit from "../../../Logic/withInit";
import VehicleMask from "../../../components/intern/vehicles/VehicleMask";
import {connect} from "react-redux";
import User from "../../../models/User";
import {SECTION} from "../../../Logic/constants";
import values from 'lodash/values';
import {isOnline} from "../../../actions/userActions";
import {clientAction__fetchOne} from "../../../actions/clientActions";
import {kfzAction__loadModelsByClient} from "../../../actions/kfzActions";

const m2s = (state, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	const client = props.client || state.clients.list[client_id];
	const user =  User._(state.user.data);
	return {
		...user.getAccessTo(SECTION.CLIENTS),
		client,
		kfz_list: values(state.kfz.cars),
		models: state.kfz.models
	};
};
const m2d = (dispatch, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(clientAction__fetchOne(client_id));
		})),
		fetchModelsOf: (kfz) => dispatch(kfzAction__loadModelsByClient(client_id))
	};
};

export default connect(m2s, m2d)(withInit(VehicleMask));