import * as React from 'react';
import PropTypes from 'prop-types';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {CircleNotchLoader} from "../../../Loaders";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Trans} from "react-i18next";
import {orderCall__get, orderCall__move, orderCall__saveMove} from "../../../../actions/orderActions";
import {ClientView} from "./ClientView";
import {OrderVehicleSelector} from "../../../../cointainer/intern/orders/OrderVehicleSelector";
import {Button, Message, Segment} from "semantic-ui-react";
import {OrderServiceSelector} from "../../../../cointainer/intern/orders/OrderServiceSelector";
import {array2object, deepMemoize as memoizeOne, falseNull, trueNull} from "../../../../Logic/extensions";
import {OrderServiceSelectionList} from "../../../../cointainer/intern/orders/OrderServiceSelectionList";
import {OrderOptionsView} from "../OrderOptionsView";
import {MaterialRangeSelector} from "./DateRangeSelector";
import {startOfMinute} from 'date-fns';
import {NextAppointmentResult} from "./NextAppointmentResult";
import {MessageGroup} from "../../../partials/Segments";
import {withModule} from "../../../../Tools/RightsProvider";
import {get} from 'lodash'

const Loader = () => <EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{minHeight: 150}} direction={EasyFlex.direction.COLUMN}>
	<h4><Trans defaults={'Maske wird geladen'}/></h4>
	<CircleNotchLoader/>
</EasyFlex>;

// noinspection JSIgnoredPromiseFromCall
export class AlternateOrderAppointmentFinder extends StatyComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		onLoadFullOrder: PropTypes.func,
		onFind: PropTypes.func,
		onSave: PropTypes.func,
		onAfterSave: PropTypes.func,
	};
	static defaultProps = {
		onLoadFullOrder: orderId => orderCall__get(orderId),
		onNotification: dispatchSnack,
		onFind: orderCall__move,
		onSave: orderCall__saveMove,
		onAfterSave: () => {}
	};
	orderData = null;
	state = {
		client: null,
		vehicle: null,
		services: [],
		worktime: 0,
		initiating: false,
		searching: false,
		saving: false,
		init: null,
		loan_car: false,
		delivery_service: null,
		direct_service: null,
		wheel_service: false,
		waiting: null,
		start_point: false,
		end_point: false,
		lookup: null,
		apmt: null,
		released: false,
		info: null,
		km: null,
		loancar_id: null,
		consultant_id: null,
		wheel_id: null,
		wheel: null,
		wheel_comment: ''
	};
	unsetLookup = {
		lookup: null,
		apmnt: null,
		released: false,
		loancar_id: null,
		consultant_id: null,
		wheel_id: null,
		wheel: null,
		comment: ''
	};
	
	get service_ids() {
		return this.getServiceIds(this.state.services);
	}
	
	get service_map() {
		return this.getServiceMap(this.state.services);
	}
	
	get use_loan_car() {
		return this.state.loan_car;
	}
	
	get use_waiting() {
		return null !== this.state.waiting ? this.state.waiting : Boolean(this.props.order.waiting);
	}
	
	get use_direct_service() {
		return null !== this.state.direct_service ? this.state.direct_service : Boolean(this.props.order.consultant_id);
	}
	
	get use_wheel_service() {
		return null !== this.state.wheel_service ? this.state.wheel_service : Boolean(this.props.order.wheel_id)
	}
	
	get use_delivery_service() {
		return null !== this.state.delivery_service ? this.state.delivery_service : Boolean(this.props.order.delivery_service);
	}
	
	get start_point() {
		return this.state.start_point !== false ? this.state.start_point : new Date(this.props.order.deliver_point);
	}
	
	get end_point() {
		return this.state.end_point !== false ? this.state.end_point : new Date(this.props.order.fetch_point);
	}
	
	get info() {
		return this.state.info !== null ? this.state.info : this.props.order.info;
	}
	
	get km() {
		return this.state.km !== null ? this.state.km : this.props.order.km;
	}
	
	get valid_search() {
		return !(this.use_delivery_service && this.use_direct_service);
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.init();
	}
	
	setData = (data) => {
		const result = data || this.orderData;
		const order = this.props.order;
		const orderVehicle = result.order_vehicles[order.order_vehicle_id];
		const vehicle = result.vehicles[orderVehicle.vehicle_id];
		const client = result.clients[orderVehicle.client_id];
		const orderServices = result.order2services[order.order_id];
		let services = [];
		for (const osid of orderServices) {
			if (osid in result.services) {
				let service = result.services[osid];
				if (service.finished_at) {
					continue;
				}
				service = result.house_services.find(s => Number(s.service_id) === Number(service.service_id));
				if (service) {
					services.push(service);
				}
			}
			
		}
		const loan_car = Boolean((result.loan_cars[order.order_id] || []).length);
		this.setState({client, vehicle, services, loan_car, wheel_service: !!order.wheel_id});
	};
	
	init = async (force = false) => {
		if (!this.orderData || force) {
			const {onLoadFullOrder, order, onNotification} = this.props;
			try {
				this.setState({initiating: true, init: null});
				const result = this.orderData = await onLoadFullOrder(order.order_id);
				this.setData(result);
			} catch (e) {
				console.error(e);
				onNotification(e.message, 'alert');
				this.setState({init: e});
			} finally {
				this.setState({initiating: false});
			}
		}
	}
	
	search = async () => {
		const {order, onFind, onNotification} = this.props;
		try {
			this.setState({searching: true});
			const params = {
				order_id: order.order_id,
				deliver_date: this.start_point,
				target_date: this.end_point,
				services: this.service_ids,
				loan_car: this.use_loan_car,
				delivery_service: this.use_delivery_service,
				direct_service: this.use_direct_service,
				wheel_service: this.use_wheel_service
			};
			const lookup = await onFind(params);
			if (!lookup.result) {
				onNotification('Es wurde kein Termin gefunden', 'alert');
			}
			this.setState({lookup});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({searching: false});
		}
	};
	
	save = async () => {
		const {onNotification, onSave, order, onAfterSave} = this.props;
		const {lookup: {token}, consultant_id, loancar_id, apmt: {start_point, end_point}, released} = this.state;
		try {
			this.setState({saving: true});
			const params = {
				order_id: order.order_id,
				info: this.info,
				km: this.km,
				token: token,
				loancar_id,
				consultant_id,
				start_point,
				end_point,
				waiting: this.use_waiting,
				delivery_service: this.use_delivery_service,
				wheel_service: this.use_wheel_service,
				released
			};
			const result = await onSave(params);
			this.orderData = result;
			onNotification('Auftrag wurde erfolgreich erweitert');
			this.setState({...this.unsetLookup});
			this.setData();
			onAfterSave(result);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({saving: false});
		}
	};
	
	getServiceIds = memoizeOne(services => services.map(s => s.service_id || s));
	getServiceMap = memoizeOne(services => array2object(services)(s => s.service_id));
	setServices = (services) => this.setState({services, ...this.unsetLookup});
	setWaiting = (waiting) => this.setState({waiting});
	setLoanCar = (loan_car) => this.setState({loan_car, ...this.unsetLookup});
	setDeliveryService = (delivery_service) => this.setState({delivery_service, ...this.unsetLookup});
	setDirectService = (direct_service) => this.setState({direct_service, ...this.unsetLookup});
	setAppointment = (apmt) => this.setState({apmt});
	setReleased = (released) => this.setState({released});
	setInfo = (info) => this.setState({info});
	setKm = (km) => this.setState({km});
	setLoanCarId = (loancar_id) => this.setState({loancar_id});
	setConsultantId = (consultant_id) => this.setState({consultant_id});
	setSearchDate = (index) => (date) => this.setState({[index]: date ? startOfMinute(date) : date, ...this.unsetLookup});
	
	
	render() {
		const {init, modules} = this.props;
		const {client, vehicle, services, initiating, start_point, end_point, searching, lookup, saving, wheel_service} = this.state;
		
		if (initiating) {
			return <Loader/>;
		}
		if (init) {
			return <Message negative>
				<pre>{JSON.stringify(init, null, 2)}</pre>
			</Message>
		}
		if (!client || !vehicle) {
			// return <Message negative><pre>{JSON.stringify(this.state, null, 2)}</pre></Message>
			return <Loader/>;
		}
		return (
			<div>
				<ClientView client={client}/>
				<OrderVehicleSelector readOnly onSelectVehicle={() => {
				}} onUnselect={() => {
				}} vehicle={vehicle}/>
				<OrderServiceSelector vehicle={vehicle} onChange={this.setServices} asGrid sortable include={services}/>
				{trueNull(services.length) && <div>
					<OrderServiceSelectionList
						vehicle={vehicle}
						services={this.service_map}
						updateOnChange
						style={{marginBottom: 20}}
						onNotifyWorktime={worktime => this.setState({worktime: worktime * 3600})}
					/>
					<OrderOptionsView
						deliveryService={this.use_delivery_service}
						loanCar={this.use_loan_car}
						setDeliveryService={this.setDeliveryService}
						waiting={this.use_waiting}
						setWaiting={this.setWaiting}
						setDirectService={this.setDirectService}
						hasLoanCarModule={modules.loancar}
						directService={this.use_direct_service}
						setLoanCar={this.setLoanCar}
						segmentProps={{basic: true, color: null}}
						celled={'internally'}
						hasWheelModule={Boolean(get(this.orderData, 'order.wheel_id', false) && modules.wheel)}
						wheelService={wheel_service}
						setWheelService={(wheel_service) => this.setState({wheel_service})}
					/>
				</div>}
				
				{trueNull(services.length) && <Segment.Group style={{marginBottom: 35}}>
					{falseNull(this.valid_search) && <Segment>
						<Message warning>
							<p className="align center"><Trans defaults="<0>Bring- & Abholservice</0> und <0>Direktannahme</0> können nicht kombiniert werden." components={[<strong>hello</strong>]}/></p>
						</Message>
					</Segment>}
					<Segment style={{paddingBottom: 35, position: 'relative'}}>
						<MaterialRangeSelector
							strict
							startPoint={this.start_point}
							endPoint={this.end_point}
							changeStartPoint={this.setSearchDate('start_point')}
							changeEndPoint={this.setSearchDate('end_point')}
						/>
						{trueNull(start_point !== false || end_point !== false) && <Button icon={'history'} basic style={{position: 'absolute', top: -1, right: -4}} onClick={() => this.setState({start_point: false, end_point: false})}/>}
					</Segment>
					{lookup && !lookup.result && <Segment><div style={{marginBottom: 35}}>
						<MessageGroup>
							<Message warning><p><Trans defaults="Es wurde keine Termin gefunden."/></p></Message>
							{/*<Message info><p><Trans defaults="Sie können versuchen den Termin zu überbuchen. Überbuchungen können den Kalender aus dem Gleichgewicht bringen. Bitte verwenden Sie dieses Mittel nur, wenn es absolut notwendig ist."/></p></Message>*/}
						</MessageGroup>
					</div></Segment>}
					<Button attached={'bottom'} secondary onClick={this.search} loading={searching} disabled={!this.valid_search || searching}><Trans defaults={'Suchen'}/></Button>
				</Segment.Group>}
				{lookup && lookup.result && <div style={{marginBottom: 35}}>
					<NextAppointmentResult
						matchData={lookup.result}
						onChange={this.setAppointment}
						onSelectLoanCar={this.setLoanCarId}
						onSelectConsultant={this.setConsultantId}
						onUpdateReleasable={this.setReleased}
						info={this.info}
						data={{...lookup.result.result, ack: true}}
						style={{marginTop: 30}}
						km={Number(this.km) || 0}
						onUpdateInfo={this.setInfo}
						onUpdateKm={this.setKm}
						loanCar={this.use_loan_car}
						directService={this.use_direct_service}
						deliveryService={this.use_delivery_service}
						wheelService={wheel_service}
					>
						<Button positive attached="bottom" loading={saving} onClick={this.save}><Trans i18nKey="actions.save" defaults="Speichern"/></Button>
					</NextAppointmentResult>
				</div>}
				
			</div>
		);
	}
}
AlternateOrderAppointmentFinder = withModule(AlternateOrderAppointmentFinder)