import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'moment/locale/de';
import './Locales';

export const moment = extendMoment(Moment);
moment.equal = (d1, d2) => d1 >= d2 && d2 >= d1;
export const instanceOfMoment = value => value instanceof moment;
export const toISO = (value) => value ? moment(value).toDate().toISOString() : value;
export const parseDate = (value) => !value ? null : moment(value).toDate();
export const parseTime = (value) => {
	if (!value) return null;
	if (typeof value === "string" && /\d{2}:\d{2}/.test(value)) {
		return value;
	}
	return moment(new Date(value)).format("HH:mm");
};
export default moment;