import * as React from "react";
// import {GET} from "ith-fetch";
import {GET} from "../../Logic/fetch";
import {IconPerson} from "../../Logic/icons";
import {Popup as ClientPopup} from "../intern/clients";

const CLIENT_IDS = [2552, 221, 2605];


export class ClientPopupTest extends React.Component {
	state = {
		clients: []
	};
	
	componentDidMount() {
		CLIENT_IDS.forEach(cid => GET(`/client/${cid}`).then(client => {
			client = Object.values(client)[0];
			this.setState(state => ({clients: [...state.clients, client]}));
		}));
	}
	
	render() {
		const {clients} = this.state;
		return (
			<div>
				{[1,2,3,4].map(i =>
					<div key={i}>
						{clients.map(c => <div key={c.client_id} style={{margin: 50}}><ClientPopup inverted client={c}><IconPerson/></ClientPopup></div>)}
					</div>
				)}
			</div>
		);
	}
}