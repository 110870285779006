import cn from "classnames";
import {logo_service, logo_service_default} from "../../../../Logic/helper";
import * as React from "react";
import WorkLabel from "./WorkLabel";

const ListView = ({accessable, service, service_order_id, serviceInfo, isWorking, isPaused, onClick, ...props}) => (
	<div className={cn('appointment-service-list', {accessable, paused: isPaused, working: isWorking, finished: !!serviceInfo.finished_at})} style={{
		boxShadow: null,
		position: 'relative',
		display: "inline-flex",
		alignItems: "center",
		margin: 8,
		cursor: accessable ? "pointer" : "default",
		// pointerEvents: accessable ? "all" : "",
		opacity: accessable ? 1 : 0.6,
		flexWrap: "wrap"
	}} onClick={accessable ? onClick(service_order_id) : null} {...props}>
		<div style={{
			width: 80,
			height: 80,
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0
		}}>
			<img src={logo_service(service.logo)} onError={(e) => e.target.src = logo_service_default()} alt={service.title} style={{maxWidth: "100%", maxHeight: "100%", borderRadius: 6}}/>
			<span className={"state-info"} style={{
				padding: "2px 5px",
				border: "2px groove rgb(88, 88, 88)",
				borderRadius: 8,
				fontWeight: "bold",
				whiteSpace: "nowrap",
				position: "absolute",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)",
				backgroundColor: "rgba(255, 255, 255, 0.75)",
				textShadow: "0 0 3px white",
			}}><WorkLabel paused={isPaused} working={isWorking} serviceInfo={serviceInfo}/></span>
		</div>
		<div style={{paddingLeft: 10, grow: 2, fontWeight: accessable ? "bold" : null}}>
			{service.title}
		
		</div>
	</div>
);

export default ListView;