import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {Button, ButtonGroup, ButtonOr, Form, FormField} from "semantic-ui-react";
import {AHToggle, ListItemMargined, Loadable} from "../../../Logic/extensions";
import {translate} from "react-i18next";
import PersonIcon from 'material-ui/svg-icons/action/assignment-ind';
import PasswordIcon from 'material-ui/svg-icons/hardware/security';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import {Subbar} from "ah-tools";
import AvatarF from '../../../images/avatar-f.png';
import AvatarM from '../../../images/avatar-m.gif';
import EditField from "../../partials/EditField";
import * as mail from 'email-validator';
import {connect} from "react-redux";
import isFunc from 'lodash/isFunction';
import {createWorker} from "../../../actions/workerActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER} from "../../../actions";
import {push} from "connected-react-router";
import isString from 'lodash/isString';


class WorkersDetailBasicCreate extends React.Component {
	static propTypes = {
		onSave: PropTypes.func.isRequired,
		house_id: PropTypes.number.isRequired,
		isCreating: PropTypes.bool
	};
	static defaultProps = {
		onSave: () => alert('onSave() is not implemented yet'),
		house_id: 0,
		isCreating: false
	};
	
	state = {
		active: true
	};
	
	handleChange = (e, {name, value}) => this.setState({[name]: value});
	handleToggle = ({currentTarget}, value) => this.setState({[currentTarget.name]: value});
	
	isValid = () => {
		const {
			first_name,
			name,
			password,
			password2,
			email,
			email2,
			gender
		} = this.state;
		return Boolean(
			first_name && first_name.trim() &&
			name && name.trim() &&
			gender && gender.trim() &&
			password && password.length >= 6 &&
			password2 && password2 === password &&
			email && email.trim() && mail.validate(email) &&
			email2 && email2 === email
		);
	};
	
	
	requiredError = (index) => {
		const value = this.state[index];
		return isString(value) && value.trim() === '' ? this.props.t('worker.detail.errors.required') : null;
	};
	
	passwordLengthError = () => {
		const value = this.state.password;
		return isString(value) && value.length < 6 ? this.props.t('worker.detail.errors.password-min-length', {length: 6}) : null;
	};
	
	passwordMismatchError = () => {
		const {password: origin, password2: value} = this.state;
		if ( isString(origin) ) {
			return value !== origin ? 'worker.detail.errors.password-mismatch' : null;
		}
		return null;
	};
	
	emailInvalidError = () => {
		const email = this.state.email;
		return isString(email) && !mail.validate(email) ? 'worker.detail.errors.email' : null;
	};
	
	emailMismatchError  = () => {
		const {email, email2} = this.state;
		if ( isString(email) && email !== email2 ) {
			return 'worker.detail.errors.email-mismatch';
		}
		return null;
	};
	
	handleSubmit = e => {
		if ( !this.isValid() ) return;
		this.props.onSave({house_id: this.props.house_id, ...this.state});
	};
	
	render() {
		const {t, house_id, isCreating} = this.props;
		if (0 === house_id) return null;
		const {email, password, gender, active} = this.state;
		return (
			<Card>
				<CardHeader title={t('worker.detail.parts.worker-info')} subtitle={t('worker.detail.parts.basisdata')}/>
				<Form onSubmit={this.handleSubmit} loading={isCreating}>
					<CardText>
						<FormField>
							<ListItemMargined primaryText={t('worker.detail.labels.active')} rightToggle={<AHToggle name={'active'} toggled={active} onToggle={this.handleToggle}/>}/>
						</FormField>
						<FormField>
							<EditField
								icon={<PersonIcon/>}
								label={t('worker.detail.labels.first-name')}
								name={'first_name'}
								placeholder={t('worker.detail.placeholders.first-name')}
								onChange={this.handleChange}
								errorText={this.requiredError('first_name')}
								editable
							/>
						</FormField>
						<FormField>
							<EditField
								icon={<PersonIcon/>}
								label={t('worker.detail.labels.name')}
								name={'name'}
								placeholder={t('worker.detail.placeholders.name')}
								onChange={this.handleChange}
								errorText={this.requiredError('name')}
								editable
							/>
						</FormField>
						<FormField>
							<EditField
								icon={<PasswordIcon/>}
								label={t('worker.detail.labels.password')}
								name={'password'}
								type={'password'}
								placeholder={t('worker.detail.placeholders.password')}
								onChange={this.handleChange}
								errorText={this.passwordLengthError()}
								editable
							/>
						</FormField>
						{password &&
						<FormField>
							<EditField
								icon={<PasswordIcon/>}
								label={t('worker.detail.labels.password-confirm')}
								name={'password2'}
								type={'password'}
								placeholder={t('worker.detail.placeholders.password-confirm')}
								onChange={this.handleChange}
								errorText={this.passwordMismatchError()}
								editable
							/>
						</FormField>
						}
						<FormField>
							<EditField
								icon={<EmailIcon/>}
								label={t('worker.detail.labels.email')}
								name={'email'}
								type={'email'}
								placeholder={t('worker.detail.placeholders.email')}
								onChange={this.handleChange}
								errorText={this.emailInvalidError()}
								editable
							/>
						</FormField>
						{email &&
						<FormField>
							<EditField
								icon={<EmailIcon/>}
								label={t('worker.detail.labels.email-confirm')}
								name={'email2'}
								type={'email'}
								placeholder={t('worker.detail.placeholders.email-confirm')}
								onChange={this.handleChange}
								errorText={this.emailMismatchError()}
								editable
							/>
						</FormField>
						}
						<FormField>
							<label>{t('worker.detail.labels.gender')}</label>
							<Subbar align={Subbar.ALIGN_CENTER}>
								<ButtonGroup>
									<Button type={'button'} color={gender === 'f' ? 'blue' : null} name={'gender'} value={'f'} onClick={this.handleChange}><img src={AvatarF} alt={'Feminin'} style={{maxHeight: 40}}/></Button>
									<ButtonOr/>
									<Button type={'button'} color={gender === 'm' ? 'blue' : null} name={'gender'} value={'m'} onClick={this.handleChange}><img src={AvatarM} alt={'Maskulin'} style={{maxHeight: 40}}/></Button>
								</ButtonGroup>
							</Subbar>
						</FormField>
						<Subbar align={Subbar.ALIGN_END} style={{marginTop: 20}}>
							<Loadable type={'submit'} disabledOnLoading inverse hoc={RaisedButton} primary disabled={!this.isValid()}>{t('actions.create')}</Loadable>
						</Subbar>
					</CardText>
				</Form>
			</Card>
		);
	}
}

WorkersDetailBasicCreate = translate()(WorkersDetailBasicCreate);

export default WorkersDetailBasicCreate;

const m2s = state => ({
	isCreating: isLoading(state, PROC_WORKER)
});
const m2d = dispatch => ({
	onSave: (worker, onSuccess) => dispatch(createWorker(worker, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.created'));
		dispatch(push(`/workers/detail/${result.workers_id}`));
	}))
});

export const WorkersDetailBasicCreateController = connect(m2s, m2d)(WorkersDetailBasicCreate);

WorkersDetailBasicCreateController.propTypes = {
	house_id: PropTypes.number.isRequired
};