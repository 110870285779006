import * as React from "react";
import {Icon} from "semantic-ui-react";
import {EasyFlex} from "../../../components/partials/ActionHeader";
import {isAccessable, ListView} from "./service_view";
import {DebugPopover} from "../../../Tools/Popover";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";

export class AppointmentServiceListView extends React.Component {
	static propTypes = {
		service_ids: PropTypes.any,
		services: PropTypes.any,
		serviceMap: PropTypes.any,
		service2resources: PropTypes.any,
		userResources: PropTypes.any,
		resources: PropTypes.any,
		strict: PropTypes.any,
		order: PropTypes.any,
		fused: PropTypes.any,
		currentWork: PropTypes.any,
		user: PropTypes.any,
		onClick: PropTypes.any
	};
	
	render() {
		let {service_ids, services, serviceMap, service2resources, userResources, resources, strict, order, fused, currentWork, user, onClick} = this.props;
		// const working = service_ids.reduce((isWork, sid) => {
		// 	if (isWork) {
		// 		return isWork;
		// 	}
		// 	const info = services[sid];
		// 	return Boolean(currentWork && info.service_id === currentWork.service_id && order.order_id === currentWork.order_id);
		// }, false);
		return (
			<React.Fragment>
				<EasyFlex wrap={EasyFlex.wrap.YES}>
					{service_ids.map((service_order_id) => {
						const serviceInfo = services[service_order_id];
						if (!serviceInfo) {
							return <Icon key={service_order_id} name="spinner" loading title="loading"/>;
						}
						
						const service = serviceMap[serviceInfo.service_id];
						if (!service) {
							return <Icon key={service_order_id} name="circle notch" loading title="loading"/>;
						}
						// look if user resource is associated with the service
						const accessable = isAccessable({service2resources, serviceInfo, userResources, resources, strict, order, fused, user});
						const isWorking = currentWork && serviceInfo.service_id === currentWork.service_id && order.order_id === currentWork.order_id;
						const isPaused = Boolean(serviceInfo.paused_by);
						return (
							<DebugPopover
									bind={serviceInfo}
									key={service_order_id}>
								<ListView
									{...{
										accessable,
										service_order_id,
										service,
										serviceInfo,
										isWorking,
										isPaused,
										onClick
									}}
								/>
							</DebugPopover>
						);
					})}
				</EasyFlex>
			</React.Fragment>
		);
	}
}

AppointmentServiceListView = connect(
	state => ({
		serviceMap: state.map.services
	})
)(AppointmentServiceListView);