// eslint-disable-next-line
import * as React from "react";
import PropTypes from "prop-types";

export const ErrorView = ({error, errorView, children}) => (
	error ? errorView : children
);
ErrorView.propTypes = {
	error: PropTypes.any,
	errorView: PropTypes.node,
	children: PropTypes.node
};
