import {CLIENT_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {CACHE_CLIENTS, CACHE_TIME_CLIENTS, PROC_CLIENT_CREATE, PROC_CLIENT_DELETE, PROC_CLIENT_UPDATE, PROC_CLIENTS_FETCH} from "./index";
import {addError} from "./errorActions";
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import isFunc from 'lodash/isFunction';
import {isCached} from "ith-redux-helpers";
import {vehicleState__insert, vehicleState__order2vehicle, vehicleState__remove} from "./vehicleActions";
import {appointmentState__update} from "./appointmentActions";
import {get} from "lodash";
import {mapState__clients_add, mapState__orders_add, mapState__vehicles_add} from "./mapActions";
import {Collector} from "../Logic/helper";
import {addSnackbar} from "./snackbarActions";

export const clientState__set = clients => ({type: CLIENT_ACTION.SET, clients});
export const clientState__insert = clients => ({type: CLIENT_ACTION.INSERT, clients});
export const clientState__add = (client, cache = false) => ({type: CLIENT_ACTION.ADD, client, cache});
export const clientState__remove = (client_id) => ({type: CLIENT_ACTION.REMOVE, client_id});

export const clientCall__getOne = id => GET(`/client/one/${id}`);
export const clientCall__getByOrder = (order) => GET(`/client/order/${order.order_id || order}`);
export const clientCall__collect = (client_ids) => POST('/client/collect', {list: client_ids});
export const clientCall__collectOne = async (client_id) => {
	const res = await clientCall__collect([client_id]);
	return res[client_id];
}
export const clientCall__list = () => GET('/client/list');
export const clientCall__listIDs = () => GET('/client/list/ids');
/**
 *
 * @param {Object} [options]
 * @param {number} [options.items]
 * @param {number} [options.page]
 * @param {string} [options.sort]
 * @param {string} [options.search]
 * @return {Promise | Promise<unknown>}
 */
export const clientCall__cursor = (options = {}) => POST('/client/cursor', options);

export const clientCall__searchTest = (options) => POST('/client/search-test', options);
/**
 *
 * @param {Object} options
 * @param {string} [options.name]
 * @param {string} [options.first_name]
 * @param {string} [options.company]
 * @param {string} [options.client_number]
 * @param {string} [options.registration_mark]
 * @param {string} [options.chassis_number]
 * @return {Promise | Promise<unknown>}
 */
export const clientCall__searchDetailed = (options) => POST('/client/search/detail', options);

let fetchQueue = [];
export const clientAction__fetch = (force = false, onSuccess) =>
	async (dispatch, getState) => {
		if (!force && isCached(getState().clients.cache, CACHE_CLIENTS)(CACHE_TIME_CLIENTS)) {
			isFunc(onSuccess) && onSuccess(getState().clients.list);
			return;
		}
		try {
			fetchQueue.push(onSuccess || null);
			if (fetchQueue.lengt > 1) {
				return;
			}
			dispatch(setLoader(PROC_CLIENTS_FETCH));
			const result = await GET('/client');
			dispatch(clientState__set(result));
			dispatch(mapState__clients_add(result));
			for (const queue of fetchQueue) {
				isFunc(queue) && queue(result);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_CLIENTS_FETCH));
			fetchQueue = [];
		}
	};
export const clientAction__fetchOne = (client, force = false, onSuccess) =>
	async (dispatch, getState) => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_CLIENTS_FETCH, client_id));
			const clients = await GET(`/client/${client_id}`);
			dispatch(clientState__insert(clients));
			dispatch(mapState__clients_add(clients));
			isFunc(onSuccess) && onSuccess(clients);
			return get(clients, client_id, null);
		} catch (e) {
			dispatch(addError(e, false));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_CLIENT_CREATE, client_id));
		}
	};


let getOneQueue = {};
const _get = (client_id, force, dispatch, getState) => new Promise(async (k) => {
	let back = get(getState(), ['clients', 'list', client_id], null);
	
	try {
		if (back && !force) {
			k(back);
			return back;
		}
		dispatch(setLoader(PROC_CLIENTS_FETCH, client_id));
		const clients = await GET(`/client/${client_id}`);
		dispatch(mapState__clients_add(clients));
		const client = Object.values(clients)[0];
		k(client);
		
	} catch (e) {
		dispatch(addError(e));
	} finally {
		delete getOneQueue[client_id];
		dispatch(unsetLoader(PROC_CLIENTS_FETCH, client_id));
	}
});
export const clientAction__getOne = (client_id, force = false, onSuccess) =>
	async (dispatch, getState) => {
		client_id = Number(client_id);
		// if (getOneQueue[client_id]) {
		// getOneQueue.push(onSuccess);
		if (!getOneQueue[client_id]) {
			getOneQueue[client_id] = _get(client_id, force, dispatch, getState);
		}
		const client = await getOneQueue[client_id];
		dispatch(clientState__insert({[client.client_id]: client}));
		dispatch(mapState__clients_add(client));
		return client;
	};

export const clientAction__create = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_CLIENT_CREATE));
			const client = await PUT('/client', data);
			dispatch(clientState__add(client));
			dispatch(mapState__clients_add(client));
			isFunc(onSuccess) && onSuccess(client);
			return client;
		} catch (e) {
			dispatch(addError(e, false));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_CLIENT_CREATE));
		}
	};
export const clientAction__change = (client, onSuccess) =>
	async dispatch => {
		const client_id = client.client_id;
		try {
			dispatch(setLoader(PROC_CLIENT_UPDATE, client_id));
			const result = await POST('/client', client);
			if (result.ack) {
				dispatch(clientState__add(result.client));
				dispatch(mapState__clients_add(result.client));
				if (result.vehicles) {
					dispatch(vehicleState__insert(result.vehicles));
					dispatch(mapState__vehicles_add(result.vehicle));
				}
				isFunc(onSuccess) && onSuccess(result.client);
				return client;
			} else {
				// dispatch(addSnackbar('Hinterlegte Leihwagen werden entzogen. Trotzdem ausführen?', 'black', 'Ja, ausführen', () => dispatch(clientAction__change({...client, force: true}, onSuccess)), 12000));
				dispatch(addSnackbar('Es sind Leihwagen hinterlegt! Diese müssen zuerst entfernt werden.', 'alert'));
			}
			return null;
		} catch (e) {
			dispatch(addError(e, false));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_CLIENT_UPDATE, client_id));
		}
	};
export const clientAction__delete = (client, force = false, onSuccess, onConfirm) =>
	async dispatch => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_CLIENT_DELETE, client_id));
			let url = `/client/${client_id}`;
			if (force) {
				url += '/force';
			}
			const result = await DELETE(url);
			if (result.ack) {
				dispatch(clientState__remove(result.client_id));
				if (result.vehicles && result.vehicles.length) {
					dispatch(vehicleState__remove(result.vehicles.map(v => v.vehicle_id)));
				}
				isFunc(onSuccess) && onSuccess(result);
			} else {
				// handle confirmation ?
				if (isFunc(onConfirm)) {
					onConfirm(result);
				} else {
					throw new Error('errors.missing-confirm-handler');
				}
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_CLIENT_DELETE, client_id));
		}
	};

export const clientAction__getByOrder = (order, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_CLIENTS_FETCH, order_id));
			const result = await clientCall__getByOrder(order_id);
			dispatch(clientState__insert(result.client));
			dispatch(appointmentState__update(result.order));
			dispatch(mapState__orders_add(result.order));
			dispatch(vehicleState__order2vehicle(result.order2vehicle));
			"client" in result && dispatch(mapState__clients_add(result.client));
			isFunc(onSuccess) && onSuccess(result);
			
			return result;
		} catch (e) {
			dispatch(addError(e));
			return null;
		} finally {
			dispatch(unsetLoader(PROC_CLIENTS_FETCH, order_id));
		}
	};

export const clientAction__cursor = (options = {}) =>
	async dispatch => {
		try {
			const result = await clientCall__cursor(options);
			if (result && 'items' in result) {
				dispatch(mapState__clients_add(result.items));
			}
			return result;
		} catch (e) {
			dispatch(addError(e));
			throw e;
		}
	};

let __collect = {};
export const clientAction__collect = clients =>
	Collector._(__collect, clients, 'client_id')
		.withLoader([PROC_CLIENTS_FETCH])
		.withStateProvider('map.clients')
		.withApiProvider(list => clientCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__clients_add(result)))
		.execute();

export const clientAction__collectOne = client =>
	async dispatch => {
		const response = await dispatch(clientAction__collect([client]));
		return Object.values(response)[0];
	};