import * as React from 'react';
import PropTypes from 'prop-types';
import {Snackbar} from "material-ui";
import {SNACKBAR} from "../Logic/constants";
import {mapOfObject} from "../Logic/extensions";
import {translate} from "react-i18next";

const SnackbarGroup = ({t, tReady, autoHideDuration = SNACKBAR.autoHideDuration, translationBase, i18n, value, onRequestClose, ...props}) => {
	const lang = i18n.getResource(i18n.language, 'translations', translationBase);
	if ( !lang ) {
		// console.error('lang ist not ready');
		return null;
	}
	return (
		<React.Fragment>
			{
				mapOfObject(lang, true,
					(translation, snackbar) =>
						<Snackbar key={snackbar} open={value === snackbar} autoHideDuration={autoHideDuration} message={translation || 'OK'} onRequestClose={onRequestClose} {...props}/>
					)
			}
		</React.Fragment>
	);
};
SnackbarGroup.propTypes = {
	autoHideDuration: PropTypes.number,
	value: PropTypes.string,
	translationBase: PropTypes.string.isRequired,
	onRequestClose: PropTypes.func.isRequired,
};
SnackbarGroup.defaultProps = {
	autoHideDuration: SNACKBAR.autoHideDuration,
	value: null
};
export default translate()(SnackbarGroup);