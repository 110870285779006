export const RESET_ACTION = 'RESET_ACTION';
export const resetAction = dispatch =>  dispatch({type: RESET_ACTION});

export const LOGIN_ACTION = {
	SUCCESS: 'LOGIN_SUCCESS',
	FAILED: 'LOGIN_FAILED',
	START: 'LOGIN',
	CLEAR: 'LOGIN_CLEAR',
};
export const LOGOUT_ACTION = {
	DONE: 'LOGOUT_DONE',
	START: 'LOGOUT'
};

export const REGISTER_ACTION = {
	START: 'REGISTER_START',
	FAILED: 'REGISTER_FAILED',
	SUCCESS: 'REGISTER_SUCCESS',
	CLEAR: 'REGISTER_CLEAR'
};

export const USER_ACTION = {
	LOGIN: 'LOGIN_USER',
	LOGOUT: 'LOGOUT_USER',
	REGISTER: 'REGISTER_USER',
	UPDATE: 'UPDATE_USER',
	SET_RESOURCES: 'SET_RESOURCES_OF_USER',
	SET_EMAIL_LOGIN: 'SET_EMAIL_LOGIN',
	UPDATE_MAIL: 'UPDATE_EMAIL_OF_USER',
	SCHEDULE: 'USER_SET_SCHEDULE'
};

export const USER_WORK_ACTION = {
	SET: 'USER_WORK_SET',
	SET_HISTORY: 'USER_WORK_SET_HISTORY'
};

export const INIT_ACTION = {
	START: 'INIT_START',
	INDEX: 'INIT_INDEX',
	LOGGED: 'INIT_LOGGED'
};

export const ERROR_ACTION = {
	ADD: 'ERROR_ADD',
	REMOVE: 'ERROR_REMOVE',
	DISMISS: 'ERROR_DISMISS'
};

export const LANGAUGE_ACTION = {
	SET: 'LANGUAGE_SET'
};

export const LOADER_ACTION = {
	ADD: 'LOADER_ADD',
	DONE: 'LOADER_DONE',
	SET: 'LOADER_SET',
	UNSET: 'LOADER_UNSET'
};

export const MENU_ACTION = {
	SHOW: 'MENU_SHOW',
	TOGGLE: 'MENU_TOGGLE'
};

export const DIALOG_ACTION = {
	ADD: 'DIALOG_ADD',
	DISMISS: 'DIALOG_DISMISS'
};

export const CARHOUSE_ACTION = {
	LOAD_LIST: 'CARHOUSE_LOAD_LIST',
	LOAD_HOUSE: 'CARHOUSE_LOAD_HOUSE',
	LOAD_TIMEZONES: 'CARHOUSE_LOAD_TIMEZONES',
	SAVE_TIMEZONE: 'CARHOUSE_SAVE_TIMEZONE',
	REMOVE_LOGO: 'CARHOUSE_REMOVE_LOGO',
	OWN: 'CARHOUSE_OWN',
	SET_COUNTRIES: 'CARHOUSE_SET_COUNTRIES',
	CUSTOMERONE: 'CARHOUSE_CUSTOMERONE',
};

export const WORKER_ACTION = {
	LOAD_ALL: 'WORKERS_LOAD',
	ADD: 'WORKERS_ADD',
	REMOVE: 'WORKERS_REMOVE',
	LOAD_ONE: 'WORKERS_DETAIL',
	RESET: 'WORKERS_RESET',
	FILTER: {
		SET: 'WORKERS_FILTER_SET'
	},
	REMOVE_AVATAR: 'REMOVE_WORKERS_AVATAR',
	UPDATE_EMAIL: 'WORKERS_UPDATE_EMAIL',
	SET_WORK: 'WORKERS_SET_WORK',
	SET_WORK_HISTORY: 'WORKERS_SET_WORK_HISTORY',
	SET_ACTIVITIES: "WORKERS_SET_ACTIVITIES",
	SET_RESOURCE_MAP: "WORKERS_SET_RESOURCE_MAP",
	SET_WORKING: "WORKERS_SET_WORKING",
	UPDATE_WORKING: "WORKERS_UPDATE_WORKING",
	REMOVING_WORKING: "WORKERS_REMOVE_WORKING",
	ADD_ID: "WORKERS_ADD_ID",
	REMOVE_ID: "WORKERS_REMOVE_ID",
	SET_CONSULTANT_SCHEDULE: 'WORKERS_SET_CONSULTANT_SCHEDULE',
	REMOVE_CONSULTANT_SCHEDULE: 'WORKERS_REMOVE_CONSULTANT_SCHEDULE'
};

export const SCHEDULE_ACTION = {
	SET: 'SCHEDULE_SET',
	SET_WORK_SCHEDULE: 'SCHEDULE_SET_WORK'
};

export const RIGHT_ACTION = {
	LOAD_AREAS: 'LOAD_AREAS',
	LOAD_GROUPS: 'LOAD_GROUPS',
	LOAD_AREAS_GROUPS: 'LOAD_AREAS_GROUPS',
	RESET: 'RESET_AREAS_GROUPS'
};

export const RESOURCE_ACTION = {
	LOAD: 'WORKER_RESOURCES_LOAD',
	ADD: 'WORKER_RESOURCES_ADD',
	UPDATE: 'WORKER_RESOURCES_UPDATE',
	REMOVE: 'WORKER_RESOURCES_REMOVE',
	RESET: 'WORKER_RESOURCES_RESET',
	WORKERS_LOAD: 'RESOURCES_4_WORKERS',
	WORKERS_ADD: 'RESOURCES_4_WORKERS_ADD',
	WORKERS_DELETE: 'RESOURCES_4_WORKERS_DELETE',
	SAVE_TIMES: 'WORKER_RESOURCE_SAVE_TIMES',
	REMOVE_ONE_TIME: 'WORKERS_RESOURCE_REMOVE_ONE_TIME',
	REMOVE_RESOURCE_TIMES: 'WORKERS_RESOURCE_REMOVE_LIST_OF_TIMES',
	UPDATE_TIME: 'WORKERS_RESOURCE_UPDATE_TIME',
	KFZ_SET: 'RESOURCE_KFZ_SET',
	MODEL_SET: 'RESOURCE_MODEL_SET',
	KFZ_UPDATE: 'RESOURCE_KFZ_UPDATE',
	MODEL_UPDATE: 'RESOURCE_MODEL_UPDATE',
	MODEL_UPDATE_MASS: 'RESOURCE_MODEL_UPDATE_MASS',
	KFZ_UNSET: 'RESOURCE_KFZ_UNSET',
	MODEL_UNSET: 'RESOURCE_MODEL_UNSET',
	MODEL_UNSET_MASS: 'RESOURCE_MODEL_UNSET_MASS'
};

export const RESOURCE_TYPE_ACTION = {
	LOAD: 'RESOURCE_TYPE_LOAD',
	SAVE: 'RESOURCE_TYPE_SAVE',
	REMOVE: 'RESOURCE_TYPE_DELETE'
};

export const FREEDAY_ACTION = {
	LOAD: 'FREEDAYS_LOAD',
	REMOVE_BY_ID: 'FREEDAYS_REMOVE_BY_ID',
	LOAD_WORKERS: 'FREEDAYS_LOAD_WORKERS',
	PUT_WORKERS: 'FREEDAYS_PUT_WORKERS',
	UPDATE_WORKERS: 'FREEDAYS_UPDATE_WORKERS',
	REMOVE_WORKERS: 'FREEDAYS_REMOVE_WORKERS'
};

export const APPOINTMENT_ACTION = {
	MULTI: 'APPOINTMENTS_MULTI',
	LIST: 'APPOINTMENTS_LIST',
	SET: 'APPOINTMENTS_LOAD',
	UPDATE: 'APPOINTMENT_UPDATE',
	ADD: 'APPOINTMENTS_ADD',
	REMOVE: 'APPOINTEMNTS_REMOVE',
	REMOVE_RESOURCES: 'APPOINTMENTS_REMOVE_RESOURCES',
	UPDATE_PROGRESS: 'APPOINTMENTS_UPDATE_PROGRESS',
	SET_WORKLOAD: 'APPOINTMENTS_SET_WORKLOAD',
	SET_PAUSED: 'APPOINTMENTS_SET_PAUSED',
	SET_TO_SECTION: 'APPOINTMENTS_SET_TO_SECTION',
	DELETE: 'APPOINTMENT_DELETE',
	SET_WORK_MAP: 'APPOINTMENT_SET_WORK_MAP',
	SSE_UPDATE: 'APPOINTMENT_UPDATE_BY_SSE',
	PAUSED_SERVICES: 'APPOINTMENT_PAUSED_SERVICES',
	DAY_LOADED: 'APPOINTMENT_DAY_LOADED'
};

export const SNACKBAR_ACTION = {
	ADD: 'SNACKBAR_ADD',
	REMOVE: 'SNACKBAR_REMOVE' 
};

export const MODULE_ACTION = {
	SET: 'SET_MODULES',
	SET_HOUSE: 'SET_HOUSE_MODULES'
};

export const KFZ_ACTION = {
	SET: 'KFZ_SET',
	ADD: 'KFZ_ADD',
	ADD_MODELS: 'KFZ_MODELS_ADD',
	SET_ONE: 'KFZ_SET_ONE',
	SET_MODELS: 'KFZ_MODELS_SET',
	INVERSE_MODELS: 'KFZ_INVERSE_MODELS',
	SET_SERVICES: 'SET_KFZ_SERVICES',
	SET_SERVICE_2_KFZ: 'SET_KFZ_2_SERVICE',
	SET_CUSTOM_SERVICE: 'SET_CUSTOM_SERVICE',
	REMOVE_CUSTOM_SERVICE: 'REMOVE_CUSTOM_SERVICE',
	SET_SERVICE_KFZ_MODEL: 'SET_SERVICE_FOR_KFZ_MODEL',
	UPDATE_SERVICE_KFZ_MODELS: 'UPDATE_SERVICE_KFZ_MODEL',
	REMOVE_SERVICE_KFZ_MODELS: 'REMOVE_SERVICE_KFZ_MODEL',
	UPDATE_SERVICE: 'UPDATE_SERVICE',
	REMOVE_SERVICE: 'REMOVE_SERVICE'
};

export const CLIENT_ACTION = {
	SET: 'SET_CLIENTS',
	INSERT: 'INSERT_CLIENTS',
	ADD: 'ADD_CLIENTS',
	REMOVE: 'REMOVE_CLIENT'
};

export const VEHICLE_ACTION = {
	SET: 'SET_VEHICLES',
	INSERT: 'INSERT_VEHICLES',
	ADD: 'ADD_VEHICLES',
	REMOVE: 'REMOVE_VEHICLE',
	SET_CLIENT_RELATION: 'SET_CLIENT_RELATION',
	REMOVE_CLIENT: 'REMOVE_CLIENT',
	SET_ORDER_VEHICLE: 'SET_ORDER_VEHICLE'
};

export const LOAN_CAR_ACTION = {
	SET: 'SET_LOAN_CAR',
	INSERT: 'INSERT_LOAN_CAR',
	REMOVE: 'REMOVE_LOAD_CAR',
	SET_SCHEDULE: 'SET_LOAN_CAR_SCHEDULE',
	ADD_SCHEDULE: 'ADD_LOAN_CAR_SCHEDULE',
	REMOVE_SCHEDULE: 'REMOVE_LOAN_CAR_SCHEDULE',
	SET_SCHEDULE_RANGE: 'SET_LOAN_CAR_SCHEDULE_RANGE',
	ORDER_LOAN_CAR: 'SET_ORDER_LOAN_CAR'
};

export const ORDER_ACTION = {
	SET: 'SET_ORDERS',
	PARTIAL : 'PARTIAL_ORDERS',
	UPDATE: 'UPDATE_ORDERS',
	UNSET: 'UNSET_ORDERS',
	SET_ACTIVITIES: 'SET_ACTIVITIES',
	RELEASED: 'SET_RELEASE_ORDERS',
	CLEAR_RELEASED: 'CLEAR_RELEASED_ORDERS',
	ADD_TO_RELEASE: 'ADD_ORDER_TO_RELEASE',
	DELETE: 'DELETE_ORDER',
	META: 'META_ORDER',
	SET_RELEASES: 'ORDER_SET_RELEASES',
	MERGE_RELEASES: 'ORDER_MERGE_RELEASES',
	REMOVE_RELEASES: 'ORDER_REMOVE_RELEASES',
	CLEAR_COMPLETE_RELEASES: 'ORDER_CLEAR_COMPLETE_RELEASES',
	CLEAR_ALL_RELEASES: 'ORDER_CLEAR_ALL_RELEASES',
	ADD_RELEASE_IDS: 'ORDER_ADD_RELEASE_IDS',
	SET_RELEASE_IDS: 'ORDER_SET_RELEASE_IDS',
	REMOVE_RELEASE_IDS: 'ORDER_REMOVE_RELEASE_IDS',
	SET_CONFLICTS: 'SET_CONFLICTS',
	UPDATE_CONFLICTS: 'UPDATE_CONFLICTS',
	REMOVE_CONFLICTS: 'REMOVE_CONFLICTS',
	SET_PROCESSABLE: 'ORDERS_SET_PROCESSABLE'
};

export const WORK_ACTION = {
	LOAD: 'LOAD_WORK',
	SET_WORKLOAD: 'WORK_SET_WORKLOAD'
};

export const SOUND_ACTIONS = {
	PLAY: "SOUND_PLAY",
	STOP: "SOUND_STOP",
	INITIATED: "SOUND_INITITATED"
};

export const SOUND_STATES = {
	PLAYING: "PLAYING",
	STOPPED: "STOPPED"
};

export const LOCAL_STORAGE_ACTIONS = {
	WORKER_LIST_VIEW: "WORKER_LIST_VIEW",
	CLIENT_LIST_VIEW: "CLIENT_LIST_VIEW",
	TIMEZONES: "LOCAL_TIMEZONES",
	COUNTRIES: "LOCAL_COUNTRIES"
};

export const SESSION_STORAGE_ACTIONS = {
	RESET: "SESSION_STORE_RESET",
	NOTIFICATION_APPOINTMENT_IDS: "UPDATE_SESSION_STORE_NOTIFICATION_APPOINTMENT_IDS",
	NOTIFICATION_APPOINTMENT_UNSET_IDS: "UPDATE_SESSION_STORE_NOTIFICATION_APPOINTMENT_UNSET_IDS",
	CLEAR_NOTIFICATION_APPOINTMENT_IDS: "CLEAR_SESSION_STORE_NOTIFICATION_APPOINTMENT_IDS",
	DEV_ORDER_CHANGE_ID: "DEV_ORDER_CHANGE_ID",
	TOGGLE_APPOINTMENTS_SHOW_UNDELIVERED: "TOGGLE_APPOINTMENTS_SHOW_UNDELIVERED",
	CHANGE_ORDER_FILTER_STATE: "CHANGE_ORDER_FILTER_STATE",
	SET_ORDER_FILTER_STATE: "SET_ORDER_FILTER_STATE",
	RESET_ORDER_FILTER_STATE: "RESET_ORDER_FILTER_STATE",
	NOTIFICATION_PAUSED_SET: "SESSION_NOTIFICATION_PAUSED_SET",
	NOTIFICATION_PAUSED_REMOVE: "SESSION_NOTIFICATION_PAUSED_REMOVE",
	NOTIFICATION_PAUSED_CLEAR: "SESSION_NOTIFICATION_PAUSED_CLEAR",
	COUNTRIES: "SESSION_COUNTRIES",
	ORDER_CONSULTANT_SELECT: 'ORDER_CONSULTANT_SELECT'
};

export const MAP_ACTIONS = {
	INIT: 'MAP_INIT',
	INDEX: (index) => `carperto-session-map-${index}`,
	MULTI: 'MAP_MULTI',
	ORDERS: 'MAP_ORDERS',
	ORDERS_REMOVE: 'MAP_ORDERS_REMOVE',
	VEHICLES: 'MAP_VEHICLES',
	VEHICLES_REMOVE: 'MAP_VEHICLES_REMOVE',
	CLIENTS: 'MAP_CLIENTS',
	CLIENTS_REMOVE: 'MAP_CLIENTS_REMOVE',
	RELEASES: 'MAP_RELEASES',
	RELEASED_REMOVE: 'MAP_RELEASES_REMOVE',
	ORDER_SERVICES: 'MAP_ORDER_SERVICES',
	ORDER_SERVICES_REMOVE: 'MAP_ORDER_SERVICES_REMOVE',
	ORDER_SERVICE_RESOURCES: 'MAP_ORDER_SERVICE_RESOURCES',
	ORDER_SERVICE_RESOURCES_REMOVE: 'MAP_ORDER_SERVICE_RESOURCES_REMOVE',
	WORKERS: 'MAP_WORKERS',
	WORKERS_REMOVE: 'MAP_WORKERS_REMOVE',
	WORKERS_LOGOUT: 'MAP_WORKERS_LOGOUT',
	RESOURCES: 'MAP_RESOURCES',
	RESOURCES_REMOVE: 'MAP_RESOURCES_REMOVE',
	KFZ: 'MAP_KFZ',
	KFZ_REMOVE: 'MAP_KFZ_REMOVE',
	KFZ_MODELS: 'MAP_KFZ_MODELS',
	KFZ_MODELS_REMOVE: 'MAP_KFZ_MODELS_REMOVE',
	SERVICES: 'MAP_SERVICES',
	SERVICES_REMOVE: 'MAP_SERVICES_REMOVE',
	WORK: 'MAP_WORK',
	WORK_REMOVE: 'MAP_WORK_REMOVE',
	FREEDAYS_WORKER: 'MAP_FREEDAYS_WORKER',
	FREEDAYS: 'MAP_FREEDAYS',
	SCHEDULE: 'MAP_SCHEDULE',
	ORDER_VEHICLES: 'MAP_ORDER_VEHICLES',
	ORDER_VEHICLES_REMOVE: 'MAP_ORDER_VEHICLES_REMOVE',
	LOAN_CAR: 'MAP_LOAN_CAR',
	LOAN_CAR_REMOVE: 'MAP_LOAN_CAR_REMOVE',
	WHEELS: 'MAP_WHEELS',
	WHEELS_REMOVE: 'MAP_WHEELS_REMOVE'
};