const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_DAY * 30;
const ONE_YEAR = ONE_DAY * 365;

export const MODULE_BASE = 1;
export const MODULE_PAYMENT = 2;
export const MODULE_LOAN_CAR = 3;

export const PROC_HOUSE = 'proc-house';
export const PROC_HOUSE_UPLOAD_LOGO = 'proc-house-upload-logo';
export const PROC_HOUSE_DELETE_LOGO = 'proc-house-delete-logo';
export const PROC_TIMEZONES = 'proc-timezones';
export const PROC_TIMEZONE_UPDATE = 'proc-timzone-update';
export const PROC_COUNTRIES_FETCH = 'proc-countries-fetch';

export const PROC_USER = 'proc-user';
export const PROC_USER_PASSWORD = 'proc-user-password';
export const PROC_USER_LOGIN = 'proc-user-login';

export const PROC_USER_FETCH_WORK = 'proc-user-fetch-work';

export const PROC_MODULE = 'proc-module';

export const PROC_SERVICE_CUSTOM = 'proc-service-custom';

export const PROC_WORKER_UPLOAD_AVATAR = 'proc-worker-upload-avatar';
export const PROC_WORKER_REMOVE_AVATAR = 'proc-worker-remove-avatar';

export const PROC_WORKER_GROUP = 'proc-worker-group';
export const PROC_WORKER = 'proc-worker';
export const PROC_WORKER_UPDATE = 'proc-worker-update';
export const PROC_WORKER_EMAIL = 'proc-worker-email';
export const PROC_WORKER_DELETE = 'proc-worker-delete';
export const PROC_WORKER_ACTIVITIES = "proc-worker-activities";

export const PROC_WORKER_FETCH_WORK = 'proc-worker-fetch-work';

export const PROC_ERROR_REPORT = 'proc-error-report';
export const PROC_CALLBACK_REQUEST = 'proc-callback-request';
export const PROC_FEEDBACK_REPORT = 'proc-feedback-report';

export const PROC_KFZ_FETCH = 'proc-kfz-fetch';
export const PROC_KFZ_FETCH_SERVICES = 'proc-kfz-fetch-services';
export const PROC_KFZ_SERVICE_UPDATE = 'proc-kfz-service-update';
export const PROC_KFZ_FETCH_MODEL_SERVICES = 'proc-kfz-fetch-model-services';
export const PROC_KFZ_UPDATE_MODEL_SERVICES = 'proc-kfz-update-model-services';
export const PROC_KFZ_REMOVE_MODEL_SERVICES = 'proc-kfz-REMOVE-model-services';

export const PROC_KFZ_CLIENT_FETCH = 'proc-kfz-client-fetch';
export const PROC_KFZ_MODELS_CLIENT_FETCH = 'proc-kfz-models-client-fetch';

export const PROC_CLIENTS_FETCH = 'proc-client-fetch';
export const PROC_CLIENT_CREATE = 'proc-client-create';
export const PROC_CLIENT_UPDATE = 'proc-client-update';
export const PROC_CLIENT_DELETE = 'proc-client-delete';

export const PROC_VEHICLES_FETCH = 'proc-vehicles-fetch';
export const PROC_VEHICLES_CREATE = 'proc-vehicles-create';
export const PROC_VEHICLES_DELETE = 'proc-vehicles-delete';
export const PROC_VEHICLES_EDIT = 'proc-vehicles-edit';
export const PROC_VEHICLES_RELATION = 'proc-vehicles-relation';

export const PROC_LOAN_CARS_FETCH = 'proc-loan-cars-fetch';
export const PROC_LOAN_CARS_SEND = 'proc-loan-cars-send';
export const PROC_LOAN_CARS_REMOVE = 'proc-loan-cars-remove';

export const PROC_SEARCH_ZIPCODE = 'proc-search-zipcode';

export const PROC_FREEDAYS = 'proc-freedays';
export const PROC_FREEDAYS_WORKERS = 'proc-freedays-workers';

export const PROC_RESOURCE_KFZ_FETCH = 'proc-resource-kfz-fetch';
export const PROC_RESOURCE_MODEL_FETCH = 'proc-resource-model-fetch';
export const PROC_RESOURCE_KFZ_ASSIGN = 'proc-resource-kfz-assign';
export const PROC_RESOURCE_MODEL_ASSIGN = 'proc-resource-model-assign';
export const PROC_RESOURCE_MODEL_MASS_ASSIGN = 'proc-resource-model-mass-assign';
export const PROC_RESOURCE_WORKERS = 'proc-resource-workers';

export const PROC_SCHEDULE = 'proc-schedule';
export const PROC_SCHEDULE_INSERT = 'proc-schedule-insert';

export const PROC_APPOINTMENTS = 'proc-appointments';
export const PROC_APPOINTMENTS_PAUSED_QUIT = "proc-appointments-paused-quit";

export const PROC_ORDER = 'proc-order';
export const PROC_ORDER_DAY = 'proc-order-day';
export const PROC_ORDER_RELEASE = 'proc-order-relase';
export const PROC_ORDER_RELEASE_LIST = 'proc-order-relase-list';
export const PROC_ORDER_DELETE = 'proc-order-delete';
export const PROC_ORDER_SERVICE = 'proc-order-service';
export const PROC_ORDER_RESOURCE = 'proc-order-resource';
export const PROC_ORDER_DELIVER_STATE = 'proc-order-deliver-state';
export const PROC_ORDER_FETCH_STATE = 'proc-order-fetch-state';
export const PROC_ORDER_DAY_LIST = 'proc-order-day-list';

export const PROC_CONSULTANT_LIST_UPDATE = 'proc-consultant-list-update';

export const PROC_DUMP_DB  = 'proc-dump-db';

export const CACHE_RIGHT_GROUPS = 'right-groups';
export const CACHE_RIGHT_RELATIONS = 'right-relations';
export const CACHE_RIGHT_AREAS = 'right-areas';
export const CACHE_KFZ = 'kfz-list';
export const CACHE_KFZ_MODELS = 'kfz-model-list';
export const CACHE_KFZ_SERVICES = 'kfz-service-list';
export const CACHE_CLIENTS = 'clients-list';
export const CACHE_COUNTRIES = 'countries';
export const CACHE_VEHICLES = 'vehicles';
export const CACHE_VEHICLES_RELATIONS = 'vehicles-relations';
export const CACHE_RESOURCE_LIST = 'resource-list';
export const CACHE_RESOURCE_KFZ = 'resource-kfz';
export const CACHE_RESOURCE_MODEL = 'resource-model';
export const CACHE_RESOURCE_WORKERS = 'resource-workers';
export const CACHE_TIMEZONES = 'cache-timezones';

export const CACHE_FREEDAYS = 'freedays-cache';
export const CACHE_FREEDAYS_WORKERS = 'freedays-cache-workers';

export const CACHE_ORDERS = 'orders-cache';

export const CACHE_TIME_MODULES = ONE_WEEK;
export const CACHE_TIME_TIMEZONES = ONE_YEAR;
export const CACHE_TIME_COUNTRIES = ONE_DAY;
export const CACHE_TIME_RIGHT_AREAS = ONE_MONTH;
export const CACHE_TIME_RIGHT_GROUPS = ONE_MONTH;
export const CACHE_TIME_RIGHT_RELATIONS = ONE_DAY;
export const CACHE_TIME_VEHICLES = ONE_DAY;
export const CACHE_TIME_CLIENTS = ONE_DAY;
export const CACHE_TIME_RESOURCE_KFZ = ONE_WEEK;
export const CACHE_TIME_RESOURCE_MODEL = ONE_WEEK;
export const CACHE_TIME_RESOURCE_WORKERS = ONE_WEEK;
export const CACHE_TIME_FREEDAYS = ONE_MONTH;
export const CACHE_TIME_ORDERS = ONE_DAY;


export const SUB_SHOW_FEEDBACK = 'sub-show-feedback';
export const SUB_SHOW_CALLBACK = 'sub-show-callback';
export const SUB_PAUSE_APPOINTMENT = "sub-pause-appointment";
export const SUB_PAUSE_RESOURCE_APPOINTMENT = "sub-pause-resource-appointment";
export const SUB_RESUME_APPOINTMENT = "sub-resume-appointment";
export const SUB_ORDER_DELETE = "sub-order-delete";
export const SUB_ORDER_CHANGE_TIMES_RELOAD = 'sub-order-change-times-reload';
export const SUB_SSE_STATUS = 'sub-sse-status';

export const SUB_ORDER_DATE_PICKER_RESET = 'sub-order-date-picker-reset';
export const SUB_ORDER_DATE_PICKER_SET = 'sub-order-date-picker-set';
export const SUB_ORDER_DATE_PICKER_SET_DATE = 'sub-order-date-picker-set-date';
export const SUB_ORDER_DATE_PICKER_SET_TIME = 'sub-order-date-picker-set-time';

export const SUB_CALCULATE_SERVICE_PRICES = 'sub-calculate-service-prices';
export const SUB_SERVICE_SELECTOR_RESET = 'sub-service-selector-reset';
export const SUB_LOAN_CAR_SCHEDULE_EVENT_REMOVED = 'sub-loan-car-schedule-event-removed';
export const SUB_SESSION_TIMEOUT = "sub-session-timeout";
export const SUB_LOGIN_SUCCESSFUL = "sub-login-successful";
export const SUB_SSE_RECONNECT = "sub-sse-reconnect";
export const SUB_PAUSED_NOTIFICATION = "sub-paused-notification";

export const SUB_DAY_SCHEDULE = 'sub-day-schedule';

export const SUB_UNAUTHERIZED = "sub-unauthorized";
export const SUB_CLIENT_SEARCH = 'sub-client-search';

export const SUB_TEST = "sub-test";
export const SUB_VERSION_UPDATE = "sub-version-update";

export const SUB_WHEEL = 'sub-wheel';
export const SUB_WHEEL_DELETE = 'sub-wheel-delete';

export const STORE_SECTION_WORK = "work_view";
export const STORE_SECTION_ACITIVITY = "work_activity";

export const STORE_APPOINTMENTS_FILTERS = 'store-appointment-filters';
export const STORE_WORK_NOTIFICATION_IDS = "store-work-notification-ids";
export const STORE_WORK_NOTIFICATION_IDS_FOR_WORKER = (id) => "store-work-notification-ids-" + id;
