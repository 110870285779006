import * as React from "react";
import PropTypes from "prop-types";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Client, Vehicle} from "../../../../models";
import {Button, Divider, Icon, Modal, Segment} from "semantic-ui-react";
import VehiclePopupInfo from "../../vehicles/VehiclePopupInfo";
import {VehicleList} from "../../vehicles/widgets/VehicleList";
import cn from "classnames";
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {get, isString, isUndefined} from "lodash";
import {clientCall__searchDetailed} from "../../../../actions/clientActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {COLORS} from "../../../../Logic/constants";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {TextField} from "material-ui";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {trueNull} from "../../../../Logic/extensions";
import {MobileAwarePopup} from "../../../partials/MiniComponents";

class VehicleInlineList extends React.Component {
	static propTypes = {
		vehicles: PropTypes.any,
		limit: PropTypes.number,
		onSelect: PropTypes.func,
		onSelectText: PropTypes.node,
		showAllText: PropTypes.node,
		client: PropTypes.object.isRequired,
		directSelect: PropTypes.bool
	};
	static defaultProps = {
		limit: 3,
		onSelectText: 'Auswählen',
		showAllText: 'Alle'
	};
	state = {
		vehicle: null,
		showAll: false
	}
	get vehicle() {
		return this.state.vehicle;
	}
	get client() {
		return this.props.client;
	}
	setVehicle = (vehicle) => () => this.setState({vehicle});
	showAll = showAll => () => this.setState({showAll});
	
	select = (vehicle) => () => {
		const {onSelect, directSelect, client} = this.props;
		if (directSelect && onSelect) {
			onSelect(vehicle, client);
		} else {
			this.setVehicle(vehicle)();
		}
	}
	
	render() {
		let {vehicles, limit, onSelect, onSelectText, client, showAllText} = this.props;
		return <div>
			<EasyFlex wrap>
				{vehicles.slice(0, limit).map((vehicle, index) => {
					const v = vehicle instanceof Vehicle ? vehicle : new Vehicle(vehicle);
					return <div key={vehicle.vehicle_id} className={'cursor pointer'} onClick={this.select(vehicle)} style={{margin: 3}}>
						{v.registration_shield}
					</div>
				})}
				<EasyFlex valign={EasyFlex.valign.CENTER} style={{paddingLeft: 10, fontWeight: 'bold'}} className={'cursor pointer'} onClick={this.showAll(true)}>
					{vehicles.length > limit ? `+ ${vehicles.length - limit}` : showAllText}
				</EasyFlex>
			</EasyFlex>
			<Modal open={!!this.state.vehicle} onClose={this.setVehicle(null)} centered size={'mini'}>
				<Modal.Content>
					{this.state.vehicle && <VehiclePopupInfo inverted={false} vehicle={this.state.vehicle}/>}
				</Modal.Content>
				<Modal.Actions>
					{onSelect && <Button basic onClick={onSelect.bind(null, this.vehicle, this.client)}>{onSelectText}</Button>}
					<Button onClick={this.setVehicle(null)}>Schließen</Button>
				</Modal.Actions>
			</Modal>
			<Modal open={this.state.showAll} onClose={this.showAll(false)} centered={false} size={'large'}>
				<Modal.Content>
					{client && <VehicleList headerProps={{top: -16}} client_id={client.client_id} onSelect={onSelect ? v => onSelect(v, this.client) : null}/>}
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={this.showAll(false)}>Schließen</Button>
				</Modal.Actions>
			</Modal>
		</div>;
	}
}



const ClientLine = ({client: c, onSelect, onSelectVehicle, onSelectVehicleText, directSelect, onClose, closeOnSelect}) => {
	if (!c) return null;
	let client;
	if (c instanceof Client) {
		client = c;
	} else {
		client = new Client(c);
	}
	
	const _select = (cl) => () => {
		if (onSelect) {
			onSelect(cl);
			closeOnSelect && onClose && onClose();
		}
	}
	
	const _selectVehicle = (vehicle, client) => {
		if (onSelectVehicle) {
			onSelectVehicle(vehicle, client);
			closeOnSelect && onClose && onClose();
		}
	}
	
	return (
		<Segment basic>
			<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} className={cn({cursor: !!onSelect, pointer: !!onSelect, hover: !!onSelect})} onClick={_select(client.origin)}>
				<div>
					<EasyFlex wrap>
						<EasyFlex valign={EasyFlex.valign.CENTER} style={{paddingTop: 5, paddingBottom: 5}}>
							<client.Icon/>
							<span style={{marginLeft: 5}}>{client.is_company ? client.company_name : client.contact}</span>
						</EasyFlex>
						{client.is_company && client.company_name !== client.contact && client.company_name !== client.contact_reverse && <EasyFlex valign={EasyFlex.valign.CENTER} style={{marginLeft: 20, paddingTop: 5, paddingBottom: 5}}>
							<client.PrivateIcon/>
							<span style={{marginLeft: 5}}>{client.contact}</span>
						</EasyFlex>}
					</EasyFlex>
					<EasyFlex style={{paddingTop: 5, paddingBottom: 5}} valign={EasyFlex.valign.CENTER}>
						<Icon name={'address book'} size={'large'}/>
						{client.has_address ? <div>
							{client.street_full && <div>{client.street_full}</div>}
							{client.city_full && <div>{client.city_full}</div>}
							{client.address_extra && <div>{client.address_extra}</div>}
						</div> : <div style={{fontSize: 'smaller', color: 'grey'}}>keine Adresse</div>}
					</EasyFlex>
				</div>
				<div>
					<EasyFlex valign={EasyFlex.valign.CENTER} style={{marginLeft: 10, paddingTop: 5, paddingBottom: 5}} align={EasyFlex.align.END}>
						<span style={{marginRight: 5}}>{client.client_number || 'n.v.'}</span>
						<Icon name={'address card'} size={'large'}/>
					</EasyFlex>
					{client.email && <EasyFlex valign={EasyFlex.valign.CENTER} style={{marginLeft: 10, paddingTop: 5, paddingBottom: 5}} align={EasyFlex.align.END}>
						<span style={{marginRight: 5}}>{client.email || 'n.v.'}</span>
						<Icon name={'mail'} size={'large'}/>
					</EasyFlex>}
					{client.phone_full && <EasyFlex align={EasyFlex.align.END} style={{paddingTop: 5, paddingBottom: 5}}>
						<span style={{marginRight: 5}}>{client.phone_full || 'n.v.'}</span>
						<Icon name={'phone'} size={'large'}/>
					</EasyFlex>}
					{client.mobile && <EasyFlex align={EasyFlex.align.END} style={{paddingTop: 5, paddingBottom: 5}}>
						<span style={{marginRight: 5}}>{client.mobile || 'n.v.'}</span>
						<Icon name={'mobile alternate'} size={'large'}/>
					</EasyFlex>}
				</div>
			</EasyFlex>
			<VehicleInlineList
				directSelect={directSelect}
				client={client.origin}
				vehicles={client.vehicles || []}
				onSelect={_selectVehicle}
				onSelectText={onSelectVehicleText}
				showAllText={null === client.vehicles ? <EasyFlex valign={EasyFlex.valign.CENTER} style={{marginLeft: -10}}><Icon name={'car'} size={'large'} style={{marginRight: 8}}/> Zeige alle</EasyFlex> : 'Zeige Liste'}
			/>
		</Segment>
	);
};


const fieldStyle = {maxWidth: 140, marginRight: 10};

class ClientSearchMask extends StatyComponent {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		loading: PropTypes.bool,
		showSubmitButton: PropTypes.bool,
		showResetButton: PropTypes.bool,
		onReset: PropTypes.func,
		onClose: PropTypes.func,
		customerOne: PropTypes.bool
	};
	state = {
		first_name: '',
		name: '',
		company: '',
		client_number: '',
		registration_mark: '',
		chassis_number: '',
		customerone: false
	}
	
	get empty() {
		for (const value of Object.values(this.state)) {
			if (isString(value) && value !== '') {
				return false;
			}
		}
		return true;
	}
	
	get valid() {
		for (const value of Object.values(this.state)) {
			if (isString(value) && value.trim()) {
				return true;
			}
		}
		return false;
	}
	
	reset = () => {
		const next = {};
		for (const index of Object.keys(this.state)) {
			if (index==='customerone') {
				continue;
			}
			next[index] = '';
		}
		this.setState(next, () => this.props.onReset && this.props.onReset(next));
	}
	
	update = (index) => (e) => this.setState({
		[index]: get(e, 'target.value', e)
	});
	
	updateMaterial = (index) => (e, value) => this.setState({
		[index]: value
	});
	
	updateCustomerOne = () => this.setState(state => ({customerone: !state.customerone}));
	
	showActionbar = memoizeOne(
		(...args) => args.some(Boolean)
	);
	
	customerOneSubmit = (e) => {
		this.props.onSubmit({...this.state, customerone: true}, e);
	};
	
	render() {
		let {onSubmit, loading, showSubmitButton, showResetButton, onReset, onClose, customerOne, ...props} = this.props;
		const {first_name, name, company, client_number, registration_mark, chassis_number, customerone} = this.state;
		const showActionBar = this.showActionbar(showSubmitButton, showResetButton, onClose, customerone)
		return (
			<form onSubmit={ e => {
				e.stopPropagation()
				onSubmit(this.state, e)
			}} {...props}>
				<EasyFlex wrap align={EasyFlex.align.CENTER}>
					<TextField
						floatingLabelText={'Kennzeichen'}
						value={registration_mark}
						onChange={this.updateMaterial('registration_mark')}
						style={fieldStyle}
					/>
					<TextField
						floatingLabelText={'Nachname'}
						value={name}
						onChange={this.updateMaterial('name')}
						style={fieldStyle}
					/>
					<TextField
						floatingLabelText={'Fahrgestellnummer'}
						value={chassis_number}
						onChange={this.updateMaterial('chassis_number')}
						style={fieldStyle}
					/>
					<TextField
						floatingLabelText={'Kundennummer'}
						value={client_number}
						onChange={this.updateMaterial('client_number')}
						style={fieldStyle}
					/>
					<TextField
						floatingLabelText={'Unternehmen'}
						value={company}
						onChange={this.updateMaterial('company')}
						style={fieldStyle}
					/>
					<TextField
						floatingLabelText={'Vorname'}
						value={first_name}
						onChange={this.updateMaterial('first_name')}
						style={fieldStyle}
					/>
				</EasyFlex>
				<div style={{ display: showActionBar ? 'block' : 'none', marginTop: 5}}>
					<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
						<div>
						
						</div>
						<div>
							{onClose && <MobileAwarePopup content={'Schließen'} inverted><Button type={'button'} icon={'close'} onClick={onClose} basic/></MobileAwarePopup>}
							{!this.empty && showResetButton && <MobileAwarePopup content={'Formular zurücksetzen'} inverted><Button type={'button'} icon={'history'} onClick={this.reset} basic/></MobileAwarePopup>}
							<Button.Group>
								{customerOne && <MobileAwarePopup content={'CustomerOne Abfrage'} inverted><Button icon loading={loading} type={'button'} color={'green'} disabled={!this.valid} onClick={this.customerOneSubmit}><Icon name={'search'}/>CO</Button></MobileAwarePopup>}
								{showSubmitButton && <MobileAwarePopup content={'Lokale Abfrage'} inverted><Button color={'blue'} loading={loading} disabled={!this.valid} icon={'search'} /></MobileAwarePopup>}
							</Button.Group>
						</div>
					</EasyFlex>
				</div>
			</form>
		);
	}
}


export class ClientSearch extends StatyComponent {
	static propTypes = {
		closeOnSelect: PropTypes.bool,
		showSubmitButton: PropTypes.bool,
		showResetButton: PropTypes.bool,
		onSearch: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onClose: PropTypes.func,
		onSelectClient: PropTypes.func,
		onSelectVehicle: PropTypes.func,
		onSelectVehicleText: PropTypes.node,
		directSelect: PropTypes.bool,
		customerOne: PropTypes.bool
	};
	static defaultProps = {
		showSubmitButton: false,
		onSearch: (options) => clientCall__searchDetailed(options),
		onNotificationBad: (message) => dispatchSnack(message, 'alert')
	};
	
	state = {
		searching: false,
		result: null,
		query: {
			name: '',
			first_name: '',
			company: '',
			client_number: '',
			registration_mark: '',
			chassis_number: ''
		}
	};
	
	get valid() {
		const {name, first_name, company, client_number, registration_mark, chassis_number} = this.state.query;
		return Boolean(name.trim() || first_name.trim() || company.trim() || client_number.trim() || registration_mark.trim() || chassis_number.trim());
	}
	
	
	search = async (query) => {
		const {onSearch, onNotificationBad} = this.props;
		try {
			this.setState({searching: true});
			const result = await onSearch(query || this.state.query);
			this.setState({result: result.list});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error || e.message);
		} finally {
			this.setState({searching: false});
		}
	}
	
	handleSearch = (query, e) => {
		e.preventDefault();
		this.search(query);
	}
	
	render() {
		const {showSubmitButton, showResetButton, onClose, onSelectClient, onSelectVehicle, onSelectVehicleText, directSelect, closeOnSelect, customerOne} = this.props;
		const {
			searching,
			// query,
			result
		} = this.state;
		return (
			<div style={{position: 'relative'}}>
				<Segment raised style={{position: 'sticky', top: -15, zIndex: 2}}>
					<ClientSearchMask customerOne={customerOne} loading={searching} onClose={onClose} onSubmit={this.handleSearch} showSubmitButton={showSubmitButton} showResetButton={showResetButton}/>
				</Segment>
				{trueNull(result && result.length) && <Segment tertiary style={{color: COLORS.SECONDARY, backgroundColor: COLORS.BACKGROUND}}>
					{result.map(client =>
						<React.Fragment key={client.client_id}>
							<ClientLine closeOnSelect={closeOnSelect} onClose={onClose} directSelect={directSelect} client={client} onSelect={onSelectClient} onSelectVehicle={onSelectVehicle} onSelectVehicleText={onSelectVehicleText}/>
							<Divider fitted/>
						</React.Fragment>
					)}
				</Segment>}
				{trueNull(result && !result.length) && <Segment tertiary style={{color: COLORS.SECONDARY, backgroundColor: COLORS.BACKGROUND, textAlign: 'center'}}>
					Keine Ergebnisse
				</Segment>}
			</div>
		);
	}
}

const _ClientSearchModal = ({open, onModalClose, modalProps, ...props}) => (
	<Modal centered={false} basic size={'small'} open={open} closeIcon onClose={onModalClose} {...modalProps}>
		<Modal.Content>
			<ClientSearch
				directSelect={false}
				onClose={onModalClose}
				showSubmitButton={true}
				showResetButton={true}
				{...props}
			/>
		</Modal.Content>
		<Modal.Actions>
			<Button onClick={onModalClose}>Schließen</Button>
		</Modal.Actions>
	</Modal>
);

export const ClientSearchModal = connect(
	(state, props) => ({
		customerOne: isUndefined(props.customerOne) ? state.carhouse.customerone : props.customerOne
	})
)(_ClientSearchModal);
ClientSearchModal.propTypes = {
	open: PropTypes.bool,
	onModalClose: PropTypes.func,
	modalProps: PropTypes.object,
	closeOnSelect: PropTypes.bool,
	showSubmitButton: PropTypes.bool,
	showResetButton: PropTypes.bool,
	onSearch: PropTypes.func,
	onNotificationBad: PropTypes.func,
	onClose: PropTypes.func,
	onSelectClient: PropTypes.func,
	onSelectVehicle: PropTypes.func,
	onSelectVehicleText: PropTypes.node,
	directSelect: PropTypes.bool
};

export const NavigationClientSearchModal = connect(
	null,
	dispatch => ({
		push: url => dispatch(push(url)),
		onSelectClient: client => dispatch(push(`/clients/${client.client_id}`)),
		onSelectVehicle: (vehicle, client) => dispatch(push(`/clients/${client.client_id}/vehicles/${vehicle.vehicle_id}`))
	})
)(ClientSearchModal)
NavigationClientSearchModal.propTypes = {
	open: PropTypes.bool,
	onModalClose: PropTypes.func,
	modalProps: PropTypes.object,
	closeOnSelect: PropTypes.bool,
	showSubmitButton: PropTypes.bool,
	showResetButton: PropTypes.bool,
	onSearch: PropTypes.func,
	onNotificationBad: PropTypes.func,
	onClose: PropTypes.func,
	onSelectClient: PropTypes.func,
	onSelectVehicle: PropTypes.func,
	onSelectVehicleText: PropTypes.node,
	directSelect: PropTypes.bool
};