import React from 'react';
import {NavLink as Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {showMenu} from "../../actions/menuActions";
import {connect} from "react-redux";

let NavLinkView = ({
	                   to,
	                   children, onClose,
	                   doNotClose = false,
	                   onAction = null,
	                   style = {},
	                   href = null,
	                   className = '',
	                   stopPropagation = false,
	                   activeClassName = '',
	                   exact,
					   router: {location: {pathname: path}},
	                   ...props
}) => {
	// const path = window.location.pathname;
	const toTest = to ? (exact !== undefined ? path : path.substr(0, to.length)) : null;
	
	return(
		!to ?
			<a className={`${className} unselectable`}
			   style={{...style, cursor: 'pointer'}}
			   href={href}
			   onClick={e => {stopPropagation && e.stopPropagation(); onAction(e)}} {...props}>
				{children}
				</a>
			:
			<Link to={to} activeClassName={null}
			      className={`${className} click pointer unselectable ${to === toTest ? activeClassName : ''}`}
			      onClick={e => !doNotClose && onClose(e)} style={style} {...props}>
				{children}
				</Link>
	);
};

const mapStateToProps = (state, props) => ({
	// onAction: props.onAction
	router: state.router
});

const mapDispatchToProps = (dispatch, props) => ({
	onClose: (e) => {
		if (props.onClose) {
			props.onClose(e, dispatch);
		} else {
			dispatch(showMenu(false));
		}
	},
	onAction: e => {
		props.onAction && props.onAction(e, dispatch);
	}
});

let NavLink = connect(mapStateToProps, mapDispatchToProps)(NavLinkView);

NavLink.propTypes = {
	to: PropTypes.string,
	onAction: PropTypes.func,
	children: PropTypes.node.isRequired,
	doNotClose: PropTypes.node,
	onClose: PropTypes.func,
	stopPropagation: PropTypes.bool,
	activeClassName: PropTypes.string,
	exact: PropTypes.any
};

export default NavLink;