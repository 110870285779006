import BaseModel from "./BaseModel";
import moment from "../Logic/Moment";
import * as React from 'react';
import {DefaultProvider} from "./Providers";
import {connect} from "react-redux";
import {orderAction__collectResources} from "../actions/orderActions";
import {withChange} from "../Logic/withInit";
import {Table} from "semantic-ui-react";
import round from 'round-precision';
import {Checkbox} from 'material-ui';
import {trueNull} from "../Logic/extensions";
import {EasyFlex} from "../components/partials/ActionHeader";
import {ProviderComponent} from "../components/partials";

const PopupInfo = ({resource: r, style, ...props}) => {
	if (!r) return null;
	let resource;
	if (r instanceof OrderResource) {
		resource = r;
	} else {
		resource = new OrderResource(r);
	}
	return (
		<Table celled basic={'very'} collapsing {...props}>
			<Table.Body>
				<Table.Row>
					<Table.Cell textAlign={'right'}>ID</Table.Cell>
					<Table.Cell>{resource.id}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>CustomService</Table.Cell>
					<Table.Cell><Checkbox readOnly checked={resource.is_custom} inputStyle={{pointerEvents: 'none'}}/></Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>OrderServiceID</Table.Cell>
					<Table.Cell>{resource.order_service_id}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>WorkerID</Table.Cell>
					<Table.Cell>{resource.workers_id}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>RessourcenID</Table.Cell>
					<Table.Cell>{resource.resource_id}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Arbeitsstunden</Table.Cell>
					<Table.Cell>{resource.hours_of_work}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Startzeit</Table.Cell>
					<Table.Cell>{resource.start_moment.format('LLL')}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Endzeit</Table.Cell>
					<Table.Cell>{resource.end_moment.format('LLL')}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>RessourceArt</Table.Cell>
					<Table.Cell>{resource.resource_name}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Verschoben</Table.Cell>
					<Table.Cell>{resource.relocated}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Beendet</Table.Cell>
					<Table.Cell>
						<EasyFlex wrap valign={EasyFlex.valign.CENTER}>
							<Checkbox readOnly checked={resource.is_finished} style={{marginRight: 10, width: 'auto'}} inputStyle={{pointerEvents: 'none'}}/>
							<span>{resource.is_finished ? resource.finished_moment.format('LLL') : null}</span>
						</EasyFlex>
					</Table.Cell>
				</Table.Row>
				{resource.finished_by && <Table.Row>
					<Table.Cell textAlign={'right'}>Beendet durch</Table.Cell>
					<Table.Cell>{resource.finished_by}</Table.Cell>
				</Table.Row>}
				<Table.Row>
					<Table.Cell textAlign={'right'}>Erstellzeit</Table.Cell>
					<Table.Cell>{resource.created_moment.format('LLL')}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign={'right'}>Gelöscht</Table.Cell>
					<Table.Cell>
						<EasyFlex wrap valign={EasyFlex.valign.CENTER}>
							<Checkbox readOnly checked={resource.is_deleted} style={{marginRight: 10, width: 'auto'}} inputStyle={{pointerEvents: 'none'}}/>
							<span>{trueNull(resource.is_deleted) && resource.deleted_moment.format('LLL')}</span>
						</EasyFlex>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>
	);
}


export class OrderResource extends BaseModel {
	static RELOCATE_NONE = 'NONE';
	static RELOCATE_SPLIT = 'SPLIT';
	static RELOCATE_OK = 'OK';
	static RELOCATE_FORCED = 'FORCED';
	static RELOACTE_FAILED = 'FAILED';
	
	_validateModel(model) {
		return 'order_service_resource_id' in model;
	}
	
	get order_service_resource_id() {
		return Number(this.__model.order_service_resource_id) || null;
	}
	
	get id() {
		return this.order_service_resource_id;
	}
	
	get is_custom() {
		return Boolean(this.__model.custom);
	}
	
	get order_service_id() {
		return Number(this.__model.order_service_id);
	}
	
	get resource_id() {
		return Number(this.__model.resource_id);
	}
	
	get workers_id() {
		return Number(this.__model.workers_id);
	}
	
	get hours_of_work() {
		return round(Number(this.__model.hours_of_work), 1);
	}
	
	get start_point() {
		return this.__model.start_point;
	}
	
	get start_moment() {
		return moment(this.start_point);
	}
	
	get end_point() {
		return this.__model.end_point;
	}
	
	get end_moment() {
		return moment(this.end_point);
	}
	
	get resource_name() {
		return this.__model.resource_name;
	}
	
	get finished_at() {
		return this.__model.finished_at;
	}
	
	get finished_moment() {
		return this.finished_at ? moment(this.finished_at) : null;
	}
	
	get is_finished() {
		return Boolean(this.finished_at);
	}
	
	get finished_by() {
		return Number(this.__model.finished_by) || null;
	}
	
	get created_at() {
		return this.__model.created_at;
	}
	
	get created_moment() {
		return moment(this.created_at);
	}
	
	get deleted_at() {
		return this.__model.deleted_at;
	}
	
	get deleted_moment() {
		return this.deleted_at ? moment(this.deleted_at) : null;
	}
	
	get is_deleted() {
		return Boolean(this.deleted_at);
	}
	
	get relocated() {
		return this.__model.relocated;
	}
	
	is_relocated = (...flags) => flags.includes(this.relocated);
	
	static PopupInfo = PopupInfo;
	
	static Provider = connect(
		(state, props) => {
			const r = state.map.order2service2resources[props.id];
			return {
				data: r ? new OrderResource(r) : null
			};
		},
		dispatch => ({
			withChange: ({id}) => dispatch(orderAction__collectResources([id]))
		})
	)(withChange(DefaultProvider, {access: 'id', dynamicIndex: 'dynamic'}));
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.order2service2resources[id];
			data = data ? new OrderResource(data) : null;
			return {data};
		}
	)(ProviderComponent)
}
