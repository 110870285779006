import * as React from 'react';
import {StatyComponent} from "../../Tools/ReactExtension";
import {ConnectedRelocateConflicts} from "../intern/orders/relocate";


export class RelocateTest extends StatyComponent {
	render() {
		return (
			<div style={{padding: 30}}>
				<ConnectedRelocateConflicts/>
			</div>
		);
	}
}