import * as React from "react";
import PropTypes from "prop-types";
import {moment} from "../Logic/Moment";
import {Segment} from "semantic-ui-react";
import {Flex, FlexChild} from "./partials/ActionHeader";
import {DatePicker, FlatButton, RaisedButton} from "material-ui";
import {DATE_PICKER} from "../Logic/constants";
import {Loadable} from "../Logic/extensions";

export class DateRangeView extends React.Component {
	static propTypes = {
		from: PropTypes.instanceOf(Date),
		to: PropTypes.instanceOf(Date),
		onFetch: PropTypes.func.isRequired,
		loading: PropTypes.bool
	};
	static defaultProps = {
		onFetch: () => alert('DateRangeView::onFetch() is not implemented, yet!'),
		loading: false
	};
	state = {
		from: null,
		to: null
	};
	
	componentDidMount() {
		const {from, to} = this.props;
		if (from instanceof Date) {
			this.setState({from});
		}
		if ( to instanceof Date) {
			this.setState({to});
		}
	}
	
	changeDate = (index) => (e, value) => this.setState({[index]: value});
	
	setDays = (count) => () => {
		let from = moment();
		if ( count < 0 ) {
			from = from.subtract(1, 'day');
		}
		let to = from.clone();
		to = to.add(count, 'day').toDate();
		from = from.toDate();
		if (count <  0 ) {
			this.setState({from: to, to: from});
		} else {
			this.setState({from, to});
		}
	};
	
	render() {
		const {onFetch, loading} = this.props;
		const {from, to} = this.state;
		
		return (
			<Segment basic>
				<Flex valign={"center"}>
					<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Schnellwahl:</strong>
					<Flex gutterHeight={20} gutterWidth={20} wrap>
						<FlexChild><FlatButton primary onClick={this.setDays(0)}>Heute</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(1)}>2 Tage</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(2)}>3 Tage</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(6)}>1 Woche</FlatButton></FlexChild>
						<FlexChild><FlatButton primary onClick={this.setDays(-6)}>letzte Woche</FlatButton></FlexChild>
					
					</Flex>
				</Flex>
				<Flex>
					<FlexChild>
						<Flex valign={'center'}>
							<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Von:</strong>
							<DatePicker
								{...DATE_PICKER()}
								hintText={"Datum"}
								floatingLabelText={'Datum'}
								maxDate={to}
								value={from}
								onChange={this.changeDate('from')}
							/>
						</Flex>
						<Flex valign={'center'}>
							<strong style={{paddingRight: 30, display: 'block', minWidth: 120}}>Bis:</strong>
							<DatePicker
								{...DATE_PICKER()}
								hintText={"Datum"}
								floatingLabelText={'Datum'}
								minDate={from}
								value={to}
								onChange={this.changeDate('to')}
							/>
						</Flex>
					</FlexChild>
					<Flex align={'center'} grow={1} valign={"center"}>
						{from && to && <Loadable hoc={RaisedButton} inverse loading={loading} disabledOnLoading onClick={onFetch.bind(null, from, to)} primary disabled={null === from && null === to}>Aktualisieren</Loadable>}
					</Flex>
				</Flex>
			</Segment>
		);
	}
}