import * as React from "react";
import PropTypes from "prop-types";
import {moment, toISO} from "../../../Logic/Moment";
import {deepMemoize as  memoize} from "../../../Logic/extensions";
import {EasyFlex, Flex, FlexChild, FlexItem, FlexWrapBreaker} from "../../../components/partials/ActionHeader";
import {array2object, Loadable, trueNull} from "../../../Logic/extensions";
import {Checkbox, IconButton, RaisedButton, TextField} from "material-ui";
import {IconRestore, IconSave} from "../../../Logic/icons";
import {get, isBoolean, isFunction} from "lodash";
import {OrderDateInfo, OrderDatePicker} from "./OrderDatePickerV2";
import {Message, Segment} from "semantic-ui-react";
import {orderAction__putToken, orderCall__findNext} from "../../../actions/orderActions";
import {loanCarCall__findInRange} from "../../../actions/loanCarActions";
import {OrderClientSelector} from "./OrderClientSelector";
import {OrderVehicleSelector} from "./OrderVehicleSelector";
import {OrderServiceSelector} from "./OrderServiceSelector";
import {OrderServiceSelectionList} from "./OrderServiceSelectionList";
import {LoanCarSelector} from "../../../components/intern/vehicles";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {addSnackbar} from "../../../actions/snackbarActions";
import {translate} from "react-i18next";
import {goToAnchor as goTo} from "react-scrollable-anchor";
import {async} from "ith-fetch";
import {OrderKmUpdate} from "./OrderKmUpdate";
import {OrderOptionsView} from "../../../components/intern/orders/OrderOptionsView";

const MIN_TITLE_WIDTH = 150;
const MIN_INFO_WIDTH = 220;
const CLASS_INFO_LINE = "order-result-info-line";

const goToAnchor = id => async(25).then(goTo.bind(undefined, id));
const anchor = id => "order-find-next-" + id;
const ANCHOR_TOP = "top";
const ANCHOR_SERVICE = "service";
const ANCHOR_DATES = "dates";
const ANCHOR_RESULT = "result";
const ANCHOR_LOAN_CARS = "loan-cars";

// class UpdatingDate extends React.PureComponent {
// 	static propTypes = {
// 		date: PropTypes.instanceOf(Date),
// 		format: PropTypes.string
// 	};
// 	static defaultProps = {
// 		format: "LLL"
// 	};
//
// 	componentDidMount() {
// 		this.interval = window.setInterval(() => {
// 			if (!this.props.date || this.date(this.props.date) !== moment().format(this.props.format)) {
// 				this.forceUpdate();
// 			}
// 		}, 1000);
// 	}
//
// 	componentWillUnmount() {
// 		window.clearInterval(this.interval);
// 	}
//
// 	date = memoize(date => moment(date || undefined).format(this.props.format));
//
// 	render() {
// 		return moment(this.props.date || undefined).format(this.props.format);
// 	}
// }

const FlexLine = ({title, children, titleWidth, propsTitle, propsContent, after, ...props}) => (
	<EasyFlex style={{marginLeft: -12, marginRight: -12}} valign={EasyFlex.valign.CENTER} {...props}>
		<FlexItem shrink={0.1} style={{minWidth: titleWidth || MIN_TITLE_WIDTH, marginLeft: 12, marginRight: 12}} {...propsTitle}>
			{!title ? <span>&nbsp;</span>  : <strong>{title}:</strong>}
		</FlexItem>
		<FlexItem shrink={0.1} grow={100} {...propsContent} style={{marginLeft: 12, marginRight: 12}}>
			{children}
		</FlexItem>
		{after}
	</EasyFlex>
);
FlexLine.propTypes = {
	title: PropTypes.node,
	children: PropTypes.node,
	propsTitle: PropTypes.object,
	propsContent: PropTypes.object,
	titleWidth: PropTypes.number,
	after: PropTypes.node
};

class Result extends React.Component {
	static propTypes = {
		requestDate: PropTypes.instanceOf(Date),
		loanCar: PropTypes.bool,
		version: PropTypes.number,
		start: PropTypes.instanceOf(Date),
		end: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
		onValid: PropTypes.func,
		children: PropTypes.node
	};
	static defaultProps = {
		onValid: () => {}
	};
	
	state = {
		start: this.props.start || null,
		end: this.props.end || null,
	};
	
	start = null;
	end = null;
	
	componentDidMount() {
		this.change();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const error = this.error();
		this.props.onValid(!error, error);
	}
	
	error = () => {
		const {
			start: s,
			end: e
		} = this.state;
		if ( !s || !e) return "Daten unvollständig!";
		if (s > this.props.start) return "Startzeit zu spät!";
		if (s < new Date(Math.min(this.props.start, new Date()))) return "Startzeit liegt in der Vergangenheit";
		if (e < this.props.end) return "Endzeit zu früh";
		return null;
	};
	
	reset = () => this.start && this.end && this.start.reset() && this.end.reset();
	
	change = () => {
		this.props.onChange(this.state);
	};
	
	render() {
		let {start, end, version, requestDate, loanCar, children} = this.props;
		const error = this.error();
		return (
			<div>
				<Flex align={"space-between"} valign={"baseline"}>
					<h4>Gefundener Termin {trueNull(version) && `v${version}`}</h4>
					<IconButton onClick={this.reset}><IconRestore/></IconButton>
				</Flex>
				{requestDate &&
				<FlexLine className={CLASS_INFO_LINE} title={"Angefragter Zeitpunkt"} titleWidth={MIN_INFO_WIDTH}>
					{moment(requestDate).format("LLL")}
				</FlexLine>
				}
				{isBoolean(loanCar) &&
				<FlexLine className={CLASS_INFO_LINE} title={"Leihwagen"} titleWidth={MIN_INFO_WIDTH}>
					{loanCar ? "Ist erwünscht" : "-"}
				</FlexLine>
				}
				{children}
				<FlexLine title={"Abgabe"}>
					<OrderDatePicker
						refMethods={ref => this.start = ref}
						maxDate={start}
						minDate={new Date()}
						date={start}
						time={start}
						onChange={start => this.setState({start}, this.change)}
					>
						<FlexWrapBreaker/>
						<OrderDateInfo date={start} titlePrimary={"Gefundene Startzeit"}/>
					</OrderDatePicker>
				</FlexLine>
				<FlexLine title={"Abholung"}>
					<OrderDatePicker
						refMethods={ref => this.end = ref}
						minDate={end}
						date={end}
						time={end}
						onChange={end => this.setState({end}, this.change)}
					>
						<FlexWrapBreaker/>
						<OrderDateInfo date={end} titlePrimary={"Gefundene Endzeit"}/>
					</OrderDatePicker>
				</FlexLine>
				{error &&
				<FlexLine propsContent={{grow: null}}>
					<Message negative><p>{error}</p></Message>
				</FlexLine>
				}
			</div>
		);
	}
}

const Workload = ({value}) => <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung im Zeitraum</span>}>{value} %</FlexLine>;

const WorkloadRange = ({value: {start_point, end_point, workload}}) => {
	if (moment(start_point).isSame(end_point, "day")) {
		return <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung am selben Tag</span>}>{workload} %</FlexLine>;
	}
	return <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung<br/>&nbsp;&nbsp;&nbsp;&nbsp;zwischen {moment(start_point).format("ll")}<br/>&nbsp;&nbsp;&nbsp;&nbsp;und {moment(end_point).format("ll")}</span>}>{workload} %</FlexLine>;
};



class NextOrderView extends React.Component {
	static propTypes = {
		clients: PropTypes.object,
		onCreate: PropTypes.func,
		onAfterCreate: PropTypes.func
	};
	static defaultProps = {
		clients: {},
		onAfterCreate: () => {}
	};
	initDate = moment().startOf("day");
	initialState = {
		client: this.props.client || null,
		vehicle: this.props.vehicle || null,
		services: [],
		loan_car: false,
		direct_service: false,
		delivery_service: false,
		waiting: false,
		start_date: this.initDate.toDate(),
		lookupMeta: [],
		valid_dates: false,
		release: false,
		info: "",
		km: "",
		update_km: false,
		next_start: null,
		next_end: null,
		loan_car_id: 0,
		loan_cars: [],
		loan_car_range: {
			start: null,
			end: null
		},
		lookup: {
			error: null,
			data: null,
			loading: false
		},
		loancarLookup: {
			error: null,
			data: null,
			loading: false
		}
	};
	
	state = {
		...this.initialState
	};
	
	componentDidMount() {
		window.toAnchor = goToAnchor;
		window.an = anchor;
	}
	
	setLoanCars = (loan_cars = []) => this.setState({loan_cars});
	
	resetState = () => this.setState({...this.initialState});
	
	setClient = client => this.setState({client, vehicle: null, services: []});
	setVehicle = vehicle => this.setState(state => {
		const client = (vehicle && this.props.clients[vehicle.client_id]) || state.client;
		// console.debug('client', vehicle.client_id, client);
		return {
			vehicle,
			client,
			services: []
		};
	}, () => vehicle && goToAnchor(anchor(ANCHOR_SERVICE)));
	setServices = (services) => this.setState({services});
	setLoanCar = loan_car => this.setState({loan_car});
	setDirectService = direct_service => this.setState({direct_service});
	setDeliveryService = delivery_service => this.setState({delivery_service});
	setWaiting = waiting => this.setState({waiting});
	updateLookup = (lookup, nextState = {}, callback) => this.setState(state => ({
		...state,
		...nextState,
		lookup: {
			...state.lookup,
			...lookup
		}
	}), callback);
	/**
	 *
	 * @param {Object} range
	 * @param {Date} range.start
	 * @param {Date} range.end
	 * @param {function} callback
	 */
	updateLoancarRange = (range, callback = undefined) => this.setState(state => ({
		...state,
		loan_car_range: {
			...state.loan_car_range,
			...range
		}
	}), callback);
	/**
	 *
	 * @param {Object} loan
	 * @param {(Object|null)} [loan.error]
	 * @param {(Object|null)} [loan.data]
	 * @param {boolean} [loan.loading]
	 * @param nextState
	 */
	updateLoancar = (loan, nextState = {}) => this.setState(state => ({
		...state,
		...nextState,
		loancarLookup: {
			...state.loancarLookup,
			...loan
		}
	}));
	setStartDate = start_date => {
		this.setState({start_date});
	};
	setLoanCarId = (loanCar) => this.setState({loan_car_id: get(loanCar, 'vehicle_id', 0)});
	
	findNext = async() => {
		const {
			vehicle,
			services,
			start_date,
			// lookupMeta,
			loan_car
		} = this.state;
		// const meta = array2object(lookupMeta)(({version, active}) => ['v'+version, active]);
		const v6 = services.length === 1;
		const query_order = [4, 2, 6];
		
		const data = {
			vehicle_id: vehicle.vehicle_id,
			services: services.map(s => s.service_id || s),
			deliver_date: toISO(start_date),
			loan_car,
			v4: true,
			v2: true,
			v6,
			query_order
		};
		try {
			this.updateLookup({error: null, loading: true, data: null});
			const response = await orderCall__findNext(data);
			this.updateLookup({data: response}, {}, () => goToAnchor(anchor(ANCHOR_RESULT)));
			this.updateLoancarRange(response.loan_car_range);
			this.setLoanCars(response.vehicles);
		} catch (e) {
			this.updateLookup({error: e.message || e.error || e});
			this.setLoanCars();
		} finally {
			this.updateLookup({loading: false});
		}
	};
	
	findLoancars = async () => {
		const {next_start, next_end, loan_car, valid_dates} = this.state;
		if ( !next_end || !next_start || !loan_car || !valid_dates) return;
		try {
			this.updateLoancar({loading: true, error: null});
			const result = await loanCarCall__findInRange({start_point: next_start, end_point: next_end, no_delay: true});
			if ( result.ack) {
				this.setLoanCars(result.list);
				this.updateLoancar({data: result.list});
				this.updateLoancarRange({start: result.start_point, end: result.end_point});
			} else {
				throw Error("Kein Leihfahrzeug gefunden!");
			}
		} catch (e) {
			this.updateLoancar({error: e.error || e.message || e});
			this.setLoanCars();
		} finally {
			this.updateLoancar({loading: false});
		}
	};
	
	getServiceMap = memoize(
		services => array2object(services)(s => s.service_id)
	);
	hasLoanCarModule = memoize(
		modules => Boolean(modules[3])
	);
	
	dateError = memoize(
		(start_date) => {
			if (!start_date) return null;
			const now = moment();
			if (start_date < now) return `Startzeit liegt in der Vergangenheit! (frühste Zeit: ${now.add(1, "minute").format("LLL")})`;
			return null;
		}
	);
	
	isToggled = (version) => this.state[`v${version}`];
	
	save = async () => {
		const {
			lookup: {
				data: {
					version, token
				}
			},
			info,
			release: released,
			next_end,
			next_start,
			km, update_km
		} = this.state;
		const loan_car_id = this.state.loan_car_id || null;
		this.props.onCreate({info, version, token, released, next_end, next_start, loan_car_id, km, update_km}, (result) => {
			this.resetState();
			this.props.onAfterCreate();
		});
	};
	
	validSave = memoize(
		(validDates, loanCar, loanCarId) => validDates && (loanCar === !!loanCarId)
	);
	
	validMode = memoize(
		(serviceCount) => {
			return !!serviceCount;
		}
	);
	
	kmError = memoize(
		(km, originKm) => {
			if (km.trim() === "") return null;
			if (km < originKm) return "Km unterhalb der original hinterlegten Kilometer-Angabe!";
			return null;
		}
	);
	
	render() {
		const {
			client,
			vehicle,
			services,
			lookup,
			loan_car, delivery_service, direct_service, waiting,
			start_date,
			valid_dates,
			release,
			info,
			loan_car_id,
			loancarLookup,
			loan_car_range,
			// lookupMeta,
			km
		} = this.state;
		const {
			modules,
			t
		} = this.props;
		
		const hasLoanCarModule = this.hasLoanCarModule(modules);
		const dateError = this.dateError(start_date);
		const validSave = this.validSave(valid_dates, loan_car, loan_car_id);
		const validMode = this.validMode(services.length);
		return(
			<Segment basic style={{position: 'relative'}} id={anchor(ANCHOR_TOP)}>
				<EasyFlex style={{marginBottom: 30}} align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
					<h3>Neuen Termin anlegen</h3>
					{trueNull(client) && <IconButton onClick={this.resetState}><IconRestore/></IconButton>}
				</EasyFlex>
				<OrderClientSelector minLabelWidth={MIN_TITLE_WIDTH} client={client} onSelectClient={this.setClient} onUnselect={() => this.setClient(null) || this.setVehicle(null)}/>
				<OrderVehicleSelector minLabelWidth={MIN_TITLE_WIDTH} client={client} vehicle={vehicle} onSelectVehicle={this.setVehicle} onUnselect={this.setVehicle.bind(this, null)}/>
				{vehicle &&
				<div id={anchor(ANCHOR_SERVICE)}>
					<OrderServiceSelector minLabelWidth={MIN_TITLE_WIDTH} vehicle={vehicle} onChange={this.setServices}/>
					{services.length > 0 &&
					<div>
						<OrderServiceSelectionList
							vehicle={vehicle}
							services={this.getServiceMap(services)}
							updateOnChange
							onNotifyWorktime={worktime => this.setState({worktime})}
							style={{marginBottom: 20}}
						/>
						{lookup.error &&
						<Message negative>
							<p>{t(lookup.error)}</p>
						</Message>
						}
						{/*<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>*/}
						{/*	{ hasLoanCarModule &&*/}
						{/*	<FlexLine vmargin={5} title={"Leihwagen"}>*/}
						{/*		<Checkbox checked={loan_car} onCheck={(_, value) => this.setLoanCar(value)}/>*/}
						{/*	</FlexLine>*/}
						{/*	}*/}
						{/*	<FlexLine vmargin={5} title={"Direktannahme"}>*/}
						{/*		<Checkbox/>*/}
						{/*	</FlexLine>*/}
						{/*</EasyFlex>*/}
						
						<OrderOptionsView
							key={'next'}
							setDirectService={this.setDirectService}
							loanCar={loan_car}
							directService={direct_service}
							waiting={waiting}
							hasLoanCarModule={hasLoanCarModule}
							setDeliveryService={this.setDeliveryService}
							setLoanCar={this.setLoanCar}
							deliveryService={delivery_service}
							setWaiting={this.setWaiting}
							segmentProps={{basic: true, color: null}}
							celled={'internally'}
						/>
						
						<div id={anchor(ANCHOR_DATES)}/>
						<FlexLine vmargin={5} wrap title={"Abgabe"} after={
							dateError &&
							<React.Fragment>
								<FlexWrapBreaker/>
								<FlexLine propsContent={{grow: null}}><Message negative>{dateError}</Message></FlexLine>
							</React.Fragment>
						}>
							<OrderDatePicker
								textTime={"Zeit [hh:mm]"}
								date={this.initDate.toDate()}
								time={this.initDate.format("HH:mm")}
								onChange={this.setStartDate}
								id={"start"}
							/>
						</FlexLine>
						<div>
							<Flex valign={"center"} align={"center"} wrap>
								{/*<Flex align={"center"}>
									<LookupModeSelector onChange={value => this.setState({lookupMeta: value})} showHandle v4 v2 lockAxis={"y"} v6_disabled={services.length > 1}/>
								</Flex>
								<div style={{width: 30}}/>*/}
								<Flex vmargin={15} direction={"column"} valign={"center"} align={'center'}>
									{/*<EasyFlex>*/}
									{/*	ab <UpdatingDate date={start_date}/>*/}
									{/*</EasyFlex>*/}
									<Loadable
										hoc={RaisedButton}
										onClick={this.findNext}
										primary
										disabled={!validMode}
										loading={lookup.loading}
										disabledOnLoading
										inverse
									>
										<span style={{display: 'inline-block', marginLeft: 10, marginRight: 10}}>Termin suchen</span>
									</Loadable>
								</Flex>
							</Flex>
							{lookup.data &&
							<div id={anchor(ANCHOR_RESULT)}>
								{!lookup.data.ack &&
								<Message color={"orange"}><p>Es konnte keine Termin gefunden werden!</p></Message>
								}
								{lookup.data.ack && lookup.data.found_range &&
									<Result
										onValid={(valid) => this.setState({valid_dates: valid})}
										onChange={({start, end}) => this.setState({next_start: start, next_end: end}, this.findLoancars)}
										start={moment(lookup.data.found_range.start).toDate()}
										end={moment(lookup.data.found_range.end).toDate()}
										version={lookup.data.version}
										requestDate={moment(lookup.data.request.deliver_date).toDate()}
										loanCar={lookup.data.request.loan_car}
									>
										{lookup.data.workload && <Workload value={lookup.data.workload.workload}/>}
										{lookup.data.workload_range && <WorkloadRange value={lookup.data.workload_range}/>}
									</Result>
								}
								
								{loancarLookup.error &&
								<FlexLine propsContent={{grow: null}}><Message negative><p>{loancarLookup.error}</p></Message></FlexLine>
								}
								{trueNull(lookup.data.vehicles) && valid_dates && !loancarLookup.error &&
								<Segment id={anchor(ANCHOR_LOAN_CARS)} basic loading={loancarLookup.loading}>
									<FlexLine propsContent={{grow: null}} titleWidth={MIN_TITLE_WIDTH - 30}>
										{trueNull(loan_car_range.start && loan_car_range.end) &&
										<div>
											<h5>Leihwagen im Zeitraum:</h5>
											<div>{moment(loan_car_range.start).format("LLL")} <span style={{marginLeft: 10, marginRight: 10}}>bis</span> {moment(loan_car_range.end).format("LLL")}</div>
										</div>
										}
										<LoanCarSelector id={loan_car_id} onChange={this.setLoanCarId} loanCars={lookup.data.vehicles}/>
									</FlexLine>
								</Segment>
								}
								<Flex valign={"center"} gutterWidth={25} wrap>
									<FlexChild style={{minWidth: 120}} shrink={0.1}></FlexChild>
									<FlexChild shrink={0.1} grow={100}>
										<OrderKmUpdate onUpdate={update_km => this.setState({update_km})} onChange={km => this.setState({km})} km={vehicle.km} error={this.kmError(km, vehicle.km)}/>
									</FlexChild>
								</Flex>
								<Flex valign={"center"} gutterWidth={25} wrap>
									<FlexChild style={{minWidth: 120}} shrink={0.1}></FlexChild>
									<FlexChild shrink={0.1} grow={100}>
										<TextField
											fullWidth
											floatingLabelText={'Zusätzliche Infos'}
											onChange={(_, info) => this.setState({info})}
											multiLine
											value={info}
										/>
									</FlexChild>
								</Flex>
								<Flex valign={"center"} gutterWidth={25} wrap>
									<FlexChild style={{minWidth: 120}} shrink={0.1}></FlexChild>
									<FlexChild shrink={0.1} grow={100}>
										<Checkbox label={"Direkt freigeben"} checked={release} onCheck={(_, release) => this.setState({release})}/>
									</FlexChild>
								</Flex>
								<Flex vmargin={10} align={"center"}>
									<Loadable onClick={this.save} labelPosition={"before"} hoc={RaisedButton} primary disabledOnLoading disabled={!validSave} icon={<IconSave color={"black"}/>}>
										Termin speichern
									</Loadable>
								</Flex>
							
							</div>
							}
						</div>
						
					</div>
					}
				</div>
				}
			</Segment>
		);
	}
}
NextOrderView = withInit(NextOrderView);
NextOrderView = connect(
	state => ({
		clients: state.clients.list,
		modules: state.modules.list
	}),
	dispatch => ({
		init: () => {
			// dispatch(clientAction__fetch());
		},
		onCreate: (data, onSuccess) => dispatch(orderAction__putToken(data, result => {
			dispatch(addSnackbar("Auftrag wurde gespeichert"));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(NextOrderView);
NextOrderView = translate()(NextOrderView);

export const OrderFindNextController = NextOrderView;