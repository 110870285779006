import areIntLocalesSupported from 'intl-locales-supported';

const langs = [];

if (global.Intl) {
	if (!areIntLocalesSupported(langs)) {
		const intl = require('intl');
		Intl.DateTimeFormat = intl.DateTimeFormat;
		Intl.NumberFormat = intl.NumberFormat;
	}
} else {
	global.Intl = require('intl');
}