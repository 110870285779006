import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Modal, Popup, Segment} from "semantic-ui-react";
import {Flex} from "../../../components/partials/ActionHeader";
import {Divider, FlatButton, IconButton, RaisedButton, TextField} from "material-ui";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../Tools/RightsProvider";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {Trans, translate} from "react-i18next";
import NoAccess from "../../../components/NoAccess";
import {isOnline} from "../../../actions/userActions";
import {vehicleAction__fetch} from "../../../actions/vehicleActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_VEHICLES_FETCH} from "../../../actions";
import {searchAction__loanCar, searchAction__vehicleSimple} from "../../../actions/searchActions";
import VirtualList from "react-tiny-virtual-list";
import {pickBy, values} from "lodash";
import {IconAdd} from "../../../Logic/icons";
import VehicleCreateController from "./VehicleCreateController";
import {loanCarCall__getAll} from "../../../actions/loanCarActions";
import {dispatchSnack} from "../../../actions/snackbarActions";

const Line = ({title, bordered, children, style, ...props}) => <Flex align={"space-between"} vmargin={8}
                                                                     valign={"center"}
                                                                     style={{...style, ...(bordered ? {borderBottom: `1px dotted ${bordered}`} : {})}} {...props}>
	<div style={{fontSize: "smaller", color: "#ccc", minWidth: 80}}>{title}:</div>
	<div style={{minWidth: 20}}/>
	<em style={{whiteSpace: "nowrap"}}>{children}</em>
</Flex>;

const PopupInfo = ({vehicle: {hsn, tsn, chassis_number, registration_mark, name}, ...props}) => {
	return <Segment basic inverted {...props}>
		<Line title={"Name"} bordered={"#666"}>{name}</Line>
		<Line title={"HSN/TSN"} bordered={"#666"}>{hsn} / {tsn}</Line>
		<Line title={"Kennzeichen"} bordered={"#666"}>{registration_mark}</Line>
		<Line title={"Fahr.gstl.nr"} bordered={"#666"}>{chassis_number}</Line>
	</Segment>;
};

class VehicleSearchController extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		loading: PropTypes.bool,
		vehicleMap: PropTypes.object,
		onSearch: PropTypes.func,
		onAbort: PropTypes.func,
		selectOnClick: PropTypes.bool,
		searchDelay: PropTypes.number,
		onSelect: PropTypes.func,
		client_id: PropTypes.number,
		loanCarMode: PropTypes.bool,
		focus: PropTypes.bool,
		onFetch: PropTypes.func,
		onNotificationBad: PropTypes.func,
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		loading: false,
		vehicleMap: {},
		selectOnClick: false,
		searchDelay: 500,
		loanCarMode: false,
		focus: true,
		onSearch: () => alert('VehicleSearchController::onSearch() is not implemented, yet!'),
		onFetch: () => loanCarCall__getAll(),
		onNotificationBad: (message) => dispatchSnack(message, 'alert')
	};
	
	delay = null;
	
	state = {
		loading: false,
		fetching: false,
		list: [],
		searchText: "",
		searchResults: [],
		searchError: null,
		selectedVehicle: 0,
		createVehicle: false
	};
	
	inputField = React.createRef();
	
	componentDidMount() {
		this.props.focus && this.inputField.current.focus();
	}
	
	setCreateVehicle = (createVehicle) => () => this.setState({createVehicle});
	afterVehicleCreate = () => {
		this.setCreateVehicle(false)();
		this.search();
	};
	
	onType = (e, value) => {
		let {searchDelay} = this.props;
		searchDelay = Math.max(0, searchDelay);
		window.clearTimeout(this.delay);
		this.delay = window.setTimeout(this.setSearch.bind(null, value), searchDelay);
	};
	
	setSearch = value => {
		this.setState({searchText: value}, () => {
			if (value.trim() !== "") {
				this.search();
			}
		});
	};
	
	fetch = async () => {
		const {onFetch, onNotificationBad} = this.props;
		try {
			this.setState({fetching: true});
			const response = await onFetch();
			this.setState({list: response.loan_cars});
		} catch (e) {
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({fetching: false});
		}
	};
	
	search = async () => {
		const {searchText} = this.state;
		const {onSearch, client_id, loanCarMode} = this.props;
		try {
			this.setState({loading: true, searchError: null});
			const response = loanCarMode ? await onSearch(searchText, true) : await onSearch(searchText, client_id);
			this.setState({
				searchResults: response.result,
				searchError: response.result.length ? null : "Keine Ergebnisse"
			});
		} catch (e) {
			console.error("error in vehicle search", e);
			this.setState({searchError: e.message});
		} finally {
			this.setState({loading: false});
		}
	};
	
	onSelect = () => {
		const {onSelect, vehicleMap, onAbort} = this.props;
		const {selectedVehicle} = this.state;
		if (onSelect && selectedVehicle) {
			onSelect(vehicleMap[selectedVehicle]);
			onAbort && onAbort();
		}
	};
	
	selectVehicle = idx => () => this.setState(state => ({
		...state,
		selectedVehicle: state.selectedVehicle === idx ? 0 : idx
	}), () => {
		if (this.props.selectOnClick) {
			this.onSelect();
		}
	});
	
	render() {
		const {
			rights: {mayRead},
			loading: propLoading,
			vehicleMap,
			onAbort,
			selectOnClick,
			loanCarMode
		} = this.props;
		
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		const {
			loading,
			searchText,
			searchError,
			searchResults,
			selectedVehicle,
			createVehicle
		} = this.state;
		
		
		let vehicles = [];
		if (searchText.trim() !== "") {
			vehicles = searchResults.map(v => ({
				...v,
				...vehicleMap[v.vehicle_id]
			})).sort((b, a) => a.weight - b.weight);
		} else {
			vehicles = values(vehicleMap);
		}
		if (loanCarMode) {
			vehicles = vehicles.filter(v => v.loan_car);
		}
		
		return (
			<Segment basic loading={propLoading}>
				<Flex align={"space-between"} valign={"center"}>
					<h3 style={{marginBottom: 15}}>Fahrzeug suchen</h3>
					{this.props.client_id ?
						<IconButton onClick={this.setCreateVehicle(true)}><IconAdd/></IconButton> : null}
				</Flex>
				<Flex valign={"center"}>
					<TextField
						ref={this.inputField}
						fullWidth
						floatingLabelText={'Filter'}
						hintText={"Kennzeichen, HSN, TSN, etc."}
						errorText={searchError}
						onChange={this.onType}
					/>
					<Icon style={{marginTop: 25, marginLeft: 10, opacity: loading ? 1 : 0}} name={"spinner"} loading/>
				</Flex>
				<VirtualList
					width={'calc(100% - 20px)'}
					height={350}
					itemCount={vehicles.length}
					itemSize={35}
					renderItem={({index, style}) => {
						const vehicle = vehicles[index];
						const isSelected = vehicle.vehicle_id === selectedVehicle;
						return (
							<Flex className="vehicle-search-controller-item" key={vehicle.vehicle_id}
							      onClick={this.selectVehicle(vehicle.vehicle_id)} style={{
								...style,
								paddingLeft: 10,
								paddingRight: 10,
								backgroundColor: isSelected ? 'lightgreen' : null,
								cursor: 'pointer'
							}} valign={"center"}>
								<span></span>
								<Flex grow={10} align={"space-between"} valign={"center"}>
									<Popup content={<PopupInfo vehicle={vehicle}/>} inverted trigger={<span
										style={{marginLeft: 15, userSelect: 'none'}}>{vehicle.name}</span>}/>
									<div style={{minWidth: 20}}/>
									<span style={{color: "#666", marginRight: 15}}>{vehicle.registration_mark}</span>
								</Flex>
							</Flex>
						);
					}}
				/>
				<div style={{marginBottom: 12}}/>
				<Divider/>
				<Flex valign={"center"} align={"space-between"}>
					<span>{onAbort && <FlatButton onClick={onAbort}>Abbrechen</FlatButton>}</span>
					<span>{!selectOnClick && <RaisedButton primary onClick={this.onSelect}
					                                       disabled={selectedVehicle === 0}>Auswählen</RaisedButton>}</span>
				</Flex>
				{/*<VehicleCreateDialog open={createVehicle} onClose={this.setCreateVehicle(false)} closeButton>
					<VehicleCreateController client_id={this.props.client_id} onAfterSave={this.afterVehicleCreate}
					                         onAfterTransfer={this.afterVehicleCreate} onBack={null}/>
				</VehicleCreateDialog>*/}
				{createVehicle && <Modal open centered={false} onClose={this.setCreateVehicle(false)}>
					<Modal.Content>
						<VehicleCreateController client_id={this.props.client_id} onAfterSave={this.afterVehicleCreate}
						                         onAfterTransfer={this.afterVehicleCreate} onBack={null}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setCreateVehicle(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Segment>
		);
	}
}

// Controllers setup
VehicleSearchController = withRights(SECTION.CLIENTS, VehicleSearchController);
VehicleSearchController = withInit(VehicleSearchController);
VehicleSearchController = connect(
	(state, props) => {
		let vehicleMap = props.vehicleMap || state.vehicles.list;
		if (props.client_id) {
			vehicleMap = pickBy(vehicleMap, v => v.client_id === props.client_id);
		}
		return {
			vehicleMap,
			loading: isLoading(state, PROC_VEHICLES_FETCH)
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(vehicleAction__fetch());
			})),
			onSearch: props.loanCarMode ? searchAction__loanCar : searchAction__vehicleSimple
		}
	}
)(VehicleSearchController);
VehicleSearchController = translate()(VehicleSearchController);


export default VehicleSearchController;