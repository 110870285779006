
import MasterDataResourceView from "../../../components/intern/master-data/MasterDataResourceView";
import {loadGroups} from "../../../actions/rightActions";
import {loadWorkers} from "../../../actions/workerActions";
import {connect} from "react-redux";
import {
	resourceAction__fetch, resourceAction__fetchWorkers
} from "../../../actions/resourceActions";
import User from "../../../models/User";
import {isOnline} from "../../../actions/userActions";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {push} from "connected-react-router";
import {values} from "lodash";

const mapState = (state, props) => {
	const user = User._(state.user.data);
	return ({
		workers: state.workers.list,
		groups: state.rights.groups.list,
		relations: state.rights.groups.relation,
		resources: values(state.resources.list),
		resources4workers: state.resources.workers,
		resourceTypes: state.resources.types,
		...user.getAccessTo(SECTION.MASTER_DATA, [2,3])
		// user: new User(state.user.data)
	});
};

const mapDispatch = (dispatch, props) => ({
	init: (refresh = false) => {
		dispatch(isOnline(() => {
			dispatch(resourceAction__fetch(refresh));
			dispatch(loadGroups(refresh));
			dispatch(loadWorkers(refresh));
			dispatch(resourceAction__fetchWorkers(refresh));
			// dispatch(loadResources4Workers(refresh));
		}));
	},
	push: to => dispatch(push(to)),
	onSelectResource: (worker, resource) => {
		const workers_id = worker.workers_id || worker;
		const resource_id = resource ? (resource.resource_id || resource) : null;
		if (resource_id) {
			dispatch(push(`/master-data/resources/${workers_id}/${resource_id}`));
		} else {
			dispatch(push(`/master-data/resources/${workers_id}`));
		}
	}
});

export default connect(mapState, mapDispatch)(withInit(MasterDataResourceView));