import React from 'react'
import PropTypes from "prop-types";
import {deepMemoize as memoizeOne, trueNull} from "../../../Logic/extensions";
import {getEwoAvailableOptions, getExtractOptions, getOptions, toColumns} from "./WheelMethods";
import {Wheel} from "../../../models";
import {Button, Form, FormField, Grid, Icon, Popup, Transition} from "semantic-ui-react";
import {DropdownField, EditField} from "../../partials";
import {SizeMe} from "react-sizeme";
import {ClientSection, VehicleSection} from "./WheelEditor";
import {ClientSearchModal} from "../clients/search/ClientSearch";
import {Trans, translate} from "react-i18next";
import cn from 'classnames'
import {EasyFlex} from "../../partials/ActionHeader";
import {withHouse} from "../../../Tools/RightsProvider";


class FieldSelector extends React.Component {
	static propTypes = {
		onSelect: PropTypes.func,
		list: PropTypes.arrayOf(PropTypes.string),
		triggerStyle: PropTypes.object
	}
	static defaultProps = {
		list: []
	}
	
	state = {
		open: false
	}
	
	render() {
		return (
			<Popup
				open={this.state.open}
				basic
				style={{padding: 0}}
				flowing
				on={['click']}
				onOpen={() => this.setState({open: true})}
				onClose={() => this.setState({open: false})}
				trigger={<Button style={this.props.triggerStyle} type={'button'} basic icon={'edit'}/>}
				header={<div style={{padding: 5}}>Feld hinzufügen</div>}
				
				content={<Button.Group vertical>
					{this.props.list.map(btn =>
						<Button onClick={() => {
							console.warn('clicked the fucking btn', btn)
							this.props.onSelect && this.props.onSelect(btn)
							this.setState({open: false})
						}} key={btn} size={'large'} icon style={{textAlign: 'left'}}><Icon name={'edit'}/> <Trans i18nKey={`tire-search.${btn}`} defaults={btn}/></Button>
					)}
				</Button.Group>}
			/>
		);
	}
}

export class WheelSearchForm extends React.Component {
	static propTypes = {
		clientId: PropTypes.number,
		vehicleId: PropTypes.number,
		onChange: PropTypes.func.isRequired,
		formId: PropTypes.string,
		className: PropTypes.string,
		provideMethod: PropTypes.func
	}
	static defaultProps = {
		onChange: () => {
		}
	}
	static fieldList = ['type', 'registration_mark', 'chassis_number', 'extract_at']
	static ewoFields = ['ewo_order_id', 'ewo_tireset_number', 'ewo_available']
	static defaultFields =  ['title', 'id']
	
	getParams = memoizeOne((clientId, vehicleId) => {
		return {
			client_id: clientId || null,
			vehicle_id: vehicleId || null
		}
	})
	
	state = {
		...this.getParams(this.props.clientId, this.props.vehicleId),
		search_client: false,
		search_vehicle: false,
		title: '',
		type: 'none',
		id: '',
		registration_mark: '',
		chassis_number: '',
		extract_at: 'none',
		ewo_order_id: '',
		ewo_tireset_number: '',
		ewo_available: Wheel.EWO_AVAILABLE_IGNORE,
		field_list: this.props.houseData && this.props.houseData.ewo_tw ? [...WheelSearchForm.fieldList, ...WheelSearchForm.ewoFields] : WheelSearchForm.fieldList,
		fields: WheelSearchForm.defaultFields
	}
	
	componentDidMount() {
		// super.componentDidMount();
		this.updateParams(this.props.clientId, this.props.vehicleId);
		this.props.provideMethod && this.props.provideMethod(this.provideFields)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateParams(this.props.clientId, this.props.vehicleId);
	}
	
	reset = () => this.setState({
		...this.getParams(this.props.clientId, this.props.vehicleId),
		search_client: false,
		search_vehicle: false,
		title: '',
		type: 'none',
		id: '',
		registration_mark: '',
		chassis_number: '',
		extract_at: 'none',
		ewo_order_id: '',
		ewo_tireset_number: '',
		ewo_available: Wheel.EWO_AVAILABLE_IGNORE,
		field_list: this.props.houseData && this.props.houseData.ewo_tw ? [...WheelSearchForm.fieldList, ...WheelSearchForm.ewoFields] : WheelSearchForm.fieldList,
		fields: WheelSearchForm.defaultFields
	})
	
	getFields = (wheel, editable, t) => ({
		title: <FormField key={'title'}>
			<EditField
				name={'title'}
				label={'Bezeichnung'}
				onChange={this.setWheelField}
				value={wheel.title}
				editable={editable}
			/>
		</FormField>,
		id: <FormField key={'id'}>
			<EditField
				name={'id'}
				label={'Externe ID'}
				onChange={this.setWheelField}
				value={wheel.id}
				editable={editable}
			/>
		</FormField>,
		type: <FormField key={'type'}>
			<DropdownField
				editable={editable}
				label={'Reifentyp'}
				value={wheel.type}
				options={this.options}
				name={'type'}
				onChange={this.setWheelField}
			/>
		</FormField>,
		registration_mark: <FormField key={'registration_mark'}>
			<EditField
				name={'registration_mark'}
				label={'Kennzeichen'}
				onChange={this.setWheelField}
				value={wheel.registration_mark}
				editable={editable}
			/>
		</FormField>,
		chassis_number: <FormField key={'chassis_number'}>
			<EditField
				name={'chassis_number'}
				label={'Fahrgestellnummer'}
				onChange={this.setWheelField}
				value={wheel.chassis_number}
				editable={editable}
			/>
		</FormField>,
		extract_at: <FormField key={'extract_at'}>
			<DropdownField
				editable={editable}
				label={'Auslagerung'}
				value={wheel.extract_at}
				options={this.extractOptions}
				name={'extract_at'}
				onChange={this.setWheelField}
			/>
		</FormField>,
		ewo_order_id: <FormField key={'ewo_order_id'}>
			<EditField
				name={'ewo_order_id'}
				label={'EwoTW Auftragsnummer'}
				onChange={this.setWheelField}
				value={wheel.ewo_order_id}
				editable={editable}
			/>
		</FormField>,
		ewo_tireset_number: <FormField key={'ewo_tireset_number'}>
			<EditField
				name={'ewo_tireset_number'}
				label={'EwoTW: Reifensatznummer' +
				''}
				onChange={this.setWheelField}
				value={wheel.ewo_tireset_number}
				editable={editable}
			/>
		</FormField>,
		ewo_available: <FormField key={'ewo_available'}>
			<DropdownField
				editable={editable}
				label={'EwoTW: Verfügbarkeit'}
				value={wheel.ewo_available}
				options={this.availableOptions}
				name={'ewo_available'}
				onChange={this.setWheelField}
			/>
		</FormField>
	})
	
	
	
	get search_fields() {
		const {client_id, vehicle_id, title, type, id, registration_mark, chassis_number, extract_at, ewo_order_id, ewo_tireset_number, ewo_available} = this.state;
		return {
			client_id,
			vehicle_id,
			title: title.trim() || null,
			type: type === 'none' ? null : type,
			id: id.trim() || null,
			registration_mark: registration_mark.trim() || null,
			chassis_number: chassis_number.trim() || null,
			extracted: 'none' === extract_at ? null : 'upcoming' === extract_at,
			ewo_order_id,
			ewo_tireset_number,
			ewo_available: ewo_available || null
		}
	}
	
	_provideFields = fields => this.props.onChange(fields)
	provideFields = (e) => {
		e && ("stopPropagation" in e) && e.stopPropagation()
		this._provideFields(this.search_fields)
	}
	updateParams = memoizeOne((clientId, vehicleId) => this.setState({...this.getParams(clientId, vehicleId)}))
	
	setWheelField = (_, {name, value}) => this.setState({[name]: value})
	
	get options() {
		return getOptions(['none', ...Wheel.TIRES], this.props.t)
	}
	
	get availableOptions() { return getEwoAvailableOptions(Wheel.EWO_AVAILABLE_TYPES, this.props.t) }
	
	get extractOptions() { return getExtractOptions(['none', 'upcoming'], this.props.t) }
	
	searchFields = (list, fields) => {
		// const wheel = new Wheel(w)
		return list.map(id => fields[id])
	}
	
	getColumns = width => {
		if (width < 400) return 1
		if (width < 800) return 2
		if (width < 1200) return 3
		return 4
	}
	slotifyFields = toColumns
	
	render() {
		const {client_id, vehicle_id} = this.state
		const {clientId, vehicleId, formId, onChange, t, i18n, tReady, className, provideMethod, houseData, dispatch, ...props} = this.props
		
		return (
			<Form className={cn(className)} {...props} onSubmit={this.provideFields} id={formId} onReset={this.reset}>
				
				<SizeMe>{({size}) => {
					if (!size || !size.width) {
						return <div/>
					}
					const cols = this.getColumns(size.width)
					let fields = this.getFields(this.state, true, this.props.t)
					fields = this.searchFields(this.state.fields, fields)
					fields = this.slotifyFields(fields, cols)
					return <div>
						<EasyFlex valign={EasyFlex.valign.END} style={{marginBottom: 20}}>
							<Grid columns={2} style={{flexGrow: 1000}}>
								<Grid.Row>
									<Grid.Column>
										<Form.Field>
											<ClientSection editable clientId={client_id} unset={() => this.setState({client_id: null})} showSearch={() => {
												this.setState({search_client: true})
											}}/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<VehicleSection editable vehicleId={vehicle_id} unset={() => this.setState({vehicle_id: null})} showSearch={() => this.setState({search_vehicle: true})}/>
										</Form.Field>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							{trueNull(this.state.field_list.length) && <FieldSelector triggerStyle={{marginLeft: 8}} list={this.state.field_list} onSelect={item => this.setState(state => ({
								...state,
								field_list: state.field_list.filter(s => s !== item),
								fields: [...state.fields, item]
							}))}/>}
						</EasyFlex>
						<Grid columns={cols}>
							<Grid.Row>
								{fields.map((field, key) =>
									<Transition.Group duration={250} as={Grid.Column} key={key}>
										{field}
									</Transition.Group>
								)}
							</Grid.Row>
						</Grid>
					</div>
				}}</SizeMe>
				
				<ClientSearchModal
					open={this.state.search_client}
					onModalClose={() => this.setState({search_client: false})}
					// onSelectVehicle={trueNull(search_vehicle) && this.handleVehicleSelection}
					onSelectClient={client => this.setState({client_id: client.client_id, vehicle_id: null})}
					closeOnSelect
					directSelect
				/>
				<ClientSearchModal
					open={this.state.search_vehicle}
					onModalClose={() => this.setState({search_vehicle: false})}
					// onSelectVehicle={trueNull(search_vehicle) && this.handleVehicleSelection}
					onSelectVehicle={v => this.setState({client_id: v.client_id, vehicle_id: v.vehicle_id})}
					closeOnSelect
					directSelect
				/>
			</Form>
		);
	}
}

WheelSearchForm = withHouse(WheelSearchForm)
WheelSearchForm = translate()(WheelSearchForm)