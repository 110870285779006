import * as React from 'react';
import history from '../../../Logic/history';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from 'connected-react-router';
import {translate} from "react-i18next";
import ClientListController from "../../../cointainer/intern/clients/ClientListController";
import ClientCreateController from "../../../cointainer/intern/clients/ClientCreateController";
import ClientEditController from "../../../cointainer/intern/clients/ClientEditController";
import VehicleListController from "../../../cointainer/intern/vehicles/VehicleListController";
import VehicleCreateController from "../../../cointainer/intern/vehicles/VehicleCreateController";
import VehicleEditController from "../../../cointainer/intern/vehicles/VehicleEditController";
import {ClientWheelView} from "../wheel/ClientWheelView";

const ClientRoutes = () => (
	<ConnectedRouter history={history}>
		<Switch>
			<Route exact path={'/clients'} component={ClientListController}/>
			<Route exact path={'/clients/add'} component={ClientCreateController}/>
			<Route exact path={'/clients/:client_id'} component={ClientEditController}/>
			<Route exact path={'/clients/:client_id/wheels'} component={ClientWheelView}/>
			<Route exact path={'/clients/:client_id/vehicles/:vehicle_id/wheels'} component={ClientWheelView}/>
			<Route exact path={'/clients/:client_id/vehicles'} component={VehicleListController}/>
			<Route exact path={'/clients/:client_id/vehicles/add'} component={VehicleCreateController}/>
			<Route exact path={'/clients/:client_id/vehicles/:vehicle_id'} component={VehicleEditController}/>
		</Switch>
	</ConnectedRouter>
);

export default translate()(ClientRoutes);