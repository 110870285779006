import * as React from "react";
import Timeline, {CustomMarker, DateHeader, SidebarHeader, TimelineHeaders} from "react-calendar-timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import {moment} from "../../Logic/Moment";
// import {DayPicker} from "../partials/DayPicker";
import {GET} from "ith-fetch";
import {EasyFlex} from "../partials/ActionHeader";
import {Checkbox, List, ListItem} from "material-ui";
import {BasicDialog} from "../../Tools/Dialog/Dialogs";
import {get, isFunction} from "lodash";
import {Icon} from "semantic-ui-react";
import {deepMemoize as memoize} from "../../Logic/extensions";
import {blackOrWhite} from "../../Logic/extensions";

export class TimelineTest extends React.Component {
	state = {
		groups: [{ id: 1, title: 'group 1', stackItems: true }, { id: 2, title: 'group 2' }, { id: 3, title: 'group 3' }],
		items: [],
		schedule: {},
		workers: {},
		resources: {},
		min: moment().startOf('day'),
		max: moment().endOf('day'),
		data: {},
		wlist: {},
		wr: [],
		now: Date.now(),
		menuIsOpen: false,
		isGrouped: false,
		isLoading: false
	};
	
	showMenu = menuIsOpen => () => this.setState({menuIsOpen});
	toggleGrouped = () => this.setState(state => ({...state, isGrouped: !state.isGrouped}));
	
	dateChangeWatcher = (start, end) => trigger => {
		const next = moment(Math.round((start + end) / 2)).format("YYYY-MM-DD");
		if (isFunction(trigger) && this._dateWatcher !== next) {
			this._dateWatcher = next;
			trigger(next, start, end);
		}
	};
	
	handleSchedule = schedule => {
		const {workers} = this.state;
		const _groups = [];
		Object.keys(schedule).forEach(id => {
			let title = `Worker-${id}`;
			if (workers[id]) {
				title = workers[id].name;
				if (workers[id].first_name) {
					title = `${title} ${workers[id].first_name.substr(0, 1).toUpperCase()}.`;
				}
				title = `${title} [${id}]`;
			}
			_groups.push(
				{id: `schedule-${id}`, title, type: 'name'},
				{id: `work-${id}`, title: <div style={{textAlign: 'right'}}>Arbeit</div>, stackItems: true, type: 'work'},
				{id: `spacer-${id}`, title: '', height: 20, type: 'spacer'}
			);
		});
		const _items = [];
		let c = 1;
		Object.keys(schedule).forEach(id => {
			schedule[id].forEach(entry => {
				_items.push({
					id: c++,
					group: `schedule-${id}`,
					title: 'Arbeitszeit',
					start_time: entry.start,
					end_time: entry.end,
					canMove: false,
					canChangeGroup: false,
					canResize: false,
					itemProps: {
						style: {
							background: '#222'
						}
					}
				});
			});
		});
	
		this.setState({groups: [..._groups], items: [..._items]});
	};
	
	handleBigSchedule = ({list, min_max, orderlist, orders, paused, schedule, wlist, workers: w, workloads}) => {
		const {resources} = this.state;
		const items = [];
		const color = ['#2196f3', '#14578c'];
		const colors = {};
		// list.sort((a, b) => new Date(a.start_point).getTime() - new Date(b.start_point).getTime());
		// list = list.sort((a, b) =>  Boolean(a.worked_by) < Boolean(b.worked_by) ? 1 : -1);
		list.forEach(order => {
			let clr = get(resources, [order.resource_id, 'color'], null);
			let i = colors[order.workers_id] || 0;
			let title = order.last_name;
			if(order.first_name) {
				title = `${title} ${order.first_name.substr(0,1).toUpperCase()}.`;
			}
			if (order.registration_mark) {
				title = `${title} <${order.registration_mark}>`;
			}
			let background;
			if (clr) {
				background = clr;
			} else {
				background = color[i % 2];
			}
			items.push({
				id: order.order_service_resource_id,
				group: `work-${order.workers_id}`,
				title,
				_order: order,
				start_time: new Date(order.start_point),
				end_time: new Date(order.end_point),
				canMove: false,
				canChangeGroup: false,
				canResize: false,
				itemProps: {
					style: {
						overflow: 'hidden',
						background,
						color: this.fontColor(background)
					}
				}
			});
			if (!clr) {
				colors[order.workers_id] = i+1;
			}
		});
		
		this.setState(state => ({...state, items: [...state.items, ...items], isLoading: false}));
	};
	
	fontColor = color => blackOrWhite(color);
	
	handleWorkers = workers => {
		this.setState({workers, isLoading: true});
		GET('/control/schedule').then(this.handleSchedule);
		GET('/order/day/today').then(this.handleBigSchedule);
	};
	
	handleDay = day => {
		this.setState({isLoading: true});
		GET('/control/schedule/' + day).then(this.handleSchedule);
		GET('/order/day/' + day).then(this.handleBigSchedule);
	};
	
	mapColors = memoize(
		(list, resources) => {
			list.forEach(entry => {
				const wid = entry._order.workers_id;
				const color = get(resources, [wid, 'color'], entry.itemProps.style.background);
				return {
					...entry,
					itemProps: {
						...entry.itemProps,
						style: {
							...entry.itemProps.style,
							background: color
						}
					}
				}
			});
			return [...list];
		}
	);
	
	componentDidMount() {
		this._dateWatcher = moment().format("YYYY-MM-DD");
		this._nowTimer = window.setInterval(() => this.setState({now: Date.now()}), 1000);
		GET('/workers/all').then(this.handleWorkers);
		GET('/resource').then(res => this.setState({resources: res, items: this.mapColors(this.state.items, res)}));
	}
	
	componentWillUnmount() {
		window.clearInterval(this._nowTimer);
	}
	
	render() {
		const {
			groups,
			items,
			menuIsOpen,
			isGrouped,
			isLoading
		} = this.state;
		return (
			<div>
				
				<div>
					<Timeline
						groups={groups}
						items={items}
						defaultTimeStart={moment().startOf('day')}
						defaultTimeEnd={moment().endOf('day')}
						horizontalLineClassNamesForGroup={group => [group.type]}
						groupRenderer={({group}) => <div className={group.type}>{group.title}</div>}
						timeSteps={{
							second: 1,
							minute: 6,
							hour: 1,
							day: 1,
							month: 1,
							year: 1
						}}
						maxZoom={7 * 86400 * 1000}
						onTimeChange={(s,e,d) => {
							this.dateChangeWatcher(s,e)(date => {
								this.handleDay(date);
							});
							d(s,e);
						}}
					>
						<TimelineHeaders>
							<SidebarHeader>
								{({ getRootProps }) => {
									return <div {...getRootProps()}>
										<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{height: "100%"}}>
											{/*<IconButton onClick={this.showMenu(true)}><IconMore color={COLORS.PRIMARY}/></IconButton>*/}
											{isLoading && <Icon name={"spinner"} loading={isLoading} color={"blue"}/>}
										</EasyFlex>
									</div>
								}}
							</SidebarHeader>
							<DateHeader unit="primaryHeader" /><DateHeader />
						</TimelineHeaders>
						<CustomMarker date={this.state.now}>
							{({ styles, date }) => <div style={{...styles, backgroundColor: 'red'}} />}
						</CustomMarker>
					</Timeline>
				</div>
				<BasicDialog open={menuIsOpen} onClose={this.showMenu(false)} closeButton closeRight>
					{menuIsOpen && <div>
						<List>
							<ListItem
								primaryText={"Gruppiert"}
								secondaryText={isGrouped ? "Termine werden gruppiert (Auftragsliste)" : "Interne Darstellung der Termine"}
								rightToggle={<Checkbox onCheck={this.toggleGrouped} checked={isGrouped} />}
							/>
						</List>
					</div>}
				</BasicDialog>
			</div>
		);
	}
}