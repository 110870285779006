import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';

let LanguageView = ({i18n, language}) => {
	const changeLanguage = (lng, e) => {
		i18n.changeLanguage(lng);
	};
	
	return (
		<div className="language-choose">
			<button type="button" disabled={'de' === language} onClick={e => changeLanguage('de', e)}>Deutsch</button>
			<button type="button" disabled={'en' === language} onClick={e => changeLanguage('en', e)}>English</button>
		</div>
	);
};

LanguageView.propTypes = {
	language: PropTypes.string.isRequired
};

export default translate()(LanguageView);