import * as React from "react";
import PropTypes from "prop-types";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading, ActionResponsiveItem, OptionHeader} from "../../../partials/ActionHeader";
import {MenuItem, SelectField} from "material-ui";
import {IconApps, IconMenu} from "../../../../Logic/icons";
import {SearchFilter, Sortable} from "ah-tools";
import {processData} from "ah-lib";
import {ConsultantCalendarLink, MiniMenu, ResourceLink, WorkerCreateLink, WorkersCalendarLink} from "../../../../Logic/MiniMenu";
import {deepMemoize as memoize} from "../../../../Logic/extensions";
import {Worker} from "../../../../models";
import {partition} from "lodash";
import {translate} from "react-i18next";
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";

export let WorkerListHeader = ({t, onBack, workers, viewType, updateViewType, selectedState, handleSelect, selectedGroup, sortOptions, sortOrder, searchText, searchType, groups, searchOptions, setSearch, allRights}) => {
	const _count = memoize(
		list => list.filter(w => !w.is_support)
	);
	const _split = memoize(
		list => partition(list, w => w.active)
	);
	const withoutSupport = _count(workers);
	const [activeWorkers, inactiveWorkers] = _split(workers);
	return (
		<ActionHeader alignment={'space-between'} valign={'center'} style={{marginBottom: 30}} wrap={'wrap-reverse'}>
			<ActionHeaderGroup valign={'center'}>
				<ActionBackButton onBack={onBack}/>
				<ActionHeading>{t('worker.list.coworkers-count', {count: withoutSupport.length})}</ActionHeading>
			</ActionHeaderGroup>
			<ActionHeaderGroup valign={'center'} alignment={'flex-end'} wrap={'wrap-reverse'}>
				<OptionHeader underWidth={1430} tooltip={t('filter.label-show')} buttonText={t('actions.close')}>
					<SelectField
						iconStyle={{display: "none"}}
						floatingLabelText={"Ansicht"}
						value={viewType}
						underlineStyle={{display: "none"}}
						style={{width: "auto", marginLeft: 5, marginRight: 5}}
						autoWidth
						onChange={(a, b, value) => updateViewType(value)}
					>
						<MenuItem primaryText={"Kacheln"} leftIcon={<IconApps/>} value={"grid"}/>
						<MenuItem primaryText={"Liste"} leftIcon={<IconMenu/>} value={"list"}/>
					</SelectField>
					<SelectField
						iconStyle={{display: 'none'}}
						floatingLabelText={t('worker.list.filter-state-title')}
						value={selectedState}
						underlineStyle={{display: 'none'}}
						style={{width: 'auto', marginLeft: 5, marginRight: 5}}
						autoWidth
						onChange={handleSelect('state')}
					>
						<MenuItem primaryText={t('worker.list.filter-state-active', {count: activeWorkers.length})} value={'active'}/>
						<MenuItem primaryText={t('worker.list.filter-state-inactive', {count: inactiveWorkers.length})} value={'inactive'}/>
					</SelectField>
					<SelectField
						iconStyle={{display: 'none'}}
						floatingLabelText={t('worker.list.filter-groups-title')}
						value={selectedGroup}
						underlineStyle={{display: 'none'}}
						style={{width: 'auto', marginLeft: 5, marginRight: 5}}
						autoWidth
						onChange={handleSelect('groups')}
					>
						<MenuItem value={'all'} primaryText={t('worker.list.filter-groups-all')}/>
						{groups.map(group =>
							<MenuItem key={group.id} value={group.id} primaryText={group.text}/>
						)}
					</SelectField>
					<Sortable
						floatingLabelText={t('worker.list.order-title')}
						options={sortOptions.map(option => processData(option)(opt => opt.title = t(`worker.list.order-${opt.key}`)))}
						value={sortOrder}
						onChange={handleSelect('order')}
					/>
					<ActionResponsiveItem style={{display: 'inline-block'}}>
						<SearchFilter
							options={searchOptions}
							value={searchText}
							selected={searchType}
							onChange={setSearch('searchText')}
							onSelect={setSearch('searchType')}
						/>
					</ActionResponsiveItem>
				</OptionHeader>
				<ActionHeaderGroup wrap={'nowrap'}>
					<MiniMenu>
						<ResourceLink/>
						<WorkerCreateLink/>
						<WorkersCalendarLink/>
						{allRights[SECTION.MASTER_DATA].mayRead && <ConsultantCalendarLink/>}
						{/*<MenuItem primaryText={"Ressourcen zuweisen"} leftIcon={<AssignIcon/>} onClick={push.bind(null, '/master-data/resources/assign')}/>*/}
						{/*<MenuItem primaryText={'neuen MitarbeiterIn anlegen'} leftIcon={<AddPersonIcon/>} onClick={push.bind(null, '/workers/create')}/>*/}
					</MiniMenu>
				</ActionHeaderGroup>
			</ActionHeaderGroup>
		</ActionHeader>
	)
};

WorkerListHeader = translate()(WorkerListHeader);
WorkerListHeader = withRights(SECTION.WORKERS, WorkerListHeader);

WorkerListHeader.propTypes = {
	workers: PropTypes.arrayOf(PropTypes.instanceOf(Worker)).isRequired,
};