
import MasterDataClosed from "../../../components/intern/master-data/MasterDataClosed";
import {
	freedaysAction__create,
	freedaysAction__fetch,
	freedaysAction__remove
} from "../../../actions/freedaysActions";
import {isOnline} from "../../../actions/userActions";
import {connect} from "react-redux";
import withInit from "../../../Logic/withInit";
import {push} from "connected-react-router"
import {addSnackbar} from "../../../actions/snackbarActions";
import {isFunction} from "lodash";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_FREEDAYS} from "../../../actions";

const m2s = (state) => ({
	freedays: state.freedays.list,
	loaded: state.freedays.loaded.list,
	loading: isLoading(state, PROC_FREEDAYS)
});

const m2d = (dispatch) => ({
	init: () => {
		dispatch(isOnline(() => {
			dispatch(freedaysAction__fetch());
		}));
	},
	onRemove: (ids, onSuccess) => dispatch(freedaysAction__remove(ids, result => {
		isFunction(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('Freie Tage entfernt!'));
	})),
	onCreate: (data, onSuccess) => dispatch(freedaysAction__create(data, onSuccess, true)),
	// onCreate: (data, onSuccess) => dispatch(freedaysAction__create(data, result => {
	// 	isFunction(onSuccess) && onSuccess(result);
	// 	dispatch(addSnackbar('Freie Tage angelegt'));
	// })),
	push: to => dispatch(push(to))
});

export default connect(m2s, m2d)(withInit(MasterDataClosed));