import * as React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {values} from "lodash";
import {MenuItem} from "material-ui";
import {resourceAction__assignWorker} from "../../../actions/resourceActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import isFunc from "lodash/isFunction";
import PointIcon from "material-ui/svg-icons/av/fiber-manual-record";
import {Flex} from "../../../components/partials/ActionHeader";
import cn from "classnames";
import {IconMenu} from "../../../Logic/MiniMenu";

const _ResourceAssigner = ({workers_id: id, resources, onSelect, className, itemProps, ...props}) => {
	return (
		<IconMenu
			className={cn("Worker-Resource-Assigner", className)}
			{...props}
		>
			{
				resources.length ? resources.map(resource =>
					<MenuItem key={`resource-selector-${resource.resource_id}`} onClick={onSelect.bind(null, resource)} {...itemProps} primaryText={<Flex valign={'center'}><PointIcon color={resource.color}/> {resource.name}</Flex>}/>
				) : <MenuItem disabled primaryText={"keine Auswahl"}/>
			}
		</IconMenu>
	);
};

const ResourceAssigner = connect(
	(state, props) => {
		return {
			resources: props.resources || values(state.resources.list || {})
		};
	},
	(dispatch, props) => {
		return {
			onSelect: (resource, onSuccess) => dispatch(resourceAction__assignWorker(props.workers_id, resource, result => {
				dispatch(addSnackbar('snackbar.md-resource.assigned'));
				isFunc(onSuccess) && onSuccess(result);
			}))
		};
	}
)(_ResourceAssigner);

ResourceAssigner.propTypes = {
	resources: PropTypes.array,
	workers_id: PropTypes.number.isRequired,
	itemProps: PropTypes.object,
	className: PropTypes.string
};

export {ResourceAssigner};