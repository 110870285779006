import * as React from "react";
import {Flex, FlexChild} from "../../../components/partials/ActionHeader";
import Client from "../../../models/Client";
import {FlatButton, IconButton} from "material-ui";
import {IconClose} from "../../../Logic/icons";
import * as PropTypes from "prop-types";
import ClientSearchController from "../clients/ClientSearchController";
import {translate} from "react-i18next";
import {falseNull} from "../../../Logic/extensions";
import {Popup as ClientPopup} from "../../../components/intern/clients";
import {Modal} from "semantic-ui-react";

export class OrderClientSelector extends React.Component {
	static propTypes = {
		client: PropTypes.object,
		minLabelWidth: PropTypes.number,
		onSelectClient: PropTypes.func.isRequired,
		onUnselect: PropTypes.func
	};
	static defaultProps = {
		minLabelWidth: 120,
		onSelectClient: () => {}
	};
	state = {
		open: false
	};
	
	toggle = open => () => this.setState({open});
	onSelectClient = client => {
		this.props.onSelectClient(client);
		this.toggle(false)();
	};
	
	render() {
		let {client, t, onSelectClient, minLabelWidth, onUnselect, i18n, tReady, ...props} = this.props;
		const {open} = this.state;
		return (
			<React.Fragment>
				<Flex style={{borderBottom: '1px dashed #eee'}} valign={"center"} gutterWidth={24} {...props}>
					<FlexChild style={{minWidth: minLabelWidth}}><strong>{t("client.singular")}:</strong></FlexChild>
					<FlexChild onClick={this.toggle(true)} className={"cursor pointer"} shrink={0.1} grow={100}>
						<ClientPopup client={client}><span>{client ?  Client._(client).getName(t) : t("actions.please-select")}</span></ClientPopup>
					</FlexChild>
					<FlexChild>
						<Flex valign={"center"}>
							<FlatButton secondary onClick={this.toggle(true)}>{t("actions.search")}</FlatButton>
							{client && <IconButton onClick={onUnselect} style={falseNull(onUnselect) && {pointerEvents: "none", opacity: 0}}><IconClose/></IconButton>}
						</Flex>
					</FlexChild>
				</Flex>
				{/*{open &&*/}
				{/*<Dialog className={"order-create-dialog"} open onRequestClose={this.toggle(false)}>*/}
				{/*	<ClientSearchController*/}
				{/*		selectOnClick*/}
				{/*		onAbort={this.toggle(false)}*/}
				{/*		onSelect={this.onSelectClient}*/}
				{/*		/>*/}
				{/*</Dialog>*/}
				{/*}*/}
				{open && <Modal open centered={false} onClose={this.toggle(false)}>
					<Modal.Content>
						<ClientSearchController
							selectOnClick
							onAbort={this.toggle(false)}
							onSelect={this.onSelectClient}
						/>
					</Modal.Content>
				</Modal>}
			</React.Fragment>
		);
	}
}
OrderClientSelector = translate()(OrderClientSelector);
