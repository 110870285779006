import {connect} from "react-redux";
import {push} from 'connected-react-router';
import User from "../../../../models/User";
import {SECTION} from "../../../../Logic/constants";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_LOAN_CARS_FETCH, PROC_LOAN_CARS_SEND} from "../../../../actions";
import {loanCarAction__fetch} from "../../../../actions/loanCarActions";
import LoanCarEditView from "../../../../components/intern/master-data/loan-cars/LoanCarEditView";
import {isOnline} from "../../../../actions/userActions";
import withInit from "../../../../Logic/withInit";

const m2s = (state, props) => {
	const user =  User._(state.user.data);
	const id = Number(props.match.params.loan_car_id);
	const loan_car = state.loan_cars.list[id];
	return {
		...user.getAccessTo(SECTION.MASTER_DATA),
		isLoading: isLoading(state, PROC_LOAN_CARS_SEND, id) || isLoading(state, PROC_LOAN_CARS_FETCH),
		loan_car
	};
};

const m2d = (dispatch, props) => {
	// const id = Number(props.match.params.loan_car_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(loanCarAction__fetch());
		})),
		onBack: () => dispatch(push('/master-data/loan-cars')),
		onSave: (load_car, onSuccess) => {},
		onDelete: (onSuccess) => {}
	};
};

export default connect(m2s, m2d)(withInit(LoanCarEditView));