import {SESSION_STORAGE_ACTIONS} from "./types";
import {isFunction} from "lodash";

export const sessionStorageAction__addAppointmentNotificationId = value => {
	return {type: SESSION_STORAGE_ACTIONS.NOTIFICATION_APPOINTMENT_IDS, value};
};
export const sessionStorageAction__unsetAppointmentNotificationId = value => ({type: SESSION_STORAGE_ACTIONS.NOTIFICATION_APPOINTMENT_UNSET_IDS, value});

export const sessionStorageAction__clearAppointmentNotifications = () => ({type: SESSION_STORAGE_ACTIONS.CLEAR_NOTIFICATION_APPOINTMENT_IDS});
export const sessionStorageAction__setOrderChangeId = (value, onSuccess) =>
	(dispatch, getState) => {
		value = Number(value);
		if (!isNaN(value) && value !== getState().session.dev_order_change_id) {
			dispatch({type: SESSION_STORAGE_ACTIONS.DEV_ORDER_CHANGE_ID, value});
			isFunction(onSuccess) && onSuccess(value);
		}
	};
export const sessionStorageAction__toggleAppointmentShowUndelivered = (value) => ({type: SESSION_STORAGE_ACTIONS.TOGGLE_APPOINTMENTS_SHOW_UNDELIVERED, value});
export const sessionStorageAction__changeOrderFilterState = (filter, value) => ({type: SESSION_STORAGE_ACTIONS.CHANGE_ORDER_FILTER_STATE, filter, value});
export const sessionStorageAction__setOrderFilterState = (value) => ({type: SESSION_STORAGE_ACTIONS.SET_ORDER_FILTER_STATE, value});
export const sessionStorageAction__resetOrderFilterState = {type: SESSION_STORAGE_ACTIONS.RESET_ORDER_FILTER_STATE};

export const sessionStorageAction__setPaused = value => ({type: SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_SET, value});
export const sessionStorageAction__removePaused = value => ({type: SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_REMOVE, value});
export const sessionStorageAction__clearPaused = value => ({type: SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_CLEAR});

export const sessionStorageAction__setOrderConsultantSelection = value => ({type: SESSION_STORAGE_ACTIONS.ORDER_CONSULTANT_SELECT, value});

export const sessionStorageAction__setCountries = value => ({type: SESSION_STORAGE_ACTIONS.COUNTRIES, value});