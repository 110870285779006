import * as React from "react";
import PropTypes from "prop-types";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import OrderServiceDetailView from "../../../components/intern/orders/OrderServiceDetailView";
import {serviceAction__calculate} from "../../../actions/serviceActions";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {kfzAction__fetchServices} from "../../../actions/kfzActions";
import {isObject, values} from "lodash";
import {publish, subscribe} from "../../../Logic/PubSub";
import {SUB_CALCULATE_SERVICE_PRICES} from "../../../actions";
import round from "round-precision";
import hash from 'object-hash';

export class OrderServiceSelectionList extends React.PureComponent {
	static propTypes = {
		vehicle: PropTypes.object.isRequired,
		services: PropTypes.oneOfType([
			PropTypes.array, PropTypes.object
		]).isRequired,
		serviceMap: PropTypes.object,
		updateOnChange: PropTypes.bool,
		onNotifyWorktime: PropTypes.func
	};
	state = {
		list: [],
		error: null,
		loading: false
	};
	
	getServiceListArray = memoize(
		(data) => isObject(data) ? values(data) : data
	);
	
	getHash = data => {
		const source = isObject(data) ? Object.values(data) : data;
		let list = [];
		for (const s of source) {
			list.push(s.service_id);
		}
		return hash(list);
	}
	
	onRequestPrices = (services) => async () => {
		if ( !services.length ) return true;
		const serviceIDs = services.map(s => s.service_id);
		try {
			this.setState({loading: true, error: null});
			const {priceList: list} = await serviceAction__calculate(this.props.vehicle, serviceIDs);
			if (this.props.onNotifyWorktime) {
				const totalWorkTime = list.reduce((carry, service) => {
					return carry + (service.extra.resource_hours_of_work || service.extra.hours_of_work);
				}, 0);
				this.props.onNotifyWorktime(round(totalWorkTime, 1));
			}
			this.setState({loading: false, list});
		} catch (e) {
			this.setState({loading: false, error: e.message});
		}
		return true;
	};
	
	componentDidMount() {
		const services = this.getServiceListArray(this.props.services);
		this.onRequestPrices(services)();
		
		this.unsubscribeCalculation = subscribe(SUB_CALCULATE_SERVICE_PRICES, () => {
			const services = this.getServiceListArray(this.props.services);
			this.onRequestPrices(services)();
		});
	}
	
	componentWillUnmount() {
		this.unsubscribeCalculation && this.unsubscribeCalculation();
	}
	
	componentDidUpdate({services}, prevState, snapshot) {
		if (this.props.updateOnChange) {
			const prev = this.getHash(services);
			const now = this.getHash(this.props.services);
			if (prev !== now) {
				this.onRequestPrices(this.getServiceListArray(this.props.services))();
			}
		}
	}
	
	render() {
		const {services: serviceData, serviceMap, style, vehicles, updateOnChange, onNotifyWorktime, init, asGrid, ...props} = this.props;
		const services = this.getServiceListArray(serviceData);
		if (!services || !services.length) return null;
		return (
			<OrderServiceDetailView
				onRequestPrices={this.onRequestPrices(services)}
				serviceMap={serviceMap}
				{...this.state}
				style={{marginTop: 15, paddingBottom: 10, borderBottom: '1px dashed #eee', ...style}}
				{...props}
			/>
		);
	}
}
OrderServiceSelectionList = withInit(OrderServiceSelectionList);
OrderServiceSelectionList = connect(
	(state, props) => ({
		serviceMap: props.serviceMap || state.kfz.service.list
	}),
	(dispatch, props) => ({
		init: () => {
			dispatch(kfzAction__fetchServices());
		}
	})
)(OrderServiceSelectionList);

export const requestServicePriceCalculation = () => publish(SUB_CALCULATE_SERVICE_PRICES);