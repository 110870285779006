export {default as IconMore} from "material-ui/svg-icons/navigation/more-vert";
export {default as IconDelete} from "material-ui/svg-icons/action/delete";
export {default as IconPersonAdd} from "material-ui/svg-icons/social/person-add";
export {default as IconPerson} from "material-ui/svg-icons/social/person";
export {default as IconGroup} from "material-ui/svg-icons/social/group";
export {default as IconGroupAdd} from "material-ui/svg-icons/social/group-add";
export {default as IconAdd, default as IconPlus} from "material-ui/svg-icons/content/add";
export {default as IconAddBox} from "material-ui/svg-icons/content/add-box";
export {default as IconAddCircle} from "material-ui/svg-icons/content/add-circle";
export {default as IconAddCircleOutline} from "material-ui/svg-icons/content/add-circle-outline";
export {default as IconPlaylistAdd} from "material-ui/svg-icons/av/playlist-add";
export {default as IconDone} from "material-ui/svg-icons/action/done";
export {default as IconAssignment} from "material-ui/svg-icons/action/assignment";
export {default as IconAssignmentPerson} from "material-ui/svg-icons/action/assignment-ind";
export {default as IconRefresh} from "material-ui/svg-icons/action/autorenew";
export {default as IconCached} from "material-ui/svg-icons/action/cached";
export {default as IconAccountBox} from "material-ui/svg-icons/action/account-box";
export {default as IconAccountCircle} from "material-ui/svg-icons/action/account-circle";
export {default as IconHelp} from "material-ui/svg-icons/action/help";
export {default as IconHelpOutline} from "material-ui/svg-icons/action/help-outline";
export {default as IconSearch} from "material-ui/svg-icons/action/search";
export {default as IconRestore} from "material-ui/svg-icons/action/restore";
export {default as IconSettings} from "material-ui/svg-icons/action/settings";
export {default as IconSupervisorAccount} from "material-ui/svg-icons/action/supervisor-account";
export {default as IconUpdate} from "material-ui/svg-icons/action/update";
export {default as IconVisibility} from "material-ui/svg-icons/action/visibility";
export {default as IconVisibilityOff} from "material-ui/svg-icons/action/visibility-off";
export {default as IconAlertAdd} from "material-ui/svg-icons/alert/add-alert";
export {default as IconError} from "material-ui/svg-icons/alert/error";
export {default as IconErrorOutline} from "material-ui/svg-icons/alert/error-outline";
export {default as IconWarning} from "material-ui/svg-icons/alert/warning";
export {default as IconFeaturedPlaylist, default as IconHouseClosed} from "material-ui/svg-icons/av/featured-play-list";
export {default as IconContactCalendar} from "material-ui/svg-icons/action/perm-contact-calendar";
export {default as IconDateRange} from "material-ui/svg-icons/action/date-range";
export {default as IconBeachAccess} from "material-ui/svg-icons/places/beach-access";
export {default as IconMoodBad} from "material-ui/svg-icons/social/mood-bad";
export {default as IconAccessible} from "material-ui/svg-icons/action/accessible";
export {default as IconHome} from "material-ui/svg-icons/action/home";
export {default as IconNoteAdd} from "material-ui/svg-icons/action/note-add";
export {default as IconBusiness} from "material-ui/svg-icons/communication/business";
export {default as IconClose} from "material-ui/svg-icons/navigation/close";
export {default as IconCreate, default as IconEdit} from "material-ui/svg-icons/content/create";
export {default as IconRemove, default as IconMinus} from "material-ui/svg-icons/content/remove";
export {default as IconCar} from "material-ui/svg-icons/maps/directions-car";
export {default as IconCalendar} from "material-ui/svg-icons/notification/event-note";
export {default as IconBack} from "material-ui/svg-icons/navigation/arrow-back";
export {default as IconLane} from "material-ui/svg-icons/action/assessment";
export {default as IconChevronLeft} from "material-ui/svg-icons/navigation/chevron-left";
export {default as IconChevronRight} from "material-ui/svg-icons/navigation/chevron-right";
export {default as IconInfo} from "material-ui/svg-icons/action/info";
export {default as IconFilterList} from "material-ui/svg-icons/content/filter-list";
export {default as IconDelivered} from "material-ui/svg-icons/editor/vertical-align-bottom";
export {default as IconFetched} from "material-ui/svg-icons/editor/vertical-align-top";
export {default as IconThumbUp} from "material-ui/svg-icons/action/thumb-up";
export {default as IconSettingsBackRestore} from "material-ui/svg-icons/action/settings-backup-restore";
export {default as IconList} from "material-ui/svg-icons/action/list";
export {default as IconEvent} from "material-ui/svg-icons/action/event";
export {default as IconShortText} from "material-ui/svg-icons/editor/short-text";
export {default as IconCheckboxPositive} from "material-ui/svg-icons/toggle/check-box";
export {default as IconCheckboxNegative} from "material-ui/svg-icons/toggle/check-box-outline-blank";
export {default as IconCheckboxIndeterminate} from "material-ui/svg-icons/toggle/indeterminate-check-box";
export {default as IconApps} from "material-ui/svg-icons/navigation/apps";
export {default as IconMenu} from "material-ui/svg-icons/navigation/menu";
export {default as IconDashBoard, default as IconStack} from "material-ui/svg-icons/action/dashboard";
export {default as IconPause} from "material-ui/svg-icons/av/pause";
export {default as IconPauseCircle} from "material-ui/svg-icons/av/pause-circle-filled";
export {default as IconPauseCircleOutline} from "material-ui/svg-icons/av/pause-circle-outline";
export {default as IconTimelapse} from "material-ui/svg-icons/image/timelapse";
export {default as IconAccessTime} from "material-ui/svg-icons/device/access-time";
export {default as IconEuro} from "material-ui/svg-icons/action/euro-symbol";
export {default as IconBuild} from "material-ui/svg-icons/action/build";
export {default as IconReorder} from "material-ui/svg-icons/action/reorder";
export {default as IconSave} from "material-ui/svg-icons/content/save";
export {default as IconPriorityHigh} from "material-ui/svg-icons/notification/priority-high";
export {default as IconNotifications} from "material-ui/svg-icons/social/notifications";
export {default as IconNotificationsActive} from "material-ui/svg-icons/social/notifications-active";
export {default as IconNotificationsOff} from "material-ui/svg-icons/social/notifications-off";
export {default as IconNotificationsNone} from "material-ui/svg-icons/social/notifications-none";
export {default as IconTimeline} from "material-ui/svg-icons/editor/format-align-left";
export {default as IconLock} from "material-ui/svg-icons/action/lock";
export {default as IconLockOpen} from "material-ui/svg-icons/action/lock-open";
export {default as IconCall, default as IconPhone} from "material-ui/svg-icons/communication/call";
export {default as IconArchive} from "material-ui/svg-icons/content/archive";
export {default as IconUnarchive} from "material-ui/svg-icons/content/unarchive";
export {default as IconStorage} from "material-ui/svg-icons/device/storage";
export {default as IconHeadsetMic} from "material-ui/svg-icons/hardware/headset-mic";
export {default as IconCode} from "material-ui/svg-icons/action/code";
export {default as IconKeyboardTab} from "material-ui/svg-icons/hardware/keyboard-tab";
export {default as IconFitToScreen} from "material-ui/svg-icons/image/crop-free";
export {default as IconScale} from "material-ui/svg-icons/image/photo-size-select-small";
export {default as IconShuffle} from "material-ui/svg-icons/av/shuffle";
export {default as IconCompare} from "material-ui/svg-icons/action/compare-arrows";
export {default as IconSwapHoriz} from "material-ui/svg-icons/action/swap-horiz";
export {default as IconRepeat} from "material-ui/svg-icons/av/repeat";
export {default as IconDoneAll} from 'material-ui/svg-icons/action/done-all';
export {default as IconAssignmentTurnedIn} from 'material-ui/svg-icons/action/assignment-turned-in';
export {default as IconAssignmentLate} from 'material-ui/svg-icons/action/assignment-late';
export {default as IconBeenHere} from 'material-ui/svg-icons/maps/beenhere';
export {default as IconVerifiedUser} from 'material-ui/svg-icons/action/verified-user';
export {default as IconContacts} from 'material-ui/svg-icons/communication/contacts';
export {default as IconExit} from 'material-ui/svg-icons/action/exit-to-app'
export {default as IconAdjust} from 'material-ui/svg-icons/image/adjust'