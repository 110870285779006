import WorkersDetailView from "../../../components/intern/workers/WorkersDetailView";
import User from "../../../models/User";
import {loadAreas, loadGroups} from "../../../actions/rightActions";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {SECTION} from "../../../Logic/constants";
import {loadWorkers} from "../../../actions/workerActions";
import withInit from "../../../Logic/withInit";

const mapState = (state, props) => {
	const userData = state.user.data;
	const user = User._(userData);
	return ({
		worker: User.getDummy(),
		user,
		right_areas: state.rights.areas.list,
		right_groups: state.rights.groups.list,
		right_relations: state.rights.groups.relation,
		createMode: true,
		workers: state.workers.list,
		carhouse: state.carhouse.own, //.list.find(h => h.house_id === userData.house_id),
		house: state.carhouse.own, //list.find(h => h.house_id === userData.house_id),
		mayCreateSelf: user.hasAccessTo(SECTION.WORKERS, User.RIGHT.CREATE),
		...user.getAccessTo(SECTION.WORKERS, [1,2,3,4])
	});
};

const mapDispatch = (dispatch, props) => {
	return ({
		init: () => {
			dispatch(loadAreas());
			dispatch(loadGroups());
			dispatch(loadWorkers())
		},
		onBack: () => dispatch(push('/workers')),
	});
};

export default connect(mapState, mapDispatch)(withInit(WorkersDetailView));