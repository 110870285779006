import * as React from "react";
import PropTypes from "prop-types";
import {Accordion, Button, Icon, Table} from "semantic-ui-react";
import moment from "../../../Logic/Moment";
import User from "../../../models/User";
import withInit from "../../../Logic/withInit";
import {withRights} from "../../../Tools";
import {COLORS, SECTION} from "../../../Logic/constants";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderQuickView from "../orders/OrderQuickView";
import {isOnline} from "../../../actions/userActions";
import {resourceAction__fetch} from "../../../actions/resourceActions";
import {appointmentAction__pauseService, appointmentAction__resumePaused} from "../../../actions/appointmentActions";
import {Client, Vehicle} from "../../../models";
import {Popup as ClientPopup} from "../../../components/intern/clients";
import {Popup as VehiclePopup} from "../../../components/intern/vehicles";
import Countdown, {renderSimple} from "../../../components/partials/Countdown";
import {deepMemoize as memoize, Required, trueNull} from "../../../Logic/extensions";
import {serviceAction__collect} from "../../../actions/serviceActions";
import {groupBy} from 'lodash';

const CellContent = ({children, title, hidden}) => (
	<div>
		{!hidden && title && <small style={{
			display: "block",
			color: "darkgray",
			textDecoration: "underline",
			fontSize: "70%"
		}}>{title}</small>}
		<div>{children}</div>
	</div>
);

const renderDeadline = (data) => {
	data[2] += (data[1] * 24);
	const value = (data[0] ? '-' : '') + renderSimple(data);
	return <span style={{fontWeight: trueNull(data[0]) && 'bold', color: data[0] ? COLORS.SEMANTIC_RED : null}}>{value}</span>;
};

export class PausedAppointments extends React.Component {
	static propTypes = {
		paused_work: PropTypes.array,
		current_work: PropTypes.array,
		user: PropTypes.instanceOf(User),
		resourceMap: PropTypes.object,
		serviceMap: PropTypes.object,
		pauseService: PropTypes.func,
		resumePaused: PropTypes.func,
		withHeader: PropTypes.bool,
		closed: PropTypes.bool
	};
	static defaultProps = {
		paused_work: [],
		current_work: [],
		resourceMap: {},
		serviceMap: {},
		pauseService: () => alert('PausedAppointments::pauseService() is not implemented!'),
		resumePaused: () => alert('PausedAppointments::resumePaused() is not implemented!'),
		withHeader: false,
		closed: false
	};
	state = {
		pauseToggle: 0,
		showOrderDetail: 0,
		open: {}
	};
	constructor(props) {
		super(props);
		this.state.pauseToggle = props.closed ? -1 : 0;
	}
	togglePause = () => this.setState(state => ({
		pauseToggle: state.pauseToggle === 0 ? -1 : 0
	}));
	setOrderView = (showOrderDetail) => () => this.setState({showOrderDetail});
	loadServices = memoize(
		pausedList => {
			const ids = pausedList.map(p => p.service_id).filter(Boolean)
			if (ids.length) {
				this.props.collectServices(ids);
			}
		}
	);
	
	mapServices = memoize(
		(services, orderMap, orderVehicleMap) => {
			let next = [];
			for (const service of services) {
				let client_id = null;
				if (service.order_id in orderMap) {
					if (orderMap[service.order_id].order_vehicle_id in orderVehicleMap) {
						client_id = orderVehicleMap[orderMap[service.order_id].order_vehicle_id].client_id;
					}
				}
				next.push({
					...service,
					client_id
				})
			}
			return next;
		}
	);
	
	toggleOpen = order_id => () => this.setState(state => ({
		...state,
		open: {
			...state.open,
			[order_id]: !state.open[order_id]
		}
	}));
	
	isOpen = (orderId) => Boolean(this.state.open[orderId]);
	
	grouped = memoize(list => Object.values(groupBy(list, 'order_id')));
	
	componentDidMount() {
		this.loadServices(this.props.paused_work)
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.loadServices(this.props.paused_work)
	}
	
	render() {
		const {
			paused_work: paused,
			current_work,
			user,
			resourceMap,
			serviceMap,
			pauseService,
			resumePaused,
			withHeader,
			orderMap,
			orderVehicleMap
		} = this.props;
		
		let pausedCount = paused.length;
		if (!pausedCount || !user) {
			// console.warn('no paused found!');
			return null;
		}
	
		const userId = user.getData().workers_id;
		const isWorking = current_work.length !== 0;
		const {
			pauseToggle,
			showOrderDetail: orderDetailId
		} = this.state;
		// paused.length && this.loadServices(paused);
		let paused_work = this.mapServices(paused, orderMap, orderVehicleMap);
		
		return (
			<div style={{maginTop: 10, marginBottom: 30}}>
				<Accordion>
					<Accordion.Title
						style={{userSelect: "none"}}
						active={pauseToggle === 0}
						index={0}
						onClick={this.togglePause}
					>
						<Icon name={"dropdown"}/>Pausierte Aufträge ({pausedCount})
					</Accordion.Title>
					<Accordion.Content active={pauseToggle === 0}>
						<Table celled striped>
							{withHeader && <Table.Header>
								<Table.Row>
									<Table.HeaderCell textAlign={"center"}>Kunde (Ressource)</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Service</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Ressource</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Arbeitszeit</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Abgabe</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Abholung</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
								</Table.Row>
							</Table.Header>}
							<Table.Body>
								{this.grouped(paused_work).map(pauseGroup => {
									// const isOpen = this.isOpen(pauseGroup[0].order_id);
									return <React.Fragment key={pauseGroup[0].order_id}>
										{/*{trueNull(pauseGroup.length > 0) &&*/}
										{/*	<Table.Row>*/}
										{/*		<Table.Cell>*/}
										{/*			<Client.Provider client_id={pauseGroup[0].client_id} placeholder={`Client: ${pauseGroup[0].client_id}`}>{client =>*/}
										{/*				<CellContent title={'Kunde (Ressource)'} hidden={withHeader}>*/}
										{/*					<ClientPopup*/}
										{/*						client={client.origin}><span>{client ? client.name : (pauseGroup[0].salutation === "company" ? `Firma ${pauseGroup[0].company_name}` : pauseGroup[0].name)}</span></ClientPopup>*/}
										{/*				</CellContent>*/}
										{/*			}</Client.Provider>*/}
										{/*		</Table.Cell>*/}
										{/*		<Table.Cell colSpan={6}>&nbsp;</Table.Cell>*/}
										{/*		<Table.Cell style={{width: 60}} textAlign={'right'}><Button icon={isOpen ? 'minus' : 'plus'} onClick={this.toggleOpen(pauseGroup[0].order_id)} basic/></Table.Cell>*/}
										{/*	</Table.Row>*/}
										{/*}*/}
										{
											 pauseGroup.map(paused => {
												const accessable = paused.paused_by === userId;
												const resource = resourceMap[paused.resource_id] || {};
												const resourceName = resource.name || "n.v.";
												const service = serviceMap[paused.service_id] || {};
												const serviceName = service.title || "n.v.";
												const clientName = `${paused.last_name || ""} ${paused.first_name || ""}`.trim() || "n.v.";
												const error = moment(paused.latest_point) < moment();
												const warning = !error && moment(paused.latest_point).add(30, 'minute') < moment();
												return (
													<Table.Row error={error} warning={warning} key={paused.order_service_id}>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															{paused.client_id && <Client.Provider client_id={paused.client_id} placeholder={`Client: ${paused.client_id}`}>{client =>
																<CellContent title={'Kunde (Ressource)'} hidden={withHeader}>
																	<ClientPopup
																		client={client.origin}><span>{client ? client.name : (paused.salutation === "company" ? `Firma ${paused.company_name}` : clientName)}</span></ClientPopup>
																</CellContent>
															}</Client.Provider>}
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<Vehicle.Provider vehicle_id={paused.vehicle_id}>{vehicle =>
																<CellContent title={"Service"} hidden={withHeader}>
																	<VehiclePopup vehicle={vehicle}><span>{serviceName}</span></VehiclePopup>
																</CellContent>
															}</Vehicle.Provider>
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<CellContent title={"Ressource"} hidden={withHeader}>{resourceName}</CellContent>
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<CellContent title={"Arbeitszeit"} hidden={withHeader}>{paused.hours_of_work} Stunden</CellContent>
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<CellContent title={"Abgabe"} hidden={withHeader}>{moment(paused.deliver_point).format("LLL")}</CellContent>
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<CellContent title={"Deadline"} hidden={withHeader}><Countdown render={renderDeadline} targetDate={moment(paused.latest_point).toDate()}/></CellContent>
														</Table.Cell>
														<Table.Cell verticalAlign={"top"} textAlign={"center"}>
															<CellContent title={"Abholung"} hidden={withHeader}>{moment(paused.fetch_point).subtract(user.is_engineer ? paused.post_process_duration : 0, 'minute').format("LLL")}<Required color={'black'} visible={user.is_engineer}/></CellContent>
														</Table.Cell>
														<Table.Cell textAlign={"center"}>
															<Button.Group>
																<Button onClick={this.setOrderView(paused.order_id)}>Details</Button>
																<Button
																	disabled={!accessable}
																	onClick={() => pauseService(paused.order_service_id, false)}
																>Freigeben</Button>
																<Button
																	disabled={!accessable || isWorking}
																	onClick={() => resumePaused(paused.order_service_id)}
																>Fortsetzen</Button>
															</Button.Group>
														</Table.Cell>
													</Table.Row>
												)
										}
											)}
									</React.Fragment>
								})}
							
							</Table.Body>
						</Table>
					</Accordion.Content>
				</Accordion>
				<OrderViewDialog open={orderDetailId !== 0} onClose={this.setOrderView(0)}>
					<OrderQuickView order_id={orderDetailId} onRequestClose={this.setOrderView(0)}/>
				</OrderViewDialog>
			</div>
		);
	}
}

PausedAppointments = withInit(PausedAppointments);
PausedAppointments = connect(
	(state, props) => {
		const user = props.user;
		if ( !user ) {
			console.error("NO USER");
			return {};
		}
		return {
			paused_work: props.paused_work || state.appointments.paused_services,
			current_work: state.user.work,
			orderMap: state.map.orders,
			orderVehicleMap: state.map.order2vehicles,
			resourceMap: state.map.resources,
			serviceMap: state.map.services, //state.kfz.service.list
		};
	},
	(dispatch) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(resourceAction__fetch());
				// dispatch(kfzAction__fetchServices());
			})),
			pauseService: (id, pauseState, onSuccess) => dispatch(appointmentAction__pauseService(id, pauseState, onSuccess)),
			resumePaused: (id, onSuccess) => dispatch(appointmentAction__resumePaused(id, onSuccess)),
			collectServices: ids => dispatch(serviceAction__collect(ids))
		};
	}
)(PausedAppointments);

PausedAppointments = withRights(SECTION.APPOINTMENTS, PausedAppointments);
PausedAppointments = translate()(PausedAppointments);

