import * as React from "react";
import PropTypes from "prop-types";
import {Header, Item, Message, Popup, Search} from "semantic-ui-react";
import {withRights} from "../../../Tools";
import {COLORS, SECTION} from "../../../Logic/constants";
import {searchCall__order} from "../../../actions/searchActions";
import {debounce} from "lodash";
import {Flex, FlexChild} from "../../../components/partials/ActionHeader";
import {IconCar, IconFeaturedPlaylist, IconPerson} from "../../../Logic/icons";
import {OrderStateProvider} from "../../../Logic/OrderStateProvider";
import {moment} from "../../../Logic/Moment";
import {withModule} from "../../../Tools/RightsProvider";

const Line = ({title, value, style}) => (
	<Item.Description  style={style}><Flex align={"space-between"}><span>{title}:</span> <span>{value}</span></Flex></Item.Description>
);
Line.propTypes = {
	title: PropTypes.node.isRequired,
	value: PropTypes.node.isRequired
};

const STATUS_ICON_STYLE = {
	height: 18,
	width: 18
};

let Status = ({modules, order, order: {released_at, delivered_at, progress, finished_at, fetched_at}}) => (
	<Flex align={"flex-end"} valign={"center"} vmargin={10} gutterWidth={10}>
		<OrderStateProvider order={order}>{({state, Icon, color}) => <React.Fragment>
			{/*<FlexChild><Popup position={"top center"} inverted content={state.released ? "Freigeben" : "Nicht freigegeben"} trigger={<Icon.Released style={STATUS_ICON_STYLE} color={color.released}/>}/></FlexChild>*/}
			<FlexChild><Popup basic position={"top center"} inverted content={state.delivered ? "Auf dem Hof" : "Nicht auf dem Hof"} trigger={<Icon.Delivered style={STATUS_ICON_STYLE} color={color.delivered}/>}/></FlexChild>
			<FlexChild><Popup basic position={"top center"} inverted content={state.progress ? `In Arbeit durch ${progress} Mitarbeiter` : 'Keine Arbeitstätigkeit'} trigger={<Icon.Progress style={STATUS_ICON_STYLE} color={color.progress}/>}/></FlexChild>
			<FlexChild><Popup basic position={"top right"} inverted content={state.finished ? "Fertig" : "Nicht fertig"} trigger={<Icon.Finished style={STATUS_ICON_STYLE} color={color.finished}/>}/></FlexChild>
			{modules.assist && <FlexChild><Popup basic position={"top right"} inverted content={state.affirmed ? "Endkontrolle (bestätigt)" : "Endkontrolle (unbestätigt)"} trigger={<Icon.Affirmed style={STATUS_ICON_STYLE} color={color.affirmed}/>}/></FlexChild>}
			{modules.assist && <FlexChild><Popup basic position={"top right"} inverted content={state.processed ? "Rechnung fertig" : "Ohne Rechnung"} trigger={<Icon.Processed style={STATUS_ICON_STYLE} color={color.processed}/>}/></FlexChild>}
			<FlexChild><Popup basic position={"top right"} inverted content={state.fetched ? "Fahrzeug abgeholt" : "Fahrzeug nicht abgeholt"} trigger={<Icon.Fetched style={STATUS_ICON_STYLE} color={color.fetched}/>}/></FlexChild>
			{state.waiting && <FlexChild><Popup basic position={"top right"} inverted content={"Wartekunde"}
			                   trigger={<Icon.Waiting style={STATUS_ICON_STYLE} color={color.waiting}/>}/></FlexChild>}
			{state.delivery_service && <FlexChild><Popup basic position={"top right"} inverted content={"Bring- & Abholservice"}
			                                    trigger={<Icon.Delivery style={STATUS_ICON_STYLE} color={color.delivery_service}/>}/></FlexChild>}
			
		</React.Fragment>}</OrderStateProvider>
	</Flex>
);
Status = withModule(Status);
Status.propTypes = {
	order: PropTypes.shape({
		released_at: PropTypes.string,
		delivered_at: PropTypes.string,
		finished_at: PropTypes.string,
		fetched_at: PropTypes.string,
		progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}).isRequired
	
};

const ResultRenderer = ({service_count, order_id, order_name, deliver_point, fetch_point, order_creation,
	                        client_name, vehicle_id, client_first_name, email, client_number, company_name,
	                        client_id, chassis_number, registration_mark, vehicle_name, hsn, tsn, ...remainingOrder
}) => (
	<div key={order_id}>
		<Flex gutterWidth={10}>
			<FlexChild>
				<IconFeaturedPlaylist/>
			</FlexChild>
			<FlexChild grow={1}>
				<Item>
					<Item.Content>
						<Item.Header>
							<Flex align={'space-between'}>
								<span>Auftrags-ID</span>
								<span>{order_id}</span>
							</Flex>
						</Item.Header>
						<Item.Description>Service: {service_count}</Item.Description>
						<Line title={"Fahrzeuganlieferung"} value={moment(deliver_point).format('LLL [Uhr]')} style={{fontWeight: 'bold', color: COLORS.SECONDARY}}/>
						<Line title={"Fertigstellung"} value={moment(fetch_point).format('LLL [Uhr]')} style={{fontWeight: 'bold', color: COLORS.SEMANTIC_RED}}/>
						<Line title={"Erstellt"} value={moment(order_creation).format('LLL [Uhr]')}/>
						<Status order={remainingOrder}/>
					</Item.Content>
				</Item>
			</FlexChild>
		</Flex>
		<Flex gutterWidth={10} vmargin={10}>
			<FlexChild>
				<IconPerson/>
			</FlexChild>
			<FlexChild grow={1}>
				<Item>
					<Item.Content>
						<Item.Header>
							<Flex align={'space-between'}>
								<span>{client_first_name} {client_name}</span>
								<span>{client_number || 'n.v.'}</span>
							</Flex>
						</Item.Header>
						{company_name && <Line title={"Firma"} value={company_name}/>}
						{email && <Line title={"E-Mail"} value={email}/>}
					</Item.Content>
				</Item>
			</FlexChild>
		</Flex>
		<Flex gutterWidth={10}>
			<FlexChild>
				<IconCar/>
			</FlexChild>
			<FlexChild grow={1}>
				<Item>
					<Item.Content>
						<Item.Header>
							<Flex align={'space-between'}>
								<span>{vehicle_name}</span>
								<span>{vehicle_id}</span>
							</Flex>
						</Item.Header>
						{registration_mark && <Line title={"Kennzeichen"} value={registration_mark}/>}
						{chassis_number && <Line title={"Fahrgestell"} value={chassis_number}/>}
						{hsn && tsn && <Line title={"HSN/TSN"} value={`${hsn}/${tsn}`}/>}
					</Item.Content>
				</Item>
			</FlexChild>
		</Flex>
	</div>
);

class OrderSearch extends React.Component {
	static propTypes = {
		onSelect: PropTypes.func
	};
	static defaultProps = {
		onSelect: () => {}
	};
	
	state = {
		count: 0,
		list: [],
		loading: false,
		error: null,
		text: '',
		searched: false
	};
	
	
	constructor(props) {
		super(props);
		this.search = debounce(this.search, 500);
	}
	
	search = async (text) => {
		try {
			if (text.trim() === '') {
				this.setState({text: '', count: 0, list: []});
				return;
			}
			// const text = this.state.text;
			this.setState({loading: true, error: null});
			const {count, list} = await searchCall__order(text);
			this.setState({count, list: list.slice(0, 10).map(o => {
				o.description = '';
				o.title = o.order_id;
				return o;
				}), searched: true});
		} catch (e) {
			this.setState({error: e.message});
		} finally {
			this.setState({loading: false});
		}
	};
	
	onResultSelect = (e, data) => {
		this.props.onSelect(data.result, data, e);
	};
	
	onSearchChange = (e, {value}) => {
		this.setState({text: value});
		this.search(value);
	};
	
	render() {
		const {
			// count,
			list,
			loading,
			error,
			text,
			searched
		} = this.state;
		return [
			<Search
				key="search-input"
				defaultOpen={false}
				className={"order-search"}
				fluid
				placeholder={"Auftrag suchen"}
				autoFocus
				loading={loading}
				onSearchChange={this.onSearchChange}
				value={text}
				results={list}
				resultRenderer={ResultRenderer}
				noResultsMessage={"Keine Aufträge gefunden"}
				showNoResults={searched}
				onResultSelect={this.onResultSelect}
			/>,
			error && <Message negative key={'search-error'}>
				<Header>Error</Header>
				<p>{error}</p>
			</Message>
		];
	}
}

OrderSearch = withRights(SECTION.ORDERS, OrderSearch);
export default OrderSearch;