import React, {Component} from 'react'
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {dispatchSnack} from "../../../actions/snackbarActions";
import {ConnectedWheelListView} from "./WheelListView";
import {Button, Modal, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import {ConnectedWheelEditor, WheelModuleInactiveLabel} from "./WheelEditor";
import {WheelSearchModal} from "./WheelSearchModal";
import {withModule} from "../../../Tools/RightsProvider";

export class ClientWheelView extends Component {
	static propTypes = {
		// wheelMap: PropTypes.object.isRequired,
		// onLoad: PropTypes.func.isRequired,
		onNotification: PropTypes.func.isRequired
	}
	static defaultProps = {
		// wheelMap: {},
		// onFetch: wheelCall_list,
		onNotification: dispatchSnack
	}
	state = {
		loading: false,
		create: false,
		detail: null,
		search: false
	}
	
	setLoading = loading => this.setState({loading})
	showCreate = create => () => this.setState({create})
	showDetail = detail => () => this.setState({detail})
	
	
	getClientId = memoizeOne(props => Number(get(props, 'params.client_id', 0)) || null)
	getVehicleId = memoizeOne(props => Number(get(props, 'params.vehicle_id', 0)) || null)
	get client_id() { return this.getClientId(this.props.match) }
	get vehicle_id() { return this.getVehicleId(this.props.match)}
	get valid() { return this.client_id || this.vehicle_id }
	
	
	render() {
		const modules = this.props.modules
		return (
			<Segment basic style={{paddingTop: 0}}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton loading={this.state.loading}/>
						<ActionHeading>Reifenübersicht <WheelModuleInactiveLabel/></ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<Button icon={'search'} basic onClick={() => this.setState({search: true})}/>
						{this.client_id && <Button icon={'user'} color={'brown'} onClick={() => this.props.history.push(`/clients/${this.client_id}`)}/>}
						{this.client_id && this.vehicle_id && <Button icon={'car'} color={'brown'}  onClick={() => this.props.history.push(`/clients/${this.client_id}/vehicles/${this.vehicle_id}`)}/>}
						{modules.wheel && <Button icon={'plus'} basic onClick={this.showCreate(true)}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<ConnectedWheelListView vehicleId={this.vehicle_id} clientId={this.client_id} isLoading={this.setLoading} onSelect={detail => this.setState({detail})}/>
				<Modal open={this.state.create} closeIcon onClose={this.showCreate(false)} closeOnDimmerClick={false} centered={false}>
					<Modal.Header>Reifensatz einlagern <WheelModuleInactiveLabel/></Modal.Header>
					<Modal.Content>
						<ConnectedWheelEditor clientId={this.client_id} vehicleId={this.vehicle_id} onAfterChange={this.showCreate(false)}/>
					</Modal.Content>
				</Modal>
				<Modal open={!!this.state.detail} closeIcon onClose={this.showDetail(null)} closeOnDimmerClick={false} centered={false}>
					<Modal.Header>Reifensatz bearbeiten <WheelModuleInactiveLabel/></Modal.Header>
					<Modal.Content>
						{this.state.detail && <ConnectedWheelEditor wheel={this.state.detail} onAfterChange={this.showDetail(null)}/>}
					</Modal.Content>
				</Modal>
				{this.state.search && <WheelSearchModal clientId={this.client_id} vehicleId={this.vehicle_id} open onClose={() => this.setState({search: false})}/>}
			</Segment>
		);
	}
}

ClientWheelView = withModule(ClientWheelView)