import * as React from "react";
import PropTypes from "prop-types";
import {EasyFlex, Flex, Space} from "../../../components/partials/ActionHeader";
import {MiniMenu} from "../../../Logic/MiniMenu";
import {IconButton, List, ListItem, MenuItem} from "material-ui";
import {IconAddBox, IconCar, IconEdit, IconRemove, IconReorder, IconShuffle} from "../../../Logic/icons";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {kfzAction__fetchServices} from "../../../actions/kfzActions";
import {Button, Icon, Modal, Segment} from "semantic-ui-react";
import {deepMemoize as memoize, trueNull} from "../../../Logic/extensions";
import {cloneDeep, isFunction, values} from "lodash";
import ModelServiceSelectorController from "../master-data/service/ModelServiceSelectorController";
import {CustomServiceForm} from "../master-data/service";
import {withLoadingWithoutDispatch} from "../../../actions/loaderActions";
import {PROC_SERVICE_CUSTOM, SUB_SERVICE_SELECTOR_RESET} from "../../../actions";
import {translate} from "react-i18next";
import {serviceAction__deleteCustom} from "../../../actions/serviceActions";
import {publish, subscribe} from "../../../Logic/PubSub";
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import arrayMove from "array-move";


const LoaderButton = withLoadingWithoutDispatch("loading", PROC_SERVICE_CUSTOM)(Button);


const ListBody = ({services, removeService, style, ...props}) => (
	<List style={{display: 'flex', flexWrap: 'wrap', marginLeft: 12, marginRight: 12, ...style}} {...props}>
		{services.map(service => { // show the selected services
			return (
				<ListItem
					key={service.service_id}
					primaryText={service.title}
					rightIconButton={
						<IconButton
							onClick={removeService.bind(null, service, service.custom)}
						>
							{<IconRemove/>}
						</IconButton>}/>
			);
		})}
	</List>
);

// const Drag = SortableHandle(() => <IconReorder color={'grey'}/>);

const SortableItem = SortableElement(({value, showHandle}) =>
	<ListItem
		leftIcon={trueNull(showHandle) && <IconReorder color={'grey'}/>}
		primaryText={value.title}
	/>
)

const SortableList = SortableContainer(({items, showHandle}) =>
	<List>
		{items.map((value, index) =>
			<SortableItem value={value} index={index} key={value.service_id} showHandle={showHandle}/>
		)}
	</List>
);

const SortableBody = ({services, showHandle, onChange}) => (
	<SortableList helperClass={'order-service-sortable'} items={services} showHandle={showHandle} onSortEnd={onChange}/>
);
SortableBody.defaultProps = {
	showHandle: true
};

export class OrderServiceSelector extends React.PureComponent {
	static defaultState = {
		selected_services: [],
		show: {
			service: false,
			custom_service: false,
			reorder: false
		}
	};
	static propTypes = {
		minLabelWidth: PropTypes.number,
		serviceMap: PropTypes.object,
		// order: PropTypes.object.isRequired,
		vehicle: PropTypes.object.isRequired,
		onDeleteCustomService: PropTypes.func,
		onChange: PropTypes.func,
		exclude: PropTypes.array,
		asGrid: PropTypes.bool,
		sortable: PropTypes.bool,
		include: PropTypes.arrayOf(PropTypes.object)
	};
	
	static defaultProps = {
		exclude: [],
		include: [],
		onDeleteCustomService: () => alert("`OrderServiceSelector::onDeleteCustomService()` is not implemented yet!")
	};
	
	state = cloneDeep(OrderServiceSelector.defaultState);
	
	reset = () => this.setState(cloneDeep(OrderServiceSelector.defaultState));
	
	componentDidMount() {
		this.setState({selected_services: this.props.include});
		this.unsubscribeReset = subscribe(SUB_SERVICE_SELECTOR_RESET, this.reset);
	}
	
	componentWillUnmount() {
		this.unsubscribeReset && this.unsubscribeReset();
	}
	
	switcheroo = ({oldIndex, newIndex}) => this.setState(s => ({selected_services: arrayMove(s.selected_services, oldIndex, newIndex)}),
		() => isFunction(this.props.onChange) && this.props.onChange(this.state.selected_services));
	
	appendService = service => {
		if (this.state.selected_services.find(s => s.service_id === service.service_id)) return true;
		this.setState(state => ({
			selected_services: [...state.selected_services, service]
		}), () => isFunction(this.props.onChange) && this.props.onChange(this.state.selected_services));
		return true;
	};
	
	removeService = (service, custom) => {
		const service_id = service.service_id || service;
		if (!this.state.selected_services.find(s => s.service_id === service_id)) return true;
		custom && this.props.onDeleteCustomService(service_id);
		this.setState(state => ({
			selected_services: state.selected_services.filter(s => s.service_id !== service_id)
		}), () => isFunction(this.props.onChange) && this.props.onChange(this.state.selected_services));
		return true;
	};
	
	show = (which, value) => () => this.setState(state => ({
		...state,
		show: {
			...state.show,
			[which]: value
		}
	}));
	
	serviceList = memoize(
		(map) => values(map)
	);
	
	getExcludes = memoize(
		list => list.map(s => s.service_id || s)
	);
	
	getServices = memoize(
		(services, exclude) => {
			return services.filter(s => !exclude.includes(s.service_id));
		}
	);
	
	// getVehicle = memoize(
	// 	(order, vehicles) => vehicles[order.order_vehicle_id]
	// );
	
	render() {
		const {
			serviceMap,
			vehicle,
			t,
			exclude,
			minLabelWidth,
			asGrid,
			sortable
		} = this.props;
		if (!serviceMap) {
			return <Segment padded={"very"} loading/>
		}
		const {
			selected_services,
			show
		} = this.state;
		// const serviceList = this.serviceList(serviceMap);
		const excludedIds = this.getExcludes(exclude);
		// const vehicle = this.getVehicle(order, order_vehicles);
		const services = this.getServices(selected_services, excludedIds);
		const selectionExclude = [...excludedIds, ...services.map(s => s.service_id)];
		return (
			<React.Fragment>
				<EasyFlex style={{borderBottom: '1px dashed #eee', marginLeft: -12, marginRight: -12, paddingRight: 12}} valign={EasyFlex.valign.BASELINE}>
					<EasyFlex valign={EasyFlex.valign.CENTER} wrap={false} style={{minWidth: minLabelWidth || 80, marginLeft: 12, marginRight: 12}}>
						<strong>Servicewahl</strong>
						<MiniMenu icon={<IconAddBox/>} position={'top left'}>
							<MenuItem
								primaryText={"Service auswählen"}
								leftIcon={<IconCar/>}
								onClick={this.show("service", true)}
							/>
							<MenuItem
								primaryText={"Angepassten Service erstellen"}
								leftIcon={<IconEdit/>}
								onClick={this.show("custom_service", true)}
							/>
						</MiniMenu>
					</EasyFlex>
					<ListBody services={services} removeService={this.removeService} style={{flexGrow: 100}}/>
					{sortable && services.length > 1 && <IconButton style={{alignSelf: 'flex-start'}} onClick={this.show('reorder', true)}><IconShuffle/></IconButton>}
					
				</EasyFlex>
				<Modal centered={false} open={show.reorder} onClose={this.show('reorder', false)} closeIcon>
					<Modal.Header as={'h4'}>Reihenfolge ändern</Modal.Header>
					<Modal.Content scrolling>
						<SortableBody services={services} onChange={this.switcheroo}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.show('reorder', false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>
				{show.service &&
				/*<Dialog className={"order-create-dialog"} open onRequestClose={this.show("service", false)} autoScrollBodyContent>
					<ModelServiceSelectorController
						excludeServiceIds={selectionExclude}
						onAbort={this.show('service', false)}
						onSelect={service => this.appendService(service) && this.show("service", false)()}
						model_id={vehicle ? (vehicle.model_id || 0) : 0}
						selectOnClick
						asGrid={asGrid}
					/>
				</Dialog>*/
					<Modal
						open
						onClose={this.show('service', false)}
						centered={false}
					>
						<Modal.Content>
							<ModelServiceSelectorController
								excludeServiceIds={selectionExclude}
								onAbort={this.show('service', false)}
								onSelect={service => this.appendService(service) && this.show("service", false)()}
								model_id={vehicle ? (vehicle.model_id || 0) : 0}
								selectOnClick
								asGrid={asGrid}
							/>
						</Modal.Content>
					</Modal>
				}
				{show.custom_service &&
				/*<BasicDialog noPadding autoScrollBodyContent modal open onClose={this.show("custom_service", false)}>
					<CustomServiceForm
						maxWidth={"inherit"}
						id={"custom-service-form"}
						onCreated={service => this.appendService(service) && this.show("custom_service", false)()}
						// onSubmit={this.onCreateCustomService}
					/>
					<Space height={30}/>
					<Flex align={"space-between"}>
						<Button
							onClick={this.show("custom_service", false)}
						>
							<Icon name={"close"}/> {t("actions.close")}
						</Button>
						<LoaderButton positive form={"custom-service-form"}>Speichern</LoaderButton>
					</Flex>
				</BasicDialog>*/
				<Modal
					open
					onClose={this.show('custom_service', false)}
					centered={false}
				>
					<Modal.Content>
						<CustomServiceForm
							maxWidth={"inherit"}
							id={"custom-service-form"}
							onCreated={service => this.appendService(service) && this.show("custom_service", false)()}
							// onSubmit={this.onCreateCustomService}
						/>
						<Space height={30}/>
						<Flex align={"space-between"}>
							<Button
								onClick={this.show("custom_service", false)}
							>
								<Icon name={"close"}/> {t("actions.close")}
							</Button>
							<LoaderButton positive form={"custom-service-form"}>Speichern</LoaderButton>
						</Flex>
					</Modal.Content>
				</Modal>
				}
			</React.Fragment>
		);
	}
}

OrderServiceSelector = withInit(OrderServiceSelector);
OrderServiceSelector = connect(
	(state) => {
		return {
			serviceMap: state.kfz.service.list
		};
	},
	(dispatch) => {
		return {
			init: () => {
				dispatch(kfzAction__fetchServices());
			},
			onDeleteCustomService: (data, onSuccess) => dispatch(serviceAction__deleteCustom(data, onSuccess))
		};
	}
)(OrderServiceSelector);
OrderServiceSelector = translate()(OrderServiceSelector);

export const requestServiceSelectorReset = () => publish(SUB_SERVICE_SELECTOR_RESET);