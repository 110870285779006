import * as React from "react";
import PropTypes from "prop-types";
import {isFunction} from "lodash";

const Switch = ({children, value = ''}) => {
	let cased = 0;
	let lastIndex = React.Children.count(children) - 1;
	return React.Children.map(children, (child, index) => {
		if (child.type === Default) {
			if ( index < lastIndex) {
				console.error("'Default' component must be last in Switch");
				return null;
			}
			if (cased) {
				return null;
			}
			return child;
		}
		let is = child.props.is;
		if ((isFunction(is) && is.call(this, value) === true) || is === value) {
			cased++;
			return child
		}
		return null;
		// React.cloneElement(child, {value})
	});
};

Switch.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		let defaults = 0;
		let lastIndex = React.Children.count(prop) - 1;
		React.Children.forEach(prop, (child, index) => {
			if (child.type !== Case && child.type !== Default) {
				error = new Error(`'${componentName}' children should be of type 'Case' or 'Default'`);
			}
			if (child.type === Default ) {
				defaults++;
				if (  index < lastIndex ) {
					error = new Element(`'${componentName}': 'Default' Component must be last one!`);
				}
			}
		});
		if ( defaults > 1 ) {
			error = new Error(`'${componentName}' children may only have 1 Default component`)
		}
		return error;
	},
	value: PropTypes.any.isRequired
};

const Case = ({children}) => <React.Fragment>{children}</React.Fragment>;
Case.propTypes = {
	children: PropTypes.node,
	is: PropTypes.any.isRequired
};

const Default = ({children}) => <React.Fragment>{children}</React.Fragment>;
Default.propTypes = {
	children: PropTypes.node
};

const If = ({children, condition}) => condition ? children : null;
If.propTypes = {
	children: PropTypes.node,
	condition: PropTypes.any.isRequired
};

const renderIf = conditionCallback => Component => props => (
	<If condition={Boolean(conditionCallback(props))}><Component/></If>
);

export {Switch, Case, Default, If, renderIf};