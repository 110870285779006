import {connect} from "react-redux";
import WorkersDetailView from "../../../components/intern/workers/WorkersDetailView";
import {
	deleteWorker,
	loadWorker
} from "../../../actions/workerActions";
import User from "../../../models/User";
import {loadAreas, loadGroups} from "../../../actions/rightActions";
import {push} from "connected-react-router";
import {SECTION} from "../../../Logic/constants";
import {isOnline} from "../../../actions/userActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import isFunc from 'lodash/isFunction';
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER_DELETE} from "../../../actions";
import withInit from "../../../Logic/withInit";

const mapState = (state, props) => {
	const user = User._(state.user.data);
	const workers_id = Number(props.match.params.id);
	return ({
		worker: workers_id ? state.workers.list[workers_id] || null : null,
		user,
		house: state.carhouse.own,
		carhouse: state.carhouse.own,
		right_areas: state.rights.areas.list,
		right_groups: state.rights.groups.list,
		right_relations: state.rights.groups.relation,
		mayChangeSelf: user.isSelf(workers_id) || user.hasAccessTo(SECTION.WORKERS, User.RIGHT.WRITE),
		isSupport: user.isSupport() && user.isSelf(workers_id),
		...user.getAccessTo(SECTION.WORKERS, [0,1,2,3,4]),
		mayRead: user.hasAccessTo(SECTION.WORKERS, User.RIGHT.READ) || user.isSelf(workers_id),
		isDeleting: isLoading(state, PROC_WORKER_DELETE, workers_id)
	});
};
const mapDispatch = (dispatch, props) => {
	// dispatch(isOnline(() => {
	// 	dispatch(loadWorker(props.match.params.id));
	// 	dispatch(loadAreas());
	// 	dispatch(loadGroups());
	// }));
	return ({
		init: () => dispatch(isOnline(() => {
			dispatch(loadWorker(props.match.params.id, true));
			dispatch(loadAreas());
			dispatch(loadGroups());
		})),
		onBack: (to) => dispatch(push(to || '/workers')),
		onDelete: (worker, onSuccess) => dispatch(deleteWorker(worker, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(push('/workers'));
			dispatch(addSnackbar('snackbar.worker.deleted'));
		})),
		push: to => dispatch(push(to))
	});
};

const WorkersDetailController = connect(mapState, mapDispatch)(withInit(WorkersDetailView));
export default WorkersDetailController;