import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Grid, Header, Label, Popup} from "semantic-ui-react";
import {Order} from "../../../models";
import {trueNull} from "../../../Logic/extensions";
import {IconLock, IconLockOpen} from "../../../Logic/icons";
import {COLORS, SECTION} from "../../../Logic/constants";
import {EasyFlex} from "../../../components/partials/ActionHeader";
import {StatyComponent} from "../../../Tools/ReactExtension";
import {CircleNotchLoader} from "../../../components/Loaders";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../components/partials";
import {isFunction} from 'lodash';
import {translate} from "react-i18next";
import {withRights} from "../../../Tools";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {orderAction__updateReleaseService, orderAction__updateReleaseStock} from "../../../actions/orderActions";

export let ReleaseBatchV1 = ({released, dispatch, ...props}) => released > 0 ? <Label circular color={"red"} {...props}>{released}</Label> : null;

ReleaseBatchV1 = connect(
	state => ({
		released: state.orders.release.length
	})
)(ReleaseBatchV1);

export const ReleaseBatch = (props) => <Order.ReleaseListProvider init_data>{({count}) =>
 count > 0 ? <Label circular color={"red"} {...props}>{count}</Label> : null
}</Order.ReleaseListProvider>;

export const ReleaseSimpleIcon = ({unlocked, size, style, ...props}) =>
	unlocked ?
		<IconLockOpen color={COLORS.SEMANTIC_GREEN} style={{width: size, height: size, ...style}} {...props}/> :
		<IconLock color={COLORS.SEMANTIC_RED} style={{width: size, height: size, ...style}} {...props}/>;
ReleaseSimpleIcon.propTypes = {
	unlocked: PropTypes.bool,
	size: PropTypes.number,
	style: PropTypes.object
};
ReleaseSimpleIcon.defaultProps = {
	unlocked: false,
	size: 20
};

const ReleasePopup = ({release, size, leftChild, rightChild,...props}) => {
	// if (!!release.stock && !!release.service) {
	// 	return 'Service freigegeben';
	// }
	return (
		<Grid centered divided columns={2} relaxed={"very"}>
			<Grid.Column textAlign={"center"}>
				<Header as={"h5"}>Service</Header>
				<p><ReleaseSimpleIcon unlocked={!!release.service} size={size} {...props}/></p>
				{leftChild}
			</Grid.Column>
			<Grid.Column textAlign={"center"}>
				<Header as={"h5"}>Lager</Header>
				<p><ReleaseSimpleIcon unlocked={!!release.stock} size={size} {...props}/></p>
				{rightChild}
			</Grid.Column>
		</Grid>
	);
};
ReleasePopup.propTypes = {
	release: PropTypes.shape({
		service: PropTypes.any,
		stock: PropTypes.any
	}).isRequired,
	size: PropTypes.number
};
ReleasePopup.defaultProps = {
	size: 32
};

export const ReleaseTwoFaceIcon = ({release, size, iconStyle, childStyle}) => {
	if (release.stock && release.service) {
		return <ReleaseSimpleIcon unlocked size={size} style={{...iconStyle, ...childStyle}}/>;
	}
	return <EasyFlex inline align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{position: "relative", ...childStyle}}>
		<ReleaseSimpleIcon color={release.service ? COLORS.SEMANTIC_GREEN : COLORS.SEMANTIC_RED} size={size} style={{...iconStyle}}/>
		<div style={{overflow: "hidden", position: "absolute", right: 0, top: 0, bottom: 0, left: "50%"}}>
			<ReleaseSimpleIcon color={release.stock ? COLORS.SEMANTIC_GREEN : COLORS.SEMANTIC_RED} size={size} style={{...iconStyle, position: "absolute", right: 0, top: 0, bottom: 0}}/>}
		</div>
	</EasyFlex>;
};
ReleaseTwoFaceIcon.propTypes = {
	release: PropTypes.shape({
		service: PropTypes.any,
		stock: PropTypes.any
	}).isRequired,
	iconStyle: PropTypes.object,
	childStyle: PropTypes.object,
	size: PropTypes.number
};
ReleaseTwoFaceIcon.defaultProps = {
	size: 20
};


export const ReleaseIcon2 = ({id, api, iconStyle, childStyle}) => {
	return (
		<Order.ReleaseProvider provideOnly={!api} id={id}>{release =>
			<Popup
				inverted
				content={trueNull(release.stock && release.service) ? 'Service freigegeben' : 'Freigabe ausstehend'}
				trigger={trueNull(release.stock && release.service) ?
					<IconLockOpen color={COLORS.SEMANTIC_GREEN} style={{width: 20, height: 20, ...iconStyle, ...childStyle}}/> :
					<IconLock color={COLORS.SEMANTIC_RED} style={{width: 20, height: 20, ...iconStyle, ...childStyle}}/>}
			/>
		}</Order.ReleaseProvider>
	);
};

export const ReleaseIcon = ({id, api, size, iconStyle, childStyle, onClick}) => {
	return (
		<Order.ReleaseProvider provideOnly={!api} id={id} >{release =>
			<Popup
				flowing
				// inverted
				trigger={
					release.stock && release.service ?
						<ReleaseSimpleIcon unlocked size={size} style={{...iconStyle, ...childStyle}} onClick={onClick && onClick.bind(null, id)}/> :
						<EasyFlex inline align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{position: "relative", ...childStyle}} onClick={onClick && onClick.bind(null, id)}>
							<ReleaseSimpleIcon color={release.service ? COLORS.SEMANTIC_GREEN : COLORS.SEMANTIC_RED} size={size} style={{...iconStyle}}/>
							<div style={{overflow: "hidden", position: "absolute", right: 0, top: 0, bottom: 0, left: "50%"}}>
								<ReleaseSimpleIcon color={release.stock ? COLORS.SEMANTIC_GREEN : COLORS.SEMANTIC_RED} size={size} style={{...iconStyle, position: "absolute", right: 0, top: 0, bottom: 0}}/>
							</div>
						</EasyFlex>
				}
			>
				<ReleasePopup release={release}/>
			</Popup>
		}</Order.ReleaseProvider>
	);
};
ReleaseIcon.propTypes = {
	id: PropTypes.number.isRequired,
	api: PropTypes.bool,
	iconStyle: PropTypes.object,
	childStyle: PropTypes.object,
	size: PropTypes.number
};
ReleaseIcon.defaultProps = {
	api: false,
	size: 20
};

class BaseServiceReleaseToggle extends React.Component {
	static propTypes = {
		orderServiceId: PropTypes.number.isRequired,
		released: PropTypes.bool,
		useToggle: PropTypes.bool,
		onChange: PropTypes.func,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		onNotification: dispatchSnack
	}
	
	state = {
		loading: false
	}
	
	change = (callback) => async() => {
		const {onChange, released, useToggle, orderServiceId, onNotification} = this.props;
		if (!isFunction(onChange)) {
			console.warn('onChange() is mandatory for `BaseServiceReleaseToggle` component');
			return null;
		}
		try {
			this.setState({loading: true});
			await onChange(orderServiceId, !released, useToggle);
			isFunction(callback) && callback();
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
		}
	}
	
	render() {
		let {released, t, rights} = this.props;
		const {loading} = this.state;
		return (
			<div>
				<ConfirmButton disabled={!rights || !rights.mayChange} basic content={released ? t('...', "Freigabe zurückziehen") : t('...', "Freigabe erteilen")}>
					<ConfirmButtonConsumer>{confirm =>
						<Button.Group>
							<Button disabled={loading} basic color={'grey'} onClick={confirm.bind(undefined, false)}>{t("actions.cancel")}</Button>
							<Button.Or/>
							<Button loading={loading} color={released ? 'red' : 'green'} onClick={this.change(confirm.bind(null, false))}>{released ? t('...', "zurückziehen") : t('...', "erteilen")}</Button>
						</Button.Group>
					}</ConfirmButtonConsumer>
				</ConfirmButton>
			</div>
		);
	}
}

BaseServiceReleaseToggle = translate()(BaseServiceReleaseToggle);
let ServiceReleaseToggle = withRights(SECTION.RELEASE, BaseServiceReleaseToggle);
ServiceReleaseToggle = connect(
	null,
	dispatch => ({
		onChange: (id, active, toggle) => dispatch(orderAction__updateReleaseService(id, active, toggle, true))
	})
)(ServiceReleaseToggle);

let StockReleaseToggle = withRights(SECTION.STOCK, BaseServiceReleaseToggle);
StockReleaseToggle = connect(
	null,
	dispatch => ({
		onChange: (id, active, toggle) => dispatch(orderAction__updateReleaseStock(id, active, toggle, true))
	})
)(StockReleaseToggle);


export class ReleaseView extends StatyComponent {
	static propTypes = {
		orderServiceId: PropTypes.number.isRequired,
		leftChild: PropTypes.node,
		rightChild: PropTypes.node
	};
	render() {
		const {orderServiceId} = this.props;
		return (
			<div>
				<Order.ReleaseProvider id={orderServiceId} placeholder={<CircleNotchLoader/>}>{rl => {
					return <Order.ConnectedRelease id={orderServiceId}>{release =>
						<ReleasePopup release={release} leftChild={
							<ServiceReleaseToggle orderServiceId={orderServiceId} released={!!release.service}/>
						} rightChild={
							<StockReleaseToggle orderServiceId={orderServiceId} released={!!release.stock}/>
						}/>
					}</Order.ConnectedRelease>
				}}</Order.ReleaseProvider>
			</div>
		);
	}
}