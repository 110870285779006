import isFunc from "lodash/isFunction";
import {setLoader, unsetLoader} from "./loaderActions";
import {PROC_SEARCH_ZIPCODE} from "./index";
// import {GET, POST} from "ith-fetch";
import {GET, POST} from "../Logic/fetch";

export const searchAction__zipcode = async (code, onSuccess, onFail, onComplete, dispatch) => {
	try {
		isFunc(dispatch) && dispatch(setLoader(PROC_SEARCH_ZIPCODE));
		const result = await GET('/search/city/zipcode/' + code);
		isFunc(onSuccess) && onSuccess(result, code);
	} catch (e) {
		isFunc(onFail) && onFail(e, code);
	} finally {
		isFunc(dispatch) && dispatch(unsetLoader(PROC_SEARCH_ZIPCODE));
		isFunc(onComplete) && onComplete(code);
	}
};

export const searchAction__zipcode__pure = (code) => GET('/search/city/zipcode/' + code);

// eslint-disable-next-line
// noinspection JSUnusedGlobalSymbols
export const searchAction__zipcode__redux = (code, onSuccess, onFail, onComplete) =>
	dispatch => {
		// noinspection JSIgnoredPromiseFromCall
		searchAction__zipcode(code, onSuccess, onFail, onComplete, dispatch);
	};

export const searchAction__client = search => GET('/search/client/' + search);

export const searchCall__order = search => GET('/search/order/' + search);

/**
 *
 * @param search
 * @param options
 *         # allow_empty: boolean => false,
 *         # mode: enum(full, pre, post, strict) => full
 *         # split_words: boolean => false (if true, every word occurance is tested)
 *         # weights: object (weighting the search for fields | 0 means do not search)
 *              # client_number: uint
 *              # first_name: uint
 *              # name: uint
 *              # company_name: uint
 *              # chassis_number: uint
 *              # first_registration: uint
 * @return {Promise<*>|*}
 */
export const searchAction__clientWithOptions = (search, options) => POST('/search/client/' + search, options);
/**
 *  Fetches only with id and weight...
 * @param searchText text to search for
 * @param client optional. client_id, or object containing client_id
 * @return {*} Promise (FETCH)
 */
export const searchAction__vehicleSimple = (searchText, client) => {
	const client_id = client ? (client.client_id || client) : null;
	const append = client_id ? '/' + client_id : '';
	return GET("/search/vehicle/simple/" + searchText + append);
};
// noinspection JSUnusedGlobalSymbols
/**
 *  fetches hole vehicle object
 * @param searchText text to search for
 * @param client optional. client_id, or object containing client_id
 * @return {*} Promise (FETCH)
 */
export const searchAction__vehicleFull = (searchText, client) => {
	const client_id = client ? (client.client_id || client) : null;
	const append = client_id ? '/' + client_id : '';
	return GET("/search/vehicle/full/" + searchText + append);
};

export const searchAction__loanCar = (searchText, simpleSearch) => {
	return GET("/search/loan-car/" + searchText + (simpleSearch ? `/${simpleSearch}` : ""));
};