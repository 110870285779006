import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Image, Label, Message, Modal, Placeholder, Popup, Segment, Table} from "semantic-ui-react";
import {withRights} from "../../../Tools";
import {COLORS, SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {PROC_ORDER, PROC_ORDER_DAY_LIST, SUB_DAY_SCHEDULE, SUB_ORDER_DELETE} from "../../../actions";
import {Trans, translate} from "react-i18next";
import {isLoading} from "../../../actions/loaderActions";
import {isOnline} from "../../../actions/userActions";
import {deepMemoize as memoize, falseNull, getTimeSpan, trueNull} from "../../../Logic/extensions";
import {orderAction__get, orderAction__listDay} from "../../../actions/orderActions";
import {kfzAction__fetchServices} from "../../../actions/kfzActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {get, isBoolean, isFunction, isInteger, isObject, uniqBy, values} from "lodash";
import {Client, Order, OrderService, Vehicle, Worker} from "../../../models";
import {push} from "connected-react-router";
import NoAccess from "../../../components/NoAccess";
import {ActionHeader, ActionHeaderGroup, EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import moment from "../../../Logic/Moment";
import {IconCar, IconNotifications, IconNotificationsNone, IconNotificationsOff, IconPauseCircleOutline, IconPerson, IconSwapHoriz} from "../../../Logic/icons";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderQuickView from "./OrderQuickView";
import round from "round-precision";
import cn from "classnames";
import {loadWorkers, workerAction__getActivities} from "../../../actions/workerActions";
import {DayPicker} from "../../../components/partials/DayPicker";
import {ConnectedWorkerAvatarView, ConnectedWorkerAvatarWorkingView} from "../workers";
import {loanCarAction__fetch} from "../../../actions/loanCarActions";
import {IconButton} from "material-ui";
import {KeyModifier} from "../../../Logic/KeyHandler";
import KeyHandler from "react-key-handler";
import {OrderStateProvider} from "../../../Logic/OrderStateProvider";
import {Popup as VehiclePopup, RegistrationMark} from "../../../components/intern/vehicles";
import {Popup as ClientPopup} from "../../../components/intern/clients";
import {ReleaseIcon} from "../release";
import {subscribe} from "../../../Logic/PubSub";
import {MobileAwarePopup} from "../../../components/partials/MiniComponents";
import {isMobile} from 'react-device-detect';
import {withModule} from "../../../Tools/RightsProvider";
import {ConnectedOrderInfoUpdateView} from "./quick";

const SELECTIONS = [
	{key: "deliver_point", title: "Abgabetermin"},
	{key: "fetch_point", title: "Abholtermin"}
];

const Wrapper = ({count, display, ...props}) => {
	if (count) {
		if (display) {
			return (
				<IconButton {...props}><IconNotifications color={COLORS.SEMANTIC_RED}/></IconButton>
			);
		}
		return (
			<IconButton {...props}><IconNotificationsOff color={COLORS.SEMANTIC_RED}/></IconButton>
		);
	}
	return <IconButton disabled><IconNotificationsNone/></IconButton>
};

const Notification = ({count, ...props}) => {
	if (!count) {
		return <Wrapper count={count} {...props}/>;
	}
	return (
		<div style={{display: "inline-block", position: "relative"}}>
			<Label circular color={"orange"} style={{position: "absolute", left: "-15%", top: "-15%"}}>{count}</Label>
			<MobileAwarePopup position={"bottom left"} inverted
			       content={<span>Zeige unvollständige Termine der Vergangenheit an <div style={{textAlign: "right"}}><kbd style={{background: "#555", padding: 4, borderRadius: 4}}>Taste V</kbd></div></span>}
			       trigger={<Wrapper count={count} {...props}/>}/>
		</div>
	);
};


let OrderState = ({order, modules}) => (
	<OrderStateProvider order={order}>{
		({Icon, color, state}) =>
			// <EasyFlex wrap>
				<Flex hmargin={8} valign={"center"} align={"flex-start"} wrap={'nowrap'}>
					<Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={"Auf dem Hof"} trigger={<Icon.Delivered color={color.delivered}/>}/>
						</div>
					</Flex>
					<Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={"In Arbeit"} trigger={<Icon.Progress color={color.progress}/>}/>
						</div>
					</Flex>
					<Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={"Fertig"} trigger={<Icon.Finished color={color.finished}/>}/>
						</div>
					</Flex>
					
					
					{modules.assist && <Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={"Endkontrolle (bestätigt)"} trigger={<Icon.Affirmed color={color.affirmed}/>}/>
						</div>
					</Flex>}
					{modules.assist && <Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={state.processing ? "In Nachbearbeitung" : "Rechnung fertig"} trigger={<Icon.Processed color={color.processed}/>}/>
						</div>
					</Flex>}
					<Flex style={{height: 48, width: 48}}>
						<div style={{margin: "auto"}}>
							<Popup inverted content={"Fahrzeug abgeholt"} trigger={<Icon.Fetched color={color.fetched}/>}/>
						</div>
					</Flex>
				</Flex>
	}</OrderStateProvider>
);

OrderState = withModule(OrderState);


class OrderSchedule extends React.PureComponent {
	static propTypes = {
		hideHeaders: PropTypes.bool,
		day: PropTypes.instanceOf(Date),
		instance: PropTypes.func,
		onOrderCountUpdate: PropTypes.func,
		vehicleMap: PropTypes.object,
		stateFilters: PropTypes.shape({
			released: PropTypes.bool,
			delivered: PropTypes.bool,
			progress: PropTypes.bool,
			affirmed: PropTypes.bool,
			post_processing: PropTypes.bool,
			post_processed: PropTypes.bool,
			finished: PropTypes.bool,
			fetched: PropTypes.bool
		}),
		consultantFilter: PropTypes.number
	};
	static defaultProps = {
		instance: () => {
		},
		onOrderCountUpdate: () => {
		},
		vehicleMap: {},
		stateFilters: {
			released: null,
			delivered: null,
			progress: null,
			finished: null,
			fetched: null,
			affirmed: null,
			post_processed: null,
			post_processing: null,
			is_waiting: null,
			is_delivery_service: null,
			is_consultant: null,
			has_wheel: null
		}
	};
	
	state = {
		day: this.props.day,
		order_id: 0,
		query: SELECTIONS[0].key,
		show_past: false,
		list: [],
		workload: {},
		show_info_edit: null,
		info_edit_portal: null
	};
	orders = 0;
	schedule = [];
	events = [];
	
	
	componentDidMount() {
		this.unsubscribeCreate = subscribe(SUB_DAY_SCHEDULE, this.handleSSEInsert);
		this.unsubscribeDelete = subscribe(SUB_ORDER_DELETE, id => this.setState(state => ({list: state.list.filter(o => o.order_id !== id)})));
		this.props.instance(this);
		this.fetch();
	}
	
	componentWillUnmount() {
		this.unsubscribeCreate && this.unsubscribeCreate();
		this.unsubscribeDelete && this.unsubscribeDelete();
		this.props.instance(null);
	}
	
	load() {
		this.fetch();
	}
	
	handleSSEInsert = (orders = []) => {
		let list = this.state.list;
		orders.push(...list);
		orders = uniqBy(orders, o => o.order_id);
		this.setState({list: orders});
	};
	
	
	day = () => this.props.day || this.state.day;
	handleSort = value => () => this.setState({query: isInteger(value) ? SELECTIONS[value].key : value});
	fetch = () => {
		// this.props.load(this.day(), message);
		// noinspection JSUnresolvedFunction
		this.props.miniLoad(this.day()).then(result => this.setState({list: result.orders})).catch(e => console.error(e));
	};
	
	showInfoEdit = (show_info_edit) => () => this.setState({show_info_edit});
	setInfoPortal = info_edit_portal => this.setState({info_edit_portal});
	
	showOrder = order_id => () => this.setState({order_id});
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const prevDay = prevProps.day || prevState.day;
		if (!moment(this.day()).isSame(prevDay, "day")) {
			this.fetch();
		}
	}
	
	
	basicFilter = (orders, day, query) => {
		day = moment(day || this.day());
		query = query || this.state.query;
		orders = orders.filter(o => !o.deleted_at && moment(o[query]) <= day.endOf('day'));
		if (!moment().isSame(day, 'day')) {
			orders = orders.filter(o => moment(o[query]).isSame(day, 'day'));
		}
		return orders;
	};
	
	prepareData = memoize(
		(day, query, orderList) => {
			day = moment(day);
			let orders = this.basicFilter(orderList, day, query).sort((a, b) => moment(a[query]).toDate() < moment(b[query]).toDate() ? -1 : 1);
			orders = orders.map((o, i) => {
				return {
					...o,
					id: o.order_id,
					start: new Date(o[query]),
					end: moment(o[query]).add(15, "minutes").toDate(),
					position: i
				}
			});
			return orders;
		}
	);
	
	processFilters = memoize(
		(orderMap, orderList, {delivered, progress, finished, affirmed, post_processing, post_processed, fetched, is_waiting, is_delivery_service, is_consultant, has_wheel}, consultantFilter) => {
			const filter = ({order_id,  delivered_at, progress: progressing, finished_at, affirmed_at, post_processing: processing, post_processed_at, fetched_at, consultant_id, waiting, delivery_service, wheel_id}) => {
				// if (!isOrder) {
				// 	return true;
				// }
				return (
					(isBoolean(delivered) ? Boolean(delivered_at) === delivered : true)
					&&
					(isBoolean(progress) ? (Boolean(progressing) === progress || Boolean(finished_at) === finished) : true)
					&&
					(isBoolean(finished) ? Boolean(finished_at) === finished : true)
					&&
					(isBoolean(affirmed) ? Boolean(affirmed_at) === affirmed : true)
					&&
					(isBoolean(post_processing) ? Boolean(processing) === post_processing : true)
					&&
					(isBoolean(post_processed) ? Boolean(post_processed_at) === post_processed : true)
					&&
					(isBoolean(fetched) ? Boolean(fetched_at) === fetched : true)
					&&
					(isBoolean(is_waiting) ? Boolean(waiting) === is_waiting : true)
					&&
					(isBoolean(is_delivery_service) ? Boolean(delivery_service) === is_delivery_service : true)
					&&
					(isBoolean(is_consultant) ? Boolean(get(orderMap, [order_id, 'consultant_id'], false)) === is_consultant : true)
					&&
					(isBoolean(has_wheel) ? Boolean(has_wheel) === Boolean(wheel_id) : true)
					&&
					(!consultantFilter || consultantFilter === consultant_id)
				);
			};
			
			return orderList.filter(filter);
		}
	);
	
	toToday = (date) => {
		const today = moment();
		return moment(date).year(today.year()).month(today.month()).date(today.date());
	};
	
	filteredSchedule = memoize(
		(schedule, day, query) => {
			if (moment(day.valueOf()).isSame(moment(), "day")) {
				return schedule.filter(s =>
					moment(day.valueOf()).isSame(moment(s[query]), "day") || (moment(s[query]).isBefore(moment().startOf("day")) && !s.finished_at && !s.fetched_at)
				);
			} else {
				return schedule.filter(s => moment(s[query]).isSame(moment(day.valueOf()), "day"));
			}
		}
	);
	
	
	emptyTimeline = memoize(
		() => {
			const today = moment();
			const it = today.clone().startOf("day").add(6, "hour");
			const end = today.clone().endOf("day").add(-2, "hour");
			const list = [];
			while (it.isBefore(end)) {
				list.push({time: it.clone(), isOrder: false});
				it.add(1, "hour");
			}
			return [list, 0];
		}
	);
	
	fitToTimeline = memoize(
		(schedule, day, query, showPast) => {
			const date = moment(day.valueOf());
			const today = date.isSame(moment(), "day");
			const it = date.clone().startOf("day").add(6, "hour");
			const end = date.clone().endOf("day").add(-2, "hour");
			const list = [];
			const ofPast = {};
			while (it.isBefore(end)) {
				const step = it.clone().add(1, "hour");
				let foundEntries = 0;
				for (const entry of schedule) {
					if (!isObject(entry)) {
						continue;
					}
					if (!(query in entry)) {
						throw Error(`'${query}' not in order !`);
					}
					
					const timeOrigin = moment(entry[query]);
					const time = today ? this.toToday(timeOrigin) : timeOrigin;
					const isPast = !time.isSame(timeOrigin);
					if (isPast && !showPast) {
						ofPast[entry.order_id] = 1;
						continue;
					}
					// const time =  timeOrigin;
					if (time.isSame(it) || time.isBetween(it, step)) {
						!foundEntries && !time.isSame(it) && list.push({time: it.clone(), isOrder: false});
						// do some magic
						entry.time = timeOrigin;
						entry.of_past = isPast;
						if (entry.of_past) {
							ofPast[entry.order_id] = 1;
						}
						foundEntries++;
						entry.isOrder = true;
						list.push(entry);
					}
				}
				if (!foundEntries) {
					list.push({time: it.clone(), isOrder: false});
				}
				it.add(1, "hour");
			}
			return [list, values(ofPast).length];
		}
	);
	
	setDate = day => this.setState({day}, this.fetch);
	
	toggleShowPast = () => this.setState(state => ({
		show_past: !state.show_past
	}));
	
	hasInProgress = memoize((events, show_past) => {
		// let hasInProgress = false;
		// for (const order of events) {
		// 	if (order.isOrder && order.progress) {
		// 		hasInProgress = true;
		// 		break;
		// 	}
		// }
		return events.filter(o => show_past ? true : !o.of_past).some(o => o.isOrder && o.progress);
		// return hasInProgress;
	});
	
	
	render() {
		let {
			// t,
			loading,
			rights: {
				mayRead,
				mayChange
			},
			hideHeaders,
			stateFilters,
			working,
			workers,
			// map,
			vehicleMap,
			orderMap,
			consultantFilter
		} = this.props;
		
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		const {
			order_id: showOrderDetailId,
			query,
			show_past,
			list: orderList,
			show_info_edit,
		} = this.state;
		
		const day = this.day();
		const isToday = moment(day).isSame(moment(), "day");
		const direction = "descending";
		let numberOfPastEvents, hasInProgress = false;
		
		let events = this.prepareData(day, query, orderList);
		events = this.filteredSchedule(events, day, query);
		events = this.processFilters(orderMap, events, stateFilters, consultantFilter);
		[events, numberOfPastEvents] = this.fitToTimeline(events, day, query, true);
		hasInProgress = this.hasInProgress(events, show_past);
		return (
			<div>
				{isToday &&
				<KeyModifier ctrl={false} shift={false} alt={false}>
					<KeyHandler
						keyValue={"v"}
						onKeyHandle={this.toggleShowPast}
					/>
				</KeyModifier>
				}
				{!hideHeaders &&
				<ActionHeader alignment={"center"} margin={0}>
					<ActionHeaderGroup>
						<DayPicker loading={loading} day={day} onChange={this.setDate} floatingLabelText={`Terminkalender (${this.orders})`}/>
					</ActionHeaderGroup>
				</ActionHeader>
				}
				<Segment basic style={{paddingLeft: 0, paddingRight: 0}}>
					<div id={"order-calendar-v2"} className={cn("schedule-view", {"show-past": show_past})}>
						<Table sortable celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} disabled collapsing>
										<Notification onClick={this.toggleShowPast}
										              count={numberOfPastEvents}
										              display={show_past}/>
									</Table.HeaderCell>
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} disabled>Kunde <span
										style={{fontSize: "75%", fontWeight: "normal"}}>Service</span></Table.HeaderCell>
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} disabled>Fahrzeug</Table.HeaderCell>
									{hasInProgress &&
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} disabled>Akt. Mitarbeiter</Table.HeaderCell>
									}
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} textAlign={"center"}
									                  sorted={query === SELECTIONS[0].key ? direction : null} onClick={this.handleSort(0)}>Abgabe</Table.HeaderCell>
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} textAlign={"center"}
									                  sorted={query === SELECTIONS[1].key ? direction : null} onClick={this.handleSort(1)}>Abholung</Table.HeaderCell>
									<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 47}} disabled collapsing/>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{events.map(order => {
									if (!order.isOrder) {
										return (
											<Table.Row key={order.time} className={"empty-time-slot"}>
												<Table.Cell verticalAlign={"top"} collapsing>{moment(order.time).format("HH:mm")}</Table.Cell>
												<Table.Cell colSpan={hasInProgress ? 6 : 5}>{order.title}</Table.Cell>
											</Table.Row>
										);
									}
									// const originalOrder = map.orders[order.order_id] || {};
									const serviceList = values(order.services);
									const serviceIds = serviceList.map(s => s.order_service_id);
									let worker = working[order.order_id] || [];
									worker = worker.map(work => ({...work, worker: workers[work.workers_id]}));
									if (!show_past && order.of_past) {
										return null;
									}
									const realOrder = orderMap[order.order_id] || order;
									return (
										<Table.Row key={`${order.order_id}-${order.time}`} negative={/*serviceList.length === 0*/ false} className={cn("schedule-row", {"of-past": order.of_past})}>
											{/* CLOCK TIME */}
											<Table.Cell verticalAlign={"top"} collapsing>
												{order.of_past && <div><small>{moment(order.time).format("D.MM.")}</small></div>}
												<div><strong>{moment(order.time).format("HH:mm")}</strong></div>
												{realOrder.delivery_service && <EasyFlex align={EasyFlex.align.CENTER} style={{marginTop: 8}}>
													<MobileAwarePopup
														inverted
														content={'Bring- und Abholservice'}
														trigger={<IconSwapHoriz color={COLORS.SEMANTIC_BLUE}/>}
													/>
												</EasyFlex>}
												{realOrder.waiting && <EasyFlex align={EasyFlex.align.CENTER} style={{marginTop: 8}}>
													<MobileAwarePopup
														inverted
														content={'Warteauftrag'}
														trigger={<IconPerson color={COLORS.SEMANTIC_RED}/>}
													/>
												</EasyFlex>}
												<Vehicle.Connected id={order.vehicle_id}>{_ => {
													const loanCar = order.loan_car_id && vehicleMap[order.loan_car_id];
													return trueNull(loanCar) && <EasyFlex align={EasyFlex.align.CENTER} style={{marginTop: 8}}><MobileAwarePopup
														content={<span>Leihfahrzeug erforderlich <div style={{color: COLORS.SEMANTIC_ORANGE, textAlign: "right", marginTop: 8}}><strong
															className={"monospace"}
															style={{background: COLORS.SECONDARY, padding: 8, borderRadius: 4}}>{loanCar.registration_mark}</strong></div></span>} inverted
														trigger={<IconCar/>}/></EasyFlex>;
												}}</Vehicle.Connected>
											</Table.Cell>
											
											{/* CLient & Services */}
											<Table.Cell verticalAlign={"top"}>
												<Client.Connected id={order.client_id} placeholder={<Placeholder style={{minWidth: 200}}><strong>Kunde</strong></Placeholder>}>{client =>
													<React.Fragment>
														<ClientPopup modal popupProps={{style: {backgroundColor: 'transparent'}}} client={client}>
															<Flex align={"space-between"} valign={"center"}>
																<strong>{client.contact_full}</strong>
																{!isMobile && <em style={{textAlign: "right"}}><Icon name={"phone"} size={"small"}/>{client.phone_full || 'n.v.'}</em>}
															</Flex>
														</ClientPopup>
														{!isMobile && client.email &&
														<Flex align={"flex-end"} valign={"center"}>
															<em style={{textAlign: "right"}}><Icon name={"mail"} size={"small"}/> {client.email}</em>
														</Flex>
														}
													
													</React.Fragment>
												}</Client.Connected>
												
												<div style={{height: 12}}/>
												{trueNull(!serviceList.length) &&
												<Message color={"grey"} style={{fontWeight: "bold", textAlign: "center", padding: 5}}>Keine Service zugewiesen !!</Message>
												}
												<OrderService.ConnectedList ids={serviceIds}>{services => {
													const missing = !services.every(Boolean);
													let workTime;
													try {
														workTime = missing ? 0 : round(services.reduce((carry, service) => carry + Number(service ? service.hours_of_work_resource : 0), 0), 1);
													} catch (e) {
														workTime = -1;
													}
													return (
														<React.Fragment>
															{services.map((service, i) =>
																!service ?
																	<Placeholder key={`${order.order_id}-order-service-${i}`} style={{minWidth: 200, marginTop: 5}}>Service {i + 1}</Placeholder>
																	:
																	<Flex className={cn("service-info", {single: serviceList.length === 1})} valign={"center"} align={"space-between"}
																	      key={`${order.order_id}-order-service-${service.order_service_id}`}>
																		<Flex valign={"center"}>
																			<ReleaseIcon childStyle={{flexShrink: 0}} id={service.order_service_id}/>
																			{service.service_name}&nbsp;&nbsp;&nbsp;&nbsp;{service.paused_by ? <Popup inverted content={
																			<ConnectedWorkerAvatarView workerId={service.paused_by}>Pausiert</ConnectedWorkerAvatarView>
																		} trigger={<IconPauseCircleOutline style={{width: 20, height: 20}} color={COLORS.SEMANTIC_RED}/>}/> : null}</Flex>
																		<div style={{fontWeight: 'bold'}}>{round(service.hours_of_work_resource, 1)}</div>
																	</Flex>
															)}
															{trueNull(!missing && services.length > 1) &&
															<Flex className={"service-summary"} valign={"center"}
															      align={"flex-end"}>
																{/*<div>{serviceList.length} Service insg.</div>*/}
																<div style={{whiteSpace: "nowrap", fontWeight: 'bold'}}>= {workTime}</div>
															</Flex>
															}
														</React.Fragment>
													);
												}}</OrderService.ConnectedList>
											
											</Table.Cell>
											
											{/* Vehicle & Workingstate */}
											<Table.Cell verticalAlign={"top"}>
												<Vehicle.Connected id={order.vehicle_id} placeholder={
													<React.Fragment>
														<Placeholder style={{minWidth: 200}}><strong>Fahrzeug</strong></Placeholder>
														<Placeholder style={{minWidth: 150, marginTop: 10, marginBottom: 10}}>Kennzeichen</Placeholder>
													</React.Fragment>
												}>{pv => {
													return <React.Fragment>
														<Flex align={"space-between"} valign={"center"}>
															<VehiclePopup modal vehicle={pv}><strong>{pv.name}</strong></VehiclePopup>
															<div/>
														</Flex>
														<Flex align={"space-between"} valign={"center"}>
															{falseNull(isMobile) && <div>Kennzeichen</div>}
															{/*{pv.registration_shield || <span>k.A.</span>}*/}
															{/*<span className={"monospace"}>{order.vehicle.registration_mark}</span>*/}
															<RegistrationMark style={{transform: "scale(0.68)", transformOrigin: isMobile ? 'left top' : "right top"}}>{pv.registration_mark || 'k.A.'}</RegistrationMark>
														</Flex>
													</React.Fragment>;
												}}</Vehicle.Connected>
												<Order.Connected id={order.order_id} placeholder={<OrderState order={order}/>}>{connectedOrder =>
													<OrderState order={connectedOrder}/>
												}</Order.Connected>
												
												<Order.Connected id={order.order_id} placeholder={order.info ? <div style={{marginTop: 8}}>
													<div><span style={{textDecoration: "underline"}} className={trueNull(mayChange) && 'cursor pointer'} onClick={trueNull(mayChange) && this.showInfoEdit(order)}>Info:</span></div>
													<div style={{fontSize: "smaller"}}>{order.info}</div>
												</div> : null}>{o =>
													 <div style={{marginTop: 8}}>
														<div><span style={{textDecoration: "underline"}} className={trueNull(mayChange) && 'cursor pointer'} onClick={trueNull(mayChange) && this.showInfoEdit(o)}>Info:</span></div>
														 {o.info && <div style={{fontSize: "smaller"}}>{o.info}</div>}
													</div>
												}</Order.Connected>
											</Table.Cell>
											
											{/* working progression */}
											{hasInProgress &&
											<Table.Cell textAlign={worker.length ? 'left' : 'center'}>
												{worker.length ? worker.map(work => {
													if (!work.worker) return null;
													return (
														<ConnectedWorkerAvatarWorkingView vertical={isMobile} inline key={work.work_id} workerId={work.workers_id} since={work.start_point}/>
													);
												}) : <div>-</div>}
											</Table.Cell>
											}
											
											{/* Delivery time */}
											<Table.Cell textAlign={"center"} className={cn({"greyed-out": this.state.query !== "deliver_point"})}>
												{order.delivered_at ? moment(order.delivered_at).format(moment(order.delivered_at).isSame(day, 'year') ? "DD.MM. LT" : "L LT") : "-"}
											</Table.Cell>
											
											{/* Fetch time */}
											<Table.Cell textAlign={"center"} className={cn({"greyed-out": this.state.query !== "fetch_point"})}>{moment(order.fetch_point).format(moment(order.fetch_point).isSame(day, 'year') ? "DD.MM. LT" : 'L LT')}</Table.Cell>
											
											{/* Action Buttons */}
											<Table.Cell textAlign={"center"} collapsing>
												<Segment.Group compact style={{boxShadow: 'none', border: 'none'}}>
													{trueNull(realOrder.consultant_id) &&
													<Worker.Connected id={realOrder.consultant_id}>{worker =>
														<Segment>
															<Popup position={'left center'} inverted content={`Serviceberater: ${worker.name}`} trigger={
																<Image wrapped src={worker.avatar} avatar/>
															}/>
														</Segment>
													}</Worker.Connected>}
													<Segment><Button
														circular
														icon={"list"}
														onClick={this.showOrder(order.order_id)}>
														{/*{order.order_id}*/}
													</Button></Segment>
												</Segment.Group>
											</Table.Cell>
										</Table.Row>
									);
									
								})}
							</Table.Body>
						</Table>
					</div>
				</Segment>
				<OrderViewDialog open={showOrderDetailId !== 0} onClose={this.showOrder(0)}>
					{showOrderDetailId > 0 && <OrderQuickView order_id={showOrderDetailId} onRequestClose={this.showOrder(0)}/>}
				</OrderViewDialog>
				<Modal open={show_info_edit !== null}>
					<Modal.Header><Trans defaults={'Auftrafsinformationen editieren'}/></Modal.Header>
					<Modal.Content>
						{trueNull(show_info_edit) && <ConnectedOrderInfoUpdateView order_id={show_info_edit.order_id} info={show_info_edit.info} id={"order-update-info-in-schedule"} onAfterUpdate={this.showInfoEdit(null)} buttonPortal={this.state.info_edit_portal}/>}
					</Modal.Content>
					<Modal.Actions>
						<EasyFlex align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER} className={'actions-bottom-margin'}>
							<Button onClick={this.showInfoEdit(null)}><Trans defaults={'Schließen'} i18nKey={'actions.close'}/></Button>
							<EasyFlex ref={this.setInfoPortal}/>
						</EasyFlex>
					</Modal.Actions>
				</Modal>
				{/*{trueNull(showOrderDetailId) && <FullscreenPortal defaultOpen onClose={this.showOrder(0)} bottomBar={*/}
				{/*	<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.END} style={{height: 60, borderTop: '1px solid #ccc', backgroundColor: COLORS.BACKGROUND, position: 'fixed', bottom: 0, width: '100%', padding: '0 20px'}}>*/}
				{/*		<Button onClick={this.showOrder(0)}>Schließen</Button>*/}
				{/*	</EasyFlex>}*/}
				{/*>*/}
				{/*	<OrderQuickView order_id={showOrderDetailId} onRequestClose={this.showOrder(0)}/>*/}
				{/*</FullscreenPortal>}*/}
			</div>
		);
	}
}

OrderSchedule = withRights(SECTION.ORDERS, OrderSchedule);
OrderSchedule = withInit(OrderSchedule);
OrderSchedule = connect(
	(state) => {
		let working = {};
		for (const work of values(state.workers.working)) {
			working[work.order_id] = [...(working[work.order_id] || []), work];
		}
		return {
			loading: isLoading(state, PROC_ORDER) || isLoading(state, PROC_ORDER_DAY_LIST),
			orderMap: state.map.orders,
			serviceMap: state.kfz.list || {},
			data: state.orders,
			min_max: state.orders.min_max,
			working,
			workers: state.workers.list,
			vehicleMap: state.map.vehicles,
			stateFilters: state.session.order_state_filters,
			map: state.map
		};
	},
	(dispatch) => {
		// noinspection JSUnusedLocalSymbols
		return {
			init: () => dispatch(isOnline(() => {
				// let day = props.day;
				const [now] = getTimeSpan(0, true);
				// day = day || now;
				// dispatch(orderAction__get(day, day));
				dispatch(kfzAction__fetchServices());
				dispatch(loadWorkers());
				dispatch(workerAction__getActivities(now));
				dispatch(loanCarAction__fetch());
			})),
			goTo: link => dispatch(push(link)),
			onRefresh: (starts = null, ends = null, callback) => {
				dispatch(workerAction__getActivities(starts.toISOString()));
				// dispatch(orderAction__get(starts, ends, true, result => {
				// 	isFunction(callback) && callback(result);
				// 	dispatch(addSnackbar('Termine wurden geladen'));
				// }));
			},
			load: (day, message, callback) => dispatch(orderAction__get(day, null, true, result => {
				isFunction(callback) && callback(result);
				message && dispatch(addSnackbar('Termine wurden geladen'));
			})),
			miniLoad: (day) => dispatch(orderAction__listDay(day, true))
		}
	}
)(OrderSchedule);
OrderSchedule = translate()(OrderSchedule);
export {OrderSchedule};