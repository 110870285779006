/* eslint-disable */
import * as React from 'react';
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {connect} from "react-redux";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";

export let AssistProvder = ({list, user, children}) => {
	const getOrder = memoizeOne(
		(user, list, method) => method(list.find(o => o.post_processing === user.workers_id), list.filter(o => !o.post_processing).length, list)
	);
	return getOrder(user, list, children);
};
AssistProvder = withRights(SECTION.ASSISTANCE, AssistProvder);
AssistProvder = connect(
	state => ({
		list: state.orders.processable
	})
)(AssistProvder);
