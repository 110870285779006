import _objectSpread from "/mnt/e/carperto/haendler/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _defineProperty from "/mnt/e/carperto/haendler/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

// import "babel-polyfill";
try {
  require('babel-polyfill');
} catch (e) {// console.info('Babel polyfill could not be loaded...', e);
}

var updateInterval = 1000 * 60 * 5;
var EXTEND_STRING = 'last_update_timestamp_';
var DEFAULT_STRING = 'last_update_timestamp';

var getUpdateCacheKey = function getUpdateCacheKey() {
  var extend = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return extend ? EXTEND_STRING + extend : DEFAULT_STRING;
};

var updateCache = function updateCache() {
  var extend = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var key = getUpdateCacheKey(extend);
  return _defineProperty({}, key, new Date().getTime());
};

var updateCacheData = function updateCacheData() {
  for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
    values[_key] = arguments[_key];
  }

  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var key = [DEFAULT_STRING].concat(values).join('_');

    if (state === null) {
      return _defineProperty({}, key, new Date().getTime());
    }

    if (typeof state === 'object') {
      if (state[key]) {
        return _defineProperty({}, key, new Date().getTime());
      }
    } else if (state) {
      return _defineProperty({}, key, new Date().getTime());
    }

    return {};
  };
};

var updateFetch = function updateFetch(object) {
  var extend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return _objectSpread({}, object, updateCache(extend));
};

var shouldUpdate = function shouldUpdate(object) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : updateInterval;
  return !object || !object.last_update_timestamp || range + object.last_update_timestamp < new Date().getTime();
};

var shouldUpdateExtend = function shouldUpdateExtend(object, extend) {
  var range = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : updateInterval;
  var key = getUpdateCacheKey(extend);
  return !object || !object[key] || range + object[key] < new Date().getTime();
};

var async = function async() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return new Promise(function (resolve) {
    var ident = setTimeout(function () {
      return resolve(ident);
    }, duration);
  });
};

var setUpdateInterval = function setUpdateInterval(value) {
  return updateInterval = value;
};

var isCached = function isCached(sourceObject) {
  for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }

  return function () {
    var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : updateInterval;
    var key = [DEFAULT_STRING].concat(args).join('_');
    return Boolean(sourceObject && sourceObject[key] && cacheTime + sourceObject[key] > new Date().getTime());
  };
};

export { updateCache, updateFetch, shouldUpdate, shouldUpdateExtend, setUpdateInterval, getUpdateCacheKey, isCached, async, updateCacheData };