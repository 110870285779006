import React from 'react';
import {dismissDialog} from "../../actions/dialogActions";
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import DialogButton from "./DialogButton";

let __dialogZindex = 100000;


let DialogRootView = ({
	                        t,
	                        dismiss,
	                        dispatch,
	                        className,
	                        children,
							onDismiss,
	                        head,
	                        style,
							cancelText,
							beforeCancel,
							afterCancel
}) => (
	<div className={`${className} component__dialog`} style={{...style, zIndex: __dialogZindex++}} >
		<div className="dialog__modal">
			{head && <div className="dialog__head">{head}</div>}
			<div className="dialog__body">{children}</div>
			
			<div className="dialog__footer">
				{beforeCancel}
				{dismiss && <DialogButton onClick={(e, call) => onDismiss ? onDismiss() : call(dismissDialog())}>{t(cancelText)}</DialogButton>}
				{afterCancel}
			</div>
		</div>
	</div>
);

DialogRootView.contextTypes = {
	onDismiss: PropTypes.func
};

DialogRootView.propTypes = {
	dismiss: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	head: PropTypes.node,
	style: PropTypes.object,
	type: PropTypes.oneOf([null, 'sheet']),
	beforeCancel: PropTypes.node,
	afterCancel: PropTypes.node,
	onDismiss: PropTypes.func
};

DialogRootView.defaultProps = {
	dismiss: true,
	className: '',
	onDismiss: null,
	head: null,
	style: {}
	
};

DialogRootView.defaultProps = {
	dismiss: true,
	className: '',
	head: null,
	style: {},
	type: null,
	cancelText: 'actions.cancel',
	beforeCancel: null,
	afterCancel: null
};

export default translate()(DialogRootView);