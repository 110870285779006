import React from 'react';
import FontAwesome from 'react-fontawesome';
import {connect} from 'react-redux';
import {showMenu, toggleMenu} from "../../actions/menuActions";
import PropTypes from 'prop-types';


class NavigationToggle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: true
		};
		this.handleResize = this.handleResize.bind(this);
	}
	
	handleResize = () => {
		const {clientWidth} = window.document.body;
		this.setState({visible: clientWidth < 1024});
	};
	
	componentDidMount() {
		window.addEventListener('resize', this.handleResize, false);
		this.handleResize();
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize, false);
	}
	
	render() {
		const {open = false, className = null, onShow, ...props} = this.props;
		const {visible} = this.state;
		if ( !visible ) {
			return null;
		}
		return (
			<button className={['menu-toggle unselectable', className, open ? 'open' : 'close'].join(' ')} {...props} type="button" onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				onShow(!open);
			}}><FontAwesome name={open ? 'close' : 'bars'}/></button>
		);
	}
}



const mapStateToProps = (state, ownProps) => ({
	open: state.menu,
	has_errors: state.errors.length
});
const mapDispatchToProps = (dispatch, props) => ({
	onShow: (visible) => dispatch(showMenu(visible)),
	onToggle: () => dispatch(toggleMenu())
});

let NavigationToggleController =  connect(mapStateToProps, mapDispatchToProps)(NavigationToggle);

NavigationToggleController.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string
};
export default NavigationToggleController;