import {MAP_ACTIONS} from "./types";
import localForage from "localforage";

export const mapState__init = (sessionState) => ({type: MAP_ACTIONS.INIT, sessionState});

export const mapState__orders_add = orders => ({type: MAP_ACTIONS.ORDERS, orders});
export const mapState__orders_remove = orders => ({type: MAP_ACTIONS.ORDERS_REMOVE, orders});

export const mapState__releases_add = releases => ({type: MAP_ACTIONS.RELEASES, releases});
export const mapState__releases_remove = releases => ({type: MAP_ACTIONS.RELEASED_REMOVE, releases});

export const mapState__orderServices_add = order2services => ({type: MAP_ACTIONS.ORDER_SERVICES, order2services});
export const mapState__orderServices_remove = order2services => ({type: MAP_ACTIONS.ORDER_SERVICES_REMOVE, order2services});

export const mapState__orderResources_add = order2service2resources => ({type: MAP_ACTIONS.ORDER_SERVICE_RESOURCES, order2service2resources});
export const mapState__orderResources_remove = order2service2resources => ({type: MAP_ACTIONS.ORDER_SERVICE_RESOURCES_REMOVE, order2service2resources});

export const mapState__work_add = work => ({type: MAP_ACTIONS.WORK, work});
export const mapState__work_remove = work => ({type: MAP_ACTIONS.WORK_REMOVE, work});

export const mapState__clients_add = clients => ({type: MAP_ACTIONS.CLIENTS, clients});
// noinspection JSUnusedGlobalSymbols
export const mapState__clients_remove = clients => ({type: MAP_ACTIONS.CLIENTS_REMOVE, clients});

export const mapState__vehicles_add = vehicles => ({type: MAP_ACTIONS.VEHICLES, vehicles});
export const mapState__vehicles_remove = vehicles => ({type: MAP_ACTIONS.VEHICLES_REMOVE, vehicles});

export const mapState__workers_add = workers => ({type: MAP_ACTIONS.WORKERS, workers});
export const mapState__workers_remove = workers => ({type: MAP_ACTIONS.WORKERS_REMOVE, workers});
export const mapState__workers_logout = () => ({type: MAP_ACTIONS.WORKERS_LOGOUT});

export const mapState__orderVehicles__add = order2vehicles => ({type: MAP_ACTIONS.ORDER_VEHICLES, order2vehicles});
export const mapState__orderVehicles__remove = order2vehicles => ({type: MAP_ACTIONS.ORDER_VEHICLES_REMOVE, order2vehicles});

export const mapState__services_add = services => ({type: MAP_ACTIONS.SERVICES, services});
export const mapState__services_remove = services => ({type: MAP_ACTIONS.SERVICES_REMOVE, services});

export const mapState__loanCars_add = loan_cars => ({type: MAP_ACTIONS.LOAN_CAR, loan_cars});
export const mapState__loanCars_remove = loan_cars => ({type: MAP_ACTIONS.LOAN_CAR_REMOVE, loan_cars});

export const mapState__kfz_add = kfz => ({type: MAP_ACTIONS.KFZ, kfz});
export const mapState__kfz_remove = kfz => ({type: MAP_ACTIONS.KFZ_REMOVE, kfz});

export const mapState__kfzModel_add = kfz_model => ({type: MAP_ACTIONS.KFZ_MODELS, kfz_model});
export const mapState__kfzModel_remove = kfz_model => ({type: MAP_ACTIONS.KFZ_MODELS_REMOVE, kfz_model});

export const mapState__resources_add = resources => ({type: MAP_ACTIONS.RESOURCES, resources});
// noinspection JSUnusedGlobalSymbols
export const mapState__resources_remove = resources => ({type: MAP_ACTIONS.RESOURCES_REMOVE, resources});

export const mapState__wheels_add = wheels => ({type: MAP_ACTIONS.WHEELS, wheels});
export const mapState__wheels_remove = wheels => ({type: MAP_ACTIONS.WHEELS_REMOVE, wheels});


export const mapState__multi = (holder, mapping = {}) => ({type: MAP_ACTIONS.MULTI, holder, mapping});



export const mapDispatch = (dispatch, holder) => {
	dispatch(mapState__multi(holder));
};

export const mapAction__init = () => async (dispatch) => {
	localForage.config();
	if (!sessionStorage.getItem('check_session')) {
		await localForage.clear();
		sessionStorage.setItem('check_session', 'ok');
	}
	const query = ['vehicles', 'orders', 'clients', 'releases', 'workers', 'resources', 'services', 'work'];
	let state = await Promise.all(query.map(async index => localForage.getItem( MAP_ACTIONS.INDEX(index))));
	state = state.map(value => typeof value === 'string' ? JSON.parse(value || '{}') : {});
	const sessionState = state.reduce((total, data, k) => {
		const key = query[k];
		return {
			...total,
			[key]: {
				...total[key],
				...data
			}
		}
	}, {});
	dispatch(mapState__init(sessionState));
	
	console.debug('mapAction__init(): ', state, sessionState);
};