// eslint-disable
import * as React from "react";
import {Button, Message, Segment} from "semantic-ui-react";
import OrderQuickView from "../../cointainer/intern/orders/OrderQuickView";
import {OrderViewDialog} from "../../Tools/Dialog/Dialogs";
import {OrderQuickService} from "../../cointainer/intern/orders/quick";

export class OrderInfoTest extends React.Component {
	state = {
		order_id: 3099,
		show: true,
		popup: false
	};
	render() {
		const {order_id, show, popup} = this.state;
		try {
			return(
				<Segment basic>
					<form onSubmit={e => {
						this.setState({show: true});
						e.preventDefault();
						
					}}>
						<div><label htmlFor="order-id">Order ID</label></div>
						<input id={'order-id'} type="number" value={order_id} onChange={e => this.setState({order_id: e.target.value})}/>
						<Button type={"submit"} positive>Zeige</Button>
						<Button type={"button"} onClick={e => this.setState({popup: true})}>Popup</Button>
					</form>
					<OrderViewDialog key={"order-dialog"} open={popup} onClose={e => this.setState({popup: false})}>
						{popup && <OrderQuickView order_id={order_id} onRequestClose={e => this.setState({popup: false})}/>}
					</OrderViewDialog>
					{show &&
						[
							<Segment key={1}>
								<OrderQuickService order_id={order_id}/>
							</Segment>,
							
						]
					}
				</Segment>
			);
		} catch (e) {
			console.error(e);
			return(
				<Message negative>{e.message}</Message>
			);
		}
	}
}