import React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import CarhouseDetail from "./CarhouseDetail";
import {PageHeader, SearchFilter, Tile, Tiles, Sortable} from "ah-tools";
import {compareDefault, compare} from "ith-compare";
import {sortDefault, sort} from "ith-sort";
import RepairImage from '../../images/repair-shop.png';
import {IMAGE_URL} from "../../Logic/constants";
import {Segment} from "semantic-ui-react";


const searchOptions = [
	{key: 'name', text: 'Name', value: 'name'}
];

const sortOptions = [
	{key: 'name-asc', order: 'ascending', title: 'Name aufsteigend'},
	{key: 'name-desc', order: 'descending', title: 'Name absteigend'}
];

let filterTrans = false, sortTrans = false;


class CarhouseView extends React.Component {
	static propTypes = {
		houses: PropTypes.arrayOf(PropTypes.object).isRequired,
		onDetail: PropTypes.func.isRequired
	};
	static defaultProps = {
		houses: [],
		onDetail: (param) => alert('onDetail() is not implemented yet: ' + param)
	};
	
	state = {
		searchBy: searchOptions[0].key,
		searchText: '',
		sort: sortOptions[0].key
	};
	constructor(props) {
		super(props);
		if ( props.t ) {
			if (!sortTrans) {
				sortOptions.forEach(opt => {
					opt.title = props.t(`sort.${opt.key}`);
				});
				sortTrans = true;
			}
			
			if (!filterTrans) {
				searchOptions.forEach((opt => {
					opt.text = props.t(`filter.${opt.key}`);
				}));
				filterTrans = true;
			}
		}
	}
	
	componentDidMount() {
		const {onLoad} = this.props;
		onLoad && onLoad();
	}
	
	static findHouse(houses = [], id) {
		for(let k in houses) {
			if ( houses[k].link === id ) {
				return houses[k];
			}
		}
		return null;
	}
	
	filtered = () => {
		const {searchBy, searchText} = this.state;
		if ( searchText.trim() === '' ) {
			return compareDefault;
		}
		switch (searchBy) {
			case 'name':
				return compare(searchText)(house => house.name);
			default:
				return compareDefault;
		}
	};
	
	sorted = () => {
		let {sort: sortable} = this.state;
		sortable = sortable.split('-');
		const order = sortable.pop();
		sortable = sortable.join('-');
		switch(sortable) {
			case 'name':
				return sort(order)(house => house.name);
			default:
				return sortDefault;
		}
	};
	
	onDetail = ident => this.props.onDetail(ident);
	
	render() {
		let {t, houses, match: {params: {house_id = null}} } = this.props;
		const {searchBy, searchText, sort} = this.state;
		
		if ( house_id ) {
			const House = CarhouseView.findHouse(houses, house_id);
			if ( !House ) return null;
			return (
				<CarhouseDetail house={House}/>
			);
		}
		houses = houses.filter(this.filtered()).sort(this.sorted());
		return (
			<Segment basic>
				<PageHeader align={'flex-end'}>
					<Sortable
						value={sort}
						options={sortOptions}
						onChange={(e, key, sort) => this.setState({sort})}
						autoWidth
					/>
					<SearchFilter
						value={searchText}
						selected={searchBy}
						options={searchOptions}
						onChange={searchText => this.setState({searchText})}
						onSelect={searchBy => this.setState({searchBy})}
					/>
				</PageHeader>
				{houses.length ?
					<div>
						<Tiles style={{justifyContent: 'center'}}>
							{houses.map(({name, house_id, logo, link}) =>
								<Tile onClick={this.onDetail.bind(this, link)} key={house_id} width={200} height={200}
								      style={{
									      cursor: 'pointer',
									      flexDirection: 'column',
									      justifyContent: 'center',
									      alignItems: 'center'
								      }}>
									<img
										src={logo ? `${IMAGE_URL}${logo.ident}.${logo.origin_extension}?${logo.updated_at}` : RepairImage}
										style={{maxWidth: '80%', maxHeight: '80%', flexGrow: 100, margin: 'auto'}}
										alt={name}/>
									<strong>{name}</strong>
								</Tile>
							)}
						</Tiles>
					</div>
					:
					<div>
						{t('houses.no-houses-in-list')}
					</div>
				}
			</Segment>
		);
	}
}



export default translate()(CarhouseView);