import * as React from 'react';
import {setHours, setMinutes, startOfMinute} from "date-fns";
import {FORMAT_DATE_DEFAULT, withMaterial} from "../../../../Tools/DatePicker";
import DatePicker from "react-datepicker";
import {translate} from "react-i18next";

export const pipeDateFunc = (callable) => date => callable(date ? startOfMinute(date) : date);

export const dateTime = (date) => date ? date.valueOf() : 0;

const MaterialPicker = withMaterial(DatePicker);
export let DefaultPicker = ({t, tReady, i18n, ...props}) => (
	<MaterialPicker
		popperClassName={'material-picker-max-content'}
		floating
		showTimeSelect
		timeFormat={'HH:mm'}
		timeCaption={'Uhrzeit'}
		dateFormat={FORMAT_DATE_DEFAULT + ' - HH:mm'}
		minTime={setHours(setMinutes(new Date(), 0), 5)}
		maxTime={setHours(setMinutes(new Date(), 0), 20)}
		{...props}
	/>
);
DefaultPicker = translate()(DefaultPicker);