import * as React from 'react';
import {Dialog} from "material-ui";

const FullScreen = ({children, ...props}) => {
	return(
		<Dialog
			open
			className={'full-screen'}
			bodyClassName={'full-screen-body'}
			overlayStyle={{paddingTop: 0}}
			bodyStyle={{height: '100vh', background: 'red', maxHeight: '100vh', padding: 0}}
			contentStyle={{width: '100%', maxWidth: '100%', height: '100vh', maxHeight: '100vh', top: 0, transform: null}}
			{...props}>
			{children}
		</Dialog>
	);
};



export default FullScreen;