import * as React from "react";
import User from "../../../models/User";
import {Flex} from "../../../components/partials/ActionHeader";
import {Image} from "semantic-ui-react";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {loadWorkers} from "../../../actions/workerActions";
import PropTypes from "prop-types";
import Countdown, {renderSimple} from "../../../components/partials/Countdown";

export const WorkerAvatarView = ({worker, children, imageProps,  containerProps}) => {
	if (!worker) {
		return children;
	}
	const user = new User(worker);
	return <Flex valign={"center"} {...containerProps}>
		<Image avatar src={user.getAvatar()} onError={e => e.target.src = user.avatar_gender} {...imageProps}/>
		<div>{user.getName()}</div>
	</Flex>;
};

export const WorkerAvatarWorkingView = ({worker, since, children, inline, vertical, ...props}) => {
	if (!worker) {
		return children;
	}
	if (!since) {
		return <WorkerAvatarView worker={worker} children={children} {...props}/>;
	}
	const user = new User(worker);
	since = since instanceof Date ? since : new Date(since);
	let style = props.style;
	if (vertical) {
		style = {
			...style,
			writingMode: "vertical-rl",
			textOrientation: "mixed"
		};
	}
	return (
		<Flex valign={"center"} style={style} >
			<Image avatar src={user.getAvatar()} onError={e => e.target.src = user.avatar_gender}/>
			<Flex direction={"column"} style={{position: "relative"}} grow={1}>
				<div style={{position: "relative", top: 3, width: "100%", whiteSpace: inline ? 'nowrap' : 'inherit'}}>{user.getName()}</div>
				<small style={{position: "relative", top: -3, textAlign: vertical ? 'center' : "left", width: "100%", paddingLeft: vertical ? null : 15}}><Countdown render={renderSimple} targetDate={since}/></small>
			</Flex>
		</Flex>
	);
};
WorkerAvatarWorkingView.defaultProps = {
	rotate: 0
};

const decorate = (Component) => {
	let Decorator = withInit(Component);
	return connect(
		(state, props) => ({
			worker: state.map.workers[props.workerId] || state.workers.list[props.workerId]
		}),
		(dispatch) => ({
			init: () => {
				dispatch(loadWorkers());
			}
		})
	)(Decorator);
};


const Connector = decorate(WorkerAvatarView);
const WorkingConnector = decorate(WorkerAvatarWorkingView);

export class ConnectedWorkerAvatarView extends React.Component {
	static propTypes = {
		workerId: PropTypes.number.isRequired
	};
	render() {
		return <Connector {...this.props}/>;
	}
}

export class ConnectedWorkerAvatarWorkingView extends React.Component {
	static propTypes = {
		workerId: PropTypes.number.isRequired,
		since: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.number,
			PropTypes.string
		])
	};
	render() {
		return <WorkingConnector {...this.props}/>;
	}
}