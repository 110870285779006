import * as React from 'react';
import {ConnectedRouter} from "connected-react-router";
import history from "../../../Logic/history";
import {Route, Switch} from "react-router";
import NavLink from "../../../cointainer/partial/NavLink";
import BaseImg from '../../../images/base-data.png';
import PaymentImg from '../../../images/payment2.png';
import ResourceImg from '../../../images/human-resources.png';
import ClosedImg from '../../../images/closed.png';
import ServiceImg from '../../../images/service2.png';
import LoanCarImg from '../../../images/loan-car-5.png';
import ResourceListImg from '../../../images/resource_list.png';
import ResourceEditImg from '../../../images/list_edit.png';
import BackImg from '../../../images/left-arrow-2.png';
import ConsultantCalendarImg from '../../../images/consultant-calendar.png';
import {translate} from "react-i18next";
import MasterDataResourceController from "../../../cointainer/intern/master-data/MasterDataResourceController";
import MasterDataResourceCalendar from "./MasterDataResourceCalendar";
import MasterDataBaseController from "../../../cointainer/intern/master-data/MasterDataBaseController";
import MasterDataClosedController from "../../../cointainer/intern/master-data/MasterDataClosedController";
import MasterDataPaymentController from "../../../cointainer/intern/master-data/MasterDataPaymentController";
import MasterDataServiceController from "../../../cointainer/intern/master-data/MasterDataServiceController";
import MasterDataServiceDetailController from "../../../cointainer/intern/master-data/MasterDataServiceDetailController";
import MasterDataKfzServiceController from "../../../cointainer/intern/master-data/MasterDataKfzServiceController";
import LoanCarListController from "../../../cointainer/intern/master-data/loan-cars/LoanCarListController";
import LoanCarCreateController from "../../../cointainer/intern/master-data/loan-cars/LoanCarCreateController";
import LoanCarEditController from "../../../cointainer/intern/master-data/loan-cars/LoanCarEditController";
import MDResourceController from "../../../cointainer/intern/master-data/base/MDResourceController";
import {isOnline} from "../../../actions/userActions";
import {fetchModules} from "../../../actions/moduleActions";
import {connect} from "react-redux";
import withInit from "../../../Logic/withInit";
import {MDInterface} from "./interface/MDInterface";
import {ConnectedConsultantCalendar} from "./consultant-calendar/ConsultantCalendar";
import {DynamicWorkerScheduleView} from "../../../cointainer/intern/master-data/calendar/DynamicWorkerScheduleView";
import {withModule} from "../../../Tools/RightsProvider";
import {MasterDataResourceCalendarView} from "./MasterDataResourceCalendarView";

const wrapWorker = (Component) => ({match, history, ...props}) => <Component workerId={Number(match.params.workers_id)} {...props}/>;

const MasterDataRoutes = () => {
	return(
		<ConnectedRouter history={history}>
			<div id="view__master-data" className="view__main">
					<Switch>
						<Route exact path="/master-data/resources" component={MasterDataResource}/>
						<Route exact path="/master-data/resources/assign" component={MasterDataResourceController}/>
						<Route exact path="/master-data/resources/view" component={MDResourceController}/>
						<Route exact path="/master-data/resources/consultant-calendar" component={ConnectedConsultantCalendar}/>
						<Route exact path="/master-data/resources/dynamic" component={DynamicWorkerScheduleView}/>
						<Route exact path="/master-data/resources/dynamic/:workers_id/:resource_id?" component={wrapWorker(DynamicWorkerScheduleView)}/>
						<Route path="/master-data/resources/:workers_id/:resource_id?"  render={(props) => <MasterDataResourceCalendarView workersId={Number(props.match.params.workers_id)}/>}/>
						<Route path="/master-data/base" component={MasterDataBaseController}/>
						<Route path="/master-data/payment" component={MasterDataPaymentController}/>
						<Route path="/master-data/closed" component={MasterDataClosedController}/>
						<Route exact path="/master-data/services/:service_id/kfz/:kfz_id" component={MasterDataKfzServiceController}/>
						<Route exact path="/master-data/services/:service_id" component={MasterDataServiceDetailController}/>
						<Route exact path="/master-data/services" component={MasterDataServiceController}/>
						<Route exact path="/master-data/loan-cars/add" component={LoanCarCreateController}/>
						<Route exact path="/master-data/loan-cars/:loan_car_id" component={LoanCarEditController}/>
						<Route exact path="/master-data/loan-cars" component={LoanCarListController}/>
						<Route exact path="/master-data/interfaces" component={MDInterface}/>
						<Route exact path="/master-data" component={MasterDataDash}/>
					</Switch>
			</div>
		</ConnectedRouter>
	);
};

export default MasterDataRoutes;

let MasterDataDash = ({t}) => (
	<ul id="nav__resources">
		<li>
			<NavLink to="/master-data/base">
				<div className="link-image">
					<img src={BaseImg} alt={t('nav.master-data.base')}/>
				</div>
				<div className="link-name">{t('nav.master-data.base')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/payment">
				<div className="link-image">
					<img src={PaymentImg} alt={t('nav.master-data.payment')}/>
				</div>
				<div className="link-name">{t('nav.master-data.payment')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/services">
				<div className="link-image">
					<img src={ServiceImg} alt={t('nav.master-data.service')}/>
				</div>
				<div className="link-name">{t('nav.master-data.service')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/resources">
				<div className="link-image">
					<img src={ResourceImg} alt={t('nav.master-data.resources')}/>
				</div>
				<div className="link-name">{t('nav.master-data.resources')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/closed">
				<div className="link-image">
					<img src={ClosedImg} alt={t('nav.master-data.closed')}/>
				</div>
				<div className="link-name">{t('nav.master-data.closed')}</div>
			</NavLink>
		</li>
		{/*<li>*/}
		{/*	<NavLink to="/master-data/interfaces">*/}
		{/*		<div className="link-image">*/}
		{/*			<img src={InterfaceImg} alt={t('nav.master-data.interfaces')}/>*/}
		{/*		</div>*/}
		{/*		<div className="link-name">{t('nav.master-data.interfaces')}</div>*/}
		{/*	</NavLink>*/}
		{/*</li>*/}
		<li>
			<NavLink to="/master-data/loan-cars">
				<div className="link-image">
					<img src={LoanCarImg} alt={t('nav.master-data.loan-cars')}/>
				</div>
				<div className="link-name">{t('nav.master-data.loan-cars')}</div>
			</NavLink>
		</li>
	
	</ul>
);
MasterDataDash = withModule(MasterDataDash)

const m2s_routes = (state) => ({
	modules: state.modules.list
});

const m2d_routes = (dispatch) => ({
	init: () => dispatch(isOnline(() => {
		dispatch(fetchModules());
	}))
});

MasterDataDash = connect(m2s_routes, m2d_routes)(withInit(MasterDataDash));

let MasterDataResource = ({t}) => (
	<ul id="nav__resources">
		<li>
			<NavLink to="/master-data">
				<div className="link-image">
					<img src={BackImg} alt={t('nav.back')}/>
				</div>
				<div className="link-name">{t('nav.back')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/resources/view">
				<div className="link-image">
					<img src={ResourceListImg} alt={t('nav.master-data.resource-list')}/>
				</div>
				<div className="link-name">{t('nav.master-data.resource-list')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/resources/assign">
				<div className="link-image">
					<img src={ResourceEditImg} alt={t('nav.master-data.resource-assign')}/>
				</div>
				<div className="link-name">{t('nav.master-data.resource-assign')}</div>
			</NavLink>
		</li>
		<li>
			<NavLink to="/master-data/resources/consultant-calendar">
				<div className="link-image">
					<img src={ConsultantCalendarImg} alt={t('nav.master-data.resource-consulant')}/>
				</div>
				<div className="link-name">{t('nav.master-data.resource-consulant')}</div>
			</NavLink>
		</li>
	</ul>
);

MasterDataDash = translate()(MasterDataDash);
MasterDataResource = translate()(MasterDataResource);