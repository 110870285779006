import * as React from 'react';
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {Button, Segment} from "semantic-ui-react";
import {loanCarCall__scheduleById} from "../../actions/loanCarActions";
import BaseModel from "../../models/BaseModel";
import {LoanCarScheduleMask} from "../../cointainer/intern/vehicles";

// const ID = 31;
const ID = 470;

export class LoanCarMaskTest extends React.Component {
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'} ulStyle={{marginLeft: 20, marginRight: 20}}>
					<ActionHeaderGroup>
						<Button icon={'angle left'} basic onClick={() => window.history.back()}/>
						<ActionHeading>Mask test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic padded>
					<BaseModel.ProviderList list={[loanCarCall__scheduleById.bind(null, ID)]} callee={[true]}>{schedule =>
						<Segment>
							<LoanCarScheduleMask loanCarSchedule={schedule}/>
						</Segment>
					}</BaseModel.ProviderList>
				</Segment>
			</div>
		);
	}
}