import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Header, Icon, Label, Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {globals} from "ah-tools";
import {dismissError} from "../actions/errorActions";
import {Dialog, TextField} from "material-ui";
import {Flex} from "../components/partials/ActionHeader";
import {isLoading, setLoader, unsetLoader} from "../actions/loaderActions";
import {PROC_ERROR_REPORT} from "../actions";
// import {PUT} from "ith-fetch";
import {PUT} from "../Logic/fetch";
import isFunc from 'lodash/isFunction';
import {addSnackbar} from "../actions/snackbarActions";
import StackTrace from "stacktrace-js";
import {falseNull} from "../Logic/extensions";

export const errorReport = (error, user_text, onSuccess) => async dispatch => {
	try {
		dispatch(setLoader(PROC_ERROR_REPORT));
		try {
			if ("error" in error) {
				let err = await StackTrace.fromError(error.error ,{});
				err = err.map(String);
				error = {
					...error,
					stack: [error.error.stack, 'StackTrace:', ...err]
				};
			}
		} catch (e2) {}
		await PUT('/dev/user-report', {...error, error_id: error.error_code, code: error.code || 0, translation: error.message, user_text});
	} catch (e) {
		console.error(e);
	} finally {
		isFunc(onSuccess) && onSuccess();
		dispatch(unsetLoader(PROC_ERROR_REPORT));
	}
};

export class ErrorView extends React.PureComponent {
	static propTypes = {
		error: PropTypes.object,
		onDismiss: PropTypes.func.isRequired,
		onReport: PropTypes.func,
		count: PropTypes.number,
		isReporting: PropTypes.bool,
		link: PropTypes.string,
		loading: PropTypes.bool,
		disabled: PropTypes.bool
	};
	static defaultProps = {
		error: {},
		onDismiss: () => console.info('Should dismiss error...'),
		count: 0,
		isReporting: false,
		link: null,
		loading: false,
		disabled: false
	};
	
	state = {
		message: ''
	};
	
	handleReport = () => {
		const {error, onReport} = this.props;
		onReport(error, this.state.message, () => this.handleMessageChange(null, ''));
	};
	
	handleMessageChange = (e, message) => this.setState({message});
	
	render() {
		const {t, error, onDismiss, count, onReport, isReporting, link, loading, disabled} = this.props;
		if ( !count ) return null;
		let msg = 'Unbekannter Fehler!';
		try {
			if (!error.message || error.message.match(/^[a-z0-9\-_.]+$/)) {
				msg = t([error.message || 'errors.default'], error.params);
			} else {
				msg = error.message;
				
			}
		} catch (e) {
			console.error('ErrorControllerV2: error messag could not be translated: ', error.message, error.params);
		}
		return(
			<Dialog open bodyStyle={{background: globals.COLOR_SECONDARY, padding: 0, overflow: 'visible', position: 'relative'}}>
				<Segment inverted basic padded={'very'} loading={isReporting}>
					<Header>
						<Icon name="warning sign" fitted color="red" size="large"/>
						
					</Header>
					<p dangerouslySetInnerHTML={{__html: msg}} style={{padding: 25, color: globals.COLOR_CONTRAST}}/>
					{onReport && error.feedback && <TextField
						floatingLabelText={t('labels.your-feedback')}
						placeholder={t('labels.feedback-placeholder')}
						multiLine
						fullWidth
						textareaStyle={{color: 'white'}}
						floatingLabelFixed
						floatingLabelStyle={{color: '#54c8ff'}}
						value={this.state.message}
						onChange={this.handleMessageChange}
					/>}
					<Flex align={onReport && error.feedback ? 'space-between' : 'flex-end'} style={{marginTop: 20}}>
						{onReport && error.feedback && <Button color={'blue'} inverted onClick={this.handleReport}><Icon name={'paper plane'}/> {t('actions.report')}</Button>}
						<Button loading={loading} disabled={loading || disabled} as={link && 'a'} href={link} color={'red'} inverted onClick={falseNull(link) && onDismiss}><Icon name="remove"/> {t('actions.dismiss')}</Button>
					</Flex>
				</Segment>
				{count > 1 && <Label style={{position: 'absolute', left: 'auto', marginTop: 'auto', right: -60, top: 35, zIndex: 9999999}} color="red" ribbon="right" content={'+ '+(count-1)}/>}
			</Dialog>
		);
	}
}

const m2s = state => ({
	error: state.errors.list.length ? state.errors.list[state.errors.list.length - 1] : {},
	count: state.errors.list.length,
	isReporting: isLoading(state, PROC_ERROR_REPORT)
});
const m2d = dispatch => ({
	onDismiss: () => dispatch(dismissError()),
	onReport: (error, user_text, onSuccess) => dispatch(errorReport(error, user_text, () => {
		isFunc(onSuccess) && onSuccess();
		dispatch(dismissError());
		dispatch(addSnackbar('snackbar.error-report.sent'));
	}))
});

export default connect(m2s, m2d)(translate()(ErrorView));