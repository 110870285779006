/////////////
//      Quick Client
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {Segment, Table} from "semantic-ui-react";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__collect} from "../../../../actions/orderActions";
import {clientAction__getByOrder} from "../../../../actions/clientActions";
import {Trans, translate} from "react-i18next";
import {Client} from "../../../../models";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";
import {ConnectedConsultantSelector} from "../../../../components/intern/clients/consultant/ConsultantClientSelector";
import {CircleNotchLoader} from "../../../../components/Loaders";


const QuickClientBody = ({client}) => (
	<Table.Body>
		<Table.Row>
			<Table.Cell><Trans defaults="Kunden ID"/></Table.Cell>
			<Table.Cell>
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
					<span>{client.id}</span>
					{/*{mayChange && <Popup*/}
					{/*	content={"Kunden bearbeiten"}*/}
					{/*	inverted*/}
					{/*	trigger={<IconButton onClick={this.editClient(true)}><IconEdit/></IconButton>}*/}
					{/*/>}*/}
				</EasyFlex>
			</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Kundennummer"/></Table.Cell>
			<Table.Cell>{client.client_number || 'k.A.'}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Name"/></Table.Cell>
			<Table.Cell>{client.name}</Table.Cell>
		</Table.Row>
		{client.is_company ?
			<Table.Row>
				<Table.Cell><Trans defaults="Kontakt"/></Table.Cell>
				<Table.Cell>{client.contact}</Table.Cell>
			</Table.Row> : null
		}
		<Table.Row>
			<Table.Cell><Trans defaults="Anschrift"/></Table.Cell>
			<Table.Cell>
				<div>{client.street_full}</div>
				<div>{client.address_extra}</div>
				<div>{client.city_full}</div>
			</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="E-Mail"/></Table.Cell>
			<Table.Cell>{client.email || 'k.A.'}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Telefon"/></Table.Cell>
			<Table.Cell>{client.phone_full || 'k.A.'}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Mobil"/></Table.Cell>
			<Table.Cell>{client.mobile_full || 'k.A.'}</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Kundenberater"/></Table.Cell>
			<Table.Cell><ConnectedConsultantSelector borderless style={{paddingLeft: 0}} readOnly client={client.origin}/></Table.Cell>
		</Table.Row>
	</Table.Body>
);

export class OrderQuickClient extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		client: PropTypes.object,
		onlyBody: PropTypes.bool
	};
	
	state = {
		edit: false
	};
	
	
	
	editClient = (yes) => () => this.setState({edit: yes});
	
	render() {
		const {
			client: clientData,
			order,
			onlyBody,
			// t,
		} = this.props;
		
		if (isEmpty(order) || isEmpty(clientData)) {
			if (onlyBody) {
				return <Table.Body>
					<Table.Row>
						<Table.Cell textAlign={'center'} colSpan={2}>
							<CircleNotchLoader/>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			}
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>
		}
		const client = new Client(clientData);
		if (onlyBody){
			return <QuickClientBody client={client}/>;
		}
		return (
			<div>
				<Table padded basic={"very"} striped celled style={{margin: "30px 15px", width: "calc(100% - 30px)"}}>
					<QuickClientBody client={client}/>
				</Table>
				{/*<BasicDialog
					open={this.state.edit}
					closeButton
					autoDetectWindowHeight
					autoScrollBodyContent
					onClose={this.editClient(false)}
				>
					<h3 style={{position: "fixed", backgroundColor: "rgba(255,255,255,0.7)", display: "flex", alignItem: "center"}}>{client.icon} <span style={{marginLeft: 5}}>{client.name}</span></h3>
					<ClientEditController client_id={client.id} showHeader={false} onAfterSave={this.editClient(false)}/>
				</BasicDialog>*/}
			</div>
		);
	}
}

OrderQuickClient = withInit(OrderQuickClient);
OrderQuickClient = withRights(SECTION.CLIENTS, OrderQuickClient);
OrderQuickClient = connect(
	(state, props) => {
		const order_id = Number(props.order_id);
		const order = state.map.orders[order_id] || {};
		let order2vehicle = {};
		let client = {};
		if (!isEmpty(order)) {
			order2vehicle = state.map.order2vehicles[order.order_vehicle_id] || {};
			if (!isEmpty(order2vehicle)) {
				client = state.map.clients[order2vehicle.client_id];
			}
		}
		// const o2v = state.
		return {
			order_id,
			order,
			order2vehicle,
			client
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__collect([props.order_id]));
			dispatch(clientAction__getByOrder(props.order_id));
			// dispatch(clientAction__fetch());
		}))
	})
)(OrderQuickClient);
OrderQuickClient = translate()(OrderQuickClient);