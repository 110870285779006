import {LOCAL_STORAGE_ACTIONS} from "./types";

export const localStorageAction__setWorkerListViewType = value => {
	if (!["grid", "list"].includes(value)) {
		throw new Error("`localStorageAction__setWorkerListViewType()`: `value` is not acceptable!");
	}
	return {type: LOCAL_STORAGE_ACTIONS.WORKER_LIST_VIEW, value};
};

export const localStorageAction__setClientistViewType = value => {
	if (!["list", "stack"].includes(value)) {
		throw new Error("`localStorageAction__setClientistViewType()`: `value` is not acceptable!");
	}
	return {type: LOCAL_STORAGE_ACTIONS.CLIENT_LIST_VIEW, value};
};

export const localStorageAction__setTimeszones = value => ({type: LOCAL_STORAGE_ACTIONS.TIMEZONES, value});
export const localStorageAction__setCountries = value => ({type: LOCAL_STORAGE_ACTIONS.COUNTRIES, value});