import * as React from 'react';
import PropTypes from 'prop-types';
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../partials/ActionHeader";
import LoanCarMask from "./LoanCarMask";
import {translate} from "react-i18next";
import DeleteButton from "../../../../cointainer/partial/DeleteButton";
import {RaisedButton} from "material-ui";
import NoAccess from "../../../NoAccess";

const LoanCarEditView = ({onBack, isLoading, loan_car, onDelete, ...props}) => {
	const {t, mayDelete, mayRead} = props;
	if ( !mayRead ) {
		return <NoAccess/>;
	}
	return (
		<Segment basic>
			<ActionHeader alignment={'space-between'}>
				<ActionHeaderGroup>
					{onBack && <ActionBackButton onBack={onBack}/>}
					<ActionHeading loading={isLoading}>{loan_car ? t("loan-car.edit-title", {title: loan_car.title}) : t("loan-car.edit-title-default")}</ActionHeading>
				</ActionHeaderGroup>
				{mayDelete &&
					<ActionHeaderGroup>
						<DeleteButton hoc={RaisedButton} isLoading={isLoading} secondary onDelete={onDelete}/>
					</ActionHeaderGroup>
				}
			</ActionHeader>
			<LoanCarMask  {...props} isLoading={isLoading} loan_car={loan_car}/>
		</Segment>
	);
};

LoanCarEditView.propTypes = {
	...LoanCarMask.propTypes,
	onBack: PropTypes.func,
	isLoading: PropTypes.bool,
	onSave: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired
};
LoanCarEditView.defaultProps = {
	...LoanCarMask.defaultProps,
	isLoading: false,
	onSave: () => alert('LoanCarEditView.onCreate() is not implemented yet!'),
	onDelete : () => alert('LoanCarEditView.onDelete() is not implemented yet!')
};

export default translate()(LoanCarEditView);