import * as React from "react";
import PropTypes from "prop-types";

export const KEYPRESS = 'keypress';
export const KEYUP = 'keyup';
export const KEYDOWN = 'keydown';


export class KeyHandler extends React.Component {
	static propTypes = {
		keyValue: PropTypes.string.isRequired,
		onKeyPress: PropTypes.func.isRequired,
		type: PropTypes.string,
		defaultPrevented: PropTypes.bool
	};
	static defaultProps = {
		type: KEYPRESS,
		defaultPrevented: false
	};
	
	handle = (e) => {
		if ( e.key === this.props.keyValue) {
			if ( this.props.defaultPrevented) {
				e.preventDefault();
			}
			this.props.onKeyPress(e);
		}
	};
	
	componentDidMount() {
		window.addEventListener(this.props.type, this.handle, true);
	}
	
	componentWillUnmount() {
		window.removeEventListener(this.props.type, this.handle);
	}
	
	render() {
		return null;
	}
}



export class KeyModifier extends React.PureComponent {
	static propTypes = {
		children: PropTypes.node,
		ctrl: PropTypes.bool,
		shift: PropTypes.bool,
		alt: PropTypes.bool,
		preventDefault: PropTypes.bool
	};
	static defaultProps = {
		ctrl: false,
		shift: false,
		alt: false
	};
	state = {
		ctrl: false,
		shift: false,
		alt: false
	};
	
	keyDown = e => {
		const {ctrl, shift, alt} = this.state;
		if ( e.ctrlKey !== ctrl || e.altKey !== alt || e.shiftKey !== shift) {
			this.props.preventDefault && e.preventDefault();
			this.setState({
				ctrl: e.ctrlKey,
				shift: e.shiftKey,
				alt: e.altKey
			});
		}
	};
	
	keyUp = (e) => {
		this.props.preventDefault && e.preventDefault();
		this.setState({
			ctrl: false,
			shift: false,
			alt: false
		});
	};
	
	componentDidMount() {
		window.addEventListener("keydown", this.keyDown, {passive: true});
		window.addEventListener("keydup", this.keyUp, {passive: true});
	}
	
	componentWillUnmount() {
		window.removeEventListener("keydown", this.keyDown);
		window.removeEventListener("keyup", this.keyUp);
	}
	
	isBool = (value) => Boolean(true === value || false === value);
	
	render() {
		const {children, alt: altKey, shift: shiftKey, ctrl: ctrlKey} = this.props;
		const {ctrl, alt, shift} = this.state;
		if ( this.isBool(altKey) && altKey !== alt) {
			return null;
		}
		if ( this.isBool(shiftKey) && shiftKey !== shift) {
			return null;
		}
		if ( this.isBool(ctrlKey) && ctrlKey !== ctrl) {
			return null;
		}
		return children;
	}
}