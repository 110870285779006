import * as React from 'react';
import PropTypes from "prop-types";
import {loanCarCall__activeSchedule, loanCarCall__getAll} from "../../../../actions/loanCarActions";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {Button, Modal, Segment, Table} from "semantic-ui-react";
import {Vehicle} from "../../../../models";
import {LoanCarScheduleList} from "./LoanCarScheduleList";
import {dispatchSnack} from "../../../../actions/snackbarActions";

export const LoanCarTableList = ({loanCars, onShowSchedule, onShowDetails, isScheduling,  ...props}) => (
	<Table basic={'very'} celled selectable {...props}>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>Name</Table.HeaderCell>
				<Table.HeaderCell>HSN/TSN</Table.HeaderCell>
				<Table.HeaderCell>Kennzeichen</Table.HeaderCell>
				<Table.HeaderCell>Fahregstell</Table.HeaderCell>
				<Table.HeaderCell>Laufleistung</Table.HeaderCell>
				<Table.HeaderCell>Baujahr</Table.HeaderCell>
				<Table.HeaderCell>Erstzulassung</Table.HeaderCell>
				<Table.HeaderCell>Leihwagen</Table.HeaderCell>
				<Table.HeaderCell>&nbsp;</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{loanCars.map(loanCar => {
				const car = loanCar instanceof Vehicle ? loanCar : new Vehicle(loanCar);
				const loading = isScheduling(car.vehicle_id);
				return <Table.Row key={`loan-car-${car.vehicle_id}`}>
					<Table.Cell>{car.name}</Table.Cell>
					<Table.Cell>{car.hsn_tsn}</Table.Cell>
					<Table.Cell>{car.registration_shield}</Table.Cell>
					<Table.Cell>{car.chassis_number}</Table.Cell>
					<Table.Cell>{car.km} km</Table.Cell>
					<Table.Cell>{car.model_year}</Table.Cell>
					<Table.Cell>{car.first_registration_xxx}</Table.Cell>
					<Table.Cell textAlign={'center'}>
						<div>{car.loan_car_start_moment ? car.loan_car_start_moment.format('LLL') : '*'}</div>
						<div><small>bis</small></div>
						<div>{car.loan_car_end_moment ? car.loan_car_end_moment.format('LLL') : '*'}</div>
					</Table.Cell>
					<Table.Cell>
						<div><Button fluid basic color={'blue'} loading={loading} disabled={loading} onClick={onShowSchedule.bind(null, car)}>Termine zeigen</Button></div>
						<div style={{marginTop: 5}}><Button fluid basic onClick={onShowDetails.bind(null, car)}>Details</Button></div>
					</Table.Cell>
				</Table.Row>
			})}
		</Table.Body>
	</Table>
);

export class LoanCarList extends React.Component {
	static propTypes = {
		onFetch: PropTypes.func,
		onLoadSchedules: PropTypes.func,
		onShowVehicle: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onNotificationGood: PropTypes.func,
		methodProvider: PropTypes.func
	};
	
	static defaultProps = {
		onNotificationBad: (message) => console.error(message),
		onNotificationGood: (message) => dispatchSnack(message),
		onFetch: () => loanCarCall__getAll(),
		onLoadSchedules: vehicle_id => loanCarCall__activeSchedule(vehicle_id)
	};
	
	state = {
		fetching: false,
		fetched: false,
		loading_details: 0,
		schedule: null,
		list: [],
		
	};
	
	componentDidMount() {
		this.fetch();
		this.props.methodProvider && this.props.methodProvider({
			load: this.fetch,
			loading: () => this.state.fetching
		});
	}
	
	componentWillUnmount() {
		this.props.methodProvider && this.props.methodProvider(null);
	}
	
	translate = memoizeOne(
		map => Object.values(map || {})
	);
	
	fetch = async () => {
		if (this.state.fetching) {
			return;
		}
		const {onFetch, onNotificationBad} = this.props;
		try {
			this.setState({fetching: true});
			const res = await onFetch();
			this.setState({list: this.translate(res.loan_cars)});
		} catch (e) {
			onNotificationBad(e.error || e.message);
		} finally {
			this.setState({fetching: false, fetched: true});
		}
		
	};
	
	fetchSchedules = async (vehicle_id) => {
		if (this.state.loading_details) {
			return;
		}
		const {onLoadSchedules, onNotificationBad, onNotificationGood} = this.props;
		try {
			vehicle_id = vehicle_id.vehicle_id || vehicle_id;
			this.setState({loading_details: vehicle_id});
			const res = await onLoadSchedules(vehicle_id);
			if (!res.schedule.length) {
				onNotificationGood('Keine Termine vorhanden');
			} else {
				this.setState({schedule: res});
			}
		} catch (e) {
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loading_details: 0});
		}
	};
	
	isLoadingDetails = id => this.state.loading_details === id;
	
	
	render() {
		const {
			fetching,
			schedule
		} = this.state;
		return (
			<Segment basic loading={fetching} style={{padding: 0, margin: 0}}>
				<LoanCarTableList onShowSchedule={this.fetchSchedules} isScheduling={this.isLoadingDetails} onShowDetails={this.props.onShowVehicle || (() => {})} loanCars={this.state.list}/>
				{schedule &&
					<Modal size={'large'} centered={false} closeIcon basic open onClose={() => this.setState({schedule: null})}>
						<Modal.Header as={'h3'}>Termine für <code>{schedule.vehicle.registration_mark}</code></Modal.Header>
						<Modal.Content scrolling>
							<LoanCarScheduleList actionRenderer={() => <span/>} hideActions list={schedule.schedule} vehicle={schedule.vehicle}/>
						</Modal.Content>
					</Modal>
				}
			</Segment>
		);
	}
}