import {connect} from 'react-redux';
import MainView from '../../components/intern/MainView';
import User from "../../models/User";
import {logout} from "../../actions/userActions";

const mapStateToProps = (state, ownProps) => {
	const user = User._(state.user.data);
	return ({
		kfz: state.kfz.cars,
		// workers_id: state.user.workers_id,
		carhouse: state.carhouse.list.find(house => house.house_id === state.user.data.house_id),
		user
	});
};

const dis = (dispatch) => ({
	onRequestLogout: (house_id) => dispatch(logout(house_id))
});

export default connect(mapStateToProps, dis)(MainView);