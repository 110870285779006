import * as React from "react";

class ModelView extends React.Component {
	
	render() {
		return(
			<div>ModelView</div>
		);
	}
}


export default ModelView;