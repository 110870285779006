import * as React from 'react';
import PropTypes from 'prop-types';
import {globals, Subbar} from 'ah-tools';
import {Card, CardMedia, CardText, CardTitle, Paper, RaisedButton, IconButton} from "material-ui";
import {Form, Icon} from "semantic-ui-react";
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import {IMAGE_URL} from "../../Logic/constants";
import UploadReadyImage from '../../images/upload-ready.png';
import NoImage from '../../images/no-image.png';
import isFunc from 'lodash/isFunction';
import {translate} from "react-i18next";
import ConfirmDialog from "../../Logic/TriggerDialog";
import {deepMemoize} from "../../Logic/extensions";

class PhotoCard extends React.Component {
	static propTypes = {
		onDelete: PropTypes.func,
		onUpload: PropTypes.func,
		deleteParams: PropTypes.array,
		uploadParams: PropTypes.array,
		image: PropTypes.shape({
			image_id: PropTypes.number.isRequired,
			ident: PropTypes.string.isRequired,
			origin_extension: PropTypes.string.isRequired,
			updated_at: PropTypes.number.isRequired
		}),
		isDeleting: PropTypes.bool,
		isUploading: PropTypes.bool,
		CardHeader: PropTypes.any,
		accepts: PropTypes.string,
		uploadID: PropTypes.string,
		imageAlt: PropTypes.string,
		imageSubtitle: PropTypes.string,
		imageButtonTitle: PropTypes.string,
		selectIcon: PropTypes.any,
		uploadButtonTitle: PropTypes.string,
		uploadIcon: PropTypes.any,
		deleteIcon: PropTypes.any,
		confirmText: PropTypes.string,
		deleteCancelButtonText: PropTypes.string,
		deleteConfirmButtonText: PropTypes.string,
		uploadReadyImage: PropTypes.any,
		ref: PropTypes.func,
		onImageLoad: PropTypes.func,
		defaultImage: PropTypes.any,
		onChange: PropTypes.func
	};
	static defaultProps = {
		deleteParams: [],
		uploadParams: [],
		isDeleting: false,
		isUploading: false,
		onChange: () => {},
		accepts: 'image/png,iamge/jpg,image/jpeg',
		uploadID: 'single-image-upload',
		imageAlt: 'upload.image-alt',
		imageSubtitle: 'upload.image-ready',
		selectButtonTitle: 'upload.image-select',
		selectIcon: <Icon name={'file image'} />,
		uploadButtonTitle: 'actions.save',
		uploadIcon: <Icon name={'upload'}/>,
		deleteIcon: <DeleteIcon/>,
		confirmText: 'dialog.sure',
		deleteCancelButtonText: 'actions.no',
		deleteConfirmButtonText: 'actions.yes',
		uploadReadyImage: UploadReadyImage,
		defaultImage: NoImage
	};
	state = {
		fetching: false,
		preview: null,
		current: null,
		buttonProps: {
			style: {backgroundColor: 'transparent'},
			zDepth: 0,
			color: globals.COLOR_ALERT
		}
	};
	uploadButton = null;
	keepImage = false;
	
	componentDidMount() {
		this.setImage(this.props.image);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if ( this.keepImage ) return;
		this.setImage(this.props.image);
	}
	
	handleUpload = e => {
		this.keepImage = true;
		const {current} = this.state;
		const {onUpload, uploadParams} = this.props;
		const params = [...uploadParams, current, () => this.keepImage = false];
		onUpload.apply(this, params);
	};
	
	setImage = deepMemoize(image => {
		image = this.getImageLink(image);
		this.setState({preview: image, current: image}, this.props.onChange);
	});
	
	handleImageLoad = e => {
		const {onImageLoad} = this.props;
		if ( onImageLoad ) {
			onImageLoad(e);
		}
	};
	
	handleRef = el => {
		this.uploadButton = el;
		isFunc(this.props.ref) && this.props.ref.call(this, el);
	};
	
	handleImageSelection = ({target: {files}}) => {
		const image = files && files.length ? files[0] : null;
		const {onChange} = this.props;
		this.setState({current: image}, onChange);
		if ( image && 'FileReader' in window) {
			const reader = new FileReader();
			this.setState({fetching: true}, onChange);
			reader.onload = ({target: {result: preview}}) => {
				this.setState({preview, fetching: false}, onChange);
				this.handleImageLoad();
			};
			reader.readAsDataURL(image);
		} else {
			if (image) {
				this.setState({preview: this.props.uploadReadyImage}, onChange);
			} else {
				this.setImage(this.props.image);
			}
			this.handleImageLoad();
		}
	};
	
	handleUploadClick = e => {
		this.uploadButton.click();
	};
	
	getImageLink = (image=undefined) => {
		image = image || this.props.image;
		if ( image ) {
			const {ident, origin_extension, updated_at} = image;
			return `${IMAGE_URL}${ident}.${origin_extension}?${updated_at}`;
		}
		return null;
	};
	
	handleButtonOver = e => {
		// if ( this.state.hovered ) return;
		this.setState(state => ({
			...state,
			buttonProps: {
				...state.buttonProps,
				zDepth: 3,
				style: {
					...state.buttonProps.style,
					backgroundColor: globals.COLOR_ALERT
				},
				color: globals.COLOR_CONTRAST
			}
		}));
	};
	handleButtonLeave = e => {
		this.setState(state => ({
			...state,
			buttonProps: {
				...state.buttonProps,
				zDepth: 0,
				style: {
					...state.buttonProps.style,
					backgroundColor: 'transparent'
				},
				color: globals.COLOR_ALERT
			}
		}));
	};
	handleDelete = onHide => {
		const {onDelete, deleteParams} = this.props;
		if (isFunc(onDelete)) {
			const params = [...deleteParams, onHide];
			this.props.onDelete.apply(this, params);
			
		}
	};
	
	injectIcon = (Component, props) => () => (React.cloneElement(Component, props));
	
	render() {
		const {
			CardHeader,
			t,
			image,
			isUploading,
			isDeleting,
			imageSubtitle,
			defaultImage,
			uploadID,
			accepts,
			imageAlt,
			selectButtonTitle,
			selectIcon,
			uploadButtonTitle,
			uploadIcon,
			deleteIcon,
			confirmText,
			deleteCancelButtonText,
			deleteConfirmButtonText,
			onDelete,
			onUpload
		} = this.props;
		const {
			current,
			preview,
			fetching,
			buttonProps: {
				style: buttonStyle,
				color: buttonColor,
				...buttonProps
			}
		} = this.state;
		const DeleteIcon = this.injectIcon(deleteIcon, {color: buttonColor});
		return(
			<Card>
				{CardHeader && CardHeader}
				<CardMedia
					overlay={current && current.name && <CardTitle title={current.name} subtitle={t(imageSubtitle)}/>}
				>
					<img src={preview || defaultImage} alt={t(imageAlt)} onLoad={this.handleImageLoad}/>
					
				</CardMedia>
				{isFunc(onUpload) &&
					<CardText>
						<Form onSubmit={this.handleUpload} loading={isUploading || fetching}>
							<input
								type="file"
								ref={this.handleRef}
								id={uploadID}
								accept={accepts}
								style={{position: 'absolute', top: -999999999999, left: -99999999999, width: 0, height: 0, overflow: 'hidden', zIndex: -10}}
								onChange={this.handleImageSelection}
							/>
							<Subbar align={Subbar.ALIGN_CENTER}>
								<RaisedButton
									onClick={this.handleUploadClick}
									primary
									style={{marginLeft: 5, marginRight: 5}}
								>
									<span style={{marginLeft: 10, marginRight: 10}}>{t(selectButtonTitle)} {selectIcon}</span>
								</RaisedButton>
								{ current && current !== this.getImageLink() &&
									<RaisedButton
										secondary
										type={'submit'}
										style={{marginLeft: 5, marginRight: 5}}
									>
										<span style={{marginLeft: 10, marginRight: 10}}>{uploadIcon} {t(uploadButtonTitle)}</span>
									</RaisedButton>
								}
							</Subbar>
						</Form>
					</CardText>
				}
				{image && isFunc(onDelete) &&
					<Paper circle style={{position: 'absolute', right: 10, top: CardHeader ? 52 : 0}} onMouseEnter={this.handleButtonOver} onMouseLeave={this.handleButtonLeave} {...buttonProps}>
						<ConfirmDialog
							loading={isDeleting}
							text={confirmText}
							buttonCancelText={deleteCancelButtonText}
							buttonConfirmText={deleteConfirmButtonText}
							onConfirm={this.handleDelete}
							trigger={<IconButton style={{borderRadius: '50%', ...buttonStyle}}><DeleteIcon/></IconButton>}/>
					</Paper>
				}
			</Card>
		);
	}
}

export default translate()(PhotoCard);
