import * as React from "react";
import PropTypes from "prop-types";
import {Button, Modal, Segment} from "semantic-ui-react";
import {Flex} from "../../../components/partials/ActionHeader";
import {Divider, FlatButton, IconButton, RaisedButton, TextField} from "material-ui";
import VirtualList from "react-tiny-virtual-list";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../Tools/RightsProvider";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {Trans, translate} from "react-i18next";
import NoAccess from "../../../components/NoAccess";
import {isOnline} from "../../../actions/userActions";
import {clientCall__cursor} from "../../../actions/clientActions";
import {IconAdd, IconBusiness, IconPerson} from "../../../Logic/icons";
import ClientCreateController from "./ClientCreateController";

class ClientSearchController extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		clientMap: PropTypes.object,
		onSearch: PropTypes.func,
		searchDelay: PropTypes.number,
		liveSearch: PropTypes.bool,
		selectOnClick: PropTypes.bool,
		onSelect: PropTypes.func,
		onAbort: PropTypes.func,
		lastNameFirst: PropTypes.bool,
		focus: PropTypes.bool
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		clientMap: {},
		searchDelay: 500,
		selectOnClick: false,
		lastNameFirst: true,
		focus: true,
		liveSearch: false,
		onSearch: (search) => clientCall__cursor({items: 50, page: 0, search})
	};
	delay = null;
	state = {
		searchText: "",
		searchResults: [],
		loading: false,
		searchError: null,
		selectedClient: null,
		createClient: false
	};
	
	inputField = React.createRef();
	
	componentDidMount() {
		this.props.focus && this.inputField.current.focus();
	}
	
	setClientCreate = (createClient) => () => this.setState({createClient});
	
	getClientName = ({name, first_name}) => this.props.lastNameFirst ? name + ' ' + first_name : first_name + ' ' + name;
	
	getName = client => {
		const name = this.getClientName(client);
		if ( client.salutation === 'company') {
			return client.company_name + ` ( ${name} )`;
		}
		return name;
	};
	
	onType = (e, value) => {
		let {searchDelay, liveSearch} = this.props;
		value = value || e.target.value;
		console.debug(e.nativeEvent, value);
		if (liveSearch) {
			searchDelay = Math.max(0, searchDelay);
			window.clearTimeout(this.delay);
			this.delay = window.setTimeout(this.setSearch.bind(this, value), searchDelay);
		} else if(e.nativeEvent.which === 13) {
			this.setSearch(value);
		}
	};
	
	refSearch = () => {
		const searchText = this.inputField.current.input.value;
		this.setSearch(searchText);
	}
	
	setSearch = (value) => {
		this.setState({searchText: value}, () => {
			if ( value.trim() !== "" ) {
				this.search();
			}
		});
	};
	
	onSelect = () => {
		const {onSelect, /*clientMap,*/ onAbort} = this.props;
		const {selectedClient} = this.state;
		if ( onSelect && selectedClient ) {
			onSelect(selectedClient);
			onAbort && onAbort();
		}
	};
	
	selectClient = selectedClient => () => this.setState({selectedClient}, () => {
		if ( this.props.selectOnClick ) {
			this.onSelect();
		}
	});
	
	search = async () => {
		const {searchText} = this.state;
		if (searchText.trim() === "") return;
		const {onSearch} = this.props;
		try {
			this.setState({loading: true, searchError: null});
			const response = await onSearch(searchText);
			this.setState({searchResults: response.items, searchError: response.items.length ? null : 'Keine Ergebnisse'});
		} catch (e) {
			this.setState({searchError: e.error||e.message});
		} finally {
			this.setState({loading: false});
		}
		
	};
	
	afterCreateClient = () => {
		this.setClientCreate(false)();
		this.search();
	};
	
	render() {
		const {
			rights: {mayRead},
			// clientMap,
			onAbort,
			selectOnClick,
			liveSearch
		} = this.props;
		
		if ( !mayRead) { return <NoAccess/>; }
		
		const {
			// searchText,
			loading,
			searchResults,
			searchError,
			selectedClient,
			createClient
		} = this.state;
		
		
		let clients = searchResults;
		// if ( searchText.trim() !== "" ) {
		// 	clients = searchResults.map(c => ({
		// 		...c,
		// 		...clientMap[c.client_id]
		// 	})).sort((b,a) => a.prio - b.prio);
		// } else {
		// 	clients = values(clientMap);
		// }
		if (typeof clients === 'undefined') {
			return ' fufufu';
		}
		return(
			<Segment basic>
				<Flex align={"space-between"} valign={"center"}>
					<h3 style={{marginBottom: 15}}>Kunden suchen</h3>
					<IconButton onClick={this.setClientCreate(true)}>{<IconAdd/>}</IconButton>
				</Flex>
				<Flex valign={'center'}>
					<TextField
						fullWidth
						floatingLabelText={'Filter'}
						hintText={"Name | Kundennummer"}
						onKeyUp={this.onType}
						errorText={searchError}
						ref={this.inputField}
					/>
					{!liveSearch && <Button basic style={{marginTop: 25, marginLeft: 10}} loading={loading} onClick={this.refSearch} icon={"search"}/>}
				</Flex>
				<VirtualList
					width={'calc(100% - 20px)'}
					height={350}
					itemCount={clients.length}
					itemSize={35}
					renderItem={({index, style}) => {
						const client = clients[index];
						const isSelected = selectedClient && client.client_id === selectedClient.client_id;
						return (
							<Flex className="client-search-controller-item" key={client.client_id} onClick={this.selectClient(client)} style={{
								...style,
								paddingLeft: 10,
								paddingRight: 10,
								backgroundColor: isSelected ? 'lightgreen' : null,
								cursor: 'pointer'
							}} valign={"center"} >
								<span>{client.salutation === 'company' ? <IconBusiness/> : <IconPerson/>}</span>
								<span style={{marginLeft: 15, userSelect: 'none'}}>{this.getName(client)}</span>
							</Flex>
						);
					}}
				/>
				<div style={{marginBottom: 12}}/>
				<Divider/>
				<Flex valign={"center"} align={"space-between"}>
					<span>{onAbort && <FlatButton onClick={onAbort}>Abbrechen</FlatButton>}</span>
					<span>{!selectOnClick && <RaisedButton primary onClick={this.onSelect} disabled={selectedClient === 0}>Auswählen</RaisedButton>}</span>
				</Flex>
				{/*<ClientCreateDialog open={createClient} onClose={this.setClientCreate(false)} closeButton>
					<ClientCreateController showHeader={false} onAfterSave={this.afterCreateClient}/>
				</ClientCreateDialog>*/}
				{createClient && <Modal open centered={false} onClose={this.setClientCreate(false)}>
					<Modal.Content>
						<ClientCreateController showHeader={false} onAfterSave={this.afterCreateClient}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setClientCreate(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Segment>
		);
	}
}

ClientSearchController = withRights(SECTION.CLIENTS, ClientSearchController);
ClientSearchController = withInit(ClientSearchController);
ClientSearchController = connect(
	(state, props) => {
		return {
			clientMap: props.clientMap || state.clients.list
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				// dispatch(clientAction__fetch());
			})),
			// onSearch: searchAction__client
		};
	}
)(ClientSearchController);
ClientSearchController = translate()(ClientSearchController);

export default ClientSearchController;