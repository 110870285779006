import * as React from "react";
import PropTypes from "prop-types";
import {Checkbox, List, ListItem} from "material-ui";

export class LoanCarSelector extends React.PureComponent {
	static propTypes = {
		loanCars: PropTypes.arrayOf(PropTypes.object),
		onChange: PropTypes.func.isRequired,
		id: PropTypes.number
	};
	state = {
		id: 0
	};
	
	componentDidMount() {
		this.props.onChange(this.get(this.props.id || this.state.id), this.props.id || this.state.id);
	}
	
	componentWillUnmount() {
		this.props.onChange(null, this.props.id || this.state.id);
	}
	
	get = (id) => this.props.loanCars.find(v => v.vehicle_id === id) || null;
	change = id => () => this.props.onChange ? this.props.onChange(this.get(id), id) : this.setState({id});
	
	
	render() {
		const {loanCars, onChange, id} = this.props;
		if (!loanCars ) {
			return null;
		}
		const selection = onChange ? parseInt(id) : this.state.id;
		return (
			
			<List>
				{loanCars.map(car => {
					const selected = selection === car.vehicle_id;
					return (
						<ListItem
							key={`loan-car-${car.vehicle_id}`}
							primaryText={car.name}
							secondaryText={car.registration_mark}
							leftCheckbox={<Checkbox checked={selected} onCheck={this.change(selected ? 0 : car.vehicle_id)}/>}
						/>
					);
				})}
			</List>
		);
	}
}