import LoanCarListView from "../../../../components/intern/master-data/loan-cars/LoanCarListView";
import {connect} from "react-redux";
import withInit from "../../../../Logic/withInit";
import {push} from 'connected-react-router';
import {isOnline} from "../../../../actions/userActions";
import {loanCarAction__fetch, loanCarAction__set} from "../../../../actions/loanCarActions";
import User from "../../../../models/User";
import {SECTION} from "../../../../Logic/constants";
import {isLoading} from "../../../../actions/loaderActions";
import { PROC_VEHICLES_FETCH} from "../../../../actions";
import values from "lodash/values";
import isFunc from "lodash/isFunction";
import {addSnackbar} from "../../../../actions/snackbarActions";

const m2s = (state, props) => {
	const user = User._(state.user.data);
	return {
		...user.getAccessTo(SECTION.MASTER_DATA),
		isFetching: isLoading(state, PROC_VEHICLES_FETCH),
		loan_cars: values(state.vehicles.list).filter(c => c.loan_car && !c.deleted_at)
	};
};

const m2d = (dispatch, props) => {
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(loanCarAction__fetch());
		})),
		onBack: () => dispatch(push('/master-data')),
		onDelete: (car, onSuccess) => () => dispatch(loanCarAction__set(car, false, result => {
			dispatch(addSnackbar('snackbar.loan-car.removed'));
			isFunc(onSuccess) && onSuccess(result);
		})),
		// onCreate: () => dispatch(push('/master-data/loan-cars/add')),
		onSelect: (client, vehicle) => dispatch(push(`/clients/${client.client_id || client}/vehicles/${vehicle.vehicle_id || vehicle}`)),
		onSetLoanCar: (car, value, onSuccess) => () => dispatch(loanCarAction__set(car, value, result => {
			dispatch(addSnackbar(value ? 'snackbar.loan-car.added' : 'snackbar.loan-car.removed'));
			isFunc(onSuccess) && onSuccess(result);
		}))
	};
};


export default connect(m2s, m2d)(withInit(LoanCarListView));