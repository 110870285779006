import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {Button, Label, Modal, Segment, Table} from "semantic-ui-react";
import {get, isArray} from "lodash";
import moment from "../../../Logic/Moment";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {Divider} from "material-ui";
import {deepMemoize as memoize, trueNull} from "../../../Logic/extensions";
import {appointmentAction__getOne} from "../../../actions/appointmentActions";
import {Flex} from "../../../components/partials/ActionHeader";
import {If} from "../../../Logic/Switch";
import Countdown, {renderBeautiful} from "../../../components/partials/Countdown";
import AppmntController from "../appointments/v2/AppmntController";
import AppointmentPaper from "../appointments/AppointmentPaper";
import {STORE_SECTION_WORK} from "../../../actions";
import {withInterval} from "../../../Logic/withInterval";
import {workAction__ofDay} from "../../../actions/orderActions";
import {withRights} from "../../../Tools";
import {COLORS, SECTION} from "../../../Logic/constants";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import {IconAlertAdd} from "../../../Logic/icons";
import OrderQuickView from "../orders/OrderQuickView";
import {soundAction__play, soundAction__stop} from "../../../actions/soundActions";
import {sessionStorageAction__addAppointmentNotificationId} from "../../../actions/sessionStorageActions";
import hash from "object-hash";

class AlertOrder extends React.Component {
	componentDidMount() {
		this.props.play();
	}
	componentWillUnmount() {
		this.props.stop();
	}
	
	render() {
		let {order, } = this.props;
		if (!order) return null;
		let {company_name, first_name, last_name, vehicle_name, registration_mark, chassis_number, deliver_point, latest_point, delivered_at, fetch_point, hours_of_work, hsn, tsn} = order;
		const cname = company_name ? "Firma " + company_name : last_name + " " + first_name;
		return <Table basic={"very"} striped color={"grey"} inverted style={{marginTop: 30, marginBottom: 30}}>
			<Table.Body>
				<Table.Row>
					<Table.Cell>Kunde</Table.Cell>
					<Table.Cell>{cname}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Fahrzeug</Table.Cell>
					<Table.Cell>{vehicle_name}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Kennzeichen</Table.Cell>
					<Table.Cell>{registration_mark}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Fahrgestellnummer</Table.Cell>
					<Table.Cell>{chassis_number}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>HSN/TSN</Table.Cell>
					<Table.Cell>{hsn} / {tsn}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Abgabe geplant</Table.Cell>
					<Table.Cell>{moment(deliver_point).format("LLL")}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Abgegeben um</Table.Cell>
					<Table.Cell>{moment(delivered_at).format("LLL")}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Letzter Zeitpunkt</Table.Cell>
					<Table.Cell>{moment(latest_point).format("LLL")}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Abholung</Table.Cell>
					<Table.Cell>{moment(fetch_point).format("LLL")}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>Arbeitszeit</Table.Cell>
					<Table.Cell>{hours_of_work} Std.</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Table>;
	}
}

AlertOrder = connect(
	null,
	dispatch => ({
		play: () => dispatch(soundAction__play),
		stop: () => dispatch(soundAction__stop)
	})
)(AlertOrder);

AlertOrder.propTypes = {
	order: PropTypes.any
};

// TODO: Fix exection on error!
let AppmntInterval = withInterval(30000)(AppmntController);
AppmntInterval = connect(
	() => {
		return {
			intervalActive: true,
			showLoader: false
		};
	},
	(dispatch) => {
		return {
			interval: (props) => {
				dispatch(workAction__ofDay("today", true, props.user.getData().workers_id, undefined, true));
				// dispatch(scheduleAction__ofWorker(props.user.getData(), "today", undefined, true));
			}
		};
	}
)(AppmntInterval);
AppmntInterval = withRights(SECTION.APPOINTMENTS, AppmntInterval);

const hashChange = (a, b) => hash(a || {}) !== hash(b || {});

class WorkView extends React.Component {
	static propTypes = {
		work: PropTypes.shape({
			order_id: PropTypes.number.isRequired,
			workers_id: PropTypes.number.isRequired,
			service_id: PropTypes.number.isRequired,
			resource_id: PropTypes.number.isRequired,
			start_point: PropTypes.number.isRequired
		}).isRequired,
		order: PropTypes.shape({
			order_id: PropTypes.number.isRequired,
			order_vehicle_id: PropTypes.number.isRequired,
			deliver_point: PropTypes.number.isRequired,
			fetch_point: PropTypes.number.isRequired,
			delivered_at: PropTypes.number,
			released_at: PropTypes.number,
			released_by: PropTypes.number,
			progress: PropTypes.number.isRequired,
			fetched_at: PropTypes.number,
			finished_at: PropTypes.number,
			name: PropTypes.string.isRequired,
			info: PropTypes.string
		}),
		order_services: PropTypes.objectOf(
			PropTypes.shape({
				order_service_id: PropTypes.number.isRequired,
				order_id: PropTypes.number.isRequired,
				service_id: PropTypes.number.isRequired,
				hours_of_work: PropTypes.number.isRequired,
				hours_of_work_resource: PropTypes.number.isRequired,
				service_name: PropTypes.string.isRequired,
				finished_at: PropTypes.number
			})
		),
		clients: PropTypes.objectOf(PropTypes.shape({
			client_id: PropTypes.number.isRequired,
			client_number: PropTypes.string,
			house_id: PropTypes.number.isRequired,
			self: PropTypes.bool.isRequired
		})),
		serviceMap: PropTypes.object,
		vehicles: PropTypes.object,
		workInfo: PropTypes.object,
		worklist: PropTypes.array,
		pauselist: PropTypes.array
	};
	
	static defaultProps = {
		worklist: [],
		pauselist: []
	};
	
	state = {
		tracking: null,
		showDetail: false,
		skipped: []
	};
	
	componentDidMount() {
		const {worklist, seen, order} = this.props;
		this.memCheckOrders(null, this.memOrderPrefilterCurrent(worklist, seen), order, this.state.tracking);
		// this.setState({skipped: this.memSkippedOrders(worklist, seen)});
	}
	
	// OKAY
	// componentWillReceiveProps({order, worklist, seen}, nextContext) {
	// 	const tracking = this.state.tracking;
	// 	this.memCheckOrders(this.memOrderPrefilterNew(worklist, seen), this.memOrderPrefilterCurrent(this.props.worklist, seen), order, seen, tracking);
	// 	// if (seen.length !== this.props.seen.length) {
	// 	// 	console.debug('seen differs');
	// 	// 	this.memCheckOrders(this.memOrderPrefilterNew(worklist || this.props.worklist, seen), this.memOrderPrefilterCurrent(this.props.worklist, seen), order, seen, tracking);
	// 	// } else if (order && worklist && worklist.length) {
	// 	// 	if (this.notSameList(worklist, this.props.worklist)) {
	// 	// 		// console.debug('not same worklist...', worklist, this.props.worklist);
	// 	// 		this.memCheckOrders(this.memOrderPrefilterNew(worklist, seen), this.memOrderPrefilterCurrent(this.props.worklist, seen), order, seen, tracking);
	// 	// 	}
	// 	// }
	// }
	
	componentDidUpdate({order, worklist, seen}, prevState, snapshot) {
		if (hashChange(order, this.props.order) || hashChange(worklist, this.props.worklist) || hashChange(seen, this.props.seen) || hashChange(this.state.tracking, prevState.tracking)) {
			const tracking = this.state.tracking;
			this.memCheckOrders(this.memOrderPrefilterNew(worklist, this.props.seen), this.memOrderPrefilterCurrent(this.props.worklist, this.props.seen), order || this.props.order, tracking);
			// this.setState({skipped: this.memSkippedOrders(worklist, this.props.seen)});
		}
	}
	
	// noinspection JSUnusedLocalSymbols
	prefilterOrders = (list, seen = []) => {
		// return list.filter((order) => !!order.delivered_at && !seen.includes(order.order_id));//.sort((left, right) => new Date(left.latest_point) < new Date(right.latest_point) ? -1 : 1);
		return list.filter((order) => !!order.delivered_at);//.sort((left, right) => new Date(left.latest_point) < new Date(right.latest_point) ? -1 : 1);
	};
	
	memOrderPrefilterNew = memoize(this.prefilterOrders);
	memOrderPrefilterCurrent = memoize(this.prefilterOrders);
	memIndexOfOrder = memoize(
		(list, order) => {
			for (const i in list) {
				if (list.hasOwnProperty(i) && list[i].order_id === order.order_id) {
					return i;
				}
			}
			return null;
		}
	);
	
	memCheckOrders =
		(list, list2, order, tracking) => {
			if (tracking) {
				return;
			}
			if (!order) {
				return;
			}
			let worklist = isArray(list) && list.length ? list : list2;
			// const worklist = list.sort((a,b) => new Date(a.latest_point) < new Date(b.latest_point) ? -1 : 1);
			
			if (worklist.length > 1) {
				let check = worklist[1];
				if (!this.props.seen.includes(check.order_id)) {
					check = new Date(check.latest_point);
					if (check < new Date()) {
						this.setState({tracking: worklist[1]});
					}
				}
				
			}
			// const index = this.memIndexOfOrder(list, order);
			// if (null !== index && index > 0) {
			// 	this.setState({tracking: worklist[0]});
			// }
		};
	
	// memSkippedOrders = memoize(
	// 	(worklist, seen, order) => worklist.filter(w => seen.includes(w.order_id)).filter(w => !order || order.order_id !== w.order_id)
	// );
	
	toggleDetailView = (showDetail) => () => this.setState({showDetail});
	
	render() {
		const {
			// work,
			order,
			// resource,
			// serviceMap,
			// vehicles,
			// clients,
			workInfo,
			section,
			// pauselist
		} = this.props;
		// const service = get(serviceMap, [work.service_id], null);
		const order_vehicle_id = get(order, 'order_vehicle_id', 0);
		const vehicle = get(section, ["order_vehicles", order_vehicle_id], null);
		const client_id = get(vehicle, "client_id", 0);
		// const client = get(section, ["clients", client_id], null);
		// const CLIENT = client ? new Client(client) : null;
		
		// this.checkOrders();
		const alertOrder = this.state.tracking;
		const showAlert = null !== alertOrder;
		
		const isDelayed = Boolean(order && new Date(order.fetch_point).getTime() - 60000 < Date.now());
		// noinspection JSCheckFunctionSignatures
		return(
			<Segment basic style={{backgroundColor: isDelayed ? "rgba(255,0,0,0.7)" : null}}>
				{order &&
					<React.Fragment>
						<Segment inverted={isDelayed} color={isDelayed ? "red" : null} style={{textAlign: 'center', color: isDelayed ? '#db2828' : 'inherit', fontWeight: isDelayed ? 'bold' : 'inherit'}}>
							<Flex align={"space-between"} valign={'center'} wrap>
								<Flex direction={"column"}>
									<Label color={"black"}>Bis zur Abgabe</Label>
									<Countdown
										targetDate={new Date(order.fetch_point)}
										render={renderBeautiful}
										triggers={[{
											name: "target",
											timer: 60
										}]}
										onTrigger={() => this.forceUpdate()}
									/>
								</Flex>
								{trueNull(workInfo) &&
								<Flex direction={"column"}>
									<Label color={"black"}>Verbleibende Arbeitszeit</Label>
									<Countdown targetDate={moment().add(workInfo.difference || 0, 'seconds').toDate()} render={renderBeautiful}/>
								</Flex>}
							</Flex>
						</Segment>
						<If condition={order.info && order.info.trim()}>
							<Segment padded={"very"}>
								<p>{order.info}</p>
							</Segment>
						</If>
					</React.Fragment>
				}
				{order && client_id && vehicle && <AppointmentPaper
					order={order}
					accessable={false}
					client_id={Number(client_id) }
					services={section.services}
					resources={section.resources}
					order2services={section.order2services}
					vehicle_id={Number(vehicle.vehicle_id)}
				/>}
				<div style={{marginTop: 40}}/>
				<Divider/>
				{/*<EasyFlex>*/}
					<h3>Weitere Arbeiten</h3>
					{/*<span style={{paddingLeft: 30}}><Popup content={'Aktuell übersprungene Arbeiten'} trigger={<EasyFlex valign={EasyFlex.valign.CENTER} style={{cursor: "default"}}><IconWarning color={COLORS.SEMANTIC_RED}/><span>&nbsp;</span><strong style={{fontSize: 'larger'}}>{this.state.skipped.length}</strong></EasyFlex>}/></span>*/}
				{/*</EasyFlex>*/}
				<AppmntInterval silent accessable={false} showHeaders={false} currentOrderWorkId={trueNull(order) && order.order_id} />
				{/*{trueNull(order) && <AppointmentAlertDialog order_id={order.order_id}/>}*/}
				{/*<BasicDialog open={showAlert} autoScrollBodyContent title={
					<Flex valign={"stretch"}>
						<IconAlertAdd className={"alert-icon"} color={COLORS.SEMANTIC_RED} style={{width: 64, height: 64, marginRight: 40}}/>
						<h3>Ein Auftrag mit erhöhter Priorität eingegangen</h3>
					</Flex>
				} actions={
					<Flex align={"space-between"}>
						<Button icon={"info"} circular color={"blue"} onClick={this.toggleDetailView(true)}/>
						<Button color={"green"} onClick={() => {
							this.props.updateSeen(this.state.tracking.order_id);
							this.setState({tracking: null});
							// this.setState(state => ({
							// 	seen: [...state.seen, this.state.tracking.order_id],
							// 	tracking: null
							// }), () => saveStoredData(this.state.seen, this.props.user.getData().workers_id))
						}}>Okay, das schaue ich mir an!</Button>
					</Flex>
				}>
					<AlertOrder order={alertOrder}/>
				</BasicDialog>*/}
				{showAlert && <Modal open centered={false}>
					<Modal.Header>
						<Flex valign={"center"}>
							<IconAlertAdd className={"alert-icon"} color={COLORS.SEMANTIC_RED} style={{width: 64, height: 64, marginRight: 40}}/>
							<div>Ein Auftrag mit erhöhter Priorität eingegangen</div>
						</Flex>
					</Modal.Header>
					<Modal.Content>
						<AlertOrder order={alertOrder}/>
					</Modal.Content>
					<Modal.Actions>
						<Flex align={"space-between"}>
							<Button icon={"info"} circular color={"blue"} onClick={this.toggleDetailView(true)}/>
							<Button color={"green"} onClick={() => {
								this.props.updateSeen(this.state.tracking.order_id);
								this.setState({tracking: null});
								// this.setState(state => ({
								// 	seen: [...state.seen, this.state.tracking.order_id],
								// 	tracking: null
								// }), () => saveStoredData(this.state.seen, this.props.user.getData().workers_id))
							}}>Okay, das schaue ich mir an!</Button>
						</Flex>
					</Modal.Actions>
				</Modal>}
				
				<OrderViewDialog open={this.state.showDetail && showAlert} onClose={this.toggleDetailView(false)}>
					{trueNull(this.state.showDetail && showAlert) && <OrderQuickView order_id={alertOrder.order_id} onRequestClose={this.toggleDetailView(false)}/>}
				</OrderViewDialog>
			</Segment>
		);
	}
}


WorkView = withInit(WorkView);
WorkView = connect(
	(state, props) => {
		const appointments = state.appointments;
		const workSection = get(state, ['appointments', 'section', STORE_SECTION_WORK], {});
		const order = get(workSection, ['orders', props.work.order_id], null);
		return {
			order,
			order_services: state.map.order2services,
			resource: get(state.map.resources, [props.work.resource_id], {}),
			vehicles: state.map.vehicles,
			serviceMap: state.map.services, //state.kfz.service.list,
			clients: state.map.clients,
			workInfo: get(appointments, ["workload", order ? order.order_id : 0], null),
			section: workSection,
			worklist: appointments.list || [],
			seen: state.session.notification_appointment_ids
		}
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(appointmentAction__getOne(props.work.order_id, STORE_SECTION_WORK));
			dispatch(workAction__ofDay('today', true, props.user.workers_id));
		})),
		updateSeen: (id) => dispatch(sessionStorageAction__addAppointmentNotificationId(id))
	})
)(WorkView);
WorkView = withRights(SECTION.APPOINTMENTS, WorkView);
WorkView = translate()(WorkView);
export default WorkView;