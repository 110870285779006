import PropTypes from 'prop-types';


export const APPOINTMENT_DEFINITION = {
	event_id: PropTypes.number.isRequired,
	workers_id: PropTypes.number.isRequired,
	resource_id: PropTypes.number.isRequired,
	aw: PropTypes.number.isRequired,
	target: PropTypes.instanceOf(Date).isRequired,
	confirmed: PropTypes.bool.isRequired
};

export const USER_DEFINITION = {
	workers_id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	first_name: PropTypes.string.isRequired,
	gender: PropTypes.string.isRequired,
	right_group_id: PropTypes.string.isRequired,
	house_id: PropTypes.number.isRequired,
	password: PropTypes.string,
	email: PropTypes.string
};

export const HOUSE_RESOURCE_DEFINITION = {
	resource_id: PropTypes.number.isRequired,
	house_id: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};

export const FREEDAY_WORKER_DEFINITION = {
	freedays_id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(['sick', 'holiday', 'master_replacement']).isRequired,
	workers_id: PropTypes.number.isRequired,
	start_time: PropTypes.number.isRequired,
	end_time: PropTypes.number.isRequired,
	allday: PropTypes.bool.isRequired
};

export const propTypes = {
	html: {
		types: {
			accessKey: PropTypes.string,
			class: PropTypes.string,
			contentEditable: PropTypes.bool,
			dir: PropTypes.oneOf(["ltr", "rtl", "auto"]),
			draggable: PropTypes.oneOf([true, false, "auto"]),
			dropzone: PropTypes.oneOf(["copy", "move", "link"]),
			hidden: PropTypes.bool,
			id: PropTypes.string,
			lang: PropTypes.string,
			spellCheck: PropTypes.bool,
			style: PropTypes.object,
			tabIndex: PropTypes.number,
			title: PropTypes.string,
			translate: PropTypes.oneOf(["yes", "no"])
		}
	},
	input: {
		types: {
			value: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string
			]),
			onChange: PropTypes.func,
			onBlur: PropTypes.func,
			onFocus: PropTypes.func,
			disabled: PropTypes.bool,
			readOnly: PropTypes.bool,
			accept: PropTypes.string,
			alt: PropTypes.string,
			autoComplete: PropTypes.oneOf(["on", "off"]),
			autoFocus: PropTypes.oneOf(["autofocus"]),
			checked: PropTypes.bool,
			form: PropTypes.string,
			height: PropTypes.number,
			maxLength: PropTypes.number,
			minLength: PropTypes.number,
			multiple: PropTypes.bool,
			placeholder: PropTypes.bool,
			required: PropTypes.bool,
			size: PropTypes.number,
			width: PropTypes.number,
			src: PropTypes.string,
			step: PropTypes.number,
			type: PropTypes.oneOf([
				"button",
				"checkbox",
				"color",
				"date",
				"datetime-local",
				"email",
				"file",
				"hidden",
				"image",
				"month",
				"number",
				"password",
				"radio",
				"range",
				"reset",
				"search",
				"submit",
				"tel",
				"text",
				"time",
				"url",
				"week"
			])
		}
	}
	
};