import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {Button, ButtonGroup, ButtonOr, Form, FormField} from "semantic-ui-react";
import {_empty, AHToggle, ListItemMargined, Loadable} from "../../../Logic/extensions";
import {translate} from "react-i18next";
import PersonIcon from 'material-ui/svg-icons/action/assignment-ind';
import {Subbar} from "ah-tools";
import AvatarF from '../../../images/avatar-f.png';
import AvatarM from '../../../images/avatar-m.gif';
import EditField from "../../partials/EditField";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER_UPDATE} from "../../../actions";
import {updateWorker} from "../../../actions/workerActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import isFunc from 'lodash/isFunction';
import isString from 'lodash/isString';
import isBool from 'lodash/isBoolean';


class WorkersDetailBasicEdit extends React.Component {
	static propTypes = {
		worker: PropTypes.shape({
			workers_id: PropTypes.number.isRequired,
			first_name: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			gender: PropTypes.string.isRequired
		}).isRequired,
		mayChange: PropTypes.bool,
		onSave: PropTypes.func.isRequired,
		isLoading: PropTypes.bool,
		self: PropTypes.bool
	};
	static defaultProps = {
		mayChange: false,
		isLoading: false,
		onSave: () => alert('onSave() is not implemented!'),
		self: false
	};
	
	mounted = false;
	
	state = {};
	
	equalsProp = (name, value) => callback => {
		const {worker} = this.props;
		return worker[name] === value ? callback(name, undefined) : callback(name, value);
	};
	
	clear = () => {
		this.mounted && this.setState({
			first_name: undefined,
			name: undefined,
			gender: undefined,
			active: undefined
		});
	};
	
	// handleChange = (e, {name, value}) => this.setState({[name]: value});
	handleChange = (e, {name, value}) => this.equalsProp(name, value)((name, value) => this.setState({[name]: value}));
	handleToggle = ({currentTarget}, value) => this.equalsProp(currentTarget.name, value)((name, value) => this.setState({[name]: value}));
	
	isValid = () => {
		let valid = !_empty(this.state);
		for( const k in this.state) {
			if ( !this.state.hasOwnProperty(k) ) continue;
			const value = this.state[k];
			if ( value === undefined ) continue;
			if ( isString(value) && value.trim() === '' ) return false;
		}
		return valid;
	};
	
	handleSubmit = e => {
		if ( !this.isValid() ) return;
		this.props.onSave(this.props.worker, this.state, this.clear);
	};
	
	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}
	
	required = value => isString(value) && value.trim() === '' ? this.props.t('worker.detail.errors.required') : null;
	
	
	render() {
		const {t, mayChange, worker: {first_name, name, gender: workerGender, active: workerActive}, isLoading, self: isSelf} = this.props;
		const {gender, active} = this.state;
		const selectedGender = gender || workerGender;
		const selectedActive = isBool(active) ? active : workerActive;
		const labelPosition = mayChange ? 'left right' : 'left';
		const required = mayChange;
		const readOnly = !mayChange;
		return (
			<Card>
				<CardHeader title={t('worker.detail.parts.worker-info')} subtitle={t('worker.detail.parts.basisdata')}/>
				<Form onSubmit={this.handleSubmit} loading={isLoading}>
					<CardText>
						<FormField>
							<ListItemMargined primaryText={t('worker.detail.labels.active')} rightToggle={<AHToggle disabled={isSelf} name={'active'} toggled={selectedActive} onToggle={this.handleToggle}/>}/>
						</FormField>
						<FormField>
							<EditField
								icon={<PersonIcon/>}
								required={required}
								readOnly={readOnly}
								defaultValue={first_name}
								label={t('worker.detail.labels.first-name')}
								name={'first_name'}
								placeholder={t('worker.detail.placeholders.first-name')}
								onChange={this.handleChange}
								editable={mayChange}
								errorText={this.required(this.state.first_name)}
							/>
						</FormField>
						<FormField>
							<EditField
								icon={<PersonIcon/>}
								required={required}
								readOnly={readOnly}
								defaultValue={name}
								labelPosition={labelPosition}
								label={t('worker.detail.labels.name')}
								name={'name'}
								placeholder={t('worker.detail.placeholders.name')}
								onChange={this.handleChange}
								editable={mayChange}
								errorText={this.required(this.state.name)}
							/>
						</FormField>
						<FormField>
							<label>{t('worker.detail.labels.gender')}</label>
							<Subbar align={Subbar.ALIGN_CENTER}>
								<ButtonGroup>
									<Button type={'button'} disabled={!mayChange} color={selectedGender === 'f' ? 'blue' : null} name={'gender'} value={'f'} onClick={this.handleChange}><img src={AvatarF} alt={'Feminin'} style={{maxHeight: 40}}/></Button>
									<ButtonOr/>
									<Button type={'button'} disabled={!mayChange} color={selectedGender === 'm' ? 'blue' : null} name={'gender'} value={'m'} onClick={this.handleChange}><img src={AvatarM} alt={'Maskulin'} style={{maxHeight: 40}}/></Button>
								</ButtonGroup>
							</Subbar>
						</FormField>
						{mayChange &&
							<Subbar align={Subbar.ALIGN_END} style={{marginTop: 20}}>
								<Loadable type={'submit'} disabledOnLoading inverse hoc={RaisedButton} primary
								          disabled={!this.isValid()}>{t('actions.save')}</Loadable>
							</Subbar>
						}
					</CardText>
				</Form>
			</Card>
		);
	}
}

WorkersDetailBasicEdit = translate()(WorkersDetailBasicEdit);


export default WorkersDetailBasicEdit;

const m2s = (state, props) => ({
	isLoading: isLoading(state, PROC_WORKER_UPDATE, props.worker.workers_id)
});
const m2d = (dispatch) => ({
	onSave: (worker, data, onSuccess) => dispatch(updateWorker(worker, data, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.updated'));
	}))
});
export const WorkersDetailBasicEditController = connect(m2s, m2d)(WorkersDetailBasicEdit);
WorkersDetailBasicEditController.propTypes = {
	worker: PropTypes.shape({
		workers_id: PropTypes.number.isRequired,
		first_name: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		gender: PropTypes.string.isRequired
	}).isRequired,
	mayChange: PropTypes.bool,
	isLoading: PropTypes.bool,
	self: PropTypes.bool
};