import React from 'react';
import {Route} from 'react-router';
import {ConnectedRouter} from "connected-react-router";
import history from '../Logic/history';
import Header from "./extern/Header";
import CarhouseController from "../cointainer/extern/CarhouseController";
import {translate} from "react-i18next";
import {Switch} from "react-router";
import cn from 'classnames';
import EmailLoginController from "../cointainer/extern/EmailLoginController";
import {PRODUCTION_MODE, SHOW_DEV_PRODUCTION} from "../Logic/constants";
import Development from "./Development";

const IndexView = ({t, logged}) => {
	return (
		<ConnectedRouter history={history}>
			<React.Fragment>
				<div id="main__content" className={cn({'ith-extern': !logged})}>
					<Header className={'ith-extern'}/>
					<div id="body">
						<Switch>
							<Route exact path="/" component={CarhouseController}/>
							<Route extact path="/email-login/:hash" component={EmailLoginController}/>
							<Route exact path="/:house_id" component={CarhouseController}/>
							{(!PRODUCTION_MODE || SHOW_DEV_PRODUCTION) && <Route path="/dev" component={Development}/>}
							<Route path="/" component={() => <div>NOTHING HERE</div>} />
						</Switch>
					</div>
				</div>
			</React.Fragment>
		</ConnectedRouter>
	
	);
};

export default translate()(IndexView);