import * as React from "react";
import PropTypes from "prop-types";
import {clientAction__collectOne, clientCall__collectOne} from "../../../../actions/clientActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {orderAction__put, orderAction__putForced, orderCall__find, orderCall__put, orderCall__put_forced} from "../../../../actions/orderActions";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {moment} from "../../../../Logic/Moment";
import {array2object, falseNull, trueNull} from "../../../../Logic/extensions";
import {Button, Header, Icon, Image, Message, Modal, Segment} from "semantic-ui-react";
import {OrderVehicleSelector} from "../../../../cointainer/intern/orders/OrderVehicleSelector";
import {OrderServiceSelector} from "../../../../cointainer/intern/orders/OrderServiceSelector";
import {OrderServiceSelectionList} from "../../../../cointainer/intern/orders/OrderServiceSelectionList";
import {OrderOptionsView} from "../OrderOptionsView";
import {COLORS} from "../../../../Logic/constants";
import {NegativeSegment} from "../../../partials/Segments";
import {FinalResult} from "./FinalResult";
import {ClientView} from "./ClientView";
import {CompactView} from "./CompactView";
import {MaterialRangeSelector} from "./DateRangeSelector";
import {NextAppointmentResult} from "./NextAppointmentResult";
import {translate} from "react-i18next";
import {get} from 'lodash';
import {connect} from "react-redux";
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {CircleNotchLoader} from "../../../Loaders";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Vehicle, Worker} from "../../../../models";
import {handleCommonErrors} from "../../../../actions/errorActions";
import {withModule} from "../../../../Tools/RightsProvider";


export class AppointmentOptionView extends React.Component {
	static propTypes = {
		result: PropTypes.shape({
			order_ranges: PropTypes.shape({
				range_start: PropTypes.any.isRequired,
				range_end: PropTypes.any.isRequired
			}).isRequired,
		}).isRequired,
		version: PropTypes.number,
		loanCarId: PropTypes.number,
		loanCarRange: PropTypes.shape({
			start: PropTypes.any.isRequired,
			end: PropTypes.any.isRequired
		}),
		consultantId: PropTypes.number,
		consultantRange: PropTypes.shape({
			start: PropTypes.any.isRequired,
			end: PropTypes.any.isRequired
		}),
		consultantDuration: PropTypes.number
	};
	render() {
		const {result, version, loanCarId, loanCarRange, consultantId, consultantRange, consultantDuration, searchRange, style, ...props} = this.props;
		return (
			<Segment style={{padding: 25, ...style}} {...props}>
				<div>
					Termin von {moment(result.order_ranges.range_start).format('LLL')} Uhr bis {moment(result.order_ranges.range_end).format('LLL')} Uhr.
				</div>
				{loanCarId &&
				<Vehicle.Provider vehicle_id={loanCarId} placeholder={<Icon name={'car'} size={'large'} color={'grey'}/>}>{vehicle =>
					<div style={{marginTop: 10}}>
						<Icon name={'car'} size={'large'}/> {vehicle.name} / {vehicle.registration_shield} von {moment(loanCarRange.start).format('LLL')} Uhr bis {moment(loanCarRange.end).format('LLL')} Uhr.
					</div>
				}</Vehicle.Provider>
				
				}
				{consultantId && <Worker.Provider workers_id={consultantId} placeholder={<Icon name={'user'} size={'large'} color={'grey'}/>}>{worker =>
					<div style={{marginTop: 10}}><Icon name={'user'} size={'large'}/> {worker.name} <Image avatar src={worker.avatar}/> für {consultantDuration} Minuten am {moment(consultantRange.start).format('LLL')} Uhr.</div>
				}</Worker.Provider>}
			</Segment>
		);
	}
}

export class AppointmentList extends StatyComponent {
	static propTypes = {
		onNotificationBad: PropTypes.func,
		onFind: PropTypes.func,
		params: PropTypes.shape({
			deliver_date: PropTypes.any,
			target_date: PropTypes.any,
			loan_car: PropTypes.bool,
			direct_service: PropTypes.bool,
			vehicle_id: PropTypes.number.isRequired,
			services: PropTypes.arrayOf(PropTypes.number).isRequired
		}).isRequired,
		onSelect: PropTypes.func.isRequired
	};
	static defaultProps = {
		onNotificationBad: message => dispatchSnack(message, 'alert'),
		onFind: orderCall__find
	};
	state = {
		token: null,
		results: [],
		error: null,
		finding: false
	};
	
	componentDidMount() {
		super.componentDidMount();
		this.search();
	}
	
	search = async() => {
		const {onNotificationBad, onFind, params} = this.props;
		try {
			this.setState({finding: true, error: null});
			const response = await onFind(params);
			this.setState({token: response.token, results: response.list});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
			this.setState({error: e});
		} finally {
			this.setState({finding: false});
		}
	};
	
	render() {
		const {results, finding, token} = this.state;
		if (finding){
			return <EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER}><CircleNotchLoader style={{margin: 30}}/></EasyFlex>;
		}
		if (!results.length) {
			return <Message negative><p>Keine Ergebnisse gefunden</p></Message>;
		}
		return (
			<div style={{backgroundColor: COLORS.BACKGROUND}}>
				{results.map((result, index) =>
					<AppointmentOptionView onClick={this.props.onSelect.bind(null, {index, indexToken: result.result.token, token, ...result, result: {...result.result, ack: true}})} className={'hover pointer cursor'} key={result.result.token} {...result}/>
				)}
			</div>
		);
	}
}

export class OrderAppointmentFinder extends React.Component {
	static propTypes = {
		hasLoanCarModule: PropTypes.bool,
		onFetchClient: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onFind: PropTypes.func,
		onFindNext: PropTypes.func,
		onSave: PropTypes.func,
		onSaveForced: PropTypes.func,
		instantSearch: PropTypes.bool,
		t: PropTypes.func,
		useSelection: PropTypes.bool
	};
	static defaultProps = {
		hasLoanCarModule: true,
		onFetchClient: (client_id) => clientCall__collectOne(client_id),
		onNotificationBad: message => dispatchSnack(message, 'alert'),
		onFind: params => orderCall__find(params),
		onSave: params => orderCall__put(params),
		onSaveForced: params => orderCall__put_forced(params),
		t: val => val
	};
	static defaultState = {
		show_search: false,
		fetching_client: false,
		searching: false,
		saving: false,
		forcing: false,
		compact: false,
		client: null,
		vehicle: null,
		services: [],
		loan_car: false,
		loan_car_selection: null,
		consultant_selection: null,
		delivery_service: false,
		direct_service: true,
		wheel_service: false,
		waiting: false,
		worktime: 0,
		start_point: null,
		end_point: null,
		search_start: null,
		search_end: null,
		force_start: null,
		force_end: null,
		error: null,
		lookup: null,
		full_match: null,
		adjust: null,
		info: '',
		km: null,
		releasable: false,
		final_result: null,
		save_error: null,
		force_error: null,
		wheel: null,
		wheel_comment: ''
	};
	
	state = {
		...OrderAppointmentFinder.defaultState
	}
	
	reset = () => this.setState({...OrderAppointmentFinder.defaultState});
	
	get search_params() {
		return {
			deliver_date: this.state.start_point,
			target_date: this.state.end_point,
			vehicle_id: this.state.vehicle.vehicle_id,
			services: this.state.services.map(s => s.service_id),
			loan_car: this.state.loan_car,
			direct_service: this.state.direct_service,
			delivery_service: this.state.delivery_service,
			wheel_service: this.state.wheel_service
		};
	}
	
	get is_next_mechanism() {
		return !this.state.end_point;
	}
	
	get start_point() {
		return new Date(Math.max(Date.now(), this.state.start_point || 0))
	}
	
	get force_start() {
		return this.state.force_start;
	}
	
	get force_start_real() {
		return new Date(Math.max(this.force_start || 0, Date.now()));
	}
	
	get force_end() {
		return this.state.force_end;
	}
	
	isWorkRangeOkay = memoizeOne(
		(worktime, start_point, end_point) => {
			if (!end_point) {
				return true;
			}
			return moment(end_point).diff(moment(this.start_point), 'second') >= worktime;
		}
	);
	
	get unset_results() {
		return {
			search_start: null,
			search_end: null,
			error: null,
			force_error: null,
			lookup: null,
			full_match: null,
			adjust: null,
			force_start: null,
			force_end: null,
			wheel: null,
			comment: ''
		};
	}
	
	get min_end() {
		return moment(this.start_point).add(this.state.worktime, 'second');
	}
	
	get min_end_forced() {
		return moment(this.force_start_real).add(this.state.worktime, 'second');
	}
	
	get workrange_ok() {
		const {worktime, start_point, end_point} = this.state;
		return this.isWorkRangeOkay(worktime, start_point, end_point);
	}
	
	fetchClient = async (client_id) => {
		const {onFetchClient, onNotificationBad} = this.props;
		try {
			this.setState({fetching_client: true});
			const client = await onFetchClient(client_id);
			this.setClient(client);
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error || e.message);
		} finally {
			this.setState({fetching_client: false});
		}
	}
	
	find = () => {
		this.set('compact')(true);
		if (this.is_next_mechanism) {
			this.findNextAppointment();
		} else {
			this.findRangedAppointment();
		}
	}
	
	search = async() => {
		const {onNotificationBad, onFind, useSelection} = this.props;
		if (useSelection) {
			this.setState({show_search: true});
			return;
		}
		try {
			this.setState({searching: true, error: null});
			const response = await onFind(this.search_params);
			if (response.list.length) {
				const result = response.list[0];
				result.index = 0;
				result.indexToken = result.result.token;
				result.token = response.token;
				result.result = {
					...result.result,
					ack: true
				}
				this.setState({lookup: result.result, show_search: false, compact: true, full_match: result});
			} else {
				this.setState({lookup: {ack: false, result: null}, show_search:false, conpact:true, full_match: null, force_start: this.state.start_point || new Date(), force_end: this.state.end_point});
			}
			// this.setState({token: response.token, results: response.list});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
			handleCommonErrors(e) && this.setState({error: e});
		} finally {
			this.setState({searching: false});
		}
	};
	
	
	save = async () => {
		const {onSave} = this.props;
		const {full_match, loan_car_selection, consultant_selection, direct_service, loan_car, delivery_service, waiting, adjust, km, vehicle, info, releasable, wheel, wheel_comment} = this.state;
		const params = {
			...full_match,
			delivery_service,
			waiting,
			saveRange: {
				start: adjust.start_point,
				end: adjust.end_point
			},
			info,
			released: releasable,
			km: km ? km.trim() || null : null,
			update_km: !!km && Number(km) !== Number(vehicle.km),
			wheel_id: wheel ? wheel.wheel_id : null,
			wheel_comment: wheel_comment.trim() || null
		};
		params.delivery_service = delivery_service;
		if (loan_car && loan_car_selection) {
			params.loanCarId = loan_car_selection;
		}
		if (direct_service && consultant_selection) {
			params.consultantId = consultant_selection;
		}
		try {
			this.setState({saving: true, save_error: null});
			const final_result = await onSave(params);
			this.setState({final_result});
		} catch (e) {
			console.error(e);
			this.set('save_error')(e.error||e.message);
		} finally {
			this.setState({saving: false});
		}
	};
	
	
	saveForced = async() => {
		const {onSaveForced} = this.props;
		try {
			this.setState({forcing: true, force_error: null});
			const {
				vehicle,
				services,
				delivery_service,
				direct_service,
				waiting,
				info,
				km,
				releasable: released,
				force_start: deliver_date,
				force_end: target_date,
				wheel
			} = this.state;
			const params = {
				vehicle_id: vehicle.vehicle_id,
				services: services.map(s => s.service_id),
				delivery_service,
				direct_service,
				waiting,
				released,
				info,
				km: km ? km.trim() || null : null,
				update_km: !!km && Number(km) !== Number(vehicle.km),
				deliver_date,
				target_date,
				wheel_id: wheel ? wheel.wheel_id : null
			};
			const result = await onSaveForced(params);
			this.setState({final_result: result});
		} catch (e) {
			console.error(e);
			this.set('force_error')(e);
		} finally {
			this.setState({forcing: false});
		}
	};
	
	
	set = (index, modifier = s => s, callback) => (value) => this.setState({[index]: modifier(value)}, callback);
	setLoanCar = (loan_car) => this.setState({loan_car, ...this.unset_results});
	setDirectService = (direct_service) => this.setState({direct_service, ...this.unset_results});
	setDeliveryService = (delivery_service) => this.setState({delivery_service, ...this.unset_results});
	setWheelService = (wheel_service) => this.setState({wheel_service, ...this.unset_results});
	setDate = (index) => value => this.setState({[index]: value, lookup: null, adjust: null}, () => this.props.instantSearch && this.setState({show_search: true}));
	setClient = client => this.setState({client});
	setVehicle = vehicle => this.setState({vehicle, ...this.unset_results, km: 0, info: ''}, () => {
		if (vehicle) {
			this.fetchClient(vehicle.client_id);
		} else {
			this.setClient(null);
		}
	});
	setServices = services => this.setState({services, ...this.unset_results});
	
	getServiceMap = memoizeOne(
		services => array2object(services)(s => s.service_id)
	);
	
	updateAdjustment = (dates) => {
		this.setState({adjust: dates});
	};
	
	get valid_force() {
		return Boolean(
			this.force_start &&
			this.force_end &&
			!this.state.loan_car &&
			!this.state.direct_service &&
			!this.state.delivery_service &&
			this.force_end > this.min_end_forced.subtract(5, 'minutes') &&
			Boolean(this.state.wheel_service) === Boolean(this.state.wheel)
		);
	}
	
	get valid_save() {
		return Boolean(
			this.state.adjust &&
			this.state.lookup &&
			(!this.state.loan_car || this.state.loan_car_selection) && (!this.state.direct_service || this.state.consultant_selection)
			&& Boolean(this.state.wheel_service) === Boolean(this.state.wheel)
		);
	}
	
	get valid_search() {
		return !Boolean(this.state.delivery_service && this.state.direct_service);
	}
	
	render() {
		const {modules, t} = this.props;
		const {
			compact,
			client,
			vehicle,
			services,
			loan_car,
			wheel_service,
			// loan_car_selection,
			direct_service,
			delivery_service,
			waiting,
			start_point, search_start,
			end_point,
			// search_end,
			worktime,
			searching,
			saving,
			forcing,
			error,
			lookup,
			// adjust,
			final_result,
			save_error,
			force_error
		} = this.state;
		if (final_result) {
			return <FinalResult result={final_result}>
				<Button.Group attached={'bottom'}>
					<Button color={'brown'} onClick={this.reset}>Neuer Auftrag</Button>
				</Button.Group>
			</FinalResult>;
		}
		const rangeError = !this.workrange_ok;
		return (
			<div>
				<div style={{display: compact ? 'none' : 'block'}}>
					<ClientView client={client}/>
					<OrderVehicleSelector defaultOpen vehicle={vehicle} onSelectVehicle={this.setVehicle} onUnselect={this.setVehicle.bind(null, null)}/>
					{vehicle && <div>
						<OrderServiceSelector vehicle={vehicle} onChange={this.setServices} asGrid sortable/>
						{trueNull(services.length) && <div>
							<OrderServiceSelectionList
								vehicle={vehicle}
								services={this.getServiceMap(services)}
								updateOnChange
								style={{marginBottom: 20}}
								onNotifyWorktime={this.set('worktime', w => w * 3600)}
							/>
							<OrderOptionsView
								hasLoanCarModule={modules.loancar}
								hasWheelModule={modules.wheel}
								loanCar={loan_car}
								deliveryService={delivery_service}
								directService={direct_service}
								waiting={waiting}
								setLoanCar={this.setLoanCar}
								setWaiting={this.set('waiting')}
								setDeliveryService={this.setDeliveryService}
								setDirectService={this.setDirectService}
								wheelService={wheel_service}
								setWheelService={this.setWheelService}
								segmentProps={{basic: true, color: null}}
								celled={'internally'}
							/>
						
						</div>}
					</div>}
				</div>
				{trueNull(compact) && <CompactView reset={() => this.set('compact')(false)} client={client} vehicle={vehicle} services={services} worktime={worktime}/>}
				{vehicle && trueNull(services.length) && <Segment.Group>
					{rangeError && <Message negative attached={'top'} style={{textAlign: 'center'}}>
						<p>
							Bis zur ihrer gewählten Zielzeit <strong>{moment(end_point).format('LLL')}</strong> kann der Auftrag nicht erfüllt werden.
							<br/>
							Die Dauer der Service übertrifft die gewählte Zeitspanne.
						</p>
					</Message>}
					<Segment style={{paddingBottom: 35, position: 'relative'}}>
						{/*<DateRangeSelector*/}
						{/*	start_point={start_point}*/}
						{/*	end_point={end_point}*/}
						{/*	changeStartPoint={this.setDate('start_point')}*/}
						{/*	changeEndPoint={this.setDate('end_point')}*/}
						{/*	endChildren={<div style={{textAlign: 'center', color: COLORS.SEMANTIC_RED}}>Nicht vor <strong>{this.min_end.format('LLL [Uhr]')}</strong></div>}*/}
						{/*/>*/}
						<MaterialRangeSelector
							strict
							startPoint={start_point}
							endPoint={end_point}
							changeStartPoint={this.setDate('start_point')}
							changeEndPoint={this.setDate('end_point')}
							endChildren={<div style={{textAlign: 'center', color: COLORS.SEMANTIC_RED}}>Nicht vor <strong>{this.min_end.format('LLL [Uhr]')}</strong></div>}
						/>
						{falseNull(compact) && <Button icon={'compress'} basic onClick={() => this.set('compact')(true)} style={{position: 'absolute', top: -1, right: -4}}/>}
					</Segment>
					{error && <NegativeSegment>
						<p>{t(error.error || error.message || error, get(error, 'params', {}))}</p>
					</NegativeSegment>}
					{falseNull(this.valid_search) && <NegativeSegment>
						<p>Bitte Direktannahme oder Bring- & Abholsservice wählen, nicht beides.</p>
					</NegativeSegment>}
					<Button attached={'bottom'} style={{pointerEvents: searching ? 'none' : null}} disabled={!this.valid_search} loading={searching} secondary onClick={this.search}>Suchen</Button>
				</Segment.Group>}
				{this.state.show_search && <Modal defaultOpen onClose={() => this.setState({show_search: false})} centered={false}>
					<Modal.Header>Terminoptionen</Modal.Header>
					<Modal.Content scrolling>
						<AppointmentList
							params={this.search_params}
							onFind={this.props.onFind}
							onSelect={(result) => {
								this.setState({lookup: result.result, show_search: false, compact: true, full_match: result});
							}}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({show_search: false})}>Schließen</Button>
					</Modal.Actions>
				</Modal>}
				{lookup &&
				<div>
					{/*{this.is_next_mechanism ?*/}
						<NextAppointmentResult
							importValue={vehicle.registration_mark}
							clientId={client.client_id}
							wheelService={wheel_service}
							info={this.state.info}
							matchData={this.state.full_match}
							loanCar={loan_car}
							directService={direct_service}
							deliveryService={delivery_service}
							data={lookup}
							error={error}
							style={{marginTop: 30}}
							onChange={this.updateAdjustment}
							defaultStart={search_start}
							onSelectLoanCar={this.set('loan_car_selection')}
							onSelectConsultant={this.set('consultant_selection')}
							km={this.state.km || (vehicle ? vehicle.km : this.state.km)}
							onUpdateKm={this.set('km')}
							onUpdateInfo={this.set('info')}
							onUpdateReleasable={this.set('releasable')}
							onSelectWheel={(wheel, comment) => this.setState({wheel, wheel_comment: comment})}
						>
							{trueNull(lookup.ack) && <Button attached={'bottom'} loading={saving} positive onClick={this.save} disabled={!this.valid_save}>Speichern</Button>}
							{falseNull(lookup.ack) &&
							<NegativeSegment>
								{falseNull(loan_car) && <p>
									Sie können versuchen den Auftrag trotzdem einzutakten. <br/>
									Jeder außergewöhnliche Eintragung kann die Balance stören. Sollten notwendige Ressourcen innerhalb der geünschten Zeitspanne nicht verfügbar sein,
									kann der Eintrag trotzdem scheitern.
								</p>}
								{trueNull(loan_car) && <p>
									Es wurde kein Termin mit Leihwagen gefunden. Dies kann auch nicht erzwungen werden.
								</p>}
								{trueNull(direct_service) && <p>
									Direktannahmen können nicht erzwungen werden.
								</p>}
								{trueNull(delivery_service) && <p>
									Bring- & Abholservice kann nicht erzwungen werden.
								</p>}
							</NegativeSegment>}
							{save_error && <NegativeSegment>
								<Header as={'h5'}>Fehler beim Speichern</Header>
								<p>{t(save_error)}</p>
							</NegativeSegment>}
							{falseNull(lookup.ack) && <Segment>
								<MaterialRangeSelector
									strict
									startPoint={this.force_start}
									endPoint={this.force_end}
									changeStartPoint={this.set('force_start')}
									changeEndPoint={this.set('force_end')}
									endChildren={<div style={{textAlign: 'center', color: COLORS.SEMANTIC_RED}}>Nicht vor <strong>{this.min_end_forced.format('LLL [Uhr]')}</strong></div>}
									startProps={{dateLabel: 'Annahme'}}
									endProps={{dateLabel: 'Abgabe'}}
								/>
							
							</Segment>}
							{force_error && <NegativeSegment>
								<Header as={'h5'}>Fehler beim Forcieren</Header>
								<p>{t(force_error)}</p>
							</NegativeSegment>}
							{falseNull(lookup.ack) && <Button attached={'bottom'} loading={forcing} disabled={!this.valid_force} color={'orange'} onClick={this.saveForced}>Eintrag erzwingen</Button>}
						</NextAppointmentResult>
				</div>
				}
			</div>
		);
	}
}

OrderAppointmentFinder = withModule(OrderAppointmentFinder);
OrderAppointmentFinder = translate()(OrderAppointmentFinder);

export const ConnectedOrderAppointmentFinder = connect(
	null,
	dispatch => ({
		onFetchClient: client_id => dispatch(clientAction__collectOne(client_id)),
		onSave: params => dispatch(orderAction__put(params)),
		onSaveForced: params => dispatch(orderAction__putForced(params))
	})
)(OrderAppointmentFinder);