import React from 'react';
import {translate} from "react-i18next";
import {IMAGE_URL, PRODUCTION_MODE, SECTION, SECTIONS, SHOW_DEV_PRODUCTION} from "../../Logic/constants";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import User from "../../models/User";
import AvatarM from '../../images/avatar-m.gif';
import AvatarF from '../../images/avatar-f.png';
import DevImage from "../../images/dev.png";
import {withModule} from "../../Tools/RightsProvider";

const filter = user => (section) => user.hasAccessTo(section.id, User.RIGHT.READ);
const avatar = gender => gender === 'm' ? AvatarM : AvatarF;
const profile = userData => userData.avatar ? `${IMAGE_URL}${userData.avatar.ident}.${userData.avatar.origin_extension}?${userData.avatar.updated_at}` : avatar(userData.gender);
const error = userData => e => e.target.src = avatar(userData.gender);

const DashView = withModule(translate()(({t, i18n, user, modules}) => !user ? null : (
	<div>
		<ul id="dash__sections">
			<li>
				<NavLink to={`/workers/detail/${user.getData().workers_id}`}>
					<div className="head">
						<img src={profile(user.getData())} alt={t('dash.your-profile')} onError={error(user.getData())}/>
					</div>
					<div className="body">
						{t('dash.your-profile')}
					</div>
				</NavLink>
			</li>
			{SECTIONS.filter(filter(user)).map(section => {
				if (section.id === SECTION.ASSISTANCE && !modules.assist) {
					return null
				}
				return <li key={section.link}>
					<NavLink to={section.link}>
						<div className="head">
							<img src={section.img} alt={t(section.translate)}/>
						</div>
						<div className="body">
							{t(section.translate)}
						</div>
					</NavLink>
				</li>
			})}
			{(!PRODUCTION_MODE || SHOW_DEV_PRODUCTION) &&
				<li>
					<NavLink to="/dev">
						<div className="head">
							<img src={DevImage} alt={"Development Section"}/>
						</div>
						<div className="body">DevSection</div>
					</NavLink>
				</li>
			}
		</ul>
	</div>
)));

const m2s = state => ({
	user: state.user.data ?  User._(state.user.data) : null
});

export default connect(m2s)(DashView);