/* eslint-disable */
import React from 'react'
import {HouseClosedLink, IconMenu} from "../../Logic/MiniMenu";
//
// const Overlay = ({open, anchor, children}) => {
// 	if (open !== undefined && !open) return null
// 	if (!anchor) return null
//
// 	return <div style={{position: 'absolute'}}>
// 		<pre>{JSON.stringify({bounds: anchor.getBoundingClientRect()})}</pre>
// 		{children}
// 	</div>
// }



export const ComponentTest = () => {
	return <div>
		{/*<IconMenu padded closeOnClick>Testsadasdadsd</IconMenu>*/}
		<IconMenu padded closeOnClick><button onClick={() => console.debug('BUTTON Click')}>Test</button></IconMenu>
		<IconMenu closeOnClick>
			<HouseClosedLink/>
		</IconMenu>
		{/*<Popup trigger={<IconButton><IconMore/></IconButton>} content={<HouseClosedLink/>} on={['click']}/>*/}
		{/*<IconButton onClick={(e) => {*/}
		{/*	setAnchor(e.currentTarget)*/}
		{/*	setShow(true)*/}
		{/*}}><IconMore/></IconButton>*/}
		{/*{show && <Popover*/}
		{/*	open*/}
		{/*	anchorEl={anchor}*/}
		{/*	onRequestClose={() => setShow(false)}*/}
		{/*	targetOrigin={{horizontal: 'right', vertical: 'top'}}*/}
		{/*	anchorOrigin={{horizontal: 'right', vertical: 'top'}}*/}
		{/*>*/}
		{/*	<HouseClosedLink/>*/}
		{/*</Popover>}*/}
	</div>
}