import * as React from "react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {Segment} from "semantic-ui-react";
import {OrderChangeTimesView} from "../../cointainer/intern/orders/OrderChangeTimesView";
import {Order} from "../../models";

export class OrderMoveTest extends React.Component {
	state = {};
	
	componentDidMount() {
	
	}
	
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Order-Move-Test</ActionHeading>
						
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic>
					<Order.CollectProvider ids={[747]}>{([order]) =>
						<div>
							<pre>{order.post_process_duration}</pre>
							<OrderChangeTimesView id={"order-change-form"} order={order} flexProps={{align: "center"}}/>
						</div>
					}</Order.CollectProvider>
				</Segment>
			</div>
		);
	}
}