import React from 'react'
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeading} from "../partials/ActionHeader";
import {MDCalendarSetting} from "../intern/master-data/base/MDCalendarSetting";


export const HouseScheduleTest = () => {
	
	return <Segment basic style={{paddingTop: 0, paddingBottom: 0}}>
		<ActionHeader alignment={'flex-start'}>
			<ActionBackButton/>
			<ActionHeading>House Schedule</ActionHeading>
		</ActionHeader>
		<MDCalendarSetting supportOnly/>
	</Segment>
}