/////////////
//      Quick Service
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {Item, Message, Segment} from "semantic-ui-react";
import {If} from "../../../../Logic/Switch";
import {applyPadding, calculateDifferenceBySeconds} from "../../../../components/partials/Countdown";
import round from "round-precision";
import {logoLink, Pad} from "../../../../Logic/extensions";
import moment from "../../../../Logic/Moment";
import {ConnectedWorkerAvatarWorkingView} from "../../workers";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__fetchOne} from "../../../../actions/orderActions";
import {kfzAction__fetchServices} from "../../../../actions/kfzActions";
import {workerAction__getWork} from "../../../../actions/workerActions";

export class OrderQuickService extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		services: PropTypes.arrayOf(PropTypes.object),
		serviceMap: PropTypes.object,
		work: PropTypes.arrayOf(PropTypes.object),
		workHistory: PropTypes.arrayOf(PropTypes.object)
	};
	static defaultProps = {
		order: {},
		services: [],
		serviceMap: {},
		work: [],
		workHistory: []
	};
	
	render() {
		const {
			order,
			services,
			serviceMap,
			work,
			workHistory
		} = this.props;
		if (isEmpty(serviceMap) || isEmpty(order)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		return (
			<div style={{marginTop: 25}}>
				<Item.Group relaxed divided>
					<If condition={services.length === 0}>
						<Item key={"missing-services"}>
							<Item.Content>
								<Item.Header>
									<Message negative>Keine Services zugwiesen!</Message>
								</Item.Header>
							</Item.Content>
						</Item>
					</If>
					{services.map(serviceInfo => {
						const service = serviceMap[serviceInfo.service_id];
						const serviceWork = work.filter(w => w.service_id === service.service_id);
						const serviceWorkHistory = workHistory.filter(w => w.service_id === service.service_id);
						let workDone = serviceWorkHistory.reduce((carry, work) => carry + work.duration, 0);
						const [, , workedHours, workedMinutes, workedSeconds] = applyPadding(calculateDifferenceBySeconds(workDone));
						workDone = round(workDone / 3600, 1);
						return (
							<Item key={serviceInfo.order_service_id}>
								<Item.Image size={"small"} src={logoLink(service)} alt={service.title}/>
								<Item.Content>
									<Item.Header>{service.title} /{service.service_id}</Item.Header>
									<Item.Description>{service.description}</Item.Description>
									<Item.Extra><Pad count={35}>Status:</Pad> {serviceInfo.finished_at ? `${moment(serviceInfo.finished_at).format("LLL")} abgeschlossen` : 'Unfertig'}</Item.Extra>
									<Item.Extra><Pad count={35}>Zeit angesetzt (Kunde):</Pad> {round(serviceInfo.hours_of_work, 1)} Stunden</Item.Extra>
									<Item.Extra><Pad count={35}>Zeit angesetzt (Mitarbeiter):</Pad> {round(serviceInfo.hours_of_work_resource, 1)} Stunden</Item.Extra>
									<Item.Extra><Pad count={35}>Zeit gearbeitet:</Pad> {workDone} Stunden /{workedHours}:{workedMinutes}:{workedSeconds}</Item.Extra>
									<Item.Extra><Pad count={35}>Preis /Stunde:</Pad> € {round(serviceInfo.price, 2)}</Item.Extra>
								</Item.Content>
								<Item.Content>
									<div style={{marginTop: 15, marginBottom: 15}}>
										{/*{serviceWorkHistory.map(work => {
											const start = moment(work.start_point).format("LLL");
											const end = moment(work.end_point).format("LLL");
											const [, , hours, minutes, seconds] = applyPadding(calculateDifferenceBySeconds(work.duration));
											return <Flex key={work.work_id} valign={"center"} wrap>
													<ConnectedWorkerAvatarView workerId={work.workers_id}>Mitarbeiter ({work.workers_id})</ConnectedWorkerAvatarView>
													<span>&nbsp;hat von {start} bis {end} für {hours}:{minutes}:{seconds} gearbeitet.</span>
											</Flex>;
										})}*/}
										{serviceWork.map(work => {
											return (
												<ConnectedWorkerAvatarWorkingView key={work.work_id} workerId={work.workers_id} since={work.start_point}>
													Mitarbeiter ({work.workers_id}) seit {moment(work.start_point).format("LLL")}
												</ConnectedWorkerAvatarWorkingView>
											);
										})}
									</div>
									{serviceWork.length === 0 ? <Item.Meta>Aktuell unbearbeitet.</Item.Meta> : null}
								</Item.Content>
							</Item>
						);
					})}
				</Item.Group>
			</div>
		);
	}
}

OrderQuickService = withInit(OrderQuickService);
OrderQuickService = connect(
	(state, props) => {
		const orderID = props.order_id;
		const order = state.appointments.orders[orderID] || {};
		let services = state.appointments.order2services[orderID] || [];
		services = services.map(sid => state.appointments.services[sid] || {});
		return {
			order,
			services,
			serviceMap: state.kfz.service.list,
			work: state.workers.work[orderID] || [],
			workHistory: state.workers.work_history[orderID] || []
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__fetchOne(props.order_id));
			dispatch(kfzAction__fetchServices());
			dispatch(workerAction__getWork(props.order_id));
		}))
	})
)(OrderQuickService);