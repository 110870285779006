import * as React from "react";
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import {TextField} from "material-ui";
import {falseNull, trueNull} from "../Logic/extensions";
import {WrappedInput} from "../components/partials/InputWrapper";
import {StatyComponent} from "./ReactExtension";
import PropTypes from "prop-types";
import {moment} from "../Logic/Moment";
import {parse, set} from "date-fns";
import {EasyFlex} from "../components/partials/ActionHeader";
import {isMobile} from "react-device-detect";
import uuid from 'uuid';
import {deepMemoize as memoizeOne} from "../Logic/extensions";

export const FORMAT_DATE_DEFAULT = 'dd.MM.yyyy';
export const FORMAT_DATE_SHORT_MONTH = 'dd. MMM yyyy';
export const FORMAT_DATE_DEFAULT_WITH_DAY = 'EEEE, ' + FORMAT_DATE_DEFAULT;
export const FORMAT_DATE_SHORT_MONTH_WITH_DAY = 'EEEE, ' + FORMAT_DATE_SHORT_MONTH;


export const DefaultDatePicker = ({className, dateFormat = FORMAT_DATE_DEFAULT, ...props}) => <DatePicker dateFormat={dateFormat} className={cn('default', className)} {...props}/>

const CustomInput = React.forwardRef((props, ref) => <TextField {...props} ref={ref}/>)

export const withMaterial = (Component, hocProps = {}) =>
	({placeholderText, floating = false, materialProps, dateFormat = FORMAT_DATE_DEFAULT,  ...props}) =>
	(<Component showWeekNumbers dateFormat={dateFormat} placeholderText={falseNull(floating) && placeholderText} {...props} customInput={
		<CustomInput inputStyle={{cursor: 'pointer'}} {...hocProps} {...materialProps} floatingLabelText={trueNull(floating) && placeholderText} />
	}/>);

export const withInputWrapper = (Component) =>
	({wrapperProps, dateFormat = FORMAT_DATE_DEFAULT, ...props}) =>
		(<Component dateFormat={dateFormat} {...props} customInput={<WrappedInput {...wrapperProps}/>}/>)

export const withMobileAwareness = (Component) => ({withPortal, onFocus, ...props}) => (
	<Component
		showWeekNumbers
		withPortal={withPortal || isMobile}
		onFocus={e => {
			onFocus && onFocus(e);
			isMobile && e.target.blur();
		}}
		{...props}
	/>);

export const MaterialPicker = withMaterial(DatePicker);
export const MaterialMobileAwarePicker = withMobileAwareness(MaterialPicker);

export class TimeInput extends StatyComponent {
	static propTypes = {
		id: PropTypes.string,
		children: PropTypes.element,
		inputProps: PropTypes.object,
		baseDate: PropTypes.instanceOf(Date),
		time: PropTypes.instanceOf(Date),
		format: PropTypes.string.isRequired,
		onChange: PropTypes.func,
		onProvide: PropTypes.func,
		required: PropTypes.bool,
		placeHolderOnFocus: PropTypes.bool
	};
	static defaultProps = {
		children: <input className={'cursor pointer'}/>,
		inputProps: {type: 'text'},
		format: "HH:mm",
		baseDate: new Date()
	};
	
	state = {
		value: '',
		focus: false
	};
	last = '';
	key = memoizeOne(
		key => key || uuid.v4()
	);
	
	componentDidMount() {
		super.componentDidMount();
		if (this.props.time) {
			const value = this.value;
			this.setState({value});
			this.last = value;
		}
	}
	
	get time() {
		return this.props.time ? moment(this.props.time).format(this.props.format) : '';
	}
	
	get value() {
		const {value, focus} = this.state;
		if (focus) {
			return value;
		}
		if (this.props.time) {
			return moment(this.props.time).format(this.props.format)
		}
		return '';
	}
	
	get placeholder() {
		return this.props.placeHolderOnFocus  ? (this.state.focus ? this.props.inputProps.placeholder || this.props.placeholder : null) : (this.props.inputProps.placeholder || this.props.placeholder);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((prevProps.time instanceof Date && this.props.time instanceof Date && prevProps.time.valueOf() !== this.props.time.valueOf()) || Boolean(prevProps.time) !== Boolean(this.props.time)) {
			if (this.props.time) {
				this.setState({value: moment(this.props.time).format('HHmm')});
			} else {
				this.setState({value: ''});
			}
		}
	}
	
	get focused() {
		return this.state.value;
	}
	
	get inputType() {
		return this.state.focus ? 'number' : this.props.inputProps.type;
	}
	
	transform = value => {
		let format = 'Hmm';
		value = value.replace(/\D/g, '').trim();
		switch (value.length) {
			case 1:
			case 2: format = 'H'; break;
			case 3:
			case 4: format = 'Hmm'; break;
			default: return null;
		}
		value = parse(value, format, this.props.time || this.props.baseDate || new Date());
		if (value.valueOf()) {
			return moment(value);
		}
		return null;
	};
	
	format = (value) => value.format(this.props.format);
	
	editable = value => {
		return value.replace(/:/g, '');
	}
	
	onFocus = (callback) => e => {
		callback && callback(e);
		if (e.defaultPrevented){
			return;
		}
		const target = e.target;
		let value = this.editable(this.focused);
		this.setState({focus: true, value}, () => {
			target.select();
		});
		
	};
	
	
	onBlur = callback => e => {
		callback && callback(e);
		if (e.defaultPrevented) {
			return;
		}
		let value = this.transform(e.target.value);
		if (value) {
			this.setState({focus: false, value: value.format(this.props.format)});
			this.props.onProvide && this.props.onProvide(value.toDate());
		} else {
			this.setState({focus: false, value: this.time});
		}
		e.target.blur();
	}
	
	onChange = callback => e => {
		callback && callback(e);
		if (e.defaultPrevented) {
			return;
		}
		const value = e.target.value;
		this.setState({value});
	}
	
	testKey = value => {
		return Boolean(value.match(/(\d|:|Control|ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Tab|Backspace|Delete|\+|-)/));
	}
	
	onKeyDown = callback => e => {
		callback && callback(e);
		if (e.defaultPrevented) {
			return;
		}
		if (!this.testKey(e.key)) {
			e.preventDefault();
			return;
		}
		
		// console.warn('key is', e.key, e.key.match(/(\d|:)/));
		const target = e.target;
		if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === '+' || e.key === '-') {
			let value = this.transform(e.target.value);
			if (!value) {
				// return;
				value = moment(this.props.baseDate || new Date());
			}
			e.preventDefault();
			const metric = e.ctrlKey ? 'hour' : 'minute';
			if (e.key === 'ArrowUp' || e.key === '+') {
				value.add(1, metric);
			} else {
				value.subtract(1, metric);
			}
			value = this.editable(this.format(value));
			this.setState({value}, () => target.select());
		}
	}
	
	
	onKeyUp = callback => e => {
		callback && callback(e);
		if (e.defaultPrevented) {
			return;
		}
		const which = e.which;
		if (which === 13) {
			this.onBlur(this.props.onBlur)(e);
		}
	}
	
	
	render() {
		const {children, id, inputProps, onFocus, onChange, onBlur, onKeyUp, onKeyDown, time, onProvide, baseDate, placeHolderOnFocus, ...props} = this.props;
		return (
			<div>
				{React.cloneElement(children, {
					...inputProps,
					id: this.key(id),
					onFocus: this.onFocus(onFocus),
					onChange: this.onChange(onChange),
					onBlur: this.onBlur(onBlur),
					onKeyUp: this.onKeyUp(onKeyUp),
					onKeyDown: this.onKeyDown(onKeyDown),
					value: this.value,
					...props,
					placeholder: this.placeholder,
				})}
			</div>
		);
	}
}

export class MaterialCompoundDateInput extends StatyComponent {
	static propTypes = {
		dateComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(React.Component), PropTypes.instanceOf(React.PureComponent)]),
		className: PropTypes.string,
		required: PropTypes.bool,
		date: PropTypes.instanceOf(Date),
		baseDate: PropTypes.instanceOf(Date),
		onChange: PropTypes.func,
		dateLabel: PropTypes.node,
		timeLabel: PropTypes.node,
		timePlaceholder: PropTypes.string,
		endOfDay: PropTypes.bool,
		minDate: PropTypes.instanceOf(Date),
		maxDate: PropTypes.instanceOf(Date),
		startDate: PropTypes.instanceOf(Date),
		endDate: PropTypes.instanceOf(Date),
		dateFormat: PropTypes.string,
		excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		includeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		highlightDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		filterDate: PropTypes.func,
		selectsStart: PropTypes.bool,
		selectsEnd: PropTypes.bool,
		disabled: PropTypes.bool,
		disabledKeyboardNavigation: PropTypes.bool,
		readOnly: PropTypes.bool,
		popperClassName: PropTypes.string,
		popperPlacement: PropTypes.string,
		popperModifiers: PropTypes.object,
		withPortal: PropTypes.bool,
		portalId: PropTypes.string,
		showWeekNumbers: PropTypes.bool,
		children: PropTypes.node,
		strictParsing: PropTypes.bool,
		monthsShown: PropTypes.number,
		timeStyle: PropTypes.object,
		timeProps: PropTypes.object
	};
	static defaultProps = {
		date: null,
		dateLabel: 'Datum',
		timeLabel: 'Zeit',
		showWeekNumbers: true,
		dateComponent: MaterialMobileAwarePicker
	};
	state = {
		date: null
	}
	
	get openToDate() {
		const {selectsStart, selectsEnd, startDate, endDate} = this.props;
		if (selectsStart) {
			return this.state.date || startDate || endDate || null;
		}
		if (selectsEnd) {
			return this.state.date || endDate || startDate || null;
		}
		return null;
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.setState({date: this.props.date});
	}
	
	componentDidUpdate({date}, prevState, snapshot) {
		if ((date && this.props.date && date.valueOf() !== this.props.date.valueOf()) || Boolean(date) !== Boolean(this.props.date)) {
			this.setState({date: this.props.date});
		}
	}
	
	handleChangeDate = (date) => {
		this.setState(state => {
			if (date) {
				if (state.date) {
					date = set(state.date, {year: date.getFullYear(), month: date.getMonth(), date: date.getDate()});
				} else {
					const update = this.props.endOfDay ? {hours: 23, minutes: 59, seconds: 0} : {hours: 0, minutes: 0, seconds: 0};
					date = set(date, update);
				}
			}
			return {date};
		}, () => this.props.onChange && this.props.onChange(this.state.date));
	};
	
	handleChangeTime = date => {
		this.setState(state => {
			if (state.date) {
				date = set(state.date, {hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds()});
			}
			return {date};
		}, () => this.props.onChange && this.props.onChange(this.state.date));
	};
	
	render() {
		const {date} = this.state;
		const {
			date: _,
			className,
			onChange,
			dateLabel,
			timeLabel,
			timePlaceholder,
			endOfDay,
			minDate,
			maxDate,
			startDate,
			endDate,
			dateFormat,
			excludeDates,
			includeDates,
			highlightDates,
			filterDate,
			selectsStart,
			selectsEnd,
			disabled,
			disabledKeyboardNavigation,
			readOnly,
			popperClassName,
			popperPlacement,
			popporModifiers,
			withPortal,
			portalId,
			showWeekNumbers,
			children,
			strictParsing,
			monthsShown,
			timeStyle,
			timeProps,
			required,
			baseDate,
			dateComponent,
			...props
		} = this.props;
		const Picker = dateComponent
		return (
			<EasyFlex className={cn('material-compound-date-input', className, {'readonly': readOnly})} {...props}>
				<Picker
					floating
					required={required}
					placeholderText={dateLabel}
					materialProps={{style: {width: 100}}}
					selected={date}
					onChange={this.handleChangeDate}
					minDate={minDate}
					maxDate={maxDate}
					startDate={startDate}
					endDate={endDate}
					openToDate={this.openToDate}
					dateFormat={dateFormat}
					excludeDates={excludeDates}
					includeDates={includeDates}
					highlightDates={highlightDates}
					filterDate={filterDate}
					selectsStart={selectsStart}
					selectsEnd={selectsEnd}
					disabled={disabled}
					disabledKeyboardNavigation={disabledKeyboardNavigation}
					readOnly={readOnly}
					popperClassName={popperClassName}
					popperPlacement={popperPlacement}
					poppperModifiers={popporModifiers}
					withPortal={withPortal || isMobile}
					portalId={portalId}
					showWeekNumbers={showWeekNumbers}
					children={children}
					strictParsing={strictParsing}
					onFocus={e => isMobile ? e.target.blur() : null}
					monthsShown={monthsShown}
				/>
				<TimeInput
					floatingLabelText={timeLabel}
					time={date}
					required={required}
					style={{width: 80, ...timeStyle}}
					onProvide={this.handleChangeTime}
					disabled={disabled}
					readOnly={readOnly}
					placeholder={timePlaceholder}
					baseDate={baseDate}
					{...timeProps}
				>
					<TextField/>
				</TimeInput>
			</EasyFlex>
		);
	}
}