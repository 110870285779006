import * as React from 'react';
import PropTypes from 'prop-types';
import {subscribe, unsubscribe} from "../../Logic/PubSub";
import {PROC_CALLBACK_REQUEST, PROC_FEEDBACK_REPORT, SUB_SHOW_CALLBACK, SUB_SHOW_FEEDBACK} from "../../actions";
import {Dialog, TextField} from "material-ui";
import {Button, Form, Icon, Label, Segment} from "semantic-ui-react";
import {Flex} from "../../components/partials/ActionHeader";
import {translate} from "react-i18next";
import {connect} from "react-redux";
import {globals} from "ah-tools";
import isFunc from "lodash/isFunction";
import {isLoading, setLoader, unsetLoader} from "../../actions/loaderActions";
// import {PUT} from "ith-fetch";
import {PUT} from "../../Logic/fetch";
import {addError} from "../../actions/errorActions";
import {addSnackbar} from "../../actions/snackbarActions";

export const callbackRequest = (telephone, onSuccess) => async dispatch => {
	try {
		dispatch(setLoader(PROC_CALLBACK_REQUEST));
		await PUT('/dev/user-callback', {telephone});
		isFunc(onSuccess) && onSuccess();
	} catch (e) {
		dispatch(addError(e));
	} finally {
		dispatch(unsetLoader(PROC_CALLBACK_REQUEST));
	}
};

export const reportFeedback = (message, onSuccess) => async dispatch => {
	try {
		dispatch(setLoader(PROC_FEEDBACK_REPORT));
		await PUT('/dev/user-feedback', {message});
		isFunc(onSuccess) && onSuccess();
	} catch (e) {
		dispatch(addError(e));
	} finally {
		dispatch(unsetLoader(PROC_FEEDBACK_REPORT));
	}
};

class Feedback extends React.Component {
	static propTypes = {
		onRequestCallback: PropTypes.func.isRequired,
		onRequestFeedback: PropTypes.func.isRequired,
		awaitingCallback: PropTypes.bool,
		awaitingFeedback: PropTypes.bool
	};
	static defaultProps = {
		onRequestCallback: () => alert('onRequestCallback() is not implemented yet'),
		onRequestFeedback: () => alert('onRequestFeedback() is not implemented yet'),
		awaitingCallback: false,
		awaitingFeedback: false
	};
	
	state = {
		feedback: false,
		feedback_message: '',
		callback: false,
		phone: ''
	};
	
	feedbackSub = null;
	callbackSub = null;
	
	handleFeedback = feedback => this.setState({feedback});
	handleCallback = callback => this.setState({callback});
	
	hideFeedback = () => this.handleFeedback(false);
	hideCallback = () => this.handleCallback(false);
	
	handleFeedbackMessage = (e, feedback_message) => this.setState({feedback_message});
	handleCallbackPhone = (e, phone) => this.setState({phone});
	
	componentDidMount() {
		this.feedbackSub = subscribe(SUB_SHOW_FEEDBACK, this.handleFeedback);
		this.callbackSub = subscribe(SUB_SHOW_CALLBACK, this.handleCallback);
	}
	
	componentWillUnmount() {
		unsubscribe(SUB_SHOW_FEEDBACK, this.feedbackSub);
		unsubscribe(SUB_SHOW_CALLBACK, this.callbackSub);
	}
	
	handleCallbackSubmit = () => {
		this.props.onRequestCallback(this.state.phone, () => {
			this.hideCallback();
			this.handleCallbackPhone(null, '');
		});
	};
	
	handleFeedbackSubmit = () => {
		this.props.onRequestFeedback(this.state.feedback_message, () => {
			this.hideFeedback();
			this.handleFeedbackMessage(null, '');
		});
	};
	
	render() {
		const {t, awaitingCallback, awaitingFeedback} = this.props;
		const {feedback, feedback_message, callback, phone} = this.state;
		return(
			<React.Fragment>
				{feedback && <Dialog open bodyStyle={{background: globals.COLOR_SECONDARY, padding: 0, overflow: 'visible', position: 'relative'}} onRequestClose={this.hideFeedback}>
					<Segment inverted basic padded={'very'} loading={awaitingFeedback}>
						<div style={{height: 50}}/>
						<Form onSubmit={this.handleFeedbackSubmit}>
							<TextField
								floatingLabelText={t('feedback.your-feedback')}
								placeholder={t('feedback.feedback-placeholder')}
								multiLine
								fullWidth
								textareaStyle={{color: 'white'}}
								floatingLabelFixed
								floatingLabelStyle={{color: '#54c8ff'}}
								value={feedback_message}
								onChange={this.handleFeedbackMessage}
							/>
							<Flex align={'flex-end'} style={{marginTop: 20}} wrap={'wrap'}>
								<Button inverted color={'grey'} style={{margin: 10}} onClick={this.hideFeedback}>{t('actions.cancel')}</Button>
								<Button color={'blue'} style={{margin: 10}} disabled={feedback_message.trim() === ''} inverted><Icon name="paper plane"/> {t('actions.send')}</Button>
							</Flex>
						</Form>
					</Segment>
					<Label style={{position: 'absolute', left: 'calc(100% + 14px)', marginTop: 'auto', top: 35, zIndex: 9999999}} color="blue" ribbon="right" content={t('feedback.feedback')}/>
				</Dialog>}
				
				<Dialog open={callback} bodyStyle={{background: globals.COLOR_SECONDARY, padding: 0, overflow: 'visible', position: 'relative'}} onRequestClose={this.hideCallback}>
					<Segment inverted basic padded={'very'} loading={awaitingCallback}>
						<div style={{height: 50}}/>
						<Form onSubmit={this.handleCallbackSubmit}>
							<TextField
								required
								floatingLabelText={t('feedback.your-telephone')}
								placeholder={t('feedback.telephone-placeholder')}
								fullWidth
								inputStyle={{color: 'white'}}
								floatingLabelFixed
								floatingLabelStyle={{color: '#54c8ff'}}
								value={phone}
								onChange={this.handleCallbackPhone}
							/>
							<Flex align={'flex-end'} style={{marginTop: 20}} wrap={'wrap'}>
								<Button type={'button'} style={{margin: 10}} inverted color={'grey'} onClick={this.hideCallback}>{t('actions.cancel')}</Button>
								<Button disabled={phone.trim() === ''} style={{margin: 10}} color={'blue'} inverted type={'submit'}><Icon name="paper plane"/> {t('feedback.request-callback')}</Button>
							</Flex>
						</Form>
					</Segment>
					<Label style={{position: 'absolute', left: 'calc(100% + 14px)', marginTop: 'auto',  top: 35, zIndex: 9999999}} color="blue" ribbon="right" content={t('feedback.callback')}/>
				</Dialog>
			</React.Fragment>
		);
	}
}

Feedback = translate()(Feedback);
const m2s = state => ({
	awaitingCallback: isLoading(state, PROC_CALLBACK_REQUEST),
	awaitingFeedback: isLoading(state, PROC_FEEDBACK_REPORT)
});
const m2d = dispatch => ({
	onRequestCallback: (phone, onSuccess) => dispatch(callbackRequest(phone, () => {
		isFunc(onSuccess) && onSuccess();
		dispatch(addSnackbar('snackbar.callback-request.sent'));
	})),
	onRequestFeedback: (message, onSuccess) => dispatch(reportFeedback(message, () => {
		isFunc(onSuccess) && onSuccess();
		dispatch(addSnackbar('snackbar.feedback-report.sent'));
	}))
});
export default connect(m2s, m2d)(Feedback);