// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {setLoader, unsetLoader} from "./loaderActions";
import {addError} from "./errorActions";
import {APPOINTMENT_ACTION} from "./types";
import {PROC_APPOINTMENTS, PROC_APPOINTMENTS_PAUSED_QUIT, STORE_APPOINTMENTS_FILTERS, SUB_PAUSE_APPOINTMENT, SUB_PAUSE_RESOURCE_APPOINTMENT, SUB_RESUME_APPOINTMENT} from "./index";
import {get, isFunction, isObject} from "lodash";
import {async} from "ith-redux-helpers";
import {userState__setWork} from "./userActions";
import {orderState__addToRelease, orderState__clearReleased} from "./orderActions";
import {workerState__setWorking} from "./workerActions";
import {publish} from "../Logic/PubSub";
import {mapDispatch, mapState__orders_add, mapState__work_add} from "./mapActions";

export const appointmentState__set = (data, update) => ({type: APPOINTMENT_ACTION.SET, data, update});
export const appointmentState__setSection = (section, data) => ({type: APPOINTMENT_ACTION.SET_TO_SECTION, section, data});
export const appointmentState__update = (data) => ({type: APPOINTMENT_ACTION.UPDATE, data});
export const appointmentState__removeResources = (resources) => ({type: APPOINTMENT_ACTION.REMOVE_RESOURCES, resources});
export const appointmentState__updateProgress = (order_id, progress) => ({type: APPOINTMENT_ACTION.UPDATE_PROGRESS, order_id, progress});
export const appointmentState__setWorkload = workload => ({type: APPOINTMENT_ACTION.SET_WORKLOAD, workload});
export const appointmentState__list = (list) => ({type: APPOINTMENT_ACTION.LIST, list});
export const appointmentState__paused = list => ({type: APPOINTMENT_ACTION.SET_PAUSED, list});
export const appointmentState__delete = order_id => ({type: APPOINTMENT_ACTION.DELETE, order_id});
export const appointmentState__workMap = work_map => ({type: APPOINTMENT_ACTION.SET_WORK_MAP, work_map});
export const appointmentState__updateSSE = (data) => ({type: APPOINTMENT_ACTION.SSE_UPDATE, data});
export const appointmentState__pausedServices = (paused_services) => ({type: APPOINTMENT_ACTION.PAUSED_SERVICES, paused_services});
export const appointmentState__dayLoaded = () => ({type: APPOINTMENT_ACTION.DAY_LOADED});

export const appointmentState__multi = (...multi) => ({type: APPOINTMENT_ACTION.MULTI, multi})

export const appointmentCall__pauseService = (id, pauseState) =>
	POST("/order/pause", {id, paused: pauseState});

export const appointmentCall__pauseResource = (id, pauseState) =>
	POST("/order/pause/resource", {id, paused: pauseState});
export const appointmentCall__quitPaused = () => DELETE('/order/pause/work');
export const appointmentCall__resumePaused = (id) => POST(`/order/resume/${id}`);
export const appointmentCall__pauseMany = (order_service_ids) => POST('/order/pause_many', {order_service_ids});
export const appointmentCall__quitMany = (resource_id, order_service_ids) => POST('/order/quit_many', {resource_id, order_service_ids});
export const queryAppointment = (house_id, resource_id, when, minutes) => {
	return GET(`/events/slots/${house_id}/${resource_id}/${when}/${minutes}`);
};
export const saveAppointment = (data) => PUT('/events/slots', data);




export const appointmentStore__set = (data = {}) => async().then(() => {
	window.localStorage.setItem(STORE_APPOINTMENTS_FILTERS, JSON.stringify(data));
});

export const appointmentStore__get = () => {
	let data = window.localStorage.getItem(STORE_APPOINTMENTS_FILTERS) || '{}';
	try {
		return JSON.parse(data);
	} catch (e) {
		return {};
	}
};

export const appointmentAction__dayLoaded = () =>
	(dispatch, getState) => !getState().appointments.loaded.daywork && dispatch(appointmentState__dayLoaded());

export const appointmentAction__load = (params = null, updateMode = false, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS));
			const data =  params ? await POST('/order/appointments', params) :  await GET('/order/appointments');
			dispatch(appointmentState__set(data, updateMode));
			mapDispatch(dispatch, data);
			isFunction(onSuccess) && onSuccess(data);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS));
		}
	};

export const loadAppointments = appointmentAction__load;

export const appointmentAction__updateReleased = (order, state, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_id));
			const result = await POST('/order/update/release', {order_id, state});
			dispatch(appointmentState__update(result));
			if ( result.order.released_at) {
				dispatch(orderState__clearReleased([order_id]));
			} else {
				dispatch(orderState__addToRelease(result.order));
			}
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_id));
		}
	};
export const appointmentAction__updateDelivered = (order, state, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_id));
			const result = await POST('/order/update/deliver', {order_id, state});
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_id));
		}
	};
export const appointmentAction__updateFetched = (order, state, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_id));
			const result = await POST('/order/update/fetch', {order_id, state});
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_id));
		}
	};
export const appointmentAction__joinWork = (order_service_id, resource_id, onSuccess, onConfirm) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_service_id));
			const result = await POST('/order/progress', {order_service_id, resource_id, active: true});
			if ( result.confirm) {
				isFunction(onConfirm) && onConfirm(result);
			} else {
				dispatch(appointmentState__update(result.data));
				mapDispatch(dispatch, result.data);
				dispatch(userState__setWork(result.work));
				isFunction(onSuccess) && onSuccess(result);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_service_id));
		}
	};
export const appointmentAction__work = (data, onSuccess, onConfirm) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, data.order_service_id));
			const result = await POST('/order/progress',data);
			if ( result.confirm) {
				isFunction(onConfirm) && onConfirm(result);
			} else {
				const quit = result.quit;
				if (isObject(quit)) {
					dispatch(appointmentState__update(quit.data));
					dispatch(userState__setWork(quit.work));
					mapDispatch(dispatch, quit.data);
				}
				dispatch(appointmentState__update(result.data));
				dispatch(userState__setWork(result.work));
				mapDispatch(dispatch, result.data);
				"work" in result && dispatch(mapState__work_add(result.work));
				isFunction(onSuccess) && onSuccess(result);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, data.order_service_id));
		}
	};

export const appointmentAction__quitWork = (order, complete, onSuccess) =>
	async dispatch => {
		let order_id = get(order, 'order_id', order || 'no-order-id');
		complete = complete ? '/complete' : '';
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_id));
			const result = await DELETE('/order/work' + complete);
			dispatch(appointmentState__update(result.data));
			dispatch(userState__setWork(result.work));
			mapDispatch(dispatch, result.data);
			"work" in result && dispatch(mapState__work_add(result.work));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_id));
		}
	};
export const appointmentAction__updateServiceState = (orderService, finished, onSuccess) =>
	async dispatch => {
		const order_service_id = orderService.order_service_id || orderService;
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_service_id));
			const result = await POST('/order/service/finished', {order_service_id, finished});
			if ( !result.acknowledged) {
				throw new Error(result.reason);
			}
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_service_id));
		}
	};
export const appointmentAction__updateResourceState = (orderServiceResource, finished, onSuccess) =>
	async dispatch => {
		const order_service_resource_id = orderServiceResource.order_service_resource_id || orderServiceResource;
		finished = Boolean(finished);
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_service_resource_id));
			const result = await POST('/order/resource/state', {order_service_resource_id, finished});
			if ( result.ack ) {
				dispatch(appointmentState__update(result.data));
				mapDispatch(dispatch, result.data);
				isFunction(onSuccess) && onSuccess(result);
			} else {
				console.warn(`appointmentAction__updateResourceState(${order_service_resource_id}, finished: ${finished ? 'true' : 'false'}) did respond with: "${result.reason}"`);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_service_resource_id));
		}
	};
export const appointmentAction__getOne = (order, section, onSuccess) =>
	async dispatch => {
		const order_id = order.order_id || order;
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, order_id));
			const result = await GET(`/order/appointment/${order_id}`);
			section ? dispatch(appointmentState__setSection(section, result)) : dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, order_id));
		}
	};
export const appointmentAction__workload = (only_unused = true, ...order_ids) =>
	async dispatch => {
		only_unused = Boolean(only_unused);
		try {
			dispatch(setLoader(PROC_APPOINTMENTS));
			const result = await POST('/order/worktimes', {only_unused, order_ids});
			dispatch(appointmentState__setWorkload(result));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS));
		}
	};
export const appointmentAction__pauseService = (id, pauseState, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, id));
			const result = await appointmentCall__pauseService(id, pauseState);
			dispatch(appointmentState__update(result));
			if ("active" in result) {
				dispatch(workerState__setWorking(result.active));
			}
			if ("quit" in result && result.quit) {
				dispatch(userState__setWork([]));
			}
			publish(SUB_PAUSE_APPOINTMENT, id, pauseState, result);
			"paused_services" in result && dispatch(appointmentState__pausedServices(result.paused_services));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, id));
		}
	};
export const appointmentAction__pauseResource = (id, pauseState, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS, id));
			const result = await appointmentCall__pauseResource(id, pauseState);
			dispatch(appointmentState__update(result));
			mapDispatch(dispatch, result);
			publish(SUB_PAUSE_RESOURCE_APPOINTMENT, id, pauseState, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS, id));
		}
	};
export const appointmentAction__quitPaused = (onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS_PAUSED_QUIT));
			const result = await appointmentCall__quitPaused();
			dispatch(userState__setWork([]));
			if ("active" in result) {
				dispatch(workerState__setWorking(result.active));
			}
			if ("order" in result) {
				dispatch(appointmentState__update(result.order));
				dispatch(mapState__orders_add(result.order));
			}
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS_PAUSED_QUIT));
		}
	};
export const appointmentAction__resumePaused = (id, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS_PAUSED_QUIT, id));
			const result = await appointmentCall__resumePaused(id);
			dispatch(appointmentState__update(result.order));
			dispatch(mapState__orders_add(result.order));
			dispatch(userState__setWork(result.work));
			dispatch(workerState__setWorking(result.active));
			"paused_services" in result && dispatch(appointmentState__pausedServices(result.paused_services));
			publish(SUB_RESUME_APPOINTMENT, id, result);
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS_PAUSED_QUIT, id));
		}
	};
export const appointmentAction__pauseMany = (ids, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS));
			const result = await appointmentCall__pauseMany(ids);
			dispatch(appointmentState__update(result));
			if ("active" in result) {
				dispatch(workerState__setWorking(result.active));
			}
			if ("quit" in result && result.quit) {
				dispatch(userState__setWork([]));
			}
			for (const id of ids) {
				publish(SUB_PAUSE_APPOINTMENT, id, true, result);
			}
			"paused_services" in result && dispatch(appointmentState__pausedServices(result.paused_services));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS));
		}
	};

export const appointmentAction__quitMany = (resource_id, ids, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_APPOINTMENTS));
			const result = await appointmentCall__quitMany(resource_id, ids);
			dispatch(appointmentState__update(result));
			if ( "active" in result ) {
				dispatch(workerState__setWorking(result.active));
			}
			if ("quit" in result && result.quit) {
				dispatch(userState__setWork([]));
			}
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_APPOINTMENTS));
		}
	};