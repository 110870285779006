import * as React from "react";
import {Button, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {soundAction__play, soundAction__stop} from "../../actions/soundActions";

const def = [1000, 2000, 1000, 1500, 1000, 4000];

export class Sound3Test extends React.PureComponent {
	state = {
		play : false,
		auto: false
	};
	// audio = React.createRef();
	
	toggle = () => this.setState(state => ({play: !state.play}), () => {
		if (this.state.play) {
			this.props.play();
		} else {
			this.props.stop();
		}
	});
	
	autoplay = (index = 0) => {
		if (index in def) {
			this.setState({auto: true});
			window.setTimeout(() => {
				this.toggle();
				this.autoplay(index+1);
			}, def[index]);
		} else {
			this.setState({auto: false});
		}
	};
	
	componentDidMount() {
		this.autoplay();
	}
	
	render() {
		return <Segment>
			<Button disabled={this.state.auto} onClick={this.toggle}>{this.state.play ? 'Stop' : 'Start'}</Button>
			<Button disabled={this.state.auto} onClick={this.autoplay.bind(null, 0)}>{this.state.auto ? '...playing...' : 'Autoplay'}</Button>
		</Segment>;
	}
}

Sound3Test = connect(
	state => state.sound,
	dispatch => ({
		play: () => dispatch(soundAction__play),
		stop: () => dispatch(soundAction__stop)
	})
)(Sound3Test);