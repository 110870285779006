import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon, Segment, Table} from "semantic-ui-react";
import {omit, isPlainObject} from "lodash";
import {trueNull} from "../../../../Logic/extensions";
import {Vehicle} from "../../../../models";
import {RegistrationMark} from "../RegistrationMark";
import {vehicleCall__ofClient} from "../../../../actions/vehicleActions";
import cn from 'classnames'
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import {translate} from "react-i18next";


export const TableHeader = ({sticky, top, zIndex, ...props}) => (
	<Table.Header {...props}>
		<Table.Row>
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Fahrzeug</Table.HeaderCell>
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>HSN/TSN</Table.HeaderCell>
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Laufleistung</Table.HeaderCell>
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Kennzeichen</Table.HeaderCell>
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Fahrgestellnummer</Table.HeaderCell>
			{/*<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Begrenzung</Table.HeaderCell>*/}
			<Table.HeaderCell className={"sticky pointer click"} style={{position: trueNull(sticky) && "sticky", zIndex, top}}>Leihwagen</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);
TableHeader.propTypes = {
	sticky: PropTypes.bool,
	top: PropTypes.any,
	zIndex: PropTypes.number
};
TableHeader.defaultProps = {
	sticky: true,
	top: 50,
	zIndex: 12
};

export class VehicleList extends React.Component {
	static propTypes = {
		client_id: PropTypes.number.isRequired,
		onFetch: PropTypes.func,
		headerProps: PropTypes.object,
		onSelect: PropTypes.func
	};
	static defaultProps = {
		onFetch: client_id => vehicleCall__ofClient(client_id)
	};
	
	state = {
		vehicles: [],
		loadingVehicles: false
	};
	
	componentDidMount() {
		this.fetch();
	}
	
	partition = memoizeOne(
		list => {
			let head = [];
			let body = [];
			for(const vehicle of list) {
				if (vehicle.loan_car) {
					head.push(vehicle);
				} else {
					body.push(vehicle);
				}
			}
			let combine = [];
			for (const vehicle of head) {
				combine.push(vehicle);
			}
			for (const vehicle of body) {
				combine.push(vehicle);
			}
			return combine;
		}
	)
	
	fetch = async () => {
		const {client_id, onFetch} = this.props;
		if (client_id) {
			try {
				this.setState({loadingVehicles: true});
				const result = await onFetch(client_id);
				const vehicles = isPlainObject(result) ? Object.values(result) : result;
				this.setState({vehicles});
			} catch (e) {
				console.error(e);
			} finally {
				this.setState({loadingVehicles: false});
			}
		} else {
			console.warn('`VehicleList`: No client id given');
		}
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.client_id !== this.props.client_id) {
			this.fetch();
		}
	}
	
	render() {
		const {vehicles: unsortedList, loadingVehicles} = this.state;
		const vehicles = this.partition(unsortedList);
		const {headerProps, bodyProps, onSelect, t, tReady, i18n, ...props} = this.props;
		const innerProps = omit(props, ['client_id', 'onFetch', 'headerProps', 'onSelect']);
		return (
			<Segment basic style={{padding: 0, margin: 0}} loading={loadingVehicles}>
				<Table celled striped {...innerProps}>
					<TableHeader {...headerProps}/>
					<Table.Body {...bodyProps}>
						{vehicles.map(v => {
							let vehicle = v instanceof Vehicle ? v : new Vehicle(v);
							return <Table.Row className={cn('hover', {'click pointer': Boolean(onSelect)})} key={`row-${vehicle.vehicle_id}`} onClick={trueNull(onSelect) && onSelect.bind(null, vehicle)}>
								{/*<Table.Cell>{k.name || vehicle.model_id}</Table.Cell>*/}
								<Table.Cell>{vehicle.name}</Table.Cell>
								<Table.Cell>{vehicle.hsn + ' / ' + vehicle.tsn}</Table.Cell>
								<Table.Cell>{vehicle.km} km</Table.Cell>
								<Table.Cell><RegistrationMark>{vehicle.registration_mark || 'n.v.'}</RegistrationMark></Table.Cell>
								<Table.Cell><code>{vehicle.chassis_number}</code></Table.Cell>
								{/*<Table.Cell>-</Table.Cell>*/}
								<Table.Cell>{vehicle.is_loan_car ? <Icon name={'car'} size={'large'}/> : null}</Table.Cell>
							</Table.Row>
						})}
					</Table.Body>
				</Table>
			</Segment>
		);
	}
}
VehicleList = translate()(VehicleList);