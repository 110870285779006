import * as React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {get, isFunction} from "lodash";
import {publish, subscribe} from "../../Logic/PubSub";
import {SUB_SSE_RECONNECT, SUB_SSE_STATUS} from "../../actions";

export class SSEWrapper extends React.PureComponent {
	static propTypes = {
		endpoint: PropTypes.string.isRequired,
		options: PropTypes.shape({
			withCredentials: PropTypes.bool
		}),
		onMessage: PropTypes.func,
		reconnectAfter: PropTypes.number,
		onInstance: PropTypes.func,
		active: PropTypes.bool,
		based: PropTypes.bool,
		debug: PropTypes.bool
	};
	static defaultProps = {
		options: {
			withCredentials: false
		},
		debug: false,
		onMessage: () => {},
		reconnectAfter: 0,
		onInstance: () => {},
		active: true
	};
	
	state = {
		sse: null
	};
	
	connect = () => {
		if ( !("EventSource" in window)) {
			console.error("SSE: EventSource is not available!");
			return;
		}
		const {debug, endpoint, options, onMessage, dispatch, onInstance, house} = this.props;
		debug && console.debug("SSE: Connecting...");
		// const sse = new ReconnectingEventSource(endpoint, {...options, max_retry_time: reconnectAfter});
		const sse = new EventSource(endpoint, options);
		
		this.setState({sse}, () => {
			onInstance.call(this, sse, this);
			sse.onopen = () => {
				debug && console.debug(`SSE: Connection establisted to ${endpoint} with options`, options);
				publish(SUB_SSE_STATUS, true);
			};
			sse.onmessage = e => {
				let data = this.props.based ? window.atob(e.data) : e.data;
				data = JSON.parse(data || 'null');
				if ( data && "action" in data) {
					if (data.action === "close-connection") {
						console.error("SSE: Close connection requested!");
						this.close(true, () => this.connect());
						return;
					}
				}
				onMessage.call(this, {...e, data}, dispatch, house);
				debug && console.debug("SSE: Message ~", data);
			};
			sse.onerror = e => {
				debug && console.error("SSE: ", e);
				publish(SUB_SSE_STATUS, false);
				this.setState({sse: null}, () => {
					onInstance.call(this, null, this);
				});
			};
		});
	};
	
	close = (updateState = true, callback) => {
		const {debug, onInstance} = this.props;
		debug && console.debug("SSE: Disconnect requested...");
		const {sse} = this.state;
		if (sse) {
			debug && console.debug("SSE: Disconnecting...");
			sse.close();
			publish(SUB_SSE_STATUS, false);
			if (updateState) {
				this.setState({sse: null}, () => {
					onInstance.call(this, null, this);
					isFunction(callback) && callback();
				});
			} else {
				onInstance.call(this, null, this);
				isFunction(callback) && callback();
			}
		}
	};
	
	componentDidMount() {
		// noinspection JSPotentiallyInvalidUsageOfThis
		this.props.debug && console.debug("SSE: Mounted...");
		this.unsubscribeReconnect = subscribe(SUB_SSE_RECONNECT, () => {
			console.warn('SSE: SUB_SSE_RECONNECT RECEIVED');
			this.close(true, this.connect);
		});
		if ( this.props.active) {
			this.connect();
		}
	}
	
	componentWillUnmount() {
		this.props.debug && console.debug("SSE: Unmounting...");
		this.close(false);
		this.unsubscribeReconnect && this.unsubscribeReconnect();
	}
	
	render() {
		const {key, children} = this.props;
		return <React.Fragment key={key} children={children}/>
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if ( prevProps.active !== this.props.active) {
			const {sse} = this.state;
			if ( this.props.active && !sse) {
				this.connect();
			} else if (!this.props.active && sse) {
				console.warn('SSE: Component did update!', this.props.active, sse);
				this.close();
			}
		}
	}
}
SSEWrapper = connect(
	(state, props) => ({
		active: props.active !== undefined ? props.active : get(state, 'carhouse.own.sse', false),
		based: props.based !== undefined ? props.based : get(state, 'carhouse.own.sse_base64', false),
		house: get(state, 'carhouse.own', {})
	}),
	dispatch => ({dispatch})
)(SSEWrapper);

export const requestSSEReconnect = publish.bind(undefined, SUB_SSE_RECONNECT);