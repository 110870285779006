import * as React from 'react';
import ImgNoAccess from '../images/no-access.png';
import {Segment} from "semantic-ui-react";
import {translate} from "react-i18next";

export default translate()(({t}) => (
	<Segment basic style={{textAlign: 'center'}} padded={'very'}>
		<h2>{t('no-access')}</h2>
		<img src={ImgNoAccess} alt={t('not-found')} style={{maxWidth: 'calc(100% - 20px)'}} />
	</Segment>
));