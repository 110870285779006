import * as React from "react";
import cn from "classnames";
import {isFunction} from "lodash";

export class HoverContext extends React.PureComponent {
	state = {
		hovered: false
	};
	
	onEnter = (prev) => e => {
		this.setState({hovered: true});
		isFunction(prev) && prev(e);
	};
	
	onLeave = (prev) => e => {
		this.setState({hovered: false});
		isFunction(prev) && prev(e);
	};
	
	render() {
		const {hovered} = this.state;
		const {onEnter, onLeave} = this;
		return (
			<React.Fragment>
				{React.Children.map(this.props.children, (child) => {
					return React.cloneElement(child, {
						className: cn( "hover-context", child.props.className, {hovered}),
						onMouseEnter: onEnter(child.props.onMouseEnter),
						onMouseLeave: onLeave(child.props.onMouseLeave)
					})
				})}
			</React.Fragment>
		);
	}
}