
import {MENU_ACTION} from "../actions/types";

const menuState = (state = false, action) => {
	switch (action.type) {
		case MENU_ACTION.SHOW:
			return action.visible;
		case MENU_ACTION.TOGGLE:
			return !state;
		default:
			return state;
	}
};

export default menuState;