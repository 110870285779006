import {withRights} from "../../../../Tools";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import withInit from "../../../../Logic/withInit";
import Appmnts from "../../../../components/intern/appointments/v2/Appmnts";
import {SECTION} from "../../../../Logic/constants";
import {isOnline} from "../../../../actions/userActions";
import {workAction__ofDay} from "../../../../actions/orderActions";
import {loadAreasAndGroups} from "../../../../actions/rightActions";
import {scheduleAction__ofWorker} from "../../../../actions/scheduleActions";
import {appointmentAction__pauseService, appointmentAction__resumePaused} from "../../../../actions/appointmentActions";
import {sessionStorageAction__toggleAppointmentShowUndelivered} from "../../../../actions/sessionStorageActions";
import {BaseModel} from "../../../../models";
import * as React from "react";

const m2s = (state, props) => {
	return {
		loaded: state.appointments.loaded.daywork,
		work: state.appointments,
		vehicles: state.map.vehicles,// state.vehicles.list,
		clients: state.map.clients,//state.clients.list,
		work_schedule: state.workers.day_schedule[props.user.getData().workers_id],
		showUndelivered: state.session.appointments_show_undelivered,
		workerSchedule: state.user.schedule
	};
};

const m2d = (dispatch, props) => {
	return {
		init: () => !props.user.isSupport() && dispatch(isOnline(() => {
			dispatch(loadAreasAndGroups());
			// dispatch(loadResourcesOfHouse());
			// dispatch(kfzAction__fetchServices());
			// dispatch(vehicleAction__fetch());
			// dispatch(clientAction__fetch());
			!props.silent && dispatch(workAction__ofDay('today', true, props.user.getData().workers_id));
			// !props.silent && dispatch(scheduleAction__ofWorker(props.user.getData(), 'today'));
		})),
		onRefresh: (day) => {
			dispatch(workAction__ofDay(day || "today", true, props.user.getData().workers_id));
			// dispatch(scheduleAction__ofWorker(props.user.getData(), day || "today"));
		},
		pauseService: (id, pauseState, onSuccess) => dispatch(appointmentAction__pauseService(id, pauseState)),
		resumePaused: (id, onSuccess) => dispatch(appointmentAction__resumePaused(id, onSuccess)),
		toggleShowDeliver: () => dispatch(sessionStorageAction__toggleAppointmentShowUndelivered())
	};
};

let AppmntController = Appmnts;
AppmntController = withInit(AppmntController);
AppmntController = connect(m2s, m2d)(AppmntController);
AppmntController = withRights(SECTION.APPOINTMENTS, AppmntController);
AppmntController = translate()(AppmntController);

export default AppmntController;


export let Test = ({day = "today", id, ...props}) => <BaseModel.ProviderList list={[workAction__ofDay.bind(null, day, true, id), scheduleAction__ofWorker.bind(null, id, day)]}>{(a,b) =>
	<AppmntController {...props}/>
}</BaseModel.ProviderList>
