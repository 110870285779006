import * as React from 'react';
import PropTypes from 'prop-types';
import {vehicleCall__get} from "../../../../actions/vehicleActions";
import hash from 'object-hash';
import {Button, Header, Icon, Label, Modal, Popup, Segment} from "semantic-ui-react";
import {EasyFlex} from "../../../partials/ActionHeader";
import {IconButton} from 'material-ui';
import Picker from 'react-datepicker';
import {IconClose} from "../../../../Logic/icons";
import {deepMemoize as memoizeOne, falseNull, trueNull} from "../../../../Logic/extensions";
import {LoanCarConflictList} from "./LoanCarConflictList";
import {loanCarCall__rangedConflicts, loanCarCall__remove, loanCarCall__update} from "../../../../actions/loanCarActions";
import {FORMAT_DATE_SHORT_MONTH_WITH_DAY, withMaterial, withMobileAwareness} from "../../../../Tools/DatePicker";
import {AlternativeLoanCar} from "./AlternativeLoanCar";
import {moment} from "../../../../Logic/Moment";
import {isObject} from 'lodash';
import {Vehicle} from "../../../../models";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../partials";
import {withModule} from "../../../../Tools/RightsProvider";

const MaterialPicker = withMobileAwareness(withMaterial(Picker));

export class LoanCar extends React.Component {
	static propTypes = {
		vehicle: PropTypes.object,
		vehicle_id: PropTypes.number.isRequired,
		onFetch: PropTypes.func,
		onSet: PropTypes.func,
		onRemove: PropTypes.func,
		onPreview: PropTypes.func,
		onUpdate: PropTypes.func,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		onFetch: vehicle_id => vehicleCall__get(vehicle_id),
		onRemove: vehicle_id => loanCarCall__remove(vehicle_id),
		onPreview: data => loanCarCall__rangedConflicts(data),
		onUpdate: data => loanCarCall__update(data),
		onNotification: message => {
		}
	};
	
	state = {
		init: null,
		vehicle: null,
		fetching: false,
		saving: false,
		previewing: false,
		conflicts: [],
		test: null,
		schedule: null,
		origin: null
	}
	
	removeConflict = (schedule) => {
		if (isObject(schedule) && 'loan_car_schedule_id' in schedule) {
			this.setState(state => ({conflicts: state.conflicts.filter(c => c.loan_car_schedule_id !== schedule.loan_car_schedule_id)}));
		}
	}
	
	handleResolve = (schedule) => {
		this.removeConflict(schedule);
		this.setSchedule(null);
	}
	
	update = vehicle => {
		if (!('loan_car_start' in vehicle)) {
			vehicle.loan_car_start = null;
		}
		if (!('loan_car_end' in vehicle)) {
			vehicle.loan_car_end = null;
		}
		this.setState({vehicle, init: vehicle});
	}
	
	resetLoanCar = () => {
		const {origin} = this.state;
		if (origin) {
			this.setState(state => ({
				...state,
				vehicle: {
					...state.vehicle,
					loan_car_start: origin.loan_car_start,
					loan_car_end: origin.loan_car_end
				}
			}))
		}
	}
	
	componentDidMount() {
		if (this.props.vehicle) {
			this.update(this.props.vehicle);
		} else {
			this.fetch();
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.vehicle && hash(this.props.vehicle) !== hash(prevProps.vehicle)) {
			this.setState({vehicle: this.props.vehicle});
		}
		if (!this.props.vehicle && prevProps.vehicle_id !== this.props.vehicle_id) {
			this.fetch();
		}
	}
	
	fetch = async () => {
		const {onFetch, vehicle_id} = this.props;
		try {
			this.setState({fetching: true});
			const vehicle = await onFetch(vehicle_id);
			this.update(vehicle);
			this.setState({origin: {...vehicle}});
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({fetching: false});
		}
	}
	
	save = async () => {
		const {onUpdate, onNotification} = this.props;
		const {vehicle} = this.state;
		if (!vehicle){
			return;
		}
		const {vehicle_id, loan_car_start: start_point, loan_car_end: end_point} = vehicle;
		try {
			this.setState({saving: true})
			const result = await onUpdate({vehicle_id, start_point, end_point});
			if (result.ack) {
				this.setState({vehicle: result.vehicle, origin: result.vehicle});
			} else {
				this.setState({conflicts: result.conflicts});
				onNotification(`Es wurden ${result.conflicts.length} Konflikte gefunden!`, 'alert');
			}
		}catch (e) {
			onNotification(e.error||e.message, 'alert');
		} finally {
			this.setState({saving: false});
		}
		
	}
	
	setSchedule = (schedule = null) => this.setState({schedule});
	
	conflicts = async () => {
		const {onPreview,  onNotification} = this.props;
		if (!this.state.vehicle) {
			return;
		}
		const {vehicle_id, loan_car_start: start_point, loan_car_end: end_point} = this.state.vehicle;
		try {
			this.setState({previewing: true});
			const conflicts = await onPreview({vehicle_id, start_point, end_point});
			!conflicts.length && onNotification('Keine Konflikte gefunden');
			this.setState({conflicts});
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({previewing: false});
		}
	}
	
	saveOn = async () => {
		const {onSet} = this.props;
		const {vehicle} = this.state;
		if (vehicle && onSet) {
			try {
				this.setState({saving: true});
				const result = await onSet(this.state.vehicle);
				if (result.ack) {
					this.update(result.vehicle);
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.setState({saving: false});
			}
		}
	};
	
	saveOff = async () => {
		const {onRemove, onNotification} = this.props;
		const {vehicle} = this.state;
		if (vehicle && onRemove) {
			try {
				this.setState({saving: true});
				const result = await onRemove(this.state.vehicle.vehicle_id);
				if (result.ack) {
					this.update(result.vehicle);
					onNotification('Fahrzeug ist kein Leihfahrzeug mehr');
				} else if (result.schedules) {
					this.setState({conflicts: result.schedules});
					onNotification('Leihwagenkonflikte entdeckt!', 'alert');
					
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.setState({saving: false});
			}
		}
	};
	
	set = (vehicleData = {}) => this.setState(state => ({
		...state,
		vehicle: {
			...state.vehicle,
			...vehicleData
		}
	}));
	
	unsetStart = () => this.set({loan_car_start: null});
	unsetEnd = () => this.set({loan_car_end: null});
	setStart = (_, date) => this.set({loan_car_start: date && date.toISOString()});
	setEnd = (_, date) => this.set({loan_car_end: date && date.toISOString()});
	
	
	// setLoanCar = () => this.set({loan_car: true});
	isLoanCar = memoizeOne(
		origin => Boolean(origin && Vehicle.loan_car_status(origin))
	);
	isLiveLoanCar = memoizeOne(
		live => Boolean(live && Vehicle.loan_car_status(live))
	);
	
	render() {
		const {fetching, saving, vehicle,  previewing, schedule, origin} = this.state;
		const {onNotification, modules} = this.props;
		const isStartPast = vehicle && vehicle.loan_car_start && moment(vehicle.loan_car_start) < moment();
		const revertable = trueNull(vehicle && origin && (vehicle.loan_car_start !== origin.loan_car_start || vehicle.loan_car_end !== origin.loan_car_end));
		const isLoanCar = this.isLoanCar(origin);
		const isLiveLoanCar = this.isLiveLoanCar(vehicle);
		return (
			<Segment loading={fetching} basic style={{margin: 0, padding: 0, minHeight: 100}}>
				{vehicle && <div>
					
					{vehicle && trueNull(this.state.conflicts.length) &&
						<Modal basic defaultOpen size={'large'} onClose={() => this.setState({conflicts: []})}>
							<Modal.Content scrolling>
								<div>
									<Header color={'orange'} inverted as={'h4'} attached={'top'}>
										<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
											<span>Konflikte</span>
											<Icon className={'cursor pointer'} name={'close'}
											      onClick={() => this.setState({conflicts: []})}/>
										</EasyFlex>
									</Header>
									<LoanCarConflictList onSelect={this.setSchedule} attached={'bottom'}
									                     list={this.state.conflicts} vehicle={vehicle}/>
									{schedule &&
									<Modal open size={'large'} onClose={this.setSchedule.bind(null, null)}>
										<Modal.Header>Alternative Leihfahrzeuge</Modal.Header>
										<Modal.Content>
											<AlternativeLoanCar onResolved={this.handleResolve} onNotification={onNotification}
											                    schedule={schedule}/>
										</Modal.Content>
									</Modal>
									}
								</div>
							</Modal.Content>
						</Modal>
					}
					<Segment>
						<Label color={isLiveLoanCar && modules.loancar ? 'green' : 'red'} ribbon>
							{isLiveLoanCar && modules.loancar ? 'aktiv' : (modules.loancar ? 'inaktiv' : 'Leihwagenmodul inaktiv')}
						</Label>
						<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.BASELINE} style={{marginTop: 10}}>
							<Header as={'h4'}>Leihfahrzeug-Einstellungen
								<Header.Subheader>Ändungen können bereits geplante/zugewiesene Termine dieses Leihwagens
									beeinflußen.</Header.Subheader>
								<Header.Subheader>Keine Angabe bedeutet <em>kein Leihwagen</em>.</Header.Subheader>
							</Header>
							<div>
								{revertable && <Popup content={'zurücksetzen'} trigger={<Button icon={'history'} onClick={this.resetLoanCar} basic/>}/>}
								{trueNull(isLoanCar) && <Popup content={'Konflikte anzeigen'}
								                                      trigger={<Button basic loading={previewing}
								                                                       onClick={this.conflicts}
								                                                       icon={'eye'}/>}/>}
							</div>
						</EasyFlex>
						
						<EasyFlex style={{paddingLeft: 10, paddingRight: 10, marginTop: 15}} wrap>
							<EasyFlex valign={EasyFlex.valign.END} style={{marginRight: 20}}>
								<MaterialPicker
									selected={vehicle.loan_car_start ? new Date(vehicle.loan_car_start) : null}
									onChange={this.setStart.bind(null, null)}
									placeholderText={`Verfügbar ${isStartPast ? 'seit' : 'ab'}`}
									floating
									dateFormat={FORMAT_DATE_SHORT_MONTH_WITH_DAY}
									maxDate={vehicle.loan_car_end ? new Date(vehicle.loan_car_end) : null}
								/>
								<IconButton onClick={trueNull(vehicle.loan_car_start) && this.unsetStart} style={{
									pointerEvents: falseNull(vehicle.loan_car_start) && 'none',
									opacity: vehicle.loan_car_start ? 1 : 0
								}}><IconClose/></IconButton>
							</EasyFlex>
							<EasyFlex valign={EasyFlex.valign.END} style={{marginRight: 20}}>
								<MaterialPicker
									selected={vehicle.loan_car_end ? new Date(vehicle.loan_car_end) : null}
									onChange={this.setEnd.bind(null, null)}
									placeholderText={'Verfügbar bis'}
									floating
									dateFormat={FORMAT_DATE_SHORT_MONTH_WITH_DAY}
									minDate={vehicle.loan_car_start ? new Date(vehicle.loan_car_start) : null}
								/>
								<IconButton onClick={trueNull(vehicle.loan_car_end) && this.unsetEnd} style={{
									pointerEvents: falseNull(vehicle.loan_car_end) && 'none',
									opacity: vehicle.loan_car_end ? 1 : 0
								}}><IconClose/></IconButton>
							</EasyFlex>
						</EasyFlex>
						<EasyFlex align={EasyFlex.align.END} wrap style={{marginTop: 20}}>
							<ConfirmButton disabled={!revertable} loading={fetching || saving} content={'Speichern'} positive basic>
								<ConfirmButtonConsumer>{confirm =>
									<Button.Group>
										<Button basic color='grey' disabled={fetching || saving} onClick={e => {
											e && e.stopPropagation();
											confirm(false);
										}}>
											<Icon name={'close'}/> Abbrechen
										</Button>
										<Button.Or/>
										<Button loading={fetching || saving} positive
										        onClick={() => {
											        confirm(false);
											        this.save();
										        }}>Speichern</Button>
									</Button.Group>
								}</ConfirmButtonConsumer>
							</ConfirmButton>
						</EasyFlex>
					</Segment>
				</div>}
			
			</Segment>
		);
	}
}

LoanCar = withModule(LoanCar);