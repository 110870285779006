import * as React from "react";
import PropTypes from "prop-types";
import {deepMemoize as memoize, falseNull, trueNull} from "../../../Logic/extensions";
import {cloneDeep, get, isFunction, pick, values} from "lodash";
import {Button, Label, Message, Segment} from "semantic-ui-react";
import {Checkbox, Divider} from "material-ui";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_ORDER} from "../../../actions";
import {
	orderAction__extend,
	orderAction__extendForced,
	orderAction__extendForcedToken,
	orderAction__extendToken,
	orderAction__fetchOneCached,
	orderAction__putExtend,
	orderAction__putExtendForcedV2,
	orderCall__extend,
	orderCall__putExtend,
	orderCall__putExtendForcedV2
} from "../../../actions/orderActions";
import {OrderServiceSelector, requestServiceSelectorReset} from "./OrderServiceSelector";
import {OrderServiceSelectionList} from "./OrderServiceSelectionList";
import {EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import {addSnackbar} from "../../../actions/snackbarActions";
import {OrderRemoveServiceView} from "./OrderRemoveServiceView";
import {Trans, translate} from "react-i18next";
import {startOfMinute} from 'date-fns';
import {MaterialCompoundDateInput} from "../../../Tools/DatePicker";
import {OrderDateInfo} from "./OrderDatePickerV2";
import {MessageGroup} from "../../../components/partials/Segments";

const DEFAULT_STATE = {
	services: [],
	order_info: "",
	released: false,
	loading: false,
	proposal: null,
	proposal_date: null,
	force_date: null,
	force_change_date: null
};

// noinspection JSIgnoredPromiseFromCall
export class OrderInsertServiceView extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		data: PropTypes.shape({
			orders: PropTypes.object.isRequired,
			order2services: PropTypes.object.isRequired,
			services: PropTypes.object.isRequired,
			service2resources: PropTypes.object.isRequired,
			resources: PropTypes.object.isRequired,
			order_vehicles: PropTypes.object.isRequired,
			loan_cars: PropTypes.object.isRequired,
			clients: PropTypes.object.isRequired
		}),
		onUpdateOrder: PropTypes.func,
		onAfterUpdate: PropTypes.func,
		loading: PropTypes.bool,
		onForce: PropTypes.func,
		onNotification: PropTypes.func,
		onExtend: PropTypes.func,
		onPutExtend: PropTypes.func,
		onPutExtendForced: PropTypes.func
	};
	static defaultProps = {
		order2services: {},
		services: {},
		onAfterUpdate: () => {
		},
		onUpdateOrder: () => alert('OrderInsertServiceView::onUpdateOrder() is missing!'),
		onNotification: (message) => console.log(message),
		onExtend: (params) => orderCall__extend(params),
		onPutExtend: (params) => orderCall__putExtend(params),
		onPutExtendForced: (params) => orderCall__putExtendForcedV2(params)
	};
	
	
	state = cloneDeep(DEFAULT_STATE);
	
	proposalDate = memoize((proposal) => {
		if (!proposal) {
			return null;
		}
		return startOfMinute(new Date(proposal.result.order_ranges.range_end));
	});
	
	proposalCurrentDate = memoize((proposed, date) => date || proposed);
	
	validProposal = memoize((date, min) => {
		return date === null || date >= min;
	});
	
	get forceDate() {
		return this.state.force_change_date || this.state.force_date;
	}
	get forceDateInvalid() {
		return this.state.force_date && this.state.force_change_date && this.state.force_change_date < this.state.force_date;
	}
	
	reset = () => this.setState(cloneDeep(DEFAULT_STATE), requestServiceSelectorReset);
	
	release = (_, released) => this.setState({released});
	
	setLookup = (data, callback) => this.setState(state => ({
		...state,
		lookup: {
			...state.lookup,
			...data
		}
	}), callback);
	/**
	 *
	 * @param {Object} data
	 * @param {Object|null} [data.data]
	 * @param {string|null} [data.error]
	 * @param {boolean} [data.loading]
	 * @param {function} [callback]
	 * @param callback
	 */
	setForced = (data, callback) => this.setState(state => ({
		...state,
		forced: {
			...state.forced,
			...data
		}
	}), callback);
	
	resetForced = () => this.setState(state => ({
		...state,
		forced: {
			...DEFAULT_STATE.forced
		}
	}));
	
	setServices = (services) => {
		const inList = this.getServices(this.props.order_id, this.props.data.order2services, this.props.data.services).map(s => s.service_id);
		services = services.filter(s => !inList.includes(s.service_id));
		this.setState({services, proposal: null, proposal_date: null});
		this.resetForced();
	};
	
	
	extendHub = () => {
		if (this.state.proposal) {
			this.extendPut();
		} else {
			this.extend();
		}
	}
	
	extend = async () => {
		const {onNotification, onExtend, order_id} = this.props;
		const {services, released} = this.state;
		try {
			this.setState({loading: true, proposal: null});
			const result = await onExtend({
				order_id,
				services: services.map(s => s.service_id || s),
				propose: true,
				released
			});
			if (result.ack) {
				if (result.type === 'direct') {
					onNotification('Der Auftrag wurde erfolgreich erweitert');
					this.reset();
					this.props.onAfterUpdate(result);
				} else if (result.type === 'propose') {
					onNotification('Der Auftrag muss zeitlich erweitert werden', 'black');
					this.setState({proposal: {...result.proposal, token: result.token}});
				} else {
					onNotification('Dieser Auftrag kann mit den gewünschen Servicen nicht erweitert werden!', 'alert');
					this.setState({proposal: false});
				}
			} else {
				onNotification('Dieser Auftrag kann mit den gewünschen Servicen nicht erweitert werden!', 'alert');
				this.setState({proposal: false});
			}
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
		}
	};
	
	extendPut = async () => {
		const {onNotification, onPutExtend, order_id} = this.props;
		const {proposal, released, proposal_date} = this.state;
		try {
			this.setState({loading: true});
			const result = await onPutExtend({
				order_id,
				released,
				token: proposal.token,
				end_date: proposal_date
			});
			if (result.ack) {
				onNotification('Auftrag wurde erfolgreich erweitert');
				this.reset();
				this.props.onAfterUpdate(result);
			} else {
				onNotification('Der Auftrag konnte nicht gespeichert werden', 'alert');
				this.setState({proposal: false});
			}
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
		}
	};
	
	force = async () => {
		const {onNotification, order_id, onPutExtendForced} = this.props;
		const {services, released, force_date} = this.state;
		try {
			this.setState({loading: true});
			const result = await onPutExtendForced({
				order_id,
				released,
				services: services.map(s => s.service_id || s),
				date: force_date
			});
			if (result.ack) {
				this.reset();
				this.props.onAfterUpdate(result);
			} else {
				onNotification('Im gewünschten Rahmen ist das Erzwingen nicht möglich', 'alert');
				this.setState({force_date: startOfMinute(new Date(result.force_date))});
			}
			
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
		}
	};
	
	getServices = memoize(
		(order_id, order2services, serviceMap) =>
			values(pick(serviceMap, order2services[order_id] || []))
	);
	getServiceIds = memoize(
		services => services.map(s => s.service_id)
	);
	getVehicle = memoize(
		(order, vehicles) => get(vehicles, order.order_vehicle_id, null)
	);
	getOrder = memoize(
		(order_id, orders) => {
			try {
				return orders[order_id] || null;
			} catch (e) {
				return null;
			}
		}
	);
	
	getStart = memoize(date => startOfMinute(new Date(date)));
	getEnd = memoize(date => startOfMinute(new Date(date)));
	
	render() {
		const {
			order_id,
			data,
			t
		} = this.props;
		const {
			// lookup,
			// order_info,
			// forced: lookForced,
			loading,
			proposal,
			proposal_date,
			force_date,
			// force_change_date
		} = this.state;
		const order = this.getOrder(order_id, data.orders);
		if (!order) return null;
		const services = this.getServices(order_id, data.order2services, data.services);
		const serviceIds = this.getServiceIds(services);
		// const orderInfo = order_info || order.info;
		const start = this.getStart(order.deliver_point);
		const end = this.getEnd(order.fetch_point);
		const proposalDate = this.proposalDate(proposal);
		const proposalCurrentDate = this.proposalCurrentDate(proposalDate, proposal_date);
		const validProposal = this.validProposal(proposalCurrentDate, proposalDate);
		return (
			<Segment basic loading={loading}>
				
				<OrderRemoveServiceView order={order}/>
				<Divider/>
				<OrderServiceSelector asGrid vehicle={data.order_vehicles[order.order_vehicle_id]} onChange={this.setServices} exclude={serviceIds}/>
				{services.length > 0 &&
				<React.Fragment>
					<OrderServiceSelectionList updateOnChange vehicle={data.order_vehicles[order.order_vehicle_id]} services={this.state.services}/>
				</React.Fragment>
				}
				{this.state.services.length > 0 &&
				<div style={{marginBottom: 30}}>
					
					
					<Flex valign={"center"} align={'center'} vmargin={10}>
						<Checkbox style={{width: 'auto'}} labelStyle={{whiteSpace: 'nowrap'}} title={t('...', 'Freigabe')} label={<Trans defaults={"Service direkt freigeben"}/>} onCheck={this.release} checked={this.state.released}/>
					</Flex>
					
					{trueNull(proposal) &&
					<div style={{paddingTop: 40, paddingBottom: 40}}>
						<MessageGroup>
							<Message info>
								<p className="align center">
									<Trans
										i18nKey={'service-extend.new-range'}
										defaults={'{count, plural, =1 {Der gewünschte Service konnte nicht im aktuellen zeitlichen Rahmen eingetragen werden.} other {Die gewünschte Service können nicht im aktuellen zeitlichen Rahmen eingetragen werden.}}'}
										count={this.state.services.length}
									/>
									<br/>
									<Trans
										i18nKey={'service-extend.new-range-proposal'}
										defaults={'Die frühmöglichste Alternative wird ihnen vorgeschlagen. Sie können aber die Abgabe weiter in die Zukunft legen.'}
									/>
									<br/>
									<Trans
										i18nKey={'service-extend.new-range-origin'}
										defaults={'Die ursprüngliche Abgabezeit war <0>{time, date, medium} {time, time, short} Uhr.</0> '}
										values={{time: new Date(order.fetch_point)}}
										components={[<Label as={'span'}>-</Label>]}
									/>
								</p>
							</Message>
							{falseNull(validProposal) &&
							<Message negative>
								<p className="align center"><Trans defaults={'Die gewünschte Abgabezeit ist nicht realisierbar.'}/></p>
							</Message>
							}
						</MessageGroup>
						<EasyFlex align={EasyFlex.align.CENTER} wrap>
							<MaterialCompoundDateInput
								readOnly
								timeLabel={<span>&nbsp;</span>}
								dateLabel={<Trans defaults={'Annahme'}/>}
								date={start}
								selectsStart
								startDate={start}
								endDate={end}
							/>
							<MaterialCompoundDateInput
								timeLabel={<span>&nbsp;</span>}
								dateLabel={<Trans defaults={'Abgabe'}/>}
								selectsEnd
								minDate={proposalDate}
								startDate={start}
								endDate={proposalCurrentDate}
								date={proposalCurrentDate}
								onChange={proposal_date => this.setState({proposal_date: startOfMinute(proposal_date)})}
							/>
						</EasyFlex>
						<div>
							<OrderDateInfo
								titlePrimary={t('...', "Ur. Abgabe")}
								titleSecondary={t('...', "Sichere Abgabe ab")}
								date={new Date(order.fetch_point)}
								range={proposalDate}
								wrap
							/>
						</div>
					</div>
					}
					
					{trueNull(proposal !== false) && <Flex align={"flex-end"}>
						{proposal_date && <Button icon={'history'} onClick={() => this.setState({proposal_date: null})} basic/>}
						<Button positive onClick={this.extendHub} disabled={!validProposal} loading={loading}><Trans defaults={'Erweitern'}/></Button>
					</Flex>}
					
					{
						trueNull(null !== proposal) &&
						<div style={{paddingTop: 40, paddingBottom: 40}}>
							<MessageGroup>
								<Message warning>
									<p className="align center">{trueNull(false === proposal) && <Trans defaults={"Es wurde keine Erweiterungsoption gefunden."}/>}</p>
									<p className="align center">
										<Trans
											defaults={"Sie haben noch die Möglichkeit der erzwungenen Überbuchung {count, plural, one{des gewählten Services} other{der gewählten Service}}.<0/>Erzwungene Überbuchungen können sich negativ auf das Gleichgewicht der Kalenders auswirken."}
											components={[<br/>]}
											count={this.state.services.length}
										/>
									
									</p>
								</Message>
							</MessageGroup>
						
						</div>
					}
					
					{trueNull(proposal !== null) && <Flex align={force_date ? 'space-around' : 'flex-end'} valign={'center'}>
						<div>
							{force_date &&
								<div>
									<MaterialCompoundDateInput
										date={this.forceDate}
										min={force_date}
										onChange={date => this.setState({force_change_date: startOfMinute(date)})}
										timeLabel={<span>&nbsp;</span>}
										dateLabel={<Trans defaults={'neue Abgabe'}/>}
									/>
									<div>
										<span><OrderDateInfo
											titleSecondary={t('...', "Sichere Abgabe ab")}
											range={force_date}
											wrap
										/></span>
									</div>
								</div>
							}
						</div>
						<Button color={'orange'} onClick={this.force} loading={loading} disabled={this.forceDateInvalid}><Trans defaults={"Überbuchen"}/></Button>
					</Flex>}
				</div>
				}
			
			</Segment>
		);
	}
}

OrderInsertServiceView = withInit(OrderInsertServiceView);
OrderInsertServiceView = connect(
	(state, props) => {
		return {
			data: state.orders,
			loading: isLoading(state, PROC_ORDER) || isLoading(state, PROC_ORDER, props.order_id)
		};
	},
	(dispatch, {order_id}) => ({
		init: () => {
			order_id && dispatch(orderAction__fetchOneCached(order_id));
		},
		dispatch,
		onUpdateOrder: (data, onSuccess) => dispatch(orderAction__extendToken(data, onSuccess)),
		onForce: (data, onSuccess) => dispatch(orderAction__extendForced(data, result => {
			dispatch(addSnackbar("Order wurde erweitert!"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		onForceToken: (data, onSuccess) => dispatch(orderAction__extendForcedToken(data, result => {
			dispatch(addSnackbar("Order wurde forciert eingetragen!"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		onNotification: (message, color) => dispatch(addSnackbar(message, color)),
		onExtend: (params) => dispatch(orderAction__extend(params, true)),
		onPutExtend: (params) => dispatch(orderAction__putExtend(params, true)),
		onPutExtendForced: (params) => dispatch(orderAction__putExtendForcedV2(params, true))
	})
)(OrderInsertServiceView);
OrderInsertServiceView = translate()(OrderInsertServiceView);