import { combineReducers } from 'redux';
import {userReducer as userState} from "./user";
import errorReducer from "./error";
import languageState from "./language";
import loaderState from "./loader";
// import {routerReducer} from 'react-router-redux';
import {connectRouter} from "connected-react-router";
import menuState from "./menu";
import dialogState from './dialog';
import carhouseState from "./carhouse";
import workersState from "./worker";
import rightState from "./rights";
import resourceState from './resources';
import freedaysState from './freedaysReducers';
import appointmentsState from './appointmentReducers';
import snackbarState from './snackbar';
import moduleState from './module';
import kfzState from './kfzReducers';
import clientState from './clientReducers';
import vehicleState from './vehicleReducers';
import loanCarState from './loanCarReducers';
import orderState from './order';
import workState from './workReducers';
import soundState from "./soundReducer";
import localStorageState from "./localStorageReducer";
import sessionStorageState from "./sessionStorageReducer";
import mapState from "./mapReducers";

const appReducer = (history) => combineReducers({
	user: userState,
	errors: errorReducer,
	language: languageState,
	loaders: loaderState,
	// router: routerReducer,
	router: connectRouter(history),
	menu: menuState,
	dialogs: dialogState,
	carhouse: carhouseState,
	workers: workersState,
	rights: rightState,
	resources: resourceState,
	freedays: freedaysState,
	appointments: appointmentsState,
	snackbar: snackbarState,
	modules: moduleState,
	kfz: kfzState,
	clients: clientState,
	vehicles: vehicleState,
	loan_cars: loanCarState,
	orders: orderState,
	work: workState,
	sound: soundState,
	local: localStorageState,
	session: sessionStorageState,
	map: mapState
});

export default appReducer;