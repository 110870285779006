'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tryable = exports.filterObj = exports.objectify = exports.processData = exports.uniqify = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

require('core-js/fn/object/values');

require('core-js/fn/array/for-each');

var _isFunction = require('lodash/isFunction');

var _isFunction2 = _interopRequireDefault(_isFunction);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

try {
  require('babel-polyfill');
} catch (e) {}

;
/**
 *
 * @param what arrays to concat uniquely
 * @return {function(*=): any[]}
 *      callback function to return identifier of array-items
 *
 * @example
 *      uniqify(array-1, array-2, ...)(callback);
 *      uniqify( [{id: 1, name: 'test'}], [{id: 2, name: 'Look'}] )( data => data.id );
 */

var uniqify = function uniqify() {
  for (var _len = arguments.length, what = Array(_len), _key = 0; _key < _len; _key++) {
    what[_key] = arguments[_key];
  }

  return function (callback) {
    if (!what || !what.length) return [];
    var holder = {};
    what.forEach(function (arr) {
      if (!arr || !arr.length) return;
      arr.forEach(function (data) {
        return holder[callback.call(null, data)] = data;
      });
    });
    return Object.values(holder);
  };
};
/**
 * takes on object as first call.
 * second call is a function with the object from the first call, this object will be returned.
 * the callback is used to make changes on the object.
 * this only works for data that is given by reference
 * @param data data to change
 * @param spreaded to not just return the object, but to spread it into a new one ({...data})
 * @return {function(*): *}
 *
 * @example
 *      let data = {test: 'hallo world'};
 *      data = processData(data)(d => d.test = 'hello world'); -> {test: 'hello world'}
 */


var processData = function processData(data) {
  var spreaded = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function (callback) {
    callback.call(null, data);
    return spreaded ? _extends({}, data) : data;
  };
};
/**
 * creates an object out of an array.
 * first call: array to transform.
 * second call: callback function for index generation: param is the iterated object/data of the array of the first call
 *
 * @param array
 * @return {function(callback)} // callback is function to get the object index: default return the whole object/data
 *
 * @example
 *      const array = [ {id: 1, value: 'first'}, {id: 2, value: 'second'} ];
 *      const object = objectify(array)(data => data.id);
 *
 *      -> object
 *          {
 *              1: {id: 1, value: 'first'},
 *              2: {id: 2, value: 'second'}
 *          }
 */


var objectify = function objectify(array) {
  return function () {
    var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (data) {
      return data;
    };
    var obj = {};
    array.forEach(function (d) {
      return object[callback.call(null, d)] = d;
    });
    return obj;
  };
};

var filterObj = function filterObj(object) {
  return function () {
    var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (data, index) {
      return true;
    };
    var ret = {};

    for (var k in object) {
      if (!object.hasOwnProperty(k)) {
        continue;
      }

      if (callback.call(null, object[k], k)) {
        ret[k] = object[k];
      }
    }

    return ret;
  };
};
/**
 *
 * @param actionCallback
 * @return {Function}
 */


var tryable = function tryable(actionCallback) {
  return function () {
    var failAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    try {
      return (0, _isFunction2.default)(actionCallback) ? actionCallback.call() : actionCallback;
    } catch (e) {
      return (0, _isFunction2.default)(failAction) ? failAction.call() : failAction;
    }
  };
};

exports.uniqify = uniqify;
exports.processData = processData;
exports.objectify = objectify;
exports.filterObj = filterObj;
exports.tryable = tryable;