import {KFZ_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {
	CACHE_KFZ,
	CACHE_KFZ_MODELS,
	CACHE_KFZ_SERVICES,
	PROC_KFZ_CLIENT_FETCH,
	PROC_KFZ_FETCH,
	PROC_KFZ_FETCH_MODEL_SERVICES,
	PROC_KFZ_FETCH_SERVICES,
	PROC_KFZ_MODELS_CLIENT_FETCH,
	PROC_KFZ_REMOVE_MODEL_SERVICES,
	PROC_KFZ_SERVICE_UPDATE,
	PROC_KFZ_UPDATE_MODEL_SERVICES
} from "./index";
import {addError} from "./errorActions";
import {shouldUpdateExtend} from "ith-redux-helpers";
// import {DELETE, GET, POST} from "ith-fetch";
import {DELETE, GET, POST} from "../Logic/fetch";
import isFunc from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import {get} from "lodash";
import {Collector} from "../Logic/helper";
import {mapState__kfz_add, mapState__kfzModel_add, mapState__services_add} from "./mapActions";

export const kfzState__setCar = (car) => ({type: KFZ_ACTION.SET_ONE, car});
export const kfzState__setCars = (cars = null) => ({type: KFZ_ACTION.SET, cars});
export const kfzState__setModelsOf = (kfz_id, models) => ({type: KFZ_ACTION.SET_MODELS, kfz_id, models});
export const kfzState__setServices = (services) => ({type: KFZ_ACTION.SET_SERVICES, services});
export const kfzState__setServicesOfCars = (service_id, origin, custom) => ({type: KFZ_ACTION.SET_SERVICE_2_KFZ, service_id, origin, custom});
export const kfzState__setCustomService = (service_id, kfz_id, custom) => ({type: KFZ_ACTION.SET_CUSTOM_SERVICE, service_id, kfz_id, custom});
export const kfzState__removeCustomService = (service_id, kfz_id) => ({type: KFZ_ACTION.REMOVE_CUSTOM_SERVICE, service_id, kfz_id});
export const kfzState__setServicesOfModels = (service_id, kfz_id, custom, origin) => ({type: KFZ_ACTION.SET_SERVICE_KFZ_MODEL, service_id, kfz_id, origin, custom});
export const kfzState__updateServicesOfModels = (service_id, kfz_id, custom) => ({type: KFZ_ACTION.UPDATE_SERVICE_KFZ_MODELS, service_id, kfz_id, custom});
export const kfzState__removeServicesOfModels = (service_id, kfz_id, models) => ({type: KFZ_ACTION.REMOVE_SERVICE_KFZ_MODELS, service_id, kfz_id, models});
export const kfzState__addCars = cars => ({type: KFZ_ACTION.ADD, cars});
export const kfzState__addModels = kfz_models => ({type: KFZ_ACTION.ADD_MODELS, kfz_models});
export const kfzState__inverseModels = inverse_models => ({type: KFZ_ACTION.INVERSE_MODELS, inverse_models});
export const kfzState__updateService = service => ({type: KFZ_ACTION.UPDATE_SERVICE, service});
export const kfzState__removeService = service_id => ({type: KFZ_ACTION.REMOVE_SERVICE, service_id});


export const kfzState__getServicePrice = (state, kfz_id, model_id, service_id) => {
	const serviceStore = state.kfz.service;
	let price = get(serviceStore, `models.${service_id}.${kfz_id}.custom.${model_id}`, null);
	let level = 'model:custom';
	if ( !price || !price.price) {
		price = get(serviceStore, `models.${service_id}.${kfz_id}.origin.${model_id}`, null);
		level = 'model:origin';
	}
	if ( !price || !price.price) {
		price = get(serviceStore, `cars.custom.${service_id}.${kfz_id}`, null);
		level = 'kfz:custom';
	}
	if ( !price || !price.price ) {
		price = get(serviceStore, `cars.origin.${service_id}.${kfz_id}`, null);
		level = 'kfz:origin';
	}
	if ( !price || !price.price ) {
		return {price: 0, level: null};
	}
	return {price, level};
};

export const kfzCall__all = () => GET('/kfz/all');
export const kfzCall__getModel = id => GET(`/kfz/model/${id}`);
export const kfzCall__collect = list => POST('/kfz/collect', {list});
export const kfzCall__collectModels = list => POST('/kfz/collect/models', {list});
export const kfzCall__collectKfzModels = list => POST('/kfz/collect/kfz-models', {list});

/**
 * fetching kfz assigned to car house
 *
 * @param force = false caching...
 * @param onSuccess callback with result data
 * @return {Function}
 */
export const kfzAction__fetch = (force = false, onSuccess) =>
	async (dispatch, getState) => {
		if (!force && !shouldUpdateExtend(getState().kfz.cache, CACHE_KFZ)) {
			return;
		}
		try {
			dispatch(setLoader(PROC_KFZ_FETCH));
			const result = await GET('/kfz/house');
			dispatch(kfzState__setCars(result.kfz));
			dispatch(mapState__kfz_add(result.kfz));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH));
		}
	};

export const kfzAction__all = () =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_KFZ_FETCH));
			const result = await kfzCall__all();
			dispatch(mapState__kfz_add(result));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH));
		}
	};

export const kfzAction__fetchModelsOf = (kfz, force = false, onSuccess = undefined) =>
	async (dispatch, getState) => {
		const kfz_id = kfz.kfz_id || kfz;
		if (!force && !shouldUpdateExtend(getState().kfz.cache, CACHE_KFZ_MODELS + kfz_id)) {
			return; // is cached
		}
		try {
			dispatch(setLoader(PROC_KFZ_FETCH, kfz_id));
			const result = await GET(`/kfz/models/of/${kfz_id}`);
			dispatch(kfzState__setCar(result.kfz));
			dispatch(kfzState__setModelsOf(kfz_id, result.models));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH, kfz_id));
		}
	};

export const kfzAction__fetchServices = (force = false, onSuccess = undefined) =>
	async (dispatch, getState) => {
		if ( !force && !shouldUpdateExtend(getState().kfz.cache, CACHE_KFZ_SERVICES)) {
			return; //is cached
		}
		try {
			dispatch(setLoader(PROC_KFZ_FETCH_SERVICES));
			const result = await GET('/kfz/services');
			dispatch(kfzState__setServices(result));
			dispatch(mapState__services_add(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH_SERVICES));
		}
	};
export const kfzAction__fetchKfzServices = (service, force = false, onSuccess = undefined) =>
	async (dispatch, getState) => {
		const service_id = service.service_id || service;
		try {
			dispatch(setLoader(PROC_KFZ_FETCH_SERVICES, service_id));
			const result = await GET(`/kfz/services/${service_id}`);
			dispatch(kfzState__setServicesOfCars(service_id, result.origin, result.custom));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH_SERVICES, service_id));
		}
	};
export const kfzAction__setCustomKfzService = (data, onSuccess = undefined) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_KFZ_SERVICE_UPDATE, data.service_id, data.kfz_id));
			const result = await POST('/kfz/service/custom', data);
			dispatch(kfzState__setCustomService(result.service_id, result.kfz_id, result.service));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_SERVICE_UPDATE, data.service_id, data.kfz_id));
		}
	};
export const kfzAction__removeKfzService = (service, kfz, onSuccess = undefined) =>
	async dispatch => {
		const service_id = service.service_id || service;
		const kfz_id = kfz.kfz_id || kfz;
		try {
			dispatch(setLoader(PROC_KFZ_SERVICE_UPDATE, service_id, kfz_id));
			const result = await DELETE(`/kfz/service/${service_id}/${kfz_id}`);
			dispatch(kfzState__removeCustomService(result.service_id, result.kfz_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e, false));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_SERVICE_UPDATE, service_id, kfz_id));
		}
	};
export const kfzAction__fetchServicesOfKfzModels = (service, kfz, onSuccess) =>
	async dispatch => {
		const service_id = service.service_id || service;
		const kfz_id = kfz.kfz_id || kfz;
		try {
			dispatch(setLoader(PROC_KFZ_FETCH_MODEL_SERVICES, service_id, kfz_id));
			const result = await GET(`/kfz/services/${service_id}/${kfz_id}`);
			dispatch(kfzState__setServicesOfModels(result.service_id, result.kfz_id, result.custom, result.origin));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_FETCH_MODEL_SERVICES, service_id, kfz_id));
		}
	};
/**
 *
 * @param service instance of service or service_id
 * @param kfz instance of kfz or kfz_id
 * @param data object wirk price, hours_of_work and models[ array of model_id ]
 * @param onSuccess
 * @return {Function}
 */
export const kfzAction__updateServicesOfKfzModels = (service, kfz, data, onSuccess) =>
	async dispatch => {
		const service_id = service.service_id || service;
		const kfz_id = kfz.kfz_id || kfz;
		try {
			dispatch(setLoader(PROC_KFZ_UPDATE_MODEL_SERVICES, service_id, kfz_id));
			const result = await POST('/kfz/service/custom/models', {kfz_id, service_id, ...data});
			dispatch(kfzState__updateServicesOfModels(result.service_id, result.kfz_id, result.models));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_UPDATE_MODEL_SERVICES, service_id, kfz_id));
		}
	};
export const kfzAction__removeServicesOfKfzModels = (service, models, onSuccess) =>
	async dispatch => {
		const service_id = service.service_id || service;
		try {
			dispatch(setLoader(PROC_KFZ_REMOVE_MODEL_SERVICES, service_id));
			const result = await DELETE(`/kfz/models/service/${service_id}`, isArray(models) ? {models} : models);
			if ( result.kfz_id && result.removed_models.length ) {
				dispatch(kfzState__removeServicesOfModels(result.service_id, result.kfz_id, result.removed_models));
			}
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_REMOVE_MODEL_SERVICES, service_id));
		}
	};
export const kfzAction__loadKfzByClient = (client, onSuccess) =>
	async dispatch => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_KFZ_CLIENT_FETCH, client_id));
			const result = await GET(`/kfz/client-kfz/${client_id}`);
			dispatch(kfzState__addCars(result.kfz));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_CLIENT_FETCH, client_id));
		}
	};
export const kfzAction__loadModelsByClient = (client, onSuccess) =>
	async dispatch => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_KFZ_MODELS_CLIENT_FETCH, client_id));
			const result = await GET(`/kfz/client-models/${client_id}`);
			dispatch(kfzState__addModels(result.kfz_models));
			dispatch(kfzState__inverseModels(result.inverse));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_KFZ_MODELS_CLIENT_FETCH, client_id));
		}
	};

let __collectKfz = {};
export const kfzAction__collect = (kfzs) =>
	Collector._(__collectKfz, kfzs, 'kfz_id')
		.withLoader([PROC_KFZ_FETCH])
		.withStateProvider('map.kfz')
		.withApiProvider(list => kfzCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__kfz_add(result)))
		.execute();

let __collectModel = {};
export const kfzAction__collectModels = (models) =>
	Collector._(__collectModel, models, 'model_id')
		.withLoader([PROC_KFZ_FETCH])
		.withStateProvider('map.kfz_model')
		.withApiProvider(list => kfzCall__collectModels(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__kfzModel_add(result)))
		.execute();