import * as React from "react";
import PropTypes from "prop-types";

export const Spacer = ({colSpan}) => (<tr className={"spacer"}><td style={{height: 2}} colSpan={colSpan}></td></tr>);
Spacer.propTypes = {
	colSpan: PropTypes.number
};
Spacer.defaultProps = {
	colSpan: 2
};

export const Line = ({name, children, inverted, style}) => (
	<tr style={{background: inverted ? '#333' : 'rgba(0,0,0,0.05)', marginBottom: 4, borderRadius: 4, ...style}}>
		<td style={{borderTopLeftRadius: 4, borderBottomLeftRadius: 4, textAlign: "right", fontSize: "smaller"}}>{name}</td>
		<td>&nbsp;</td>
		<td style={{borderTopRightRadius: 4, borderBottomRightRadius: 4, whiteSpace: "nowrap"}}><code>{children || 'n.v.'}</code></td>
	</tr>
);
Line.propTypes = {
	name: PropTypes.node,
	children: PropTypes.node,
	inverted: PropTypes.bool,
	style: PropTypes.object
};
Line.defaultProps = {
	inverted: true
};

export const Icon = ({icon}) => {
	const IconName = icon;
	return <IconName color={"gray"} style={{marginRight: 8}}/>;
};