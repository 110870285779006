import * as React from "react";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Case, Default, Switch} from "../../Logic/Switch";
import {Button, Confirm} from "semantic-ui-react";
import onClickOutside from "react-onclickoutside";
import {isBoolean, isString} from "lodash";
import {useTranslation} from "../../actions/useStates";

const {Provider, Consumer} = React.createContext(() => {
});

export class ConfirmButton extends React.Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
		content: PropTypes.node.isRequired,
		as: PropTypes.any,
		before: PropTypes.node,
		wrapperProps: PropTypes.object,
		after: PropTypes.node
	};
	static defaultProps = {
		as: 'span'
	};
	state = {
		active: false
	};
	
	confirm = (active) => this.setState({active});
	handleClickOutside = e => this.confirm(false);
	
	render() {
		const {children, as, wrapperProps, content, before, after, disableOnClickOutside, enableOnClickOutside, preventDefault, stopPropagation, outsideClickIgnoreClass, eventTypes, ...props} = this.props;
		const Wrapper = as;
		return (
			<Wrapper {...wrapperProps}>
				{before}
				<Switch value={this.state.active}>
					<Case is={true}>
						<Provider value={this.confirm}>{children}</Provider>
					</Case>
					<Default>
						<Button {...props} onClick={e => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation();
							this.confirm(true);
						}}>{content}</Button>
					</Default>
				</Switch>
				{after}
			</Wrapper>
		);
	}
}

ConfirmButton = onClickOutside(ConfirmButton);

export let ConfirmationButton = ({as: Wrapper, wrapperProps, children, content, before, after, onStateChange, ...props}) => {
	const [active, setActive] = useState(false)
	const _setActive = (state) => {
		setActive(state)
		onStateChange && onStateChange(state)
	}
	ConfirmationButton.handleClickOutside = () => _setActive(false)
	const {disableOnClickOutside, enableOnClickOutside, preventDefault, stopPropagation, outsideClickIgnoreClass, eventTypes, wrappedRef, ...furtherProps} = props
	return <Wrapper {...wrapperProps}>
		{before}
		{active && children && children(_setActive.bind(this, false))}
		{!active && <Button {...furtherProps} onClick={e => {
			// noinspection JSUnresolvedFunction
			e && e.stopPropagation()
			// noinspection JSUnresolvedFunction
			e && e.preventDefault()
			_setActive(true)
		}}>{content}</Button>}
		{after}
	</Wrapper>
}
ConfirmationButton.propTypes = {
	as: PropTypes.any,
	wrapperProps: PropTypes.object,
	children: PropTypes.func,
	content: PropTypes.node,
	before: PropTypes.node,
	after: PropTypes.node,
	onStateChange: PropTypes.func
}
ConfirmationButton.defaultProps = {
	as: 'span'
}
const _confirmationButtonConfig = {
	handleClickOutside: () => ConfirmationButton.handleClickOutside
}
ConfirmationButton = onClickOutside(ConfirmationButton, _confirmationButtonConfig)


export const ConfirmationDialog = ({children, trigger, closeOnConfirm, content, confirmButton, cancelButton, onConfirm, onCancel, open, ...props}) => {
	const [active, setActive] = useState(open)
	const [t] = useTranslation()
	
	useEffect(() => {
		if (isBoolean(open)) {
			setActive(open)
		}
	}, [open])
	
	const hasOpen = isBoolean(open)
	
	return <>
		{!hasOpen && trigger && React.cloneElement(trigger, {
			onClick: e => {
				e && e.stopPropagation()
				e && e.preventDefault()
				setActive(true)
			}
		})}
		{active && <Confirm
			{...props}
			open={active}
			confirmButton={t(confirmButton, 'Ok')}
			cancelButton={t(cancelButton, 'Abbrechen')}
			content={isString(children) ? t(children) : children}
			onCancel={(e) => {
				e.stopPropagation()
				e.preventDefault()
				onCancel ? onCancel(e, setActive.bind(this, false)) : setActive(false)
			}}
			onConfirm={(e) => {
				e.stopPropagation()
				e.preventDefault()
				onConfirm()
				closeOnConfirm && !hasOpen && setActive(false)
				
			}}/>}
	</>
}
ConfirmationDialog.propTypes = {
	trigger: PropTypes.element,
	children: PropTypes.node,
	closeOnConfirm: PropTypes.bool,
	content: PropTypes.node,
	header: PropTypes.node,
	confirmButton: PropTypes.string,
	cancelButton: PropTypes.string,
	onConfirm: PropTypes.func,
	size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen']),
	centered: PropTypes.bool,
	onCancel: PropTypes.func,
	open: PropTypes.bool
}
ConfirmationDialog.defaultProps = {
	confirmButton: 'Ok',
	cancelButton: 'Abbrechen',
	content: 'Aktion wirklich durchführen?',
	children: 'Aktion wirklich durchführen?',
	size: 'mini',
	centered: false,
	closeOnConfirm: true
}

export {
	Consumer as ConfirmButtonConsumer
};