import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {ThreewayCheckbox} from "../ThreewayCheckbox";


export const CheckboxTest = () => {
	
	return (
		<Segment basic>
			<ActionHeader alignment={"flex-start"}>
				<ActionHeaderGroup>
					<ActionBackButton/>
					<ActionHeading>Threeway checkbox test</ActionHeading>
				</ActionHeaderGroup>
			</ActionHeader>
			<Segment basic padded={"very"}>
				<div>colored: <ThreewayCheckbox colored={true}/></div>
				<div>uncolored: <ThreewayCheckbox colored={false}/></div>
				<div>disabled: <ThreewayCheckbox disabled value={1}/></div>
				<div>disabled uncolored: <ThreewayCheckbox disabled value={-1} colored={false}/></div>
				<div>sized 48: <ThreewayCheckbox size={48}/></div>
				<div><label htmlFor={"labelled"}>Customly Labelled</label> <ThreewayCheckbox id={"labelled"}/></div>
				<div><ThreewayCheckbox label={"componenent label"}/></div>
				<div><ThreewayCheckbox label={"componenent label on right"} labelRight size={48}/></div>
			</Segment>
		</Segment>
	);
};