import * as React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import constants from "../../../../Logic/constants";
import {
	Card, CardHeader, CardText, DatePicker, Dialog, FlatButton, MenuItem, RaisedButton,
	SelectField
} from "material-ui";
import {Col, Grid, Row} from "react-bootstrap";
import {ConfirmDialog} from "../../../../Tools/material-ui";

const moment = extendMoment(Moment);
const dateFormat = constants.DATE_FORMAT;


const _defaultState = {
	start: null,
	end: null,
	resource: null,
	removeResources: [],
	rangeResource: null,
	expanded: [false, false],
	confirmAll: false,
	confirmRange: false
};

class RemoveDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = _defaultState;
		this.onCancel = this.onCancel.bind(this);
		this.toggleView = this.toggleView.bind(this);
		this.onRemoveResources = this.onRemoveResources.bind(this);
		this.setStart = this.setStart.bind(this);
		this.setEnd = this.setEnd.bind(this);
		this.closeConfirmAll = this.closeConfirmAll.bind(this);
		this.closeConfirmRange = this.closeConfirmRange.bind(this);
		this.showConfirmAll = this.showConfirmAll.bind(this);
		this.showConfirmRange = this.showConfirmRange.bind(this);
		this.isRangeInvalid = this.isRangeInvalid.bind(this);
		this.onRemoveRange = this.onRemoveRange.bind(this);
	}
	
	setStart = (_, date) => {
		this.setState({start: moment(date).startOf('day').toDate()});
	};
	
	setEnd = (_, date) => {
		this.setState({end: moment(date).endOf('day').toDate()});
	};
	
	onCancel = () => {
		this.setState(_defaultState);
		this.props.onRequestClose();
	};
	
	toggleView = (index, value) => this.setState(prev => ({
		expanded: prev.expanded.map((el, i) => i === index ? value : false)
	}));
	
	onRemoveResources = () => {
		this.setState({confirmAll: false});
		this.props.onRemoveResources(this.state.removeResources, () => {
			this.setState(_defaultState);
		});
	};
	
	onRemoveRange = () => {
		this.setState({confirmRange: false});
		const {rangeResource: resource_id, start, end} = this.state;
		this.props.onRemoveRange({resource_id, start: start.getTime(), end: end.getTime(), onSuccess: () => {
			this.setState(_defaultState);
		}});
	};
	
	showConfirmAll = () => this.setState({confirmAll: true});
	showConfirmRange = () => this.setState({confirmRange: true});
	closeConfirmAll = () => this.setState({confirmAll: false});
	closeConfirmRange = () => this.setState({confirmRange: false});
	
	isRangeInvalid = () => {
		const {start, end, rangeResource} = this.state;
		return !start || !end || rangeResource === null;
	};
	
	render() {
		const {
			open,
			resources
		} = this.props;
		return (
			<Dialog open={open} onRequestClose={this.onCancel} title="Arbeitszeiten löschen" actionsContainerStyle={{display: 'flex', padding: '10px 20px'}} actions={[
				<FlatButton onClick={this.onCancel}>Abbrechen</FlatButton>
			]}>
				<Card expanded={this.state.expanded[0]} onExpandChange={open => this.toggleView(0, open)}>
					<CardHeader title="Ressourcezeiten löschen" subtitle="Ressourcen auswählen" actAsExpander={true}
					            showExpandableButton={true}/>
					<CardText expandable={true}>
						<form onSubmit={e => {
							e.preventDefault();
							e.stopPropagation();
							this.showConfirmAll();
							
						}}>
							<Grid fluid={true}>
								<Row>
									<Col xs={12} sm={6}>
										<SelectField value={this.state.removeResources}
										             multiple={true}
										             hintText="Ressourcen wählen"
										             onChange={(a, b, values) => this.setState({removeResources: values})}>
											{resources.map(res =>
												<MenuItem key={res.resource_id}
												          insetChildren={true}
												          value={res.resource_id}
												          primaryText={res.title}
												          checked={this.state.removeResources.indexOf(res.resource_id) >= 0}/>)
											}
										</SelectField>
									</Col>
									<Col xs={12} sm={6}>
										<RaisedButton className="pull-right"
										              disabled={!this.state.removeResources.length} secondary={true}
										              type="submit">Alle löschen</RaisedButton>
									</Col>
								</Row>
							</Grid>
						</form>
					</CardText>
				</Card>
				
				<Card expanded={this.state.expanded[1]} onExpandChange={open => this.toggleView(1, open)}>
					<CardHeader title="Zeitspanne einer Ressource entfernen" subtitle="Ressource & Spanne festlegen"
					            actAsExpander={true} showExpandableButton={true}/>
					<CardText expandable={true}>
						<form onSubmit={e => {
							e.preventDefault();
							e.stopPropagation();
							this.showConfirmRange();
						}}>
							<Grid fluid={true}>
								<Row>
									<Col xs={12} sm={6}>
										<SelectField value={this.state.rangeResource}
										             hintText="Ressource wählen"
										             onChange={(a, b, values) => this.setState({rangeResource: values})}>
											{resources.map(res =>
												<MenuItem key={res.resource_id}
												          value={res.resource_id}
												          primaryText={res.title}/>)
											}
										</SelectField>
									</Col>
									<Col  xs={12} sm={6}>
										<DatePicker minDate={new Date()} value={this.state.start} hintText="Von" onChange={this.setStart} formatDate={date => moment(date).format(dateFormat)}/>
										<DatePicker minDate={new Date()} value={this.state.end} hintText="Bis (einschließlich)" onChange={this.setEnd} formatDate={date => moment(date).format(dateFormat)}/>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<RaisedButton style={{float: 'right'}} disabled={this.isRangeInvalid()} secondary={true} onClick={() => this.setState({confirmRange: true})}>Löschen</RaisedButton>
									</Col>
								</Row>
							</Grid>
						</form>
					</CardText>
				</Card>
				<ConfirmDialog open={this.state.confirmAll} onCancel={this.closeConfirmAll}
				               onConfirm={this.onRemoveResources} text="Alle gewählten Ressources werden gelöscht."/>
				<ConfirmDialog open={this.state.confirmRange} onCancel={this.closeConfirmRange} onConfirm={this.onRemoveRange} text="Alle Zeiten dieser Ressource in der gewählten Spanne werden gelöscht."/>
			</Dialog>
		);
	}
}

RemoveDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	onRemoveResources: PropTypes.func.isRequired,
	onRemoveRange: PropTypes.func.isRequired,
	resources: PropTypes.array.isRequired
};
RemoveDialog.defaultProps = {
	open: false,
	resources: []
};

export default RemoveDialog;