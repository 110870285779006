import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Table} from "semantic-ui-react";
import {EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import {moment} from "../../../Logic/Moment";
import {falseNull, trueNull} from "../../../Logic/extensions";
import Countdown, {applyPadding} from "../../../components/partials/Countdown";
import cn from "classnames";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderQuickView from "../orders/OrderQuickView";
import {Client, Vehicle} from "../../../models";
import ClientPopup from "../../../components/intern/clients/ClientPopup";
import VehiclePopup from "../../../components/intern/vehicles/VehiclePopup";
import {connect} from "react-redux";
import {sessionStorageAction__addAppointmentNotificationId} from "../../../actions/sessionStorageActions";

export class JoinWorkForced extends React.Component {
	static propTypes = {
		work: PropTypes.array.isRequired,
		request: PropTypes.any,
		serviceMap: PropTypes.object,
		resourceMap: PropTypes.object,
		joinForced: PropTypes.func,
		updateSeen: PropTypes.func,
	};
	static defaultProps = {
		work: [],
		request: {},
		serviceMap: {},
		resourceMap: {},
		joinForced: () => {},
		updateSeen: (id) => {}
	};
	state = {
		show_order: 0
	};
	showOrder = show_order => () => this.setState({show_order});
	
	onSuccess = () => {
		const ids = this.props.work.filter(w => !!w.delivered_at).map(w => w.order_id);
		this.props.updateSeen(ids);
	};
	
	render() {
		const {
			work,
			request,
			serviceMap,
			resourceMap,
			joinForced
		} = this.props;
		const {
			show_order
		} = this.state;
		return (
			<React.Fragment>
				<Table celled style={{marginTop: 20, marginBottom: 20}}>
					<Table.Body>
						{work.map((w, i) => {
							const backgroundColor = "#f6f6f6";
							const service = serviceMap[w.service_id] || {};
							const resource = resourceMap[w.resource_id] || {};
							return (
								<React.Fragment key={w.order_service_id}>
									<Table.Row>
										<Table.Cell colSpan={2}>
											<Flex align={"space-between"} valign={"center"}>
												<span>Auftrag {i + 1}</span>
												<Button icon="th list" circular size={"tiny"} onClick={this.showOrder(w.order_id)}/>
											</Flex>
										</Table.Cell>
									</Table.Row>
									
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Kunde</Table.Cell>
										<Table.Cell>
											<Client.Provider client_id={w.client_id}>{client =>
												<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
													<span>{client.contact}</span>
													<ClientPopup client={client}><Icon name={"info"}/></ClientPopup>
												</EasyFlex>
											}</Client.Provider>
										</Table.Cell>
									</Table.Row>
									<Vehicle.Provider vehicle_id={w.vehicle_id}>{vehicle =>
										<React.Fragment>
											<Table.Row style={{backgroundColor}}>
												<Table.Cell>
													&nbsp;
												</Table.Cell>
												<Table.Cell>
													<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
														<span>{vehicle.registration_shield}</span>
														<VehiclePopup vehicle={vehicle}><Icon name={"info"}/></VehiclePopup>
													</EasyFlex>
												
												</Table.Cell>
											</Table.Row>
										</React.Fragment>
									}</Vehicle.Provider>
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Service</Table.Cell>
										<Table.Cell>{service.title}</Table.Cell>
									</Table.Row>
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Ressource</Table.Cell>
										<Table.Cell>{resource.name}</Table.Cell>
									</Table.Row>
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Abgabe</Table.Cell>
										<Table.Cell>
											{moment(w.deliver_point).format("LLL")}
											{falseNull(w.delivered_at) &&
											<div style={{marginTop: 8}}>
												<Icon color={"red"} name={"exclamation triangle"}/> Fahrzeug nicht vor Ort! <Icon color={"red"} name={"exclamation triangle"}/>
												<div>
													<Countdown targetDate={moment(w.deliver_point).toDate()} render={([...dateInfo]) => {
														const [negative, , hours, minutes, seconds] = applyPadding(dateInfo);
														return <div
															style={{marginTop: 8}}
														>
															Zeitl. Differenz: <span className={cn("color", {
															"semantic-red": negative,
															"semantic-green": !negative,
															"minus-before": negative
														})}>{`${hours}:${minutes}:${seconds}`}</span>
														</div>;
													}}/>
												</div>
											</div>
											}
										</Table.Cell>
									</Table.Row>
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Spätester Anfangszeitpunkt</Table.Cell>
										<Table.Cell>
											{moment(w.latest_point).format("LLL")}
										</Table.Cell>
									</Table.Row>
									<Table.Row style={{backgroundColor}}>
										<Table.Cell>Zielzeit</Table.Cell>
										<Table.Cell>
											{moment(w.fetch_point).format("LLL")}
										</Table.Cell>
									</Table.Row>
									{trueNull(false) &&
									<Table.Row>
										<Table.Cell colSpan={2}/>
									</Table.Row>
									}
								</React.Fragment>
							);
						})}
					</Table.Body>
				</Table>
				<Flex align={"center"} valign={"center"} vmargin={30}>
					<Button negative onClick={() => joinForced(request, this.onSuccess)}>Trotzdem Arbeit beginnen</Button>
				</Flex>
				<OrderViewDialog onClose={this.showOrder(0)} open={show_order > 0}>
					<OrderQuickView order_id={show_order} onRequestClose={this.showOrder(0)}/>
				</OrderViewDialog>
			</React.Fragment>
		);
	}
}

JoinWorkForced = connect(
	null,
	dispatch => ({
		updateSeen: (id) => dispatch(sessionStorageAction__addAppointmentNotificationId(id))
	})
)(JoinWorkForced);