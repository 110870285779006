import * as React from 'react';
import PropTypes from 'prop-types';
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, ActionResponsiveItem, Flex, FlexChild, OptionHeader, ResponsiveFlexChild} from "../../partials/ActionHeader";
import NoAccess from "../../NoAccess";
import {processTranslate, withScreenReader} from "../../../Logic/extensions";
import {Button, ButtonGroup, Icon, Label, Modal, Popup, Segment} from "semantic-ui-react";
import {Trans, translate} from "react-i18next";
import VirtualList from 'react-tiny-virtual-list';
import {Checkbox, IconButton} from "material-ui";
import values from 'lodash/values';
import {SCREEN} from "../../../Logic/constants";
import {SearchFilter, Sortable} from "ah-tools";
import {sort, sortDefault, sortNum} from "ith-sort";
import {compare, compareDefault} from "ith-compare";
import {SizeMe} from 'react-sizeme';
import MDServicePrice from "./service/MDServicePrice";
// import ResourceSelector from "./ResourceSelector";
import {ResourceButton, ResourceChips, ResourceDialog} from "../../../cointainer/intern/master-data/service/ServiceResourceController";
import {PriceView, ResourceView} from "../../../cointainer/intern/master-data/service";
// import ResourceModeIcon from "material-ui/svg-icons/action/perm-data-setting";

let sortOptions = [
	{key: 'type-asc', order: 'ascending', title: 'Typ aufsteigend'},
	{key: 'type-desc', order: 'descending', title: 'Typ absteigend'},
	{key: 'tsn-asc', order: 'ascending', title: 'TSN aufsteigend'},
	{key: 'tsn-desc', order: 'descending', title: 'TSN absteigend'},
	{key: 'hsn-tsn-asc', order: 'ascending', title: 'HSN/TSN aufsteigend'},
	{key: 'hsn-tsn-desc', order: 'descending', title: 'HSN/TSN absteigend'},
	{key: 'ps-asc', order: 'ascending', title: 'PS aufsteigend'},
	{key: 'ps-desc', order: 'descending', title: 'PS absteigend'},
	{key: 'ccm-asc', order: 'ascending', title: 'CCM aufsteigend'},
	{key: 'ccm-desc', order: 'descending', title: 'CCM absteigend'},

];

let searchOptions = [
	{key: 'type', text: 'Typ', value: 'type'},
	{key: 'hsn-tsn', text: 'HSN/TSN', value: 'hsn-tsn'},
	{key: 'tsn', text: 'TSN', value: 'tsn'}
];

const ResourceModeToggle = ({active, onClick, t}) => (
	<Popup content={t(active ? 'Leave Resource Mode' : 'Show Resource Mode')} trigger={<Button icon color={'blue'} onClick={onClick}><Icon name={'users'} color={active ? 'blue': null} inverted/></Button>}/>
);
ResourceModeToggle.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func,
	t: PropTypes.func.isRequired
};
ResourceModeToggle.defaultProps = {
	active: false
};

class MasterDataKfzServiceView extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		onBack: PropTypes.func,
		service: PropTypes.shape({
			service_id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired
		}),
		kfz: PropTypes.shape({
			kfz_id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		model_list: PropTypes.array.isRequired,
		service_model: PropTypes.shape({
			custom: PropTypes.object.isRequired,
			origin: PropTypes.object.isRequired
		}),
		service_car_origin: PropTypes.shape({
			hours_of_work: PropTypes.number.isRequired,
			price: PropTypes.number.isRequired
		}),
		service_car_custom: PropTypes.shape({
			hours_of_work: PropTypes.number.isRequired,
			price: PropTypes.number.isRequired
		}),
		saveCustomPrice: PropTypes.func.isRequired,
		removeCustomPrice: PropTypes.func.isRequired,
		mayRead: PropTypes.bool,
		mayChange: PropTypes.bool,
		resource_list: PropTypes.array.isRequired
	};
	
	static defaultProps = {
		mayRead: false,
		mayChange: false,
		model_list: [],
		resource_list: []
	};
	
	state = {
		container: {maxHeight: 0, width: 0},
		selected: {},
		showTypes: 'all',
		showOrder: sortOptions[0].key,
		showSearch: searchOptions[0].key,
		showSearchText: '',
		showMask: false, // mask to enter price and hours
		showSelection: false, // show dialog with selected models
		resourceMode: false,
		resourceMask: false
	};
	
	constructor(props) {
		super(props);
		if ( !this.translated ) {
			sortOptions = sortOptions.map(obj => processTranslate(props.t, obj)(data => `sort.${data.key}`));
			searchOptions = searchOptions.map(obj => processTranslate(props.t, obj, 'text')(data => `filter.${data.key}`));
			this.translated = true;
		}
	}
	
	translated = false;
	container = null;
	
	handleContainerRef = el => {
		this.container = el;
		this.handleResize();
	};
	
	toggleResourceMode = () => this.setState(state => ({resourceMode: !state.resourceMode}));
	showResourceMask = resourceMask => () => this.setState({resourceMask});
	
	handleSelect = (model) => (e, value) => {
		const model_id = model.model_id || model;
		this.setState(state => ({
			...state,
			selected: {
				...state.selected,
				[model_id]: value ? model_id : undefined
			}
		}));
	};
	
	handleResize = () => {
		if (this.container) {
			try {
				const {top, width, height} = this.container.getBoundingClientRect();
				const maxHeight = Math.max(335, window.innerHeight - top);
				this.setState(state => ({
					...state,
					container: {maxHeight, width, height}
				}));
			} catch(e){}
		}
	};
	
	toggleCheckAllModels = (forceRemove = false) => () => {
		const checked = values(this.state.selected).filter(m => m !== undefined).length;
		if ( forceRemove || checked === this.props.model_list.length) {
			this.setState(state => ({
				...state,
				selected: {}
			}));
		} else {
			const selected = {};
			this.props.model_list.forEach(m => selected[m.model_id] = m.model_id);
			this.setState({selected});
		}
	};
	
	setFilter = name => value => this.setState({[name]: value});
	setFilterB = name => (a , value) => this.setFilter(name)(value);
	setFilterC = name => (a , b, value) => this.setFilter(name)(value);
	
	componentDidMount() {
		// this.props.init();
		this.handleResize();
		window.addEventListener('resize', this.handleResize, true);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}
	
	virtualListHeight = () => {
		const {screen: {width}} = this.props;
		if ( this.state.resourceMode ) {
			if ( width >= SCREEN.SM) {
				return 100;
			}
			return 145;
		}
		if ( width >= SCREEN.SM ) {
			return 60;
		}
		return 90;
	};
	
	
	
	sorted = () => {
		const {showOrder} = this.state;
		if ( !showOrder ) return sortDefault;
		const _ = showOrder.split('-');
		const order = _.pop();
		const key = _.join('-');
		switch(key) {
			case 'type': return sort(order)(m => m.type);
			case 'hsn-tsn': return sort(order)(m => m.hsn + m.tsn);
			case 'tsn': return sort(order)(m => m.tsn);
			case 'ps': return sortNum(order)(m => m.ps);
			case 'ccm': return sortNum(order)(m => m.ccm);
			default: return sortDefault;
		}
	};
	
	searched = () => {
		const {showSearchText, showSearch} = this.state;
		if (showSearchText.trim() === '') return compareDefault;
		switch(showSearch) {
			case 'type': return compare(showSearchText)(m => m.type);
			case 'hsn-tsn': return compare(showSearchText, {fuzzy: false})(m => m.hsn + '/' + m.tsn);
			case 'tsn': return compare(showSearchText, {fuzzy: false})(m => m.tsn);
			default: return compareDefault;
		}
	};
	
	carServicePrice = () => {
		const {service_car_custom: custom, service_car_origin: origin} = this.props;
		if (custom) {
			return {...custom, is_custom: true, level: 'house'};
		}
		if ( origin ) {
			return {...origin, is_custom: false, level: null};
		}
		return null;
	};
	
	modelServicePrice = ({model_id}, carService) => {
		const {service_model} = this.props;
		if ( !service_model ) return carService;
		const {custom, origin} = service_model;
		if ( custom && custom[model_id] ) {
			return {...custom[model_id], is_custom: true, level: 'car'};
		}
		if ( origin && origin[model_id] ) {
			if (carService && carService.is_custom) {
				return carService;
			}
			return {...origin[model_id], is_custom: false, level: null};
		}
		return carService;
	};
	
	showMask = showMask => () => this.setState({showMask});
	showSelection = showSelection => () => this.setState({showSelection});
	
	getFullSelection = () => {
		const {model_list} = this.props;
		const {selected} = this.state;
		return model_list.filter(m => !!selected[m.model_id]);
	};
	
	saveCustomPrice = data => {
		data.models = values(this.state.selected).filter(m => !!m);
		this.props.saveCustomPrice(data, this.showMask(false));
	};
	
	removeCustomPrice = () => {
		const models = values(this.state.selected).filter(m => !!m);
		this.props.removeCustomPrice(models);
	};
	
	
	render() {
		const {onBack, service: originService, kfz, mayRead, mayChange, t, service_model, model_list, resource_list} = this.props;
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		if (!originService || !kfz || !service_model) {
			return (<Segment basic loading style={{minHeight: 400}}/>);
		}
		const virtual_model_list = model_list.filter(this.searched()).sort(this.sorted());
		const {container, showOrder, showSearch, showSearchText, showMask, showSelection, resourceMode, resourceMask: showResourceMask} = this.state;
		const service = processTranslate(t, originService, 'title')( s => `service.name.service-${s.service_id}`);
		const selectedModels = values(this.state.selected).filter(m => m !== undefined);
		const virtualListHeight = this.virtualListHeight();
		const parentServicePrice = this.carServicePrice();
		return(
			<div>
				<ActionHeader alignment={'space-between'} wrap={'wrap'}>
					<ActionHeaderGroup>
						{onBack && <ActionBackButton onBack={onBack}/>}
						<ActionHeading>{t('service.service-for-kfz', {title: service.title, kfz: kfz.name})}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup valign={'center'}>
						{!resourceMode &&
							<OptionHeader underWidth={1400} tooltip={t('filter.label-show')} buttonText={t('actions.close')}
							              valign={'center'}>
								
								<Sortable
									style={{marginLeft: 5, marginRight: 5, marginTop: -7, width: 'auto'}}
									floatingLabelText={'Sortierung'}
									options={sortOptions}
									value={showOrder}
									autoWidth
									onChange={this.setFilterC('showOrder')}
								/>
								<ActionResponsiveItem>
									<SearchFilter
										options={searchOptions}
										value={showSearchText}
										selected={showSearch}
										onChange={this.setFilter('showSearchText')}
										onSelect={this.setFilter('showSearch')}
									/>
								</ActionResponsiveItem>
							</OptionHeader>
						}
						{resourceMode ?
							<ButtonGroup>
								<Label disabled >Resource Mode <i>{selectedModels.length}</i> </Label>
								<Popup content={t("For all")} trigger={<Button icon color="blue" onClick={this.showResourceMask(true)}><Icon name={"edit"} inverted/></Button>}/>
								<Popup content={t("Leave Resource Mode")} trigger={<Button icon negative onClick={this.toggleResourceMode}><Icon name={"close"} inverted/></Button>}/>
							</ButtonGroup>
							:
							<ButtonGroup>
								{selectedModels.length < model_list.length && <Popup content={t('service.select-all')} trigger={<Button icon color={'grey'} onClick={this.toggleCheckAllModels()}><Icon name={'check square outline'} size={'large'}/></Button>}/>}
								{selectedModels.length > 0 && <Popup content={t('service.deselect-all')} trigger={<Button icon color={'grey'} onClick={this.toggleCheckAllModels(true)}><Icon name={'close'} inverted/></Button>}/>}
								{selectedModels.length > 0 && <ResourceModeToggle t={t} active={resourceMode} onClick={this.toggleResourceMode}/>}
								<Popup content={t('service.define-values')} trigger={<Button onClick={this.showMask(true)} disabled={!mayChange || 0 === selectedModels.length}>{t('service.number-selected', {count: selectedModels.length})}</Button>}/>
								{selectedModels.length > 0 && <Popup content={t('service.show-selection')} trigger={<Button icon onClick={this.showSelection(true)}><Icon name={'list'}/></Button>}/>}
								{selectedModels.length > 0 && <Popup content={t('service.reset-selection')} trigger={<Button icon negative onClick={this.removeCustomPrice}><Icon name={'trash'}/></Button>}/>}
							</ButtonGroup>
						}
					</ActionHeaderGroup>
					
				</ActionHeader>
				<div ref={this.handleContainerRef} style={{marginTop: 40, marginBottom: 10, minHeight: this.state.container.maxHeight - 35}}>
					{resourceMode ?
						<ResourceList
							service_id={service.service_id}
							kfz_id={kfz.kfz_id}
							resourceList={resource_list}
							container={container}
							virtualModelList={this.getFullSelection()}
							virtualListHeight={virtualListHeight}
							parentServicePrice={parentServicePrice}
							modelServicePrice={this.modelServicePrice}
							toggleMask={this.showResourceMask}
						/>
						:
						<DefaultList
							service_id={service.service_id}
							kfz_id={kfz.kfz_id}
							container={container}
							virtualModelList={virtual_model_list}
							virtualListHeight={virtualListHeight}
							parentServicePrice={parentServicePrice}
							handleSelect={this.handleSelect}
							modelServicePrice={this.modelServicePrice}
							selection={this.state.selected}
						/>
					}
					
				</div>
			{/*	{showMask &&
					<Dialog open onRequestClose={this.showMask(false)} autoScrollBodyContent actions={
						<Flex align={'flex-start'}>
							<FlatButton onClick={this.showMask((false))}>{t('actions.cancel')}</FlatButton>
						</Flex>
					}>
						<MDServicePrice service_id={service.service_id} editable={mayChange} kfz_id={kfz.kfz_id} onSubmit={this.saveCustomPrice}/>
					</Dialog>
				}*/}
				{showMask && <Modal open centered={false} onClose={this.showMask(false)}>
					<Modal.Content>
						<MDServicePrice service_id={service.service_id} editable={mayChange} kfz_id={kfz.kfz_id} onSubmit={this.saveCustomPrice}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showMask(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{/*{showSelection &&
					<Dialog open onRequestClose={this.showSelection(false)} autoScrollBodyContent contentStyle={{ maxWidth:'calc(100% - 20px)'}} actions={[<Flex align={'flex-end'}>
						<FlatButton onClick={this.showSelection(false)}>{t('actions.close')}</FlatButton>
					</Flex>]}>
						<Segment basic>
							<SelectionList models={this.getFullSelection()} removeModel={this.handleSelect}/>
						</Segment>
					</Dialog>
				}*/}
				{showSelection && <Modal open centered={false} onClose={this.showSelection(false)}>
					<Modal.Content>
						<SelectionList models={this.getFullSelection()} removeModel={this.handleSelect}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showSelection(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{showResourceMask &&
					<ResourceDialog service_id={service.service_id} onHide={this.showResourceMask(false)} resourceTypes={resource_list} modelList={selectedModels}/>
				}
			</div>
		);
	}
}

const DefaultList = ({container, virtualModelList, virtualListHeight, parentServicePrice, handleSelect, modelServicePrice, selection, kfz_id, service_id}) => (
	<VirtualList
		width={container.width}
		height={container.maxHeight - 40}
		itemCount={virtualModelList.length}
		itemSize={virtualListHeight}
		renderItem={({index, style}) => {
			const model = virtualModelList[index];
			const checked = !!selection[model.model_id];
			const modelService = modelServicePrice(model, parentServicePrice);
			return(
				<Flex valign={'center'} style={{...style, height: virtualListHeight, paddingLeft: 10, paddingRight: 10, marginRight: 10, overflow: 'hidden', borderBottom: '1px solid #ccc'}} key={model.model_id}>
					<Checkbox className={'ah-model-checkbox'} inputStyle={{height: virtualListHeight}} checked={checked} onCheck={handleSelect(model)} label={
						<Flex align={'space-between'} valign={'center'}>
							<ResponsiveFlexChild grow={1}>
								<Flex wrap={'wrap'} valign={'baseline'} style={{width: '100%', fontSize: 15, fontWeight: checked ? 'bold' : 'normal'}} gutterWidth={10}>
									<ResponsiveFlexChild ellipsis widthELG={4/9} widthLG={3/9} widthMD={6/9} widthSM={6/9} width={2/2}>{model.type}</ResponsiveFlexChild>
									<ResponsiveFlexChild ellipsis widthELG={1/9} widthLG={1/9} widthMD={3/9} widthSM={3/9} width={1/2}>{model.fromYear} - {model.tillYear}</ResponsiveFlexChild>
									<ResponsiveFlexChild ellipsis widthELG={1/9} widthLG={1/9} widthMD={2/9} widthSM={3/9} width={1/2}>{model.hsn}/{model.tsn}</ResponsiveFlexChild>
									<ResponsiveFlexChild ellipsis widthELG={3/9} widthLG={3/9} widthMD={4/9} widthSM={6/9} width={1}>{model.ccm} ccm, {model.ps} ps, {model.kw} kw</ResponsiveFlexChild>
								</Flex>
							</ResponsiveFlexChild>
							{modelService && <ResponsiveFlexChild shrink={0} style={{textAlign: 'right'}} hiddenXS hiddenSM>
								<ButtonGroup>
									<PriceView kfz_id={kfz_id} service_id={service_id} model_id={model.model_id}/>
									<ResourceView kfz_id={kfz_id} service_id={service_id} model_id={model.model_id}/>
								</ButtonGroup>
								{/*<PriceInfo icon={modelService.level} hours={modelService.hours_of_work} price={modelService.price}/>*/}
							</ResponsiveFlexChild>}
						</Flex>
					}/>
				</Flex>
			);
		}}
	/>
);
DefaultList.propTypes = {
	container: PropTypes.object.isRequired,
	virtualModelList: PropTypes.array.isRequired,
	virtualListHeight: PropTypes.number.isRequired,
	kfz_id: PropTypes.number.isRequired,
	service_id: PropTypes.number.isRequired,
	parentServicePrice: PropTypes.object,
	handleSelect: PropTypes.func.isRequired,
	modelServicePrice: PropTypes.func.isRequired,
	selection: PropTypes.object.isRequired
};

const SelectionList = translate()(({t,models, removeModel}) => {
	if (!models.length) {
		return <div>{t('service.no-selection')}</div>;
	}
	return(
		<Segment basic style={{width: '100%', height: 'calc(100vh - 300px)', minHeight: 300}}>
			<SizeMe monitorHeight>
				{({size}) =>
					<div style={{width: '100%', height: 'calc(100vh - 300px)', minHeight: 300}}>
						<VirtualList
							height={size.height || 300}
							width={size.width}
							itemCount={models.length}
							itemSize={50}
							renderItem={({index, style}) => {
								const {model_id, type, hsn, tsn} = models[index];
								return(
									<Flex key={`dyn-select-${model_id}`} valign={'center'} style={{...style}}>
										<FlexChild grow={5} ellipsis width={5/9} title={type}>{type}</FlexChild>
										<FlexChild grow={1} ellipsis width={3/9} title={`${hsn}/${tsn}`}>{hsn}/{tsn}</FlexChild>
										<FlexChild shrink={0} width={1/9} title={`${hsn}/${tsn}`} style={{textAlign: 'right'}}>
											<IconButton onClick={removeModel(model_id)}><Icon name={'trash'}/></IconButton>
										</FlexChild>
									</Flex>
								);
							}}
						/>
					</div>
				}
			</SizeMe>
		</Segment>
	);
});
SelectionList.propTypes = {
	models: PropTypes.array.isRequired,
	removeModel: PropTypes.func.isRequired
};
SelectionList.defaultProps = {
	models: []
};

class ResourceList extends React.PureComponent {
	static propTypes = {
		container: PropTypes.object.isRequired,
		virtualModelList: PropTypes.array.isRequired,
		virtualListHeight: PropTypes.number.isRequired,
		parentServicePrice: PropTypes.object,
		modelServicePrice: PropTypes.func.isRequired,
		resourceList: PropTypes.array.isRequired
	};
	
	render() {
		const {container, virtualModelList, virtualListHeight, parentServicePrice, modelServicePrice, resourceList, kfz_id, service_id} = this.props;
		return (
			<React.Fragment>
				<VirtualList
					width={container.width}
					height={container.maxHeight - 40}
					itemCount={virtualModelList.length}
					itemSize={virtualListHeight}
					renderItem={({index, style}) => {
						const model = virtualModelList[index];
						const modelService = modelServicePrice(model, parentServicePrice);
						return(
							<Flex align={"center"} key={model.model_id} direction={"column"} style={{...style, height: virtualListHeight, borderBottom: '1px solid #ccc', paddingLeft: 10, paddingRight: 10, marginRight: 10}}>
								<Flex valign={'center'} align={"space-between"} style={{paddingTop: 5, paddingBottom: 5}} >
									<ResponsiveFlexChild grow={1} shrink={1}>
										<Flex wrap={'wrap'} valign={'center'} style={{width: '100%', fontSize: 15, fontWeight: 'normal'}} gutterWidth={10}>
											<ResponsiveFlexChild shrink={0} ellipsis widthELG={4/9} widthLG={3/9} widthMD={6/9} widthSM={6/9} width={2/2}>{model.type} ({model.model_id})</ResponsiveFlexChild>
											<ResponsiveFlexChild shrink={0} ellipsis widthELG={1/9} widthLG={1/9} widthMD={3/9} widthSM={3/9} width={1/2}>{model.fromYear} - {model.tillYear}</ResponsiveFlexChild>
											<ResponsiveFlexChild shrink={0} ellipsis widthELG={1/9} widthLG={1/9} widthMD={2/9} widthSM={3/9} width={1/2}>{model.hsn}/{model.tsn}</ResponsiveFlexChild>
											<ResponsiveFlexChild shrink={0} ellipsis widthELG={3/9} widthLG={3/9} widthMD={4/9} widthSM={6/9} width={1}>{model.ccm} ccm, {model.ps} ps, {model.kw} kw</ResponsiveFlexChild>
										</Flex>
									</ResponsiveFlexChild>
									{modelService && <ResponsiveFlexChild shrink={0} style={{textAlign: 'right'}} hiddenXS hiddenSM>
										{/*<PriceInfo icon={modelService.level} hours={modelService.hours_of_work} price={modelService.price}/>*/}
										<ButtonGroup>
											<PriceView kfz_id={kfz_id} service_id={service_id} model_id={model.model_id}/>
											<ResourceView kfz_id={kfz_id} service_id={service_id} model_id={model.model_id}/>
										</ButtonGroup>
									</ResponsiveFlexChild>}
								</Flex>
								<Flex  valign={'center'} align={"space-between"}  style={{paddingTop: 5, paddingBottom: 5}}>
									<ResourceChips service_id={service_id} model={model}/>
									<ResourceButton service_id={service_id} model={model} icon={"right"} onLeft inline focusOnSelect
									                positionalAwareness resourceList={resourceList} onSubmit={data => {
										data.close();
									}}/>
								</Flex>
							</Flex>
						);
					}}
				/>
			</React.Fragment>
		);
	}
}

const PriceInfo = ({icon, hours, price, ...props}) => {
	return(
		<ButtonGroup {...props}>
			{icon === 'car' ? <Button disabled icon><Icon name={'car'}/></Button> : null}
			{icon === 'house' ? <Button disabled icon><Icon name={'home'}/></Button> : null}
			<Button icon><Icon name={'time'}/> {hours} &nbsp;&nbsp;<Icon name={'eur'}/> {price}</Button>
		</ButtonGroup>
	);
};
PriceInfo.propTypes = {
	icon: PropTypes.oneOf(['car', 'house']),
	hours: PropTypes.number.isRequired,
	price: PropTypes.number.isRequired
};

export default translate()(withScreenReader(MasterDataKfzServiceView));