import {avatar, avatar_gender, propertiesExists} from "../Logic/helper";
import BaseModel from "./BaseModel";
import {isFunction, isNumber, isArray} from "lodash";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loadWorker, workerAction__collect} from "../actions/workerActions";
import withInit, {withChange} from "../Logic/withInit";
import {isLoading} from "../actions/loaderActions";
import {PROC_WORKER} from "../actions";
import {ProviderComponent} from "../components/partials";

const Provider = ({worker, children, placeholder}) => worker && isFunction(children) ? children(worker) : placeholder;
Provider.propTypes = {
	workers_id: PropTypes.number,
	placeholder: PropTypes.node
};
Provider.defaultProps = {
	placeholder: null
};

const CollectProvider = ({workers, children, placeholder, loading, allowEmpty}) => isArray(workers) && isFunction(children) ? children(workers, loading) : placeholder;
CollectProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({workers_id: PropTypes.number})])),
	preview: PropTypes.bool,
	placeholder: PropTypes.node,
	onSuccess: PropTypes.func,
	full: PropTypes.bool,
	initOnly: PropTypes.bool,
	dynamic: PropTypes.bool,
	allowEmpty: PropTypes.bool
};
CollectProvider.defaultProps = {
	ids: [],
	placeholder: null,
	preview: false,
	full: false,
	dynamic: true,
	allowEmpty: false
};

export class Worker extends BaseModel {
	_validateModel(model) {
		return propertiesExists(model, "workers_id", "house_id", "gender", "right_group_id");
	}
	

	get first_name() {
		return this.__model.first_name;
	}
	
	get last_name() {
		return this.__model.name;
	}
	
	get right_id() {
		return this.__model.right_group_id;
	}
	
	get is_engineer() {
		return this.right_id === 'engineer';
	}
	
	get is_support() {
		return this.right_id === 'support';
	}
	
	get name() {
		return this.join('u||name', 'u||first_name');
	}
	
	get initials() {
		return [this.first_name, this.name].filter(Boolean).map(v => v.substr(0, 1).toUpperCase()).join('');
	}
	
	get name_short() {
		return this.join('u||name', 'c||first_name');
	}
	
	get email() {
		return this.__model.email;
	}
	
	get active() {
		return this.__model.active;
	}
	
	get section() {
		return this.join('rights.groups.{{right_group_id}}');
	}
	
	get gender() {
		return this.join('gender.{{gender}}');
	}
	
	get workers_id() {
		return this.__model.workers_id;
	}
	
	get id() {
		return this.workers_id;
	}
	
	get house_id() {
		return this.__model.house_id;
	}
	
	get house() {
		return this.__model.carhouse;
	}
	
	get avatar() {
		return avatar(this.__model.avatar, this.__model.gender);
	}
	
	get avatar_gender() {
		return avatar_gender(this.__model.gender);
	}
	
	get rights() {
		return this.__model.rights;
	}
	
	get rights_custom() {
		return this.__model.customRights;
	}
	
	get avatar_background() {
		return `url("${this.avatar}")`;
	}
	
	get avatar_gender_background() {
		return `url("${this.avatar_gender}")`;
	}
	
	get logged_at() {
		return this.__model.logged_at;
	}
	
	
	is(worker) {
		if (isNumber(worker)) {
			return worker === this.id;
		}
		return worker && worker.workers_id && worker.workers_id === this.id;
	}
	
	static Provider = connect(
		(state, props) => {
			const w = state.map.workers[props.workers_id] || state.workers.list[props.workers_id];
			return {
				worker: w ? new Worker(w) : null
			};
		},
		(dispatch, props) => {
			return {
				init: () => {
					props.workers_id && dispatch(loadWorker(props.workers_id));
				}
			};
		}
	)(withInit(Provider));
	
	static CollectProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.workers_id || id)).filter(Boolean);
			let workers = [...ids].map(id => state.map.workers[id] || null);
			if (!workers.length && !props.allowEmpty) {
				workers = null;
			} else if(workers.includes(null)) {
				if (props.preview) {
					workers = workers.filter(Boolean);
				} else {
					workers = null;
				}
			}
			if (props.useModel && isArray(workers)) {
				workers = [...workers.map(w => w ? new Worker(w) : null)].filter(Boolean);
			}
			return {
				workers,
				ids,
				loading: isLoading(state, PROC_WORKER)
			}
		},
		(dispatch, props) => ({
			withChange: (_props) => {
				_props.ids && _props.ids.length && dispatch(workerAction__collect(_props.ids));
			}
		})
	)(withChange(CollectProvider, {access: 'ids', dynamicIndex: 'dynamic'}));
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.workers[id];
			data = data ? new Worker(data) : null;
			return {data};
		}
	)(ProviderComponent)
}

Worker.CollectProvider.propTypes = {
	useModel: PropTypes.bool
};
Worker.CollectProvider.defaultProps = {
	useModel: true
};