import {loadCarhouses} from "../../actions/carhouseActions";
// import CarhouseView from "../../components/extern/CarhouseView";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {CarhouseView} from "../../components/extern";

const mapStateToProps = state => ({
	houses: state.carhouse.list
});

const mapDispatchToProps = dispatch => ({
	onLoad: () => dispatch(loadCarhouses()),
	onDetail: ident => dispatch(push('/' + ident))
});



export default connect(mapStateToProps, mapDispatchToProps)(CarhouseView);