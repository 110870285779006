import DialogRootView from './DialogRootView';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {dismissDialog} from "../../actions/dialogActions";

let DialogController = connect(
	(state, props) => ({
	
	}),
	(dispatch, props) => ({
		dispatch,
		onDismiss: () => {
			props.onDismiss ? props.onDismiss() : dispatch(dismissDialog())
		}
	})
)(DialogRootView);

DialogController.propTypes = {
	dismiss: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	head: PropTypes.node,
	style: PropTypes.object,
	type: PropTypes.oneOf([null, 'sheet']),
	beforeCancel: PropTypes.node,
	afterCancel: PropTypes.node,
	cancelText: PropTypes.string,
	onDismiss: PropTypes.func
};
DialogController.defaultProps = {
	dismiss: true,
	className: '',
	head: null,
	style: {},
	type: null,
	cancelText: 'actions.cancel',
	beforeCancel: null,
	afterCancel: null
};
export default DialogController;