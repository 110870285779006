import {FREEDAY_ACTION, RESET_ACTION} from "../actions/types";
import {updateCache, updateCacheData} from "ith-redux-helpers";
import {CACHE_FREEDAYS, CACHE_FREEDAYS_WORKERS, CACHE_TIME_FREEDAYS} from "../actions";

export const defaultFreedaysState = {
	list: [],
	workers: [],
	cache: {},
	loaded: {
		list: false
	}
};

export default (state = defaultFreedaysState, {type, list, id, freeday}) => {
	switch(type) {
		case RESET_ACTION:
			return defaultFreedaysState;
		case FREEDAY_ACTION.LOAD:
			if (list !== null) {
				return {
					list,
					cache: {
						...state.cache,
						...updateCache(CACHE_FREEDAYS)
					},
					loaded: {
						...state.loaded,
						list: true
					}
				};
			}
			return defaultFreedaysState;
		case FREEDAY_ACTION.REMOVE_BY_ID:
			return {
				...state,
				list: state.list.filter(evt => evt.id !== id),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_FREEDAYS)(CACHE_TIME_FREEDAYS)
				}
			};
		case FREEDAY_ACTION.LOAD_WORKERS:
			return {
				...state,
				workers: list,
				cache: {
					...state.cache,
					...updateCache(CACHE_FREEDAYS_WORKERS)
				}
			};
		case FREEDAY_ACTION.PUT_WORKERS:
			return {
				...state,
				workers: [...state.workers, freeday],
				cache: {
					...state.cache,
					...updateCacheData(CACHE_FREEDAYS_WORKERS)(CACHE_TIME_FREEDAYS)
				}
			};
		case FREEDAY_ACTION.UPDATE_WORKERS:
			return {
				...state,
				workers: state.workers.map(e => {
					if ( e.freedays_id === freeday.freedays_id ) {
						return freeday;
					}
					return e;
				}),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_FREEDAYS_WORKERS)(CACHE_TIME_FREEDAYS)
				}
			};
		case FREEDAY_ACTION.REMOVE_WORKERS:
			return {
				...state,
				workers: state.workers.filter(e => e.freedays_id !== id),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_FREEDAYS_WORKERS)(CACHE_TIME_FREEDAYS)
				}
			};
		default:
			return state;
	}
};