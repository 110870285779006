import * as React from 'react';
import ImgNotFound from '../images/404.png';
import {Segment} from "semantic-ui-react";
import {translate} from "react-i18next";

export default translate()(({t}) => (
	<Segment basic style={{textAlign: 'center'}} padded={'very'}>
		<h2>{t('not-found')}</h2>
		<img src={ImgNotFound} alt={t('not-found')} />
	</Segment>
));