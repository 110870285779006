import * as React from 'react';
import PropTypes from 'prop-types';
import {
	Card, CardActions, CardHeader, CardText, Dialog, Divider, FlatButton, IconButton, List, ListItem,
	RaisedButton, Snackbar, TextField
} from "material-ui";
import FontAwesome from 'react-fontawesome';
import {ChromePicker} from 'react-color';
import {PRIMARY_COLOR} from "../../../../Logic/styles";
import {withRouter} from "react-router-dom";
import User from "../../../../models/User";
import {AHIconButton} from "../../../../Logic/extensions";
import {Icon} from "semantic-ui-react";
import {Flex} from "../../../partials/ActionHeader";


class MDResource extends React.Component {
	static propTypes = {
		onSave: PropTypes.func.isRequired,
		onUpdate: PropTypes.func,
		onDelete: PropTypes.func,
		resources: PropTypes.array.isRequired,
		user: PropTypes.instanceOf(User),
		mayRead: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayDelete: PropTypes.bool,
		onBack: PropTypes.func,
		hideBack: PropTypes.bool
	};
	static defaultProps = {
		onUpdate: null,
		onDelete: null,
		resources: [],
		mayRead: false,
		mayChange: false,
		mayCreate: false,
		mayDelte: false,
		hideBack: false
	};
	
	constructor(props) {
		super(props);
		this.state = {
			colorPicker: null,
			color: PRIMARY_COLOR,
			snackbar: null,
			create: false,
			edit: null,
			confirm: null,
			createResourceName: ''
		};
		// this.createResourceName = '';
		this.createResourceColor = null;
		this.chosenColor = null;
		this.handleOutSideClick = this.handleOutSideClick.bind(this);
		this.existsResource = this.existsResource.bind(this);
	}
	
	handleOutSideClick = () => this.setState({edit:null});
	
	componentDidMount() {
		window.addEventListener('click', this.handleOutSideClick, false);
	}
	
	componentWillUnmount() {
		window.removeEventListener('click', this.handleOutSideClick, false);
	}
	
	existsResource = (name) => {
		return this.props.resources.some(r => r.name.toLowerCase() === name.toLowerCase());
	};
	
	render() {
		let editName;
		const {edit} = this.state;
		const {
			onSave,
			onUpdate,
			onDelete,
			resources,
			onBack, hideBack,
			mayRead, mayChange, mayCreate, mayDelete
		} = this.props;
		
		if ( !mayRead ) {
			return null;
		}
		
		return (
			<Card>
				{!hideBack && onBack ?
					<Flex valign={'center'}>
						<AHIconButton onClick={onBack}><Icon name={'chevron left'}/></AHIconButton>
						<CardHeader title="Basiseinstellungen" subtitle="Ressourcen definieren"/>
					</Flex>
					 :
					<CardHeader title="Basiseinstellungen" subtitle="Ressourcen definieren"/>
				}
				<CardText>
					<List>
						<Divider/>
						{resources.sort((a,b) => a.name.localeCompare(b.name)).map(resource =>
							<ListItem
								key={resource.resource_id}
								onClick={(e) => {
									e.stopPropagation();
									if ( mayChange) {
										this.setState({edit: resource.resource_id});
									}
								}}
								rightIconButton={
									mayDelete && !resource.fixed && onDelete ?
										<IconButton style={{fontSize: 16}}
			                                onClick={(e) => {
			                                	e.preventDefault();
			                                	e.stopPropagation();
												this.setState({confirm: onDelete.bind(this, resource.resource_id, () => {
													this.setState({confirm: null, snackbar: 'deleted'});
												})});
							}}><FontAwesome name="close" color="black"/></IconButton> : null}>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<span>{!onUpdate || edit !== resource.resource_id ? resource.name : <React.Fragment><TextField onChange={(_, value) => editName = value} id={`edit-button-${resource.resource_id}`} defaultValue={resource.name} /><FlatButton onClick={(e) =>{
										e.stopPropagation();
										onUpdate(resource.resource_id, editName || resource.name, null, () => {
											this.setState({edit: null, snackbar: 'edited'});
											editName = null;
										})
									}} primary={true} label="Ändern"/></React.Fragment>}</span>
									<span style={{display: 'block', height: 20, width: 20, backgroundColor: resource.color}} onClick={e => {
										if (mayChange && onUpdate) {
											e.stopPropagation();
											e.preventDefault();
											this.setState({colorPicker: resource.resource_id, color: resource.color});
											this.chosenColor = resource.color;
										}
									}}>&nbsp;</span>
								</div>
							</ListItem>
						)}
						<Divider/>
					</List>
				</CardText>
				<CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
					<FlatButton style={{paddingLeft: 10, paddingRight: 10}} onClick={() => this.props.history.push('/master-data/resources/assign')}>Zu den Zuweisungen</FlatButton>
					{mayCreate && <FlatButton style={{paddingLeft: 10, paddingRight: 10}} onClick={() => this.setState({create: true})} primary={true}>Weitere Ressource erstellen</FlatButton>}
				</CardActions>
				{/* COLOR UPDATE DIALOG*/}
				<Dialog open={this.state.colorPicker !== null}
				        bodyStyle={{display: 'flex', justifyContent: 'center'}}
						actionsContainerStyle={{display: 'flex', justifyContent: 'space-between'}}
				        title="Farbe wählen"
				        onRequestClose={() => this.setState({colorPicker: null})}
				        actions={[
					<FlatButton label="Schließen" onClick={() => this.setState({colorPicker: null})}/>,
					<RaisedButton primary={true}  label="Speichern" onClick={() => {
						onUpdate(this.state.colorPicker, null, this.chosenColor, () => this.setState({colorPicker: null, snackbar: 'color_saved'}))
					}}/>
				]}>
					<ChromePicker disableAlpha={true} color={this.state.color} onChangeComplete={(color) => {
						this.chosenColor = color.hex;
						this.setState({color});
					}}/>
				</Dialog>
				{/* CREATE NEW ENTRY DIALOG */}
				<Dialog open={this.state.create}
				        onRequestClose={() => this.setState({create:false})}
				        bodyStyle={{display: 'flex', justifyContent: 'space-around'}}
				        title="Ressource erstellen"
				        actionsContainerStyle={{display: 'flex', justifyContent: 'space-between'}}
				        actions={[
					        <FlatButton label="Abbrechen" onClick={() => this.setState({create: false})}/>,
					        <RaisedButton primary={true}  disabled={this.state.createResourceName === '' || this.existsResource(this.state.createResourceName)} label="Speichern" onClick={() => {
					        	onSave(this.state.createResourceName, this.createResourceColor, () => {
							        this.setState({create: false, snackbar: 'created', createResourceName: ''})
						        });
					        }}/>
				        ]}>
					<TextField errorText={this.existsResource(this.state.createResourceName) ? 'Ressource bereits vorhangen' : null} floatingLabelText="Name der Ressource" onChange={(_, value) => this.setState({createResourceName: value.trim()})}/>
					<ChromePicker disableAlpha={true} color={this.state.color} onChangeComplete={color => {
						this.createResourceColor = color.hex;
						this.setState({color});
					}}/>
				</Dialog>
				<Dialog
					actionsContainerStyle={{display: 'flex', justifyContent: 'space-between'}}
					open={this.state.confirm !== null}
					onRequestClose={() => this.setState({confirm: null})}
					actions={[
					<FlatButton label="Abbrechen" onClick={() => this.setState({confirm: null})}/>,
						<RaisedButton label="Löschen" primary={true} onClick={() => this.state.confirm()}/>
				]}>
					Bist du dir sicher? Alle Arbeitszeiten, die an diese Ressource geknüpft sind, werden gelöscht.
				</Dialog>
				<Snackbar open={this.state.snackbar === 'color_saved'} autoHideDuration={3000} message="Änderung der Farbe gespeichert." onRequestClose={() => this.setState({snackbar: null})} />
				<Snackbar open={this.state.snackbar === 'created'} autoHideDuration={3000} message="Ressource erstellt" onRequestClose={() => this.setState({snackbar: null})} />
				<Snackbar open={this.state.snackbar === 'deleted'} autoHideDuration={3000} message="Ressource gelöscht" onRequestClose={() => this.setState({snackbar: null})} />
				<Snackbar open={this.state.snackbar === 'edited'} autoHideDuration={3000} message="Name der Ressource geändert" onRequestClose={() => this.setState({snackbar: null})} />
			</Card>
		);
	}
}

export default withRouter(MDResource);