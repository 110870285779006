import * as React from "react";
import {useEffect} from "react";
import {EasyFlex} from "../../../components/partials/ActionHeader";
import {IconAlertAdd} from "../../../Logic/icons";
import {COLORS} from "../../../Logic/constants";
import {Button, Icon, Modal, Segment, Table} from "semantic-ui-react";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import moment from "../../../Logic/Moment";
import {connect, useDispatch, useSelector} from "react-redux";
import {soundAction__play, soundAction__stop} from "../../../actions/soundActions";
import PropTypes from "prop-types";
import {STORE_SECTION_WORK} from "../../../actions";
import {sessionStorageAction__addAppointmentNotificationId} from "../../../actions/sessionStorageActions";
import {trueNull} from "../../../Logic/extensions";
import OrderQuickView from "../orders/OrderQuickView";
import {get} from "lodash";
import {Client, Vehicle} from "../../../models";
import ClientPopup from "../../../components/intern/clients/ClientPopup";
import VehiclePopup from "../../../components/intern/vehicles/VehiclePopup";
import {translate} from "react-i18next";

// export class AlertOrder extends React.Component {
// 	static propTypes = {
// 		order: PropTypes.object
// 	};
//
// 	componentDidMount() {
// 		this.props.play();
// 	}
//
// 	componentWillUnmount() {
// 		this.props.stop();
// 	}
//
// 	render() {
// 		if (!this.props.order) return null;
// 		let { order: {client_id, vehicle_id, deliver_point, latest_point, delivered_at, fetch_point, hours_of_work}} = this.props;
//
// 		return <Table basic={"very"} striped color={"grey"} inverted style={{marginTop: 30, marginBottom: 30}}>
// 			<Table.Body>
// 				<Table.Row>
// 					<Table.Cell colSpan={2}>
// 						<pre>{JSON.stringify(this.props.order, null, 2)}</pre>
// 					</Table.Cell>
// 				</Table.Row>
// 				<Table.Row>
// 					<Table.Cell>Kunde</Table.Cell>
// 					<Table.Cell>
// 						<Client.Provider placeholder={<Icon name={"spinner"} loading/>} client_id={client_id}>
// 							{client =>
// 								<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_BETWEEN}>
// 									<span>{client.contact}</span>
// 									<ClientPopup client={client}><Icon name={"info"}/></ClientPopup>
// 								</EasyFlex>
// 							}
// 						</Client.Provider>
// 					</Table.Cell>
// 				</Table.Row>
// 				<Vehicle.Provider vehicle_id={vehicle_id} placeholder={<Table.Row><Table.Cell colSpan={2}><Segment basic loading/></Table.Cell></Table.Row>}>{vehicle =>
// 					<React.Fragment>
// 						<Table.Row>
// 							<Table.Cell>Fahrzeug</Table.Cell>
// 							<Table.Cell>
// 								<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
// 									<span>{vehicle.name || 'n.v.'}</span>
// 									<VehiclePopup vehicle={vehicle}><Icon name={"info"}/></VehiclePopup>
// 								</EasyFlex>
// 							</Table.Cell>
// 						</Table.Row>
// 						<Table.Row>
// 							<Table.Cell>Kennzeichen</Table.Cell>
// 							<Table.Cell>{vehicle.registration_shield || 'n.v.'}</Table.Cell>
// 						</Table.Row>
// 						<Table.Row>
// 							<Table.Cell>Fahrgestellnummer</Table.Cell>
// 							<Table.Cell>{vehicle.chassis_number || 'n.v.'}</Table.Cell>
// 						</Table.Row>
// 						<Table.Row>
// 							<Table.Cell>HSN/TSN</Table.Cell>
// 							<Table.Cell>{vehicle.hsn_tsn || 'n.v.'}</Table.Cell>
// 						</Table.Row>
// 					</React.Fragment>
// 				}</Vehicle.Provider>
// 				<Table.Row>
// 					<Table.Cell>Abgabe</Table.Cell>
// 					<Table.Cell>{moment(deliver_point).format("LLL")}</Table.Cell>
// 				</Table.Row>
// 				<Table.Row>
// 					<Table.Cell>Abgegeben um</Table.Cell>
// 					<Table.Cell>{moment(delivered_at).format("LLL")}</Table.Cell>
// 				</Table.Row>
// 				<Table.Row>
// 					<Table.Cell>Letzter Zeitpunkt</Table.Cell>
// 					<Table.Cell>{moment(latest_point).format("LLL")}</Table.Cell>
// 				</Table.Row>
// 				<Table.Row>
// 					<Table.Cell>Abholung</Table.Cell>
// 					<Table.Cell>{moment(fetch_point).format("LLL")}</Table.Cell>
// 				</Table.Row>
// 				<Table.Row>
// 					<Table.Cell>Arbeitszeit</Table.Cell>
// 					<Table.Cell>{hours_of_work || 'n.v.'} Std.</Table.Cell>
// 				</Table.Row>
// 			</Table.Body>
// 		</Table>;
// 	}
// }
//
// AlertOrder = connect(
// 	null,
// 	dispatch => ({
// 		play: () => dispatch(soundAction__play),
// 		stop: () => dispatch(soundAction__stop)
// 	})
// )(AlertOrder);

export const AlertOrder = ({order}) => {
	const dispatch = useDispatch()
	if (!order) return null;
	
	useEffect(() => {
		dispatch(soundAction__play)
		return () => {
			dispatch(soundAction__stop)
		}
	}, [!!order])
	const orderVehicleId = get(order, 'order_vehicle_id', 0)
	const orderVehicle = useSelector(state => get(state, `map.order2vehicles.${orderVehicleId}`, {}))
	const clientId = get(order, 'client_id', get(orderVehicle, 'client_id', null))
	const vehicleId = get(order, 'vehicle_id', get(orderVehicle, 'client_id', null))
	let {deliver_point, latest_point, delivered_at, fetch_point, hours_of_work} = order
	
	return <Table basic={"very"} definition striped color={"grey"} inverted style={{marginTop: 30, marginBottom: 30}}>
		<Table.Body>
			<Table.Row>
				<Table.Cell>Kunde</Table.Cell>
				<Table.Cell>
					{clientId && <Client.Provider placeholder={<Icon name={"spinner"} loading/>} client_id={clientId}>
						{client =>
							<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_BETWEEN}>
								<span>{client.contact}</span>
								<ClientPopup client={client}><Icon name={"info"}/></ClientPopup>
							</EasyFlex>
						}
					</Client.Provider>}
				</Table.Cell>
			</Table.Row>
			{vehicleId && <Vehicle.Provider vehicle_id={vehicleId} placeholder={<Table.Row><Table.Cell colSpan={2}><Segment basic loading/></Table.Cell></Table.Row>}>{vehicle =>
				<React.Fragment>
					<Table.Row>
						<Table.Cell>Fahrzeug</Table.Cell>
						<Table.Cell>
							<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
								<span>{vehicle.name || 'n.v.'}</span>
								<VehiclePopup vehicle={vehicle}><Icon name={"info"}/></VehiclePopup>
							</EasyFlex>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Kennzeichen</Table.Cell>
						<Table.Cell>{vehicle.registration_shield || 'n.v.'}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Fahrgestellnummer</Table.Cell>
						<Table.Cell>{vehicle.chassis_number || 'n.v.'}</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>HSN/TSN</Table.Cell>
						<Table.Cell>{vehicle.hsn_tsn || 'n.v.'}</Table.Cell>
					</Table.Row>
				</React.Fragment>
			}</Vehicle.Provider>}
			<Table.Row>
				<Table.Cell>Abgabe</Table.Cell>
				<Table.Cell>{moment(deliver_point).format("LLL")}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Abgegeben um</Table.Cell>
				<Table.Cell>{moment(delivered_at).format("LLL")}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Letzter Zeitpunkt</Table.Cell>
				<Table.Cell>{moment(latest_point).format("LLL")}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Abholung</Table.Cell>
				<Table.Cell>{moment(fetch_point).format("LLL")}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell>Arbeitszeit</Table.Cell>
				<Table.Cell>{hours_of_work || 'n.v.'} Std.</Table.Cell>
			</Table.Row>
		</Table.Body>
	</Table>
}

// export const checkAlertOrders = (list, order, seen = []) => {
// 	if (!order) {
// 		return;
// 	}
// 	list = list.sort((a,b) => new Date(a.latest_point) < new Date(b.latest_point) ? -1 : 1);
// 	const now = new Date();
// 	for (const i in list) {
// 		if (!list.hasOwnProperty(i)) continue;
// 		const o = list[i];
// 		if (o.order_id === order.order_id) {
// 			if (i > 0) {
// 				for (let k = 0; k < 1; ++k) {
// 					const wo = list[k];
// 					if (wo.delivered_at && wo.released_at) {
// 						const time = moment(wo.delivered_at).add(60 * 30, 's').toDate();
// 						if ((!seen.includes(wo.order_id)) && (time >= now || new Date(wo.latest_point) < now) && (!this.state.tracking || this.state.tracking)) {}
// 					}
// 				}
// 			}
// 		}
// 	}
// };


export class AppointmentAlertDialog extends React.Component {
	static propTypes = {
		orders: PropTypes.arrayOf(PropTypes.object),
		onUpdateSeen: PropTypes.func.isRequired
	};
	
	static defaultProps = {
		orders: []
	};
	
	state = {
		detailed_order: null
	};
	
	showDetail = order => () => this.setState({detailed_order: order});
	
	render() {
		const {orders, onUpdateSeen, t} = this.props;
		const {detailed_order} = this.state;
		
		if (!orders.length) {
			return null;
		}
		const alertOrder = orders[0];
		return [
			// <BasicDialog key={"alert-dialog"} open autoScrollBodyContent title={
			// 	<Flex valign={"stretch"}>
			// 		<IconAlertAdd className={"alert-icon"} color={COLORS.SEMANTIC_RED} style={{width: 64, height: 64, marginRight: 40}}/>
			// 		<h3>Ein Auftrag mit erhöhter Priorität eingegangen</h3>
			// 	</Flex>
			// } actions={
			// 	<Flex align={"space-between"}>
			// 		<Button icon={"th list"} circular color={"blue"} onClick={this.showDetail(alertOrder)}/>
			// 		<Button color={"green"} onClick={() => {
			// 			onUpdateSeen(alertOrder.order_id);
			// 		}}>Okay, das schaue ich mir an!</Button>
			// 	</Flex>
			// }>
			// 	<AlertOrder order={alertOrder}/>
			// 	{orders.length > 1 &&
			// 		<EasyFlex align={EasyFlex.align.CENTER}>
			// 			<div dangerouslySetInnerHTML={{__html: t('order.and-more', {count: orders.length - 1})}}/>
			// 		</EasyFlex>
			// 	}
			// </BasicDialog>,
			<Modal
				key={'alert-modal'}
				open
			    centered={false}
			>
				<Modal.Header>
					<EasyFlex valign={EasyFlex.valign.CENTER}>
						<IconAlertAdd className={"alert-icon"} color={COLORS.SEMANTIC_RED} style={{width: 64, height: 64, marginRight: 40}}/>
						<div>Ein Auftrag mit erhöhter Priorität eingegangen</div>
					</EasyFlex>
				</Modal.Header>
				<Modal.Content>
					<AlertOrder order={alertOrder}/>
					{orders.length > 1 &&
					<EasyFlex align={EasyFlex.align.CENTER}>
						<div dangerouslySetInnerHTML={{__html: t('order.and-more', {count: orders.length - 1})}}/>
					</EasyFlex>
					}
				</Modal.Content>
				<Modal.Actions>
					<EasyFlex align={EasyFlex.align.SPACE_BETWEEN}>
						<Button icon={"th list"} circular color={"blue"} onClick={this.showDetail(alertOrder)}/>
						<Button color={"green"} onClick={() => {
							onUpdateSeen(alertOrder.order_id);
						}}>Okay, das schaue ich mir an!</Button>
					</EasyFlex>
				</Modal.Actions>
			</Modal>,
			<OrderViewDialog key={"order-dialog"} open={Boolean(detailed_order)} onClose={this.showDetail(null)}>
				{trueNull(detailed_order) && <OrderQuickView order_id={detailed_order.order_id} onRequestClose={this.showDetail(null)}/>}
			</OrderViewDialog>
		];
	}
}

AppointmentAlertDialog = connect(
	(state, props) => {
		return {
			order: props.order_id ? get(state, ["appointments", "section", STORE_SECTION_WORK, "orders", props.order_id], null) : null,
			worklist: state.appointments.list || [],
			seen: state.session.notification_appointment_ids
			
		};
	},
	(dispatch, props) => {
		return {
			updateSeen: id => dispatch(sessionStorageAction__addAppointmentNotificationId(id))
		};
	}
)(AppointmentAlertDialog);
AppointmentAlertDialog = translate()(AppointmentAlertDialog);

export default AppointmentAlertDialog;