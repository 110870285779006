/////////////
//      Quick Vehicle
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {Popup, Segment, Table} from "semantic-ui-react";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {vehicleAction__getByOrder} from "../../../../actions/vehicleActions";
import {Vehicle} from "../../../../models";
import {IconRestore} from "../../../../Logic/icons";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";
import {Trans, translate} from "react-i18next";
import {CircleNotchLoader} from "../../../../components/Loaders";

const ComparedData = ({history, current, renderHistory, renderCurrent, na, prefix, suffix, t}) => (
	current !== history ? <div>
			<div>{prefix} {renderCurrent || current || na} {suffix}</div>
			<EasyFlex valign={EasyFlex.valign.CENTER} style={{opacity: 0.4}}>
				<Popup inverted content={t('...', "Wert bei Auftragserstellung")} trigger={<IconRestore style={{marginRight: 4}}/>}/>{prefix} {renderHistory || history || na} {suffix}
			</EasyFlex>
		</div> :
		<div>{prefix} {renderCurrent || current || na} {suffix}</div>
);
ComparedData.propTypes = {
	current: PropTypes.any,
	history: PropTypes.any,
	renderCurrent: PropTypes.any,
	renderHistory: PropTypes.any,
	na: PropTypes.node,
	prefix: PropTypes.node,
	suffix: PropTypes.node
};
ComparedData.defaultProps = {
	na: 'k.A.',
	prefix: null,
	suffix: null
};

const QuickVehicleBody = ({vehicle, history, t}) => (
	<Table.Body>
		<Table.Row>
			<Table.Cell><Trans defaults="Bezeichnung"/></Table.Cell>
			<Table.Cell>
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
					<ComparedData current={vehicle.name} history={history.name} t={t}/>
					{/*{mayChange && <Popup*/}
					{/*	inverted*/}
					{/*	content={"Fahrzeugdaten bearbeiten"}*/}
					{/*	trigger={<IconButton onClick={this.editVehicle(true)}><IconEdit/></IconButton>}*/}
					{/*/>}*/}
				</EasyFlex>
			</Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Kennzeichen"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.registration_mark} history={history.registration_mark} renderCurrent={vehicle.registration_shield}
			                          renderHistory={history.registration_shield}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="HSN / TSN"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.hsn_tsn} history={history.hsn_tsn}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Fahrgestellnummer"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.chassis_number} history={history.chassis_number}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Fahrleistung"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.km} history={history.km} suffix={"km"}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Modeljahr"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.model_year} history={history.model_year}/></Table.Cell>
		</Table.Row>
		<Table.Row>
			<Table.Cell><Trans defaults="Erstzulassung"/></Table.Cell>
			<Table.Cell><ComparedData t={t} current={vehicle.first_registration_xx} history={history.first_registration_xx}/></Table.Cell>
		</Table.Row>
	</Table.Body>
);

export class OrderQuickVehicle extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		order2vehicle: PropTypes.object,
		vehicle: PropTypes.object,
		onlyBody: PropTypes.bool
	};
	static defaultProps = {
		order: {},
		order2vehicle: {},
		vehicle: {}
	};
	
	state = {
		edit: false
	};
	
	editVehicle = edit => () => this.setState({edit});
	
	render() {
		const {
			order2vehicle: vehicleData,
			vehicle: currentVehicle,
			onlyBody,
			t
			// rights: {
			// 	mayChange
			// }
		} = this.props;
		
		if (isEmpty(vehicleData) || isEmpty(currentVehicle)) {
			if (onlyBody) {
				return <Table.Body>
					<Table.Row>
						<Table.Cell textAlign={'center'} colSpan={2}>
							<CircleNotchLoader/>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			}
			return <Segment loading basic style={{minWidth: 100, minHeight: 100}}/>;
		}
		const history = new Vehicle(vehicleData);
		
		if (onlyBody) {
			return <Vehicle.Provider vehicle_id={history.vehicle_id}>{vehicle =>
				<QuickVehicleBody history={history} vehicle={vehicle} t={t}/>
			}</Vehicle.Provider>;
		}
		return (
			<Vehicle.Provider vehicle_id={history.vehicle_id}>{vehicle =>
				<div>
					<Table padded basic={"very"} striped celled style={{margin: "30px 15px", width: "calc(100% - 30px)"}}>
						<QuickVehicleBody history={history} vehicle={vehicle} t={t}/>
					</Table>
				{/*	<BasicDialog
						open={this.state.edit}
						closeButton
						autoDetectWindowHeight
						autoScrollBodyContent
						onClose={this.editVehicle(false)}
					>
						<h3 style={{position: "fixed", backgroundColor: "rgba(255,255,255,0.7)", display: "flex", alignItems: "center"}}>
							<Icon name={"car"}/> <span style={{marginLeft: 5}}>{vehicle.name}</span>
						</h3>
						<VehicleEditController client_id={vehicle.client_id} vehicle_id={vehicle.id} showHeader={false} onAfterSave={this.editVehicle(false)}/>
					</BasicDialog>*/}
				</div>
			}</Vehicle.Provider>
		);
	}
}

OrderQuickVehicle = withInit(OrderQuickVehicle);
OrderQuickVehicle = withRights(SECTION.CLIENTS, OrderQuickVehicle);
OrderQuickVehicle = connect(
	(state, props) => {
		const order_id = Number(props.order_id);
		const order = state.appointments.orders[order_id] || {};
		let order2vehicle = {};
		let vehicle = {};
		if (!isEmpty(order)) {
			order2vehicle = state.vehicles.order2vehicle[order.order_vehicle_id] || {};
			if (!isEmpty(order2vehicle)) {
				vehicle = state.vehicles.list[order2vehicle.vehicle_id];
			}
		}
		return {
			order_id,
			order,
			order2vehicle,
			vehicle
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(vehicleAction__getByOrder(props.order_id, [true, true, true]));
		}))
	})
)(OrderQuickVehicle);
OrderQuickVehicle = translate()(OrderQuickVehicle);