import React from "react";
import PropTypes from 'prop-types';
import {Trans} from "react-i18next";


export const TimeTrans = ({type, value}) => <Trans i18nKey={'time.' + type} values={{value}}/>;
TimeTrans.propTypes = {
	type: PropTypes.oneOf([
		'pure',
		'pure-short',
		'pure-long',
		'full',
		'full-long',
		'date',
		'date-long',
		'date-short',
		'time',
		'time-full'
	]).isRequired,
	value: PropTypes.instanceOf(Date).isRequired
}