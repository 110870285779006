import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {SECTION} from "../../../Logic/constants";


export const getOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`tires.${value}`, value), value})))
export const getTireOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`tires.${value}`, value), value})))
export const getExtractOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`extract-options.${value}`, value), value})))
export const getSetTypeOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`set-type-options.${value}`, value), value})))
export const getServiceOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`wheel-service-options.${value}`, value), value})))
export const getRimOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`wheel-rim.${value}`, value), value})))
export const getEwoAvailableOptions = memoizeOne((list, t) => list.map(value => ({key: value, text: t(`wheel-ewo-available.${value}`, value), value})))

const sections = [SECTION.ORDERS, SECTION.CLIENTS, SECTION.APPOINTMENTS]
export const mapRights = (rights) => ({
	read: sections.some(el => rights[el].mayRead),
	change: sections.some(el => rights[el].mayChange),
	create: sections.some(el => rights[el].mayCreate),
	delete: sections.some(el => rights[el].mayDelete)
})

export const toColumns = (items, columns) => {
	console.debug('toColums', items, columns)
	let list = [];
	for (let i = 0; i < columns; i++) {
		list.push([]);
	}
	
	for (const i in items) {
		const entry = items[i];
		const index = i % columns;
		list[index].push(entry);
	}
	return list;
}