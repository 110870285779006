import * as React from "react";
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeading} from "../partials/ActionHeader";
import {WorkloadViewer} from "../../cointainer/intern/orders/WorkloadViewer";


export const WorkloadTest = () => (
	<Segment>
		<ActionHeader alignment={"flex-start"}>
			<ActionBackButton/>
			<ActionHeading>Workload Viewer</ActionHeading>
		</ActionHeader>
		<WorkloadViewer/>
	</Segment>
);