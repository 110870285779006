import * as React from 'react';
import PropTypes from 'prop-types';
import {Trans, translate} from "react-i18next";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading,} from "../../partials/ActionHeader";
import {withRouter} from "react-router-dom";
import {Col, Grid, Row} from "react-bootstrap";
import constants, {ACCESS_PROP_DEFAULTS, ACCESS_PROP_DEFINTIONS, COLORS, DATE_PICKER} from "../../../Logic/constants";
import {DatePicker, FlatButton, IconButton, MenuItem, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TextField} from "material-ui";

import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import SnackbarGroup from "../../../Tools/SnackbarGroup";
import ConfirmDialog from "../../../Tools/material-ui/ConfirmDialog";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import {MiniMenu, ResourceLink} from "../../../Logic/MiniMenu";
import {IconAddBox, IconDelete} from "../../../Logic/icons";
import {Button, Message, Modal, Segment} from "semantic-ui-react";
import KeyHandler, {KEYUP} from "react-key-handler";
import {freedaysCall__create} from "../../../actions/freedaysActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {trueNull} from "../../../Logic/extensions";


const CancableDatepicker = ({children, onCancel, className, visible, ...props}) => (
	<div className={`datepicker cancallable ${className}`} {...props}>
		{children}
		{visible && onCancel && <IconButton style={{fontSize: '18px'}} className="cancel-icon" onClick={e => {
			e.stopPropagation();
			onCancel();
		}}><FontAwesome className="icon" name="close" color="#222"/></IconButton>}
	</div>
);
CancableDatepicker.propTypes = {
	children: PropTypes.node.isRequired,
	onCancel: PropTypes.func,
	visible: PropTypes.bool.isRequired,
};
CancableDatepicker.defaultProps = {
	className: '',
	visible: true
};

const _defaultState = {
	createView: false,
	snackbar: null,
	confirm: null,
	creating: false,
	force: 0,
	data: {
		start: null,
		end: null,
		name: null
	},
	selection: []
};

class MasterDataClosed extends React.Component {
	static propTypes = {
		...ACCESS_PROP_DEFINTIONS,
		init: PropTypes.func.isRequired,
		addFreedays: PropTypes.func,
		removeFreedayByID: PropTypes.func,
		freedays: PropTypes.array.isRequired,
		onRemove: PropTypes.func.isRequired,
		onCreate: PropTypes.func.isRequired,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		...ACCESS_PROP_DEFAULTS,
		freedays: [],
		onCreate: freedaysCall__create,
		onNotification: dispatchSnack
	};
	
	constructor(props) {
		super(props);
		this.state = _defaultState;
		
		this.push = props.history.push;
	}
	
	lastDeletedRow = -1;
	
	create = async() => {
		const {onCreate, onNotification} = this.props;
		const {data: {start, end, name}, force} = this.state;
		try {
			this.setState({creating: true, force: false});
			const result = await onCreate({start, end, name, force: !!force});
			if (result) {
				if ("ack" in result) {
					this.setState({force: result.count || 1});
					onNotification(`Es gibt ${result.count || 'unbekannt viele'} Konflikte!`, COLORS.SEMANTIC_BROWN);
				} else {
					onNotification('Eintrag wurde erfolgreich durchgefuehrt')
					this.setState({..._defaultState})
				}
			} else {
				onNotification('Ein unbekannter Fehler ist aufgetreten!', 'alert')
			}
			
		} catch (e) {
			onNotification(e.message, 'alert');
		} finally {
			this.setState({creating: false});
		}
		
	}
	
	resetState = () => this.setState(_defaultState);
	
	showCreateView = (visible = false) => () => this.setState({createView: visible, force: 0, data: {name: null, start: null, end: null}});
	
	setData = (index) => (e, data) => this.setState(prev => ({
		...prev,
		data: {
			...prev.data,
			[index]: data
		}
	}));
	
	setDate = (index) => (e, date) => this.setState(state => ({
		...state,
		force: 0,
		data: {
			...state.data,
			[index]: date
		}
	}));
	
	getSelectionIDs = (selection) => {
		selection = selection || this.state.selection;
		const {freedays} = this.props;
		return selection.map(index => freedays[index].freedays_id);
	};
	
	onDirectDelete = (row) => () => {
		this.lastDeletedRow = row;
		if (this.state.selection.indexOf(row) === -1) {
			const ids = this.getSelectionIDs([row]);
			this.props.onRemove(ids, () =>
				this.setState(state => {
					const selection = state.selection.map(id => id > row ? id - 1 : id);
					return {selection};
				})
			);
		}
	};
	
	onSelectionChange = (selection) => {
		// selection = selection.filter(value => value !== this.lastDeletedRow);
		if (-1 !== this.lastDeletedRow) {
			this.lastDeletedRow = -1;
		} else {
			this.setState({selection});
		}
	};
	
	onMassDelete = () => {
		const ids = this.getSelectionIDs();
		this.props.onRemove(ids, () => this.setState({selection: []}));
	};
	
	getMinDate = () => {
		const date = this.state.data.start || new Date();
		return moment(date).add(1, 'd').toDate();
	};
	
	snackbar = (value = null) => this.setState({snackbar: null});
	
	onSave = () => {
		this.props.onCreate(this.state.data, () => {
			this.setState({
				..._defaultState,
				snackbar: 'put'
			});
		});
	};
	
	onRemove = id => {
		this.props.removeFreedayByID(id, () => {
			this.setState({confirm: null, snackbar: 'deleted'});
		});
	};
	
	confirmName = () => this.state.confirm ? this.state.confirm.name : 'Eintrag';
	
	render() {
		const {data: {start: startDate, end: endDate, name: dateName}, createView: showCreateView, selection, force, creating} = this.state;
		const {freedays, loading} = this.props;
		return (
			<div id="master-data__closed">
				<KeyHandler
					keyValue={"Insert"}
					keyEventName={KEYUP}
					onKeyHandle={this.showCreateView(true)}
				/>
				<KeyHandler
					keyValue={"Delete"}
					keyEventName={KEYUP}
					onKeyHandle={selection.length ? this.onMassDelete : null}
				/>
				<ActionHeader alignment="space-between">
					<ActionHeaderGroup>
						<ActionBackButton onBack={() => this.push('/master-data')}/>
						<ActionHeading>Schließzeiten</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<MiniMenu>
							<MenuItem primaryText={"Neue Tage anlegen"} secondaryText={"Ins"} leftIcon={<IconAddBox/>} onClick={this.showCreateView(true)}/>
							<ResourceLink/>
							<MenuItem primaryText={'Auswahl löschen'} secondaryText={"Entf"} leftIcon={<IconDelete/>} disabled={!selection.length}/>
						</MiniMenu>
						{/*<ActionHeaderLink to="/master-data/resources">Ressourceszuordnung</ActionHeaderLink>*/}
						{/*<ActionIconButton icon="plus" onAction={() => this.showCreateView(true)}/>*/}
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic loading={loading}>
					<Table multiSelectable onRowSelection={this.onSelectionChange}>
						<TableHeader displaySelectAll={true}>
							<TableRow>
								<TableHeaderColumn>Name</TableHeaderColumn>
								<TableHeaderColumn>Datum</TableHeaderColumn>
								<TableHeaderColumn style={{textAlign: 'right'}}>
									{
										selection.length > 0 && <FlatButton onClick={this.onMassDelete} secondary icon={
											<DeleteIcon/>}>Auswahl </FlatButton>
									}
								</TableHeaderColumn>
							</TableRow>
						</TableHeader>
						<TableBody deselectOnClickaway={false}>
							{freedays.map((freeday, row) =>
								<TableRow key={freeday.freedays_id} selected={selection.indexOf(row) !== -1}>
									<TableRowColumn>
										<strong>{freeday.name || `Freier Tag (${freeday.freedays_id})`}</strong>
									</TableRowColumn>
									<TableRowColumn>
										{moment(freeday.freedate).format(constants.DATE_FORMAT)}
									</TableRowColumn>
									<TableRowColumn style={{textAlign: 'right'}}>
										<IconButton disabled={selection.indexOf(row) !== -1}
										            onClick={this.onDirectDelete(row)} className={'freedays-delete-button'}><DeleteIcon/></IconButton>
									</TableRowColumn>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Segment>
				
				<Modal open={showCreateView} closeOnDimmerClick={false} centered={false} onClose={this.showCreateView(false)} size={'mini'}>
					<Modal.Header><Trans i18nKey={'actions.create'} defaults={'Erstellen'}/></Modal.Header>
					<Modal.Content>
						<Grid fluid={true}>
							<Row>
								<Col xs={12} sm={6}>
									<TextField
										defaultValue={dateName}
										onChange={this.setData('name')}
										hintText="Benennung (optional)"
										floatingLabelText="Benennung (optional)"/>
								</Col>
								<Col xs={12} sm={6}>
									<CancableDatepicker visible={startDate !== null} onCancel={this.setDate('start').bind(null, null, null)}>
										<DatePicker
											{...DATE_PICKER()}
											floatingLabelText="Startpunkt"
											hintText="Startpunkt"
											minDate={new Date()}
											value={startDate}
											onChange={this.setDate('start')}/>
									</CancableDatepicker>
									
									<CancableDatepicker visible={endDate !== null} onCancel={this.setDate('end').bind(null, null, null)}>
										<DatePicker
											{...DATE_PICKER()}
											floatingLabelText="Bis inlusive (optionial)"
											hintText="Bis inlusive (optionial)"
											disabled={null === startDate}
											value={endDate}
											onChange={this.setDate('end')}
											minDate={this.getMinDate()}/>
									</CancableDatepicker>
								</Col>
							</Row>
						</Grid>
						{trueNull(force) &&
							<Message warning>
								<p><Trans defaults={'Es gibt {count, plural, one{einen Konflikt} other{# Konflikte}}!'} count={force}/></p>
							</Message>
						}
					</Modal.Content>
					<Modal.Actions>
						<Button disabled={creating} onClick={this.showCreateView(false)}><Trans i18nKey={'actions.cancel'} defaults={'Abbrechen'}/></Button>
						<Button loading={creating} color={force ? 'brown' : 'green'} onClick={this.create}><Trans i18nKey={force > 0 ? null : 'actions.save'} defaults={force > 0 ? 'Forcieren' : 'Speichern'}/></Button>
					</Modal.Actions>
				</Modal>
			{/*	<Dialog
					title="Erstellen"
					open={showCreateView}
					onRequestClose={() => this.showCreateView(false)}
					actionsContainerStyle={DIALOG_ACTION_STYLE}
					actions={[
						<FlatButton onClick={() => this.showCreateView(false)}>Abbrechen</FlatButton>,
						<RaisedButton primary={true} disabled={!startDate} onClick={() => this.onSave()}>Speichern</RaisedButton>
					]}>
					<form>
						<Grid fluid={true}>
							<Row>
								<Col xs={12} sm={6}>
									<TextField
										defaultValue={dateName}
										onChange={(_, name) => this.setData({name})}
										hintText="Benennung (optional)"
										floatingLabelText="Benennung (optional)"/>
								</Col>
								<Col xs={12} sm={6}>
									<CancableDatepicker visible={startDate !== null} onCancel={() => this.setData({start: null, end: null})}>
										<DatePicker
											{...DATE_PICKER()}
											floatingLabelText="Startpunkt"
											hintText="Startpunkt"
											minDate={new Date()}
											value={startDate}
											onChange={(_, start) => this.setData({start})}/>
									</CancableDatepicker>
									
									<CancableDatepicker visible={endDate !== null} onCancel={() => this.setData({end: null})}>
										<DatePicker
											{...DATE_PICKER()}
											floatingLabelText="Bis inlusive (optionial)"
											hintText="Bis inlusive (optionial)"
											disabled={null === startDate}
											value={endDate}
											onChange={(_, end) => this.setData({end})}
											minDate={this.getMinDate()}/>
									</CancableDatepicker>
								</Col>
							</Row>
						</Grid>
					</form>
				</Dialog>*/}
				<ConfirmDialog open={this.state.confirm !== null} onCancel={() => this.setState({confirm: null})} onConfirm={() => this.onRemove(this.state.confirm.id)}
				               text={<div><strong style={{color: 'red'}}>{this.state.confirm ? this.state.confirm.title : 'Eintrag'}</strong> wird gelöscht...<br/>Vergessen Sie nicht Ressources für diesen Tag neu zuzuordnen.</div>}/>
				<SnackbarGroup onRequestClose={() => this.snackbar()} translationBase={'snackbar.md-freedays'} value={this.state.snackbar}/>
			</div>
		);
	}
}


export default withRouter(translate()(MasterDataClosed));