import * as React from 'react';
import {OrderAppointmentFinder} from "../intern/orders/finder/OrderAppointmentFinder";

export class OrderSearchTest extends React.Component {
	state = {
		value: 'off'
	};
	
	toggle = () => this.setState(state => ({
		value: state.value === 'off' ? 'on' : 'off'
	}));
	
	render() {
		return (
			
			<div style={{padding: 50}}>
					<OrderAppointmentFinder instantSearch={false} useSelection={false}/>
			</div>
		);
	}
}