import {connect} from "react-redux";
import MDPrice from "../../../../components/intern/master-data/payment/MDPrice";

const m2s = (state) => ({
	house: state.carhouse.own
});

const m2d = (dispatch) => {
	return {
	
	};
};

export default connect(m2s, m2d)(MDPrice);