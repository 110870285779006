import * as React from 'react';
import PropTypes from "prop-types";
import isFunc from 'lodash/isFunction';
import {StatyComponent} from "../Tools/ReactExtension";
import {subscribe} from "./PubSub";
import {SUB_SSE_STATUS} from "../actions";
import {dequal} from "dequal";
import {deepMemoize as memoizeOne} from "./extensions";
import {useEffect} from "react";

// export default (Component) =>
// 	class extends React.Component {
// 		componentDidMount() {
// 			if ( isFunc(this.props.init )) {
// 				this.props.init();
// 			}
// 		}
// 		render() {
// 			return(<Component {...this.props}/>);
// 		}
// 	};

export default (Component) => (props) => {
	useEffect(() => {
		isFunc(props.init) && props.init()
	}, [])
	return <Component {...props}/>
}

const _defaultConditionsProps = {access: null, update: null, dynamicIndex: null};
export const withCondition = (Component, props = _defaultConditionsProps) =>
	class extends React.Component {
		finalProps = {..._defaultConditionsProps, ...props}
		shouldComponentUpdate(nextProps, nextState, nextContext) {
			const {access, update, dynamicIndex} = this.finalProps;
			const useAccess = dynamicIndex ? Boolean(this.props[dynamicIndex]) : true;
			if (useAccess && access) {
				return !dequal(this.props[access], nextProps[access])
				// const hash_a = hasher(this.props[access] || {});
				// const hash_b = hasher(nextProps[access] || {});
				// return hash_a !== hash_b;
			}
			if (isFunc(update)) {
				return update(this.props, nextProps, nextState, nextContext);
			}
			return true;
		}
		
		render() {
			return (<Component {...this.props}/>);
		}
	};

const _defaultChangeProps = {access: null, dynamicIndex: null, init: true};
export const withChange = (Component, props = _defaultChangeProps) =>
	class extends React.Component {
		static propTypes = {
			withChange: PropTypes.func
		};
		finalProps = {..._defaultChangeProps, ...props}
		
		get dynamicIndex() {
			const dynamicIndex = this.finalProps.dynamicIndex
			return dynamicIndex && dynamicIndex in this.props ? Boolean(this.props[dynamicIndex]) : true
		}
		
		get index() {
			return this.finalProps.access
		}
		
		get accessIndex() {
			return this.dynamicIndex ? this.index : null
		}
		
		get indexValue() {
			return this.accessIndex ? this.props[this.accessIndex] : null
		}
		
		execute = memoizeOne((test) => {
			isFunc(this.props.withChange) && this.props.withChange(this.props)
		})
		
		componentDidMount() {
			const {init} = {..._defaultChangeProps, ...props};
			if (init) {
				this.execute(this.indexValue)
			}
		}
		
		componentDidUpdate(prevProps, prevState, snapshot) {
			this.execute(this.indexValue)
		}
		
		render() {
			const {withChange, ...props} = this.props;
			return <Component {...props}/>
		}
	};

export const withSSEStatus = (Component) =>
	class extends StatyComponent {
		state = {
			online: false
		}
		
		componentDidMount() {
			super.componentDidMount();
			this.listener = subscribe(SUB_SSE_STATUS, online => this.setState({online}));
		}
		
		componentWillUnmount() {
			super.componentWillUnmount();
			this.listener && this.listener();
		}
		
		render() {
			return (
				<Component {...this.state.props} sseOnline={this.state.online}/>
			);
		}
	}