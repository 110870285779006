import * as React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import BigCalendar from "react-big-calendar";
import Moment from "moment";
import {extendMoment} from "moment-range";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {scheduleAction__deleteByIds, scheduleAction__fetch} from "../../../actions/scheduleActions";
import {isFunction, values, get, keys, pick, pickBy} from "lodash";
import {blackOrWhite, SelectField, time2object} from "../../../Logic/extensions";
import {IconButton, MenuItem} from "material-ui";
import {Flex} from "../../../components/partials/ActionHeader";
import {SizeMe} from "react-sizeme";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import {addSnackbar} from "../../../actions/snackbarActions";
import {freedaysAction__fetch} from "../../../actions/freedaysActions";
import memoizeOne from 'memoize-one'
// import {deepMemoize as memoizeOne} from "../../../Logic/extensions";

const moment =  extendMoment(Moment);
BigCalendar.momentLocalizer(moment);

const SELECTED_BACKGROUND = '#222';
const SELECTED_OUTER_SHADOW = '#2185d0';
const SELECTED_INNER_SHADOW = 'red';

const deleteStyle = {
	position: 'absolute',
	right: 10,
	top: 10,
	background: SELECTED_BACKGROUND,
	color: SELECTED_OUTER_SHADOW,
	padding: 12,
	boxShadow: `0 0 8px 2px gray`,
	borderRadius: 8
};

const DeleteView = ({open = false, size = 32, onClick}) => {
	if ( !open) {
		return null;
	}
	size = Math.min(48, Math.abs(size));
	const padding = (48 - size) / 2;
	return (
		<Flex style={deleteStyle} direction={"column"} align={"center"} valign={"center"}>
			<IconButton onClick={onClick} className={'workers-schedule-delete'} style={{background: '#555', padding}} iconStyle={{width: size, height: size}}><DeleteIcon color={SELECTED_INNER_SHADOW}/></IconButton>
		</Flex>
	);
};

class Schedule extends React.Component {
	static propTypes = {
		workers_id: PropTypes.number.isRequired,
		resource_id: PropTypes.number,
		resources: PropTypes.object,
		view: PropTypes.string,
		deleteButton: PropTypes.element,
		buttonPortal: PropTypes.any
	};
	static defaultProps = {
		schedule: [],
		resources: {},
		freedays: [],
		deleteButton: <DeleteView/>
	};
	state = {
		day: -1,
		selectedEvent: {}
	};
	
	set = index => (e, key, value) => this.setState({[index]: value});
	onSelectEvent = ({schedule_id: id}) => this.setState(state => ({
		...state,
		selectedEvent: {
			...state.selectedEvent,
			[id]: !state.selectedEvent[id]
		}
	}));
	
	startAccessor = event => {
		if ( event._type === 'freedays4house') {
			return new Date(event.freedate);
		}
		return moment(time2object(event.start_time)).day(event.weekday + 1).toDate();
	};
	
	endAccessor = event => {
		if ( event._type === 'freedays4house') {
			return moment(event.freedate).endOf('day').toDate();
		}
		return moment(time2object(event.end_time)).day(event.weekday + 1).toDate();
	};
	
	isFreeDay = (event) => {
		const {freedays} = this.props;
		if ( event._type === 'freedays4house') {
			return false;
		}
		const eventDate = moment(time2object(event.start_time)).day(event.weekday + 1);
		for (const freeday of freedays) {
			if ( eventDate.isSame(freeday.freedate, 'day')) {
				return true;
			}
		}
		return false;
	};
	
	eventGetter = (selectedEvent, resources) => (event) => {
		const isFreedate = event._type === 'freedays4house';
		const isSelected = !isFreedate && selectedEvent[event.schedule_id];
		const isFreeday = this.isFreeDay(event);
		let color = isSelected ? SELECTED_BACKGROUND : get(resources[event.resource_id], 'color', 'darkgray');
		const selectedStyle = !isSelected ? {} : {
			boxShadow: `0 0 8px 1px ${SELECTED_OUTER_SHADOW}, 0 0 18px 3px ${SELECTED_INNER_SHADOW} inset`,
			padding: 12
		};
		// color = blackOrWhite(color);
		// color = isFreeday ? 'black' : color;
		return ({
			style: {
				color: blackOrWhite(color),
				backgroundColor: color,
				opacity: isFreeday ? (isSelected ? 0.7 : 0.3) : 1,
				pointerEvents: isFreedate ? 'none' : 'all',
				borderRadius: 0,
				border: 0,
				...selectedStyle
			}
		});
	};
	
	portalButton = memoizeOne(
		(deleteButton, buttonPortal, deleteIds) => {
			const DeleteButton = React.cloneElement(deleteButton, {open: deleteIds.length > 0, onClick: this.props.onDelete.bind(null, deleteIds, this.onClearSelections)})
			return buttonPortal ? ReactDOM.createPortal(DeleteButton, buttonPortal) : DeleteButton;
		}
	);
	
	onClearSelections = () => this.setState({selectedEvent: {}});
	
	render() {
		const {
			t, i18n, tReady,
			workers_id, resource_id,
			schedule, resources,
			onDelete, freedays,
			deleteButton,
			buttonPortal,
			...props
		} = this.props;
		const {day: selectedDay, selectedEvent} = this.state;
		
		const day = selectedDay >= 0 ? selectedDay : moment().day() - 1;
		
		// Handle language view
		const defs = i18n.getResource(i18n.language, 'translations', 'big-calendar.defs') || {};
		defs.showMore = (count) => t('big-calendar.showMore', {count});
		const formats = {
			dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture)
		};
		const resourceList = values(resources).filter(r => !resource_id || r.resource_id === resource_id);
		const deleteIds = keys(pickBy(selectedEvent, ev => ev));
		// const DeleteButton = React.cloneElement(deleteButton, {open: deleteIds.length > 0, onClick: onDelete.bind(null, deleteIds, this.onClearSelections)});
		const DeleteButton = this.portalButton(deleteButton, buttonPortal, deleteIds);
		return (
			<div style={{width: '100%', position: 'relative'}}>
				<SizeMe>
					{({size}) => (
						<div>
							{size.width < 1000 &&
								<Flex valign={"center"} align={"flex-end"}>
									<SelectField
										vmargin={10}
										floatingLabelText={"Wochentag"}
										onChange={this.set('day')}
										value={day}
									>
										{[0,1,2,3,4,5,6].map(iday =>
											<MenuItem key={`weekday-${iday}`} value={iday} primaryText={t(`weekdays.${iday}`)} />
										)}
									</SelectField>
								</Flex>
							}
							<BigCalendar
								defaultDate={new Date()}
								date={size.width < 1000 ? moment().day(day + 1).toDate() : null}
								defaultView={"week"}
								view={size.width < 1000 ? 'day' : 'week'}
								step={60}
								onView={() => {}}
								onNavigate={() => {}}
								views={["week", "day"]}
								culture={"de"}
								messages={defs}
								toolbar={false}
								formats={formats}
								resources={resourceList}
								resourceIdAccessor={'resource_id'}
								resourceTitleAccessor={'name'}
								events={[...schedule, ...freedays]}
								onSelectEvent={this.onSelectEvent}
								startAccessor={this.startAccessor}
								endAccessor={this.endAccessor}
								titleAccessor={event => get(resources[event.resource_id], 'name', get(event, 'title', '[Event]'))}
								resourceAccessor={'resource_id'}
								eventPropGetter={this.eventGetter(selectedEvent, resources)}
								{...props}
							/>
						</div>
					)}
				</SizeMe>
				{/*<DeleteView open={deleteIds.length > 0} onClick={onDelete.bind(null, deleteIds, this.onClearSelections)}/>*/}
				{DeleteButton}
			</div>
		);
	}
}

Schedule = withInit(Schedule);

Schedule = connect(
	(state, props) => {
		// worker id
		const workers_id = Number(props.workers_id || 0);
		// chosen resource id // may be empty
		// const resource_id = props.resource_id || null;
		// IDs of resources available to given worker ('s ID)
		const workersResources = keys(state.resources.workers[workers_id] || {});
		// filter all resources to keep only workers
		let resources = pick(props.resources || state.resources.list, workersResources);
		// get schedule of worker...
		let schedule = state.workers.schedule[workers_id] || {};
		const freedays = state.freedays.list;
		// filter to given resource ...
		schedule = values(schedule);//.filter(s => null === resource_id ? true : s.resource_id === resource_id);
		return {
			schedule,
			resources,
			freedays
		};
	},
	(dispatch, props) => {
		const workers_id = Number(props.workers_id || 0);
		return {
			init: () => {
				dispatch(isOnline(() => {
					dispatch(scheduleAction__fetch(workers_id));
					dispatch(freedaysAction__fetch())
				}))
			},
			onDelete: (idArray, onSuccess) => dispatch(scheduleAction__deleteByIds(workers_id, idArray, result => {
				isFunction(onSuccess) && onSuccess(result);
				dispatch(addSnackbar('Einträge entfernt'));
			}))
		};
	}
)(Schedule);

Schedule = translate()(Schedule);

export default Schedule;