import {CARHOUSE_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {addError} from "./errorActions";
// import {DELETE, GET, POST, UPLOAD} from "ith-fetch";
import {DELETE, GET, POST, UPLOAD} from "../Logic/fetch";
import {isCached} from "ith-redux-helpers";
import {CACHE_COUNTRIES, CACHE_TIME_COUNTRIES, CACHE_TIME_TIMEZONES, CACHE_TIMEZONES, PROC_COUNTRIES_FETCH, PROC_DUMP_DB, PROC_HOUSE, PROC_HOUSE_DELETE_LOGO, PROC_HOUSE_UPLOAD_LOGO, PROC_TIMEZONE_UPDATE, PROC_TIMEZONES} from "./index";
import isFunc from 'lodash/isFunction';
import {localStorageAction__setTimeszones} from "./localStorageActions";
import {sessionStorageAction__setCountries} from "./sessionStorageActions";

const _setTimezones = timezones => ({type: CARHOUSE_ACTION.LOAD_TIMEZONES, timezones});
export const houseState_setTimezones = timezones => _setTimezones(timezones);
export const houseState__setOne = carhouse => ({type: CARHOUSE_ACTION.LOAD_HOUSE, carhouse});
export const houseState__setList = (houses) => ({type: CARHOUSE_ACTION.LOAD_LIST, houses});
export const houseState__removeLogo = house_id => ({type: CARHOUSE_ACTION.REMOVE_LOGO, house_id});
export const houseState__setOwn = (carhouse=null) => ({type: CARHOUSE_ACTION.OWN, carhouse});
export const houseState__setCounries = (countries, mode = null) => ({type: CARHOUSE_ACTION.SET_COUNTRIES, countries, mode});
export const houseState__customerOne = customerone => ({type: CARHOUSE_ACTION.CUSTOMERONE, customerone});

export const houseCall__getCountries = (indexed = false) => indexed ? GET('/house/countries/indexed') : GET('/house/countries');
export const houseCall__getEvosecInterface = () => GET('/house/interface/evosec');
/**
 *
 * @param {Object} options
 * @param {Boolean} options.active
 * @param {Boolean} options.ssl
 * @param {string} options.host
 * @param {string} options.port
 * @param {string} options.virtual_host
 * @param {string} options.username
 * @param {string} options.password
 * @param {string} options.queue
 * @param {string} options.exchange
 * @param {string} options.routing_key
 * @return {Promise | Promise<unknown>}
 */
export const houseCall__postEvosecInterface = (options) => POST('/house/interface/evosec', options);


export const loadCarhouses = () =>
	async dispatch => {
		try {
			// dispatch(addLoader('carhouse::list'));
			dispatch(setLoader(PROC_HOUSE));
			const houses = await GET('/house/all');
			dispatch(houseState__setList(houses));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_HOUSE));
			// dispatch(removeLoader('carhouse::list'));
		}
	};

export const loadTimezones = () =>
	async (dispatch, getState) => {
		if ( isCached(getState().carhouse.cache, CACHE_TIMEZONES)(CACHE_TIME_TIMEZONES)) {
			return;
		}
		if (getState().local.timezones) {
			dispatch(_setTimezones(getState().local.timezones));
			return;
		}
		try {
			dispatch(setLoader(PROC_TIMEZONES));
			// dispatch(addLoader('carhouse::timezone'));
			const zones = await GET('/house/timezones');
			dispatch(_setTimezones(zones));
			dispatch(localStorageAction__setTimeszones(zones));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_TIMEZONES));
			// dispatch(removeLoader('carhouse::timezone'));
		}
	};

export const loadOwnCarhouse = () =>
	async dispatch => {
		try {
			// dispatch(addLoader('carhouse::own'));
			dispatch(setLoader(PROC_HOUSE));
			const house = await GET('/house/own');
			dispatch(houseState__setOne(house));
			console.warn('DISPATCHING OWN FROM loadOwnCarhouse')
			dispatch(houseState__setOwn(house));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			// dispatch(removeLoader('carhouse::own'));
			dispatch(unsetLoader(PROC_HOUSE));
		}
	};

export const updateTimezone = (timezone, onSuccess = null) =>
	async dispatch => {
		try {
			// dispatch(addLoader('carhouse::set_timeout'));
			dispatch(setLoader(PROC_TIMEZONE_UPDATE));
			const house = await POST('/house/timezone', {timezone});
			dispatch(houseState__setOne(house));
			onSuccess && onSuccess(house);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_TIMEZONE_UPDATE));
			// dispatch(removeLoader('carhouse::set_timeout'));
		}
	};
export const updateMasterDataOfHouse = (house, data, part=null, onSuccess) =>
	async (dispatch, getState) => {
		const house_id = house.house_id || house;
		const own = getState().carhouse.own;
		try {
			dispatch(setLoader(PROC_HOUSE, house_id, part));
			const result = await POST('/house', {house_id, ...data});
			dispatch(houseState__setOne(result.house));
			if ( own.house_id === house_id ) {
				dispatch(houseState__setOwn(result.house));
			}
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_HOUSE, house_id, part));
		}
	};
export const uploadLogoForHouse = (image, onSuccess) =>
	async dispatch => {
		// const house_id = house.house_id || house;
		const data = new FormData();
		data.append('file', image);
		try {
			dispatch(setLoader(PROC_HOUSE_UPLOAD_LOGO));
			// const result = await UPLOAD('/house/logo/' + house_id, data);
			const result = await UPLOAD('/image/house-logo', data);
			dispatch(houseState__setOne(result.house));
			dispatch(houseState__setOwn(result.house));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_HOUSE_UPLOAD_LOGO));
		}
	};
export const removeLogoOfHouse = (onSuccess) =>
	async dispatch => {
		// const house_id = house.house_id || house;
		try {
			dispatch(setLoader(PROC_HOUSE_DELETE_LOGO));
			// const result = await DELETE('/house/logo/' + house_id);
			const result = await DELETE('/image/house-logo');
			dispatch(houseState__removeLogo(result.house_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_HOUSE_DELETE_LOGO));
		}
	};

export const houseAction__fetchCountries = (onSuccess) =>
	async (dispatch, getState) => {
		if ( isCached(getState.carhouse.cache, CACHE_COUNTRIES)(CACHE_TIME_COUNTRIES) ) {
			return;
		}
		try {
			dispatch(setLoader(PROC_COUNTRIES_FETCH));
			const countries = await GET('/house/countries/as-object');
			dispatch(houseState__setCounries(countries));
			isFunc(onSuccess) && onSuccess(countries);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_COUNTRIES_FETCH));
		}
	};
export const houseAction__dumpDb = onSuccess =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_DUMP_DB));
			const response = await GET('/admin/dump/sql');
			isFunc(onSuccess) && onSuccess(response);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_DUMP_DB));
		}
	};
export const houseAction__getCountries = (force, onSuccess) =>
	async (dispatch, getState) => {
		try {
			if (!force && getState().session.countries) {
				return getState().session.countries;
			}
			const list = await houseCall__getCountries(true);
			dispatch(sessionStorageAction__setCountries(list));
			return list;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const houseCall_getEwoTw = () => GET('/house/ewo-tw')
export const houseCall_postEwoTw = (data) => POST('/house/ewo-tw', data)
//
export const houseCall_getEwoTw_order = (orderId) => GET(`/house/ewo-tw/order/${orderId}`)
export const houseCall_getEwoTw_orderRemoval = (orderId) => GET(`/house/ewo-tw/removal-order/${orderId}`)
export const houseCall_getEwoTw_tireset = (number) => GET(`/house/ewo-tw/tireset/${number}`)
export const houseCall_getEwoTw_tiresetRemoval = (number) => GET(`/house/ewo-tw/tireset-removal/${number}`)
export const houseCall_getEwoTw_tiresetRack = (number) => GET(`/house/ewo-tw/tireset-rack/${number}`)