import * as React from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {Icon} from "semantic-ui-react";
import {Flex} from "../../../components/partials/ActionHeader";
import {List, ListItem} from "material-ui";
import {Vehicle} from "../../../models";

class AppointmentVehicle extends React.PureComponent {
	static propTypes = {
		vehicle_id: PropTypes.number.isRequired
	};
	static defaultProps = {
		vehicle_id: 0
	};
	render() {
		const {vehicle_id} = this.props;
		return(
			<Vehicle.Connected id={vehicle_id} placeholder={<Icon name={'circle notch'} loading/>}>{ vehicle =>
				<div>
					<h4 style={{marginLeft: 15}}>Fahrzeugdaten</h4>
					<Flex wrap>
						<List>
							<ListItem
								disabled
								primaryText={vehicle.vehicle_id}
								secondaryText={"Fahrzeug ID"}
							/>
							<ListItem
								disabled
								primaryText={vehicle.name || "n.v."}
								secondaryText={"Bezeichnung"}
							/>
						</List>
						<List>
							<ListItem
								disabled
								primaryText={`${vehicle.hsn} / ${vehicle.tsn}`}
								secondaryText={"HSN/TSN"}
							/>
							<ListItem
								disabled
								primaryText={vehicle.km || "n.v."}
								secondaryText={"Kilometer"}
							/>
						</List>
						<List>
							<ListItem
								disabled
								primaryText={vehicle.chassis_number || "n.v."}
								secondaryText={"Fahrgestellnummer"}
							/>
							<ListItem
								disabled
								primaryText={vehicle.registration_mark || "n.v."}
								secondaryText={"Kennzeichen"}
							/>
						</List>
						<List>
							<ListItem
								disabled
								primaryText={vehicle.model_year || 'n.v.'}
								secondaryText={"Modeljahr"}
							/>
							<ListItem
								disabled
								primaryText={vehicle.loan_car ? "Ja" : "Nein"}
								secondaryText={"Leihfahrzeug?"}
							/>
						</List>
					</Flex>
				</div>
			}</Vehicle.Connected>
			
		);
	}
}

AppointmentVehicle = translate()(AppointmentVehicle);
export default AppointmentVehicle;