import React from 'react';
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';

const DevToolsHandler = createDevTools(
	<DockMonitor toggleVisibilityKey='ctrl-h'
	             changePositionKey='ctrl-q'
	             changeMonitorKey='ctrl-m'
	             defaultIsVisible={false}
	             onClick={e => e.stopPropagation()}
	>
		<LogMonitor className={'fufu'} onClick={e => console.error('FUFUF ALL')} />
		{/*<ChartMonitor />*/}
	</DockMonitor>
);

export default DevToolsHandler;