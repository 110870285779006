import {isLoading} from "../../actions/loaderActions";
import {PROC_USER_LOGIN} from "../../actions";
import EmailLoginView from "../../components/extern/EmailLoginView";
import {connect} from "react-redux";
import {validateEmailLogin} from "../../actions/userActions";

const m2s = state => ({
	isLoading: isLoading(state, PROC_USER_LOGIN),
	failed: state.user.email_login_failed
});

const m2d = (dispatch, props) => {
	dispatch(validateEmailLogin(props.match.params.hash));
};

export default connect(m2s, m2d)(EmailLoginView);