import VehicleEditView from "../../../components/intern/vehicles/VehicleEditView";
import {connect} from "react-redux";
import User from "../../../models/User";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {clientAction__fetchOne} from "../../../actions/clientActions";
import {push} from "connected-react-router";
import {SECTION} from "../../../Logic/constants";
import {kfzAction__loadKfzByClient} from "../../../actions/kfzActions";
import values from 'lodash/values';
import {vehicleAction__create} from "../../../actions/vehicleActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import PropTypes from "prop-types";
import {get, isFunction as isFunc} from "lodash";


const m2s = (state, props) => {
	const client_id = Number(get(props, "match.params.client_id", props.client_id));
	const client = state.clients.list[client_id];
	const user =  User._(state.user.data);
	return {
		client,
		...user.getAccessTo(SECTION.CLIENTS),
		kfz_list: values(state.kfz.cars)
	};
};
const m2d = (dispatch, props) => {
	const client_id = Number(get(props, "match.params.client_id", props.client_id));
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(clientAction__fetchOne(client_id));
			dispatch(kfzAction__loadKfzByClient(client_id));
			// dispatch(kfzAction__loadModelsByClient(client_id));
		})),
		onSave: (data, onSuccess) => dispatch(vehicleAction__create(client_id, data, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.vehicle.created'));
			// dispatch(push(`/clients/${client_id}/vehicles`));
			// console.debug('Result before pushing to client site', result);
			// props.onAfterSave(dispatch, result);
		})),
		onAfterSave: props.onAfterSave || ((result) => dispatch(push(`/clients/${result.client_id}/vehicles`))),
		onBack: props.onBack !== undefined ? props.onBack : (() => dispatch(push(`/clients/${client_id}/vehicles`))),
		onAfterTransfer: props.onAfterTransfer || ((result) => dispatch(push(`/clients/${result.client.client_id}/vehicles/${result.vehicle.vehicle_id}`)))
	};
};

const VehicleCreateController = connect(m2s, m2d)(withInit(VehicleEditView));
VehicleCreateController.propTypes = {
	onAfterSave: PropTypes.func,
	client_id: PropTypes.number,
	onBack: PropTypes.func
};

export default VehicleCreateController;