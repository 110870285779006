import * as React from "react";
import PropTypes from "prop-types";
import {Message, Popup, Segment, Statistic} from "semantic-ui-react";
import {ActionHeader, ActionHeaderGroup, ActionHeading, Flex, OptionHeader} from "../../../partials/ActionHeader";
import {MiniMenu} from "../../../../Logic/MiniMenu";
import {Divider, IconButton, MenuItem} from "material-ui";
import {IconAdd, IconAssignment, IconBack, IconBuild, IconRefresh, IconVisibility} from "../../../../Logic/icons";
import {withRouter} from "react-router";
import {concat, get, partition} from "lodash";
import KeyHandler from "react-key-handler";
import moment from "../../../../Logic/Moment";
import Countdown from "../../../partials/Countdown";
import {PROC_ORDER_DAY, SUB_ORDER_DELETE, SUB_PAUSE_APPOINTMENT, SUB_RESUME_APPOINTMENT} from "../../../../actions";
import {subscribe, unsubscribe} from "../../../../Logic/PubSub";
import OrderQuickView from "../../../../cointainer/intern/orders/OrderQuickView";
import {OrderViewDialog} from "../../../../Tools/Dialog/Dialogs";
import {deepMemoize as memoize, trueNull} from "../../../../Logic/extensions";
import AppointmentPaper from "../../../../cointainer/intern/appointments/AppointmentPaper";
import {PausedAppointments} from "../../../../cointainer/intern/appointments";
import {NativeDayPicker as DayPicker} from "../../../partials/DayPicker";
import {Order} from "../../../../models";
import {withLoading} from "../../../../actions/loaderActions";
import {ConnectedLoaderIcon} from "../../../Loaders";
import {connect} from "react-redux";

let StatusView = ({order_services, order_resources, list, work_schedule, renderCounter}) => {
	const duration = () => {
		const schedule = work_schedule;
		const day = moment().format("YYYY-MM-DD");
		return get(schedule, [day, "duration"], 0);
	};
	
	const orderServices = memoize(
		(list, services) => {
			let ids = {};
			let map = {};
			let omap = {};
			for(const order of list) {
				omap[order.order_id] = order;
			}
			// noinspection DuplicatedCode
			for (const [sid, service] of Object.entries(services)) {
				if (service.order_id in omap) {
					map[sid] = service;
					if (service.order_id in ids) {
						ids[service.order_id].push(Number(sid));
					} else {
						ids[service.order_id] = [Number(sid)];
					}
				}
			}
			return [ids, map, omap];
		}
	);
	
	const orderResources = memoize(
		(services, resources) => {
			let ids = {};
			let map = {};
			// noinspection DuplicatedCode
			for (const [rid, resource] of Object.entries(resources)) {
				if (resource.order_service_id in services) {
					map[rid] = resource;
					if (resource.order_service_id in ids) {
						ids[resource.order_service_id].push(Number(rid));
					} else {
						ids[resource.order_service_id] = [Number(rid)];
					}
				}
			}
			return [ids, map];
		}
	);
	
	const workDuration = memoize(
		(resources) => {
			let duration = 0;
			for (const [, resource] of Object.entries(resources)){
				if (resource.finished_at) {
					continue;
				}
				const start = moment(resource.start_point);
				const end = moment(resource.end_point);
				duration += end.diff(start, 'seconds');
			}
			return duration;
		}
	);
	
	const [, services] = orderServices(list, order_services);
	const [, resources] = orderResources(services, order_resources);
	const remainingDuration = duration();
	const workloadDuration = workDuration(resources);
	const diff = remainingDuration - workloadDuration;
	const targetDate = moment().add(diff, "seconds").toDate();
	return(
		<Message positive={remainingDuration >= workloadDuration} negative={remainingDuration < workloadDuration}><Countdown render={renderCounter} liveChange targetDate={targetDate}/></Message>
	);
};
StatusView.propTypes = {
	// work: PropTypes.object.isRequired,
	work_schedule: PropTypes.object.isRequired,
	renderCounter: PropTypes.func,
	list: PropTypes.array
};
StatusView.defaultProps = {
	list: []
};

StatusView = connect(
	(state, {list}) => ({
		list: list || state.appointments.list,
		orders: state.map.orders,
		order_services: state.map.order2services,
		order_resources: state.map.order2service2resources
	})
)(StatusView);

const WorkStats = ({showUndelivered, workCount, undeliveredCount, totalCount, visibleCount = 0, showVisibleIcon}) => {
	if (undeliveredCount) {
		return (
			<Statistic.Group size={"mini"} style={{margin: 0, marginBottom: 7, cursor: 'default'}}>
				<Statistic size={"mini"} style={{margin: 0, marginRight: showUndelivered ? 0 : 5}}>
					<Popup content={<span>Verfügbare Arbeiten<br/><small style={{display: 'block', textAlign: 'right'}}>{visibleCount} {showUndelivered ? `+${undeliveredCount} ` : ''}werden angezeigt</small></span>} trigger={<Statistic.Value><IconBuild style={{transform: 'scale(0.75)', transformOrigin: 'right bottom'}}/> {workCount}</Statistic.Value>}/>
				</Statistic>
				{showUndelivered &&
					<Statistic size={"mini"} style={{margin: 0, marginRight: 8}}>
						<Popup content={<span>Erwartete Arbeiten <small style={{display: 'block', textAlign: 'right'}}>noch nicht da</small></span>} trigger={<Statistic.Value><IconAdd style={{transform: 'scale(0.75)', transformOrigin: 'right bottom', marginRight: -8}}/> {undeliveredCount}</Statistic.Value>}/>
					</Statistic>
				}
				<Statistic size={"mini"} style={{margin: 0, marginRight: 8}}>
					<Popup content={<span>Alle Arbeiten <small style={{display: 'block', textAlign: 'right'}}>für den gewählten Tag</small></span>} trigger={<Statistic.Value><IconAssignment style={{transform: 'scale(0.75)', transformOrigin: 'right bottom'}}/> {totalCount}</Statistic.Value>}/>
				</Statistic>
				{showVisibleIcon &&
					<Statistic size={"mini"} style={{margin: 0}}>
						<Popup content={"Angezeigte Arbeiten"} trigger={<Statistic.Value><IconVisibility style={{transform: 'scale(0.75)', transformOrigin: 'right bottom', marginRight: -4}}/> {visibleCount}</Statistic.Value>}/>
					</Statistic>
				}
			</Statistic.Group>
		);
	}
	return (
		<Statistic.Group size={"mini"} style={{margin: 0, marginBottom: 7, cursor: 'default'}}>
			<Statistic size={"mini"} style={{margin: 0, marginRight: 8}}>
				<Popup content={<span>Verfügbare Arbeiten<br/><small style={{display: 'block', textAlign: 'right'}}>{visibleCount} werden angezeigt</small></span>} trigger={<Statistic.Value><IconBuild style={{transform: 'scale(0.75)', transformOrigin: 'right bottom'}}/> {workCount}</Statistic.Value>}/>
			</Statistic>
			{showVisibleIcon &&
				<Statistic size={"mini"} style={{margin: 0}}>
					<Popup content={"Angezeigte Arbeiten"} trigger={<Statistic.Value><IconVisibility style={{transform: 'scale(0.75)', transformOrigin: 'right bottom', marginRight: -4}}/> {visibleCount}</Statistic.Value>}/>
				</Statistic>
			}
		</Statistic.Group>
	);
};

let LoaderIcon = withLoading('loading', PROC_ORDER_DAY)(ConnectedLoaderIcon);

class Appmnts extends React.Component {
	static propTypes = {
		loading: PropTypes.bool,
		work: PropTypes.shape({
			work_map: PropTypes.object.isRequired,
			list: PropTypes.array.isRequired,
			// orders: PropTypes.object.isRequired,
			// services: PropTypes.object.isRequired,
			// resources: PropTypes.object.isRequired,
			// order2services: PropTypes.object.isRequired,
			// service2resources: PropTypes.object.isRequired,
		}).isRequired,
		vehicles: PropTypes.object,
		work_schedule: PropTypes.object,
		showHeaders: PropTypes.bool,
		showPaused: PropTypes.bool,
		showStatus: PropTypes.bool,
		showLoader: PropTypes.bool,
		accessable: PropTypes.bool,
		currentOrderWorkId: PropTypes.number,
		resumePaused: PropTypes.func,
		numberInList: PropTypes.number,
		onRefresh: PropTypes.func,
		workerSchedule: PropTypes.arrayOf(PropTypes.shape({start: PropTypes.any.isRequired, end: PropTypes.any.isRequired})),
		workerScheduleRequired: PropTypes.bool
	};
	static defaultProps = {
		loading: false,
		vehicles: {},
		showLoader: true,
		showHeaders: true,
		showPaused: true,
		showStatus: false,
		accessable: true,
		numberInList: 1,
		workerScheduleRequired: true
	};
	
	state = {
		showAll: true,
		showUndelivered: false,
		date: new Date(),
		pauseToggle: 0,
		orderDetailId: 0
	};
	
	setOrderView = (orderDetailId) => () => this.setState({orderDetailId});
	
	sub = null;
	resume = null;
	
	componentDidMount() {
		this.sub = subscribe(SUB_PAUSE_APPOINTMENT, this.fetch);
		this.resume = subscribe(SUB_RESUME_APPOINTMENT, this.fetch);
		this.orderDeleteChannel = subscribe(SUB_ORDER_DELETE, this.fetch);
		// console.warn('workerSchedule [MOUNT]: ', this.props.workerSchedule);
		this.processWorkerSchedule(this.props.workerSchedule);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.warn('workerSchedule [UPDATE]: ', this.props.workerSchedule);
		this.processWorkerSchedule(this.props.workerSchedule);
	}
	
	componentWillUnmount() {
		unsubscribe(SUB_PAUSE_APPOINTMENT, this.sub);
		unsubscribe(SUB_RESUME_APPOINTMENT, this.resume);
		unsubscribe(SUB_ORDER_DELETE, this.orderDeleteChannel);
	}
	
	togglePause = index => this.setState(state => ({
		pauseToggle: state.pauseToggle === index ? -1 : index
	}));
	
	toggle = (name) => () => this.setState(state => ({
		...state,
		[name]: !state[name]
	}));
	
	filter = () => {
		const {showUndelivered} = this.props;
		return ({order_id}) => {
			const order = get(this.props, ["work", "orders", order_id], null);
			if (order) {
				const {delivered_at, released_at} = order;
				return (showUndelivered || !!delivered_at) && (!!released_at);
			}
			return false;
		};
	};
	
	processWorkerSchedule = memoize(
		(schedule) => {
			if (schedule && schedule.some(Boolean)) {
				const now = moment(this.state.date);
				const weekday = now.day() - 1;
				let nextOne = this.findNextDay(schedule, weekday);
				if (null !== nextOne) {
					nextOne = moment(now).add(nextOne, 'day');
					// let end;
					if (schedule[weekday] && (moment(schedule[weekday].end) < now || !schedule[weekday])) {
						this.setState({date: nextOne.toDate()}, () => this.fetch());
					}
				}
			}
		}
	);
	
	findNextDay = (schedule, current) => {
		for (let i = 1; i < schedule.length + 1; ++i) {
			let pointer = (current + i) % schedule.length;
			if (schedule[pointer]) {
				return i;
			}
		}
		return null;
	}
	
	filterDelivered = ({delivered_at}) => {
		return !!delivered_at;
		// const order = get(this.props, ["work", "orders", order_id], null);
		// if (order) {
		// 	return  !!order.delivered_at;
		// }
		// return false;
	};
	
	count = list => {
		let c1 = 0, c2 = 0;
		for (const o of list) {
			const order = get(this.props, ["work", "orders", o.order_id], null);
			if ( order ) {
				c1 += !order.delivered_at && !!order.released_at ? 1 : 0;
				c2 += this.isFinished(order.order_id, this.props.work) ? 1 : 0;
			}
		}
		return [c1, c2];
	};
	
	fetch = () => {
		this.props.onRefresh(this.state.date.toISOString());
	};
	
	handleDate = (inc) => () => {
		let date;
		if ( !this.state.date ) {
			date = new Date();
		} else {
			date = moment(this.state.date).add(inc, 'day').toDate();
		}
		this.setState({date}, this.fetch);
	};
	
	isFinished = (order_id, {list, order2services, service2resources, resources}) => {
		let finished = [];
		for(const item of list) {
			if (item.order_id === order_id) {
				for (const sid of order2services[order_id]) {
					for (const rid of service2resources[sid]) {
						const resource = resources[rid];
						if ( resource.order_service_resource_id === item.order_service_resource_id) {
							finished.push(Boolean(item.finished_at));
						}
					}
				}
				
			}
		}
		return finished.reduce((carry, value) => carry && value, true);
	};
	
	renderCounter = ([negative, , hours, minutes]) => {
		if ( negative ) {
			return `${hours} Stunden und ${minutes} Minuten in Verzug!`;
		}
		return `Ein Puffer von ${hours} Stunden und ${minutes} Minuten`;
	};
	
	sortView = (a, b) => {
		let val = Number(!!b.delivered_at) - Number(!!a.delivered_at);
		if ( !val) {
			val = moment(a.latest_point) - moment(b.latest_point);
		}
		return val;
	};
	
	render() {
		const {
			history: {push},
			loading,
			onRefresh,
			work_schedule,
			work,
			work: {
				list: initList,
				orders,
				work_map
			},
			showHeaders, showPaused, showStatus,
			accessable,
			currentOrderWorkId,
			showUndelivered,
			toggleShowDeliver,
			numberInList,
			loaded
		} = this.props;
		
		const {
			// showAll,
			// showFinished,
			date,
			orderDetailId
		} = this.state;
		
		
		if (!loaded) {
			if (showHeaders) {
				return (
					<Segment basic>
						<ActionHeader alignment={'space-between'} wrap={"wrap-reverse"}>
								<ActionHeading>Lade Aufträge </ActionHeading>
						</ActionHeader>
					</Segment>
				);
			}
			return null;
		}
		
		// console.debug('LOADING LEVEL 1');
		const ids = initList.map(o => o.order_id);
		return (
			<Order.CollectProvider ids={ids} full placeholder={
				<Segment basic>
					<KeyHandler keyValue={'o'} onKeyHandle={toggleShowDeliver} />
					{onRefresh && <KeyHandler keyValue={'r'} onKeyHandle={this.fetch} />}
					
					<ActionHeader alignment={'space-between'} wrap={"wrap-reverse"}>
						<ActionHeaderGroup>
							<ActionHeading>Keine aktiven Aufträge</ActionHeading>
						</ActionHeaderGroup>
						<ActionHeaderGroup>
							<OptionHeader underWidth={800}>
								<Flex align={"center"} valign={"flex-end"}>
									<DayPicker
										disabled={loading}
										day={date}
										onChange={date => this.setState({date}, this.fetch)}
									/>
								</Flex>
							</OptionHeader>
						</ActionHeaderGroup>
						<ActionHeaderGroup>
							<IconButton onClick={this.fetch}><IconRefresh/></IconButton>
							<MiniMenu>
								<MenuItem
									primaryText={'Zurück zur Übersicht'}
									leftIcon={<IconBack/>}
									onClick={() => push('/')}
								/>
							</MiniMenu>
						</ActionHeaderGroup>
					</ActionHeader>
					{trueNull(!ids.length && showPaused) && <PausedAppointments/>}
				</Segment>
			}>{() => {
				// const list = fuseOrders(initList);
				// console.debug('LOADING LEVEL 2222');
				const mappedList = initList.map(o => ({...orders[o.order_id], ...o }));
				let [readyList, notDeliveredList] = partition(mappedList, this.filterDelivered);
				const undeliveredCount = notDeliveredList.length;
				const workCount = readyList.length;
				readyList = readyList.filter(o => o.order_id !== currentOrderWorkId).slice(0, numberInList);
				// noinspection JSUnresolvedVariable
				notDeliveredList = notDeliveredList.filter(o => o.order_id !== currentOrderWorkId);
				let viewList = (showUndelivered ? concat(readyList, notDeliveredList) : readyList);
				viewList.sort(this.sortView);
				return <Segment basic>
					<KeyHandler keyValue={'o'} onKeyHandle={toggleShowDeliver} />
					{onRefresh && <KeyHandler keyValue={'r'} onKeyHandle={this.fetch} />}
					{trueNull(showHeaders) &&
						<ActionHeader alignment={'space-between'} wrap={"wrap-reverse"}>
							<ActionHeaderGroup valign={'center'}>
								<LoaderIcon name={'spinner'}/> <ActionHeading>Deine Aufträge &nbsp;<WorkStats visibleCount={numberInList} showVisibleIcon={false} showUndelivered={showUndelivered} undeliveredCount={undeliveredCount} workCount={workCount} totalCount={mappedList.length}/></ActionHeading>
							</ActionHeaderGroup>
							<ActionHeaderGroup>
								<OptionHeader underWidth={800}>
									<Flex align={"center"} valign={"baseline"}>
										<DayPicker
											disabled={loading}
											day={date}
											onChange={date => this.setState({date}, this.fetch)}
										/>
									</Flex>
								</OptionHeader>
							</ActionHeaderGroup>
							<ActionHeaderGroup>
								<IconButton onClick={this.fetch}><IconRefresh/></IconButton>
								<MiniMenu>
									<MenuItem
										primaryText={'Zurück zur Übersicht'}
										leftIcon={<IconBack/>}
										onClick={() => push('/')}
									/>
									<Divider/>
									<MenuItem
										checked={showUndelivered}
										primaryText={`Inkl. nicht vor Ort (${undeliveredCount})`}
										secondaryText={'O'}
										insetChildren
										onClick={toggleShowDeliver}
									/>
								</MiniMenu>
							</ActionHeaderGroup>
						</ActionHeader>
					}
					{showPaused && <PausedAppointments/>}
					{showStatus && moment(date).isSame(new Date(), 'day') ?
						<StatusView list={work.list} work_schedule={work_schedule || {}} renderCounter={this.renderCounter}/> : null}
					<div>
						
							
							 {viewList.map(order => {
								const client_id = Number(get(order, 'client_id', 0));
								const vehicle_id = get(order, 'vehicle_id', 0);
								return <AppointmentPaper
									strict
									fused
									accessable={accessable}
									key={order.order_service_id}
									order={order}
									client_id={Number(client_id)}
									vehicle_id={Number(vehicle_id)}
									work_map={work_map}
								/>
							})}
						
					</div>
					<OrderViewDialog onClose={this.setOrderView(0)} open={orderDetailId > 0}>
						<OrderQuickView order_id={orderDetailId} onRequestClose={this.setOrderView(0)}/>
					</OrderViewDialog>
				</Segment>
			}}</Order.CollectProvider>
		);
	}
}

export default withRouter(Appmnts);

