import {SNACKBAR_ACTION} from "./types";
import {store} from "../store";
import {useDispatch} from "react-redux";

const _add = (message, color, action, actionCallback, duration) => ({type: SNACKBAR_ACTION.ADD, message, color, action, actionCallback, duration});
const _rm  = (message) => ({type: SNACKBAR_ACTION.REMOVE, message});

export const addSnackbar = (message, color, action = null, actionCallback = null, duration = 3000) => _add(message, color, action, actionCallback, duration);
export const hideSnackbar = message => _rm(message);

export const dispatchSnack = (message, color, action = null, actionCallback = null, duration = 3000) => store.dispatch(_add(message, color, action, actionCallback, duration));

/**
 *
 * @return {function(string=, string=, *=, function=, number=): *}
 */
export const useNotification = () => {
	const dispatch = useDispatch()
	return (message, color, action = null, actionCallback = null, duration = 3000) => dispatch(_add(message, color, action, actionCallback, duration))
}