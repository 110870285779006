import * as React from "react";
import PropTypes from "prop-types";
import {List, ListItem, Tab, Tabs} from "material-ui";
import {globals} from "ah-tools";
import {IconAdjust, IconCar, IconInfo, IconPerson} from "../../../Logic/icons";
import {Segment} from "semantic-ui-react";
import {Case, Default, Switch} from "../../../Logic/Switch";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {orderAction__fetchOneCached} from "../../../actions/orderActions";
import {isOnline} from "../../../actions/userActions";
import {SUB_ORDER_DELETE} from "../../../actions";
import {subscribe, unsubscribe} from "../../../Logic/PubSub";
import {BasicDialog} from "../../../Tools/Dialog/Dialogs";
import {Flex} from "../../../components/partials/ActionHeader";
import {OrderChangeTimesView} from "./OrderChangeTimesView";
import {OrderRemoveServiceView} from "./OrderRemoveServiceView";
import {OrderInsertServiceView} from "./OrderInsertServiceView";
import {OrderQuickClient, OrderQuickInfo, OrderQuickLoanCar, OrderQuickService, OrderQuickVehicle, OrderQuickWheel} from "./quick";
import {withModule} from "../../../Tools/RightsProvider";

export class OrderQuickChange extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number,
		order: PropTypes.object,
		onOpened: PropTypes.func,
	};
	static defaultProps = {
		onOpened: () => {
		}
	};
	state = {
		show_move: false,
		show_remove: false,
		show_add: false
	};
	
	show = (which, how) => () => this.setState({[`show_${which}`]: how}, () => this.props.onOpened(how));
	
	render() {
		const {order} = this.props;
		const {
			show_move,
			show_remove,
			show_add
		} = this.state;
		if (!order) return null;
		return (
			<React.Fragment>
				
				<List>
					<ListItem primaryText={"Termin verschieben"} onClick={this.show('move', true)}/>
					<ListItem primaryText={"Service entfernen"} onClick={this.show('remove', true)}/>
					<ListItem primaryText={"Service bearbeiten"} onClick={this.show('add', true)}/>
				</List>
				<BasicDialog modal title={"Abgabe- und Abholzeiten ändern"} autoScrollBodyContent open={show_move} onClose={this.show('move', false)} noPadding closeButton
				             renderActions={(closeBtn) => (
					             <Flex align={"space-between"} valign={"center"}>
						             {closeBtn}
						             {/*<Flex valign={"center"}>*/}
							         {/*    <IconButton style={{marginRight: 20}} onClick={requestOrderChangeTimesReload}><IconRefresh/></IconButton>*/}
							         {/*    <Button type={"submit"} form={"order-change-form"} positive>Ändern</Button>*/}
						             {/*</Flex>*/}
					             </Flex>
				             )}>
					<OrderChangeTimesView id={"order-change-form"} order={order} flexProps={{align: "center"}}/>
				</BasicDialog>
				<BasicDialog modal open={show_remove} title={"Service entfernen"} onClose={this.show('remove', false)} autoScrollBodyContent noPadding closeButton>
					{!!order && <OrderRemoveServiceView order_id={order.order_id}/>}
				</BasicDialog>
				<BasicDialog modal open={show_add} title={"Service bearbeiten"} onClose={this.show('add', false)} autoScrollBodyContent noPadding closeButton>
					{!!order && <OrderInsertServiceView order_id={order.order_id}/>}
				</BasicDialog>
			</React.Fragment>
		);
	}
}

OrderQuickChange = withInit(OrderQuickChange);
OrderQuickChange = connect(
	(state, {order, order_id}) => {
		return {
			order: order || state.orders.orders[order_id]
		};
	},
	(dispatch, {order_id}) => {
		return {
			init: () => dispatch(isOnline(() => {
				order_id && dispatch(orderAction__fetchOneCached(order_id));
			}))
		};
	}
)(OrderQuickChange);


/////////////
//      Quick View Summary
///////////////////////
class OrderQuickView extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		onRequestClose: PropTypes.func
	};
	static defaultProps = {};
	
	state = {
		view: "info",
		opened: false
	};
	
	componentDidMount() {
		this.closeChange = subscribe(SUB_ORDER_DELETE, (order_id) => {
			this.props.onRequestClose && this.props.onRequestClose(order_id);
		})
	}
	
	componentWillUnmount() {
		unsubscribe(SUB_ORDER_DELETE, this.closeChange);
	}
	
	onChangeTab = view => this.setState({view});
	
	render() {
		const {order_id, modules} = this.props;
		const {view} = this.state;
		return (
			<div>
				<Tabs inkBarStyle={{backgroundColor: globals.COLOR_SECONDARY}} value={view} onChange={this.onChangeTab}>
					<Tab icon={<IconInfo/>} label={"Info"} value={"info"}/>
					<Tab icon={<IconInfo/>} label={"Service"} value={"service"}/>
					<Tab icon={<IconPerson/>} label={"Kunde"} value={"client"}/>
					<Tab icon={<IconCar/>} label={"Fahrzeug"} value={"vehicle"}/>
					<Tab icon={<IconCar/>} label={"Leihwagen"} value={"loan_car"}/>
					{modules.wheel && <Tab icon={<IconAdjust/>} label={'Reifen'} value={'wheels'}/>}
				</Tabs>
				<Segment basic>
					<Switch value={view}>
						<Case is={"service"}>
							<OrderQuickService order_id={order_id}/>
						</Case>
						<Case is={"client"}>
							<OrderQuickClient order_id={order_id}/>
						</Case>
						<Case is={"vehicle"}>
							<OrderQuickVehicle order_id={order_id}/>
						</Case>
						<Case is={"loan_car"}>
							<OrderQuickLoanCar order_id={order_id}/>
						</Case>
						<Case is={'wheels'}>
							<OrderQuickWheel order_id={order_id}/>
						</Case>
						<Default>
							<OrderQuickInfo order_id={order_id}/>
						</Default>
					</Switch>
				</Segment>
			</div>
		);
	}
}

export default withModule(OrderQuickView);