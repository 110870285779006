
import {RESOURCE_ACTION, RESOURCE_TYPE_ACTION} from "./types";
import {addLoader, removeLoader, setLoader, unsetLoader} from "./loaderActions";
import {addError} from "./errorActions";
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {isCached, shouldUpdate, shouldUpdateExtend} from "ith-redux-helpers";
import {
	CACHE_RESOURCE_KFZ,
	CACHE_RESOURCE_MODEL, CACHE_RESOURCE_WORKERS,
	CACHE_TIME_RESOURCE_KFZ,
	CACHE_TIME_RESOURCE_MODEL, CACHE_TIME_RESOURCE_WORKERS, PROC_RESOURCE_KFZ_ASSIGN,
	PROC_RESOURCE_KFZ_FETCH, PROC_RESOURCE_MODEL_FETCH, PROC_RESOURCE_MODEL_MASS_ASSIGN, PROC_RESOURCE_WORKERS
} from "./index";
import isFunc from "lodash/isFunction";
import {round, get} from "lodash";

const _list = (list) => ({type:RESOURCE_ACTION.LOAD, list});
const _add = resource => ({type: RESOURCE_ACTION.ADD, resource});
const _update = (resource, id) => ({type: RESOURCE_ACTION.UPDATE, resource, id});
const _delete = id => ({type: RESOURCE_ACTION.REMOVE, id});
const _rel_load = (list) => ({type: RESOURCE_ACTION.WORKERS_LOAD, list});
const _rel_add = list => ({type: RESOURCE_ACTION.WORKERS_ADD, list});
const _rel_delete = (workers_id, resource_id) => ({type: RESOURCE_ACTION.WORKERS_DELETE, workers_id, resource_id});
const _load = (list) => ({type: RESOURCE_TYPE_ACTION.LOAD, list});
const _save = (resource) => ({type: RESOURCE_TYPE_ACTION.SAVE, resource});
const _deleteOne = resource => ({type: RESOURCE_TYPE_ACTION.REMOVE, resource});
const _setResources = (workers_id, resources) => ({type: RESOURCE_ACTION.SAVE_TIMES, workers_id, resources});
const _removeOneTime = (workers_id, resource_id) => ({type: RESOURCE_ACTION.REMOVE_ONE_TIME, workers_id, resource_id});
const _removeTimesOfResources = (workers_id, resource_ids) => ({type: RESOURCE_ACTION.REMOVE_RESOURCE_TIMES, workers_id, resource_ids});
const _updateTime = (resource) => ({type: RESOURCE_ACTION.UPDATE_TIME, resource});

export const resourceState__setList = (list) => _list(list);
export const resourceState__addToList = (resource) => _add(resource);
export const resourceState__updateList = (resource) => _update(resource, resource.resource_id);
export const resourceState__setKfz = (kfz) => ({type: RESOURCE_ACTION.KFZ_SET, kfz});
export const resourceState__updateKfz = (kfz) => ({type: RESOURCE_ACTION.KFZ_UPDATE, kfz});
export const resourceState__setModels = (models) => ({type: RESOURCE_ACTION.MODEL_SET, models});
export const resourceState__updateModels = (models) => ({type: RESOURCE_ACTION.MODEL_UPDATE, models});
export const resourceState__mergeModels = (models, service_id) => ({type: RESOURCE_ACTION.MODEL_UPDATE_MASS, models});
export const resourceState__unsetKfz = (service_id, kfz_id, resource_id) => ({type: RESOURCE_ACTION.KFZ_UNSET, service_id, kfz_id, resource_id});
export const resourceState__unsetModel = (service_id, model_id, resource_id) => ({type: RESOURCE_ACTION.MODEL_UNSET, model_id, resource_id, service_id});
export const resourceState__removeResourceFromModels = (model_ids, resource_id, service_id) => ({type: RESOURCE_ACTION.MODEL_UNSET_MASS, model_ids, resource_id, service_id});
export const resourceState__setWorkers = (list) => _rel_load(list);
export const resourceState__updateWorker = (list) => _rel_add(list);
export const resourceState__unsetWorker = (workers_id, resource_id) => _rel_delete(workers_id, resource_id);

export const resetWorkerResources = () => ({type: RESOURCE_ACTION.RESET});


export const resourceState__getAccumulatedHoursOfWork = (state, kfz_id, model_id, service_id) => {
	let data = get(state, ["resources", "models", service_id, model_id], null);
	if ( !data || !data.length ) {
		data = get(state, ["resources", "kfz", service_id, kfz_id], null);// state.resources.kfz[kfz_id];
	}
	if ( !data || !data.length ) {
		return 0;
	}
	return round(data.map(a => a.hours_of_work).reduce((a,b) => a + b), 1);
};


let queueResHouse = 0;
export const resourceCall__loadOfHouse = () => GET('/resource');
export const loadResourcesOfHouse = (force = false) =>
	async (dispatch, getState)=> {
		try {
			if ( !force && !shouldUpdate(getState().resources)) {
				return;
			}
			queueResHouse++;
			if ( queueResHouse > 1 ) {
				return;
			}
			dispatch(addLoader('worker_resources::load'));
			const resources = await GET('/resource');
			dispatch(_list(resources));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			queueResHouse = [];
			dispatch(removeLoader('worker_resources::load'));
		}
	};

export const resourceAction__add = (name, color, onSuccess=null) =>
	async dispatch => {
		try {
			dispatch(addLoader('worker_resource::add'));
			const resource = await PUT('/resource', {name, color});
			dispatch(_add(resource));
			onSuccess && onSuccess(resource);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('worker_resource::add'));
		}
	};
export const addResourceToHouse = resourceAction__add;

export const resourceAction__update = (resource_id, name, color, onSuccess=null) =>
	async dispatch => {
		try {
			dispatch(addLoader('worker_resources::update'));
			const resource = await POST('/resource', {resource_id, name, color});
			dispatch(_update(resource.data, resource.origin));
			onSuccess && onSuccess(resource);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('worker_resources::update'));
		}
	};
export const updateResourceForHouse = resourceAction__update;

export const resourceAction__delete = (resource_id, onSuccess=null) =>
	async dispatch => {
		try {
			dispatch(addLoader('worker_resources::delete'));
			const resource = await DELETE('/resource/' + resource_id);
			dispatch(_delete(resource.resource_id));
			onSuccess && onSuccess(resource);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('worker_resources::delete'));
		}
	};
export const deleteResourceOfHouse = resourceAction__delete;

export const loadResources4Workers = (force = false) =>
	async (dispatch, getState) => {
		try {
			if ( !force && !shouldUpdateExtend(getState().resources, 'workers')) {
				return;
			}
			dispatch(addLoader('resources4workers::load'));
			const list = await GET('/house/workers/resources');
			dispatch(_rel_load(list));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resources4workers::load'));
		}
	};
export const putResource4Workers = (workers_id, resource_id, onSuccess=null) =>
	async dispatch => {
		try {
			dispatch(addLoader('putResorce4Worekrs'));
			const data = await PUT('/house/workers/resources', {workers_id, resource_id});
			dispatch(_rel_add(data));
			onSuccess && onSuccess(data);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('putResorce4Worekrs'));
		}
	};
export const deleteResource4Workers = (workers_id, resource_id, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader('deleteResource4Workers'));
			const data = await DELETE(`/house/workers/resources/${workers_id}/${resource_id}`);
			dispatch(_rel_delete(data));
			onSuccess && onSuccess(data);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('deleteResource4Workers'));
		}
	};
export const saveResourceTimes = (oTimeData, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader('resource_times::save'));
			const result = await POST('/house/resource/times', oTimeData);
			dispatch(_setResources(result.workers_id, result.new_dates));
			onSuccess && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resource_times::save'));
		}
	};
export const loadResourcesTimesOf = (workers_id) =>
	async dispatch => {
		try {
			dispatch(addLoader('resource_times::load'));
			const result = await GET('/house/resources-of/'+workers_id);
			dispatch(_setResources(workers_id, result));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resource_times::load'));
		}
	};
export const deleteResourceTimeOf = (resource_id, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader('deleteResourceTimeOf'));
			const del = await DELETE('/house/resources/times/one/'+resource_id);
			dispatch(_removeOneTime(del.deleted.workers_id, del.deleted.id || resource_id));
			onSuccess && onSuccess(del);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('deleteResourceTimeOf'));
		}
	};
export const updateResourceTime = (id, start, end, onCollision, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader('updateResourceTime'));
			const update = await POST('/house/resources/times/update', {id, start, end});
			if ( update.success ) {
				dispatch(_updateTime(update.updated));
				onSuccess && onSuccess(update);
			} else {
				onCollision(update);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('updateResourceTime'));
		}
	};
export const deleteTimesOfResources = (workers_id, resource_ids, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader('deleteTimesOfResources'));
			resource_ids = resource_ids.join(',');
			const result = await DELETE(`/house/resources/times/all-of/${workers_id}/${resource_ids}`);
			dispatch(_removeTimesOfResources(workers_id, result.deleted));
			onSuccess && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('deleteTimesOfResources'));
		}
	};
export const deleteTimesRangesOfResource = (workers_id, resource_id, start, end, onSuccess=null) =>
	async dispatch => {
		try {
			dispatch(addLoader('deleteTimesRangesOfResource'));
			const result = await DELETE(`/house/resources/times/range/${workers_id}/${resource_id}/${start}/${end}`);
			dispatch(_setResources(workers_id, result));
			onSuccess && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('deleteTimesRangesOfResource'));
		}
	};






/////////////////////////
///////////
//					OLD functions

export const loadResourceTypes = () =>
	async dispatch => {
		try {
			dispatch(addLoader('resources::load'));
			const list = await GET('/house/resource-list');
			dispatch(_load(list));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resources::load'));
		}
	};

export const saveResourceType = (workers_id, resource) =>
	async (dispatch, getState) => {
		try {
			dispatch(addLoader('resource_type::save'));
			const res = await PUT('/house/resource', {workers_id, resource});
			dispatch(_save(res));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resource_type::save'));
		}
	};
export const deleteResourceType = (resource_id) =>
	async dispatch => {
		try {
			dispatch(addLoader('resource_type::delete'));
			const resource = await DELETE('/house/resource/' + resource_id);
			dispatch(_deleteOne(resource));
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader('resource_type::delete'));
		}
	};


export const resourceAction__fetch = loadResourcesOfHouse;
export const resourceAction__fetchKfz = (force = false, onSuccess) =>
	async (dispatch, getState) => {
		if ( !force && isCached(getState().resources.cache, CACHE_RESOURCE_KFZ)(CACHE_TIME_RESOURCE_KFZ)) {
			isFunc(onSuccess) && onSuccess(getState().resources.kfz);
			return;
		}
		try {
			dispatch(setLoader(PROC_RESOURCE_KFZ_FETCH));
			const result = await GET('/resource/kfz');
			dispatch(resourceState__setKfz(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_KFZ_FETCH));
		}
	};
export const resourceAction__fetchModels = (force = false, onSuccess) =>
	async (dispatch, getState) => {
		if ( !force && isCached(getState().resources.cache, CACHE_RESOURCE_MODEL)(CACHE_TIME_RESOURCE_MODEL)) {
			isFunc(onSuccess) && onSuccess(getState().resources.models);
			return;
		}
		try {
			dispatch(setLoader(PROC_RESOURCE_MODEL_FETCH));
			const result = await GET('/resource/model');
			dispatch(resourceState__setModels(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_MODEL_FETCH));
		}
	};
export const resourceAction__assignToKfz = (service, kfz, resource, hours_of_work, onSuccess) =>
	async(dispatch) => {
		const service_id = service.service_id || service;
		const kfz_id = kfz.kfz_id || kfz;
		const resource_id = resource.resource_id || resource;
		try {
			dispatch(setLoader(PROC_RESOURCE_KFZ_ASSIGN, kfz_id));
			const result = await PUT('/resource/kfz', {service_id, kfz_id, resource_id, hours_of_work});
			dispatch(resourceState__updateKfz(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_KFZ_ASSIGN, kfz_id));
		}
	};
export const resourceAction__unassignFromKfz = (service, kfz, resource, onSuccess) =>
	async(dispatch) => {
		const service_id = service.service_id || service;
		const kfz_id = kfz.kfz_id || kfz;
		const resource_id = resource.resource_id || resource;
		try {
			dispatch(setLoader(PROC_RESOURCE_KFZ_ASSIGN, kfz_id, resource_id));
			const result = await DELETE(`/resource/kfz/${service_id}/${kfz_id}/${resource_id}`);
			dispatch(resourceState__unsetKfz(result.service_id, result.kfz_id, result.resource_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_KFZ_ASSIGN, kfz_id, resource_id));
		}
	};
export const resourceAction__assignToModel = (service, model, resource, hours_of_work, onSuccess) =>
	async(dispatch) => {
		const service_id = service.service_id || service;
		const model_id = model.model_id || model;
		const resource_id = resource.resource_id || resource;
		try {
			const result = await PUT('/resource/model', {service_id, resource_id, model_id, hours_of_work});
			dispatch(resourceState__updateModels(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		}
	};
export const resourceAction__unassignFromModel = (service, model, resource, onSucces) =>
	async (dispatch) => {
		const service_id = service.service_id || service;
		const model_id = model.model_id || model;
		const resource_id = resource.resource_id || resource;
		try {
			const result = await DELETE(`/resource/model/${service_id}/${model_id}/${resource_id}`);
			dispatch(resourceState__unsetModel(service_id, model_id, resource_id));
			isFunc(onSucces) && onSucces(result);
		} catch (e) {
			dispatch(addError(e));
		}
	};
export const resourceAction__assignMassiveToModel = (service, models, resource, hours_of_work, onSuccess) =>
	async (dispatch) => {
		const resource_id = resource.resource_id || resource;
		const service_id = service.service_id || service;
		try {
			dispatch(setLoader(PROC_RESOURCE_MODEL_MASS_ASSIGN));
			const result = await PUT('/resource/models', {service_id, model_id: models, resource_id, hours_of_work});
			dispatch(resourceState__mergeModels(result, service_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_MODEL_MASS_ASSIGN));
		}
	};
export const resourceAction__unassignMassiveFromModels = (service, models, resource, onSuccess) =>
	async (dispatch) => {
		const service_id = service.service_id || service;
		const resource_id = resource.resource_id || resource;
		models = models.map(m => m.model_id || m);
		try {
			dispatch(setLoader(PROC_RESOURCE_MODEL_MASS_ASSIGN));
			const result = await DELETE(`/resource/models/${resource_id}`, {service_id, model_id: models});
			dispatch(resourceState__removeResourceFromModels(result.removed_ids, result.resource_id, result.service_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_MODEL_MASS_ASSIGN));
		}
	};

export const resourceCall__fetchWorkers = () => GET('/resource/workers');
export const resourceAction__fetchWorkers = (force=false, onSuccess) =>
	async (dispatch, getState) => {
		if ( !force && isCached(getState().resources.cache, CACHE_RESOURCE_WORKERS)(CACHE_TIME_RESOURCE_WORKERS)) {
			isFunc(onSuccess) && onSuccess(getState().resources.workers);
			return getState().resources.workers;
		}
		try {
			dispatch(setLoader(PROC_RESOURCE_WORKERS));
			const result = await GET('/resource/workers');
			dispatch(resourceState__setWorkers(result));
			isFunc(onSuccess) && onSuccess(result);
			return result
		} catch (e) {
			dispatch(addError(e));
			return null
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_WORKERS));
		}
	};
export const resourceAction__assignWorker = (worker, resource, onSucces) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		const resource_id = resource.resource_id || resource;
		try {
			dispatch(setLoader(PROC_RESOURCE_WORKERS, workers_id));
			const result = await PUT('/resource/worker', {resource_id, workers_id});
			dispatch(resourceState__updateWorker(result));
			isFunc(onSucces) && onSucces(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_WORKERS, workers_id));
		}
	};
export const resourceAction__unassignFromWorker = (worker, resource, onSuccess) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		const resource_id = resource.resource_id || resource;
		try {
			dispatch(setLoader(PROC_RESOURCE_WORKERS, workers_id));
			const result = await DELETE(`/resource/worker/${workers_id}/${resource_id}`);
			dispatch(resourceState__unsetWorker(workers_id, resource_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_RESOURCE_WORKERS, workers_id));
		}
	};