import {useHouse, useTranslation, useUser} from "../../../../actions/useStates";
import {get} from "lodash";
import {useNotification} from "../../../../actions/snackbarActions";
import {useDispatch} from "react-redux";
import {updateMasterDataOfHouse} from "../../../../actions/carhouseActions";
import {addError} from "../../../../actions/errorActions";
import {scheduleCall__importRequest, scheduleCall_import} from "../../../../actions/scheduleActions";
import {Paper} from "material-ui";
import {Button, Divider, Form, Header, Modal} from "semantic-ui-react";
import {DropdownField} from "../../../partials";
import {EasyFlex} from "../../../partials/ActionHeader";
import {MaterialCompoundDateInput, MaterialMobileAwarePicker} from "../../../../Tools/DatePicker";
import {TimeTrans} from "../../../../Tools";
import {CircleNotchLoader} from "../../../Loaders";
import {Trans} from "react-i18next";
import {ConfirmationButton} from "../../../partials/ConfirmButton";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";

const _options = ['static', 'dynamic']

export const MDCalendarSetting = ({supportOnly}) => {
	const [init, setInit] = useState(false)
	const [option, setOption] = useState('static')
	const [saving, setSaving] = useState(false)
	const [executing, setExecuting] = useState(false)
	const [requesting, setRequesting] = useState(false)
	const [request, setRequest] = useState(null)
	const [executed, setExecuted] = useState(null)
	const [date, setDate] = useState(null)
	const [confirm, setConfirm] = useState(false)
	const [t] = useTranslation()
	const house = useHouse()
	const houseOption = get(house, 'schedule_type', 'static')
	const houseId = get(house, 'house_id', 0)
	const notify = useNotification()
	const dispatch = useDispatch()
	const user = useUser()
	const editable = Boolean(!supportOnly || (user && user.is_support))
	
	useEffect(() => {
		setInit(true)
		setOption(houseOption)
	}, [houseOption])
	
	const options = useMemo(() => {
		return _options.map(s => ({key: s, value: s, text: t ? t(`house-schedule.${s}`) : s.substr(0, 1).toUpperCase() + s.substr(1)}))
	}, [t])
	
	const update = async () => {
		try {
			if (saving) return
			setSaving(true)
			dispatch(updateMasterDataOfHouse(houseId, {schedule_type: option}))
			notify('Daten wurden aktualisiert')
		} catch (e) {
			dispatch(addError(e))
		} finally {
			setSaving(false)
		}
	}
	
	const _request = async () => {
		try {
			if (requesting) return
			setRequesting(true)
			const _result = await scheduleCall__importRequest()
			setRequest(_result)
			if (_result && _result.date) {
				setDate(new Date(_result.date))
			}
		} catch (e) {
			dispatch(addError(e))
		} finally {
			setRequesting(false)
		}
	}
	const _execute = async () => {
		if (!date || executing) return
		try {
			setExecuting(true)
			const _result = await scheduleCall_import(date)
			setRequest(null)
			setExecuted(_result)
		} catch (e) {
			dispatch(addError(e))
		} finally {
			setExecuting(false)
		}
	}
	
	return <Paper zDepth={1} rounded style={{padding: 12}}>
		<Header as={'h3'}>Kalenderauswahl</Header>
		<Form onSubmit={update} loading={!init}>
			<Form.Field>
				<DropdownField
					editable={editable}
					readOnly={!editable}
					required
					label={'Kalender verwenden'}
					options={options}
					value={option}
					onChange={(e, {value}) => setOption(value)}
				/>
			</Form.Field>
			<Form.Field>
				{editable && <EasyFlex alignBetween>
					<Button loading={requesting} onClick={_request} negative type={'button'}>Statischen Kalender überführen</Button>
					<Button positive type={'submit'} disabled={!init} loading={saving}>Speichern</Button>
				</EasyFlex>}
				<div>
					<Modal open={!!request} size={'mini'} onClose={() => setRequest(null)} centered={false}>
						<Modal.Content>
							{request && <div>
								{!request.date && <div>
									<div>
										Bitte geben sie an bis zu welchem Datum der Kalender importiert werden soll.
									</div>
									<EasyFlex alignCenter>
										<MaterialCompoundDateInput
										
										/>
									</EasyFlex>
								</div>}
								{request.date && <div>
									<div>
										Es wurden Termine bis zum <strong>{date ? <TimeTrans type={'date'} value={date}/> : <CircleNotchLoader size={null}/>}</strong> gefunden.
										<Divider/>
										Sie können den Zeitraum anpassen, jedoch werden alle dynamischen Zeiten bis dato überschrieben.
									</div>
									<EasyFlex alignCenter>
										<MaterialMobileAwarePicker
											floating
											placeholderText={'Datum inkl.'}
											selected={date}
											minDate={new Date()}
											onChange={setDate}
										/>
									</EasyFlex>
								</div>}
							</div>}
						</Modal.Content>
						{request && <Modal.Actions>
							{!confirm && <Button  onClick={() => setRequest(null)}><Trans i18nKey={'actions.cancel'}/></Button>}
							<ConfirmationButton loading={executing} onStateChange={setConfirm} content={'OK'} primary>{abortConfirmation =>
								<Button.Group>
									<Button basic color={'grey'} className={'not-clickable'} type={'button'}>Ausführen?</Button>
									<Button basic color={'grey'} icon={'close'} onClick={abortConfirmation}/>
									<Button.Or/>
									<Button color={'green'} loading={executing} onClick={_execute}>Ja, ausführen</Button>
								</Button.Group>
							}</ConfirmationButton>
						</Modal.Actions>}
					</Modal>
					<Modal open={!!executed} size={'mini'} onClose={() => setExecuted(null)} centered={false}>
						<Modal.Content>
							{executed && <div>
								Aktion wurde erfolgreich durchgeführt.<br/>
								Es wurden <strong>{'created' in executed ? executed.created : 'k.A.'}</strong> Zeiten angelegt und <strong>{'removed' in executed ? executed.removed : 'k.A.'}</strong> Zeiten überschrieben.
							</div>}
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => setExecuted(null)}><Trans i18nKey={'actions.close'}/></Button>
						</Modal.Actions>
					</Modal>
				</div>
			</Form.Field>
		</Form>
	</Paper>
}
MDCalendarSetting.propTypes = {
	supportOnly: PropTypes.bool
}
MDCalendarSetting.defaultProps = {
	supportOnly: false
}
