import {VEHICLE_ACTION} from "./types";
import isFunc from 'lodash/isFunction';
import {setLoader, unsetLoader} from "./loaderActions";
import {CACHE_TIME_VEHICLES, CACHE_VEHICLES, CACHE_VEHICLES_RELATIONS, PROC_VEHICLES_CREATE, PROC_VEHICLES_DELETE, PROC_VEHICLES_EDIT, PROC_VEHICLES_FETCH, PROC_VEHICLES_RELATION} from "./index";
import {addError} from "./errorActions";
// import {DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {isCached} from "ith-redux-helpers";
import {appointmentState__update} from "./appointmentActions";
import {get, isUndefined} from "lodash";
import {mapState__orders_add, mapState__orderVehicles__add, mapState__vehicles_add} from "./mapActions";
import {Collector} from "../Logic/helper";
import {kfzAction__collectModels} from "./kfzActions";

export const vehicleState__set = vehicles => ({type: VEHICLE_ACTION.SET, vehicles});
export const vehicleState__add = vehicle => ({type: VEHICLE_ACTION.ADD, vehicle});
export const vehicleState__insert = vehicles => ({type: VEHICLE_ACTION.INSERT, vehicles});
export const vehicleState__remove = vehicle_id => ({type: VEHICLE_ACTION.REMOVE, vehicle_id});
export const vehicleState__relations = (client2vehicle, vehicles) => ({type: VEHICLE_ACTION.SET_CLIENT_RELATION, client2vehicle, vehicles});
export const vehicleState__order2vehicle = (order2vehicle) => ({type: VEHICLE_ACTION.SET_ORDER_VEHICLE, order2vehicle});

export const vehicleCall__getByOrder = order => GET(`/vehicle/order/${order.order_id || order}`);
export const vehicleCall__transfer = (vehicle_id, client_id) => POST('/vehicle/transfer', {vehicle: vehicle_id.vehicle_id || vehicle_id, client: client_id.client_id || client_id});
export const vehicleCall__searchChassis = (chassis, partial) => {
	if (!isUndefined(partial)) {
		partial = '/' + (partial ? 'true' : 'false');
	} else {
		partial = '';
	}
	return GET(`/vehicle/chassis/${chassis}${partial}`);
};
export const vehicleCall__get = id => GET(`/vehicle/one/${id}`);
export const vehicleCall__collect = vehicle_ids => POST('/vehicle/collect', {list: vehicle_ids});
export const vehicleCall__collectOrders = vehicle_ids => POST('/vehicle/collect/order', {list: vehicle_ids});
export const vehicleCall__ofClient = client_id => GET(`/vehicle/client/${client_id}`);
export const vehicleCall__ofClients = client_ids => POST('/vehicle/clients', {list: client_ids});

let fetchQueue = [];
export const vehicleAction__fetch = (force=false, onSuccess) =>
	async (dispatch, getState) => {
		if (!force && isCached(getState().vehicles.cache, CACHE_VEHICLES)(CACHE_TIME_VEHICLES)) {
			isFunc(onSuccess) && onSuccess(getState().vehicles.list);
			return;
		}
		try {
			fetchQueue.push(onSuccess || null);
			if ( fetchQueue.length > 1 ) {
				return;
			}
			dispatch(setLoader(PROC_VEHICLES_FETCH));
			const vehicles = await GET('/vehicle');
			dispatch(vehicleState__set(vehicles));
			dispatch(mapState__vehicles_add(vehicles));
			for (const queue of fetchQueue) {
				isFunc(queue) && queue(vehicles);
			}
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_FETCH));
			fetchQueue = [];
		}
	};
export const vehicleAction__fetchOfClient = (client, force=false, onSuccess) =>
	async (dispatch, getState) => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_VEHICLES_FETCH, client_id));
			const result = await GET(`/vehicle/client/${client_id}`);
			dispatch(vehicleState__insert(result));
			dispatch(mapState__vehicles_add(result));
			const ids = Object.keys(result);
			dispatch(kfzAction__collectModels(ids));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_FETCH, client_id));
		}
	};

export const vehicleAction__fetchOfClients = (clients, onSuccess) =>
	async dispatch => {
		const client_ids = clients.map(c => c.client_id || c);
		try {
			const list = await vehicleCall__ofClients(client_ids);
			dispatch(mapState__vehicles_add(list));
			isFunc(onSuccess) && onSuccess(list);
			return list;
		} catch (e) {
			dispatch(addError(e));
			return [];
		}
	};

export const vehicleAction__create = (client, vehicle, onSuccess) =>
	async dispatch => {
		const client_id = client.client_id || client;
		try {
			dispatch(setLoader(PROC_VEHICLES_CREATE, client_id));
			const result = await PUT('/vehicle', {...vehicle, client_id});
			dispatch(vehicleState__add(result));
			dispatch(mapState__vehicles_add(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_CREATE, client_id));
		}
	};
export const vehicleAction__change = (vehicle, onSuccess) =>
	async dispatch => {
		const vehicle_id = vehicle.vehicle_id || vehicle;
		try {
			dispatch(setLoader(PROC_VEHICLES_EDIT, vehicle_id));
			const result = await POST('/vehicle', {...vehicle});
			dispatch(vehicleState__add(result));
			dispatch(mapState__vehicles_add(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_EDIT, vehicle_id));
		}
	};
export const vehicleAction__delete = (vehicle, onSuccess) =>
	async dispatch => {
		const vehicle_id = vehicle.vehicle_id || vehicle;
		try {
			dispatch(setLoader(PROC_VEHICLES_DELETE, vehicle_id));
			const result = await DELETE(`/vehicle/${vehicle_id}`);
			dispatch(vehicleState__remove(result.vehicle.vehicle_id));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_DELETE, vehicle_id));
		}
	};
export const vehicleAction__relations = (onSuccess) =>
	async (dispatch, getState) => {
		if ( isCached(getState().vehicles.cache, CACHE_VEHICLES)(CACHE_TIME_VEHICLES) && isCached(getState().vehicles.cache, CACHE_VEHICLES_RELATIONS)(CACHE_TIME_VEHICLES)) {
			isFunc(onSuccess) && onSuccess({
				rel_client: getState().vehicles.client2vehicle,
				rel_vehicle: getState().vehicles.list
		});
			return;
		}
		try {
			dispatch(setLoader(PROC_VEHICLES_RELATION));
			const result = await GET('/vehicle/house');
			dispatch(vehicleState__relations(result.rel_client, result.rel_vehicle));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_RELATION));
		}
	};

export const vehicleAction__getByOrder = (order, update = [false, true, true], onSuccess) =>
	async (dispatch, getState) => {
		const exists = get(getState().vehicles, ["order2vehicles", order.order_id || order], false);
		if (exists) {
			console.error(order, "exists already (vehicleAction__getByOrder())");
			return;
		}
		try {
			const result = await vehicleCall__getByOrder(order);
			"order" in result && dispatch(mapState__orders_add(result.order));
			update[0] && dispatch(appointmentState__update(result.order));
			update[1] && dispatch(vehicleState__order2vehicle(result.order2vehicle));
			update[2] && dispatch(vehicleState__insert(result.vehicle));
			"vehicle" in result && dispatch(mapState__vehicles_add(result.vehicle));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		}
	};

export const vehicleAction__transfer = (vehicle_id, client_id, onSuccess) =>
	async dispatch => {
		const id = vehicle_id.vehicle_id || vehicle_id;
		try {
			dispatch(setLoader(PROC_VEHICLES_EDIT, id));
			const result = await vehicleCall__transfer(vehicle_id, client_id);
			dispatch(vehicleState__add(result.vehicle));
			dispatch(mapState__vehicles_add(result.vehicle));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_VEHICLES_EDIT, id));
		}
	};

let getOneQueue = {};
export const vehicleAction__getOne = (vehicle_id) =>
	async(dispatch, getState) => {
		if (!getOneQueue[vehicle_id]) {
			getOneQueue[vehicle_id] = new Promise(async k => {
				let vehicle = get(getState(), ['vehicles', 'list', vehicle_id]);
				
				try {
					if (vehicle ) {
						k(vehicle);
						return vehicle;
					}
					dispatch(setLoader(PROC_VEHICLES_FETCH, vehicle_id));
					vehicle = await vehicleCall__get(vehicle_id);
					
					k(vehicle);
				} catch (e) {
					dispatch(addError(e));
				} finally {
					delete getOneQueue[vehicle_id];
					dispatch(unsetLoader(PROC_VEHICLES_FETCH, vehicle_id));
				}
			});
		}
		const vehicle = await getOneQueue[vehicle_id];
		dispatch(vehicleState__add(vehicle));
		dispatch(mapState__vehicles_add(vehicle));
		return vehicle;
	};

let __collect = {};
export const vehicleAction__collect = vehicles =>
	Collector._(__collect, vehicles, 'vehicle_id')
		.withLoader([PROC_VEHICLES_FETCH])
		.withStateProvider('map.vehicles')
		.withApiProvider(list => vehicleCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__vehicles_add(result)))
		.execute();

let __collectOrder = {};
export const vehicleAction__collectOrders = (vehicles) =>
	Collector._(__collectOrder, vehicles, 'order_vehicle_id')
		.withStateProvider('map.order2vehicles')
		.withResultProvider('order')
		.withApiProvider(list => vehicleCall__collectOrders(list))
		.withStoreProvider((result, dispatch) => {
			dispatch(mapState__orderVehicles__add(result.order));
			"vehicles" in result && dispatch(mapState__vehicles_add(result.vehicles))
		})
		.execute();