import * as React from "react";
import {EasyFlex} from "../components/partials/ActionHeader";
import PropTypes from 'prop-types';
import {Pagination} from "semantic-ui-react";


export const FixedBottomPagination = ({page, pages, onPageChange, paginationProps, ...props}) => (
	pages < 2 ? null :
		<EasyFlex align={EasyFlex.align.CENTER} {...props}>
			<Pagination activePage={page+1} totalPages={pages} onPageChange={onPageChange} {...paginationProps}/>
		</EasyFlex>
);

FixedBottomPagination.propTypes = {
	page: PropTypes.number,
	pages: PropTypes.number,
	onPageChange: PropTypes.func,
	paginationProps: PropTypes.object
};