import {RESET_ACTION, SESSION_STORAGE_ACTIONS} from "../actions/types";
import {isArray, isBoolean, omit, uniq} from "lodash";
import {array2object} from "../Logic/extensions";

const SESSION_STORAGE_NAME = "carperto-session-storage";

const getStorageObject = () => JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME) || "{}");

const updateStorageObject = (object) => {
	sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify({...object}));
	return object;
};

const defaultState = {
	notification_appointment_ids: [],
	notification_paused_map: {},
	dev_order_change_id: 0,
	appointments_show_undelivered: false,
	order_state_filters: {
		released: null,
		delivered: null,
		progress: null,
		finished: null,
		fetched: null,
		is_waiting: null,
		is_delivery_service: null,
		is_consultant: null
	},
	order_consultant_selection: null,
	countries: null
};

export default (state = defaultState, {type, value, filter}) => {
	switch (type) {
		case RESET_ACTION:
		case SESSION_STORAGE_ACTIONS.RESET:
			return updateStorageObject(defaultState);
		case SESSION_STORAGE_ACTIONS.NOTIFICATION_APPOINTMENT_IDS:
			value = isArray(value) ? value : [value];
			return updateStorageObject({
				...state,
				notification_appointment_ids: uniq([...state.notification_appointment_ids, ...value])
			});
		case SESSION_STORAGE_ACTIONS.CLEAR_NOTIFICATION_APPOINTMENT_IDS:
			return updateStorageObject({
				...state,
				notification_appointment_ids: []
			});
		case SESSION_STORAGE_ACTIONS.NOTIFICATION_APPOINTMENT_UNSET_IDS:
			value = isArray(value) ? value : [value];
			return updateStorageObject({
				...state,
				notification_appointment_ids: state.notification_appointment_ids.filter(id => !value.includes(id))
			});
		case SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_SET:
			if (!isArray(value)) {
				value = [value];
			}
			return updateStorageObject({
				...state,
				notification_paused_map: {
					...state.notification_paused_map,
					...array2object(value)(v => v)
				}
			});
		case SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_REMOVE:
			if (!isArray(value)) {
				value = [value];
			}
			return updateStorageObject({
				...state,
				notification_paused_map: omit(state.notification_paused_map, value)
			});
		case SESSION_STORAGE_ACTIONS.NOTIFICATION_PAUSED_CLEAR:
			return updateStorageObject({
				...state,
				notification_paused_map: {}
			});
		case SESSION_STORAGE_ACTIONS.DEV_ORDER_CHANGE_ID:
			return updateStorageObject({
				...state,
				dev_order_change_id: value
			});
		case SESSION_STORAGE_ACTIONS.TOGGLE_APPOINTMENTS_SHOW_UNDELIVERED:
			return updateStorageObject({
				...state,
				appointments_show_undelivered: isBoolean(value) ? value : !state.appointments_show_undelivered
			});
		case SESSION_STORAGE_ACTIONS.CHANGE_ORDER_FILTER_STATE:
			return updateStorageObject({
				...state,
				order_state_filters: {
					...state.order_state_filters,
					[filter]: value
				}
			});
		case SESSION_STORAGE_ACTIONS.SET_ORDER_FILTER_STATE:
			return updateStorageObject({
				...state,
				order_state_filters: {
					...value
				}
			});
		case SESSION_STORAGE_ACTIONS.RESET_ORDER_FILTER_STATE:
			return updateStorageObject({
				...state,
				order_state_filters: {
					...defaultState.order_state_filters
				}
			});
		case SESSION_STORAGE_ACTIONS.COUNTRIES:
			return updateStorageObject({
				...state,
				countries: value
			});
		case SESSION_STORAGE_ACTIONS.ORDER_CONSULTANT_SELECT:
			return updateStorageObject({
				...state,
				order_consultant_selection: value
			});
		default:
			return {
				...state,
				...getStorageObject()
			};
	}
};