import {isOnline} from "../../../../actions/userActions";
import {kfzAction__fetch} from "../../../../actions/kfzActions";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_KFZ_FETCH} from "../../../../actions";
import MDCars from "../../../../components/intern/master-data/payment/MDCars";
import {connect} from "react-redux";
import withInit from "../../../../Logic/withInit";

const m2s = state => ({
	loading: isLoading(state, PROC_KFZ_FETCH),
	cars: state.kfz.cars ? Object.values(state.kfz.cars) : undefined
});

const m2d = dispatch => {
	// dispatch(isOnline(() => {
	// 	dispatch(kfzAction__fetch());
	// }));
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(kfzAction__fetch());
		})),
		onFetch: (force, onSuccess) => dispatch(kfzAction__fetch(force, onSuccess))
	};
};

export default connect(m2s, m2d)(withInit(MDCars));