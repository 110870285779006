import * as React from 'react';
import PropTypes from "prop-types";
import {vehicleCall__collect} from "../../../../actions/vehicleActions";
import {clientCall__collect} from "../../../../actions/clientActions";
import {orderCall__collect} from "../../../../actions/orderActions";
import {deepMemoize as memoizeOne, falseNull, trueNull} from "../../../../Logic/extensions";
import {omit} from "lodash";
import {Button, Table} from "semantic-ui-react";
import {Client, Order, Vehicle} from "../../../../models";
import {moment} from "../../../../Logic/Moment";
import {translate} from "react-i18next";
import Countdown, {renderSimple} from "../../../partials/Countdown";
import {FullscreenPortal} from "../../../../Tools/Dialog/Dialogs";
import OrderQuickView from "../../../../cointainer/intern/orders/OrderQuickView";

export let LoanCarScheduleHeader = ({vehicle, client, order, start_point, end_point, info, actions}) => (
	<Table.Header>
		<Table.Row>
			{trueNull(vehicle) && <Table.HeaderCell>Fahrzeug</Table.HeaderCell>}
			{trueNull(client) && <Table.HeaderCell>Kunde</Table.HeaderCell>}
			{trueNull(order) && <Table.HeaderCell>Auftrag</Table.HeaderCell>}
			{trueNull(start_point) && <Table.HeaderCell>Von</Table.HeaderCell>}
			{trueNull(end_point) && <Table.HeaderCell>Bis</Table.HeaderCell>}
			{trueNull(info) && <Table.HeaderCell>Info</Table.HeaderCell>}
			{trueNull(actions) && <Table.HeaderCell>&nbsp;</Table.HeaderCell>}
		</Table.Row>
	</Table.Header>
)
LoanCarScheduleHeader.propTypes = {
	vehicle: PropTypes.bool,
	client: PropTypes.bool,
	order: PropTypes.bool,
	start_point: PropTypes.bool,
	end_point: PropTypes.bool,
	info: PropTypes.bool,
	actions: PropTypes.bool
}
LoanCarScheduleHeader.defaultProps = {
	vehicle: true,
	client: true,
	order: true,
	start_point: true,
	end_point: true,
	info: true,
	actions: true
};
LoanCarScheduleHeader = translate()(LoanCarScheduleHeader);


// const OrderDetail = ({onShowOrderDetail, icon = 'list', ...props}) => onShowOrderDetail ? <Button {...props} icon={icon} onClick={onShowOrderDetail}/> : <Icon {...props} name={icon}/>;

const StartPointCountdown = ({date, prefix, suffix, ...props}) => <div {...props}>{prefix}<Countdown targetDate={date} render={renderSimple}/>{suffix}</div>;

export class LoanCarScheduleList extends React.Component {
	static propTypes = {
		list: PropTypes.arrayOf(
			PropTypes.shape({
				loan_car_schedule_id: PropTypes.number.isRequired,
				vehicle_id: PropTypes.number.isRequired,
				house_id: PropTypes.number.isRequired,
				client_id: PropTypes.number,
				order_id: PropTypes.number,
				info: PropTypes.string,
				start_point: PropTypes.any,
				end_point: PropTypes.any
			})
		).isRequired,
		vehicle: PropTypes.shape({
			vehicle_id: PropTypes.number.isRequired,
			client_id: PropTypes.number.isRequired,
			loan_car: PropTypes.bool
		}).isRequired,
		onCollectVehicles: PropTypes.func,
		onCollectClients: PropTypes.func,
		onCollectOrders: PropTypes.func,
		onShowOrderDetail: PropTypes.func,
		actionRenderer: PropTypes.func.isRequired,
		hideActions: PropTypes.bool,
		actionCellProps: PropTypes.object
	};
	static defaultProps = {
		onCollectVehicles: (ids) => vehicleCall__collect(ids),
		onCollectClients: (ids) => clientCall__collect(ids),
		onCollectOrders: (ids) => orderCall__collect(ids),
		hideActions: false
	};
	
	state = {
		vehicles: {},
		clients: {},
		orders: {},
		detail: 0
	}
	
	componentDidMount() {
		const {list, onCollectVehicles, onCollectClients, onCollectOrders} = this.props;
		const {orders, vehicles, clients} = this.collectIds(list);
		vehicles.length && onCollectVehicles(vehicles).then(vehicles => this.setState({vehicles})).catch(console.error);
		clients.length && onCollectClients(clients).then(clients => this.setState({clients})).catch(console.error);
		orders.length && onCollectOrders(orders).then(({orders}) => this.setState({orders})).catch(console.error);
	}
	
	
	collectIds = memoizeOne(
		list => {
			let orders = {}, vehicles = {}, clients = {};
			for (const s of list) {
				if (s.order_id) {
					orders[s.order_id] = s.order_id;
				}
				if (s.vehicle_id) {
					vehicles[s.vehicle_id] = s.vehicle_id;
				}
				if (s.client_id) {
					clients[s.client_id] = s.client_id;
				}
			}
			return {orders: Object.values(orders), vehicles: Object.values(vehicles), clients: Object.values(clients)};
		}
	)
	
	hasInfo = memoizeOne(
		list => list.reduce((carry, s) => carry || !!s.info, false)
	);
	
	
	render() {
		const {list, vehicle, onShowOrderDetail, onSelect, hideAction, actionRenderer, actionCellProps, ...props} = this.props;
		const {clients, vehicles, orders} = this.state;
		const info = this.hasInfo(list);
		const tableProps = omit(props, ['list', 'vehicle', 'onCollectVehicles', 'onCollectClients', 'onCollectOrders', 'onShowOrderDetail', 'actionRenderer', 'hideActions', 'actionCellProps'])
		return (
			<React.Fragment>
				<Table {...tableProps}>
					<LoanCarScheduleHeader info={info} actions={!hideAction}/>
					<Table.Body>
						{list.map(schedule => {
							let vehicle = vehicles[schedule.vehicle_id] || null;
							if (vehicle) {
								vehicle = new Vehicle(vehicle);
							}
							let client = schedule.client_id ? clients[schedule.client_id] || null : null;
							if (client) {
								client = new Client(client);
							}
							let order = schedule.order_id ? orders[schedule.order_id] || null : null;
							if (order) {
								order = new Order(order);
							}
							const is_in_time = moment(schedule.start_point) <= moment() || (order && (order.progress || order.delivered_at) && !order.finished_at);
							const warning = !is_in_time && moment().add(1, 'day') > moment(schedule.start_point);
							return (
								<Table.Row
									key={`conflicting-${schedule.loan_car_schedule_id}`}
									// warning={warning}
									negative={is_in_time}
								>
									<Table.Cell>{vehicle ? vehicle.name : '...'}</Table.Cell>
									<Table.Cell>{client ? client.contact : '...'}</Table.Cell>
									<Table.Cell>{order ? <Button onClick={() => this.setState({detail: order.order_id})} icon={'list'} circular/> : '...'}</Table.Cell>
									<Table.Cell>{schedule.start_point ? moment(schedule.start_point).format('LLL') : '-'}{trueNull(warning) &&
									<StartPointCountdown
										style={{textIndent: 25, fontSize: 'smaller', fontWeight: 'bold'}} prefix={'in '}
										date={new Date(schedule.start_point)}/>}</Table.Cell>
									<Table.Cell>{schedule.end_point ? moment(schedule.end_point).format('LLL') : '-'}</Table.Cell>
									{trueNull(info) && <Table.Cell>{schedule.info}</Table.Cell>}
									{falseNull(hideAction) && <Table.Cell textAlign={'right'} {...actionCellProps}>
										{
											actionRenderer(this, {schedule, vehicle, client, order, is_in_time, warning})
										}
									</Table.Cell>}
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
				<FullscreenPortal open={!!this.state.detail} bottomBar={Bar => <Bar><Button onClick={() => this.setState({detail: 0})}>Schließen</Button></Bar>}>
					{trueNull(this.state.detail) && <OrderQuickView order_id={this.state.detail} onRequestClose={() => this.setState({detail: 0})}/>}
				</FullscreenPortal>
			</React.Fragment>
		);
	}
}