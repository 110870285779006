'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
} // import "babel-polyfill";


try {
  require('babel-polyfill');
} catch (e) {// console.info('Babel polyfill could not be loaded...', e);
}

var updateInterval = 1000 * 60 * 5;
var EXTEND_STRING = 'last_update_timestamp_';
var DEFAULT_STRING = 'last_update_timestamp';

var getUpdateCacheKey = function getUpdateCacheKey() {
  var extend = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return extend ? EXTEND_STRING + extend : DEFAULT_STRING;
};

var updateCache = function updateCache() {
  var extend = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var key = getUpdateCacheKey(extend);
  return _defineProperty({}, key, new Date().getTime());
};

var updateCacheData = function updateCacheData() {
  for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
    values[_key] = arguments[_key];
  }

  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var key = [DEFAULT_STRING].concat(values).join('_');

    if (state === null) {
      return _defineProperty({}, key, new Date().getTime());
    }

    if ((typeof state === 'undefined' ? 'undefined' : _typeof(state)) === 'object') {
      if (state[key]) {
        return _defineProperty({}, key, new Date().getTime());
      }
    } else if (state) {
      return _defineProperty({}, key, new Date().getTime());
    }

    return {};
  };
};

var updateFetch = function updateFetch(object) {
  var extend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return _extends({}, object, updateCache(extend));
};

var shouldUpdate = function shouldUpdate(object) {
  var range = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : updateInterval;
  return !object || !object.last_update_timestamp || range + object.last_update_timestamp < new Date().getTime();
};

var shouldUpdateExtend = function shouldUpdateExtend(object, extend) {
  var range = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : updateInterval;
  var key = getUpdateCacheKey(extend);
  return !object || !object[key] || range + object[key] < new Date().getTime();
};

var async = function async() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return new Promise(function (resolve) {
    var ident = setTimeout(function () {
      return resolve(ident);
    }, duration);
  });
};

var setUpdateInterval = function setUpdateInterval(value) {
  return updateInterval = value;
};

var isCached = function isCached(sourceObject) {
  for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }

  return function () {
    var cacheTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : updateInterval;
    var key = [DEFAULT_STRING].concat(args).join('_');
    return Boolean(sourceObject && sourceObject[key] && cacheTime + sourceObject[key] > new Date().getTime());
  };
};

exports.updateCache = updateCache;
exports.updateFetch = updateFetch;
exports.shouldUpdate = shouldUpdate;
exports.shouldUpdateExtend = shouldUpdateExtend;
exports.setUpdateInterval = setUpdateInterval;
exports.getUpdateCacheKey = getUpdateCacheKey;
exports.isCached = isCached;
exports.async = async;
exports.updateCacheData = updateCacheData;