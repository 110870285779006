import * as React from "react";
import PropTypes from 'prop-types';
import {Button, FormField, Grid, Segment} from "semantic-ui-react";
import {AHToggle, deepMemoize as memoizeOne, ListItemMargined} from "../../../../Logic/extensions";
import {withSize} from 'react-sizeme';
import {getSalutationList} from "../../../../Logic/constants";
import EditField from "../../../partials/EditField";
import ClientIcon from "material-ui/svg-icons/action/account-circle";
import {ValidationDropdown, ValidationField} from "../../../partials/ValidationField";
import {translate} from "react-i18next";
import {getValidationValue, ValidationForm} from "../../../partials/ValidationContext";
import InputWrapper, {WrappedInput} from "../../../partials/InputWrapper";
import AddressIcon from "material-ui/svg-icons/action/home";
import {ValidationSearch} from "../../../partials";
import {searchAction__zipcode__pure} from "../../../../actions/searchActions";
import DropdownField from "../../../partials/DropdownField";
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import EmailIcon from "material-ui/svg-icons/communication/email";
import TelephoneIcon from "material-ui/svg-icons/communication/phone";
import MobileIcon from "material-ui/svg-icons/hardware/phone-iphone";
import {clientCall__collectOne} from "../../../../actions/clientActions";
import {EasyFlex} from "../../../partials/ActionHeader";
import {omit} from "lodash";
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {ConnectedConsultantSelector} from "../consultant/ConsultantClientSelector";


const WIDTH = 'auto';


// noinspection JSIgnoredPromiseFromCall,JSUnresolvedVariable
export class ClientDetail extends StatyComponent {
	static propTypes = {
		client_id: PropTypes.number,
		onFetch: PropTypes.func,
		onError: PropTypes.func,
		onSave: PropTypes.func,
		onClient: PropTypes.func,
		enableSubmitOnEnter: PropTypes.bool
	};
	static defaultProps = {
		editable: false,
		countries: {country_id: "germany"},
		onFetch: (client_id) => clientCall__collectOne(client_id),
		onClient: () => {},
		enableSubmitOnEnter: false
	};
	
	state = {
		fetching: false,
		saving: false,
		client: {
			self: false,
			consultant_id: null,
			client_number: '',
			salutation: '',
			company_name: '',
			name: '',
			first_name: '',
			street: '',
			house_no: '',
			address_extra: '',
			city: '',
			zipcode: '',
			country: 'germany',
			email : '',
			telephone_prefix: '',
			telephone: '',
			mobile: '',
			email_confirm: ''
		},
		formErrors: 0,
		errors: null
	};
	
	fetch = async() => {
		const  {client_id, onFetch, onError, onClient} = this.props;
		if (client_id) {
			try {
				this.setState({fetching: true});
				const client = await onFetch(client_id);
				client && this.setState({client}, () => onClient(client));
			} catch (e) {
				console.error(e);
				onError && onError(e);
			} finally {
				this.setState({fetching: false});
			}
			
		}
	}
	
	save = async() => {
		const {onSave, onError, onClient} = this.props;
		if (onSave) {
			try {
				this.setState({saving: true});
				const client = await onSave(this.state.client);
				client && this.setState({client}, () => onClient(client));
			} catch (e) {
				console.error(e);
				onError && onError(e);
			} finally {
				this.setState({saving: false});
			}
		}
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.fetch();
	}
	
	setCallback = name => (e, value) => this.setState(state => ({
		...state,
		client: {
			...state.client,
			[name]: value
		}
	}));
	
	set = (e, {name, value}) => this.setState(state => ({...state, client: {...state.client, [name]: value || ''}}));
	setConsultant = (consultant) => this.set(null, {name: 'consultant_id', value: consultant ? consultant.workers_id : null});
	// setAndUpdate = (e, {name, value}) => this.setState(state => ({...state, client: {...state.client, [name]: value || ''}}), () => this.forceUpdate());
	
	setZipcode = (e, {result}) => this.setState(state => ({
		...state,
		client: {
			...state.client,
			zipcode: result.zipcode,
			city: `${result.name} ${result.name_extra}`
		}
	}));
	
	getSalutations = () => {
		return getSalutationList();
	};
	
	columns = memoizeOne(
		width => Math.max(1, Math.floor(width / 366))
	);
	
	onValidationUpdate = (data) => {
		this.setState({formErrors: data.error, errors: data});
	};
	
	validation = getValidationValue(this.onValidationUpdate, false);
	
	countries = memoizeOne(
		data => values(mapValues(data, (c) => {
			c.value = c.country_id;
			c.text = this.props.t(`country.${c.value}`, c.value[0].toUpperCase() + c.value.slice(1));
			return c;
		}))
	);
	
	get valid() {
		return !this.state.formErrors;
	}
	
	getPhonePrefix = memoizeOne(
		(map,selection) => selection ? map[selection].phone_prefix : '+49'
	);
	
	render() {
		const {size, editable: givenEditable, t, i18n, tReady, givenClient, countries: countryProps, onSave, enableSubmitOnEnter, ...props} = this.props;
		const {client, fetching, saving} = this.state;
		
		const chunks = this.columns(size.width);
		const countries = this.countries(countryProps);
		const editable = Boolean(onSave && givenEditable);
		const innerProps = omit(props,['client_id', 'onFetch', 'onError', 'onSave', 'onClient', 'enableSubmitOnEnter']);
		// const phonePrefix = this.getPhonePrefix(countryProps, client.country);
		
		return(
			<div {...innerProps}>
				<Segment basic loading={fetching || saving} style={{margin: 0, padding: 0}}>
					<ValidationForm value={this.validation}>
						<Grid columns={chunks} celled='internally' >
							<Grid.Row>
								<Grid.Column>
									<div style={{maxWidth: WIDTH}}>
										<ListItemMargined
											className="multiline-toggle"
											primaryText={"Interne Abteilung"}
											secondaryText={client.self ? "Es handelt sich um eine Abteilung innerhalb Ihres Unternehmens, oder um Ihr Unternehmen als Ganzes." : "Es handelt sich um einen externen (normalen) Kunden."}
											rightToggle={<AHToggle disabled={!editable} toggled={client.self} onToggle={this.setCallback('self')}/>}
										/>
										<FormField>
											<EditField
												icon={<ClientIcon/>}
												editable={editable}
												name={'client_number'}
												label={t('client.client-number')}
												placeholder={t('client.client-number-example')}
												autoComplete={'off'}
												value={client.client_number}
												onChange={this.set}
											/>
										</FormField>
										<FormField>
											<ValidationDropdown
												labeled
												value={client.salutation}
												name={'salutation'}
												selection
												onChange={this.set}
												id={'salutation2'}
												placeholder={"Bitte auswählen"}
												label={t('client.salutation')}
												icon={<ClientIcon/>}
												// trigger={client.salutation ? SALUTATION_OPTIONS[client.salutation].text : 'Bitte auswählen'}
												options={this.getSalutations()}
												editable={editable}
												required
											/>
										</FormField>
										{client.salutation === 'company' &&
										<FormField required>
											<ValidationField
												icon={<ClientIcon/>}
												editable={editable}
												required={'company' === client.salutation}
												label={t('client.company-name')}
												placeholder={t('client.company-name-example')}
												autoComplete={'off'}
												name={'company_name'}
												onChange={this.set}
												value={client.company_name}
											/>
										</FormField>
										}
										<FormField>
											<ValidationField
												icon={<ClientIcon/>}
												editable={editable}
												label={t('client.first-name')}
												placeholder={t('client.first-name-example')}
												autoComplete={'off'}
												name={'first_name'}
												onChange={this.set}
												value={client.first_name}
											/>
										</FormField>
										<FormField required>
											<ValidationField
												// required={client.salutation !== 'company'}
												icon={<ClientIcon/>}
												editable={editable}
												label={t('client.name')}
												placeholder={t('client.name-example')}
												autoComplete={'off'}
												name={'name'}
												onChange={this.set}
												value={client.name}
											/>
										</FormField>
									</div>
								</Grid.Column>
								<Grid.Column>
									<div style={{maxWidth: WIDTH}}>
										<FormField>
											<InputWrapper label="Straße & Hausnummer" onChange={this.set} editable={editable} icon={<AddressIcon/>}>
												<WrappedInput grow={90} shrink={0.3} placeholder="Straße" name="street" value={client.street} />
												<WrappedInput placeholder="Nr" name="house_no" value={client.house_no} />
											</InputWrapper>
										</FormField>
										<FormField>
											<EditField
												icon={<AddressIcon/>}
												editable={editable}
												label={t('client.address-extra')}
												placeholder={t('client.address-extra-example')}
												autoComplete={'off'}
												name={'address_extra'}
												value={client.address_extra}
												onChange={this.set}
											/>
										</FormField>
										
										<FormField>
											{client.country === 'germany' ? <ValidationSearch
												onSearch={searchAction__zipcode__pure}
												onKey={r => `${r.zipcode} - ${r.name} ${r.name_extra}`}
												onChange={this.set}
												onResultSelect={this.setZipcode}
												label={t('client.zipcode')}
												placeholder={t('client.zipcode-example')}
												name={"zipcode"}
												value={client.zipcode}
												minSearchLength={2}
												autoComplete='off'
												// value={client.zipcode}
												icon={<AddressIcon/>}
												onValidate={(value) => {
													if (value.trim() === '') return null;
													if (client.country === 'germany' && !/\d{5}/.test(value)) {
														return 'Die PLZ in Deutschland besteht aus 5 Zahlen.';
													}
													return null;
												}}
											/>: <EditField
												icon={<AddressIcon/>}
												editable={editable}
												label={t('client.zipcode')}
												placeholder={t('client.zipcode-example')}
												autoComplete={'off'}
												name={'zipcode'}
												value={client.zipcode}
												onChange={this.set}
											/>}
										</FormField>
										<FormField>
											<EditField
												icon={<AddressIcon/>}
												editable={editable}
												label={t('client.city')}
												placeholder={t('client.city-example')}
												autoComplete={'off'}
												name={'city'}
												value={client.city}
												onChange={this.set}
											/>
										</FormField>
										<FormField>
											<DropdownField
												search
												editable={editable}
												label={t('client.country')}
												name={"country"}
												value={client.country}
												onChange={this.set}
												icon={<AddressIcon/>}
												options={countries}
												placeholder={"Land auswählen"}
											/>
										</FormField>
									</div>
								</Grid.Column>
								<Grid.Column>
									<div style={{maxWidth: WIDTH}}>
										<FormField>
											<ValidationField
												icon={<EmailIcon/>}
												editable={editable}
												label={t('client.email')}
												placeholder={t('client.email-example')}
												autoComplete={'off'}
												name={'email'}
												value={client.email}
												onChange={this.set}
												type="email"
												optional
											/>
										</FormField>
										{/*{(!givenClient || givenClient.email !== client.email) && editable && client.email && <FormField>
											<ValidationField
												icon={<EmailIcon/>}
												editable
												required
												text={t("client.email-confirm")}
												autoComplete="off"
												name={'email_confirm'}
												value={client.email_confirm}
												type={"email"}
												onChange={this.set}
												onValidate={value => {
													if ( !this.props.client && client.email !== value) return  'E-Mail müssen übereinstimmen';
													return null;
												}}
											/>
										</FormField>}*/}
										<FormField>
											<InputWrapper icon={<TelephoneIcon/>} label="Telefon" editable={editable} onChange={this.set} keepRatio>
												<span style={{flexShrink: 0, paddingLeft: 3, paddingRight: 3}}>{/*phonePrefix*/}</span>
												<WrappedInput grow={40} name="telephone_prefix" value={client.telephone_prefix} placeholder="Vorwahl"/>
												<WrappedInput grow={60} name="telephone" value={client.telephone} placeholder="Nummer"/>
											</InputWrapper>
										</FormField>
										<FormField>
											<EditField
												icon={<MobileIcon/>}
												editable={editable}
												label={t('client.mobile')}
												placeholder={t('client.mobile-example')}
												autoComplete={'off'}
												name={'mobile'}
												value={client.mobile}
												onChange={this.set}
												before={<span style={{paddingTop: 2, flexShrink: 0, paddingLeft: 3, paddingRight: 3, alignSelf: 'center', marginLeft: -6, marginRight: 8}}>{/*phonePrefix*/}</span>}
											/>
										</FormField>
										<FormField>
											<label>Serviceberater</label>
											<ConnectedConsultantSelector readOnly={!editable} white scrolling basic fluid client={client} onSelect={this.setConsultant}/>
										</FormField>
									</div>
								</Grid.Column>
							</Grid.Row>
							
						</Grid>
						{onSave && <EasyFlex align={EasyFlex.align.END}>
							<Button type={enableSubmitOnEnter ? 'submit' : 'button'} onClick={this.save} loading={saving} disabled={!this.valid || fetching || saving}
							        positive>{t('actions.save')}</Button>
						</EasyFlex>}
					</ValidationForm>
				</Segment>
			</div>
		);
	}
}

ClientDetail = withSize()(ClientDetail);
ClientDetail = translate()(ClientDetail);