import * as React from "react";
import {connect} from "react-redux";
import {clientAction__change, clientAction__collectOne, clientAction__cursor} from "../../actions/clientActions";
import {ClientList as C} from "../intern/clients/widgets/ClientList";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, ActionIconButton} from "../partials/ActionHeader";
import * as PropTypes from "prop-types";
import {ClientDetail as D} from "../intern/clients/widgets/ClientDetail";
import {Client} from "../../models";
import {addSnackbar} from "../../actions/snackbarActions";
import {Button, Dropdown, Input} from "semantic-ui-react";
import {translate} from "react-i18next";
import {VehicleList} from "../intern/vehicles/widgets/VehicleList";
import {deepMemoize as memoizeOne} from "../../Logic/extensions";
import {VehicleEditWrapper} from "../intern/vehicles/widgets/VehicleEditWrapper";
import VehicleMask from "../intern/vehicles/VehicleMask";
import {CircleNotchLoader} from "../Loaders";
import {LoanCar} from "../intern/vehicles/widgets/LoanCar";


let ClientList = connect(
	state => ({
		clients: state.map.clients
	}),
	dispatch => ({
		onFetch: options => dispatch(clientAction__cursor(options))
	})
)(C);

let ClientDetail = connect(
	null,
	dispatch => ({
		onFetch: (client_id) => dispatch(clientAction__collectOne(client_id)),
		onSave: (client) => dispatch(clientAction__change(client, () => {
			dispatch(addSnackbar('Kunde wurde gespeichert.'));
		}))
	})
)(D);

// const Count = ({count, ...props}) => <span {...props}>{count}</span>;

class ListView extends React.Component {
	static sorts = [
		{key: 'name--asc', text: 'Name aufsteigend'},
		{key: 'name--desc', text: 'Name absteigend'},
	]
	state = {
		count: null,
		search: '',
		sort: 'name--asc'
	}
	
	mapSorts = memoizeOne(
		list => list.map(s => {
			s.value = s.key;
			return s;
		})
	);
	
	setOrder = (e, {value: sort}) => this.setState({sort});
	
	updateSearch = e => {
		if (e.which === 13) {
			this.setState({search: e.target.value.trim()});
		}
	}
	
	render() {
		let {onBack, onDetail, onVehicle, t} = this.props;
		const {count} = this.state;
		const options = this.mapSorts(ListView.sorts);
		return <div style={{paddingBottom: 40}}>
			<ActionHeader alignment={'space-between'}>
				<ActionHeaderGroup>
					<ActionBackButton onBack={onBack}/>
					<ActionHeading>{t('clients.title_count', {count: count || 0})}</ActionHeading>
				</ActionHeaderGroup>
				<ActionHeaderGroup ulStyle={{marginRight: 20}}>
						<Dropdown
							style={{marginRight: 10}}
							selection
							value={this.state.sort}
							options={options}
							onChange={this.setOrder}
						/>
						<Input onKeyUp={this.updateSearch} placeholder={'Name | Kennzeichen | ...'} icon={'search'} iconPosition={'left'}/>
				</ActionHeaderGroup>
			</ActionHeader>
			
			<ClientList itemsPerPage={50} sort={this.state.sort} onClientCount={count => this.setState({count})} searchText={this.state.search} onShowVehicles={onVehicle} onShowClient={onDetail}/>
		</div>;
	}
}
ListView.propTypes = {onBack: PropTypes.func, onDetail: PropTypes.func};
ListView = translate()(ListView);


class DetailView extends React.Component {
	state = {
		client: null
	};
	render() {
		let client = this.state.client;
		if (client) {
			client = new Client(client);
		}
		return <div>
			<ActionHeader alignment={'space-between'}>
				<ActionHeaderGroup>
					<ActionIconButton onAction={this.props.onBack} icon={'chevron-left'}/>
					<ActionHeading>{client ? <span style={{display: 'flex', alignItems: 'center'}}><client.Icon style={{marginRight: 5}}/> {client.contact_full}</span> : 'Kunde'} ({this.props.client_id})</ActionHeading>
				</ActionHeaderGroup>
				<ActionHeaderGroup ulStyle={{marginRight: 20}}>
					<Button icon={'car'} onClick={this.props.onVehicles}/>
				</ActionHeaderGroup>
			</ActionHeader>
			<ClientDetail client_id={this.props.client_id} editable={true} onClient={client => this.setState({client})}/>
		</div>;
	}
}


class CarView extends React.Component {
	render() {
		return(
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionIconButton onAction={this.props.onBack} icon={'chevron-left'}/>
						<ActionHeading>Fahrzeuge</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{this.props.onViewClients && <Button icon={'users'} onClick={this.props.onViewClients}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<VehicleList onSelect={this.props.onShowVehicle} headerProps={{top: 0}} client_id={this.props.client_id}/>
			</div>
		);
	}
}

let CarDetail = VehicleEditWrapper(VehicleMask, true, <CircleNotchLoader/>);
let ConnectedLoanCar = connect(
	null,
	dispatch => ({
		onNotification: (message, color) => dispatch(addSnackbar(message, color))
	})
)(LoanCar);

class CarDetailView extends React.Component {
	render() {
		return(
			<div style={{paddingBottom: 50}}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionIconButton onAction={this.props.onBack} icon={'chevron-left'}/>
						<ActionHeading>Fahrzeug {this.props.vehicle_id}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{this.props.onViewClients && <Button color={'black'} icon={'users'} onClick={this.props.onViewClients}/>}
						{this.props.onViewVehicles && <Button color={'black'} icon={'car'} onClick={this.props.onViewVehicles}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<div style={{paddingLeft: 20, paddingRight: 20}}>
					<CarDetail vehicle_id={this.props.vehicle_id} mayRead mayCreate mayChange/>
				</div>
				<div style={{marginTop: 40, paddingLeft: 20, paddingRight: 20}}>
					<ConnectedLoanCar vehicle_id={this.props.vehicle_id}/>
				</div>
			</div>
		);
	}
}


export class ClientTest extends React.Component {
	state = {
		view: 'list',
		// client_id: 3360,
		client_id: 501,
		// vehicle_id: 4465
		vehicle_id: 10024
	};
	
	view = (view = 'list', extra = {}) => () => this.setState({view, ...extra});
	onDetail = (client) => this.view('detail', {client_id: client.client_id || client})();
	onVehicles = (client) => this.view('vehicles', {client_id: client.client_id || client})();
	
	onVehicle = (vehicle) => this.view('vehicle-detail', {vehicle_id: vehicle.vehicle_id || vehicle})();
	
	render() {
		switch (this.state.view) {
			case 'detail':
				return <DetailView client_id={this.state.client_id} onBack={this.view()} onVehicles={this.view('vehicles')}/>;
			case 'vehicles':
				return <CarView client_id={this.state.client_id} onBack={this.view('detail')} onViewClients={this.view()} onShowVehicle={this.onVehicle}/>;
			case 'vehicle-detail':
				return <CarDetailView vehicle_id={this.state.vehicle_id} client_id={this.state.client_id} onBack={this.view('vehicles')} onViewClients={this.view()} onViewVehicles={this.view('vehicles')}/>;
			default:
				return <ListView onVehicle={this.onVehicles} onDetail={this.onDetail} onBack={this.view()}/>;
		}
	}
}