import {RESET_ACTION, RESOURCE_ACTION, RESOURCE_TYPE_ACTION} from "../actions/types";
import {updateCache, updateCacheData} from "ith-redux-helpers";
import {blackOrWhite, array_unique} from "../Logic/extensions";
import {CACHE_RESOURCE_KFZ, CACHE_RESOURCE_LIST, CACHE_RESOURCE_MODEL, CACHE_RESOURCE_WORKERS} from "../actions";
import {uniqBy, omit, merge, mergeWith, isArray} from "lodash";

const initState = {
	list: {},
	kfz: {},
	models: {},
	types: [],
	worker: {},
	workers: {},
	cache: {},
	loaded: {
		list: false,
		models: false,
		types: false,
		worker: false,
		workers: false
	}
};

// const _merge = (left, right) => {
// 	let key;
// 	for( key in right ) {
// 		if ( left[key] ) {
// 			left[key] = uniqBy([...right[key], ...left[key]], res => res.resource_id).sort((a,b) => a.resource_id - b.resource_id);
// 		} else {
// 			left[key] = right[key];
// 		}
// 	}
// 	return left;
// };

const _remove = (models, ids, resource_id) => {
	let id;
	for (id of ids) {
		if (models[id]) {
			models[id] = models[id].filter(r => r.resource_id !== resource_id)
		}
	}
	return models;
};

export default (state = initState, action) => {
	switch (action.type) {
		case RESET_ACTION:
			return initState;
		case RESOURCE_ACTION.LOAD:
			return {
				...state,
				list: action.list,
				cache: {
					...state.cache,
					...updateCache(CACHE_RESOURCE_LIST)
				},
				loaded: {
					...state.loaded,
					list: true
				}
			};
		case RESOURCE_ACTION.ADD:
			return {
				...state,
				// types: [...state.types],
				list: {...state.list, [action.resource.resource_id]: action.resource},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_LIST)(state.cache)
				}
			};
		
			// action.resource_id = action.resource.workers_id;
		case RESOURCE_ACTION.UPDATE:
			return {
				...state,
				list: {...state.list, [action.resource.resource_id]: action.resource},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_LIST)(state.cache)
				}
			};
		case RESOURCE_ACTION.REMOVE:
			// const {['' + action.resource_id]: _, ...list} = state.list;
			return {
				...state,
				list: omit(state.list, [action.id]),
				cache: {
					...updateCacheData(CACHE_RESOURCE_LIST)(state.cache)
				}
			};
		case RESOURCE_ACTION.WORKERS_LOAD:
			return {
				...state,
				workers: action.list,
				cache: {
					...state.cache,
					...updateCache(CACHE_RESOURCE_WORKERS)
				},
				loaded: {
					...state.loaded,
					workers: true
				}
				// ...updateCache('workers')
			};
		case RESOURCE_ACTION.WORKERS_ADD:
			return {
				...state,
				workers: {
					...state.workers,
					...action.list
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_WORKERS)(state.cache)
				}
			};
		case RESOURCE_ACTION.WORKERS_DELETE:
			return {
				...state,
				workers: {
					...state.workers,
					[action.workers_id]: omit(state.workers[action.workers_id], [action.resource_id])
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_WORKERS)(state.cache)
				}
			};
		case RESOURCE_TYPE_ACTION.LOAD:
			return {
				...state,
				list: {...state.list},
				types: action.list,
				...updateCache()
			};
		
		case RESOURCE_TYPE_ACTION.SAVE:
			if (state.types.filter(value => value === action.resource).length) {
				return state;
			}
			return {
				...state,
				list: {...state.list},
				types: array_unique([...state.types, action.resource], t => t.resource_id)
			};
		case RESOURCE_TYPE_ACTION.REMOVE:
			return {
				...state,
				list: {...state.list},
				types: state.types.filter(t => t.resource_id !== action.resource.resource_id)
			};
		case RESOURCE_ACTION.SAVE_TIMES:
			let {workers_id, resources} = action;
			resources = resources.map(r => ({
				...r,
				// id: r.id,
				// title: '',
				resourceId: ''+r.resource_id,
				start: new Date(r.start),
				end: new Date(r.end),
				fontColor: blackOrWhite(r.color)
			}));
			return {...state, worker: {...state.worker, [workers_id]: resources}};
		case RESOURCE_ACTION.REMOVE_ONE_TIME:
			const {workers_id: wID, resource_id: rID} = action;
			try {
				return {...state, worker: {...state.worker, [wID]: state.worker[wID].filter(r => r.id !== rID)}};
			} catch(e){
				console.error('ERROR', e);
				return state;
			}
		case RESOURCE_ACTION.UPDATE_TIME:
			const {workers_id: uw_ID, id: ur_ID} = action.resource;
			action.resource.start = new Date(action.resource.start);
			action.resource.end = new Date(action.resource.end);
			try {
				return {...state, worker: {...state.worker, [uw_ID]: state.worker[uw_ID].map(r => r.id === ur_ID ? {...r, ...action.resource} : r)}};
			} catch (e) {
				console.error(RESOURCE_ACTION.UPDATE_TIME, e);
				return state;
			}
		case RESOURCE_ACTION.REMOVE_RESOURCE_TIMES:
			const {workers_id: dw_ID, resource_ids} = action;
			try {
				let newList = state.worker[dw_ID];
				resource_ids.forEach(resourceId =>
					newList = newList.filter(r => r.resource_id !== resourceId)
				);
				return {
					...state,
					worker: {
						...state.worker,
						[dw_ID]: newList
					}
				};
			} catch(e) {
				console.error(e);
				return state;
			}
		case RESOURCE_ACTION.RESET:
			return initState;
		case RESOURCE_ACTION.KFZ_SET:
			return {
				...state,
				kfz: action.kfz,
				cache: {
					...state.cache,
					...updateCache(CACHE_RESOURCE_KFZ)
				},
				loaded: {
					...state.loaded,
					kfz: true
				}
			};
		case RESOURCE_ACTION.KFZ_UPDATE:
			return {
				...state,
				kfz: {
					...state.kfz,
					...action.kfz
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_KFZ)(state.cache)
				}
			};
		case RESOURCE_ACTION.KFZ_UNSET:
			return {
				...state,
				kfz: {
					...state.kfz,
					[action.service_id]: {
						...state.kfz[action.service_id],
						[action.kfz_id]: state.kfz[action.service_id][action.kfz_id].filter(m => m.resource_id !== action.resource_id)
					}
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_KFZ)(state.cache)
				}
			};
		case RESOURCE_ACTION.MODEL_SET:
			return {
				...state,
				models: action.models,
				cache: {
					...state.cache,
					...updateCache(CACHE_RESOURCE_MODEL)
				},
				loaded: {
					...state.loaded,
					models: true
				}
			};
		case RESOURCE_ACTION.MODEL_UPDATE:
			return {
				...state,
				models: merge(state.models, action.models),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_MODEL)(state.cache)
				}
			};
		case RESOURCE_ACTION.MODEL_UNSET:
			return {
				...state,
				models: {
					...state.models,
					[action.service_id]: {
						...state.models[action.service_id],
						[action.model_id]: state.models[action.service_id][action.model_id].filter(res => res.resource_id !== action.resource_id)
					}
					
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_MODEL)(state.cache)
				}
			};
		case RESOURCE_ACTION.MODEL_UPDATE_MASS:
			return {
				...state,
				models: mergeWith(state.models, action.models, (obj, src) => {
					if (isArray(obj)) {
						return uniqBy([...src, ...obj], m => m.resource_id);
					}
					// return merge(src, obj);
				}),
				// models: {
				// 	...state.models,
				// 	[action.service_id]: {
				// 		..._merge(state.models[action.service_id], action.models[action.service_id])
				// 	}
				// },
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_MODEL)(state.cache)
				}
			};
		case RESOURCE_ACTION.MODEL_UNSET_MASS:
			console.error("UNSETMASS", "service", action.service_id);
			return {
				...state,
				models: {
					...state.models,
					[action.service_id]: {
						..._remove(state.models[action.service_id], action.model_ids, action.resource_id)
					}
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_RESOURCE_MODEL)(state.cache)
				}
			};
		default:
			return state;
	}
};