import * as React from 'react';
import {EasyFlex} from "../../../partials/ActionHeader";
import {Button, Label, Popup, Segment} from "semantic-ui-react";
import {Client, Vehicle} from "../../../../models";
import ClientPopupInfo from "../../clients/ClientPopupInfo";
import VehiclePopupInfo from "../../vehicles/VehiclePopupInfo";
import {IconBuild, IconCar} from "../../../../Logic/icons";
import round from "round-precision";
import {translate} from "react-i18next";

const CompactLine = ({children, style, ...props}) => {
	return <EasyFlex style={{marginBottom: 15, marginRight: 25, ...style}} valign={EasyFlex.valign.CENTER} {...props}>{children}</EasyFlex>
};

const CompactContent = ({icon, children}) => {
	const Icon = icon;
	return <React.Fragment>
		<Icon style={{marginRight: 10}}/> {children}
	</React.Fragment>;
};

const CompactPopup = ({children, ...props}) => <Popup {...props} trigger={<EasyFlex valign={EasyFlex.valign.CENTER}>{children}</EasyFlex>}/>

export let CompactView = ({client, vehicle, services, worktime, style, reset, t, tReady, i18n, ...props}) => {
	const c = client instanceof Client ? client : new Client(client);
	const v = vehicle instanceof Vehicle ? vehicle : new Vehicle(vehicle);
	return (
		<EasyFlex valign={EasyFlex.valign.START} align={EasyFlex.align.SPACE_BETWEEN} style={{marginBottom: 30, ...style}} {...props}>
			<Segment as={EasyFlex} style={{paddingTop: 30}} grow={10} valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_BETWEEN} wrap>
				{/*<EasyFlex grow={10} valign={EasyFlex.valign.CENTER} align={EasyFlex.align.SPACE_BETWEEN} wrap>*/}
				<CompactLine>
					<CompactPopup inverted content={<ClientPopupInfo client={client} inverted/>}>
						<CompactContent icon={c.Icon}><span style={{marginTop: 3}}>{c.contact}</span></CompactContent>
					</CompactPopup>
				</CompactLine>
				<CompactLine>
					<CompactPopup inverted content={<VehiclePopupInfo vehicle={vehicle} inverted/>}>
						<CompactContent icon={IconCar}><span style={{marginTop: 3}}>{v.name}</span></CompactContent>
					</CompactPopup>
				</CompactLine>
				<CompactLine>
					<CompactContent icon={IconBuild}>
						<span><Label circular>{services.length}</Label> Service</span> <Label style={{marginLeft: 8}}>{round(worktime / 3600, 1)} Std.</Label>
					</CompactContent>
				</CompactLine>
				{/*</EasyFlex>*/}
			</Segment>
			<EasyFlex grow={0} shrink={0} style={{paddingLeft: 10}}>
				<Button icon={'expand'} basic onClick={reset}/>
			</EasyFlex>
		</EasyFlex>
	);
};

CompactView = translate()(CompactView);