import * as React from 'react';
import BigCalendar from "react-big-calendar";
import {moment, toISO} from "../../../../Logic/Moment";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {TimeTrans} from "../../../../Tools";
import {SIGNS} from "../../../../Logic/constants";
import cn from "classnames";
import {MobileAwarePopup} from "../../../../components/partials/MiniComponents";
import {Button, Dimmer, Icon, Loader} from "semantic-ui-react";
import {translate} from "react-i18next";
import {deepMemoize as memoizeOne} from "../../../../Logic/extensions";
import PropTypes from "prop-types";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {workerCall__getAll} from "../../../../actions/workerActions";
import {resourceCall__fetchWorkers, resourceCall__loadOfHouse} from "../../../../actions/resourceActions";
import {scheduleCall__dynamicAgenda, scheduleCall__dynamicDay, scheduleCall__dynamicMonth, scheduleCall__dynamicWeek} from "../../../../actions/scheduleActions";
import {endOfDay, endOfHour, getHours, isSameDay, setHours, startOfDay, startOfHour} from "date-fns";
import {get} from "lodash";
import {blackOrWhite, trueNull} from "../../../../Logic/extensions";
import {SizeMe} from "react-sizeme";
import {navigate} from 'react-big-calendar/lib/utils/constants';
import {DefaultDatePicker, withMobileAwareness} from "../../../../Tools/DatePicker";

BigCalendar.momentLocalizer(moment);

const CustomEventPopup = ({title, event}) => (
	<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER}>
		<strong>{title}</strong>
		<span><TimeTrans type={'time-full'} value={new Date(event.start_time)}/></span>
		<span style={{color: '#888'}}>{SIGNS.DOTS_VERTICAL}</span>
		<span><TimeTrans type={'time-full'} value={new Date(event.end_time)}/></span>
	</EasyFlex>
);

const CustomEventRange = ({event}) => (
	<span className={cn('custom-event-range')}>
		<TimeTrans type={'time'} value={new Date(event.start_time)}/>
		-
		<TimeTrans type={'time'} value={new Date(event.end_time)}/>
	</span>
);

let CustomEvent = ({event, title, editMode, view, t}) => {
	const selected = event.selected;
	return (
		<MobileAwarePopup disabled={editMode || view === 'agenda'} flowing position={'top center'} basic={false} modal inverted content={<CustomEventPopup event={event} title={title}/>}>
			<div className={'custom-event-content'}>
				<CustomEventRange event={event}/>
				<span className={'custom-event-title'} data-month={t('...', 'Arbeitszeit')}>{title}</span>
				{editMode && <div className={cn('custom-event-edit-holder', {selected})}><Icon size={'large'} name={selected ? 'check circle outline' : 'circle outline'} className={cn('custom-event-editable', {selected})}/></div>}
			</div>
		</MobileAwarePopup>
	);
};
CustomEvent = translate()(CustomEvent);

const customEventHoc = memoizeOne((props) => (Component) => (defaultProps) => <Component {...props} {...defaultProps}/>);


const CustomDate = withMobileAwareness(DefaultDatePicker);

class DateButton extends React.Component {
	static propTypes = {
		value: PropTypes.any,
		onClick: PropTypes.any
	}
	render() {
		let {value, onClick, ...props} = this.props;
		return <Button {...props} onClick={onClick} children={value}/>;
	}
}


class CustomToolbar extends React.Component {
	render() {
		let {
			messages,
			label,
			date
		} = this.props
		return (
			<div className="rbc-toolbar">
				<Button.Group basic>
					<Button onClick={this.navigate(navigate.PREVIOUS)}>{messages.previous}</Button>
					<Button onClick={this.navigate(navigate.NEXT)}>{messages.next}</Button>
					<Button active={isSameDay(date, new Date())} onClick={this.navigate(navigate.TODAY)}>{messages.today}</Button>
				</Button.Group>
				<CustomDate
					customInput={<DateButton basic style={{marginLeft: 8}}/>}
					selected={date}
					onChange={this.toDate}
					style={{marginLeft: 8}}
					// materialProps={{style: {width: 100}}}
				/>
				<span className="rbc-toolbar-label">{label}</span>
		        <Button.Group basic>
			        {this.viewNamesGroup(messages)}
		        </Button.Group>
			</div>
		)
	}
	
	navigate = action => () => {
		this.props.onNavigate(action)
	}
	
	toDate = date => this.props.onNavigate(null, date);
	
	view = view => () => {
		this.props.onViewChange(view)
	}
	
	viewNamesGroup(messages) {
		let viewNames = this.props.views
		const view = this.props.view
		
		if (viewNames.length > 1) {
			return viewNames.map(name => (
				<Button
					type="button"
					key={name}
					active={view === name}
					className={cn({'rbc-active': view === name})}
					onClick={this.view(name)}
				>
					{messages[name]}
				</Button>
			))
		}
	}
}


export class DynamicWorkerSchedule extends React.Component {
	static propTypes = {
		workerId: PropTypes.number,
		resourceId: PropTypes.number,
		onNotification: PropTypes.func,
		onLoadWorkers: PropTypes.func,
		onLoadResources: PropTypes.func,
		onLoadWorkerResources: PropTypes.func,
		onProvideWorkerList: PropTypes.func,
		onProvideWorkerResources: PropTypes.func,
		onProvideView: PropTypes.func,
		onProvideDate: PropTypes.func,
		isLoadingCalendar: PropTypes.func,
		isLoadingWorkers: PropTypes.func,
		isEditable: PropTypes.func,
		isResourceConsidered: PropTypes.func,
		onLoadWeek: PropTypes.func,
		onLoadDay: PropTypes.func,
		onLoadAgenda: PropTypes.func,
		onLoadMonth: PropTypes.func,
		hideDateLabel: PropTypes.bool,
		editMode: PropTypes.bool,
		onProvideSelection: PropTypes.func,
		methodsProvider: PropTypes.func
	};
	static defaultProps = {
		onNotification: dispatchSnack,
		onLoadWorkers: workerCall__getAll,
		onLoadResources: resourceCall__loadOfHouse,
		onLoadWorkerResources: resourceCall__fetchWorkers,
		onProvideWorkerList: list => {
		},
		onProvideWorkerResources: list => {
		},
		onProvideSelection: ids => {
		},
		onProvideDate: date => {
		},
		onProvideView: view => {
		},
		onLoadWeek: scheduleCall__dynamicWeek,
		onLoadDay: scheduleCall__dynamicDay,
		onLoadAgenda: scheduleCall__dynamicAgenda,
		onLoadMonth: scheduleCall__dynamicMonth,
		isLoadingCalendar: value => {
		},
		isLoadingWorkers: value => {
		},
		isEditable: value => {
		},
		isResourceConsidered: value => {
		},
		editMode: false,
		hideDateLabel: false,
		methodsProvider: data => {}
	};
	
	state = {
		loading_workers: false,
		loading_resources: false,
		loading_worker_resources: false,
		loading_view: false,
		view: 'week',
		day: new Date(),
		events: [],
		workers: {},
		resources: {},
		worker_resources: {},
		selections: {}
	};
	resourceList = null;
	
	
	componentDidMount() {
		this.loadMeta();
		this.props.methodsProvider({
			refresh: () => this._updateView( this.state.view, this.props.workerId, toISO(this.state.day))
		});
	}
	
	componentWillUnmount() {
		this.props.methodsProvider(null);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.resourceList = this.getResourceList(this.state.resources, this.state.worker_resources, this.props.workerId)
		this.updateView(this.state.view, this.props.workerId, toISO(this.state.day));
		this.handleEditMode(this.props.editMode, this.props.resourceId);
		this.handleSelectionChange(this.state.selections);
		this.handleEditable(this.state.view);
		this.handleResourceAvailable(this.state.view);
		this.handleDate(this.state.day);
		this.handleView(this.state.view);
	}
	
	handleEditMode = memoizeOne(
		editMode => this.setState({selections: {}})
	);
	handleSelectionChange = memoizeOne(
		selections => {
			this.props.onProvideSelection(Object.keys(selections).filter(v => selections[v]))
		}
	);
	handleEditable = memoizeOne(
		view => this.props.isEditable(view === 'week' || view === 'day')
	);
	handleResourceAvailable = memoizeOne(
		view => this.props.isResourceConsidered(view === 'week' || view === 'day')
	);
	handleDate = memoizeOne(
		date => this.props.onProvideDate(date)
	);
	handleView = memoizeOne(
		view => this.props.onProvideView(view)
	);
	
	loadWorkers = async () => {
		const {onNotification, onLoadWorkers, isLoadingWorkers} = this.props;
		try {
			this.setState({loading_workers: true});
			isLoadingWorkers(true);
			const workers = await onLoadWorkers();
			this.setState({workers}, () => this.getRelatedWorkers(this.state.workers, this.state.worker_resources));
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_workers: false});
			isLoadingWorkers(false);
		}
	}
	
	loadResources = async () => {
		const {onNotification, onLoadResources} = this.props;
		try {
			this.setState({loading_resources: true});
			const resources = await onLoadResources();
			this.setState({resources});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_resources: false});
		}
	};
	
	loadWorkerResources = async () => {
		const {onNotification, onLoadWorkerResources} = this.props;
		try {
			this.setState({loading_worker_resources: true});
			const worker_resources = await onLoadWorkerResources();
			this.setState({worker_resources}, () => this.getRelatedWorkers(this.state.workers, this.state.worker_resources));
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_worker_resources: false});
		}
	};
	
	loadWeek = async (workerId, day = null) => {
		const {onLoadWeek, onNotification, isLoadingCalendar} = this.props;
		try {
			this.setState({loading_view: true, events: [], selections: {}});
			isLoadingCalendar(true);
			const events = await onLoadWeek(workerId, day);
			this.setState({events});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_view: false});
			isLoadingCalendar(false);
		}
	};
	
	loadDay = async (workerId, day = null) => {
		const {onLoadDay, onNotification, isLoadingCalendar} = this.props;
		try {
			this.setState({loading_view: true, events: [], selections: {}});
			isLoadingCalendar(true);
			const events = await onLoadDay(workerId, day);
			this.setState({events});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_view: false});
			isLoadingCalendar(false);
		}
	};
	
	loadAgenda = async (workerId, day = null) => {
		const {onLoadAgenda, onNotification, isLoadingCalendar} = this.props;
		try {
			this.setState({loading_view: true, events: [], selections: {}});
			isLoadingCalendar(true);
			const events = await onLoadAgenda(workerId, day);
			this.setState({events});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_view: false});
			isLoadingCalendar(false);
		}
	};
	
	loadMonth = async (workerId, day = null) => {
		const {onLoadMonth, onNotification, isLoadingCalendar} = this.props;
		try {
			this.setState({loading_view: true, events: [], selections: {}});
			isLoadingCalendar(true);
			const events = await onLoadMonth(workerId, day);
			this.setState({events});
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading_view: false});
			isLoadingCalendar(false);
		}
	};
	
	_updateView = (view, workerId, day) => {
		if (!workerId) {
			console.warn('no worker id given');
			return;
		}
		switch (view) {
			case 'week':
				this.loadWeek(workerId, day);
				break;
			case 'day':
				this.loadDay(workerId, day);
				break;
			case 'agenda':
				this.loadAgenda(workerId, day);
				break;
			case 'month':
				this.loadMonth(workerId, day);
				break;
			default:
				console.warn('View', view, 'is not applicable');
				this.setState({events: []})
		}
	};
	
	updateView = memoizeOne(this._updateView);
	
	loadMeta = () => {
		this.loadWorkers();
		this.loadResources();
		this.loadWorkerResources();
	};
	
	getDefs = memoizeOne(
		language => {
			const defs = this.props.i18n.getResource(language.match(/\w+/), 'translations', 'big-calendar.defs') || {};
			defs.showMore = (count) => this.props.i18n.t('big-calendar.showMore', {count});
			return defs;
		}
	);
	
	get formats() {
		return {
			dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture)
		};
	}
	
	getResourceList = memoizeOne(
		(map, workerMap, workerId) => {
			const list = workerId ? Object.values(map).filter(r => workerId in workerMap && r.resource_id in workerMap[workerId]) : null
			this.props.onProvideWorkerResources(list);
			return list;
		}
	);
	
	getFilteredResourceList = memoizeOne(
		(list, resourceId) => list && list.filter(r => !resourceId || r.resource_id === resourceId)
	);
	
	getRelatedWorkers = memoizeOne(
		(workerMap, workerResourceMap) => {
			const list = Object.values(workerMap).filter(w => w.workers_id in workerResourceMap);
			this.props.onProvideWorkerList(list);
			return list;
		}
	);
	
	minMax = memoizeOne(
		list => {
			let min = 23;
			let max = 0;
			let dateMin = startOfDay(new Date());
			let dateMax = endOfDay(new Date());
			for (const event of list) {
				const lowDate = new Date(event.start_time);
				const highDate = new Date(event.end_time);
				const low = getHours(lowDate);
				const high = getHours(highDate);
				if (low < min) {
					min = low;
					dateMin = startOfHour(setHours(new Date(), low));
				}
				if (high > max) {
					max = high;
					dateMax = endOfHour(setHours(new Date(), high));
				}
			}
			return [dateMin, dateMax];
		}
	);
	
	mapEventSelection = memoizeOne(
		(list, selections) => {
			let mapped = [];
			for (const event of list) {
				mapped.push({
					...event,
					selected: Boolean(selections[event.schedule_id])
				});
			}
			return mapped;
		}
	);
	
	eventProp = memoizeOne(
		resources => (event) => {
			const color = get(resources, [event.resource_id, 'color'], 'darkgray');
			return {
				style: {
					color: blackOrWhite(color),
					backgroundColor: color,
					borderRadius: 3,
					borderColor: event.selected ? 'red' : null,
					boxShadow: event.selected ? '0 0 3px 1px black' : null
					// border: 0
				}
			};
		}
	);
	
	selectEvent = memoizeOne((editMode) => trueNull(editMode) && (event => this.setState(state => ({
		...state,
		selections: {
			...state.selections,
			[event.schedule_id]: !state.selections[event.schedule_id]
		}
	}))));
	
	startAccessor = event => {
		return new Date(event.start_time);
	}
	endAccessor = event => {
		return new Date(event.end_time);
	}
	titleAccessor = memoizeOne(view => event => {
		return get(this.state.resources[event.resource_id], 'name', get(event, 'title', view === 'month' ? 'Arbeitszeit' : '[Event]'));
	});
	
	
	render() {
		const {i18n, hideDateLabel, editMode, workerId} = this.props;
		const {view, events, resources, loading_view, selections} = this.state;
		const calendarDefs = this.getDefs(i18n.language);
		const resourceList = this.getFilteredResourceList(this.resourceList, this.props.resourceId);
		const [minDate, maxDate] = this.minMax(events);
		const eventList = this.mapEventSelection(events, selections);
		// let resourceList = this.getResourceList(resources, worker_resources, workerId);
		// resourceList = this.getFilteredResourceList(resourceList, resourceId);
		return (
			<div>
				{/*<pre>{JSON.stringify({minDate, maxDate}, null, 2)}</pre>*/}
				<SizeMe>{size =>
					<div>
						<Dimmer active={loading_view} inverted>
							<Loader/>
						</Dimmer>
						<BigCalendar
							length={14}
							toolbar={!!workerId}
							className={cn('worker-schedule-calendar', {'hide-date-label': hideDateLabel, 'edit-mode': editMode}, `view-${view}`)}
							events={eventList}
							defaultView={view}
							culture={'de'}
							defaultDate={new Date()}
							messages={calendarDefs}
							formats={this.formats}
							onView={(view) => this.setState({view})}
							onNavigate={(day, view) => this.setState({day, view})}
							resources={resourceList}
							resourceIdAccessor={'resource_id'}
							resourceTitleAccessor={'name'}
							startAccessor={this.startAccessor}
							endAccessor={this.endAccessor}
							titleAccessor={this.titleAccessor(view)}
							resourceAccessor={'resource_id'}
							eventPropGetter={this.eventProp(resources)}
							min={minDate}
							max={maxDate}
							onSelectEvent={this.selectEvent(editMode)}
							components={{
								event: customEventHoc({editMode, view})(CustomEvent),
								toolbar: CustomToolbar
							}}
						/>
					</div>
				}</SizeMe>
			</div>
		);
	}
}

DynamicWorkerSchedule = translate()(DynamicWorkerSchedule);