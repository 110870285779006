import * as React from "react";
import PropTypes from "prop-types";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex, Flex, OptionHeader, Space} from "../../partials/ActionHeader";
import {IconMenu, MiniMenu, MiniMenuItem} from "../../../Logic/MiniMenu";
import {Divider, IconButton} from "material-ui";
import {Button, Dropdown, Icon, Label, Modal, Popup, Segment, Statistic} from "semantic-ui-react";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../Tools/RightsProvider";
import {translate} from "react-i18next";
import constants, {COLORS, laneAccess, SECTION} from "../../../Logic/constants";
import NoAccess from "../../NoAccess";
import {IconBack, IconBuild, IconCar, IconFilterList, IconLane, IconNoteAdd, IconRefresh, IconSearch, IconTimeline} from "../../../Logic/icons";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {orderAction__get} from "../../../actions/orderActions";
import {connect} from "react-redux";
import {isBoolean, isFunction, mapValues, values} from "lodash";
import moment from "../../../Logic/Moment";
import {push} from "connected-react-router";
import KeyHandler, {KEYUP} from "react-key-handler";
import OrderSearch from "../../../cointainer/intern/orders/OrderSearch";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {FullscreenPortal, OrderSearchDialog, OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import LoanCarSchedule from "../../../cointainer/intern/vehicles/LoanCarSchedule";
import {addSnackbar} from "../../../actions/snackbarActions";
import {deepMemoize as memoize, falseNull, trueNull} from "../../../Logic/extensions";
import {PROC_ORDER, PROC_ORDER_DAY_LIST} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {OrderSchedule} from "../../../cointainer/intern/orders/OrderScheduleV2";
import {NativeDayPicker as DayPicker} from "../../partials/DayPicker";
import {KeyModifier} from "../../../Logic/KeyHandler";
import cn from "classnames";
import {OrderStateFilter} from "./OrderStateFilter";
import {sessionStorageAction__resetOrderFilterState, sessionStorageAction__setOrderConsultantSelection, sessionStorageAction__setOrderFilterState} from "../../../actions/sessionStorageActions";
import {ConnectedOrderAppointmentFinder} from "./finder/OrderAppointmentFinder";
import {workerCall__getConsultants} from "../../../actions/workerActions";
import {sortAsc} from "ith-sort/source";
import {Worker} from "../../../models";


// let WorkLoad = ({workload}) => `(${workload} %)`;
let WorkLoad = ({workload, open = true}) => trueNull(open) && (
	<Popup content={'Auslastung'} trigger={
		<Statistic size={'mini'} horizontal style={{transform: 'scale(0.7)'}}>
			<Statistic.Value>
				{workload}<Icon name='percent' size={'small'}/>
			</Statistic.Value>
		</Statistic>
	}/>

);
WorkLoad = connect(state => ({workload: state.work.workload}))(WorkLoad);

const RangeTitle = ({from, to, loading}) => {
	if (!loading && Boolean(from && to)) {
		from = moment(from);
		to = moment(to);
		const now = moment();
		const diff = to.diff(from, 'day');
		if (now.isSame(from, 'day')) {
			if (from.isSame(to, 'day')) {
				return <strong>Heute</strong>;
			}
			from = 'Heute';
		} else {
			from = from.format(constants.DATE_FORMAT);
		}
		if (diff === 1) {
			to = 'Morgen';
		} else if (diff === 2) {
			to = 'Übermorgen';
		} else {
			to = to.format(constants.DATE_FORMAT);
		}
		return <span>von <strong>{from}</strong> bis <strong>{to}</strong></span>;
	}
	return <Icon name={'spinner'} loading/>;
};
RangeTitle.propTypes = {
	from: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
	loading: PropTypes.bool
};
RangeTitle.defaultProps = {
	loading: false
};

const OrderFilters = connect(
	state => ({
		states: state.session.order_state_filters
	}),
	dispatch => ({
		onUpdateAll: (value) => dispatch(sessionStorageAction__setOrderFilterState(value)),
		onReset: () => dispatch(sessionStorageAction__resetOrderFilterState)
	})
)(OrderStateFilter);

class OrderMainView extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		orderMap: PropTypes.object.isRequired,
		serviceMap: PropTypes.object.isRequired,
		onRefresh: PropTypes.func,
		loading: PropTypes.bool,
		data: PropTypes.shape({
			orders: PropTypes.object.isRequired,
			services: PropTypes.object.isRequired,
			resources: PropTypes.object.isRequired,
			service2resources: PropTypes.object.isRequired,
			order2services: PropTypes.object.isRequired,
			order_vehicles: PropTypes.object.isRequired,
			clients: PropTypes.object.isRequired,
		}),
		onLoadConsultants: PropTypes.func
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		orderMap: {},
		onLoadConsultants: workerCall__getConsultants
	};
	state = {
		day: new Date(),
		createDialog: false,
		searchDialog: false,
		orderView: 0,
		minimenu: false,
		loancar_schedule: false,
		showFilters: null,
		showDateRangePicker: false,
		showCalendar: false,
		ranges: {
			starts: new Date(),
			ends: new Date()
		},
		filters: {
			released_at: 0,
			delivered_at: 0,
			progress: 0,
			finished_at: 0,
			fetched_at: 0
		},
		tmpFilters: {
			released_at: 0,
			delivered_at: 0,
			progress: 0,
			finished_at: 0,
			fetched_at: 0
		},
		orderCount: 0,
		totalCount: 0,
		consultants: [],
		consultant: null // depreciated in favor of props.consultantSelection
	};
	
	componentDidMount() {
		// noinspection JSIgnoredPromiseFromCall
		this.loadConsultants();
	}
	
	schedule = null;
	filterButton = () => {
		console.error('hehehe filter')
	};
	
	showCreateDialog = (createDialog) => (e) => {
		e && e.nativeEvent && e.nativeEvent.stopImmediatePropagation();
		this.setState({createDialog});
	};
	showSearchDialog = (searchDialog) => () => this.setState({searchDialog});
	setOrderView = (orderView) => () => this.setState({orderView});
	showLoancarSchedule = (loancar_schedule) => () => this.setState({loancar_schedule});
	miniMenu = minimenu => () => this.setState({minimenu});
	updateOrderCount = (orderCount, totalCount) => this.setState({orderCount, totalCount});
	
	reset = () => this.setState(state => ({tmpFilters: {...state.filters}}));
	clear = () => {
		const data = mapValues(this.state.tmpFilters, () => 0);
		this.setState({
			tmpFilters: data
		});
	};
	
	loadConsultants = async () => {
		const {onLoadConsultants} = this.props;
		try {
			const consultants = await onLoadConsultants();
			this.setState({consultants});
		} catch (e) {
			console.error(e);
		}
	};
	
	showFilters = e => this.setState({showFilters: e ? e.currentTarget : null});
	
	fetch = () => {
		// const {starts, ends} = this.state.ranges;
		// this.props.onRefresh(starts, ends, true);
		// noinspection JSUnresolvedFunction
		this.schedule && this.schedule.fetch();
	};
	
	getSetFilterCount = memoize(
		filters => {
			let count = 0;
			for (const filter of values(filters)) {
				if (isBoolean(filter)) {
					count++;
				}
			}
			return count;
		}
	);
	
	// selectConsultant = (e, {value}) => this.setState({consultant: value});
	selectConsultantItem = (consultant) => () => this.props.setOrderConsultantSelection(consultant);//this.setState({consultant});
	
	getConsultants = memoize(
		(consultants) => consultants.map(c => new Worker(c)).sort(sortAsc(w => w.name))
	);
	
	getSelectedConsultant = memoize(
		(list, selected) => list.find(c => c.id === selected)
	);
	
	laneAccess = memoize(laneAccess);
	
	render() {
		const {
			rights: {mayRead, mayCreate, mayDelete},
			goTo,
			// allRights,
			// onRefresh,
			loading,
			orderFilters,
			user,
			consultantSelection
		} = this.props;
		
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		// const workerRights = allRights[SECTION.WORKERS];
		
		const {
			createDialog: showCreateOrdersDialog,
			searchDialog: showSearchDialog,
			orderView: orderViewID,
			loancar_schedule: showLoancarSchedule,
			// minimenu,
			showCalendar,
			consultants
		} = this.state;
		
		const laneAccess = this.laneAccess(user);
		const filterCount = this.getSetFilterCount(orderFilters);
		const hasPopup = showLoancarSchedule || showCalendar || showSearchDialog || showCreateOrdersDialog || orderViewID > 0 || this.state.showDateRangePicker;
		const consultantList = this.getConsultants(consultants);
		const selectedConsultant = this.getSelectedConsultant(consultantList, consultantSelection);
		return (
			<Segment basic>
				{falseNull(hasPopup) &&
				<React.Fragment>
					<KeyModifier ctrl={false} shift={false} alt={false}>
						<KeyHandler
							type={KEYUP}
							keyValue={'Insert'}
							onKeyHandle={this.showCreateDialog(true)}
						/>
						<KeyHandler
							type={KEYUP}
							keyValue={'s'}
							onKeyHandle={this.showSearchDialog(true)}
						/>
						{laneAccess && <KeyHandler
							type={KEYUP}
							keyValue={'l'}
							onKeyHandle={goTo.bind(null, '/orders/lanes')}
						/>}
						<KeyHandler
							type={KEYUP}
							keyValue={'a'}
							onKeyHandle={goTo.bind(null, '/orders/workers')}
						/>
						{mayDelete && <KeyHandler
							type={KEYUP}
							keyValue={'t'}
							onKeyHandle={goTo.bind(null, '/orders/timeline')}
						/>}
						<KeyHandler
							type={KEYUP}
							keyValue={'c'}
							onKeyHandle={this.showLoancarSchedule(true)}
						/>
						<KeyHandler
							keyValue={"r"}
							onKeyHandle={this.fetch}
						/>
						<KeyHandler
							keyValue={"f"}
							onKeyHandle={() => this.filterButton()}
						/>
					</KeyModifier>
				</React.Fragment>
				}
				
				<ActionHeader alignment={'space-between'} className={"sticky"} style={{position: "sticky", top: 0, zIndex: 13, background: COLORS.BACKGROUND}}>
					<ActionHeaderGroup>
						<ActionBackButton loading={loading}/>
						<ActionHeading>Termine <WorkLoad open={!loading}/></ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{/*<RangeTitle loading={loading} from={starts} to={ends}/>*/}
						<OptionHeader underWidth={700}>
							<Flex align={"center"} valign={"flex-end"}><DayPicker placeholderText={null} disabled={hasPopup} onChange={day => this.setState({day})} loading={loading}/></Flex>
						</OptionHeader>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{trueNull(consultants.length) && <Dropdown
							placeholder={'Serviceberater'}
							button
							labeled
							icon={'user'}
							className={'icon'}
							text={selectedConsultant ? selectedConsultant.name : 'Alle Termine'}
						>
							<Dropdown.Menu>
								{consultantSelection && <React.Fragment>
									<Dropdown.Item onClick={this.selectConsultantItem(null)}>Alle Termine</Dropdown.Item>
									<Dropdown.Divider/>
								</React.Fragment>}
								{consultantList.map(item =>
									<Dropdown.Item key={item.id} selected={consultantSelection === item.id} onClick={this.selectConsultantItem(item.id)} text={item.name}/>
								)}
							</Dropdown.Menu>
						
						</Dropdown>}
						<IconButton onClick={this.fetch}><IconRefresh/></IconButton>
						<IconMenu menuWrap={false} expose={func => this.filterButton = func || (() => {})} icon={<>
							<IconFilterList/>
							<Label floating color={"orange"} circular size={"mini"}>{filterCount}</Label>
						</>}
						          closeOnClick={false}
						          buttonProps={{className: cn("labelled", {active: filterCount > 0})}}
						>
							{closeMenu => <Segment basic>
								<OrderFilters onUpdateHook={() => this.setState({showFilters: null}, () => closeMenu())}/>
							</Segment>}
						</IconMenu>
						
						<MiniMenu
							closeOnClick
						>
							<MiniMenuItem
								primaryText={"Zurück zur Übersicht"}
								leftIcon={<IconBack/>}
								link={'/'}
								// onClick={goTo.bind(null, '/')}
							/>
							<Divider/>
							
							{mayCreate && <MiniMenuItem
								secondaryText={"INS"}
								primaryText={"Neuer Termin"}
								leftIcon={<IconNoteAdd/>}
								onClick={this.showCreateDialog(true)}
								onAfterClick={this.miniMenu(false)}
							/>}
							<MiniMenuItem
								primaryText={"Termin suchen"}
								leftIcon={<IconSearch/>}
								onClick={this.showSearchDialog(true)}
								onAfterClick={this.miniMenu(false)}
								secondaryText={"S"}
							/>
							<Divider/>
							{mayDelete && <MiniMenuItem
								primaryText={"Timeline"}
								leftIcon={<IconTimeline/>}
								onClick={goTo.bind(null, '/orders/timeline')}
								secondaryText={"T"}
							/>}
							{laneAccess && <MiniMenuItem
								primaryText={'View Lanes'}
								secondaryText={"L"}
								leftIcon={<IconLane/>}
								onClick={goTo.bind(null, '/orders/lanes')}
							/>}
							<MiniMenuItem
								primaryText={"Leihfahrzeugkalender"}
								secondaryText={"C"}
								leftIcon={<IconCar/>}
								onClick={this.showLoancarSchedule(true)}
								onAfterClick={this.miniMenu(false)}
							/>
							<MiniMenuItem
								primaryText={'Mitarbeiteraktivitäten'}
								secondaryText={"A"}
								leftIcon={<IconBuild/>}
								onClick={goTo.bind(null, '/orders/workers')}
							/>
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<OrderSchedule
					consultantFilter={consultantSelection}
					day={this.state.day}
					hideHeaders
					instance={el => this.schedule = el}
					onOrderCountUpdate={this.updateOrderCount}
				/>
				<Space height={50}/>
				<Modal open={showCreateOrdersDialog} onClose={this.showCreateDialog(false)} centered={false} closeOnDimmerClick={false} size={'small'} className={'modal-order-search'}>
					<Modal.Content>
						{showCreateOrdersDialog && <ConnectedOrderAppointmentFinder instantSearch={false}/>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showCreateDialog(false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>
				<OrderSearchDialog onClose={this.showSearchDialog(false)} open={showSearchDialog}>
					<OrderSearch onSelect={order => this.setOrderView(Number(order.order_id))()}/>
				</OrderSearchDialog>
				<OrderViewDialog onClose={this.setOrderView(0)} open={orderViewID > 0}>
					<OrderQuickView order_id={orderViewID} onRequestClose={this.setOrderView(0)}/>
				</OrderViewDialog>
				<FullscreenPortal open={showLoancarSchedule} onClose={this.showLoancarSchedule(false)}>
					<div style={{width: '100%', height: 'calc(100vh - 60px)', overflowY: 'auto'}}>
						<LoanCarSchedule/>
					</div>
					<EasyFlex style={{height: 60, paddingRight: 20, paddingLeft: 20}} align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER}>
						<Button negative onClick={this.showLoancarSchedule(false)} icon><Icon name={'close'}/> Schließen</Button>
					</EasyFlex>
				</FullscreenPortal>
			</Segment>
		);
	}
}

OrderMainView = withRights(SECTION.ORDERS, OrderMainView);
OrderMainView = withInit(OrderMainView);
OrderMainView = connect(
	(state) => {
		return {
			loading: isLoading(state, PROC_ORDER) || isLoading(state, PROC_ORDER_DAY_LIST),
			orderMap: state.orders.orders,
			serviceMap: state.kfz.service.list || {},
			data: state.orders,
			orderFilters: state.session.order_state_filters,
			consultantSelection: state.session.order_consultant_selection
		};
	},
	(dispatch) => {
		return {
			init: () => dispatch(isOnline(() => {
				// const [now, then] = getTimeSpan(0, true);
				// dispatch(orderAction__get(now, then));
				// dispatch(kfzAction__fetchServices());
			})),
			goTo: (link) => dispatch(push(link)),
			onRefresh: (starts = null, ends = null, callback) => dispatch(orderAction__get(starts, ends, true, (result) => {
				isFunction(callback) && callback(result);
				dispatch(addSnackbar('Termine wurden geladen'));
			})),
			setOrderConsultantSelection: value => dispatch(sessionStorageAction__setOrderConsultantSelection(value))
		};
	}
)(OrderMainView);
OrderMainView = translate()(OrderMainView);


export default OrderMainView;