/*@depreciated */
import * as React from 'react';
import PropTypes from 'prop-types';
import {FormField, Icon, Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {Flex} from "../../partials/ActionHeader";
import {FlatButton, Paper, RaisedButton} from "material-ui";
import StackGrid from 'react-stack-grid';
import EditField from "../../partials/EditField";
import ClientIcon from 'material-ui/svg-icons/action/account-circle';
import AddressIcon from 'material-ui/svg-icons/action/home';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import TelephoneIcon from 'material-ui/svg-icons/communication/phone';
import MobileIcon from 'material-ui/svg-icons/hardware/phone-iphone';
import sizeMe from 'react-sizeme';
import {AHToggle, jsonNullify, jsonRemoveNull, ListItemMargined, object_equals} from "../../../Logic/extensions";
import {tryable} from "ah-lib";
import NoAccess from "../../NoAccess";
import {getSalutationList, PAPER_STYLE} from "../../../Logic/constants";
import values from 'lodash/values';
import DropdownField from "../../partials/DropdownField";
import mapValues from "lodash/mapValues"
import {ValidationDropdown, ValidationField} from "../../partials/ValidationField";
import {getValidationValue, ValidationForm} from "../../partials/ValidationContext";
import {ValidationSearch} from "../../partials";
import {searchAction__zipcode__pure} from "../../../actions/searchActions";
import InputWrapper, {WrappedInput} from "../../partials/InputWrapper";
import {isObject} from "lodash";
import ConfirmDialog from "../../../Tools/material-ui/ConfirmDialog";


// const SALUTATION_OPTIONS = {
// 	her: {key: "her", value: "her", text: "Frau"},
// 	him: {key: "him", value: "him", text: "Herr"},
// 	company: {key: "company", value: "company", text: "Firma"},
// 	merchant: {key: "merchant", value: "merchant", text: "Händler"}
// };

// const SALUTATION_OPTIONS_ARRAY = values(SALUTATION_OPTIONS);
// const SALUTATION_OPTIONS_ARRAY = values(mapValues(getSalutations(), (data, value) => ({text: data, value})));

class ClientMaskView extends React.Component{
	static propTypes = {
		client: PropTypes.shape({
			client_id: PropTypes.number.isRequired,
			house_id: PropTypes.number.isRequired,
			model_id: PropTypes.number
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayRead: PropTypes.bool,
		mayDelete: PropTypes.bool,
		onDelete: PropTypes.func,
		isDeleting: PropTypes.bool,
		countries: PropTypes.arrayOf(PropTypes.shape({
			country_id: PropTypes.string.isRequired
		})),
		searchZipcode: PropTypes.func
	};
	static defaultProps = {
		isSaving: false,
		mayCreate: false,
		mayChange: false,
		mayRead: false,
		mayDelete: false,
		isDeleting: false,
		onSave: () => alert('onSave() is not implemented yet!'),
		countries: [{country_id: "germany"}]
	};
	
	onValidationUpdate = (data) => {
		this.setState({formErrors: data.error, errors: data});
	};
	
	state = {
		client: {
			self: false,
			client_number: '',
			salutation: '',
			company_name: '',
			name: '',
			first_name: '',
			street: '',
			house_no: '',
			address_extra: '',
			city: '',
			zipcode: '',
			country: 'germany',
			email : '',
			telephone_prefix: '',
			telephone: '',
			mobile: '',
			email_confirm: ''
		},
		focused: {},
		showDeleteConfirm: false,
		showDeleteVehicleConfirm: false,
		deleteVehicleCount: 0,
		canvas: false,
		makingScreenshot: false,
		formErrors: 0,
		errors: null
	};
	
	
	validation = getValidationValue(this.onValidationUpdate, false);
	
	getSalutations = () => {
		return getSalutationList();
	};
	
	entry = React.createRef();
	
	componentDidMount() {
		this._handleClientProps(this.props.client);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		isObject(this.props.client) && !object_equals(this.props.client, prevProps.client) && this._handleClientProps(this.props.client);
	}
	
	_handleClientProps = (client) => {
		client && this.setState({client: jsonRemoveNull(client)});
	};
	
	
	set = (e, {name, value}) => this.setState(state => ({...state, client: {...state.client, [name]: value || ''}}));
	
	setCallback = name => (e, value) => this.setState(state => ({
		...state,
		client: {
			...state.client,
			[name]: value
		}
	}));
	
	setSalutation = (e, {value: salutation}) => {
		this.setState(state => {
			const company_name = salutation !== 'company' ? '' : state.client.company_name;
			return {
				...state,
				client: {
					...state.client,
					company_name, salutation
				}
			};
		});
	};
	
	setZipcode = (e, {result}) => this.setState(state => ({
		...state,
		client: {
			...state.client,
			zipcode: result.zipcode,
			city: `${result.name} ${result.name_extra}`
		}
	}));
	
	// focus = (name) => () => !this.state.focused[name] && this.setState(state => ({...state, focused: {...state.focused, [name]: true}}));
	
	required = (index, error = 'errors.input.required') => {
		const t = this.props.t;
		return this.state.focused[index] && this.state.client[index].trim() === '' ? t(error) : null;
	};
	
	value = (index) => tryable(() => this.props.client[index])(() => this.state.client[index]);
	
	isValid = () => !this.state.formErrors;
	
	save = () => {
		const {client} = this.state;
		console.dir(client);
		this.props.onSave(jsonNullify(client));
	};
	
	showDeleteConfirm = showDeleteConfirm => () => this.setState({showDeleteConfirm});
	showDeleteVehicleConfirm = (showDeleteVehicleConfirm, deleteVehicleCount) => () => this.setState(state => ({
		...state,
		showDeleteVehicleConfirm,
		deleteVehicleCount: deleteVehicleCount === undefined ? state.deleteVehcileCount : deleteVehicleCount
	}));
	
	handleDelete = client => () => this.props.onDelete(client, false, null, ({vehicles}) => {
		this.showDeleteConfirm(false)();
		this.showDeleteVehicleConfirm(true, vehicles.length)();
	});
	forceDelete = client => () => this.props.onDelete(client, true);
	
	
	
	render(){
		const {t, i18n, tReady, size, isSaving, client: givenClient, mayCreate, mayRead, mayChange, mayDelete, onSave, onDelete, isDeleting, countries: countryProps,...props} = this.props;
		
		if ( !givenClient) {
			if ( !mayCreate) {
				return <NoAccess/>
			}
		} else {
			if ( !mayRead ) {
				return <NoAccess/>
			}
		}
		const countries = values(mapValues(countryProps, (c) => {
			c.value = c.country_id;
			c.text = t(`country.${c.value}`);
			return c;
		}));
		const editable = givenClient ? mayChange : mayCreate;
		const {client, showDeleteConfirm} = this.state;
		this.getSalutations();
		return(
			<React.Fragment>
			
					<ValidationForm value={this.validation}  {...props} loading={isSaving} onSubmit={this.save}>
						<StackGrid gutterHeight={20} gutterWidth={20} duration={0} columnWidth={size.width < 600 ? '100%' : 350} monitorImagesLoaded>
							<Paper style={PAPER_STYLE}>
								<ListItemMargined
									className="multiline-toggle"
									primaryText={"Interne Abteilung"}
									secondaryText={client.self ? "Es handelt sich um eine Abteilung innerhalb Ihres Unternehmens, oder um Ihr Unternehmen als Ganzes." : "Es handelt sich um einen externen (normalen) Kunden."}
									rightToggle={<AHToggle disabled={!editable} toggled={client.self} onToggle={this.setCallback('self')}/>}
								/>
								<FormField>
									<EditField
										icon={<ClientIcon/>}
										editable={editable}
										name={'client_number'}
										label={t('client.client-number')}
										placeholder={t('client.client-number-example')}
										autoComplete={'off'}
										value={client.client_number}
										onChange={this.set}
									/>
								</FormField>
								<FormField>
									<ValidationDropdown
										labeled
										value={client.salutation}
										name={'salutation'}
										selection
										onChange={this.set}
										id={'salutation2'}
										placeholder={"Bitte auswählen"}
										label={t('client.salutation')}
										icon={<ClientIcon/>}
										// trigger={client.salutation ? SALUTATION_OPTIONS[client.salutation].text : 'Bitte auswählen'}
						                options={this.getSalutations()}
										editable={editable}
										required
									/>
								</FormField>
								{client.salutation === 'company' &&
									<FormField required>
										<ValidationField
											icon={<ClientIcon/>}
											editable={editable}
											required={'company' === client.salutation}
											label={t('client.company-name')}
											placeholder={t('client.company-name-example')}
											autoComplete={'off'}
											name={'company_name'}
											onChange={this.set}
											value={client.company_name}
										/>
									</FormField>
								}
								<FormField>
									<ValidationField
										icon={<ClientIcon/>}
										editable={editable}
										label={t('client.first-name')}
										placeholder={t('client.first-name-example')}
										autoComplete={'off'}
										name={'first_name'}
										onChange={this.set}
										value={client.first_name}
									/>
								</FormField>
								<FormField required>
									<ValidationField
										required
										icon={<ClientIcon/>}
										editable={editable}
										label={t('client.name')}
										placeholder={t('client.name-example')}
										autoComplete={'off'}
										name={'name'}
										onChange={this.set}
										value={client.name}
									/>
								</FormField>
							</Paper>
							<Paper style={PAPER_STYLE}>
								<FormField>
									<InputWrapper label="Straße & Hausnummer" onChange={this.set} editable={editable} icon={<AddressIcon/>}>
										<WrappedInput grow={90} shrink={0.3} placeholder="Straße" name="street" value={client.street} />
										<WrappedInput placeholder="Nr" name="house_no" value={client.house_no} />
									</InputWrapper>
								</FormField>
									<FormField>
										<EditField
											icon={<AddressIcon/>}
											editable={editable}
											label={t('client.address-extra')}
											placeholder={t('client.address-extra-example')}
											autoComplete={'off'}
											name={'address_extra'}
											value={client.address_extra}
											onChange={this.set}
										/>
									</FormField>
									
									<FormField>
										<ValidationSearch
											onSearch={searchAction__zipcode__pure}
											onKey={r => `${r.zipcode} - ${r.name} ${r.name_extra}`}
											onChange={this.set}
											onResultSelect={this.setZipcode}
											label={t('client.zipcode')}
											placeholder={t('client.zipcode-example')}
											name={"zipcode"}
											value={client.zipcode}
											minSearchLength={2}
											// value={client.zipcode}
											icon={<AddressIcon/>}
											onValidate={(value) => {
												if ( value.trim() === '') return null;
												if (client.country === 'germany' && !/\d{5}/.test(value)) {
													return 'Die PLZ in Deutschland besteht aus 5 Zahlen.';
												}
												return null;
											}}
										/>
									</FormField>
									<FormField>
										<EditField
											icon={<AddressIcon/>}
											editable={editable}
											label={t('client.city')}
											placeholder={t('client.city-example')}
											autoComplete={'off'}
											name={'city'}
											value={client.city}
											onChange={this.set}
										/>
									</FormField>
									<FormField>
										<DropdownField
											editable={editable}
											label={t('client.country')}
											name={"country"}
											value={client.country}
											onChange={this.set}
											icon={<AddressIcon/>}
											options={countries}
											placeholder={"Land auswählen"}
										/>
									</FormField>
							</Paper>
							<Paper style={PAPER_STYLE}>
								<FormField>
									<ValidationField
										icon={<EmailIcon/>}
										editable={editable}
										label={t('client.email')}
										placeholder={t('client.email-example')}
										autoComplete={'off'}
										name={'email'}
										value={client.email}
										onChange={this.set}
										type="email"
										optional
									/>
								</FormField>
								
							{/*	{(!givenClient || givenClient.email !== client.email) && editable && client.email && <FormField>
									<ValidationField
										icon={<EmailIcon/>}
										editable
										required
										text={t("client.email-confirm")}
										autoComplete="off"
										name={'email_confirm'}
										value={client.email_confirm}
										type={"email"}
										onChange={this.set}
										onValidate={value => {
											if ( !this.props.client && client.email !== value) return  'E-Mail müssen übereinstimmen';
											return null;
										}}
									/>
								</FormField>}*/}
								<FormField>
									<InputWrapper icon={<TelephoneIcon/>} label="Telefon" editable={editable} onChange={this.set} keepRatio>
										<span style={{flexShrink: 0, paddingLeft: 3, paddingRight: 3}}>+49</span>
										<WrappedInput grow={40} name="telephone_prefix" value={client.telephone_prefix} placeholder="Vorwahl"/>
										<WrappedInput grow={60} name="telephone" value={client.telephone} placeholder="Nummer"/>
									</InputWrapper>
								</FormField>
								<FormField>
									<EditField
										icon={<MobileIcon/>}
										editable={editable}
										label={t('client.mobile')}
										placeholder={t('client.mobile-example')}
										autoComplete={'off'}
										name={'mobile'}
										value={client.mobile}
										onChange={this.set}
										before={<span style={{paddingTop: 2, flexShrink: 0, paddingLeft: 3, paddingRight: 3, alignSelf: 'center', marginLeft: -6, marginRight: 8}}>+49</span>}
									/>
								</FormField>
							</Paper>
							{ onDelete && givenClient && mayDelete &&
								<Paper data-html2canvas-ignore style={PAPER_STYLE}>
									<Segment padded={'very'} basic loading={isDeleting}>
										<p>
											{showDeleteConfirm ? 'Sind sie sicher, dass sie den Kunden löschen wollen?' : 'Kunde löschen?'}
										</p>
										{!showDeleteConfirm && <RaisedButton onClick={this.showDeleteConfirm(true)} secondary fullWidth><Icon name={'trash'}/> {t('actions.delete')}</RaisedButton>}
										{showDeleteConfirm &&
											<Flex align={'space-between'}>
												<FlatButton onClick={this.showDeleteConfirm(false)}>{t('actions.no')}</FlatButton>
												<RaisedButton onClick={this.handleDelete(givenClient)} secondary><Icon name={'trash'}/> {t('actions.yes')}</RaisedButton>
											</Flex>
										}
									</Segment>
								</Paper>
							}
						</StackGrid>
						{editable && <div style={{marginTop: 25, marginBottom: 40}}>
							<Flex data-html2canvas-ignore align={'flex-end'}>
								<RaisedButton disabled={!this.isValid()} primary type={'submit'}>{t(givenClient ? 'actions.save' : 'actions.create')}</RaisedButton>
							</Flex>
						</div>}
						{/*<pre>{JSON.stringify({valid: this.isValid(), formErrors: this.state.formErrors, errors: this.state.errors}, null, 2)}</pre>*/}
						{/*<pre>{JSON.stringify(this.state, null, 2)}</pre>*/}
					</ValidationForm>
				<ConfirmDialog
					text={`${this.state.deleteVehicleCount} Fahrzeuge werden mit dem Kunden gelöscht. Sind Sie sicher?`}
					open={this.state.showDeleteVehicleConfirm}
					onCancel={this.showDeleteVehicleConfirm(false, 0)}
					onConfirm={this.forceDelete(givenClient)}
				/>
			</React.Fragment>
		);
	}
}

export default translate()(sizeMe()(ClientMaskView));