import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {mapState__orders_add, mapState__wheels_add, mapState__wheels_remove} from "./mapActions";
import {addError} from "./errorActions";
import {dispatch} from "../store";
import {publish} from "../Logic/PubSub";
import {SUB_WHEEL} from "./index";

export const wheelCall_list = (params = null) => POST('/wheel' + (params ? '/with-params' : ''), params || {});
export const wheelAction_list = (params = null, throws = false) =>
	async dispatch => {
		try {
			const wheels = await wheelCall_list(params);
			dispatch(mapState__wheels_add(wheels));
			return wheels;
		} catch (e) {
			if (throws) {
				throw e;
			}
			dispatch(addError(e, false));
			return null;
		}
		
	};

export const wheelCall_put = (wheel) => PUT('/wheel', wheel);
export const wheelAction_put = (wheel, throws = false) =>
	async dispatch => {
		try {
			const data = await wheelCall_put(wheel);
			dispatch(mapState__wheels_add(data))
			return data
		} catch (e) {
			if (throws) {
				throw e
			}
			dispatch(addError(e, false));
			return null
		}
	}
	
export const wheelCall_update = wheel => POST('/wheel', wheel);
export const wheelAction_update = (wheel, throws = false) =>
	async dispatch => {
		try {
			const data = await wheelCall_update(wheel)
			dispatch(mapState__wheels_add(data))
			return data
		} catch (e) {
			if (throws) throw e
			dispatch(addError(e, false))
			return null
		}
	}
	
export const wheelCall_delete = id => DELETE('/wheel/' + id);
export const wheelAction_delete = (id, throws = false) =>
	async dispatch => {
		try {
			const wheel = await wheelCall_delete(id);
			dispatch(mapState__wheels_remove(wheel))
			return wheel
		} catch (e) {
			if (throws) throw e
			dispatch(addError(e, false))
			return null
		}
	}
	
export const wheelCall_assignOrder = (order_id, wheel_id, comment, force=false) => PUT('/wheel/order', {order_id, wheel_id, force, comment})
export const wheelDispatch_assignOrder = async (order_id, wheel_id, comment, force = false) => {
	const data = await wheelCall_assignOrder(order_id, wheel_id, comment, force)
	if (!data.ack) {
		return data
	}
	dispatch(mapState__wheels_add(data.wheel))
	dispatch(mapState__orders_add(data.order))
	'old_wheel' in data && dispatch(mapState__wheels_add(data.old_wheel))
	return data
}

export const wheelCall_clearOrder = (order_id) => DELETE(`/wheel/order/${order_id}`)
export const wheelDispatch_clearOrder = async (order_id) => {
	const data = await wheelCall_clearOrder(order_id)
	dispatch(mapState__orders_add(data.order))
	data.wheel && dispatch(mapState__wheels_add(data.wheel))
	return data
}

export const wheelCall_one = wheel_id => GET(`/wheel/one/${wheel_id}`)
export const wheelDispatch_one = async wheel_id => {
	const wheel = await wheelCall_one(wheel_id)
	dispatch(mapState__wheels_add(wheel))
	publish(SUB_WHEEL, wheel)
	return wheel
}

//
export const wheelCall_getEwoTw_order = (orderId) => GET(`/wheel/ewo-tw/order/${orderId}`)
export const wheelCall_getEwoTw_orderRemoval = (orderId) => GET(`/wheel/ewo-tw/removal-order/${orderId}`)
export const wheelCall_getEwoTw_tireset = (number) => GET(`/wheel/ewo-tw/tireset/${number}`)
export const wheelCall_getEwoTw_tiresetRemoval = (number) => GET(`/wheel/ewo-tw/tireset-removal/${number}`)
export const wheelCall_getEwoTw_tiresetRack = (number) => GET(`/wheel/ewo-tw/tireset-rack/${number}`)

export const wheelCall_ewoTw_createOrder = (params) => POST('/wheel/ewo-tw/create-order', params)
export const wheelCall_ewoTw_cancelOrder = (order_id, wheel_id) => POST('/wheel/ewo-tw/cancel-order', {order_id, wheel_id})
export const wheelCall_ewoTw_createRemoval = (params) => POST('/wheel/ewo-tw/create-removal', params)
export const wheelCall_ewoTw_updateRemoval = (params) => POST('/wheel/ewo-tw/update-removal', params)
export const wheelCall_ewoTw_cancelRemoval = (order_id, wheel_id) => POST('/wheel/ewo-tw/cancel-removal', {order_id, wheel_id})

export const wheelCall_ewoTw_import = (order_id, client_id = null) => POST('/wheel/ewo-tw/import', {order_id, client_id})
export const wheelDispatch_ewoTw_import = async (order_id, client_id = null) => {
	const wheel = await wheelCall_ewoTw_import(order_id, client_id)
	dispatch(mapState__wheels_add(wheel))
	publish(SUB_WHEEL, wheel)
	return wheel
}

export const wheelCall_ewoTw_logs = params => POST('/wheel/ewo-tw/logs', params || {})

export const wheelCall_ewoTw_refresh = wheel_id => GET('/wheel/ewo-tw/refresh/' + wheel_id)
export const wheelDispatch_ewoTw_refresh = async wheel_id => {
	const wheel = await wheelCall_ewoTw_refresh(wheel_id)
	dispatch(mapState__wheels_add(wheel))
	publish(SUB_WHEEL, wheel)
	return wheel
}