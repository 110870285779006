import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardText, Divider, List, ListItem} from "material-ui";
import AddIcon from "material-ui/svg-icons/content/add-circle";
import {translate} from "react-i18next";
import {globals} from "ah-tools";
import cn from "classnames";
import {Flex, FlexChild} from "../../../partials/ActionHeader";
import {Button, Form, FormField, Modal} from "semantic-ui-react";
import EditField from "../../../partials/EditField";
import {AHIconButton} from "../../../../Logic/extensions";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import isArray from "lodash/isArray";

class Resource extends React.PureComponent {
	state = {
		value: 1
	};
	
	componentDidMount() {
		this.setState({value: this.props.value || 1});
	}
	
	
	componentDidUpdate(prevProps, {value: current}, snapshot) {
		const {value} = this.props
		if ( value && current !== value) {
			this.setState({value});
		}
	}
	
	handleSave = () => {
		this.props.onSave(this.props.resource, this.state.value);
	};
	
	render () {
		const {t, resource, max=9999, style, onSave, ...props} = this.props;
		return (
			<div style={{paddingLeft: 10, borderLeft: '1px solid gray', ...style}} {...props}>
				<h4>{resource.name}</h4>
				<Form onSubmit={this.handleSave}>
					<FormField>
						<EditField
							editable
							onChange={(_, {value}) => this.setState({value})}
							text={t('service.worktime-hours')}
							type="number"
							step={0.1}
							min={0.1}
							max={max}
							value={this.state.value}
						/>
					</FormField>
					<FormField>
						<Button primary>{t('actions.save')}</Button>
					</FormField>
				</Form>
			</div>
		);
	}
}

const Remaining  = ({base = 1, current=1}) => {
	const diff = base - current;
	const style = (color) => ({
		color,
		fontWeight: 'bold',
		display: 'block',
		textAlign: 'right'
	});
	// console.info("calulation:", base, current, diff);
	if ( 0 === diff ) {
		return <ListItem disabled secondaryText={''} primaryText={<span style={style('inherit')}>Balanced</span>}/>
	}
	if ( diff > 0 ) {
		return <ListItem
			disabled
			primaryText={<span style={style('darkgreen')}>{diff.toFixed(2)}</span>}
			secondaryText={'Übrig'}
		/>;
	}
	return (
		<ListItem
			disabled
			primaryText={<span style={style('orange')}>{Math.abs(diff).toFixed(2)}</span>}
			secondaryText={'Überschuss'}
		/>
	);
};

class ServiceResourceView extends React.Component {
	static propTypes = {
		time: PropTypes.number.isRequired,
		resources: PropTypes.array.isRequired,
		resource_list: PropTypes.array.isRequired,
		toggled: PropTypes.bool,
		onToggle: PropTypes.func,
		onSave: PropTypes.func,
		onResourceRemove: PropTypes.func.isRequired,
		kfz_id: PropTypes.number.isRequired,
		service_id: PropTypes.number.isRequired,
		onResourceAssign: PropTypes.func.isRequired,
		hours_of_work: PropTypes.number.isRequired
		
	};
	static defaultProps = {
		resources: [],
		resource_list: [],
		toggled: false,
		onResourceRemove: () => alert('onResourceRemove() is not implemented yet!'),
		onResourceAssign: () => alert('onResourceAssign() is not implemented yet!')
	};
	
	state = {
		editMode: false,
		activeResource: 0
	};
	
	handleResourceSelection = id => () =>this.setState(state => ({
		...state,
		activeResource: state.activeResource === id ? 0 : id
	}));
	
	handleEditMode = editMode => () => this.setState({editMode});
	
	handleRemove = (model) => () => {
		this.props.onResourceRemove(model);
	};
	
	// handleAssign = (resource, hours_of_work) => this.props.onResourceAssign(this.props.kfz_id, resource, hours_of_work);
	
	render() {
		const {t, time,  resource_list, resources, onToggle, toggled, onResourceAssign, hours_of_work} = this.props;
		const {editMode} = this.state;
		const isDefault = !isArray(resources) || resources.length === 0;
		let totalAmount = 0;
		// console.info('RES', resources);
		return(
			<Card style={{boxShadow: 'none'}} expanded={toggled} onExpandChange={onToggle}>
				<CardHeader title="Ressourcen" actAsExpander showExpandableButton className={cn("ith-card-pointer", {highlight: toggled})}/>
				<CardText expandable style={{padding: 0}}>
					{isDefault
						?
						<List>
							<ListItem disabled >Standardressource (Techniker) für {time} Stunden zugewiesen.</ListItem>
						</List>
						:
						<div>
							<List>
								{resources.map(r => {
									totalAmount += r.hours_of_work;
									let name = resource_list.find(r2 => r2.resource_id === r.resource_id);
									name = name ? name.name : '-';
									return (
										<ListItem key={`update-service-res-${r.resource_id}`} disabled rightIconButton={<AHIconButton onClick={this.handleRemove(r)}><CloseIcon/></AHIconButton>} primaryText={`für ${r.hours_of_work} Stunden`} secondaryText={name}/>
									);
								})}
								<Divider/>
								<Flex align={'space-between'}>
									<ListItem disabled secondaryText={"Gesamt"} primaryText={`= ${totalAmount} Stunden`}/>
									<Remaining current={totalAmount} base={hours_of_work}/>
									
								</Flex>
								
							</List>
						</div>
					}
					<ListItem  primaryText="Eigene Ressourcen festlegen" leftIcon={<AddIcon color={globals.COLOR_SECONDARY}/>} onClick={this.handleEditMode(true)}/>
				</CardText>
			{/*	{editMode && <Dialog open onRequestClose={this.handleEditMode(false)}>
					<div>
						<Flex valign={'flex-start'} gutterWidth={30} gutterHeight={30}>
							<FlexChild width={this.state.activeResource ? 0.5 : 1}>
								{resource_list.map(resource => {
									return <ListItem key={`service-res-select-${resource.resource_id}`} primaryText={resource.name} className={cn({'active-resource': this.state.activeResource === resource.resource_id})} onClick={this.handleResourceSelection(resource.resource_id)}/>
								})}
								<Flex align="flex-end">
									<Button style={{marginTop:10}} onClick={this.handleEditMode(false)}>{t('actions.close')}</Button>
								</Flex>
							</FlexChild>
							{this.state.activeResource !== 0 ? <FlexChild><Resource resource={resource_list.find(r => r.resource_id === this.state.activeResource)} onSave={onResourceAssign} t={t}/></FlexChild> : null}
						</Flex>
					</div>
				</Dialog>}*/}
				{editMode && <Modal open centered={false} onClose={this.handleEditMode(false)}>
					<Modal.Content>
						<div>
							<Flex valign={'flex-start'} gutterWidth={30} gutterHeight={30}>
								<FlexChild width={this.state.activeResource ? 0.5 : 1}>
									{resource_list.map(resource => {
										return <ListItem key={`service-res-select-${resource.resource_id}`} primaryText={resource.name} className={cn({'active-resource': this.state.activeResource === resource.resource_id})} onClick={this.handleResourceSelection(resource.resource_id)}/>
									})}
									<Flex align="flex-end">
										<Button style={{marginTop:10}} onClick={this.handleEditMode(false)}>{t('actions.close')}</Button>
									</Flex>
								</FlexChild>
								{this.state.activeResource !== 0 ? <FlexChild><Resource resource={resource_list.find(r => r.resource_id === this.state.activeResource)} onSave={onResourceAssign} t={t}/></FlexChild> : null}
							</Flex>
						</div>
					</Modal.Content>
				</Modal>}
			</Card>
		);
	}
}

export default translate()(ServiceResourceView);