import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import ICU from 'i18next-icu';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
registerLocale('de', de);
setDefaultLocale(de)

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(reactI18nextModule)
	.use(ICU)
	.init({
		fallbackLng: 'de',
		ns: ['translations'],
		defaultNS: 'translations',
		
		debug: false,
		
		interpolation: {
			escapeValue: false
		},
		
		react: {
			useSuspense: false,
			wait: true
		}
	});

export default i18n;
