import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Segment} from "semantic-ui-react";
import {Flex} from "../../components/partials/ActionHeader";


const ConfirmDialog = ({open, onCancel, onConfirm, text, buttonText, cancelText, loading}) => (
	/*<Dialog open={open} onRequestClose={onCancel}>
		<Segment padded basic loading={loading}>
			<div style={{marginBottom: 50}}>
				<strong>Bist du dir sicher?</strong>
				{text && <div>{text}</div>}
			</div>
			<Flex align={'space-between'}>
				<FlatButton onClick={onCancel}>{cancelText}</FlatButton>,
				<RaisedButton secondary={true} onClick={onConfirm}>{buttonText}</RaisedButton>
			</Flex>
		</Segment>
	</Dialog>*/
	open && <Modal open centered={false} onClose={onCancel} size={'mini'}>
		<Modal.Content>
			<Segment padded basic loading={loading}>
				<div style={{marginBottom: 50}}>
					<strong>Bist du dir sicher?</strong>
					{text && <div>{text}</div>}
				</div>
				
			</Segment>
		</Modal.Content>
		<Modal.Actions>
			<Flex align={'space-between'}>
				<Button onClick={onCancel}>{cancelText}</Button>,
				<Button positive onClick={onConfirm}>{buttonText}</Button>
			</Flex>
		</Modal.Actions>
	</Modal>
);
ConfirmDialog.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	text: PropTypes.node,
	open: PropTypes.bool.isRequired,
	buttonText: PropTypes.node,
	cancelText: PropTypes.node
};
ConfirmDialog.defaultProps = {
	open: false,
	loading: false,
	text: null,
	buttonText: 'Löschen',
	cancelText: 'Abbrechen'
};

export default ConfirmDialog;