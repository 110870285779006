import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Modal, Popup, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex, Flex, Mount} from "../../partials/ActionHeader";
import {MiniMenu, MiniMenuItem} from "../../../Logic/MiniMenu";
import {Divider, FloatingActionButton, IconButton, MenuItem, Paper, Slider} from "material-ui";
import {IconClose, IconDone, IconFitToScreen, IconNoteAdd, IconPerson, IconRefresh, IconScale, IconSearch, IconVisibility, IconVisibilityOff} from "../../../Logic/icons";
import withInit from "../../../Logic/withInit";
import {withRights} from "../../../Tools";
import {COLORS, laneAccess, SECTION} from "../../../Logic/constants";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {translate} from "react-i18next";
import moment from '../../../Logic/Moment';
import {get, isEmpty, isFunction, mapValues, set, values} from "lodash";
import {orderAction__getLanes, orderAction__getLaneView} from "../../../actions/orderActions";
import {isOnline} from "../../../actions/userActions";
import {resourceAction__fetch, resourceAction__fetchWorkers} from "../../../actions/resourceActions";
import {loadWorkers} from "../../../actions/workerActions";
import {angle, deepMemoize as memoizeOne, distance, flattenCollection, partitionArray, trueNull} from "../../../Logic/extensions";
import cn from "classnames";
import KeyHandler, {KEYUP} from "react-key-handler";
import {OrderSearchDialog, OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderSearch from "../../../cointainer/intern/orders/OrderSearch";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {subscribe, unsubscribe} from "../../../Logic/PubSub";
import {SUB_ORDER_DELETE} from "../../../actions";
import {HoverContext} from "../../partials";
import {KeyModifier} from "../../../Logic/KeyHandler";
import {NativeDayPicker as DayPicker} from "../../partials/DayPicker";
import NoAccess from "../../NoAccess";
import {OrderRangeView, OrderStatusView} from "./OrderStatusView";
import {OrderLaneSickness} from "./OrderLaneSickness";
import {Worker} from "../../../models";
import {OrderResource} from "../../../models/OrderResource";
import {CircleNotchLoader} from "../../Loaders";
import {ConnectedOrderAppointmentFinder} from "./finder/OrderAppointmentFinder";
import {OnlineIcon} from "../../../Tools/OnlineIcon";

class CurrentTimeline extends React.PureComponent {
	static propTypes = {
		min: PropTypes.instanceOf(moment),
		max: PropTypes.instanceOf(moment),
		interval: PropTypes.number,
		styler: PropTypes.func
	};
	static defaultProps = {
		min: moment().startOf('day'),
		max: moment().endOf('day'),
		interval: 1000,
		styler: (now, style) => style || {}
	};
	state = {
		time: ''
	};
	
	interval = null;
	
	componentDidMount() {
		const interval = Math.max(100, this.props.interval);
		this.interval = window.setInterval(this.handleTime, interval);
	}
	
	componentWillUnmount() {
		unsubscribe(SUB_ORDER_DELETE, this.deleteChannel);
		window.clearInterval(this.interval);
	}
	
	handleTime = () => {
		const time = moment().format(this.props.format || 'HH:mm');
		if (time !== this.state.time) {
			this.setState({time});
		}
	};
	
	render() {
		const {min, max, styler} = this.props;
		const now = moment();
		if (!now.isBetween(min, max)) return null;
		const {time} = this.state;
		return (<div className={'lane-current-time'} style={styler(now, {})} data-time={time + ' Uhr'}/>);
	}
}


const CarView = ({order: {hsn, tsn, registration_mark, chassis_number, vehicle_name}, ...props}) => (
	<div {...props}>
		{vehicle_name && <div style={{textAlign: "center"}}>{vehicle_name}</div>}
		<Flex align={"flex-start"} valign={"center"}>
			<Icon name={"car"}/>
			<div style={{minWidth: 15}}/>
			<div>
				
				<table>
					<tbody>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>HSN/TSN:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"}
						                                       style={{
							                                       whiteSpace: "nowrap",
							                                       fontSize: 11
						                                       }}>{hsn} / {tsn}</span>
						</td>
					</tr>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>Kennzeichen:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"} style={{
							whiteSpace: "nowrap",
							fontSize: 11
						}}>{registration_mark || "n.v."}</span></td>
					</tr>
					<tr>
						<td style={{color: "#888", fontSize: 12}}>F.g.N.:</td>
						<td style={{minWidth: 20}}></td>
						<td style={{textAlign: "right"}}><span className={"monospace"} style={{
							whiteSpace: "nowrap",
							fontSize: 11
						}}>{chassis_number || "n.v."}</span></td>
					</tr>
					</tbody>
				</table>
			
			</div>
		</Flex>
	</div>
);


const PopupView = ({order, t}) => {
	const contact = `${order.first_name} ${order.last_name}`;
	const company = order.company_name;
	let title = company || contact;
	title = t(`client.salutations.${order.salutation}`) + " " + title;
	return <Segment basic>
		<OrderStatusView order={order}/>
		<div style={{height: 16}}/>
		<em style={{display: "block", textAlign: "center"}}>{title}</em>
		<div style={{height: 16}}/>
		<CarView order={order} style={{padding: 8, backgroundColor: COLORS.SECONDARY}}/>
		<div style={{height: 16}}/>
		<OrderRangeView order={order}/>
	</Segment>;
};

const Subtractor = ({reassign}) => (
	<div>
		<Worker.Provider workers_id={reassign.worker_id}>{worker =>
			<EasyFlex style={{marginBottom: 15}}><strong
				style={{
					display: "inline-block",
					minWidth: 50
				}}>{worker.id}</strong><span>{worker.name}</span></EasyFlex>
		}</Worker.Provider>
		<EasyFlex style={{marginBottom: 15}}><strong style={{
			display: "inline-block",
			minWidth: 50
		}}>Von</strong><span>{moment(reassign.start).format("LLL")}</span></EasyFlex>
		<EasyFlex style={{marginBottom: 15}}><strong style={{
			display: "inline-block",
			minWidth: 50
		}}>Bis</strong><span>{moment(reassign.end).format("LLL")}</span></EasyFlex>
		<EasyFlex style={{marginBottom: 15}}><strong
			style={{display: "inline-block", minWidth: 50}}>Past</strong><span>{reassign.past_allowed ?
			<IconDone color={COLORS.SEMANTIC_GREEN}/> : <IconClose color={COLORS.SEMANTIC_RED}/>}</span></EasyFlex>
	</div>
);

class OrderLaneView extends React.Component {
	static propTypes = {
		onBack: PropTypes.func.isRequired,
		worker2resources: PropTypes.object.isRequired,
		resources: PropTypes.object.isRequired,
		workers: PropTypes.object.isRequired
	};
	static defaultProps = {
		worker2resources: {},
		resources: {},
		workers: {}
	};
	state = {
		lanes: {},
		rises: [],
		date: new Date(),
		fetching: false,
		createDialog: false,
		mouseDown: false,
		grouped: false,
		orderDetail: null,
		searchDialog: false,
		selectedOrder: 0,
		selectedResource: 0,
		minimenu: false,
		scale: 1,
		showScaler: false,
		reassign: null,
		showReassign: false,
		reassignValid: false,
		tmpAssign: null,
		showAssign: false,
		hideFinished: false,
		max: 0
	};
	indexes = {};
	refs = {};
	worker2resources = null;
	refCalendar = null;
	refContainer = new React.createRef();
	// mouseDown = false;
	movePos = {x: 0, y: 0};
	

	handleScroll = (e) => {
		console.debug('OrderLaneView: scrolling', e.delta);
	};
	
	toggleHideFinished = (hide = null) => () => this.setState(state => ({
		hideFinished: null === hide ? !state.hideFinished : hide
	}), () => this.fetch());
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, {passive: true});
		this.deleteChannel = subscribe(SUB_ORDER_DELETE, () => {
			// noinspection JSIgnoredPromiseFromCall
			this.fetch();
		})
		// noinspection JSIgnoredPromiseFromCall
		this.fetch();
		// window.addEventListener('keyup', this.registerKeyNav, true);
	}
	
	mouseDown = (mouseDown) => this.setState({mouseDown});
	
	registerKeyNav = (e) => {
		const elem = window.document.activeElement;
		if (elem && !elem.closest('input, textarea')) {
			// console.debug('is-so-key input');
			switch (e.which) {
				case 37:
					e.preventDefault();
					this.handleDate(-1)();
					break;
				case 39:
					e.preventDefault();
					this.handleDate(1)();
					break;
				default:
					break;
			}
		}
	};
	
	setScale = (scale) => scale > 0 && this.setState({scale: scale});
	
	scaleToWidth = () => {
		const base = this.refContainer.current.clientWidth - 35;
		const target = this.refCalendar.scrollWidth;
		const scale = base / target;
		this.setScale(scale);
	};
	
	miniMenu = (minimenu) => () => this.setState({minimenu});
	
	toggleDialog = name => () => this.setState(state => ({
		[name]: !state[name]
	}));
	
	registerMouseDown = (event) => {
		const e = event.nativeEvent;
		this.movePos = {x: e.x, y: e.y};
		this.mouseDown(true);
	};
	registerMouseUp = () => {
		this.mouseDown(false);
	};
	registerMouseLeave = () => {
		if (this.state.mouseDown) {
			this.mouseDown(false);
		}
		
	};
	registerCalendarRef = r => {
		this.refCalendar = r;
	};
	
	
	
	registerMouseMove = (event) => {
		if (this.state.mouseDown) {
			const e = event.nativeEvent;
			const diff = {x: e.x - this.movePos.x, y: e.y - this.movePos.y};
			this.movePos = {x: e.x, y: e.y};
			const maxLeft = this.refCalendar.scrollWidth - this.refCalendar.clientWidth;
			const maxTop = document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight;
			let left = this.refCalendar.scrollLeft - diff.x;
			let top = document.scrollingElement.scrollTop - diff.y;
			left = Math.max(0, left);
			left = Math.min(left, maxLeft);
			top = Math.max(0, top);
			top = Math.min(top, maxTop);
			this.refCalendar.scrollLeft = left;
			document.scrollingElement.scrollTop = top;
		}
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.handleRes(this.props.worker2resources);
	}
	
	componentWillUnmount() {
		window.removeEventListener('keyup', this.registerKeyNav);
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	ref = (wid, rid) => r => {
		if (!r) return;
		set(this.refs, [wid, rid], r.style.top);
	};
	
	setGrouped = (value) => () => this.setState(state => {
		return {
			...state,
			grouped: value
		}
	}, () => {
		this.fetch();
	});
	
	fetch = async (callback) => {
		const {date, grouped, reassign} = this.state;
		if (null === date) return;
		this.refs = {};
		try {
			this.setState({fetching: true});
			let res;
			if (grouped) {
				res = await orderAction__getLanes(date, grouped);
			} else {
				res = await orderAction__getLaneView(date, reassign);
			}
			const [lanes, rises] = this.handleData(res);
			this.setState({lanes, rises, fetching: false}, () => {
				isFunction(callback) && callback();
			});
		} catch (e) {
			console.error(e);
			this.setState({fetching: false});
			alert(e.error || e.message);
		}
	};
	
	handleRes = memoizeOne((worker2resources) => {
		this.worker2resources = values(worker2resources);
		this.worker2resources = flattenCollection(this.worker2resources, n => values(n));
		this.worker2resources.forEach((res, i) => {
			this.indexes[`${res.workers_id}_${res.resource_id}`] = i;
		});
	});
	
	handleData = (data) => {
		this.handleRes(this.props.worker2resources);
		const rises = [];
		const hideFinished = this.state.hideFinished;
		data.list = data.list || [];
		
		let times = [];
		for (const o of data.list) {
			times.push(moment(o.start_point).valueOf(), moment(o.end_point).valueOf());
		}
		let [min, max] = data.min_max = data.min_max ? data.min_max.map(s => moment(s)) : [moment().startOf('day').add(7, 'hour'), moment().startOf('day').add(18, 'hour')];
		max = Math.max(Math.max(...times), max);
		max = moment(max).endOf('hour').add(1, 'hour');
		// max = moment(max).endOf('hour');
		// data.hours = max.diff(min, 'hours');
		// data.ranges = range(min.get('hour'), max.get('hour'));
		if (this.state.grouped) {
			min = Math.min(...times);
			min = moment(min).subtract(1, 'hour').startOf('hour');
		} else {
			min = min.startOf('hour');
		}
		const orders = get(data, 'orders.orders', {});
		let mrange = moment.range(min, max);
		data.ranges = Array.from( mrange.by('hour'));
		data.hours = data.ranges.length;
		data.min_max = [min, max];
		
		data.list = data.list.filter(o => !hideFinished || !o.finished_at);
		
		data.list = data.list.map((order) => {
			order.info = {};
			let index = this.indexes[`${order.workers_id}_${order.resource_id}`];
			order.info.index = index;
			let bg = get(this.props.resources, [order.resource_id, 'color'], '#222222');
			order.info.bg = bg;
			index = index === undefined ? -1 : index;
			let left = index * 200 + 1 + 5;
			order.start_point = moment(order.start_point);
			order.end_point = moment(order.end_point);
			order.info.start = order.start_point.utc().inspect();
			order.info.end = order.end_point.utc().inspect();
			order.info.min = min;
			order.created_at = moment(get(orders, [order.order_id, 'created_at'], 0));
			let top = order.start_point.diff(min, 'hours', true) * 100 + 1;
			let height = order.end_point.diff(order.start_point, 'hours', true) * 100 + 1;
			order.style = {top, height, left, backgroundColor: bg};
			order.center = [left + 100, top + height / 2];
			return order;
		});
		
		let rs = {};
		data.list.forEach((o) => {
			if (rs[o.order_id]) {
				rs[o.order_id] = [...rs[o.order_id], o];
			} else {
				rs[o.order_id] = [o];
			}
		});
		rs = mapValues(rs, l => {
			l.sort((a, b) => {
				return a.start_point.diff(b.start_point, 'second');
			});
			return l;
		});
		rs = flattenCollection(values(rs), r => r);
		
		rs.forEach((order, i) => {
			const prev = rs[i - 1];
			if (prev && prev.order_id === order.order_id) {
				let line = {};
				// let [prevX, prevY] = prev.center;
				// let [curX, curY] = order.center;
				line.startPoint = prev.center;
				line.endPoint = order.center;
				line.angle = angle(prev.center, order.center);
				line.distance = distance(prev.center, order.center);
				line.id = `line-${prev.order_service_resource_id}-${order.order_service_resource_id}`;
				rises.push(line);
			}
		});
		
		data.list = partitionArray(data.list, o => o.order_id);
		
		
		
		return [data, rises];
	};
	
	// setOrderDetail = (orderDetail) => () => this.setState({orderDetail});
	selectOrder = (selectedOrder) => () => this.setState({selectedOrder});
	selectResource = (selectedResource, orderId = 0) => () => this.setState({selectedResource}, () => {
		this.order_id = orderId;
	});
	
	showCreateDialog = (createDialog) => () => this.setState({createDialog});
	
	handleDate = (inc) => () => {
		let date;
		if (!this.state.date) {
			date = new Date();
		} else {
			date = moment(this.state.date).add(inc, 'day').toDate();
		}
		this.setState({date}, this.fetch);
	};
	
	workload = (list, id) => {
		if (id in list) {
			return <div style={{fontSize: 11}}>{`${list[id]} %`}</div>;
		}
		return null;
	};
	
	toggleScaler = () => this.setState(s => ({...s, showScaler: !s.showScaler}));
	toggleReassigner = () => this.setState(s => ({...s, showReassign: !s.showReassign}));
	toggleAssignView = () => this.setState(s => ({...s, showAssign: !s.showAssign}));
	unsetReassign = () => this.setState({reassign: null});
	togglePast = () => this.setState(s => {
		if (!s.reassign) return s;
		return {
			...s,
			reassign: {
				...s.reassign,
				past_allowed: !s.reassign.past_allowed
			}
		}
	});
	
	laneAccess = memoizeOne(laneAccess);
	
	render() {
		let {resources, workers, t, user} = this.props;
		const laneAccess = this.laneAccess(user);
		if (!laneAccess) {
			return <NoAccess/>;
		}
		let {
			lanes,
			rises,
			fetching,
			createDialog: showCreateOrdersDialog,
			searchDialog: showSearchDialog,
			selectedOrder: selectedOrderID,
			selectedResource: selectedResourceID,
			// minimenu,
			scale,
			showScaler,
			showReassign,
			tmpAssign,
			reassign,
			showAssign
		} = this.state;
		this.handleRes(this.props.worker2resources);
		if (isEmpty(lanes) || !this.worker2resources) {
			console.info('w2r', this.worker2resources);
			return null;
		}
		let list = lanes.list || [];
		let workloads = lanes.workloads;
		let [min, max] = lanes.min_max;
		let rng = lanes.ranges;
		let schedule = lanes.schedule.map(s => {
			s.times = s.times.map(time => {
				time.start = moment(time.start);
				time.end = moment(time.end);
				const top = time.start.diff(min, 'hours', true) * 100 + 1;
				const left = get(this.indexes, `${s.workers_id}_${s.resource_id}`, -1) * 200 + 1;
				const height = time.end.diff(time.start, 'hours', true) * 100 + 1;
				time.style = {top, left, height};
				return time;
			});
			return s;
		});
		const noKeys = this.state.createDialog || this.state.searchDialog || this.state.orderDetail !== null || this.state.selectedOrder !== 0;
		const width = 100 / scale;
		// const now = moment();
		// const isBetween = now.isBetween(min, max);
		// const timeTop = now.diff(min, 'hours', true) * 100;
		// noinspection JSUnresolvedFunction
		return (
			<div style={{padding: "1em"}} ref={this.refContainer}>
				<Mount show={!noKeys}>
					<KeyModifier ctrl={false} shift={false} alt={false}>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'Insert'}
							onKeyHandle={this.showCreateDialog(true)}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'r'}
							onKeyHandle={this.fetch}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'g'}
							onKeyHandle={this.setGrouped(!this.state.grouped)}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'s'}
							onKeyHandle={this.toggleDialog("searchDialog")}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'w'}
							onKeyHandle={this.scaleToWidth}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'c'}
							onKeyHandle={this.toggleScaler}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'b'}
							onKeyHandle={this.toggleReassigner}
						/>
						<KeyHandler
							keyEventName={KEYUP}
							keyValue={'h'}
							onKeyHandle={this.toggleHideFinished()}
						/>
						{reassign && <KeyHandler
							keyEventName={KEYUP}
							keyValue={'a'}
							onKeyHandle={this.toggleAssignView}
						/>}
						{reassign && <KeyHandler
							keyEventName={KEYUP}
							keyValue={'Delete'}
							onKeyHandle={this.unsetReassign}
						/>}
						{reassign && <KeyHandler
							keyEventName={KEYUP}
							keyValue={'p'}
							onKeyHandle={this.togglePast}
						/>}
					</KeyModifier>
				</Mount>
				<ActionHeader alignment={'space-between'} className={"sticky"}
				              style={{position: "sticky", top: 0, zIndex: 12, background: COLORS.BACKGROUND}}>
					<ActionHeaderGroup>
						<ActionBackButton loading={fetching}/>
						<ActionHeading>Auftragslanes {this.state.grouped && '(gruppiert)'}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<Flex valign={"flex-end"} align={"center"}>
							<DayPicker
								// hintText={"Datum"}
								// floatingLabelText={"Datum"}
								// value={date}
								disabled={fetching}
								onChange={(date) => this.setState({date}, this.fetch)}
							/>
						</Flex>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{reassign &&
						<Popup content={<Subtractor reassign={reassign}/>} position={"bottom right"} inverted
						       trigger={<IconButton onClick={this.toggleAssignView}><IconVisibility/></IconButton>}/>}
						<IconButton onClick={this.fetch}><IconRefresh/></IconButton>
						<MiniMenu
							className={'mini-menu'}
							// open={minimenu}
							// onClick={this.miniMenu(true)}
							// onRequestChange={this.miniMenu(false)}
							closeOnClick
						>{() => <>
							<MenuItem
								primaryText={"Reload"}
								leftIcon={<IconRefresh/>}
								onClick={this.fetch}
								secondaryText={'R'}/>
							<MenuItem
								primaryText={'Gruppiert'}
								insetChildren
								checked={this.state.grouped}
								onClick={this.setGrouped(!this.state.grouped)}
								secondaryText={'G'}
							/>
							<MiniMenuItem
								primaryText={"Termin suchen"}
								leftIcon={<IconSearch/>}
								secondaryText={"S"}
								onAfterClick={this.miniMenu(false)}
								onClick={this.toggleDialog("searchDialog")}
							/>
							<Divider/>
							<MiniMenuItem
								primaryText={"Neuer Termin"}
								leftIcon={<IconNoteAdd/>}
								onClick={this.showCreateDialog(true)}
								onAfterClick={this.miniMenu(false)}
								secondaryText={'INS'}/>
							<Divider/>
							<MiniMenuItem
								primaryText={"Ansicht anpassen"}
								onClick={this.scaleToWidth}
								secondaryText={'W'}
								leftIcon={<IconFitToScreen/>}
								onAfterClick={this.miniMenu(false)}
							/>
							<MiniMenuItem
								primaryText={showScaler ? "Skalierung ausblenden" : "Skalierung einblenden"}
								secondaryText={'C'}
								leftIcon={<IconScale/>}
								onClick={this.toggleScaler}
								onAfterClick={this.miniMenu(false)}
							/>
							<Divider/>
							<MiniMenuItem
								primaryText={"Benutzersubtraktion"}
								secondaryText={"B"}
								leftIcon={<IconPerson/>}
								onClick={this.toggleReassigner}
								onAfterClick={this.miniMenu(false)}
							/>
							{reassign && <MiniMenuItem
								primaryText={"Subtraktor anzeigen"}
								secondaryText={"A"}
								leftIcon={<IconVisibility/>}
								onClick={this.toggleAssignView}
								onAfterClick={this.miniMenu(false)}
							/>}
							{reassign && <MiniMenuItem
								primaryText={"Subtraktor entfernen"}
								secondaryText={"DEL"}
								leftIcon={<IconVisibilityOff/>}
								onClick={this.unsetReassign}
								onAfterClick={this.miniMenu(false)}
							/>}
							{reassign && <MiniMenuItem
								primaryText={reassign.past_allowed ? "Vergangenheit erlaubt" : "Vergangenheit verboten"}
								secondaryText={"P"}
								leftIcon={reassign.past_allowed ? <IconDone/> : <IconClose/>}
								onClick={this.togglePast}
								onAfterClick={this.miniMenu(false)}
							/>}
							<Divider/>
							<MiniMenuItem
								primaryText={'Beendete verstecken'}
								secondaryText={'H'}
								insetChildren
								checked={this.state.hideFinished}
								onClick={this.toggleHideFinished()}
								onAfterClick={this.miniMenu(false)}
							/>
						</>}
						
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<div className={cn('lane', {"mouse-down": this.state.mouseDown})}
				     ref={this.registerCalendarRef}
				     onMouseLeave={this.registerMouseLeave}
				     onMouseDown={this.registerMouseDown}
				     onMouseUp={this.registerMouseUp}
				     onMouseMove={this.registerMouseMove}
				     style={{
					     transform: `scale(${scale})`,
					     width: `${width}%`,
					     transformOrigin: "top left"
				     }}
				>
					<div className="lane-header">
						<div className="lane-free"></div>
						{this.worker2resources.map((res) => {
							const w = get(workers, [res.workers_id], {});
							const name = w.name || res.workers_id;
							const first_name = w.first_name || '';
							return (
								<div key={`head-${res.workers_id}_${res.resource_id}`} className="lane-header-cell">
									<div className={cn("lane-worker", {
										inactive: !w.active,
										deleted: w.deleted
									})}><OnlineIcon style={{transform: 'translateY(-3px)'}} size={'mini'} online={w.logged_at}/>{name} {first_name}{this.workload(workloads, res.workers_id)}</div>
									<div
										style={{color: get(resources, [res.resource_id, 'color'], 'black')}}>{get(resources, [res.resource_id, 'name'], res.resource_id)}</div>
								</div>
							);
						})}
					</div>
					<div className={"lane-body"}>
						<CurrentTimeline min={min} max={max} styler={(m => ({top: m.diff(min, 'hours', true) * 100}))}/>
						<div className={"lane-legend"}>
							{rng.map(hour => {
								return (
									<div key={`hour-legend-${hour.unix()}`} className="lane-legend-cell">{hour.get('hour')}:00</div>
								);
							})}
						</div>
						
						<div className={"lane-data"}>
							{schedule.map(({times}, i) =>
								times.map(({style}, ii) => <div key={`${i}_${ii}`} style={style}
								                                className={"lane-worktime"}/>)
							)}
							{rng.map(hour => {
								return (
									<div key={`hour-cell-${hour.unix()}`} className="lane-row">
										{this.worker2resources.map(res => {
											return (
												<div key={`labe-${hour}-${res.workers_id}-${res.resource_id}`}
												     className="lane-cell"></div>
											);
										})}
									</div>
								);
							})}
							
							{list.map((d,i) => {
								return <HoverContext key={`hover-${d[0].order_id}-${d[0].order_service_resource_id}-${i}`}>
									{d.map((data) => {
										const contact = `${data.first_name} ${data.last_name}`;
										const company = data.company_name;
										let title = company || contact;
										title = t(`client.salutations.${data.salutation}`) + " " + title;
										return (
											<div key={`order-${data.order_service_resource_id}`}
											     className="lane-event"
											     style={data.style}
											     onContextMenu={e => {
												     if (this.state.grouped) {
													     this.selectOrder(data.order_id)();
												     } else {
													     this.selectResource(data.order_service_resource_id, data.order_id)();
												     }
												     e.preventDefault();
											     }}
												// title={`Deliver: ${data.deliver_point}\nFetch:   ${data.fetch_point}\nLatest:  ${data.latest_point}`}
											>
												<Popup inverted content={<PopupView t={t} order={data}/>} trigger={
													<div
														className="lane-event-title">{title || `Auftrag ${data.order_id}`}</div>
												}/>
												<span className="lane-id">{data.order_id}</span>
												<span className="lane-ordered-id">{data.ordered_id}</span>
											</div>
										);
									})}
								</HoverContext>
							})}
							{list.map(
								data =>
									data.map(({order_service_resource_id, order_id, center: [left, top], set_by}, i) => {
											return (<div key={`order-point-${i}-${order_service_resource_id}`}
											             style={{left: left - 4, top: top - 4}}
											             className={"lane-point"}/>);
										}
									))
							}
							{rises.map(r => {
								const [left, top] = r.startPoint;
								const width = r.distance;
								const rotate = r.angle;
								return (
									<div key={r.id} style={{left, top, width, transform: `rotate(${rotate}deg)`}}
									     className={"lane-connect"}/>
								);
							})}
						
						</div>
					</div>
				</div>
				<OrderSearchDialog onClose={this.toggleDialog("searchDialog")} open={showSearchDialog}>
					<OrderSearch onSelect={order => this.selectOrder(Number(order.order_id))()}/>
				</OrderSearchDialog>
				
				{/*<OrderCreateDialog open={showCreateOrdersDialog} onClose={this.showCreateDialog(false)}>*/}
				{/*	{showCreateOrdersDialog && <OrderCreateView onAfterCreate={() => {*/}
				{/*		this.fetch(() => {*/}
				{/*			this.showCreateDialog(false)();*/}
				{/*			this.selectOrder(0)();*/}
				{/*		});*/}
				{/*	}}/>}*/}
				{/*</OrderCreateDialog>*/}
				<Modal open={showCreateOrdersDialog} onClose={this.showCreateDialog(false)} centered={false} closeOnDimmerClick={false} size={'large'} className={'modal-order-search'}>
					<Modal.Content>
						{showCreateOrdersDialog && <ConnectedOrderAppointmentFinder instantSearch={false}/>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showCreateDialog(false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>
				{trueNull(selectedOrderID > 0) &&
				<OrderViewDialog onClose={this.selectOrder(0)} open>
					<OrderQuickView order_id={selectedOrderID} onRequestClose={this.selectOrder(0)}/>
				</OrderViewDialog>}
				{trueNull(selectedResourceID > 0) && <Modal size={'small'} closeIcon open onClose={this.selectResource(0, 0)}>
					<Modal.Header as='h3'>Ressource <strong>{selectedResourceID}</strong></Modal.Header>
					<Modal.Content style={{textAlign: 'center'}} scrolling><OrderResource.Provider id={selectedResourceID}
					                                                 placeholder={<CircleNotchLoader/>}>{resource =>
						<OrderResource.PopupInfo resource={resource} collapsing={false}/>
					}</OrderResource.Provider></Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.selectOrder(this.order_id)()}>Zeige Auftrag</Button>
					</Modal.Actions>
				</Modal>}
				{trueNull(showScaler) && <Paper zDepth={4} className={cn("scale-view")}>
					{/*<EasyFlex valign={EasyFlex.valign.CENTER}>*/}
					<Slider value={scale} min={0.01} max={2.5} step={0.01}
					        onChange={(e, scale) => this.setScale(scale)}/>
					{/*</EasyFlex>*/}
					<FloatingActionButton className={cn("close-button")} mini secondary
					                      onClick={this.toggleScaler}><IconClose/></FloatingActionButton>
				</Paper>}
				{trueNull(showReassign) &&
				<Modal defaultOpen closeIcon closeOnDimmerClick={false} onClose={this.toggleReassigner}>
					<Modal.Header>Removal</Modal.Header>
					<Modal.Content scrolling>
						<OrderLaneSickness onSelect={tmpAssign => this.setState({tmpAssign})}/>
					</Modal.Content>
					<Modal.Actions>
						<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
							<Button onClick={this.toggleReassigner}>Verwerfen</Button>
							<Button positive disabled={null === tmpAssign} onClick={() => this.setState({
								reassign: tmpAssign,
								tmpAssign: null,
								showReassign: false
							})}>Anwenden</Button></EasyFlex>
					</Modal.Actions>
				</Modal>}
				{trueNull(showAssign) && reassign && <Modal basic defaultOpen closeIcon onClose={this.toggleAssignView}>
					<Modal.Header>Subtraktor</Modal.Header>
					<Modal.Content scrolling>
						<Subtractor reassign={reassign}/>
					</Modal.Content>
				</Modal>}
			</div>
		);
	}
}

OrderLaneView = withRights(SECTION.ORDERS, OrderLaneView);
OrderLaneView = withInit(OrderLaneView);
OrderLaneView = connect(
	(state) => {
		return {
			worker2resources: state.resources.workers,
			resources: state.resources.list,
			workers: state.workers.list
		};
	},
	(dispatch) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(resourceAction__fetch());
				dispatch(loadWorkers());
				dispatch(resourceAction__fetchWorkers());
			})),
			onBack: () => dispatch(push('/orders'))
		};
	}
)(OrderLaneView);
OrderLaneView = translate()(OrderLaneView);

export default OrderLaneView;