import {RESET_ACTION, SOUND_ACTIONS, SOUND_STATES} from "../actions/types";

const defaultState = {
	playStatus: SOUND_STATES.STOPPED,
	volume: 0,
	initiated: false
};
export default (state = defaultState, {type, volume = 100}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case SOUND_ACTIONS.PLAY:
			return {
				...state,
				playStatus: SOUND_STATES.PLAYING,
				volume
			};
		case SOUND_ACTIONS.STOP:
			return {
				...state,
				playStatus: SOUND_STATES.STOPPED
			};
		case SOUND_ACTIONS.INITIATED:
		return {
			...state,
			initiated: true
		};
		default:
			return state;
	}
};