import {
	addResourceToHouse, deleteResourceOfHouse, loadResourcesOfHouse,
	updateResourceForHouse
} from "../../../../actions/resourceActions";
import MDResource from "../../../../components/intern/master-data/base/MDResource";
import {connect} from "react-redux";
import User from "../../../../models/User";
import {isOnline} from "../../../../actions/userActions";
import {SECTION} from "../../../../Logic/constants";
import withInit from "../../../../Logic/withInit";
import {push} from 'connected-react-router';
import values from "lodash/values";

const m2s = (state) => {
	const user =  User._(state.user.data);
	return ({
		resources: values(state.resources.list),
		...user.getAccessTo(SECTION.MASTER_DATA)
	});
};

const m2d = (dispatch) => {
	// dispatch(isOnline(() => {
	//     dispatch(loadResourcesOfHouse());
	// }));
	return ({
		init: () => dispatch(isOnline(() => {
			dispatch(loadResourcesOfHouse());
		})),
		onBack: () => dispatch(push('/master-data/resources')),
		onSave: (name, color, onSuccess = null) => dispatch(addResourceToHouse(name, color, onSuccess)),
		onUpdate: (resource_id, name, color, onSuccess = null) => dispatch(updateResourceForHouse(resource_id, name, color, onSuccess)),
		onDelete: (resource_id, onSuccess = null) => dispatch(deleteResourceOfHouse(resource_id, onSuccess))
	});
};

export default connect(m2s, m2d)(withInit(MDResource));