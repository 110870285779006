import * as React from "react";
import PropTypes from "prop-types";
import {DatePicker, IconButton} from "material-ui";
import {IconChevronLeft, IconChevronRight} from "../../Logic/icons";
import moment from "../../Logic/Moment";
import KeyHandler from "react-key-handler";
import {Mount} from "./ActionHeader";
import {KeyModifier} from "../../Logic/KeyHandler";
import {StatyComponent} from "../../Tools/ReactExtension";
import NativePicker from 'react-datepicker';
import {withMaterial, withMobileAwareness} from "../../Tools/DatePicker";
import {trueNull} from "../../Logic/extensions";

const WIDTH = 130;
const MaterialPicker = withMobileAwareness(withMaterial(NativePicker, {style:{width: WIDTH}, textareaStyle:{width: WIDTH}, inputStyle: {width: WIDTH, cursor: 'pointer'}}));

export class DayPicker extends React.Component {
	static propTypes = {
		day: PropTypes.instanceOf(Date),
		onChange: PropTypes.func,
		loading: PropTypes.bool,
		disabled: PropTypes.bool,
		keysDisabled: PropTypes.bool,
	};
	static defaultProps = {
		onChange: () => {},
		keysDisabled: false,
		disabled: false,
		loading: false
	};
	
	state = {
		day: this.props.day || new Date()
	};
	
	
	componentDidMount() {
		this.props.onChange.call(this, this.state.day);
	}
	
	
	handleDate = (inc) => () => {
		let date;
		if ( !this.state.day ) {
			date = new Date();
		} else {
			date = moment(this.state.day).add(inc, 'day').toDate();
		}
		this.setDate(date);
	};
	
	setDate = day => this.setState({day}, this.props.onChange.bind(this, day));
	getDate = () => this.props.day || this.state.day;
	render() {
		const {day, onChange, loading, style, disabled: disabledProp, keysDisabled, ...props} = this.props;
		const disabled = Boolean(loading || disabledProp);
		return(
			<React.Fragment>
				<Mount show={!disabled && !keysDisabled}>
					<KeyModifier ctrl={false} alt={false} shift={false}>
						<KeyHandler
							keyValue={"ArrowLeft"}
							onKeyHandle={this.handleDate(-1)}
						/>
						<KeyHandler
							keyValue={"ArrowRight"}
							onKeyHandle={this.handleDate(1)}
						/>
					</KeyModifier>
				</Mount>
				<IconButton disabled={disabled} onClick={this.handleDate(-1)}><IconChevronLeft className={"scaled-icon"}/></IconButton>
				<DatePicker
					hintText={"Datum"}
					// dateFormat={'PP'}
					showWeekNumbers
					floatingLabelText={"Datum"}
					style={{marginLeft: 15, marginRight: 15, ...style}}
					textFieldStyle={{cursor: "pointer"}}
					{...props}
					value={this.getDate()}
					disabled={disabled}
					onChange={(_, date) => this.setDate(date)}
					
				/>
				<IconButton disabled={disabled} onClick={this.handleDate(1)}><IconChevronRight className={"scaled-icon"}/></IconButton>
			</React.Fragment>
		);
	}
}

export class NativeDayPicker extends StatyComponent {
	static propTypes = {
		day: PropTypes.instanceOf(Date),
		onChange: PropTypes.func,
		loading: PropTypes.bool,
		disabled: PropTypes.bool,
		keysDisabled: PropTypes.bool,
	};
	static defaultProps = {
		onChange: () => {},
		keysDisabled: false,
		disabled: false,
		loading: false
	};
	
	key = null;
	state = {
		day: this.props.day || new Date(),
		raw: null
	};
	
	get raw() {
		const {raw, day} = this.state;
		return null  === raw ? day : raw;
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.props.onChange.call(this, this.state.day);
	}
	
	handleDate = (inc) => () => {
		let date;
		if ( !this.state.day ) {
			date = new Date();
		} else {
			date = moment(this.state.day).add(inc, 'day').toDate();
		}
		this.setDate(date);
	};
	
	setDate = day => this.setState({day}, this.props.onChange.bind(this, day));
	getDate = () => this.props.day || this.state.day;
	
	render() {
		const {day, onChange, loading, style, disabled: disabledProp, keysDisabled, ...props} = this.props;
		const disabled = Boolean(loading || disabledProp);
		return(
			<React.Fragment>
				{trueNull(!disabled && !keysDisabled) && <KeyModifier ctrl={false} alt={false} shift={false}>
					<KeyHandler
						keyValue={"ArrowLeft"}
						onKeyHandle={this.handleDate(-1)}
					/>
					<KeyHandler
						keyValue={"ArrowRight"}
						onKeyHandle={this.handleDate(1)}
					/>
				</KeyModifier>}
				<IconButton disabled={disabled} onClick={this.handleDate(-1)}><IconChevronLeft className={"scaled-icon"}/></IconButton>
				<MaterialPicker
					popperClassName={'material-picker-max-content'}
					floating
					dateFormat={'ccc dd.MM.yyyy'}
					placeholderText={'Datum'}
					{...props}
					selected={this.getDate()}
					onChange={this.setDate}
					disabled={disabled}
					required
					strictParsing
				/>
				<IconButton disabled={disabled} onClick={this.handleDate(1)}><IconChevronRight className={"scaled-icon"}/></IconButton>
			</React.Fragment>
		);
	}
}