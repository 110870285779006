import * as React from 'react';
import PropTypes from 'prop-types';
import {Segment} from "semantic-ui-react";
import {Avatar, Card, CardHeader, List, ListItem} from "material-ui";
import DefaultCarImage from '../../../../images/car.png';
import {IMAGE_URL} from "../../../../Logic/constants";
import {translate} from "react-i18next";

const image = (logo) => {
	if (!logo) return DefaultCarImage;
	const {origin_extension, ident, updated_at} = logo;
	return `${IMAGE_URL}${ident}.${origin_extension}?${updated_at}`;
};

const MDCars = ({t, cars, loading}) => {
	if ( loading ) {
		return (
			<Card>
				<Segment style={{minHeight: 300}} loading/>
			</Card>
		);
	}
	return (
		<Card>
			<CardHeader title={t('payment.setting')} subtitle={t('payment.kfz-brands')}/>
			<List style={{paddingBottom: 35}}>
				{cars.map(kfz => (
					<ListItem key={kfz.kfz_id} primaryText={kfz.name} leftAvatar={<Avatar src={image(kfz.logo)} alt={kfz.name}/>}/>
				))}
			</List>
		</Card>
	);
};

MDCars.propTypes = {
	cars: PropTypes.array,
	loading: PropTypes.bool
};
MDCars.defaultProps = {
	cars: [],
	loading: false
};

export default translate()(MDCars);