import * as React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import {
	Card,
	CardHeader,
	Dialog,
	Divider, FlatButton,
	List,
	ListItem, Menu, MenuItem,
	Subheader
} from "material-ui";
import {sortAsc} from "ith-sort";
import {Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {changeWorkerGroup, changeWorkerPermission} from "../../../actions/workerActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {PROC_WORKER_GROUP} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import isFunc from 'lodash/isFunction';
import User from "../../../models/User";
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import DefaultIcon from 'material-ui/svg-icons/navigation/chevron-right';
import NoticeIcon from 'material-ui/svg-icons/action/assignment-late';
import {Subbar} from "ah-tools";

class WorkersDetailRights extends React.Component {
	static propTypes = {
		groups: PropTypes.array.isRequired,
		areas: PropTypes.array.isRequired,
		relation: PropTypes.object.isRequired,
		worker: PropTypes.shape({
			workers_id: PropTypes.number.isRequired,
			right_group_id: PropTypes.string.isRequired
		}).isRequired,
		mayGrant: PropTypes.bool.isRequired,
		onChangeGroup: PropTypes.func.isRequired,
		changingGroup: PropTypes.bool,
		onChangeCustom: PropTypes.func.isRequired,
		changingCustom: PropTypes.bool,
		self: PropTypes.bool
	};
	static defaultProps = {
		relation: {},
		areas: [],
		groups: [],
		group: 'useless',
		onChangeGroup: () => alert('onChangeGroup() is not implemented yet'),
		changingGroup: false,
		onChangeCustom: () => alert('onChangeCustom() is not implemented yet'),
		changingCustom: false,
		mayGrant: false,
		self: false
	};
	
	state = {
		groupDialog: false,
		areaDialog: null
	};
	
	toggleGroupDialog = groupDialog => () => this.setState({groupDialog});
	
	handleGroupSelection = value => () => {
		this.props.onChangeGroup(this.props.worker, value, this.toggleGroupDialog(false));
	};
	
	toggleAreaDialog = (data) => () => {
		if ( !data ) return this.setState({areaDialog: data});
		return this.setState(state => ({
			...state,
			areaDialog: {
				...state.areaDialog,
				...data
			}
		}));
	};
	
	handleAreaPermissonSelection = (area, permission) => () => this.props.onChangeCustom(this.props.worker, area, permission, this.toggleAreaDialog(null));
	
	getRightIcon = (right, current, def) => {
		if ( right === current ) {
			return <CheckIcon/>
		}
		if ( right === def ) {
			return <DefaultIcon/>
		}
		return null;
	};
	
	getCustomRight = (area, mayGrant=false) => {
		const {worker, relation} = this.props;
		const {customRights, right_group_id: group} = worker;
		if ( customRights && relation && relation[area] ) {
			const result = {
				area,
				selection: null,
				custom: null,
				default: null,
				mayGrant
			};
			result.default = relation[area][group];
			if ( customRights[area] ) {
				result.selection = result.custom = customRights[area].access;
			} else {
				result.selection = result.default;
			}
			return result;
		}
		return null;
	};
	
	render() {
		const {
			t,
			areas,
			groups,
			worker: {
				right_group_id: workerGroup
			},
			changingGroup,
			changingCustom,
			mayGrant,
			self: isSelf
		} = this.props;
		const {
			groupDialog,
			areaDialog
		} = this.state;
		return(
			<Card>
				<CardHeader title={t('worker.detail.parts.worker-info')} subtitle={t('worker.detail.parts.rights')}/>
				<List>
					<Subheader style={{fontWeight: 'bold'}}>{t('worker.detail.parts.group-rights')}</Subheader>
					<Divider/>
					<ListItem disabled={!mayGrant || isSelf} primaryText={'Gehört zu der Gruppe'} secondaryText={t(`rights.groups.${workerGroup}`)} onClick={this.toggleGroupDialog(true)}/>
					<Subheader style={{fontWeight: 'bold'}}>{t('worker.detail.parts.sections')}</Subheader>
					<Divider/>
					{areas.map(area => {
						const result = this.getCustomRight(area, area === 'workers' || area === "orders");
						if ( !result ) return null;
						const {selection, default: defSelection} = result;
						return (
							<ListItem
								disabled={!mayGrant || isSelf}
								key={area}
								primaryText={t(`rights.areas.${area}`)}
								secondaryText={t(`rights.desc.${selection}`)}
								onClick={this.toggleAreaDialog(result)}
								rightIcon={defSelection !== selection ? <NoticeIcon/> : null}
							/>
						);
					})}
				</List>
				{areas.length &&
					<React.Fragment>
						<Divider/>
						<Subbar style={{padding: 20}}>
							<NoticeIcon/> {t('worker.detail.notice.not-group-right')}
						</Subbar>
					</React.Fragment>
				}
				<Dialog open={groupDialog} onRequestClose={this.toggleGroupDialog(false)} autoScrollBodyContent>
					<Segment basic loading={changingGroup}>
						<Menu>
							<Subheader>Gruppe auswählen</Subheader>
							{groups.map(group => ({text: t(`rights.groups.${group}`), group})).sort(sortAsc(o => o.text)).map(({text, group}) =>
								<MenuItem disabled={group === workerGroup} onClick={this.handleGroupSelection(group)} key={group} primaryText={text} insetChildren checked={group === workerGroup}/>
							)}
						</Menu>
						<Subbar align={Subbar.ALIGN_END}>
							<FlatButton onClick={this.toggleGroupDialog(false)}>{t('actions.close')}</FlatButton>
						</Subbar>
					</Segment>
				</Dialog>
				<Dialog open={areaDialog !== null} onRequestClose={this.toggleAreaDialog(null)} autoScrollBodyContent>
					<Segment basic loading={changingCustom}>
						<List>
							<Subheader>{t('worker.detail.parts.define-permission')}</Subheader>
							{User.rightsList.map(rightName => {
								const {area, selection, mayGrant, default: defValue} = areaDialog || {};
								if ( !mayGrant && rightName === 'grant' ) return null;
								return (
									<ListItem
										key={`right-name-${rightName}`}
										primaryText={t(`rights.steps.${rightName}`)}
										secondaryText={t(`rights.desc.${rightName}`)}
										title={t(`rights.desc.${rightName}`)}
										insetChildren
										disabled={rightName === selection || isSelf}
										leftIcon={this.getRightIcon(rightName, selection, defValue)}
										onClick={this.handleAreaPermissonSelection(area, rightName)}
									/>
								);
							})}
						</List>
						<Segment>
							<div>
								<CheckIcon/> {t('worker.detail.notice.current-permission')}
							</div>
							
							<div style={{marginTop: 8}}>
								<DefaultIcon/> {t('worker.detail.notice.other-permission')}
							</div>
						</Segment>
						<Subbar align={Subbar.ALIGN_END}>
							<FlatButton onClick={this.toggleAreaDialog(null)}>{t('actions.close')}</FlatButton>
						</Subbar>
					</Segment>
				</Dialog>
			</Card>
		);
	}
}

WorkersDetailRights = translate()(WorkersDetailRights);

export default WorkersDetailRights;

const m2s = state => ({
	changingGroup: isLoading(state, PROC_WORKER_GROUP),
	changingCustom: isLoading(state, PROC_WORKER_GROUP)
});
const m2d = dispatch => ({
	onChangeGroup: (workers, group_id, onSuccess) => dispatch(changeWorkerGroup(workers, group_id, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.rights.group-changed'));
	})),
	onChangeCustom: (worker, area, access, onSuccess) => dispatch(changeWorkerPermission(worker, area, access, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.rights.area-permission-changed'));
	}))
});

export const WorkersDetailRightsController = connect(m2s, m2d)(WorkersDetailRights);

WorkersDetailRightsController.propTypes = {
	worker: PropTypes.shape({
		workers_id: PropTypes.number.isRequired,
		right_group_id: PropTypes.string.isRequired
	}).isRequired,
	mayGrant: PropTypes.bool.isRequired,
	relation: PropTypes.object.isRequired,
	areas: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	self: PropTypes.bool
};

WorkersDetailRightsController.defaultProps = {
	relation: {},
	areas: [],
	groups: [],
	self: false
};