import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Segment, Table} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {translate} from "react-i18next";
import {SECTION} from "../../../Logic/constants";
import {withRights} from "../../../Tools/RightsProvider";
import {MenuItem} from "material-ui";
import {MiniMenu, MiniMenuItem} from "../../../Logic/MiniMenu";
import {IconList, IconRefresh} from "../../../Logic/icons";
import {isOnline} from "../../../actions/userActions";
import {get} from "lodash";
import {loadWorkers, workerAction__getActivities, workerAction__stopWorkOf} from "../../../actions/workerActions";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {PROC_WORKER_ACTIVITIES, STORE_SECTION_ACITIVITY} from "../../../actions";
import User from "../../../models/User";
import Countdown, {applyPadding} from "../../partials/Countdown";
import KeyHandler from "react-key-handler";
import {isLoading} from "../../../actions/loaderActions";
import {trueNull} from "../../../Logic/extensions";
import {values} from "lodash";
import {addSnackbar} from "../../../actions/snackbarActions";
import {ConfirmButton, ConfirmButtonConsumer} from "../../partials/ConfirmButton";
import {OnlineIcon} from "../../../Tools/OnlineIcon";

class OrderWorkerView extends React.Component {
	static propTypes = {
		activityMap: PropTypes.object,
		workerMap: PropTypes.object,
		activities: PropTypes.arrayOf(PropTypes.object),
		resourceMap: PropTypes.object,
		work: PropTypes.object,
		onReload: PropTypes.func,
		loading: PropTypes.bool,
		workingMap: PropTypes.object,
		onQuitWork: PropTypes.func,
		isQuitting: PropTypes.func
	};
	static defaultProps = {
		activityMap: {},
		workerMap: {},
		loading: false,
		isQuitting: () => false
	};
	state = {
		viewOrderID: 0
	};
	
	viewOrder = viewOrderID => (e) => {
		e && e.stopPropagation && e.stopPropagation();
		this.setState({viewOrderID});
	};
	
	quitWork = worker => e => {
		e && e.stopPropagation && e.stopPropagation();
		this.props.onQuitWork(worker);
	};
	
	render() {
		const {
			goTo,
			workerMap,
			rights: {mayDelete},
			// activities: activityList,
			resourceMap,
			onReload,
			workingMap,
			loading,
			isQuitting
		} = this.props;
		const activeList = values(workingMap);
		const activeWorkerIDs = values(workingMap).map(w => w.workers_id);
		const inactiveWorkerIDs = Object.keys(resourceMap).map(id => Number(id)).filter(id => activeWorkerIDs.indexOf(id) < 0);
		// const activities = values(activityMap);
		// const [active, idle] = partition(activities, 'start_point');
		const {viewOrderID} = this.state;
		return (
			<Segment basic>
				{onReload &&
					<KeyHandler
						keyValue={"r"}
						onKeyHandle={onReload}
					/>
				}
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading loading={loading}>Mitarbeiteraktivitäten</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<MiniMenu>
							<MiniMenuItem
								primaryText={'Zu den Aufträgen'}
								leftIcon={<IconList/>}
								link={"/orders"}
								// onClick={goTo.bind(null, '/orders')}
							/>
							{onReload && <MenuItem
								primaryText={"Aktualisieren"}
								secondaryText={"R"}
								leftIcon={<IconRefresh/>}
								onClick={onReload}
							/>}
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic>
					{ trueNull(activeList.length) &&
						<Table celled color={"green"}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell colSpan={mayDelete ? 4 : 3}>Aktive Mitarbeiter</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell>Name</Table.HeaderCell>
									<Table.HeaderCell>Arbeitet seit</Table.HeaderCell>
									<Table.HeaderCell/>
									{mayDelete && <Table.HeaderCell/>}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{activeList.map(a => {
									const worker = get(workerMap, [a.workers_id], {});
									const w = new User(worker);
									const quitting = isQuitting(a.workers_id);
									return <Table.Row key={a.work_id} className={"cursor pointer"} onClick={goTo.bind(null, `/orders/workers/${worker.workers_id}`)}>
										<Table.Cell><OnlineIcon size={'tiny'} style={{transform: 'translateY(-3px)'}} online={worker.logged_at}/>{w.getName()}</Table.Cell>
										<Table.Cell><Countdown targetDate={a.start_point} render={data => applyPadding(data).slice(2, 5).join(":")}/></Table.Cell>
										<Table.Cell textAlign={"center"}><Button circular icon={"info"} onClick={this.viewOrder(a.order_id)}/></Table.Cell>
										{mayDelete && <Table.Cell textAlign={"center"}>
											{/*<Button loading={isQuitting(a.workers_id)} negative onClick={this.quitWork(worker)}>Aktivität beenden</Button>*/}
											<ConfirmButton negative content={"Aktivität beenden"}>
												<ConfirmButtonConsumer>{confirm =>
													<Button.Group>
														<Button disabled={quitting} onClick={e => {
															e && e.stopPropagation();
															confirm(false);
														}}><Icon name={"close"} />Abbrechen</Button>
														<Button.Or/>
														<Button loading={quitting} negative onClick={this.quitWork(worker)}>Fortfahren</Button>
													</Button.Group>
												}</ConfirmButtonConsumer>
											</ConfirmButton>
										</Table.Cell>}
									</Table.Row>
								})}
							</Table.Body>
						</Table>
					}
					{ trueNull(inactiveWorkerIDs.length) &&
						<Table celled color={"red"}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Inaktive Mitarbeiter</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{inactiveWorkerIDs.map(id => {
									const worker = get(workerMap, [id], {});
									const w = new User(worker);
									return <Table.Row key={id} className={"cursor pointer"} onClick={goTo.bind(null, `/orders/workers/${worker.workers_id}`)}>
										<Table.Cell><OnlineIcon size={'tiny'} style={{transform: 'translateY(-3px)'}} online={worker.logged_at}/>{w.getName()}</Table.Cell>
									</Table.Row>
								})}
							</Table.Body>
						</Table>
					}
				</Segment>
				<OrderViewDialog open={viewOrderID > 0} onClose={this.viewOrder(0)}>
					<OrderQuickView order_id={viewOrderID} onRequestClose={this.viewOrder(0)}/>
				</OrderViewDialog>
			</Segment>
		);
	}
}


OrderWorkerView = withRights(SECTION.ORDERS, OrderWorkerView);
OrderWorkerView = withInit(OrderWorkerView);
OrderWorkerView = connect(
	(state, props) => ({
		activityMap: state.orders.activities,
		activities: state.workers.activities,
		resourceMap: state.workers.resourceMap,
		work: get(state, ["appointments", "section", STORE_SECTION_ACITIVITY], {}),
		workerMap: state.workers.list,
		workingMap: state.workers.working,
		loading: isLoading(state, PROC_WORKER_ACTIVITIES),
		isQuitting: (id) => isLoading(state, PROC_WORKER_ACTIVITIES, id)
	}),
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(loadWorkers());
			// dispatch(orderAction__activities());
			dispatch(workerAction__getActivities());
		})),
		onReload: () => dispatch(workerAction__getActivities()),
		onQuitWork: (worker) => dispatch(workerAction__stopWorkOf(worker, result => {
			dispatch(addSnackbar("Der Mitarbeiter wurde von der Arbeit abgezogen"));
		})),
		goTo: link => dispatch(push(link))
	})
)(OrderWorkerView);
OrderWorkerView = translate()(OrderWorkerView);

export default OrderWorkerView;