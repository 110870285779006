import React from 'react'
import PropTypes from 'prop-types'
import {EasyFlex} from "../../../partials/ActionHeader";
import {Button, FormField, Message, Modal, TextArea} from "semantic-ui-react";
import {ConnectedWheelEditor} from "../../wheel/WheelEditor";
import {WheelSearchModal} from "../../wheel/WheelSearchModal";
import {Wheel} from "../../../../models";
import {Trans} from "react-i18next";
import {withHouse} from "../../../../Tools/RightsProvider";
import {get} from 'lodash'
import {FSModal} from "../../wheel/ewotw/EwoTW";
import {ConnectedWheelListView} from "../../wheel/WheelListView";
import {trueNull} from "../../../../Logic/extensions";

export class WheelSelector extends React.Component {
	static propTypes = {
		vehicleId: PropTypes.number.isRequired,
		onSelect: PropTypes.func.isRequired,
		wheel: PropTypes.object,
		clientId: PropTypes.number,
		importValue: PropTypes.string
	}
	static defaultProps = {
		importValue: ''
	}
	state = {
		create: false,
		search: false,
		wheel: this.props.wheel || null,
		comment: '',
		showWheel: false,
		clientWheels: false,
		vehicleWheels: false
	}
	
	
	render() {
		const {vehicleId, onSelect, houseData, clientId} = this.props
		return (
			<div>
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} wrap>
					<Button.Group basic>
						<Button className={'not-clickable'}>
							{!this.state.wheel && <span>Kein Reifensatz ausgewählt</span>}
							{this.state.wheel && <span>{get(this.state.wheel, 'ewo_tw.tireSetNumber', 'Reifensatz ausgewählt')}</span>}
						</Button>
						{this.state.wheel && <Button icon={'close'} onClick={e => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation()
							this.setState({wheel: null}, () => {
								onSelect && onSelect(null)
							})
						}}/>}
						{this.state.wheel &&
						<Button icon={'eye'} onClick={e => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation()
							this.setState({showWheel: true})
						}}/>
						}
					</Button.Group>
					<Button.Group>
						<Button primary icon={'search'} labelPosition={'left'} onClick={() => this.setState({search: true})} content={this.state.wheel ? 'Satz wechseln' : 'Satz auswählen'}/>
						{trueNull(clientId) && <Button color={'grey'} icon={'user'} onClick={() => this.setState({clientWheels: true})}/>}
						{trueNull(vehicleId) && <Button color={'grey'} icon={'car'} onClick={() => this.setState({vehicleWheels: true})}/>}
					</Button.Group>
				</EasyFlex>
				{houseData.ewo_tw && this.state.wheel && <div className={'ui form'} style={{marginTop: 20}}>
					<FormField>
						<label>Kommentar zur Auslagerung</label>
						<TextArea value={this.state.comment} onChange={(e, {value}) => this.setState({comment: value}, () => onSelect(this.state.wheel, this.state.comment))}/>
					</FormField>
				</div>}
				<Modal open={this.state.create} centered={false} onClose={() => this.setState({create: false})}>
					<Modal.Header>Reifensatz eintragen</Modal.Header>
					<Modal.Content>
						<ConnectedWheelEditor clientId={clientId} vehicleId={vehicleId} onAfterChange={wheel => this.setState({wheel, create: false}, () => onSelect(wheel, this.state.comment))}/>
					</Modal.Content>
				</Modal>
				{this.state.search && <WheelSearchModal
					skipDetail
					importValue={this.props.importValue || ''}
					open
					clientId={this.props.clientId}
					vehicleId={vehicleId}
					onClose={() => this.setState({search: false})}
					closeOnSelect
					onSelect={wheel => this.setState({wheel}, () => onSelect(wheel, this.state.comment))}
				/>}
				{this.state.showWheel && <Modal open centered={false} size={'tiny'} basic onClose={() => this.setState({showWheel: false})}>
					<Modal.Content>
						<Wheel.PopupInfo definition inverted wheel={this.state.wheel}/>
					</Modal.Content>
					<Modal.Actions>
						<Button inverted onClick={e => {
							// noinspection JSUnresolvedFunction
							e && e.stopPropagation()
							this.setState({showWheel: false})
						}}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{this.state.clientWheels && <FSModal
					open
					centered={false}
					onClose={() => this.setState({clientWheels: false})}
				>
					<Modal.Content>
						<ConnectedWheelListView
							clientId={clientId}
							onSelect={wheel => this.setState({wheel, clientWheels: false}, () => onSelect(this.state.wheel, this.state.comment))}
							emptyRender={<Message info><p>Keine Reifen hinterlegt</p></Message>}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({clientWheels: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
				{this.state.vehicleWheels && <FSModal
					open
					centered={false}
					onClose={() => this.setState({vehicleWheels: false})}
				>
					<Modal.Content>
						<ConnectedWheelListView
							vehicleId={vehicleId}
							onSelect={wheel => this.setState({wheel, vehicleWheels: false}, () => onSelect(this.state.wheel, this.state.comment))}
							emptyRender={<Message info><p>Keine Reifen hinterlegt</p></Message>}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({vehicleWheels: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</FSModal>}
			</div>
		);
	}
}

WheelSelector = withHouse(WheelSelector)