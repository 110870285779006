const isAccessable = ({service2resources, serviceInfo, userResources, resources, strict, order, fused, user}) => {
	if (!serviceInfo.released) {
		return false;
	}
	const res = service2resources[serviceInfo.order_service_id];
	if (res) {
		for (const resID of res) {
			if (userResources.indexOf(resources[resID].resource_id) !== -1) {
				if (strict && "resource_ids" in order) {
					if (fused) {
						if (order.workers_id === user.getData().workers_id && order.resource_ids.indexOf(resources[resID].resource_id) !== -1) {
							return true;
						}
					} else if (order.workers_id === user.getData().workers_id && order.resource_id === resources[resID].resource_id) {
						return true;
					}
				} else {
					return true;
				}
			}
		}
	}
	return false;
};

export default isAccessable;