import BaseModel from "./BaseModel";
// eslint-disable-next-line
import * as React from "react";
import PropTypes from "prop-types";
import {moment} from "../Logic/Moment";
import {connect} from "react-redux";
import {isUndefined, isFunction} from "lodash";
import {ProviderComponent} from "../components/partials";


const Connector = ({work, placeholder, children}) => work && isFunction(children) ? children(work) : placeholder;
Connector.propTypes = {
	placeholder: PropTypes.node,
	children: PropTypes.func,
	order_id: PropTypes.number,
	service_id: PropTypes.number,
	resource_id: PropTypes.number,
	workers_id: PropTypes.number,
	work_id: PropTypes.number,
	active: PropTypes.bool
};
Connector.defaultProps = {
	placeholder: null
};



export class Work extends BaseModel {
	_validateModel(model) {
		return "work_id" in model;
	}
	
	get work_id() { return Number(this.__model.work); }
	get id() { return this.work_id; }
	get workers_id() { return Number(this.__model.workers_id); }
	get service_id() { return Number(this.__model.service_id); }
	get resource_id() { return Number(this.__model.resource_id) || null; }
	get start_point() { return this.__model.start_point; }
	get start_point_moment() { return moment(this.start_point); }
	get end_point() { return this.__model.end_point || null; }
	get end_point_moment() { return this.end_point ? moment(this.end_point) : null; }
	get duration() { return Number(this.__model.duration) || null; }
	get created_at() { return this.__model.created_at; }
	get created_moment() { return moment(this.created_at); }
	get updated_at() { return this.__model.updated_at; }
	get updated_moment() { return moment(this.updated_at); }
	get is_active() { return !this.end_point; }
	
	static Connector = connect(
		(state, {work_id, order_id, service_id, resource_id, workers_id, active}) => ({
			work: Object.values(state.map.work).filter(w =>
				(!work_id || work_id === w.work_id) &&
				(!order_id || order_id === w.order_id) &&
				(!service_id || service_id === w.service_id) &&
				(!resource_id || resource_id === w.resource) &&
				(!workers_id || workers_id === w.workers_id) &&
				(isUndefined(active) || active === !w.end_point)
			)
		})
	)(Connector);
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.work[id];
			data = data ? new Work(data) : null;
			return {data};
		}
	)(ProviderComponent)
}
