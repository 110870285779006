// import {async, DELETE, GET, POST, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT, async} from "../Logic/fetch";
import {setLoader, unsetLoader} from "./loaderActions";
import {PROC_ORDER_SERVICE, PROC_SERVICE_CUSTOM} from "./index";
import {addError} from "./errorActions";
import {isFunction} from "lodash";
import {kfzState__removeService, kfzState__updateService} from "./kfzActions";
import {Collector} from "../Logic/helper";
import {mapState__services_add} from "./mapActions";

export const serviceCall__putCustom = (service) => PUT('/service/custom', service);
export const serviceCall__deleteCustom = (service) => DELETE(`/service/custom/${service.service_id || service}`);
export const serviceCall__collect = services => POST('/service/collect', {list: services});

export const serviceAction__findOfModel = (model) => {
	const model_id = model.model_id || model;
	return GET(`/service/model/${model_id}`);
};

export const serviceAction__calculate = (vehicle, serviceIdList) => {
	const vehicle_id = vehicle.vehicle_id || vehicle;
	return POST('/service/calculate', {vehicle_id, services: serviceIdList});
};

export const serviceAction__saveCustom = (service, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_SERVICE_CUSTOM));
			const result = await serviceCall__putCustom(service);
			dispatch(kfzState__updateService(result));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SERVICE_CUSTOM));
		}
	};
export const serviceAction__mockSaveCustom = (service, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_SERVICE_CUSTOM));
			await async(2500);
			service.service_id = Number.parseInt(Math.round(Math.random() * 100000));
			isFunction(onSuccess) && onSuccess(service);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SERVICE_CUSTOM));
		}
	};

export const serviceAction__deleteCustom = (service, onSuccess) =>
	async dispatch => {
		const id = service.service_id || service;
		try {
			dispatch(setLoader(PROC_SERVICE_CUSTOM, id));
			const result = await serviceCall__deleteCustom(service);
			dispatch(kfzState__removeService(id));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SERVICE_CUSTOM, id));
		}
	};

let __collect = {};
export const serviceAction__collect = services =>
	Collector._(__collect, services, 'service_id')
		.withLoader([PROC_ORDER_SERVICE])
		.withStateProvider('map.services')
		.withApiProvider(list => serviceCall__collect(list))
		.withStoreProvider((result, dispatch) => dispatch(mapState__services_add(result)))
		.execute();