import * as React from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {translate} from "react-i18next";
import {Form, FormField} from "semantic-ui-react";
import {_empty, _req, Loadable} from "../../../../Logic/extensions";
import PersonIcon from 'material-ui/svg-icons/social/person';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import EditIcon from 'material-ui/svg-icons/image/edit';
import {Subbar} from "ah-tools";
import EditField from "../../../partials/EditField";


class MDHouseCEO extends React.PureComponent {
	static propTypes = {
		t: PropTypes.func,
		i18n: PropTypes.object,
		tReady: PropTypes.any,
		house: PropTypes.shape({
			ceo: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			tax_code: PropTypes.string,
			jurisdiction: PropTypes.string
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool,
		mayChange: PropTypes.bool
	};
	static defaultProps = {
		isSaving: false,
		mayChange: false
	};
	
	state = {};
	
	handleChange = (e, {name, value}) => {
		if (this.props.house[name] === value) {
			return this.setState({[name]: undefined});
		}
		return this.setState({[name]: value});
	};
	
	isValid = () => {
		const save = this.state;
		return !_empty(save) &&
			_req(save.ceo) &&
			_req(save.email);
	};
	
	handleSave = () => {
		this.props.onSave(this.props.house, this.state, () => this.setState({
			ceo: undefined,
			email: undefined,
			tax_code: undefined,
			jurisdiction: undefined
		}));
	};
	
	render() {
		const {t, house, isSaving, mayChange: editable} = this.props;
		return(
			<Card>
				<CardHeader title={t('worker.detail.parts.basisdata')} subtitle={t('worker.detail.parts.ceo')}/>
				<CardText>
					<Form onSubmit={this.handleSave}>
						<FormField>
							<EditField
								icon={<PersonIcon/>}
								text={t('worker.detail.labels.ceo')}
								required
								defaultValue={house.ceo}
								name={'ceo'}
								onChange={this.handleChange}
								editable={editable}
							/>
						</FormField>
						<FormField>
							<EditField icon={<EmailIcon/>} text={t('worker.detail.labels.email-ceo')} required defaultValue={house.email} name={'email'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<FormField>
							<EditField icon={<EditIcon/>}  text={t('worker.detail.labels.tax-code')} defaultValue={house.tax_code} name={'tax_code'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<FormField>
							<EditField icon={<EditIcon/>} text={t('worker.detail.labels.jurisdiction')} defaultValue={house.jurisdiction} name={'jurisdiction'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<Subbar style={{marginTop: 20}} align={Subbar.ALIGN_END}>
							<Loadable loading={isSaving} disabledOnLoading disabled={!this.isValid()} inverse type={'submit'} primary hoc={RaisedButton}>{t('actions.save')}</Loadable>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}


export default translate()(MDHouseCEO);