import VehicleEditView from "../../../components/intern/vehicles/VehicleEditView";
import {connect} from "react-redux";
import User from "../../../models/User";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {clientAction__fetchOne} from "../../../actions/clientActions";
import {push} from "connected-react-router";
import {SECTION} from "../../../Logic/constants";
import {kfzAction__loadKfzByClient, kfzAction__loadModelsByClient} from "../../../actions/kfzActions";
import values from 'lodash/values';
import {
	vehicleAction__change,
	vehicleAction__delete,
	vehicleAction__fetchOfClient
} from "../../../actions/vehicleActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import isFunc from 'lodash/isFunction';
import {isLoading} from "../../../actions/loaderActions";
import {PROC_VEHICLES_FETCH} from "../../../actions";


const m2s = (state, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	const vehicle_id = Number(props.vehicle_id || props.match.params.vehicle_id);
	const vehicle = state.vehicles.list[vehicle_id];
	const client = state.clients.list[client_id];
	const user =  User._(state.user.data);
	const _isLoading = isLoading(state, PROC_VEHICLES_FETCH, client_id);
	return {
		client, vehicle,
		isLoading: _isLoading,
		...user.getAccessTo(SECTION.CLIENTS),
		kfz_list: values(state.kfz.cars)
	};
};
const m2d = (dispatch, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	// const vehicle_id = Number(props.match.params.vehicle_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(clientAction__fetchOne(client_id));
			dispatch(kfzAction__loadKfzByClient(client_id));
			dispatch(kfzAction__loadModelsByClient(client_id));
			dispatch(vehicleAction__fetchOfClient(client_id));
			// dispatch(kfzAction__loadModelsByClient(client_id));
		})),
		onDelete: (vehicle, onSuccess) => dispatch(vehicleAction__delete(vehicle, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.vehicle.deleted'));
			dispatch(push(`/clients/${client_id}/vehicles`));
		})),
		onSave: (data, onSuccess) => dispatch(vehicleAction__change(data, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.vehicle.changed'));
		})),
		onBack:() => window.history.back(),
		toVehicles:() => dispatch(push(`/clients/${client_id}/vehicles`)),
		toClient:() => dispatch(push(`/clients/${client_id}`)),
		toWheels: () => dispatch(push(`/clients/${client_id}/vehicles/${props.match.params.vehicle_id}/wheels`))
	};
};

export default connect(m2s, m2d)(withInit(VehicleEditView));