import * as React from "react";
import {ConnectedRouter} from "connected-react-router";
import history from "../Logic/history";
import {Route, Switch} from "react-router";
import {Segment} from "semantic-ui-react";
import {ActionHeader, ActionHeading, EasyFlex} from "./partials/ActionHeader";
import {NavLink} from "react-router-dom";
import {CheckboxTest} from "./development/CheckboxTest";
import {SoundTest} from "./development/SoundTest";
import {CustomServiceTest} from "./development/CustomServiceTest";
import {ErrorBoundaryTest} from "./development/ErrorBoundaryTest";
import {CountdownTest} from "./development/CountdownTest";
import {OrderChangeTest} from "./development/OrderChangeTest";
import {NextOrderTest} from "./development/NextOrderTest";
import {OrderAppointmentTest} from "./development/OrderAppointmentTest";
import {WorkloadTest} from "./development/WorkloadTest";
import {ChartTest} from "./development/ChartTest";
import {TimelineTest} from "./development/TimelineTest";
import {FunctionTest} from "./development/FunctionTest";
import {GrouplineTest} from "./development/GrouplineTest";
import {ClientPopupTest} from "./development/ClientPopupTest";
import {VehiclePopupTest} from "./development/VehiclePopupTest";
import {OrderAlertTest} from "./development/OrderAlertTest";
import {OrderInfoTest} from "./development/OrderInfoTest";
import {ClientTest} from "./development/ClientTest";
import {Sound2Test} from "./development/Sound2Test";
import {Sound3Test} from "./development/Sound3Test";
import {LogoutOverlayTest} from "./development/LogoutOverlayTest";
import {WorkerizeTest} from "./development/WorkerizeTest";
import {AppointmentViewTest} from "./development/AppointmentViewTest";
import {LoanCarTest} from "./development/LoanCarTest";
import {LoanCarMaskTest} from "./development/LoanCarMaskTest";
import {ClientSearchTest} from "./development/ClientSearchTest";
import {OrderSearchTest} from "./development/OrderSearchTest";
import {RelocateTest} from "./development/RelocateTest";
import {ConsultantTest} from "./development/ConsultantTest";
import {FindAppointmentTest} from "./development/FindAppointmentTest";
import {TimeInputTest} from "./development/TimeInputTest";
import {ConsultantSelectorTest} from "./development/ConsultantSelectorTest";
import {OrderExtendTest} from "./development/OrderExtendTest";
import {OrderMoveTest} from "./development/OrderMoveTest";
import {OrderServiceTest} from "./development/OrderServiceTest";
import {DynamicScheduleTest} from "./development/DynamicScheduleTest";
import {DynamicScheduleAnalyzerTest} from "./development/DynamicScheduleAnalyzerTest";
import {WheelListTest} from "./development/WheelListTest";
import {OrderWheelTest} from "./development/OrderWheelTest";
import {EtoTwTest} from "./development/EtoTwTest";
import {ComponentTest} from "./development/ComponentTest";
import {HouseScheduleTest} from "./development/HouseScheduleTest";


const devRoutes = [
	{path: "/dev/three-way-checkbox", component: CheckboxTest},
	{path: "/dev/sound-playback", component: SoundTest},
	{path: "/dev/custom-service", component: CustomServiceTest},
	{path: "/dev/error-boundary", component: ErrorBoundaryTest},
	{path: "/dev/countdown", component: CountdownTest},
	{path: "/dev/order-change", component: OrderChangeTest},
	{path: "/dev/next-order", component: NextOrderTest},
	{path: "/dev/create-order", component: OrderAppointmentTest},
	{path: "/dev/workload-viewer", component: WorkloadTest},
	{path: "/dev/chart", component: ChartTest},
	{path: "/dev/timeline", component: TimelineTest},
	{path: "/dev/groupline", component: GrouplineTest},
	{path: "/dev/function", component: FunctionTest},
	{path: "/dev/client-popup", component: ClientPopupTest},
	{path: "/dev/vehicle-popup", component: VehiclePopupTest},
	{path: "/dev/order-alert", component: OrderAlertTest},
	{path: "/dev/order-info", component: OrderInfoTest},
	{path: "/dev/client-view", component: ClientTest},
	{path: "/dev/sound-2", component: Sound2Test},
	{path: "/dev/sound-global", component: Sound3Test},
	{path: "/dev/logout-overlay", component: LogoutOverlayTest},
	{path: "/dev/workerize", component: WorkerizeTest},
	{path: "/dev/appointment", component: AppointmentViewTest},
	{path: "/dev/loan-cars", component: LoanCarTest},
	{path: "/dev/loan-car-mask", component: LoanCarMaskTest},
	{path: "/dev/client-search", component: ClientSearchTest},
	{path: "/dev/order-search", component: OrderSearchTest},
	{path: "/dev/relocate", component: RelocateTest},
	{path: "/dev/consultant", component: ConsultantTest},
	{path: "/dev/find-appointment", component: FindAppointmentTest},
	{path: "/dev/time-input", component: TimeInputTest},
	{path: "/dev/consultant-selector", component: ConsultantSelectorTest},
	{path: "/dev/order-extend", component: OrderExtendTest},
	{path: "/dev/order-move", component: OrderMoveTest},
	{path: "/dev/order-quick-service", component: OrderServiceTest},
	{path: "/dev/dynamic-schedule", component: DynamicScheduleTest},
	{path: "/dev/dynamic-schedule-analyzer", component: DynamicScheduleAnalyzerTest},
	{path: "/dev/wheel-list", component: WheelListTest},
	{path: "/dev/order-wheel", component: OrderWheelTest},
	{path: "/dev/ewo-tw", component: EtoTwTest},
	{path: "/dev/component", component: ComponentTest},
	{path: "/dev/house-schedule", component: HouseScheduleTest},
];

const name = value => {
	value = value.split("/");
	value.shift();
	value.shift();
	return value.join("/");
};

const Routes = () => {
	return (
		<Segment basic>
			<ActionHeader alignment={"flex-start"}>
				<ActionHeading>Development</ActionHeading>
			</ActionHeader>
			<EasyFlex wrap={EasyFlex.wrap.YES}>
				{devRoutes.map(({path}) => <div style={{padding: 10, margin: 8, border: "1px solid #ddd"}} key={path}><NavLink to={path}>{name(path)}</NavLink></div>)}
			</EasyFlex>
		</Segment>
	);
};

class Development extends React.Component {
	state = {
		view: 'dev'
	};
	
	render() {
		// const {view} = this.state;
		return (
			<ConnectedRouter history={history}>
				<Switch>
					<Route exact path={"/dev"} component={Routes}/>
					{devRoutes.map(routeProps => <Route key={routeProps.path} {...routeProps}/>)}
				</Switch>
			</ConnectedRouter>
		);
	}
}

export default Development;