import * as React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {Popup} from "semantic-ui-react";
import {IconShortText as DefaultIcon, IconClose as ClearIcon} from "../../Logic/icons";
import {COLOR_SEMANTIC_ERROR, DATE_MASK_PROPS, DATE_TIME_MASK_PROPS, TIME_MASK_PROPS} from "../../Logic/constants";
import InputMask from "react-input-mask";
import {isArray, isFunction, isEqual} from "lodash";
import {translate} from "react-i18next";
import {Required, trueNull} from "../../Logic/extensions";
import moment from "../../Logic/Moment";

/**
 * Pencil icon on the right, originally set with iconPosition 'left right', but this validates the propTypes.
 * Thus using this style for the input if icon is visible
 * @type {{borderTopRightRadius: number, borderBottomRightRadius: number, borderRight: string}}
 */
const INPUT_ON_RIGHT_ICON_STYLE = {
	borderTopRightRadius: 0,
	borderBottomRightRadius: 0,
	borderRight: 'none'
};

const PROP_TYPES = {
	value: PropTypes.string,
	icon: PropTypes.any,
	editable: PropTypes.bool,
	editPopup: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	text: PropTypes.string,
	readOnly: PropTypes.bool,
	errorText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]),
	error: PropTypes.bool,
	autoComplete: PropTypes.string,
	labelPosition: PropTypes.string,
	errorBeneeth: PropTypes.bool,
	before: PropTypes.node,
	clearable: PropTypes.bool,
	// mask props
	mask: PropTypes.string,
	maskChar: PropTypes.string,
	formatChars: PropTypes.object,
	alwaysShowMask: PropTypes.bool,
	inputRef: PropTypes.func,
	beforeMaskedValueChange: PropTypes.func,
	permanents: PropTypes.arrayOf(PropTypes.number),
	maskFunc: PropTypes.func,
	extractValue: PropTypes.func
};

const DEFAULT_PROPS = {
	icon: <DefaultIcon/>,
	editable: false,
	readyOnly: false,
	editPopup: null,
	errorText: null,
	error: false,
	autoComplete: "off",
	errorBeneeth: false,
	required: false,
	clearable: false
};

/**
 * function to inject some styles, etc. into given icon...
 * @param icon
 * @param color
 * @param error
 * @return {*}
 * @private
 */
const _injectIcon = (icon, color='black', error = false) => typeof icon === 'string' ? <span
	style={{color: error ? COLOR_SEMANTIC_ERROR : color}}>{icon}</span> : React.cloneElement(icon, {color: error ? COLOR_SEMANTIC_ERROR : color});

export class MaskField extends React.Component {
	static propTypes = PROP_TYPES;
	static defaultProps = DEFAULT_PROPS;
	state = {
		focused: false,
		value: ""
	};
	defaultEmpty = "";
	
	constructor(props) {
		super(props);
		// check value is predefined
		if ( props.value !== undefined ) {
			this.state.value = props.value;
		}
		// set empty mask
		this.createDefaultEmpty(props)
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {value, ...props} = this.props
		if (value !== prevProps.value) {
			this.setState({value});
		}
		// check if masking params changed and change the empty mask value if necessary
		const cmp = ["mask", "maskChar", "formatChars"];
		for(const type of cmp) {
			if (props[type] && !isEqual(prevProps[type], props[type])) {
				this.createDefaultEmpty(props);
				break;
			}
		}
	}
	
	createDefaultEmpty = ({mask, maskChar, formatChars}) => {
		if ( mask && maskChar && formatChars) {
			let string = "";
			for (const char of mask.split("")) {
				if ( char in formatChars) {
					string += maskChar;
				} else {
					string += char;
				}
			}
			this.defaultEmpty = string;
		}
	};
	
	change = e => this.setState({value: e.target.value}, this.onChange.bind(null, e));
	reset = e => this.setState({value: "", focused: false}, this.onChange.bind(null, e));
	focus = next => e => this.setState({focused: true}, () => isFunction(next) && next(e));
	blur = next => e => this.setState({focused: false}, () => isFunction(next) && next(e));
	extract = () => this.props.extractValue ? this.props.extractValue(this.state.value) : this.state.value;
	onChange = (e) => this.props.onChange ? this.props.onChange(e, this.state.value, this.extract()) : null;
	
	translate = errorText => isArray(errorText) ? this.props.t.call(null, errorText[0], errorText[1] || {}) : this.props.t(errorText);
	
	mask = () => this.props.maskFunc ? this.props.maskFunc(this.state.value, this.props.mask) : this.props.mask;
	
	empty = () => {
		const value = this.state.value.trim();
		return value === "" || this.defaultEmpty === value;
	};
	
	render() {
		const {
			t, tReady, i18n,
			editPopup, errorText, errorBeneeth, error,
			readyOnly, editable, autoComplete, required, clearable,
			icon, before, children,
			labelPosition, text, label, placeholder,
			onChange, onFocus, onBlur, maskFunc, mask, extractValue,
			...props
		} = this.props;
		const {
			value,
			focused
		} = this.state;
		const blocked = readyOnly || !editable;
		const showPopup = Boolean((errorText && !errorBeneeth) || editPopup);
		const popup = errorText || editPopup;
		const labelText = label || text;
		const placeholderText = placeholder || text;
		const Icon = _injectIcon(icon, blocked ? "lightgray" : "black", errorText);
		const empty = this.empty();
		const hasError = Boolean(error || errorText);
		const clearIconVisible = trueNull(clearable && !empty);
		
		return(
			<React.Fragment>
				<div className={cn("mask-field", {
					"label-on-top": labelPosition === "top",
					focused: focused,
					readyOnly: blocked,
					error: hasError,
					"error-beneeth": errorBeneeth,
					required,
					clearable
				})}>
					{labelText && <label className={cn("mask-field-label")}>{labelText}<Required visible={required}/></label>}
					<div className={cn("mask-field-input")}>
						<div className="mask-field-icon">
							{showPopup ? <Popup inverted position={"top left"} content={this.translate(popup)} trigger={Icon} /> : Icon}
							
						</div>
						<InputMask
							{...props}
							mask={this.mask()}
							value={value}
							onChange={this.change}
							onFocus={this.focus(onFocus)}
							onBlur={this.blur(onBlur)}
							disabled={blocked}
							required={required}
						>
							{(inputProps) => <input {...inputProps} value={value} required={required} disabled={blocked} autoComplete={autoComplete} placeholder={placeholderText} style={!blocked ? INPUT_ON_RIGHT_ICON_STYLE : null}/>}
						</InputMask>
						{clearIconVisible && <div className={cn("mask-field-clear")} onClick={this.reset}>
							<ClearIcon color={hasError ? COLOR_SEMANTIC_ERROR : null}/>
						</div>}
						{children}
					</div>
					{errorBeneeth && errorText ? <div className={cn("mask-field-error")}>{this.translate(errorText)}</div> : null}
				</div>
				
			</React.Fragment>
		);
	}
}
MaskField = translate()(MaskField);


const _asTime = (Component) => ({withSeconds, extractValue, ...props}) => {
	const extract = extractValue || (value => {
		const [hours, minutes, seconds] = value.trim().split(":");
		const m = moment(`${moment().format("YYYY-MM-DD")} ${value}`);
		return {
			date: m.isValid() ? m.toDate() : null,
			hours: parseInt(hours),
			minutes: parseInt(minutes),
			seconds: parseInt(seconds)
		}
	});
	return <Component
		{...TIME_MASK_PROPS}
		mask={withSeconds ? "29:59:59" : TIME_MASK_PROPS.mask}
		extractValue={extract}
		{...props}
	/>;
};

const _asDate = (Component) => ({extractValue, ...props}) => {
	const extract = extractValue || (value => {
		const [days, months, years] = value.trim().split(".");
		const m = moment(value, "L");
		return {
			date: m.isValid() ? m.toDate() : null,
			years: parseInt(years),
			months: parseInt(months),
			days: parseInt(days)
		};
	});
	return <Component
		{...DATE_MASK_PROPS}
		extractValue={extract}
		{...props}
	/>;
};

const _asDateTime = (Component) => ({withSeconds, extractValue, ...props}) => {
	const extract = extractValue || (value => {
		const [date, time] = value.trim().split(" ").filter(f => f.trim() !== "");
		const [hours, minutes, seconds] = time.split(":");
		const [days, months, years] = date.split(".");
		const m = moment(value);
		return {
			date: m.isValid ? m.toDate() : null,
			years: parseInt(years),
			months: parseInt(months),
			days: parseInt(days),
			hours: parseInt(hours),
			minutes: parseInt(minutes),
			seconds: parseInt(seconds)
		}
	});
	
	return <Component
		{...DATE_TIME_MASK_PROPS}
		mask={withSeconds ? DATE_TIME_MASK_PROPS.mask + ":59" : DATE_TIME_MASK_PROPS.mask}
		extractValue={extract}
		{...props}
	/>;
};

export const TimeMaskField = _asTime(MaskField);
TimeMaskField.propTypes = {
	...PROP_TYPES,
	withSeconds: PropTypes.bool
};

export const DateMaskField = _asDate(MaskField);
DateMaskField.propTypes = {
	...PROP_TYPES,
};

export const DateTimeMaskField = _asDateTime(MaskField);
DateTimeMaskField.propTypes = {
	...PROP_TYPES,
	withSeconds: PropTypes.bool,
};