import * as React from "react";
import PropTypes from "prop-types";
import {Icon, Popup, Segment} from "semantic-ui-react";
import {Flex} from "../../../components/partials/ActionHeader";
import {Divider, FlatButton, IconButton, RaisedButton, TextField} from "material-ui";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../Tools/RightsProvider";
import {SECTION} from "../../../Logic/constants";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import NoAccess from "../../../components/NoAccess";
import {isOnline} from "../../../actions/userActions";
import {vehicleAction__fetch} from "../../../actions/vehicleActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_VEHICLES_FETCH} from "../../../actions";
import {searchAction__loanCar, searchAction__vehicleSimple} from "../../../actions/searchActions";
import VirtualList from "react-tiny-virtual-list";
import {pickBy} from "lodash";
import {IconAdd} from "../../../Logic/icons";
import {loanCarCall__findInRange} from "../../../actions/loanCarActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {StatyComponent} from "../../../Tools/ReactExtension";
import {moment} from "../../../Logic/Moment";
import {array2object} from "../../../Logic/extensions";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import fuzzy from 'fuzzysearch';

const Line = ({title, bordered, children, style, ...props}) => <Flex align={"space-between"} vmargin={8}
                                                                     valign={"center"}
                                                                     style={{...style, ...(bordered ? {borderBottom: `1px dotted ${bordered}`} : {})}} {...props}>
	<div style={{fontSize: "smaller", color: "#ccc", minWidth: 80}}>{title}:</div>
	<div style={{minWidth: 20}}/>
	<em style={{whiteSpace: "nowrap"}}>{children}</em>
</Flex>;

const PopupInfo = ({vehicle: {hsn, tsn, chassis_number, registration_mark, name}, ...props}) => {
	return <Segment basic inverted {...props}>
		<Line title={"Name"} bordered={"#666"}>{name}</Line>
		<Line title={"HSN/TSN"} bordered={"#666"}>{hsn} / {tsn}</Line>
		<Line title={"Kennzeichen"} bordered={"#666"}>{registration_mark}</Line>
		<Line title={"Fahr.gstl.nr"} bordered={"#666"}>{chassis_number}</Line>
	</Segment>;
};

class LoanCarSearchController extends StatyComponent {
	static propTypes = {
		...RIGHT_PROPS,
		onSearch: PropTypes.func,
		onAbort: PropTypes.func,
		selectOnClick: PropTypes.bool,
		searchDelay: PropTypes.number,
		onSelect: PropTypes.func,
		client_id: PropTypes.number,
		focus: PropTypes.bool,
		delayed: PropTypes.bool.isRequired,
		startPoint: PropTypes.instanceOf(Date).isRequired,
		endPoint: PropTypes.instanceOf(Date).isRequired,
		onFind: PropTypes.func,
		onNotificationBad: PropTypes.func,
		loanCarScheduleId: PropTypes.number
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		loading: false,
		vehicleMap: {},
		selectOnClick: false,
		searchDelay: 500,
		loanCarMode: false,
		focus: true,
		onSearch: () => alert('VehicleSearchController::onSearch() is not implemented, yet!'),
		onFind: (params) => loanCarCall__findInRange(params),
		onNotificationBad: (message) => dispatchSnack(message, 'alert')
	};
	
	delay = null;
	
	state = {
		fetching: false,
		list: [],
		searchText: "",
		selection: null
	};
	
	inputField = React.createRef();
	
	componentDidMount() {
		super.componentDidMount();
		this.props.focus && this.inputField.current.focus();
		this.fetch();
	}
	
	setCreateVehicle = (createVehicle) => () => this.setState({createVehicle});
	
	afterVehicleCreate = () => {
		this.setCreateVehicle(false)();
		this.search();
	};
	
	onType = (e, value) => {
		let {searchDelay} = this.props;
		searchDelay = Math.max(0, searchDelay);
		window.clearTimeout(this.delay);
		this.delay = window.setTimeout(this.setSearch.bind(null, value), searchDelay);
	};
	
	setSearch = value => {
		this.setState({searchText: value});
	};
	
	fetch = async () => {
		const {onFind, onNotificationBad, startPoint, endPoint, delayed, loanCarScheduleId} = this.props;
		try {
			this.setState({fetching: true});
			let data = {
				start_point: startPoint.toISOString(),
				end_point: endPoint.toISOString(),
				no_delay: !delayed,
			};
			if (loanCarScheduleId) {
				data.loan_car_schedule_id = loanCarScheduleId;
			}
			const response = await onFind(data);
			this.setState({list: response.list});
		} catch (e) {
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({fetching: false});
		}
	};
	
	// search = async () => {
	// 	const {searchText} = this.state;
	// 	const {onSearch, client_id, loanCarMode} = this.props;
	// 	try {
	// 		this.setState({loading: true, searchError: null});
	// 		const response = loanCarMode ? await onSearch(searchText, true) : await onSearch(searchText, client_id);
	// 		this.setState({
	// 			searchResults: response.result,
	// 			searchError: response.result.length ? null : "Keine Ergebnisse"
	// 		});
	// 	} catch (e) {
	// 		console.error("error in vehicle search", e);
	// 		this.setState({searchError: e.message});
	// 	} finally {
	// 		this.setState({loading: false});
	// 	}
	// };
	//
	onSelect = (selection = null) => {
		const {onSelect, onAbort} = this.props;
		selection = selection || this.state.selection;
		if (onSelect && selection) {
			onSelect(selection);
			onAbort && onAbort();
		}
	};
	
	selectVehicle = selection => () => this.setState({selection}, () => {
		if (this.props.selectOnClick) {
			this.onSelect(selection);
		}
	});
	
	filter = (list, searchText) => {
		if (!searchText.trim()) {
			return list;
		}
		const searchKeys = searchText.toLowerCase().split(/\s+/).filter(Boolean);
		let map = array2object(list || [])(i => i.vehicle_id);
		const add2map = (id) => {
			if (!map[id]['hits']) {
				map[id]['hits'] = 1;
			} else {
				map[id]['hits']++;
			}
		}
		// search name
		for(const search of searchKeys) {
			list.filter(v => fuzzy(search, v.name.toLowerCase())).forEach(v => add2map(v.vehicle_id));
			list.filter(v => fuzzy(search, v.registration_mark.toLowerCase())).forEach(v => add2map(v.vehicle_id));
		}
		console.debug(searchKeys, map);
		// console.debug(Object.entries(map).sort((l, r) => r[1] > l[1] ? -1 : 1).map((_, id) => list.find(v => v.vehicle_id === id)));
		return Object.values(map).filter(v => v.hits).sort((l, r) => r.hits > l.hits ? 1 : -1);// Object.entries(map).sort((l, r) => r[1] > l[1] ? -1 : 1).map((_, id) => list.find(v => v.vehicle_id === id));
	}
	
	memFilter = memoizeOne(this.filter);
	
	render() {
		const {
			rights: {mayRead},
			loading: propLoading,
			// vehicleMap,
			onAbort,
			selectOnClick,
			startPoint,
			endPoint
		} = this.props;
		
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		const {
			fetching,
			searchText,
			list,
			selection
		} = this.state;
		
		
		let vehicles = this.memFilter(list, searchText);
		let vehicleCount = vehicles ? vehicles.length : 0;
		
		return (
			<Segment basic loading={propLoading}>
				<Flex align={"space-between"} valign={"center"}>
					<h3 style={{marginBottom: 15}}>{fetching && <Icon name={'spinner'} loading/>}Verfügbare Leihfahrzeuge zw. {moment(startPoint).format('L LT')} und {moment(endPoint).format('L LT')}</h3>
					{this.props.client_id ?
						<IconButton onClick={this.setCreateVehicle(true)}><IconAdd/></IconButton> : null}
				</Flex>
				<Flex valign={"center"}>
					<TextField
						ref={this.inputField}
						fullWidth
						floatingLabelText={'Filter'}
						hintText={"Kennzeichen, Marke, Variante"}
						errorText={searchText.trim() && !vehicleCount ? 'Keine Ergebnisse' : null}
						onChange={this.onType}
					/>
					{/*<Icon style={{marginTop: 25, marginLeft: 10, opacity: searchText.trim() ? 1 : 0}} name={"close"} onClick={this.setSearch.bind(null, '')}/>*/}
				</Flex>
				<VirtualList
					width={'calc(100% - 20px)'}
					height={350}
					itemCount={vehicles ? vehicleCount : 0}
					itemSize={35}
					renderItem={({index, style}) => {
						const vehicle = vehicles[index];
						const isSelected = selection && vehicle.vehicle_id === selection.vehicle_id;
						return (
							<Flex className="vehicle-search-controller-item" key={vehicle.vehicle_id}
							      onClick={this.selectVehicle(vehicle)} style={{
								...style,
								paddingLeft: 10,
								paddingRight: 10,
								backgroundColor: isSelected ? 'lightgreen' : null,
								cursor: 'pointer'
							}} valign={"center"}>
								<span/>
								<Flex grow={10} align={"space-between"} valign={"center"}>
									<Popup content={<PopupInfo vehicle={vehicle}/>} inverted trigger={<span
										style={{marginLeft: 15, userSelect: 'none'}}>{vehicle.name}</span>}/>
									<div style={{minWidth: 20}}/>
									<span style={{color: "#666", marginRight: 15}}>{vehicle.registration_mark}</span>
								</Flex>
							</Flex>
						);
					}}
				/>
				<div style={{marginBottom: 12}}/>
				<Divider/>
				<Flex valign={"center"} align={"space-between"}>
					<span>{onAbort && <FlatButton onClick={onAbort}>Abbrechen</FlatButton>}</span>
					<span>{!selectOnClick && <RaisedButton primary onClick={this.onSelect}
					                                       disabled={selection === null}>Auswählen</RaisedButton>}</span>
				</Flex>
			</Segment>
		);
	}
}

// Controllers setup
LoanCarSearchController = withRights(SECTION.CLIENTS, LoanCarSearchController);
// VehicleSearchController = withInit(VehicleSearchController);
LoanCarSearchController = connect(
	(state, props) => {
		let vehicleMap = props.vehicleMap || state.vehicles.list;
		if (props.client_id) {
			vehicleMap = pickBy(vehicleMap, v => v.client_id === props.client_id);
		}
		return {
			vehicleMap,
			loading: isLoading(state, PROC_VEHICLES_FETCH)
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(vehicleAction__fetch());
			})),
			onSearch: props.loanCarMode ? searchAction__loanCar : searchAction__vehicleSimple
		}
	}
)(LoanCarSearchController);
LoanCarSearchController = translate()(LoanCarSearchController);


export default LoanCarSearchController;