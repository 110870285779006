import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {Form, FormField} from "semantic-ui-react";
import PasswordIcon from 'material-ui/svg-icons/hardware/security';
import {Subbar} from "ah-tools";
import {translate} from "react-i18next";
import EditField from "../../partials/EditField";
import isFunc from 'lodash/isFunction';
import {connect} from "react-redux";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_USER_PASSWORD} from "../../../actions";
import {updatePasswordOfUser} from "../../../actions/userActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {async} from "ith-fetch";

class WorkersDetailChangePassword extends React.PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		isLoading: PropTypes.bool,
		onUpdateLayout: PropTypes.func
	};
	static defaultProps = {
		onChange: () => alert('onChange() is not implemented yet'),
		isLoading: false,
		onUpdateLayout: () => {}
	};
	
	state = {
		pw_old: '',
		pw: '',
		pw2: ''
	};
	
	reset = () => this.setState({
		pw_old: '',
		pw: '',
		pw2: '',
		expanded: false
	});
	
	handleChange = (e, {name, value}) => this.setState({[name]: value});
	
	isValid = () => {
		const {
			pw_old,
			pw,
			pw2
		} = this.state;
		return Boolean(
			pw_old.length >= 6 && pw.length >= 6 && pw === pw2
		);
	};
	
	handleSubmit = () => {
		if ( !this.isValid() ) return;
		const {pw_old, pw} = this.state;
		this.props.onChange(pw_old, pw, this.reset);
	};
	
	handleExpand = () => {
		this.setState(state => ({
			expanded: !state.expanded
		}), () => {
			async(100).then(this.props.onUpdateLayout);
		});
	};
	
	oldNotGiven = (old, pw, pw2) => Boolean((pw.length || pw2.length) && old.length < 6);
	oldNotGivenText = (old, pw, pw2) => this.oldNotGiven(old, pw, pw2) ? this.props.t('worker.detail.errors.password-required') : null;
	tooShort = pw => pw.length > 0 && pw.length < 6;
	missMatch = (pw, pw2) => pw2.length > 0 && pw !== pw2;
	passwordErrorText = pw => this.tooShort(pw) ? this.props.t('worker.detail.errors.password-min-length', {length: 6}) : null;
	passwordMissmatchText = (pw, pw2) => this.missMatch(pw, pw2) ? this.props.t('worker.detail.errors.password-mismatch') : null;
	
	render() {
		const {t, isLoading} = this.props;
		const {pw_old, pw, pw2, expanded} = this.state;
		return(
			<Card expanded={expanded} onExpandChange={this.handleExpand}>
				<CardHeader title={t('worker.detail.parts.worker-password')} subtitle={t('worker.detail.parts.change-password')} actAsExpander showExpandableButton className={'ith-card-pointer'}/>
				<CardText expandable>
					<Form onSubmit={this.handleSubmit} loading={isLoading}>
						<FormField>
							<EditField
								icon={<PasswordIcon/>}
								required
								name={'pw_old'}
								text={t('worker.detail.labels.current-password')}
								type={'password'}
								editable
								value={pw_old}
								onChange={this.handleChange}
								// error={this.oldNotGiven(pw_old, pw, pw2)}
								errorText={this.oldNotGivenText(pw_old, pw, pw2)}
							/>
						</FormField>
						
						<FormField>
							<EditField
								icon={<PasswordIcon/>}
								required
								name={'pw'}
								text={t('worker.detail.labels.new-password')}
								type={'password'}
								editable
								value={pw}
								onChange={this.handleChange}
								// error={this.tooShort(pw)}
								errorText={this.passwordErrorText(pw)}
							/>
						</FormField>
						
						<FormField>
							<EditField
								icon={<PasswordIcon/>}
								required
								name={'pw2'}
								text={t('worker.detail.labels.confirm-password')}
								type={'password'}
								editable
								value={pw2}
								onChange={this.handleChange}
								// error={this.missMatch(pw, pw2)}
								errorText={this.passwordMissmatchText(pw, pw2)}
							/>
						</FormField>
						
						<Subbar align={Subbar.ALIGN_END}>
							<RaisedButton disabled={!this.isValid()} type={'submit'} primary ><span style={{paddingLeft: 10, paddingRight: 10}}>{t('actions.change-password')}</span></RaisedButton>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}

WorkersDetailChangePassword = translate()(WorkersDetailChangePassword);

export default WorkersDetailChangePassword;

const m2s = state => ({
	isLoading: isLoading(state, PROC_USER_PASSWORD)
});
const m2d = dispatch => ({
	onChange: (old_one, new_one, onSuccess) => dispatch(updatePasswordOfUser(old_one, new_one, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.worker.password-changed'));
	}))
});

export const WorkersDetailChangePasswordController = connect(m2s, m2d)(WorkersDetailChangePassword);
WorkersDetailChangePasswordController.propTypes = {
	onUpdateLayout: PropTypes.func
};