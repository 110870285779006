import * as React from "react";
import {EasyFlex, Flex, FlexChild} from "../../../components/partials/ActionHeader";
import {FlatButton, IconButton} from "material-ui";
import {falseNull, trueNull} from "../../../Logic/extensions";
import {IconCar, IconClose} from "../../../Logic/icons";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {Popup as VehiclePopup} from "../../../components/intern/vehicles";
import {ClientSearchModal} from "../../../components/intern/clients/search/ClientSearch";
import cn from 'classnames';

export class OrderVehicleSelector extends React.PureComponent {
	static propTypes = {
		readOnly: PropTypes.bool,
		minLabelWidth: PropTypes.number,
		vehicle: PropTypes.object,
		client: PropTypes.object,
		onSelectVehicle: PropTypes.func.isRequired,
		onUnselect: PropTypes.func,
		defaultOpen: PropTypes.bool
	};
	static defaultProps = {
		minLabelWidth: 120,
		defaultOpen: false,
		readOnly: false
	};
	state = {
		open: false
	};
	
	componentDidMount() {
		this.setState({open: this.props.defaultOpen})
	}
	
	toggle = open => () => this.setState({open});
	onSelect = vehicle => {
		this.props.onSelectVehicle(vehicle);
		this.toggle(false)();
	};
	
	render() {
		const {open} = this.state;
		const {minLabelWidth, vehicle, client, onUnselect, defaultOpen, onSelectVehicle, i18n, tReady, t, readOnly, ...props} = this.props;
		return (
			<React.Fragment>
				<Flex style={{borderBottom: '1px dashed #eee'}} valign={"center"} gutterWidth={24} {...props}>
					<FlexChild style={{minWidth: minLabelWidth}}><strong>{t("vehicle")}:</strong></FlexChild>
					<FlexChild onClick={falseNull(vehicle) && this.toggle(true)} className={cn("cursor", {pointer: !vehicle})} shrink={0.1} grow={100}>
						{
							vehicle ? <VehiclePopup vehicle={vehicle}><EasyFlex valign={EasyFlex.valign.CENTER} style={{fontWeight: 'bold'}}><IconCar style={{position: 'relative', top: -2}}/> <span>{vehicle.name}</span></EasyFlex></VehiclePopup> : <span>{t("actions.please-select")}</span>
						}
						{/*<VehiclePopup vehicle={vehicle}><span>{vehicle ? (vehicle.name) : t("actions.please-select")}</span></VehiclePopup>*/}
					</FlexChild>
					<FlexChild>
						{falseNull(readOnly) && <Flex valign={"center"}>
							<FlatButton secondary onClick={this.toggle(true)}>{t("actions.search")}</FlatButton>
							{trueNull(vehicle || client) && <IconButton style={vehicle && onUnselect ? {} : {pointerEvents: "none", opacity: 0}} onClick={onUnselect}><IconClose/></IconButton>}
						</Flex>}
					</FlexChild>
				</Flex>
				{open &&
					<ClientSearchModal
						open={open}
						onModalClose={this.toggle(false)}
						onSelectVehicle={this.onSelect}
						directSelect
					/>
					// <Dialog className={"order-create-dialog"} open onRequestClose={this.toggle(false)}>
					// 	<VehicleSearchController
					// 		onAbort={this.toggle(false)}
					// 		onSelect={this.onSelect}
					// 		client_id={client ? client.client_id : null}
					// 		selectOnClick/>
					// </Dialog>
				}
			</React.Fragment>
		);
	}
}
OrderVehicleSelector = translate()(OrderVehicleSelector);