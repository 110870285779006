import * as React from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader, CardText, RaisedButton} from "material-ui";
import {translate} from "react-i18next";
import { Form, FormField,  FormTextArea} from "semantic-ui-react";
import {_empty, _req,  Loadable} from "../../../../Logic/extensions";
import HomeIcon from 'material-ui/svg-icons/action/home';
import LinkIcon from 'material-ui/svg-icons/content/link';
import {Subbar} from "ah-tools";
import EditField from "../../../partials/EditField";



class MDHouseData extends React.PureComponent {
	static propTypes = {
		t: PropTypes.func,
		i18n: PropTypes.object,
		tReady: PropTypes.any,
		house: PropTypes.shape({
			name: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
			business_hours: PropTypes.string,
			postal_address: PropTypes.string,
			website: PropTypes.string
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool,
		mayChange: PropTypes.bool
	};
	
	static defaultProps = {
		isSaving: false,
		mayChange: false
	};
	
	state = {};
	
	handleChange = (e, {name, value}) => {
		if (this.props.house[name] === value) {
			return this.setState({[name]: undefined});
		}
		return this.setState({[name]: value});
	};
	
	isValid = () => {
		const save = this.state;
		return !_empty(save) &&
			_req(save.name) &&
			_req(save.link) &&
			_req(save.postal_address);
	};
	
	handleSave = () => {
		this.props.onSave(this.props.house, this.state, () => this.setState({
			name: undefined,
			link: undefined,
			postal_address: undefined,
			business_hours: undefined,
			website: undefined
		}));
	};
	
	render() {
		const {t, house, isSaving, mayChange: editable} = this.props;
		return(
			<Card>
				<CardHeader title={t('worker.detail.parts.basisdata')} subtitle={t('worker.detail.parts.carhouse')}/>
				<CardText>
					<Form onSubmit={this.handleSave}>
						<FormField>
							<EditField icon={<HomeIcon/>} text={t('worker.detail.labels.carhouse-name')} required defaultValue={house.name} name={'name'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<FormField>
							<EditField icon={<LinkIcon/>} text={t('worker.detail.labels.carhouse-link')} required defaultValue={house.link} name={'link'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<FormField>
							<FormTextArea
								required
								label={t('worker.detail.labels.carhouse-address')}
								defaultValue={house.postal_address}
								name={'postal_address'}
								// autoHeight
								onChange={this.handleChange}
								readOnly={!editable}
							/>
						</FormField>
						<FormField>
							<EditField icon={'http(s)://'} text={t('worker.detail.labels.carhouse-website')} defaultValue={house.website} name={'website'} onChange={this.handleChange} editable={editable}/>
						</FormField>
						<FormField>
							<FormTextArea
								label={t('worker.detail.labels.carhouse-business-hours')}
								defaultValue={house.business_hours}
								name={'business_hours'}
								// autoHeight
								onChange={this.handleChange}
								readOnly={!editable}
							/>
						</FormField>
						<Subbar style={{marginTop: 20}} align={Subbar.ALIGN_END}>
							<Loadable loading={isSaving} disabledOnLoading onClick={this.handleSave} disabled={!this.isValid()} inverse type={'submit'} primary hoc={RaisedButton}>{t('actions.save')}</Loadable>
						</Subbar>
					</Form>
				</CardText>
			</Card>
		);
	}
}

export default translate()(MDHouseData);