import * as React from 'react';
import PropTypes from 'prop-types';
import {AutoComplete, Card, CardActions, CardHeader, CardText, FlatButton, Snackbar} from "material-ui";
import {translate} from "react-i18next";

class MDTimeZone extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			typedTimezone: '', // typezone typed in autocomplete
			showSaved: false  // show snackbar for saved action
		};
		
		// functional bindings
		this.isValid = this.isValid.bind(this);
		this.updateEntry = this.updateEntry.bind(this);
		this.showError = this.showError.bind(this);
	}

	errorText = () => this.props.t('worker.detail.labels.invalid-timezone'); // error text
	
	// update typing
	updateEntry(value) {
		value = value.trim();
		this.setState({
			typedTimezone: value
		});
	}
	
	// validates entry
	isValid() {
		const {timezones} = this.props;
		return timezones.some(zone => zone === this.state.typedTimezone);
	}
	
	// return error text if not valid
	showError() {
		return this.state.typedTimezone.length && !this.isValid() ? this.errorText() : null;
	}
	
	render() {
		const {
			timezones,
			onSaveTimezone,
			currentTimezone,
			mayChange,
			t
		} = this.props;
		const timezone = currentTimezone || t('worker.detail.labels.select-timezone'); // default value for autocomplete
		// Rights
		// const mayChange = user.hasAccessTo(SECTION.MASTER_DATA, User.RIGHT_WRITE);
		return (
			<Card>
				<CardHeader title={t('worker.detail.parts.basic-settings')} subtitle={t('worker.detail.parts.timezone')}/>
				<CardText>
					<AutoComplete
						errorText={this.showError()}
						onNewRequest={this.updateEntry}
						onUpdateInput={this.updateEntry}
						listStyle={{maxHeight: 200, overflowY: 'auto'}}
						openOnFocus={true}
						hintText={timezone}
						disabled={!mayChange}
						dataSource={timezones}
						searchText={this.state.typedTimezone}
						filter={AutoComplete.fuzzyFilter}
					/>
				</CardText>
				<CardActions style={{display: 'flex', justifyContent: 'flex-end'}}>
					<FlatButton primary={true} label="Speichern" disabled={!this.isValid()}
					            onClick={() => onSaveTimezone && onSaveTimezone(this.state.typedTimezone, () => {
						            this.setState({typedTimezone: '', showSaved: true});
					            })}/>
				</CardActions>
				<Snackbar open={this.state.showSaved} message={t('snackbar.md-timezone.saved')} autoHideDuration={3000}
				          onRequestClose={() => this.setState({showSaved: false})}/>
			</Card>
		);
	}
}

MDTimeZone.propTypes = {
	timezones: PropTypes.arrayOf( PropTypes.string ).isRequired,
	onSaveTimezone: PropTypes.func,
	currentTimezone: PropTypes.string,
	mayChange: PropTypes.bool
};
MDTimeZone.defaultProps = {
	timezones: [],
	currentTimezone: null,
	onSaveTimezone: null,
	mayChange: false
};

export default translate()(MDTimeZone);