import * as React from "react";
import PropTypes from "prop-types";
import {Button, Modal, Popup, Segment, Table} from "semantic-ui-react";
import {StatyComponent} from "../../Tools/ReactExtension";
import {isMobile} from 'react-device-detect';
import {Trans} from "react-i18next";

export const NoBreak = ({children, style, ...props}) => <span style={{whiteSpace: "nowrap", ...style}} {...props}>{children}</span>;
export const LoadingSegment = ({height, style, ...props}) => <Segment style={{minHeight: height, ...style}} basic loading {...props}/>;
LoadingSegment.propTypes = {
	height: PropTypes.number,
	style: PropTypes.object
};
LoadingSegment.defaultProps = {
	height: 50
};
export const LoadingTableRow = ({rowProps, segmentProps, segmentStyle, height, colSpan, ...props}) => (
	<Table.Row {...rowProps}>
		<Table.Cell colSpan={colSpan} {...props}>
			<LoadingSegment height={height} style={segmentStyle} {...segmentProps}/>
		</Table.Cell>
	</Table.Row>
);
LoadingTableRow.propTypes = {
	rowProps: PropTypes.object,
	segmentProps: PropTypes.object,
	segmentStyle: PropTypes.object,
	height: PropTypes.number,
	colSpan: PropTypes.number
};
LoadingTableRow.defaultProps = {
	colSpan: 1
};

export const IfMobile = ({as, ...props}) => {
	if (isMobile) {
		const Wrapper = as;
		return <Wrapper {...props}/>;
	}
	return null;
}
IfMobile.propTypes = {
	as: PropTypes.any
};
IfMobile.defaultProps = {
	as: React.Fragment
}

export class ContextPopup extends StatyComponent {
	static propTypes = {
		controlled: PropTypes.bool,
		children: PropTypes.element,
		trigger: PropTypes.element,
		content: PropTypes.node,
		basic: PropTypes.bool,
		inverted: PropTypes.bool,
		className: PropTypes.string,
		context: PropTypes.any,
		eventsEnabled: PropTypes.bool,
		flowing: PropTypes.bool,
		header: PropTypes.element,
		hoverable: PropTypes.bool,
		offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		onMount: PropTypes.func,
		onOpen: PropTypes.func,
		onUnmount: PropTypes.func,
		pinned: PropTypes.bool,
		popperDependencies: PropTypes.array,
		popperModifiers: PropTypes.object,
		position: PropTypes.string,
		positionFixed: PropTypes.bool,
		size: PropTypes.string,
		style: PropTypes.object,
		whide: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		on: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		modal: PropTypes.bool
	}
	static defaultProps = {
		basic: true,
		controlled: true,
		on: ['click', 'hover']
	}
	state = {
		open: false
	}
	
	onContextMenu = e => {
		if (this.props.controlled) {
			e.preventDefault();
			console.debug('onContextMenu clicked');
			this.setState(state => ({open: !state.open}));
		}
	}
	
	onClose = (native) => (e) => {
		this.setState({open: false});
		native && native(e);
	}
	
	render() {
		const {children, trigger, on, controlled, onClose, modal, ...props} = this.props;
		const content = trigger || children;
		if (controlled && modal) {
			return <React.Fragment>
				{React.isValidElement(content) && React.cloneElement(content, {onContextMenu: this.onContextMenu, onClick: () => this.setState({open: true})})}
				<Modal size={'tiny'} basic open={this.state.open} onClose={this.onClose(onClose)}>
					<Modal.Content>{this.props.content}</Modal.Content>
					<Modal.Actions><Button inverted onClick={this.onClose(onClose)}><Trans defaults={"Schließen"}/></Button></Modal.Actions>
				</Modal>
			</React.Fragment>
		}
		return (
			<Popup {...props}
			       trigger={content ? (controlled ? React.cloneElement(content, {onContextMenu: this.onContextMenu}) : content) : null}
			       on={controlled ? 'click' : on}
			       open={controlled ? this.state.open : undefined}
			       onClose={this.onClose(onClose)}
			/>
		);
	}
}


export const MobileAwarePopup = (props) => <ContextPopup {...props} modal={props.modal && isMobile} controlled={isMobile}/>
MobileAwarePopup.propTypes = {
	children: PropTypes.element,
	content: PropTypes.node,
	basic: PropTypes.bool,
	inverted: PropTypes.bool,
	className: PropTypes.string,
	context: PropTypes.any,
	eventsEnabled: PropTypes.bool,
	flowing: PropTypes.bool,
	header: PropTypes.element,
	hoverable: PropTypes.bool,
	offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onMount: PropTypes.func,
	onOpen: PropTypes.func,
	onUnmount: PropTypes.func,
	pinned: PropTypes.bool,
	popperDependencies: PropTypes.array,
	popperModifiers: PropTypes.object,
	position: PropTypes.string,
	positionFixed: PropTypes.bool,
	size: PropTypes.string,
	style: PropTypes.object,
	modal: PropTypes.bool,
	whide: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	on: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};