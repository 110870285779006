import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Message, Modal, Segment} from "semantic-ui-react";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../Tools/RightsProvider";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {Trans, translate} from "react-i18next";
import NoAccess from "../../../components/NoAccess";
import {EasyFlex, Flex, FlexChild, FlexItem, FlexWrapBreaker, Space} from "../../../components/partials/ActionHeader";
import {isLoading, withLoadingWithoutDispatch} from "../../../actions/loaderActions";
import {Checkbox, IconButton, RaisedButton, TextField} from "material-ui";
import {isOnline} from "../../../actions/userActions";
import {get, isFunction, isString, values} from "lodash";
import {IconRestore} from "../../../Logic/icons";
import ModelServiceSelectorController from "../master-data/service/ModelServiceSelectorController";
import {serviceAction__deleteCustom, serviceAction__saveCustom} from "../../../actions/serviceActions";
import {orderAction__putForced, orderAction__putToken, orderCall__appointmentLookup_v6} from "../../../actions/orderActions";
import {resourceAction__fetch} from "../../../actions/resourceActions";
import {array2object, deepMemoize as memoize, Loadable, trueNull} from "../../../Logic/extensions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {PROC_ORDER, PROC_SERVICE_CUSTOM} from "../../../actions";
import {CustomServiceForm} from "../master-data/service";
import {moment} from "../../../Logic/Moment";
import {OrderServiceSelector} from "./OrderServiceSelector";
import {OrderServiceSelectionList} from "./OrderServiceSelectionList";
import {OrderDatePicker} from "./OrderDatePickerV2";
import {OrderClientSelector} from "./OrderClientSelector";
import {OrderVehicleSelector} from "./OrderVehicleSelector";
import {LoanCarSelector} from "../../../components/intern/vehicles";
import {OrderRangeSummary} from "../../../components/intern/orders/OrderRangeSummary";
import {OrderKmUpdate} from "./OrderKmUpdate";
import {OrderOptionsView} from "../../../components/intern/orders/OrderOptionsView";


const DEFAULT_LOOKUP = {
	loading: false,
	error: null,
	data: null
};

const DEFAULT_SAVE = {
	loading: false,
	result: null,
	error: null
};

const MIN_LABEL_WIDTH = 150;
const LoaderButton = withLoadingWithoutDispatch("loading", PROC_SERVICE_CUSTOM)(Button);

const MIN_INFO_WIDTH = 220;
const CLASS_INFO_LINE = "order-result-info-line";

const FlexLine = ({title, children, titleWidth, propsTitle, propsContent, after, ...props}) => (
	<EasyFlex style={{marginLeft: -12, marginRight: -12}} valign={EasyFlex.valign.CENTER} {...props}>
		<FlexItem shrink={0.1} style={{minWidth: titleWidth || MIN_LABEL_WIDTH, marginLeft: 12, marginRight: 12}} {...propsTitle}>
			{!title ? <span>&nbsp;</span>  : <strong>{title}:</strong>}
		</FlexItem>
		<FlexItem shrink={0.1} grow={100} {...propsContent} style={{marginLeft: 12, marginRight: 12}}>
			{children}
		</FlexItem>
		{after}
	</EasyFlex>
);
FlexLine.propTypes = {
	title: PropTypes.node,
	children: PropTypes.node,
	propsTitle: PropTypes.object,
	propsContent: PropTypes.object,
	titleWidth: PropTypes.number,
	after: PropTypes.node
};

const Workload = ({value}) => <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung im Zeitraum</span>}>{value} %</FlexLine>;
const WorkloadRange = ({value: {start_point, end_point, workload}}) => {
	if (moment(start_point).isSame(end_point, "day")) {
		return <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung am selben Tag</span>}>{workload} %</FlexLine>;
	}
	return <FlexLine className={CLASS_INFO_LINE} titleWidth={MIN_INFO_WIDTH} title={<span>Auslastung<br/>&nbsp;&nbsp;&nbsp;&nbsp;zwischen {moment(start_point).format("ll")}<br/>&nbsp;&nbsp;&nbsp;&nbsp;und {moment(end_point).format("ll")}</span>}>{workload} %</FlexLine>;
};


class OrderCreateController extends React.Component{
	static propTypes = {
		...RIGHT_PROPS,
		onAbort: PropTypes.func,
		clients: PropTypes.array,
		resources: PropTypes.object,
		onCreated: PropTypes.func,
		onOrderCreate: PropTypes.func,
		onAfterCreate: PropTypes.func,
		onOrderCreateByToken: PropTypes.func,
		onOrderCreateForced: PropTypes.func,
		loading: PropTypes.bool
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		clients: [],
		resources: {},
		onOrderCreate: () => {},
		loading: false,
		onAfterCreate: () => {},
		onOrderCreateByToken: () => {}
	};
	now = moment().add(2, "minute");
	state = {
		showClientSearch: false,
		showVehicleSearch: false,
		showServiceSelect: false,
		showCustomServiceSelection: false,
		client: null,
		vehicle: null,
		services: {},
		target_date: null,
		deliver_date: this.now.toDate(),
		worktime: 0,
		lookup: DEFAULT_LOOKUP,
		name: '',
		info: '',
		km: "",
		update_km: false,
		selected_loan_car: 0,
		save: DEFAULT_SAVE,
		released: false,
		loan_car: false,
		direct_service: false,
		delivery_service: false,
		waiting: false
	};
	
	showClientSearch = showClientSearch => () => this.setState({ showClientSearch});
	showVehicleSearch = showVehicleSearch => () => this.setState({showVehicleSearch});
	showServiceSelect = showServiceSelect => () => this.setState({showServiceSelect});
	showCustomServiceSelection = showCustomServiceSelection => () => this.setState({showCustomServiceSelection});
	toggleLoanCar = () => this.setState(state => ({loan_car: !state.loan_car}));
	setLoanCar = (loan_car) => {
		console.warn('setting loan car in', this);
		this.setState({loan_car});
	}
	setLoanCar2 = (loan_car) => {
		console.warn('setting loan car in', this);
		this.setState({loan_car});
	}
	setDirectService = (direct_service) => this.setState({direct_service});
	setDeliveryService = (delivery_service) => this.setState({delivery_service});
	setWaiting = (waiting) => this.setState({waiting});
	resetLoanCar = () => this.setState({loan_car: false});
	
	
	set = index => (e, value) => this.setState({[index]: isString(value) ? value : e.targt.value});
	setClient = client => this.setState({client, lookup: DEFAULT_LOOKUP, name: '', info: ''});
	resetClient = () => this.setState({client: null, vehicle: null, services: {}, lookup: DEFAULT_LOOKUP, name: '', info: '' });
	setVehicle = vehicle => this.setState({vehicle, lookup: DEFAULT_LOOKUP, name: '', info: ''});
	resetVehicle = () => this.setState({vehicle: null, services: {}, lookup: DEFAULT_LOOKUP, name: '', info: ''});
	setSelectedLoanCar = (selected_loan_car) => this.setState({selected_loan_car: selected_loan_car ? selected_loan_car.vehicle_id : 0});
	setService = service => this.setState(state => ({
		...state,
		services: {
			...state.services,
			[service.service_id]: service
		},
		lookup: DEFAULT_LOOKUP, name: '', info: ''
	}));
	setServices = services => {
		const obj = array2object(services)(s => s.service_id);
		this.setState({
			services: obj,
			lookup: DEFAULT_LOOKUP,
			name: "",
			info: ""
		})
	};
	setLookup = data => this.setState(state => ({
		...state,
		selected_loan_car: 0,
		lookup: {
			...state.lookup,
			...data
		}
	}));
	
	setDeliverDate = deliver_date => this.setState({deliver_date});
	
	setTargetDate = target_date => this.setState({target_date});
	
	onSelectClient = (client) => {
		const {client: currentClient} = this.state;
		if ( !currentClient || currentClient.client_id !== client.client_id) {
			this.setState({vehicle: null});
		}
		this.setClient(client);
		this.showClientSearch(false)();
	};
	
	onSelectVehicle = vehicle => {
		const {clients} = this.props;
		this.setVehicle(vehicle);
		this.showVehicleSearch(false)();
		const client = clients.find(c => c.client_id === vehicle.client_id);
		if (client) {
			this.setClient(client);
		}
	};
	
	
	onSelectService = service => {
		this.setService(service);
		this.showServiceSelect(false)();
	};
	
	onRequestLookup = async() => {
		let {vehicle, services,  loan_car, target_date, deliver_date} = this.state;
		const vehicle_id = vehicle.vehicle_id;
		services = values(services).map(s => s.service_id);
		const data = {
			services,
			vehicle_id,
			loan_car,
			target_date,
			deliver_date
		};
		try {
			this.setLookup({loading: true, error: null});
			this.setSelectedLoanCar(null);
			const result = await orderCall__appointmentLookup_v6(data);
			this.setLookup({loading: false, data: result});
		} catch (e) {
			this.setLookup({loading: false, error: e.message || e.error});
		}
	};
	
	onOrderCreateForced = () => {
		let {vehicle, services, target_date, deliver_date, name, info, released, km, update_km} = this.state;
		const vehicle_id = vehicle.vehicle_id;
		services = values(services).map(s => s.service_id);
		const data = {
			services,
			vehicle_id,
			name,
			info,
			released,
			target_date,
			deliver_date,
			km,
			update_km
		};
		this.props.onOrderCreateForced(data, result => {
			this.resetClient();
			this.props.onAfterCreate(result);
		});
	};
	
	onOrderCreateToken =  () => {
		const {name, info, lookup: {data: {version, token}}, released, selected_loan_car: loan_car_id, km, update_km} = this.state;
		this.props.onOrderCreateByToken({name, info, version, token, released, loan_car_id, km, update_km}, result => {
			this.resetClient();
			this.props.onAfterCreate(result);
		})
	};
	
	
	onCreateCustomService = (data, Component) => {
		this.props.onCreateCustomService(data, result => {
			this.setService(result);
			this.showCustomServiceSelection(false)();
		});
	};
	
	dateError = memoize(
		(start_date,  end_date, worktime) => {
			if (!start_date || !end_date) return "Datumsangaben sind nicht vollstandig";
			const now = moment();
			if (start_date < now) return `Startzeit liegt in der Vergangenheit! (frühste Zeit: ${now.add(1, "minute").format("LLL")})`;
			let earliest_end_date = moment(start_date).add(worktime, "hours");
			return earliest_end_date > end_date ? `Ungenügend Zeit! ( frühste Fertigstellung: ${earliest_end_date.format("LLL")})` : null;
		}
	);
	
	kmError = memoize(
		(km, km_origin) => {
			if (km.trim() === "") return null;
			if (km < km_origin) return "Wert unterhalb des Originals!";
			return null;
		}
	);
	
	render() {
		const {
			rights: {mayCreate},
			loading,
			modules,
			t
		} = this.props;
		
		if ( !mayCreate ) {
			return <NoAccess/>;
		}
		
		const {
			showServiceSelect,
			showCustomServiceSelection,
			client, vehicle, km,
			services: serviceMap,
			lookup,
			info: orderInfo,
			deliver_date, target_date, worktime,
			loan_car, direct_service, delivery_service, waiting
		} = this.state;
		
		window.serviceMap = serviceMap;
		
		const hasLoanCarModule = !!modules[3];
		
		const serviceList = values(serviceMap);
		const dateError = this.dateError(deliver_date, target_date, worktime);
		
		return (
			<Segment basic style={{position: 'relative'}}>
				<EasyFlex style={{marginBottom: 30}} align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
					<h3 >Neuen Termin anlegen</h3>
					{trueNull(client) && <IconButton onClick={this.resetClient}><IconRestore/></IconButton>}
				</EasyFlex>
				<OrderClientSelector  client={client} onSelectClient={this.onSelectClient} onUnselect={this.resetClient} minLabelWidth={MIN_LABEL_WIDTH}/>
				<OrderVehicleSelector onSelectVehicle={this.onSelectVehicle} client={client} vehicle={vehicle} onUnselect={this.resetVehicle} minLabelWidth={MIN_LABEL_WIDTH}/>
				{
					vehicle && // show SERVICE SELECTION POPUP
						<OrderServiceSelector vehicle={vehicle} onChange={this.setServices}/>
				}
				{serviceList.length ? // show DATE SELECTION
					<div>
						{/* service details */}
						<OrderServiceSelectionList vehicle={vehicle} services={this.state.services} updateOnChange onNotifyWorktime={worktime => this.setState({worktime})}/>
						<div style={{height: 20}}/>
						{ lookup.error &&
							<div style={{marginBottom: 20, paddingBottom: 20, borderBottom: "1px dashed #eee"}}>
								<Message negative >
									<p>{t(lookup.error)}</p>
								</Message>
							</div>
						}
						{/*<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>*/}
						{/*	{hasLoanCarModule && <Flex valign={"center"} vmargin={5} gutterWidth={25}>*/}
						{/*		<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong>Leihwagen?</strong></FlexChild>*/}
						{/*		<FlexChild><Checkbox checked={loan_car} onCheck={this.toggleLoanCar}/></FlexChild>*/}
						{/*	</Flex>}*/}
						{/*	<Flex valign={"center"} vmargin={5} gutterWidth={25}>*/}
						{/*		<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong>Direktannahme</strong></FlexChild>*/}
						{/*		<FlexChild><Checkbox/></FlexChild>*/}
						{/*	</Flex>*/}
						{/*</EasyFlex>*/}
						
						<OrderOptionsView
							key={'ranged'}
							deliveryService={delivery_service}
							loanCar={loan_car}
							hasLoanCarModule={hasLoanCarModule}
							setDirectService={this.setDirectService}
							directService={direct_service}
							setDeliveryService={this.setDeliveryService}
							setLoanCar={this.setLoanCar2}
							waiting={waiting}
							setWaiting={this.setWaiting}
							segmentProps={{basic: true, color: null}}
							celled={'internally'}
						/>
					
						{/* delivery date time */}
						<Flex valign={"center"} vmargin={5} gutterWidth={25}>
							<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong>Abgabe:</strong></FlexChild>
							<FlexChild shrink={0.1} grow={100}>
								<OrderDatePicker
									textDate={"Datum"}
									textTime={"Zeit [hh:mm]"}
									minDate={new Date()}
									date={this.now.toDate()}
									time={this.now.format("HH:mm")}
									onChange={this.setDeliverDate}
								/>
							</FlexChild>
						</Flex>
						{/* targeted finish/pick up date time */}
						<Flex valign={"center"} vmargin={5} gutterWidth={25} wrap>
							<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong>Fertig bis:</strong></FlexChild>
							<FlexChild shrink={0.1} grow={100}>
								<OrderDatePicker
									textDate={"Datum"}
									textTime={"Zeit [hh:mm]"}
									minDate={new Date()}
									date={new Date()}
									onChange={this.setTargetDate}
								/>
							</FlexChild>
							{dateError &&
								<React.Fragment>
									<FlexWrapBreaker/>
									<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}></FlexChild>
									<FlexChild  shrink={0.1}><Message negative>{dateError}</Message></FlexChild>
								</React.Fragment>
							}
						</Flex>
						<EasyFlex wrap valign={EasyFlex.valign.CENTER}>
							{get(lookup, "data.order_ranges", null) &&
								<OrderRangeSummary ranges={lookup.data.order_ranges} style={{marginRight: 30}}/>
							}
							<FlexItem grow={10}>
								{
									get(lookup, "data.workload", null) &&
									<Workload value={lookup.data.workload.workload}/>
								}
								{
									get(lookup, "data.workload_range", null) &&
									<WorkloadRange value={lookup.data.workload_range}/>
								}
							</FlexItem>
						</EasyFlex>
						
						<div>
							<Flex vmargin={15} align={'center'} valign={"center"}>
								
								<Loadable
									hoc={RaisedButton}
									onClick={this.onRequestLookup}
									primary
									// disabled={!this.isValidTimes()}
									loading={lookup.loading}
									disabledOnLoading
									inverse
								>
									<span style={{display: 'inline-block', marginLeft: 10, marginRight: 10}}>Termin prüfen</span>
								</Loadable>
							</Flex>
							
							{lookup.data && <div>
								{ trueNull(lookup.data.vehicles) &&
									<LoanCarSelector id={this.state.selected_loan_car} onChange={this.setSelectedLoanCar} loanCars={lookup.data.vehicles}/>
								}
								
								<OrderKmUpdate error={this.kmError(km, vehicle.km)} onUpdate={update_km => this.setState({update_km})} onChange={km => this.setState({km})} km={vehicle.km}/>
								<Flex valign={"center"} vmargin={5}>
									<TextField
										fullWidth
										floatingLabelText={'Zusätzliche Infos'}
										onChange={this.set('info')}
										multiLine
										value={orderInfo}
									/>
								</Flex>
								<Flex valign={"center"} vmargin={15}>
									<Checkbox title={"Direkt freigeben"} label={"Direkt freigeben"} onCheck={(e, value) => this.setState({released: value})}/>
								</Flex>
								{ lookup.data.result ?
									<Flex valign={"center"} vmargin={5} gutterWidth={25}>
										<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong style={{color: 'green'}}>Termin verfügbar:</strong></FlexChild>
										<FlexChild shrink={0.1} grow={100}/>
										<FlexChild shrink={1} grow={100} style={{textAlign: 'right'}}>
											<Loadable hoc={RaisedButton} disabledOnLoading loading={loading} inverse primary onClick={this.onOrderCreateToken}>
												<div style={{marginLeft: 10, marginRight: 10}}>Jetzt buchen</div></Loadable>
										</FlexChild>
									</Flex>
									:
									<Flex valign={"center"} vmargin={5} gutterWidth={25}>
										<FlexChild style={{minWidth: MIN_LABEL_WIDTH}}><strong style={{color: 'red'}}>Kein Termin:</strong></FlexChild>
										
										<FlexChild shrink={0.1} grow={100} style={{textAlign: 'right'}}>
											<Loadable hoc={RaisedButton} onClick={this.onOrderCreateForced} disabledOnLoading inverse loading={loading} secondary><div style={{marginLeft: 10, marginRight: 10}}>Trotzdem buchen</div></Loadable>
										</FlexChild>
									</Flex>
								}
							</div>}
						</div>
						
					</div>
					: null
				}
				
				{/*<div style={{marginBottom: 30}}/>
				<Flex align={"space-between"}>
					{onAbort && <FlatButton onClick={onAbort}>Abbrechen</FlatButton>}
					<span/>
				</Flex>*/}
				{/*{showServiceSelect &&
					<Dialog className={"order-create-dialog"} open onRequestClose={this.showServiceSelect(false)} autoScrollBodyContent>
						<ModelServiceSelectorController
							onAbort={this.showServiceSelect(false)}
							onSelect={this.onSelectService}
							model_id={vehicle.model_id || 0}
							selectOnClick/>
					</Dialog>
				}*/}
				{showServiceSelect && <Modal open centered={false} onClose={this.showServiceSelect(false)}>
					<Modal.Content>
						<ModelServiceSelectorController
							onAbort={this.showServiceSelect(false)}
							onSelect={this.onSelectService}
							model_id={vehicle.model_id || 0}
							selectOnClick/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.showServiceSelect(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
				{showCustomServiceSelection && <Modal open centered={false} onClose={this.showCustomServiceSelection(false)}>
					<Modal.Content>
						<CustomServiceForm maxWidth={"inherit"} id={"custom-service-form"} onSubmit={this.onCreateCustomService}/>
						<Space height={30}/>
						<Flex align={"space-between"}>
							<Button onClick={this.showCustomServiceSelection(false)}><Icon name={"close"}/> {t("actions.close")}</Button>
							<LoaderButton positive form={"custom-service-form"}>Speichern</LoaderButton>
						</Flex>
					</Modal.Content>
				</Modal>}
				{/*<BasicDialog autoScrollBodyContent modal open={showCustomServiceSelection} onClose={this.showCustomServiceSelection(false)}>
					<CustomServiceForm maxWidth={"inherit"} id={"custom-service-form"} onSubmit={this.onCreateCustomService}/>
					<Space height={30}/>
					<Flex align={"space-between"}>
						<Button onClick={this.showCustomServiceSelection(false)}><Icon name={"close"}/> {t("actions.close")}</Button>
						<LoaderButton positive form={"custom-service-form"}>Speichern</LoaderButton>
					</Flex>
				</BasicDialog>*/}
			</Segment>
		);
	}
}

OrderCreateController = withRights(SECTION.ORDERS, OrderCreateController);
OrderCreateController = withInit(OrderCreateController);

OrderCreateController = connect(
	(state, props) => {
		return {
			clients: props.clients || values(state.clients.list),
			resources: props.resources || state.resources.list,
			loading: isLoading(state, PROC_ORDER),
			modules: state.modules.list
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				// dispatch(clientAction__fetch(true));
				dispatch(resourceAction__fetch());
			})),
			onOrderCreate: (data, onSuccess) => {
				alert('FAILED!!');
			},
			onOrderCreateByToken: (data, onSuccess) => dispatch(orderAction__putToken(data, result => {
				dispatch(addSnackbar("Auftrag wurde angelegt"));
				isFunction(onSuccess) && onSuccess(result);
			})),
			onOrderCreateForced: (data, onSuccess) => dispatch(orderAction__putForced(data, result => {
				dispatch(addSnackbar('Auftrag wurde angelegt'));
				isFunction(onSuccess) && onSuccess(result);
			})),
			onCreateCustomService: (data, onSuccess) => dispatch(serviceAction__saveCustom(data, onSuccess)),
			onDeleteCustomService: (data, onSuccess) => dispatch(serviceAction__deleteCustom(data, onSuccess))
		};
	}
)(OrderCreateController);

OrderCreateController = translate()(OrderCreateController);

export default OrderCreateController;