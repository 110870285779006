import {ORDER_ACTION, RESET_ACTION} from "../actions/types";
import {CACHE_ORDERS} from "../actions";
import {get, isArray, omit, uniqBy, xor, uniq} from "lodash";
import {updateCache} from "ith-redux-helpers";
import {array2object} from "../Logic/extensions";

const defaultState = {
	meta: {
		callee: null,
		date: null,
		endDate: null
	},
	release_data: [],
	orders: {},
	services: {},
	resources: {},
	order2services: {},
	service2resources: {},
	order_vehicles: {},
	clients: {},
	loan_cars: {},
	activities: {},
	cache: {},
	min_max: ["00:00:00", "23:59:59"],
	release: [],
	releases: {},
	conflicts: [],
	processable: [],
	loaded: {
		orders: {},
		services: {},
		resources: {}
	}
};

const update = (state, data, ...index) => {
	let up = {};
	for (const key of index) {
		if (!(key in data)) continue;
		up[key] = {
			...state[key],
			...data[key]
		};
	}
	return up;
};


export default (state = defaultState, {type, data, orders, order2service, order2service2resource, activities, list, order, order_id, meta, releases, merge, release_data, conflicts}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case ORDER_ACTION.SET_RELEASE_IDS:
			return {
				...state,
				release_data
			};
		case ORDER_ACTION.ADD_RELEASE_IDS:
			if (!isArray(release_data)) {
				release_data = [release_data];
			}
			release_data = release_data.filter(r => "order_service_id" in r);
			return {
				...state,
				release_data: uniqBy([...state.release_data, ...release_data], r => r.order_service_id)
			};
		case ORDER_ACTION.SET_CONFLICTS:
			return {
				...state,
				conflicts
			};
		case ORDER_ACTION.UPDATE_CONFLICTS:
			return {
				...state,
				conflicts: uniq([...state.conflicts, ...conflicts])
			};
		case ORDER_ACTION.REMOVE_CONFLICTS:
			return {
				...state,
				conflicts: state.conflicts.filter(c => !conflicts.includes(c))
			};
		case ORDER_ACTION.REMOVE_RELEASE_IDS:
			if (!isArray(release_data)) {
				release_data = [release_data];
			}
			release_data = release_data.map(r => Number(get(r, 'order_service_id', r))).filter(Boolean);
			release_data = array2object(release_data)(r => r.order_service_id || r);
			return {
				...state,
				release_data: state.release_data.filter(r => !(r.order_service_id in release_data))
			};
		case ORDER_ACTION.META:
			return {
				...state,
				meta: {
					callee: meta.callee === undefined ? state.meta.callee : meta.callee,
					date: meta.date === undefined ? state.meta.date : meta.date,
					startDate: meta.startDate === undefined ? state.meta.startDate : meta.startDate,
				}
			};
		case ORDER_ACTION.UPDATE:
			return {
				...state,
				...update(state, data, 'orders', 'services', 'resources', 'order2services', 'service2resources', 'order_vehicles', 'clients', 'loan_cars')
				
			};
		case ORDER_ACTION.UNSET:
			let next = state;
			if ( "services" in data) {
				const o2s = {};
				for (const service of data.services) {
					o2s[service.order_id] = xor(next.order2services[service.order_id] || [], [service.order_service_id]);
				}
				next = {
					...next,
					services: omit(state.services, data.services.map(s => s.order_service_id) || []),
					order2services: {
						...next.order2services,
						...o2s
					}
				};
			}
			if ("resources" in data) {
				const s2r = {};
				for (const res of data.resources) {
					s2r[res.order_service_id] = xor(next.service2resources[res.order_service_id] || [], [res.order_service_resource_id]);
				}
				next = {
					...next,
					resources: omit(state.resources, data.resources.map(r => r.order_service_resource_id) || []),
					service2resources: {
						...next.service2resources,
						...s2r
					}
				};
			}
			return next;
		case ORDER_ACTION.SET:
			return {
				...state,
				...data,
				cache: {
					...state.cache,
					...updateCache(CACHE_ORDERS)
				}
			};
		case ORDER_ACTION.DELETE:
			if ( order_id in state.orders) {
				return {
					...state,
					orders: omit(state.orders, [order_id]),
					service2resources: omit(state.service2resources, state.order2services[order_id] || []),
					order2services: omit(state.order2services, [order_id])
				};
			}
			return state;
		case ORDER_ACTION.PARTIAL:
			return {
				...state,
				order: {
					...state.order,
					...orders
				},
				order2service: {
					...state.order2service,
					...order2service
				},
				order2service2resource: {
					...state.order2service2resource,
					...order2service2resource
				}
			};
		case ORDER_ACTION.SET_ACTIVITIES:
			return {
				...state,
				activities: {
					...state.activities,
					...activities
				}
			};
		case ORDER_ACTION.RELEASED:
			if (merge) {
				return {
					...state,
					release: uniqBy([...list, ...state.release], r => Number(r.order_id)).filter(r => !r.released_at)
				}
			}
			return {
				...state,
				release: list
			};
		case ORDER_ACTION.CLEAR_RELEASED:
			list = list.map(Number);
			const release = state.release.filter(({order_id}) => !list.includes(Number(order_id)));
			return {
				...state,
				release
			};
		case ORDER_ACTION.ADD_TO_RELEASE:
			let _release = uniqBy(state.release.concat(order), r => Number(r.order_id));
			_release = _release.filter(r => !r.released_at);
			
			return {
				...state,
				release: _release
			};
		case ORDER_ACTION.SET_RELEASES:
			return {
				...state,
				releases
			};
		case ORDER_ACTION.MERGE_RELEASES:
			const _next = {
				...state,
				releases: {
					...state.releases,
					...releases
				}
			};
			return _next;
		case ORDER_ACTION.REMOVE_RELEASES:
			return {
				...state,
				releases: omit(state.releases, releases)
			};
		case ORDER_ACTION.CLEAR_ALL_RELEASES:
			return {
				...state,
				releases: {}
			};
		case ORDER_ACTION.CLEAR_COMPLETE_RELEASES:
			return state;
			// return {
			// 	...state,
			// 	releases: pickBy(state.releases, r => !(r.stock && r.service))
			// };
		case ORDER_ACTION.SET_PROCESSABLE:
			return {
				...state,
				processable: list
			};
		default:
			return state;
	}
}