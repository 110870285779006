
import {LANGAUGE_ACTION} from "../actions/types";

const languageState = (state = 'de', action) => {
	switch (action.type) {
		case LANGAUGE_ACTION.SET:
			return action.language;
		default:
			return state;
	}
};

export default languageState;