import {ERROR_ACTION} from "./types";
import {replace} from 'connected-react-router';
// import {PUT} from "ith-fetch";
import {PUT} from "../Logic/fetch";
import {addSnackbar, dispatchSnack} from "./snackbarActions";
import {requestLoginView} from "../components/intern";
import {dispatch, store} from "../store";
import {publish} from "../Logic/PubSub";
import {SUB_UNAUTHERIZED} from "./index";

export const errorCall__javascript = error => PUT('/dev/error', error);

export const errorState__add = error => ({
	type: ERROR_ACTION.ADD,
	message: error.message || error,
	code: error.code,
	params: error.params,
	error_code: error.error_code || -1,
	feedback: error.feedback !== undefined ? error.feedback : true,
	error
});

export const handleCommonErrors = (error, ...types) => {
	const code = 'code' in error ? error.code : error;
	if (types.length === 0){
		types = [401, 403, 404];
	}
	if (types.includes(code)) {
		switch(code) {
			case 401:
				publish(SUB_UNAUTHERIZED);
				requestLoginView();
				dispatchSnack('errors.session-los', 'alert');
				return false;
			case 404:
				dispatchSnack('errors.not-found', 'alert');
				return false;
			case 403:
				dispatchSnack('no-access', 'alert');
				return false;
			default:
				return true
		}
	}
	return true;
};

// export const addError = message => {
// 	console.log('error:', message);
// 	return ({type:ERROR_ACTION.ADD, message: message.message || message});
// };

export const addError = (error, redirect = true, code) =>
	async (dispatch, getState) => {
		console.error(error);
		if ( redirect ) {
			code = error.code || code;
			if ( code === 401 ) {
				// runLogout(dispatch, getState().carhouse.own.link);
				publish(SUB_UNAUTHERIZED);
				requestLoginView();
				dispatch(addSnackbar("errors.session-lost", "alert"));
				return;
			}
			if (code === 404) {
				return dispatch(replace('/not-found'));
			}
			if (code === 403) {
				return dispatch(replace('/no-access'));
			}
		}
		return dispatch(errorState__add(error));
	};

export const dispatchError = (error, redirect = true, code) => {
	if (redirect) {
		code = error.code || code;
		if ( code === 401 ) {
			// runLogout(dispatch, getState().carhouse.own.link);
			publish(SUB_UNAUTHERIZED);
			requestLoginView();
			dispatch(addSnackbar("errors.session-lost", "alert"));
			return;
		}
		if (code === 404) {
			return dispatch(replace('/not-found'));
		}
		if (code === 403) {
			return dispatch(replace('/no-access'));
		}
	}
	return dispatch(errorState__add(error))
}

export const addErrorGlobal = error => store.dispatch(errorState__add(error));

export const removeError = () => ({type: ERROR_ACTION.REMOVE});

export const dismissError = () => ({type: ERROR_ACTION.DISMISS});