import * as React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import {Segment} from "semantic-ui-react";
import StackGrid from 'react-stack-grid';
import {processTranslate, withScreenReader} from "../../../Logic/extensions";
import ServiceImage from '../../../images/service2.png';
import Ink from 'react-ink';
import {Card, CardMedia, CardTitle} from "material-ui";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import {IMAGE_URL} from "../../../Logic/constants";

const TILE_WIDTH = 150;
const TILE_RESPONSIVE = 320;
const TILE_MARGIN = 20;

class MasterDataServiceView extends React.Component {
	static propTypes = {
		screen: PropTypes.shape({
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired
		}).isRequired,
		services: PropTypes.array,
		mayRead: PropTypes.bool,
		noAcess: PropTypes.func,
		onSelect: PropTypes.func.isRequired
	};
	static defaultProps = {
		services: [],
		mayRead: false,
		noAccess: () => {},
		onSelect: service => alert('onSelect() is not implemented yet')
	};
	
	componentDidMount() {
		const {mayRead, noAccess} = this.props;
		if ( !mayRead ) {
			noAccess();
		}
	}
	
	translatedServices = () => service => processTranslate(this.props.t, service)(s => `service.name.service-${s.service_id}`)
	
	logo = ({logo}) => {
		if( !logo ) {
			return ServiceImage;
		}
		return `${IMAGE_URL}${logo.ident}.${logo.origin_extension}?${logo.updated_at}`;
	};
	
	backgroundLogo = (avatar) => `url("${this.logo(avatar)}")`;
	
	handleSelect = service => () => this.props.onSelect(service);
	
	render() {
		const {t, screen, services: pureServices, onBack} = this.props;
		const services = pureServices.filter(s => !s.custom).map(this.translatedServices());
		return (
			<Segment basic style={{height: '100%'}}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						{onBack && <ActionBackButton onBack={onBack}/>}
						<ActionHeading>{t('service.service-and-prices')}</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<StackGrid duration={0} columnWidth={screen.width < TILE_RESPONSIVE ? '100%' : TILE_WIDTH} gutterHeight={TILE_MARGIN} gutterWidth={screen.width < TILE_RESPONSIVE ? 0 : TILE_MARGIN} monitorImagesLoaded>
					{services.map(service =>
						<Card key={service.service_id} onClick={this.handleSelect(service)} className={'pointer service-grid'}>
							<CardMedia
								className={"service-card-media"}
								mediaStyle={{overflow: 'hidden', height: screen.width < TILE_RESPONSIVE ? screen.width : TILE_WIDTH, position: 'relative'}}
								style={{backgroundImage: this.backgroundLogo(service), backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeast"}}
								overlay={<CardTitle style={{marginBottom: 8}} className={"service-card-overlay"} title={service.title}/>}
							>
								{/*<img src={this.logo(service)} alt={service.title} />*/}
							</CardMedia>
							<Ink radius={400}/>
						</Card>
					)}
				</StackGrid>
			</Segment>
		);
	}
}

export default translate()(withScreenReader(MasterDataServiceView));