import * as React from "react";
import PropTypes from "prop-types";
import {Button, Modal, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../partials/ActionHeader";
import {MiniMenu, ResourceLink, WorkerDetailLink, WorkersCalendarLink} from "../../../../Logic/MiniMenu";
import {SelectField} from "../../../../Logic/extensions";
import {Divider, MenuItem} from "material-ui";
import {RIGHT_DEFAULT_PROPS, RIGHT_PROPS, withRights} from "../../../../Tools/RightsProvider";
import {SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../NoAccess";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {loadWorkers} from "../../../../actions/workerActions";
import {get, values} from "lodash";
import {IconAccessible, IconBeachAccess, IconEvent, IconGroup, IconPerson} from "../../../../Logic/icons";
import {withRouter} from "react-router";
import BigCalendar from "react-big-calendar";
import {translate} from "react-i18next";
import WorkersFreedayCreateView from "./WorkersFreedayCreateController";
import {freedaysAction__fetchWorkers} from "../../../../actions/freedaysActions";
import moment from "../../../../Logic/Moment";
import WorkersFreedayEditView from "./WorkersFreedayEditController";
// import memoize from 'memoize-one'
import {deepMemoize as memoize} from "../../../../Logic/extensions";

const COLOR_MAP = {
	holiday: '#049c3b',
	sick: '#85000a',
	master_replacement: '#192c92',
	intern: '#a58f12'
};

class WorkerCalendar extends React.Component {
	static propTypes = {
		...RIGHT_PROPS,
		workersMap: PropTypes.object.isRequired,
		freedaysList: PropTypes.array
	};
	static defaultProps = {
		...RIGHT_DEFAULT_PROPS,
		workersMap: {},
		freedaysList: []
	};
	state = {
		currentWorker: 0,
		viewType: 'all',
		createType: null,
		createDialog: false,
		editEvent: null
	};
	
	componentDidMount() {
		// update state on load
		if ( this.props.match.params.workers_id ) {
			// this.state.currentWorker = Number(this.props.match.params.workers_id) || 0;
			this.setState({currentWorker: Number(this.props.match.params.workers_id) || 0})
		}
	}
	
	
	componentDidUpdate(prevProps, state, snapshot) {
		if ( this.props.match.params.workers_id) {
			const workers_id = Number(this.props.match.params.workers_id) || 0;
			if ( workers_id !== state.currentWorker ) {
				this.setState({currentWorker: workers_id});
			}
		}
	}
	
	setWorker = (e, idx, currentWorker) => this.setState({currentWorker},
		() => currentWorker ?
			this.props.history.push(`/workers/calendar/${currentWorker}`) :
			this.props.history.push('/workers/calendar')
	);
	setViewType = (e, idx, viewType) => this.setState({viewType});
	
	showCreateDialog = (createDialog, createType = null) => () => this.setState({createType: createDialog ? createType : null, createDialog});
	showEditDialog = (editEvent) => this.setState({editEvent});
	
	prepareCalendar = () => {
		const {i18n, t} = this.props;
		const messages = i18n.getResource(i18n.language.match(/\w+/), "translations", "big-calendar.defs") || {};
		messages.showMore = count => t("big-calendar.showMore", {count});
		return {
			defaultDate: new Date(),
			messages,
			culture: "de",
			defaultView: 'week',
			views: ["month", "week", "day", "agenda"]
		};
	};
	
	titleAccessor = (event) => {
		const {workersMap} = this.props;
		const worker = workersMap[event.workers_id];
		if ( !worker ) return 'Mitarbeiter';
		return worker.first_name.substr(0, 1).toUpperCase() + ' ' + worker.name;
		
	};
	
	eventPropGetter = () => event => {
		return {
			style: {
				backgroundColor:  COLOR_MAP[event.type]
			}
		};
	};
	
	onSelectEvent = (event) => {
		if (!get(this.props, 'rights.mayChange', false)) return;
		this.showEditDialog(event);
	};
	
	filtered = (list) => {
		const {currentWorker, viewType} = this.state;
		if (!currentWorker && 'all' === viewType) return list;
		return list.filter(event => {
			return (currentWorker === 0 || currentWorker === event.workers_id) &&
				('all' === viewType || viewType === event.type);
		});
	};
	
	getWorkerList = memoize(map => values(map).filter(w => w.active && !w.deleted));
	
	render() {
		const {
			rights: {mayRead, mayCreate},
			workersMap,
			freedaysList: originalFreedaysList
		} = this.props;
		
		if ( !mayRead ) {
			return <NoAccess/>;
		}
		
		const {
			currentWorker: currentWorkerId,
			viewType: currentViewType,
			createDialog: createDialogIsVisible,
			createType,
			editEvent
		} = this.state;
		
		const freedaysList = this.filtered(originalFreedaysList);
		
		// calendar
		const calendarProps = this.prepareCalendar();
		const workerList = this.getWorkerList(workersMap);
		
		return(
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>ArbeiterKalender</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<SelectField
							vmargin={10}
							floatingLabelText={'Mitarbeiter'}
							value={currentWorkerId}
							onChange={this.setWorker}
						>
							<MenuItem leftIcon={<IconGroup/>} value={0} primaryText={'Alle'}/>
							{workerList.length > 0 && <Divider/>}
							{workerList.map(worker => {
								return (
									<MenuItem
										key={`select-worker-${worker.workers_id}`}
										value={worker.workers_id}
										leftIcon={<IconPerson/>}
										primaryText={`${worker.first_name.substr(0,1).toUpperCase()}. ${worker.name}`}
									/>
								);
							})}
						</SelectField>
						<SelectField
							vmargin={10}
							value={currentViewType}
							onChange={this.setViewType}
							floatingLabelText={'Zeige an'}
						>
							<MenuItem value={'all'} primaryText={'Alles'}/>
							<MenuItem value={'holiday'} primaryText={'Urlaub'}/>
							<MenuItem value={'sick'} primaryText={'Krankheit'}/>
							<MenuItem value={'master_replacement'} primaryText={'Meistervertretung'}/>
							<MenuItem value={'intern'} primaryText={'Intern/Schulung'}/>
						</SelectField>
						<MiniMenu>
							{mayCreate && <MenuItem primaryText={'Urlaub anlegen'} leftIcon={<IconBeachAccess/>} onClick={this.showCreateDialog(true, "holiday")}/>}
							{mayCreate && <MenuItem primaryText={'Krankenzeit anlegen'} leftIcon={<IconAccessible/>} onClick={this.showCreateDialog(true, "sick")}/>}
							{mayCreate && <MenuItem primaryText={'Meistervertretung anlegen'} leftIcon={<IconPerson/>} onClick={this.showCreateDialog(true, "master_replacement")}/>}
							{mayCreate && <MenuItem primaryText={'Intern/Schulung anlegen'} leftIcon={<IconEvent/>} onClick={this.showCreateDialog(true, "intern")}/>}
							<Divider/>
							{currentWorkerId && <WorkerDetailLink workers_id={currentWorkerId}/>}
							{currentWorkerId && <WorkersCalendarLink workers_id={currentWorkerId}/>}
							<ResourceLink/>
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment basic style={{minHeight: 200}}>
					<BigCalendar
						{...calendarProps}
						events={freedaysList}
						startAccessor={event => new Date(event.start_time)}
						endAccessor={event => new Date(event.end_time)}
						allDayAccessor={event => event.allday}
						titleAccessor={this.titleAccessor}
						eventPropGetter={this.eventPropGetter()}
						onSelectEvent={this.onSelectEvent}
						step={30}
						min={moment().startOf('d').add(6, 'h').toDate()}
						max={moment().startOf('d').add(20, 'h').toDate()}
						timeslots={2}
					/>
				</Segment>
				{createDialogIsVisible &&
					<Modal open onClose={this.showCreateDialog(false)}>
						<Modal.Content>
							<WorkersFreedayCreateView
								workersMap={workersMap}
								workers_id={currentWorkerId}
								type={createType}
								onAfterCreate={result => {
									this.showCreateDialog(false)();
									this.showEditDialog(result.freeday);
								}}
							/>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={this.showCreateDialog(false)}>Schließen</Button>
						</Modal.Actions>
					</Modal>
					
				}
				{editEvent &&
					<Modal open onClose={this.showEditDialog.bind(null, null)}>
						<Modal.Content>
							<WorkersFreedayEditView onClose={this.showEditDialog.bind(null, null)} freeday={editEvent} onAfterRemove={() => this.showEditDialog(null)}/>
						</Modal.Content>
					</Modal>
				}
			</div>
		);
	}
}

WorkerCalendar = withRights(SECTION.WORKERS, WorkerCalendar);
WorkerCalendar = withRouter(WorkerCalendar);
WorkerCalendar = withInit(WorkerCalendar);

WorkerCalendar = connect(
	(state, props) => {
		return {
			workersMap: state.workers.list,
			freedaysList: state.freedays.workers
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(loadWorkers());
				dispatch(freedaysAction__fetchWorkers(true))
			}))
		};
	}
)(WorkerCalendar);

WorkerCalendar = translate()(WorkerCalendar);

export default WorkerCalendar;