import * as React from 'react';
import PropTypes from 'prop-types';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import {scheduleCall__getConsultantsAt, scheduleCall__getConsultantsOrder} from "../../../../actions/scheduleActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {Card, Header, Image, Message, Segment} from "semantic-ui-react";
import {Worker} from "../../../../models";
import {falseNull} from "../../../../Logic/extensions";
import cn from 'classnames';
import {moment} from "../../../../Logic/Moment";
import {COLORS} from "../../../../Logic/constants";
import {get} from 'lodash';

export class ConsultantSelector extends StatyComponent {
	static propTypes = {
		startPoint: PropTypes.any,
		minutes: PropTypes.number,
		preSelection: PropTypes.number,
		onList: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onSelect: PropTypes.func.isRequired
	};
	static defaultProps = {
		onList: scheduleCall__getConsultantsAt,
		onNotificationBad: message => dispatchSnack(message, 'alert')
	};
	
	state = {
		list: [],
		loading: false,
		error: null,
		selection: null
	};
	preSelected = false;
	
	get date() {
		return this.props.startPoint;
	}
	
	componentDidMount() {
		super.componentDidMount();
		this.preSelected = false;
		this.load();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.startPoint.valueOf() !== this.props.startPoint.valueOf()) {
			this.preSelected = false;
			this.load();
		}
		if (prevProps.preSelection !== this.props.preSelection && this.props.preSelection) {
			this.preSelected = false;
			this.preSelect();
		}
	}
	
	preSelect = (list, selection) => {
		list = list || this.state.list;
		selection = selection || (this.props.preSelection && !this.preSelected ? this.props.preSelection : this.state.selection);
		selection = list.find(w => w.workers_id === selection);
		this.preSelected = true;
		this.setState({selection: get(selection, 'workers_id', null)}, this.afterSelect());
	}
	
	load = async () => {
		const {onList, onNotificationBad, minutes} = this.props;
		if (this.state.loading) {
			console.error('is currently loading');
			return;
		}
		try {
			this.setState({loading: true, error: null});
			const list = await onList(this.date, minutes);
			// const selectionBeacon = preSelection && !this.preSelected ? preSelection : this.state.selection;
			// this.preSelected = true;
			// const exists = list.find(w => w.workers_id === selectionBeacon);
			this.setState({list});
			this.preSelect(list);
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loading: false});
		}
	};
	
	afterSelect = (selection = null) => () => this.props.onSelect(selection || this.state.selection);
	select = (id, callback) => () => this.setState(state => ({
		...state,
		selection: state.selection === id ? null : id
	}), callback);
	
	render() {
		const {loading, list, selection} = this.state;
		return (
			<Segment basic loading={loading}>
				<Header as={'h4'} style={{textAlign: 'center', marginBottom: 40}}>
					Serviceberater auswählen
					<Header.Subheader>Um {moment(this.date).format('LLL')} Uhr</Header.Subheader>
				</Header>
				{falseNull(list.length) && <Message negative><p>Kein Serviceberater verfügbar!</p></Message>}
				<Card.Group centered stackable>
					{list.map(consultant => {
						const worker = new Worker(consultant);
						return <Card
							key={consultant.workers_id}
							className={cn('order-search-loan-car', {active: selection === worker.workers_id})}
							style={{margin: 5, maxWidth: 180}}
							onClick={this.select(worker.workers_id, this.afterSelect())}
						>
							<Image className={'consultant-image'} src={worker.avatar} wrapped ui={false}/>
							<Card.Content textAlign={'center'} style={{backgroundColor: selection === worker.workers_id ? COLORS.SEMANTIC_BLUE : null}}>
								<Card.Description>{worker.name}</Card.Description>
							</Card.Content>
						</Card>
					})}
				</Card.Group>
			</Segment>
		);
	}
}

export class ConsultantOrderSelector extends StatyComponent {
	static propTypes = {
		orderId: PropTypes.number.isRequired,
		minutes: PropTypes.number,
		onList: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onSelect: PropTypes.func.isRequired
	};
	static defaultProps = {
		onList: scheduleCall__getConsultantsOrder,
		onNotificationBad: message => dispatchSnack(message, 'alert')
	};
	
	state = {
		list: [],
		loading: false,
		error: null,
		selection: null
	};
	
	
	
	componentDidMount() {
		super.componentDidMount();
		// noinspection JSIgnoredPromiseFromCall
		this.load();
	}
	
	load = async () => {
		const {onList, onNotificationBad, minutes, orderId} = this.props;
		if (this.state.loading) {
			console.error('is currently loading');
			return;
		}
		try {
			this.setState({loading: true, error: null});
			const list = await onList(orderId, minutes);
			// const selectionBeacon = preSelection && !this.preSelected ? preSelection : this.state.selection;
			// this.preSelected = true;
			// const exists = list.find(w => w.workers_id === selectionBeacon);
			this.setState({list});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error||e.message);
		} finally {
			this.setState({loading: false});
		}
	};
	
	afterSelect = (selection = null) => () => this.props.onSelect(selection || this.state.selection);
	select = (id, callback) => () => this.setState(state => ({
		...state,
		selection: state.selection === id ? null : id
	}), callback);
	
	render() {
		const {loading, list, selection} = this.state;
		return (
			<Segment basic loading={loading}>
				<Header as={'h4'} style={{textAlign: 'center', marginBottom: 40}}>
					Serviceberater auswählen
					{/*<Header.Subheader>Um {moment(this.date).format('LLL')} Uhr</Header.Subheader>*/}
				</Header>
				{falseNull(list.length) && <Message negative><p>Kein Serviceberater verfügbar!</p></Message>}
				<Card.Group centered stackable>
					{list.map(consultant => {
						const worker = new Worker(consultant);
						return <Card
							key={consultant.workers_id}
							className={cn('order-search-loan-car', {active: selection === worker.workers_id})}
							style={{margin: 5, maxWidth: 180}}
							onClick={this.select(worker.workers_id, this.afterSelect())}
						>
							<Image className={'consultant-image'} src={worker.avatar} wrapped ui={false}/>
							<Card.Content textAlign={'center'} style={{backgroundColor: selection === worker.workers_id ? COLORS.SEMANTIC_BLUE : null}}>
								<Card.Description>{worker.name}</Card.Description>
							</Card.Content>
						</Card>
					})}
				</Card.Group>
			</Segment>
		);
	}
}