import * as React from 'react';
import {DynamicWorkerScheduleView} from "../../cointainer/intern/master-data/calendar/DynamicWorkerScheduleView";
import {Trans} from "react-i18next";
import {Worker} from "../../models";
import {deepMemoize as memoizeOne} from "../../Logic/extensions";

export class DynamicScheduleTest extends React.Component {
	
	titleProvider = memoizeOne(
		worker => {
			if (worker) {
				const w = new Worker(worker);
				return <Trans defaults={'Arbeitszeiten von {name}'} values={{name: w.name_short}}/>;
			}
			return <Trans defaults={'Arbeitskalender'}/>;
		}
	)
	
	render() {
		// const {worker_list, resource_list, selected, resource, loading_calendar, loading_workers, edit_mode, edit_fields, editable, resourcable, view, date} = this.state;
		return (
			<DynamicWorkerScheduleView/>
		);
	}
}