import VehiclePopupInfo from "./VehiclePopupInfo";
import * as React from "react";
import PropTypes from "prop-types";
import {MobileAwarePopup} from "../../partials/MiniComponents";

const VehiclePopup = ({vehicle, children, inverted, popupProps, ...props}) => (
	vehicle ? <MobileAwarePopup trigger={children} inverted={inverted} {...props} content={<VehiclePopupInfo vehicle={vehicle} inverted={inverted} {...popupProps}/>}/> : children
);
VehiclePopup.propTypes = {
	vehicle: PropTypes.object,
	children: PropTypes.node,
	inverted: PropTypes.bool,
	flowing: PropTypes.bool,
	position: PropTypes.string
};
VehiclePopup.defaultProps = {
	inverted: true,
	flowing: true,
	position: "bottom left"
};

export default VehiclePopup;