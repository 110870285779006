import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Label, Pagination, Popup} from "semantic-ui-react";
import {translate} from "react-i18next";
import {ActionHeader, ActionHeaderGroup, ActionHeading, Flex, OptionHeader} from "../../partials/ActionHeader";
import {processTranslate, trueNull} from "../../../Logic/extensions";
import NoAccess from "../../NoAccess";
import {ItemCountSelector, Sortable} from "ah-tools";
import {sort, sortDefault, sortNumDesc} from "ith-sort";
import escapeRegex from "escape-string-regexp";
import {goToAnchor} from "react-scrollable-anchor";
import {ClientList} from "./widgets/ClientList";
import {connect} from "react-redux";
import {clientAction__cursor} from "../../../actions/clientActions";
import {push} from "connected-react-router";
import {SUB_CLIENT_SEARCH} from "../../../actions";
import {publish} from "../../../Logic/PubSub";


/**
 * Sorting options
 * @type {*[]}
 */
let sortOptions = [
	{key: 'name--asc', order: 'ascending', title: 'Name aufsteigend'},
	{key: 'name--desc', order: 'descending', title: 'Name absteigend'},
];

/**
 * List oft accepted items per page
 * @type {number[]}
 */
const elementsPerPage = [25, 50, 100];


/**
 * Pagination Component at the bottom of the page
 * @param page
 * @param pages
 * @param onPageChange
 * @param props
 * @param paginationProps
 * @return {null}
 * @constructor
 */
const PaginationLine = ({page, pages, onPageChange, paginationProps, ...props}) => (
	pages < 2 ? null :
	<Flex align={'center'} {...props}>
		<Pagination activePage={page+1} totalPages={pages} onPageChange={onPageChange} {...paginationProps}/>
	</Flex>
);
PaginationLine.propTypes = {
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	paginationProps: PropTypes.object
};
PaginationLine.defaultProps = {
	page: 0,
	pages: 0
};

const ClientSubtitle = ({client}) => (
	trueNull(client) &&
	<div className={"client-stack-subtitle"}>
		{client.street ? <div>{client.street} {client.house_no}</div> : <div style={{fontStyle: "italic", color: "#ddd"}}>Keine Straße angegeben</div>}
		{client.zipcode ? <div>{client.zipcode} {client.city}</div> : <div style={{fontStyle: "italic", color: "#ddd"}}>Kein Ort angegeben</div>}
	</div>
);
ClientSubtitle.propTypes = {
	client: PropTypes.shape({
		salutation: PropTypes.string.isRequired,
		client_id: PropTypes.number.isRequired,
		street: PropTypes.string,
		house_no: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string
		]),
		zipcode: PropTypes.string,
		city: PropTypes.string
	})
};


const ConnectedList = connect(
	state => ({
		clients: state.map.clients
	}),
	dispatch => ({
		onFetch: options => dispatch(clientAction__cursor(options)),
		onShowClient: client => dispatch(push(`/clients/${client.client_id}`)),
		onShowVehicles: client => dispatch(push(`/clients/${client.client_id}/vehicles`))
	})
)(ClientList);

class ClientListView extends React.Component {
	static propTypes = {
		clients: PropTypes.array.isRequired,
		client_list: PropTypes.object,
		onBack: PropTypes.func,
		mayRead: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayDelete: PropTypes.bool,
		toCreate: PropTypes.func,
		onSelect: PropTypes.func.isRequired,
		vehicles: PropTypes.object,
		client_vehicles: PropTypes.object,
		onSelectVehicle: PropTypes.func,
		has_loan_car: PropTypes.bool,
		onSetLoanCar: PropTypes.func,
		mayLoanCar: PropTypes.bool,
		changingLoanCar: PropTypes.func,
		fetching: PropTypes.bool
	};
	static defaultProps = {
		clients: [],
		mayRead: false,
		mayChange: false,
		mayCreate: false,
		mayDelete: false,
		mayLoanCar: false,
		vehicles: {},
		client_vehicles: {},
		client_list: {},
		changingLoanCar: () => false,
		onSelect: () => alert('onSelect() is not implemented yet!')
	};
	// stackGrid = null;
	
	state = {
		order: sortOptions[0].key, // sorting
		clientsPerPage: elementsPerPage[1], // clients per page
		page: 0, // current page, default 0
		searchFilter: null, // list of clients found by the multi dimensional search
		expand: {}, // list expanded cards
		searchText: '', // current search text
		searchValue: '',
		clientCount: 0,
	};
	
	handleSelect = index => (e, key, value) => this.setState({[index]: value});
	/**
	 * default setter
	 * @param index
	 * @return {function(*): (void|*)}
	 */
	set = index => value => this.setState({[index]: value, page: 0});
	/**
	 * Handle paging
	 * @param e
	 * @param page
	 * @return {void|*}
	 */
	setPage = (e, page) => this.setState({page: page.activePage - 1}, () => goToAnchor('client-header'));
	/**
	 * return sorting function
	 * @return {*}
	 */
	sorted = () => {
		if ( this.state.searchFilter ) {
			// search is active, thus sorting by priority
			return sortNumDesc(c => c.prio);
		}
		const option = sortOptions.find(el => el.key === this.state.order);
		const _ = option.key.split('-');
		const order = _.pop();
		const key = _.join('-');
		
		switch (key) {
			case 'name':
				return sort(order)(c => c.salutation === 'company' ? c.company_name || '' : c.name || '');
			default:
				return sortDefault;
		}
	};
	/**
	 * go to client detail page
	 * @param client
	 * @return {Function}
	 */
	handleClientSelect = client => (e) => {
		this.props.onSelect(client);
		e.stopPropagation();
	};
	/**
	 * Add <mark/> to highlight search results of clients
	 * @param searchText
	 * @return {function(*): {name: *, first_name: *, company_name: *, client_number: *}}
	 */
	higlightClient = searchText => client => {
		const {name, first_name, company_name, client_number} = client;
		const searchString = '(' + escapeRegex(searchText.trim()) + ')';
		const regex = new RegExp(searchString, 'ig');
		const replace = '<mark class="highlight">$1</mark>';
		return {
			...client,
			name: name ? name.replace(regex, replace) : name,
			first_name: first_name ? first_name.replace(regex, replace) : first_name,
			company_name: company_name ? company_name.replace(regex, replace) : company_name,
			client_number: client_number ? client_number.replace(regex, replace) : client_number
		};
	};
	/**
	 * Toggle loan car On/Off
	 * @param car
	 * @return {Function}
	 */
	handleLoanCar = (car) => (e, value) => {
		this.props.onSetLoanCar && this.props.onSetLoanCar(car, value);
	};
	
	updateSearch = e => {
		e.which === 13 ? this.setState({searchText: e.target.value.trim()}) : this.updateValue(e);
	};
	
	updateValue = e => {
		this.setState({searchValue: e.target.value});
	}
	
	render() {
		const {
			t,
			onBack,
			mayRead,
			mayCreate,
			toCreate,
		} = this.props;
		if ( !mayRead ) {
			return <NoAccess/>;
		}
		const {
			order: sortOrder,
			searchText,
			clientsPerPage,
			searchFilter,
			clientCount,
		} = this.state;
		sortOptions = sortOptions.map( data => processTranslate(t, data, 'title')(obj => `client.sorting.${obj.key}`));
		return(
			<div style={{paddingBottom: 70}}>
				{/* Header Line*/}
				<ActionHeader alignment={'space-between'} wrap="wrap" id={'client-header'} ulStyle={{marginLeft: 20, marginRight: 20}}>
					<ActionHeaderGroup>
						{/*{onBack && <ActionBackButton onBack={onBack}/>}*/}
						{onBack && <Button basic icon={'angle left'} onClick={onBack}/>}
						{this.state.searchText === '' ? <ActionHeading>{t('client.basic-title')} <Label style={{marginLeft: 10}} basic><Icon name={'group'}/>{clientCount}</Label></ActionHeading> : <ActionHeading>{t('client.basic-title')}<Label style={{marginLeft: 10}} basic><Icon name={'search'}/>{clientCount}</Label></ActionHeading>}
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<OptionHeader underWidth={750} tooltip={t('filter.label-show')} buttonText={t('actions.close')}>
							<ItemCountSelector
								options={elementsPerPage}
								selected={clientsPerPage}
								onChange={this.set('clientsPerPage')}
								floatingLabelText={t('client.per-page')}
							/>
							<Sortable
								disabled={searchFilter !== null}
								floatingLabelText={t('client.sort')}
								options={sortOptions}
								value={sortOrder}
								onChange={this.handleSelect('order')}
							/>
						</OptionHeader>
						<Button icon={'search'} basic size={'medium'} onClick={() => publish(SUB_CLIENT_SEARCH)}/>
						{mayCreate && toCreate && <Popup inverted position={'left center'} content={t('client.tooltip-create')} trigger={<Button color='black' style={{marginLeft: 8}} icon={'plus'} onClick={toCreate}/>}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<ConnectedList
					itemsPerPage={clientsPerPage}
					onClientCount={clientCount => this.setState({clientCount})}
					sort={sortOrder}
					searchText={searchText}
				/>
			
			</div>
		);
	}
}

export default translate()(ClientListView);