import * as React from 'react';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {loanCarCall__findInRange} from "../../../../actions/loanCarActions";
import {Header, Message, Segment} from "semantic-ui-react";
import {moment} from "../../../../Logic/Moment";
import {falseNull} from "../../../../Logic/extensions";
import {EasyFlex} from "../../../partials/ActionHeader";
import cn from "classnames";
import {dateTime} from "./Helpers";
import {translate} from "react-i18next";

export class LoanCarSelector extends StatyComponent {
	static propTypes = {
		preSelection: PropTypes.number,
		list: PropTypes.arrayOf(PropTypes.object),
		rangeStart: PropTypes.instanceOf(Date).isRequired,
		rangeEnd: PropTypes.instanceOf(Date).isRequired,
		onSelect: PropTypes.func.isRequired,
		onSearch: PropTypes.func,
		searchOnMount: PropTypes.bool
	};
	static defaultProps = {
		list: null,
		onSearch: (start_point, end_point) => loanCarCall__findInRange({start_point, end_point})
	};
	state = {
		searching: false,
		list: null,
		selection: 0,
		error: null
	};
	preSelected = false;
	
	search = async () => {
		const {onSearch, preSelection} = this.props;
		try {
			this.setState({searching: true, error: null});
			const result = await onSearch(this.props.rangeStart, this.props.rangeEnd);
			const list = result.list || [];
			const selectionBeacon = preSelection && !this.preSelected ? preSelection : this.state.selection;
			this.preSelected = true;
			const exists = list.find(v => v.vehicle_id === selectionBeacon);
			this.setState({list, selection: exists ? exists.vehicle_id : 0}, () => this.props.onSelect(this.state.selection || null));
		} catch (e) {
			console.error(e);
			this.setState({error: 'Fehler bei der Aktualisierung', list: []});
		} finally {
			this.setState({searching: false});
		}
	};
	
	componentDidMount() {
		super.componentDidMount();
		this.preSelected = false;
		this.props.searchOnMount && this.search();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (dateTime(prevProps.rangeStart) !== dateTime(this.props.rangeStart) || dateTime(prevProps.rangeEnd) !== dateTime(this.props.rangeEnd)) {
			console.warn('searing anew...');
			this.search();
		}
	}
	
	get list() {
		return this.state.list || this.props.list || [];
	}
	
	select = (id) => () => this.setState(state => ({
		selection: state.selection === id ? 0 : id
	}), () => this.props.onSelect(this.state.selection || null));
	
	render() {
		const {selection, searching} = this.state;
		const {rangeStart, rangeEnd} = this.props;
		return (
			<Segment basic loading={searching}>
				<Header as={'h4'} style={{textAlign: 'center'}}>
					Leihwagen auswählen
					<Header.Subheader>{moment(rangeStart).format('LLL [Uhr]')} <strong>bis</strong> {moment(rangeEnd).format('LLL [Uhr]')}</Header.Subheader>
				</Header>
				{falseNull(this.list.length) && <Message><p>Keine Leihwagen verfügbar!</p></Message>}
				<EasyFlex valign={EasyFlex.valign.STRETCH} align={EasyFlex.align.CENTER} wrap>
					{this.list.map(v =>
						<Segment key={v.vehicle_id} className={cn('order-search-loan-car', {active: selection === v.vehicle_id})} style={{margin: 5}} onClick={this.select(v.vehicle_id)}>
							<div>{v.name}</div>
							<div>{v.registration_mark}</div>
						</Segment>
					)}
				</EasyFlex>
			</Segment>
		);
	}
}

LoanCarSelector = translate()(LoanCarSelector);