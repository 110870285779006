
import {ERROR_ACTION} from "../actions/types";

let errorID = 0;

const defaultState = {
	list: []
};

const errorReducer = (state = defaultState, {type, message, code, params, error_code, feedback, error}) => {
	switch (type) {
		case ERROR_ACTION.ADD:
			return {
				...state,
				list: [{id: errorID++, message, code, params, error_code, feedback, at: new Date().getTime(), error}, ...state.list]
			};
		case ERROR_ACTION.REMOVE:
		case ERROR_ACTION.DISMISS:
			state.list.pop();
			return {
				...state,
				list: [...state.list]
			};
		default:
			return state;
	}
};

export default errorReducer;