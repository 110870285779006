import * as React from "react";
import {Button, Label, Modal, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import withInit from "../../../Logic/withInit";
import {translate} from "react-i18next";
import {isOnline} from "../../../actions/userActions";
import {loanCarAction__schedule} from "../../../actions/loanCarActions";
import {PROC_LOAN_CARS_FETCH, SUB_LOAN_CAR_SCHEDULE_EVENT_REMOVED} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {ActionHeader, ActionHeaderGroup, ActionHeading, EasyFlex} from "../../../components/partials/ActionHeader";
import BigCalendar from "react-big-calendar";
import {cloneDeep, get} from "lodash";
import {IconButton} from "material-ui";
import {IconAdd, IconClose, IconPerson} from "../../../Logic/icons";
import cn from "classnames";
import LoanCarScheduleDetail from "./LoanCarScheduleDetail";
import LoanCarScheduleMask from "./LoanCarScheduleMask";
import KeyHandler, {KEYUP} from "react-key-handler";
import {withModule, withRights} from "../../../Tools/RightsProvider";
import {SECTION} from "../../../Logic/constants";
import {publish, subscribe} from "../../../Logic/PubSub";
import {RegistrationMark} from "../../../components/intern/vehicles/RegistrationMark";
import Client from "../../../models/Client";

class LoanCarSchedule extends React.Component {
	state = {
		view: "week",
		date : new Date(),
		detail_id: 0,
		create: false
	};
	
	componentDidMount() {
		this.unsubscribeRemovedEvent = subscribe(SUB_LOAN_CAR_SCHEDULE_EVENT_REMOVED, id => {
			if(this.state.detail_id === id) {
				this.setDetailId(0)();
			}
		})
	}
	
	componentWillUnmount() {
		this.unsubscribeRemovedEvent && this.unsubscribeRemovedEvent();
	}
	
	setView = (view) => this.setState({view, detail_id: 0}, this.updateSchedule);
	setDate = (date) => this.setState({date, detail_id: 0}, this.updateSchedule);
	setDetailId = (detail_id) => () => this.setState({detail_id});
	setCreate = create => () => this.setState({create});
	
	prepareCalendar = () => {
		const {i18n, t} = this.props;
		const messages = i18n.getResource(i18n.language.match(/\w+/), "translations", "big-calendar.defs") || {};
		messages.showMore = count => t("big-calendar.showMore", {count});
		return {
			defaultDate: new Date(),
			messages,
			culture: "de",
			defaultView: "week",
			views: ["month", "week", "day", "agenda"]
		};
	};
	
	prepareData = () => {
		const {schedule, cars, clients, t} = this.props;
		const next = cloneDeep(schedule || []) || [];
		return next.map(s => {
			const rm = get(cars, [s.vehicle_id, "registration_mark"], null);
			const title = get(cars, [s.vehicle_id, "name"], "-");
			let client = null;
			if (s.client_id) {
				client = get(clients, s.client_id, null);
				if (client) {
					client = Client._(client);
					client = client.getName(t);
				}
			}
			if (!rm && !client) {
				s.title = title;
			} else {
				s.title = <div>
					{client && <EasyFlex valign={EasyFlex.valign.CENTER} style={{marginTop: 5, marginBottom: 12}}><IconPerson/> {client}</EasyFlex>}
					<div>{title}</div>
					{rm && <div style={{marginTop: 5}}><RegistrationMark>{rm}</RegistrationMark></div>}
				</div>;
			}
			s.id = s.loan_car_schedule_id;
			s.start = new Date(s.start_point);
			s.end = new Date(s.end_point);
			return s;
		});
	};
	
	prepareDetail = (id) => {
		if ( id === 0 ) {
			return {vehicle: {}, schedule: {}};
		}
		try {
			const {schedule: detailSchedule, cars} = this.props;
			const schedule = detailSchedule.find(s => s.loan_car_schedule_id === id);
			const vehicle = cars[schedule.vehicle_id];
			return {vehicle, schedule};
		} catch (e) {
			return {vehicle: {}, schedule: {}};
		}
	};
	
	onSelectEvent = (event) => {
		// console.error('event', event);
		this.setDetailId(event.loan_car_schedule_id)();
	};
	
	updateSchedule = () => this.props.onSchedule(this.state.date, this.state.view !== "agenda" ? this.state.view : 30);
	
	render() {
		const {
			loading,
			// onSchedule,
			rights,
			allRights,
			modules
		} = this.props;
		const {detail_id, create: showCreateMask} = this.state;
		const calendarProps = this.prepareCalendar();
		const events = this.prepareData();
		const detailProps = this.prepareDetail(detail_id);
		const mayCreate = rights.mayCreate || allRights[SECTION.MASTER_DATA].mayCreate;
		return(
			<Segment basic loading={loading}>
				<KeyHandler
					keyValue={'Insert'}
					keyEventName={KEYUP}
					onKeyHandle={this.setCreate(true)}
				/>
				<ActionHeader alignment={"space-between"}>
					<ActionHeaderGroup>
						<ActionHeading>Leihwagenkalender {!modules.loancar && <Label color={'red'} style={{marginLeft: 8}}>Leihwagenmodul inaktiv</Label>}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{mayCreate ? <IconButton onClick={this.setCreate(true)}><IconAdd/></IconButton> : null}
					</ActionHeaderGroup>
				</ActionHeader>
				<div id={"loan-car-calendar"} className={cn({details: detail_id !== 0})}>
					<BigCalendar
						{...calendarProps}
						step={30}
						timeslots={2}
						events={events}
						onView={this.setView}
						onNavigate={this.setDate}
						onSelectEvent={this.onSelectEvent}
					/>
					<div id={"loan-car-sideinfo"}>
						<div className="sideinfo-content">
							<div className="sideinfo-header">
								<IconButton onClick={this.setDetailId(0)}><IconClose/></IconButton>
							</div>
							<div className="sideinfo-body">
								{detail_id !== 0 ? <LoanCarScheduleDetail onDelete={this.setDetailId(0)} schedule_id={detail_id} {...detailProps}/> : null}
							</div>
						</div>
					</div>
				</div>
				<Modal open={showCreateMask} onClose={this.setCreate(false)}>
					<Modal.Content>
						<LoanCarScheduleMask/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.setCreate(false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>
			</Segment>
		);
	}
}
LoanCarSchedule = withModule(LoanCarSchedule);
LoanCarSchedule = withInit(LoanCarSchedule);
LoanCarSchedule = withRights(SECTION.ORDERS, LoanCarSchedule);
LoanCarSchedule = connect(
	(state, props) => {
		return {
			loading: isLoading(state, PROC_LOAN_CARS_FETCH),
			cars: state.loan_cars.list,
			schedule: state.loan_cars.schedule,
			clients: state.clients.list
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(loanCarAction__schedule());
		})),
		onSchedule: (day, range, onSuccess) => dispatch(loanCarAction__schedule(day, range, onSuccess))
	})
)(LoanCarSchedule);
LoanCarSchedule = translate()(LoanCarSchedule);

export default LoanCarSchedule;

export const requestLoanCarScheduleEventRemovement = (id) => publish(SUB_LOAN_CAR_SCHEDULE_EVENT_REMOVED, id);