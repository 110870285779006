/**
 * Validation Context:
 * Context for ValidationField.js
 *
 * This context sums up the ValidationField onValid() results
 *
 * @example
 * class FormComonent extends React.Component {
 *      validationObject = getValidationValue(this.onValidationUpdate); // creating context object
 *
 *      onValidationUpdate = (validationData) => { ... validation data handling, maybe updating state};
 *
 *      render() {
 *          return (
 *              <ValidationProvider value={this.validationObject}>
 *                  <Content>
 *                      <ValidationField  .../>
 *                  </Content>
 *              </ValidationProvider>
 *          );
 *      }
 *  }
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import {Form} from "semantic-ui-react";
import uniq from "lodash/uniq";

/**
 * This method should be called only once, to create the ValidationProvider value for the context object.
 * Ideally as class property.
 *
 *
 *
 * @param onUpdate
 *      this method is called ad bound to the return object and the only param is the return object hiself.
 *      this object contains informations about the collected errors of his sub ValidationField's
 *
 * @param silentMode
 * @return {{error: number, errorsByName: {}, errorsBySerial: {}, add: function(*, *, *=): getValidationValue, remove: function(*, *=): getValidationValue, get: function(): getValidationValue, onUpdate: *}}
 */
export const getValidationValue = (onUpdate, silentMode = null) => ({
	/**
	 * current serial instance
	 */
	serial: 1,
	usedSerials: [],
	/**
	 * defaults null
	 */
	silentMode,
	/**
	 * each ValidationField has its unique serial number powered by 2,
	 * if this value is not 0 (zero) then there is an unfulfilled requirement.
	 */
	error: 0,
	/**
	 * if the ValidationField contains the name property then this field contains the error message by this name.
	 * @example
	 *  name = 'title'
	 *  errorsByName: {title: "required"}
	 */
	errorsByName: {},
	/**
	 * like errorsByName, just with the serial instead of the name. thus it always exists.
	 */
	errorsBySerial: {},
	registered: {},
	/**
	 * this function is consumed by the ValidationField to add error
	 * @param error
	 * @param serial
	 * @param name
	 * @return {getValidationValue}
	 */
	add: function(error, serial, name) {
		this.error = this.error | serial;
		this.errorsBySerial[serial] = error;
		if ( name ) {
			this.errorsByName[name] = error;
		}
		this.onUpdate(this);
		return this;
	},
	/**
	 * this function is consumed by the ValidationField to remove error
	 * @param serial
	 * @param name
	 * @return {getValidationValue}
	 */
	remove: function(serial, name) {
		this.error = this.error & ( this.error ^ serial);
		if ( this.errorsBySerial[serial]) {
			delete this.errorsBySerial[serial];
		}
		if ( name && this.errorsByName[name]) {
			delete this.errorsByName[name];
		}
		this.onUpdate(this);
		return this;
	},
	/**
	 * getter of this object
	 * @return {getValidationValue}
	 */
	get: function() {return this;},
	/**
	 * consumed by the ValidationField to trigger changes
	 */
	onUpdate: onUpdate.bind(this),
	/**
	 *
	 * @return {number} next serial, power of 2
	 */
	getNextSerial: function(name = null) {
		let current;
		if (this.usedSerials.length) {
			current = this.usedSerials.pop();
		} else {
			current = this.serial;
			this.serial *= 2;
		}
		if ( name ) {
			this.registered[name] = current;
		}
		return current;
	},
	
	subscribe: function(name=null) {
		return this.getNextSerial(name);
	},
	
	unsubscribe: function(serial, name=null) {
		if (serial < this.serial) {
			this.remove(serial, name);
			this.usedSerials = uniq([serial, ...this.usedSerials]);
			if ( name ) {
				delete this.registered[name];
			}
		}
		return this;
	}
});

const {Provider: ValidationContextProvider, Consumer: ValidationContextConsumer} = React.createContext();

/**
 * Component to provide validation of ValidationField components within this component
 *
 * @param props -> value is needed, look at the top documentation
 * @return {*}
 * @constructor
 */
export const ValidationProvider = (props) => (<ValidationContextProvider {...props}/>);

/**
 *
 * @param Component
 * @return {function({value: *, props: *}): *}
 */
export const withValidationProvider = Component => class extends React.Component {
	render() {
		const {value, ...props} = this.props;
		return ( <ValidationContextProvider value={value}><Component {...props}/></ValidationContextProvider>);
	}
};

/**
 * Function to infuse a consumer with the validation data object.
 * Thus consumed by ValidationField
 *
 * @param Component
 * @return {function(*): *}
 */
export const withValidation = Component => props => (
	<ValidationContextConsumer>
		{data =>
			<Component validationProvider={data} {...props}/>
		}
	</ValidationContextConsumer>
);

ValidationProvider.propTypes = {
	value: PropTypes.shape({
		error: PropTypes.number.isRequired,
		errorsByName: PropTypes.object.isRequired,
		errorsBySerial: PropTypes.object.isRequired,
		add: PropTypes.func.isRequired,
		remove: PropTypes.func.isRequired,
		get: PropTypes.func.isRequired
	}).isRequired
};

/**
 * enchances Semanti-UI Form element with validation provider
 * @type {function({value: *, props: *}): *}
 */
export const ValidationForm = withValidationProvider(Form);

ValidationForm.propTypes = {
	...Form.propTypes,
	value: PropTypes.object.isRequired
};
ValidationForm.defaultProps = {
	...Form.defaultProps
};