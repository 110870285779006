import * as React from "react";
import {trueNull} from "../../Logic/extensions";
import {Button, Header, Icon, Image, Modal, Placeholder, Table} from "semantic-ui-react";
import {subscribe} from "../../Logic/PubSub";
import {SUB_PAUSED_NOTIFICATION} from "../../actions";
import ServiceImage from "../../images/service2.png";
import {Client, Order, OrderService, OrderVehicle, Vehicle} from "../../models";
import {EasyFlex} from "../../components/partials/ActionHeader";
import ClientPopupInfo from "../../components/intern/clients/ClientPopupInfo";
import VehiclePopupInfo from "../../components/intern/vehicles/VehiclePopupInfo";
import {translate} from "react-i18next";
import OrderQuickView from "./orders/OrderQuickView";
import Countdown, {renderSimple} from "../../components/partials/Countdown";
import {COLORS} from "../../Logic/constants";
import moment from "../../Logic/Moment";
import {withUser} from "../../Tools/RightsProvider";

const getArray = (count) => {
	let a = [], i;
	for (i = 0; i < count; ++i) {
		a.push(i);
	}
	return a;
};

const RowPlaceholder = (props) => (
	<Table.Row {...props}>
		<Table.Cell>
			<Placeholder>
				<Placeholder.Image square style={{width: 48}}/>
			</Placeholder>
		</Table.Cell>
		<Table.Cell style={{width: "100%"}} colSpan={4}>
			<Placeholder>
				<Placeholder.Header>
					<Placeholder.Line length={"medium"}/>
					<Placeholder.Line length={"long"}/>
				</Placeholder.Header>
			</Placeholder>
		</Table.Cell>
	</Table.Row>
);

const TablePlaceholder = ({count}) => (
	<Table basic={"very"} celled collapsing>
		<Table.Body>
			{getArray(count).map((_, i) =>
				<RowPlaceholder key={i}/>
			)}
		</Table.Body>
	</Table>
);


const getChain = (oid, orders, ov, clients) => {
	try {
		const order = orders.find(o => o.order_id === oid);
		const vehicle = ov.find(v => v.order_vehicle_id === order.order_vehicle_id);
		const client = clients.find(c => c.client_id === vehicle.client_id);
		return [new Order(order), new OrderVehicle(vehicle), new Client(client)];
	} catch (e) {
		// console.error(e);
		return [null, null, null];
	}
};

const PauseHeader = () => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell>&nbsp;</Table.HeaderCell>
			<Table.HeaderCell>
				<Header>
					<Header.Content>
						<EasyFlex valign={EasyFlex.valign.CENTER}><span style={{marginRight: 5}}>Name des Kunden</span></EasyFlex>
						<Header.Subheader>Servicebezeichung</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.HeaderCell>
			<Table.HeaderCell verticalAlign={"middle"}>
				<Header as={"h5"}>
					<Header.Content>
						Ressource
						<Header.Subheader>Offiziell</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.HeaderCell>
			<Table.HeaderCell verticalAlign={"middle"} textAlign={"right"}>
				<Header as={"h5"}>
					<Header.Content style={{textAlign: "right"}}>
						<Header.Subheader>Deadline</Header.Subheader>
						<Header.Subheader>Abgabe</Header.Subheader>
					</Header.Content>
				</Header>
			</Table.HeaderCell>
			<Table.HeaderCell verticalAlign={"middle"}>&nbsp;</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);

const renderCountdown = (color = COLORS.SEMANTIC_RED) => (data) => {
	data[2] += (data[1] * 24);
	const value = (data[0] ? '-' : '') + renderSimple(data);
	return <span style={{fontWeight: trueNull(data[0]) && 'bold', color: data[0] ? color : null}}>{value}</span>;
};

let PausedList = ({user, paused, showClient, showVehicle, showOrder, showService}) => (
	<Order.CollectProvider dynamic ids={paused.map(p => p.order_id)} placeholder={<TablePlaceholder count={paused.length}/>}>{(orders) =>
		<Vehicle.CollectOrderProvider dynamic ids={orders.map(o => o.order_vehicle_id)} placeholder={<TablePlaceholder count={paused.length}/>}>{ov =>
			<Client.CollectProvider preview dynamic ids={ov.map(o => o.client_id)} placeholder={<TablePlaceholder count={paused.length}/>}>{clients =>
				<Table basic={"very"} celled collapsing>
					<PauseHeader/>
					<Table.Body>
						{paused.map((service, key) => {
							const [order, vehicle, client] = getChain(service.order_id, orders, ov, clients);
							if (!order || !vehicle || !client) {
								// console.error('chain', service.order_id, order, vehicle, client);
								return <RowPlaceholder key={key}/>
							}
							return <Table.Row key={service.order_service_id}>
								<Table.Cell><Image src={ServiceImage} style={{maxWidth: 48, maxHeight: 48}}/></Table.Cell>
								<Table.Cell>
									<Header>
										<Header.Content>
											<EasyFlex valign={EasyFlex.valign.CENTER}><span style={{marginRight: 5}}>{client.icon}</span><span>{client.contact}</span></EasyFlex>
											<Header.Subheader>{service.service_name}</Header.Subheader>
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell verticalAlign={"middle"}>
									<Header as={"h5"}>
										<Header.Content>
											{service.hours_of_work_resource} Std.
											{trueNull(service.hours_of_work_resource !== service.hours_of_work) && <Header.Subheader>{service.hours_of_work} Std.</Header.Subheader>}
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell verticalAlign={"middle"}>
									<Header as={"h5"}>
										<Header.Content style={{fontFamily: "monospace", textAlign: "right"}}>
											<Header.Subheader><Countdown render={renderCountdown(COLORS.SEMANTIC_ORANGE)} targetDate={moment(service.latest_point).toDate()}/> Std.</Header.Subheader>
											<Header.Subheader><Countdown render={renderCountdown()} targetDate={moment(service.fetch_point).subtract(user.is_engineer ? service.post_process_duration : 0, 'minute').toDate()}/> Std.</Header.Subheader>
										</Header.Content>
									</Header>
								</Table.Cell>
								<Table.Cell verticalAlign={"middle"}>
									<Button.Group size={"large"}>
										<Button icon={"list"} onClick={() => showOrder(order)}/>
										<Button icon={"wrench"} onClick={() => showService(service.order_service_id)}/>
										<Button icon={"car"} onClick={() => showVehicle(vehicle)}/>
										<Button icon={"user"} onClick={() => showClient(client)}/>
									</Button.Group>
								</Table.Cell>
							</Table.Row>;
						})}
					</Table.Body>
				</Table>
			}</Client.CollectProvider>
		}</Vehicle.CollectOrderProvider>
	}</Order.CollectProvider>
);

PausedList = withUser(PausedList);

const ClientModal = ({client, onClose}) => (
	<Modal open closeIcon onClose={onClose} centered={false} size={"tiny"} >
		<Modal.Header as={"h3"}>Kunde: {client.contact}</Modal.Header>
		<Modal.Content>
			<ClientPopupInfo inverted={false} client={client} style={{textAlign: "center"}} tableProps={{style: {display: "inline-table", borderCollapse: "collapse"}}}/>
		</Modal.Content>
	</Modal>
);

const PLoader = () => (
	<Modal.Content style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: 150}}>
		<Icon name={"circle notch"} loading size={"big"}/>
	</Modal.Content>
);

const OrderServiceModal = ({service, onClose}) => (
	<Modal defaultOpen closeIcon onClose={onClose} centered={false} size={"tiny"}>
		<OrderService.Provider id={service.order_service_id || service} dynamic placeholder={<PLoader/>}>{service =>
			<React.Fragment>
				<Modal.Header>Service: {service.service_name}</Modal.Header>
				<Modal.Content style={{textAlign: "center"}}>
					<OrderService.PopupInfo service={service} inverted={false} style={{display: "inline-table"}}/>
				</Modal.Content>
			</React.Fragment>
		}</OrderService.Provider>
	</Modal>
);



const OrderVehicleModal = ({vehicle, onClose}) => (
	<Modal open closeIcon onClose={onClose} centered={false} size={"tiny"} >
		<Modal.Header as={"h3"}>Fahrzeug: {vehicle.name}</Modal.Header>
		<Modal.Content style={{textAlign: "center"}}>
			<VehiclePopupInfo inverted={false} vehicle={vehicle} style={{display: "inline-table", borderCollapse: "collapse"}}/>
		</Modal.Content>
	</Modal>
);

// const OrderModal = ({order, onClose}) => (
// 	<Modal open closeIcon onClose={onClose} centered={false} size={"mini"} >
// 		<Modal.Header as={"h3"}>Auftrag: {order.order_id}</Modal.Header>
// 		<Modal.Content style={{textAlign: "center"}}>
// 			<Order.PopupInfo inverted={false} order={order} style={{display: "inline-table"}}/>
// 		</Modal.Content>
// 	</Modal>
// );

const OrderFullModal = ({order, onClose, t}) => (
	<Modal open onClose={onClose} style={{top: 0, width:
			"100%", position: "fixed", zIndex: 1000, filter: "none", background: "white", padding: 0}}>
		<Modal.Content style={{padding: 0, maxHeight: "calc(100vh - 93px)", height: "calc(100vh - 93px)"}} scrolling>
			<OrderQuickView order_id={order.order_id} onRequestClose={onClose}/>
		</Modal.Content>
		<Modal.Actions><Button onClick={onClose}>{t('actions.close')}</Button></Modal.Actions>
	</Modal>
);


export class PausedNotificationController extends React.Component {
	state = {
		paused: [],
		client: null,
		vehicle: null,
		order: null,
		service: null
	};
	mountNode = React.createRef();
	
	setPaused = (list = []) => this.setState({paused: list});
	showDetail = (section) => (data = null) => this.setState({[section]: data});
	
	componentDidMount() {
		this.unsubscribePauseNotification = subscribe(SUB_PAUSED_NOTIFICATION, this.setPaused);
	}
	
	componentWillUnmount() {
		this.unsubscribePauseNotification && this.unsubscribePauseNotification();
	}
	
	render() {
		const {t} = this.props;
		const {paused, client, vehicle, order, service} = this.state;
		return trueNull(paused.length) && <Modal closeOnDimmerClick={false} open closeIcon centered={false} onClose={() => this.setPaused()}>
			<Modal.Header><Icon name="attention" color={"red"}/>Pausierte Aufträge</Modal.Header>
			<Modal.Content  >
				{/*<TablePlaceholder count={paused.length}/>*/}
				<PausedList
					paused={paused}
					showClient={this.showDetail('client')}
					showVehicle={this.showDetail('vehicle')}
					showOrder={this.showDetail('order')}
					showService={this.showDetail('service')}
				/>
			</Modal.Content>
			<Modal.Actions ref={this.mountNode}>
				<Button onClick={() => this.setPaused()}>{t('actions.close')}</Button>
			</Modal.Actions>
			{client && <ClientModal client={client} onClose={this.showDetail('client').bind(this, null)}/>}
			{vehicle && <OrderVehicleModal vehicle={vehicle} onClose={this.showDetail('vehicle').bind(this, null)}/>}
			{order && <OrderFullModal t={t} order={order} onClose={this.showDetail('order').bind(this, null)}/>}
			{service && <OrderServiceModal service={service} onClose={this.showDetail('service').bind(this, null)}/>}
		</Modal>;
	}
}

PausedNotificationController = translate()(PausedNotificationController);