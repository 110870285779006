/////////////
//      Quick Service
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty, uniqBy} from "lodash";
import {Button, Icon, Item, Label, Message, Modal, Popup, Segment} from "semantic-ui-react";
import {If} from "../../../../Logic/Switch";
import round from "round-precision";
import {blackOrWhite, deepMemoize as memoizeOne, logoLink, partitionArray, trueNull} from "../../../../Logic/extensions";
import {ConnectedWorkerAvatarView, ConnectedWorkerAvatarWorkingView} from "../../workers";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__collect, orderAction__collectServices, orderAction__fetchOne} from "../../../../actions/orderActions";
import {kfzAction__fetchServices} from "../../../../actions/kfzActions";
import {workerAction__getWork} from "../../../../actions/workerActions";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import {IconBuild, IconDone, IconPauseCircleOutline} from "../../../../Logic/icons";
import {COLORS} from "../../../../Logic/constants";
import {Work} from "../../../../models/Work";
import {WorkerAvatarView} from "../../workers/WorkerAvatarView";
import {ReleaseIcon} from "../../release";
import {Worker} from "../../../../models";
import {Trans, translate} from "react-i18next";

const ServiceStatus = ({serviceInfo, workDone, itemStyle, t, ...props}) => {
	workDone = round(workDone / 3600, 1);
	const hasDifferentResourceWorktime = serviceInfo.hours_of_work !== serviceInfo.hours_of_work_resource;
	return(
		<EasyFlex direction={EasyFlex.direction.COLUMN} align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{padding: 8}} {...props}>
			<EasyFlex align={EasyFlex.align.CENTER} style={{background: "#f5f5f5", padding: 12, borderRadius: 20, ...itemStyle}}>
				<ReleaseIcon id={serviceInfo.order_service_id} api size={24} childStyle={{marginRight: 12}}/>
				{
					serviceInfo.paused_by ?
						<Popup inverted content={
							<ConnectedWorkerAvatarView workerId={serviceInfo.paused_by}>Pausiert</ConnectedWorkerAvatarView>
						} trigger={<IconPauseCircleOutline color={COLORS.SEMANTIC_RED}/>}/> :
					<Popup
						inverted
						content={serviceInfo.finished_at ? t('...', 'Servicearbeiten abgeschloßen') : t('...', 'Servicearbeiten nicht abgeschloßen')}
						trigger={<IconDone color={serviceInfo.finished_at ? COLORS.SEMANTIC_GREEN : COLORS.SEMANTIC_RED}/>}
					/>
				}
			</EasyFlex>
			<EasyFlex style={itemStyle}>
				<Popup
					inverted
					content={hasDifferentResourceWorktime ? t('...', 'Geplante Arbeitszeit (für den Kunden)') : t('...', 'Geplante Arbeitzeit')}
					trigger={<div><strong>{round(serviceInfo.hours_of_work, 1)}</strong> Std.</div>}
				/>
				{hasDifferentResourceWorktime &&
					<React.Fragment>
						<div>&nbsp;/&nbsp;</div>
						<Popup
							inverted
							content={t('...', 'Geplante Arbeitszeit (für die Werkstatt)')}
							trigger={<div><strong>{round(serviceInfo.hours_of_work_resource, 1)}</strong> Std.</div>}
						/>
						
					</React.Fragment>
				}
			</EasyFlex>
			<EasyFlex style={itemStyle}>
				<Popup
					inverted
					content={t('...', 'Absolvierte Arbeitszeit (Kann auch bei fertigen Leistungen 0 betragen)')}
					trigger={<div style={{display: "flex", alignItems: "center"}}><IconBuild color={"#ccc"} style={{marginRight: 4, width: 18, height: 18}}/> <strong>{workDone}</strong>&nbsp;Std.</div>}
				/>
			</EasyFlex>
			{/*<EasyFlex style={itemStyle}>
				<Popup
					inverted
					content={"Angesetzter Stundenpreis"}
					trigger={<div><strong>{round(serviceInfo.price, 2)}</strong> €&nbsp;/&nbsp;Std.</div>}
				/>
			</EasyFlex>*/}
		</EasyFlex>
	);
};

let WorkerView = ({worker: w}) => {
	if (!w) {
		return null;
	}
	
	return <WorkerAvatarView worker={w}/>;
};
WorkerView = connect((state, props) => ({worker: state.map.workers[props.workers_id] || state.workers.list[props.workers_id]}))(WorkerView);

const WorkDoneView = ({work}) => {
	if (!work.length) {
		return <div></div>;
	}
	// const total = work.map(o => o.reduce((s, w) => s + w.duration, 0));
	let next = work.map(w => w.sort((a, b) => b.end_point - a.end_point)).map(w => w[w.length - 1]);
	
	// return <pre>{JSON.stringify(next, null, 2)}</pre>;
	return next.map((w) => <EasyFlex style={{marginBottom: 8}} direction={EasyFlex.direction.COLUMN} key={w.workers_id}>
		<div><Trans defaults="Durchgeführt am {date, date, medium} {date, time, short} Uhr von" values={{date: new Date(w.end_point)}}/></div>
		<div style={{padding: '4px 8px 0 12px'}}><WorkerView workers_id={w.workers_id}/></div>
	</EasyFlex>);
};


const DescModal = ({service, title, children, ...props}) =>
	<Modal {...props} trigger={children} closeIcon>
		{service.title &&<Modal.Header><Icon name={'info'}/> {service.title}</Modal.Header>}
		<Modal.Content>
			{service.description}
		</Modal.Content>
	</Modal>;

// noinspection DuplicatedCode
export class OrderQuickService extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		services: PropTypes.arrayOf(PropTypes.object),
		serviceMap: PropTypes.object,
		work: PropTypes.arrayOf(PropTypes.object),
		workHistory: PropTypes.arrayOf(PropTypes.object),
		onCollectOrders: PropTypes.func,
		onCollectOrderServices: PropTypes.func
	};
	static defaultProps = {
		order: {},
		services: [],
		serviceMap: {},
		work: [],
		workHistory: []
	};
	
	componentDidMount() {
		this.fetchOrder(this.props.order.order_id);
		this.fetchOrderServices(this.props.services);
	}
	
	fetchOrder = memoizeOne(
		order_id => this.props.onCollectOrders([order_id])
	);
	fetchOrderServices = memoizeOne(
		services => {
			const ids = services.map(s => s.order_service_id);
			this.props.onCollectOrderServices([ids]);
		}
	);
	
	resourceArray = memoizeOne(
		resources => Object.values(resources)
	);
	
	// noinspection DuplicatedCode
	buildResources = memoizeOne(
		(services, resources, source) => {
			let map = {};
			const resourceList = this.resourceArray(resources);
			for(const {order_service_id} of services) {
				for( const resource of resourceList) {
					if (Number(resource.order_service_id) === Number(order_service_id)) {
						if (!map[order_service_id]) {
							map[order_service_id] = [];
						}
						if (source[resource.resource_id]) {
							map[order_service_id].push(source[resource.resource_id]);
						} else {
							map[order_service_id].push(resource);
						}
					}
				}
			}
			return map;
		}
	);
	
	uniqResources = memoizeOne(
		map => {
			let next = {};
			for (const i in map) {
				// noinspection JSUnfilteredForInLoop
				next[i] = uniqBy(map[i], r => r.resource_id);
			}
			return next;
		}
	);
	
	historyWorkers = memoizeOne(
		list => {
			let map = {};
			for(const work of list) {
				map[work.workers_id] = true;
			}
			return Object.keys(map).sort();
		}
	);
	
	render() {
		const {
			order,
			services,
			serviceMap,
			orderResources,
			resources,
			work,
			workHistory,
			t
		} = this.props;
		if (isEmpty(serviceMap) || isEmpty(order)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const resourceMap = this.uniqResources(this.buildResources(services, orderResources, resources));
		const collectableWorkerIds = this.historyWorkers(workHistory);
		return (
			<div style={{marginTop: 25, padding: 15}}>
				<Worker.CollectProvider ids={collectableWorkerIds}/>
				<Item.Group relaxed divided>
					<If condition={services.length === 0}>
						<Item key={"missing-services"}>
							<Item.Content>
								<Item.Header>
									<Message negative><Trans defaults="Keine Services zugwiesen!"/></Message>
								</Item.Header>
							</Item.Content>
						</Item>
					</If>
					{services.map(serviceInfo => {
						const service = serviceMap[serviceInfo.service_id];
						const serviceWork = work.filter(w => Number(w.service_id) === Number(service.service_id));
						const serviceWorkHistory = workHistory.filter(w => Number(w.service_id) === Number(service.service_id));
						let workDone = serviceWorkHistory.reduce((carry, work) => carry + work.duration, 0);
						const resourceList = resourceMap[serviceInfo.order_service_id] || [];
						return (
							<EasyFlex key={serviceInfo.order_service_id} style={{marginTop: 8, marginBottom: 8}} direction={EasyFlex.direction.COLUMN} className={"order-service-list"}>
								<h2 className={"osl-title"}>{service.description && <DescModal service={service}><Button size={'tiny'} icon={'info'} basic/></DescModal>} {service.title}</h2>
								{trueNull(true) && <h3 className={"osl-title"}>
									{resourceList.map(resource =>
										<Label key={resource.resource_id} style={{backgroundColor: resource.color || null, color: blackOrWhite(resource.color || '#ffffff')}}>{resource.name || resource.resource_name}</Label>
									)}
								</h3>}
								<EasyFlex wrap={EasyFlex.wrap.YES} className={"osl-body"}>
									<EasyFlex className={"osl-image-info"}>
										<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} className={"osl-image"} style={{width: 150}}>
											<img src={logoLink(service)} alt={service.title} style={{maxWidth: 150, maxHeight: 150}}/>
										</EasyFlex>
										<div style={{paddingLeft: 32, paddingRight:12, flexShrink: 0}} className={"osl-info"}>
											<ServiceStatus workDone={workDone} serviceInfo={serviceInfo} itemStyle={{margin: 8}} className={"osl-info-status"} t={t}/>
										</div>
									</EasyFlex>
									<div style={{flexGrow: 1, marginLeft: -12, paddingLeft: 24}} className={"osl-work-history"}>
										<div style={{marginTop: 15, marginBottom: 35}}>
											{serviceInfo.finished_at ? <Work.Connector order_id={order.order_id} service_id={serviceInfo.service_id}>{worklist => {
												worklist = partitionArray(worklist, w => w.workers_id);
												return <WorkDoneView work={worklist} t={t}/>
											}}</Work.Connector> :
												(serviceWork.length ? serviceWork.map(work => {
												return (
													<ConnectedWorkerAvatarWorkingView key={work.work_id} workerId={work.workers_id} since={work.start_point}>
														{/*Mitarbeiter ({work.workers_id}) seit {moment(work.start_point).format("LLL")}*/}
														<Trans defaults="Mitarbeiter ({wid}) seit {date, date, medium} {date, time, short} Uhr" values={{wid: work.workers_id, date: new Date(work.start_point)}}/>
													</ConnectedWorkerAvatarWorkingView>
												);
											}) : <Item.Meta><Trans defaults="Aktuell unbearbeitet."/></Item.Meta>)}
										</div>
										
									</div>
								</EasyFlex>
							</EasyFlex>
						);
					})}
				</Item.Group>
			</div>
		);
	}
}

OrderQuickService = withInit(OrderQuickService);
OrderQuickService = connect(
	(state, props) => {
		const orderID = Number(props.order_id);
		const order = state.map.orders[orderID];//state.appointments.orders[orderID] || {};
		let services = [];
		for (const [, service] of Object.entries(state.map.order2services)) {
			if (service.order_id === orderID) {
				services.push(service);
			}
		}
		// let services = state.appointments.order2services[orderID] || [];
		// services = services.map(sid => state.appointments.services[sid] || {});
		return {
			order,
			services,
			serviceMap: state.map.services,// state.kfz.service.list,
			orderResources: state.map.order2service2resources,
			resources: state.map.resources,
			work: state.workers.work[orderID] || [],
			workHistory: state.workers.work_history[orderID] || []
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__fetchOne(props.order_id));
			dispatch(kfzAction__fetchServices());
			dispatch(workerAction__getWork(props.order_id));
		})),
		onCollectOrders: ids => dispatch(orderAction__collect(ids)),
		onCollectOrderServices: ids => dispatch(orderAction__collectServices(ids))
	})
)(OrderQuickService);
OrderQuickService = translate()(OrderQuickService);