/**
 * This component extends the EditField.js for validation purpose.
 * It uses given/standard props like 'required' to determine if an EditField (input) is valid.
 * The validation returns just the first error found.
 *
 * Furthermore, this component is a consumer of the ValidationProvider of ValidationContext.js.
 *
 * IMPORTANT:
 *  This component must be controlled.
 *
 * @example
 *  <ValidationField
 *      value={this.state.value}
 *      required // will check on required field, in comparison to html5 requirement check, this one also checks trimmed
 *      name="title"
 *      text="Title"
 *      onChange={(event, {name, value} => this.setState(...))} // semantic-ui Input property // updating value
 *  />
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import EditField from "./EditField";
import {withValidation} from "./ValidationContext";
import isNaN from "lodash/isNaN";
import * as mail from 'email-validator';
import isFunc from "lodash/isFunction";
import isNum from "lodash/isNumber";
import {isString} from "lodash";
import moment from "../../Logic/Moment";
import DatePicker from "./DatePicker";
import DropdownField from "./DropdownField";
import cn from "classnames";

/**
 * serial number of the ValidationField component
 * @type {number}
 */
let INSTANCE_NUM = 1;
/**
 * Serial number getter method
 * @return {number}
 */
const getInstance = () => {
	const instance = INSTANCE_NUM;
	INSTANCE_NUM *= 2;
	return instance;
};

/**
 * REQUIRED validation function
 * @param value
 * @return {boolean}
 */
const validateRequired = (value) => value.trim().length === 0 ? "errors.input.required" : null;

/**
 *
 * @param value input value
 * @return {*} error string if invalid, else null
 */
const validateNumber = value => /^\s*[-+]?\d*([,.]\d+)?\s*$/.test(value ) ? null : "errors.input.number";
/**
 *
 * @param value input
 * @return {*} null if valid, error string else
 */
const validateInt = value => /^\s*[-+]?\d+\s*$/.test(value) ? null : "errors.input.type.int";
/**
 *
 * @param value input
 * @return {*} null on valid, error string else
 */
const validateFloat = value => validateNumber(value) ? "errors.input.type.float" : null;

/**
 *
 * @param value input value
 * @param type input type
 * @return {*} error string or null
 */
const validateType = (value, type = '') => {
	type = isString(type) ? type.toLowerCase() : type;
	switch(type) {
		case "number":
			return isNaN(Number(value)) ? "errors.input.type.number" : null;
		case "float":
			return validateFloat(value);
		case "int":
			return validateInt(value);
		case "email":
			return validateEmail(value);
		default:
			return null;
	}
};
/**
 *
 * @param value input value
 * @param minlength min string length of value
 * @param trimmed trim before validate
 * @return {*}
 */
const validateMinLength = (value, minlength, trimmed=false) => {
	value = trimmed ? value.trim() : value;
	return value.length < minlength ? ["errors.input.min-length", {value: minlength}] : null;
};
/**
 *
 * @param value input value
 * @param maxlength max string length of value
 * @param trimmed trim before validate
 * @return {*}
 */
const validateMaxLength = (value, maxlength, trimmed=false) => {
	value = trimmed ? value.trim() : value;
	return value.length > maxlength ? ["errors.input.max-length", {value: maxlength}] : null;
};
/**
 *
 * @param value input value
 * @param min min value
 * @return {*}
 */
const validateMinNumber = (value, min) => {
	const valueNum = Number(value);
	const minNum = Number(min);
	if (isNaN(valueNum)) return "errors.input.min.value-nan";
	if (isNaN(minNum)) return "errors.input.min.min-nan";
	return valueNum < minNum ? ["errors.input.min.failed", {value: minNum}] : null;
};

/**
 *
 * @param value input value
 * @param max max value
 * @return {*}
 */
const validateMaxNumber = (value, max) => {
	const valueNum = Number(value);
	const maxNum = Number(max);
	if (isNaN(valueNum)) return "errors.input.max.value-nan";
	if (isNaN(maxNum)) return "errors.input.max.max-nan";
	return valueNum > maxNum ? ["errors.input.max.failed", {value: maxNum}] : null;
};
/**
 *
 * @param value input value
 * @param trimmed trim beforehand ?
 * @param optional prop optional...
 * @return {boolean} true if optional + value-length or not optional...
 * @private
 */
const _optional = (value, trimmed, optional) => {
	value = trimmed ? value.trim() : value;
	if ( optional ) {
		return value.length > 0;
	}
	return true;
};

/**
 *
 * @param value input value
 * @param pattern pattern to match input
 * @param patternMessage error message on validation error
 * @param trimmed trim beforehand
 * @return {*}
 */
const validatePattern = (value, pattern, patternMessage, trimmed = false) => {
	if ( !isString(value)) return null;
	if ( !isString(pattern)) return null;
	value = trimmed ? value.trim() : value;
	pattern = pattern.substr(0, 1) === "^" ? pattern : "^" + pattern;
	pattern = pattern.substr(-1, 1) === "$" ? pattern : pattern + "$";
	pattern = new RegExp(pattern);
	return ( !pattern.test(value) ) ? ( patternMessage ||"errors.input.pattern" ) : null;
};

const validateEmail = (value) => {
	if ( !isString(value)) return null;
	return !mail.validate(value.trim()) ? "errors.input.type.email" : null;
};

/**
 * Overall validtion callback
 * @param props of the ValidationField component
 * @return {*}
 */
const validate = (props) => {
	const {value: valueOrigin = '', required, type, minlength, maxlength, trimmed, min, max, pattern, patternMessage, optional: isOptional, onValidate} = props;
	const value = `${valueOrigin}`;
	const optional = _optional(value, trimmed, isOptional);
	
	// validate required attribute
	if ( required ) {
		const requiredResult = validateRequired(value);
		if ( requiredResult) return requiredResult;
	}
	
	// validate type attribute
	if ( optional && type ) {
		const typeResult = validateType(value, type);
		if ( typeResult ) return typeResult;
	}
	
	// validate minlength
	if ( optional && Number(minlength) >= 0) {
		const minLengthResult = validateMinLength(value, Number(minlength), trimmed);
		if ( minLengthResult ) return minLengthResult;
	}
	
	// validate maxlength
	if ( optional && Number(maxlength) > 0 ) {
		const maxLengthResult = validateMaxLength(value, Number(maxlength), trimmed);
		if (maxLengthResult) return maxLengthResult;
	}
	
	// validate min attribute
	if ( optional && min !== undefined && min !== null ) {
		const minResult = validateMinNumber(value, min);
		if ( minResult) return minResult;
	}
	
	// validate max attribute
	if ( optional && max !== undefined && max !== null ) {
		const maxResult = validateMaxNumber(value, max);
		if ( maxResult ) return maxResult;
	}
	
	// validate pattern attribute
	if ( optional && isString(pattern) ) {
		const patternResult = validatePattern(value, pattern, patternMessage, trimmed);
		if ( patternResult) return patternResult;
	}
	
	// use custom validation method
	if ( isFunc(onValidate) ) {
		return onValidate.call(props, value);
	}
	
	// return ok!
	return null;
};

/**
 * Enhancing EditField with validation mechanism
 *
 * @param Component EditField
 * @return enhancement of EditField (theoretically general)
 */
export const validateEditFieldClass = Component => class extends React.Component {
	state = {focused: false}; // used to determine if error should be displayed
	serial = 0; // serial instanciation
	silentValidError = null; // error holder (all errors)
	validError = null; // errorholder if focused is true
	
	ref = React.createRef(); // component reference...
	name = null;
	
	
	
	constructor(props) {
		super(props);
		this.name = props.name;
		// this.state.focused = Boolean(props.focused);
		// create scoped serial if possible
		this.serial = props.validationProvider ? (this.serial || props.validationProvider.subscribe(props.name)) : getInstance();
	}
	
	
	shouldComponentUpdate({value}, {focused}) {
		// update only if focus or value changed, else it results into an endless loop
		return this.props.value !== value || focused !== this.state.focused;
	}
	
	componentDidUpdate() {
		this.onValid(); // present the result
	}
	
	componentDidMount() {
		this.onValid(); // present the result
	}
	
	componentWillUnmount() {
		const {validationProvider, name} = this.props;
		if ( validationProvider ) {
			validationProvider.unsubscribe(this.serial, name);
		}
	}
	
	/**
	 * Handling input focus
	 * @param e
	 */
	onFocus = e => {
		!this.state.focused && this.setState({focused: true});
		this.props.onFocus && this.props.onFocus(e);
	};
	/**
	 * handling validation changes/states
	 */
	onValid = () => {
		const {onValid, validationProvider, name} = this.props;
		const {serial, silentValidError, ref} = this;
		
		if ( isFunc(onValid) ) {
			onValid(!Boolean(silentValidError), serial, {error: silentValidError, element: ref ? ref.current : null, name: name || serial});
		}
		// checking for context data of ValidationContext.ValidationProvider
		if ( validationProvider ) {
			if (silentValidError) {
				validationProvider.add(silentValidError, serial, name);
			} else {
				validationProvider.remove(serial, name);
			}
		}
	};
	
	silentMode = () => {
		// prefer field value before context value
		const {silentMode, validationProvider} = this.props;
		if ( silentMode === null ) {
			return validationProvider && validationProvider.silentMode !== null ? validationProvider.silentMode : true;
		}
		return silentMode;
	};
	
	render() {
		// exclude some props
		const {onFocus, onValid, validationProvider, trimmed, optional, patternMessage, ref, silentMode, errorText, onValidate, ...props} = this.props;
		const validError = validate(this.props); // validate..
		this.silentValidError = errorText || validError;
		this.validError = errorText || (this.state.focused || !this.silentMode() ? validError : null);
		return (
				<Component ref={this.ref} formNoValidate {...props} errorText={this.validError} onFocus={this.onFocus} isValid={!Boolean(this.silentValidError)}/>
		);
	}
};

// enhancing EditField + subscribing to ValidationContext.ValidationProvider
export const ValidationField = withValidation(validateEditFieldClass(EditField));

ValidationField.propTypes = {
	...EditField.propTypes,
	silentMode: PropTypes.bool, // set false to always show error (without focus)
	onValid: PropTypes.func, // validation callback (isValid, serial, {error: ?string, element: ?element, name: ?string}) => void
	trimmed: PropTypes.bool,
	required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	type: PropTypes.string,
	pattern: PropTypes.string,
	patternMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minlength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxlength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	optional: PropTypes.bool,
	onValidate: PropTypes.func
};

ValidationField.defaultProps = {
	...EditField.defaultProps,
	silentMode: null
};

/**
 *
 * @param value value to test (input)
 * @param dateFormat dateformat for parsing
 * @return {*} string value
 * @private
 */
const _moment = (value, dateFormat) => isNum(value) ? moment(value).format(dateFormat) : value;

const validateDate = props => {
	const {value: initValue = '', required, onValidate, dateFormat} = props;
	const value = _moment(`${initValue}`.trim(), dateFormat);
	if ( required ) {
		const requiredResult = validateRequired(value);
		if (requiredResult) return requiredResult;
	}
	if ( isFunc(onValidate)) {
		return onValidate.call(props, value, _moment);
	}
	return null;
};

/**
 * Enhancement for DatePicker.js
 * @param Component Component to enhance
 * @return {{new(*=): {silentMode, silentValidError, serial, ref, handleFocus, state, validError, onValid, componentDidUpdate(): void, render(): *, shouldComponentUpdate({value: *}, {focus: *}): *, componentDidMount(): void}, prototype: {silentMode, silentValidError, serial, ref, handleFocus, state, validError, onValid, componentDidUpdate(): void, render(): *, shouldComponentUpdate({value: *}, {focus: *}): *, componentDidMount(): void}}}
 */
const validateDateFieldClass = Component => class extends React.Component {
	state = {focus: false};
	serial = 1;
	silentValidError = null;
	validError = null;
	ref = React.createRef();
	
	constructor(props) {
		super(props);
		// create scoped serial if possible
		this.serial = props.validationProvider ? props.validationProvider.getNextSerial() : getInstance();
	}
	
	shouldComponentUpdate({value}, {focus}) {
		// update only if focus or value changed, else it results into an endless loop
		return this.props.value !== value || focus !== this.state.focus;
	}
	
	componentDidUpdate() {
		this.onValid(); // present the result
	}
	
	componentDidMount() {
		this.onValid(); // present the result
	}
	
	handleFocus = next => e => {
		!this.state.focus && this.setState({focus: true});
		isFunc(next) && next(e, e.target);
	};
	
	silentMode = () => {
		// prefer field value before context value
		const {silentMode, validationProvider} = this.props;
		if ( silentMode === null ) {
			return validationProvider && validationProvider.silentMode !== null ? validationProvider.silentMode : true;
		}
		return silentMode;
	};
	
	onValid = () => {
		const {onValid, validationProvider, name} = this.props;
		const {silentValidError, serial, ref} = this;
		
		if (isFunc(onValid) ) {
			onValid(!Boolean(silentValidError), serial, {error: silentValidError, element: ref ? ref.current : null, name: name || serial});
		}
		// checking for context data of ValidationContext.ValidationProvider
		if ( validationProvider ) {
			if (silentValidError) {
				validationProvider.add(silentValidError, serial, name);
			} else {
				validationProvider.remove(serial, name);
			}
		}
	};
	
	render() {
		const {errorText, silentMode, onFocus, validationProvider, ...props} = this.props;
		const validError = validateDate(this.props);
		this.silentValidError = errorText || validError;
		this.validError = errorText || ( this.state.focus || !this.silentMode() ? validError : null);
		return(
			<Component formNoValidate {...props} errorText={this.validError} onFocus={this.handleFocus(onFocus)}/>
		);
	}
};

export const ValidationDate = withValidation(validateDateFieldClass(DatePicker));

ValidationDate.propTypes = {
	...DatePicker.propTypes,
	required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	silentMode: PropTypes.bool,
	onValid: PropTypes.func,
	onValidate: PropTypes.func
};
ValidationDate.defaultProps = {
	...DatePicker.defaultProps,
	silentMode: null
};

const validateDropdown = (props) => {
	const {value: initValue = '', required, onValidate, dateFormat} = props;
	const value = _moment(`${initValue}`.trim(), dateFormat);
	if ( required ) {
		const requiredResult = validateRequired(value);
		if (requiredResult) return requiredResult;
	}
	if ( isFunc(onValidate)) {
		return onValidate.call(props, value, _moment);
	}
	return null;
};

const validateDropdownFieldClass = Component => class extends React.Component {
	state = {focus: false, open: false};
	serial = 1;
	silentValidError = null;
	validError = null;
	ref = React.createRef();
	
	constructor(props) {
		super(props);
		this.serial = props.validationProvider ? props.validationProvider.getNextSerial() : getInstance();
	}
	
	shouldComponentUpdate({value}, {focus, open}) {
		// update only if focus or value changed, else it results into an endless loop
		return this.props.value !== value || focus !== this.state.focus || open !== this.state.open;
	}
	
	componentDidUpdate() {
		this.onValid(); // present the result
	}
	
	componentDidMount() {
		this.onValid(); // present the result
	}
	
	handleFocus = next => e => {
		!this.state.focus && this.setState({focus: true});
		isFunc(next) && next(e, e.target);
	};
	
	handleOpen = (open, next) => (e, data) => {
		this.setState({open});
		if (isFunc(next)) next(e, data);
	};
	
	silentMode = () => {
		// prefer field value before context value
		const {silentMode, validationProvider} = this.props;
		if ( silentMode === null ) {
			return validationProvider && validationProvider.silentMode !== null ? validationProvider.silentMode : true;
		}
		return silentMode;
	};
	
	onValid = () => {
		const {onValid, validationProvider, name} = this.props;
		const {silentValidError, serial, ref} = this;
		
		if (isFunc(onValid) ) {
			onValid(!Boolean(silentValidError), serial, {error: silentValidError, element: ref ? ref.current : null, name: name || serial});
		}
		// checking for context data of ValidationContext.ValidationProvider
		if ( validationProvider ) {
			if (silentValidError) {
				validationProvider.add(silentValidError, serial, name);
			} else {
				validationProvider.remove(serial, name);
			}
		}
	};
	
	render() {
		const {errorText, silentMode, onFocus, validationProvider, onOpen, onClose, ...props} = this.props;
		const validError = validateDropdown(this.props);
		this.silentValidError = errorText || validError;
		this.validError = errorText ? errorText : ( Boolean(Boolean(this.state.focus && !this.state.open) || !this.silentMode()) ? validError : null);
		
		return(
			<Component
				ref={this.ref}
				formNoValidate
				className={cn({'fufu-open': this.state.open})}
				{...props}
				errorText={this.validError}
				onFocus={this.handleFocus(onFocus)}
				onOpen={this.handleOpen(true, onOpen)}
				onClose={this.handleOpen(false, onClose)}
			/>
		);
	}
};

export const ValidationDropdown = withValidation(validateDropdownFieldClass(DropdownField));

ValidationDropdown.propTypes = {
	...DropdownField.propTypes,
	required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	silentMode: PropTypes.bool,
	onValid: PropTypes.func,
	onValidate: PropTypes.func
};
ValidationDropdown.defaultProps = {
	...DropdownField.defaultProps,
	silentMode: null
};

export default ValidationField;

/**
 * ValidationField can be used without ValidationProvider in combination with the onValid props.
 *
 *
 * @param bool validation success
 * @param serial serial number
 * @param base curent result of this function or defaul = 0
 * @return {number}
 */
export const validateResult = (bool, serial, base = 0) => bool ? base & (base ^ serial) : base | serial;