import * as React from 'react';
import {AppointmentPaper} from "../../cointainer/intern/appointments";
import {Order} from "../../models";
import {Segment} from "semantic-ui-react";


export class AppointmentViewTest extends React.Component {
	
	render() {
		return(
			<Order.CollectProvider ids={[2912, 3296]} full>{orders =>
				<Segment padded basic>
					{/*<pre>{JSON.stringify(orders, null, 2)}</pre>*/}
					{/*<AppointmentPaper order={orders[0]}/>*/}
					{orders.map(order => <AppointmentPaper order={order}/>)}
				</Segment>
			}</Order.CollectProvider>
		);
	}
}