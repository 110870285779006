/* eslint-disable */
import * as React from 'react';
import {isFunction} from "lodash";
import PropTypes from "prop-types";

export const DefaultProvider = ({data, children, placeholder}) => data && isFunction(children) ? children(data) : placeholder;
DefaultProvider.propTypes = {
  id: PropTypes.any.isRequired,
  children: PropTypes.func,
  placeholder: PropTypes.node,
  dynamic: PropTypes.bool
};
DefaultProvider.defaultProps = {
  placeholder: null,
  dynamic: true
};