import * as React from "react";
import {ErrorBoundary} from "../../cointainer/ErrorBoundary";

class ErrorCMP extends React.Component {
	e = () => {
		throw Error('Error Boundary Test');
		// eslint-disable-next-line
		return <div>DUDU</div>;
	};
	render() {
		return <div>{this.e()}</div>;
	}
}

export class ErrorBoundaryTest extends React.Component {
	render() {
		return (
			<ErrorBoundary visible send={false}>
				<ErrorCMP/>
			</ErrorBoundary>
		);
	}
}