import * as React from 'react';
import PropTypes from "prop-types";
import {moment} from "../../../../Logic/Moment";
import {Button, Label, Segment} from "semantic-ui-react";
import {PositiveSegment} from "../../../partials/Segments";
import {FullscreenPortal} from "../../../../Tools/Dialog/Dialogs";
import {EasyFlex} from "../../../partials/ActionHeader";
import {COLORS} from "../../../../Logic/constants";
import OrderQuickView from "../../../../cointainer/intern/orders/OrderQuickView";
import {translate} from "react-i18next";

export class FinalResult extends React.Component {
	static propTypes = {
		result: PropTypes.object,
		children: PropTypes.node
	}
	state = {
		open: false
	};
	
	set = (open) => (e) => {
		if (e && e.stopPropagation) {
			e.stopPropagation();
		}
		this.setState({open});
	}
	
	render() {
		let {result, children} = this.props;
		const start = moment(result.order.deliver_point);
		const end = moment(result.order.fetch_point);
		return <Segment.Group>
			<PositiveSegment style={{paddingBottom: 50}}>
				<div>Der Auftrag wurde unter der Nummer <Label color={'brown'} className={'cursor pointer'} onClick={this.set(true)}><strong><code>{result.order.order_id}</code></strong></Label> gespeichert.</div>
				<div>Von <strong>{start.format('LLL [Uhr]')}</strong> bist <strong>{end.format('LLL [Uhr]')}</strong>.</div>
				{this.state.open &&  <FullscreenPortal scrolling defaultOpen onClose={this.set(false)} bottomBar={
					<EasyFlex valign={EasyFlex.valign.CENTER} align={EasyFlex.align.END} style={{height: 60, borderTop: '1px solid #ccc', backgroundColor: COLORS.BACKGROUND, position: 'fixed', bottom: 0, width: '100%', padding: '0 20px'}}>
						<Button onClick={this.set(false)}>Schließen</Button>
					</EasyFlex>
				}>
					<OrderQuickView order_id={result.order.order_id} onRequestClose={this.set(false)}/>
				</FullscreenPortal>}
			</PositiveSegment>
			{children}
		</Segment.Group>
	}
}

FinalResult = translate()(FinalResult);