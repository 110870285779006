import * as React from "react";
import PropTypes from "prop-types";
import {EasyFlex} from "../../partials/ActionHeader";
import {Button} from "semantic-ui-react";
import {ThreewayCheckbox} from "../../ThreewayCheckbox";
import {Divider} from "material-ui";
import {isBoolean, values} from "lodash";
import {deepMemoize as  memoize} from "../../../Logic/extensions";
import {translate} from "react-i18next";

const STATE_FILTERS = [
	"delivered",
	"progress",
	"finished",
	"affirmed",
	"post_processing",
	"post_processed",
	"fetched",
	"is_waiting",
	"is_delivery_service",
	"is_consultant",
	"has_wheel"
];

const translateCheckboxValue = value => {
	switch (value) {
		case ThreewayCheckbox.STATUS.NEGATIVE:
			return false;
		case ThreewayCheckbox.STATUS.POSITIVE:
			return true;
		default:
			return null;
	}
};

const translateBoolish = value => {
	if (isBoolean(value)) {
		return value ? ThreewayCheckbox.STATUS.POSITIVE : ThreewayCheckbox.STATUS.NEGATIVE;
	}
	return ThreewayCheckbox.STATUS.INDETERMINATE;
};

export class OrderStateFilter extends React.PureComponent {
	static propTypes = {
		states: PropTypes.shape({
			delivered: PropTypes.bool,
			progress: PropTypes.bool,
			finished: PropTypes.bool,
			affirmed: PropTypes.bool,
			post_processing: PropTypes.bool,
			post_processed: PropTypes.bool,
			fetched: PropTypes.bool,
			is_waiting: PropTypes.bool,
			is_delivery_service: PropTypes.bool,
			is_consultant: PropTypes.bool,
			has_wheel: PropTypes.bool
		}).isRequired,
		onUpdateAll: PropTypes.func,
		onReset: PropTypes.func,
		onUpdateHook: PropTypes.func,
		onChangeHook: PropTypes.func,
		onResetHook: PropTypes.func
	};
	
	static defaultProps = {
		states: {
			delivered: null,
			progress: null,
			finished: null,
			affirmed: null,
			post_processing: null,
			post_processed: null,
			fetched: null,
			is_waiting: null,
			is_delivery_service: null,
			is_consultant: null,
			has_wheel: null
		},
		onUpdateHook: () => {},
		onChangeHook: () => {},
		onResetHook: () => {}
	};
	
	static isSameState = (left, right) => {
		return STATE_FILTERS.reduce((state, value) => {
			return state && left[value] === right[value];
		}, true)
	};
	
	state = {
		tmp: {
			...OrderStateFilter.defaultProps.states
		}
	};
	
	componentDidMount() {
		this.setTempState(this.props.states);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const same = OrderStateFilter.isSameState(prevProps.states, this.props.states);
		if (!same) {
			this.setTempState(this.props.states);
		}
	}
	
	updateTempState = (name, value) => this.setState(state => ({
		...state,
		tmp: {
			...state.tmp,
			[name]: value
		}
	}), this.props.onChangeHook.bind(null, name, value));
	
	setTempState = (value, hook = false) => this.setState(state => ({
		...state,
		tmp: {
			...state.tmp,
			...value
		}
	}), hook ? this.props.onUpdateHook.bind(null, value) : null);
	
	reset = () => this.setState(state => ({
		...state,
		tmp: {
			...state.tmp,
			...this.props.states
		}
	}), this.props.onResetHook.bind(null, this.props.states));
	
	setDefault = () => this.setTempState(OrderStateFilter.defaultProps.states);
	
	isSame = memoize(
		(left, right) => OrderStateFilter.isSameState(left, right)
	);
	
	isSet = memoize(
		states => {
			for(let value of values(states)) {
				if (null !== value) {
					return true;
				}
			}
			return false;
		}
	);
	
	render() {
		const {
			onUpdateAll,
			t
		} = this.props;
		const isSame = this.isSame(this.state.tmp, this.props.states);
		const isSet = this.isSet(this.state.tmp);
		
		return (
			<EasyFlex direction={EasyFlex.direction.COLUMN}>
				{STATE_FILTERS.map(state => <div key={state} style={{padding: "8px"}}>
					<ThreewayCheckbox label={t(`filterStates.${state}`)} labelProps={{style: {justifyContent: "space-between"}}} value={translateBoolish(this.state.tmp[state])} onStateChange={value => this.updateTempState(state, translateCheckboxValue(value))}/>
				</div>)}
				<Divider style={{marginBottom: 10}}/>
				<EasyFlex align={EasyFlex.align.SPACE_EVENLY} valign={EasyFlex.valign.CENTER}>
					<Button disabled={isSame} positive onClick={() => {
						onUpdateAll(this.state.tmp);
						this.props.onUpdateHook(this.state.tmp);
					}}>Übernehmen</Button>
					<div style={{minWidth: 30}}/>
					<Button disabled={isSame} circular icon={"history"} onClick={this.reset}/>
					<Button disabled={!isSet} negative circular icon={"redo"} onClick={this.setDefault}/>
				</EasyFlex>
			</EasyFlex>
		);
	}
}

OrderStateFilter = translate()(OrderStateFilter);