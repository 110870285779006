import { connect } from 'react-redux';
import IndexView from "../components/IndexView";
import {withRouter} from "react-router-dom";


const mapStateToProps = (state, props) => {
	return {
		logged: !!state.user.data
	};
};
const IndexController = withRouter(connect(mapStateToProps)(IndexView));

export default IndexController;