import MDTimeZone from "../../../../components/intern/master-data/base/MDTimeZone";
import {loadOwnCarhouse, loadTimezones, updateTimezone} from "../../../../actions/carhouseActions";
import {connect} from "react-redux";
import User from "../../../../models/User";
import {SECTION} from "../../../../Logic/constants";
import {isOnline} from "../../../../actions/userActions";
import withInit from "../../../../Logic/withInit";

const m2state = (state) => {
	let currentTimezone;
	// const houseId = state.carhouse.own.house_id;
	currentTimezone = state.carhouse.own;
	currentTimezone = currentTimezone ? currentTimezone.timezone : null;
	const user = User._(state.user.data);
	return {
		currentTimezone,
		timezones: state.carhouse.timezones.list,
		...user.getAccessTo(SECTION.MASTER_DATA, [1])
	};
};
const m2dispatch = (dispatch) => {
	// dispatch(isOnline(() => {
	// 	dispatch(loadTimezones());
	// 	dispatch(loadOwnCarhouse());
	// }));
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(loadTimezones());
			dispatch(loadOwnCarhouse());
		})),
		onSaveTimezone: (timezone, onSuccess) => dispatch(updateTimezone(timezone, onSuccess))
	};
};

export default connect(m2state, m2dispatch)(withInit(MDTimeZone));