import {connect} from "react-redux";
import {push} from "connected-react-router";
import MasterDataPaymentView from "../../../components/intern/master-data/MasterDataPaymentView";

const map2state = (state) => ({
});
const map2dispatch = (dispatch) => {
	return {
		onBack: () => dispatch(push('/master-data'))
	};
};

export default connect(map2state, map2dispatch)(MasterDataPaymentView);