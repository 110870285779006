import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isOnline} from "../../../actions/userActions";
import withInit from "../../../Logic/withInit";
import {Button, Modal, Segment} from "semantic-ui-react";
import {get, isEmpty} from "lodash";
import {List, ListItem} from "material-ui";
import moment from "../../../Logic/Moment";
import {Flex} from "../../../components/partials/ActionHeader";
import LoanCarScheduleMask from "./LoanCarScheduleMask";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import Client from "../../../models/Client";
import {deepMemoize as memoize} from "../../../Logic/extensions";

class LoanCarScheduleDetail extends React.Component {
	static propTypes = {
		schedule_id: PropTypes.number.isRequired,
		schedule: PropTypes.object,
		vehicle: PropTypes.object,
		onDelete: PropTypes.func
	};
	static defaultProps = {
		schedule: {},
		vehicle: {},
		onDelete: () => {}
	};
	state = {
		edit: false
	};
	setEdit = edit => () => this.setState({edit});
	
	handleDelete = () => {
		this.setState({edit: false});
		this.props.onDelete();
	};
	
	getClient = memoize(
		(schedule, clients) => {
			if (!schedule) return null;
			if (!schedule.client_id) return null;
			let client = get(clients, schedule.client_id, null);
			if (client) {
				client =  Client._(client);
				return client.getName();
			}
			return null;
		}
	);
	
	render() {
		const {schedule, vehicle, rights, allRights, clients} = this.props;
		if ( isEmpty(schedule) || isEmpty(vehicle)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>
		}
		const clientName = this.getClient(schedule, clients);
		const mayChange = rights.mayChange || allRights[SECTION.MASTER_DATA].mayChange;
		const {edit} = this.state;
		return (
			<Segment basic>
				<List>
					<ListItem
						disabled
						primaryText={vehicle.name}
						secondaryText={"Fahrzeug"}
					/>
					<ListItem
						disabled
						primaryText={`${vehicle.hsn} / ${vehicle.tsn}`}
						secondaryText={"HSN / TSN"}
					/>
					<ListItem
						disabled
						primaryText={vehicle.registration_mark}
						secondaryText={"Kennzeichen"}
					/>
					{clientName && <ListItem
						disabled
						primaryText={clientName}
						secondaryText={"Kunde"}
					/>}
					<ListItem
						disabled
						primaryText={moment(schedule.start_point).format("LLL")}
						secondaryText={"Abholzeit"}
					/>
					<ListItem
						disabled
						primaryText={moment(schedule.end_point).format("LLL")}
						secondaryText={"Bringzeit"}
					/>
					<ListItem
						disabled={!schedule.order_id}
						primaryText={schedule.order_id || "Kein Auftrag verknüpft"}
						secondaryText={"Auftrag"}
					/>
					<ListItem
						disabled={true || !schedule.client_id}
						primaryText={schedule.client_id || "Kein Kunde verknüpft"}
						secondaryText={"Kunde"}
					/>
				</List>
				<Flex align={"center"}>
					<Button positive onClick={this.setEdit(true)}><span style={{paddingLeft: 5, paddingRight: 5}}>{mayChange ? "Details ändern" : "Details ansehen"}</span></Button>
				</Flex>
				{edit && <Modal defaultOpen closeIcon centered={false} onClose={this.setEdit(false)}>
					<Modal.Content scrolling>
						<LoanCarScheduleMask loanCarSchedule={schedule} onAfterDelete={this.handleDelete}/>
					</Modal.Content>
					<Modal.Actions style={{textAlign: 'left'}}>
						<Button onClick={this.setEdit(false)}>Schließen</Button>
					</Modal.Actions>
				</Modal>}
				{/*<BasicDialog open={edit} onClose={this.setEdit(false)} closeButton autoScrollBodyContent noPadding>*/}
				{/*	<LoanCarScheduleMask loanCarSchedule={schedule} onAfterDelete={this.handleDelete}/>*/}
				{/*</BasicDialog>*/}
			</Segment>
		);
	}
}

LoanCarScheduleDetail = withInit(LoanCarScheduleDetail);
LoanCarScheduleDetail = withRights(SECTION.ORDERS, LoanCarScheduleDetail);
LoanCarScheduleDetail = connect(
	(state, props) => {
		const id = props.schedule_id;
		return {
			clients: state.clients.list,
			vehicle: props.vehicle || get(state, ["loan_cars", "list", id], {}),
			schedule: props.schedule || state.loan_cars.schedule.find(s => s.loan_car_schedule_id === id) || {}
		};
	},
	(dispatch) => {
		return {
			init: () => dispatch(isOnline(() => {
			
			}))
		};
	}
)(LoanCarScheduleDetail);

export default LoanCarScheduleDetail;