import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Popup, Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import {connect} from "react-redux";
import {ClientDetail} from "./widgets/ClientDetail";
import {clientAction__change, clientAction__collectOne} from "../../../actions/clientActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {SUB_CLIENT_SEARCH} from "../../../actions";
import {publish} from "../../../Logic/PubSub";
import {get} from 'lodash';
import {withModule} from "../../../Tools/RightsProvider";

const ConnectedClientDetail = connect(
	state => ({
		countries: state.session.countries
	}),
	(dispatch, props) => ({
		onFetch: client_id => dispatch(clientAction__collectOne(client_id)),
		onSave: props.onSave || (client => dispatch(clientAction__change(client, () => {
			dispatch(addSnackbar('Kunde wurde gespeichert'));
		})))
	})
)(ClientDetail);

class ClientEditView extends React.Component {
	static propTypes = {
		onBack: PropTypes.func,
		toVehicles: PropTypes.func,
		toWheels: PropTypes.func,
		headerTitle: PropTypes.string,
		client: PropTypes.shape({
			client_id: PropTypes.number.isRequired,
			house_id: PropTypes.number.isRequired,
			model_id: PropTypes.number
		}),
		onSave: PropTypes.func.isRequired,
		isSaving: PropTypes.bool,
		mayCreate: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayRead: PropTypes.bool,
		onDelete: PropTypes.func,
		isDeleting: PropTypes.bool,
		searchZipcode: PropTypes.func,
		showHeader: PropTypes.bool,
		onAfterSave: PropTypes.func,
		is_create: PropTypes.bool
	};
	static defaultProps = {
		onSave: () => alert('onSave() is not implemented yet'),
		isSaving: false,
		mayCreate: false,
		mayChange: false,
		mayRead: false,
		isDeleting: false,
		showHeader: true,
		is_create: false,
		onAfterSave: () => {}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (get(this.props, 'match.params.client_id', null) !== get(prevProps, 'match.params.client_id', null)) {
			this.props.init && this.props.init();
		}
	}
	
	render() {
		const {t, onBack, client_id, headerTitle, toClients, modules,  onSave,  mayCreate, mayChange,   is_create, toVehicles,   showHeader, toWheels} = this.props;
		return(
			<Segment basic>
				{showHeader &&
					<ActionHeader alignment={'space-between'} style={{marginBottom: 25}}>
						<ActionHeaderGroup>
							{onBack && <Button icon={'angle left'} basic onClick={onBack}/>}
							{headerTitle && <ActionHeading>{headerTitle}</ActionHeading>}
						</ActionHeaderGroup>
						<ActionHeaderGroup>
							<Button icon={'search'} basic onClick={() => publish(SUB_CLIENT_SEARCH)}/>
							{toClients && <Popup inverted basic position={'bottom right'} content={'Zu den Kunden'} trigger={<Button icon={'group'} color={'brown'} style={{marginLeft: 5}} onClick={toClients}/>}/> }
							{toVehicles && <Popup inverted basic position={'bottom right'} content={t('client.show-vehicles')} trigger={<Button icon={'car'} color={'brown'} style={{marginLeft: 5}} onClick={toVehicles}/>}/> }
							{modules.wheel && toWheels && <Popup inverted basic position={'bottom right'} content={'Reifen'} trigger={<Button icon={'dot circle'} color={'brown'} style={{marginLeft: 5}} onClick={toWheels}/>}/>}
						</ActionHeaderGroup>
					</ActionHeader>
				}
				<ConnectedClientDetail client_id={Number(client_id)} editable={is_create ? mayCreate : mayChange} onSave={onSave}/>
				{/*<NavigationClientSearchModal open={this.state.showSearch} onModalClose={() => this.setState({showSearch: false})}/>*/}
				{/*<ClientMaskView*/}
				{/*	// searchZipcode={searchZipcode}*/}
				{/*	mayDelete={mayDelete}*/}
				{/*	mayCreate={mayCreate}*/}
				{/*	mayChange={mayChange}*/}
				{/*	mayRead={mayRead}*/}
				{/*	client={client}*/}
				{/*	onSave={onSave}*/}
				{/*	isSaving={isSaving}*/}
				{/*	onDelete={onDelete}*/}
				{/*	isDeleting={isDeleting}*/}
				{/*/>*/}
			</Segment>
		);
	}
}

ClientEditView = withModule(ClientEditView)
export default translate()(ClientEditView);