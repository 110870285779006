import {KFZ_ACTION, RESET_ACTION} from "../actions/types";
import isEmpty from 'lodash/isEmpty';
import {CACHE_KFZ, CACHE_KFZ_MODELS, CACHE_KFZ_SERVICES} from "../actions";
import {updateCache, getUpdateCacheKey} from "ith-redux-helpers";
import omit from 'lodash/omit';
import merge from 'lodash/merge';

const defaultState = {
	cars: undefined,
	models: {},
	inverseModels: {},
	service: {
		list: undefined,
		cars: {
			origin: {},
			custom: {}
		},
		models: {}
	},
	cache: {}
};

/**
 State definition:
    cars: [object]
        [kfz_id]:   [object] of kfz
 
    models: [object]
        [kfz_id]:   [object]
            [model_id]:     [object] of model
 
    service: [object]
        cars: [object]
	        custom: [object]
	            [service_id]: [object]
	                [kfz_id]: [object] of price-def
	        origin: [object]
	            [service_id]: [object]
	                [kfz_id]: [object] of price-def
        models: [object]
            [service_id]: [object]
                [kfz_id]: [obejct]
                    custom: [object]
                        [model_id]: [object] of price-def
                    origin: [object]
                        [model_id]: [object] of price-def
        list: [object]
            [service_id]: [object] of service
            
 
 
 */

export default (state = defaultState, {type, car, cars, models, kfz_id, service, services, service_id, origin, custom, kfz_models, inverse_models}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case KFZ_ACTION.UPDATE_SERVICE:
			return {
				...state,
				service: {
					...state.service,
					list: {
						...state.service.list,
						[service.service_id]: service
					}
				}
			};
		case KFZ_ACTION.REMOVE_SERVICE:
			return {
				...state,
				service: {
					...state.service,
					list: omit(state.service.list, [service_id])
				}
			};
		case KFZ_ACTION.INVERSE_MODELS:
			return {
				...state,
				inverseModels: {
					...state.inverseModels,
					...inverse_models
				}
			};
		case KFZ_ACTION.SET_ONE:
			kfz_id = car.kfz_id;
			cars = state.cars || {};
			return {
				...state,
				cars: {
					...cars,
					[kfz_id]: car
				}
			};
		case KFZ_ACTION.ADD:
			return {
				...state,
				cars: {
					...state.cars,
				}
			};
		
		case KFZ_ACTION.SET:
			if (isEmpty(cars)) {
				return {
					...state,
					cars: undefined,
					cache: omit(state.cache, [getUpdateCacheKey(CACHE_KFZ)])
				};
			}
			return {
				...state,
				cars,
				cache: {
					...state.cache,
					...updateCache(CACHE_KFZ)
				}
			};
		case KFZ_ACTION.ADD_MODELS:
			return {
				...state,
				models: merge(state.models, kfz_models)
			};
		case KFZ_ACTION.SET_MODELS:
			return {
				...state,
				models: {
					...state.models,
					[kfz_id]: {
						...(state.models[kfz_id] || {}),
						...models
					}
				},
				cache: {
					...state.cache,
					...updateCache(CACHE_KFZ_MODELS + kfz_id)
				}
			};
		case KFZ_ACTION.SET_SERVICES:
			return {
				...state,
				service: {
					...state.service,
					list: services
				},
				cache: {
					...state.cache,
					...updateCache(CACHE_KFZ_SERVICES)
				}
			};
		case KFZ_ACTION.SET_SERVICE_2_KFZ:
			const _origin = state.service.cars.origin || {};
			const _custom = state.service.cars.custom || {};
			return {
				...state,
				service: {
					...state.service,
					cars: {
						...state.service.cars,
						origin: {
							..._origin,
							[service_id]: {
								...state.service.cars.origin[service_id],
								...origin
							}
						},
						custom: {
							..._custom,
							[service_id]: {
								...state.service.cars.custom[service_id],
								...custom
							}
						}
					}
				}
			};
		case KFZ_ACTION.SET_CUSTOM_SERVICE:
			return {
				...state,
				service: {
					...state.service,
					cars: {
						...state.service.cars,
						custom: {
							...state.service.cars.custom,
							[service_id]: {
								...state.service.cars.custom[service_id],
								[kfz_id]: {
									...(state.service.cars.custom[service_id][kfz_id] || {}),
									...custom
								}
							}
						}
					}
				}
			};
		case KFZ_ACTION.REMOVE_CUSTOM_SERVICE:
			return {
				...state,
				service: {
					...state.service,
					cars: {
						...state.service.cars,
						custom: {
							...state.service.cars.custom,
							[service_id]: omit(state.service.cars.custom[service_id], [kfz_id])
						}
					}
				}
			};
		case KFZ_ACTION.SET_SERVICE_KFZ_MODEL:
			// return state;
			const item = {
				[service_id]: {
					[kfz_id]: {
						custom,
						origin
					}
				}
			};
			return {
				...state,
				service: {
					...state.service,
					models: {
						...item
					}
				}
			};
		case KFZ_ACTION.UPDATE_SERVICE_KFZ_MODELS:
			try {
				return {
					...state,
					service: {
						...state.service,
						models: {
							...state.service.models,
							[service_id]: {
								...state.service.models[service_id],
								[kfz_id]: {
									...state.service.models[service_id][kfz_id],
									custom: {
										...state.service.models[service_id][kfz_id].custom,
										...custom
									}
								}
							}
						}
					}
				};
			} catch(e) {
				console.error('UPDATE_SERVICE_KFZ_MODELS failed: \n', e);
				return state;
			}
		case KFZ_ACTION.REMOVE_SERVICE_KFZ_MODELS:
			try {
				return {
					...state,
					service: {
						...state.service,
						models: {
							...state.service.models,
							[service_id]: {
								...state.service.models[service_id],
								[kfz_id]: {
									...state.service.models[service_id][kfz_id],
									custom: omit(state.service.models[service_id][kfz_id].custom, models)
								}
							}
						}
					}
				};
			} catch(e) {
				console.error('REMOVE_SERVICE_KFZ_MODELS failed:\n', e);
				return state;
			}
		default:
			return state;
	}
};