import * as React from 'react';
import {LoanCarList} from "../intern/vehicles/widgets/LoanCarList";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {Button} from "semantic-ui-react";


const ConnectedLoanCarList = connect(
	null,
	dispatch => ({
		onShowVehicle: vehicle => dispatch(push(`/clients/${vehicle.client_id}/vehicles/${vehicle.vehicle_id}`))
	})
)(LoanCarList);

export class LoanCarTest extends React.Component{
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<Button style={{marginLeft: 20}} basic icon={'angle left'} onClick={() => window.history.back()}/>
						<ActionHeading>Leihfahrzeuge</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
					
					</ActionHeaderGroup>
				</ActionHeader>
				<div style={{padding: 20}}>
					<ConnectedLoanCarList/>
				</div>
			</div>
		);
	}
}