import {INIT_ACTION, LANGAUGE_ACTION, LOGIN_ACTION, resetAction, USER_ACTION, USER_WORK_ACTION} from './types';
// import {DELETE, GET, POST, PUT} from 'ith-fetch';
import {DELETE, GET, POST, PUT} from '../Logic/fetch';
import {async, updateFetch} from "ith-redux-helpers";
import {addError} from "./errorActions";
import {addLoader, removeLoader, setLoader, unsetLoader} from "./loaderActions";
import {push} from "connected-react-router";
import {resetWorkers} from "./workerActions";
import {resetAreasAndGroups} from "./rightActions";
import {resetWorkerResources} from "./resourceActions";
import {resetFreedays} from "./freedaysActions";
import {PROC_USER_FETCH_WORK, PROC_USER_LOGIN, PROC_USER_PASSWORD, SUB_PAUSED_NOTIFICATION, SUB_VERSION_UPDATE} from "./index";
import {houseState__customerOne, houseState__setCounries, houseState__setOne, houseState__setOwn, houseState_setTimezones} from "./carhouseActions";
import isFunc from 'lodash/isFunction';
import {moduleState__set} from "./moduleActions";
import {kfzState__setCars} from "./kfzActions";
import {sessionStorageAction__clearAppointmentNotifications} from "./sessionStorageActions";
import {requestHideLoginView} from "../components/partials/LoginView";
import {orderAction__releasableList, orderState__processable} from "./orderActions";
import localForage from "localforage";
import {appointmentState__pausedServices} from "./appointmentActions";
import {publish} from "../Logic/PubSub";
import {VERSION} from "../Logic/constants";


localForage.config();

// login
const _loginSuccess = user => ({type: USER_ACTION.LOGIN, user});
// logout
const _logoutDone = () => ({type: USER_ACTION.LOGOUT});
// register
const _registerSuccess = user => ({type: USER_ACTION.REGISTER, user});
// eslint-disable-next-line
const _prefetchLogged = user => ({type: INIT_ACTION.LOGGED, user});
const _prefetchIndex = () => ({type: INIT_ACTION.INDEX});

export const userState__setEmailLoginState = (email_login_failed) => ({type: USER_ACTION.SET_EMAIL_LOGIN, email_login_failed});
export const userState__setEmail = (email) => ({type: USER_ACTION.UPDATE_MAIL, email});
export const userState__setWork = (work) => ({type: USER_WORK_ACTION.SET, work});
export const userState__setWorkHistory = (work) => ({type: USER_WORK_ACTION.SET_HISTORY, work});
export const userState__setResources = (resources) => ({type: USER_ACTION.SET_RESOURCES, resources});
export const userState__setSchedule = (schedule) => ({type: USER_ACTION.SCHEDULE, schedule});

export const userHandler__version = version => {
	if (VERSION < 0) {
		console.error('Version has not been loaded', VERSION, version);
		return;
	}
	version > VERSION && publish(SUB_VERSION_UPDATE, version, VERSION);
};

export const userAction__version = () =>
	fetch("/version.json", {
		mode: 'cors',
		headers: {
			'Accept': 'text/json'
		},
		credentials: 'include',
		cache: 'no-cache'
	}).then(res => res.json()).then(json => userHandler__version(json.version));

window._ver = userAction__version;

const _setStorage = (user) => {
	async().then(() => {
		window.localStorage.setItem('store-user', JSON.stringify(updateFetch(user)));
	});
};

const _clearStorage = () => {
	async().then(() => {
		window.localStorage.removeItem('store-user');
	});
};

export const clearUserStorage = _clearStorage;

export const updateUser = (data, updateLocalStorage = true) => {
	updateLocalStorage && _setStorage(data);
	return {type: USER_ACTION.UPDATE, data};
};

let ONLINE_STACK = [];

const handleLoginState = (dispatch, result) => {
	"worker" in result && dispatch(_loginSuccess(result.worker));
	"worker" in result && _setStorage(result.worker);
	
	"house" in result && dispatch(houseState__setOne(result.house));
	"house" in result && dispatch(houseState__setOwn(result.house));
	"kfz" in result && dispatch(kfzState__setCars(result.kfz));
	"modules" in result && dispatch(moduleState__set(result.modules));
	"countries" in result && dispatch(houseState__setCounries(result.countries));
	"timezones" in result && dispatch(houseState_setTimezones(result.timezones));
	"resources" in result && dispatch(userState__setResources(result.resources));
	"work" in result && dispatch(userState__setWork(result.work));
	"paused" in result && dispatch(appointmentState__pausedServices(result.paused));
	"customerone" in result && dispatch(houseState__customerOne(result.customerone));
	"schedule" in result && dispatch(userState__setSchedule(result.schedule));
	"post_process" in result && dispatch(orderState__processable(result.post_process));
	requestHideLoginView();
};

export const prefetch = (onSuccess = null) =>
	async (dispatch, getState) => {
		try {
			if (getState().user.data) {
				isFunc(onSuccess) && onSuccess();
				return;
			}
			
			ONLINE_STACK.push(onSuccess);
			if (ONLINE_STACK.length > 1) {
				return;
			}
			
			dispatch(addLoader());
			// dispatch(setLoader(PROC_USER));
			const result = await GET('/workers/access');
			handleLoginState(dispatch, result);
			dispatch(orderAction__releasableList());
			for (const success of ONLINE_STACK) {
				success && success(result);
			}
			
		} catch (e) {
			dispatch(_prefetchIndex());
		} finally {
			dispatch(removeLoader());
			ONLINE_STACK = [];
		}
		
	};
export const isOnline = prefetch;


export const login = (house_id, email, password, onSuccess = null, {
	onDone = () => {
	}, onFail = () => {
	}
}) => {
	return async dispatch => {
		try {
			// dispatch(addLoader());
			dispatch(setLoader(PROC_USER_LOGIN));
			const result = await POST('/workers/access', {house_id, email, password});
			await localForage.clear();
			handleLoginState(dispatch, result);
			dispatch(orderAction__releasableList());
			dispatch(push('/'));
			onSuccess && onSuccess(result);
			
			"paused" in result && result.paused.length && publish(SUB_PAUSED_NOTIFICATION, result.paused);
			// async().then(() => {
			// 	window.localStorage.setItem('store-user', JSON.stringify(updateFetch(user)));
			// });
		} catch (e) {
			dispatch(addError(e));
			onFail(e);
		} finally {
			// dispatch(removeLoader());
			dispatch(unsetLoader(PROC_USER_LOGIN));
			onDone();
		}
	}
};

export const updatePasswordOfUser = (old_password, password, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_USER_PASSWORD));
			const result = await POST('/workers/password', {old_password, password});
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_USER_PASSWORD));
		}
	};

export const requestEmailLogin = (email, house, onSuccess) =>
	async dispatch => {
		const house_id = house.house_id || house;
		try {
			dispatch(setLoader(PROC_USER_LOGIN));
			const result = await POST('/workers/login-email', {email, house_id});
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_USER_LOGIN));
		}
	};

export const validateEmailLogin = (hash, onSuccess, onFail) =>
	async dispatch => {
		try {
			dispatch(userState__setEmailLoginState(false));
			dispatch(setLoader(PROC_USER_LOGIN));
			const result = await GET('/workers/login-email/' + hash);
			await localForage.clear();
			handleLoginState(dispatch, result);
			dispatch(orderAction__releasableList());
			dispatch(push('/'));
			isFunc(onSuccess) && onSuccess(result);
			"paused" in result && result.paused.length && publish(SUB_PAUSED_NOTIFICATION, result.paused);
		} catch (e) {
			isFunc(onFail) && onFail(e);
			dispatch(userState__setEmailLoginState(true));
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_USER_LOGIN));
		}
	};

export const requestNewPassword = (email, house, onSuccess) =>
	async dispatch => {
		const house_id = house.house_id || house;
		try {
			dispatch(setLoader(PROC_USER_LOGIN));
			const result = await POST('/workers/reset-password', {email, house_id});
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_USER_LOGIN));
		}
	};

export const clearLogin = () => ({type: LOGIN_ACTION.CLEAR});

export const runLogout = (dispatch, loginLink) => {
	_clearStorage();
	localForage.clear();
	dispatch(_logoutDone());
	dispatch(sessionStorageAction__clearAppointmentNotifications());
	// dispatch(push(house_id ? '/' + house_id : '/'));
	dispatch(resetWorkers());
	dispatch(resetAreasAndGroups());
	dispatch(removeLoader());
	dispatch(resetWorkerResources());
	dispatch(resetFreedays());
	dispatch(push(loginLink ? '/' + loginLink : '/'));
};

export const logout = (house_id = null, onSuccess = null) =>
	async (dispatch, getState) => {
		house_id = house_id || getState().carhouse.own.link;
		try {
			dispatch(addLoader());
			await DELETE('/workers/access');
		} catch (e) {
			console.error(e);
		} finally {
			runLogout(dispatch, house_id);
			dispatch(resetAction);
			onSuccess && onSuccess();
		}
	};

export const setLanguage = abbr => ({type: LANGAUGE_ACTION.SET, language: abbr});

export const register = (email, password, first_name, name, onSuccess = null) =>
	async dispatch => {
		try {
			dispatch(addLoader());
			const user = await PUT('/workers', {email, password, first_name, name});
			dispatch(_registerSuccess(user));
			dispatch(push('/'));
			onSuccess && onSuccess(user);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(removeLoader());
		}
	};

export const userAction__getWork = (onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_USER_FETCH_WORK));
			const data = await GET('/order/work');
			dispatch(userState__setWork(data.self));
			isFunc(onSuccess) && onSuccess(data);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_USER_FETCH_WORK));
		}
	};