import React from 'react';
import PropTypes from 'prop-types';
import constants from "../../Logic/constants";

let RegisterView = ({id, onRegister, hideSubmit, isLoading, animate, loader}) => {
	let emailInput, firstNameInput, nameInput, passwordInput;
	return (
		<form id={id} onSubmit={e => {
			e.preventDefault();
			onRegister(emailInput.value, passwordInput.value, firstNameInput.value, nameInput.value);
		}}>
			<div className="register__row">
				<input type="email" placeholder="E-Mail" required ref={input => emailInput = input}/>
			</div>
			<div className="register__row">
				<input type="text" placeholder="Vorname" required ref={input => firstNameInput = input}/>
			</div>
			<div className="register__row">
				<input type="text" placeholder="Name" required ref={input => nameInput = input}/>
			</div>
			<div className="register__row">
				<input type="password" placeholder="Passwort" required ref={input => passwordInput = input}/>
			</div>
			{ !hideSubmit &&
				<div className="register__row">
					<button type="submit" className="on-light">Registrieren</button>
				</div>
			}
			{ isLoading &&
				<div className={"loader" + (animate && 'animate')}>
					<img src={loader} alt="Registering"/>
				</div>
			}
		</form>
	);
};

RegisterView.propTypes = {
	id: PropTypes.string,
	onRegister: PropTypes.func.isRequired,
	hideSubmit: PropTypes.bool,
	isLoading: PropTypes.bool,
	animate: PropTypes.bool,
	loader: PropTypes.node
};
RegisterView.defaultProps = {
	id: null,
	hideSubmit: false,
	isLoading: false,
	animate: false,
	loader: constants.LOADER
};

export default RegisterView;