import {SCHEDULE_ACTION, WORK_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {PROC_SCHEDULE, PROC_SCHEDULE_INSERT} from "./index";
import {addError} from "./errorActions";
// import {DELETE, GET, PUT} from "ith-fetch";
import {DELETE, GET, POST, PUT} from "../Logic/fetch";
import {isFunction} from "lodash";
import moment from "moment";
import {toISO} from "../Logic/Moment";
import {workerState__removeConsultantSchedule, workerState__setConsultantSchedule} from "./workerActions";
import {useState} from "react";
import {useDispatch} from "react-redux";

// State handlers
export const scheduleState__set = (workers_id, schedule) => ({type: SCHEDULE_ACTION.SET, workers_id, schedule});
export const scheduleState__workSchedule = (workers_id, day, schedule, duration) => ({type: SCHEDULE_ACTION.SET_WORK_SCHEDULE, workers_id, day, schedule, duration});
export const scheduleState__setWorkload = workload => ({type: WORK_ACTION.SET_WORKLOAD, workload});

// callees
export const scheduleCall__ofWorker = (worker, day) => GET(`/schedule/worker/${worker.workers_id || worker}/day/${day}`);
export const scheduleCall__workloadOfDay = (day) => GET(`/schedule/workload/${toISO(day)}`);
export const scheduleCall__workloadBetween = (start, end) => GET(`/schedule/workload/${toISO(start)}/${toISO(end)}`);
export const scheduleCall__getConsultant = () => GET('/schedule/consultant');
export const scheduleCall__putConstultant = (params) => PUT('/schedule/consultant', params);
export const scheduleCall__deleteConsultant = id => DELETE('/schedule/consultant/' + id);
export const scheduleCall__getConsultantsAt = (date, minutes = null) => GET(`/schedule/consultants/${toISO(date)}` + (minutes ? `/${minutes}` : ''));
export const scheduleCall__getConsultantsOrder = (order_id, minutes = null) => GET(`/schedule/consultants-order/${order_id}` + (minutes ? `/${minutes}` : ''));
export const scheduleCall__isDeliveryAt = (date, minutes = null) => GET(`/schedule/delivery/${toISO(date)}` + (minutes ? `/${minutes}` : ''));

// Action handlers
export const scheduleAction__fetch = (worker, onSuccess) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		try {
			dispatch(setLoader(PROC_SCHEDULE, workers_id));
			const result = await GET(`/work-time/${workers_id}`);
			dispatch(scheduleState__set(result.workers_id, result.schedule));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SCHEDULE, workers_id));
		}
	};

export const scheduleAction__getConsultant = (force = false) =>
	async (dispatch, getState) => {
		if (!force && getState().workers.consultant_schedules.length) {
			return getState().workers.consultant_schedules;
		}
		try {
			const consultants = await scheduleCall__getConsultant();
			// dispatch(mapState__workers_add(__consultant));
			dispatch(workerState__setConsultantSchedule(consultants));
			return consultants;
		} catch (e) {
			dispatch(addError(e));
			return getState().workers.consultant_schedules;
		}
	};
// noinspection JSUnusedGlobalSymbols
export const scheduleAction__getConsultantsAt = (date, minutes=null) =>
	async dispatch => {
		try {
			return await scheduleCall__getConsultantsAt(date, minutes);
		} catch (e) {
			dispatch(addError(e));
			return [];
		}
	};
export const scheduleAction__putConsultant = (params) =>
	async (dispatch, getState) => {
		try {
			const consultants = await scheduleCall__putConstultant(params);
			dispatch(workerState__setConsultantSchedule(consultants));
			return consultants;
		} catch (e) {
			dispatch(addError(e));
			return getState().workers.consultant_schedules;
		}
	};
// noinspection JSUnusedGlobalSymbols
export const scheduleAction__deleteConsultant = (id) =>
	async dispatch => {
		try {
			const schedule = await scheduleCall__deleteConsultant(id);
			dispatch(workerState__removeConsultantSchedule(schedule));
			// if (__consultant) {
			// 	__consultant = __consultant.filter(c => c.consultant_schedule_id !== schedule.consultant_schedule_id);
			// }
			return schedule;
		} catch (e) {
			dispatch(addError(e));
			return null;
		}
	};

export const scheduleAction__deleteByIds = (worker, idsArray, onSuccess) =>
	async dispatch => {
		const workers_id = worker.workers_id || worker;
		try {
			dispatch(setLoader(PROC_SCHEDULE, workers_id));
			const result = await DELETE(`/work-time/${workers_id}/ids`, {schedule_ids: idsArray});
			dispatch(scheduleState__set(result.workers_id, result.schedule));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SCHEDULE, workers_id));
		}
	};
/**
 *
 * @param data
 *      workers_id:     int
 *      resource_ids:   array[ int ]
 *      weekdays:       array[ int ]
 *      start_time:     string 'HH:MM'
 *      end_time:       string 'HH:MM'
 *      force:          bool
 * @param onSuccess
 * @return {Function}
 */
export const scheduleAction__insert = (data, onSuccess) =>
	async dispatch => {
		try {
			dispatch(setLoader(PROC_SCHEDULE_INSERT));
			const result = await PUT('/work-time', data);
			dispatch(scheduleState__set(result.workers_id, result.schedule));
			isFunction(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_SCHEDULE_INSERT));
		}
	};

let LAST_SCHEDULE_DAY = "today";
export const scheduleAction__ofWorker = (worker, day = LAST_SCHEDULE_DAY, onSuccess, hideErrors = false) =>
	async dispatch => {
	day = day || LAST_SCHEDULE_DAY;
		try {
			const result = await scheduleCall__ofWorker(worker, day);
			LAST_SCHEDULE_DAY = day;
			const {schedule, day: responseDay, duration} = result;
			dispatch(scheduleState__workSchedule(worker.workers_id || worker, moment(responseDay).format("YYYY-MM-DD"), schedule, duration))
			isFunction(onSuccess) && onSuccess(result);
			return result;
		} catch (e) {
			if ( hideErrors) {
				if (e.code === 401) {
					dispatch(addError(e));
				}
				console.warn('Loading worker schedule failed', e);
			} else {
				dispatch(addError(e));
			}
		}
	};

export const scheduleCall__dynamicWeek = (workerId, aroundDay = null) => GET(['/work-time', 'dynamic', 'week', workerId, aroundDay].filter(Boolean).join('/'));

export const scheduleCall__dynamicDay = (workerId, day = null) => GET(['/work-time', 'dynamic', 'day', workerId, day].filter(Boolean).join('/'));

export const scheduleCall__dynamicAgenda = (workerId, day = null) => GET(['/work-time', 'dynamic', 'agenda', workerId, day].filter(Boolean).join('/'));

export const scheduleCall__dynamicMonth = (workerId, day = null) => GET(['/work-time', 'dynamic', 'month', workerId, day].filter(Boolean).join('/'));

export const scheduleCall__dynamicAnalyze = (start_point = null, end_point = null) => POST('/work-time/dynamic/analyze', {start_point: toISO(start_point), end_point: toISO(end_point)});

export const scheduleCall__dynamicInsert = (list, range, worker_id, mode) => PUT('/work-time/dynamic', {list, range, worker_id, mode});

export const scheduleCall__dynamicDelete = (worker_id, resources, days, start, end) => DELETE('/work-time/dynamic', {worker_id, resources, days, start, end});
export const scheduleCall__dynamicDeleteIDs = (ids) => DELETE('/work-time/dynamic/ids', {ids})

export const scheduleCall__importRequest = () => GET('/schedule/import')

export const scheduleCall_import = date => POST('/schedule/import', {date: toISO(date)})