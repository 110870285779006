/* eslint-disable */
import * as React from "react";
import PropTypes from "prop-types";
import {Button, Form, Input, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, Flex} from "../partials/ActionHeader";
import {IconButton, List, ListItem} from "material-ui";
import {connect} from "react-redux";
import withInit from "../../Logic/withInit";
import {isOnline} from "../../actions/userActions";
import {orderAction__fetchOneCached} from "../../actions/orderActions";
import {isNumber, isObject} from "lodash";
import {BasicDialog} from "../../Tools/Dialog/Dialogs";
import {OrderChangeTimesView, requestOrderChangeTimesReload} from "../../cointainer/intern/orders/OrderChangeTimesView";
import {IconRefresh} from "../../Logic/icons";
import {OrderRemoveServiceView} from "../../cointainer/intern/orders/OrderRemoveServiceView";
import {OrderInsertServiceView} from "../../cointainer/intern/orders/OrderInsertServiceView";
import {sessionStorageAction__setOrderChangeId} from "../../actions/sessionStorageActions";
import {OrderQuickEdit} from "../../cointainer/intern/orders/quick/OrderQuickEdit";


class OrderQuickChange extends React.PureComponent {
	static propTypes = {
		order: PropTypes.object
	};
	state = {
		show_move: false,
		show_remove: false,
		show_add: false
	};
	
	show = (which, how) => () => this.setState({[`show_${which}`]: how});
	
	render() {
		const {order} = this.props;
		const {
			show_move,
			show_remove,
			show_add
		} = this.state;
		if (!order) return null;
		return (
			<React.Fragment>
				
				<List>
					<ListItem primaryText={"Termin verschieben"} onClick={this.show('move', true)}/>
					<ListItem primaryText={"Service entfernen"} onClick={this.show('remove', true)}/>
					<ListItem primaryText={"Service bearbeiten"} onClick={this.show('add', true)}/>
				</List>
				<BasicDialog modal title={"Abgabe- und Abholzeiten ändern"} autoScrollBodyContent open={show_move} onClose={this.show('move', false)} noPadding closeButton renderActions={(closeBtn) => (
					<Flex align={"space-between"} valign={"center"}>
						{closeBtn}
						<Flex valign={"center"}>
							<IconButton style={{marginRight: 20}} onClick={requestOrderChangeTimesReload}><IconRefresh/></IconButton>
							<Button type={"submit"} form={"order-change-form"} positive>Ändern</Button>
						</Flex>
					</Flex>
				)}>
					<OrderChangeTimesView id={"order-change-form"} order={order} hideSubmit hideReload flexProps={{align: "center"}}/>
				</BasicDialog>
				<BasicDialog modal open={show_remove} title={"Service entfernen"} onClose={this.show('remove', false)} autoScrollBodyContent noPadding closeButton>
					<OrderRemoveServiceView order={order}/>
				</BasicDialog>
				<BasicDialog modal open={show_add} title={"Service bearbeiten"} onClose={this.show('add', false)} autoScrollBodyContent noPadding closeButton>
					<OrderInsertServiceView order_id={order.order_id || order}/>
				</BasicDialog>
			</React.Fragment>
		);
	}
}
OrderQuickChange = withInit(OrderQuickChange);
OrderQuickChange = connect(
	(state, props) => {
		return {
			order: isObject(props.order) ? props.order : state.orders.orders[props.order]
		};
	},
	(dispatch, props) => {
		return {
			init: () => dispatch(isOnline(() => {
				isNumber(props.order) && dispatch(orderAction__fetchOneCached(props.order));
			}))
		};
	}
)(OrderQuickChange);

export class OrderChangeTest extends React.PureComponent {
	state = {
		value: this.props.order_id
	};
	
	// componentDidUpdate(prevProps, prevState, snapshot) {
	// 	if (prevProps.order_id !== this.props.order_id) {
	// 		this.setState({value: this.props.order_id});
	// 	}
	// }
	
	render() {
		return (
			<Segment basic>
				<ActionHeader alignment={"flex-start"}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Order Change Test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<Form onSubmit={() => this.props.update(this.state.value, id => this.props.fetch(id))}>
						<Input value={this.state.value} onChange={(e,{value}) => this.setState({value})}/>
						<Button>ändern</Button>
					</Form>
				</div>
				<div style={{padding: 50}}>
					<OrderQuickEdit order_id={this.props.order_id}/>
				</div>
			</Segment>
		);
	}
}
OrderChangeTest = connect(
	state => ({
		order_id: state.session.dev_order_change_id
	}),
	dispatch => ({
		update: (value, callback) => dispatch(sessionStorageAction__setOrderChangeId(value, callback)),
		fetch: (id) => dispatch(orderAction__fetchOneCached(id))
	})
)(OrderChangeTest);