import {CLIENT_ACTION, RESET_ACTION} from "../actions/types";
import {CACHE_CLIENTS} from "../actions";
import {updateCache} from "ith-redux-helpers";
import omit from 'lodash/omit';
import {updateCacheData} from "ith-redux-helpers/source";

const defaultState = {
	list: {},
	cache: {}
};


export default (state = defaultState, {type, clients, client, client_id, cache=false}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case CLIENT_ACTION.SET:
			return {
				...state,
				list: clients,
				cache: {
					...state.cache,
					...updateCache(CACHE_CLIENTS)
				}
			};
		case CLIENT_ACTION.INSERT:
			return {
				...state,
				list: {...state.list, ...clients},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_CLIENTS)(state.cache)
				}
			};
		case CLIENT_ACTION.ADD:
			return {
				...state,
				list: {
					...state.list,
					[client.client_id]: client
				},
				cache: {
					...state.cache,
					...updateCacheData(CACHE_CLIENTS)(state.cache)
				}
			};
		case CLIENT_ACTION.REMOVE:
			return {
				...state,
				list: omit(state.list, [client_id]),
				cache: {
					...state.cache,
					...updateCacheData(CACHE_CLIENTS)(state.cache)
				}
			};
		default:
			return state;
	}
};