import {MODULE_ACTION} from "./types";
import {setLoader, unsetLoader} from "./loaderActions";
import {CACHE_TIME_MODULES, PROC_MODULE} from "./index";
import {addError} from "./errorActions";
// import {GET} from "ith-fetch";
import {GET} from "../Logic/fetch";
import isFunc from 'lodash/isFunction';
import {isCached} from "ith-redux-helpers";

export const moduleState__set = (modules = {}) => ({type: MODULE_ACTION.SET, modules});
// export const moduleState__setHouses = (houses = {}) => ({type: MODULE_ACTION.SET_HOUSE, houses});

export const fetchModules = (force=false, onSuccess) =>
	async (dispatch, getState) => {
		if ( !force && isCached(getState().modules.cache)(CACHE_TIME_MODULES) ) {
			return;
		}
		try {
			dispatch(setLoader(PROC_MODULE));
			const result = await GET('/module');
			dispatch(moduleState__set(result));
			isFunc(onSuccess) && onSuccess(result);
		} catch (e) {
			dispatch(addError(e));
		} finally {
			dispatch(unsetLoader(PROC_MODULE));
		}
	};

// export const fetchModulesWithHouses = onSuccess => fetchModules(true, onSuccess);
//
// export const fetchModulesOfHouse = (onSuccess) =>
// 	async dispatch => {
// 		try {
// 			dispatch(setLoader(PROC_MODULE));
// 			const result = await GET('/module/of-house');
// 			dispatch(moduleState__setHouses(result));
// 			isFunc(onSuccess) && onSuccess(result);
// 		} catch (e) {
// 			dispatch(addError(e));
// 		} finally {
// 			dispatch(unsetLoader(PROC_MODULE));
// 		}
// 	};