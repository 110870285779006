import {INIT_ACTION, LOGIN_ACTION, LOGOUT_ACTION, REGISTER_ACTION, USER_ACTION, USER_WORK_ACTION} from '../actions/types';
import {updateCache} from "ith-redux-helpers";

const initialState = {
	data: null,
	work: [],
	work_history: [],
	loaded: {
		work: false,
		work_history: false
	},
	resources: [],
	email_login_failed: false,
	schedule: null,
	updated: 0
};

export function userReducer(state = initialState, action){
	switch (action.type) {
		// case RESET_ACTION:
		// 	return initialState;
		case USER_ACTION.LOGIN:
		case LOGIN_ACTION.SUCCESS:
		case INIT_ACTION.LOGGED:
			return {...state, data: action.user, updated: new Date().getTime(), ...updateCache()};
		case USER_ACTION.UPDATE:
			return {...state, data: {...state.data, ...action.data}, ...updateCache()};
		case LOGOUT_ACTION.DONE:
		case USER_ACTION.LOGOUT:
			return {...initialState, data: false };
		case REGISTER_ACTION.SUCCESS:
		case USER_ACTION.REGISTER:
			return {...initialState, data: action.user, updated: new Date().getTime(), ...updateCache()};
		case INIT_ACTION.INDEX:
			return {...initialState, data: false};
		case USER_ACTION.SCHEDULE:
			return {
				...state,
				schedule: action.schedule
			};
		case USER_ACTION.SET_EMAIL_LOGIN:
			return {
				...state,
				email_login_failed: action.email_login_failed
			};
		case USER_ACTION.UPDATE_EMAIL:
			return {
				...state,
				data: {
					...state.data,
					email: action.email
				}
			};
		case USER_WORK_ACTION.SET:
			return {
				...state,
				work: action.work,
				loaded: {
					...state.loaded,
					work: true
				}
			};
		case USER_WORK_ACTION.SET_HISTORY:
			return {
				...state,
				work_history: action.work,
				loaded: {
					...state.loaded,
					work_history: true
				}
			};
		case USER_ACTION.SET_RESOURCES:
			return {
				...state,
				resources: action.resources
			};
		default:
			return state;
	}
}