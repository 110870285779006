import ClientEditView from "../../../components/intern/clients/ClientEditView";
import {connect} from "react-redux";
import {isOnline} from "../../../actions/userActions";
import withInit from "../../../Logic/withInit";
import User from "../../../models/User";
import {push} from 'connected-react-router';
import {SECTION} from "../../../Logic/constants";
import {clientAction__change, clientAction__delete, clientAction__fetchOne} from "../../../actions/clientActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import isFunc from 'lodash/isFunction';
import {isLoading} from "../../../actions/loaderActions";
import {PROC_CLIENT_DELETE, PROC_CLIENT_UPDATE} from "../../../actions";

const m2s = (state, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	const user = User._(state.user.data);
	const client = state.clients.list[client_id];
	return {
		...user.getAccessTo(SECTION.CLIENTS),
		client, client_id,
		headerTitle: client ? (client.salutation === 'company' ? client.company_name : client.first_name + ' ' + client.name) : '...',
		isSaving: isLoading(state, PROC_CLIENT_UPDATE, client_id),
		isDeleting: isLoading(state, PROC_CLIENT_DELETE, client_id),
		is_create: false
	};
};

const m2d = (dispatch, props) => {
	const client_id = Number(props.client_id || props.match.params.client_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(clientAction__fetchOne(client_id));
		})),
		onBack: () => window.history.back(),
		toClients: () => dispatch(push('/clients')),
		onSave: (client, onSuccess) => dispatch(clientAction__change(client, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.clients.updated'));
		})),
		onDelete: (client, force = false, onSuccess, onConfirm) => dispatch(clientAction__delete(client, force,result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.clients.removed'));
			dispatch(push('/clients'));
		}, onConfirm)),
		toVehicles: () => dispatch(push(`/clients/${client_id}/vehicles`)),
		toWheels: () => dispatch(push(`/clients/${client_id}/wheels`))
	};
};

export default connect(m2s, m2d)(withInit(ClientEditView));