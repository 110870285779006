import * as React from "react";
import {Button, Icon} from "semantic-ui-react";
import {connect} from "react-redux";
import {withLoading} from "../../actions/loaderActions";
import {PROC_DUMP_DB} from "../../actions";
import {houseAction__dumpDb} from "../../actions/carhouseActions";
import {addSnackbar} from "../../actions/snackbarActions";

export let SqlDump = ({dispatch, ...props}) => {
	return <Button inverted icon {...props}><Icon name={"download"}/></Button>
};

SqlDump = withLoading('loading', PROC_DUMP_DB)(SqlDump);
SqlDump = connect(
	null,
	(dispatch) => ({
		onClick: () => dispatch(houseAction__dumpDb(res => dispatch(addSnackbar(res.ack ? 'Datenbankdump wurde erstellt' : 'Fehler beim erstellen des Dumps!'))))
	})
)(SqlDump);
