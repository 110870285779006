import {LOAN_CAR_ACTION, RESET_ACTION} from "../actions/types";
import {omit, uniqBy, isObject} from "lodash";

const defaultState = {
	list: {},
	schedule: [],
	schedule_range: {
		start: null,
		end: null
	},
	order: {},
	cache: {}
};

export default (state = defaultState, {type, loan_cars, loan_car, id: remove_id, schedule, range_start, range_end}) => {
	switch(type) {
		case RESET_ACTION:
			return defaultState;
		case LOAN_CAR_ACTION.SET:
			return {
				...state,
				list: {
					...state.list,
					...loan_cars
				}
			};
		case LOAN_CAR_ACTION.INSERT:
			return {
				...state,
				list: {
					...state.list,
					[loan_car.loan_car_id]: loan_car
				}
			};
		case LOAN_CAR_ACTION.REMOVE:
			return {
				...state,
				list: omit(state.list, [remove_id])
			};
		case LOAN_CAR_ACTION.SET_SCHEDULE:
			return {
				...state,
				schedule: uniqBy([...(schedule || []), ...state.schedule], s => s.loan_car_schedule_id)
			};
		case LOAN_CAR_ACTION.ADD_SCHEDULE:
			return {
				...state,
				schedule: uniqBy([schedule, ...state.schedule], s => s.loan_car_schedule_id)
			};
		case LOAN_CAR_ACTION.REMOVE_SCHEDULE:
			let order_id = null;
			if (isObject(schedule) && "order_id" in schedule) {
				order_id = schedule.order_id;
			}
			return {
				...state,
				schedule: state.schedule.filter(s => s.loan_car_schedule_id !== (schedule.loan_car_schedule_id || schedule)),
				order: order_id ? omit(state.order, [order_id]) : state.order
			};
		case LOAN_CAR_ACTION.SET_SCHEDULE_RANGE:
			// range_start = range_start instanceof Date ? range_start : new Date(range_start);
			// range_end = range_end instanceof Date ? range_end : new Date(range_end);
			return {
				...state,
				schedule_range: {
					start: range_start,
					end: range_end
				}
			};
		case LOAN_CAR_ACTION.ORDER_LOAN_CAR:
			return {
				...state,
				order: {
					...state.order,
					...loan_cars
				}
			};
		default:
			return state;
	}
};