import * as React from "react";
import PropTypes from "prop-types";
import {DatePicker, TextField} from "material-ui";
import {DATE_PICKER, TIME_MASK_PROPS} from "../../../Logic/constants";
import InputMask from "react-input-mask";
import {Flex, FlexWrapBreaker} from "../../../components/partials/ActionHeader";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import {moment, parseDate, parseTime} from "../../../Logic/Moment";
import {momentFromDateAndTime} from "../../../Logic/extensions";
import {translate} from "react-i18next";
import {SUB_ORDER_DATE_PICKER_RESET, SUB_ORDER_DATE_PICKER_SET, SUB_ORDER_DATE_PICKER_SET_DATE, SUB_ORDER_DATE_PICKER_SET_TIME} from "../../../actions";
import {publish, subscribe} from "../../../Logic/PubSub";
import cn from "classnames";

const Meta = ({date, range, titlePrimary, titleSecondary, ...props}) => (
	<Flex align={"center"} valign={"center"} wrap style={{color: "#999"}} {...props}>
		{titlePrimary && date && <div style={{marginRight: 25}}><small><strong>{titlePrimary}:</strong> {moment(date).format("LLL")}</small></div>}
		{titleSecondary && range && <div><small><strong>{titleSecondary}:</strong> {moment(range).format("LLL")}</small></div>}
	</Flex>
);

export const OrderDateInfo = ({date, range, titlePrimary, titleSecondary, style, wrap}) => (
	<React.Fragment>
		{wrap && <FlexWrapBreaker/>}
		<div style={{marginLeft: 50, marginTop: -10, ...style}}>
			<Meta date={date} range={range} titlePrimary={titlePrimary} titleSecondary={titleSecondary}/>
		</div>
	</React.Fragment>
);
OrderDateInfo.propTypes = {
	date: PropTypes.instanceOf(Date),
	range: PropTypes.instanceOf(Date),
	titlePrimary: PropTypes.string,
	titleSecondary: PropTypes.string,
	style: PropTypes.object,
	wrap: PropTypes.bool
};

const DATE_PROP = PropTypes.oneOfType([
	PropTypes.instanceOf(Date),
	PropTypes.string,
	PropTypes.number
]);

export class OrderDatePicker extends React.PureComponent {
	static propTypes = {
		date: DATE_PROP,
		time: DATE_PROP,
		minDate: DATE_PROP,
		maxDate: DATE_PROP,
		flexProps: PropTypes.object,
		onChange: PropTypes.func,
		onChangeDate: PropTypes.func,
		onChangeTime: PropTypes.func,
		refMethods: PropTypes.func,
		textDate: PropTypes.string,
		textTime: PropTypes.string,
		children: PropTypes.node,
		loading: PropTypes.bool,
		id: PropTypes.string,
		disabled: PropTypes.bool,
		onComplete: PropTypes.func,
		controlled: PropTypes.bool
	};
	static defaultProps = {
		// minDate: new Date(),
		onChange: () => {},
		onChangeDate: () => {},
		onChangeTime: () => {},
		onComplete: () => {},
		refMethods: () => {},
		textDate: "Datum",
		textTime: "Zeit",
		controlled: false,
		loading: false,
		disabled: false,
		flexProps: {}
	};
	
	/**
	 *
	 * @param time time string to test against
	 * @return {boolean}
	 */
	testTime = time => /\d{2}:\d{2}/.test(time);
	/**
	 * converts date (string, number, Date) to Date
	 * @param value
	 * @return Date|null
	 */
	convert_date = parseDate;
	/**
	 * converts time (string, number, Date) to HH:mm time string!
	 * @param value
	 * @return {string|null}
	 */
	convert_time = parseTime;
	convertMaxDate = memoize(value => this.convert_date(value));
	convertDate = memoize(value => this.convert_date(value));
	convertMinDate = memoize(value => this.convert_date(value));
	convertTime = memoize(value => this.convert_time(value));
	
	state = {
		date: this.convertDate(this.props.date),
		time: this.convertTime(this.props.time) || ""
	};
	
	set = (date = null, time = null) => {
		this.setState({
			date: this.convertDate(date || this.props.date),
			time: this.convertTime(time || this.props.time) || ""
		}, () => {
			this.props.onChangeTime(this.getFinalTime(this.state.time));
			this.props.onChange(...this.getFinalParams(this.state.date, this.state.time));
			this.props.onComplete(this.isComplete());
		});
		return true;
	};
	reset = () => this.set(this.props.date, this.props.time);
	setDate = date => (date = this.convertDate(date)) && this.updateDate(false)(null, date);
	setTime = time => (time = this.convertTime(time) || "") && this.updateTime(false)({target: {value: time}});
	
	componentDidMount() {
		this.props.refMethods({
			reset: this.reset,
			setDate: this.setDate,
			setTime: this.setTime,
			set: (date, time=null) => this.set(date, time || date)
		});
		// reset to initial date/time
		this.unsubscribeReset = subscribe(SUB_ORDER_DATE_PICKER_RESET, ( id) => {
			if (!this.props.controlled && (!id || id === this.props.id )) {
				this.reset();
			}
		});
		// set new date and time, date is used for time if time fails
		this.unsubscribeSet = subscribe(SUB_ORDER_DATE_PICKER_SET, (date, time, id) => {
			if (!this.props.controlled && (!id || id === this.props.id )) {
				this.set(date, time, date);
				// this.setState({
				// 	date: this.convertDate(date),
				// 	time: this.convertTime(time) || this.convertTime(date) || ""
				// }, () => {
				// 	this.props.onChangeTime(this.getFinalTime(this.state.time));
				// 	this.props.onChange(...this.getFinalParams(this.state.date, this.state.time));
				// 	this.props.onComplete(this.isComplete());
				// });
			}
		});
		// set date
		this.unsubscribeSetDate = subscribe(SUB_ORDER_DATE_PICKER_SET_DATE, (date, id) => {
			if (!this.props.controlled && (!id || id === this.props.id)) {
				this.setDate(date);
				// this.updateDate(false)(null, this.convertDate(date));
			}
		});
		// set time
		this.unsubscribeSetTime = subscribe(SUB_ORDER_DATE_PICKER_SET_TIME, (time, id) => {
			if (!this.props.controlled && (!id || id === this.props.id)) {
				this.setTime(time);
				// this.updateTime(false)({
				// 	target: {
				// 		value: this.convertTime(time)
				// 	}
				// });
			}
		});
	}
	
	componentWillUnmount() {
		this.props.refMethods(null);
		// remove registrations
		this.unsubscribeReset && this.unsubscribeReset();
		this.unsubscribeSet && this.unsubscribeSet();
		this.unsubscribeSetDate && this.unsubscribeSetDate();
		this.unsubscribeSetTime && this.unsubscribeSetTime();
		
	}
	
	/**
	 * Returns the time if complete, else null
	 * @type {string|null}
	 */
	getFinalTime = memoize(
		time => this.testTime(time) ? time : null
	);
	/**
	 * Returns Date of date and time string combination or null if date is missing or time is not complete
	 * @type {Date|null}
	 */
	getFinalDate = memoize(
		(date, time) => date && this.testTime(time) ? momentFromDateAndTime(date, time).toDate() : null
	);
	/**
	 * Returns array of [ final_date, inner_date, inner_time ]
	 * @type {result}
	 */
	getFinalParams = memoize(
		(date, time) => [this.getFinalDate(date, time), date, this.getFinalTime(time)]
	);
	/**
	 * Returns the date and time from props (controlled) or from state
	 * @type {result}
	 */
	controlled = memoize(
		(controlled, state, props) => {
			if (controlled) {
				return {
					date: props.date,
					time: props.time
				}
			}
			return state;
		}
	);
	/**
	 * Update the date state
	 * @param controlled
	 * @return {Function}
	 */
	updateDate = (controlled) => (e, date) => {
		if (controlled) {
			this.props.onChangeDate(date);
			this.props.onChange(date, this.props.time, this.getFinalDate(date, this.props.time));
			return true;
		}
		this.setState({
			date
		}, () => {
			console.warn("change recevied", this.props.id);
			this.props.onChangeDate(date);
			this.props.onChange(...this.getFinalParams(this.state.date, this.state.time));
			this.props.onComplete(this.isComplete());
		});
		return true;
	};
	/**
	 * returns true if the date is complete
	 * @return {boolean}
	 */
	isComplete = () => null !== this.getFinalDate(this.state.date, this.state.time);
	/**
	 * updated the time state
	 * @param controlled
	 * @return {Function}
	 */
	updateTime = (controlled) => ({target:{value}}) => {
		if ( controlled) {
			this.props.onChangeTime(value);
			this.props.onChange(this.props.date, value, this.getFinalDate(this.props.date, value));
			return true;
		}
		const timeFailed = !this.testTime(value);
		this.setState({
			time: value
		}, () => {
			this.props.onChangeTime(timeFailed ? null : value);
			this.props.onChange(...this.getFinalParams(this.state.date, this.state.time));
			this.props.onComplete(this.isComplete());
		});
		return true;
	};
	
	render() {
		const {controlled, minDate, maxDate, flexProps, textDate, textTime, children, loading, disabled, className} = this.props;
		// const [date, time] = this.getViewDate(this.state.date, this.props.date);
		const {date, time} = this.controlled(controlled, this.state, this.props);
		return(
			<React.Fragment>
				<Flex className={cn("order-date-picker", className)} vmargin={25} align={"flex-start"} wrap {...flexProps}>
					<DatePicker
						{...DATE_PICKER()}
						minDate={this.convertMinDate(minDate)}
						maxDate={this.convertMaxDate(maxDate)}
						hintText={textDate}
						floatingLabelText={textDate}
						value={date}
						onChange={this.updateDate(controlled)}
						disabled={disabled || loading}
					/>
					<InputMask
						{...TIME_MASK_PROPS}
						value={time}
						disabled={false}
						readOnly={disabled || loading}
						onChange={this.updateTime(controlled)}
					>{() =>
						<TextField
							floatingLabelText={textTime} className={cn({readonly: disabled || loading})}
						/>
					}</InputMask>
					{children}
				</Flex>
			</React.Fragment>
		);
	}
}

OrderDatePicker = translate()(OrderDatePicker);

export const requestDatePickerReset = (id) => publish(SUB_ORDER_DATE_PICKER_RESET, id);
export const requestDatePickerSet = (date, time, id) => publish(SUB_ORDER_DATE_PICKER_SET, date, time, id);
export const requestDatePickerSetDate = (date, id) => publish(SUB_ORDER_DATE_PICKER_SET_DATE, date, id);
export const requestDatePickerSetTime = (time, id) => publish(SUB_ORDER_DATE_PICKER_SET_TIME, time, id);