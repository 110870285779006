import * as React from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {Icon} from "semantic-ui-react";
import {Flex} from "../../../components/partials/ActionHeader";
import {List, ListItem} from "material-ui";
import {Client} from "../../../models";

class AppointmentClient extends React.PureComponent {
	static propTypes = {
		client_id: PropTypes.number.isRequired
	};
	render() {
		const {client_id} = this.props;
		return (
			<Client.Connected id={client_id} placeholder={<Icon name={'circle notch'} loading/>}>{client =>
				<div>
					<h4 style={{marginLeft: 15}}>Kundendaten</h4>
					<Flex wrap>

						<List>
							<ListItem
								disabled
								primaryText={client.client_id}
								secondaryText={"Kunden ID"}
							/>
							<ListItem
								disabled
								primaryText={client.client_number || "n.v."}
								secondaryText={"Kunden Nummer"}
							/>
						</List>



						<List>
							<ListItem
								disabled
								primaryText={client.self ? "Interne Kunde" : "Externer Kunde"}
								secondaryText={"Kundentyp"}
							/>
							<ListItem
								disabled
								primaryText={client.name}
								secondaryText={"Name"}
							/>
							{client.is_company ?
								<ListItem
									disabled
									primaryText={client.contact}
									secondaryText={"Kontakt"}
								/> : null}

						</List>
						

						<List>
							<ListItem
								disabled
								primaryText={`${client.street} ${client.house_no}`}
								secondaryText={"Adresse"}
							/>
							{client.address_extra ?
								<ListItem
									disabled
									primaryText={client.address_extra}
									secondaryText={"Adresszusatz"}
								/> : null}
							<ListItem
								disabled
								primaryText={`${client.zipcode} ${client.city}`}
								secondaryText={"Ort"}
							/>
						</List>

						<List>
							<ListItem
								disabled
								primaryText={client.email || "n.v."}
								secondaryText={"E-Mail"}
							/>
							<ListItem
								disabled
								primaryText={client.phone_full || "n.v."}
								secondaryText={"Telefon"}
							/>
							<ListItem
								disabled
								primaryText={client.mobile || "n.v."}
								secondaryText={"Mobil"}
							/>
						</List>
					</Flex>
				</div>
			}</Client.Connected>
		
		);
	}
}
AppointmentClient = translate()(AppointmentClient);
export default AppointmentClient;