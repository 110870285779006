import LoanCarCreateView from "../../../../components/intern/master-data/loan-cars/LoanCarCreateView";
import {connect} from "react-redux";
import {push} from 'connected-react-router';
import User from "../../../../models/User";
import {SECTION} from "../../../../Logic/constants";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_LOAN_CARS_SEND} from "../../../../actions";

const m2s = (state, props) => {
	const user =  User._(state.user.data);
	return {
		...user.getAccessTo(SECTION.MASTER_DATA),
		isLoading: isLoading(state, PROC_LOAN_CARS_SEND)
	};
};

const m2d = (dispatch, props) => {
	return {
		onBack: () => dispatch(push('/master-data/loan-cars')),
		onSave: (load_car, onSuccess) => {}
	};
};

export default connect(m2s, m2d)(LoanCarCreateView);