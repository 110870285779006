import {array2object} from "../../../Logic/extensions";
import {uniqBy} from "lodash";

export const rightFilter = (service, stock) => release => (service && !release.service) || (stock && !release.stock);

export const filterData = (list, releases = {}, mayService = false, mayStock = false) => {
	let release_list = [];
	try {
		release_list = list.map(r => releases[r.order_service_id] || null).filter(Boolean).filter(rightFilter(mayService, mayStock));
	} catch(e){
		console.error('Relasefilter has error',e );
	}
	const release_map = array2object(release_list)('order_service_id');
	const visible_data = list.filter(d => d.order_service_id in release_map);
	const count = uniqBy(visible_data, d => d.order_id).length;
	return {
		release_list,
		data_list: visible_data,
		count
	};
};