import * as React from 'react';
import {GET} from "ith-fetch";
import {addError} from "../actions/errorActions";
import {connect} from "react-redux";
import {replace} from 'connected-react-router';
import {PRODUCTION_MODE} from "../Logic/constants";

class Test extends React.PureComponent {
	componentDidMount() {
	
	}
	render() {
		return <div>
			This is an exception test!
		</div>;
	}
}

const testException = () => async dispatch => {
	try {
		await GET('/dev/test-exception');
	} catch (e) {
		dispatch(addError(e));
		dispatch(addError(e));
	} finally {
	}
};

const m2d = dispatch => {
	if ( PRODUCTION_MODE ) {
		dispatch(replace('/not-found'));
	} else {
		dispatch(testException());
	}
	return {};
};

export default connect(null, m2d)(Test);