import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/concat';
import 'core-js/fn/array/every';
import 'core-js/fn/array/some';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/keys';
import 'core-js/fn/object/values';
import "core-js/fn/object/entries";
import 'core-js/fn/array/map';
import 'core-js/fn/array/for-each';
import "core-js/fn/string/pad-start";
import "core-js/fn/string/pad-end";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import registerServiceWorker from './registerServiceWorker';
import AppController from "./cointainer/AppController";
import i18n from './i18n';
import {clearUserStorage, setLanguage} from "./actions/userActions";
import history from './Logic/history';
// import {ConnectedRouter} from 'react-router-redux';
import {ConnectedRouter} from "connected-react-router";
import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import './style/index.css';
import constants from "./Logic/constants";
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MaterialThemeExtension from './style/material';
import {setBaseUrl} from "ith-fetch";
import {ScreenContext} from "./Logic/extensions";
import moment from './Logic/Moment';
import {store} from "./store";
import {mapAction__init} from "./actions/mapActions";
import {resetAction} from "./actions/types";
import hasher from 'object-hash'
import {deepMemoize} from "./Logic/extensions";

const theme = getMuiTheme(lightBaseTheme, MaterialThemeExtension);

// i18n.changeLanguage('de');
i18n.on('languageChanged', lng => {
	store.dispatch(setLanguage(lng));
});

if ( !constants.PRODUCTION ) {
	
	window.store = store;
	window.hasher = hasher
	window.moment = moment;
	window.mem = deepMemoize(data => {
		console.debug('mem test says', data)
	})
}

// SUPPORT
if ( window.location.search === '?support') {
	clearUserStorage();
	store.dispatch(resetAction);
	window.location.href = window.location.href.replace('?support', '');
} else if (window.location.search || window.location.href.match(/\?/)) {
	window.history.replaceState(null, null, window.location.href.replace(/\?.*/, ""));
}
// initiate map session
store.dispatch(mapAction__init());
setBaseUrl(constants.URL);

try {
	ReactDOM.render(
		<Provider store={store}>
			<MuiThemeProvider muiTheme={theme}>
				<ConnectedRouter history={history} basename={window.location.pathname || ''}>
					<ScreenContext>
							<AppController/>
					</ScreenContext>
				</ConnectedRouter>
			</MuiThemeProvider>
		</Provider>,
		document.getElementById('root')
	);
	if (!constants.PRODUCTION) {
		registerServiceWorker();
		// unregister();
	}
} catch(e) {
	console.error(e);
}
