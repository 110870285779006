/////////////
//      Quick Client
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {Grid, Segment} from "semantic-ui-react";
import {List, ListItem} from "material-ui";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__fetchOne} from "../../../../actions/orderActions";
import {clientAction__fetch, clientAction__getByOrder} from "../../../../actions/clientActions";
import {translate} from "react-i18next";

export class OrderQuickClient extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		client: PropTypes.object
	};
	
	render() {
		const {client, order, t} = this.props;
		
		if (isEmpty(order) || isEmpty(client)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>
		}
		return (
			<Grid stackable columns={2}>
				<Grid.Column>
					<List>
						<ListItem disabled secondaryText={"KundenID"} primaryText={client.client_id}/>
						<ListItem disabled secondaryText={"Kundennummer"} primaryText={client.client_number || "n.v."}/>
						<ListItem disabled secondaryText={"Anrede"} primaryText={t(`client.salutations.${client.salutation}`)}/>
						<ListItem disabled secondaryText={"Vorname"} primaryText={client.first_name}/>
						<ListItem disabled secondaryText={"Name"} primaryText={client.name}/>
						<ListItem disabled secondaryText={"Firma"} primaryText={client.company_name || "n.v."}/>
					</List>
				</Grid.Column>
				<Grid.Column>
					<List>
						<ListItem disabled secondaryText={"Straße + Nr"} primaryText={client.street + " " + client.house_no}/>
						{client.address_extra && <ListItem disabled secondaryText={"Adresszusatz"} primaryText={client.address_extra}/>}
						<ListItem disabled secondaryText={"PLZ + Stadt"} primaryText={client.zipcode + " " + client.city}/>
						<ListItem disabled secondaryText={"Land"} primaryText={t(`country.${client.country}`)}/>
						<ListItem disabled secondaryText={"E-Mail"} primaryText={client.email}/>
						<ListItem disabled secondaryText={"Telefon"} primaryText={`+49 ${client.telephone_prefix} ${client.telephone}`}/>
						<ListItem disabled secondaryText={"Mobil"} primaryText={"+49 " + client.mobile}/>
					</List>
				</Grid.Column>
			</Grid>
		);
	}
}

OrderQuickClient = withInit(OrderQuickClient);
OrderQuickClient = connect(
	(state, props) => {
		const order_id = Number(props.order_id);
		const order = state.appointments.orders[order_id] || {};
		let order2vehicle = {};
		let client = {};
		if (!isEmpty(order)) {
			order2vehicle = state.vehicles.order2vehicle[order.order_vehicle_id] || {};
			if (!isEmpty(order2vehicle)) {
				client = state.clients.list[order2vehicle.client_id];
			}
		}
		// const o2v = state.
		return {
			order_id,
			order,
			order2vehicle,
			client
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(orderAction__fetchOne(props.order_id));
			dispatch(clientAction__getByOrder(props.order_id));
			dispatch(clientAction__fetch());
		}))
	})
)(OrderQuickClient);
OrderQuickClient = translate()(OrderQuickClient);