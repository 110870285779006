import * as React from "react";
import {Button, Grid, Message, Segment} from "semantic-ui-react";
import {COLORS} from "../../../../Logic/constants";
import {connect} from "react-redux";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_LOAN_CARS_FETCH, PROC_LOAN_CARS_SEND} from "../../../../actions";
import {loanCarAction__assignOrder, loanCarAction__getByOrder, loanCarCall__findOfOrder} from "../../../../actions/loanCarActions";
import {translate} from "react-i18next";
import PropTypes from "prop-types";
import moment, {toISO} from "../../../../Logic/Moment";
import {EasyFlex, FlexItem, Space} from "../../../../components/partials/ActionHeader";
import {OrderDatePicker} from "../OrderDatePickerV2";
import {RegistrationMark} from "../../../../components/intern/vehicles";
import {get, isFunction} from "lodash";
import {List, ListItem} from "material-ui";
import withInit from "../../../../Logic/withInit";
import {isOnline} from "../../../../actions/userActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {ErrorView} from "./ErrorView";
import {LoanCarRemove} from "./LoanCarRemove";


/////////////
//      Quick LoanCar
///////////////////////
export class OrderQuickLoanCar extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		scheduleList: PropTypes.array,
		loanCarsMap: PropTypes.object,
		loading: PropTypes.bool,
		onAssign: PropTypes.func,
		assigning: PropTypes.bool
	};
	
	state = {
		fetching: false,
		loan_cars: [],
		error: null,
		ack: null,
		range_start: null,
		range_end: null,
		selected: 0
	};
	
	lookup = async() => {
		try {
			this.setState({fetching: true, error: null, ack: null, selected: 0});
			const result = await loanCarCall__findOfOrder(this.props.order_id);
			this.setState({ack: result.ack, loan_cars: result.list || [], range_start: result.start || null, range_end: result.end || null});
		} catch (e) {
			this.setState({error: e.error || e.message || String(e)})
		} finally {
			this.setState({fetching: false})
		}
	};
	
	assign = () => {
		const {range_start, range_end, selected} = this.state;
		const data = {
			start_point: toISO(range_start),
			end_point: toISO(range_end),
			order_id: this.props.order_id,
			vehicle_id: selected
		};
		this.props.onAssign(data, () => this.setState({loan_cars: [], error: null, ack: null, range_start: null, range_end: null, selected: 0}));
	};
	
	render() {
		const {loading, scheduleList, loanCarsMap, t} = this.props;
		if (loading) {
			return (<Segment basic loading style={{minHeight: 100, minWidth: 100}}/>)
		}
		
		if (!scheduleList.length) {
			const {
				loan_cars,
				fetching,
				ack,
				error,
				range_start,
				range_end
			} = this.state;
			return (
				<Segment basic loading={this.props.assigning}>
					<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER}>
						<FlexItem style={{pading: "0 10px"}}>
							<Message><p>Kein Leihwagen vergeben</p></Message>
						</FlexItem>
						<FlexItem style={{pading: "0 10px"}}>
							<Button onClick={this.lookup} loading={fetching}>Leihfahrzeug hinzubuchen</Button>
						</FlexItem>
					</EasyFlex>
					{ack === false &&
					<Message color={"blue"}>
						<p>Kein Leihfahrzeug gefunden</p>
					</Message>
					}
					<ErrorView error={error} errorView={error && <Message negative><p>{t([error.message || error.error || String(error)], "errors.bad")}</p></Message>}>
						<Space height={20}/>
						{range_start && range_end &&
						<EasyFlex align={EasyFlex.align.CENTER} style={{paddingTop: 40, paddingBottom: 30, background: "#fafafa"}}>
							<FlexItem style={{textAlign:"center", paddingLeft: 5, paddingRight: 5}}>
								<strong>Fahrzeug ab</strong>
								<OrderDatePicker
									date={moment(range_start).toDate()}
									time={moment(range_start).toDate()}
									disabled
								/>
							</FlexItem>
							<FlexItem style={{paddingLeft: 5, paddingRight:5, borderLeft: "1px solid #ddd", textAlign: "center"}}>
								<strong>Fahrzeug bis</strong>
								<OrderDatePicker
									date={moment(range_end).toDate()}
									time={moment(range_end).toDate()}
									disabled
								/>
							</FlexItem>
						</EasyFlex>
						}
						{loan_cars && loan_cars.length > 0 &&
						<div>
							{loan_cars.map((v, i) =>
								<EasyFlex onClick={() => this.setState({selected: v.vehicle_id})} key={v.vehicle_id} align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER} style={{
									padding: 20,
									background: this.state.selected === v.vehicle_id ? COLORS.PRIMARY : (i % 2 ? "#fafafa" : null),
									cursor: "pointer"
								}}>
									<strong>{v.name}</strong>
									<RegistrationMark>{v.registration_mark}</RegistrationMark>
								</EasyFlex>
							)}
						</div>
						}
						{this.state.selected > 0 &&
						<EasyFlex align={EasyFlex.align.CENTER} valign={EasyFlex.valign.CENTER} style={{padding: 40}}>
							<Button positive onClick={this.assign}>Fahrzeug zuweisen</Button>
						</EasyFlex>
						}
					</ErrorView>
				
				</Segment>
			);
		}
		
		
		
		return (
			scheduleList.map(schedule => {
				const loanCar = loanCarsMap[get(schedule, "vehicle_id", 0)] || {};
				return (
					<Grid key={schedule.loan_car_schedule_id} stackable columns={2}>
						<Grid.Column>
							<List>
								<ListItem
									disabled
									secondaryText={"Fahrzeug"}
									primaryText={loanCar.name}
								/>
								<ListItem
									disabled
									secondaryText={"Von"}
									primaryText={moment(schedule.start_point).format("LLL")}
								/>
								<ListItem
									disabled
									secondaryText={"Bis"}
									primaryText={moment(schedule.end_point).format("LLL")}
								/>
							</List>
						</Grid.Column>
						<Grid.Column>
							<List>
								<ListItem disabled secondaryText={"HSN/TSN"} primaryText={`${loanCar.hsn}/${loanCar.tsn}`}/>
								<ListItem disabled secondaryText={"Fahrgestellnummer"} primaryText={loanCar.chassis_number}/>
								<ListItem disabled secondaryText={"Kennzeichen"} primaryText={loanCar.registration_mark}/>
								<ListItem disabled>
									<LoanCarRemove id={schedule.loan_car_schedule_id}/>
								</ListItem>
							</List>
						</Grid.Column>
					</Grid>
				);
			})
		);
	}
}

OrderQuickLoanCar = withInit(OrderQuickLoanCar);
OrderQuickLoanCar = connect(
	(state, props) => {
		return {
			loading: isLoading(state, PROC_LOAN_CARS_FETCH, props.order_id),
			scheduleList: get(state, ["loan_cars", "order", props.order_id], []),
			loanCarsMap: get(state, "loan_cars.list", {}),
			assigning: isLoading(state, PROC_LOAN_CARS_SEND)
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			dispatch(loanCarAction__getByOrder(props.order_id));
		})),
		onAssign: (data, onSuccess) => dispatch(loanCarAction__assignOrder(data, result => {
			dispatch(addSnackbar('Leihfahrzeug wurde zugewiesen'));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(OrderQuickLoanCar);
OrderQuickLoanCar = translate()(OrderQuickLoanCar);
