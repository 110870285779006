import * as React from "react";
import PropTypes from "prop-types";
import {OrderChangeTimesView} from "../OrderChangeTimesView";
import {Button, Modal} from "semantic-ui-react";
import {OrderInsertServiceView} from "../OrderInsertServiceViewV2";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__fetchOneCached} from "../../../../actions/orderActions";
import {Trans} from "react-i18next";

export class OrderQuickEdit extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number,
		order: PropTypes.object,
		onOpened: PropTypes.func,
	};
	static defaultProps = {
		onOpened: () => {
		}
	};
	state = {
		show_move: false,
		show_remove: false,
		show_add: false
	};
	
	show = (which, how) => () => this.setState({[`show_${which}`]: how}, () => this.props.onOpened(how));
	
	render() {
		const {order} = this.props;
		const {
			show_move,
			// show_remove,
			show_add
		} = this.state;
		if (!order) return null;
		return (
			<React.Fragment>
				<Button.Group>
					<Button onClick={this.show('move', true)}><Trans defaults="Termin verschieben"/></Button>
					{/*<Button onClick={this.show('remove', true)}>Service entfernen</Button>*/}
					<Button onClick={this.show('add', true)}><Trans defaults="Service bearbeiten"/></Button>
				</Button.Group>
			{/*	<BasicDialog modal title={"Abgabe- und Abholzeiten ändern"} autoScrollBodyContent open={show_move} onClose={this.show('move', false)} noPadding closeButton
				             renderActions={(closeBtn) => (
					             <Flex align={"space-between"} valign={"center"}>
						             {closeBtn}
						             <Flex valign={"center"}>
							             <IconButton style={{marginRight: 20}} onClick={requestOrderChangeTimesReload}><IconRefresh/></IconButton>
							             <Button type={"submit"} form={"order-change-form"} positive>Ändern</Button>
						             </Flex>
					             </Flex>
				             )}>
					<OrderChangeTimesView id={"order-change-form"} order={order} hideSubmit hideReload flexProps={{align: "center"}}/>
				</BasicDialog>*/}
				<Modal open={show_move} closeOnDimmerClick={false} onClose={this.show('move', false)}>
					<Modal.Header><Trans defaults="Abgabe- und Abholzeiten ändern"/></Modal.Header>
					<Modal.Content>
						<OrderChangeTimesView id={"order-change-form"} order={order}  flexProps={{align: "center"}}/>
					</Modal.Content>
					<Modal.Actions>
						{/*<Button icon={'history'} onClick={requestOrderChangeTimesReload}/>*/}
						{/*<Button positive type={'submit'} form={'order-change-form'}>Ändern</Button>*/}
						<Button onClick={this.show('move', false)}><Trans defaults="Schließen"/></Button>
					</Modal.Actions>
				</Modal>
				{/*<Modal open={show_remove} size={'tiny'} closeOnDimmerClick={false} onClose={this.show('remove', false)}>*/}
				{/*	<Modal.Header>Service entfernen</Modal.Header>*/}
				{/*	<Modal.Content>{!!order && <OrderRemoveServiceView order_id={order.order_id}/>}</Modal.Content>*/}
				{/*	<Modal.Actions><Button onClick={this.show('remove', false)}>Schließen</Button></Modal.Actions>*/}
				{/*</Modal>*/}
				<Modal open={show_add} closeOnDimmerClick={false} onClose={this.show('add', false)}>
					<Modal.Header><Trans defaults="Service bearbeiten"/></Modal.Header>
					<Modal.Content>{!!order && <OrderInsertServiceView order_id={order.order_id}/>}</Modal.Content>
					<Modal.Actions><Button onClick={this.show('add', false)}><Trans defaults="Schließen"/></Button></Modal.Actions>
				</Modal>
			
			</React.Fragment>
		);
	}
}

OrderQuickEdit = withInit(OrderQuickEdit);
OrderQuickEdit = connect(
	(state, {order, order_id}) => {
		return {
			order: order || state.orders.orders[order_id]
		};
	},
	(dispatch, {order_id}) => {
		return {
			init: () => dispatch(isOnline(() => {
				order_id && dispatch(orderAction__fetchOneCached(order_id));
			}))
		};
	}
)(OrderQuickEdit);