import * as React from 'react';
import PropTypes from 'prop-types';
import MDTimeZoneController from "../../../cointainer/intern/master-data/base/MDTimeZoneController";
import {Col, Grid, Row} from "react-bootstrap";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import MDResourceController from "../../../cointainer/intern/master-data/base/MDResourceController";
import MDHouseDataController from "../../../cointainer/intern/master-data/base/MDHouseDataController";
import MDHouseCEOController from "../../../cointainer/intern/master-data/base/MDHouseCEOController";
import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';
import MDHouseLogoController from "../../../cointainer/intern/master-data/base/MDHouseLogoController";
import {translate} from "react-i18next";
import MDHouseWorkerController from "../../../cointainer/intern/master-data/base/MDHouseWorkerController";
import MDHouseAppointmentController from "../../../cointainer/intern/master-data/base/MDHouseAppointmentController";
import {VERSION} from "../../../Logic/constants";
import {MDEwoTW} from "./base/MDEwoTW";
import {MDCalendarSetting} from "./base/MDCalendarSetting";

class MasterDataBaseView extends React.Component {
	grid = null;
	state = {
		grid: null
	};
	
	setGrid = grid => this.grid = grid;
	onUpdateLayout = () => this.grid && this.grid.updateLayout();
	
	render() {
		const {t, onBack, size} = this.props;
		return (
			<Grid className="text-left" fluid={true}>
				<Row>
					<Col xs={12}>
						<ActionHeader alignment={'space-between'}>
							<ActionHeaderGroup>
								{onBack && <ActionBackButton onBack={onBack}/>}
								<ActionHeading style={{marginLeft: 20}}>{t('worker.detail.parts.basisdata')} </ActionHeading>
							</ActionHeaderGroup>
							<span>Build-Nummer: {VERSION}</span>
						</ActionHeader>
					</Col>
				</Row>
				<StackGrid columnWidth={size.width < 700 ? '100%' : 500} gutterWidth={10} gutterHeight={10} gridRef={this.setGrid}>
					<MDHouseDataController key={'house-data'}/>
					<MDHouseCEOController key={'house-ceo'}/>
					<MDHouseLogoController key={'logo'} onImageLoad={this.onUpdateLayout} imageAlt={'upload.image-alt-house'}/>
					<MDHouseWorkerController key={'house-worker'}/>
					<MDHouseAppointmentController key={'house-appointments'}/>
					<MDTimeZoneController key={'timezone'}/>
					<MDResourceController key={'resource'} hideBack/>
					<MDEwoTW key={'ewo-tw'}/>
					<MDCalendarSetting supportOnly/>
				</StackGrid>
			</Grid>
		);
	}
}

MasterDataBaseView.propTypes = {
	onBack: PropTypes.func
};
MasterDataBaseView.defaultProps = {
	onBack: null
};


export default sizeMe()(translate()(MasterDataBaseView));