import * as React from 'react';
import PropTypes from 'prop-types';

const Pre = ({data, spacing, visible, style, title, ...props}) => (
	visible ? <pre style={{textAlign: 'left', ...style}}{...props}>{title ? <strong><u>{title}:</u><br/></strong> : null}{JSON.stringify(data, null, spacing)}</pre> : null
);
Pre.propTypes = {
	data: PropTypes.any,
	spacing: PropTypes.number,
	visible: PropTypes.bool,
	style: PropTypes.object,
	title: PropTypes.node
};
Pre.defaultProps = {
	spacing: 2,
	visible: true,
	style: {},
	title: null
};
export default Pre;
