import * as React from "react";
import {Placeholder} from "semantic-ui-react";
import LogoutOverlay from "../../cointainer/intern/LogoutOverlay";

const Ph = ({count = 10}) => {
	let list = [];
	while(count > 0) {
		list.push(count--);
	}
	
	return	(
		<Placeholder fluid>
			{list.map(i =>
				<Placeholder.Paragraph key={i}>
					<Placeholder.Line length={"long"}/>
					<Placeholder.Line length={"short"}/>
					<Placeholder.Line length={"medium"}/>
					<Placeholder.Line length={"full"}/>
					<Placeholder.Line length={"short"}/>
				</Placeholder.Paragraph>
			)}
		</Placeholder>
	);
};



export class LogoutOverlayTest extends React.PureComponent {
	render() {
		
		return (
			<div style={{padding: 20}}>
				<Ph/>
				<LogoutOverlay/>
			</div>
		);
	}
}
