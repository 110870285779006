import React from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import constants from '../../Logic/constants';

let LogoutView = ({t, animate, loader, onLogout, className, children, isLoading}) => {
	const trans = element => (typeof element === 'string' ? t(element) : element);
	return (
		<button type="button" className={'logout-btn ' + className} onClick={e => onLogout(e)} disabled={isLoading}>
			{trans(children)}
			{ isLoading && <div className={'loader ' + (animate && 'animate')}>
				<img src={loader} alt={t('imageAlt.logout')}/>
			</div>}
		</button>
	)
};

LogoutView.propTypes = {
	onLogout: PropTypes.func.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	isLoading: PropTypes.bool,
	loader: PropTypes.node,
	animate: PropTypes.bool
};
LogoutView.defaultProps = {
	className: '',
	children: 'actions.logout',
	isLoading: false,
	loader: constants.LOADER,
	animate: false
};

export default translate()(LogoutView);