import * as React from "react";
import PropTypes from "prop-types";
import {Flex, FlexChild} from "../../../partials/ActionHeader";
import {Card, CardMedia, CardTitle} from "material-ui";
import Ink from "react-ink";
import {Worker} from "../../../../models";
import {OnlineIcon} from "../../../../Tools/OnlineIcon";

export const WorkerGridView = ({workers, onDetail, gridSize, gutterSize}) => (
	<Flex wrap gutterWidth={gutterSize} gutterHeight={gutterSize}>
		{
			workers.map(worker =>
				<FlexChild key={worker.id}>
					<Card onClick={() => onDetail(worker.id)} style={{position: 'relative', width: gridSize, height: gridSize}} className={'pointer worker-grid'}>
						
						<CardMedia
							className={"worker-card-media"}
							mediaStyle={{overflow: 'hidden', height: gridSize, position: 'relative'}}
							style={{background: worker.avatar_background, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}
							overlay={<CardTitle className={"worker-card-overlay"} title={<span>{worker.name} <OnlineIcon style={{transform: 'translateY(-3px)'}} size={'mini'} online={worker.logged_at}/></span>} subtitle={worker.section}/>}>
						</CardMedia>
						<Ink radius={400}/>
					</Card>
				</FlexChild>
			)
		}
	</Flex>
);
WorkerGridView.propTypes = {
	workers: PropTypes.arrayOf(PropTypes.instanceOf(Worker)).isRequired,
	onDetail: PropTypes.func.isRequired,
	gridSize: PropTypes.number,
	gutterSize: PropTypes.number
};
WorkerGridView.defaultProps = {
	gridSize: 150,
	gutterSize: 10
};