import * as React from "react";
import User from "../models/User";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {MODULES} from "../Logic/constants";
import {mapValues} from 'lodash'
import {deepMemoize as memoizeOne} from "../Logic/extensions";

const noRights = {
	mayRead: false,
	mayChange: false,
	mayCreate: false,
	mayDelete: false,
	mayGrant: false
};
export const withUser = (Component) => connect(state => ({
	user: new User(state.user.data)
}))(Component);

export const withRights = (section, component) => {
	const m2s = (state, props) => {
		const user = User._(state.user.data);
		return {
			...props,
			user,
			rights: user ? user.access(section) : User.noRights,
			allRights: user.accessMap()
		};
	};
	
	return connect(m2s)(component);
};

const RIGHT_SHAPE = {
	mayRead: PropTypes.bool.isRequired,
	mayChange: PropTypes.bool.isRequired,
	mayCreate: PropTypes.bool.isRequired,
	mayDelete: PropTypes.bool.isRequired,
	mayGrant: PropTypes.bool.isRequired
};

export const RIGHT_PROPS = {
	user: PropTypes.instanceOf(User).isRequired,
	rights: PropTypes.shape(RIGHT_SHAPE).isRequired,
	allRights: PropTypes.shape({
		'master-data': PropTypes.shape(RIGHT_SHAPE).isRequired,
		workers: PropTypes.shape(RIGHT_SHAPE).isRequired,
		clients: PropTypes.shape(RIGHT_SHAPE).isRequired,
		appointments: PropTypes.shape(RIGHT_SHAPE).isRequired,
		orders: PropTypes.shape(RIGHT_SHAPE).isRequired,
	})
};

export const RIGHT_DEFAULT_PROPS = {
	rights: User.noRights,
	allRights: {
		'master-data': User.noRights,
		workers: User.noRights,
		clients: User.noRights,
		appointments: User.noRights,
		orders: User.noRights,
	}
};


const isModule = (source, type) => {
	if (typeof type === 'string') {
		return !!source[MODULES[type] || 0]
	}
	return !!source[type]
}
const mod = memoizeOne((map, list) => mapValues(map, (val => !!list[val])))

export const withModule = (Component) => connect(
	state => ({
		modules: mod(MODULES, state.modules.list),
		isModule: (type) => isModule(state.modules.list, type)
	})
)((props) => <Component {...props}/>)

export const withHouse = (Component) => connect(
	state => ({
		houseData: state.carhouse.own || {}
	})
)(props => <Component {...props}/>)