import * as React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export class StatyComponent extends React.Component {
	is_mounted = false;
	
	componentDidMount() {
		this.is_mounted = true;
	}
	
	componentWillUnmount() {
		this.is_mounted = false;
	}
	
	setState(state, callback) {
		if (this.is_mounted) {
			super.setState(state, callback);
		} else {
			// if (typeof state === 'function') {
			// 	state = state(this.state);
			// }
			// this.state = {...this.state, ...state};
			typeof callback === 'function' && callback();
		}
	}
}

export class StatyPureComponent extends React.PureComponent {
	is_mounted = false;
	
	componentDidMount() {
		this.is_mounted = true;
	}
	
	componentWillUnmount() {
		this.is_mounted = false;
	}
	
	setState(state, callback) {
		if (this.is_mounted) {
			super.setState(state, callback);
		} else {
			// if (typeof state === 'function') {
			// 	state = state(this.state);
			// }
			// this.state = {...this.state, ...state};
			typeof callback === 'function' && callback();
		}
	}
}

export const PortalWrapper = ({children, portal, only}) => portal ? ReactDOM.createPortal(<React.Fragment>{children}</React.Fragment>, portal) : (only ? null : <React.Fragment>{children}</React.Fragment>);
PortalWrapper.propTypes = {
	children: PropTypes.node,
	portal: PropTypes.object,
	only: PropTypes.bool
}
PortalWrapper.defaultProps = {
	only: false
}