import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Trans, translate} from "react-i18next";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import {get, isFunction} from "lodash";
import {workerAction__getWork} from "../../../actions/workerActions";
import {Button, Header, Modal, Icon, Segment, Table} from "semantic-ui-react";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_WORKER_FETCH_WORK, SUB_PAUSE_APPOINTMENT} from "../../../actions/index";
import {FlatButton} from "material-ui";
import {EasyFlex, Flex, FlexChild} from "../../../components/partials/ActionHeader";
import {appointmentAction__joinWork, appointmentAction__pauseResource, appointmentAction__pauseService, appointmentAction__updateResourceState, appointmentAction__updateServiceState, appointmentAction__work} from "../../../actions/appointmentActions";
import {addSnackbar} from "../../../actions/snackbarActions";
import {falseNull, Loadable} from "../../../Logic/extensions";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import {publish} from "../../../Logic/PubSub";
import cn from "classnames";
import {JoinWorkForced} from "./JoinWorkForced";
import {ReleaseIcon} from "../release";

class AppointmentWorkers extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		workdload: PropTypes.array,
		resources: PropTypes.object,
		services: PropTypes.object,
		serviceMap: PropTypes.object,
		resourceMap: PropTypes.object,
		userResources: PropTypes.array,
		order2services: PropTypes.object,
		service2resources: PropTypes.object,
		loading: PropTypes.bool,
		join: PropTypes.func,
		joinForced: PropTypes.func,
		changeState: PropTypes.func,
		onUpdateServiceState: PropTypes.func,
		onUpdateResourceState: PropTypes.func,
		strict: PropTypes.bool,
		fused: PropTypes.bool,
		currentWork: PropTypes.object,
		accessable: PropTypes.bool,
		work_map: PropTypes.object,
		filter_unreleased: PropTypes.bool
	};
	static defaultProps = {
		workload: [],
		order: {},
		resourceMap: {},
		resources: {},
		services: {},
		serviceMap: {},
		order2services: {},
		service2resources: {},
		userResources: [],
		loading: false,
		strict: false,
		fused: false,
		onUpdateServiceState: () => alert('AppointmentWorkers::onUpdateServiceState() is not implemented yet'),
		onUpdateResourceState: () => alert('AppointmentWorkers::onUpdateResourceState() is not implemented yet'),
		join: () => alert('AppointmentWorkers::join() is not implemented, yet!'),
		joinForces: () => alert('AppointmentWorkers::joinForced() is not implemented, yet!'),
		changeState: () => alert('AppointmentWorkers::changeState() is not implemented, yet!'),
		accessable: true,
		work_map: {},
		filter_unreleased: false
	};
	
	state = {
		confirm: false,
		request: {},
		work: []
	};
	
	onConfirm = ({work, confirm, request}) => this.setState({work, confirm, request});
	
	render() {
		const {
			workload,
			loading,
			order,
			services,
			serviceMap,
			resources,
			resourceMap,
			userResources,
			join,
			joinForced,
			// order2services,
			service2resources,
			onUpdateResourceState,
			strict,
			user,
			pauseService,
			accessable: propAccessable,
			currentWork,
			work_map,
			fused,
			filter_unreleased,
			service_ids: serviceIds
		} = this.props;
		// let service_ids = order2services[order.order_id] || null;
		let service_ids = serviceIds;
		if ( null === service_ids ) {
			console.warn('No service ids in appointment paper of order', order.order_id);
			return <Icon name={'circle notch'} loading/>;
		}
		if (filter_unreleased) {
			service_ids = service_ids.filter(id => (work_map[order.order_id] || []).find(o => o.order_service_id === id));
		}
		return(
			<Segment basic loading={loading} style={{margin: 0, padding: 0}}>
				<Header size={"small"}>Arbeiten ({workload.length})</Header>
				<Table style={{with: '100%'}} basic={"very"}>
					<Table.Body>
						
						{service_ids.map(serve_id => {
							// Order Service
							const serviceInfo = services[serve_id];
							if ( !serviceInfo ){
								console.warn('order-service missing in appoitnmentpaper of order/service', order.order_id, serve_id);
								return <Icon name={'circle notch'} loading/>;
							}
							// Associated resources
							const resource_ids = service2resources[serve_id] || null;
							if (null === resource_ids) {
								console.warn('resource ids missing  appointmentpaper of order/service', order.order_id, serve_id);
								return <Icon name={'circle notch'} loading/>;
							}
							// real service data
							const service = serviceMap[serviceInfo.service_id];
							if ( !service) {
								console.warn('origin service missing in appoitnmentpaper of order/service', order.order_id, serve_id);
								return <Icon name={'circle notch'} loading/>;
							}
							const resourceHash = {};
							// return <pre>{JSON.stringify({resourceMap}, null, 2)}</pre>
							return (
								<React.Fragment key={`${serve_id}`}>
									<Table.Row>
										<Table.HeaderCell collapsing>
											<EasyFlex valign={EasyFlex.valign.CENTER}>
												<ReleaseIcon id={serviceInfo.order_service_id} size={20} api/>
												<span>{service.title}</span>
											</EasyFlex>
										</Table.HeaderCell>
										<Table.HeaderCell collapsing>&nbsp;</Table.HeaderCell>
										<Table.HeaderCell>&nbsp;</Table.HeaderCell>
									</Table.Row>
									{resource_ids.map(res_id => {
										const resourceInfo = resources[res_id];
										if ( !resourceInfo ) {
											console.warn('order-resource missing in appoitnmentpaper of order/service/resource', order.order_id, res_id);
											return <Icon name={'circle notch'} loading/>;
										}
										const resource = resourceMap[resourceInfo.resource_id];
										if (!resource) {
											console.warn('origin resource missing in appointmentpaper of order/service/resource', order.order_id, serve_id, resourceInfo.resource_id);
											return <Icon name={'circle notch'} loading/>;
										}
										// do not repeat same resource
										if ( resourceHash[resource.resource_id]) {
											return null;
										}
										resourceHash[resource.resource_id] = true;
										// \end same resource
										let accessable = propAccessable && userResources.includes(resource.resource_id);
										if (strict) {
											if (fused && "resource_ids" in order) {
												accessable = accessable && order.workers_id === user.getData().workers_id && order.resource_ids.indexOf(resourceInfo.resource_id) !== -1;
											} else {
												accessable = accessable && order.workers_id === user.getData().workers_id && order.resource_id === resourceInfo.resource_id;
											}
										}
										accessable = serviceInfo.released && accessable;
										
										const resWorkers = workload.filter(w => w.service_id === service.service_id && w.resource_id === resource.resource_id);
										const paused = !!serviceInfo.paused_by;
										const isWorking = currentWork && service.service_id === currentWork.service_id && order.order_id === currentWork.order_id;
										const activeWorkerCount = resWorkers.length - Number(isWorking);
										let workDesc = null;
										if (resWorkers.length) {
											workDesc = `(${resWorkers.length} Mitarbeiter)`;
											if (isWorking) {
												workDesc = `(Du)`;
												if (activeWorkerCount) {
													workDesc = `(Du und ${activeWorkerCount} Mitarbeiter)`;
												}
											}
										}
										return (
											<Table.Row  key={`${serve_id}-${res_id}`} className={cn("appointment-worker-service", {accessable})}>
												<Table.Cell><strong style={{whiteSpace: "nowrap"}}>{workDesc}</strong></Table.Cell>
												<Table.Cell>{resource.name}</Table.Cell>
												<Table.Cell>
													<Flex align={'flex-end'} valign={'center'} wrap gutterHeight={10} gutterWidth={30}>
														{falseNull(serviceInfo.finished_at) &&
															<FlexChild>
																<Loadable hoc={FlatButton} disabledOnLoading disabled={!accessable}  onClick={() => pauseService(serviceInfo.order_service_id, !serviceInfo.paused_by)}>
																	<span style={{paddingLeft: 5, paddingRight: 5, whiteSpace: "nowrap"}}>{serviceInfo.paused_by ? "Pause beenden (freigeben)" : "Pausieren"}</span>
																</Loadable>
															</FlexChild>
														}
														<FlexChild>
															<Loadable secondary hoc={FlatButton} disabledOnLoading disabled={!accessable || paused} onClick={onUpdateResourceState.bind(null, resourceInfo,  !Boolean(resourceInfo.finished_at))}>
																<span style={{paddingLeft: 5, paddingRight: 5, whiteSpace: "nowrap"}}>{resourceInfo.finished_at ? 'wieder öffnen' : 'Arbeit abschließen'}</span>
															</Loadable>
														</FlexChild>
														{falseNull(serviceInfo.finished_at) &&
															<FlexChild>
																<FlatButton primary onClick={join.bind(null, serviceInfo, resource, null, this.onConfirm)} disabled={!accessable || paused}>
																	<span style={{paddingLeft: 5, paddingRight: 5, whiteSpace: "nowrap"}}>Arbeit beginnen</span>
																</FlatButton>
															</FlexChild>
														}
													</Flex>
												</Table.Cell>
											</Table.Row>
										);
									})}
									
								</React.Fragment>
							);
						})}
					</Table.Body>
				</Table>
			{/*	<BasicDialog
					open={this.state.confirm}
					onClose={() => this.setState({confirm: false})}
					autoScrollBodyContent
					closeButton
					title={<Header as={"h3"} inverted><Icon size={"large"} color={"red"} name={"exclamation triangle"}/> Höher priorisierte Arbeiten übersprungen!</Header>}
				>
					<JoinWorkForced
						work={this.state.work}
						serviceMap={serviceMap}
						resourceMap={resourceMap}
						joinForced={joinForced}
						request={this.state.request}
					/>
				</BasicDialog>*/}
				{this.state.confirm && <Modal open centered={false} onClose={() => this.setState({confirm: false})}>
					<Modal.Header><Icon size={"large"} color={"red"} name={"exclamation triangle"}/> Höher priorisierte Arbeiten übersprungen!</Modal.Header>
					<Modal.Content>
						<JoinWorkForced
							work={this.state.work}
							serviceMap={serviceMap}
							resourceMap={resourceMap}
							joinForced={joinForced}
							request={this.state.request}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({confirm: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Segment>
		);
	}
}

AppointmentWorkers = withInit(AppointmentWorkers);
AppointmentWorkers = connect(
	(state, props) => ({
		workload: get(state, ['workers', 'work', props.order_id], []),
		// order: props.order || get(state, ['appointments', 'orders', props.order_id], {}),
		resourceMap: state.map.resources,
		serviceMap: state.map.services,
		userResources: get(state, 'user.resources', []),
		// services: state.appointments.services,
		// resources: state.appointments.resources,
		// order2services: state.appointments.order2services,
		// service2resources: state.appointments.service2resources,
		loading: isLoading(state, PROC_WORKER_FETCH_WORK, props.order_id),
		work_map: props.work_map || state.appointments.work_map,
		currentWork: state.user.work[0] || {}
	}),
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			// fetching all infos to order
			dispatch(workerAction__getWork(props.order_id));
		})),
		join: (order_service, resource, onSuccess, onConfirm) => dispatch(appointmentAction__joinWork(order_service.order_service_id || order_service, resource.resource_id || resource, result => {
			dispatch(addSnackbar('Arbeit beigetreten'));
			isFunction(onSuccess) && onSuccess(result);
		}, onConfirm)),
		joinForced: (data, onSuccess) => dispatch(appointmentAction__work({...data, force: true}, result => {
			dispatch(addSnackbar("Arbeit beigetreten"));
			isFunction(onSuccess) && onSuccess(result);
		})),
		changeState: (orderServiceResource, finished, onSuccess) => dispatch(appointmentAction__updateResourceState(orderServiceResource, finished, onSuccess)),
		onUpdateServiceState: (orderService, finished, onSuccess) => dispatch(appointmentAction__updateServiceState(orderService, finished, onSuccess)),
		onUpdateResourceState: (orderServiceResource, finished, onSuccess) => dispatch(appointmentAction__updateResourceState(orderServiceResource, finished, onSuccess)),
		pauseService: (id, pauseState, onSuccess) => dispatch(appointmentAction__pauseService(id, pauseState, result => {
			publish(SUB_PAUSE_APPOINTMENT, "service", id, pauseState, result);
			isFunction(onSuccess) && onSuccess(result);
		})),
		pauseResource: (id, pauseState, onSuccess) => dispatch(appointmentAction__pauseResource(id, pauseState, result => {
			publish(SUB_PAUSE_APPOINTMENT, "resource", id, pauseState, result);
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(AppointmentWorkers);
AppointmentWorkers = withRights(SECTION.APPOINTMENTS, AppointmentWorkers);
AppointmentWorkers = translate()(AppointmentWorkers);
export default AppointmentWorkers;