import * as React from 'react';
import PropTypes from 'prop-types';
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading, EasyFlex} from "../../partials/ActionHeader";
import {Button, Icon, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {orderAction__collect, orderAction__processable, orderAction__startProcessing, orderCall__collect, orderCall__processable, orderCall__startProcessing} from "../../../actions/orderActions";
import {deepMemoize as memoizeOne} from "../../../Logic/extensions";
import {Client, Order, OrderVehicle, Worker} from "../../../models";
import {TimeTrans, withRights} from "../../../Tools";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import ClientPopup from "../clients/ClientPopup";
import VehiclePopup from "../vehicles/VehiclePopup";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {isFunction} from 'lodash';
import {SECTION} from "../../../Logic/constants";
import {Trans, translate} from "react-i18next";
import {moment} from "../../../Logic/Moment";
import {PostProcessActiveView} from "./PostProcessActiveView";

const Header = (props) => {
	return <ActionHeader alignment={"space-between"}>
		<ActionHeaderGroup>
			<ActionBackButton loading={props.loading}/>
			<ActionHeading><Trans defaults={'Nachbearbeitung'}/></ActionHeading>
		</ActionHeaderGroup>
		<ActionHeaderGroup>
			<Button loading={props.loading} basic icon="history" onClick={props.onClick}/>
		</ActionHeaderGroup>
	</ActionHeader>;
};

const OrderClient = ({clientId}) => (
	<Client.Connected id={clientId}>
		{client =>
			<ClientPopup client={client.origin || client}><EasyFlex valign={EasyFlex.valign.CENTER}>
				<client.Icon style={{marginRight: 5}}/>
				{client.name}</EasyFlex></ClientPopup>
		}
	</Client.Connected>
);

const VehicleView = ({orderVehicle}) => (
	<VehiclePopup vehicle={orderVehicle}><span>{orderVehicle.name}</span></VehiclePopup>
);

const RegMarkView = ({orderVehicle}) => {
	const v = orderVehicle ? new OrderVehicle(orderVehicle) : null;
	if (!v) {
		return v;
	}
	return v.registration_shield;
}


const ActionView = ({order, disabled, loading, onClick}) => (
	order.post_processing ?
		<Button.Group basic><Button className="not-clickable" icon><Icon name={'cog'} loading style={{height: 'auto'}}/></Button>
			<Button className="not-clickable">
				<Worker.Provider workers_id={order.post_processing} placeholder={<Trans defaults={"In Bearbeitung"}/>}>{worker => worker.name}</Worker.Provider>
			</Button></Button.Group>
			:
			<Button basic disabled={disabled} loading={loading} onClick={onClick}><Trans defaults="Nachbearbeitung starten"/></Button>
);


Header.propTypes = {
	loading: PropTypes.bool,
	onClick: PropTypes.func
};

export class AssistOverview extends React.Component {
	static propTypes = {
		list: PropTypes.arrayOf(PropTypes.object),
		orderMap: PropTypes.object,
		orderVehicleMap: PropTypes.object,
		onStart: PropTypes.func,
		onLoad: PropTypes.func,
		onCollectOrders: PropTypes.func,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		orderMap: {},
		orderVehicleMap: {},
		onStart: orderCall__startProcessing,
		onLoad: orderCall__processable,
		onCollectOrders: ids => orderCall__collect(ids, true),
		onNotification: dispatchSnack
	};
	
	state = {
		show_order: null,
		loading: false,
		starting: 0
	};
	
	load = async (callback) => {
		const {onLoad, onNotification} = this.props;
		try {
			this.setState({loading: true});
			await onLoad();
			isFunction(callback) && callback();
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({loading: false});
		}
	}
	
	start = (order_id) => async () => {
		const {onStart, onNotification} = this.props;
		try {
			this.setState({starting: order_id});
			await onStart(order_id);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert');
		} finally {
			this.setState({starting: 0});
		}
	};
	
	showOrder = (show_order) => () => this.setState({show_order});
	
	fetchOrders = memoizeOne(
		list => this.props.onCollectOrders(list.map(o => o.order_id || o), true)
	);
	
	mapOrders = memoizeOne(
		(list, map) => list.map(o => map[o.order_id] || {...o, unmapped: true})
	);
	
	allLoaded = memoizeOne(
		list => !list.some(o => o.unmapped)
	);
	
	getOrderVehicle = memoizeOne(
		(order, orderVehicleMap) => orderVehicleMap[order.order_vehicle_id] || null
	);
	
	getWorkOrder = memoizeOne(
		(user, list) => list.find(o => o.post_processing === user.workers_id)
	);
	
	componentDidMount() {
		this.load(() => this.fetchOrders(this.props.list));
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.fetchOrders(this.props.list);
	}
	
	sorted = memoizeOne(
		list => list.sort((l, r) => {
			let lp = l.post_processing ? 1 : 0;
			let rp = r.post_processing ? 1 : 0;
			let test = rp - lp;
			if (!test) {
				return moment(l.fetch_point) < moment(r.fetch_point) ? -1 : 1;
			}
			return test;
		})
	);
	
	render() {
		const {show_order, loading, starting} = this.state;
		const {list, orderMap, orderVehicleMap, /*rights,*/ user} = this.props;
		const workOrder = this.getWorkOrder(user, list);
		if (workOrder) {
			return <div>
				<Header loading={loading} onClick={this.load}/>
				<Segment basic style={{paddingLeft: 0, paddingRight: 0}}>
					<PostProcessActiveView order={workOrder}/>
				</Segment>
			</div>
		}
		const orders = this.sorted(this.mapOrders(list, orderMap));
		// const allLoaded = this.allLoaded(orders);
		return (
			<div>
				<Header loading={loading} onClick={this.load}/>
				<Segment basic style={{paddingLeft: 0, paddingRight: 0}}>
					<Table basic={'very'} celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell><Trans defaults={"Kunde"}/></Table.HeaderCell>
								<Table.HeaderCell><Trans defaults={"Fahrzeug"}/></Table.HeaderCell>
								<Table.HeaderCell><Trans defaults={"Kennzeichen"}/></Table.HeaderCell>
								<Table.HeaderCell><Trans defaults={"Abgabe"}/></Table.HeaderCell>
								<Table.HeaderCell><Trans defaults={"Aktion"}/></Table.HeaderCell>
								<Table.HeaderCell>&nbsp;</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{orders.map(({order_id}) => <Order.Connected key={order_id} id={order_id}>{order => {
								const orderVehicle = this.getOrderVehicle(order, orderVehicleMap);
								return <Table.Row warning={!!order.post_processing}>
									{/* Order CLIENT */}
									<Table.Cell>
										<OrderClient clientId={orderVehicle.client_id}/>
									</Table.Cell>
									{/* Order Vehicle */}
									<Table.Cell>
										<VehicleView orderVehicle={orderVehicle}/>
									</Table.Cell>
									<Table.Cell>
										<RegMarkView orderVehicle={orderVehicle}/>
									</Table.Cell>
									{/* Affirmation DATE */}
									<Table.Cell>
										<div><TimeTrans value={order.fetch_moment.toDate()} type={'full'}/></div>
									</Table.Cell>
									{/* Action BUTTON */}
									<Table.Cell>
										<ActionView
											order={order}
											disabled={Boolean(loading || starting)}
											loading={starting === order.order_id}
											onClick={this.start(order.order_id)}
										/>
									</Table.Cell>
									{/* Order Detail BUTTON */}
									<Table.Cell textAlign={'right'}>
										<Button icon="list" basic onClick={this.showOrder(order.order_id)}></Button>
									</Table.Cell>
								</Table.Row>
							}
							}</Order.Connected>)}
						</Table.Body>
					</Table>
				</Segment>
				<OrderViewDialog onClose={this.showOrder(null)} open={!!show_order}>
					{show_order && <OrderQuickView order_id={show_order} onRequestClose={this.showOrder(null)}/>}
				</OrderViewDialog>
			</div>
		);
	}
}

AssistOverview = withRights(SECTION.ASSISTANCE, AssistOverview);
AssistOverview = connect(
	state => ({
		list: state.orders.processable,
		orderMap: state.map.orders,
		orderVehicleMap: state.map.order2vehicles
	}),
	dispatch => ({
		onStart: (order_id) => dispatch(orderAction__startProcessing(order_id, true)),
		onLoad: () => dispatch(orderAction__processable(true)),
		onCollectOrders: (ids) => dispatch(orderAction__collect(ids, true))
	})
)(AssistOverview);
AssistOverview = translate()(AssistOverview);