import {publish} from "../Logic/PubSub";
import {SUB_DAY_SCHEDULE, SUB_ORDER_DELETE, SUB_WHEEL, SUB_WHEEL_DELETE} from "./index";
import {orderState__delete, orderState__processable, orderState__removeConflicts, orderState__removeReleaseIDs, orderState__setConflicts, orderState__setReleaseIDs, orderState__update} from "./orderActions";
import {workerState__addIDs, workerState__removeConsultantSchedule, workerState__removeIDs, workerState__removeWorking, workerState__setConsultantSchedule, workerState__setWorkHistory, workerState__updateWorking} from "./workerActions";
import {appointmentState__updateSSE} from "./appointmentActions";
import {requestLoanCarScheduleEventRemovement} from "../cointainer/intern/vehicles";
import {loanCarState__addSchedule, loanCarState__removeSchedule} from "./loanCarActions";
import {kfzState__removeService, kfzState__updateService} from "./kfzActions";
import {isArray, get} from 'lodash'
import {
	mapDispatch,
	mapState__clients_add,
	mapState__orderResources_add,
	mapState__orderResources_remove,
	mapState__orders_add,
	mapState__orders_remove,
	mapState__orderServices_add,
	mapState__orderServices_remove,
	mapState__orderVehicles__add,
	mapState__releases_add,
	mapState__services_add,
	mapState__services_remove,
	mapState__vehicles_add,
	mapState__wheels_add,
	mapState__wheels_remove,
	mapState__workers_add,
	mapState__workers_remove
} from "./mapActions";
import {runLogout, userAction__version} from "./userActions";
import {moduleState__set} from "./moduleActions";
import {houseState__setOwn} from "./carhouseActions";
import {resetAction} from "./types";

export const dataHandler = (e, dispatch, house) => {
	if ( e.data ) {
		try {
			// return console.log('SSE: ', e.data);
			const {
				action,
				payload,
				// exclude,
				// source
			} = e.data;
			// console.debug('SSE:', action, source, payload);
			switch(action) {
				case 'order-create':
					dispatch(orderState__update(payload));
					mapDispatch(dispatch, payload);
					break;
				case 'order-update':
					dispatch(orderState__update(payload));
					dispatch(appointmentState__updateSSE(payload));
					mapDispatch(dispatch, payload);
					break;
				case 'order-day-create':
					publish(SUB_DAY_SCHEDULE, payload);
					break;
				case 'order-delete':
					publish(SUB_ORDER_DELETE, payload.order_id);
					dispatch(orderState__delete(payload.order_id));
					dispatch(mapState__orders_remove(payload.order_id));
					break;
				case 'conflicts':
					dispatch(orderState__setConflicts(payload));
					break;
				case 'conflicts-resolve':
					dispatch(orderState__removeConflicts(payload));
					break;
				case 'order':
					dispatch(mapState__orders_add(payload));
					break;
				case 'order-service':
					dispatch(mapState__orderServices_add(payload));
					break;
				case 'order-service-delete':
					dispatch(mapState__orderServices_remove(payload));
					break;
				case 'order-resource':
					dispatch(mapState__orderResources_add(payload));
					break;
				case 'order-resource-delete':
					dispatch(mapState__orderResources_remove(payload));
					break;
				case 'client':
					dispatch(mapState__clients_add(payload));
					break;
				case 'vehicle':
					dispatch(mapState__vehicles_add(payload));
					break;
				case 'vehicle-transfer':
					// todo: vehicle-transfer, with params:
					// vehicle_id (changed vehicle)
					// from_client_id
					// to_client_id
					break;
				case 'order_vehicle':
					dispatch(mapState__orderVehicles__add(payload));
					break;
				case 'worker':
					dispatch(mapState__workers_add(payload));
					break;
				case 'worker-ids':
					dispatch(workerState__addIDs(payload));
					break;
				case 'worker-delete':
					dispatch(workerState__removeIDs(payload));
					dispatch(mapState__workers_remove(payload));
					break;
				case 'work-join':
					dispatch(workerState__updateWorking(payload));
					break;
				case 'work-leave':
					dispatch(workerState__removeWorking(payload));
					break;
				case 'work-summary':
					// "work" in payload && dispatch(workerState__setWork(payload.work));
					"work_history" in payload && dispatch(workerState__setWorkHistory(payload.work_history));
					break;
				case 'loan-car-event':
					dispatch(loanCarState__addSchedule(payload));
					break;
				case 'loan-car-event-remove':
					const loan_car_event = payload.loan_car_schedule_id || payload;
					requestLoanCarScheduleEventRemovement(loan_car_event);
					dispatch(loanCarState__removeSchedule(loan_car_event));
					break;
				case 'service-update':
					dispatch(kfzState__updateService(payload));
					dispatch(mapState__services_add(payload));
					break;
				case 'service-delete':
					dispatch(kfzState__removeService(payload));
					dispatch(mapState__services_remove(payload));
					break;
				case 'order-affirmed':
					dispatch(orderState__processable(payload));
					break;
				case 'release-update':
					"services" in payload && dispatch(mapState__orderServices_add(payload.services));
					"releases" in payload && dispatch(mapState__releases_add(payload.releases));
					"data" in payload && dispatch(orderState__setReleaseIDs(payload.data));
					"cleared" in payload && payload.cleared.length && dispatch(orderState__removeReleaseIDs(payload.cleared));
					
					// dispatch(orderState__release(payload.orders));
					// dispatch(orderState__release_set(payload.releases));
					break;
				case 'consultant-schedule-list':
					dispatch(workerState__setConsultantSchedule(payload));
					break;
				case 'consultant-schedule-remove':
					dispatch(workerState__removeConsultantSchedule(payload));
					break;
				case 'modules':
					dispatch(moduleState__set(payload));
					break;
				case 'wheel-insert':
					dispatch(mapState__wheels_add(payload))
					publish(SUB_WHEEL, ...(isArray(payload) ? payload : [payload]))
					break
				case 'wheel-remove':
					dispatch(mapState__wheels_remove(payload))
					publish(SUB_WHEEL_DELETE, ...(isArray(payload) ? payload : [payload]))
					break
				case 'house-update':
					dispatch(houseState__setOwn(payload))
					break
				case 'user-online':
					payload && dispatch(mapState__workers_add(payload))
					break;
				case 'user-offline':
					payload && dispatch(mapState__workers_add(payload))
					break;
				case 'user-logout':
					const houseLink = get(payload, 'link', house.link)
					runLogout(dispatch, houseLink || null);
					dispatch(resetAction);
					break;
				case 'ping':
					// console.debug('Received a ping message...');
					break;
				case 'debug':
					console.debug('SSE_DEBUG:', payload);
					break;
				case 'version':
					// console.debug('SSE_VERSION:', payload);
					// noinspection JSIgnoredPromiseFromCall
					userAction__version();
					break;
				default:
					console.warn('SSE:', 'Unknow action has been skipped ~', action);
			}
		} catch (e) {
			console.error("SSE: Failed to parse data!", e);
		}
	}
};