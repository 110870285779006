import * as React from 'react';
import {StatyComponent} from "../../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {moment} from "../../../../Logic/Moment";
import {Checkbox, Divider, Form, Input, Message, Segment, TextArea} from "semantic-ui-react";
import {falseNull, trueNull} from "../../../../Logic/extensions";
import {NegativeSegment, PositiveSegment} from "../../../partials/Segments";
import {EasyFlex} from "../../../partials/ActionHeader";
import {RestrictedRangeSelector} from "./RestrictedRangeSelector";
import {LoanCarSelector} from "./LoanCarSelector";
import {translate} from "react-i18next";
import {ConsultantSelector} from "./ConsultantSelector";
import hash from 'object-hash';
import {get} from 'lodash';
import {scheduleCall__isDeliveryAt} from "../../../../actions/scheduleActions";
import {withHouse, withModule} from "../../../../Tools/RightsProvider";
import {WheelSelector} from "./WheelSelector";

export class NextAppointmentResult extends StatyComponent {
	static propTypes = {
		data: PropTypes.shape({
			order_ranges: PropTypes.shape({
				range_start: PropTypes.any.isRequired,
				range_end: PropTypes.any.isRequired
			})
		}),
		matchData: PropTypes.shape({
			result: PropTypes.shape({
				order_ranges: PropTypes.shape({
					range_start: PropTypes.any.isRequired,
					range_end: PropTypes.any.isRequired
				})
			}),
			version: PropTypes.number.isRequired,
			loanCarId: PropTypes.number,
			loanCarRange: PropTypes.shape({
				start: PropTypes.any.isRequired,
				end: PropTypes.any.isRequired
			}),
			consultantId: PropTypes.number,
			consultantRange: PropTypes.shape({
				start: PropTypes.any.isRequired,
				end: PropTypes.any.isRequired
			}),
			consultantDuration: PropTypes.number,
			searchRange: PropTypes.shape({
				start: PropTypes.any.isRequired,
				end: PropTypes.any.isRequired
			}),
			deliveryDuration: PropTypes.number,
			deliveryRange: PropTypes.shape({
				start: PropTypes.any.isRequired,
				end: PropTypes.any.isRequired
			})
		}),
		km: PropTypes.number,
		defaultStart: PropTypes.instanceOf(Date),
		defaultEnd: PropTypes.instanceOf(Date),
		onChange: PropTypes.func.isRequired,
		onSelectLoanCar: PropTypes.func.isRequired,
		onSelectConsultant: PropTypes.func.isRequired,
		loanCar: PropTypes.bool,
		directService: PropTypes.bool,
		deliveryService: PropTypes.bool,
		onUpdateKm: PropTypes.func,
		onUpdateInfo: PropTypes.func,
		children: PropTypes.node,
		onUpdateReleasable: PropTypes.func.isRequired,
		onCheckDelivery: PropTypes.func,
		info: PropTypes.string,
		wheelService: PropTypes.bool,
		vehicleId: PropTypes.number,
		onSelectWheel: PropTypes.func,
		wheel: PropTypes.object,
		clientId: PropTypes.number,
		importValue: PropTypes.string
	};
	static defaultProps = {
		defaultStart: null,
		defaultEnd: null,
		km: 0,
		info: '',
		importValue: '',
		onCheckDelivery: (date) => scheduleCall__isDeliveryAt(date)
	};
	
	state = {
		start_point: null,
		end_point: null,
		valid: null,
		checking_delivery: false,
		delivery_valid: true,
		info: ''
	};
	
	get start_point() {
		return this.state.start_point || new Date(this.props.data.order_ranges.range_start);
	}
	
	get end_point() {
		return this.state.end_point || new Date(this.props.data.order_ranges.range_end);
	}
	
	get start_moment() {
		return moment(this.props.data.order_ranges.range_start);
	}
	
	get end_moment() {
		return moment(this.props.data.order_ranges.range_end);
	}
	
	get info() {
		return this.state.info || this.props.info;
	}
	
	get has_error() {
		if (!this.props.data.ack) return true;
		if (this.state.valid) {
			return !this.state.valid.valid;
		}
		return !this.state.delivery_valid;
	}
	
	update = (data, valid) => {
		this.setState({...data, valid}, () => {
			let push = valid && valid.valid ? data : null;
			if (data) {
				this.checkDelivery(success => this.props.onChange(success ? data : null));
			} else {
				this.props.onChange(push);
			}
		})
	};
	
	updateInfo = (e, {value}) => this.setState({info: value}, () => this.props.onUpdateInfo && this.props.onUpdateInfo(value));
	
	checkDelivery =  async (callback) => {
		const {onCheckDelivery, deliveryService} = this.props;
		if (!deliveryService) {
			return this.setState({delivery_valid:true}, () => callback(true));
		}
		try {
			this.setState({checking_delivery: true});
			const result = await onCheckDelivery(this.start_point);
			this.setState({delivery_valid: result.ack});
			return callback(result.ack);
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({checking_delivery: false});
		}
	};
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (hash(prevProps.data) !== hash(this.props.data)) {
			this.setState({start_point: null, end_point: null, valid: null});
		}
	}
	
	render() {
		let {data, matchData, directService, style, children, loanCar, onUpdateKm, km, vehicleId, onUpdateInfo, onUpdateReleasable, deliveryService, houseData, wheelService, modules, onSelectWheel} = this.props;
		if (!data) {
			return null;
		}
		const {
			valid,
			delivery_valid
		} = this.state;
		return <Segment.Group style={style}>
			{trueNull(data && data.ack) && <PositiveSegment>
				<p>
					Es wurde ein Termin von <strong>{this.start_moment.format('LLL [Uhr]')}</strong> bis <strong>{this.end_moment.format('LLL [Uhr]')}</strong> gefunden.<br/>
					Sie können nun die gewünschte An- und Abgabezeit im Rahmen des Termins anpassen.
				</p>
				{trueNull(modules.wheel && wheelService && houseData.wheel_preparation_duration) && <React.Fragment>
					<Divider/>
					<p>Durch die Option <strong>Reifenauslagerung</strong> ist der frühste Termin um <strong>{houseData.wheel_preparation_duration} Stunden</strong> verzögert.</p>
				</React.Fragment>}
			</PositiveSegment>}
			{trueNull(this.has_error) && <NegativeSegment>
				{trueNull( !valid || !data.ack) && <p>Es wurde kein Termin gefunden!</p>}
				{trueNull(valid) && <div>
					{falseNull(valid.valid_start) && <p>
						Die <strong>gewünschte Annahmezeit</strong> ist nicht realisierbar. <br/>
						Wird bei zeitnahen Terminen zu lange gewartet, ist es möglich, dass eine neue Suche durchgeführt werden muss, um den Termin realisieren zu können.
					</p>}
					{falseNull(valid.valid_end) && <p>Die <strong>gewünschte Abgabezeit</strong> ist nicht realisierbar.</p>}
					
				</div>}
			</NegativeSegment>}
			{trueNull(deliveryService && !delivery_valid) && <NegativeSegment><p>Zur gewählten Startzeit ist kein Bring- & Abholservice verfügbar.</p></NegativeSegment>}
			{trueNull(data.ack && data.order_ranges) &&<Segment style={{paddingBottom: 30}}>
				<RestrictedRangeSelector
					rangeStart={new Date(data.order_ranges.range_start)}
					rangeEnd={new Date(data.order_ranges.range_end)}
					defaultStart={loanCar ? null : this.props.defaultStart}
					defaultEnd={loanCar ? null : this.props.defaultEnd}
					onChange={this.update}
					nowBuffer={900}
					strict={loanCar}
				/>
			</Segment>}
			{data.ack && loanCar &&
			<Segment>
				<LoanCarSelector
					preSelection={matchData.loanCarId}
					searchOnMount
					rangeStart={this.start_point}
					rangeEnd={this.end_point}
					list={data.vehicles}
					onSelect={this.props.onSelectLoanCar}
				/>
			</Segment>
			}
			{data.ack && directService &&
				<Segment>
					<ConsultantSelector
						onSelect={this.props.onSelectConsultant}
						preSelection={get(matchData, 'consultantId', null)}
						startPoint={this.start_point}
						minutes={get(matchData, 'consultantDuration', null)}
					/>
				</Segment>
			}
			{data.ack && wheelService &&
				<Segment padded={'very'}>
					{onSelectWheel ? <WheelSelector
						importValue={this.props.importValue || ''}
						wheel={this.props.wheel}
						clientId={this.props.clientId}
						vehicleId={vehicleId || matchData.result.request.vehicle_id}
						onSelect={(wheel, comment) => onSelectWheel && onSelectWheel(wheel, comment)}
					/> : <div>
						<Message info>
							<p style={{textAlign: 'center'}}>
								Ein Reifensatz ist hinterlegt. Somit wird auch der Auslagerungszeitpunkt verschoben.
								Unter gewissen Umständen, kann dies zeitlich nicht mehr möglich sein.
								<Divider as={'span'} style={{display: 'block'}}/>
								Wird eine Verschiebung der Reifenauslagerung nicht mehr benötigt, deaktivieren Sie die Reifenauslagerungsoption.
								
							</p>
						</Message>
					</div>}
				</Segment>
			}
			{onUpdateInfo && <Segment style={{padding: 0}}>
				<Form>
					<TextArea style={{border: 'none'}} value={this.info} placeholder={'Informationen zum Auftrag'} onChange={this.updateInfo}/>
				</Form>
			</Segment>}
			<Segment>
				{/*{onUpdateKm && <EasyFlex align={EasyFlex.align.CENTER} wrap>*/}
				{/*	<span style={{margin: 5}}>*/}
				{/*		<label style={{marginRight: 10}}>Laufleistung aktualisieren</label>*/}
				{/*		<Input type={'number'} min={0} step={1} defaultValue={km} onChange={(e, {value}) => onUpdateKm(value)} />*/}
				{/*	</span>*/}
				{/*</EasyFlex>}*/}
				<EasyFlex align={EasyFlex.align.SPACE_AROUND} wrap>
					{onUpdateKm && <span style={{margin: 5}}>
						<label style={{marginRight: 10}}>Laufleistung aktualisieren (km)</label>
						<Input type={'number'} min={0} step={1} defaultValue={km} onChange={(e, {value}) => onUpdateKm(value)} />
					</span>}
					<EasyFlex valign={EasyFlex.valign.CENTER} style={{margin: 5}}>
						<label style={{marginRight: 10}}>Auftrag direkt freigeben</label>
						<Checkbox toggle defaultChecked={false} onChange={(e, {checked}) => onUpdateReleasable(checked)} />
					</EasyFlex>
				</EasyFlex>
			</Segment>
			
			{children}
		</Segment.Group>;
	}
}

NextAppointmentResult = withModule(NextAppointmentResult)
NextAppointmentResult = withHouse(NextAppointmentResult)
NextAppointmentResult = translate()(NextAppointmentResult);