/////////////
//      Quick Info
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Image, Modal, Segment, Statistic, Table} from "semantic-ui-react";
import {COLORS, SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../../components/NoAccess";
import {EasyFlex} from "../../../../components/partials/ActionHeader";
import nl2br from "react-nl2br";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {get, isEmpty, isFunction} from "lodash";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_ORDER, PROC_ORDER_DELETE, PROC_ORDER_DELIVER_STATE, PROC_ORDER_FETCH_STATE} from "../../../../actions";
import {isOnline} from "../../../../actions/userActions";
import {
	orderAction__affirm,
	orderAction__delete,
	orderAction__delivered,
	orderAction__fetched,
	orderAction__fetchOne,
	orderAction__postProcessed,
	orderAction__removeConsultant,
	orderAction__updateConsultant,
	orderAction__updateDeliveryService,
	orderAction__updateInfo,
	orderAction__updateWaitingService,
	orderCall__affirm,
	orderCall__postProcessed,
	orderCall__removeConsultant,
	orderCall__updateConsultant,
	orderCall__updateDeliveryService,
	orderCall__updateWaitingService
} from "../../../../actions/orderActions";
import {addSnackbar, dispatchSnack} from "../../../../actions/snackbarActions";
import {TimeTrans, withRights} from "../../../../Tools";
import {ConnectedOrderInfoUpdateView} from "./OrderInfoUpdate";
import {OrderStateProvider} from "../../../../Logic/OrderStateProvider";
import {Order, Worker} from "../../../../models";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../../components/partials";
import {OrderQuickEdit} from "./OrderQuickEdit";
import {deepMemoize as memoizeOne, trueNull} from "../../../../Logic/extensions";
import {ConsultantOrderSelector} from "../../../../components/intern/orders/finder/ConsultantSelector";
import cn from 'classnames';
import {Trans, translate} from "react-i18next";
import {ConnectedEditFetchPoint} from "./EditFetchPoint";
import {withModule} from "../../../../Tools/RightsProvider";


const ConsultantHandle = ({order, mayChange, readOnly, removing_consultant, removeConsultant, showConsultants}) => {
	const editable = !readOnly && mayChange;
	
	return order.consultant_id ?
		<Worker.Provider workers_id={order.consultant_id}>{w =>
			<EasyFlex valign={EasyFlex.valign.CENTER}>
				<div style={{marginRight: 10}}>
						<span>
							<span><Image src={w.avatar} avatar/></span>
							<span> {w.name} </span>
							<span><Trans defaults={"für {count, plural, one{eine Minute} other{# Minuten}}"} count={order.consultant_duration}/></span>
						</span>
				</div>
				{/*<Button disabled negative icon={'close'}/>*/}
				{editable &&
				<EasyFlex valign={EasyFlex.valign.CENTER}>
					<ConfirmButton basic icon content={<Icon name={'trash'}/>}>
						<ConfirmButtonConsumer>{confirm =>
							<Button.Group>
								<Button basic color={'grey'} icon disabled={removing_consultant} onClick={e => {
									// noinspection JSUnresolvedFunction
									e && e.stopPropagation();
									confirm(false);
								}}><Icon name={'close'}/></Button>
								<Button.Or/>
								<Button loading={removing_consultant} color={'red'} onClick={removeConsultant(order.order_id)} icon={'trash'}/>
							</Button.Group>
						}</ConfirmButtonConsumer>
					</ConfirmButton>
				</EasyFlex>
				}
			</EasyFlex>
		}</Worker.Provider> :
		<Button animated basic className={cn({'not-clickable': !editable})} onClick={showConsultants(true)}>
			<Button.Content visible><Trans defaults={"Nicht zugewiesen"}/></Button.Content>
			<Button.Content hidden><Trans defaults={"Auswählen"}/> <Icon name={'hand pointer'}/></Button.Content>
		</Button>
}

const DeliveryHandle = ({order, mayChange, readOnly, updating_delivery, updateDeliverService, t}) => {
	const editable = mayChange && !readOnly;
	return <React.Fragment><Button
		icon
		style={{pointerEvents: 'none'}}
		attached={editable ? 'left' : null}
		positive={order.delivery_service}
		negative={!order.delivery_service}
	>{order.delivery_service ? <Trans defaults={'Erwünscht'}/> : <Trans defaults={'Unerwünscht'}/>} <Icon name={order.delivery_service ? 'check' : 'close'}/></Button>
		{editable && <ConfirmButton color={'black'} attached={'right'} icon content={<Icon name={'hand point up'}/>}>
			<ConfirmButtonConsumer>{confirm =>
				<Button.Group attached={'left'}>
					<Button disabled={updating_delivery} icon onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={'close'}/></Button>
					<Button.Or/>
					<Button loading={updating_delivery} color={'black'} onClick={updateDeliverService(order.order_id, !order.delivery_service, () => confirm(false))}>{order.delivery_service ? t('...', 'abmelden') : t('...', 'anmelden')}</Button>
				</Button.Group>
			}</ConfirmButtonConsumer>
		</ConfirmButton>}
	</React.Fragment>
};

const WaitingHandle = ({order, mayChange, readOnly, updating_waiting, updateWaitingService, t}) => {
	const editable = mayChange && !readOnly;
	return <React.Fragment>
		<Button
			icon
			style={{pointerEvents: 'none'}}
			attached={editable ? 'left' : null}
			positive={order.waiting}
			negative={!order.waiting}
		>{order.waiting ? t('...', 'Ja') : t('...', 'Nein')} <Icon name={order.waiting ? 'check' : 'close'}/></Button>
		{editable && <ConfirmButton color={'black'} attached={'right'} icon content={<Icon name={'hand point up'}/>}>
			<ConfirmButtonConsumer>{confirm =>
				<Button.Group attached={'left'}>
					<Button disabled={updating_waiting} icon onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={'close'}/></Button>
					<Button.Or/>
					<Button loading={updating_waiting} color={'black'} onClick={updateWaitingService(order.order_id, !order.waiting, () => confirm(false))}>{order.waiting ? t('...', 'abmelden') : t('...', 'anmelden')}</Button>
				</Button.Group>
			}</ConfirmButtonConsumer>
		</ConfirmButton>}
	</React.Fragment>
}

const TakeInHandle = ({order, mayChange, readOnly, isDelivering, updateDelivered, t}) => {
	const editable = mayChange && !readOnly;
	return editable ? <ConfirmButton negative={!!order.delivered_at} positive={!order.delivered_at} content={!order.delivered_at ? t('...', "Fahrzeug annehmen") : t('...', 'Annahme rückgängig machen')}>
			<ConfirmButtonConsumer>{confirm =>
				<Button.Group>
					<Button disabled={isDelivering} onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={"close"}/><Trans defaults={"Abbrechen"}/></Button>
					<Button.Or/>
					<Button loading={isDelivering} negative={!!order.delivered_at} positive={!order.delivered_at}
					        onClick={() => updateDelivered(order.origin, !order.delivered_at, confirm.bind(null, false))}>{order.delivered_at ? t('...', 'Zurückziehen') : t('...', 'Annehmen')}</Button>
				</Button.Group>
			}</ConfirmButtonConsumer>
		</ConfirmButton> : <Button className="not-clickable" basic color={order.delivered_at ? 'green' : 'red'}>{order.delivered_at ? t('...', 'Fahrzeug wurde angenommen') : t('...', 'Annahme ausstehend')}</Button>
};

const TakeOutHandle = ({order, mayChange, readOnly, isFetching, updateFetched, t}) => {
	const editable = mayChange && !readOnly;
	return editable ? <ConfirmButton negative={!!order.fetched_at} positive={!order.fetched_at} content={!order.fetched_at ? t('...', "Fahrzeug abgeben") : t('...', 'Abgabe rückgängig machen')}>
			<ConfirmButtonConsumer>{confirm =>
				<Button.Group>
					<Button disabled={isFetching} onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={"close"}/><Trans defaults={"Abbrechen"}/></Button>
					<Button.Or/>
					<Button loading={isFetching} negative={!!order.fetched_at} positive={!order.fetched_at} onClick={() => updateFetched(order.origin, !order.fetched_at, confirm.bind(null, false))}>{order.fetched_at ? t('...', 'Zurückziehen') : t('...', 'Abgeben')}</Button>
				</Button.Group>
			}</ConfirmButtonConsumer>
		</ConfirmButton> : <Button basic color={order.fetched_at ? 'green' : 'red'} className={'not-clickable'}>{order.fetched_at ? t('...', 'Fahrzeug wurde abgegeben') : t('...', 'Abgabe ausstehend')}</Button>
};

const AffirmationHandle = ({order, mayChange, readOnly, updating_affirmation, updateAffirmationState, mayReset, t}) => {
	const editable = mayChange && !readOnly;
	const showButton = mayReset || !order.affirmed_at;
	return editable && showButton ? <ConfirmButton wrapperProps={{basic: false, className: 'extended'}} as={Button.Group} icon color="black" content={<Icon name={'hand point up'}/>} before={order.affirmed_by ?
			<Worker.Provider workers_id={order.affirmed_by} placeholder={<Button color={'green'} style={{pointerEvents: 'none', marginLeft: 5}}>Bestätigt</Button>}>{worker => <Button color={'green'} style={{
				pointerEvents: 'none',
				marginLeft: 5
			}}>{worker.name}</Button>}</Worker.Provider> : <Button  color={'red'} style={{pointerEvents: 'none', marginLeft: 5}}><Trans defaults={"Ausstehend"}/></Button>}>
			<ConfirmButtonConsumer>{confirm =>
				<React.Fragment>
					<Button icon onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={'close'}/></Button>
					<Button loading={updating_affirmation} color={'black'}
					        onClick={updateAffirmationState(order.order_id, !order.affirmed_at, () => confirm(false))}>{order.affirmed_at ? t('...', 'zurückziehen') : t('...', 'bestätigen')}</Button>
				</React.Fragment>
			}</ConfirmButtonConsumer>
		</ConfirmButton> : (order.affirmed_by ?
			<Worker.Provider workers_id={order.affirmed_by} placeholder={<Button color={'green'} basic className="not-clickable"><Trans defaults={"Bestätigt"}/></Button>}>{worker => <Button basic color={'green'} className="not-clickable">{worker.name}</Button>}</Worker.Provider> :
			<Button basic color={'red'} className="not-clickable"><Trans defaults={"Ausstehend"}/></Button>)
};

const PostProcessHandle = ({order, mayChange, readOnly, udpating_post_process, updatePostProcessState, t}) => {
	const editable = mayChange && !readOnly;
	return editable ? <ConfirmButton wrapperProps={{basic: false, className: 'extended'}} as={Button.Group} icon color={'black'} content={<Icon name={'hand point up'}/>} before={order.post_processed_by ?
			<Worker.Provider workers_id={order.post_processed_by} placeholder={<Button color={'green'} style={{pointerEvents: 'none', marginLeft: 5}}><Trans defaults={"Nachbearbeitet"}/></Button>}>{worker => <Button color={'green'} style={{
				pointerEvents: 'none',
				marginLeft: 5
			}}>{worker.name}</Button>}</Worker.Provider> : <Button color={'red'} style={{pointerEvents: 'none', marginLeft: 5}}><Trans defaults={"Ausstehend"}/></Button>}>
			<ConfirmButtonConsumer>{confirm =>
				<React.Fragment>
					<Button icon onClick={e => {
						// noinspection JSUnresolvedFunction
						e && e.stopPropagation();
						confirm(false);
					}}><Icon name={'close'}/></Button>
					<Button loading={udpating_post_process} color={'black'}
					        onClick={updatePostProcessState(order.order_id, !order.post_processed_at, () => confirm(false))}>{order.post_processed_at ? t('...', 'zurückziehen') : t('...', 'bestätigen')}</Button>
				</React.Fragment>
			}</ConfirmButtonConsumer>
		</ConfirmButton> : (order.post_processed_by ?
			<Worker.Provider workers_id={order.post_processed_by} placeholder={<Button color={'green'} basic className="not-clickable"><Trans defaults={"Nachbearbeitet"}/></Button>}>{worker => <Button basic color={'green'} className="not-clickable">{worker.name}</Button>}</Worker.Provider> :
			<Button basic color={'red'} className="not-clickable"><Trans defaults={"Ausstehend"}/></Button>)
};

const DateComp = ({date, style, children, ...props}) => (
	<small style={{display: 'block', marginLeft: 16, ...style}} {...props}><TimeTrans type={'full'} value={date}/></small>
);

// noinspection JSUnresolvedFunction
export class OrderQuickInfo extends React.PureComponent {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		loading: PropTypes.bool,
		order2services: PropTypes.object,
		updateReleased: PropTypes.func,
		updateDelivered: PropTypes.func,
		updateFetched: PropTypes.func,
		updateInfo: PropTypes.func,
		rights: PropTypes.object,
		allRights: PropTypes.object,
		deleting: PropTypes.bool,
		onOpened: PropTypes.func,
		iconSize: PropTypes.number,
		iconStyle: PropTypes.object,
		dateFormat: PropTypes.string,
		isDelivering: PropTypes.bool,
		isFetching: PropTypes.bool,
		onUpdateDeliveryService: PropTypes.func,
		onUpdateWaitingService: PropTypes.func,
		onNotificationGood: PropTypes.func,
		onNotificationBad: PropTypes.func,
		onRemoveConsultant: PropTypes.func,
		onUpdateConsultant: PropTypes.func,
		onUpdateAffirmation: PropTypes.func,
		readOnly: PropTypes.bool
	};
	static defaultProps = {
		order: {},
		loading: false,
		deleting: false,
		order2services: [],
		iconSize: 32,
		iconStyle: {},
		dateFormat: "L LT",
		onOpened: () => {
		},
		isDelivering: false,
		isFetching: false,
		onUpdateDeliveryService: (order_id, delivery_service) => orderCall__updateDeliveryService(order_id, delivery_service),
		onUpdateWaitingService: (order_id, waiting) => orderCall__updateWaitingService(order_id, waiting),
		onNotificationGood: (message) => dispatchSnack(message),
		onNotificationBad: message => dispatchSnack(message, 'alert'),
		onRemoveConsultant: orderCall__removeConsultant,
		onUpdateConsultant: orderCall__updateConsultant,
		onUpdateAffirmation: orderCall__affirm,
		onUpdatePostProcess: orderCall__postProcessed
	};
	state = {
		removing_consultant: false,
		updating_consultant: false,
		info_update_dialog: false,
		releaseView: null,
		updating_delivery: false,
		updating_waiting: false,
		updating_affirmation: false,
		udpating_post_process: false,
		show_consultants: false,
		selected_consultant: null,
		edit_fetchpoint: false,
		buttonPortal: null,
		infoEditButtonPortal: null
	};
	
	showConsultants = (show_consultants) => () => this.setState({show_consultants});
	selectConsultant = selected_consultant => () => this.setState({selected_consultant});
	editFetchpoint = (edit_fetchpoint) => () => this.setState({edit_fetchpoint});
	
	updateDeliverService = (order_id, delivery_service, callback) => async () => {
		const {onNotificationGood, onNotificationBad, onUpdateDeliveryService, t} = this.props;
		try {
			this.setState({updating_delivery: true});
			await onUpdateDeliveryService(order_id, delivery_service);
			onNotificationGood(t('...', 'Bring- und Abholservicestatus aktualisiert'));
		} catch (e) {
			console.error(e);
			onNotificationBad(e.message);
		} finally {
			this.setState({updating_delivery: false});
			callback && callback();
		}
	}
	
	updateWaitingService = (order_id, waiting, callback) => async () => {
		const {onNotificationGood, onUpdateWaitingService, t} = this.props;
		try {
			this.setState({updating_waiting: true});
			await onUpdateWaitingService(order_id, waiting);
			onNotificationGood(t('...', 'Wartekunde-Status wurde aktualisiert'));
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({updating_waiting: false});
			callback && callback();
		}
	};
	
	updateAffirmationState = (order_id, state, callback) => async () => {
		const {onNotificationGood, onUpdateAffirmation, onNotificationBad, t} = this.props;
		try {
			this.setState({updating_affirmation: true});
			await onUpdateAffirmation(order_id, state);
			onNotificationGood(t('...', 'Abschlussstatus wurde aktualisiert'));
		} catch (e) {
			console.error(e);
			onNotificationBad(e.message);
		} finally {
			this.setState({updating_affirmation: false});
			callback && callback();
		}
	};
	
	updatePostProcessState = (order_id, state, callback) => async () => {
		const {onNotificationGood, onNotificationBad, onUpdatePostProcess, t} = this.props;
		try {
			this.setState({updating_post_process: true});
			await onUpdatePostProcess(order_id, state);
			onNotificationGood(t('...', 'Nachbearbeitungsstatus wurde aktualisiert'));
		} catch (e) {
			console.error(e);
			onNotificationBad(e.message);
		} finally {
			this.setState({updating_post_process: false});
			callback && callback();
		}
	};
	
	removeConsultant = orderId => async () => {
		const {onNotificationBad, onRemoveConsultant} = this.props;
		try {
			this.setState({removing_consultant: true});
			await onRemoveConsultant(orderId);
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error || e.message);
		} finally {
			this.setState({removing_consultant: false});
		}
	}
	
	updateConsultant = (orderId) => async () => {
		const {onNotificationBad, onUpdateConsultant} = this.props;
		if (!this.state.selected_consultant) {
			return;
		}
		try {
			this.setState({updating_consultant: true});
			await onUpdateConsultant(orderId, this.state.selected_consultant);
			this.setState({selected_consultant: null, show_consultants: false});
		} catch (e) {
			console.error(e);
			onNotificationBad(e.error || e.message);
		} finally {
			this.setState({updating_consultant: false});
		}
	};
	
	showUpdateDialog = (open) => () => this.setState({info_update_dialog: open});
	// setReleaseView = (e) => this.setState({releaseView: e ? e.currentTarget : null});
	getServices = memoizeOne(
		(order_id, order2services) => {
			let list = [];
			for (const service of Object.values(order2services)) {
				if (order_id === service.order_id) {
					list.push(service);
				}
			}
			return list;
		}
	);
	
	render() {
		const {
			loading,
			order: orderData,
			order2services,
			// updateReleased,
			updateDelivered,
			updateFetched,
			rights,
			allRights,
			deleting,
			iconSize,
			iconStyle,
			dateFormat,
			isDelivering,
			isFetching,
			readOnly,
			t,
			modules
		} = this.props;
		
		if (!orderData || isEmpty(orderData)) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const order = new Order(orderData);
		
		const mayRead = rights.mayRead || allRights[SECTION.APPOINTMENTS].mayRead;
		if (!mayRead) {
			return <NoAccess/>;
		}
		const orderServices = this.getServices(order.order_id, order2services);
		
		// const mayRelease = allRights[SECTION.RELEASE].mayChange || rights.mayChange;
		const mayChange = rights.mayChange;
		const mayDelete = rights.mayDelete;
		
		const mayResetAffirmation = allRights[SECTION.ASSISTANCE].mayDelete;
		
		const {
			info_update_dialog,
			updating_delivery,
			updating_waiting,
			updating_affirmation,
			udpating_post_process,
			removing_consultant,
			show_consultants,
			selected_consultant,
			updating_consultant
			// releaseView
		} = this.state;
		
		return (
			<Segment basic loading={loading}>
				<Statistic.Group size={"mini"} style={{justifyContent: "center"}}>
					<Statistic>
						<Statistic.Label><Trans defaults={"Autragsnummer"}/></Statistic.Label>
						<Statistic.Value>{order.id}</Statistic.Value>
					</Statistic>
					<Statistic>
						<Statistic.Label><Trans defaults={"Serviceleistungen"}/></Statistic.Label>
						<Statistic.Value>{orderServices.length}</Statistic.Value>
					</Statistic>
				</Statistic.Group>
				<OrderStateProvider order={orderData}>{({Icon, color, state}) =>
					<div style={{background: "#505050", margin: "40px 0", paddingTop: 12, paddingBottom: 12}}>
						<EasyFlex wrap={EasyFlex.wrap.YES} direction={EasyFlex.direction.ROW} align={EasyFlex.align.SPACE_AROUND} valign={EasyFlex.valign.START}>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Delivered color={color.delivered} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.delivered ? <Trans defaults={'Angenommen'}/> : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Progress color={color.progress} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.progress ? (state.finished ? <Trans defaults={'Abgeschlossen'}/> : <Trans defaults={'In Bearbeitung'}/>) : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Finished color={color.finished} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.finished ? <Trans defaults={'Abgeschlossen'}/> : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>
							{modules.assist && <EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Affirmed color={color.affirmed} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.affirmed ? <Trans defaults={'Endkontrolle (bestätigt)'}/> : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>}
							{modules.assist && <EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Processed color={color.processed} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.processed ? <Trans defaults={'Rechnung fertig'}/> : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>}
							<EasyFlex direction={EasyFlex.direction.COLUMN} valign={EasyFlex.valign.CENTER} style={{padding: 10, color: COLORS.CONTRAST}}>
								<Icon.Fetched color={color.fetched} style={{width: iconSize, height: iconSize, marginBottom: 8, ...iconStyle}}/>
								<div style={{marginLeft: 12, marginRight: 12}}>{state.fetched ? <Trans defaults={'Abgegeben'}/> : <Trans defaults={'Ausstehend'}/>}</div>
							</EasyFlex>
						</EasyFlex>
					</div>
				}</OrderStateProvider>
				
				<Table padded basic={'very'} striped celled style={{margin: '30px 15px', width: 'calc(100% - 30px)'}}>
					<Table.Body>
						<Table.Row>
							<Table.Cell><Trans defaults={"Serviceberater"}/></Table.Cell>
							<Table.Cell>
								<ConsultantHandle
									order={order}
									mayChange={mayChange}
									removeConsultant={this.removeConsultant}
									showConsultants={this.showConsultants}
									removing_consultant={removing_consultant}
									readOnly={readOnly}
								/>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><Trans defaults={"Bring- und Abholservice"}/></Table.Cell>
							<Table.Cell>
								<DeliveryHandle
									t={t}
									order={order}
									readOnly={readOnly}
									mayChange={mayChange}
									updateDeliverService={this.updateDeliverService}
									updating_delivery={updating_delivery}
								/>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><Trans defaults={"Wartekunde"}/></Table.Cell>
							<Table.Cell>
								<WaitingHandle
									t={t}
									order={order}
									readOnly={readOnly}
									mayChange={mayChange}
									updating_waiting={updating_waiting}
									updateWaitingService={this.updateWaitingService}
								/>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><Trans defaults={"Erstelldatum"}/></Table.Cell>
							<Table.Cell>
								{order.workers_id && <Worker.Connected id={order.workers_id}>{worker => <Button basic style={{pointerEvents: 'none', marginLeft: 5}}>{worker.name}</Button>}</Worker.Connected>}
								<DateComp date={new Date(order.created_at)}/>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><Trans defaults={"Erwartete Annahme"}/></Table.Cell>
							<Table.Cell>{order.deliver_moment.format(dateFormat)}</Table.Cell>
						</Table.Row>
						{order.delivered_at && <Table.Row>
							<Table.Cell><Trans defaults={"Annahme erfolgt"}/></Table.Cell>
							<Table.Cell>{order.delivered_moment.format(dateFormat)}</Table.Cell>
						</Table.Row>}
						{trueNull(mayChange) && <Table.Row>
							<Table.Cell><Trans defaults={"Fahrzeugannahme"}/></Table.Cell>
							<Table.Cell>
								<TakeInHandle
									t={t}
									order={order}
									mayChange={mayChange}
									readOnly={readOnly}
									isDelivering={isDelivering}
									updateDelivered={updateDelivered}
								/>
							</Table.Cell>
						</Table.Row>}
						{modules.assist && <Table.Row>
							<Table.Cell><Trans defaults={"Endkontrolle"}/></Table.Cell>
							<Table.Cell>
								<AffirmationHandle
									t={t}
									order={order}
									mayChange={mayChange}
									mayReset={mayResetAffirmation}
									readOnly={readOnly || !order.finished_at}
									updateAffirmationState={this.updateAffirmationState}
									updating_affirmation={updating_affirmation}
								/>
								{order.affirmed_at && <DateComp date={new Date(order.affirmed_at)}/>}
							</Table.Cell>
						</Table.Row>}
						{modules.assist && <Table.Row>
							<Table.Cell><Trans defaults={"Nachbearbeitung"}/></Table.Cell>
							<Table.Cell>
								<PostProcessHandle
									t={t}
									order={order}
									mayChange={mayChange}
									readOnly={readOnly || !order.affirmed_at}
									udpating_post_process={udpating_post_process}
									updatePostProcessState={this.updatePostProcessState}
								/>
								{order.post_processed_at && <DateComp date={new Date(order.post_processed_at)}/>}
							</Table.Cell>
						</Table.Row>}
						<Table.Row>
							<Table.Cell><Trans defaults={"Erwartete Abgabe"}/></Table.Cell>
							<Table.Cell>{order.fetch_moment.format(dateFormat)} {modules.assist && order.affirmed_at && !order.fetched_at && <Button basic circular icon={'pencil'} onClick={this.editFetchpoint(true)}/>}</Table.Cell>
							{/*<Table.Cell>{order.fetch_moment.subtract(user.is_engineer ? order.post_process_duration : 0, 'minute').format(dateFormat)}<Required color={'black'} visible={user.is_engineer}/></Table.Cell>*/}
						</Table.Row>
						{order.fetched_at && <Table.Row>
							<Table.Cell><Trans defaults="Abgabe erfolgt"/></Table.Cell>
							<Table.Cell>
								{order.fetched_moment.format(dateFormat)}
							</Table.Cell>
						</Table.Row>}
						{trueNull(mayChange) && <Table.Row>
							<Table.Cell><Trans defaults="Fahrzeugabgabe"/></Table.Cell>
							<Table.Cell>
								<TakeOutHandle
									t={t}
									order={order}
									readOnly={readOnly}
									mayChange={mayChange}
									isFetching={isFetching}
									updateFetched={updateFetched}
								/>
							</Table.Cell>
						</Table.Row>}
					</Table.Body>
				</Table>
				
				
				
				<fieldset style={{border: "1px solid #ccc", position: "relative"}}>
					<legend style={{padding: "0 10px", fontWeight: "bold"}}><Icon name={"info circle"} size={"large"}/></legend>
					{order.info && order.info.trim() ?
						<p style={{margin: 10}}>{nl2br(order.info)}</p> :
						<p style={{margin: 10, color: '#ccc'}}><Trans defaults="Keine Informationen vorhanden."/></p>
					}
					{mayChange && !readOnly && <Button circular basic color={'grey'} icon={'pencil'} className={'order-info-edit-button'} onClick={this.showUpdateDialog(true)}/>}
				</fieldset>
				{mayChange && !readOnly && <EasyFlex align={EasyFlex.align.SPACE_BETWEEN} style={{marginTop: 40}} wrap={EasyFlex.wrap.YES}>
					<OrderQuickEdit order={order.origin} onOpened={this.props.onOpened}/>
					<span>{mayDelete && <ConfirmButton negative content={t('...', "Auftrag löschen")}>
						<ConfirmButtonConsumer>{confirm =>
							<Button.Group>
								<Button disabled={deleting} onClick={e => {
									// noinspection JSUnresolvedFunction
									e && e.stopPropagation();
									confirm(false);
								}}><Icon name={"close"}/><Trans defaults="Abbrechen"/></Button>
								<Button.Or/>
								<Button loading={deleting} negative onClick={() => this.props.deleteOrder(order)}><Trans defaults="Löschen"/></Button>
							</Button.Group>
						}</ConfirmButtonConsumer>
					</ConfirmButton>}
					</span>
				</EasyFlex>}
				
				{/*<BasicDialog modal open={info_update_dialog} autoScrollBodyContent onClose={this.showUpdateDialog(false)} noPadding closeButton*/}
				{/*             renderActions={closeBtn => <Flex align={"space-between"} valign={"center"}>*/}
				{/*	             {closeBtn}*/}
				{/*	             <Button positive form={"order-update-info"}><Trans defaults="Speichern"/></Button>*/}
				{/*             </Flex>}>*/}
				{/*	{info_update_dialog && <ConnectedOrderInfoUpdateView order_id={order.order_id} info={order.info} id={"order-update-info"} onAfterUpdate={this.showUpdateDialog(false)}/>}*/}
				{/*</BasicDialog>*/}
				<Modal open={info_update_dialog}>
					<Modal.Header><Trans defaults={'Auftrafsinformationen editieren'}/></Modal.Header>
					<Modal.Content>
						{info_update_dialog && <ConnectedOrderInfoUpdateView order_id={order.order_id} info={order.info} id={"order-update-info"} onAfterUpdate={this.showUpdateDialog(false)} buttonPortal={this.state.infoEditButtonPortal}/>}
					</Modal.Content>
					<Modal.Actions>
						<EasyFlex align={EasyFlex.align.END} valign={EasyFlex.valign.CENTER} className={'actions-bottom-margin'}>
							<Button onClick={this.showUpdateDialog(false)}><Trans defaults={'Schließen'} i18nKey={'actions.close'}/></Button>
							<EasyFlex ref={r => this.setState({infoEditButtonPortal: r})}/>
						</EasyFlex>
					</Modal.Actions>
				</Modal>
				<Modal open={show_consultants} onClose={this.showConsultants(false)}>
					<Modal.Content>
						<ConsultantOrderSelector orderId={order.order_id} onSelect={consultant => {
							this.selectConsultant(consultant)();
						}} startPoint={order.deliver_moment.toDate()}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({show_consultants: false, selected_consultant: null})}><Trans defaults="Abbrechen"/></Button>
						<Button disabled={null === selected_consultant} loading={updating_consultant} positive onClick={this.updateConsultant(order.order_id)}><Trans defaults="Auswählen"/></Button>
					</Modal.Actions>
				</Modal>
				<Modal open={this.state.edit_fetchpoint} centered={false} size={'mini'}>
					<Modal.Header>Erwartete Abgabe anpassen</Modal.Header>
					<Modal.Content>
						{trueNull(this.state.edit_fetchpoint) && <ConnectedEditFetchPoint buttonPortal={this.state.buttonPortal} order={order} onDismiss={this.editFetchpoint(false)}/>}
					</Modal.Content>
					<Modal.Actions>
						<EasyFlex align={EasyFlex.align.END} className={'actions-bottom-margin'}>
							<Button onClick={this.editFetchpoint(false)}><Trans i18nKey={'actions.cancel'} defaults="Abbrechen"/></Button>
							<EasyFlex ref={r => this.setState({buttonPortal: r})}></EasyFlex>
						</EasyFlex>
					</Modal.Actions>
				</Modal>
			</Segment>
		);
	}
}
OrderQuickInfo = withModule(OrderQuickInfo);
OrderQuickInfo = withInit(OrderQuickInfo);
OrderQuickInfo = connect(
	(state, props) => {
		const orderID = props.order_id;
		const order = get(state.map.orders, [orderID], {});// get(state.appointments.orders, [orderID], {});
		const order2services = state.map.order2services;//get(state.appointments.order2services, [orderID], []);
		// let service2resources = [];
		// for (const id of order2services) {
		// 	service2resources.concat(get(state.appointments.service2resources, [id], []));
		// }
		return {
			loading: isLoading(state, PROC_ORDER, orderID),
			orders: state.appointments,
			order,
			order2services,
			// service2resources,
			deleting: isLoading(state, PROC_ORDER_DELETE),
			isDelivering: isLoading(state, PROC_ORDER_DELIVER_STATE, orderID),
			isFetching: isLoading(state, PROC_ORDER_FETCH_STATE, orderID)
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			if (props.rights.mayRead) {
				dispatch(orderAction__fetchOne(props.order_id));
			}
		})),
		updateReleased: () => {
		},
		updateDelivered: (order, state, onSuccess) => dispatch(orderAction__delivered(order, state, () => {
			isFunction(onSuccess) && onSuccess();
			dispatch(addSnackbar("Status: Geliefert geändert"));
		})),
		updateFetched: (order, state, onSuccess) => dispatch(orderAction__fetched(order, state, () => {
			isFunction(onSuccess) && onSuccess();
			dispatch(addSnackbar("Status: Abgeholt geändert"));
		})),
		deleteOrder: (order, onSuccess) => dispatch(orderAction__delete(order, result => {
			dispatch(addSnackbar("Auftrag wurde gelöscht!"));
			isFunction(onSuccess) && onSuccess(result);
		}, false)),
		updateInfo: (order_id, info, onSuccess) => dispatch(orderAction__updateInfo(order_id, info, result => {
			dispatch(addSnackbar('Infos wurden aktualisiert'));
			isFunction(onSuccess) && onSuccess(result);
		})),
		onUpdateDeliveryService: (order_id, delivery_service) => dispatch(orderAction__updateDeliveryService(order_id, delivery_service)),
		onUpdateWaitingService: (order_id, waiting) => dispatch(orderAction__updateWaitingService(order_id, waiting)),
		onRemoveConsultant: orderId => dispatch(orderAction__removeConsultant(orderId)),
		onUpdateConsultant: (orderId, consultantId) => dispatch(orderAction__updateConsultant(orderId, consultantId)),
		onUpdateAffirmation: (orderId, state) => dispatch(orderAction__affirm(orderId, state, true)),
		onUpdatePostProcess: (orderId, state) => dispatch(orderAction__postProcessed(orderId, state, true))
	})
)(OrderQuickInfo);
OrderQuickInfo = withRights(SECTION.ORDERS, OrderQuickInfo);
OrderQuickInfo = translate()(OrderQuickInfo);