import * as React from 'react';
import PropTypes from 'prop-types';
import Color from "color";
import {Icon, Label, Popup} from 'semantic-ui-react';
import {asFormLabel, asIconButton, asToggle, globals} from 'ah-tools';
import isFunc from 'lodash/isFunction';
import isString from 'lodash/isString';
import {FlatButton as MaterialFlatButton, IconButton, ListItem, SelectField, Toggle} from 'material-ui';
import {translate} from "react-i18next";
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import lodashValues from 'lodash/values';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import isArray from "lodash/isArray";
import get from "lodash/get";
import anim from "animated-scrollto";
import {moment} from "./Moment";
import cn from "classnames";
import {isEqual} from "lodash";
import {logo_service} from "./helper";
import memOne from 'memoize-one'
import {dequal} from 'dequal'

const {Provider: ScreenContextProvider, Consumer: ScreenReader} = React.createContext({width: 0, height: 0});

const lengthOfObject = function(obj) {
	let l = 0;
	for ( let k in obj ) {
		if ( obj.hasOwnProperty(k)) ++l;
	}
	return l;
};


const mapOfObject = function(obj, map) {
	let asArray = false;
	if (arguments.length > 2 ) {
		asArray = !!map;
		map = arguments[2];
	}
	let ret = asArray ? [] : {};
	for ( let k in obj ) {
		if ( !obj.hasOwnProperty(k)) continue;
		const result = map.call(obj, obj[k], k);
		if ( result !== undefined ) {
			if ( asArray ) {
				ret.push(result);
			} else {
				ret[k] = result;
			}
		}
	}
	return ret;
};

// noinspection JSUnusedGlobalSymbols
const filterOfObject = function(obj, filter) {
	const asArray = arguments.length > 2 ? !!filter : false;
	filter = arguments.length > 2 ? arguments[2] : filter;
	return mapOfObject(obj, asArray, (o, k) => {
		const yes = filter.call(this, o, k);
		return yes ? o : undefined;
	});
};

const filter_object = object => filter => {
	if ( !isObject(object) ) throw new Error('Parameter `object` is not an object');
	const filtered = {};
	// noinspection JSUnusedLocalSymbols
	filter = isFunc(filter) ? filter : ((a, b) => a);
	for (const i in object ) {
		if ( object.hasOwnProperty(i)) {
			if (Boolean(filter(object[i], i))) {
				filtered[i] = object[i];
			}
		}
	}
	return filtered;
};

const array_unique = (array = [], filter = null) => {
	const obj = {};
	return array.filter((value, index) => {
		value = filter ? filter(value, index) : value;
		if (!obj[value]) {
			obj[value]=value;
			return true;
		}
		return false;
	});
};

const array_offset = (array = [], offset = 0) => {
	let a = [];
	if (offset < 0) {
		offset = offset % array.length + array.length;
	}
	array.forEach((_, index) => a.push(array[(index+offset)%array.length]));
	return a;
};

const array_disjunct = (callable = null, ...arrays) => {
	const obj = {};
	arrays.forEach(arr => {
		arr.forEach(value => {
			const index = callable ? callable.call(null, value) : value;
			if ( obj[index] ) {
				obj[index].count++;
			} else {
				obj[index] = {value, count: 1};
			}
		})
	});
	return Object.keys(obj).filter(index => obj[index].count === 1).map(index => obj[index].value);
};

const blackOrWhite = (backgroundColor) =>
	new Color(backgroundColor).luminosity() <= 0.5 ? 'white' : 'black';

window.bow = blackOrWhite;

// noinspection JSUnusedGlobalSymbols
const darkenOrLighten = (color, how, lumen = 0.5) => {
	const c = new Color(color);
	if (c.luminosity() <= lumen) {
		return c.darken(how);
	}
	return c.lighten(how);
};

const _empty = (obj) => {
	if (typeof obj !== 'object') return true;
	for( const k in obj ) {
		// noinspection JSUnfilteredForInLoop
		if (obj[k] !== undefined ) return false;
	}
	return true;
};

// noinspection JSUnusedGlobalSymbols
const _omitUndefined = (obj) => {
	const ret = {};
	for (const k in obj) {
		// noinspection JSUnfilteredForInLoop
		if ( obj[k] !== undefined ) { // noinspection JSUnfilteredForInLoop
			ret[k] = obj[k];
		}
	}
	return ret;
};

const _req = (what) => Boolean(typeof what === 'undefined' ? isString(what) : what.trim() !== '');

const asPopup = (Label) =>
	class extends React.PureComponent {
		render() {
			const {popup, ...props} = this.props;
			if ( popup ) {
				return <Popup {...popup} trigger={<Label {...props}/>} />;
			}
			return <Label {...props}/>
		}
	};

const FormLabel = asPopup(asFormLabel(Label));


// noinspection JSPotentiallyInvalidUsageOfThis
const asLoaderButton = (Component) =>
	class extends React.PureComponent {
		static propTypes = {
			loading: PropTypes.bool,
			disabledOnLoading: PropTypes.bool,
			noCalling: PropTypes.bool,
			inverse: PropTypes.bool
		};
		static defaultProps = {
			loading: false,
			disabledOnLoading: false,
			noCalling: true,
			inverse: false
		};
		handleClick = e => {
			if ( this.props.loading && this.props.noCalling ) {
				// e.stopPropagation();
				// console.log('NOT CALLING :P');
				return;
			}
			isFunc(this.props.onClick) && this.props.onClick.call(this, e);
		};
		render() {
			const {loading, children, disabled, onClick, disabledOnLoading, inverse, noCalling, className, icon, ...props} = this.props;
			return <Component
				onClick={this.handleClick}
				className={cn("loadable-button", className)}
				icon={loading ? null : icon}
				disabled={disabledOnLoading ? (loading || disabled) : disabled} {...props}>
					{loading ? <Icon size={'large'} style={{color: inverse ? globals.COLOR_CONTRAST : globals.COLOR_PRIMARY}} name={'circle notched'} loading /> : children}
				</Component>
		}
	};

// noinspection JSUnusedGlobalSymbols
const FlatButtonLoader = asLoaderButton(MaterialFlatButton);

// const Loadable = ({children }) => asLoaderButton(children);
class Loadable extends React.PureComponent {
	cmp = null;
	constructor(props) {
		super(props);
		this.cmp = asLoaderButton(props.hoc);
	}
	render = () => {
		const {hoc, ...rest} = this.props;
		const Component = this.cmp;
		return <Component {...rest}/>
	}
}

const Required = ({style, visible, children, color, ...props}) => (
	<React.Fragment>{children}<span style={{color, paddingLeft: 3, paddingRight: 3, ...style, display: visible ? get(style, "style.display", "inline") : "none"}} {...props}>*</span></React.Fragment>
);
Required.propTypes = {
	style: PropTypes.object,
	visible: PropTypes.bool,
	children: PropTypes.node
};
Required.defaultProps = {
	visible: true,
	color: 'red'
};

const time2object = (time) => {
	const [hour, minute, second] = time.split(':');
	return {hour, minute, second: second || 0};
};

Loadable.propTypes = {
	hoc: PropTypes.any.isRequired,
	props: PropTypes.object,
	disabledOnLoading: PropTypes.bool,
	noCalling: PropTypes.bool,
	loading: PropTypes.bool,
	inverse: PropTypes.bool
};
Loadable.defaultProps = {
	props: {},
	loading: false,
	disabledOnLoading: false,
	noCalling: false,
	inverse: false
};

let T = ({children, as, t, tReady, replace, ...props}) => {
	const Tag = as;
	if ( !children ) return;
	return <Tag dangerouslySetInnerHTML={{__html: t(children, replace)}} {...props}/>
};
T = translate()(T);
T.propTypes = {
	children: PropTypes.string,
	as: PropTypes.string,
	replace: PropTypes.object
};
T.defaultProps = {
	as: 'span'
};

const partitionObject = (iter, callback) => {
	const holder = {};
	for (const item of iter) {
		const index = callback(item);
		if ( holder[index]) {
			holder[index].push(item);
		} else {
			holder[index] = [item];
		}
	}
	return holder;
};

const partitionArray = (iter, callback) => Object.values(partitionObject(iter, callback));

const withScreenDefaults = {method: 'debounce', delay: 150, options: null};

const withScreen = (option = withScreenDefaults) => (Component) => {
	option = {...withScreenDefaults, ...option};
	// noinspection JSPotentiallyInvalidUsageOfThis
	return class extends React.Component {
		state = {
			width: 0,
			height: 0
		};
		
		__onChangeScreen = () => {
			this.setState({
				width: window.innerWidth,
				height: window.innerHeight
			});
		};
		
		__method = () => {
			const {method, delay, options} = option;
			switch (method) {
				case 'debounce':
					return _debounce(this.__onChangeScreen, delay, options);
				case 'throttle':
					return _throttle(this.__onChangeScreen, delay, options);
				default:
					return this.__onChangeScreen;
			}
		};
		
		componentDidMount() {
			this.__onChangeScreen();
			window.addEventListener('resize', this.__method(), true);
		}
		
		componentWillUnmount() {
			window.removeEventListener('resize', this.__method());
		}
		
		render() {
			const props = this.props;
			return <Component {...props} screen={this.state}/>
		}
	}
};

const ScreenContext = withScreen()(({screen, children}) => <ScreenContextProvider value={screen}>{children}</ScreenContextProvider>);

const withScreenReader = (Component) => (props) => (<ScreenReader>{screen => <Component screen={screen} {...props}/>}</ScreenReader>);

// const ScreenProvider = ({children, screen}) => (<ScreenContextProvider value={screen}>{children}</ScreenContextProvider>);
// const _ScreenProvider = ({children, screen}) => children.call(null, screen);
// const ScreenProvider = withScreen()(_ScreenProvider);


const withMargin = (Component) => ({style, ...props}) => (<Component style={{margin: 'auto -15px', ...style}} {...props}/>);
const ListItemMargined = withMargin(ListItem);

const AHToggle = asToggle(Toggle);
const AHIconButton = asIconButton(IconButton);

const values = (obj) => {
	return isObject(obj) ? lodashValues(obj) : undefined;
};




const TRANSLATE_FAILED = '__failed__';

const processTranslate = (t, obj, index='title') => how => {
	const data = {...obj};
	const trans = how.call(null, data);
	const next = t([trans, TRANSLATE_FAILED]);
	data._origin = data[index];
	// data.__translated = TRANSLATE_FAILED !== next;
	data[index] = next === TRANSLATE_FAILED ? data._origin : next;
	return data;
};

const jsonRemoveNull = obj => mapValues(obj, value => null === value ? '' : value);

const jsonNullify = (obj, which = 'all') => mapValues(obj, (value, index) => {
	if ( which === 'all' || which.includes(index) ) {
		if (isString(value) && value.trim() === '') {
			return null;
		}
	}
	return value;
});

const scrollto = (element, speed = 700) => {
	const to = isString(element) ? document.querySelector(element) : element;
	if ( to ) {
		const scrollTop = to.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
		anim(document.body, scrollTop, speed);
		anim(document.documentElement, scrollTop, speed);
	} else {
		console.warn('scrollto: element to scroll not found!', element, to, isString(to));
	}
};

const array2object = (array, innerList = false) => callback => {
	const call = isString(callback) ? iteree => get(iteree, callback) : callback;
	let back = {};
	for (let entry of array) {
		const index = call.call(null, entry);
		if (isArray(index)) {
			back[index[0]] = index[1] !== undefined ? index[1] : entry;
		} else {
			if (innerList) {
				if (index in back) {
					back[index].push(entry);
				} else {
					back[index] = [entry];
				}
			} else {
				back[index] = entry;
			}
		}
	}
	return back;
};

const SelectFieldEx = ({iconStyle, underlineStyle, vmargin, style, ...props}) => (
	<SelectField
		iconStyle={{display: 'none', ...iconStyle}}
		underlineStyle={{display: 'none', ...underlineStyle}}
		floatingLabelStyle={{whiteSpace: "nowrap"}}
		style={{width: "auto", marginLeft: vmargin || 5, marginRight: vmargin || 5, ...style}}
		autoWidth
		{...props}
	/>
);
SelectFieldEx.propTypes = {
	vmargin: PropTypes.number,
	floatingLabelText: PropTypes.node,
	onChange: PropTypes.func,
	value: PropTypes.any,
};
SelectFieldEx.defaultProps = {
	autoWidth: true
};

const flattenCollection = (collection, callback) => {
	if ( !isArray(collection) ) {
		console.error("flattenCollection: collection is not an array");
		return collection;
	}
	let next;
	for(let i = 0; i < collection.length; ++i) {
		next = callback(collection[i], i, collection);
		if ( isArray(next)) {
			collection.splice(i, 1, ...next);
			i += (next.length - 1);
		}
	}
	return collection;
};

window.time2object = time2object;

const angle = ([ax, ay], [bx,by]) => {
	let a = Math.atan((ay-by)/(ax-bx));
	a = (a * 180 / Math.PI) * (-1);
	if ( bx > ax) {
		a *= (-1);
	} else if (bx < ax) {
		a = a * (-1) + 180;
	}
	return a;
};
const distance = ([ax,ay], [bx, by]) => {
	const a = ax - bx;
	const b = ay - by;
	return Math.sqrt( a*a + b*b );
};

window.angle = angle;

const logoLink = ({logo}) => logo_service(logo);

const firstOf = (object) => {
	// noinspection LoopStatementThatDoesntLoopJS
	for (const [, o] of Object.entries(object)) {
		return o;
	}
	return null;
};

const Pad = ({direction, count, fill,children}) => (
	<span style={{whiteSpace: "pre", fontFamily: "'Lucica Console', monospace"}} dangerouslySetInnerHTML={{__html: direction === "left" ?
		children.padStart(count, fill) :
		children.padEnd(count, fill)}}/>
);
Pad.propTypes = {
	direction: PropTypes.oneOf(["left", "right"]),
	count: PropTypes.number,
	fill: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.string
};
Pad.defaultProps = {
	direction: "right",
	count: 0,
	fill: " ",
	children: ""
};

const trueNull = (value) => Boolean(value) ? true : null;
const falseNull = (value) => !Boolean(value) ? true : null;

const getTimeSpan = (days, formatted = false) => {
	let from = moment();
	if ( days < 0 ) {
		from = from.subtract(1, 'day');
	}
	let to = from.clone();
	to = to.add(days, 'day').toDate();
	from = from.toDate();
	if ( formatted ) {
		to = to.toISOString();
		from = from.toISOString();
	}
	if (days <  0 ) {
		return [to, from];
	} else {
		return [from, to];
	}
};

const fuseOrders = (list) => {
	if (!isArray(list)) return list;
	const map = {};
	// build the map
	for(const order of list) {
		if (!map[order.order_id]) {
			map[order.order_id] = [order];
		} else {
			map[order.order_id].push(order);
		}
	}
	const keys = Object.keys(map);
	const next = [];
	for (const key of keys) {
		const o = map[key];
		if (o.length) {
			const first = o[0];
			const ids = [];
			for(const order of o) {
				ids.push(order.resource_id);
			}
			first.resource_ids = ids;
			next.push(first);
		}
	}
	return next;
};

const object_equals = (a, b) => isObject(a) && isObject(b) && isEqual(a, b);

const momentFromDateAndTime = (date, time) => {
	return moment(`${moment(date).format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD HH:mm");
};
const updateDateKeepTime = (date, prevDate) => {
	const time = moment(prevDate).format("HH:mm");
	return momentFromDateAndTime(date, time);
};

const xor = (a,b) => Boolean(!a ^ !b);

const promised = callable => new Promise(resolve => {
	resolve(callable());
})

const deepMemoize = (func, cmp) => memOne(func, cmp || dequal)

export {
	Pad,
	deepMemoize,
	firstOf,
	lengthOfObject,
	mapOfObject,
	filterOfObject,
	array_offset,
	array_unique,
	array_disjunct,
	blackOrWhite,
	FormLabel,
	FlatButtonLoader,
	Loadable,
	_empty,
	_omitUndefined,
	_req,
	T,
	withScreen,
	ListItemMargined,
	AHToggle,
	AHIconButton,
	values,
	processTranslate,
	ScreenReader,
	ScreenContext,
	withScreenReader,
	jsonRemoveNull,
	jsonNullify,
	scrollto,
	array2object,
	SelectFieldEx as SelectField,
	time2object,
	flattenCollection,
	angle,
	distance,
	logoLink,
	Required,
	trueNull,
	falseNull,
	getTimeSpan,
	partitionObject,
	partitionArray,
	filter_object,
	fuseOrders,
	object_equals,
	momentFromDateAndTime,
	updateDateKeepTime,
	darkenOrLighten,
	xor,
	promised
};
