import React from 'react';
import PropTypes from 'prop-types';

let DialogButton = ({onClick, className, children, ...props}) => (
	<button className={`on-light ${className}`} {...props} onClick={onClick}>{children}</button>
);


DialogButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

DialogButton.defaultProps = {
	className: ''
};

// DialogButton = connect(mapState, mapDispatch)(DialogButton);

export default DialogButton;