import * as React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "material-ui";
import {IconChevronLeft} from "../Logic/icons";

export default class ScrollTop extends React.PureComponent {
	static propTypes = {
		margin: PropTypes.number
	};
	static defaultProps = {
		margin: 100
	};
	
	state = {
		top: 0
	};
	
	handleScroll = e => {
		const top = window.scrollY || window.scrollTop;
		this.setState({top});
	};
	
	handleToTop = () => {
		if ( 'scrollTop' in window ) {
			window.scrollTop = 0;
		} else if( 'scroll' in window) {
			window.scroll(0,0);
		} else {
			console.error('no scrollType found!');
		}
	};
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, {passive: true});
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	render() {
		const {top} = this.state;
		const {margin} = this.props;
		const topMargin = Math.max(0, margin);
		return(
			<IconButton style={{
				position: 'fixed',
				bottom: 0,
				right: 0,
				zIndex: 99,
				display: top >= topMargin ? 'inline-block' : 'none'
			}} onClick={this.handleToTop}><IconChevronLeft className={"rotate-right-90"} style={{transform: "rotate(90deg)"}}/></IconButton>
		);
	}
}