import * as React from 'react';
import PropTypes from 'prop-types';
import {translate} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/de';
import {loadWorkers} from "../../../actions/workerActions";
import {deleteResourceTimeOf, deleteTimesOfResources, deleteTimesRangesOfResource, loadResourcesOfHouse, resourceAction__fetchWorkers, saveResourceTimes, updateResourceTime} from "../../../actions/resourceActions";
import ActionHeader, {ActionBackButton, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import {SelectField, trueNull} from "../../../Logic/extensions";
import {isOnline} from "../../../actions/userActions";
import {ACCESS_PROP_DEFAULTS, ACCESS_PROP_DEFINTIONS, SECTION} from "../../../Logic/constants";
import User from "../../../models/User";
import WorkerTimeDialog from "./resource-calendar/WorkTimeDialog";
import RemoveDialog from "./resource-calendar/RemoveDialog";
// import {loadFreedays} from "../../../actions/freedaysActions";
import {values} from "lodash";
import {Divider, MenuItem} from "material-ui";
import {Button, Icon, Label, Message, Modal, Segment} from "semantic-ui-react";
import NoAccess from "../../NoAccess";
import WorkersScheduleController from "../../../cointainer/intern/workers/WorkersScheduleController";
import WorkersScheduleCreateMaskController from "../../../cointainer/intern/workers/WorkersScheduleCreateMaskController";
import TimeIcon from "material-ui/svg-icons/action/event";
import {push} from "connected-react-router";
import {IconMenu, ResourceLink, WorkerDetailLink, WorkersCalendarLink} from "../../../Logic/MiniMenu";
import {ConfirmButton, ConfirmButtonConsumer} from "../../partials";
import {IconCalendar} from "../../../Logic/icons";


const moment = extendMoment(Moment);
BigCalendar.momentLocalizer(moment);

const ItemCore = ({name, color}) => (
	<div style={{position: 'relative', paddingLeft: 12}}><div style={{position: 'absolute', top: '30%', left: 0, bottom: '30%', width: 5, background: color}}/>{name}</div>
);

const DeleteButton = ({open, onClick}) => trueNull(open) &&
	<ConfirmButton icon content={<React.Fragment><Icon name={'trash'}/> Auswahl löschen</React.Fragment>} negative>
		<ConfirmButtonConsumer>{confirm =>
			<Button.Group>
				<Button basic color='grey'  onClick={e => {
					// noinspection JSUnresolvedFunction
					e && e.stopPropagation();
					confirm(false);
				}}>
					<Icon name={'close'}/> Abbrechen
				</Button>
				<Button.Or/>
				<Button negative onClick={onClick}>Löschen</Button>
			</Button.Group>
		}</ConfirmButtonConsumer>
	</ConfirmButton>;
	// <Button icon onClick={onClick} negative {...props}><Icon name={'trash'}/> Auswahl löschen</Button>;

class MasterDataResourceCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			create: null, // show time create view
			edit: null, // show event edit
			remove: false, // show remove view
			filter: Number(props.match.params.resource_id) || 'all', // resouce filter key
			view: 'week',
			snackbar: null // Snackbar control...
		};
		this.workers_id = props.match.params.workers_id;
		this.handleSave = this.handleSave.bind(this);
		this.deleteTimeResources = this.deleteTimeResources.bind(this);
		this.deleteTimeRanges = this.deleteTimeRanges.bind(this);
		window.MasterDataResourceCalendar = this;
	}
	
	setFilter = (e, index, filter) => this.setState({filter});
	// setSelection = name => (e, index, value) => this.setState({[name]: value});
	
	componentDidMount() { // load neccessary data
		this.props.onLoad();
	}
	
	handleSave = (data, onSuccess) => {
		//this.showCreate(create);
		this.props.onSaveTimes && this.props.onSaveTimes(data, onSuccess);
	};
	
	deleteTimeResources = (aResourceIDs, onSuccess=null) => {
		this.props.onDeleteResourceTimes(this.workers_id, aResourceIDs, () => {
			this.setState({snackbar: 'time_list_deleted', remove: false});
			onSuccess && onSuccess();
		});
	};
	
	deleteTimeRanges = ({resource_id, start, end, onSuccess = null}) => {
		this.props.onDeleteRangesOfTimes(this.workers_id, resource_id, start, end, () => {
			this.setState({snackbar: 'time_range_deleted', remove: false});
			onSuccess && onSuccess();
		})
	};
	
	showCreate = workersId => () => this.setState({create: workersId});
	showEdit = edit => this.setState({edit});
	test = React.createRef();
	
	render() {
		const {
			// history: {push},
			// push,
			resources,
			// onDeleteResources,
			worker,
			mayRead,  mayDelete, mayChange,
			onUpdateTime,
			loaded,
			onSwitchView
		} = this.props;
		
		if (!loaded || !worker) {
			return <Segment style={{minHeight: 300}} loading/>;
		}
		
		if ( !mayRead ) {
			return <NoAccess/>;
		}
		
		const resourceList = resources.sort((a,b) => a.name.localeCompare(b.name));
		const hasResources = resourceList.length > 0;
		
		// let filteredEvents = events.length ? events.filter(e => this.state.filter === 'all' ? true : 'resource-'+e.resourceId === this.state.filter ) : MasterDataResourceCalendar.defaultProps.events;
		// filteredEvents = [...this.props.freedays, ...filteredEvents];
		// const filteredResources = resourceList.filter(r => this.state.filter === 'all' || r.resource_id === this.state.filter);
		// console.warn("filteredRes", filteredResources, resourceList);
		return (
			<div id="master-data__resource-calendar">
				{/* HEADER SECTOIN */}
				<ActionHeader alignment="space-between">
					<ActionHeaderGroup>
						<ActionBackButton
							// onBack={() => push('/master-data/resources/assign')}
						/>
						{worker &&
						<ActionHeading>{worker.first_name.substr(0, 1)}. {worker.name}: Ressourcen {'active' in this.props && !this.props.active && <Label color={'red'} style={{marginLeft: 8}}>Inaktiv</Label>}</ActionHeading>}
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<div ref={this.test}/>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{/*<ActionHeaderLink to="/master-data/closed">Schließzeiten</ActionHeaderLink>*/}
						{hasResources && <SelectField
							vmargin={10}
							floatingLabelText={'Ressourcen'}
							value={this.state.filter}
							onChange={this.setFilter}
						>
							<MenuItem primaryText={'Alle'} value={'all'}/>
							<Divider/>
							{resourceList.map(({resource_id, name, color}) =>
								<MenuItem key={`res-filter-${resource_id}`} primaryText={<ItemCore color={color} name={name} />} value={resource_id}/>
							)}
						</SelectField>}
						
						<IconMenu closeOnClick>
							<ResourceLink/>
							<Divider/>
							<MenuItem primaryText={'Zeiten zuweisen'} leftIcon={<TimeIcon/>} onClick={this.showCreate(worker.workers_id)}/>
							<Divider/>
							{/*<MenuItem primaryText={'Mitarebeiterdetails ansehen'} leftIcon={<PersonIcon/>} onClick={push.bind(null, `/workers/detail/${worker.workers_id}`)}/>*/}
							<WorkerDetailLink workers_id={worker.workers_id}/>
							<WorkersCalendarLink workers_id={worker.workers_id}/>
							{onSwitchView && <>
								<Divider/>
								<MenuItem primaryText={'Zum dynamischen Kalender'} leftIcon={<IconCalendar/>} onClick={onSwitchView}/>
								</>}
						</IconMenu>
						{/*{mayCreate && <ActionIconButton icon="plus" onAction={this.showCreate(worker.workers_id)}/>}*/}
						{/*{mayDelete && <ActionIconButton onAction={() => this.setState({remove: true})} icon="trash" buttonClassName="btn btn-danger" />}*/}
					</ActionHeaderGroup>
				</ActionHeader>
				{/* Calendar */}
				{hasResources ? <WorkersScheduleController
					workers_id={worker.workers_id}
					resource_id={this.state.filter === 'all' ? null : this.state.filter}
					// view={this.state.view}
					deleteButton={<DeleteButton/>}
					buttonPortal={trueNull(this.test) && this.test.current}
					step={30}
					min={moment().startOf('day').add(5, 'hours').toDate()}
					max={moment().endOf('day').subtract(4, 'hours').toDate()}
				/> : <Segment basic>
					<Message warning><h2>Keine Ressourcen zugwiesen</h2></Message>
				</Segment>}
				
				{/* EDIT VIEW */}
				{/*{this.state.create !== null &&
				<ResourceDialog
					workerId={this.state.create}
					onClose={() => this.showCreate(null)}
					onSave={this.handleSave}
					resources={resourceList}
					defaultResource={this.state.filter === 'all' ? null : this.state.filter}/>
				}*/}
				{this.state.create !== null &&
					<Modal open onClose={this.showCreate(null)}>
						<Modal.Content>
							<WorkersScheduleCreateMaskController onClose={this.showCreate(null)} selectedResource={this.state.filter !== 'all' ? this.state.filter : null} workers_id={worker.workers_id}/>
						</Modal.Content>
						{/*<Modal.Actions>*/}
						{/*	<Button onClick={this.showCreate(null)}>Schließen</Button>*/}
						{/*</Modal.Actions>*/}
					</Modal>
				}
				<WorkerTimeDialog
					mayDelete={mayDelete}
					mayChange={mayChange}
					event={this.state.edit}
					onSave={({id, start, end}, onFail) => {
						onUpdateTime(id, start, end, onFail, () => {
							this.setState({snackbar: 'time_changed', edit: null});
						});
					}}
					onRequestClose={() => this.showEdit(null)}
					onDelete={(resource_id) => this.props.onDeleteTime(resource_id, () => {
						this.setState({snackbar: 'time_deleted', edit: null});
					})}
				/>
				<RemoveDialog onRemoveRange={this.deleteTimeRanges} onRemoveResources={this.deleteTimeResources} open={this.state.remove} onRequestClose={() => this.setState({remove: false})} resources={resourceList} />
			</div>
		);
	}
}

MasterDataResourceCalendar.propTypes = {
	resources: PropTypes.arrayOf(PropTypes.object).isRequired,
	resource4workers: PropTypes.arrayOf(PropTypes.object).isRequired,
	events: PropTypes.arrayOf(PropTypes.object),
	worker: PropTypes.object,
	onLoad: PropTypes.func,
	onSaveTimes: PropTypes.func,
	onDeleteTime: PropTypes.func,
	onUpdateTime: PropTypes.func,
	...ACCESS_PROP_DEFINTIONS
};

MasterDataResourceCalendar.defaultProps = {
	resources: [],
	resource4workers: [],
	events: [],
	worker: null,
	...ACCESS_PROP_DEFAULTS
};

const mapState = (state, props) => {
	const workers_id = Number(props.workersId || props.match.params.workers_id) || null;
	const user =  User._(state.user.data);
	let rw = workers_id ? values(state.resources.workers[workers_id] || {}).map(r => {
		const back = {...state.resources.list[r.resource_id]};
		back.title = back.name;
		back.id = back.resource_id;
		return back;
	}) : [];
	
	const loaded = state.resources.loaded;
	return ({
		// workers: state.workers.list,
		// resourceTypes: state.resources.types,
		// worker: state.workers.list.
		worker: state.workers.list[workers_id], //workers_id ? state.workers.list[workers_id] : null,
		// resource4workers: rw, //workers_id  ? state.resources.workers.filter(res => res.workers_id === workers_id) : state.resources.workers,
		resources: rw,
		events: [], //workers_id ? state.resources.worker[workers_id] : MasterDataResourceCalendar.defaultProps.events,
		freedays: state.freedays.list,
		...user.getAccessTo(SECTION.MASTER_DATA),
		loaded: loaded.list && loaded.workers && workers_id
	});
};
const mapDispatch = (dispatch, props) => ({
	onLoad: (refresh = false) => {
		dispatch(isOnline(() => {
			dispatch(loadWorkers(refresh));
			dispatch(loadResourcesOfHouse(refresh));
			!props.skipWorkerResources && dispatch(resourceAction__fetchWorkers(refresh));
			// dispatch(loadResources4Workers(refresh));
			// dispatch(loadFreedays(refresh));
			// const workers_id = props.match.params.workers_id;
			// workers_id && dispatch(loadResourcesTimesOf(workers_id));
		}));
	},
	push: to => dispatch(push(to)),
	onSaveTimes: (oData, onSuccess) => dispatch(saveResourceTimes(oData, onSuccess)),
	onDeleteTime: (resource_id, onSuccess) => dispatch(deleteResourceTimeOf(resource_id, onSuccess)),
	onUpdateTime: (resource_id, start, end, onFail, onSuccess = null) => dispatch(updateResourceTime(resource_id, start, end, onFail, onSuccess)),
	onDeleteResourceTimes: (workers_id, resource_ids, onSuccess = null) => dispatch(deleteTimesOfResources(workers_id, resource_ids, onSuccess)),
	onDeleteRangesOfTimes: (workers_id, resource_id, start, end, onSuccess=null) => dispatch(deleteTimesRangesOfResource(workers_id, resource_id, start, end, onSuccess))
});


export default connect(mapState, mapDispatch)(withRouter(translate()(MasterDataResourceCalendar)));