import * as React from 'react';
import PropTypes from 'prop-types';
import {loanCarCall__findInRange, loanCarCall__replace} from "../../../../actions/loanCarActions";
import {CircleNotchLoader} from "../../../Loaders";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Button, Message, Table} from "semantic-ui-react";
import {Vehicle} from "../../../../models";

export class AlternativeLoanCar extends React.Component {
	static propTypes = {
		schedule: PropTypes.shape({
			loan_car_schedule_id: PropTypes.number.isRequired,
			start_point: PropTypes.any,
			end_point: PropTypes.any,
			vehicle_id: PropTypes.number.isRequired
		}).isRequired,
		onAlternatives: PropTypes.func,
		onReplace: PropTypes.func,
		onResolved: PropTypes.func,
		onNotification: PropTypes.func
	};
	static defaultProps = {
		onAlternatives: (data) => loanCarCall__findInRange(data),
		onReplace: (schedule_id, replace_id) => loanCarCall__replace(schedule_id, replace_id),
		onResolved: (schedule) => {},
		onNotification:(message, color) => console.log(message)
	};
	
	state = {
		loading: false,
		replacing: 0,
		options: null
	};
	
	componentDidMount() {
		this.fetch();
	}
	
	fetch = async () => {
		const {onAlternatives, schedule} = this.props;
		try {
			this.setState({loading: true});
			const response = await onAlternatives({ignore_vehicles: [schedule.vehicle_id], start_point: schedule.start_point, end_point: schedule.end_point, no_delay: true});
			this.setState({options: response.list});
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({loading: false});
		}
		
	};
	
	replace = (vehicle_id) => async () => {
		const {schedule, onReplace, onNotification, onResolved} = this.props;
		try {
			this.setState({replacing: vehicle_id});
			const response = await onReplace(schedule.loan_car_schedule_id, vehicle_id);
			if (!response.ack) {
				// do something...
				onNotification('Gewähltes Fahrzeug scheint nicht mehr verfügbar zu sein');
				response.vehicles && this.setState({options: response.vehicles});
			} else {
				this.setState({options: true}, () => onResolved(response.schedule));
				onNotification('Fahrzeug wurde umgebucht');
			}
			
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({replacing: 0});
		}
	};
	
	render() {
		const {loading, options} = this.state;
		if (loading) {
			return <EasyFlex align={EasyFlex.align.CENTER}><CircleNotchLoader loading/></EasyFlex>;
		}
		if (true === options) {
			return <Message positive><p>Aktion ausgeführt</p></Message>
		}
		if (null === options) {
			return <Message warning><p>Inhalt nicht geladen</p></Message>
		}
		if (!options || !options.length) {
			return <Message><p>Keine Fahrzeuge verfügbar</p></Message>
		}
		return(
			<Table selectable basic={'very'} celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>HSN/TSN</Table.HeaderCell>
						<Table.HeaderCell>Kennzeichen</Table.HeaderCell>
						<Table.HeaderCell>Fahrgestell</Table.HeaderCell>
						<Table.HeaderCell>Erstzulassung</Table.HeaderCell>
						<Table.HeaderCell>Baujahr</Table.HeaderCell>
						<Table.HeaderCell>Laufleistung</Table.HeaderCell>
						<Table.HeaderCell>&nbsp;</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{options.map(vehicle => {
						vehicle = vehicle instanceof Vehicle ? vehicle : new Vehicle(vehicle);
						return <Table.Row key={`loan-car-vehicle-${vehicle.vehicle_id}`}>
							<Table.Cell>{vehicle.name}</Table.Cell>
							<Table.Cell>{vehicle.hsn_tsn}</Table.Cell>
							<Table.Cell>{vehicle.registration_shield}</Table.Cell>
							<Table.Cell>{vehicle.chassis_number}</Table.Cell>
							<Table.Cell>{vehicle.first_registration_xx}</Table.Cell>
							<Table.Cell>{vehicle.model_year}</Table.Cell>
							<Table.Cell>{vehicle.km} km</Table.Cell>
							<Table.Cell><Button onClick={this.replace(vehicle.vehicle_id)} basic compact>Auswählen</Button></Table.Cell>
						</Table.Row>;
					})}
				</Table.Body>
			</Table>
		);
	}
}