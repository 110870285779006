'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sort = exports.sortDefault = exports.sortDesc = exports.sortAsc = exports.sortBool = exports.sortBoolDesc = exports.sortBoolAsc = exports.sortNum = exports.sortNumDesc = exports.sortNumAsc = undefined;

var _isString = require('lodash/isString');

var _isString2 = _interopRequireDefault(_isString);

require('core-js/fn/array/includes');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // try {
// 	require('babel-polyfill');
// } catch(e) {
// 	// console.info('Babel polyfill could not be loaded...', e);
// }


var truish = function truish(what) {
  return ['asc', 'ascending', false].includes((0, _isString2.default)(what) ? what.toLowerCase() : what);
};

var boolValue = function boolValue(value) {
  return Boolean(value) ? 1 : 0;
};

var sortNumAsc = function sortNumAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (a, b) {
    return collector(a) - collector(b);
  };
};

var sortNumDesc = function sortNumDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (b, a) {
    return collector(a) - collector(b);
  };
};

var sortNum = function sortNum() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortNumAsc(collector) : sortNumDesc(collector);
  };
};

var sortBoolAsc = function sortBoolAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return sortNumAsc(function (data) {
    return boolValue(collector(data));
  });
};

var sortBoolDesc = function sortBoolDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return sortNumDesc(function (data) {
    return boolValue(collector(data));
  });
};

var sortBool = function sortBool() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortBoolAsc(collector) : sortBoolDesc(collector);
  };
};

var sortAsc = function sortAsc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (a, b) {
    return collector(a).toLowerCase().localeCompare(collector(b).toLowerCase());
  };
};

var sortDesc = function sortDesc() {
  var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
    return obj;
  };
  return function (b, a) {
    return collector(a).toLowerCase().localeCompare(collector(b).toLowerCase());
  };
};

var sort = function sort() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'asc';
  return function (collector) {
    return truish(mode) ? sortAsc(collector) : sortDesc(collector);
  };
};

var sortDefault = function sortDefault() {
  return 0;
};

exports.sortNumAsc = sortNumAsc;
exports.sortNumDesc = sortNumDesc;
exports.sortNum = sortNum;
exports.sortBoolAsc = sortBoolAsc;
exports.sortBoolDesc = sortBoolDesc;
exports.sortBool = sortBool;
exports.sortAsc = sortAsc;
exports.sortDesc = sortDesc;
exports.sortDefault = sortDefault;
exports.sort = sort;