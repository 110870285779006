import * as React from "react";
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import history from "../../../Logic/history";
import OrderMainView from "./OrderMainView";
import OrderLaneView from "./OrderLaneView";
import OrderWorkerView from "./OrderWorkerView";
import OrderWorkerDetailView from "./OrderWorkerDetailView";
import {GrouplineTest} from "../../development/GrouplineTest";

const OrderRoutes = () => (
	<ConnectedRouter history={history}>
		<div id="view__orders">
			<Switch>
				<Route exact path="/orders" component={OrderMainView}/>
				<Route exact path="/orders/lanes" component={OrderLaneView}/>
				<Route exact path="/orders/timeline" component={GrouplineTest}/>
				<Route exact path="/orders/workers" component={OrderWorkerView}/>
				<Route exact path="/orders/workers/:id" component={OrderWorkerDetailView}/>
			</Switch>
		</div>
	</ConnectedRouter>
);


export default OrderRoutes;