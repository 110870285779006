import * as React from "react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {OrderQuickService} from "../../cointainer/intern/orders/quick";


export class OrderServiceTest extends React.Component {
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Quick Service Detail Test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<OrderQuickService order_id={188}/>
			</div>
		);
	}
}