import * as React from 'react';
import PropTypes from 'prop-types';
import {DateInput} from "semantic-ui-calendar-react";
import cn from 'classnames';
import {FormLabel} from "../../Logic/extensions";
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import {translate} from "react-i18next";
import moment from "../../Logic/Moment";
import isFunc from "lodash/isFunction";
import isNum from "lodash/isNumber";
import isArray from "lodash/isArray";
import {COLOR_SEMANTIC_ERROR} from "../../Logic/constants";

class DatePicker extends React.Component {
	state = {
		focused: false
	};
	
	handleFocus = (focused, next) => e => {
		this.setState({focused});
		if ( next ) next(e);
	};
	
	handleChange = (e, input) => {
		const {onChange, dateFormat, name} = this.props;
		if ( isFunc(onChange) ) {
			input.name = input.name || name;
			onChange(e, input, {string: input.value.trim(), moment: input.value.trim().length ? moment(input.value, dateFormat) : null, name});
		}
	};
	
	handleClear = e => {
		const {onClear, name} = this.props;
		if ( isFunc(onClear) ) {
			const input = e.target;
			input.name = input.name || name;
			onClear(e, input);
		}
	};
	
	translate = (errorText) => isArray(errorText) ? this.props.t.call(null, errorText[0], errorText[1] || {}) : this.props.t(errorText);
	
	moment = value => isNum(value) ? moment(value).format(this.props.dateFormat) : value;
	
	render() {
		const {t, tReady, i18n,  dateFormat, className, editable, icon, text, label, placeholder, required, readOnly, content, value: initValue, onClear, onBlur, onFocus, onChange, errorText, ...props} = this.props;
		const labelText = label || text;
		const placeholderText = placeholder || text;
		const value = this.moment(initValue);
		const blocked = readOnly || !editable;
		
		return(
			<div className={cn('ith-edit-field', "date-input", 'field', {focused: this.state.focused, required, error: errorText, readonly: blocked})}>
				{labelText && <label>{labelText}</label>}
				<div className={cn('ah-date-input-container')}>
					<DateInput
						value={value}
						dateFormat={dateFormat}
						required={required}
						iconPosition={'left'}
						readOnly
						disabled={!editable}
						// icon={<Popup content={'fufufu'} trigger={<Icon name={icon} className={cn('ah-icon-primary', 'ith-edit-field-icon-left')}/>}/>}
						placeholder={placeholderText}
						className={cn('ah-date-input', className)}
						onFocus={this.handleFocus(true, onFocus)}
						onBlur={this.handleFocus(false, onBlur)}
						onChange={this.handleChange}
						{...props}
					/>
					{onClear && value ? <FormLabel className={'ith-edit-field-icon-right'}><CloseIcon color={errorText ? COLOR_SEMANTIC_ERROR : 'black'} onClick={this.handleClear}/></FormLabel> : null}
					{/*editable && <FormLabel className={'ith-edit-field-icon-right'} popup={errorText || content ? {
						content: this.translate(errorText || content),
						inverted: true,
						position: 'top right'
					} : null}>
						{onClear && value ? <CloseIcon color={errorText ? COLOR_SEMANTIC_ERROR : 'black'} onClick={this.handleClear}/> : <EditIcon color={errorText ? COLOR_SEMANTIC_ERROR : 'black'}/>}
					</FormLabel>*/}
				</div>
			</div>
		);
	}
}

DatePicker.propTypes = {
	...DateInput.propTypes,
	dateFormat: PropTypes.string,
	className: PropTypes.string,
	editable: PropTypes.bool,
	icon: PropTypes.string,
	text: PropTypes.node,
	placeholder: PropTypes.string,
	onClear: PropTypes.func,
	label: PropTypes.node,
	content: PropTypes.string,
	autoComplete: PropTypes.string
};
DatePicker.defaultProps = {
	...DateInput.defaultProps,
	dateFormat: 'L',
	editable: false,
	icon: 'calendar',
	autoComplete: 'off'
};

export default translate()(DatePicker);