import * as React from "react";
import BaseModel from "./BaseModel";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isArray, isFunction} from "lodash";
import {clientAction__collect, clientAction__getOne} from "../actions/clientActions";
import {IconBusiness, IconPerson} from "../Logic/icons";
import {withChange} from "../Logic/withInit";
import {isLoading} from "../actions/loaderActions";
import {PROC_CLIENTS_FETCH} from "../actions";
import {ProviderComponent} from "../components/partials";


const Provider = ({client, children, placeholder}) => client && isFunction(children) ? children(client) : placeholder;
Provider.propTypes = {
	client_id: PropTypes.number,
	placeholder: PropTypes.node
};
Provider.defaultProps = {
	placeholder: null
};

const CollectProvider = ({children, clients, placeholder, loading}) => isArray(clients) && isFunction(children) ? children(clients, loading) : placeholder;
CollectProvider.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({client_id: PropTypes.number})])),
	preview: PropTypes.bool,
	placeholder: PropTypes.node,
	dynamic: PropTypes.bool
};
CollectProvider.defaultProps = {
	ids: [],
	placeholder: null,
	preview: false,
	dynamic: true
};

export class Client extends BaseModel {
	_validateModel(model) {
		return ("client_id" in model) && ("client_number" in model);
	}
	
	isCompany() {
		return this.__model.company_name && this.__model.company_name.trim() !== '';
	}
	
	get is_company() {
		return this.__model.salutation === 'company' && this.__model.company_name && this.__model.company_name.trim() !== '';
	}
	
	getName() {
		if (this.isCompany()) {
			const salutation = this.t ? this.t(`client.salutations.${this.__model.salutation}`) : null;
			return salutation ? `${salutation} ${this.__model.company_name}` : this.__model.company_name;
		}
		return this.getContact();
	}
	
	get company_name() {
		return this.__model.company_name;
	}
	
	get id() {
		return this.__model.client_id;
	}
	
	get client_id() {
		return this.__model.client_id;
	}
	
	get salutation() {
		return this.join('client.salutations.{{salutation}}')
	}
	
	get name() {
		if (this.is_company) {
			return this.join('client.salutations.{{salutation}}', 'company_name');
		}
		return this.contact;
	}
	
	get Icon() {
		return this.is_company ? IconBusiness : IconPerson;
	}
	
	get icon() {
		const Icon = this.Icon;
		return <Icon/>;
	}
	
	get PrivateIcon() {
		return IconPerson;
	}
	
	get CompanyIcon() {
		return IconBusiness;
	}
	
	get private_icon() {
		const Icon = this.PrivateIcon;
		return <Icon/>;
	}
	
	get company_icon() {
		const Icon = this.CompanyIcon;
		return <Icon/>;
	}
	
	get client_number() {
		return this.__model.client_number;
	}
	
	get street() {
		return this.__model.street;
	}
	
	get house_number() {
		return this.__model.house_no;
	}
	
	get house_no() {
		return this.__model.house_no;
	}
	
	get street_full() {
		return this.join('street', 'house_no').trim();
	}
	
	
	get address_extra() {
		return this.__model.address_extra ? this.__model.address_extra.trim() : this.__model.address_extra;
	}
	
	get city() {
		return this.__model.city;
	}
	
	get zipcode() {
		return this.__model.zipcode;
	}
	
	get city_full() {
		return this.join('zipcode', 'city').trim();
	}
	
	get email() {
		return this.__model.email;
	}
	
	get contact() {
		return this.join('name', 'first_name').trim();
	}
	
	get contact_reverse() {
		return this.join('first_name', 'name').trim();
	}
	
	get contact_full() {
		return this.join('client.salutations.{{salutation}}', 'name', 'first_name').trim();
	}
	
	get country() {
		return this.join('country.{{country}}');
	}
	
	get has_address() {
		return Boolean(this.street_full || this.city_full || this.address_extra);
	}
	
	getContact() {
		return this.__model.first_name ? `${this.__model.name} ${this.__model.first_name}` : this.__model.name;
	}
	
	isSelf() {
		return this.__model.self;
	}
	
	get self() {
		return this.__model.self;
	}
	
	get(index, def = null) {
		return this.__model[index] || def;
	}
	
	getPhoneNumber(def = null) {
		if (!this.__model.telephone || this.__model.telephone.trim() === '') {
			return def;
		}
		if (this.__model.telephone_prefix && this.__model.telephone_prefix.trim() !== '') {
			return `${this.__model.telephone_prefix} / ${this.__model.telephone}`;
		}
		return this.__model.telephone;
	}
	
	get phone_number() {
		if (!this.__model.telephone || !this.__model.telephone.trim()) {
			return null;
		}
		return this.join('telephone_prefix', '/', 'telephone');
	}
	
	get phone_full() {
		if (this.phone_number) {
			return this.country_prefix + ' ' + this.phone_number;
		}
		return null;
	}
	
	get country_prefix() {
		switch (this.__model.country) {
			case "germany":
				return '+49';
			default:
				return '';
		}
	}
	
	get mobile() {
		return this.__model.mobile;
	}
	
	get mobile_full() {
		if (this.mobile) {
			return this.country_prefix + ' ' + this.mobile;
		}
		return null;
	}
	
	get vehicles() {
		return this.__model.vehicles || null;
	}
	
	rawData() {
		return this.origin;
	}
	
	static propTypes__shape = PropTypes.shape({
		client_id: PropTypes.number.isRequired,
		client_number: PropTypes.string
	});
	static propTypes__instance = PropTypes.instanceOf(Client);
	static propTypes__both = PropTypes.oneOfType([Client.propTypes__shape, Client.propTypes__instance]);
	
	static Provider = connect((state, props) => {
		const c = state.map.clients[props.client_id] || state.clients.list[props.client_id];
		return {
			client: c ? new Client(c) : null
		};
	}, (dispatch, props) => {
		return {
			withChange: () => {
				props.client_id && dispatch(clientAction__getOne(props.client_id));
			}
		};
	})(withChange(Provider,{access: 'client_id', dynamicIndex: 'dynamic'}));
	
	static CollectProvider = connect(
		(state, props) => {
			const ids = [...props.ids].map(id => Number(id.client_id || id)).filter(Boolean);
			let clients = [...ids].map(id => state.map.clients[id] || null);
			if (!clients.length) {
				clients = null;
			} else if (clients.includes(null)) {
				if (props.preview) {
					clients = clients.filter(Boolean);
				} else {
					clients = null;
				}
			}
			return {
				clients,
				ids,
				loading: isLoading(state, PROC_CLIENTS_FETCH)
			};
		},
		(dispatch) => ({
			withChange: ({ids}) =>  (ids && ids.length && dispatch(clientAction__collect(ids)))
		})
	)(withChange(CollectProvider, {access: 'ids', dynamicIndex: 'dynamic'}));
	
	static Connected = connect(
		(state, {id, client: given}) => {
			if (given) {
				let client = given instanceof Client ? given : new Client(given);
				return {
					data: client
				};
			}
			try {
				let data = state.map.clients[id];
				data = data ? new Client(data) : null;
				return {data};
			} catch (e) {
				console.error('failed to load clients data', state.map);
				return {data: null};
			}
		}
	)(ProviderComponent);
	
}


export default Client;