import * as React from "react";
import PropTypes from "prop-types";
import {IconButton, Paper} from "material-ui";
import {EasyFlex, Flex} from "../../../components/partials/ActionHeader";
import AppointmentCarStatus from "./AppointmentCarStatus";
import {SECTION} from "../../../Logic/constants";
import {Icon, Segment} from "semantic-ui-react";
import {IconBuild, IconCar, IconInfo, IconPerson} from "../../../Logic/icons";
import {withRights} from "../../../Tools";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_APPOINTMENTS} from "../../../actions";
import AppointmentInfo from "../../../components/intern/appointments/AppointmentInfo";
import {Case, Default, Switch} from "../../../Logic/Switch";
import AppointmentClient from "./AppointmentClient";
import AppointmentVehicle from "./AppointmentVehicle";
import AppointmentWorkers from "./AppointmentWorkers";
import withInit from "../../../Logic/withInit";
import {isOnline} from "../../../actions/userActions";
import OrderQuickView from "../orders/OrderQuickView";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import {AppointmentDateInfo} from "./paper";
import {orderAction__collect} from "../../../actions/orderActions";
import {deepMemoize as memoize} from "../../../Logic/extensions";
import {array2object} from "../../../Logic/extensions";

// const DATE_FORMAT = "DD.MM.YY";
// const TIME_FORMAT = "HH:mm";
// const TIME_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;



class AppointmentPaper extends React.Component {
	static propTypes = {
		order: PropTypes.object.isRequired,
		order_id: PropTypes.number,
		client_id: PropTypes.number,
		vehicle_id: PropTypes.number,
		services: PropTypes.object,
		resources: PropTypes.object,
		order2services: PropTypes.object,
		strict: PropTypes.bool,
		accessable: PropTypes.bool,
		fused: PropTypes.bool,
		work_map: PropTypes.object,
		filter_unreleased: PropTypes.bool
	};
	static defaultProps = {
		services: {},
		resources: {},
		serviceMap: {},
		order2services: {},
		loading: false,
		client_id: 0,
		vehicle_id: 0,
		strict: false,
		fused: false,
		accessable: true,
		work_map: {},
		filter_unreleased: false
	};
	
	state = {
		view: 'info',
		show_quick_view: false
	};
	
	
	setView = view => () => this.setState({view});
	setInfoView = () => this.setState(state => ({
		view: 'info',
		show_quick_view: state.view === 'info'
	}));
	closeQuickView = () => this.setState({show_quick_view: false});
	
	orderVehicle = memoize(
		(map, id) => map[id] || null
	);
	client = memoize(
		(map, vehicle) => vehicle ? (map[vehicle.client_id] || null) : null
	);
	
	services = memoize(
		(map, order_id) => {
			let list = {};
			let ids = {};
			for (let [id,service] of Object.entries(map)) {
				if (service.order_id === order_id) {
					list[id] = service;
					if (order_id in ids) {
						ids[order_id].push(service.order_service_id);
					} else {
						ids[order_id] = [service.order_service_id];
					}
				}
			}
			return [list, ids];
		}
	);
	
	serviceIds = memoize(
		services => Object.values(services).map(s => s.order_service_id)
	);
	resources = memoize(
		(map, services) => {
			// let ids = services.map(s => s.order_service_id);
			let idMap = array2object(Object.values(services))('order_service_id');
			let ids = {};
			let list = {};
			for (let [id, resource] of Object.entries(map)) {
				let sid = resource.order_service_id;
				if (sid in idMap) {
					list[id] = resource;
					if (sid in ids) {
						ids[sid].push(resource.order_service_resource_id);
					} else {
						ids[sid] = [resource.order_service_resource_id];
					}
				}
			}
			return [list, ids];
		}
	);
	
	render() {
		const {
			order,
			services: givenServices,
			resources: givenResources,
			serviceMap,
			loading,
			// client_id: givenClientId,
			// vehicle_id,
			// resources,
			// order2services,
			strict,
			accessable,
			// orderVehicle,
			fused,
			work_map,
			filter_unreleased,
			clients,
			orderVehicles
		} = this.props;
		
		// const created = moment(order.created_at).format(constants.DATE_FORMAT);
		// const sid = order2services[order.order] || [];
		// const delivered = moment(order.deliver_point);
		// const fetched = moment(order.fetch_point);
		// const noReturnPoint = moment(order.latest_point);
		if (!order) {
			console.error('order is required and missing!');
			return <Icon name={'circle notch'} color={'red'} loading/>;
		}
		let orderVehicle = this.orderVehicle(orderVehicles, order.order_vehicle_id);
		let client = this.client(clients, orderVehicle);
		let client_id = client ? client.client_id : 0;
		let [services, order2services] = this.services(givenServices, order.order_id);
		let service_ids = this.serviceIds(services);
		let [resources, service2resources] = this.resources(givenResources, services);
		let vehicle_id = orderVehicle ? orderVehicle.vehicle_id : 0;
		const {view, show_quick_view} = this.state;
		return (
			<React.Fragment>
				<Paper style={{padding: '20px 15px', marginBottom: 10}}>
					<Segment basic loading={loading} style={{padding: 0, margin: 0}}>
						
						<Flex>
							<Flex><AppointmentCarStatus accessable={accessable} order={order} order_id={order.order_id}/></Flex>
							
							<div className={"appointment-status-times"} style={{
								marginRight: 15,
								padding: "25px 15px 20px 15px",
								background: "#f5f5f5",
								marginTop: -20,
								marginBottom: -20
							}}>
									<div style={{margin: "auto"}}><AppointmentDateInfo order={order} inverted/></div>
							</div>
							
							<Flex direction={'column'} grow={100}>
								<Switch value={view}>
									<Case is={'client'}><AppointmentClient client_id={client_id}/></Case>
									<Case is={'car'}><AppointmentVehicle vehicle_id={vehicle_id}/></Case>
									<Case is={'workers'}>
										<AppointmentWorkers
											services={services}
											resources={resources}
											order2services={order2services}
											service2resources={service2resources}
											work_map={work_map}
											fused={fused}
											accessable={accessable}
											strict={strict}
											order={order}
											order_id={order.order_id}
											filter_unreleased={filter_unreleased}
											service_ids={service_ids}
										/>
									</Case>
									<Default>
										<AppointmentInfo
											filter_unreleased={filter_unreleased}
											work_map={work_map}
											fused={fused}
											orderVehicle={orderVehicle}
											client={client}
											client_id={client_id}
											strict={strict}
											order={order}
											services={services}
											serviceMap={serviceMap}
											order2services={order2services}
											service2resources={service2resources}
											resources={resources}
											changeView={this.setView}
										/>
									</Default>
								</Switch>
							</Flex>
							<EasyFlex direction={EasyFlex.direction.COLUMN} align={EasyFlex.align.CENTER} style={{margin: "-20px -15px -20px 10px", padding: "20px 0", background: "#f5f5f5"}}>
								<IconButton onClick={this.setInfoView}><IconInfo color={view === 'info' ? "green" : null}/></IconButton>
								<IconButton onClick={this.setView('client')}><IconPerson color={view === 'client' ? "green" : null}/></IconButton>
								<IconButton onClick={this.setView('car')}><IconCar color={view === 'car' ? "green" : null}/></IconButton>
								<IconButton onClick={this.setView('workers')}><IconBuild color={view === 'workers' ? "green" : null}/></IconButton>
							</EasyFlex>
						</Flex>
					</Segment>
					<OrderViewDialog onClose={this.closeQuickView} open={show_quick_view}>
						{show_quick_view && <OrderQuickView order_id={order.order_id} onRequestClose={this.closeQuickView}/>}
					</OrderViewDialog>
					
					
				</Paper>
				{/*<pre>{JSON.stringify({service_ids}, null, 2)}</pre>*/}
			</React.Fragment>
		);
	}
}

AppointmentPaper = withRights(SECTION.APPOINTMENTS, AppointmentPaper);
AppointmentPaper = withInit(AppointmentPaper);
AppointmentPaper = connect(
	(state, props) => ({
		services: props.services || state.map.order2services,
		resources: props.resources || state.map.order2service2resources,
		order2services: props.order2services || state.appointments.order2services,
		serviceMap: state.kfz.service.list,
		orderVehicles: state.map.order2vehicles,//get(state.map.vehicles, props.order.order_vehicle_id, null) || get(state, ["vehicles", "order2vehicle", props.order.order_vehicle_id || 0], null),
		loading: isLoading(state, PROC_APPOINTMENTS, props.order_id || 0),
		work_map: props.work_map || state.appointments.work_map,
		clients: state.map.clients
	}),
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			// props.order && dispatch(vehicleAction__getByOrder(props.order));
			// props.client_id && dispatch(clientAction__getOne(props.client_id));
			if (props.collect && (props.order_id || props.order)) {
				dispatch(orderAction__collect([props.order ? props.order.order_id : props.order_id]));
			}
		}))
	})
)(AppointmentPaper);
AppointmentPaper = translate()(AppointmentPaper);


export default AppointmentPaper;