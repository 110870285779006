import * as React from 'react';
import {Client} from "../../../../models";
import {EasyFlex} from "../../../partials/ActionHeader";
import ClientPopup from "../../clients/ClientPopup";

export const ClientView = ({client: c}) => {
	if (!c) {
		return null;
	}
	let client = c;
	if (!(c instanceof Client)) {
		client = new Client(c);
	}
	return (
		<EasyFlex valign={EasyFlex.valign.CENTER} style={{paddingLeft: 144}}>
			<ClientPopup client={client} inverted>
				<span style={{display: 'flex', alignItems: 'center'}}>{React.cloneElement(client.icon, {style: {position: 'relative', top: -1}})} <strong>{client.contact}</strong></span>
			</ClientPopup>
			
		</EasyFlex>
	);
}