/* eslint-disable */
import * as React from 'react';
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {Button, Modal, Segment} from "semantic-ui-react";
import {ConnectedWheelEditor} from "../intern/wheel/WheelEditor";
import {ConnectedWheelListView} from "../intern/wheel/WheelListView";
import {isPlainObject} from 'lodash'
import {Wheel} from "../../models";
import {dispatchSnack} from "../../actions/snackbarActions";
import {wheelCall_list} from "../../actions/wheelActions";
import {WheelSearchModal} from "../intern/wheel/WheelSearchModal";

export class WheelListTest extends React.Component {
	state = {
		showEdit: false,
		loading: false,
		reloader: null,
		edit_wheel: null,
		show_search: false,
		searching: false,
		search_list: null,
		wheel: null
	}
	
	search = async params => {
		try {
			this.setState({searching: true, search_list: []})
			/**
			 *
			 * @type {Object}
			 */
			const response = await wheelCall_list(params);
			this.setState({search_list: Object.values(response)})
		} catch (e) {
			console.error(e)
			dispatchSnack(e.message, 'alert')
		} finally {
			this.setState({searching: false})
		}
	}
	
	showEdit = showEdit => () => this.setState({showEdit});
	setLoading = loading => this.setState({loading})
	setReloader = reloader => this.setState({reloader})
	setEditWheel = showEdit => this.setState({showEdit})
	showSearch = show_search => () => this.setState({show_search})
	
	render() {
		const {showEdit, loading, reloader} = this.state
		return (
			<Segment basic style={{paddingTop: 0}}>
				<ActionHeader alignment={'space-between'}>
					<ActionHeaderGroup>
						<ActionBackButton loading={loading}/>
						<ActionHeading>Wheel list</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						{reloader && <Button loading={loading} disabled={loading} icon={'redo'} basic onClick={reloader}/>}
						<Button icon={'search'} basic onClick={this.showSearch(true)}/>
						<Button basic icon={'plus'} onClick={this.showEdit(true)}/>
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					<ConnectedWheelListView isLoading={this.setLoading} provideReload={this.setReloader} onSelect={this.setEditWheel} headerProps={{sticky: true}}/>
				</div>
				<Modal open={!!showEdit} centered={false} closeIcon onClose={this.showEdit(null)} closeOnDimmerClick={false}>
					<Modal.Header>Reifen {isPlainObject(showEdit) && showEdit.wheel_id}</Modal.Header>
					<Modal.Content>
						<ConnectedWheelEditor vehicleRequired onAfterChange={this.showEdit(null)} wheel={isPlainObject(showEdit) ? showEdit : null}/>
					</Modal.Content>
				</Modal>
				
				<WheelSearchModal
					open={this.state.show_search}
					onClose={() => this.setState({show_search: false})}
					closeIcon
					onSelect={wheel => console.warn('Wheel', wheel)}
					
				/>
			</Segment>
		);
	}
}
