import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-i18next';
import {IMAGE_URL} from '../../Logic/constants';
import {Form, FormField, FormInput, Responsive} from "semantic-ui-react";
import {Paper, RaisedButton, Tab, Tabs} from "material-ui";
import {FormLabel, Loadable, T, withScreen} from "../../Logic/extensions";
import EmailIcon from 'material-ui/svg-icons/communication/email';
import SecretIcon from 'material-ui/svg-icons/communication/vpn-key';
import HelpIcon from 'material-ui/svg-icons/action/help';
import {globals} from "ah-tools";
import {publish} from "../../Logic/PubSub";
import {SUB_LOGIN_SUCCESSFUL} from "../../actions";
import {requestSSEReconnect} from "../../cointainer/intern/SSEWrapper";

const MODE_NORMAL = 'normal';
const MODE_EMAIL = 'email';
const MODE_RESET = 'reset';

class LoginView extends Component {
	static propTypes = {
		onLogin: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
		isLoading: PropTypes.bool,
		house_id: PropTypes.number.isRequired,
		title: PropTypes.string,
		onEmailRequest: PropTypes.func.isRequired,
		onRequestPassword: PropTypes.func.isRequired,
		className: PropTypes.string,
		triggerSSEReconnect: PropTypes.bool
	};
	static defaultProps = {
		isLoading: false,
		onReset: () => alert('Passwort reset is not implemented yet')
	};
	
	
	login = {
		email: '',
		password: ''
	};
	
	state = {
		loading: false,
		mode: MODE_NORMAL
	};
	
	// handleChange = (e, {name, value}) => this.setState({[name]: value});
	
	_normalLogin = (email, password) => this.props.onLogin(email, password, () => this.props.triggerSSEReconnect && requestSSEReconnect());
	_emailLogin = (email) => this.props.onEmailRequest(email, this.props.house_id);
	_resetLogin = (email) => this.props.onRequestPassword(email, this.props.house_id);
	
	handleSubmit = () => {
		const {email, password} = this.login;
		const {mode} = this.state;
		switch(mode) {
			case MODE_EMAIL:
				this._emailLogin(email);
				break;
			case MODE_RESET:
				this._resetLogin(email);
				break;
			case MODE_NORMAL:
			default:
					this._normalLogin(email, password);
				
		}
	};
	
	handleChange = (e, {name, value}) => {
		this.login = {
			...this.login,
			[name]: value
		};
	};
	
	handleTabs = mode => this.setState({mode});
	
	label = (value) => {
		const {t, screen: {width}} = this.props;
		return width < 700 ? null : t(value);
	};
	
	render() {
		const {t, title, isLoading, logo, className, children} = this.props;
		const {mode} = this.state;
		return (
			<Form onSubmit={this.handleSubmit} className={className}>
				
				<Paper zDepth={2} rounded>
					<Tabs inkBarStyle={{backgroundColor: globals.COLOR_SECONDARY}} value={mode} onChange={this.handleTabs}>
						<Tab icon={<SecretIcon/>} label={this.label('login.label.normal')} value={MODE_NORMAL}/>
						<Tab icon={<EmailIcon />} label={this.label('login.label.email')} value={MODE_EMAIL}/>
						<Tab icon={<HelpIcon />} label={this.label('login.label.reset')} value={MODE_RESET}/>
					</Tabs>
					{children}
					<div style={{padding: '40px 40px 50px 40px', display: 'flex', flexDirection: 'column'}}>
						{/*{mode === MODE_RESET && <Label className={'pointer hover'} ribbon={'right'} style={{position: 'absolute', left: 'calc(100% + 1rem + 0.1em)', top: 20}} onClick={() => this.setState({mode: MODE_NORMAL})}>Abbrechen</Label>}*/}
						<div style={{display: 'flex', alignItems: 'center', flexWrap: "wrap", justifyContent: "center"}}>
							{ logo &&
							<Responsive minWidth={600} style={{marginRight: 40, maxHeight: 250, maxWidth: 200}}>
								<img src={`${IMAGE_URL}${logo.ident}.${logo.origin_extension}?${logo.updated_at}`} alt={title || 'Login'} style={{maxHeight: 250, maxWidth: 200}}/>
							</Responsive>
							}
							<div>
								<h2>{title || t('login.label.title')}</h2>
								<FormField>
									<FormInput
										labelPosition={'left'}
										label={t('login.input.email')}
										type={'email'}
										placeholder={t('login.input.email-placeholder')}
										name={'email'}
										onChange={this.handleChange}
										required
									>
										<FormLabel><EmailIcon/></FormLabel>
										<input/>
									</FormInput>
								</FormField>
								{mode === MODE_NORMAL &&
									<React.Fragment>
										<FormField>
											<FormInput
												labelPosition={'left'}
												label={t('login.input.password')}
												type={'password'}
												name={'password'}
												placeholder={t('login.input.password-placeholder')}
												onChange={this.handleChange}
												required
											>
												<FormLabel className={'pointer hover'} popup={{
													content: t('login.label.password-forgotten'),
													inverted: true,
													position: 'right center'
												}} onClick={() => this.handleChange({mode: MODE_RESET})}><SecretIcon/></FormLabel>
												<input/>
											</FormInput>
										</FormField>
										<FormField>
											<Loadable type={'submit'} style={{marginTop: 15}} inverse noCalling={false} disabledOnLoading loading={isLoading} primary fullWidth hoc={RaisedButton}>{t('actions.login')}</Loadable>
										</FormField>
									</React.Fragment>
								}
								{mode === MODE_RESET &&
									<React.Fragment>
										<T as={'p'}>login.info.email-password</T>
										<Loadable style={{marginTop: 15}} type={'submit'} inverse noCalling={false} disabledOnLoading loading={isLoading} secondary fullWidth hoc={RaisedButton}>{t('actions.reset-password')}</Loadable>
									</React.Fragment>
								}
								{mode === MODE_EMAIL &&
									<React.Fragment>
										<T as={'p'}>login.info.login-link</T>
										<Loadable hoc={RaisedButton} type={'submit'} style={{marginTop: 15}} primary inverse noCalling={false} disabledOnLoading loading={isLoading} fullWidth>{t('actions.request-password')}</Loadable>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</Paper>
			</Form>
		);
	}
}

export const requestHideLoginView = publish.bind(undefined, SUB_LOGIN_SUCCESSFUL);

export default withScreen({method: 'throttle'})(translate()(LoginView));