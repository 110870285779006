import * as React from "react";
import PropTypes from "prop-types";
import {Button, Segment, Table} from "semantic-ui-react";
import {withRights} from "../../../Tools";
import {SECTION} from "../../../Logic/constants";
import withInit from "../../../Logic/withInit";
import {connect} from "react-redux";
import {translate} from "react-i18next";
import {isOnline} from "../../../actions/userActions";
import {loadWorkers, workerAction__getActivities} from "../../../actions/workerActions";
import {get, groupBy} from "lodash";
import {PROC_WORKER_ACTIVITIES, STORE_SECTION_ACITIVITY} from "../../../actions";
import {isLoading} from "../../../actions/loaderActions";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading, Flex, OptionHeader} from "../../partials/ActionHeader";
import {MenuItem} from "material-ui";
import {IconPerson, IconRefresh} from "../../../Logic/icons";
import moment from "../../../Logic/Moment";
import User from "../../../models/User";
import {MiniMenu} from "../../../Logic/MiniMenu";
import {push} from "connected-react-router";
import {deepMemoize as memoizeOne, trueNull} from "../../../Logic/extensions";
import Countdown, {getFastTimeString} from "../../partials/Countdown";
import {kfzAction__fetchServices} from "../../../actions/kfzActions";
import {resourceAction__fetch} from "../../../actions/resourceActions";
import OrderQuickView from "../../../cointainer/intern/orders/OrderQuickView";
import {OrderViewDialog} from "../../../Tools/Dialog/Dialogs";
import KeyHandler from "react-key-handler";
import {NativeDayPicker as DayPicker} from "../../partials/DayPicker";

/*class Interval extends React.PureComponent {
	static propTypes = {
		duration: PropTypes.number
	};
	static defaultProps = {
		duration: 1000
	};
	state = {
		update: 0
	};
	componentDidMount() {
		this.timer = window.setInterval(() => {
			// console.debug('updating interval');
			this.forceUpdate();
		}, Math.max( 10, this.props.duration));
	}
	
	componentWillUnmount() {
		window.clearInterval(this.timer);
		this.timer = null;
	}
	
	render() {
		return this.props.children();
	}
}*/

const HoursOfWork = ({service, timeView = false}) => {
	if ( !service) return null;
	const {hours_of_work, hours_of_work_resource} = service;
	if ( timeView ) {
		return getFastTimeString(Math.round(hours_of_work_resource * 3600));
	}
	if ( hours_of_work === hours_of_work_resource) {
		return <span>{hours_of_work} Stunden</span>
	}
	return <span><small>{hours_of_work}</small> / {hours_of_work_resource} Stunden</span>
};

class OrderWorkerDetailView extends React.Component {
	static propTypes = {
		id: PropTypes.number,
		workerMap: PropTypes.object,
		activities: PropTypes.arrayOf(PropTypes.object),
		resourceMap: PropTypes.object,
		work: PropTypes.object,
		serviceMap: PropTypes.object,
		onLoad: PropTypes.func,
		goTo: PropTypes.func,
		loading: PropTypes.bool
	};
	static defaultProps = {
		loading: false
	};
	state = {
		day: new Date(),
		viewOrderID: 0,
		open: {}
	};
	
	toggleOpen = (order_id) => () => this.setState(state => {
		return ({
			...state,
			open: {
				...state.open,
				[order_id]: !state.open[order_id]
			}
		})
	});
	
	viewOrder = viewOrderID => (e) => {
		e && e.stopPropagation();
		this.setState({viewOrderID});
	};
	
	onRefresh = () => this.props.onLoad(this.state.day.toISOString());
	
	handleDay = (inc) => () => {
		let day;
		if ( !this.state.day ) {
			day = new Date();
		} else {
			day = moment(this.state.day).add(inc, 'day').toDate();
		}
		this.setState({day}, this.onRefresh);
	};
	
	duration = duration => getFastTimeString(duration);
	
	getActive =
		list => {
			for (const activity of list) {
				if (!activity.end_point) {
					return activity.start_point;
				}
			}
			return null;
		};
	
	getService = (order_id, service_id = null) => {
		const {work: {order2services, services}} = this.props;
		if ( order2services) {
			let v = order2services[order_id];
			if (v) {
				v = v.map(i => services[i]);
				if (service_id) {
					return v.find(s => s.service_id === service_id);
				}
				return v;
			}
		}
		return null;
	};
	
	grouped = memoizeOne(
		activites => Object.values(groupBy(activites, 'order_id'))
	);
	
	getIdent = ({order_id, service_id, resource_id}) => `${order_id}-${service_id}-${resource_id}`;
	// noinspection JSUnusedLocalSymbols
	sumChildren = (list, serviceMap) => {
		let track = {};
		let planned = 0;
		let made = 0;
		for (const item of list) {
			made += item.duration;
			let id = this.getIdent(item);
			if (id in track) {
				continue;
			}
			track[id] = true;
			let service = this.getService(item.order_id, item.service_id);
			if (service) {
				planned += (service.hours_of_work_resource === service.hours_of_work ? service.hours_of_work : service.hours_of_work_resource) * 3600;
			}
		}
		return [planned, made];
	}
	
	
	render() {
		const {
			id: workerID,
			loading,
			activities: list,
			workerMap,
			goTo,
			allRights,
			serviceMap,
			resourceMap,
			// work: {
				// orders,
				// services,
				// order2services
			// }
		} = this.props;
		const {
			day,
			viewOrderID
		} = this.state;
		const worker = workerMap[workerID];
		if ( !worker ) {
			return <Segment loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const Worker = new User(worker);
		const mayReadWorkers = allRights[SECTION.WORKERS].mayRead || false;
		let sumWorkTime = 0;
		// let startPoint = null;
		let activities = this.grouped(list);
		let sumPlanned = 0;// this.sumTotalPlaned(activities, serviceMap);
		return(
		<Segment basic>
				{trueNull(mayReadWorkers) &&
					<KeyHandler
						keyValue={"p"}
						onKeyHandle={goTo.bind(null, `/workers/detail/${workerID}`)}
					/>
				}
				<KeyHandler
					keyValue={"r"}
					onKeyHandle={this.onRefresh}
				/>
				<ActionHeader alignment={"space-between"} valign={"center"}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading loading={loading}>Arbeiten von {Worker.getName()}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<OptionHeader underWidth={900}>
							<Flex align={"center"} valign={"flex-end"}>
								<DayPicker placeholderText={null} loading={loading} date={day} onChange={day => this.setState({day}, this.onRefresh)}/>
							</Flex>
						</OptionHeader>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<MiniMenu>
							<MenuItem
								primaryText={"Aktualisieren"}
								secondaryText={"R"}
								leftIcon={<IconRefresh/>}
								onClick={this.onRefresh}
							/>
							{trueNull(mayReadWorkers) &&
								<MenuItem
									primaryText={"Zum Profil wechseln"}
									secondaryText={"P"}
									leftIcon={<IconPerson/>}
									onClick={goTo.bind(null, `/workers/detail/${workerID}`)}
								/>
							}
						</MiniMenu>
					</ActionHeaderGroup>
				</ActionHeader>
				{activities.length ?
					<div>
						<Table celled stackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Auftrag</Table.HeaderCell>
									<Table.HeaderCell>Service</Table.HeaderCell>
									<Table.HeaderCell>Geplante Dauer</Table.HeaderCell>
									<Table.HeaderCell>Ressource</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Startzeit</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Endzeit</Table.HeaderCell>
									<Table.HeaderCell textAlign={"center"}>Dauer</Table.HeaderCell>
									<Table.HeaderCell/>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{activities.map(group => {
									let first = group[0];
									let open = Boolean(this.state.open[first.order_id]);
									let [planned, made] = this.sumChildren(group, serviceMap);
									sumPlanned += planned;
									sumWorkTime += made;
									return <React.Fragment key={first.order_id}>
										<Table.Row style={{backgroundColor: '#ccc'}} className={'cursor pointer'} onClick={this.toggleOpen(first.order_id)}>
											<Table.Cell>{first.order_id}</Table.Cell>
											<Table.Cell>{group.length} Service</Table.Cell>
											<Table.Cell>{getFastTimeString(planned)}</Table.Cell>
											<Table.Cell colSpan={3}>&nbsp;</Table.Cell>
											
											<Table.Cell textAlign={'center'}>{getFastTimeString(made)}</Table.Cell>
											<Table.Cell textAlign={'right'}>
												<Button.Group basic size={'small'}>
													<Button basic icon={"info"} size={"small"} circular onClick={this.viewOrder(first.order_id)}/>
													<Button icon={open ? 'minus' : 'plus'} size={'small'} onClick={e => {
														// noinspection JSUnresolvedFunction
														e.stopPropagation();
														this.toggleOpen(first.order_id)();
													}}/>
												</Button.Group>
											</Table.Cell>
										</Table.Row>
										{trueNull(open) && group.map(a => {
											const duration = this.duration(a.duration);
											// sumWorkTime += a.duration;
											// if (!a.end_point) {
											// 	startPoint = a.start_point;
											// }
											const serviceInfo = serviceMap[a.service_id] || {};
											const resource = resourceMap[a.resource_id] || {};
											const service = this.getService(a.order_id, a.service_id) || {};
											return (
												<Table.Row key={a.work_id}>
													<Table.Cell>&nbsp;</Table.Cell>
													<Table.Cell>{serviceInfo.title}</Table.Cell>
													<Table.Cell><HoursOfWork service={service} timeView/></Table.Cell>
													<Table.Cell>{resource.name}</Table.Cell>
													<Table.Cell textAlign={"center"}>{moment(a.start_point).format("LLL")}</Table.Cell>
													<Table.Cell textAlign={"center"}>{a.end_point ? moment(a.end_point).format("LLL") : "-"}</Table.Cell>
													<Table.Cell textAlign={"center"}>{a.end_point ? duration : <Countdown targetDate={a.start_point} render={(_, seconds) => getFastTimeString(seconds)}/>}</Table.Cell>
													<Table.Cell textAlign={"center"}>&nbsp;</Table.Cell>
												</Table.Row>
											);
										})}
									</React.Fragment>
								})}
								
								
							</Table.Body>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell/>
									<Table.HeaderCell/>
									<Table.HeaderCell>Gepl. Ges.Dauer</Table.HeaderCell>
									<Table.HeaderCell/>
									<Table.HeaderCell/>
									<Table.HeaderCell/>
									<Table.HeaderCell textAlign={"center"}>Ges. Dauer</Table.HeaderCell>
									<Table.HeaderCell/>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell/>
									<Table.Cell/>
									<Table.Cell>{getFastTimeString(sumPlanned)}</Table.Cell>
									<Table.Cell/>
									<Table.Cell/>
									<Table.Cell/>
									<Table.Cell textAlign={"center"}>{getFastTimeString(sumWorkTime)}{/*{startPoint ? <Interval>{ () => getFastTimeString(round((Date.now() - startPoint + sumWorkTime  * 1000) / 1000, 0))}</Interval> : this.duration(sumWorkTime)}*/}</Table.Cell>
									<Table.Cell/>
								</Table.Row>
							</Table.Body>
						</Table>
					</div> :
				    <div>
					Noch keine Aktivitäten am {moment(day).format("LL")}
					</div>
				}
				<OrderViewDialog open={viewOrderID > 0} onClose={this.viewOrder(0)}>
					<OrderQuickView order_id={viewOrderID} onRequestClose={this.viewOrder(0)}/>
				</OrderViewDialog>
			</Segment>
		);
	}
}

OrderWorkerDetailView = withInit(OrderWorkerDetailView);
OrderWorkerDetailView = connect(
	(state, props) => {
		const id = Number(props.match.params.id);
		return {
			id,
			activities: state.workers.activities,
			resourceMap: state.resources.list,
			work: get(state, ["appointments", "section", STORE_SECTION_ACITIVITY], {}),
			workerMap: state.workers.list,
			loading: isLoading(state, PROC_WORKER_ACTIVITIES),
			serviceMap: state.kfz.service.list || {}
		};
	},
	(dispatch, props) => {
		const id = Number(props.match.params.id);
		return {
			init: () => dispatch(isOnline(() => {
				dispatch(loadWorkers());
				dispatch(workerAction__getActivities('now', id));
				dispatch(kfzAction__fetchServices());
				dispatch(resourceAction__fetch());
			})),
			onLoad: (day) => dispatch(workerAction__getActivities(day, id)),
			goTo: link => dispatch(push(link))
		};
	}
)(OrderWorkerDetailView);
OrderWorkerDetailView = withRights(SECTION.ORDERS, OrderWorkerDetailView);
OrderWorkerDetailView = translate()(OrderWorkerDetailView);

export default OrderWorkerDetailView;