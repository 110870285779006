
import React from 'react'
import {Icon} from "semantic-ui-react";
import PropTypes from 'prop-types'

export const OnlineIcon = ({online, ...props}) => {
	return <Icon name={'circle'} size={'small'} color={online ? 'green' : 'red'} {...props}/>;
}

OnlineIcon.propTypes = {
	online: PropTypes.any
}