import * as React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import isFunc from "lodash/isFunction";
import isString from "lodash/isString";
import get from "lodash/get";
import {Portal} from "semantic-ui-react";
import IconSVG from "material-ui/svg-icons/av/playlist-add";
import IconClose from "material-ui/svg-icons/navigation/close";
import {deepMemoize} from "../../../Logic/extensions";

class ResourceSelector extends React.Component {
	static propTypes = {
		resourceList: PropTypes.array.isRequired,
		style: PropTypes.object,
		onTop: PropTypes.bool,
		onLeft: PropTypes.bool,
		positionalAwareness: PropTypes.bool,
		icon: PropTypes.oneOf([null, undefined, true, false, 'left', 'center', 'right']),
		inline: PropTypes.bool,
		onSubmit: PropTypes.func.isRequired,
		getKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		inputProps: PropTypes.object,
		iconContent: PropTypes.node,
		selectContent: PropTypes.node,
		iconProps: PropTypes.object,
		defaultPrevented: PropTypes.bool,
		debug: PropTypes.bool,
		focusOnSelect: PropTypes.bool,
		labelContent: PropTypes.node,
		buttonContent: PropTypes.node
	};
	static defaultProps = {
		resource_list: [],
		onTop: false,
		onLeft: false,
		positionalAwareness: false,
		icon: false,
		inline: false,
		onSubmit: () => alert('onSubmit() is not implemented yet!'),
		selectContent: 'SELECT',
		defaultPrevented: true,
		debug: false,
		focusOnSelect: false,
		labelContent: 'Hours of Work',
		buttonContent: 'Save'
	};
	
	element = null;
	listElement = null;
	formElement = null;
	inputElement = null;
	
	state = {
		visible: false,
		selected: -1,
		onLeft: false,
		onTop: false,
		onCenter: false,
		value: '',
		bounds: {},
		style: {}
	};
	
	debug = (how, ...what) => {
		how = ["debug", "info", "log", "warn", "error"].indexOf(how) >= 0 ? how : "debug";
		if ( this.props.debug ) {
			console[how].apply(this, what);
		}
	};
	
	componentDidMount() {
		const {onLeft, onTop} = this.props;
		this.setState({
			onLeft,
			onTop
		});
		document.addEventListener('click', this.clickAwayEvent, true);
	}
	
	componentWillUnmount() {
		document.removeEventListener('click', this.clickAwayEvent);
	}
	// OKAY
	// componentWillReceiveProps({onLeft, onTop}, {onLeft: onLeftBefore, onTop: onTopBefore}) {
	// 	if ( onTopBefore !== onTop || onLeftBefore !== onLeft ) {
	// 		this.setState(state => ({
	// 			onLeft: onLeft !== undefined ? Boolean(onLeft) : state.onLeft,
	// 			onTop: onTop !== undefined ? Boolean(onTop) : state.onTop
	// 		}));
	// 	}
	// }
	
	componentDidUpdate(prevProps, {onLeft: onLeftBefore, onTop: onTopBefore}, snapshot) {
		const {onLeft, onTop} = this.props
		if ( onTopBefore !== onTop || onLeftBefore !== onLeft ) {
			this.setState(state => ({
				onLeft: onLeft !== undefined ? Boolean(onLeft) : state.onLeft,
				onTop: onTop !== undefined ? Boolean(onTop) : state.onTop
			}));
		}
	}
	
	
	clickAwayEvent = (e) => {
		this.debug(null, 'ResourceSelector: clickaway', this.element, this.listElement, this.inputElement);
		if ( (this.element && !this.element.contains(e.target)) && (this.listElement && !this.listElement.contains(e.target))) {
			this.setState({
				visible: false,
				selected: -1
			});
		}
	};
	
	onSelect = () => {
		const {positionalAwareness} = this.props;
		const {visible, selected} = this.state;
		if (this.formElement && positionalAwareness && visible && selected >= 0 ) {
			this.debug("debug", 'ok now test position after select...');
			const data = this.formElement.getBoundingClientRect();
			if ( data.left < 0 ) {
				this.debug("debug", 'ResourceSelector: is too left');
				this.setState({onLeft: false});
			} else if (window.innerWidth - data.right < 0) {
				this.debug("debug", 'ResourceSelector: is too right');
				this.setState({onLeft: true});
			}
		}
	};
	
	select = (selected = -1) => () => this.setState(state => ({
		...state,
		selected: selected === state.selected ? -1 : selected
	}), () => {
		this.onSelect();
	});
	
	show = (visible = null, selected = null) => () => {
		this.setState(state => ({
			...state,
			visible: null !== visible ? visible : false,
			selected: null !== selected ? selected : state.selected
		}));
		
	};
	
	onSubmit = (e) => {
		const {onSubmit, resourceList, defaultPrevented} = this.props;
		const {selected, value} = this.state;
		const data = {
			value,
			resource: resourceList[selected],
			index: selected,
			close: this.show(false, -1),
			deselect: this.select(-1)
		};
		defaultPrevented && e.preventDefault();
		onSubmit(data, e);
	};
	
	key = entry => {
		const {getKey} = this.props;
		if (isString(getKey)) {
			return get(entry, getKey, entry);
		}
		if (isFunc(getKey)) {
			return getKey(entry);
		}
		return entry;
	};
	
	render() {
		const {
			resourceList,
			className,
			icon,
			inline,
			iconContent,
			iconProps,
			style,
			selectContent,
			inputProps,
			buttonContent,
			labelContent,
			positionalAwareness,
			focusOnSelect
		} = this.props;
		
		const {
			visible,
			selected,
			onTop,
			onLeft
		} = this.state;
		
		// const resourceCount = resourceList.length;
		return (
			<div className={cn(className, "rs-selector", {
				inline,
				icon,
				"on-left": onLeft,
				"on-top": onTop,
				selected: selected >= 0,
				open: visible,
				"icon-left": "left" === icon,
				"icon-right": "right" === icon
			})} ref={r => this.element = r} style={style}>
				<span className={cn("rs-selector-button", {hidden: visible})} onClick={this.show(!visible)} {...iconProps}>{icon ? (iconContent || <IconSVG style={{width: 32, height: 32}}/>) : selectContent}</span>
				{ visible &&
				<SelectorForm
					debug={this.debug}
					resourceList={resourceList}
					onSubmit={this.onSubmit}
					inputProps={inputProps}
					inputRef={r => this.inputElement = r}
					formRef={r => this.formElement = r}
					listRef={r => this.listElement = r}
					selected={selected}
					icon={icon}
					buttonContent={buttonContent}
					labelContent={labelContent}
					iconContent={iconContent}
					inline={inline}
					onLeft={onLeft}
					onTop={onTop}
					onShow={this.show}
					onSelect={this.select}
					onKey={this.key}
					client={this.element}
					positionalAwareness={positionalAwareness}
					focusOnSelect={focusOnSelect}
					onValue={e => this.setState({value: e.target.value})}
				/>
				}
			</div>
		);
	}
}

const CloseLine = ({selected, resourceList, getKey, onSelect, onShow, open}) => !open ? null : (
	<div className={cn("rs-selector-closer")} onClick={() => selected >= 0 ? onSelect(-1)() : onShow(false)() }>
		<span className={cn("rs-selector-close-icon")} ><IconClose color={"red"}/></span>
		{selected >= 0 && <span className={cn("rs-selector-inline")}>{getKey(resourceList[selected])}</span>}
	</div>
);
CloseLine.propTypes = {
	selected: PropTypes.number,
	resourceList: PropTypes.array,
	onSelect: PropTypes.func,
	onShow: PropTypes.func,
	open: PropTypes.bool
};



const EditField = ({selected, onSubmit, formRef, inputRef, inputProps, buttonContent, labelContent, focusOnSelect, onValue, open}) => {
	if ( !open ) {
		return null;
	}
	if ( selected < 0 ) {
		return null;
	}
	
	const rndId = String(Math.random()).replace('.', '');
	return(
		<div className={cn("rs-selector-form")} ref={formRef}>
			<form onSubmit={onSubmit}>
				<div><label className={cn("rs-selector-label")} htmlFor={`change-${rndId}`}>{labelContent}</label></div>
				<div><input onChange={onValue} ref={r => {
					inputRef(r);
					if ( focusOnSelect && r ) {
						r.focus();
					}
				}} className={cn("rs-selector-input")} type="text" id={`change-${rndId}`}
				            placeholder="hours" {...inputProps} /></div>
				<div>
					<button className={cn("rs-selector-submit")}>{buttonContent}</button>
				</div>
			</form>
		</div>
	);
	
};
EditField.propTypes = {
	selected: PropTypes.number,
	onSubmit: PropTypes.func,
	formRef: PropTypes.func,
	inputRef: PropTypes.func,
	inputProps: PropTypes.object,
	buttonContent: PropTypes.node,
	labelContent: PropTypes.node,
	focusOnSelect: PropTypes.bool,
	onValue: PropTypes.func,
	open: PropTypes.bool
};


class SelectorForm extends React.PureComponent {
	state = {
		ref: null,
		onTop: false
	};
	
	updateOnTop = deepMemoize(onTop => this.setState({onTop}))
	
	componentDidMount() {
		this.updateOnTop(this.props.onTop)
		// this.setState({onTop: this.props.onTop});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// const {onTop} = this.props
		// this.setState({onTop});
		this.updateOnTop(this.props.onTop)
	}
	
	pos = (el ) => {
		let pos = {top:0, left: 0};
		if (el) {
			const t = el.getBoundingClientRect();
			pos = {
				width: t.width,
				height: t.height,
				top: t.top,
				left: t.left
			};
		}
		pos.top += (window.scrollTop || window.pageYOffset || 0);
		pos.left += (window.scrollLeft || window.pageXOffset || 0);
		return pos;
	};
	
	verticalAwareness = () => {
		const {onTop, ref} = this.state;
		const {onTop: onTopGiven, positionalAwareness} = this.props;
		if (positionalAwareness && ref && onTopGiven === onTop) {
			const bounds = ref.getBoundingClientRect();
			if (onTop) {
				if ( bounds.top < 0) {
					this.setState({onTop: false});
				}
			} else {
				if ( bounds.bottom > window.innerHeight) {
					this.setState({onTop: true});
				}
			}
		}
	};
	
	render() {
		const {debug, inline, icon, onLeft, onValue, selected, resourceList, onSelect, onShow, onSubmit, formRef, inputRef, listRef, inputProps, buttonContent, labelContent, onKey,  client, focusOnSelect} = this.props;
		const {ref, onTop} = this.state;
		const clientData = client ? this.pos(client) : {left: 0, top: 0, width: 0, height: 0};
		const relative = {};
		const thisData = ref ? ref.getBoundingClientRect() : {width: 0, height: 0};
		debug("debug", "SelectorForm: icon =", icon, "thisData =", thisData);
		switch(icon) {
			case true:
			case "center":
				debug(null, 'SelectorForm: icon centered selected');
				relative.left = -thisData.width/2 + clientData.width/2;
				break;
			case "right":
				debug(null, 'SelectorForm: icon on right selected');
				relative.left = null;
				relative.right = 0;
				break;
			default:
				break;
		}
		if( onTop ) {
			relative.bottom = 0;
			relative.top = null;
		}
		debug("debug", "SelectorForm: relative =", relative, "clientData =", thisData);
		return(
			<Portal open>
				<div className="rs-selector-position" style={{...clientData, position: 'absolute'}}>
					<div className={cn("rs-selector-frame", {
						inline, icon, "on-left": onLeft, "on-top": onTop, selected: 0 <= selected,
						"icon-left": "left" === icon,
						"icon-right": "right" === icon
					})} style={{position: 'absolute', ...relative}} ref={r => {
						debug("debug", "SelectorForm: list-ref =", r);
						listRef(r);
						this.setState({ref: r}, () => {
							this.verticalAwareness();
						});
					}}>
						
						<div className={cn("rs-selector-container")}>
							<EditField onValue={onValue} selected={selected} onSubmit={onSubmit} formRef={formRef} inputRef={inputRef} focusOnSelect={focusOnSelect}
							           inputProps={inputProps} buttonContent={buttonContent} labelContent={labelContent}
							           open={onLeft}/>
							<div>
								<CloseLine selected={selected} resourceList={resourceList} onSelect={onSelect}
								           onShow={onShow} getKey={onKey} open={!onTop}/>
								<ul className={cn("rs-selector-list")}>
									{resourceList.map((data, idx) =>
										<li className={cn("rs-selector-element", {selected: idx === selected})}
										    key={`rs-${idx}`} onClick={onSelect(idx)}>
											{onKey(data)}
										</li>
									)}
								</ul>
								<CloseLine selected={selected} resourceList={resourceList} onSelect={onSelect}
								           onShow={onShow} getKey={onKey} open={onTop}/>
							</div>
							<EditField onValue={onValue} selected={selected} onSubmit={onSubmit} formRef={formRef} inputRef={inputRef}  focusOnSelect={focusOnSelect}
							           inputProps={inputProps} buttonContent={buttonContent} labelContent={labelContent}
							           open={!onLeft}/>
						</div>
					</div>
				</div>
			</Portal>
		);
	}
}

SelectorForm.propTypes = {
	resourceList: PropTypes.array.isRequired,
	onTop: PropTypes.bool,
	onLeft: PropTypes.bool,
	focusOnSelect: PropTypes.bool,
	icon: PropTypes.oneOf([null, undefined, true, false, 'left', 'center', 'right']),
	inline: PropTypes.bool,
	positionalAwareness: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	inputProps: PropTypes.object,
	iconContent: PropTypes.node,
	labelContent: PropTypes.node,
	buttonContent: PropTypes.node,
	inputRef: PropTypes.func,
	listRef: PropTypes.func,
	formRef: PropTypes.func,
	selected: PropTypes.number,
	onSelect: PropTypes.func,
	onShow: PropTypes.func,
	onKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	client: PropTypes.object,
	debug: PropTypes.func.isRequired,
	onValue: PropTypes.func
};

export default ResourceSelector;