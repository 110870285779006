import * as React from 'react';
import PropTypes from 'prop-types';
import {Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../../../partials/ActionHeader";
import LoanCarMask from "./LoanCarMask";
import {translate} from "react-i18next";
import NoAccess from "../../../NoAccess";

const LoanCarCreateView = ({onBack, isLoading, ...props}) => {
	const {t, mayCreate} = props;
	if ( !mayCreate ) {
		return <NoAccess/>;
	}
	return (
		<Segment basic>
			<ActionHeader alignment={'space-between'}>
				<ActionHeaderGroup>
					{onBack && <ActionBackButton onBack={onBack}/>}
					<ActionHeading loading={isLoading}>{t("loan-car.create-title")}</ActionHeading>
				</ActionHeaderGroup>
			</ActionHeader>
			<LoanCarMask  {...props} isLoading={isLoading}/>
		</Segment>
	);
};

LoanCarCreateView.propTypes = {
	...LoanCarMask.propTypes,
	onBack: PropTypes.func,
	isLoading: PropTypes.bool,
	onSave: PropTypes.func.isRequired
};
LoanCarCreateView.defaultProps = {
	...LoanCarMask.defaultProps,
	isLoading: false,
	onSave: () => alert('LoanCarCreateView.onCreate() is not implemented yet!')
};

export default translate()(LoanCarCreateView);