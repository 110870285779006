import {MAP_ACTIONS, RESET_ACTION} from "../actions/types";
import {array2object} from "../Logic/extensions";
import {isArray, isInteger, isPlainObject, omit, mapValues} from "lodash";
import localForage from "localforage";

const updateForage = (index) => (object) => {
	localForage.setItem(MAP_ACTIONS.INDEX(index), JSON.stringify(object));
	return object;
};

// const getForage = (index) => localForage.getItem(_index(index) )



const defaultState = {
	orders: {},
	order2vehicles: {},
	vehicles: {},
	clients: {},
	releases: {},
	order2services: {},
	order2service2resources: {},
	workers: {},
	resources: {},
	kfz: {},
	kfz_model: {},
	services: {},
	work: {},
	freedays_workers: {},
	freedays: {},
	schedule: {},
	loan_cars: {},
	wheels: {}
};

localForage.config();


const handleInsert = (data, access, action = 'undefinedAction') => {
	if (isArray(data)) {
		return array2object(data)(d => Number(d[access]) || d[access]);
	}
	if (isPlainObject(data)) {
		if (access in data) {
			return {
				[Number(data[access]) || data[access]]: data
			};
		}
		return data;
	}
	console.warn(`mapReducer: Action #${action}[${access}] failed on insert with: `, data);
	return {};
};

const handleRemove = (data, access, action) => {
	if (!data) {
		return [];
	}
	if (isInteger(data) || isPlainObject(data)) {
		data = [data];
	}
	if ( isArray(data)) {
		return data.map(d => isPlainObject(d) ? (Number(d[access]) || d[access]) : (Number(d) || d)).filter(Boolean);
	}
	console.warn(`mapReducer: Action #${action}[${access}] failed on remove with: `, data);
	return null;
};


const insertion = (state, forage = false) => (id, data, accessor, type) => ({
	...state,
	[id]: forage ? updateForage(id)({
		...state[id],
		...handleInsert(data, accessor, type)
	}) : {
		...state[id],
		...handleInsert(data, accessor, type)
	}
});
const extraction = (state, forage = false) => (id, data, accessor, type) => {
	const next = handleRemove(data, accessor, type);
	return null === next ? state : {
		...state,
		[id]: forage ? updateForage(id)(next.length ? omit(state[id], next) : {}) : (next.length ? omit(state[id], next) : {})
	};
};

const defaultMapper = {services: 'order2services', resources: 'order2service2resources', order_vehicles: 'order2vehicles', house_services: 'services', house_resources: 'resources'};

const mapKeys = (data, mapper = {}) => {
	mapper = {...defaultMapper, ...mapper};
	// console.debug('mapping keys of', mapper);
	let back = {};
	for(const [i, k] of Object.entries(mapper)) {
		if (i in data) {
			if (k in defaultState) {
				back[k] = data[i];
			}
			delete(data[i]);
			if (k in data) {
				delete data[k];
			}
		}
	}
	for(const [i, k] of Object.entries(data)) {
		// let key = i in mapper ? mapper[i] : i;
		// console.debug('found key ', key);
		if (i in defaultState) {
			back[i] = k;
			
		}
	}
	return back;
};

const accessor = {
	orders: 'order_id',
	releases: 'order_service_id',
	order2services: 'order_service_id',
	order2service2resources: 'order_service_resource_id',
	work: 'work_id',
	clients: 'client_id',
	vehicles: 'vehicle_id',
	workers: 'workers_id',
	order2vehicles: 'order_vehicle_id',
	loan_cars: 'loan_car_schedule_id',
	kfz: 'kfz_id',
	kfz_model: 'model_id',
	resources: 'resource_id',
	services: 'service_id',
	wheels: 'wheel_id'
};

const multiInsertion = (state, holder, mapping = {}) => {
	holder = mapKeys(holder, mapping);
	for (const [key, data] of Object.entries(holder)) {
		const access = accessor[key];
		if (access) {
			state = insertion(state, false)(key, data, access);
		} else {
			console.error('state', access, 'not found');
		}
	}
	return state;
};


export default (state = defaultState, {
	type, orders, releases, order2services, order2service2resources, work, clients,
	vehicles, workers, order2vehicles, services, sessionState, loan_cars, kfz, kfz_model, resources, holder, mapping, wheels
	
}) => {
	switch (type) {
		case RESET_ACTION:
			return defaultState;
		case MAP_ACTIONS.INIT:
			return {
				...state,
				...sessionState
			};
		case MAP_ACTIONS.MULTI:
			return multiInsertion(state, holder, mapping);
		case MAP_ACTIONS.ORDERS:
			return insertion(state, false)('orders', orders, 'order_id', type);
		case MAP_ACTIONS.ORDERS_REMOVE:
			return extraction(state, false)('orders', orders, 'order_id', type);
		case MAP_ACTIONS.RELEASES:
			return insertion(state, false)('releases', releases, 'order_service_id', type);
		case MAP_ACTIONS.RELEASED_REMOVE:
			return extraction(state, false)('releases', releases, 'order_service_id', type);
		case MAP_ACTIONS.ORDER_SERVICES:
			return insertion(state, false)('order2services', order2services, 'order_service_id', type);
		case MAP_ACTIONS.ORDER_SERVICES_REMOVE:
			return extraction(state, false)('order2services', order2services, 'order_service_id', type);
		case MAP_ACTIONS.ORDER_SERVICE_RESOURCES:
			return insertion(state, false)('order2service2resources', order2service2resources, 'order_service_resource_id', type);
		case MAP_ACTIONS.ORDER_SERVICE_RESOURCES_REMOVE:
			return extraction(state, false)('order2service2resources', order2service2resources, 'order_service_resource_id', type);
		case MAP_ACTIONS.WORK:
			return insertion(state, false)('work', work, 'work_id', type);
		case MAP_ACTIONS.WORK_REMOVE:
			return extraction(state, false)('work', work, 'work_id', type);
		case MAP_ACTIONS.CLIENTS:
			return insertion(state, false)('clients', clients, 'client_id', type);
		case MAP_ACTIONS.CLIENTS_REMOVE:
			return extraction(state, false)('clients', clients, 'client_id', type);
		case MAP_ACTIONS.VEHICLES:
			return insertion(state, false)('vehicles', vehicles, 'vehicle_id', type);
		case MAP_ACTIONS.VEHICLES_REMOVE:
			return extraction(state, false)('vehicles', vehicles, 'vehicle_id', type);
		case MAP_ACTIONS.WORKERS:
			return insertion(state, false)('workers', workers, 'workers_id', type);
		case MAP_ACTIONS.WORKERS_REMOVE:
			return extraction(state, false)('workers', workers, 'workers_id', type);
		case MAP_ACTIONS.ORDER_VEHICLES:
			return insertion(state, false)('order2vehicles', order2vehicles, 'order_vehicle_id', type);
		case MAP_ACTIONS.ORDER_VEHICLES_REMOVE:
			return extraction(state, false)('order2vehicles', order2vehicles, 'order_vehicle_id', type);
		case MAP_ACTIONS.SERVICES:
			return insertion(state, false)('services', services, 'service_id', type);
		case MAP_ACTIONS.SERVICES_REMOVE:
			return extraction(state, false)('services', services, 'service_id', type);
		case MAP_ACTIONS.LOAN_CAR:
			return insertion(state, false)('loan_cars', loan_cars, 'loan_car_schedule_id', type);
		case MAP_ACTIONS.LOAN_CAR_REMOVE:
			return extraction(state, false)('loan_cars', loan_cars, 'loan_car_schedule_id', type);
		case MAP_ACTIONS.KFZ:
			return insertion(state, false)('kfz', kfz, 'kfz_id', type);
		case MAP_ACTIONS.KFZ_REMOVE:
			return extraction(state, false)('kfz', kfz, 'kfz_id', type);
		case MAP_ACTIONS.KFZ_MODELS:
			return insertion(state, false)('kfz_model', kfz_model, 'model_id', type);
		case MAP_ACTIONS.KFZ_MODELS_REMOVE:
			return extraction(state, false)('kfz_model', kfz_model, 'model_id', type);
		case MAP_ACTIONS.RESOURCES:
			return insertion(state, false)('resources', resources, 'resource_id', type);
		case MAP_ACTIONS.RESOURCES_REMOVE:
			return extraction(state, false)('resources', resources, 'resource_id', type);
		case MAP_ACTIONS.WHEELS:
			return insertion(state, false)('wheels', wheels, 'wheel_id', type);
		case MAP_ACTIONS.WHEELS_REMOVE:
			return extraction(state, false)('wheels', wheels, 'wheel_id', type);
		case MAP_ACTIONS.WORKERS_LOGOUT:
			return {
				...state,
				workers: mapValues(state.workers, worker => {
					worker.logged_at = null
					return worker
				})
			}
		default:
			return state;
	}
};