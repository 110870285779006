import * as React from "react";
import PropTypes from "prop-types";
import {EasyFlex, FlexItem} from "../../../components/partials/ActionHeader";
import {Checkbox, TextField} from "material-ui";
import {translate} from "react-i18next";
import {Popup} from "semantic-ui-react";


let OrderKmUpdate = ({t, tReady, i18n, km, onUpdate, error, onChange, ...props}) => {
	return(
		<EasyFlex direction={EasyFlex.direction.COLUMN} style={{marginTop: 20, marginBotton: 10}} {...props}>
			<FlexItem grow={4}>
				<TextField
					fullWidth
					type={"number"}
					min={km}
					step={1}
					errorText={error}
					floatingLabelText={`Aktuelle Kilometer (${km})`}
					hintText={`${km} km`}
					onChange={(_, value) => onChange(value)}
				/>
			</FlexItem>
			<FlexItem grow={1}>
				<Checkbox
					title={"Km aktualisieren"}
					label={
						<Popup
							content={"Original Fahrzeug-km zusätzlich aktualisieren."}
							inverted
							trigger={
								<span style={{whiteSpace: "nowrap"}}>Km aktualisieren</span>}/>
						}
					onCheck={(_, update_km) => onUpdate(update_km)}/>
			</FlexItem>
		</EasyFlex>
	);
};
OrderKmUpdate.propTypes = {
	km: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]).isRequired,
	onUpdate: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};
OrderKmUpdate = translate()(OrderKmUpdate);
export {OrderKmUpdate};