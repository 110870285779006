import React from 'react'
import PropTypes from 'prop-types'
import {withRights} from "../../../../Tools";
import {SECTION} from "../../../../Logic/constants";
import {withModule} from "../../../../Tools/RightsProvider";
import {houseCall_getEwoTw, houseCall_postEwoTw} from "../../../../actions/carhouseActions";
import {dispatchSnack} from "../../../../actions/snackbarActions";
import {AHToggle, deepMemoize as memoizeOne, ListItemMargined} from "../../../../Logic/extensions";
import {Card, CardHeader, CardText} from "material-ui";
import EditField from "../../../partials/EditField";
import {IconSettings} from "../../../../Logic/icons";
import {Button, Form} from "semantic-ui-react";
import {EasyFlex} from "../../../partials/ActionHeader";
import {Trans} from "react-i18next";

export class MDEwoTW extends React.Component {
	static propTypes = {
		onLoad: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		onNotification: PropTypes.func.isRequired,
		expandable: PropTypes.bool
	}
	static defaultProps = {
		onLoad: () => houseCall_getEwoTw(),
		onSave: data => houseCall_postEwoTw(data),
		onNotification: dispatchSnack
	}
	state = {
		loading: false,
		saving: false,
		data: {
			house_id: null,
			active: false,
			username: '',
			password: '',
			url: '',
			tenant_id: '',
			branch_id: '',
			tenant_name: ''
		}
	}
	
	componentDidMount() {
		this.load()
	}
	
	load = async () => {
		const {onLoad, onNotification} = this.props
		try {
			this.setState({loading: true})
			const data = await onLoad()
			this.updateData(data)
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({loading: false})
		}
	}
	
	save = async () => {
		const {onSave, onNotification} = this.props
		const {loading, saving} = this.state
		if (loading || saving) return
		try {
			this.setState({saving: true})
			const data = await onSave(this.state.data)
			this.updateData(data)
			onNotification('Einstellungen wurden übernommen')
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({saving: false})
		}
	}
	
	setData = data => this.setState(state => ({
		...state,
		data: {
			...state.data,
			...data
		}
	}))
	
	updateData = memoizeOne(this.setData)
	
	render() {
		const {user, rights: {mayChange}, modules, expandable} = this.props;
		if (!modules || !mayChange) {
			return null;
		}
		const {
			loading, saving,
			data: {
				active, username, password, url, tenant_id, branch_id, tenant_name
			}
		} = this.state
		const editable = user.is_support
		return (
			<Card>
				<CardHeader title={'EwoTW Reifenanbindung'} subtitle={'Aktivierung & Einstellungen'} actAsExpander={expandable} showExpandableButton={expandable}/>
				<CardText expandable={expandable}>
					<Form onSubmit={this.save}>
						<ListItemMargined
							primaryText={'Aktiv'}
							secondaryText={active ? 'EwoTW Reifenanbindung aktiv' : 'EwoTW Reifenanbindung inaktiv'}
							rightToggle={<AHToggle disabled={!editable} toggled={active} onToggle={(e, active) => this.setData({active})}/>}/>
						{active && <React.Fragment>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'Benutzername'}
									name={'username'}
									value={username}
									editable={editable}
									onChange={(e, {value: username}) => this.setData({username})}
								/>
							</Form.Field>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'Passwort'}
									type={'password'}
									name={'password'}
									value={password}
									editable={editable}
									onChange={(e, {value: password}) => this.setData({password})}
								/>
							</Form.Field>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'URL (Base)'}
									name={'url'}
									value={url}
									editable={editable}
									onChange={(e, {value: url}) => this.setData({url})}
								/>
							</Form.Field>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'Tenant Name'}
									name={'tenant_name'}
									value={tenant_name}
									editable={editable}
									onChange={(e, {value: tenant_name}) => this.setData({tenant_name})}
								/>
							</Form.Field>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'Tenant ID'}
									name={'tenant_id'}
									value={String(tenant_id)}
									editable={editable}
									type={'number'}
									min={0}
									step={1}
									onChange={(e, {value: tenant_id}) => this.setData({tenant_id})}
								/>
							</Form.Field>
							<Form.Field>
								<div style={{height: 12}}/>
								<EditField
									icon={<IconSettings/>}
									text={'Branch ID'}
									name={'branch_id'}
									value={String(branch_id)}
									editable={editable}
									type={'number'}
									min={0}
									step={1}
									onChange={(e, {value: branch_id}) => this.setData({branch_id})}
								/>
							</Form.Field>
						</React.Fragment>}
						{editable && <EasyFlex align={EasyFlex.align.END}>
							<Button positive loading={loading || saving}><Trans i18nKey={'actions.save'}/></Button>
						</EasyFlex>}
					</Form>
				</CardText>
			</Card>
		);
	}
}

MDEwoTW = withRights(SECTION.MASTER_DATA, MDEwoTW)
MDEwoTW = withModule(MDEwoTW)