import * as React from 'react'
import {StatyComponent} from "../../../Tools/ReactExtension";
import PropTypes from "prop-types";
import {wheelAction_delete, wheelAction_put, wheelAction_update, wheelCall_delete, wheelCall_put, wheelCall_update} from "../../../actions/wheelActions";
import {dispatchSnack} from "../../../actions/snackbarActions";
import {SECTION} from "../../../Logic/constants";
import {Client, Vehicle, Wheel} from "../../../models";
// import memoizeOne from 'memoize-one'
import {deepMemoize as memoizeOne, falseNull, Required, trueNull} from "../../../Logic/extensions";
import {Button, Confirm, Divider, Form, FormField, FormTextArea, Grid, Header, Icon, Label, Modal, Transition} from "semantic-ui-react";
import {DropdownField, EditField} from "../../partials";
import {SizeMe} from "react-sizeme";
import {EasyFlex} from "../../partials/ActionHeader";
import {Trans, translate} from "react-i18next";
import {withRights} from "../../../Tools";
import {connect} from "react-redux";
import {getOptions, getRimOptions, getServiceOptions, getSetTypeOptions, mapRights, toColumns} from "./WheelMethods";
import {ClientSearchModal} from "../clients/search/ClientSearch";
import ClientPopupInfo from "../clients/ClientPopupInfo";
import {MobileAwarePopup} from "../../partials/MiniComponents";
import VehiclePopupInfo from "../vehicles/VehiclePopupInfo";
import cn from 'classnames'
import {get} from 'lodash'
import {withHouse, withModule} from "../../../Tools/RightsProvider";
import {EwoRefresh, OrderStateView} from "./ewotw/EwoTW";

export const ClientSection = ({clientId, showSearch, unset, editable, required}) => {
	if (clientId) {
		return <Form.Field>
			<label><Required visible={required}>Kunde</Required></label>
			<Button.Group basic fluid style={{justifyContent: 'space-between'}}>
				<Button type={'button'} className={cn({'not-clickable': !editable})} style={{flexGrow: 1000, textAlign: 'left'}} onClick={showSearch}>
					<Client.Provider client_id={clientId}>{client =>
						client.name
					}</Client.Provider>
				</Button>
				<MobileAwarePopup inverted content={
					<Client.Connected id={clientId}>{client =>
						<ClientPopupInfo client={client} compact/>
					}</Client.Connected>
				}><Button type={'button'} icon={'eye'}/></MobileAwarePopup>
				<Button className={cn({'not-clickable': !editable})} type={'button'} icon={'close'} onClick={unset || null}/>
			</Button.Group>
		</Form.Field>
	}
	return <Form.Field>
		<label><Required visible={required}>Kunde</Required></label>
		<Button className={cn({'not-clickable': !editable})} type={'button'} basic fluid icon onClick={showSearch}><Icon name={'user'}/> Kunde wählen</Button>
	</Form.Field>
}
ClientSection.defaultProps = {
	required: false,
	editable: false
}

export const VehicleSection = ({vehicleId, showSearch, unset, editable, required}) => {
	if (vehicleId) {
		return <Form.Field>
			<label><Required visible={required}>Fahrzeug</Required></label>
			<Button.Group basic fluid style={{justifyContent: 'space-between'}}>
				<Button type={'button'} className={cn({'not-clickable': !editable})} style={{flexGrow: 1000, textAlign: 'left'}} onClick={showSearch}>
					<Vehicle.Provider vehicle_id={vehicleId}>{vehicle =>
						vehicle.registration_mark || vehicle.name
					}</Vehicle.Provider>
				</Button>
				<MobileAwarePopup inverted content={
					<Vehicle.Connected id={vehicleId}>{vehicle =>
						<VehiclePopupInfo vehicle={vehicle}/>
					}</Vehicle.Connected>
				}>
					<Button type={'button'} icon={'eye'}/>
				</MobileAwarePopup>
				<Button className={cn({'not-clickable': !editable})} type={'button'} icon={'close'} onClick={unset || null}/>
			</Button.Group>
		</Form.Field>
	}
	return <Form.Field>
		<label><Required visible={required}>Fahrzeug</Required></label>
		<Button className={cn({'not-clickable': !editable})} type={'button'} basic fluid icon onClick={showSearch}><Icon name={'car'}/> Fahrzeug wählen</Button>
	</Form.Field>
}
VehicleSection.defaultProps = {
	required: false,
	editable: false
}

export const WheelModuleInactiveLabel = withModule(({modules, style, isModule, dispatch, ...props}) => falseNull(modules.wheel) && <Label color={'red'} style={{marginLeft: 8, ...style}} {...props}>Reifenmodul inaktiv</Label>)

export const EwoSection = ({wheel, onChange, editable, ...props}) => [
	<FormField key={'ewo_tireset_number'}>
		<EditField
			required
			editable={editable}
			label={'Reifensatznummer'}
			value={wheel.ewo_tireset_number}
			name={'ewo_tireset_number'}
			onChange={onChange}
			{...props}
		/>
	</FormField>
]

export class WheelEditor extends StatyComponent {
	static propTypes = {
		wheel: PropTypes.object,
		onAfterChange: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		onUpdate: PropTypes.func.isRequired,
		onNotification: PropTypes.func.isRequired,
		onCancel: PropTypes.func,
		isSaving: PropTypes.func.isRequired,
		clientId: PropTypes.number,
		vehicleId: PropTypes.number,
		formId: PropTypes.string,
		readOnly: PropTypes.bool,
		vehicleRequired: PropTypes.bool,
		clientRequired: PropTypes.bool,
		saveText: PropTypes.node,
		confirmationNeeded: PropTypes.bool
	}
	static defaultProps = {
		onAfterChange: () => {
		},
		onSave: wheelCall_put,
		onUpdate: wheelCall_update,
		onNotification: dispatchSnack,
		onCancel: wheelCall_delete,
		isSaving: () => {
		},
		vehicleRequired: false,
		clientRequired: true,
		confirmationNeeded: false
	}
	static defaultWheel = {
		wheel_id: null,
		house_id: null,
		client_id: null,
		vehicle_id: null,
		id: '',
		set_count: 4,
		title: '',
		type: null, //Wheel.TIRE_MIXED,
		set_type: Wheel.SET_CLIENT,
		service_type: Wheel.SERVICE_STANDARD,
		inch: 0,
		registration_mark: '',
		chassis_number: '',
		without_rims: false,
		rim_type: null, // Wheel.RIM_NONE,
		commment: '',
		reference_number: '',
		ewo_tireset_number: '',
		deleted_at: null,
		extract_at: null,
		extraction_confirmed: false
	}
	state = {
		wheel: {...WheelEditor.defaultWheel, client_id: this.props.clientId, vehicle_id: this.props.vehicleId},
		saving: false,
		search_client: false,
		search_vehicle: false,
		confirm: false,
		showEwo: false,
		canceling: false
	}
	
	get isEdit() {
		return !!this.props.wheel;
	}
	
	get options() {
		return getOptions(Wheel.TIRES, this.props.t);
	}
	
	get setTypeOptions() {
		return getSetTypeOptions(Wheel.SET_TYPES, this.props.t)
	}
	
	get serviceOptions() {
		return getServiceOptions(Wheel.SERVICE_TYPES, this.props.t)
	}
	
	get rimOptions() {
		return getRimOptions(Wheel.RIM_TYPES, this.props.t)
	}
	
	get isInvalid() {
		return Boolean(
			(this.props.clientRequired && !this.state.wheel.client_id) ||
			(this.props.vehicleRequired && !this.state.wheel.vehicle_id) ||
			(this.props.houseData && this.props.houseData.ewo_tw && !this.state.wheel.ewo_tireset_number.trim() && !this.isEdit) ||
			(!this.state.wheel.rim_type) ||
			(!this.state.wheel.type)
		)
	}
	
	getRights = memoizeOne(mapRights)
	
	componentDidMount() {
		super.componentDidMount();
		this.updateWheel(this.props.wheel);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.updateWheel(this.props.wheel);
	}
	
	execute = (force = false) => {
		if (!force && this.props.confirmationNeeded) {
			this.setState({confirm: true})
		} else {
			this.isEdit ? this.update() : this.create()
		}
	}
	
	create = async () => {
		const {wheel} = this.state;
		const {onSave, onNotification, isSaving, onAfterChange, vehicleRequired, clientRequired} = this.props;
		if (clientRequired && !wheel.client_id) {
			onNotification('Die Angabe eines Kunden ist notwenig', 'brown')
			return
		}
		if (vehicleRequired && !wheel.vehicle_id) {
			onNotification('Die Angabe eines Fahrzeuges ist notwenig', 'brown')
			return
		}
		try {
			this.setState({saving: true, confirm: false});
			isSaving(true)
			const result = await onSave(wheel);
			onNotification('Daten wurden gespeichert');
			onAfterChange(result);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert')
		} finally {
			this.setState({saving: false})
			isSaving(false)
		}
	}
	
	update = async () => {
		const {wheel} = this.state;
		const {onUpdate, onNotification, isSaving, onAfterChange, clientRequired, vehicleRequired} = this.props;
		if (clientRequired && !wheel.client_id) {
			onNotification('Die Angabe eines Kunden ist notwenig', 'brown')
			return
		}
		if (vehicleRequired && !wheel.vehicle_id) {
			onNotification('Die Angabe eines Fahrzeuges ist notwenig', 'brown')
			return
		}
		try {
			this.setState({saving: true, confirm: false});
			isSaving(true)
			const result = await onUpdate(wheel);
			onNotification('Daten wurden aktualisiert');
			onAfterChange(result);
		} catch (e) {
			console.error(e);
			onNotification(e.message, 'alert')
		} finally {
			this.setState({saving: false})
			isSaving(false)
		}
	}
	
	cancel = async () => {
		const {wheel, canceling} = this.state
		const {onNotification, onCancel, onAfterChange} = this.props
		if (canceling) return
		try {
			this.setState({canceling: true})
			const w = await onCancel(wheel.wheel_id)
			onNotification('Einlagerung wurde storniert')
			onAfterChange && onAfterChange(w)
		} catch (e) {
			console.error(e)
			onNotification(e.message, 'alert')
		} finally {
			this.setState({canceling: false})
		}
	}
	
	showClientSearch = (search_client) => () => this.setState({search_client})
	showVehicleSearch = (search_vehicle) => () => this.setState({search_vehicle})
	
	setWheel = (wheel, callback) => this.setState(state => ({
		...state,
		wheel: {
			...state.wheel,
			...wheel
		}
	}), callback)
	
	handleClientSelection = client => this.setWheel({client_id: client.client_id}, this.showClientSearch(false))
	handleVehicleSelection = vehicle => this.setWheel({vehicle_id: vehicle.vehicle_id, client_id: vehicle.client_id}, this.showVehicleSearch(false))
	
	setWheelField = (_, {name, value}) => this.setWheel({[name]: value})
	
	updateWheel = memoizeOne(this.setWheel)
	
	wheelFields = (w, editable, t) => {
		const wheel = new Wheel(w)
		return [
			<FormField key={'service_type'}>
				<DropdownField
					editable={editable}
					label={'Service'}
					value={wheel.service_type}
					options={this.serviceOptions}
					name={'service_type'}
					onChange={this.setWheelField}
					required
					placeholder={'Bitte wählen'}
				/>
			</FormField>,
			<FormField key={'set_type'}>
				<DropdownField
					editable={editable}
					label={'Reifensatztyp'}
					value={wheel.set_type}
					options={this.setTypeOptions}
					name={'set_type'}
					onChange={this.setWheelField}
					required
					placeholder={'Bitte wählen'}
				/>
			</FormField>,
			// <FormField key={'title'}>
			// 	<EditField
			// 		name={'title'}
			// 		label={'Bezeichnung'}
			// 		onChange={this.setWheelField}
			// 		value={wheel.title}
			// 		editable={editable}
			// 	/>
			// </FormField>,
			<FormField key={'type'}>
				<DropdownField
					required
					editable={editable}
					label={'Reifentyp'}
					value={wheel.type}
					options={this.options}
					name={'type'}
					onChange={this.setWheelField}
					placeholder={'Bitte wählen'}
				/>
			</FormField>,
			<FormField key={'rim_type'}>
				<DropdownField
					editable={editable}
					label={'Felgen'}
					value={wheel.rim_type}
					options={this.rimOptions}
					name={'rim_type'}
					onChange={this.setWheelField}
					placeholder={'Bitte wählen'}
					required
				/>
			</FormField>,
			<FormField key={'reference_number'}>
				<EditField
					name={'reference_number'}
					label={'Referenznummer'}
					onChange={this.setWheelField}
					value={wheel.reference_number}
					editable={editable}
				/>
			</FormField>,
			<FormField key={'set_count'}>
				<EditField
					name={'set_count'}
					label={'Räderanzahl'}
					onChange={this.setWheelField}
					value={wheel.origin.set_count}
					editable={editable}
					type={'number'}
					min={0}
				/>
			</FormField>,
			<FormField key={'inch'}>
				<EditField
					name={'inch'}
					label={'Größe in Zoll'}
					onChange={this.setWheelField}
					value={wheel.origin.inch}
					editable={editable}
					type={'number'}
					min={0}
				/>
			</FormField>,
			// <FormField key={'registration_mark'}>
			// 	<EditField
			// 		name={'registration_mark'}
			// 		label={'Kennzeichen'}
			// 		onChange={this.setWheelField}
			// 		value={wheel.registration_mark}
			// 		editable={editable}
			// 	/>
			// </FormField>,
			// <FormField key={'chassis_number'}>
			// 	<EditField
			// 		name={'chassis_number'}
			// 		label={'Fahrgestellnummer'}
			// 		onChange={this.setWheelField}
			// 		value={wheel.chassis_number}
			// 		editable={editable}
			// 	/>
			// </FormField>,
			<FormField key={'comment'}>
				<label>Kommentar</label>
				<FormTextArea value={wheel.comment} onChange={this.setWheelField} name={'comment'} readOnly={!editable} className={cn({'not-clickable': !editable})} placeholder={'Kommentar'}/>
			</FormField>,
			<FormField key={'extract_at'}>
				<EditField
					editable={false}
					label={'Auslagerung'}
					value={wheel.extract_at ? wheel.extract_moment.format('L') : 'Nicht ausgelagert'}
					name={'extract_at'}
				/>,
			</FormField>,
			// <FormField key={'extraction_confirmed'}>
			// 	<EditField
			// 		editable={false}
			// 		label={'Auslagerung bestätigt'}
			// 		value={wheel.extraction_confirmed ? 'Ja' : 'Nein'}
			// 		name={'extraction_confirmed'}
			// 	/>
			// </FormField>
		]
	}
	
	getColumns = (width) => {
		if (width < 600) return 1
		if (width < 1200) return 2
		return 3
	}
	
	getColumnWheels = toColumns
	
	ewoFields = memoizeOne(props => EwoSection(props))
	ewoCols = toColumns
	
	get isStorageCancable() {
		const {wheel} = this.state
		const orderId = get(wheel, 'ewo_tw.storageOrderId')
		const removalId = get(wheel, 'ewo_tw.storageRemovalId')
		const isOpen = get(wheel, 'ewo_tw.data.orderStatus', 'OPEN')  === 'OPEN'
		return Boolean(orderId && !removalId && isOpen && !wheel.deleted_at)
	}
	
	render() {
		const {wheel, saving, search_client, search_vehicle} = this.state
		const {t, allRights, formId, children, readOnly, modules, vehicleRequired, clientRequired, houseData} = this.props;
		const rights = this.getRights(allRights)
		const editable = readOnly ? false : (this.isEdit ? rights.change : rights.create);
		return (
			<Form id={formId}>
				<SizeMe>{({size}) => {
					if (!size.width) {
						return <div/>;
					}
					const columns = this.getColumns(size.width);
					const showEwo = houseData.ewo_tw && !this.isEdit;
					const sections = this.getColumnWheels(this.wheelFields(wheel, editable, t), columns);
					const ewoSection = showEwo && this.ewoFields(({wheel, editable, onChange: this.setWheelField}))
					const ewoCols = showEwo ? this.ewoCols(ewoSection, columns) : []
					return <div>
						<Grid columns={columns}>
							<Grid.Row>
								<Grid.Column>
									<ClientSection required={clientRequired} editable={editable} clientId={wheel.client_id} showSearch={this.showClientSearch(true)} unset={() => this.setWheel({client_id: null})}/>
								</Grid.Column>
								<Grid.Column>
									<VehicleSection required={vehicleRequired} editable={editable} vehicleId={wheel.vehicle_id} showSearch={this.showVehicleSearch(true)} unset={() => this.setWheel({vehicle_id: null})}/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								{sections.map((wheels, key) =>
									<Transition.Group duration={250} as={Grid.Column} key={key}>
										{wheels}
									</Transition.Group>
								)}
							</Grid.Row>
						</Grid>
						{houseData.ewo_tw &&
						<React.Fragment>
							{!this.isEdit ? <div>
									<Divider/>
									<Header as={'h4'}>EwoTW</Header>
									<Grid columns={columns}>
										{ewoCols.map((ewoData, key) =>
											<Transition.Group duration={250} as={Grid.Column} key={key}>
												{ewoData}
											</Transition.Group>
										)}
									</Grid>
								</div> :
								<div>
									<Divider/>
									<Header as={'h4'}>EwoTW</Header>
									{wheel.ewo_tw && wheel.ewo_tw.tireSetNumber ?
										<React.Fragment>
											<Button.Group basic>
												<Button onClick={() => this.setState({showEwo: true})} basic>Zeige EwoTW Daten</Button>
												{this.isStorageCancable && <Button type={'button'} loading={this.state.canceling} disabled={this.state.canceling} negative onClick={this.cancel}> Einlagerung stornieren</Button>}
												<EwoRefresh wheelId={wheel.wheel_id} notifyOnSuccess/>
											</Button.Group>
										</React.Fragment>
										:
										<pre>Keine EwoTW Daten hinterlegt</pre>}
								</div>
							}
						</React.Fragment>
						}
					</div>
				}}</SizeMe>
				<EasyFlex align={EasyFlex.align.SPACE_BETWEEN} valign={EasyFlex.valign.CENTER} style={{marginTop: 20}}>
					<div></div>
					<EasyFlex valign={EasyFlex.valign.CENTER}>
						{children}
						{editable && <Button disabled={(!this.isEdit && !modules.wheel) || this.isInvalid} positive icon loading={saving} onClick={() => this.execute()}><Icon name={'save'}/>
							{this.props.saveText ? this.props.saveText : (this.isEdit ? <Trans i18nKey={'actions.change'} defaults={'Ändern'}/> : <Trans defaults={'Speichern'} i18nKey={'actions.save'}/>)}
						</Button>}
					</EasyFlex>
				</EasyFlex>
				{trueNull(search_vehicle || search_client) && <ClientSearchModal
					open
					onModalClose={search_client ? this.showClientSearch(false) : this.showVehicleSearch(false)}
					onSelectVehicle={trueNull(search_vehicle) && this.handleVehicleSelection}
					onSelectClient={trueNull(search_client) && this.handleClientSelection}
					directSelect
				/>}
				{this.state.confirm && <Confirm
					size={'mini'}
					open
					content={'Wirklich ausführen?'}
					cancelButton={this.props.t('actions.cancel')}
					onCancel={() => this.setState({confirm: false})}
					onConfirm={() => this.execute(true)}
				/>}
				{this.state.showEwo && <Modal open centered={false} onClose={() => this.setState({showEwo: false})}>
					<Modal.Content>
						{wheel.ewo_tw.data ? <OrderStateView result={wheel.ewo_tw.data}/> : <pre>{JSON.stringify(wheel.ewo_tw, null, 2)}</pre>}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.setState({showEwo: false})}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Form>
		);
	}
}

WheelEditor = translate()(WheelEditor);
WheelEditor = withRights(SECTION.ORDERS, WheelEditor);
WheelEditor = withModule(WheelEditor)
WheelEditor = withHouse(WheelEditor)

export const ConnectedWheelEditor = connect(
	null,
	dispatch => ({
		onSave: wheel => dispatch(wheelAction_put(wheel, true)),
		onUpdate: wheel => dispatch(wheelAction_update(wheel, true)),
		onCancel: id => dispatch(wheelAction_delete(id, true))
	})
)(WheelEditor);

ConnectedWheelEditor.propTypes = {
	wheel: PropTypes.object,
	onAfterChange: PropTypes.func,
	onNotification: PropTypes.func,
	isSaving: PropTypes.func,
	clientId: PropTypes.number,
	vehicleId: PropTypes.number,
	formId: PropTypes.string,
	readOnly: PropTypes.bool,
	onSave: PropTypes.func,
	onUpdate: PropTypes.func,
	saveText: PropTypes.node
}