import _objectSpread from "/mnt/e/carperto/haendler/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import fuzzysearch from "fuzzysearch";

var lowered = function lowered() {
  var what = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var toLower = arguments.length > 1 ? arguments[1] : undefined;
  return toLower ? what.toLowerCase() : what;
};

var trimed = function trimed() {
  var what = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var trimed = arguments.length > 1 ? arguments[1] : undefined;
  return trimed ? what.trim() : what;
};

var _ = function _(what) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    lowercase: true,
    trimed: true
  };
  return lowered(trimed(what, options.trimed), options.lowercase);
};

export var COMPARE_DEFAULT_OPTIONS = {
  fuzzy: true,
  lowercase: true,
  trimed: true
};
export var compare = function compare(needle) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : COMPARE_DEFAULT_OPTIONS;
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      options = _objectSpread({}, COMPARE_DEFAULT_OPTIONS, options);
      return options.fuzzy ? fuzzysearch(_(needle, options), _(collector(haystack), options)) : !!_(collector(haystack), options).match(_(needle, options));
    };
  };
};
export var compareNum = function compareNum(needle) {
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      return Number(needle) === Number(collector(haystack));
    };
  };
};
export var equals = function equals(needle) {
  var lowercased = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function () {
    var collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function (obj) {
      return obj;
    };
    return function (haystack) {
      return lowered(needle, lowercased) === lowered(collector(haystack));
    };
  };
};
export var compareDefault = function compareDefault() {
  return true;
};