/////////////
//      Quick Info
///////////////////////
import * as React from "react";
import PropTypes from "prop-types";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {SECTION} from "../../../../Logic/constants";
import NoAccess from "../../../../components/NoAccess";
import {Flex} from "../../../../components/partials/ActionHeader";
import {IconButton, List, ListItem, Toggle} from "material-ui";
import {IconEdit} from "../../../../Logic/icons";
import nl2br from "react-nl2br";
import moment from "../../../../Logic/Moment";
import {ConfirmButton, ConfirmButtonConsumer} from "../../../../components/partials";
import {BasicDialog} from "../../../../Tools/Dialog/Dialogs";
import withInit from "../../../../Logic/withInit";
import {connect} from "react-redux";
import {get, isFunction} from "lodash";
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_ORDER, PROC_ORDER_DELETE} from "../../../../actions";
import {isOnline} from "../../../../actions/userActions";
import {orderAction__delete, orderAction__delivered, orderAction__fetched, orderAction__fetchOne, orderAction__released, orderAction__updateInfo} from "../../../../actions/orderActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {withRights} from "../../../../Tools";
import {OrderQuickChange} from "../OrderQuickView";
import {ConnectedOrderInfoUpdateView} from "./OrderInfoUpdate";


export class OrderQuickInfo extends React.Component {
	static propTypes = {
		order_id: PropTypes.number.isRequired,
		order: PropTypes.object,
		loading: PropTypes.bool,
		order2services: PropTypes.array,
		updateReleased: PropTypes.func,
		updateDelivered: PropTypes.func,
		updateFetched: PropTypes.func,
		updateInfo: PropTypes.func,
		rights: PropTypes.object,
		allRights: PropTypes.object,
		deleting: PropTypes.bool,
		onOpened: PropTypes.func
	};
	static defaultProps = {
		order: {},
		loading: false,
		deleting: false,
		order2services: [],
		onOpened: () => {}
	};
	state = {
		info_update_dialog: false
	};
	
	showUpdateDialog = (open) => () => this.setState({info_update_dialog: open});
	
	render() {
		const {
			loading,
			order,
			order2services,
			updateReleased,
			updateDelivered,
			updateFetched,
			rights,
			allRights,
			deleting,
		} = this.props;
		
		if (!order) {
			return <Segment basic loading style={{minWidth: 100, minHeight: 100}}/>;
		}
		const mayRead = rights.mayRead || allRights[SECTION.APPOINTMENTS].mayRead;
		if (!mayRead) {
			return <NoAccess/>;
		}
		
		const mayRelease = allRights[SECTION.RELEASE].mayChange || rights.mayChange;
		const mayChange = rights.mayChange;
		const mayDelete = rights.mayDelete;
		
		const {
			info_update_dialog
		} = this.state;
		
		return (
			<Segment basic loading={loading}>
				<Segment padded style={{minHeight: 0}}>
					<Flex align={"space-between"} valign={"center"}>
						<h5>Infos</h5>
						{mayChange && <IconButton onClick={this.showUpdateDialog(true)}><IconEdit/></IconButton>}
					</Flex>
					<p>{nl2br(order.info) || "Keine Angabe"}</p>
				</Segment>
				<Grid key={"grid-view"} stackable columns={mayChange ? 3 : 2}>
					<Grid.Column>
						<List>
							<ListItem disabled secondaryText={"AutragsID"} primaryText={order.order_id}/>
							<ListItem disabled secondaryText={"AutragsNummer"} primaryText={order.order_id}/>
							<ListItem disabled secondaryText={"Auftragsinformationen"} primaryText={order.info || "k.A."}/>
							<ListItem disabled secondaryText={"Lieferzeit"} primaryText={moment(order.deliver_point).format('LLL')}/>
							<ListItem disabled secondaryText={"Abholzeit"} primaryText={moment(order.fetch_point).format('LLL')}/>
							<ListItem disabled secondaryText={"Erstellt"} primaryText={moment(order.created_at).format('LLL')}/>
						</List>
					</Grid.Column>
					<Grid.Column>
						<List>
							<ListItem disabled secondaryText={"Anzahl an Serviceleistungen"} primaryText={order2services.length}/>
							<ListItem
								className={"unselectable inherit-cursor"}
								secondaryText={"Freigegeben"}
								primaryText={order.released_at ? moment(order.released_at).format("LLL") : 'Nein'}
								rightToggle={<Toggle disabled={!mayRelease} toggled={!!order.released_at} onToggle={updateReleased.bind(null, order, !order.released_at)}/>}
							/>
							<ListItem
								className={"unselectable inherit-cursor"}
								secondaryText={"Geliefert"}
								primaryText={order.delivered_at ? moment(order.delivered_at).format("LLL") : 'Nein'}
								rightToggle={<Toggle disabled={!mayChange} toggled={!!order.delivered_at} onToggle={updateDelivered.bind(null, order, !order.delivered_at)}/>}
							/>
							<ListItem disabled secondaryText={"In Bearbeitung"} primaryText={order.progress ? `Wird von ${order.progress} Mitarbeiter bearbeitet` : 'Nein'}/>
							<ListItem disabled secondaryText={"Fertig gestellt"} primaryText={order.finished_at ? moment(order.finished_at).format("LLL") : 'Nein'}/>
							<ListItem
								className={"unselectable inherit-cursor"}
								secondaryText={"Abgeholt"}
								primaryText={order.fetched_at ? moment(order.fetched_at).format("LLL") : 'Nein'}
								rightToggle={<Toggle disabled={!mayChange} toggled={!!order.fetched_at} onToggle={updateFetched.bind(null, order, !order.fetched_at)}/>}
							/>
						
						</List>
					</Grid.Column>
					{mayChange &&
					<Grid.Column>
						<List>
							<ListItem disabled>&nbsp;</ListItem>
							<ListItem disabled>&nbsp;</ListItem>
						</List>
						{!!order && !!order.order_id && <OrderQuickChange order_id={order.order_id} onOpened={this.props.onOpened}/>}
						<List>
							{mayDelete &&
							<ListItem disabled>
								<ConfirmButton negative content={"Auftrag löschen"}>
									<ConfirmButtonConsumer>{confirm =>
										<Button.Group>
											<Button disabled={deleting} onClick={e => {
												e && e.stopPropagation();
												confirm(false);
											}}><Icon name={"close"}/>Abbrechen</Button>
											<Button.Or/>
											<Button loading={deleting} negative onClick={() => this.props.deleteOrder(order)}>Löschen</Button>
										</Button.Group>
									}</ConfirmButtonConsumer>
								</ConfirmButton>
							</ListItem>
							}
						</List>
					</Grid.Column>
					}
				</Grid>
				<BasicDialog modal open={info_update_dialog} autoScrollBodyContent onClose={this.showUpdateDialog(false)} noPadding closeButton renderActions={closeBtn => <Flex align={"space-between"} valign={"center"}>
					{closeBtn}
					<Button positive form={"order-update-info"}>Speichern</Button>
				</Flex>}>
					{info_update_dialog && <ConnectedOrderInfoUpdateView order_id={order.order_id} info={order.info} id={"order-update-info"} onAfterUpdate={this.showUpdateDialog(false)}/>}
				</BasicDialog>
			</Segment>
		);
	}
}

OrderQuickInfo = withInit(OrderQuickInfo);
OrderQuickInfo = connect(
	(state, props) => {
		const orderID = props.order_id;
		const order = get(state.appointments.orders, [orderID], {});
		const order2services = get(state.appointments.order2services, [orderID], []);
		let service2resources = [];
		for (const id of order2services) {
			service2resources.concat(get(state.appointments.service2resources, [id], []));
		}
		return {
			loading: isLoading(state, PROC_ORDER, orderID),
			orders: state.appointments,
			order,
			order2services,
			service2resources,
			deleting: isLoading(state, PROC_ORDER_DELETE)
		};
	},
	(dispatch, props) => ({
		init: () => dispatch(isOnline(() => {
			if (props.rights.mayRead) {
				dispatch(orderAction__fetchOne(props.order_id));
			}
		})),
		updateReleased: (order, state) => dispatch(orderAction__released(order, state, () => dispatch(addSnackbar("Status: Freigegeben geändert")))),
		updateDelivered: (order, state) => dispatch(orderAction__delivered(order, state, () => dispatch(addSnackbar("Status: Geliefert geändert")))),
		updateFetched: (order, state) => dispatch(orderAction__fetched(order, state, () => dispatch(addSnackbar("Status: Abgeholt geändert")))),
		deleteOrder: (order, onSuccess) => dispatch(orderAction__delete(order, result => {
			dispatch(addSnackbar("Auftrag wurde gelöscht!"));
			isFunction(onSuccess) && onSuccess(result);
		}, false)),
		updateInfo: (order_id, info, onSuccess) => dispatch(orderAction__updateInfo(order_id, info, result => {
			dispatch(addSnackbar('Infos wurden aktualisiert'));
			isFunction(onSuccess) && onSuccess(result);
		}))
	})
)(OrderQuickInfo);
OrderQuickInfo = withRights(SECTION.ORDERS, OrderQuickInfo);