import Vehicle from "./Vehicle";
import {connect} from "react-redux";
import {ProviderComponent} from "../components/partials";

export class OrderVehicle extends Vehicle {
	_validateModel(model) {
		return super._validateModel(model) && ("order_vehicle_id" in model);
	}
	
	get order_vehicle_id() { return Number(this.__model.order_vehicle_id); }
	get id() { return this.order_vehicle_id; }
	
	static Connected = connect(
		(state, {id}) => {
			let data = state.map.order2vehicles[id];
			data = data ? new OrderVehicle(data) : null;
			return {data};
		}
	)(ProviderComponent)
}