import {APPOINTMENT_ACTION, RESET_ACTION} from "../actions/types";
import {isArray, omit} from "lodash";
// import {updateCache} from "ith-redux-helpers";

const _defaultState = {
	worklist: {},
	list: [],
	work_map: {},
	resources: {},
	services: {},
	orders: {},
	order2services: {},
	service2resources: {},
	vehicles: {},
	order_vehicles: {},
	clients: {},
	workload: {},
	paused: {},
	section: {},
	paused_services: [],
	from: null,
	to: null,
	loaded: {
		workload: false,
		daywork: false
	}
};

const omitState = (state, order_id) => ({
	...state,
	orders: omit(state.orders, [order_id]),
	service2resources: omit(state.service2resources, state.order2services[order_id] || []),
	order2services: omit(state.order2services, [order_id])
});

const updateState = (state, data) => {
	let up = {};
	for (const index of Object.keys(data)) {
		if (!(index in state)) continue;
		up[index] = {
			...state[index],
			...data[index]
		};
	}
	return up;
};

const updateSections = (sections, data) => {
	let up = {};
	for (const section of Object.keys(sections)) {
		up[section] = {
			...sections[section],
			...updateState(sections[section], data)
		};
	}
	return up;
};


const handler = (state = _defaultState, {type, list, data, work_map, order_id, progress, update, workload, resources, section, paused_services, multi}) => {
	switch (type) {
		case RESET_ACTION:
			return _defaultState;
		case APPOINTMENT_ACTION.MULTI:
			for (const entry of multi) {
				state = handler(state, entry);
			}
			return state;
		case APPOINTMENT_ACTION.SSE_UPDATE:
			return {
				...state,
				...updateState(state, data),
				section: {
					...state.section,
					...updateSections(state.section, data)
				}
			};
		case APPOINTMENT_ACTION.SET:
			if ( update ) {
				// to not overwrite old data (f.e. at personelle appointment view
				return {
					...state,
					orders: {
						...state.orders,
						...data.orders
					},
					services: {
						...state.services,
						...data.services
					},
					resources: {
						...state.resources,
						...data.resources
					},
					order2services: {
						...state.order2services,
						...data.order2services
					},
					service2resources: {
						...state.service2resources,
						...data.service2resources
					},
					order_vehicles: {
						...state.order_vehicles,
						...(data.order_vehicles || {})
					},
					clients: {
						...state.clients,
						...(data.clients || {})
					},
					// paused_services: uniqBy([...(data.paused_services || []), ...state.paused_services], ps => ps.order_service_id),
					from : data.from || null,
					to: data.to || null
				};
			}
			return {
				...state,
				resources: data.resources,
				services: data.services,
				orders: data.orders,
				vehicles: data.vehicles,
				order2services: data.order2services,
				service2resources: data.service2resources,
				order_vehicles: data.order_vehicles || {},
				clients: data.clients || {},
				// paused_services: data.paused_services || [],
				from: data.from || null,
				to: data.to || null
			};
		case APPOINTMENT_ACTION.SET_TO_SECTION:
			return {
				...state,
				section: {
					[section]: {
						resources: data.resources || {},
						services: data.services || {},
						orders: data.orders || {},
						vehicles: data.vehicles || {},
						order2services: data.order2services || {},
						service2resources: data.service2resources || {},
						order_vehicles: data.order_vehicles || {},
						clients: data.clients || {},
						from: data.from || null,
						to: data.to || null
					}
				}
			};
		case APPOINTMENT_ACTION.DELETE:
			return {
				...omitState(state, order_id),
				// section: state.section
			};
		case APPOINTMENT_ACTION.LIST:
			return {
				...state,
				list
			};
		case APPOINTMENT_ACTION.SET_PAUSED:
			return {
				...state,
				paused: list
			};
		case APPOINTMENT_ACTION.SET_WORK_MAP:
			return {
				...state,
				work_map
			};
		case APPOINTMENT_ACTION.REMOVE_RESOURCES:
			
			return {
				...state,
				resources: omit(state.resources, resources)
			};
		case APPOINTMENT_ACTION.UPDATE:
			return {
				...state,
				orders: {
					...state.orders,
					...data.orders
				},
				services: {
					...state.services,
					...data.services
				},
				resources: {
					...state.resources,
					...data.resources
				},
				order2services: {
					...state.order2services,
					...data.order2services
				},
				service2resources: {
					...state.service2resources,
					...data.service2resources
				},
				vehicles: {
					...state.vehicles,
					...(data.vehicles || {})
				},
				order_vehicles: {
					...state.order_vehicles,
					...(data.order_vehicles || {})
				},
				clients: {
					...state.clients,
					...(data.clients || {})
				},
				// paused_services: uniqBy([...(data.paused_services || []), ...state.paused_services], ps => ps.order_service_id)
			};
		case APPOINTMENT_ACTION.UPDATE_PROGRESS:
			return {
				...state,
				orders: {
					...state.orders,
					[order_id]: {
						...state.orders[order_id],
						progress
					}
				}
			};
		case APPOINTMENT_ACTION.DAY_LOADED:
			if(state.loaded.daywork) {
				return state;
			}
			return {
				...state,
				loaded: {
					...state.loaded,
					daywork: true
				}
			};
		case APPOINTMENT_ACTION.SET_WORKLOAD:
			return {
				...state,
				workload: {
					...state.workload,
					...workload
				},
				loaded: {
					...state.loaded,
					workload: true
				}
			};
		case APPOINTMENT_ACTION.PAUSED_SERVICES:
			if (!isArray(paused_services)) {
				paused_services = [paused_services].filter(Boolean);
			}
			return {
				...state,
				paused_services
			};
		default:
			return state;
	}
};

export default handler;