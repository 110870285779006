import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Popup, Segment} from "semantic-ui-react";
import {translate} from "react-i18next";
import {ActionHeader, ActionHeaderGroup, ActionHeading} from "../../partials/ActionHeader";
import {deepMemoize as memoize, trueNull} from "../../../Logic/extensions";
import {Avatar} from "material-ui";
import {COLORS, IMAGE_URL} from "../../../Logic/constants";
import NoAccess from "../../NoAccess";
import {VehicleList} from "./widgets/VehicleList";
import {SUB_CLIENT_SEARCH} from "../../../actions";
import {publish} from "../../../Logic/PubSub";

class VehicleListView extends React.Component {
	static propTypes = {
		onBack: PropTypes.func,
		client: PropTypes.object.isRequired,
		vehicles: PropTypes.array.isRequired,
		toCreate: PropTypes.func.isRequired,
		toVehicle: PropTypes.func.isRequired,
		kfz: PropTypes.object,
		models: PropTypes.object,
		inverseModels: PropTypes.object,
		mayRead: PropTypes.bool,
		mayCreate: PropTypes.bool,
		loading: PropTypes.bool
	};
	static defaultProps = {
		client: {},
		vehicles: [],
		kfz: {},
		models: {},
		inverseModels: {},
		mayRead: false,
		mayCreate: false,
		toCreate: () => alert('toCreate() is not implemented yet!'),
		toVehicle: () => alert('toVehicle() is not implemented yet!')
	};
	
	state = {
		column: null,
		direction: null
	};
	
	findAvatar = model_id => {
		if ( model_id ) {
			const {inverseModels, kfz: kfzList} = this.props;
			if ( inverseModels && inverseModels[model_id]) {
				const kfz = kfzList[inverseModels[model_id]];
				if ( kfz && kfz.logo) {
					const {ident, origin_extension, update_at} = kfz.logo;
					return <Avatar src={`${IMAGE_URL}${ident}.${origin_extension}?${update_at}`}/>;
				}
			}
		}
		return null;
	};
	
	prepare = memoize(
		vehicles => vehicles.map(v => {
			v.hsn_tsn = v.hsn + ' / ' + v.tsn;
			return v;
		})
	);
	
	// setColumnSort = (column) => () => this.setState(state => {
	// 	// const isSame = column === state.column;
	// 	const direction = state.direction && (state.direction === 'asc' ? 'desc' : 'asc') || 'asc';
	// });
	
	selectVehicle = (vehicle)  => this.props.toVehicle(vehicle.vehicle_id || vehicle);
	
	render() {
		const {onBack, client, client_id, toClient, toCreate, /*vehicles: vehicleList,*/ mayRead, mayCreate, loading} = this.props;
		if ( !mayRead ) {
			return <NoAccess/>;
		}
		// let vehicles = this.prepare(vehicleList);
		return(
			<Segment basic>
				<ActionHeader alignment={'space-between'} className={"sticky"} style={{position: "sticky", top: 0, zIndex: 12, background: COLORS.BACKGROUND}}>
					<ActionHeaderGroup>
						{onBack && <Button basic icon={'angle left'} onClick={onBack}/>}
						<ActionHeading loading={loading}>Fahrzeuge von {client.name}</ActionHeading>
					</ActionHeaderGroup>
					<ActionHeaderGroup>
						<Button icon={'search'} basic onClick={() => publish(SUB_CLIENT_SEARCH)}/>
						{/*{mayCreate && <AHIconButton onClick={toCreate}><Icon name={'plus'} size={'large'}/></AHIconButton>}*/}
						{trueNull(toClient) && <Popup position={'bottom right'} content={'Zum Kunden'} trigger={<Button icon={'user'} onClick={toClient} color={'brown'}/>}/>}
						{mayCreate && <Popup inverted position={'bottom right'} content={'Neues Fahrzeug anlegen'} trigger={<Button icon={'plus'} onClick={toCreate} style={{marginLeft: 5}} color={'black'}/>}/>}
					</ActionHeaderGroup>
				</ActionHeader>
				<div>
					
					<VehicleList client_id={client_id} onSelect={this.selectVehicle}/>
					{/*<NavigationClientSearchModal open={this.state.showSearch} onModalClose={() => this.setState({showSearch: false})}/>*/}
					{/*<Table sortable celled>*/}
					{/*	<Table.Header>*/}
					{/*		<Table.Row>*/}
					{/*			/!*<Table.HeaderCell className={"sticky"} style={{position: "sticky", zIndex: 12, top: 50, pointerEvents: 'none'}} disabled>Marke</Table.HeaderCell>*!/*/}
					{/*			<Table.HeaderCell className={"sticky pointer click"} style={{position: "sticky", zIndex: 12, top: 50}}>Fahrzeug</Table.HeaderCell>*/}
					{/*			<Table.HeaderCell className={"sticky pointer click"} style={{position: "sticky", zIndex: 12, top: 50}}>HSN/TSN</Table.HeaderCell>*/}
					{/*			<Table.HeaderCell className={"sticky pointer click"} style={{position: "sticky", zIndex: 12, top: 50}}>Laufleistung</Table.HeaderCell>*/}
					{/*			<Table.HeaderCell className={"sticky pointer click"} style={{position: "sticky", zIndex: 12, top: 50}}>Kennzeichen</Table.HeaderCell>*/}
					{/*			<Table.HeaderCell className={"sticky pointer click"} style={{position: "sticky", zIndex: 12, top: 50}}>Fahrgestellnummer</Table.HeaderCell>*/}
					{/*		</Table.Row>*/}
					{/*	</Table.Header>*/}
					{/*	<Table.Body>*/}
					{/*		{vehicles.map( vehicle => {*/}
					{/*			const model = models[vehicle.model_id];*/}
					{/*			const k = model ? kfz[model.kfz_id] : {};*/}
					{/*			return <Table.Row className={'click pointer'} key={`row-${vehicle.vehicle_id}`} onClick={this.selectVehicle(vehicle)}>*/}
					{/*				/!*<Table.Cell>{k.name || vehicle.model_id}</Table.Cell>*!/*/}
					{/*				<Table.Cell>{vehicle.name}</Table.Cell>*/}
					{/*				<Table.Cell>{vehicle.hsn + ' / ' + vehicle.tsn}</Table.Cell>*/}
					{/*				<Table.Cell>{vehicle.km} km</Table.Cell>*/}
					{/*				<Table.Cell><RegistrationMark>{vehicle.registration_mark || 'n.v.'}</RegistrationMark></Table.Cell>*/}
					{/*				<Table.Cell><code>{vehicle.chassis_number}</code></Table.Cell>*/}
					{/*			</Table.Row>*/}
					{/*		})}*/}
					{/*	</Table.Body>*/}
					{/*</Table>*/}
				</div>
			</Segment>
		);
	}
}

export default translate()(VehicleListView);