import * as React from 'react';
import {connect} from "react-redux";
import {addSnackbar} from "../../../../actions/snackbarActions";
import {removeLogoOfHouse, uploadLogoForHouse} from "../../../../actions/carhouseActions";
import isFunc from 'lodash/isFunction';
import {isLoading} from "../../../../actions/loaderActions";
import {PROC_HOUSE_DELETE_LOGO, PROC_HOUSE_UPLOAD_LOGO} from "../../../../actions";
import {CardHeader} from "material-ui";
import DefaultImage from '../../../../images/repair-shop.png';
import PhotoCard from "../../../partial/PhotoCard";

const m2s = state => {
	const house = state.carhouse.own;
	return ({
		image: house ? house.logo : null,
		uploadParams: [],
		deleteParams: [],
		isDeleting: isLoading(state, PROC_HOUSE_DELETE_LOGO),
		isUploading: isLoading(state, PROC_HOUSE_UPLOAD_LOGO),
		CardHeader: <CardHeader title={'Basisdaten'} subtitle={'Autohaus-Logo'}/>,
		defaultImage: DefaultImage
	});
};

const m2d = dispatch => ({
	onUpload: (image, onSuccess) => dispatch(uploadLogoForHouse(image, result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.md-house.logo-uploaded'));
	})),
	onDelete: (onSuccess) => dispatch(removeLogoOfHouse(result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.md-house.logo-removed'));
	}))
	
});

export default connect(m2s, m2d)(PhotoCard);