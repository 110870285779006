import * as React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const Spinner = ({size: width, border, inverted, style}) => (
	<span style={{display: 'inline-block', ...style, width}} className={cn("loader spinner", border ? `border-${border}` : null, {inverted})}/>
);

Spinner.propTypes = {
	size: PropTypes.number,
	border: PropTypes.number,
	inverted: PropTypes.bool,
	style: PropTypes.object
};
Spinner.defaultProps = {
	size: 20,
	border: 3,
	inverted: false
};

export default Spinner;