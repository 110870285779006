import * as React from 'react';
import PropTypes from 'prop-types';
import {Trans, translate} from "react-i18next";
import FontAwesome from 'react-fontawesome';
import {addDialog, dismissDialog} from "../../actions/dialogActions";
import ConfirmDialog from "../../Tools/Dialog/ConfirmDialog";
import NavLink from "../../cointainer/partial/NavLink";
import {connect} from "react-redux";
import {Dropdown, MenuItem} from "react-bootstrap";
import * as Material from "material-ui";
import {AHIconButton, deepMemoize, ScreenReader, trueNull, withScreenReader} from "../../Logic/extensions";
import {Button, Icon, Modal} from "semantic-ui-react";
import {SCREEN} from "../../Logic/constants";
import {isBoolean, isString, values} from "lodash";
import cn from 'classnames'

const _flexParams = (obj) => {
	let last = null
	for (const [key, value] of Object.entries(obj)) {
		if (value) {
			last = key
		}
	}
	return last
}

// ICON BUTTON
const ActionIconButton = ({icon, onAction, actionParams, className, buttonClassName, fullName, ...props}) => (
	<li className={`action-button icon ${className}`} {...props}>
		<NavLink className={buttonClassName} data-text={fullName} to={typeof onAction === 'string' ? onAction : null} onAction={() => {
			if (typeof onAction === 'function') {
				onAction.apply(null, actionParams)
			}
		}}><FontAwesome name={icon}/></NavLink>
	</li>
);
ActionIconButton.propTypes = {
	icon: PropTypes.string.isRequired,
	onAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	actionParams: PropTypes.array,
	className: PropTypes.string,
	buttonClassName: PropTypes.string
};
ActionIconButton.defaultProps = {
	actionParams: [],
	className: '',
	buttonClassName: ''
};

// Action Text Button
const ActionTextButton = ({text, onAction, actionParams, className, ...props}) => (
	<li className={`action-button text ${className}`} {...props}>
		<NavLink to={typeof onAction === 'string' ? onAction : null} onAction={() => {
			if (typeof onAction === 'function') {
				onAction.apply(null, actionParams)
			}
		}}>{text}</NavLink>
	</li>
);
ActionTextButton.propTypes = {
	text: PropTypes.string.isRequired,
	onAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	actionParams: PropTypes.array,
	className: PropTypes.string
};
ActionTextButton.defaultProps = {
	actionParams: [],
	className: ''
};

// BACK BUTTON
// eslint-disable-next-line
let ActionBackButton = ({onBack, className, ...props}) => (
	<Button {...props} icon={'angle left'} basic onClick={onBack || (() => window.history.back())}/>
);
ActionBackButton.propTypes = {
	onBack: PropTypes.func,
	className: PropTypes.string
};
ActionBackButton.defaultProps = {
	className: '',
	onBack: () => window.history.back()
};

// REFRESH BUTTON
const ActionRefreshButton = ({onRefresh, fullName, className, ...props}) => (
	<li className={`action-button icon refresh ${className}`} {...props}>
		{/*eslint-disable-next-line*/}
		<a onClick={onRefresh} data-text={fullName}><FontAwesome name="refresh" alt="Neuladen"/></a>
	</li>
);
ActionRefreshButton.propTypes = {
	onRefresh: PropTypes.func.isRequired,
	className: PropTypes.string
};
ActionRefreshButton.defaultProps = {
	className: ''
};


// Action HEADING
const ActionHeading = ({children, className, loading, ...props}) => (
	<li className={`action-button heading ${className}`} {...props}><h3>{loading && <Icon name={'circle notch'} loading/>}{children}</h3></li>
);
ActionHeading.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	loading: PropTypes.bool
};
ActionHeading.defaultProps = {
	className: '',
	loading: false
};


// DELETE BUTTON
const _deleteDialog = (onDelete, deleteParams, confirmMessage) => (
	<ConfirmDialog onOk={(e, dispatch) => {
		dispatch(dismissDialog());
		onDelete.apply(null, deleteParams);
	}} message={confirmMessage}/>
);
const _ActionDeleteButton = ({onDelete, confirmMessage, deleteParams, onDialog, className, ...props}) => (
	<li className={`action-button icon delete ${className}`} {...props}>
		<NavLink className="btn btn-danger" onAction={e => onDialog(_deleteDialog(onDelete, deleteParams, confirmMessage))}><FontAwesome name="trash"/></NavLink>
	</li>
);
const ActionDeleteButton = connect(
	null,
	(dispatch, props) => ({
		onDialog: dialog => dispatch(addDialog(dialog))
	})
)(_ActionDeleteButton);
ActionDeleteButton.propTypes = {
	onDelete: PropTypes.func.isRequired,
	deleteParams: PropTypes.array,
	confirmMessage: PropTypes.string.isRequired,
	className: PropTypes.string
};
ActionDeleteButton.defaultProps = {
	deleteParams: [],
	className: ''
};

// Grouping Headers
const ActionHeaderGroup = ({children, className, alignment, valign: alignItems, ulStyle, wrap: flexWrap, ...props}) => (
	<li className={`action-group ${className}`} {...props}>
		<ul style={{...ulStyle, justifyContent: alignment, alignItems, flexWrap}}>
			{children}
		</ul>
	</li>
);
ActionHeaderGroup.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	alignment: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-around', 'space-between']),
	valign: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch', 'inherit', 'initial']),
	ulStyle: PropTypes.object,
	wrap: PropTypes.oneOf(['wrap', 'wrap-reverse', 'nowrap', 'inherit'])
};
ActionHeaderGroup.defaultProps = {
	children: null,
	className: '',
	alignment: null,
	ulStyle: {},
	valign: null
};

class ActionSelectFieldFilter extends React.Component {
	constructor(props) {
		super(props);
		this.accessoryKey = this.props.accessoryKey;
		this.accessoryValue = this.props.accessoryValue;
		this.state = {
			selection: props.default,
			items: props.items
		};
	}
	
	updateItems = deepMemoize(items => this.setState({items}))
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// this.setState({items: this.props.items});
		this.updateItems(this.props.items)
	}
	
	render() {
		const {items, selection} = this.state;
		const {onSelect} = this.props;
		return (
			<ActionHeaderGroup>
				<li className="material">
					<Material.SelectField
						value={selection || items[0][this.accessoryKey]}
						onChange={(_, index, key) => {
							this.setState({selection: key});
							onSelect(key, items[index]);
						}}
						style={{top: '8px'}}
						underlineStyle={{display: 'none'}}
					>
						{items.map((s, i) =>
							<Material.MenuItem key={s[this.accessoryKey]} value={s[this.accessoryKey]} primaryText={s[this.accessoryValue]}/>
						)}
					</Material.SelectField>
				</li>
			</ActionHeaderGroup>
		);
	}
}

ActionSelectFieldFilter.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	accessoryKey: PropTypes.string,
	accessoryValue: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	default: PropTypes.string
};
ActionSelectFieldFilter.defaultProps = {
	items: [],
	default: 'all',
	accessoryKey: 'key',
	accessoryValue: 'value'
};

// Action Select filter
class ActionSelectFilter extends React.Component {
	constructor(props) {
		super(props);
		this.accessoryKey = props.accessoryKey;
		this.accessoryValue = props.accessoryValue;
		this.state = {
			selection: props.default,
			items: props.items
		};
		this.update = this.update.bind(this);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.items && Object.keys(prevProps.items).length !== Object.keys(this.props.items).length) {
			this.setState({items: this.props.items});
		}
	}
	
	update(selection) {
		const {onSelect = (key) => true} = this.props;
		onSelect(selection) && this.setState({selection});
	}
	
	render() {
		const {
			id = Math.random() + '-dropdown',
			title,
			bsStyle,
			prefix,
			suffix,
			className,
			prefixElement,
			suffixElement,
			keyPrefix,
			pullRight
		} = this.props;
		const {selection, items} = this.state;
		return (
			<ActionHeaderGroup className={`action-select ${className}`}>
				{prefix && <li className="prefix">{prefix}</li>}
				<li className="bootstrap">
					<Dropdown pullRight={pullRight} id={id} bsStyle="primary" onSelect={(what) => this.update(what)}>
						<Dropdown.Toggle bsSize="large" bsStyle={bsStyle}>{prefixElement}{title || (items.filter(s => s[this.accessoryKey] === selection)[0] || items[0])[this.accessoryValue]}{suffixElement}</Dropdown.Toggle>
						<Dropdown.Menu>
							{items.map((s, i) =>
								s.divider ? <MenuItem divider={true} key={i}/> :
									<MenuItem disabled={s.disabled} divider={false} active={s[this.accessoryKey] === selection} key={keyPrefix + (s[this.accessoryKey] || i)}
									          eventKey={s[this.accessoryKey]}>{prefixElement}{prefixElement}{s[this.accessoryValue]}{suffixElement}</MenuItem>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</li>
				{suffix && <li className="suffix">{suffix}</li>}
			</ActionHeaderGroup>
		);
	}
}

ActionSelectFilter.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onSelect: PropTypes.func,
	default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	bsStyle: PropTypes.string,
	prefix: PropTypes.node,
	prefixElement: PropTypes.node,
	suffix: PropTypes.node,
	suffixElement: PropTypes.node,
	className: PropTypes.string,
	accessoryKey: PropTypes.string,
	accessoryValue: PropTypes.string,
	keyPrefix: PropTypes.string,
	pullRight: PropTypes.bool
};
ActionSelectFilter.defaultProps = {
	items: [],
	title: null,
	bsStyle: 'primary',
	prefix: null,
	suffix: null,
	className: '',
	prefixElement: null,
	suffixElement: null,
	keyPrefix: '',
	pullRight: false,
	accessoryKey: 'key',
	accessoryValue: 'value',
	default: 'all'
};

const ActionHeaderLink = ({children, to, exact, className, ...props}) => (
	<NavLink className={`view__action-header-link ${className}`} to={to} exact={exact} {...props}>{children}</NavLink>
);
ActionHeaderLink.propTypes = {
	children: PropTypes.node.isRequired,
	to: PropTypes.string.isRequired,
	exact: PropTypes.bool,
	className: PropTypes.string
};
ActionHeaderLink.defaultProps = {
	exact: false,
	className: ''
};


// ACTION HEADER
let ActionHeader = ({
	                    ulStyle, className, margin, children, t, alignment, valign: _valignItems, tReady, i18n, wrap: flexWrap,
	                    alignCenter, alignStart, alignEnd, alignBetween, alignAround, alignEvenly,
						valignCenter, valignStart, valignEnd, valignStretch, valignBaseline,
	                    ...props
                    }) => {
	const alignItems = _flexParams({'center': valignCenter, 'flex-start': valignStart, 'flex-end': valignEnd, 'stretch': valignStretch, 'baseline': valignBaseline}) || _valignItems
	const justifyContent = _flexParams({'center': alignCenter, 'flex-start': alignStart, 'flex-end': alignEnd, 'space-between': alignBetween, 'space-around': alignAround, 'space-evenly': alignEvenly}) || alignment
	flexWrap = isBoolean(flexWrap) ? (flexWrap ? 'wrap' : 'nowrap') : flexWrap
	return (
		<div className={cn('view__action-header', className)} {...props}>
			<ul style={{margin, ...ulStyle, justifyContent, alignItems, flexWrap}}>
				{children}
			</ul>
		</div>
	);
}

ActionHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	alignment: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-around', 'space-between']),
	valign: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch', 'inherit', 'initial']),
	ulStyle: PropTypes.object,
	wrap: PropTypes.oneOf(['wrap', 'wrap-reverse', 'nowrap', 'inherit', true, false]),
	margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	alignCenter: PropTypes.bool,
	alignStart: PropTypes.bool,
	alignEnd: PropTypes.bool,
	alignBetween: PropTypes.bool,
	alignAround: PropTypes.bool,
	alignEvenly: PropTypes.bool,
	valignCenter: PropTypes.bool,
	valignStart: PropTypes.bool,
	valignEnd: PropTypes.bool,
	valignStretch: PropTypes.bool,
	valignBaseline: PropTypes.bool
};
ActionHeader.defaultProps = {
	ulStyle: {},
	className: '',
	children: null,
	alignment: null,
	valign: null,
	wrap: null
};


const {Provider, Consumer} = React.createContext({
	responsive: false, hideDialog: () => {
	}
});
const FlexContext = React.createContext({gutterWidth: 0, gutterHeight: 0});


class OptionHeader extends React.Component {
	static propTypes = {
		underWidth: PropTypes.number,
		tooltip: PropTypes.string,
		buttonText: PropTypes.string,
		inverted: PropTypes.bool
	};
	static defaultProps = {
		underWidth: 0,
		inverted: false
	};
	
	state = {
		visible: false
	};
	
	handleDialog = visible => () => this.setState({visible});
	
	render() {
		const {visible} = this.state;
		const {children, screen, underWidth, tooltip, inverted, buttonText, ...props} = this.props;
		const responsive = screen.width < underWidth;
		return (
			<React.Fragment>
				<ActionHeaderGroup {...props}>
					{responsive ? <AHIconButton popup={tooltip ? {content: tooltip, inverted} : null} onClick={this.handleDialog(true)}><Icon name={'filter'} size={'large'}/></AHIconButton> : children}
				</ActionHeaderGroup>
				{/*{responsive &&  visible &&
						<Dialog open onRequestClose={this.handleDialog(false)} className={'ith-responsive-filter'} bodyClassName={'ah-responsive-body'} bodyStyle={{overflowY: 'auto !important'}}>
							<Provider value={{responsive, hideDialog: this.handleDialog(false)}}>
								{children}
							</Provider>
 							{ buttonText &&
								<Flex align={'flex-end'} style={{marginTop: 20}}>
									<FlatButton onClick={this.handleDialog(false)}>{buttonText}</FlatButton>
								</Flex>
							}
						</Dialog>
					}*/}
				{responsive && visible && <Modal open centered={false} onClose={this.handleDialog(false)}>
					<Modal.Content>
						<Provider value={{responsive, hideDialog: this.handleDialog(false)}}>
							{children}
						</Provider>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.handleDialog(false)}><Trans i18nKey={buttonText || 'actions.close'} defaults={buttonText}/></Button>
					</Modal.Actions>
				</Modal>}
			</React.Fragment>
		);
	}
}

OptionHeader = withScreenReader(OptionHeader);

const ActionResponsiveItem = ({as: Tag, children, hideOnClick, style, ...props}) => (
	<Consumer>
		{({responsive, hideDialog}) => (
			responsive ?
				<Tag
					style={{paddingTop: 10, paddingBottom: 10, ...style}}
					onClick={hideOnClick ? hideDialog : null} {...props}>{children}</Tag>
				: children)
		}
	</Consumer>
);
ActionResponsiveItem.propTypes = {
	as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
	children: PropTypes.node,
	hideOnClick: PropTypes.bool,
	style: PropTypes.object
};
ActionResponsiveItem.defaultProps = {
	as: 'div',
	hideOnClick: false
};

const Flex = ({
	              as: Tag,
	              children,
	              inline, align:
		justifyContent, valign:
		alignItems, wrap:
		flexWrapInit, direction:
		flexDirection, grow:
		flexGrow, basis:
		flexBasis, shrink:
		flexShrink,
	              hmargin, vmargin,
	              gutterWidth, gutterHeight,
	              style, ...props
              }) => {
	const mH = -Math.abs(gutterWidth / 2) + hmargin;
	const mV = -Math.abs(gutterHeight / 2) + vmargin;
	const flexWrap = (typeof flexWrapInit === 'boolean') ? (flexWrapInit ? 'wrap' : null) : flexWrapInit;
	return (
		<FlexContext.Provider value={{gutterHeight, gutterWidth}}>
			<Tag children={children} style={{...style, marginLeft: mH, marginRight: mH, marginTop: mV, marginBottom: mV, display: inline ? 'inline-flex' : 'flex', justifyContent, alignItems, flexWrap, flexDirection, flexGrow, flexBasis, flexShrink}} {...props}/>
		</FlexContext.Provider>
	)
};
Flex.propTypes = {
	inline: PropTypes.bool,
	as: PropTypes.string,
	children: PropTypes.node,
	align: PropTypes.string,
	valign: PropTypes.string,
	wrap: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	direction: PropTypes.string,
	grow: PropTypes.number,
	basis: PropTypes.number,
	style: PropTypes.object,
	shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	gutterWidth: PropTypes.number,
	gutterHeight: PropTypes.number,
	hmargin: PropTypes.number,
	vmargin: PropTypes.number
};
Flex.defaultProps = {
	as: 'div',
	inline: false,
	align: null,
	valign: null,
	wrap: null,
	direction: null,
	grow: null,
	basis: null,
	style: null,
	shrink: null,
	gutterWidth: 0,
	gutterHeight: 0,
	hmargin: 0,
	vmargin: 0
};


const EasyFlex = React.forwardRef(({
	                                   style,
	                                   inline,
	                                   wrap: _wrap,
	                                   direction,
	                                   align,
	                                   valign,
	                                   grow: flexGrow,
	                                   shrink: flexShrink,
	                                   basis: flexBasis,
	                                   alignContent,
	                                   alignSelf: _self,
	                                   order,
	                                   wrapReverse,
	                                   wrapNot,
	                                   directionRow,
	                                   directionRowReverse,
	                                   directionColumn,
	                                   directionColumnReverse,
	                                   alignStart,
	                                   alignEnd,
	                                   alignCenter,
	                                   alignBetween,
	                                   alignAround,
	                                   alignEvenly,
	                                   valignStart,
	                                   valignEnd,
	                                   valignCenter,
	                                   valignStretch,
	                                   valignBaseline,
	                                   selfAuto,
	                                   selfStart,
	                                   selfEnd,
	                                   selfCenter,
	                                   selfStretch,
	                                   selfBaseline,
	                                   ...props
                                   }, ref) => {
	let flexWrap = _flexParams({'wrap-reverse': wrapReverse, 'nowrap': wrapNot});
	if (undefined !== _wrap) {
		flexWrap = isBoolean(_wrap) ? (_wrap ? "wrap" : "nowrap") : _wrap;
	}
	const flexDirection = _flexParams({'row': directionRow, 'row-reverse': directionRowReverse, 'column': directionColumn, 'column-reverse': directionColumnReverse}) || direction
	const justifyContent = _flexParams({'flex-start': alignStart, 'flex-end': alignEnd, 'center': alignCenter, 'space-between': alignBetween, 'space-around': alignAround, 'space-evenly': alignEvenly}) || align
	const alignItems = _flexParams({'flex-start': valignStart, 'flex-end': valignEnd, 'center': valignCenter, 'stretch': valignStretch, 'baseline': valignBaseline}) || valign
	const alignSelf = _flexParams({'flex-start': selfStart, 'flex-end': selfEnd, 'center': selfCenter, 'stretch': selfStretch, 'auto': selfAuto, 'baseline': selfBaseline}) || _self
	return (
		<div ref={ref} style={{
			display: inline ? "inline-flex" : "flex",
			flexWrap, flexDirection, justifyContent, alignItems, alignContent, order, flexGrow, flexShrink, flexBasis, alignSelf,
			...style
		}} {...props}/>
	);
});
EasyFlex.display = {
	FLEX: "flex",
	INLINE: "flex-inline"
};
EasyFlex.direction = {
	ROW: "row",
	ROW_REVERSE: "row-reverse",
	COLUMN: "column",
	COLUMN_REVERSE: "column-reverse"
};
EasyFlex.wrap = {
	YES: "wrap",
	NO: "nowrap",
	REVERSE: "wrap-reverse"
};
EasyFlex.align = {
	START: "flex-start",
	END: "flex-end",
	CENTER: "center",
	SPACE_BETWEEN: "space-between",
	SPACE_AROUND: "space-around",
	SPACE_EVENLY: "space-evenly"
};
EasyFlex.valign = {
	START: "flex-start",
	END: "flex-end",
	CENTER: "center",
	STRETCH: "stretch",
	BASELINE: "baseline"
};
EasyFlex.alignContent = {
	START: "flex-start",
	END: "flex-end",
	CENTER: "center",
	STRETCH: "stretch",
	SPACE_BETWEEN: "space-between",
	SPACE_AROUND: "space-around"
};
EasyFlex.alignSelf = {
	AUTO: "auto",
	START: "flex-start",
	END: "flex-end",
	CENTER: "center",
	STRETCH: "stretch",
	BASELINE: "baseline"
};
EasyFlex.propTypes = {
	inline: PropTypes.bool,
	wrap: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf(values(EasyFlex.wrap))
	]),
	direction: PropTypes.oneOf(values(EasyFlex.direction)),
	align: PropTypes.oneOf(values(EasyFlex.align)),
	valign: PropTypes.oneOf(values(EasyFlex.valign)),
	alignContent: PropTypes.oneOf(values(EasyFlex.alignContent)),
	alignSelf: PropTypes.oneOf(values(EasyFlex.alignSelf)),
	style: PropTypes.object,
	grow: PropTypes.number,
	shrink: PropTypes.number,
	order: PropTypes.number,
	basis: PropTypes.number,
	className: PropTypes.string,
	alignCenter: PropTypes.bool,
	alignStart: PropTypes.bool,
	alignEnd: PropTypes.bool,
	alignBetween: PropTypes.bool,
	alignAround: PropTypes.bool,
	alignEvenly: PropTypes.bool,
	valignCenter: PropTypes.bool,
	valignStart: PropTypes.bool,
	valignEnd: PropTypes.bool,
	valignStretch: PropTypes.bool,
	valignBaseline: PropTypes.bool,
	wrapReverse: PropTypes.bool,
	wrapNot: PropTypes.bool,
	directionRow: PropTypes.bool,
	directionRowReverse: PropTypes.bool,
	directionColumn: PropTypes.bool,
	directionColumnReverse: PropTypes.bool,
	selfAuto: PropTypes.bool,
	selfStart: PropTypes.bool,
	selfEnd: PropTypes.bool,
	selfCenter: PropTypes.bool,
	selfStretch: PropTypes.bool,
	selfBaseline: PropTypes.bool,
};

export const View = ({hidden, as: Container, ...props}) => (
	<Container className={cn('view-control', {hidden})} {...props}/>
)
View.propTypes = {
	hidden: PropTypes.any,
	as: PropTypes.any
}
View.defaultProps = {
	hidden: false,
	as: 'div'
}

const FlexItem = ({style, shrink: flexShrink, grow: flexGrow, basis: flexBasis, order, alignSelf, ...props}) => <div style={{
	flexShrink, flexGrow, flexBasis, alignSelf, order,
	...style
}} {...props}/>;
FlexItem.alignSelf = EasyFlex.alignSelf;
FlexItem.propTypes = {
	alignSelf: PropTypes.oneOf(values(EasyFlex.alignSelf)),
	style: PropTypes.object,
	grow: PropTypes.number,
	shrink: PropTypes.number,
	order: PropTypes.number,
	basis: PropTypes.number
};

const handleFlexWidth = (width, gutterWidth) => {
	if (width <= 1) {
		return `calc( ${100 * width}% - ${gutterWidth}px)`;
	}
	if (!width) {
		return null;
	}
	return `calc( ${isString(width) ? width : width + "px"} - ${gutterWidth}px)`;
};

const FlexChild = ({as: Tag, grow: flexGrow, shrink: flexShrink, align: alignSelf, style, width, order, hidden, ellipsis, ...props}) => {
	const ellipStyle = ellipsis ? {
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	} : {};
	return (
		<FlexContext.Consumer>
			{({gutterWidth, gutterHeight}) =>
				<Tag
					style={{
						...style,
						display: hidden ? 'none' : (style ? style.display : null),
						marginLeft: Math.abs(gutterWidth / 2),
						marginRight: Math.abs(gutterWidth / 2),
						marginTop: Math.abs(gutterHeight / 2),
						marginBottom: Math.abs(gutterHeight / 2),
						flexGrow, flexShrink, alignSelf,
						width: handleFlexWidth(width, gutterWidth)/* width ? `calc( ${100 * width}% - ${gutterWidth}px)` : null*/,
						order,
						...ellipStyle
					}}
					{...props}
				/>
			}
		</FlexContext.Consumer>
	);
};

const FlexWrapBreaker = ({style}) => (
	<div style={{width: "100%", height: 0, padding: 0, margin: 0, ...style}}/>
);

FlexChild.propTypes = {
	as: PropTypes.any,
	style: PropTypes.object,
	grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	align: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ellipsis: PropTypes.bool,
	hidden: PropTypes.bool,
	order: PropTypes.number
};
FlexChild.defaultProps = {
	as: 'div',
	screen: {
		width: 0,
		height: 0
	},
	hidden: false,
	ellipsis: false
};

const between = (value, min, max) => value >= min && value <= max;
// const InfusedFlexChild = withScreenReader(FlexChild);
const ResponsiveFlexChild = ({width, widthXS, widthSM, widthMD, widthLG, widthELG, order, orderELG, orderLG, orderMD, orderSM, orderXS, hidden, hiddenXXS, hiddenXS, hiddenSM, hiddenMD, hiddenLG, hiddenELG, ...props}) => (
	<ScreenReader>{screen => {
		if (screen.width > 0) {
			if (widthELG && (screen.width >= SCREEN.ELG)) {
				width = widthELG || width;
				order = orderELG || order;
			} else if (widthLG && (screen.width >= SCREEN.LG)) {
				width = widthLG || width;
				order = orderLG || order;
			} else if (widthMD && (screen.width >= SCREEN.MD)) {
				width = widthMD || width;
				order = orderMD || order;
			} else if (widthSM && (screen.width >= SCREEN.SM)) {
				width = widthSM || width;
				order = orderSM || order;
			} else if (widthXS && (screen.width >= SCREEN.XS)) {
				width = widthXS || width;
				order = orderXS || order;
			}
		}
		if (between(screen.width, 0, SCREEN.XS)) {
			hidden = hiddenXXS;
		} else if (between(screen.width, SCREEN.XS + 1, SCREEN.SM)) {
			hidden = hiddenXS;
		} else if (between(screen.width, SCREEN.SM + 1, SCREEN.MD)) {
			hidden = hiddenSM;
		} else if (between(screen.width, SCREEN.MD + 1, SCREEN.LG)) {
			hidden = hiddenMD;
		} else if (between(screen.width, SCREEN.LG + 1, SCREEN.ELG)) {
			hidden = hiddenLG;
		} else if (screen.width > SCREEN.ELG) {
			hidden = hiddenELG;
		}
		return (<FlexChild screen={screen} width={width} order={order} hidden={hidden} {...props}/>);
	}}</ScreenReader>
);
ResponsiveFlexChild.propTypes = {
	as: PropTypes.any,
	style: PropTypes.object,
	grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	align: PropTypes.string,
	width: PropTypes.number,
	widthXS: PropTypes.number,
	widthSM: PropTypes.number,
	widthMD: PropTypes.number,
	widthLG: PropTypes.number,
	widthELG: PropTypes.number,
	order: PropTypes.number,
	orderXS: PropTypes.number,
	orderSM: PropTypes.number,
	orderMD: PropTypes.number,
	orderLG: PropTypes.number,
	orderELG: PropTypes.number,
	ellipsis: PropTypes.bool,
	hidden: PropTypes.bool,
	hiddenXXS: PropTypes.bool,
	hiddenXS: PropTypes.bool,
	hiddenSM: PropTypes.bool,
	hiddenMD: PropTypes.bool,
	hiddenLG: PropTypes.bool,
	hiddenELG: PropTypes.bool
};
ResponsiveFlexChild.defaultProps = {
	as: 'div',
	ellipsis: false,
	hidden: false,
	hiddenXXS: false,
	hiddenXS: false,
	hiddenSM: false,
	hiddenMD: false,
	hiddenLG: false,
	hiddenELG: false
};

const Space = ({height, style, ...props}) => <div style={{height, ...style}} {...props}/>;
const Mount = ({children, show}) => trueNull(show) && <React.Fragment>{children}</React.Fragment>;

export {
	ActionHeaderGroup, ActionBackButton, ActionRefreshButton, ActionHeader,
	ActionHeading, ActionDeleteButton, ActionIconButton, ActionTextButton,
	ActionSelectFilter, ActionSelectFieldFilter, ActionHeaderLink, Flex, FlexChild, ResponsiveFlexChild,
	OptionHeader, Consumer as OptionConsumer, ActionResponsiveItem, Space, Mount, FlexWrapBreaker, EasyFlex, FlexItem
};
export default translate()(ActionHeader);