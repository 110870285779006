import * as React from 'react';
import PropTypes from 'prop-types';
import {Trans, translate} from "react-i18next";
import {Button, Form, FormField, Grid, Header, Icon, List, Message, Modal, Segment,} from "semantic-ui-react";
import sizeMe from 'react-sizeme';
import {MOMENTJS, PAPER_STYLE} from "../../../Logic/constants";
import {FlatButton, IconButton, MenuItem, Paper, SelectField} from "material-ui";
import EditField from "../../partials/EditField";
import NoAccess from "../../NoAccess";
import {EasyFlex, Flex, FlexItem} from "../../partials/ActionHeader";
import ModelSearchController from "../../../cointainer/intern/vehicles/ModelSearchController";
import moment from '../../../Logic/Moment';
import {deepMemoize as memoizeOne, jsonNullify, jsonRemoveNull} from "../../../Logic/extensions";
import {DateMaskField} from "../../partials/MaskField";
import {IconCar, IconEvent, IconSearch} from "../../../Logic/icons";
import {vehicleAction__transfer, vehicleCall__searchChassis} from "../../../actions/vehicleActions";
import {ConfirmButton, ConfirmButtonConsumer} from "../../partials/ConfirmButton";
import Highlight from "react-highlighter";
import {connect} from "react-redux";
import {addSnackbar} from "../../../actions/snackbarActions";
import {debounce, get, isFunction} from "lodash";

const VehicleView = ({hightlight='###########', vehicle, vehicle: {chassis_number, name, registration_mark, hsn, tsn, km}, onConfirm}) => (
	<EasyFlex valign={EasyFlex.valign.CENTER}>
		<FlexItem style={{paddingLeft: 10, paddingRight: 10}} shrink={0}><IconCar/></FlexItem>
		<FlexItem style={{paddingLeft: 10, paddingRight: 10}} grow={10} shrink={1}>
			<Header as={"h5"} style={{marginBottom: 8}}><Highlight search={hightlight}>{chassis_number}</Highlight></Header>
			<div>{name}</div>
			<div>{registration_mark}</div>
			<div>{hsn}/{tsn} - {km} km</div>
		</FlexItem>
		<FlexItem style={{paddingLeft: 10, paddingRight: 10}} grow={1} shrink={.1}>
			<ConfirmButton positive content={"Fahrzeug übetragen"}>
				<ConfirmButtonConsumer>{ confirm =>
					<Button.Group>
						<Button onClick={e => {
							e && e.stopPropagation();
							confirm(false);
						}}><Icon name={"close"}/>Abbrechen</Button>
						<Button.Or/>
						<Button positive onClick={onConfirm.bind(null, vehicle)}>Übertragen</Button>
					</Button.Group>
				}</ConfirmButtonConsumer>
			</ConfirmButton>
		</FlexItem>
	</EasyFlex>
);

class SearchChassis extends React.Component {
	static propTypes = {
		searchTerm: PropTypes.string.isRequired,
		onSelect: PropTypes.func.isRequired
	};
	state = {
		list: [],
		loading: false,
		error: null
	};
	
	async componentDidMount() {
		try {
			this.setState({loading: true, error: null});
			const result = await vehicleCall__searchChassis(this.props.searchTerm);
			this.setState({list: result.list});
		} catch (e) {
			this.setState({list: [], error: e.error || e.message || e});
			console.error("VehicleMask.js -> SerchChassis Component -> ", e);
		} finally {
			this.setState({loading: false});
		}
	}
	
	render() {
		const {
			list,
			loading,
			error
		} = this.state;
		return (
			<Segment basic loading={loading}>
				<Header style={{marginBottom: 15}} as={"h3"}>Ergebnisse</Header>
				{error ?
					<Message negative>
						<p>Ein Fehler is aufgetreten!</p>
					</Message>
					:
					<List divided relaxed>
						{list.map(vehicle => <List.Item key={vehicle.vehicle_id}>
							<List.Content>
								<VehicleView vehicle={vehicle} hightlight={this.props.searchTerm.trim()} onConfirm={this.props.onSelect}/>
							</List.Content>
						</List.Item>)}
					</List>
				}
			</Segment>
		);
	}
}


const PaperWrap = ({wrap, children}) => wrap ? <Paper style={{...PAPER_STYLE, marginBottom: 20}}>{children}</Paper> : <Segment style={{marginBottom: 20}}>{children}</Segment>;

let KfzItemList = ({list, children}) => children(list);
KfzItemList = connect(
	state => ({
		list: Object.values(get(state, 'kfz.cars', {}))
	})
)(KfzItemList);


class VehicleMask extends React.Component {
	static propTypes = {
		vehicle: PropTypes.object,
		isSaving: PropTypes.bool,
		onSave: PropTypes.func.isRequired,
		mayCreate: PropTypes.bool,
		mayChange: PropTypes.bool,
		mayRead: PropTypes.bool,
		kfz_list: PropTypes.array.isRequired,
		isLoading: PropTypes.bool,
		client_id: PropTypes.number,
		onTransfer: PropTypes.func,
		onAfterTransfer: PropTypes.func,
		onAfterSave: PropTypes.func,
		papered: PropTypes.bool
	};
	static defaultProps = {
		isSaving: false,
		isLoading: false,
		kfz_list: [],
		onSave: () => alert('onSave() is not implemented yet!'),
		papered: false
	};
	
	state = {
		fullscreen: false,
		chassis_search: false,
		exists_chassis: false,
		kfz: {
			brand: 0,
			model: 0
		},
		vehicle: {
			model_id: '',
			hsn: '',
			tsn: '',
			chassis_number: '',
			registration_mark: '',
			km: '',
			model_year: '',
			name: '',
			first_registration: ''
		},
		focused: {
			model_id: true
		}
	};
	
	constructor(props) {
		super(props);
		this.existsChassis = debounce(this.existsChassis, 400);
	}
	
	_handleVehicleProps = memoizeOne(vehicle => {
		if (vehicle) {
			const v = {...vehicle};
			if ( v.first_registration) {
				v.first_registration = moment(v.first_registration).format(MOMENTJS.DATE_FORMAT);
			}
			this.setState({vehicle: jsonRemoveNull(v)});
		}
	});
	
	componentDidMount() {
		this._handleVehicleProps(this.props.vehicle);
	}
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this._handleVehicleProps(this.props.vehicle);
	}
	
	showModelList = fullscreen => () => this.setState({fullscreen});
	
	handleFocus = index => () => !this.state.focused[index] && this.setState(state => ({...state, focused: {...state.focused, [index]: true}}));
	handleChange = index => (_, {value}) => this.setState(state => ({...state, vehicle: {...state.vehicle, [index]: value}}));
	handleNoChange = index => (_, {value}) => this.setState(state => ({...state, vehicle: {...state.vehicle, [index]: value, model_id: ''}}));
	handleFirstRegistration = (e, first_registration) => this.setState(state => ({ ...state, vehicle: {...state.vehicle, first_registration}}));
	handleKfz = (_, key, value) => {
		this.setState(state => ({...state, kfz: {...state.kfz, brand: value}}));
	}
	
	required = (index, error = 'errors.input.required') => this.state.focused[index] && `${this.state.vehicle[index]}`.trim() === '' ? this.props.t(error) : null;
	minLength = (index, value, error = 'errors.input.min-length') => this.state.focused[index] && this.state.vehicle[index].trim().length < value ? this.props.t(error, {value}) : null;
	maxLength = (index, value, error = 'errors.input.max-length') => this.state.focused[index] && this.state.vehicle[index].trim().length > value ? this.props.t(error, {value}) : null;
	
	validLengthOf = (index, number) => this.state.vehicle[index].trim().length === number;
	valid = () => {
		// const {first_registration} = this.state.vehicle;
		// const frValid = Boolean(first_registration.trim()) && first_registration.indexOf("_") < 0 && moment(first_registration).isValid();
		return this.validLengthOf('hsn', 4) && this.validLengthOf('tsn', 3) && this.validFirstRegistration() && !this.required('model_id');
	};
	
	validFirstRegistration = () => {
		const {first_registration} = this.state.vehicle;
		return (!Boolean(first_registration.trim()) || Boolean(first_registration.trim() === "__.__.____") || first_registration.indexOf("_") < 0) && moment(first_registration, "L").isValid();
	};
	
	handleSave = () => {
		const vehicle = {...this.state.vehicle};
		this.props.onSave(jsonNullify(vehicle), this.props.onAfterSave);
	};
	
	firstRegistrationError = () => {
		return this.validFirstRegistration() ? null : "Kein gültiges Datum!";
	};
	
	existsChassis = async number => {
		if (!number) return;
		try {
			const result = await vehicleCall__searchChassis(number, false);
			this.setState({exists_chassis: !!result.list.length});
		} catch(e){
			console.error(e);
		}
	};
	
	handleModelSelect = model => this.setState(state => ({
		...state,
		vehicle: {
			...state.vehicle,
			name: model.type,
			tsn: model.tsn,
			hsn: model.hsn,
			model_id: model.model_id
		}
	}));
	
	searchChassis = chassis_search => () => this.setState({chassis_search});
	
	set = (index, value) => () => this.setState(state => ({...state, vehicle:{...state.vehicle, [index]: value}}));
	
	onTransfer = (vehicle) => {
		this.props.onTransfer(vehicle.vehicle_id, this.props.client_id, result => {
			this.props.onAfterTransfer && this.props.onAfterTransfer(result);
		});
	};
	
	columns = memoizeOne(
		width => Math.max(1, Math.floor(width / 444))
	);
	
	render() {
		const {t, size, mayRead, mayCreate, mayChange, vehicle: givenVehicle, isSaving, isLoading, papered} = this.props;
		
		if (!mayRead) return <NoAccess/>;
		
		const editable = givenVehicle ? mayChange : mayCreate;
		
		const {vehicle, kfz: selectedKfz, fullscreen} = this.state;
		const columns = this.columns(size.width);
		
		return(
			<Form onSubmit={this.handleSave} loading={isSaving || isLoading}>
				<Grid columns={columns} stretched>
					<Grid.Row>
						<Grid.Column stretched>
							{mayChange && <PaperWrap wrap={papered}>
								<label><strong>{t('vehicle-mask.find-vehicle')}</strong></label>
								<KfzItemList>{list =>
									<SelectField value={selectedKfz.brand} disabled={!mayChange} floatingLabelText={t('vehicle-mask.select-vehicle-brand')} fullWidth onChange={this.handleKfz} hintText={'-- Bitte auswählen --'}>
										{list.map(kfz => <MenuItem key={kfz.kfz_id} value={kfz.kfz_id} primaryText={kfz.name}/>)}
									</SelectField>
								}</KfzItemList>
								<FlatButton disabled={0 === selectedKfz.brand } fullWidth style={{marginTop: 25}} onClick={this.showModelList(true)}>{t('vehicle-mask.search-vehicle')}</FlatButton>
							</PaperWrap>}
						</Grid.Column>
						<Grid.Column stretched>
							<PaperWrap wrap={papered}>
								<FormField>
									<EditField
										editable={editable}
										label={t('vehicle-mask.ident')}
										placeholder={t('vehicle-mask.manufacturer-model-name')}
										autoComplete={'off'}
										value={vehicle.name}
										onChange={this.handleChange('name')}
										onFocus={this.handleFocus('name')}
									/>
								</FormField>
								<FormField required>
									<EditField
										required
										label={t('vehicle-mask.model-id')}
										placeholder={t('vehicle-mask.here-stands-model-id')}
										autoComplete={'off'}
										value={vehicle.model_id}
										errorText={this.required('model_id', 'Bitte ein Modell auswählen')}
										errorBeneeth
									/>
								</FormField>
								<FormField required>
									<EditField
										required
										editable={editable}
										label={t('vehicle-mask.hsn')}
										placeholder={t('vehicle-mask.four-digit-number')}
										autoComplete={'off'}
										value={vehicle.hsn}
										onChange={this.handleNoChange('hsn')}
										onFocus={this.handleFocus('hsn')}
										errorText={this.required('hsn') || this.minLength('hsn', 4) || this.maxLength('hsn', 4)}
									/>
								</FormField>
								<FormField required>
									<EditField
										required
										editable={editable}
										label={t('vehicle-mask.tsn')}
										placeholder={t('vehicle-mask.three-digit-number')}
										autoComplete={'off'}
										value={vehicle.tsn}
										onChange={this.handleNoChange('tsn')}
										onFocus={this.handleFocus('tsn')}
										errorText={this.required('tsn') || this.minLength('tsn', 3) || this.maxLength('tsn', 3)}
									/>
								</FormField>
							</PaperWrap>
						</Grid.Column>
						<Grid.Column stretched>
							<PaperWrap wrap={papered}>
								<FormField>
									<EditField
										editable={editable}
										label={t('vehicle-mask.chassis-number')}
										placeholder={t('vehicle-mask.chassis-number')}
										autoComplete={'off'}
										value={vehicle.chassis_number}
										onChange={this.handleChange('chassis_number')}
										onFocus={this.handleFocus('chassis_number')}
										onKeyUp={e => this.existsChassis(e.target.value.trim())}
										errorText={this.state.exists_chassis ? 'Fahrgestellnummer bereits vergeben!' : null}
										errorBeneeth
									>
										{! givenVehicle && vehicle.chassis_number && <IconButton onClick={this.searchChassis(true)}><IconSearch/></IconButton>}
									</EditField>
								</FormField>
								<FormField>
									<EditField
										editable={editable}
										label={t('vehicle-mask.registration-mark')}
										placeholder={t('vehicle-mask.registration-mark-example')}
										autoComplete={'off'}
										value={vehicle.registration_mark}
										onChange={this.handleChange('registration_mark')}
										onFocus={this.handleFocus('registration_mark')}
									/>
								</FormField>
								<FormField>
									<EditField
										editable={editable}
										label={t('vehicle-mask.model-year')}
										placeholder={t('vehicle-mask.model-year')}
										autoComplete={'off'}
										value={vehicle.model_year}
										onChange={this.handleChange('model_year')}
										onFocus={this.handleFocus('model_year')}
										type={'numner'}
										min={1800}
									/>
								</FormField>
								{/*<FormField>
							<DatePicker
								value={vehicle.first_registration}
								onChange={this.handleChange('first_registration')}
								startMode={'year'}
								maxDate={now}
								editable={editable}
								autoComplete={'off'}
								text={t('vehicle-mask.first-registration')}
								onClear={this.set('first_registration', '')}
							/>
							
						</FormField>*/}
								<FormField>
									<DateMaskField
										label={t('vehicle-mask.first-registration')}
										placeholder={t('vehicle-mask.first-registration-placeholder')}
										icon={<IconEvent/>}
										editable
										onChange={this.handleFirstRegistration}
										value={vehicle.first_registration}
										errorText={this.firstRegistrationError()}
										clearable
									/>
								</FormField>
								<FormField>
									<EditField
										editable={editable}
										label={t('vehicle-mask.km')}
										placeholder={t('vehicle-mask.km-rounded')}
										autoComplete={'off'}
										value={vehicle.km}
										type={'number'}
										min={0}
										onChange={this.handleChange('km')}
										onFocus={this.handleFocus('km')}
									/>
								</FormField>
							</PaperWrap>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{
					editable &&
						<div style={{marginTop: 25, marginBottom: 40}}>
							<Flex align={'flex-end'}>
								<Button basic onClick={this.handleSave} loading={isLoading || isSaving} positive type={'button'} disabled={!this.valid()}>{vehicle ? t('actions.save') : t('actions.create')}</Button>
							</Flex>
						</div>
				}
				
				{fullscreen && <ModelSearchController kfz_id={selectedKfz.brand} onHide={this.showModelList(false)} onSelect={this.handleModelSelect}/>}
				{/*<BasicDialog autoScrollBodyContent open={this.state.chassis_search} onClose={this.searchChassis(false)} closeButton>
					{this.state.chassis_search && <SearchChassis onSelect={this.onTransfer} searchTerm={vehicle.chassis_number}/>}
				</BasicDialog>*/}
				{this.state.chassis_search && <Modal open centered={false} onClose={this.searchChassis(false)}>
					<Modal.Content>
						<SearchChassis onSelect={this.onTransfer} searchTerm={vehicle.chassis_number}/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.searchChassis(false)}><Trans i18nKey={'actions.close'}/></Button>
					</Modal.Actions>
				</Modal>}
			</Form>
		);
	}
}

VehicleMask = sizeMe()(VehicleMask);
VehicleMask = translate()(VehicleMask);
VehicleMask = connect(null, dispatch => ({
	onTransfer: (vehicle_id, client_id, onSuccess) => dispatch(vehicleAction__transfer(vehicle_id, client_id, result => {
		dispatch(addSnackbar('Fahrzeug wurde transferiert.'));
		isFunction(onSuccess) && onSuccess(result);
	})),
}))(VehicleMask);

export default VehicleMask;