import * as React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ErrorView} from "./ErrorControllerV2";
import {push} from "connected-react-router";
import {translate} from "react-i18next";
import {errorCall__javascript} from "../actions/errorActions";
import StackTrace from "stacktrace-js";

class ErrorBoundary extends React.Component {
	static propTypes = {
		visible: PropTypes.bool,
		errorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
		send: PropTypes.bool,
		debug: PropTypes.bool,
		debugView: PropTypes.bool
	};
	static defaultProps = {
		visible: false,
		send: true,
		debug: false,
		debugView: false
	};
	
	state = {
		error: null,
		errorInfo: null,
		sending: false,
		isSent: false
	};
	
	sendToServer = async (error) => {
		this.props.debug && console.error("Sending error to server...", this.props.send);
		if (this.props.send) {
			try {
				this.setState({sending: true});
				await errorCall__javascript(error);
			} finally {
				this.setState({sending: false});
			}
		}
		this.setState({isSent: true});
	};
	
	componentDidCatch(error, errorInfo) {
		if (this.hasError ) {
			this.props.debug && console.error("component already reported error!");
			
		} else {
			this.hasError = true;
			this.props.debug && console.error("component did catch -> ERROR", error);
			this.props.debug && console.error("component did catch -> E-STRING", error.toString());
			this.props.debug && console.error("component did catch -> E-SOURCE", String(error));
			this.props.debug && console.error("component did catch -> INFO", errorInfo);
			errorInfo = {
				...errorInfo,
				error: error.toString(),
				message: "errors.executation",
				code: 409,
				feedback: false,
				stack: errorInfo.componentStack.split("\n"),
				location: window.location.toString()
			};
			this.setState({error, errorInfo});
			StackTrace.fromError(error).then(err => {
				const errorMap = err.map(String);
				this.props.debug && console.error("component did catch -> TRACE", errorMap);
				errorInfo = {
					...errorInfo,
					stack: [...errorInfo.stack, "", "StackTrace:", ...errorMap]
				};
				this.setState({errorInfo});
				this.sendToServer(errorInfo);
			}).catch(() => {
				this.props.debug && console.error("component failed to TRACE!");
				this.sendToServer(errorInfo);
			});
		}
	}
	
	render() {
		const {
			visible,
			errorComponent: ErrorComponentView,
			onDismiss,
			t,
			debugView
		} = this.props;
		const {
			error,
			errorInfo,
			sending
		} = this.state;
		if (visible && errorInfo) {
			if (ErrorComponentView) {
				if ( React.isValidElement(ErrorComponentView)) {
					return React.cloneElement(ErrorComponentView, {error, errorInfo});
				}
				const EV = <ErrorComponentView error={error} errorInfo={errorInfo}/>;
				if ( React.isValidElement(EV)) {
					return EV;
				}
			}
			return debugView ? <pre>{JSON.stringify(errorInfo, null, 2)}</pre> : <ErrorView link={'/'} disabled={sending} loading={sending} error={errorInfo} onDismiss={onDismiss} count={1} t={t}/>
		}
		return this.props.children;
	}
}

ErrorBoundary = connect(
	null,
	dispatch => ({
		onDismiss: () => dispatch(push('/')),
	})
)(ErrorBoundary);
ErrorBoundary = translate()(ErrorBoundary);

export {ErrorBoundary};