import * as React from 'react';
import {Button, Segment} from "semantic-ui-react";
import {ActionBackButton, ActionHeader, ActionHeaderGroup, ActionHeading} from "../partials/ActionHeader";
import {ClientSearchModal} from "../intern/clients/search/ClientSearch";
import {clientCall__searchTest} from "../../actions/clientActions";


export class ClientSearchTest extends React.Component {
	state = {
		show: true
	}
	render() {
		return (
			<div>
				<ActionHeader alignment={'space-between'} ulStyle={{marginLeft: 20, marginRight: 20}}>
					<ActionHeaderGroup>
						<ActionBackButton/>
						<ActionHeading>Client Search Test</ActionHeading>
					</ActionHeaderGroup>
				</ActionHeader>
				<Segment>
					<Button onClick={() => this.setState({show: true})}>OPEN</Button>
					<ClientSearchModal
						open={this.state.show}
						onModalClose={() => this.setState({show: false})}
						onSelectClient={client => this.setState({show: false}, () => console.warn('selected client only', client)) }
						onSelectVehicle={(vehicle, client) => this.setState({show: false}, () => console.warn('selected', vehicle, client)) }
						onSearch={clientCall__searchTest}
					/>
				</Segment>
			</div>
		);
	}
}