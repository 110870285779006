import {connect} from "react-redux";
import {PROC_HOUSE} from "../../../../actions";
import {isLoading} from "../../../../actions/loaderActions";
import {addSnackbar} from "../../../../actions/snackbarActions";
import isFunc from "lodash/isFunction";
import {updateMasterDataOfHouse} from "../../../../actions/carhouseActions";
import MDHouseWorker from "../../../../components/intern/master-data/base/MDHouseWorker";

const m2s = state => {
	const house = state.carhouse.own;
	return ({
		house,
		isSaving: house ? isLoading(state, PROC_HOUSE, house.house_id, 'worker') : false
	});
};

const m2d = dispatch => ({
	onSave: (house, data, onSuccess) => dispatch(updateMasterDataOfHouse(house, data, 'worker', result => {
		isFunc(onSuccess) && onSuccess(result);
		dispatch(addSnackbar('snackbar.md-house.updated'));
	}))
});

export default connect(m2s, m2d)(MDHouseWorker);