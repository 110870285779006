import User from "../../../models/User";
import {
	kfzAction__fetch,
	kfzAction__fetchKfzServices,
	kfzAction__fetchModelsOf,
	kfzAction__fetchServices,
	kfzAction__fetchServicesOfKfzModels,
	kfzAction__removeServicesOfKfzModels,
	kfzAction__updateServicesOfKfzModels
} from "../../../actions/kfzActions";
import {isLoading} from "../../../actions/loaderActions";
import {PROC_KFZ_FETCH, PROC_KFZ_FETCH_MODEL_SERVICES, PROC_KFZ_FETCH_SERVICES} from "../../../actions";
import {SECTION} from "../../../Logic/constants";
import {isOnline} from "../../../actions/userActions";
import {connect} from "react-redux";
import {tryable} from "ah-lib";
import {routeState__noAccess, routeState__push} from "../../../actions/routeActions";
import {values} from '../../../Logic/extensions';
import MasterDataKfzServiceView from "../../../components/intern/master-data/MasterDataKfzServiceView";
import withInit from "../../../Logic/withInit";
import isFunc from "lodash/isFunction";
import {addSnackbar} from "../../../actions/snackbarActions";
import {loadResourcesOfHouse, resourceAction__fetchModels} from "../../../actions/resourceActions";

const m2s = (state, props) => {
	const user = User._(state.user.data);
	const service_id = Number(props.match.params.service_id);
	const kfz_id = Number(props.match.params.kfz_id);
	return {
		service_id, kfz_id,
		fetchingService: isLoading(state, PROC_KFZ_FETCH_SERVICES),
		fetchingCars: isLoading(state, PROC_KFZ_FETCH),
		fetchingModelService: isLoading(state, PROC_KFZ_FETCH_MODEL_SERVICES, service_id, kfz_id),
		...user.getAccessTo(SECTION.MASTER_DATA, [0, 1]),
		service: tryable(() => state.kfz.service.list[service_id])(undefined),
		kfz: tryable(() => state.kfz.cars[kfz_id])(undefined),
		kfz_list: tryable(() => values(state.kfz.cars))(undefined),
		service_car_custom: tryable(() => state.kfz.service.cars.custom[service_id][kfz_id])(undefined),
		service_car_origin: tryable(() => state.kfz.service.cars.origin[service_id][kfz_id])(undefined),
		model_list: tryable(() => values(state.kfz.models[kfz_id]))(undefined),
		service_model: tryable(() => state.kfz.service.models[service_id][kfz_id])(undefined),
		resource_list: values(state.resources.list)
	};
};

const m2d = (dispatch, props) => {
	const service_id = Number(props.match.params.service_id);
	const kfz_id = Number(props.match.params.kfz_id);
	return {
		init: () => dispatch(isOnline(() => {
			dispatch(loadResourcesOfHouse());
			dispatch(kfzAction__fetchServices());
			dispatch(kfzAction__fetch());
			dispatch(kfzAction__fetchKfzServices(service_id));
			dispatch(kfzAction__fetchModelsOf(kfz_id));
			dispatch(resourceAction__fetchModels());
			dispatch(kfzAction__fetchServicesOfKfzModels(service_id, kfz_id));
		})),
		noAccess: () => dispatch(routeState__noAccess()),
		toModelsList: kfz_id => dispatch(routeState__push(`/master-data/services/${service_id}/kfz/${kfz_id}`)),
		onBack: () => dispatch(routeState__push(`/master-data/services/${service_id}`)),
		saveCustomPrice: (data, onSuccess) => dispatch(kfzAction__updateServicesOfKfzModels(service_id, kfz_id, data, result => {
			isFunc(onSuccess) && onSuccess(result);
			dispatch(addSnackbar('snackbar.md-service.custom-model-service.updated'));
		})),
		removeCustomPrice: (models, onSuccess) => dispatch(kfzAction__removeServicesOfKfzModels(service_id, models, result => {
			isFunc(onSuccess) && onSuccess(result);
			if (result.removed_models.length) {
				dispatch(addSnackbar('snackbar.md-service.custom-model-service.removed'));
			} else {
				dispatch(addSnackbar('snackbar.md-service.custom-model-service.none-given'));
			}
		}))
	};
};


export default connect(m2s, m2d)(withInit(MasterDataKfzServiceView));